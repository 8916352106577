// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as yup from "yup";

export const VALIDATION_SCHEMA = yup.object().shape({
    name: yup.string().required("Name is required"),
    user_type: yup.string().required("Provider Type is required"),
    email: yup.string().email("Enter a valid email address").required("Email is required"),
    mobile: yup
        .string()
        .matches(/^\d{10}$/, "Contact Number must be a valid US number")
        .required("Mobile no. is required"),
});
