// Copyright aptihealth, inc. 2019 All Rights Reserved

import * as actionTypes from "./actionTypes";

export const showLoader =
    (isMultiPartRequest = false) =>
    (dispatch) => {
        dispatch({ type: actionTypes.SHOW_SPINNER, isMultiPartRequest });
    };

export const hideLoader = () => (dispatch) => {
    dispatch({ type: actionTypes.HIDE_SPINNER });
};
