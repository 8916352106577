// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import DashboardViewCard from "./DashboardViewCard";
import "./styles.scss";
import TodaysSchedule from "./TodaysSchedule";
import UpcomingVideoCall from "./UpcomingVideoCall";
import NinetyDayTreatmentWidget from "./NinetyDayTreatmentWidget";
import { api } from "../../../APIRequests";
import moment from "moment-timezone";
import AptihealthTips from "./AptihealthTips";
import _ from "lodash";
import { ProviderBanner } from "./ProviderBanner";
import { showAlertWithActionAndCustomButton } from "../../../redux/actions/alerts";
import { connect } from "react-redux";
import DraftNotesCard from "./DraftNotesCard";
import { isUserPCP } from "../../../redux/actions/auth";
import { getScheduleForToday } from "../../../utils/scheduleUtils";

class DashboardView extends Component {
    PDF_URL =
        "https://aptihealth-portal-backend-stack-de-assetsb7c36b1b-c5j0nt8kzbnt.s3-us-west-2.amazonaws.com/pdfs/aptihealth_Patient+Support.pdf";

    constructor(props) {
        super(props);
        this.state = {
            calls: null,
            nextCall: null,
            tips: null,
            banner: null,
            provider_draft_notes: 0,
        };
    }

    componentDidMount() {
        const options = { params: { showLoader: false } };
        let today = moment.utc().format("YYYY-MM-DD");
        let queryParams = { start_date: today, end_date: today };
        getScheduleForToday()
            .then((response) => {
                if (response && response.schedule && response.schedule.length > 0) {
                    let calls = response.schedule.filter((call) => {
                        return (
                            moment
                                .utc(call.timestamp, "YYYY/M/D HH:mm")
                                .isSame(Date.now(), "day") &&
                            call.hasOwnProperty("call_status") &&
                            call["call_status"] === true
                        );
                    });

                    calls.map((call) => {
                        call.timestamp = moment
                            .utc(call.timestamp, "YYYY/M/D HH:mm")
                            .local()
                            .format("HH:mm");
                    });
                    if (calls.length > 0) {
                        this.setState({
                            nextCall: calls[0],
                            calls: calls,
                        });
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
        api.provider
            .fetch_aptihealth_tips({ options })
            .then((response) => {
                this.setState({
                    tips: response.tips,
                });
            })
            .catch((err) => {
                console.log(err);
            });

        api.provider
            .fetch_provider_banner({ options })
            .then((response) => {
                if (!_.isEmpty(response)) {
                    this.setState({
                        banner: response,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });

        api.provider
            .fetch_provider_draft_notes_count()
            .then((response) => {
                this.setState({ provider_draft_notes: response });
            })
            .catch((err) => {
                console.log(err);
            });

        if (!(this.props.daysUntilPasswordReset == null)) {
            if (this.props.daysUntilPasswordReset <= 7) {
                this.props.showAlertWithActionAndCustomButton(
                    `For security best practices and your protection aptihealth requires you to change your password every 90 days. Your password is set to expire in ${this.props.daysUntilPasswordReset} days.`,
                    () => {
                        this.props.history.push("/app/change-password");
                    },
                    "Change Password",
                );
            }
        }
    }

    render() {
        let banner = this.state.banner;
        return (
            <div data-public="true">
                <h3 className="fs-25 fw-bold mb-3 mb-lg-4">Dashboard</h3>
                <div className="row no-gutters">
                    {banner && (
                        <div className="col-12 my-4">
                            <ProviderBanner
                                clickUrl={banner.clickUrl}
                                headerText={banner.headerText}
                                bodyText={banner.bodyText}
                            />
                        </div>
                    )}
                    <div className="col-lg-4 col-6 pr-lg-4 pr-2">
                        <DashboardViewCard patient_count={this.props.patient_count} />
                    </div>
                    <div className="col-lg-4 col-6 pl-2 pr-2">
                        <UpcomingVideoCall
                            {...this.props}
                            clickOnMobile
                            nextCall={this.state.nextCall}
                        />
                    </div>
                    {!isUserPCP() && (
                        <div className="col-lg-4 col-6 pl-lg-4 pl-md-2 mt-4 mt-lg-0 pl-sm-0 pl-xs-0">
                            <DraftNotesCard numberOfDraftNotes={this.state.provider_draft_notes} />
                        </div>
                    )}
                </div>
                <br />
                <div className="row no-gutters">
                    <div className="col-12">
                        {this.state.tips && <AptihealthTips aptiTips={this.state.tips} />}
                    </div>
                </div>
                <br />
                <div className="row no-gutters">
                    <div className="col-12 col-lg-8 mb-4 mb-lg-0 pr-lg-4 ">
                        <NinetyDayTreatmentWidget />
                    </div>
                    <div className="col-lg-4 col-12 pr-lg-1">
                        {this.state.calls && (
                            <TodaysSchedule {...this.props} calls={this.state.calls} />
                        )}
                        {!this.state.calls && <TodaysSchedule />}
                    </div>
                </div>
                <br />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        daysUntilPasswordReset: state.auth.daysUntilPasswordReset,
    };
};
export default connect(mapStateToProps, { showAlertWithActionAndCustomButton })(DashboardView);
