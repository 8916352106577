import React, { useEffect, useState } from "react";
import { processRouteInitData } from "./RouterFactoryUtils";
import _ from "lodash";
import { useLocation, withRouter } from "react-router-dom";

const RouterFactory = (props) => {
    const [routerProps, setRouterProps] = useState(_.cloneDeep(props));
    const { search } = useLocation();

    useEffect(() => {
        setRouterProps(props);
    }, [props]);

    useEffect(() => {
        try {
            const pattern = "\\bpatientName\\b|\\bemail\\b";
            if (search && search.match(pattern)) {
                const urlSearchParams = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());
                const queryString = Object.keys(params)
                    .filter((param) => !param.match(pattern))
                    .map((key) => key + "=" + params[key])
                    .join("&");
                const newLocation = queryString
                    ? window.location.pathname + `?${queryString}`
                    : window.location.pathname;
                props.history.push(newLocation);
            }
        } catch (e) {
            console.log(e);
        }
    }, [search]);

    const allowRedirect =
        window.location.pathname === "/" ||
        window.location.pathname === "/app" ||
        window.location.pathname === "/app/home" ||
        window.location.pathname === "/auth" ||
        window.location.pathname === "/auth/sign-in" ||
        window.location.pathname === "/auth/sign-up" ||
        window.location.pathname === "/auth/challenge" ||
        !window.localStorage.getItem("token");

    return processRouteInitData(allowRedirect, routerProps);
};

export default withRouter(RouterFactory);
