import Button from "../../../UI/Button";
import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";

export const diReminderWidget = (props) => {
    const { call, onSubmit } = props;

    const localizedCallDateTime = moment.utc(call.timestamp, "YYYY-MM-DD HH:mm").local();
    const localizedCallDate = localizedCallDateTime.format("MMMM DD, YYYY");
    const localizedCallTime = localizedCallDateTime.format("hh:mm A");

    const changeAppointment = () => {
        onSubmit();
        props.history.push("/app/schedule/available");
    };

    return (
        <>
            <div
                style={{ color: "#668391" }}
                className="d-flex flex-column justify-content-center align-items-center fs-lg-22 fs-16 fw-bold my-4 text-align-center">
                <p className={"mb-0"}>Therapist Consultation with {call.provider_name}</p>
                <div className="d-flex">
                    <p>{localizedCallDate}</p>
                    <span className="mx-1">&bull;</span>
                    <p>{localizedCallTime}</p>
                </div>
            </div>
            <div className={"text-center my-3"}>
                <Button
                    className="Btn Btn-med Btn--pri fw-bold"
                    style={{ textDecoration: "none", padding: "10px 20px" }}
                    onClick={changeAppointment}>
                    Change Appointment
                </Button>
            </div>
        </>
    );
};

export const DIReminderWidget = React.memo(withRouter(diReminderWidget));
