import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import {createClassNameString} from "../../component-library";
import {ICON_SIZES, ICON_TYPES} from "../../constants/icons";

export const BuildingIcon = ({className, iconType, iconSize, ...props}) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path d="M10 8H7V11H10V8Z" />
                <path d="M7 14H10V17H7V14Z" />
                <path d="M10 20H7V23H10V20Z" />
                <path d="M12 8H15V11H12V8Z" />
                <path d="M15 14H12V17H15V14Z" />
                <path d="M12 20H15V23H12V20Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M19 8C19 5.79086 17.2091 4 15 4H7C4.79086 4 3 5.79086 3 8V28H19V8ZM17 8C17 6.89543 16.1046 6 15 6H7C5.89543 6 5 6.89543 5 8V26H17V8Z" />
                <path d="M25.5 13H23.5V15H25.5V13Z" />
                <path d="M23.5 17H25.5V19H23.5V17Z" />
                <path d="M25.5 21H23.5V23H25.5V21Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M29 13C29 10.7909 27.2091 9 25 9H24C21.7909 9 20 10.7909 20 13V28H29V13ZM27 26V13C27 11.8954 26.1046 11 25 11H24C22.8954 11 22 11.8954 22 13V26H27Z" />
            </svg>
        );
    } else if (iconType === ICON_TYPES.FILLED) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path fillRule="evenodd" clipRule="evenodd" d="M19 8V28H3V8C3 5.79086 4.79086 4 7 4H15C17.2091 4 19 5.79086 19 8ZM10 8H7V11H10V8ZM10 14H7V17H10V14ZM7 20H10V23H7V20ZM15 8H12V11H15V8ZM12 14H15V17H12V14ZM15 20H12V23H15V20Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M29 13V28H20V13C20 10.7909 21.7909 9 24 9H25C27.2091 9 29 10.7909 29 13ZM23.5 13H25.5V15H23.5V13ZM23.5 17H25.5V19H23.5V17ZM25.5 21H23.5V23H25.5V21Z" />
            </svg>
        );
    }

    return null;
};

BuildingIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

BuildingIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
