import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { CalendarEventBody, CalendarEventTime, CalendarEventTitle } from "./CalendarEvent";
import moment from "moment";

export const Availability = ({ position, startTime, endTime, details }) => {
    return (
        <CalendarEventBody className={"Availability__body"} position={position} details={details}>
            <CalendarEventTitle title={"Available"} />
            <CalendarEventTime startTime={startTime} endTime={endTime} />
        </CalendarEventBody>
    );
};

Availability.propTypes = {
    position: PropTypes.object,
    startTime: PropTypes.instanceOf(moment),
    endTime: PropTypes.instanceOf(moment),
};
