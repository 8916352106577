export const STATUS_LOOKUP = {
    accepted: {
        class: "insurance-eligibility-status-card--accepted",
        formattedStatus: "Accepted",
        definition: "Patient is eligible for services",
    },
    declined: {
        class: "insurance-eligibility-status-card--declined",
        formattedStatus: "Declined",
        definition: "Patient is not eligible for services",
    },
    no_match: {
        class: "insurance-eligibility-status-card--no-match",
        formattedStatus: "No Match",
        definition: "Patient's information was not found",
    },
    not_configured: {
        class: "insurance-eligibility-status-card--not-configured",
        formattedStatus: "Not Configured",
        definition: "Patient's carrier is not configured with eligibility check",
    },
    pending: {
        class: "insurance-eligibility-status-card--pending",
        formattedStatus: "Pending",
        definition: "Unable to check eligibility at this time",
    },
};
