import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import ProfileDetailsCard from "../../../../Patient/Profile/ProfileDetailsCard";
import { Form, Formik } from "formik";
import { Card, FormField, Input } from "../../../../../component-library";
import * as yup from "yup";
import Hr from "../../../../UI/Hr";
import SubmitControls from "../../../../Patient/Profile/SubmitControls";
import { api } from "../../../../../APIRequests";
import CardHeading from "../../../../UI/Headings/CardHeading";
import Button from "../../../../UI/Button";
import { CustomForm } from "../../../../../component-library/CustomForm";

const actionDescription =
    "This email is used to sign in to the platform and receive communications. Changing it " +
    "will trigger a verification email to the new address. The patient must verify their new email within 24 hours.";

const ChangePatientEmailCard = (props) => {
    const patientId = props.match.params.patientId;
    const [isEditing, setIsEditing] = useState(false);
    const [email, setEmail] = useState(props.patientDetails && props.patientDetails.email);

    const submitChangeEmail = async (formData, actions) => {
        const newEmail = formData.email;
        const changeEmailData = {
            patient_id: patientId,
            new_email: newEmail,
        };
        await api.admin.change_patient_email({ data: changeEmailData });
        setEmail(newEmail);
        setIsEditing(false);
        const newPatientDetails = { ...props.patientDetails, email: newEmail };
        const activityResponse = await api.shared.fetch_patient_activity_feed({
            urlParams: { patientId },
        });
        const newTimelineData = { ...props.timelineData };
        newTimelineData["activities"] = activityResponse.results;
        props.updateState({ patientDetails: newPatientDetails, timelineData: newTimelineData });
    };

    const form = (formikProps) => {
        const { handleChange, handleBlur, handleFocus, values, errors, touched } = formikProps;

        return (
            <Form style={{ width: "100%" }}>
                <FormField
                    className={"apti-ChangePatientEmail"}
                    style={{ maxWidth: 350 }}
                    errorMessage={touched.email ? errors.email : ""}>
                    <Input
                        name="email"
                        placeholder={"Enter Email"}
                        value={values.email}
                        error={touched.email && errors.email}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                </FormField>
                <div className="mb-3 d-lg-none">
                    <Hr />
                </div>
                <SubmitControls cancelHandler={() => setIsEditing(false)} />
            </Form>
        );
    };

    const validation = yup.object().shape({
        email: yup.string().email("Enter a valid email address").required("Email is required"),
    });

    return (
        <div className={"AdminTab__ChangePatientEmailCard"}>
            <Card className={"p-3 p-lg-4 mb-3 mb-lg-4"}>
                <div className="d-flex justify-content-between">
                    <CardHeading className="fs-16 m-0 mb-3" text={"Change Patient Email"} />
                    {!isEditing && (
                        <Button
                            onClick={() => setIsEditing(!isEditing)}
                            className={
                                "ProviderProfile__ProfileDetailsCard-controls cursor mr-2 fs-14 fw-bold mb-3"
                            }>
                            {"Edit"}
                        </Button>
                    )}
                </div>
                <Hr />
                <div className="no-gutters row w-100">
                    <div className="mt-4">
                        <p className={"admin-card-description"}>{actionDescription}</p>
                        <p className={"mb-1 font-weight-bold"}>Current Email</p>
                        {!isEditing && (
                            <div className="fs-14">
                                <span>{email}</span>
                            </div>
                        )}
                        {isEditing && (
                            <CustomForm
                                initialValues={{ email: email }}
                                validationSchema={validation}
                                onSubmit={submitChangeEmail}
                                render={form}
                            />
                        )}
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default withRouter(ChangePatientEmailCard);
