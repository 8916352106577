import "./styles.scss";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, FormField } from "../../../../component-library";
import { transformValueToDropDown } from "../calendarAppointmentPickerUtils";
import {
    updateAppointmentEndTime,
    updateAppointmentStartTime,
    updateAppointmentTime,
} from "../../../../redux/actions/calendar";

// filters drop down options based on the label and not the value
// we do this because the value is represented in 24hr time
const filterByLabel = (option, inputValue) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
};

export const TimePicker = ({ menuPosition }) => {
    const dispatch = useDispatch();
    const appointmentSelections = useSelector((state) => state.calendar.appointmentSelections);
    const errors = useSelector((state) => state.calendar.appointmentSelectionErrors);
    const options = appointmentSelections?.selectableTimes ?? [];
    return (
        <FormField
            className="apti-CalendarAppointmentDateTimePicker--field apti-CalendarAppointmentDateTimePicker--wrapped-fields"
            label="Time"
            errorMessage={errors.time}>
            <Dropdown
                error={errors.time !== null}
                name={"time"}
                value={transformValueToDropDown(options, appointmentSelections.time)}
                filterOptions={filterByLabel}
                options={options}
                placeholder={"Time"}
                onChange={(value) => {
                    dispatch(updateAppointmentTime(value.target.value));
                }}
                menuPosition={menuPosition}
            />
        </FormField>
    );
};

export const FromToTimePicker = ({ menuPosition }) => {
    const dispatch = useDispatch();
    const appointmentSelections = useSelector((state) => state.calendar.appointmentSelections);
    const errors = useSelector((state) => state.calendar.appointmentSelectionErrors);
    const fromOptions = appointmentSelections?.selectableTimesFrom;
    const toOptions = appointmentSelections?.selectableTimesTo;

    return (
        <div className="apti-CalendarAppointmentDateTimePicker--wrapper-container">
            <FormField
                className="apti-CalendarAppointmentDateTimePicker--wrapped-fields"
                label="From"
                errorMessage={errors.startTime}>
                <Dropdown
                    error={errors.startTime !== null}
                    name={"from"}
                    value={transformValueToDropDown(fromOptions, appointmentSelections.startTime)}
                    filterOptions={filterByLabel}
                    options={fromOptions}
                    placeholder={"From"}
                    onChange={(value) => {
                        dispatch(updateAppointmentStartTime(value.target.value));
                    }}
                    menuPosition={menuPosition}
                />
            </FormField>
            <FormField
                className="apti-CalendarAppointmentDateTimePicker--wrapped-fields"
                label="To"
                errorMessage={errors.endTime}>
                <Dropdown
                    error={errors.endTime !== null}
                    name={"to"}
                    value={transformValueToDropDown(toOptions, appointmentSelections.endTime)}
                    filterOptions={filterByLabel}
                    options={toOptions}
                    placeholder={"To"}
                    onChange={(value) => {
                        dispatch(updateAppointmentEndTime(value.target.value));
                    }}
                    menuPosition={menuPosition}
                />
            </FormField>
        </div>
    );
};
