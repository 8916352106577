// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import images from "../../../utils/images";

const ScheduleDateControls = (props) => {
    const { getPrevDay, getNextDay, dateString, getToday, showDatePicker } = props;

    return (
        /* ---------- CONTROLS for schedule availability view --------------- */

        <div
            className="PrScheduleAvailability__controls d-flex align-items-center p-3"
            data-public={"true"}>
            <div className="row no-gutters w-100">
                <div className="order-1 pl-lg-3 fw-bold">
                    <button
                        onClick={() => getToday(true)}
                        className="PrScheduleAvailability__controls-today-btn">
                        Today
                    </button>
                </div>
                <div className="PrScheduleAvailability__controls-calender mt-3 mt-lg-0 d-flex align-items-center order-3 order-lg-2">
                    <button onClick={getPrevDay}>
                        <img src={images("./icons/cal_prev.svg")} alt="previous" />
                    </button>
                    <button onClick={getNextDay}>
                        <img src={images("./icons/cal_next.svg")} alt="next" />
                    </button>
                    <button onClick={showDatePicker} className="ml-2 d-flex align-items-center">
                        <img src={images("./icons/calender-icon.svg")} alt="calender" />
                        {/* <span className="ml-2 fs-14 fw-bold">{dateString}</span> */}
                    </button>
                </div>
                <div className="d-flex align-items-center justify-content-end order-2 order-lg-3">
                    {/* <button className="PrScheduleAvailability__controls-search d-flex align-items-center justify-content-center">
                        <img src={images('./icons/search.svg')} alt="calender" />
                    </button> */}
                </div>
            </div>
        </div>
    );
    /* --------------------------  end  ---------------------------- */
};

export default ScheduleDateControls;
