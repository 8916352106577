import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
import { createClassNameString } from "../utils";
import { Card } from "../Card";
import { TextTypes } from "../constants";
import { Text } from "../Text";
import { Image } from "../Image";

export const MemberProfileActivityEntry = ({
    children,
    headerClassName,
    title,
    subTitle,
    localDateTime,
    iconSrc,
    iconAlt,
    iconComponent,
}) => {
    const renderIcon = () => {
        if (iconComponent) {
            return iconComponent;
        } else if (iconSrc) {
            return <Image
                src={iconSrc}
                alt={iconAlt}
                className="apti-MemberProfileActivityEntry__ContentContainer__Icon"
            />;
        }
        return <></>;
    };

    return <Card className="apti-CL apti-MemberProfileActivityEntry">
        <div
            className={createClassNameString([
                "apti-MemberProfileActivityEntry__Header",
                headerClassName,
            ])}>
            <Text type={TextTypes.subtitle}>{title}</Text>
            <p className="apti-MemberProfileActivityEntry__Header__Date">{localDateTime}</p>
        </div>
        <div className="apti-MemberProfileActivityEntry__ContentContainer">
            {renderIcon()}
            <div>
                <Text type={TextTypes.subtitle}>{subTitle}</Text>
                <div>{children}</div>
            </div>
        </div>
    </Card>;
};

MemberProfileActivityEntry.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
    headerClassName: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    localDateTime: PropTypes.string,
    iconSrc: PropTypes.string,
    iconAlt: PropTypes.string,
    iconComponent: PropTypes.element,
};

MemberProfileActivityEntry.defaultProps = {
    children: <></>,
    headerClassName: undefined,
    title: "",
    subTitle: "",
    localDateTime: "",
    iconSrc: "",
    iconAlt: "",
    iconComponent: undefined,
};
