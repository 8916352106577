// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { createRef, useEffect, useState } from "react";
import { deviceDetect } from "react-device-detect";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import parser from "ua-parser-js";
import { api } from "../../../APIRequests";
import { getRole } from "../../../redux/actions/auth";
import sounds from "../../../utils/sounds";
import Button from "../../UI/Button";
import { isSupportedBrowser } from "../SystemCheck";
import "./styles.scss";

const AVCheck = (props) => {
    const [micTest, setMicTest] = useState(false);
    const [speakersTest, setSpeakersTest] = useState(false);
    const [videoTest, setVideoTest] = useState(false);
    const [localVideoRef, setLocalVideoRef] = useState(null);
    const [rafID, setRafID] = useState(null);
    const [audioVolume, setAudioVolume] = useState(0);
    const numAudioMeterLevels = 5;
    const [audioStream, setAudioStream] = useState(null);
    const [videoStream, setVideoStream] = useState(null);
    const [avNotSupported, setAvNotSupported] = useState(null);

    useEffect(() => {
        try {
            setAvNotSupported(!isSupportedBrowser(deviceDetect(), parser()));

            let videoRef = createRef();
            setLocalVideoRef(videoRef);

            // monkeypatch audio snd user media
            window.AudioContext = window.AudioContext || window.webkitAudioContext;

            navigator.getUserMedia =
                navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
        } catch (e) {
            console.log(e);
        }
    }, []);

    const enableAudio = () => {
        try {
            if (navigator.getUserMedia) {
                navigator.getUserMedia({ audio: true }, gotAudioStream, console.log);
            } else {
                navigator.mediaDevices
                    .getUserMedia({ audio: true })
                    .then(gotAudioStream)
                    .catch(console.log);
            }
            setMicTest(true);
        } catch (e) {
            console.log(e);
        }
    };

    const enableVideo = () => {
        try {
            if (navigator.getUserMedia) {
                navigator.getUserMedia(
                    { video: true },
                    (stream) => {
                        setVideoStream(stream);
                        localVideoRef.current.srcObject = stream;
                    },
                    console.log,
                );
            } else {
                navigator.mediaDevices
                    .getUserMedia({ video: true })
                    .then((stream) => {
                        setVideoStream(stream);
                        localVideoRef.current.srcObject = stream;
                    })
                    .catch(console.log);
            }
            setVideoTest(true);
        } catch (e) {
            console.log(e);
        }
    };

    function gotAudioStream(stream) {
        setAudioStream(stream);
        // grab an audio context
        const audioContext = new AudioContext();

        // Create an AudioNode from the stream.
        let mediaStreamSource = audioContext.createMediaStreamSource(stream);

        // Create a new volume meter and connect it.
        let meter = createAudioMeter(audioContext);
        mediaStreamSource.connect(meter);

        // kick off the visual updating
        onLevelChange(meter);
    }

    // https://github.com/cwilso/volume-meter/blob/master/volume-meter.js
    function createAudioMeter(audioContext, clipLevel, averaging, clipLag) {
        let processor = audioContext.createScriptProcessor(512);
        processor.onaudioprocess = volumeAudioProcess;
        processor.clipping = false;
        processor.lastClip = 0;
        processor.volume = 0;
        processor.clipLevel = clipLevel || 0.98;
        processor.averaging = averaging || 0.95;
        processor.clipLag = clipLag || 750;

        // this will have no effect, since we don't copy the input to the output,
        // but works around a current Chrome bug.
        processor.connect(audioContext.destination);

        processor.checkClipping = function () {
            if (!this.clipping) {
                return false;
            }
            if (this.lastClip + this.clipLag < window.performance.now()) {
                this.clipping = false;
            }
            return this.clipping;
        };

        processor.shutdown = function () {
            this.disconnect();
            this.onaudioprocess = null;
        };

        return processor;
    }

    function volumeAudioProcess(event) {
        let buf = event.inputBuffer.getChannelData(0);
        let bufLength = buf.length;
        let sum = 0;
        let x;

        // Do a root-mean-square on the samples: sum up the squares...
        for (let i = 0; i < bufLength; i++) {
            x = buf[i];
            if (Math.abs(x) >= this.clipLevel) {
                this.clipping = true;
                this.lastClip = window.performance.now();
            }
            sum += x * x;
        }

        // ... then take the square root of the sum.
        let rms = Math.sqrt(sum / bufLength);

        // Now smooth this out with the averaging factor applied
        // to the previous sample - take the max here because we
        // want "fast attack, slow release."
        this.volume = Math.max(rms, this.volume * this.averaging);
    }

    function onLevelChange(meter) {
        let audioVolume = Math.floor(meter.volume * 100 * 3);
        setAudioVolume(audioVolume);
        setRafID(window.requestAnimationFrame(() => onLevelChange(meter)));
    }

    const submitAVCheck = async () => {
        try {
            if (audioStream) {
                audioStream.getTracks().forEach(function (track) {
                    track.stop();
                });
            }
            if (videoStream) {
                videoStream.getTracks().forEach(function (track) {
                    track.stop();
                });
            }
            const preferences = props.profile.hasOwnProperty("preferences")
                ? props.profile.preferences
                : {};
            preferences["av"] = {
                mic: {
                    tested: micTest,
                },
                speakers: {
                    tested: speakersTest,
                },
                video: {
                    tested: videoTest,
                },
            };
            const data = { preferences, username: props.profile.username };
            const currentUserRole = getRole();
            if (currentUserRole.startsWith("provider")) {
                await api.provider.update_profile({ data });
            } else if (currentUserRole.startsWith("user")) {
                await api.patient.update_profile({ data });
            }
            props.history.push("/app");
        } catch (e) {
            console.log(e);
            props.history.push("/app");
        }
    };

    const playTone = () => {
        new Audio(sounds("./Bison-SoundBible.com-1105726036.mp3"))
            .play()
            .then((success) => setSpeakersTest(true))
            .catch(console.log);
    };

    return (
        <>
            <div className="viewTutorial" data-public="true">
                <div className="container">
                    <div className="viewTutorial-card px-3 py-5 px-lg-5 mb-5">
                        <div className={"text-center"}>
                            <h5 className="fw-bold fs-17 pb-4 m-0">Test Audio & Video</h5>
                            {avNotSupported && (
                                <div className={"txt-error fw-bold"}>
                                    You are using an unsupported OS and/or Browser.
                                </div>
                            )}
                            <div className={"my-3 mx-1"}>
                                <div className={"text-left"}>
                                    <span className={"fw-bold"}>Audio</span>
                                    <hr style={{ marginTop: 0 }} />
                                </div>
                                <div>
                                    <div className={"fw-bold"}>Microphone</div>
                                    {!micTest && (
                                        <div>
                                            <Button
                                                className="Btn Btn--pri fs-16 text-center text-white test-btn"
                                                disabled={avNotSupported ? "disabled" : null}
                                                onClick={enableAudio}>
                                                Test
                                            </Button>
                                        </div>
                                    )}
                                    {micTest && (
                                        <div className={"d-flex justify-content-between"}>
                                            {Array(numAudioMeterLevels)
                                                .fill(0)
                                                .map((value, i) => {
                                                    const width =
                                                        audioVolume >
                                                        ((i + 1) * 100) / numAudioMeterLevels
                                                            ? 100
                                                            : 0;
                                                    return (
                                                        <div className={"volume-background mx-1"}>
                                                            <div
                                                                className={"volume-fill"}
                                                                style={{ width: `${width}%` }}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    )}
                                </div>
                                <div className={"mb-5 mt-3"}>
                                    <div className={"fw-bold"}>Speakers</div>
                                    <Button
                                        className="Btn Btn--pri fs-16 text-center text-white test-btn"
                                        onClick={playTone}>
                                        Play Tone
                                    </Button>
                                </div>
                                <div className={"text-left"}>
                                    <span className={"fw-bold"}>Video</span>
                                    <hr style={{ marginTop: 0 }} />
                                </div>
                                <div>
                                    <div className={"fw-bold"}>Camera</div>
                                    <div className="video-test">
                                        {!videoTest && (
                                            <div className={"video-test-placeholder"}>
                                                <Button
                                                    className="Btn Btn--pri fs-16 text-center text-white
                                            video-test-placeholder-btn-wpr test-btn"
                                                    disabled={avNotSupported ? "disabled" : null}
                                                    onClick={enableVideo}>
                                                    Test
                                                </Button>
                                            </div>
                                        )}
                                        {videoTest && <video ref={localVideoRef} autoPlay muted />}
                                    </div>
                                </div>
                            </div>
                            <div className={"txt-gry fs-12 my-3"}>
                                If you have any issues please contact support at
                                support@aptihealth.com.
                            </div>
                            <Button
                                className="Btn Btn--pri fs-16 text-center text-white"
                                onClick={submitAVCheck}>
                                Finish
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};
export default connect(mapStateToProps)(withRouter(AVCheck));
