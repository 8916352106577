// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as actionTypes from "../actions/actionTypes";

const initialState = {
    alertConf: null,
};

const setAlert = (state, action) => {
    return {
        alertConf: action.alertConf,
    };
};

const unsetAlert = (state, action) => {
    return {
        alertConf: null,
    };
};

const logout = (state, action) => {
    return {
        ...initialState,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ALERT:
            return setAlert(state, action);
        case actionTypes.UNSET_ALERT:
            return unsetAlert(state, action);
        case actionTypes.LOG_OUT:
            return logout(state, action);
        default:
            return state;
    }
};

export default reducer;
