// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component, Fragment } from "react";
import Dashboard from "../../containers/Dashboard";

import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import PropTypes from "prop-types";

class Layout extends Component {
    render() {
        return <Fragment>{this.props.children}</Fragment>;
    }
}

Layout.propTypes = {};

export default Layout;
