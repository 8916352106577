import React, { useState } from "react";
import _ from "lodash";

import { AddInsurance } from "../../AddInsurance";
import { insuranceListToSelectFormat } from "../../../utils/GenericListUtils";
import { CATCH_ALL_INSURANCE_OPTIONS } from "../../../constants/insurnaceIconMap";
import { getCarrierMetadata } from "../../../components/Common/InsuranceEligibility/carrierMetadata";

export const ProviderReferralHOC = ({
    isPrimaryInsurance,
    formikProps,
    firstName,
    lastName,
    files,
    insuranceCompanyOptions,
    onBrowseButtonClicked,
    ...props
}) => {
    const { handleChange, handleBlur, handleFocus, values, errors, touched } = formikProps;
    const { memberIdPlaceholder, memberIdTip, memberIdTooltipContents, groupIdTooltipContents } =
        getCarrierMetadata(values.insurance[isPrimaryInsurance ? 0 : 1].form.carrier);
    const [noInsurance, setNoInsurance] = useState(false);

    const mapDropDownValue = (value) => ({
        label: value,
        value: value,
    });

    const getPolicyHolderValue = () => {
        if (values.insurance[isPrimaryInsurance ? 0 : 1].policy_holder_question) {
            return values.insurance[isPrimaryInsurance ? 0 : 1].policy_holder_question;
        }

        return "yes";
    };

    const mappedValues = {
        policyHolderName: `${firstName} ${lastName}`,
        insuranceCompany: mapDropDownValue(
            values.insurance[isPrimaryInsurance ? 0 : 1].form.carrier,
        ),
        policyHolder: getPolicyHolderValue(),
        memberId: values.insurance[isPrimaryInsurance ? 0 : 1].form.member_id,
        groupId: values.insurance[isPrimaryInsurance ? 0 : 1].form.group_id,
    };

    const mappedNames = {
        insuranceCompany: `insurance[${isPrimaryInsurance ? "0" : "1"}].form.carrier`,
        policyRelationship: `insurance[${isPrimaryInsurance ? "0" : "1"}].form.policy_relationship`,
        policyHolder: `insurance[${isPrimaryInsurance ? "0" : "1"}].form.policy_holder_question`,
        policyHolderName: `insurance[${isPrimaryInsurance ? "0" : "1"}].form.policy_holder_name`,
        memberId: `insurance[${isPrimaryInsurance ? "0" : "1"}].form.member_id`,
        groupId: `insurance[${isPrimaryInsurance ? "0" : "1"}].form.group_id`,
        insuranceFront: `insurance[${isPrimaryInsurance ? "0" : "1"}].form.card_front`,
        insuranceBack: `insurance[${isPrimaryInsurance ? "0" : "1"}].form.card_back`,
    };

    const mappedTouched = {
        insuranceCompany: _.get(
            touched,
            `insurance[${isPrimaryInsurance ? "0" : "1"}].form.carrier`,
        ),
        policyHolder: _.get(
            touched,
            `insurance[${isPrimaryInsurance ? "0" : "1"}].form.policy_holder_question`,
        ),
        memberId: _.get(touched, `insurance[${isPrimaryInsurance ? "0" : "1"}].form.member_id`),
        groupId: _.get(touched, `insurance[${isPrimaryInsurance ? "0" : "1"}].form.group_id`),
    };

    const mappedErrors = {
        insuranceCompany: _.get(
            errors,
            `insurance[${isPrimaryInsurance ? "0" : "1"}].form.carrier`,
        ),
        policyHolder: _.get(
            errors,
            `insurance[${isPrimaryInsurance ? "0" : "1"}].form.policy_holder_question`,
        ),
        memberId: _.get(errors, `insurance[${isPrimaryInsurance ? "0" : "1"}].form.member_id`),
        groupId: _.get(errors, `insurance[${isPrimaryInsurance ? "0" : "1"}].form.group_id`),
    };

    const generatePolicyHolderName = (e, value) => {
        let policyHolderName = "Other";
        if (value === "yes") {
            policyHolderName = `${firstName} ${lastName}`;
        }
        handleChange({
            ...e,
            target: { ...e.target, name: mappedNames["policyHolderName"], value: policyHolderName },
        });
    };

    const generatePolicyHolderRelationship = (e, value) => {
        let policyHolderRelationship = "Other";
        if (value === "yes") {
            policyHolderRelationship = "self";
        }
        handleChange({
            ...e,
            target: {
                ...e.target,
                name: mappedNames["policyRelationship"],
                value: policyHolderRelationship,
            },
        });
    };

    const mappedChangeHandler = (e) => {
        const { name, value } = e.target;
        const mappedName = mappedNames[name];
        let mappedValue = value;

        switch (name) {
            case "policyHolder":
                generatePolicyHolderName(e, value);
                generatePolicyHolderRelationship(e, value);
                break;
            case "insuranceCompany":
                if (CATCH_ALL_INSURANCE_OPTIONS.includes(value)) {
                    resetInsurance();
                    setNoInsurance(true);
                } else {
                    setNoInsurance(false);
                }
                break;
        }

        handleChange({ ...e, target: { ...e.target, name: mappedName, value: mappedValue } });
    };

    const resetInsurance = () => {
        for (const name in mappedNames) {
            if (name === "insuranceCompany") {
                continue;
            }
            formikProps.setFieldValue(mappedNames[name], "");
        }
    };

    const onFileDeleteHandler = (fileName) => {
        let insuranceSide = "insuranceBack";
        if (fileName.startsWith("primary_front")) {
            insuranceSide = "insuranceFront";
        }

        handleChange({ target: { name: mappedNames[insuranceSide], value: null } });
    };

    const getInsuranceFileNames = () => {
        const fileNameArray = [];
        const frontImage = _.get(values, mappedNames["insuranceFront"]);
        const backImage = _.get(values, mappedNames["insuranceBack"]);
        if (frontImage) {
            fileNameArray.push(frontImage);
        }
        if (backImage) {
            fileNameArray.push(backImage);
        }
        return fileNameArray;
    };

    return (
        <AddInsurance
            policyHolderLabel="Is the Patient the Policy Holder?"
            values={mappedValues}
            touched={mappedTouched}
            errors={mappedErrors}
            handleChange={mappedChangeHandler}
            handleBlur={handleBlur}
            handleFocus={handleFocus}
            onFileDelete={onFileDeleteHandler}
            onBrowseButtonClicked={onBrowseButtonClicked}
            files={getInsuranceFileNames()}
            insuranceCompanyOptions={insuranceListToSelectFormat(insuranceCompanyOptions)}
            isPrimaryInsurance={isPrimaryInsurance}
            noInsurance={noInsurance}
            memberIdPlaceholder={memberIdPlaceholder}
            memberIdTip={memberIdTip}
            memberIdTooltipContents={memberIdTooltipContents}
            groupIdTooltipContents={groupIdTooltipContents}
            {...props}
        />
    );
};
