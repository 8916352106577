// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
// import images from '../../../utils/images';
// import * as animationData from './spinner.json';
import Lottie from "react-lottie";

import "./styles.scss";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("./spinner.json"),
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

class Spinner extends Component {
    render() {
        return (
            <div className="Spinner position-fixed text-dark d-flex align-items-center justify-content-center">
                {/* <img width="60" src={images('./common/loader.svg')} alt="loader svg" /> */}
                <Lottie options={defaultOptions} height={400} width={400} />
            </div>
        );
    }
}
export default Spinner;
