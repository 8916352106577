// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
const images = require.context("../../../assets/images/", true);

const Logo = (props) => {
    if (props.style === "white") {
        return (
            <img className="img-fluid" alt="aptihealth logo" src={images("./common/logo.svg")} />
        );
    }
    return (
        <img
            style={{ maxWidth: "200px", maxHeight: "31px" }}
            className="img-fluid"
            alt="aptihealth logo"
            src={images("./common/apti_logo_white.png")}
        />
    );
};

export default Logo;
