// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import "./styles.scss";
import _ from "lodash";

class Accordion extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="py-3">
                <h2 className="mb-0">
                    <button
                        className="AccordionBtn fs-14 text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target={this.props.dataTarget}
                        aria-expanded="false"
                        aria-controls={this.props.ariaControls}>
                        {this.props.text}
                    </button>
                </h2>

                <div
                    id={this.props.contentId}
                    className="collapse"
                    aria-labelledby={this.props.ariaLabelledby}
                    data-parent="#AptiAccordion">
                    <div className="px-3 mt-4">
                        {this.props.info &&
                            this.props.info.map((items) => {
                                if (
                                    items.hasOwnProperty("type") &&
                                    items["type"] === "img" &&
                                    items["value"]
                                ) {
                                    if (!_.get(items, "value[0]") && !_.get(items, "value[1]")) {
                                        return <></>;
                                    }
                                    return (
                                        <div key={items.property} className="mt-4" data-private={"true"}>
                                            <p className="fw-bold mb-2 fs-14">{items.property}</p>
                                            {items["value"].map((item, index) => {
                                                return (
                                                    <img
                                                        key={item + `${index}`}
                                                        className="PatientProfile__insurance-image-preview cursor"
                                                        src={item}
                                                        alt=""
                                                        onClick={
                                                            items.hasOwnProperty("onclick")
                                                                ? () => items["onclick"](item)
                                                                : null
                                                        }
                                                    />
                                                );
                                            })}
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={items.property} className="mt-4">
                                            <p className="fw-bold mb-2 fs-14">{items.property}</p>
                                            <p className="mb-0 fs-14">{items.value}</p>
                                        </div>
                                    );
                                }
                            })}
                    </div>
                </div>
            </div>
        );
    }
}

export default Accordion;
