// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import PropTypes from "prop-types";

const SidebarSublistItem = (props) => {
    const { title } = props;

    return (
        <div className="SidebarSublistItem fs-14">
            <span className="SidebarSublistItem__link-title">{title}</span>
        </div>
    );
};

export default SidebarSublistItem;
