import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { createClassNameString } from "../utils";
import { Text } from "../Text/Text";
import { ErrorCard } from "../ErrorCard";
import { TextTypes, TextColors } from "../constants";

export const PatientChartPaymentBanner = ({ className, patientName }) => {
    return (
        <ErrorCard
            className={createClassNameString(["PatientChartPaymentBanner-banner", className])}>
            <Text data-public type={TextTypes.label} color={TextColors.red}>
                No valid card on file: Hold on scheduling appointments for <span data-private>{patientName}</span> until they add or update their payment method.
            </Text>
        </ErrorCard>
    );
};

PatientChartPaymentBanner.propTypes = {
    updatePaymentRequired: PropTypes.bool,
    className: PropTypes.string,
};
