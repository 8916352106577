import React from "react";

const Message = (props) => {
    const renderLinks = (message) => {
        if (message.match(/(https:|http:|www\.)\S*/gi)) {
            const messageSplit = message.split(/(\s+)/);
            messageSplit.forEach((word, index) => {
                if (word.match(/^(https:|http:)\S*/gi)) {
                    messageSplit[index] = (
                        <a target="_blank" href={word} rel="noreferrer">
                            {word}
                        </a>
                    );
                } else if (word.match(/^(www\.)\S*/gi)) {
                    messageSplit[index] = (
                        <a target="_blank" href={"//" + word} rel="noreferrer">
                            {word}
                        </a>
                    );
                } else if (word === "") {
                    messageSplit[index] = <span>&nbsp;</span>;
                } else {
                    messageSplit[index] = <span>{word}</span>;
                }
            });
            return messageSplit;
        }

        return message;
    };

    const readMessage = (message) => {
        const lines = message.split(/([\n])/);
        const renderedLines = lines.map((line, index) => {
            return renderLinks(line);
        });
        return renderedLines;
    };

    return readMessage(props.message);
};

export default React.memo(Message);
