// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import Button from "../UI/Button";
import images from "../../utils/images";
import "./styles.scss";
import WistiaEmbed from "../Screening/a5Complete/WistiaEmbed";
import Testimonials from "./Testimonials";
import { ConsentToTermsOfUse } from "../Common/ConsentToTermsOfUse";
import { Footer } from "../../component-library/Footer";

export function getConsentSubHeader() {
    return (
        <div className={"sub-header-container"}>
            <div className={"sub-header justify-content-center"}>
                <div>
                    <p className={"header-text"}>
                        aptihealth Provides An Expert Care Team Dedicated To Your Success
                    </p>
                    <p className={"grey-text"}>
                        To get started, please fill out the consent form and create your account.
                    </p>
                </div>
                <img className="img-fluid shield" src={images("./icons/icn-shield.png")} />
            </div>
        </div>
    );
}

const ConsentScreen = (props) => {
    const { consent, setConsent, campaignId, practiceId } = props;

    const currentYear = new Date().getFullYear();

    return (
        <>
            <div>
                <div>
                    <img className="img-fluid" src={images("./consent-header.png")} />
                </div>
                {getConsentSubHeader()}
                <div className={"top-body body-padding"}>
                    <p className={"green-text fs-17 fw-bold mt-5"}>Get Started</p>

                    <div className={"pl-4 pr-4"}>
                        <p className={"mt-4"}>Welcome to aptihealth!</p>
                        <p className={"fw-bold mt-4"}>
                            Before we have you create your account, we'll require your consent.{" "}
                        </p>

                        <div className={"fs-15 txt-gry"}>
                            <ConsentToTermsOfUse
                                consent={consent}
                                setConsent={setConsent}
                                campaignId={campaignId}
                                practiceId={practiceId}
                            />
                        </div>

                        <div className={"d-flex justify-content-left"} style={{ width: "100%" }}>
                            <Button
                                className="Btn Btn--pri mt-4"
                                disabled={
                                    consent &&
                                    consent["eula"] &&
                                    consent["privacy"] &&
                                    consent["hipaa"]
                                        ? null
                                        : "disabled"
                                }
                                onClick={props.continue}
                                data-e2e={"primary-account-info-continue-button"}>
                                {" "}
                                Continue
                            </Button>
                        </div>
                        <p className={"fw-bold mt-4"}>
                            For more information, visit aptihealth.com/welcome or call us at (888)
                            454-3827.
                        </p>
                    </div>
                    <div className={"list d-flex flex-column"}>
                        <p className={"green-text fs-17 fw-bold mt-5"}>We Support You With:</p>
                        <ul className={"support-list pl-4 d-flex flex-wrap"}>
                            <li>Expert care from a therapist matched to your specific needs</li>
                            <li>Convenient, personalized therapy focused on your goals</li>
                            <li>Easy-to-schedule online therapy appointments</li>
                            <li>A commitment to feeling better within 90-days and beyond</li>
                            <li>Treatment coordinated with your doctor</li>
                            <li>A dedicated care team to help you every step of the way</li>
                            <li>
                                Direct messaging with your therapist for support between sessions
                            </li>
                        </ul>
                        <img
                            className="img-fluid pl-4 laptop-img align-self-center"
                            src={images("./Laptop-Tablet-Phone-Suite.png")}
                        />
                    </div>
                </div>
                <div>
                    <div className={"green-bar"} />
                    <div className={"sub-header-container story"}>
                        <div className={"sub-header"}>
                            <div>
                                <p className={"header-text"}>aptihealth Member Success Stories</p>
                                <p className={"grey-text"}>
                                    Our team of specialists care about our members' mental health
                                    and well-being and empower them to make positive changes. Watch
                                    Hannah's inspiring journey to learn more about the aptihealth
                                    experience.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={"wistia-container pt-3 pb-3"}>
                        <WistiaEmbed hashedId="35ae79eexf" />
                    </div>
                </div>
            </div>
            <div className={"green-header"}>
                <Testimonials />
            </div>
            <div className={"d-flex"} style={{ width: "100%", height: "100px" }}>
                <a className="mt-4 m-auto" href="#">
                    <Button className="Btn Btn--blue" onClick={() => {}}>
                        Get Started Today
                    </Button>
                </a>
            </div>
            <div className={"grey-testimonies"}>
                <div className={"header"}>
                    <div>
                        <p className={"header-text"}>Dedicated To Your Success</p>
                        <p className={"grey-text"}>
                            Our team of therapists and prescribers are dedicated to your success and
                            focus on empowering you to achieve your goals for better mental health.
                        </p>
                    </div>
                </div>
                <div className={"testimonies"}>
                    <div>
                        <img className="img-fluid" src={images("./Mia-Headshot.png")} />
                        <p className={"green-text fs-17 fw-bold mt-5"}>
                            Mia, Behavioral Health Specialist
                        </p>
                        <p className={"mt-4 italics"}>
                            "At aptihealth we see a broad range of patients, from mild to more
                            severe patients who are facing urgent challenges and more deep-rooted
                            trauma. We are preparing our members to work through tough life
                            challenges. We are working on action planning, goal planning, and all of
                            the things they can do to become more successful. They feel supported by
                            the care team we have in place, they show up, they’re engaged in care,
                            and are focused on getting better. Through aptihealth they are
                            transforming their lives."
                        </p>
                    </div>
                    <div>
                        <img className="img-fluid" src={images("./Kristen-Headshot.png")} />
                        <p className={"green-text fs-17 fw-bold mt-5"}>
                            Kristen, Behavioral Health Specialist
                        </p>
                        <p className={"mt-4 italics"}>
                            "aptihealth is a completely different model of care—a virtual care model
                            that’s more supportive, goal-oriented, and efficient. It has
                            significantly enhanced my relationship with patients and the time I
                            spend focused on their care. The support and encouragement that I can
                            provide between therapy sessions through the platform is outstanding. It
                            keeps my patients motivated, focused on their goals, and ultimately,
                            they know that I care about them and their success."
                        </p>
                    </div>
                </div>
            </div>
            <div className={"green-bar-large d-flex"}>
                <a className="mt-4 m-auto" href="#">
                    <Button className="Btn Btn--blue" onClick={() => {}}>
                        Get Started Today
                    </Button>
                </a>
            </div>
            <div className={"easy-to-user"}>
                <div style={{ width: "fit-content", margin: "auto" }}>
                    <div className={"header"}>
                        <div>
                            <p className={"header-text"}>It's Easy to Use aptihealth</p>
                            <p className={"grey-text"}>
                                All you need is a smartphone or a computer to access our secure
                                application via the aptihealth app (iOS) or your browser (Chrome,
                                Firefox, or Safari).
                            </p>
                        </div>
                    </div>
                    <div className={"d-flex icons-container"}>
                        <div className={"d-flex flex-column"}>
                            <div className={"d-flex mb-4"}>
                                <img
                                    className="img-fluid icons mr-5"
                                    src={images("./notification-graphic.png")}
                                />
                                <p className={"header-text align-self-center"}>
                                    Your Personalized Assessment
                                </p>
                            </div>
                            <div className={"d-flex mb-4"}>
                                <img
                                    className="img-fluid icons calendar"
                                    src={images("./calendar.png")}
                                />
                                <p className={"header-text align-self-center"}>
                                    Scheduling Appointments
                                </p>
                            </div>
                        </div>
                        <div className={"d-flex flex-column"}>
                            <div className={"d-flex mb-4"}>
                                <img className="img-fluid icons mr-5" src={images("./phone.png")} />
                                <p className={"header-text align-self-center"}>
                                    Online Video Therapy Sessions
                                </p>
                            </div>
                            <div className={"d-flex mb-4"}>
                                <img
                                    className="img-fluid icons conversation"
                                    src={images("./conversation.png")}
                                />
                                <p className={"header-text align-self-center"}>
                                    Direct Messaging Your Therapist
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ConsentScreen;
