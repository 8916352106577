import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ProfileDetailsCard from "../../../../Patient/Profile/ProfileDetailsCard";
import { Form, Formik } from "formik";
import {
    FormField,
    Dropdown,
    CheckboxOld,
    Text,
    TextTypes,
    Card,
} from "../../../../../component-library";
import * as yup from "yup";
import Hr from "../../../../UI/Hr";
import SubmitControls from "../../../../Patient/Profile/SubmitControls";
import { api } from "../../../../../APIRequests";
import CardHeading from "../../../../UI/Headings/CardHeading";
import Button from "../../../../UI/Button";
import { CustomForm } from "../../../../../component-library/CustomForm";

const actionDescription =
    "Change which practice and provider have access to a patient's records. Note making this " +
    "change requires obtaining and uploading a patient's written consent in advance.";

const ChangePatientPracticeCard = (props) => {
    const patientId = props.match.params.patientId;
    const [isEditing, setIsEditing] = useState(false);
    const [pcpId, setPcpId] = useState(props.patientDetails && props.patientDetails.pcp_id);
    const [pcpName, setPcpName] = useState(props.patientDetails && props.patientDetails.pcp_name);
    const [pcpPracticeId, setPCPPracticeId] = useState(
        props.patientDetails && props.patientDetails.pcp_practice_id,
    );

    const [practices, setPractices] = useState([]);
    const [currentPractice, setCurrentPractice] = useState(null);
    const [providersOfPractice, setProvidersOfPractice] = useState([]);

    const submitChangePractice = async (formData, actions) => {
        if (!formData.consent) {
            return;
        }

        const newPcp = formData.pcp;
        const newPcpPracticeId = formData.pcp_practice_id;
        const newPcpName = providersOfPractice.find((provider) => provider.value === newPcp).label;
        const changePracticeData = {
            patient_id: patientId,
            new_pcp_id: newPcp,
            new_pcp_practice_id: newPcpPracticeId,
            new_pcp_name: newPcpName,
        };

        await api.admin.change_patient_practice({ data: changePracticeData });

        setCurrentPractice(practices.find((practice) => practice.value === newPcpPracticeId).label);
        setPcpName(newPcpName);
        setPcpId(newPcp);
        setIsEditing(false);
        const newPatientDetails = { ...props.patientDetails, pcp_name: newPcp };
        const activityResponse = await api.shared.fetch_patient_activity_feed({
            urlParams: { patientId },
        });
        const newTimelineData = { ...props.timelineData };
        newTimelineData["activities"] = activityResponse.results;
        props.updateState({ patientDetails: newPatientDetails, timelineData: newTimelineData });
    };

    const fetchAllPractices = async () => {
        const options = { params: { showLoader: false } };
        const allPractices = await api.admin.fetch_all_practices({ options });
        setCurrentPractice(allPractices.find((practice) => practice.id === pcpPracticeId).name);

        const allPrimaryCarePractices = allPractices.filter(
            (practice) => practice.practice_type === "Primary Care",
        );
        const allPrimaryCarePracticesOptions = allPrimaryCarePractices.map((practice) => ({
            value: practice.id,
            label: practice.name,
        }));

        setPractices(allPrimaryCarePracticesOptions);
    };

    const fetchAllProvidersInPractice = async (pcpPracticeId) => {
        const options = { params: { showLoader: false } };
        const data = {
            pcp_practice_id: pcpPracticeId,
        };
        const allProvidersInPractice = await api.admin.fetch_providers_by_pcp_practice_id({
            options,
            data,
        });
        const allProvidersInPracticeOptions = allProvidersInPractice.map((provider) => ({
            value: provider.username,
            label: provider.name,
        }));

        setProvidersOfPractice(allProvidersInPracticeOptions);
    };

    const currentPracticeInfo = () => {
        return (
            <div>
                <div
                    style={{
                        maxWidth: "350px",
                        display: "inline-flex",
                        flexDirection: "column",
                        width: "100%",
                        marginTop: "1rem",
                    }}>
                    <span className={"mb-1 font-weight-bold"}>Current Practice</span>
                    <span>{currentPractice}</span>
                </div>
                <div
                    style={{
                        maxWidth: "350px",
                        display: "inline-flex",
                        flexDirection: "column",
                        width: "100%",
                        marginTop: "1rem",
                    }}>
                    <span className={"mb-1 font-weight-bold"}>Current PCP</span>
                    <span>{pcpName}</span>
                </div>
            </div>
        );
    };

    const form = (formikProps) => {
        const { handleChange, handleBlur, handleFocus, values, errors, touched, setFieldValue } =
            formikProps;

        return (
            <Form style={{ width: "100%" }}>
                <div className={"mt-3"} style={{ display: "flex", alignItems: "center" }}>
                    <CheckboxOld
                        style={{ marginRight: 10 }}
                        name="consent"
                        checked={values.consent}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        disabled={!values.pcp_practice_id || !values.pcp}
                    />
                    <Text className="fs-14" type={TextTypes.paragraph}>
                        I obtained a written consent to share the patient’s information with the new
                        practice.
                    </Text>
                </div>
                {currentPracticeInfo()}
                <FormField
                    className={"mt-3"}
                    label={"New Practice"}
                    style={{ maxWidth: 350 }}
                    errorMessage={touched.pcp_practice_id ? errors.pcp_practice_id : ""}>
                    <Dropdown
                        name="pcp_practice_id"
                        placeholder={currentPractice}
                        value={practices.find(
                            (practice) => practice.value === values.pcp_practice_id,
                        )}
                        error={touched.pcp_practice_id && errors.pcp_practice_id}
                        isDisabled={!practices.length}
                        onChange={(e) => {
                            setProvidersOfPractice([]);
                            setFieldValue("pcp", "");
                            setPCPPracticeId(e.target.value);
                            fetchAllProvidersInPractice(e.target.value);
                            handleChange(e);
                        }}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        options={practices}
                    />
                </FormField>
                <FormField
                    label={"New PCP"}
                    style={{ maxWidth: 350 }}
                    errorMessage={touched.pcp ? errors.pcp : ""}>
                    <Dropdown
                        name="pcp"
                        placeholder={pcpName}
                        value={providersOfPractice.find(
                            (provider) => provider.value === values.pcp,
                        )}
                        error={touched.pcp && errors.pcp}
                        isDisabled={!values.pcp_practice_id || !providersOfPractice.length}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        options={providersOfPractice}
                    />
                </FormField>

                <div className="mb-3 d-lg-none">
                    <Hr />
                </div>
                <SubmitControls cancelHandler={() => setIsEditing(false)} />
            </Form>
        );
    };

    const validation = yup.object().shape({
        pcp_practice_id: yup.string().required("Practice is required"),
        pcp: yup.string().required("Provider is required"),
    });

    useEffect(() => {
        fetchAllPractices();

        if (pcpPracticeId) {
            fetchAllProvidersInPractice(pcpPracticeId);
        }
    }, []);

    return (
        <div className={"AdminTab__ChangePatientPracticeCard"}>
            <Card className={"p-3 p-lg-4 mb-3 mb-lg-4"}>
                <div className="d-flex justify-content-between">
                    <CardHeading className="fs-16 m-0 mb-3" text={"Change Patient Practice"} />
                    {!isEditing && (
                        <Button
                            onClick={() => setIsEditing(!isEditing)}
                            className={
                                "ProviderProfile__ProfileDetailsCard-controls cursor mr-2 fs-14 fw-bold mb-3"
                            }>
                            {"Edit"}
                        </Button>
                    )}
                </div>
                <Hr />
                <div className="no-gutters row w-100">
                    <div className="mt-4">
                        <p className={"admin-card-description mb-0"}>{actionDescription}</p>
                        {!isEditing && currentPracticeInfo()}
                        {isEditing && (
                            <CustomForm
                                initialValues={{
                                    pcp_practice_id: pcpPracticeId,
                                    pcp: pcpId,
                                    consent: false,
                                }}
                                validationSchema={validation}
                                onSubmit={submitChangePractice}
                                render={form}
                            />
                        )}
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default withRouter(ChangePatientPracticeCard);
