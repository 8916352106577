// Copyright aptihealth, inc. 2021 All Rights Reserved
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import SetPassword from "./SetPassword";
import MemberAgreement from "./MemberAgreement";
import { getQueryParams } from "../../../../utils/filters";
import { api } from "../../../../APIRequests";
import moment from "moment";
import { tagReferralPath } from "../../../../utils/logRocketUtils";

const AccountSetup = (props) => {
    const [password, setPassword] = useState(null);
    const [sectionToShow, setSectionToShow] = useState("set_password");

    useEffect(() => {
        tagReferralPath("patient_auto_signup");
    }, []);

    const baseProps = {
        password: password,
        setPassword: setPassword,
        setSectionToShow: setSectionToShow,
        id: getQueryParams("id", props.location.id),
    };

    const accountLookUpSections = {
        set_password: <SetPassword {...baseProps} />,
        member_agreement: <MemberAgreement {...baseProps} />,
    };

    return (
        <>
            <div className={"p-lg-5 p-3"}>{accountLookUpSections[sectionToShow]}</div>
        </>
    );
};

export default withRouter(AccountSetup);
