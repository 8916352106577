import * as actionTypes from "../actions/actionTypes";

let initialState = {
    currentQuestion: null,
    selectedOptions: [],
    lastSelectedOption: null,
    completionPercentage: 0,
    isLastQuestion: false,
    a5Details: null,
};

const nextQuestion = (state, action) => {
    return {
        ...state,
        completionPercentage: action.payload.completionPercentage,
        currentQuestion: action.payload.nextQuestion,
        isLastQuestion: action.payload.isLast,
        selectedOptions: [],
        lastSelectedOption: null,
    };
};

const previousQuestion = (state, action) => {
    return {
        ...state,
        currentQuestion: action.payload.currentQuestion,
        completionPercentage: action.payload.completionPercentage,
        isLastQuestion: action.payload.isLast,
        lastSelectedOption: null,
        selectedOptions: [],
    };
};

const optionSelected = (state, action) => {
    return {
        ...state,
        selectedOptions: action.payload.selectedOptions,
        lastSelectedOption: action.payload.selectedOptionFull,
    };
};

const updateDetails = (state, action) => {
    return {
        ...state,
        a5Details: action.payload,
    };
};

const logout = (state, action) => {
    return {
        ...initialState,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.A5_NEXT_QUESTION:
            return nextQuestion(state, action);
        case actionTypes.A5_PREVIOUS_QUESTION:
            return previousQuestion(state, action);
        case actionTypes.A5_OPTION_SELECTED:
            return optionSelected(state, action);
        case actionTypes.A5_UPDATE:
            return updateDetails(state, action);
        case actionTypes.LOG_OUT:
            return logout(state, action);
        default:
            return state;
    }
};

export default reducer;
