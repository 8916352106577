import React from "react";
import {
    A5R_ASSESSMENT_TYPE,
    A5_SUB_ASSESSMENT_TYPE,
    A5_V2_ASSESSMENT_TYPE,
    DEFAULT_ASSESSMENT_TYPE,
} from "../../../../../constants/assessments";
import { A5AssessmentDisplay } from "./A5AssessmentDisplay";
import "./styles.scss";
import { CarePlanCard } from "../CarePlanCard";
import { CarePlanIconButton } from "../CarePlanIconButton";
import { ReportIcon } from "../../../../../component-library";
import { getReportPresignedUrl } from "../../../../../utils/fileUtil";
import { A5V1AssessmentDisplay } from "./A5V1AssessmentDisplay";
import { CarePlanEmptyContent } from "../CarePlanEmptyContent";
import moment from "moment";

/**
 * Displays the most recent A5 assessment (an A5 if no A5R exists, and an A5R if it exists)
 */
export const A5AssessmentCard = ({ assessmentSummaries }) => {
    // enforce only most recent a5 is displayed
    const getAssessmentName = (assessmentSummaries) => {
        if (assessmentSummaries?.[A5R_ASSESSMENT_TYPE]) {
            return "A5-R";
        } else if (assessmentSummaries?.[A5_V2_ASSESSMENT_TYPE]) {
            return "A5";
        } else if (assessmentSummaries?.[A5_SUB_ASSESSMENT_TYPE]) {
            return "A5";
        }

        // when none of these have an assessment value we default to the empty
        // state for the A5
        return "A5";
    };

    const getAssessmentTypeToDisplay = () => {
        if (assessmentSummaries?.[A5R_ASSESSMENT_TYPE]) {
            return A5R_ASSESSMENT_TYPE;
        } else if (assessmentSummaries?.[A5_V2_ASSESSMENT_TYPE]) {
            return A5_V2_ASSESSMENT_TYPE;
        } else if (assessmentSummaries?.[A5_SUB_ASSESSMENT_TYPE]) {
            return A5_SUB_ASSESSMENT_TYPE;
        } else if (assessmentSummaries?.[DEFAULT_ASSESSMENT_TYPE]) {
            return DEFAULT_ASSESSMENT_TYPE;
        }

        return "None";
    };

    const assessmentName = getAssessmentName(assessmentSummaries);
    const assessmentType = getAssessmentTypeToDisplay();
    const assessmentSummary = assessmentSummaries?.[assessmentType];

    const getCompletedTime = () => {
        const localCompletedTime = moment
            .utc(assessmentSummary.completed_time, "YYYY-MM-DDTHH:mm:ss")
            .local();

        if (localCompletedTime.isValid()) {
            return localCompletedTime.format("M/D/YYYY");
        }

        return "";
    };

    return (
        <CarePlanCard
            title={`${assessmentName} Responses`}
            actions={
                <>
                    {assessmentType !== "None" && assessmentSummary?.report_file_info && (
                        <CarePlanIconButton
                            onClick={async () => {
                                const reportUrl = await getReportPresignedUrl(
                                    assessmentSummary?.report_file_info,
                                );
                                window.open(reportUrl);
                            }}
                            text="Download Full Report"
                            Icon={ReportIcon}
                            iconClassName="A5AssessmentCard--icon"
                            iconButtonClassName="A5AssessmentCard--icon_button"
                        />
                    )}
                </>
            }>
            <p className="A5AssessmentCard--subtitle">Select responses from {assessmentName}.</p>

            {assessmentType === "None" && (
                <CarePlanEmptyContent
                    text={"No completed A5."}
                    containerClassName={"A5AssessmentCard--empty_card"}
                />
            )}

            {assessmentType === DEFAULT_ASSESSMENT_TYPE && (
                <CarePlanEmptyContent
                    text={
                        "Unsupported version of the A5. Please see the Reports tab to view the results."
                    }
                    containerClassName={"A5AssessmentCard--empty_card"}
                />
            )}

            {(assessmentType === A5R_ASSESSMENT_TYPE ||
                assessmentType === A5_V2_ASSESSMENT_TYPE) && (
                <A5AssessmentDisplay assessmentSummary={assessmentSummary.summary} />
            )}

            {assessmentType === A5_SUB_ASSESSMENT_TYPE && (
                <A5V1AssessmentDisplay assessmentSummary={assessmentSummary.summary} />
            )}

            {assessmentSummary && assessmentType !== DEFAULT_ASSESSMENT_TYPE && (
                <div className="A5AssessmentDisplay--meta_data">
                    <p className="A5AssessmentDisplay--meta_data_text">
                        Submitted by the {assessmentSummary.submitted_by}
                    </p>
                    <p className="A5AssessmentDisplay--meta_data_text">{getCompletedTime()}</p>
                </div>
            )}
        </CarePlanCard>
    );
};
