// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, {useEffect} from "react";
import images from "../../../../utils/images";
import {Button} from "../../../../component-library";
import {Channel} from "./Channel";
import PropTypes from "prop-types";

export const ChannelsSideBar = (props) => {
    const {
        client,
        hasMoreChannels,
        onMessageReceived,
        onSelectChannel,
        showMoreChannels,
        switchView,
    } = props;

    useEffect(() => {
        const ChannelHandler = new client.ChannelHandler();
        ChannelHandler.onMessageReceived = onMessageReceived;
        client.addChannelHandler("CHANNEL_SIDE_BAR_HANDLER", ChannelHandler);

        return () => {
            client.removeChannelHandler("CHANNEL_SIDE_BAR_HANDLER");
        };
    }, []);

    const channelsHeader = <header>
        <div className="Messages__ChannelSideBar__Header position-relative d-flex flex-row justify-content-between">
            <div className="title">
                <span>
                    <b>Messages</b>
                </span>
            </div>
        </div>
    </header>;

    const loadMoreButton = <div className="Messages__ChannelSideBar__ShowMoreChannelsButton d-flex align-items-center justify-content-center">
        <Button onClick={showMoreChannels} buttonType={"primary"}>
            Show More
        </Button>
    </div>;

    const channelListHeader = <div className="InnerHeader d-flex flex-row justify-content-between">
        <span>
            <b>Active Conversations</b>
        </span>
        <img
            className="add-btn"
            src={images("./icons/add-white-btn.svg")}
            alt="search icon"
            onClick={switchView}
        />
    </div>;

    const renderChannels = (channels) => {
        return channels.map((channel) => {
            return <Channel
                channel={channel}
                onSelectChannel={onSelectChannel}
                key={channel.name}
                lastMessage={channel.lastMessage}
                unreadMessageCount={channel.unreadMessageCount}
                url={channel.url}
            />;
        });
    };

    const channelList = <div className="Messages__ChannelSideBar__List">
        { channelListHeader }
        <div>
            <ul>
                { renderChannels(props.channels) }
            </ul>
        </div>
        { hasMoreChannels && loadMoreButton }
    </div>;

    return <>
        { channelsHeader }
        { channelList }
    </>;
};

ChannelsSideBar.propTypes = {
    channels: PropTypes.array.isRequired,
    client: PropTypes.object.isRequired,
    hasMoreChannels: PropTypes.bool.isRequired,
    onSelectChannel: PropTypes.func.isRequired,
    showMoreChannels: PropTypes.func.isRequired,
    switchView: PropTypes.func.isRequired,
};
