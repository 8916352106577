// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import { MemberProfileActivityEntry } from "../../../component-library";

const BillingChange = (props) => {
    const { localCreatedTime, keyContent } = props;
    const { automatic_payment_enabled, admin_name, admin_id } = keyContent;

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Dark"
            title="Billing Change"
            subTitle={`Automatic charging ${automatic_payment_enabled ? "enabled" : "disabled"}.`}
            localDateTime={localCreatedTime}
            iconSrc="activate_timeline.svg"
            iconAlt="BillingChangeIcon">
            <div className={"fs-14"}>{`Updated By: ${admin_name} (${admin_id})`}</div>
        </MemberProfileActivityEntry>
    );
};

export default BillingChange;
