import * as yup from "yup";
import moment from "moment";
import { transformTimeSlotArray } from "../../../../Calendar/CalendarAppointmentPicker/calendarAppointmentPickerUtils";
import { breakTimeIntoXMinuteIntervals } from "../../../../../utils/calendar/dates";
import { STANDARD_DOB_MATCHES_VALIDATION } from "../../../../Common/FormHelpers/MemberInfo/ValidatorBuilder";
import { getLocalTimezone } from "../../../../../redux/reducers/authReducer";
import { isAuthorized } from "../../../../../redux/actions/auth";
import { admin_cse, provider_case_manager, provider_peer } from "../../../../../config/roles";

export const hasAccessToViewAdvancedCareActivityNote = () => {
    return (
        // allow intake or BHS
        isAuthorized("provider:behavioral") ||
        isAuthorized(admin_cse) ||
        isAuthorized("provider:prescribe") ||
        isAuthorized(provider_case_manager) ||
        isAuthorized(provider_peer)
    );
};

export const hasAccessToCreateAdvancedCareActivityNote = (providerType) => {
    return (
        (isAuthorized("provider:behavioral") && providerType === "BEHAVIORAL") ||
        isAuthorized(admin_cse) ||
        isAuthorized("provider:prescribe") ||
        isAuthorized(provider_case_manager) ||
        isAuthorized(provider_peer)
    );
};

export const ADVANCED_CARE_ACTIVITY_CONTENT_FIELDS_TO_TRANSFORM_TO_BOOLEAN = [
    "patient_encounter",
    "patient_participant_attest",
    "activity_result",
];

export const ADVANCED_CARE_ACTIVITY_INITIAL_VALUES = {
    patient_first_name: "",
    patient_last_name: "",
    provider_id: "",
    provider_type: "",
    provider_name: "",
    signature: "",
    signature_date: "",
    provider_cp_credentials: "",
    is_billable: false,
    is_processed: false,
    version: 7,
    note_type: "Advanced Care Activity",
    title: "Advanced Care Activity Assessment",
    note_state: "completed",

    service_location: "Virtual",
    session_date: moment().format("MMDDYYYY"),
    session_from: "",
    session_to: "",
    content: {
        timezone: getLocalTimezone(),
        activity_service_type: "",
        activity_purpose: [],
        activity_purpose_other_details: "",
        communication_type: "",
        communication_type_other_details: "",
        activity_reason: "",
        care_team_member_type: "",
        care_team_member_type_other_details: "",
        // This is converted to a boolean on submission
        patient_encounter: "",
        // This is converted to a boolean on submission
        patient_participant_attest: "",
        intended_duration: "",
        reason_for_communication: [],
        // This is converted to a boolean on submission
        activity_result: "",
        activity_result_note: "",
        recommended_next: [],
        recommended_next_other_details: "",
        additional_information: "",
    },
};

export const ACTIVITY_SEVICE_TYPE_OPTIONS = [
    {
        label: "Case management",
        value: "Case management",
    },
    {
        label: "Peer support",
        value: "Peer support",
    },
    {
        label: "Care coordination",
        value: "Care coordination",
    },
    {
        label: "Prescriber support",
        value: "Prescriber support",
    },
];

export const ACTIVITY_PURPOSE_OPTIONS = [
    {
        label: "Crisis support",
        value: "Crisis support",
    },
    {
        label: "Goal support",
        value: "Goal support",
    },
    {
        label: "Medication management support",
        value: "Medication management support",
    },
    {
        label: "Patient education",
        value: "Patient education",
    },
    {
        label: "Patient outreach",
        value: "Patient outreach",
    },
    {
        label: "Real-time event notification",
        value: "Real-time event notification",
    },
    {
        label: "Quality measure support",
        value: "Quality measure support",
    },
    {
        label: "Scheduling support",
        value: "Scheduling support",
    },
    {
        label: "Other (Please Specify)",
        value: "Other",
    },
    {
        label: "Translation services",
        value: "Translation services",
    },
    { label: "HARP education", value: "HARP education" },
];

export const ACTIVITY_CLASS_OPTIONS = [
    {
        label: "Virtual",
        value: "Virtual",
    },
    {
        label: "In Person",
        value: "In Person",
    },
];

export const COMMUNICATION_TYPE_OPTIONS = [
    {
        label: "Phone call (audio only)",
        value: "Phone call (audio only)",
    },
    {
        label: "Video call (audio and video)",
        value: "Video call (audio and video)",
    },
    {
        label: "Text",
        value: "Text",
    },
    {
        label: "In app messaging",
        value: "In app messaging",
    },
    {
        label: "On call",
        value: "On call",
    },
    {
        label: "In person patient home",
        value: "In person patient home",
    },
    {
        label: "In person office",
        value: "In person office",
    },
    {
        label: "In person transportation",
        value: "In person transportation",
    },
    {
        label: "Other (Please Specify)",
        value: "Other",
    },
];

export const ACTIVITY_REASON_OPTIONS = [
    {
        label: "Yes, planned/routine",
        value: "Yes, planned/routine",
    },
    {
        label: "No, unplanned",
        value: "No, unplanned",
    },
];

export const CARE_TEAM_MEMBER_TYPE_OPTIONS = [
    {
        label: "BHS",
        value: "BHS",
    },
    {
        label: "Prescriber",
        value: "Prescriber",
    },
    {
        label: "RN Case Manager",
        value: "RN Case Manager",
    },
    {
        label: "RN (prescriber support)",
        value: "RN (prescriber support)",
    },
    {
        label: "CMA (Certified Medical Assistant)",
        value: "CMA (Certified Medical Assistant)",
    },
    {
        label: "Peer Specialist",
        value: "Peer Specialist",
    },
    {
        label: "Care Coordinator",
        value: "Care Coordinator",
    },
    {
        label: "Clinical Supervisor",
        value: "Clinical Supervisor",
    },
    {
        label: "Other (Please Specify)",
        value: "Other",
    },
];

export const YES_NO_OPTIONS = [
    { label: "Yes", value: "Yes", booleanValue: true },
    { label: "No", value: "No", booleanValue: false },
];

export const REASON_FOR_COMMUNICATION_OPTIONS = [
    { label: "Appointment changes", value: "Appointment changes" },
    { label: "SUD", value: "SUD" },
    { label: "SDoH", value: "SDoH" },
    { label: "Medical", value: "Medical" },
    { label: "Acute Care Encounter", value: "Acute Care Encounter" },
    { label: "Outreach/support", value: "Outreach/support" },
    { label: "Medication", value: "Medication" },
    { label: "Care team collaboration", value: "Care team collaboration" },
    { label: "CBO connection", value: "CBO connection" },
];

export const RECOMMENDED_NEXT_OPTIONS = [
    {
        label: "Follow up within 24-48 hours (immediate need)",
        value: "Follow up within 24-48 hours (immediate need)",
    },
    { label: "Follow up within week", value: "Follow up within week" },
    { label: "Follow up within month", value: "Follow up within month" },
    { label: "No follow up needed at this time", value: "No follow up needed at this time" },
    { label: "Other (Please Specify)", value: "Other" },
];

export const TIME_SLOT_OPTIONS = transformTimeSlotArray(
    breakTimeIntoXMinuteIntervals(moment("00:00", "HH:mm"), moment("23:59", "HH:mm"), 5),
);

const INTENDED_DURATION_MAX_LENGTH_MINUTES = 120;
const INTENDED_DURATION_INCREMENT = 5;

export const INTENDED_DURATION_OPTIONS = Array.from(
    Array(INTENDED_DURATION_MAX_LENGTH_MINUTES / INTENDED_DURATION_INCREMENT),
    (_, index) => {
        const numberOfMinutes = (index + 1) * 5;
        const duration = moment.duration(numberOfMinutes, "minutes");
        const hours = duration.hours();
        const remainingMinutes = duration.minutes();

        let formattedString = "";
        if (hours === 0) {
            formattedString = `${remainingMinutes} min`;
        } else if (remainingMinutes === 0) {
            formattedString = `${hours} hr`;
        } else {
            formattedString = `${hours} hr ${remainingMinutes} min`;
        }

        return {
            label: formattedString,
            value: numberOfMinutes,
        };
    },
);

export const filterDurationOptionsByLabel = (option, inputValue) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
};

export const ADVANCED_CARE_ACTIVITY_VALIDATION = yup.object().shape({
    signature: yup.string().required("Question is required"),
    session_date: STANDARD_DOB_MATCHES_VALIDATION()
        .required("Question is required")
        .test("activity_purpose", "Activity Date must be in the past", (value) => {
            const today = moment();
            const enteredDay = moment(value, "MMDDYYYY");

            // error if the entered day is in the future
            return today.isAfter(enteredDay);
        }),

    session_from: yup
        .string()
        .required("Question is required")
        .oneOf(
            TIME_SLOT_OPTIONS.map((item) => item.value),
            "An invalid answer was selected",
        ),
    session_to: yup
        .string()
        .required("Question is required")
        .oneOf(
            TIME_SLOT_OPTIONS.map((item) => item.value),
            "An invalid answer was selected",
        ),

    start_date_time_error: yup
        .string()
        .when(["session_from", "session_date"], (session_from, session_date) => {
            const startTime = moment(`${session_date} ${session_from}`, "MMDDYYYY HH:mm");
            const today = moment();
            if (startTime.isSameOrAfter(today)) {
                return yup
                    .string()
                    .test("date_time_error", "The start time must be in the past", () => false);
            }
            return yup.string();
        }),
    end_date_time_error: yup
        .string()
        .when(["session_to", "session_date"], (session_to, session_date) => {
            const endTime = moment(`${session_date} ${session_to}`, "MMDDYYYY HH:mm");
            const today = moment();
            if (endTime.isAfter(today)) {
                return yup
                    .string()
                    .test("date_time_error", "The end time must be in the past", () => false);
            }
            return yup.string();
        }),
    time_error: yup.string().when(["session_from", "session_to"], (session_from, session_to) => {
        if (!session_from || !session_to) {
            return yup.string();
        }

        const allottedTime = moment(session_to, "HH:mm").diff(
            moment(session_from, "HH:mm"),
            "minutes",
        );

        if (allottedTime <= 0) {
            return yup
                .string()
                .test("time_error", "Please enter a valid start and end time", () => false);
        }

        return yup.string();
    }),
    content: yup.object().shape({
        activity_service_type: yup
            .string()
            .required("Question is required")
            .oneOf(
                ACTIVITY_SEVICE_TYPE_OPTIONS.map((item) => item.value),
                "An invalid answer was selected",
            ),
        activity_purpose: yup
            .array()
            .required("Question is required")
            .test("activity_purpose", "An invalid answer was selected", (value) => {
                const activity_purpose_values = ACTIVITY_PURPOSE_OPTIONS.map((item) => item.value);
                return value.every((item) => activity_purpose_values.includes(item));
            }),
        activity_purpose_other_details: yup.string().when("activity_purpose", {
            is: (activity_purpose) => activity_purpose.includes("Other"),
            then: yup.string().required("Question is required"),
        }),
        communication_type: yup
            .string()
            .required("Question is required")
            .oneOf(
                COMMUNICATION_TYPE_OPTIONS.map((item) => item.value),
                "An invalid answer was selected",
            ),
        communication_type_other_details: yup.string().when("communication_type", {
            is: (communication_type) => communication_type === "Other",
            then: yup.string().required("Question is required"),
        }),
        activity_reason: yup
            .string()
            .required("Question is required")
            .oneOf(
                ACTIVITY_REASON_OPTIONS.map((item) => item.value),
                "An invalid answer was selected",
            ),

        care_team_member_type: yup
            .string()
            .required("Question is required")
            .oneOf(
                CARE_TEAM_MEMBER_TYPE_OPTIONS.map((item) => item.value),
                "An invalid answer was selected",
            ),

        care_team_member_type_other_details: yup.string().when("care_team_member_type", {
            is: (care_team_member_type) => care_team_member_type === "Other",
            then: yup.string().required("Question is required"),
        }),
        // This is converted to a boolean on submission
        patient_encounter: yup
            .string()
            .required("Question is required")
            .oneOf(
                YES_NO_OPTIONS.map((item) => item.value),
                "An invalid answer was selected",
            ),
        // This is converted to a boolean on submission
        patient_participant_attest: yup
            .string()
            .required("Question is required")
            .oneOf(
                YES_NO_OPTIONS.map((item) => item.value),
                "An invalid answer was selected",
            ),
        intended_duration: yup
            .string()
            .required("Question is required")
            .test(
                "intended_duration",
                "Please enter a duration in 5 minute increments",
                (value) => {
                    const valueAsNumber = Number(value);
                    if (!valueAsNumber) {
                        return false;
                    }

                    return valueAsNumber % 5 === 0;
                },
            )
            .test("intended_duration", "Duration cannot be longer than a day", (value) => {
                const valueAsNumber = Number(value);
                if (!valueAsNumber) {
                    return false;
                }

                return valueAsNumber <= 1440;
            }),
        reason_for_communication: yup
            .array()
            .required("Question is required")
            .test("reason_for_communication", "An invalid answer was selected", (value) => {
                const reason_for_communication_values = REASON_FOR_COMMUNICATION_OPTIONS.map(
                    (item) => item.value,
                );
                return value.every((item) => reason_for_communication_values.includes(item));
            }),
        // This is converted to a boolean on submission
        activity_result: yup
            .string()
            .required("Question is required")
            .oneOf(
                YES_NO_OPTIONS.map((item) => item.value),
                "An invalid answer was selected",
            ),
        activity_result_note: yup.string().when("activity_result", {
            is: (activity_result) => activity_result === "No",
            then: yup.string().required("Question is required"),
        }),

        recommended_next: yup
            .array()
            .required("Question is required")
            .test("recommended_next", "An invalid answer was selected", (value) => {
                const recommended_next_values = RECOMMENDED_NEXT_OPTIONS.map((item) => item.value);
                return value.every((item) => recommended_next_values.includes(item));
            }),
        recommended_next_other_details: yup.string().when("recommended_next", {
            is: (recommended_next) => recommended_next.includes("Other"),
            then: yup.string().required("Question is required"),
        }),
        additional_information: yup.string().required("Question is required"),
    }),
});
