// Copyright aptihealth, inc. 2019 All Rights Reserved
import images from "../../../utils/images";
import Button from "../../UI/Button";
import React from "react";
import { allottedTimeFactory } from "../ScheduleAvailability/constants";
import { providerTypeToDefaultEvent } from "./MemberProfile/MemberProfileHeader";
import { connect } from "react-redux";

const CallPatientPopUp = (props) => {
    const { cancelHandler, callHandler } = props;
    return (
        <div className="PatientStatusPopUp position-fixed d-flex align-items-center justify-content-center">
            <div className="PatientStatusPopUp__inner d-flex flex-column text-center position-absolute">
                <hr />
                <img src={images("./icons/call-large.svg")} alt="Menu" className="img-fluid" />
                <span className="fs-15 mt-2 p-3">
                    An Instant Call will be activated by calling the patient directly. They will be
                    alerted with a notification and a new call with standard&nbsp;
                    {allottedTimeFactory(providerTypeToDefaultEvent(props.profile))} minute CPT code
                    will commence. Select ‘Call’ to initiate Instant Call or ‘Cancel’ to move back
                    to Patient Profile.
                </span>
                <div className="PatientStatusPopUp__btn-wpr  d-flex justify-content-center pt-3 my-4">
                    <Button
                        onClick={cancelHandler}
                        type="button"
                        className="Btn Btn--otl-pri Btn-sm mx-2">
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        className="Btn Btn--pri Btn-sm mx-2"
                        onClick={callHandler}>
                        Call
                    </Button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps)(CallPatientPopUp);
