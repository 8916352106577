import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import moment from "moment";
import * as yup from "yup";
import Recaptcha from "react-recaptcha";

import { api } from "../../../../APIRequests";
import { ERROR_CODES } from "../../../../constants/errorCode";
import { showAlertWithAction } from "../../../../redux/actions/alerts";

import {
    Button,
    ButtonTypes,
    DateInput,
    CheckboxOld,
    Dropdown,
    FormField,
    Input,
    Link,
    PhoneInput,
    RadioGroup,
    Text,
    TextColors,
    TextTypes,
    mappedChangeHandler,
    mapDropDownValue,
    firstNameValidation,
    lastNameValidation,
    genderValidation,
    emailValidation,
    dateOfBirthValidation,
    mobileValidation,
    RadioOld,
} from "../../../../component-library";

const GENDER_OPTIONS = [
    { label: "Female", value: "Female" },
    { label: "Male", value: "Male" },
    { label: "Transgender", value: "Transgender" },
    { label: "Non-binary", value: "Non-binary" },
    { label: "Gender fluid", value: "Gender fluid" },
    { label: "Other", value: "Other" },
];

const VALIDATION_SCHEMA = yup.object().shape({
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    dateOfBirth: dateOfBirthValidation,
    gender: genderValidation,
    email: emailValidation,
    mobile: mobileValidation,
    ackPolicy: yup.bool().oneOf([true], "Acknowledgment is required"),
    recaptcha: yup.string().required("Recaptcha is required"),
});

const GuardianInformation = ({
    configs,

    prevStep,
    nextStep,

    ...props
}) => {
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);

    useEffect(() => {
        if (configs.OVERRIDE_RECAPTCHA) {
            setRecaptchaVerified(true);
        }
    }, [configs.OVERRIDE_RECAPTCHA]);

    return (
        <>
            <Text
                className="WFS_GuardianInformation--header"
                type={TextTypes.heading2}
                color={TextColors.green}>
                Parent/Guardian's Information
            </Text>
            <Text
                className="WFS_GuardianInformation--paragraph"
                type={TextTypes.paragraph}
                color={TextColors.grey}>
                Please fill out the information below to create an aptihealth account for yourself.
                You’ll have an account to consent to care for your child and receive important
                notices regarding your child’s care.
            </Text>
            <Formik
                initialValues={{
                    firstName: "",
                    lastName: "",
                    dateOfBirth: "",
                    gender: "",
                    email: "",
                    mobile: "",
                    legalGuardian: "yes",
                    ackPolicy: false,
                    recaptcha: "",
                }}
                validationSchema={VALIDATION_SCHEMA}
                onSubmit={async (values, { setSubmitting }) => {
                    if (!recaptchaVerified) {
                        return;
                    }
                    try {
                        const datetimeNow = moment().toISOString();
                        await api.auth.sign_up({
                            data: {
                                first_name: values.firstName,
                                last_name: values.lastName,
                                dob: values.dateOfBirth,
                                mobile: values.mobile,
                                email: values.email,
                                email_validation: values.email,
                                interested_in_guardianship: true,
                                guardianship_program_type: "PARENT_CHILD",
                                consent: {
                                    eula: [
                                        {
                                            date: datetimeNow,
                                            client: "web",
                                            consent_given_by: "self",
                                        },
                                    ],
                                    privacy: [
                                        {
                                            date: datetimeNow,
                                            client: "web",
                                            consent_given_by: "self",
                                        },
                                    ],
                                    hipaa: [
                                        {
                                            date: datetimeNow,
                                            client: "web",
                                            consent_given_by: "self",
                                        },
                                    ],
                                },
                                group_type: "patient_self_signup",
                                Username: values.email,
                            },
                        });
                        setSubmitting(false);
                    } catch (e) {
                        console.error(e);
                        const error = ERROR_CODES.hasOwnProperty(e.message)
                            ? ERROR_CODES[e.message]
                            : ERROR_CODES["SignUpDefault"];
                        props.showAlertWithAction(`${error.message} Code: ${error.code}`);
                        setSubmitting(false);
                    }
                }}>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleFocus,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isValid,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="WFS_GuardianInformation--grid">
                            <FormField
                                label="Parent/Guardian’s First Name"
                                errorMessage={touched.firstName ? errors.firstName : ""}>
                                <Input
                                    name="firstName"
                                    value={values.firstName}
                                    error={touched.firstName && errors.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    placeholder="Enter First Name"
                                />
                            </FormField>
                            <FormField
                                label="Parent/Guardian’s Last Name"
                                errorMessage={touched.lastName ? errors.lastName : ""}>
                                <Input
                                    name="lastName"
                                    value={values.lastName}
                                    error={touched.lastName && errors.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    placeholder="Enter Last Name"
                                />
                            </FormField>
                            <FormField
                                label="Parent/Guardian’s Date of Birth"
                                errorMessage={touched.dateOfBirth ? errors.dateOfBirth : ""}>
                                <DateInput
                                    name="dateOfBirth"
                                    value={values.dateOfBirth}
                                    error={touched.dateOfBirth && errors.dateOfBirth}
                                    onChange={mappedChangeHandler(handleChange, "dateOfBirth")}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    placeholder="MM/DD/YYYY"
                                />
                            </FormField>
                            <FormField
                                label="Parent/Guardian’s Gender"
                                errorMessage={touched.gender ? errors.gender : ""}>
                                <Dropdown
                                    name="gender"
                                    value={mapDropDownValue(values.gender)}
                                    error={touched.gender && errors.gender}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    options={GENDER_OPTIONS}
                                    placeholder="Select Gender"
                                />
                            </FormField>
                            <FormField
                                label="Parent/Guardian’s Email"
                                errorMessage={touched.email ? errors.email : ""}>
                                <Input
                                    name="email"
                                    error={touched.email && errors.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    placeholder="Enter Email"
                                />
                            </FormField>
                            <FormField
                                label="Parent/Guardian’s Mobile Number"
                                errorMessage={touched.mobile ? errors.mobile : ""}>
                                <PhoneInput
                                    name="mobile"
                                    error={touched.mobile && errors.mobile}
                                    onChange={mappedChangeHandler(handleChange, "mobile")}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    placeholder="Enter Mobile Number"
                                />
                            </FormField>
                        </div>
                        <FormField label="Are you the legal guardian of the child you are registering?">
                            <RadioGroup
                                name="legalGuardian"
                                defaultValue="yes"
                                value={values.legalGuardian}
                                onFocus={handleFocus}
                                onChange={handleChange}>
                                <RadioOld label="Yes" value="yes" />
                                <RadioOld label="No" value="no" />
                            </RadioGroup>
                        </FormField>

                        <div className="WFS_GuardianInformation--text">
                            <Text
                                className="WFS_GuardianInformation--paragraph"
                                type={TextTypes.paragraph}
                                color={TextColors.grey}>
                                Thank you for your trust in AptiHealth to treat your child. Before
                                we have you create their account, we need your consent on a few
                                items. Please view and print/save the documents linked below, as
                                they inform you of your rights and obligations while using
                                AptiHealth. By accessing and using the AptiHealth Services, you are
                                agreeing to our{" "}
                                <Link href="https://www.aptihealth.com/terms-and-conditions">
                                    Terms and Conditions of Use
                                </Link>
                                .
                            </Text>
                            <Text
                                className="WFS_GuardianInformation--paragraph"
                                type={TextTypes.paragraph}
                                color={TextColors.grey}>
                                By checking the box and clicking continue, (i) I acknowledge that I
                                have read the{" "}
                                <Link href="https://www.aptihealth.com/privacy">
                                    HIPAA Notice of Privacy Practices
                                </Link>{" "}
                                and (ii) I consent to AptiHealth’s processing of information
                                relating to me or my child as described in the{" "}
                                <Link href="https://www.aptihealth.com/hipaaprivacy">
                                    Privacy Policy
                                </Link>
                                .
                            </Text>
                        </div>
                        <div className="WFS_GuardianInformation--checkbox">
                            <CheckboxOld
                                name="ackPolicy"
                                checked={values.ackPolicy}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                            />
                            <Text type={TextTypes.paragraph} color={TextColors.grey}>
                                I agree to the above statement
                            </Text>
                        </div>
                        <Recaptcha
                            className="mx-auto recaptcha"
                            size={window.innerWidth < 768 ? "compact" : "normal"}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            verifyCallback={(response) => {
                                setFieldValue("recaptcha", response);
                                setRecaptchaVerified(true);
                            }}
                        />
                        <Button
                            className="WFS_GuardianInformation--button"
                            buttonType={ButtonTypes.primary}
                            disabled={!isValid}
                            type="submit">
                            Continue
                        </Button>
                    </form>
                )}
            </Formik>

            <div className="WFS_GuardianInformation--links">
                <Text type={TextTypes.paragraph} color={TextColors.grey}>
                    Already have an account?
                </Text>
                <Link>Log In</Link>
            </div>
            <button onClick={prevStep}>Previous Step</button>
            <button onClick={nextStep}>Next Step</button>
        </>
    );
};

const mapStateToProps = (state) => ({
    configs: state.configs,
});

export default connect(mapStateToProps, { showAlertWithAction })(GuardianInformation);
