import React from "react";
import Input from "../../components/UI/Input";
import "./styles.scss";
import { createClassNameString } from "../utils";

import _ from "lodash";
import "./styles.scss";

export const ConsentEmail = ({ consentEvent, children, className, type, color, ...props }) => (
    <span className="justified-center">
        <div>
            <Input
                className="custom-checkbox mr-2 mt-1"
                style={{
                    "min-height": "16px",
                    "min-width": "16px",
                    "max-height": "1rem",
                    "max-width": "1rem",
                }}
                type={"checkbox"}
                onClick={() => consentEvent()}
            />
        </div>
        <span className="fs-15 txt-gry">
            I consent to receive marketing and reminder emails from AptiHealth. I understand that my
            consent to such emails is not required to use AptiHealth’s services and that I may
            revoke my consent at any time.{" "}
        </span>
    </span>
);

ConsentEmail.defaultProps = {
    consentEvent: () => {},
};
