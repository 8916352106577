import * as actionTypes from "../actions/actionTypes";
import LogRocket from "logrocket";

const initialState = {
    omnisearchDisplayStatus: false
};

const setDynamicRouteConfigReducer = (state, action) => {
    return { ...state, ...action.payload };
};

const setOmnisearchDisplayStatusReducer = (state, action) => {
    return { ...state, omnisearchDisplayStatus: action.payload };
};

const logout = (state, action) => {
    LogRocket.identify();
    return {
        ...initialState,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_DYNAMIC_ROUTE_CONFIGURATION:
            return setDynamicRouteConfigReducer(state, action);
        case actionTypes.SET_OMNISEARCH_DISPLAY_STATUS:
            return setOmnisearchDisplayStatusReducer(state, action);
        case actionTypes.LOG_OUT:
            return logout(state, action);
        default:
            return state;
    }
};

export default reducer;
