import React from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";

import {
    Button,
    ButtonTypes,
    PageBreak,
    Text,
    TextArea,
    TextColors,
    TextTypes,
} from "../../../../component-library";

import "./styles.scss";
import { CustomForm } from "../../../../component-library/CustomForm";

export default ({ onSubmit, onCancel }) => (
    <div className="AddendumForm">
        <Text className="AddendumForm__title" type={TextTypes.heading2} layout="block">
            Add Addendum
        </Text>
        <PageBreak color="grey" />
        <CustomForm
            initialValues={{
                addendum: "",
            }}
            validationSchema={yup.object().shape({
                addendum: yup.string().required("Comment is required."),
            })}
            onSubmit={onSubmit}
            render={(formikProps) => (
                <>
                    <div className="AddendumForm__form">
                        <TextArea
                            className="AddendumForm__textarea"
                            name="addendum"
                            placeholder="Type Comment..."
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}>
                            {formikProps.values.addendum}
                        </TextArea>
                        {formikProps.errors.addendum && formikProps.touched.addendum && (
                            <Text type={TextTypes.small} color={TextColors.red} layout="block">
                                {formikProps.errors.addendum}
                            </Text>
                        )}
                        <Text
                            className="AddendumForm__cancel--text"
                            color={TextColors.grey}
                            layout="block">
                            Addendums will not be saved in drafts
                        </Text>
                    </div>

                    <div className="AddendumForm__buttons">
                        <Button
                            className="AddendumForm__cancel"
                            buttonType={ButtonTypes.primaryOutline}
                            onClick={() => {
                                formikProps.resetForm();
                                onCancel();
                            }}>
                            Cancel
                        </Button>
                        <Button
                            className="AddendumForm__submit"
                            type="submit"
                            buttonType={ButtonTypes.primary}
                            onClick={formikProps.handleSubmit}>
                            Save
                        </Button>
                    </div>
                </>
            )}
        />
    </div>
);
