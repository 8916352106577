// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import CardSecondary from "../../../components/UI/Card/CardSecondary";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { logOut } from "../../../redux/actions/auth";
import { connect, useSelector } from "react-redux";
import { getPatientAge } from "../Providers/schema/newPatientValidation";
import { Link, PageBanner, Text } from "../../../component-library";
import { PatientPaymentBanner } from "../../../component-library";
import { UserIcon } from "../../../components/Icons/UserIcon";
import { ICON_SIZES, ICON_TYPES } from "../../../constants/icons";
import { LockIcon } from "../../../components/Icons/LockIcon";
import { ShieldIcon } from "../../../components/Icons/ShieldIcon";
import { CardIcon } from "../../../components/Icons/CardIcon";
import { AddUserIcon } from "../../../components/Icons/AddUserIcon";
import { BellIcon } from "../../../components/Icons/BellIcon";
import { AptihealthIcon } from "../../../components/Icons/AptihealthIcon";
import { EnvelopeIcon } from "../../../components/Icons/EnvelopeIcon";
import { TourIcon } from "../../../components/Icons/TourIcon";
import { CameraIcon } from "../../../components/Icons/CameraIcon";
import { DocumentIcon } from "../../../components/Icons/DocumentIcon";
import Logout from "../../../components/Common/SettingsView/Logout";

const PatientSettings = (props) => {
    const showPaywall = useSelector((state) => state.patientCardInfo?.showPaywall);

    let displayAddChild = () => {
        return false;
    };
    if (props.profile != null && props.profile.dob != null) {
        displayAddChild = () => {
            return 18 < getPatientAge(props.profile.dob);
        };
    }

    return (
        <div className="container">
            {showPaywall && <PatientPaymentBanner />}
            <h3 style={{ color: "#0E3C51" }} className={"mt-3 fs-18 fw-bold"}>
                Settings
            </h3>
            <SettingsCard
                title={"Account"}
                content={
                    <>
                        <Setting
                            onClick={() => props.history.push(props.baseRoute + "/profile")}
                            iconComponent={UserIcon}
                            title={"Profile"}
                        />
                        <Setting
                            onClick={() => props.history.push(props.baseRoute + "/change-password")}
                            iconComponent={LockIcon}
                            title={"Password"}
                        />
                        <Setting
                            onClick={() =>
                                props.history.push(props.baseRoute + "/patient-insurance")
                            }
                            iconComponent={ShieldIcon}
                            title={"Insurance"}
                        />
                        <Setting
                            onClick={() => props.history.push(props.baseRoute + "/patient-payment")}
                            iconComponent={CardIcon}
                            title={"Payment"}
                        />
                        {displayAddChild() && (
                            <Setting
                                onClick={() =>
                                    props.history.push(
                                        props.baseRoute + "/teens-and-kids/program-details",
                                    )
                                }
                                iconComponent={AddUserIcon}
                                title={"Add Child"}
                            />
                        )}
                        <Setting
                            onClick={() =>
                                props.history.push(props.baseRoute + "/patient-notifications")
                            }
                            iconComponent={BellIcon}
                            title={"Notifications"}
                            noBorderBottom
                        />
                    </>
                }
            />
            <SettingsCard
                title={"Support"}
                content={
                    <>
                        <Setting
                            onClick={() =>
                                window.open("https://www.aptihealth.com/about/", "_blank").focus()
                            }
                            iconComponent={AptihealthIcon}
                            title={"About aptihealth"}
                        />
                        <Setting
                            onClick={() =>
                                window
                                    .open(
                                        "mailto:support@aptihealth.com?subject=Web Support Request",
                                        "_top",
                                    )
                                    .focus()
                            }
                            iconComponent={EnvelopeIcon}
                            title={"Member Support"}
                        />
                        <Setting
                            onClick={() => props.history.push(props.baseRoute + "/tutorial")}
                            iconComponent={TourIcon}
                            title={"Tutorials"}
                        />
                        <Setting
                            onClick={() => props.history.push(props.baseRoute + "/av-check")}
                            iconComponent={CameraIcon}
                            title={"Audio & Visual Check"}
                            noBorderBottom
                        />
                    </>
                }
            />
            <SettingsCard
                title={"Legal"}
                content={
                    <>
                        <Setting
                            onClick={() =>
                                window
                                    .open("https://www.aptihealth.com/hipaaprivacy", "_blank")
                                    .focus()
                            }
                            iconComponent={DocumentIcon}
                            title={"HIPAA Notice of Privacy Practices"}
                            noBorderBottom
                        />
                    </>
                }
            />
            <CardSecondary className="mt-3 mx-lg-auto">
                <Logout onLogOut={() => props.logOut({ history: props.history })} />
            </CardSecondary>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps, { logOut })(withRouter(PatientSettings));

const Setting = withRouter((props) => {
    const border = props.noBorderBottom ? "p-bottom" : "p-border";
    const IconComponent = props.iconComponent;
    return (
        <div className={`patient-dropdown-text-mobile ${border} mr-3 ml-2`} onClick={props.onClick}>
            <div className="patient-profile-icons">
                {IconComponent && (
                    <IconComponent
                        iconType={ICON_TYPES.OUTLINE}
                        iconSize={ICON_SIZES.SMALL}
                        className="Settings__PatIconComponent"
                    />
                )}
            </div>

            <div className="d-flex w-100 justify-content-between Settings__icon-text">
                <span>{props.title}</span>
            </div>
        </div>
    );
});

const SettingsCard = (props) => {
    return (
        <CardSecondary className=" mx-lg-auto mt-lg-5 mt-3 mb-3 mb-lg-3">
            <div className="d-flex flex-column">
                <span className="fw-bold patient-settings-title-mobile">{props.title}</span>
                <div className="ml-2">{props.content}</div>
            </div>
        </CardSecondary>
    );
};
