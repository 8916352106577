// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Fragment } from "react";

const SurveyControls = (props) => {
    const { rating, id, click } = props;
    let class_0 = "survey-option p-2 cursor" + " " + (rating == 0 ? "active" : "");
    let class_1 = "survey-option p-2 cursor" + " " + (rating == 1 ? "active" : "");
    let class_2 = "survey-option p-2 cursor" + " " + (rating == 2 ? "active" : "");
    let class_3 = "survey-option p-2 cursor" + " " + (rating == 3 ? "active" : "");

    const onOptionClick = (e) => {
        let value = Number(e.target.innerText);
        click(id, value);
    };

    return (
        <Fragment>
            <div className="col-6 col-md-3 text-center survey-option-wpr">
                <div onClick={onOptionClick} className={class_0}>
                    0
                </div>
                <p className="mt-2">Strongly Disagree</p>
            </div>
            <div className="col-6 col-md-3 text-center survey-option-wpr">
                <div onClick={onOptionClick} className={class_1}>
                    1
                </div>
                <p className="mt-2">Disagree</p>
            </div>
            <div className="col-6 col-md-3 text-center survey-option-wpr">
                <div onClick={onOptionClick} className={class_2}>
                    2
                </div>
                <p className="mt-2">Agree</p>
            </div>
            <div className="col-6 col-md-3 text-center survey-option-wpr">
                <div onClick={onOptionClick} className={class_3}>
                    3
                </div>
                <p className="mt-2">Strongly Agree</p>
            </div>
        </Fragment>
    );
};

export default SurveyControls;
