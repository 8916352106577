import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { CardHeading } from "../../../UI/Headings";
import images from "../../../../utils/images";
import _ from "lodash";
import { Portal } from "../../../Common/Modal/Portal";
import { DIAGNOSTIC_INTERVIEW_90791 } from "../../../Provider/ScheduleAvailability/constants";
import { convertUnknownDateStringFormat } from "../../../../utils/momentUtils";

export const AccomplishmentsWidget = (props) => {
    const { memberMilestoneActivities = [], memberSessionActivities = [] } = props;

    const renderActivityIcon = (icon) => {
        return <img src={icon} alt="" className="img-fluid my-0 mb-4 mb-md-0 mx-0 mx-md-2" />;
    };

    const renderActivitySectionHeader = (sectionTitle, activitiesToRender) => {
        const openButtonRender = (
            <span className="txt-sec fs-16 fw-bold cursor float-right">+ View all</span>
        );
        const headerRender = <h6 className="modal-title fw-bold">{sectionTitle}</h6>;
        return (
            <div className="row mb-2">
                <div className="col">
                    <Portal headerRender={headerRender} openButtonRender={openButtonRender}>
                        {activitiesToRender.map(renderActivitySectionBody)}
                    </Portal>
                    <h6 className="fw-bold">{sectionTitle}</h6>
                    <div className="border-bottom"></div>
                </div>
            </div>
        );
    };

    const renderActivitySectionBody = (activity, index, activityList) => {
        const { header, content, icon } = ACTIVITY_CONTENT_TRANSFORM_MAP[activity.type]
            ? ACTIVITY_CONTENT_TRANSFORM_MAP[activity.type](activity)
            : {};
        let rowClasses = "";
        if (activityList.length > 1) {
            rowClasses = "ActivitySectionBodyItem";
            if (index === 0) {
                rowClasses = "ActivitySectionBodyFirstItem";
            } else if (index === activityList.length - 1) {
                rowClasses = "ActivitySectionBodyLastItem";
            }
        }

        return header && content && icon ? (
            <div className={`row ${rowClasses}`}>
                <div className="col d-flex align-items-center pb-3">
                    {renderActivityIcon(icon)}
                    <div>
                        <CardHeading text={header} className="d-lg-inline-block pl-1 fs-16 mb-0" />
                        <p className="fs-14 pl-1 my-0 mb-4 mb-md-0 txt-gry">{content}</p>
                    </div>
                </div>
            </div>
        ) : (
            <></>
        );
    };

    const renderActivitySection = (sectionTitle, activities, activityAmount = 2) => {
        const clonedActivities = _.cloneDeep(activities);
        const activitiesToRender = clonedActivities.slice(0, activityAmount);
        return activitiesToRender ? (
            <>
                {renderActivitySectionHeader(sectionTitle, clonedActivities)}
                {activitiesToRender.map(renderActivitySectionBody)}
            </>
        ) : (
            <></>
        );
    };

    const renderedSections = {
        milestoneActivities: memberMilestoneActivities,
        sessionActivities: memberSessionActivities,
    };

    if (memberMilestoneActivities.length > 0) {
        renderedSections["milestoneActivities"] = renderActivitySection(
            "Milestones",
            memberMilestoneActivities,
        );
    }

    if (memberSessionActivities.length > 0) {
        const uniqueSessionActivities = [
            ...new Map(
                memberSessionActivities.map((activity) => [
                    `${activity["timestamp"]}|${activity["title"]}|${activity["type"]}`,
                    activity,
                ]),
            ).values(),
        ];
        renderedSections["sessionActivities"] = renderActivitySection(
            "Completed Sessions",
            uniqueSessionActivities,
        );
    }

    return (
        <div className="dashboard-widget-card dashboard-widget-card-xs px-3 py-3">
            {renderedSections["milestoneActivities"]}
            {renderedSections["sessionActivities"]}
        </div>
    );
};

export default AccomplishmentsWidget;

const buildScheduleCardTitle = (activity) => {
    return {
        header: activity["title"],
        content: `${activity["timestamp"]}`,
        icon: images("./icons/common/milestone/extended_care_started.svg"),
    };
};

const buildMilestoneCardTitle = (activity) => {
    const callDateTime = moment.unix(activity["timestamp"]).utc().local().format("LLL");
    return { header: activity["title"], content: `${callDateTime}`, icon: activity["icon"] };
};

const ACTIVITY_CONTENT_TRANSFORM_MAP = {
    milestone: buildMilestoneCardTitle,
    schedule: buildScheduleCardTitle,
};

export const buildPatientAccomplishmentSessions = (activities) => {
    return activities
        .filter(
            (activity) => activity.key_content["appointment_status"] === "Appointment Completed",
        )
        .map((activity) => {
            const callDateTime = moment
                .utc(`${activity.key_content["call_date"]} ${activity.key_content["call_time"]}`)
                .local()
                .format("LLL");
            const allottedTime = activity.key_content["duration"]
                ? ` - ${activity.key_content["duration"]} minutes`
                : "";
            const type = "schedule";
            const timestamp = `${callDateTime + allottedTime}`;
            const providerName = activity.key_content["provider_name"]
                ? ` with ${activity.key_content["provider_name"]}`
                : "";
            if (activity.key_content["call_event_type"] === DIAGNOSTIC_INTERVIEW_90791) {
                return {
                    title: "Therapist Consultation" + providerName,
                    timestamp,
                    icon: `${images("./icons/common/intake_bhs/intake-bhs-sec-white.svg")}`,
                    type,
                };
            } else if (activity.key_content["provider_type"] === "PRESCRIBE") {
                return {
                    title: "Medication Management Session" + providerName,
                    timestamp,
                    icon: `${images("./icons/common/prescriber/presciber-sec-white.svg")}`,
                    type,
                };
            } else {
                return {
                    title: "Therapist Session" + providerName,
                    timestamp,
                    icon: `${images("./icons/common/therapist/therapist-sec-white.svg")}`,
                    type,
                };
            }
        });
};

export const buildPatientAccomplishmentMilestones = (patient) => {
    const type = "milestone";
    const milestoneEventProps = [
        "ninety_day_start_date",
        "ninety_day_30_days_completed_date_time",
        "ninety_day_60_days_completed_date_time",
        "ninety_day_90_days_completed_date_time",
        "assessment_completed_date",
        "initial_video_consult_date_time",
        "extended_care_start_date_time",
        "created_date",
        "initial_prescriber_call_date_time",
    ];
    const milestoneEventHeaderMap = {
        ninety_day_start_date: {
            title: "90-Day Care Started",
            icon: `${images("./icons/common/milestone/90_day_started.svg")}`,
        },
        ninety_day_30_days_completed_date_time: {
            title: "30 Days on aptihealth",
            icon: `${images("./icons/common/milestone/30_day.svg")}`,
        },
        ninety_day_60_days_completed_date_time: {
            title: "60 Days on aptihealth",
            icon: `${images("./icons/common/milestone/60_day.svg")}`,
        },
        ninety_day_90_days_completed_date_time: {
            title: "90-Day Care Program Completed",
            icon: `${images("./icons/common/milestone/90_day_completed.svg")}`,
        },
        assessment_completed_date: {
            title: "Assessment Completed",
            icon: `${images("./icons/common/milestone/assessment_complete.svg")}`,
        },
        initial_video_consult_date_time: {
            title: "First Session Completed",
            icon: `${images("./icons/common/milestone/first_session.svg")}`,
        },
        initial_prescriber_call_date_time: {
            title: "First Session Completed",
            icon: `${images("./icons/common/milestone/first_session.svg")}`,
        },
        extended_care_start_date_time: {
            title: "Extended Care Program Started",
            icon: `${images("./icons/common/milestone/extended_care_started.svg")}`,
        },
        created_date: {
            title: "Became an aptihealth Member",
            icon: `${images("./icons/common/small_logo/logo-sec-white.svg")}`,
        },
    };
    const milestones = [];
    milestoneEventProps.forEach((property) => {
        if (patient[property]) {
            const timestamp = convertUnknownDateStringFormat(patient[property], "X");
            // quick fix for teens + kids, if they're auto flagged to have passed the assessment, stash it from the users sight
            if (
                property === "assessment_completed_date" &&
                patient[property] === patient["assessment_started_date"] &&
                patient.group_type === "teens_kids_program"
            ) {
                return;
            }
            const activity = { type, timestamp, ...milestoneEventHeaderMap[property] };
            let existingMilestone = milestones.find(
                (milestone) => milestone["title"] === activity["title"],
            );
            if (existingMilestone) {
                existingMilestone =
                    activity["timestamp"] < existingMilestone["timestamp"]
                        ? activity
                        : existingMilestone;
            } else {
                milestones.push(activity);
            }
        }
    });

    return milestones.sort(
        (activityA, activityB) => activityB["timestamp"] - activityA["timestamp"],
    );
};
