/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const AlertIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Alert"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 5.84615C10.3922 5.84615 5.84615 10.3922 5.84615 16C5.84615 21.6078 10.3922 26.1538 16 26.1538C21.6078 26.1538 26.1538 21.6078 26.1538 16C26.1538 10.3922 21.6078 5.84615 16 5.84615ZM4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 10.6461C16.5098 10.6461 16.9231 11.0594 16.9231 11.5692V16C16.9231 16.5098 16.5098 16.923 16 16.923C15.4902 16.923 15.0769 16.5098 15.0769 16V11.5692C15.0769 11.0594 15.4902 10.6461 16 10.6461Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.0769 20.4308C15.0769 19.921 15.4902 19.5077 16 19.5077H16.0117C16.5215 19.5077 16.9348 19.921 16.9348 20.4308C16.9348 20.9406 16.5215 21.3539 16.0117 21.3539H16C15.4902 21.3539 15.0769 20.9406 15.0769 20.4308Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Alert"
                {...props}>
                <path
                    d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16 11.2V16"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16 20.8H16.0127"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Alert"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM30 20C30 25.5228 25.5228 30 20 30C14.4772 30 10 25.5228 10 20C10 14.4772 14.4772 10 20 10C25.5228 10 30 14.4772 30 20ZM21 16C21 15.4477 20.5523 15 20 15C19.4477 15 19 15.4477 19 16V20C19 20.5522 19.4477 21 20 21C20.5523 21 21 20.5522 21 20V16ZM19 24C19 23.4478 19.4477 23 20 23H20.0095C20.5618 23 21.0095 23.4478 21.0095 24C21.0095 24.5523 20.5618 25 20.0095 25H20C19.4477 25 19 24.5523 19 24Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

AlertIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

AlertIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
