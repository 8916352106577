// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import { MemberProfileActivityEntry } from "../../../component-library";

export const WaitListAddedActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const { wait_list_reason, added_by_name, removed_by_name } = keyContent;

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Dark"
            title="Waitlist Change"
            subTitle={`Waitlisted (Reason: ${wait_list_reason})`}
            localDateTime={localCreatedTime}
            iconSrc="icons/common/task/InsuranceBlue.svg"
            iconAlt="WaitlistedIcon">
            <div className={"fs-14"}>{`Updated By: ${added_by_name ?? removed_by_name}`}</div>
        </MemberProfileActivityEntry>
    );
};

export const WaitListRemovedActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const { added_by_name, removed_by_name } = keyContent;

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Dark"
            title="Waitlist Change"
            subTitle={"Removed from waitlist"}
            localDateTime={localCreatedTime}
            iconSrc="icons/common/task/InsuranceBlue.svg"
            iconAlt="WaitlistRemovedIcon">
            <div className={"fs-14"}>{`Updated By: ${added_by_name ?? removed_by_name}`}</div>
        </MemberProfileActivityEntry>
    );
};
