import React, { useEffect } from "react";
import { AuthWrapper } from "./index";
import { Formik } from "formik";
import FormikInput from "../UI/FormikInput";
import Button from "../UI/Button";
import * as Yup from "yup";
import { setAuthAfterPasswordReset } from "../../redux/actions/auth";
import { connect } from "react-redux";
import { yup_password_factory } from "../../utils/yupSchemaUtils";
import CardHeading from "../UI/Headings/CardHeading";
import { withRouter } from "react-router-dom";
import { CustomForm } from "../../component-library/CustomForm";

const validation = Yup.object().shape({
    current_password: yup_password_factory(),
    new_password: yup_password_factory(),
    confirm_password: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref("new_password"), null], "Password does not match"),
});

const PwResetRequired = (props) => {
    useEffect(() => {
        if (!props.auth.token) {
            props.history.push("/auth/log-in");
        }
    }, []);

    let setNewPasswordHandler = (form, actions) => {
        delete form.confirm_password;
        const { refreshToken, token, accessToken } = props.auth;
        let cleanResponse = {
            AuthenticationResult: {
                IdToken: token,
                RefreshToken: refreshToken,
                AccessToken: accessToken,
            },
        };
        props.setAuthAfterPasswordReset(form, props.auth.accessToken, cleanResponse);
        actions.setSubmitting(false);
    };

    const forgotPassword = () => {
        props.history.push("/auth/forgot-password");
    };

    return (
        <AuthWrapper showBackLink={true}>
            <section className="ResetPassword">
                <CardHeading className="fs-20 patient-profile-purple" text="Change Password" />
                We noticed that your password hasn't been updated for the past 90 days. Please reset
                your password to continue.
                <hr className="my-2" />
                <CustomForm
                    validationSchema={validation}
                    onSubmit={setNewPasswordHandler}
                    initialValues={{ new_password: "", confirm_password: "", current_password: "" }}
                    render={(formikProps) => (
                        <form onSubmit={formikProps.handleSubmit}>
                            <FormikInput
                                errors={formikProps.errors}
                                touched={formikProps.touched}
                                formEl={{
                                    name: "current_password",
                                    elementType: "input",
                                    elementConfig: {
                                        type: "password",
                                        label: "Current Password",
                                        placeholder: "Enter Current Password",
                                    },
                                }}
                            />
                            <FormikInput
                                errors={formikProps.errors}
                                touched={formikProps.touched}
                                formEl={{
                                    name: "new_password",
                                    elementType: "input",
                                    elementConfig: {
                                        type: "password",
                                        label: "New Password",
                                        placeholder: "Enter New Password",
                                    },
                                }}
                            />
                            <FormikInput
                                errors={formikProps.errors}
                                touched={formikProps.touched}
                                formEl={{
                                    name: "confirm_password",
                                    elementType: "input",
                                    elementConfig: {
                                        type: "password",
                                        label: "Confirm New Password",
                                        placeholder: "Confirm New Password",
                                    },
                                }}
                            />
                            <div className={"d-flex justify-content-end"}>
                                <Button
                                    onClick={forgotPassword}
                                    type={"button"}
                                    className="Btn Btn-link txt-sec"
                                    style={{ textDecoration: "none", margin: 0 }}>
                                    Forgot Password?
                                </Button>
                            </div>
                            <div className="d-flex justify-content-end">
                                <>
                                    <Button
                                        disabled={formikProps.isSubmitting}
                                        type="submit"
                                        className="Btn Btn--pri Btn-sm mt-2 ">
                                        Confirm
                                    </Button>
                                    <a
                                        onClick={() => props.history.push("/auth/sign-in")}
                                        className="Btn Btn--otl-pri Btn-sm mt-2 ml-2"
                                        style={{ textAlign: "center", cursor: "pointer" }}>
                                        Cancel
                                    </a>
                                </>
                            </div>
                        </form>
                    )}
                />
            </section>
        </AuthWrapper>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps, { setAuthAfterPasswordReset })(withRouter(PwResetRequired));
