// Copyright aptihealth, inc. 2021 All Rights Reserved
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "./styles.scss";
import _ from "lodash";
import Button from "../../../UI/Button";

const PracticeFailure = (props) => {
    const { sharedWorkflowData, setSharedWorkflowData } = props;

    return (
        <>
            <div className={"p-lg-5 p-3 my-5"}>
                <div className={"fs-26 txt-pri fw-bold mb-5 mt-5 text-center"}>
                    We’re sorry, something has gone wrong on our end.
                </div>
                <div className={"text-center mt-5 mb-4 workflow-container-sm"}>
                    <p className={"grey-text fw-bold pt-5 mt-5 mb-0"}>
                        What could have caused this?
                    </p>
                    <p className={"grey-text"}>
                        You might have completed a screening in another location.
                    </p>
                </div>
                <div className={"text-center mt-4 mb-5 workflow-container-sm"}>
                    <p className={"grey-text fw-bold mb-0"}>What can you do?</p>
                    <p className={"grey-text"}>Please look for a staff member to help you.</p>
                </div>
                <div className={"text-center mt-4 mb-5 workflow-container-sm"}>
                    <Button
                        onClick={() => window.location.reload()}
                        className={"Btn Btn--pri Btn--sm-2 workflow-btn-lg mt-5"}
                        type={"button"}>
                        Back to Home Screen
                    </Button>
                </div>
            </div>
        </>
    );
};

export default withRouter(PracticeFailure);
