// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as actionTypes from "../actions/actionTypes";

const initialState = {
    isLoading: false,
    isMultiPartRequest: false,
};

const showLoader = (state, action) => {
    return {
        isLoading: true,
        isMultiPartRequest: action.isMultiPartRequest,
    };
};
const hideLoader = (state, action) => {
    return {
        isLoading: false,
        isMultiPartRequest: false,
    };
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_SPINNER:
            return showLoader(state, action);
        case actionTypes.HIDE_SPINNER:
            return hideLoader(state, action);
        default:
            return state;
    }
};

export default reducer;
