// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component, Fragment } from "react";
import QuestionnaireLayout from "../../../components/Common/QuestionnaireLayout";
import { SCQBox } from "../../../components/Common/QuestionnaireWidgets";
import Hr from "../../../components/UI/Hr";
import Button from "../../../components/UI/Button";
import { setDynamicRouteConfiguration } from "../../../redux/actions/navbar";
import { connect } from "react-redux";
import { api } from "../../../APIRequests";
import { getScreeningAnswers, transformScreeningQuestionsArray } from "../Providers/providerAPI";
import { providerRoute } from "../../../config/roles";
import { getQueryParams } from "../../../utils/filters";

class GAD7 extends Component {
    questionnaireHeading = "Generalized Anxiety Disorder(GAD-7) scale";

    state = {
        questionnaireList: [],
        /** ---------------------
         * Enabling submit button if option in every question is selected
         *
         * @See updateSubmitBtnDisableState
         */
        submitDisabled: true,
        /**
         *
         * ----------------------*/
        patientId: this.props.match.params.patientId,
        patientName: "",
    };

    /**
     * `optionConf` is the configuration passed
     * as the prop to `SCQBox` component
     */
    optionConf = [
        { value: 0, caption: "Not at all" },
        { value: 1, caption: "Several days" },
        { value: 2, caption: "More than half the days" },
        { value: 3, caption: "Nearly every day" },
    ];
    optionsArr = [0, 1, 2, 3];

    /**
     * Checks for enter key, and if submit button is enabled then submits form on Enter key
     */
    documentKeydownHandler = (e) => {
        if (e.keyCode == 13) {
            const submitBtn = this.SubmitBtnWprRef.current.querySelector(".js-GAD7__SubmitBtn");
            if (submitBtn && !submitBtn.disabled) {
                submitBtn.click(); // submits the form
            }
        }
    };

    componentDidMount() {
        if (this.state.patientId && this.state.patientName) {
            getScreeningAnswers("gad", this.props, this.state, this.getGAD7Questions);
        } else {
            this.getGAD7Questions();
        }

        const dynamicRouteConfig = {};
        dynamicRouteConfig["menuSection"] = { icon: "./icons/back-arrow.svg", link: "/" };
        dynamicRouteConfig["logoSection"] = { title: "GAD-7" };
        this.props.setDynamicRouteConfiguration(dynamicRouteConfig);

        //setup for submit on enter
        document.addEventListener("keydown", this.documentKeydownHandler);
        this.SubmitBtnWprRef = React.createRef(null);
    }
    componentWillUnmount() {
        this.props.setDynamicRouteConfiguration({});

        document.removeEventListener("keydown", this.documentKeydownHandler);
    }

    /**
     * API call to fetch questionnaire list
     */
    getGAD7Questions = async (answers) => {
        try {
            api.patient
                .fetch_GAD7_questions()
                .then((response) => {
                    let questionsArray = response.question;
                    this.setState({
                        questionnaireList: transformScreeningQuestionsArray(
                            questionsArray,
                            answers,
                        ),
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * Handles the `SCQBox` component's `onOptionSelect` event.
     * `value` and the `questionId` are passed down from the `SCQBox` widget
     *
     * @param {(string|number)} value Value selected. Can be a string or number.
     * @param {(string|number)} questionId Id of question passed down.
     */
    optionSelectHandler = (value, questionId) => {
        /** Finding index of the question with selected `question_id` in questionnaireList in `state` */
        let foundIndex = this.state.questionnaireList.findIndex((item) => {
            return item.question_id == questionId;
        });

        let staleQuestionsForUpdate = [...this.state.questionnaireList];
        staleQuestionsForUpdate[foundIndex] = {
            ...staleQuestionsForUpdate[foundIndex],
            answer: value,
        };
        this.setState(
            {
                questionnaireList: staleQuestionsForUpdate,
            },
            function () {
                this.updateSubmitBtnDisableState();
            },
        );
    };

    /**
     * Changes the submit button state from disabled to enabled
     * once all question options are selected.
     * The button's `disabled` attribute is bound to the `this.state.submitDisabled` property.
     */
    updateSubmitBtnDisableState = () => {
        let isAnyValueNull = this.state.questionnaireList.some((qtn) => {
            return qtn.answer === null;
        });
        if (isAnyValueNull === false) {
            this.setState({
                submitDisabled: false,
            });
        }
    };

    submitHandler = async () => {
        /** Cleaning the final form data for submit from unwanted keys in Object */
        let cleanedQuestionnaire = this.cleanRequestDataBeforeSubmit();
        let data = { screening: cleanedQuestionnaire };
        try {
            let response = await api.patient.submit_GAD7_questions({ data });
            /** On successful form submit route the user back to home */
            this.routeUserBackToHome();
        } catch (err) {
            console.log(err);
        }
    };

    cancelHandler = () => {
        if (!this.state.patientId) {
            /** Returning back the user to home on cancel */
            this.routeUserBackToHome();
        } else {
            this.props.history.push(`/app/patient/${this.state.patientId}/profile`);
        }
    };

    /** Returning back the user to home page */
    routeUserBackToHome = () => {
        this.props.history.replace("/app/home");
    };

    cleanRequestDataBeforeSubmit = () => {
        let cleanData = this.state.questionnaireList.map((qtn) => {
            return {
                question_id: qtn.question_id,
                rating: qtn.answer,
            };
        });

        return cleanData;
    };

    render() {
        let hasQuestions = this.state.questionnaireList.length > 0 ? true : false;
        let { submitDisabled } = this.state;

        return (
            hasQuestions && (
                <Fragment>
                    <QuestionnaireLayout
                        heading={this.questionnaireHeading}
                        headerRightContent={this.state.patientName && this.state.patientName}>
                        <div className="mt-4 pb-3 px-lg-3">
                            {/*//TODO Why is this disabled if no patientName? Can we get here without patientName?*/}

                            <fieldset disabled={this.state.patientName ? "disabled" : ""}>
                                {this.state.questionnaireList.map((q) => {
                                    return (
                                        <SCQBox
                                            OptionsArr={this.optionsArr}
                                            answer={q.answer}
                                            onOptionSelect={this.optionSelectHandler}
                                            key={q.question_id}
                                            optionConf={this.optionConf}
                                            questionStatement={q.question}
                                            id={q.question_id}
                                        />
                                    );
                                })}
                            </fieldset>
                        </div>
                        {/* submit controls desktop version */}
                        <div className="px-lg-3 my-1 d-none d-lg-block">
                            <Hr />
                            <div className="py-2">
                                <div
                                    ref={this.SubmitBtnWprRef}
                                    className="d-flex py-4 justify-content-end">
                                    <Button
                                        onClick={this.cancelHandler}
                                        className="Btn Btn--otl-pri Btn-sm mr-3">
                                        Cancel
                                    </Button>
                                    {/* button's `disabled` state is bound to the boolean `this.state.submitDisabled` */}
                                    <Button
                                        disabled={submitDisabled}
                                        onClick={this.submitHandler}
                                        className="js-GAD7__SubmitBtn Btn Btn--pri Btn-sm">
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </QuestionnaireLayout>
                    {/* submit controls mobile version */}
                    <div className="container px-lg-3 py-4 d-lg-none">
                        <div className="d-flex py-2 justify-content-center">
                            <Button
                                onClick={this.cancelHandler}
                                className="Btn mr-3 Btn--otl-pri Btn-sm w-50">
                                Cancel
                            </Button>
                            {/* button's `disabled` state is bound to the boolean `this.state.submitDisabled` */}
                            <Button
                                disabled={submitDisabled}
                                onClick={this.submitHandler}
                                className="Btn Btn--pri Btn-sm w-50">
                                Submit
                            </Button>
                        </div>
                    </div>
                </Fragment>
            )
        );
    }
}

export default connect(null, { setDynamicRouteConfiguration })(GAD7);
