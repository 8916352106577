// Copyright aptihealth, inc. 2019 All Rights Reserved

export const INITIAL_CLINICIAN_NOTES_SCHEMA = [
    {
        name: "difficulties_description",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "1. Please describe your difficulties:",
            placeholder: "Please describe your difficulties",
        },
    },
    {
        name: "difficulties_start",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "2. When did these difficulties begin?",
            placeholder: "When did these difficulties begin?",
        },
    },
    {
        name: "difficulties_causation",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "3. How did these difficulties begin or what caused these difficulties?",
            placeholder: "How did these difficulties begin or what caused these difficulties?",
        },
    },
    {
        name: "difficulties_change",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "4. How have these difficulties changed over time?",
            placeholder: "How have these difficulties changed over time?",
        },
    },
    {
        name: "difficulties_impact",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "5. How have these difficulties impact in your life?",
            placeholder: "How have these difficulties impact in your life?",
        },
    },
    {
        name: "difficulties_affect_others",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "6. How have these difficulties affected others?",
            placeholder: "How have these difficulties affected others?",
        },
    },
    {
        name: "past_solutions",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "7. Have you tried to solve these difficulties (e.g. Past solutions, treatment, etc.)?",
            placeholder:
                "Have you tried to solve these difficulties (e.g. Past solutions, treatment, etc.)?",
        },
    },
    {
        name: "past_solutions_success",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "8. Which past solutions have worked and why?",
            placeholder: "Which past solutions have worked and why?",
        },
    },
    {
        name: "past_solutions_fail",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "9. Which past solutions did not work and why?",
            placeholder: "Which past solutions did not work and why?",
        },
    },
    {
        name: "motivation_to_solve",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "10. Are you motivated to solve these problems now?",
            placeholder: "Are you motivated to solve these problems now?",
        },
    },
    {
        name: "solution_requirements",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "11. What do you need to solve these problems?",
            placeholder: "What do you need to solve these problems?",
        },
    },
    {
        name: "post_success_changes",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "12. If we were successful, what changes would you make or how would your life be different?",
            placeholder:
                "If we were successful, what changes would you make or how would your life be different?",
        },
    },
    {
        name: "current_medications",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "13. Current medications?",
            placeholder: "Current medications?",
        },
    },
    {
        name: "medication_duration",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "14. How long have you been taking these medications?",
            placeholder: "How long have you been taking these medications?",
        },
    },
    {
        name: "medication_success",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "15. Have these medications been helpful?",
            placeholder: "Have these medications been helpful?",
        },
    },
    {
        name: "medication_side_effects",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "16. Any side effects of current medications?",
            placeholder: "Any side effects of current medications?",
        },
    },
    {
        name: "past_medications",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "17. What medications have you taken in the past?",
            placeholder: "What medications have you taken in the past?",
        },
    },
    {
        name: "current_past_comparison",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "18. How do you feel about current medications compared to past medications?",
            placeholder: "How do you feel about current medications compared to past medications?",
        },
    },
    {
        name: "current_no_comparison",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "19. How do you feel about current medications compared to no medications?",
            placeholder: "How do you feel about current medications compared to no medications?",
        },
    },
    {
        name: "additional_info",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "20. Additional Information",
            placeholder: "Additional Information",
        },
    },
];

export const OUTCOME_CLINICIAN_NOTES_SCHEMA = [
    {
        name: "difficulties_description",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "1. Please describe your difficulties today.",
            placeholder: "Please describe your difficulties today.",
        },
    },
    {
        name: "difficulties_change",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "2. How have your difficulties changed from 3 months ago?",
            placeholder: "How have your difficulties changed from 3 months ago?",
        },
    },
    {
        name: "difficulties_impact",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "3. What is the impact of your current difficulties?",
            placeholder: "What is the impact of your current difficulties?",
        },
    },
    {
        name: "difficulties_cope",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "4. How do you cope with these difficulties today versus 3 months ago?",
            placeholder: "How do you cope with these difficulties today versus 3 months ago?",
        },
    },
    {
        name: "solution_change",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "5. What improvements have you made in the last 3 months?",
            placeholder: "What improvements have you made in the last 3 months?",
        },
    },
    {
        name: "solution_how",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "6. How have you achieved these improvements?",
            placeholder: "How have you achieved these improvements?",
        },
    },
    {
        name: "solution_affect_others",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "7. How do these improvements impact your life and relationships?",
            placeholder: "How do these improvements impact your life and relationships?",
        },
    },
    {
        name: "motivation_to_solve",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "8. How motivated are you to continue improving your life?",
            placeholder: "How motivated are you to continue improving your life?",
        },
    },
    {
        name: "solution_support",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "9. What support do you need to continue to improve your life?",
            placeholder: "What support do you need to continue to improve your life?",
        },
    },
    {
        name: "solution_continue",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "10. How will your life be different if you continue to improve?",
            placeholder: "How will your life be different if you continue to improve?",
        },
    },
    {
        name: "current_medications",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "11. If applicable, what are your current medications?",
            placeholder: "If applicable, what are your current medications?",
        },
    },
    {
        name: "medication_success",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "12. Are these medications helpful?",
            placeholder: "Are these medications helpful?",
        },
    },
    {
        name: "medication_side_effects",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "13. Any side effects of current medications?",
            placeholder: "Any side effects of current medications?",
        },
    },
    {
        name: "current_past_comparison",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "14. How do current medications compare to past medications so do they compare or no medications?",
            placeholder:
                "How do current medications compare to past medications so do they compare or no medications?",
        },
    },
    {
        name: "medication_future",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "15. What do you what do you think your medications will look like in the future?",
            placeholder:
                "What do you what do you think your medications will look like in the future?",
        },
    },
    {
        name: "additional_info",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "16. Additional Information",
            placeholder: "Additional Information",
        },
    },
];

export const MENTAL_STATUS_QUESTIONS_SCHEMA = [
    {
        name: "body_type",
        elementType: "radio",
        elementConfig: {
            label: "1. Body Type",
            options: [
                { value: "thin", display: "Thin" },
                { value: "average", display: "Average" },
                { value: "overweight", display: "Overweight" },
                { value: "obese", display: "Obese" },
            ],
        },
    },
    {
        name: "height",
        elementType: "radio",
        elementConfig: {
            label: "2. Height",
            options: [
                { value: "short", display: "Short" },
                { value: "average", display: "Average" },
                { value: "tall", display: "Tall" },
                { value: "very_tall", display: "Very Tall" },
            ],
        },
    },
    {
        name: "consciousness",
        elementType: "radio",
        elementConfig: {
            label: "3. Consciousness",
            options: [
                { value: "alert", display: "Alert" },
                { value: "sleepy", display: "Sleepy" },
                { value: "delirious", display: "Delirious" },
                { value: "in_a_stupor", display: "In a stupor" },
            ],
        },
    },
    {
        name: "dress",
        elementType: "radio",
        elementConfig: {
            label: "4. Dress",
            options: [
                { value: "inappropriately", display: "Inappropriately" },
                { value: "poorly", display: "Poorly" },
                { value: "casually", display: "Casually" },
                { value: "well", display: "Well-dressed" },
            ],
        },
    },
    {
        name: "hygiene",
        elementType: "radio",
        elementConfig: {
            label: "5. Hygiene",
            options: [
                { value: "disheveled", display: "Disheveled" },
                { value: "adequately_groomed", display: "Adequately groomed" },
                { value: "well_groomed", display: "Well groomed" },
            ],
        },
    },
    {
        name: "appearance_1",
        elementType: "radio",
        elementConfig: {
            label: "6. Appearance",
            options: [
                { value: "stated_age", display: "Stated Age" },
                { value: "younger", display: "Younger" },
                { value: "older", display: "Older" },
            ],
        },
    },
    {
        name: "appearance_2",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
            label: "7. Appearance",
            options: [
                { value: "athletic", display: "Athletic" },
                { value: "healthy", display: "Healthy" },
                { value: "sickly", display: "Sickly" },
                { value: "frail", display: "Frail" },
            ],
        },
    },
    {
        name: "speech_volume",
        elementType: "radio",
        elementConfig: {
            label: "8. Speech Volume",
            options: [
                { value: "whisper", display: "Whisper" },
                { value: "soft", display: "Soft" },
                { value: "normal", display: "Normal" },
                { value: "loud", display: "Loud" },
            ],
        },
    },
    {
        name: "speech_flow",
        elementType: "radio",
        elementConfig: {
            label: "9. Speech Flow",
            options: [
                { value: "slow_delayed", display: "Slow / Delayed" },
                { value: "hesitant", display: "Hesitant" },
                { value: "normal_spontaneous", display: "Normal / Spontaneous" },
                { value: "talkative", display: "Talkative" },
            ],
        },
    },
    {
        name: "speech_qualities",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
            label: "10. Speech Qualities",
            options: [
                { value: "mumbling", display: "Mumbling" },
                { value: "slurring", display: "Slurring" },
                { value: "perseveration", display: "Perseveration" },
                { value: "stuttering", display: "Stuttering" },
                { value: "accent", display: "Accent" },
                { value: "lisp", display: "Lisp" },
                { value: "monotone", display: "Monotone" },
                { value: "none", display: "None of the above" },
            ],
        },
    },
    {
        name: "speech_content",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
            label: "11. Speech Content",
            options: [
                { value: "word_salad", display: "Word Salad" },
                { value: "clanging", display: "Clanging" },
                { value: "punning", display: "Punning" },
                { value: "neologism", display: "Neologism" },
                { value: "none", display: "None of the above" },
            ],
        },
    },
    {
        name: "eye_contact",
        elementType: "radio",
        elementConfig: {
            label: "12. Eye Contact",
            options: [
                { value: "avoidant", display: "Avoidant" },
                { value: "intermittent", display: "Intermittent" },
                { value: "almost_constant", display: "Alomost constant" },
                { value: "appropriate", display: "Appropriate eye contact" },
            ],
        },
    },
    {
        name: "behavior_activity",
        elementType: "radio",
        elementConfig: {
            label: "13. Behavior Activity",
            options: [
                { value: "slow", display: "Slow" },
                { value: "calm", display: "Calm" },
                { value: "tense", display: "Tense" },
                { value: "agitated", display: "Agitated" },
            ],
        },
    },
    {
        name: "behavior_purpose",
        elementType: "radio",
        elementConfig: {
            label: "14. Behavior Purpose",
            options: [
                { value: "bizarre", display: "Bizarre" },
                { value: "aimless", display: "Aimless" },
                { value: "purposeful", display: "Purposeful" },
            ],
        },
    },
    {
        name: "behavioral_qualities",
        elementType: "input",
        elementConfig: {
            label: "15. Behavioral Qualities",
            type: "checkbox",
            options: [
                { value: "tics", display: "Tics" },
                { value: "twitches", display: "Twitches" },
                { value: "stereotypic_motions", display: "Stereotypic Motions" },
                { value: "none", display: "None of the above" },
            ],
        },
    },
    {
        name: "relationship_to_interviewer",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
            label: "16. Relationship to Interviewer",
            options: [
                { value: "threatening", display: "Threatening" },
                { value: "argumentative", display: "Argumentative" },
                { value: "defensive", display: "Defensive" },
                { value: "evasive", display: "Evasive" },
                { value: "apathetic", display: "Apathetic" },
                { value: "cooperative", display: "Cooperative" },
                { value: "friendly", display: "Friendly" },
                { value: "interested", display: "Interested" },
                { value: "ingratiating", display: "Ingratiating" },
                { value: "seductive", display: "Seductive" },
            ],
        },
    },
    {
        name: "level_of_rapport",
        elementType: "radio",
        elementConfig: {
            label: "17. Level of Rapport",
            options: [
                { value: "minimal", display: "Minimal" },
                { value: "adequate", display: "Adequate" },
                { value: "good", display: "Good" },
            ],
        },
    },
    {
        name: "thought_process",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
            label: "18. Thought Process",
            options: [
                { value: "thought_blocking", display: "Thought Blocking" },
                { value: "limited", display: "Limited (poverty)" },
                { value: "loose", display: "Loose" },
                { value: "circumstantial", display: "Circumstantial" },
                { value: "tangential", display: "Tangential" },
                { value: "flight_of_ideas", display: "Flight of ideas" },
                { value: "logical", display: "Logical" },
            ],
        },
    },
    {
        name: "quality_of_thoughts",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
            label: "19. Quality of Thoughts",
            options: [
                { value: "paranoid", display: "Paranoid" },
                { value: "fearful", display: "Fearful" },
                { value: "suspicious", display: "Suspicious" },
                { value: "grandiose", display: "Grandiose" },
                { value: "obsessional", display: "Obsessional" },
                { value: "hypochondriacal", display: "Hypochondriacal" },
                { value: "negativistic", display: "Negativistic" },
                { value: "depressed", display: "Depressed" },
                { value: "magical", display: "Magical" },
                { value: "appropriate", display: "Appropriate" },
            ],
        },
    },
    {
        name: "hallucinations",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
            label: "20. Hallucinations",
            options: [
                { value: "no_hallucinations", display: "No hallucinations" },
                { value: "auditory", display: "Auditory" },
                { value: "command", display: "Command" },
                { value: "visual", display: "Visual" },
                { value: "tactile", display: "Tactile" },
                { value: "olfactory", display: "Olfactory" },
                { value: "gustatory", display: "Gustatory" },
            ],
        },
    },
    {
        name: "delusions",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
            label: "21. Delusions",
            options: [
                { value: "no_delusions", display: "No delusions" },
                { value: "grandeur", display: "Grandeur" },
                { value: "persecution", display: "Persecution" },
                { value: "somatic", display: "Somatic" },
                { value: "jealousy", display: "Jealousy" },
                { value: "love", display: "Love" },
                { value: "guilt", display: "Guilt" },
                { value: "ideas_of_reference", display: "Ideas of reference" },
                { value: "ideas_of_influence", display: "Ideas of influence" },
            ],
        },
    },
    {
        name: "perceptual_distortions",
        elementType: "input",
        elementConfig: {
            label: "22. Perceptual Distortions",
            type: "checkbox",
            options: [
                { value: "depersonalization", display: "Depersonalization" },
                { value: "derealization", display: "Derealization" },
                { value: "disassociation", display: "Disassociation" },
                { value: "none", display: "None of the above" },
            ],
        },
    },
    {
        name: "affect1",
        elementType: "input",
        elementConfig: {
            label: "23. Affect",
            type: "checkbox",
            options: [
                { value: "flat", display: "Flat" },
                { value: "blunted", display: "Blunted" },
                { value: "restricted", display: "Restricted" },
                { value: "broad", display: "Broad" },
            ],
        },
    },
    {
        name: "affect2",
        elementType: "radio",
        elementConfig: {
            label: "24. Affect",
            options: [
                { value: "appropriate", display: "Appropriate" },
                { value: "inappropriate", display: "Inappropriate" },
            ],
        },
    },
    {
        name: "mood",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
            label: "25. Mood",
            options: [
                { value: "expansive", display: "Expansive" },
                { value: "euphoric", display: "Euphoric" },
                { value: "euthymic", display: "Euthymic" },
                { value: "dysphoric", display: "Dysphoric" },
                { value: "irritable", display: "Irritable" },
                { value: "anxious", display: "Anxious" },
                { value: "guilty", display: "Guilty" },
                { value: "grief_stricken", display: "Grief-stricken" },
            ],
        },
    },
    {
        name: "orientation",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
            label: "26. Orientation",
            options: [
                { value: "not_oriented_to_person", display: "Not oriented to person" },
                { value: "not_oriented_to_place", display: "Not oriented to place" },
                { value: "not_oriented_to_time", display: "Not oriented to time" },
                { value: "all", display: "Oriented X 3" },
            ],
        },
    },
    {
        name: "level_of_intelligence1",
        elementType: "radio",
        elementConfig: {
            label: "27. Level of Intelligence",
            options: [
                { value: "retarded", display: "Retarded" },
                { value: "below_average", display: "Below average" },
                { value: "average", display: "Average" },
                { value: "above_average", display: "Above average" },
            ],
        },
    },
    {
        name: "level_of_intelligence2",
        elementType: "radio",
        elementConfig: {
            label: "28. Level of Intelligence",
            options: [
                { value: "with_abstract_thinking", display: "With abstract thinking" },
                { value: "without_abstract_thinking", display: "Without abstract thinking" },
            ],
        },
    },
    {
        name: "concentration",
        elementType: "radio",
        elementConfig: {
            label: "29. Concentration",
            options: [
                { value: "intact", display: "Intact" },
                { value: "mildly_impaired", display: "Mildly impaired" },
                { value: "moderately_impaired", display: "Moderately impaired" },
                { value: "severely_impaired", display: "Severely impaired" },
            ],
        },
    },
    {
        name: "memory",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
            label: "30. Memory",
            options: [
                { value: "no_problems", display: "No memory problems" },
                { value: "immediate", display: "Immediate" },
                { value: "short_term", display: "Short-term" },
                { value: "long_term", display: "Long-term" },
            ],
        },
    },
    {
        name: "impulse_control",
        elementType: "radio",
        elementConfig: {
            label: "31. Impulse Control",
            options: [
                { value: "good", display: "Good" },
                { value: "fair", display: "Fair" },
                { value: "poor", display: "Poor" },
                { value: "impaired", display: "Impaired" },
            ],
        },
    },
    {
        name: "judgement",
        elementType: "radio",
        elementConfig: {
            label: "32. Judgement",
            options: [
                { value: "good", display: "Good" },
                { value: "fair", display: "Fair" },
                { value: "poor", display: "Poor" },
                { value: "impaired", display: "Impaired" },
            ],
        },
    },
    {
        name: "insight",
        elementType: "radio",
        elementConfig: {
            label: "33. Insight",
            options: [
                { value: "good", display: "Good" },
                { value: "fair", display: "Fair" },
                { value: "poor", display: "Poor" },
                { value: "impaired", display: "Impaired" },
            ],
        },
    },
    {
        name: "homicidal_risk",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
            label: "34. Homicidal Risk",
            options: [
                { value: "ideation", display: "Ideation" },
                { value: "plans_or_means", display: "Plans or means" },
                { value: "intent", display: "Intent" },
                { value: "recency_or_lethality", display: "Recency or lethality" },
                { value: "history_of_violence", display: "History of violence" },
                { value: "situational_specificity", display: "Situational specificity" },
                { value: "impulsivity", display: "Impulsivity" },
                { value: "none", display: "None of the above" },
            ],
        },
    },
    {
        name: "suicidal_risk",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
            label: "35. Suicidal Risk",
            options: [
                { value: "ideation", display: "Ideation" },
                { value: "plans_or_means", display: "Plans or means" },
                { value: "intent", display: "Intent" },
                { value: "recency_or_lethality", display: "Recency or lethality" },
                { value: "history_of_violence", display: "History of violence" },
                { value: "situational_specificity", display: "Situational specificity" },
                { value: "impulsivity", display: "Impulsivity" },
                { value: "none", display: "None of the above" },
            ],
        },
    },
    {
        name: "special_needs",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
            label: "36. Special Needs",
            options: [
                { value: "no_special_needs", display: "No special needs" },
                { value: "ambulation", display: "Ambulation" },
                { value: "vision", display: "Vision" },
                { value: "hearing", display: "Hearing" },
                { value: "reading", display: "Reading" },
                { value: "writing", display: "Writing" },
                { value: "diet", display: "Diet" },
                { value: "language_or_communication", display: "Language or communication" },
                { value: "other", display: "Other health factors" },
                { value: "none", display: "None of the above" },
            ],
        },
    },
];

export const INITIAL_CLINICAL_DIAGNOSIS_SCHEMA = [
    {
        name: "overall_assessment_impressions",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "Overall Assessment Impressions",
            placeholder: "Overall Assessment Impressions",
        },
    },
    {
        name: "diagnostic_impressions",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "Diagnostic Impressions",
            placeholder: "Diagnostic Impressions",
        },
    },
    {
        name: "recommendations",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "Recommendations",
            placeholder: "Recommendations",
        },
    },
    {
        name: "enroll",
        elementType: "select",
        elementConfig: {
            sentenceCase: false,
            options: [
                { value: "90_day_care", display: "90 Day Care" },
                { value: "no", display: "No" },
                { value: "cdphp_spmi_contract", display: "CDPHP SPMI Contract" },
                { value: "cdphp_medication_only", display: "CDPHP Medication Only" },
            ],
            label: "Assessment Recommendation",
            placeholder: "Assessment Recommendation",
        },
    },
    {
        name: "ICD_10_diagnosis_axis_1",
        elementType: "reactselect",
        elementConfig: {
            options: [],
            label: "ICD-10 Primary Diagnosis",
            placeholder: "",
        },
    },
    {
        name: "ICD_10_diagnosis_axis_2",
        elementType: "reactselect",
        elementConfig: {
            options: [],
            label: "ICD-10 Secondary Diagnosis",
            placeholder: "",
        },
    },
    {
        name: "call_id",
        elementType: "select",
        elementConfig: {
            options: [],
            label: "Link Call",
            placeholder: "",
        },
    },
    {
        name: "additional_diagnostic_activities_placeholder",
        elementType: "checkbox",
        elementConfig: {
            type: "checkbox",
            label: "Diagnostic Review",
            placeholder: "",
        },
    },
];

export const OUTCOME_CLINICAL_DIAGNOSIS_SCHEMA = [
    {
        name: "overall_assessment_impressions",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "Overall Assessment Impressions",
            placeholder: "Overall Assessment Impressions",
        },
    },
    {
        name: "diagnostic_impressions",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "Diagnostic Impressions",
            placeholder: "Diagnostic Impressions",
        },
    },
    {
        name: "recommendations",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "Recommendations",
            placeholder: "Recommendations",
        },
    },
    {
        name: "pcp_consult_outcome",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "Return to PCP care", label: "Return to PCP care" },
                {
                    value: "aptihealth Extended-Care for counseling",
                    label: "aptihealth Extended-Care for counseling",
                },
                {
                    value: "aptihealth Extended-Care for counseling & medication management",
                    label: "aptihealth Extended-Care for counseling & medication management",
                },
            ],
            label: "Post 90-Day Assessment Outcome",
            placeholder: "Post 90-Day Assessment Outcome",
        },
    },
];
