// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as actionTypes from "./actionTypes";
import { uuidv4 } from "../../utils/filters";
import { setExceptionReporting, unsetExceptionReporting } from "./exceptionReporting";

export const toastMessageTypes = {
    success: "success",
    success_v2: "success_v2",
    warning: "warning",
    error: "error",
    error_v2: "error_v2",
};

export const addToast =
    ({
        message,
        messageType,
        hideDismissButton,
        dismissMessage,
        dismissTimeout,
        requestId,
        hideReportButton,
    }) =>
    (dispatch) => {
        const toastId = uuidv4();
        const showExceptionReporting = () => {
            dispatch(
                setExceptionReporting({
                    requestId,
                    dismiss: () => {
                        dispatch(unsetExceptionReporting());
                    },
                }),
            );
        };
        dispatch({
            type: actionTypes.ADD_TOAST,
            payload: {
                message,
                messageType,
                toastId,
                hideDismissButton,
                dismissMessage,
                dismissTimeout,
                requestId,
                hideReportButton,
                onReportButtonClick: showExceptionReporting,
            },
        });
    };

export const removeToast = ({ toastId }) => ({
    type: actionTypes.REMOVE_TOAST,
    payload: {
        toastId,
    },
});

export const clearAllToasts = () => ({
    type: actionTypes.CLEAR_ALL_TOASTS,
});
