// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import CardSecondary from "../../UI/Card/CardSecondary";
import images from "../../../utils/images";
import Button from "../../UI/Button";
import { withRouter } from "react-router-dom";
import { patientRoute } from "../../../config/roles";

const ScheduleSuccess = (props) => {
    const { behavioralSpecialist } = props;

    const appointmentMessage = behavioralSpecialist
        ? "Appointment Scheduled!"
        : "Appointment Rescheduled!";
    const dashboardRouteHandler = () => {
        props.history.push(patientRoute + "/home");
    };
    return (
        <div className="overlay">
            <div className="ScheduleSuccess-wpr">
                <CardSecondary className="mx-lg-auto">
                    <div className="px-5 py-4 text-center">
                        <img
                            src={images("./common/successCheck.svg")}
                            alt="success"
                            className="mb-4"
                        />
                        <p className="ScheduleSuccess__text fs-17 fw-bold">{appointmentMessage}</p>
                        {behavioralSpecialist && (
                            <p className="ScheduleSuccess__text fs-15 mb-4">
                                Your meeting with {props.behavioralSpecialist} has been successfully
                                scheduled.
                            </p>
                        )}
                        <Button className="Btn Btn--pri" onClick={dashboardRouteHandler}>
                            OK
                        </Button>
                    </div>
                </CardSecondary>
            </div>
        </div>
    );
};

export default withRouter(ScheduleSuccess);
