// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import {MemberProfileActivityEntry, ShieldIcon} from "../../../component-library";
import moment from "moment-timezone";
import {ICON_SIZES, ICON_TYPES} from "../../../constants/icons";

const formatCallDate = (call_date, call_time, format) => {
    const localCallDatetime = moment.utc(`${call_date} ${call_time}`).local();
    return localCallDatetime.format(format);
};

export const CallScheduledActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const { call_date, call_time, provider_name, appointment_created_by } = keyContent;

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Dark"
            title="Video Call Scheduled"
            subTitle={`Appointment Scheduled for 
                ${formatCallDate(call_date, call_time, "MM/DD/YYYY \\at\\ hh:mm a")} 
                with ${provider_name}`}
            localDateTime={localCreatedTime}
            iconSrc="icons/call-icon-dark.svg"
            iconAlt="CallScheduledIcon">
            <div className={"fs-14"}>{`Created by ${appointment_created_by}`}</div>
        </MemberProfileActivityEntry>
    );
};

export const CallCompletedActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const { call_date, call_time, provider_name, appointment_created_by } = keyContent;

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Primary"
            title="Video Call Completed"
            subTitle={`Appointment Completed for 
                ${formatCallDate(call_date, call_time, "MM/DD/YYYY \\at\\ hh:mm a")} 
                with ${provider_name}`}
            localDateTime={localCreatedTime}
            iconSrc="icons/call-icon-primary.svg"
            iconAlt="CallCompletedIcon">
            <div className={"fs-14"}>{`Created by ${appointment_created_by}`}</div>
        </MemberProfileActivityEntry>
    );
};

const buildCallCancelledActivitySubTitle = (
    is_auto_cancelled,
    call_date,
    call_time,
    provider_name,
) => {
    if (is_auto_cancelled) {
        return "Video Call Auto-Cancelled";
    }

    return `Appointment Cancelled for 
        ${formatCallDate(call_date, call_time, "MM/DD/YYYY \\at\\ hh:mm a")} 
        with ${provider_name}`;
};

const getCancelledBy = (
    cancellation_reason,
    provider_name,
    patient_name,
    session_cancelled_by,
    provider_id,
    patient_id,
) => {
    if (
        cancellation_reason &&
        cancellation_reason.cancelled_by_user_type &&
        cancellation_reason.cancelled_by_user_type.includes("admin")
    ) {
        return "Admin";
    }
    if (session_cancelled_by === patient_id && patient_name) {
        return patient_name;
    } else if (session_cancelled_by === provider_id && provider_name) {
        return provider_name;
    } else {
        return provider_name;
    }
};

const buildCallCancelledActivityContent = (
    is_auto_cancelled,
    call_date,
    call_time,
    provider_name,
    cancellation_reason,
    patient_name,
    session_cancelled_by,
    provider_id,
    patient_id,
) => {
    const formattedDate = formatCallDate(call_date, call_time, "MM/DD/YYYY at hh:mm a");
    if (is_auto_cancelled) {
        return `Session originally scheduled for ${formattedDate} has been auto-cancelled.`;
    }

    return `Cancelled by ${getCancelledBy(
        cancellation_reason,
        provider_name,
        patient_name,
        session_cancelled_by,
        provider_id,
        patient_id,
    )}`;
};

export const CallCancelledActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const {
        call_date,
        call_time,
        provider_name,
        provider_id,
        patient_name,
        patient_id,
        is_auto_cancelled,
        cancellation_reason,
        session_cancelled_by,
    } = keyContent;

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Danger"
            title="Video Call Cancelled"
            subTitle={buildCallCancelledActivitySubTitle(
                is_auto_cancelled,
                call_date,
                call_time,
                provider_name,
            )}
            localDateTime={localCreatedTime}
            iconSrc="icons/call-icon-danger.svg"
            iconAlt="CallCancelledIcon">
            <div className={"fs-14"}>
                {buildCallCancelledActivityContent(
                    is_auto_cancelled,
                    call_date,
                    call_time,
                    provider_name,
                    cancellation_reason,
                    patient_name,
                    session_cancelled_by,
                    provider_id,
                    patient_id,
                )}
            </div>
        </MemberProfileActivityEntry>
    );
};

export const CallRescheduledActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const { call_date, call_time, provider_name, appointment_created_by } = keyContent;

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Dark"
            title="Video Call Rescheduled"
            subTitle={`Appointment Rescheduled for 
                ${formatCallDate(call_date, call_time, "MM/DD/YYYY \\at\\ hh:mm a")} 
                with ${provider_name}`}
            localDateTime={localCreatedTime}
            iconSrc="icons/call-icon-dark.svg"
            iconAlt="CallRescheduledIcon">
            <div className={"fs-14"}>{`Rescheduled by ${appointment_created_by}`}</div>
        </MemberProfileActivityEntry>
    );
};

export const InstantCallRequestedActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const { call_date, call_time, provider_name, appointment_created_by } = keyContent;

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Warning"
            title="Video Call Requested"
            subTitle={`Call Started for 
                ${formatCallDate(call_date, call_time, "MM/DD/YYYY \\at\\ hh:mm a")} 
                with ${provider_name}`}
            localDateTime={localCreatedTime}
            iconSrc="icons/call-icon-warning.svg"
            iconAlt="CallStartedIcon"
            children={<div className={"fs-14"}>{`Created by ${appointment_created_by}`}</div>}
        />
    );
};

export const PatientNoShowActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const { call_date, call_time, provider_name } = keyContent;

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Danger"
            title="Patient No-Show"
            subTitle={`No-Showed for 
                ${formatCallDate(call_date, call_time, "MM/DD/YYYY hh:mm a")} 
                appointment with ${provider_name}`}
            localDateTime={localCreatedTime}
            iconSrc="icons/call-icon-danger.svg"
            iconAlt="PatientNoShowIcon"
        />
    );
};


export const SchedulingRestrictionActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const { is_scheduling_restricted, updated_by_name } = keyContent;

    const subtitle = is_scheduling_restricted ?
        "Patient Restricted from Scheduling" : "Patient Scheduling Restriction Removed";

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Dark"
            title="Scheduling Restriction Change"
            subTitle={subtitle}
            localDateTime={localCreatedTime}
            iconComponent={
                <ShieldIcon
                    iconType={ICON_TYPES.CIRCLE}
                    iconSize={ICON_SIZES.LARGE}
                    className="apti-MemberProfileActivityEntry__ContentContainer__Icon"
                    style={{fill: "#103E50"}}
                />
            }
        >
            <div className={"fs-14"}>{`Updated by ${updated_by_name}`}</div>
        </MemberProfileActivityEntry>
    );
};
