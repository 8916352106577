// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import { CommentBox } from "../ReportsView";
const XBARReportCommentBox = (props) => {
    const { toggle, submit, ReportComment, handleChange, handleBlur, error } = props;

    return (
        <CommentBox
            cancel={toggle}
            submit={submit}
            value={ReportComment}
            onInputChange={handleChange}
            onInputBlur={handleBlur}
            error={error}
            placeholder="Please enter your comment"
        />
    );
};

export default XBARReportCommentBox;
