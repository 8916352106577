import React from "react";

import { TextTypes, TextColors } from "../../constants";
import { Button } from "../../Button";
import { Text } from "../../Text";
import { clickAnalytics } from "../../../components/Common/InsuranceEligibility/signup_analytics";
import "./styles.scss";

// Relates to: Self Signup

export const VerifyInsuranceDeclined = ({ title, step, insuranceName, insuranceURL, prevStep }) => {
    return (
        <div className="apti-VerifyInsurance">
            <Text type={TextTypes.heading1} color={TextColors.green}>
                {title}
            </Text>
            <Text className="details">
                For questions about your coverage, please contact&nbsp;
                {insuranceURL.length > 0 && (
                    <a
                        className="bold green link"
                        onClick={() => clickAnalytics("self_signup", "declined", "payor")}
                        href={insuranceURL}
                        target="_blank"
                        rel="noreferrer">
                        {" "}
                        {insuranceName}
                    </a>
                )}
                {insuranceURL.length === 0 && (
                    <>
                        <span className="bold green"> {insuranceName}</span>
                    </>
                )}
                .
            </Text>
            <Button
                className={"link"}
                onClick={() => {
                    clickAnalytics("self_signup", "declined", "back");
                    prevStep();
                }}>
                Back to Insurance
            </Button>
        </div>
    );
};

VerifyInsuranceDeclined.defaultProps = {
    title: "",
    insuranceName: "",
    insuranceURL: "",
    prevStep: () => {},
};
