// Copyright aptihealth, inc. 2019 All Rights Reserved

import moment from "moment-timezone";
import React from "react";
import { withRouter } from "react-router-dom";
import { CardHeading } from "../../../components/UI/Headings";
import { getQueryParams } from "../../../utils/filters";
import {
    CRISIS_EVAL_90839,
    CRISIS_EVAL_EXTENDED_90840,
    DIAGNOSTIC_INTERVIEW_90791,
    DIAGNOSTIC_INTERVIEW_90792,
    ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215,
    FAMILY_THERAPY_90847,
    FAMILY_THERAPY_WO_PATIENT_90846,
    MEDICATION_MGMT_99212,
    MEDICATION_MGMT_99213,
    MEDICATION_MGMT_99214,
    OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
    OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
    _30_MIN_INDIVIDUAL_90832,
    _45_MIN_INDIVIDUAL_90834,
    _60_MIN_INDIVIDUAL_90837,
    allottedTimeFactory,
} from "../../Provider/ScheduleAvailability/constants";
import Button from "../../UI/Button";
import Label from "../../UI/Label";
import { Select } from "../../UI/StyledInput";
import HeadingPri from "./HeadingPri";
import TimeSlotOptions from "./TimeSlotOptions";
import TimeslotTile from "./TimeSlotTile";

const TimeSlot = (props) => {
    let slotsCount = null,
        morningTimeSlots = [],
        eveningTimeSlots = [];

    let { conf, timeSlots, onaddingSlot, timezone } = props;
    const { isDisabled, selectedDate, selectedTimeSlot } = conf;
    const today = moment();
    const todaysDate = today.format("YYYY-MM-DD");
    const currentTime = moment
        .utc()
        .tz(timezone)
        .format("HHmm"); /** restricting local time to EST */
    let isToday = selectedDate === todaysDate;

    if (timeSlots && isToday) {
        timeSlots = timeSlots.filter((ts) => {
            const trimmedTs = ts.replace(":", "");
            return trimmedTs > currentTime;
        });
    }

    const cancelSchedule = () => {
        props.history.goBack();
    };

    if (timeSlots && timeSlots.length > 0) {
        slotsCount = timeSlots.length;
        timeSlots.sort().forEach((Slot) => {
            let localTimeSlot = moment(Slot, "HH:mm").format("hh:mm A");
            let hoursStr = localTimeSlot.substring(6, 8);

            if (hoursStr === "AM") {
                morningTimeSlots.push(
                    <TimeSlotOptions
                        active={selectedTimeSlot}
                        TimeOption={localTimeSlot}
                        click={props.click}
                        key={Slot}
                    />,
                );
            } else {
                eveningTimeSlots.push(
                    <TimeSlotOptions
                        active={selectedTimeSlot}
                        TimeOption={localTimeSlot}
                        click={props.click}
                        key={Slot}
                    />,
                );
            }
        });
    }

    let options = [
        {
            value: DIAGNOSTIC_INTERVIEW_90791,
            display: `Diagnostic Interview (90791) (${allottedTimeFactory(
                DIAGNOSTIC_INTERVIEW_90791,
            )} minutes)`,
        },
        {
            value: _30_MIN_INDIVIDUAL_90832,
            display: `30 Minute Individual (90832) (${allottedTimeFactory(
                _30_MIN_INDIVIDUAL_90832,
            )} minutes)`,
        },
        {
            value: _45_MIN_INDIVIDUAL_90834,
            display: `45 Minute Individual (90834) (${allottedTimeFactory(
                _45_MIN_INDIVIDUAL_90834,
            )} minutes)`,
        },
        {
            value: _60_MIN_INDIVIDUAL_90837,
            display: `60 Minute Individual (90837) (${allottedTimeFactory(
                _60_MIN_INDIVIDUAL_90837,
            )} minutes)`,
        },
        {
            value: FAMILY_THERAPY_90847,
            display: `Family Therapy w/ Patient (90847) (${allottedTimeFactory(
                FAMILY_THERAPY_90847,
            )} minutes)`,
        },
        {
            value: FAMILY_THERAPY_WO_PATIENT_90846,
            display: `Family Therapy w/o Patient (90846) (${allottedTimeFactory(
                FAMILY_THERAPY_WO_PATIENT_90846,
            )} minutes)`,
        },
        {
            value: CRISIS_EVAL_90839,
            display: `Crisis Evaluation (90839) (${allottedTimeFactory(
                CRISIS_EVAL_90839,
            )} minutes)`,
        },
        {
            value: CRISIS_EVAL_EXTENDED_90840,
            display: `Crisis Evaluation Extended (90840) (${allottedTimeFactory(
                CRISIS_EVAL_EXTENDED_90840,
            )} minutes)`,
        },
        {
            value: OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
            display: `Office or Other Outpatient Visit w/ New Patient (99204) (${allottedTimeFactory(
                OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
            )} minutes)`,
        },
        {
            value: OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
            display: `Office or Other Outpatient Visit w/ New Patient (99205) (${allottedTimeFactory(
                OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
            )} minutes)`,
        },
    ];
    if (getQueryParams("providerType", props.location.search) === "Prescriber") {
        options = [
            {
                value: DIAGNOSTIC_INTERVIEW_90792,
                display: `Diagnostic Interview (90792) (${allottedTimeFactory(
                    DIAGNOSTIC_INTERVIEW_90792,
                )} minutes)`,
            },
            {
                value: MEDICATION_MGMT_99212,
                display: `Medication Management (99212) (${allottedTimeFactory(
                    MEDICATION_MGMT_99212,
                )} minutes)`,
            },
            {
                value: MEDICATION_MGMT_99213,
                display: `Medication Management (99213) (${allottedTimeFactory(
                    MEDICATION_MGMT_99213,
                )} minutes)`,
            },
            {
                value: MEDICATION_MGMT_99214,
                display: `Medication Management (99214) (${allottedTimeFactory(
                    MEDICATION_MGMT_99214,
                )} minutes)`,
            },
            {
                value: ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215,
                display: `Established Patient Office or Other Outpatient Services (99215) (${allottedTimeFactory(
                    ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215,
                )} minutes)`,
            },
            {
                value: CRISIS_EVAL_90839,
                display: `Crisis Evaluation (90839) (${allottedTimeFactory(
                    CRISIS_EVAL_90839,
                )} minutes)`,
            },
        ];
    }

    let eventTypeStyleChanges = "";
    if (props.eventType) {
        eventTypeStyleChanges = "_events";
    }

    const timeSlotsAvailable = !props.timeSlotsLoading && timeSlots && timeSlots.length > 0;
    const noTimeSlotsAvailable = !props.timeSlotsLoading && !(timeSlots && timeSlots.length > 0);

    const renderTimeSlotGroup = (timeSlots) => {
        if (props.timeSlotsLoading) {
            return <h6 className="mb-3">Loading...</h6>;
        }
        if (timeSlots.length > 0) {
            return timeSlots;
        }
        return <h6 className="mb-3">No available slot</h6>;
    };

    const renderTimeSlotGroups = () => {
        if (noTimeSlotsAvailable) {
            return (
                <div className={"text-center mb-3 mt-3"}>
                    There are no times available for this day.
                    <br />
                    Please select a new date.
                </div>
            );
        }
        return (
            <>
                <HeadingPri text="Morning" />
                {renderTimeSlotGroup(morningTimeSlots)}
                <HeadingPri text="Afternoon" />
                {renderTimeSlotGroup(eveningTimeSlots)}
            </>
        );
    };

    return (
        <div className="Schedule__TimeSlot-wpr mx-0 mx-md-5">
            <div className={`Schedule__TimeSlot${eventTypeStyleChanges}`}>
                <CardHeading
                    className="mt-2 mb-4 d-none d-lg-block fs-18"
                    text="Available session times"
                />
                <TimeslotTile
                    isToday={isToday}
                    dateStr={props.dateStr}
                    slotsCount={slotsCount}
                    timeSlotsLoading={props.timeSlotsLoading}
                />
                {renderTimeSlotGroups()}
            </div>
            {props.eventType && timeSlotsAvailable && (
                <div className="FormGroup">
                    <Label>Select Appointment Type</Label>
                    <Select
                        name="schedule_type"
                        changeHandler={(e) => props.onEventTypeChange(e.target.value)}
                        value={props.eventType}
                        options={options}
                    />
                </div>
            )}
            <div className="Schedule__TimeSlot-Btn-wpr d-flex flex-column flex-xl-row justify-content-xl-between text-center text-xl-left mb-5 mb-xl-0 mx-auto">
                <Button
                    className={`Schedule__TimeSlot-Btn${eventTypeStyleChanges} Btn Btn-sm Btn--pri `}
                    disabled={isDisabled}
                    onClick={onaddingSlot}>
                    Confirm
                </Button>
                <Button
                    className={`Schedule__TimeSlot-Btn${eventTypeStyleChanges} Btn Btn-sm Btn--otl-pri `}
                    onClick={cancelSchedule}>
                    Cancel
                </Button>
            </div>
        </div>
    );
};

export default withRouter(TimeSlot);
