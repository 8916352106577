import React, { useEffect, useState } from "react";
import { api } from "../../../APIRequests";
import { Form, Formik } from "formik";
import FormikInputFormat from "../../UI/formikMobileInput";
import FormikInput from "../../UI/FormikInput";
import Button from "../../UI/Button";
import * as yup from "yup";
import { dobValidationSchemaFactory } from "../../../utils/yupSchemaUtils";
import { STATES } from "../../../constants/states";
import moment from "moment";
import { connect } from "react-redux";
import { showSuccessfulSubmission } from "../../../redux/actions/auth";
import { CustomForm } from "../../../component-library/CustomForm";

const GetNotified = (props) => {
    const [carriers, setCarriers] = useState([]);

    const getNotifiedSchema = [
        {
            name: "insurance_carrier",
            elementType: "select",
            elementConfig: {
                options: carriers,
                label: "Child’s Insurance Carrier",
                placeholder: "Select Child’s Insurance Carrier",
            },
        },
        {
            name: "state",
            elementType: "select",
            elementConfig: {
                options: STATES,
                label: "State",
                placeholder: "Select State",
            },
        },
    ];

    useEffect(() => {
        (async () => {
            api.patient
                .fetch_insurance_list({ params: { showLoader: false } })
                .then((cleanResponse) => {
                    let carriersOptions = cleanResponse.carriers.map((carriers) => {
                        return { value: carriers, display: carriers };
                    });
                    setCarriers(carriersOptions);
                });
        })();
    }, []);

    const submitHandler = async (formData) => {
        const data = {
            content: {
                dob: moment(formData.dob, "MMDDYYYY").format("MM/DD/YYYY"),
                email: formData.email,
                insurance_carrier: formData.insurance_carrier,
                state: formData.state,
            },
            event_type: "CHILD_PROGRAM_EXPANSION",
        };
        await api.shared.create_event_notification({ data });
        props.showSuccessfulSubmission();
        window.localStorage.removeItem("redirectAfterAuth");
        props.history.push("/app/home");
    };

    const renderForm = (formikProps) => {
        const formikInputProps = {
            errors: formikProps.errors,
            touched: formikProps.touched,
        };

        return (
            <Form>
                <div className="mx-auto px-0 mb-3">
                    <div className="container px-0">
                        <div className="get-notified-input">
                            <FormikInput
                                {...formikInputProps}
                                key={"email"}
                                formEl={{
                                    name: "email",
                                    elementType: "input",
                                    elementConfig: {
                                        type: "email",
                                        label: "Your Email Address",
                                        placeholder: "Enter Your Email Address",
                                    },
                                }}
                            />
                        </div>
                        <div className="get-notified-input">
                            <FormikInputFormat
                                {...formikInputProps}
                                key={"dob"}
                                formEl={{
                                    name: "dob",
                                    elementType: "input",
                                    elementConfig: {
                                        type: "text",
                                        label: "Child's Date of Birth",
                                        placeholder: "MM/DD/YYYY",
                                    },
                                }}
                                value={formikProps && formikProps.values["dob"]}
                                onChange={async (val) => {
                                    await formikProps.setFieldValue("dob", val.value);
                                    formikProps.setFieldTouched("dob");
                                }}
                            />
                        </div>
                        {getNotifiedSchema.map((formEl) => {
                            return (
                                <div className="get-notified-input">
                                    <FormikInput
                                        key={formEl.name}
                                        errors={formikProps.errors}
                                        touched={formikProps.touched}
                                        formEl={formEl}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="d-flex justify-content-center pb-5">
                    <Button type="submit" className="Btn Btn--sec self-signup-btn">
                        Submit
                    </Button>
                </div>
            </Form>
        );
    };

    return (
        <div className={"get-notified"}>
            <div className={"fs-20 get-notified-title fw-bold text-center"}>
                Thank you for your interest in aptihealth’s Teens + Kids Care Program. Please
                provide your contact information below to be notified when your child becomes
                eligible.
            </div>
            <CustomForm
                validationSchema={getNotifiedValidation}
                onSubmit={submitHandler}
                render={renderForm}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps, { showSuccessfulSubmission })(GetNotified);

const getNotifiedValidation = yup.object().shape({
    email: yup.string().email("Enter a valid email address").required("Email is required"),
    dob: dobValidationSchemaFactory("MMDDYYYY").required("Date of birth is required"),
    insurance_carrier: yup.string().required("Insurance Carrier is required"),
    state: yup.string().required("State is required"),
});
