// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { useEffect, useState } from "react";
import "./styles.scss";
import images from "../../../utils/images";
import moment from "moment-timezone";
import { AllowAVStep } from "./Steps/AllowAVStep";
import { PreviewStep } from "./Steps/PreviewStep";
import { PatientInRoomStep } from "./Steps/PatientInRoomStep";
import Toolbar from "../../Navigation/Toolbar";
import { baseRoute } from "../../../config/roles";
import { isAuthorized } from "../../../redux/actions/auth";
import ProviderVideoWorkflow from "./Steps/ProviderVideoWorkflowStep";
import QuestionsStep from "./Steps/QuestionsStep";
import GuidedCallProgress from "./GuidedCallProgress";
import { durationToString } from "../../../utils/momentUtils";
import GuidedCallActionIcons from "./GuidedCallActionIcons";
import { withRouter } from "react-router-dom";
import GuidedVideoSection from "./GuidedVideoCallSection";
import ReconnectStep from "./Steps/ReconnectStep";
import PostSessionSurveyWrapper from "../../../containers/Dashboard/Patient/PostSessionSurveyWrapper";
import ProviderMessageStep from "./Steps/ProviderMessageStep";

export const GuidedVideoCall = (props) => {
    const {
        step,
        callDetails,

        localTracks,
        localMedia,
        remoteTracks,
        remoteMedia,

        setStep,
        startCall,
        endCall,

        allottedTime,
        startTime,
        otherParticipantName,
        participantDisconnected,
        redirectToMessageProviderDialog,
    } = props;

    let [audioOff, setAudioOff] = useState(false);
    let [videoOff, setVideoOff] = useState(false);
    let [remainingTimeLeft, setRemainingTimeLeft] = useState();
    let [timer, setTimer] = useState("00:00");

    const toggleTrackType = (trackType) => {
        const track = localTracks.find((track) => track.kind === trackType);

        if (track.isEnabled) {
            track.disable();
        } else {
            track.enable();
        }

        if (trackType === "audio") {
            setAudioOff(!track.isEnabled);
        } else {
            setVideoOff(!track.isEnabled);
        }

        //toggleFun(!track.isEnabled)
    };

    let toggleAudioBtn = (
        <button className="GuidedVideoCall__controls-btn">
            <img
                className="mx-3 d-block cursor"
                onClick={() => toggleTrackType("audio")}
                src={audioOff ? images("./icons/audio_off.svg") : images("./common/mic.svg")}
                alt="audioToggle"
            />
        </button>
    );

    let toggleVideoBtn = (
        <button className="GuidedVideoCall__controls-btn">
            <img
                className="mx-3 d-block cursor"
                onClick={() => toggleTrackType("video")}
                src={videoOff ? images("./icons/video_off.svg") : images("./common/video.svg")}
                alt="videoToggle"
            />
        </button>
    );

    useEffect(() => {
        const timerInterval = setInterval(() => {
            let current = moment();
            if (current.isAfter(startTime)) {
                let diff = moment.duration(current.diff(startTime));
                let timeString = durationToString(diff);
                setTimer(timeString);
            }
        }, 1000);

        return () => {
            clearInterval(timerInterval);
        };
    });

    if (isAuthorized("user:patient")) {
        switch (step) {
            case "ALLOW_AV": {
                return <AllowAVStep {...props} />;
            }

            case "PREVIEW": {
                return (
                    <PreviewStep
                        {...props}
                        toggleAudioBtn={toggleAudioBtn}
                        setAudioOff={setAudioOff}
                        setVideoOff={setVideoOff}
                        toggleVideoBtn={toggleVideoBtn}
                        isPartient={true}
                        startWorkflow={() => {
                            if (!callDetails.patient_question_sets) {
                                setStep("QUESTIONS");
                            } else {
                                startCall();
                            }
                        }}
                    />
                );
            }

            case "QUESTIONS": {
                return <QuestionsStep startCall={startCall} callDetails={callDetails} />;
            }

            case "IN_ROOM": {
                return (
                    <PatientInRoomStep
                        redirectToMessageProviderDialog={redirectToMessageProviderDialog}
                        remainingTimeLeft={remainingTimeLeft}
                        allottedTime={allottedTime}
                        remoteTracks={remoteTracks}
                        otherParticipantName={otherParticipantName}
                        remoteMedia={remoteMedia}
                        localMedia={localMedia}
                        toggleAudioBtn={toggleAudioBtn}
                        toggleVideoBtn={toggleVideoBtn}
                        participantDisconnected={participantDisconnected}
                        timer={timer}
                        {...props}
                    />
                );
            }

            case "PROVIDER_MESSAGE_STEP": {
                return <ProviderMessageStep {...props} />;
            }

            case "RECONNECT":
                return <ReconnectStep {...props} />;
        }
    } else {
        switch (step) {
            case "ALLOW_AV": {
                return <AllowAVStep {...props} />;
            }

            case "PREVIEW": {
                return (
                    <PreviewStep
                        {...props}
                        toggleAudioBtn={toggleAudioBtn}
                        toggleVideoBtn={toggleVideoBtn}
                        isPartient={false}
                        setAudioOff={setAudioOff}
                        setVideoOff={setVideoOff}
                        startWorkflow={() => setStep("IN_ROOM")}
                    />
                );
            }
            case "IN_ROOM": {
                return (
                    <ProviderVideoWorkflow
                        remainingTimeLeft={remainingTimeLeft}
                        allottedTime={allottedTime}
                        remoteTracks={remoteTracks}
                        otherParticipantName={otherParticipantName}
                        remoteMedia={remoteMedia}
                        localMedia={localMedia}
                        toggleAudioBtn={toggleAudioBtn}
                        toggleVideoBtn={toggleVideoBtn}
                        participantDisconnected={participantDisconnected}
                        timer={timer}
                        {...props}
                    />
                );
            }
        }
    }
};

const VideoViewBaseComponent = (props) => {
    const { header, content, workflow, currentStep, callDetails } = props;
    const [showLauncher, setShowLauncher] = useState(false);

    const navbarConfiguration = {
        customRenderSection: [
            {
                isComponent: true,
                render: GuidedVideoSection,
                componentProps: {
                    workflow: workflow,
                    currentStep: currentStep,
                    showLauncher: () => setShowLauncher(true),
                    ...props,
                },
            },
        ],
    };

    let headerContent = (
        <>
            <div className="GuidedVideoCall__header flex-lg-row justify-content-center px-5">
                {header}
            </div>
            <div className="Toolbar__margin-fix" />
        </>
    );

    return (
        <>
            <div className="GuidedVideoCall position-fixed">
                <Toolbar baseRoute={baseRoute} navbarConfiguration={navbarConfiguration}></Toolbar>
                {header && headerContent}
                {content}
            </div>
        </>
    );
};

export const VideoViewBase = withRouter(VideoViewBaseComponent);
