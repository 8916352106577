import React, { Component } from "react";
import "./styles.scss";

export class Rating extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentRating: this.props.currentRating,
            type: this.props.type ? this.props.type : "star",
        };
    }

    onClickHandler = (rating) => {
        if (!this.props.disabled) {
            this.setState({ currentRating: rating });
            if (this.props.onClick) {
                this.props.onClick(rating);
            }
        }
    };

    ratingComponent = (currentRating, index) => {
        switch (this.state.type) {
            case "star": {
                return (
                    <Star
                        currentRating={currentRating}
                        otherClasses={this.props.otherClasses}
                        index={index}
                        onClickHandler={this.onClickHandler}
                    />
                );
            }
            case "number": {
                return (
                    <Number
                        currentRating={currentRating}
                        otherClasses={this.props.otherClasses}
                        index={index}
                        onClickHandler={this.onClickHandler}
                    />
                );
            }
        }
    };

    render() {
        let component;
        switch (this.state.type) {
            case "star": {
                component = Star;
            }
        }

        return (
            <div className="rating" ref="rating" data-rating={this.state.currentRating}>
                {Array(this.props.totalRating)
                    .fill()
                    .map((_, n) => {
                        return this.ratingComponent(this.state.currentRating, n);
                    })}
            </div>
        );
    }
}

const Star = (props) => {
    let { otherClasses, index, onClickHandler, currentRating } = props;
    let cssType = currentRating >= index + 1 ? "star-active" : "star";

    return (
        <span
            className={`${cssType} ${otherClasses}`}
            key={index + 1}
            onClick={() => onClickHandler(index + 1)}>
            &#9733;
        </span>
    );
};

const Number = (props) => {
    let { otherClasses, index, onClickHandler, currentRating } = props;
    let cssType = currentRating >= index ? "number-active" : "number";

    let margin = "ml-2";
    if (index === 0) {
        margin = "";
    }

    return (
        <div
            className={`${cssType} ${otherClasses} ${margin}`}
            key={index}
            onClick={() => onClickHandler(index)}>
            {index}
        </div>
    );
};
