// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component, Fragment } from "react";
import {
    List,
    ListBody,
    ListHeader,
    ListItem,
    ListTitleBar,
    TitleBarItem,
} from "../../../components/UI/ListView";
import SearchBar from "../../../components/UI/SearchBar";
import ListLoader from "../../../components/Common/Loaders/ListLoader";
import { api } from "../../../APIRequests";
import images from "../../../utils/images";
import { admin_cse, admin_csm, adminRoute } from "../../../config/roles";
import { Link } from "react-router-dom";
import { isAuthorized } from "../../../redux/actions/auth";
import { connect } from "react-redux";
import { setDynamicRouteConfiguration } from "../../../redux/actions/navbar";
import Badge from "../../../components/UI/Badge";
import Circle from "../../../components/UI/Circle";
import { Button, ButtonTypes } from "../../../component-library";
import { getAutomationDataAttr } from "../../../utils/automationUtils";

class PracticeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            practiceList: null,
            search: "",
        };
    }

    componentDidMount() {
        (async () => {
            let options = { params: { showLoader: false } };
            let practiceList = await api.admin.fetch_all_practices({ options });
            this.setState({
                practiceList: practiceList,
            });
        })();
    }

    componentWillUnmount() {
        this.props.setDynamicRouteConfiguration({});
    }

    getListChildren = (practice) => {
        return (
            <Fragment>
                {/* for mobile devices */}
                <div className="d-flex d-lg-none justify-content-between" data-public={"true"}>
                    <div
                        className="d-flex fs-14 flex-column"
                        style={{ width: "65%", wordWrap: "break-word" }}>
                        <span className="fs-16 fw-bold">{practice.name}</span>
                        <span className="fs-16 fw-bold">{practice.practice_type}</span>
                        <span>{practice.general_email}</span>
                    </div>
                </div>
                {/* for desktop */}
                <div
                    className="row no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between"
                    data-public={"true"}>
                    <span className="col-4">{practice.name}</span>
                    <span className="col-2">{practice.practice_type}</span>
                    <span className="col-5">{practice.general_email}</span>
                    <div className="col-1 d-flex d-flex justify-content-center">
                        <div className="cursor ml-5">
                            <img
                                onClick={() =>
                                    this.props.history.push(adminRoute + "/practice/" + practice.id)
                                }
                                src={images("./common/view.png")}
                                alt="view icon"
                                {...getAutomationDataAttr(`ViewPractice_${practice.name}`)}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    updateSearch = (e) => {
        this.setState({ search: e.target.value });
    };

    addButtonClickHandler = () => {
        this.props.history.push("/app/practice/add");
    };

    renderControls = () => {
        return (
            <div className="d-flex render-controls">
                <SearchBar
                    className="mr-lg-2"
                    value={this.state.search}
                    onchange={this.updateSearch}
                />

                {(isAuthorized(admin_cse) || isAuthorized(admin_csm)) && (
                    <Button buttonType={ButtonTypes.primaryV2} onClick={this.addButtonClickHandler}>
                        New Practice
                    </Button>
                )}
            </div>
        );
    };

    render() {
        let filteredPractices;
        if (this.state.practiceList) {
            filteredPractices = this.state.practiceList
                .filter((practice) => {
                    return practice.name
                        .toLowerCase()
                        .match(new RegExp("^" + this.state.search.toLowerCase()));
                })
                .sort((practiceA, practiceB) => (practiceA.name > practiceB.name ? 1 : -1));
        }

        let listItems = (
            <Fragment>
                {filteredPractices &&
                    filteredPractices.map((practice) => {
                        return (
                            <ListItem
                                key={practice.id}
                                clickOnMobile
                                clickHandler={() => {
                                    this.props.history.push(
                                        adminRoute + "/practice/" + practice.id,
                                    );
                                }}>
                                {this.getListChildren(practice)}
                            </ListItem>
                        );
                    })}
            </Fragment>
        );

        return (
            <div>
                <ListBody>
                    <ListHeader title={"Practices"} render={this.renderControls} />
                    <ListTitleBar>
                        <TitleBarItem classes="col-4" text="Practice Name" />
                        <TitleBarItem classes="col-2" text="Practice Type" />
                        <TitleBarItem classes="col-5" text="General Email Address" />
                        <TitleBarItem classes="col-1" text="" />
                    </ListTitleBar>
                    <List>{this.state.practiceList ? listItems : <ListLoader />}</List>
                </ListBody>
            </div>
        );
    }
}

export default connect(null, { setDynamicRouteConfiguration })(PracticeList);
