// Copyright aptihealth, inc. 2019 All Rights Reserved
import { http, httpAuth } from "../utils/axios";
import { getGroupAuthType } from "../redux/actions/auth";

const a5ScreeningRequests = {
    fetch_next_question: function ({ data, options, headers }) {
        let conf = {
            method: "post",
            url: "/v1/a5_screening/next",
            data,
            ...options,
            headers: { Authorization: "DOB " + headers["dob"] },
        };
        return http(conf);
    },
    fetch_previous_question: function ({ data, options, headers }) {
        let conf = {
            method: "post",
            url: "/v1/a5_screening/previous",
            data,
            ...options,
            headers: { Authorization: "DOB " + headers["dob"] },
        };
        return http(conf);
    },
    complete_a5_screening: function ({ queryParams, options, headers }) {
        let conf = {
            method: "post",
            url: `/v1/a5_screening/complete?screening_id=${queryParams.screeningId}&genReport=${queryParams.genReport}`,
            ...options,
            headers: { Authorization: "DOB " + headers["dob"] },
        };
        return http(conf);
    },
    create_public_a5_screening: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/a5_screening/create_public",
            data,
        };
        return http(conf);
    },
    get_provider_names: function ({ queryParams, options, headers }) {
        let conf = {
            method: "post",
            url: `/v1/a5_screening/get_providers?screeningId=${queryParams.screeningId}`,
            ...options,
            headers: { Authorization: "DOB " + headers["dob"] },
        };
        return http(conf);
    },
    notify_provider: function ({ queryParams, options, headers, data }) {
        let conf = {
            method: "post",
            url: `/v1/a5_screening/notify_provider?screeningId=${queryParams.screeningId}`,
            ...options,
            headers: { Authorization: "DOB " + headers["dob"] },
            data,
        };
        return http(conf);
    },
    get_a5_report: function ({ queryParams, options, headers, data }) {
        let conf = {
            method: "post",
            url: `/v1/a5_screening/get_a5_report?screeningId=${queryParams.screeningId}`,
            ...options,
            headers: { Authorization: "DOB " + headers["dob"] },
            data,
        };
        return http(conf);
    },
    get_practice_name: function ({ queryParams, options }) {
        let conf = {
            method: "post",
            url: `/v1/a5_screening/get_practice_name?practiceId=${queryParams.practiceId}`,
            ...options,
        };
        return http(conf);
    },
    get_crisis_risk: function ({ queryParams, options }) {
        let conf = {
            method: "post",
            url: `/v1/a5_screening/get_crisis_risk?screening_id=${queryParams.assessmentId}`,
            ...options,
        };
        return httpAuth(conf);
    },
};

export default a5ScreeningRequests;
