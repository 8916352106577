// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as actionTypes from "../actions/actionTypes";

const initialState = {
    exceptionReportConf: null,
};

const setExceptionReport = (state, action) => {
    return {
        exceptionReportConf: action.exceptionReportConf,
    };
};

const unsetExceptionReport = (state, action) => {
    return {
        exceptionReportConf: null,
    };
};

const logout = (state, action) => {
    return {
        ...initialState,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_EXCEPTION_REPORT:
            return setExceptionReport(state, action);
        case actionTypes.UNSET_EXCEPTION_REPORT:
            return unsetExceptionReport(state, action);
        case actionTypes.LOG_OUT:
            return logout(state, action);
        default:
            return state;
    }
};

export default reducer;
