// Copyright aptihealth, inc. 2019 All Rights Reserved

import moment from "moment-timezone";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { api } from "../../../APIRequests";
import { Link, PageBanner, Text } from "../../../component-library";
import ScheduleWidgets from "../../../components/Common/Schedule/ScheduleWidgets";
import { ScheduleSuccess } from "../../../components/Patient/Schedule";
import {
    _30_MIN_INDIVIDUAL_90832,
    allottedTimeFactory,
    DIAGNOSTIC_INTERVIEW_90791,
    MEDICATION_MGMT_99214,
    TEENS_AND_KIDS_DIAGNOSTIC_INTERVIEW_90791_ALLOTTED_TIME,
} from "../../../components/Provider/ScheduleAvailability/constants";
import CardSecondary from "../../../components/UI/Card/CardSecondary";
import { showAlertWithAction } from "../../../redux/actions/alerts";
import { getPatientProfile, isUserPatient } from "../../../redux/actions/auth";
import { getQueryParams } from "../../../utils/filters";
import { getScheduleForToday, isCareTeamRestricted } from "../../../utils/scheduleUtils";
import PatientCareTeamSchedule from "../../../components/Patient/Schedule/PatientCareTeam";
import {
    DesktopCareTeamLoader,
    MobileCareTeamLoader,
} from "../../../components/Common/Loaders/CareTeamLoader";
import { PatientPaymentBanner } from "../../../component-library";

/**
 * This component is used in two different ways:
 *  1. Creating new schedule with `mode: 'new'`
 *  2. Rescheduling/editing with `mode: 'edit'`
 *
 *  Note: `mode` is passed as `location.state.mode` property via in react-router.
 *        Reschedule will require a queryParam `provider-id` to reschedule with same provider
 */

// Ninety day care status
const NOT_ASSIGNED = "not_assigned";

class Schedule extends Component {
    constructor(props) {
        super(props);
        let mode = getQueryParams("mode", this.props.location.search);
        if (!mode) {
            props.history.push("/app");
        } else {
            /** TODO: revert back provider-id to queryParams */
            let providerId = getQueryParams("provider_id", this.props.location.search) || null;
            let call_id = getQueryParams("call_id", this.props.location.search) || null;
            this.state = {
                mode,
                providerId,
                call_id,
                timeSlots: null,
                dateStr: "",
                selectedTimeSlot: null,
                timeSlotsLoading: true,
                isDisabled: true,
                slotsCount: 0,
                selectedDate: null,
                appointmentDetail: null,
                patientDetails: null,
            };
        }
    }

    getAvailableSchedules = (dateString, providerId) => {
        let urlParams = {
            dateString,
            tz: this.props.timezone,
            providerId,
            allottedTime: getQueryParams("allotted_time", this.props.location.search),
            eventType: this.state.call && this.state.call.event_type,
        };
        const options = { params: { showLoader: true } };
        return api.shared.available_schedules({ urlParams, options });
    };

    scheduleCall = (data) => {
        let { date, time } = data;
        const { timezone } = this.props;
        const queryParams = { timezone };

        switch (this.state.mode) {
            case "new": {
                if (data.provider_id) {
                    return api.schedule.post_schedule({
                        data: {
                            ...data,
                            timezone,
                            timestamp: `${date} ${time}`,
                        },
                    });
                }

                // Handles matching and scheduling in 1 call. To be removed in future scheduling work
                return api.patient.schedule_call({ data, queryParams });
            }
            case "edit": {
                return api.schedule.put_schedule({
                    data: {
                        ...data,
                        timezone,
                        call_id: this.state.call_id,
                        timestamp: `${date} ${time}`,
                    },
                });
            }
            default: {
            }
        }
    };

    onTimeSlotClick = (slotValue) => {
        this.setState({
            selectedTimeSlot: slotValue,
            isDisabled: false,
        });
    };

    onAddingSlot = () => {
        let localTZ = this.props.timezone;
        let combinedDateTime = this.state.selectedDate + " " + this.state.selectedTimeSlot;
        let UTCDate = moment
            .tz(combinedDateTime, "YYYY-MM-DD hh:mm a", localTZ)
            .utc()
            .format("YYYY-MM-DD");
        let UTCTime = moment
            .tz(combinedDateTime, "YYYY-MM-DD hh:mm a", localTZ)
            .utc()
            .format("HH:mm");
        let local_date_time = moment
            .tz(combinedDateTime, "YYYY-MM-DD hh:mm a", localTZ)
            .format("YYYY-MM-DD HH:mm");
        let data = {
            date: UTCDate,
            time: UTCTime,
            local_date_time,
        };
        const { patientProfile } = this.props;

        if (this.state.providerId) {
            data.provider_id = this.state.providerId;
        }

        if (this.state.providerType) {
            if (this.state.providerType === "Prescriber") {
                data.provider_type = "PRESCRIBE";
            } else {
                data.provider_type = "BEHAVIORAL";
            }
        } else {
            data.provider_type = "BEHAVIORAL_INTAKE";
        }

        this.scheduleCall(data)
            .then((cleanResponse) => {
                let appointmentDetail = cleanResponse.appointment_details;

                this.setState({
                    appointmentDetail: appointmentDetail || "rescheduled",
                });

                this.props.getPatientProfile();
            })
            .catch((err) => {
                this.props.showAlertWithAction(err.message);
                console.log(err);
            });
    };

    relevantProviderTypes = ["Behavioral Specialist", "Prescriber"];

    fetchCareTeam = async () => {
        const options = { params: { showLoader: false } };
        let patientProfile = await api.patient.fetch_profile({ options });
        let careTeamMembers = patientProfile.user.care_team_members.filter((member) =>
            this.relevantProviderTypes.includes(member.type),
        );

        if (this.state.mode === "edit" && this.state.providerId) {
            careTeamMembers = careTeamMembers.filter(
                (member) => member.provider_id === this.state.providerId,
            );
        }

        return careTeamMembers;
    };

    fetchCall = async () => {
        const scheduleCallList = await getScheduleForToday();
        return scheduleCallList.schedule.find((call) => call.callId === this.state.call_id);
    };

    async componentDidMount() {
        const call = this.state.call_id ? await this.fetchCall() : null;
        const careTeamMembers = this.props.patientProfile.care_team_members.filter((member) =>
            this.relevantProviderTypes.includes(member.type),
        );
        let defaultProviderId =
            getQueryParams("provider_id", this.props.location.search) || undefined;
        let defaultProviderType;

        const careTeamMembersWithDisabledAttribute = careTeamMembers.map((member) => {
            const newMember = { ...member };
            newMember["disabled"] = isCareTeamRestricted(member["type"], this.props.patientProfile);
            if (!defaultProviderId && !newMember["disabled"]) {
                defaultProviderId = newMember?.provider_id;
                defaultProviderType = newMember?.type;
            } else if (
                defaultProviderId === newMember?.provider_id &&
                defaultProviderType === undefined
            ) {
                defaultProviderType = newMember?.type;
            }
            return newMember;
        });

        this.setState(
            {
                providerId: defaultProviderId,
                providerType: defaultProviderType,
                careTeamMembers: careTeamMembersWithDisabledAttribute,
                patientDetails: this.props.patientProfile,
                call,
            },
            () => this.resetDaySelection(),
        );
    }

    careTeamMemberSelect = (providerId, providerType) => {
        let newState = { providerId: providerId };
        if (providerType) {
            newState["providerType"] = providerType;
        }
        this.setState(newState);
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.providerId && prevState.providerId !== this.state.providerId) {
            this.resetDaySelection();
        }
    }

    resetDaySelection() {
        const earliestSelection = moment().tz(this.props.timezone);

        if (!isSameDaySchedulingAllowed(this?.state?.providerType)) {
            earliestSelection.add(1, "day");
        } else {
            earliestSelection.add(30, "minute");
        }

        const searchDate = this.state.selectedDate || earliestSelection.format("YYYY-MM-DD");

        daySelectHandler(searchDate, this);
    }

    renderCareTeamSection = () => {
        return (
            <div className="Schedule__profile-info col-12 col-lg-10 p-lg-4 pb-lg-5">
                <CardSecondary className="py-4 px-3 px-lg-4">
                    <div style={{ minHeight: "110px" }}>
                        <p className="fs-18 pb-1">
                            <strong>Care Team</strong>
                        </p>
                        <hr />
                        {this.state.careTeamMembers && this.state.careTeamMembers.length > 0 ? (
                            <PatientCareTeamSchedule
                                providerId={this.state.providerId}
                                careTeamMembers={this.state.careTeamMembers}
                                onMemberSelect={this.careTeamMemberSelect}
                                patientDetails={this.state.patientDetails}
                            />
                        ) : (
                            <div>
                                <DesktopCareTeamLoader classes="d-lg-block d-none" />
                                <MobileCareTeamLoader classes="d-block d-lg-none" />
                            </div>
                        )}
                    </div>
                </CardSecondary>
            </div>
        );
    };

    render() {
        const { patientProfile, patientCardInfo, flags } = this.props;
        const { showPaywall } = patientCardInfo ?? {};

        if (patientProfile.status === "INACTIVE") {
            this.props.history.push("/app/schedule/inactive");
        }

        let timeSlotConf = {
            selectedTimeSlot: this.state.selectedTimeSlot,
            isDisabled: this.state.isDisabled,
            selectedDate: this.state.selectedDate,
        };

        let showCareTeamSection = false;

        /** checking if to show care team section */
        if (patientProfile && patientProfile.ninety_day_care !== NOT_ASSIGNED) {
            showCareTeamSection = true;
        }

        return (
            <div data-public="true">
                {showPaywall && (
                    <PatientPaymentBanner className={"PatientPaymentBanner-schedule"} />
                )}
                <div className="Schedule__wpr p-lg-4 mx-auto row no-gutters">
                    {this.state.appointmentDetail && (
                        <ScheduleSuccess
                            behavioralSpecialist={this.state.appointmentDetail.provider_name}
                        />
                    )}
                    {showCareTeamSection && this.renderCareTeamSection()}
                    <ScheduleWidgets
                        timeSlotsLoading={this.state.timeSlotsLoading}
                        timeSlots={this.state.timeSlots}
                        that={this}
                        timeSlotConf={timeSlotConf}
                        timezone={this.props.timezone}
                        onTimeSlotClick={this.onTimeSlotClick}
                        onAddingSlot={this.onAddingSlot}
                        dateStr={this.state.dateStr}
                        selectedDate={this.state.selectedDate && moment(this.state.selectedDate)}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        timezone: state.auth.timezone,
        patientProfile: state.auth.profile,
        patientCardInfo: state.patientCardInfo,
        flags: state.flags,
    };
};

export default connect(mapStateToProps, { getPatientProfile, showAlertWithAction })(Schedule);

export const monthName = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
];

export const isSameDaySchedulingAllowed = (providerType = null) => {
    return (
        providerType == null || providerType === "Intake Behavioral Specialist" || !isUserPatient()
    );
};

export const daySelectHandler = (dateString, that) => {
    let month = Number(dateString.substring(5, 7));
    let date = Number(dateString.substring(8, 11));

    that.setState({
        dateStr: date + " " + monthName[month],
        isDisabled: true,
        selectedDate: dateString,
        selectedTimeSlot: null,
        timeSlots: null,
        timeSlotsLoading: true,
    });

    const earliestSelection = isSameDaySchedulingAllowed(that?.state?.providerType)
        ? moment().add(30, "minute")
        : moment().add(1, "day");
    const earliestSelectionDate = earliestSelection.format("YYYY-MM-DD");
    if (moment(dateString) >= moment(earliestSelectionDate)) {
        that.getAvailableSchedules(dateString, that.state.providerId)
            .then((cleanResponse) => {
                let timeSlots = cleanResponse.time_slots;

                /**
                 * If the current day is selected, filter out timeslots starting before the current time
                 * */
                if (timeSlots && dateString === earliestSelectionDate) {
                    timeSlots = timeSlots.filter((ts) => {
                        return (
                            moment(ts, "HH:mm").format("HHmm") > earliestSelection.format("HHmm")
                        );
                    });
                }
                that.setState({
                    timeSlots: timeSlots,
                    timeSlotsLoading: false,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    } else {
        that.setState({
            timeSlots: [],
            timeSlotsLoading: false,
        });
    }
};
