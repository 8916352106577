import React from "react";

import { getIn } from "formik";
import _ from "lodash";
import "./styles.scss";
import { RadioGroup, RadioOld, Text, TextColors, TextTypes } from "../../../component-library";

// NOTE: THIS PATTERN WAS COPIED FROM the care management form
export const WaitlistFormRadioField = ({
    radioOptions = [],
    name,
    formikProps,
    handleChange: externalHandleChange,
}) => {
    const errorMessage = getIn(formikProps.errors, name);
    const isTouched = getIn(formikProps.touched, name);

    const handleChange = async (event) => {
        await externalHandleChange(event);
        await formikProps.setFieldValue(name, event.target.value);
    };

    return (
        <>
            <RadioGroup
                className={"WaitlistPage--patient-state"}
                name={name}
                onChange={handleChange}
                value={_.get(formikProps.values, name)}>
                {radioOptions.map((option) => {
                    return (
                        <RadioOld
                            key={option.value}
                            version="v2"
                            labelClassName={"font-weight-normal"}
                            radioClassName={"font-weight-normal"}
                            className={"WaitlistPage--patient-state-label mb-2"}
                            label={option.label}
                            value={option.value}
                        />
                    );
                })}
            </RadioGroup>
            {errorMessage && isTouched && (
                <Text className="pt-2" type={TextTypes.small} color={TextColors.red}>
                    {errorMessage}
                </Text>
            )}
        </>
    );
};
