import React from "react";

export const CareManagementSectionTitle = ({ children }) => {
    return (
        <div className="CareManagementSectionTitle--wrapper">
            <div className="CareManagementSectionTitle--line" />
            <h2 className="CareManagementSectionTitle--title">{children}</h2>
        </div>
    );
};
