/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const EllipsisHorizontalIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="EllipsisHorizontal"
                {...props}>
                <path d="M24.25 16C24.25 15.0335 23.4665 14.25 22.5 14.25C21.5335 14.25 20.75 15.0335 20.75 16C20.75 16.9665 21.5335 17.75 22.5 17.75C23.4665 17.75 24.25 16.9665 24.25 16Z" />
                <path d="M17.75 16C17.75 15.0335 16.9665 14.25 16 14.25C15.0335 14.25 14.25 15.0335 14.25 16C14.25 16.9665 15.0335 17.75 16 17.75C16.9665 17.75 17.75 16.9665 17.75 16Z" />
                <path d="M11.25 16C11.25 15.0335 10.4665 14.25 9.5 14.25C8.5335 14.25 7.75 15.0335 7.75 16C7.75 16.9665 8.5335 17.75 9.5 17.75C10.4665 17.75 11.25 16.9665 11.25 16Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="EllipsisHorizontal"
                {...props}>
                <path d="M25 16C25 14.6193 23.8807 13.5 22.5 13.5C21.1193 13.5 20 14.6193 20 16C20 17.3807 21.1193 18.5 22.5 18.5C23.8807 18.5 25 17.3807 25 16Z" />
                <path d="M18.5 16C18.5 14.6193 17.3807 13.5 16 13.5C14.6193 13.5 13.5 14.6193 13.5 16C13.5 17.3807 14.6193 18.5 16 18.5C17.3807 18.5 18.5 17.3807 18.5 16Z" />
                <path d="M12 16C12 14.6193 10.8807 13.5 9.5 13.5C8.11929 13.5 7 14.6193 7 16C7 17.3807 8.11929 18.5 9.5 18.5C10.8807 18.5 12 17.3807 12 16Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="EllipsisHorizontal"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M-8.74228e-07 20C-1.35705e-06 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 -3.91405e-07 20 -8.74228e-07C8.95431 -1.35705e-06 -3.91405e-07 8.9543 -8.74228e-07 20ZM26.75 20.25C26.75 19.2145 25.9105 18.375 24.875 18.375C23.8395 18.375 23 19.2145 23 20.25C23 21.2855 23.8395 22.125 24.875 22.125C25.9105 22.125 26.75 21.2855 26.75 20.25ZM21.875 20.25C21.875 19.2145 21.0355 18.375 20 18.375C18.9645 18.375 18.125 19.2145 18.125 20.25C18.125 21.2855 18.9645 22.125 20 22.125C21.0355 22.125 21.875 21.2855 21.875 20.25ZM15.125 18.375C16.1605 18.375 17 19.2145 17 20.25C17 21.2855 16.1605 22.125 15.125 22.125C14.0895 22.125 13.25 21.2855 13.25 20.25C13.25 19.2145 14.0895 18.375 15.125 18.375Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

EllipsisHorizontalIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

EllipsisHorizontalIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
