import React from "react";
import PropTypes from "prop-types";

import { createClassNameString, tabletViewport } from "../utils";
import { PopoverPositions } from "../constants";

import { Info } from "../Info";

import "./styles.scss";
import { getAutomationDataAttr } from "../../utils/automationUtils";

export const Input = ({
    className,
    inputClassName,

    name,
    disabled,
    placeholder,
    value,
    defaultValue,
    error,

    infoContent,

    onChange,
    onFocus,
    onBlur,
    ignoreEnterSubmit,

    testId,
    ...props
}) => {
    const windowWidth = window.innerWidth;

    const getPointer = (el) => {
        if (el.selectionStart) {
            return el.selectionStart;
        }
        return 0;
    };

    const onKeyDown = (e) => {
        if (e.key === "Enter" && ignoreEnterSubmit) {
            let content = e.target.value;
            let pointerPosition = getPointer(e.target);
            e.target.value =
                content.substring(0, pointerPosition) +
                content.substring(pointerPosition, content.length);
            e.preventDefault();
            e.stopPropagation();
        }
    };

    return (
        <div className={createClassNameString(["apti-CL apti-Input--container", className])}>
            <input
                className={createClassNameString([
                    "apti-CL apti-Input",
                    inputClassName,
                    error && "error",
                ])}
                name={name}
                type="text"
                disabled={disabled}
                placeholder={placeholder}
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                {...getAutomationDataAttr(testId)}
                {...props}
            />
            {infoContent && (
                <Info
                    className="apti-Input--info"
                    position={
                        windowWidth < tabletViewport
                            ? PopoverPositions.left
                            : PopoverPositions.right
                    }>
                    {infoContent}
                </Info>
            )}
        </div>
    );
};

Input.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    error: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    testId: PropTypes.string,
};

Input.defaultProps = {
    className: undefined,
    name: "",
    disabled: false,
    placeholder: "",
    value: undefined,
    defaultValue: undefined,
    error: false,
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    testId: undefined,
};
