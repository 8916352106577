// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as actionTypes from "./actionTypes";
import { api } from "../../APIRequests";
import { profileFetchSuccess } from "./auth";
import { allowLoginPopUps } from "./patient";
import { hideLoader, showLoader } from "./loader";

const updatePaymentProcessorSession = (sessionInfo) => ({
    type: actionTypes.UPDATE_PAYMENT_PROCESSOR_SESSION,
    payload: {
        ...sessionInfo,
    },
});

export const fetchPaymentProcessorSessionToken = ({
    firstName,
    lastName,
    getToken = true,
    addToken = true,
    transactionType = "AUTHORIZATION",
    transactionAmount = 0.01,
    captchaVerificationToken,
    dispatch,
    history,
    workflow,
}) => {
    return api.payment
        .getPaymentSessionToken({
            data: {
                first_name: firstName,
                last_name: lastName,
                get_token: getToken,
                add_token: addToken,
                transaction_type: transactionType,
                transaction_amount: transactionAmount,
                captcha_response_key: captchaVerificationToken,
            },
            errorTypeConfig: {
                ActivityException: {
                    preventToast: true,
                    errorCallback: async () => {
                        if (workflow !== "MOBILE") {
                            dispatch(showLoader(true));
                            const profile = await api.auth.fetch_profile({
                                options: { params: { showLoader: false } },
                            });
                            dispatch(profileFetchSuccess(profile.user));
                            dispatch(allowLoginPopUps());
                            dispatch(hideLoader());
                            history.replace("/app/home");
                        }
                    },
                },
            },
        })
        .then((body) => {
            const { session_token } = body;
            dispatch(updatePaymentProcessorSession({ sessionToken: session_token }));

            return session_token;
        });
};
