// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import Button from "../../UI/Button";

const navControls = (props) => {
    return (
        <div className="Assessment__nav">
            <div className="container Assessment__nav-innner mb-4 p-lg-0">
                <div className="d-flex h-100 align-items-center justify-content-between">
                    <Button
                        className="Btn Btn--otl-pri  mx-2"
                        onClick={props.prev}
                        disabled={
                            !(
                                props.questionId &&
                                (props.questionId > 1 || props.questionId !== "DEMO.ethnicity")
                            )
                        }>
                        PREVIOUS
                    </Button>

                    <Button
                        disabled={!(props.selectedOptions && props.selectedOptions.length > 0)}
                        className="Btn Btn--pri  mx-2 "
                        onClick={props.next}>
                        {props.isLast ? "SUBMIT" : "NEXT"}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default navControls;
