// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import PropTypes from "prop-types";
import images from "../../../../utils/images";
import Button from "../../../UI/Button";

export const ChatMenu = (props) => {
    const {
        archiveConversation,
        hideMenu,
    } = props;

    return <div className={"chat-modal"}>
        <div className={"chat-modal-card"}>
            <div className={"chat-modal-body"}>
                <img
                    className="GuidedVideoCall__close-btn"
                    src={images("./icons/cross.svg")}
                    role="button"
                    onClick={hideMenu}
                    alt="close"
                />
                <p className={"fw-bold mt-2"}>Archive Conversation?</p>
                <Button
                    type="button"
                    className="Btn Btn--err"
                    onClick={archiveConversation}>
                    Archive
                </Button>
            </div>
        </div>
    </div>;
};

ChatMenu.propTypes = {
    archiveConversation: PropTypes.func.isRequired,
    hideMenu: PropTypes.func.isRequired,
};
