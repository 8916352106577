import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";
import { AnswerBubble } from "../AnswerBubble";
import { Text } from "../Text";
import { TextColors, TextTypes } from "../constants";

export const SingleQuestionAnswerBubble = ({
    className,
    style,
    questionSetCategory,
    questionSetOptions,
    answerIds,
}) => {
    const classNamesArray = ["apti-CL apti-SingleQuestionAnswerBubble", className];

    return (
        <div className={createClassNameString(classNamesArray)} style={style}>
            <div className={"pb-3"}>
                <Text type={TextTypes.label} color={TextColors.purple}>
                    {questionSetCategory}
                </Text>
            </div>

            {questionSetOptions.map((questionSetOption, optionIndex) => {
                const filled = answerIds.includes(optionIndex);
                return (
                    <div
                        className={"d-flex pb-1"}
                        key={`apti-SingleQuestionAnswerBubble-${questionSetCategory}-${optionIndex}`}>
                        <div className={"pr-2"}>
                            <AnswerBubble filled={filled} />
                        </div>
                        <Text>{questionSetOption}</Text>
                    </div>
                );
            })}
        </div>
    );
};

SingleQuestionAnswerBubble.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    questionText: PropTypes.string,
    questionSetOptions: PropTypes.array,
    answerIds: PropTypes.object,
};

SingleQuestionAnswerBubble.defaultProps = {
    className: undefined,
    style: {},
    questionText: undefined,
    questionSetOptions: [],
    answerIds: [],
};
