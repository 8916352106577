// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import PropTypes from "prop-types";

const SidebarListContainer = (props) => {
    const { bgWhite, children } = props;

    const classList = ["SidebarListContainer"];
    if (bgWhite) {
        classList.push("SidebarListContainer--bg-white");
    } else {
        classList.push("SidebarListContainer--bg-gray");
    }

    return <div className={classList.join(" ")}>{children}</div>;
};

SidebarListContainer.propTypes = {
    children: PropTypes.node.isRequired,
    bgWhite: PropTypes.bool,
};

export default SidebarListContainer;
