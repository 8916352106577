// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import CardHeading from "../../../../UI/Headings/CardHeading";
import { api } from "../../../../../APIRequests";
import SurveyControls from "./SurveyControls";
import CardSecondary from "../../../../UI/Card/CardSecondary";
import { Link, withRouter } from "react-router-dom";
import Button from "../../../../UI/Button";
import cloneDeep from "lodash/cloneDeep";
import { connect } from "react-redux";
import {
    getAssessmentStatus,
    setAssessmentStatus,
    updateScheduleList,
} from "../../../../../redux/actions/patient";
import {
    getPatientProfile,
    profileFetchSuccess,
    setPatientProfile,
} from "../../../../../redux/actions/auth";

class SurveyQuestion extends Component {
    state = {
        questionnaire: null,
        isDisabled: true,
    };

    componentDidMount() {
        this.props.mode === "prescriber"
            ? this.getPrescriberSurveyQuestions()
            : this.getEvaluatorSurveyQuestions();
    }

    getEvaluatorSurveyQuestions = () => {
        api.patient.fetch_evaluator_survey_question().then((clearResponse) => {
            let questionnaire = clearResponse.question;
            this.transformQuestions(questionnaire);
        });
    };

    getPrescriberSurveyQuestions = () => {
        api.patient.fetch_prescriber_survey_question().then((clearResponse) => {
            let questionnaire = clearResponse.question;
            this.transformQuestions(questionnaire);
        });
    };
    sendEvaluatorSurveyRating = (data) => {
        api.patient.send_evaluator_survey_answer({ data }).then((clearResponse) => {
            this.redirectToHome();
        });
    };
    sendPrescriberrSurveyRating = (data) => {
        api.patient.send_prescriber_survey_answer({ data }).then((clearResponse) => {
            this.redirectToHome();
        });
    };
    transformQuestions = (questions) => {
        let transformedQuestions = questions.map((q) => {
            return {
                question_id: q.id,
                question: q.question,
                rating: null,
            };
        });
        this.setState({
            questionnaire: transformedQuestions,
        });
    };

    redirectToHome = () => {
        this.props.getPatientProfile();
        this.props.history.push("/app/home");
    };

    selectOptionHandler = (id, rating) => {
        let questions = [...this.state.questionnaire];
        let index = questions.findIndex((q) => {
            return q.question_id == id;
        });
        questions[index] = { ...questions[index], rating };
        if (questions.some((question) => question.rating == null)) {
            this.setState({
                isDisabled: true,
            });
        } else {
            this.setState({
                isDisabled: false,
            });
        }
        this.setState({
            questionnaire: [...questions],
        });
    };
    surveySubmitHandler = () => {
        let trimmedData = this.trimBeforeSubmit(this.state.questionnaire);
        let data = { survey: trimmedData };
        //    this.props.submitRatingScaleQuestinos(data)
        this.props.mode === "prescriber"
            ? this.sendPrescriberrSurveyRating(data)
            : this.sendEvaluatorSurveyRating(data);
    };
    trimBeforeSubmit = (questionnaire) => {
        let clonedQuestions = cloneDeep(questionnaire);
        let trimmed = clonedQuestions.map((q) => {
            return {
                question_id: q.question_id,
                rating: q.rating,
            };
        });
        return trimmed;
    };

    render() {
        const { questionnaire } = this.state;
        return (
            <div>
                <CardSecondary className="survey-card mb-md-5 mx-auto px-3">
                    <CardHeading
                        text="Satisfaction Survey"
                        className="fs-14 fs-md-16 mx-md-2 d-none d-md-block px-md-2 py-md-4 mb-0"
                    />
                    <hr className="m-0 d-none d-md-block" />
                    <div className="mx-md-2 px-md-2">
                        <CardHeading
                            text={
                                this.props.mode == "prescriber"
                                    ? "Patient Satisfaction with Prescriber at Evaluation"
                                    : "Patient Satisfaction with Evaluator"
                            }
                            className="fs-14 fs-md-16 pt-4 "
                        />
                        {questionnaire &&
                            questionnaire.map((surveyQuestion) => {
                                return (
                                    <div>
                                        <CardHeading
                                            text={
                                                surveyQuestion.question_id +
                                                ". " +
                                                surveyQuestion.question
                                            }
                                            className="fs-14 fs-md-16 pt-md-4 mt-4 mb-3"
                                        />
                                        <div className="container">
                                            <div className="row">
                                                <SurveyControls
                                                    rating={surveyQuestion.rating}
                                                    id={surveyQuestion.question_id}
                                                    click={this.selectOptionHandler}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        <hr className="d-none d-md-block mt-4" />
                        <div className="d-lg-flex d-none justify-content-end pb-5 pt-3">
                            <Link to={"/app/home"}>
                                <Button type="button" className="Btn Btn--otl-pri Btn-sm mx-2">
                                    Cancel
                                </Button>
                            </Link>
                            <Button
                                type="button"
                                className="Btn Btn--pri Btn-sm mx-2"
                                disabled={this.state.isDisabled}
                                onClick={this.surveySubmitHandler}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </CardSecondary>
                <div className="d-flex d-lg-none justify-content-end py-3 my-4">
                    <Link to={"/app/home"}>
                        <Button type="button" className="Btn Btn--otl-pri Btn-xs mx-2">
                            Cancel
                        </Button>
                    </Link>
                    <Button
                        type="button"
                        className="Btn Btn--pri Btn-xs mx-2"
                        disabled={this.state.isDisabled}
                        onClick={this.surveySubmitHandler}>
                        Submit
                    </Button>
                </div>
            </div>
        );
    }
}

export default connect(() => {}, { getPatientProfile })(withRouter(SurveyQuestion));
