// Copyright aptihealth, inc. 2021 All Rights Reserved
import React, { useState } from "react";
import { TempBanner } from "./TempBanner";
import { TempWorkflowStep } from "./WorkflowSteps/TempWorkflowStep";

import { withRouter } from "react-router-dom";
import "./style.scss";
import { showAlertWithAction } from "../../../redux/actions/alerts";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import { Footer } from "../../../component-library/Footer";
import GuardianInformation from "./WorkflowSteps/GuardianInformation";

const SignUp = (props) => {
    const [showSignUp, setShowSignUp] = useState(true);
    const [showLogIn, setShowLogIn] = useState(false);
    const [activeStep, setActiveStep] = useState("member_type");
    const [accountData, setAccountData] = useState(cloneDeep(ACCOUNT_INITIAL_VALUES));
    const [insuranceEligibilityResponse, setInsuranceEligibilityResponse] = useState({});

    const memberType = "member_type";
    const guardianInformation = "guardian_information";
    const childInformation = "child_information";
    const consentToTreatment = "consent_to_treatment";
    const scheduleSession = "schedule_session";
    const nextSteps = "next_steps";

    const baseWorkFlowProps = {
        accountData,
        setAccountData,
        setShowSignUp,
        setShowLogIn,
        setInsuranceEligibilityResponse,
        insuranceEligibilityResponse,
    };

    const changeWorkFlowStep = (activeStep) => {
        window.scrollTo(0, 0);
        setActiveStep(activeStep);
        props.history.push(`/auth/signup/${activeStep}`);
    };

    const workflowSteps = {
        member_type: (
            <TempWorkflowStep
                {...baseWorkFlowProps}
                title="member_type"
                nextStep={() => changeWorkFlowStep(guardianInformation)}
            />
        ),
        guardian_information: (
            <GuardianInformation
                {...baseWorkFlowProps}
                title="guardian_information"
                prevStep={() => changeWorkFlowStep(memberType)}
                nextStep={() => changeWorkFlowStep(childInformation)}
            />
        ),
        child_information: (
            <TempWorkflowStep
                {...baseWorkFlowProps}
                title="child_information"
                prevStep={() => changeWorkFlowStep(guardianInformation)}
                nextStep={() => changeWorkFlowStep(consentToTreatment)}
            />
        ),
        consent_to_treatment: (
            <TempWorkflowStep
                {...baseWorkFlowProps}
                title="consent_to_treatment"
                prevStep={() => changeWorkFlowStep(childInformation)}
                nextStep={() => changeWorkFlowStep(scheduleSession)}
            />
        ),
        schedule_session: (
            <TempWorkflowStep
                {...baseWorkFlowProps}
                title="schedule_session"
                prevStep={() => changeWorkFlowStep(consentToTreatment)}
                nextStep={() => changeWorkFlowStep(nextSteps)}
            />
        ),
        next_steps: (
            <TempWorkflowStep
                {...baseWorkFlowProps}
                title="next_steps"
                prevStep={() => changeWorkFlowStep(scheduleSession)}
            />
        ),
    };

    return (
        <>
            <div style={{ overflowX: "hidden" }} className={"self-signup-content"}>
                <div>
                    <TempBanner />
                    <div className="WFS_Signup">{showSignUp && workflowSteps[activeStep]}</div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default connect(null, { showAlertWithAction })(withRouter(SignUp));

const ACCOUNT_INITIAL_VALUES = {
    first_name: "",
    last_name: "",
    dob: "",
    mobile: "",
    email: "",
    email_validation: "",
    password: "",
    confirm_password: "",
    address: {
        street: "",
        city: "",
        state: "",
        zip_code: "",
    },
    emergency_contact_person: "",
    emergency_contact_number: "",
    pcp_name: "",
    pcp_location: "",
    no_pcp: false,
    primary_insurance: {
        carrier: "",
        member_id: "",
        group_id: "",
        policy_holder_name: "",
        state: "",
        card_front: "",
        card_back: "",
    },
    interested_in_guardianship: false,
};
