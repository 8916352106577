// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import "./styles.scss";

const Label = (props) => {
    return (
        <div>
            <label className={"Label " + (props.classes ? props.classes : "")}>
                {props.children}
            </label>
        </div>
    );
};

export default Label;
