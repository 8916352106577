// Copyright aptihealth, inc. 2020 All Rights Reserved

import React, { useEffect } from "react";
import "../components/Navigation/Toolbar/styles.scss";
import "./App.scss";
import Brand from "../components/Navigation/Toolbar/Brand";
import images from "../utils/images";

const RedirectSplashScreen = (props) => {
    useEffect(() => {
        const timer = setTimeout(() => props.history.push(props.redirectPath), 3000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <header className="Toolbar">
            {/*DESKTOP*/}
            <nav className="Toolbar__nav-desktop d-none d-lg-flex px-lg-5 align-items-center">
                <Brand />
            </nav>
            {/*MOBILE*/}
            <nav>
                <div className="Toolbar__nav-mobile d-flex justify-content-between d-lg-none px-2 align-items-center">
                    <div className="Toozlbar__nav-center-items d-flex justify-content-center align-items-center">
                        <Brand />
                    </div>
                </div>
            </nav>
            <div className="Toolbar__margin-fix" />
            <div className="redirect-modal">
                <div className={"redirect-circle"}>
                    <img
                        className="img-fluid redirect-arrow"
                        src={images("./icons/redirect-arrow.png")}
                    />
                </div>
                <span className={"hang-tight"}>Hang Tight</span>
                <span className={"redirect-text"}>
                    You’re being redirected to another page. It may take up to 10 seconds.
                </span>
                <img className="img-fluid green-rectangle" src={images("./green-rectangle.svg")} />
            </div>
        </header>
    );
};

export default RedirectSplashScreen;
