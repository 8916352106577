// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import images from "../../../utils/images";
import Button from "../../UI/Button";
import { withRouter } from "react-router-dom";

const TutorialScreen = (props) => {
    const { baseRoute, active } = props;
    const backToSettings = () => {
        if (props.Slide === "Slide5") {
            props.history.push(baseRoute + "/system-check");
        } else {
            props.click(props.Slide, props.next);
        }
    };

    return (
        <div>
            <button
                className="transparent-btn transparent-btn-left"
                onClick={() => {
                    props.click(props.Slide, props.previous);
                }}></button>
            <button
                className="transparent-btn transparent-btn-right"
                onClick={() => {
                    props.click(props.Slide, props.next);
                }}></button>
            <div className="viewTutorial-imgWpr d-flex justify-content-center align-items-start">
                {props.Slide !== "Slide5" && (
                    <img
                        src={images("./common/" + props.Tutorial + ".svg")}
                        alt="1st Tutorial screen"
                        className="img-fluid"
                    />
                )}
                {props.Slide === "Slide5" && (
                    <img
                        src={images("./common/setting.png")}
                        alt="system check"
                        className="img-fluid"
                    />
                )}
            </div>
            <div className="text-center">
                <p className="mb-4 mt-3 fs-15 fw-bold">{props.heading}</p>
                <p className="fs-14">{props.text}</p>
            </div>
            <div className="text-center">
                <span className={props.Slide == "Slide1" ? "dot mx-2 active" : "dot mx-2"}></span>
                <span className={props.Slide == "Slide2" ? "dot mx-2 active" : "dot mx-2"}></span>
                <span className={props.Slide == "Slide3" ? "dot mx-2 active" : "dot mx-2"}></span>
                <span className={props.Slide == "Slide4" ? "dot mx-2 active" : "dot mx-2"}></span>
                <span className={props.Slide == "Slide5" ? "dot mx-2 active" : "dot mx-2"}></span>
            </div>
            <div className="d-flex justify-content-center">
                <Button className="Btn Btn--pri mt-3 mt-lg-5" onClick={backToSettings}>
                    {props.btnText}
                </Button>
            </div>
        </div>
    );
};

export default withRouter(TutorialScreen);
