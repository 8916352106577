import React, { useEffect, useState } from "react";

export const ExistingPatientIntakeHeader = ({ currentState }) => {
    const headerClass = "fs-22 fw-bold pl-4";
    const lookup = {
        schedulingRequired: () => (
            <div className={headerClass}>Schedule aptihealth consultation</div>
        ),
        reschedule: () => <div className={headerClass}>aptihealth consultation scheduled</div>,
        confirmSchedule: () => <div className={headerClass}>Confirm aptihealth consultation</div>,
        intakeComplete: () => (
            <div className={headerClass}>Patient attended aptihealth consultation</div>
        ),
        intakeMissed: () => <div className={headerClass}>Patient missed consultation</div>,
        loading: () => <div className={headerClass}>Loading...</div>,
    };

    return lookup[currentState]();
};
