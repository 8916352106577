import { useRef } from "react";
import { useEffect } from "react";

/**
 * calls a handler function when the user clicks outside of the ref.
 * clicking a ref's child element does not trigger the handler
 * @param {*} ref the element to detect outside clicks on
 * @param {*} handler the function to call when the user clicks outside the ref
 */
export const useClickOutside = (ref, handler) => {
    // useRef is being used to store a reference to the passed in handler
    // which allows it to be referenced correctly by the useEffect callback without
    // updating the useEffect.
    // This was added to avoid reattaching the click listener each time
    // the passed in handler changes
    const savedCallback = useRef(handler);

    useEffect(() => {
        savedCallback.current = handler;
    }, [handler]);

    useEffect(() => {
        const callBack = (event) => {
            // only trigger if the current ref is available and
            // the clicked element is not a descendent
            if (ref.current && !ref.current.contains(event.target)) {
                // call the latest reference to handler
                savedCallback.current(event);
            }
        };

        document.addEventListener("click", callBack);

        return () => {
            document.removeEventListener("click", callBack);
        };
    }, [ref]);
};
