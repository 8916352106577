// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import "./styles.scss";
import { NavLink } from "react-router-dom";
import { getAutomationDataAttr } from "../../../utils/automationUtils";

const NavMenuItem = (props) => {
    const extraClassNames = props.className ? props.className : "mx-1 mx-lg-2 mb-lg-0";
    return (
        <li className={`NavMenuItem cursor ${extraClassNames}`}>
            <NavLink
                className="NavAnchor d-flex align-items-center justify-content-center h-100 flex-column flex-lg-row text-nowrap"
                to={props.route}
                data-public={"true"}
                {...getAutomationDataAttr(props.testId)}>
                {props.children}
            </NavLink>
        </li>
    );
};

export default NavMenuItem;
