/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const ChevronDownIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="ChevronDown"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.29289 12.2929C7.68342 11.9024 8.31658 11.9024 8.70711 12.2929L16 19.5858L23.2929 12.2929C23.6834 11.9024 24.3166 11.9024 24.7071 12.2929C25.0976 12.6834 25.0976 13.3166 24.7071 13.7071L16 22.4142L7.29289 13.7071C6.90237 13.3166 6.90237 12.6834 7.29289 12.2929Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="ChevronDown"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.58579 11.5858C7.36683 10.8047 8.63317 10.8047 9.41421 11.5858L16 18.1716L22.5858 11.5858C23.3668 10.8047 24.6332 10.8047 25.4142 11.5858C26.1953 12.3668 26.1953 13.6332 25.4142 14.4142L16 23.8284L6.58579 14.4142C5.80474 13.6332 5.80474 12.3668 6.58579 11.5858Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="ChevronDown"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM15.0607 16.6893C14.4749 16.1036 13.5251 16.1036 12.9393 16.6893C12.3536 17.2751 12.3536 18.2249 12.9393 18.8107L20 25.8713L27.0607 18.8107C27.6464 18.2249 27.6464 17.2751 27.0607 16.6893C26.4749 16.1036 25.5251 16.1036 24.9393 16.6893L20 21.6287L15.0607 16.6893Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

ChevronDownIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

ChevronDownIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
