import React from "react";
import "./styles.scss";
import { createClassNameString } from "../utils";

export const EmptyCard = ({ className, text }) => {
    return (
        <div className={createClassNameString([className, "EmptyCard--empty-container"])}>
            <div className={"EmptyCard--empty"}>
                <p className={"EmptyCard--empty-text"}>{text}</p>
            </div>
        </div>
    );
};
