// Copyright aptihealth, inc. 2019 All Rights Reserved
import _, { isObject } from "lodash";
import { GROUP_AUTH_HEADER_NAME } from "../constants/axios";
import { getGroupAuthType } from "../redux/actions/auth";
import { httpAuth } from "../utils/axios";

const providerRequests = {
    set_provider_comment: function ({ data, urlParams }) {
        let conf = {
            method: "post",
            url: `/v1/patient_management/${urlParams.patientId}/report-comment`,
            data,
        };
        return httpAuth(conf);
    },
    fetch_provider_profile: function ({ options, queryParams } = {}) {
        const url =
            queryParams && queryParams.providerId
                ? `/v1/provider/profile_get?provider_id=${queryParams.providerId}`
                : "/v1/provider/profile_get";
        let conf = {
            method: "post",
            url: url,
            ...options,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
    fetch_provider_profile_on_behalf_of: function ({ options, queryParams } = {}) {
        // right now this is only used for peers and case managers to fetch other providers
        // profiles to support the calendar
        const url = `/v1/provider/profile_get?provider_id=${queryParams.providerId}&patient_id=${queryParams.patientId}`;
        let conf = {
            method: "post",
            url: url,
            ...options,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
    create_new_patient: function ({ data }) {
        let conf = { method: "post", url: "/v1/provider/register_patient", data };
        return httpAuth(conf);
    },
    post_progress_note: function ({ data, urlParams, options }) {
        let conf = {
            method: "post",
            url: `/v1/patient_management/${urlParams.patientId}/post_progress_notes`,
            data,
            ...options,
        };
        return httpAuth(conf);
    },

    put_progress_note: function ({ data, urlParams }) {
        let conf = {
            method: "post",
            url: `/v1/patient_management/${urlParams.patientId}/put_progress_notes`,
            data,
        };
        return httpAuth(conf);
    },

    delete_progress_note: function ({ data, urlParams }) {
        let conf = {
            method: "post",
            url: `/v1/patient_management/${urlParams.patientId}/delete_progress_notes`,
            data,
        };
        return httpAuth(conf);
    },

    get_patient_consents: function ({ data, urlParams }) {
        let conf = {
            method: "post",
            url: `/v1/patient_management/${urlParams.patientId}/get_latest_consents`,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            data,
        };
        return httpAuth(conf);
    },

    add_consent: function ({ data, urlParams }) {
        let conf = {
            method: "post",
            url: `/v1/patient_management/${urlParams.patientId}/add_consent`,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            data,
        };
        return httpAuth(conf);
    },

    update_profile: function ({ data, queryParams }) {
        const url =
            queryParams && queryParams.providerId
                ? `/v2/provider/profile_update?provider_id=${queryParams.providerId}`
                : "/v2/provider/profile_update";
        let conf = {
            method: "post",
            url: url,
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    fetch_patient_list: function ({ options, queryParams } = {}) {
        const url =
            queryParams && queryParams.providerId
                ? `/v1/provider/patient_list?provider_id=${queryParams.providerId}`
                : "/v1/provider/patient_list";

        let conf = {
            method: "post",
            url,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            ...options,
        };
        return httpAuth(conf);
    },
    /** API for resending Referral code in Provider patient list section */

    resend_referral_code: function (queryParams, options) {
        let conf = {
            method: "post",
            url: `/v1/provider/resend-referral?code=${queryParams.referralCode}`,
            ...options,
        };
        return httpAuth(conf);
    },
    fetch_aptihealth_tips: function ({ options } = {}) {
        let conf = {
            method: "post",
            url: "/v1/provider/tips",
            ...options,
        };
        return httpAuth(conf);
    },

    fetch_provider_banner: function ({ options } = {}) {
        let conf = {
            method: "post",
            url: "/v1/provider/banner",
            ...options,
        };
        return httpAuth(conf);
    },

    fetch_patient_count: function () {
        let conf = { method: "post", url: "/v1/provider/patient_count" };
        return httpAuth(conf);
    },

    begin_ninetyDays_care_plan: function ({ data, urlParams }) {
        let conf = {
            method: "post",
            url: `/v2/provider/begin_ninety_day?patientId=${urlParams.patientId}`,
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
    add_care_plan_objectives: function ({ data, urlParams }) {
        let conf = {
            method: "post",
            url: `/v1/care-plan/${urlParams.patientId}/add-objectives`,
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
    get_care_plan: function ({ data, urlParams }) {
        let conf = {
            method: "post",
            url: `/v1/care-plan/${urlParams.patientId}/profile`,
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    update_patient_details: function ({ data, urlParams }) {
        if (_.has(data, "assessment_status") && isObject(data["assessment_status"])) {
            delete data["assessment_status"];
        }
        let conf = {
            method: "post",
            url: `/v1/patient_management/${urlParams.patientId}/update_patient_details`,
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    post_patient_clinical_assessment: function ({ data, urlParams, queryParams }) {
        let conf = {
            method: "post",
            url: `/v1/patient_management/${urlParams.patientId}/post_clinical_assessment?type=${queryParams.type}`,
            data,
        };
        return httpAuth(conf);
    },

    put_patient_clinical_assessment: function ({ data, urlParams, queryParams }) {
        let conf = {
            method: "post",
            url: `/v1/patient_management/${urlParams.patientId}/put_clinical_assessment?type=${queryParams.type}&isComplete=${queryParams.isComplete}&id=${queryParams.id}&browserTimezone=${queryParams.browserTimezone}`,
            data,
        };
        return httpAuth(conf);
    },

    fetch_90_day_care_widget_data: function ({ queryParams }) {
        let conf = {
            method: "post",
            url: `/v1/provider/ninety_day_count?start_date=${queryParams.start_date}&end_date=${queryParams.end_date}`,
        };

        return httpAuth(conf);
    },

    /**
     * Fetches providers availability for appointments.
     * @param {Object} - An Object which may contain `data`,`urlParams`, `queryParams` and more.
     *
     * Note: `data` shall be `{ date:'YYYY-MM-DD',provider_username:'yyxxzz' }`
     *        if api is calleupdate_patient_detailsd from anywhere except provider's account,
     *        and `{date:'YYYY-MM-DD'}`, only in case when called via provider's account
     */
    fetch_provider_availability: function ({ data }) {
        let conf = { method: "post", url: "/v2/provider/availability", data };
        return httpAuth(conf);
    },
    /**
     * Used for both create and delete with respective `method` type in query params
     */
    set_away_schedule: function ({ data, queryParams }) {
        data["isLocal"] = data["isLocal"] ? data["isLocal"] : false;
        let conf = {
            method: "post",
            url: `/v2/provider/away_slots?method=${queryParams.method}`,
            data,
        };
        return httpAuth(conf);
    },
    update_away_schedule: function ({ data }) {
        let conf = { method: "post", url: "/v2/provider/away_slots?method=edit", data };
        return httpAuth(conf);
    },
    set_video_consultation_schedule: function ({ data, urlParams }) {
        let conf = {
            method: "post",
            url: `/v1/patient_management/${
                urlParams.patientId
            }/provider-appointment?timezone=${encodeURIComponent(urlParams.timezone)}`,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            data,
        };
        return httpAuth(conf);
    },
    /**
     * Below are Common patient requests which are called with
     * helper method `commonPatientRequest()` defined on top of file,
     * These calls have the same `url` with only change in last `/path`
     */

    fetch_practice_members: function () {
        let conf = {
            method: "post",
            url: "/v1/provider/practice_members",
        };

        return httpAuth(conf);
    },

    fetch_ICD10_dataset: function () {
        let conf = {
            method: "post",
            url: "/v1/provider/ICD_10_data",
        };

        return httpAuth(conf);
    },

    fetch_completed_calls: function ({ urlParams }) {
        let conf = {
            method: "post",
            url: `/v1/patient_management/${urlParams.patientId}/get_completed_calls`,
        };
        return httpAuth(conf);
    },

    fetch_provider_draft_notes: function (data = {}) {
        let conf = {
            data,
            method: "post",
            url: "/v1/provider/get_provider_draft_notes",
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    fetch_provider_draft_notes_count: function (data = {}) {
        let conf = {
            data,
            method: "post",
            url: "/v1/provider/get_provider_draft_notes_count",
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    check_and_fetch_eligibility_record: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/insurance_eligibility/authenticated_check_eligibility",
            data,
        };
        return httpAuth(conf);
    },

    post_outreach_attempt: function ({ data, urlParams }) {
        const conf = {
            method: "post",
            url: `/v1/patient_management/${urlParams.patientId}/outreach_attempt`,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            data,
        };
        return httpAuth(conf);
    },
};

export default providerRequests;
