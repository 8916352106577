// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useEffect } from "react";
import RatingControls from "../../../../components/Common/RatingScaleView/RatingControls";
import { SCQBox } from "../../../../components/Common/QuestionnaireWidgets";
import { convertStatus } from "./ClinicalAssessment";
import { toSentenceCase } from "../../../../utils/filters";
import Button from "../../../../components/UI/Button";

const ClinicianRating = (props) => {
    const { statusArr, submitHandler, done, vals } = props;

    const SubmitBtnWprRef = React.useRef(null);

    let ratingSchema = [
        "ability_to_keep_life_stable",
        "ability_to_get_and_keep_a_job",
        "religion_improve_life",
        "ability_to_have_fun",
        "health_and_taking_care_of_self",
        "trouble_with_the_law",
        "relationship_with_family",
        "ability_to_deal_with_feelings",
        "liking_oneself",
        "relationship_with_others",
        "severity_of_alcohol_drug_use",
        "severity_of_emotional_mental_problems",
        "risk_of_harming_self_or_others",
        "readiness_to_improve_ones_life",
        "beliefs_that_treatment_can_be_helpful",
    ];

    /**
     * Configuration for the `SCQBox` widget, and is required
     */
    const optionConf = [
        { value: 0, caption: "No Problem" },
        { value: 1, caption: "Mild Problem" },
        { value: 2, caption: "Mild to Moderate" },
        { value: 3, caption: "Moderate Problem" },
        { value: 4, caption: "Moderate to Severe" },
        { value: 5, caption: "Severe Problem" },
    ];

    const optionsArr = [0, 1, 2, 3, 4, 5]; //for SCQBox component

    /**
     * Checks for enter key, and if submit button is enabled then submits form on Enter key
     */
    const documentKeydownHandler = (e) => {
        if (e.keyCode == 13) {
            const submitBtn = SubmitBtnWprRef.current.querySelector(
                ".js-ClinicalRating__Submit-btn",
            );
            if (submitBtn && !submitBtn.disabled) {
                submitBtn.click(); // submits the form
            }
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", documentKeydownHandler);
        return () => {
            document.removeEventListener("keydown", documentKeydownHandler);
        };
    });

    return (
        <div className="px-3">
            <form onSubmit={submitHandler}>
                {ratingSchema.map((property, i) => {
                    let answer;
                    let readOnly = false;
                    if (vals) {
                        answer = vals[property] || null;
                    }
                    if (done) {
                        readOnly = true;
                    }
                    return (
                        <SCQBox
                            key={i}
                            questionStatement={toSentenceCase(property.replace(/_/g, " "))}
                            id={i + 1}
                            optionConf={optionConf}
                            formConf={{ name: property }}
                            OptionsArr={optionsArr}
                            answer={answer}
                            readOnly={readOnly}
                            onOptionSelect={() => {}}
                        />
                    );
                })}
                <div
                    ref={SubmitBtnWprRef}
                    className="d-flex justify-content-center justify-content-lg-end">
                    <Button
                        type="button"
                        onClick={props.goBack}
                        className=" Btn Btn--otl-pri Btn-sm mr-3">
                        Back
                    </Button>
                    <Button
                        type="submit"
                        className="js-ClinicalRating__Submit-btn Btn Btn--pri Btn-sm ">
                        Continue
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default ClinicianRating;
