// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component, useState } from "react";
import { connect } from "react-redux";
import PatientProfileDetailsCard from "../../../components/Patient/Profile/PatientProfileDetailsCard";
import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { api } from "../../../APIRequests";

const Notifications = (props) => {
    const [profileData, setProfileData] = useState(props.profileData);

    const SMSToggleSubmitHandler = () => {
        const clonedProfileData = cloneDeep(profileData);
        clonedProfileData["preferences"]["is_sms_enabled"] =
            !clonedProfileData["preferences"]["is_sms_enabled"];

        api.patient
            .update_preferences(clonedProfileData["preferences"])
            .then((response) => {
                setProfileData(clonedProfileData);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const emailNotificationToggleSubmitHandler = (type) => {
        const clonedProfileData = cloneDeep(profileData);
        const newValue = !clonedProfileData["preferences"]["email_subscriptions"][type];
        const data = {};
        data[type] = newValue;
        api.patient
            .update_email_preferences(data)
            .then((response) => {
                if (response) {
                    setProfileData(response);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const isSMSEnabled = _.get(profileData, "preferences.is_sms_enabled", false);
    const sessionUpdates = _.get(
        profileData,
        "preferences.email_subscriptions.session_updates",
        false,
    );
    const marketingCommunications = _.get(
        profileData,
        "preferences.email_subscriptions.marketing_communications",
        false,
    );
    return (
        <div className="container px-3" data-public={"true"}>
            <h1 className="d-none d-lg-block fs-22 fw-bold my-4 py-2">Notifications</h1>
            {profileData && (
                <>
                    <PatientProfileDetailsCard heading={"Account Updates"} type={"Required"}>
                        <div className="fs-14 d-flex flex-column mt-2 mb-4">
                            <span>
                                Receive important email notices about your aptihealth account and
                                services.
                            </span>
                        </div>
                    </PatientProfileDetailsCard>

                    <PatientProfileDetailsCard
                        heading={"Session Update"}
                        type={"Toggle"}
                        onclick={() => emailNotificationToggleSubmitHandler("session_updates")}
                        selected={sessionUpdates}
                        testId={"SessionUpdateNotificationsToggle"}>
                        <div className="fs-14 d-flex flex-column mt-2 mb-4">
                            <span>
                                Receive email notices about your appointments, including
                                confirmations, reminders, and cancellations.
                            </span>
                        </div>
                    </PatientProfileDetailsCard>

                    <PatientProfileDetailsCard
                        heading={"Marketing Communications"}
                        type={"Toggle"}
                        onclick={() =>
                            emailNotificationToggleSubmitHandler("marketing_communications")
                        }
                        selected={marketingCommunications}
                        testId={"MarketingCommunicationNotificationsToggle"}>
                        <div className="fs-14 d-flex flex-column mt-2 mb-4">
                            <span>
                                Receive emails that highlight features of the aptihealth platform,
                                our behavioral healthcare services, and
                            </span>
                            <span>the benefits of care.</span>
                        </div>
                    </PatientProfileDetailsCard>

                    <PatientProfileDetailsCard
                        heading={"SMS Notifications"}
                        type={"Toggle"}
                        onclick={SMSToggleSubmitHandler}
                        selected={isSMSEnabled}>
                        <div className="fs-14 d-flex flex-column mt-2 mb-4">
                            <span>
                                Receive important text messages about your appointments and in-app
                                messages from your care team.
                            </span>
                            <span>We recommend enabling SMS to receive notices faster.</span>
                        </div>
                    </PatientProfileDetailsCard>
                </>
            )}
        </div>
    );
};

export default Notifications;
