import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";

export const ErrorCard = ({ children, className, ...props }) => (
    <div className={createClassNameString(["apti-CL apti-ErrorCard", className])} {...props}>
        {children}
    </div>
);

ErrorCard.propTypes = {
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
};

ErrorCard.defaultProps = {
    children: <></>,
    className: undefined,
};
