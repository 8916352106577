import React, { useEffect, useState } from "react";

import { api } from "../../APIRequests";
import { Footer, Header, Link, Text, TextColors, TextTypes } from "../../component-library";

import "./styles.scss";

const PricingPage = ({}) => {
    const [uninsuredPrices, setUninsuredPrices] = useState({
        diagnostic_interview: {},
        therapy: {},
        medication_management: {},
    });

    const { diagnostic_interview, therapy, medication_management } = uninsuredPrices;

    const getUninsuredPrices = async () => {
        const queryParams = { feature_name: "UNINSURED_PRICES" };
        const config = await api.public.get_config({ queryParams });
        setUninsuredPrices(config);
    };

    useEffect(() => {
        (async () => {
            await getUninsuredPrices();
        })();
    }, []);

    return (
        <>
            <Header />
            <div className="PricingPage">
                <Text
                    className="PricingPage__header"
                    type={TextTypes.heading1}
                    color={TextColors.navy}>
                    What should I expect to pay for sessions?
                </Text>

                <div className="PricingPage__paragraph--block">
                    <Text
                        className="PricingPage__paragraph"
                        type={TextTypes.paragraph}
                        color={TextColors.grey}>
                        If you have insurance, your cost share/financial responsibility will be
                        based on your coverage, licensure type of provider, session duration, and
                        your care needs.
                    </Text>
                    <Text
                        className="PricingPage__paragraph"
                        type={TextTypes.paragraph}
                        color={TextColors.grey}>
                        If you don't have insurance, or want to pay out of pocket, we've listed your
                        estimated session cost below.
                    </Text>
                </div>

                <div className="PricingPage__table single">
                    <div className="PricingPage__table--left">
                        <div className="PricingPage__title">Initial Consultation</div>
                        <div className="PricingPage__description">
                            Your first session with a licensed provider
                        </div>
                    </div>
                    <div className="PricingPage__title">{diagnostic_interview.first_session}</div>
                </div>

                <div className="PricingPage__table">
                    <div className="PricingPage__table--header">Therapy</div>
                    <div className="PricingPage__table--cells">
                        <div className="PricingPage__table--cell">
                            <div className="PricingPage__title">{therapy.thirty_min}</div>
                            <div className="PricingPage__description">
                                per <span>30 min</span> session
                            </div>
                        </div>
                        <div className="PricingPage__table--cell">
                            <div className="PricingPage__title">{therapy.forty_five_min}</div>
                            <div className="PricingPage__description">
                                per <span>45 min</span> session
                            </div>
                        </div>
                        <div className="PricingPage__table--cell">
                            <div className="PricingPage__title">{therapy.sixty_min}</div>
                            <div className="PricingPage__description">
                                per <span>60 min</span> session
                            </div>
                        </div>
                        <div className="PricingPage__table--cell">
                            <div className="PricingPage__title">{therapy.add_thirty}</div>
                            <div className="PricingPage__description">
                                per additional <span>30 min</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="PricingPage__table">
                    <div className="PricingPage__table--header">Medication Management</div>
                    <div className="PricingPage__table--row">
                        <div className="PricingPage__table--left">
                            <div className="PricingPage__title">Medication Intake</div>
                            <div className="PricingPage__description">
                                Your first session with your prescriber
                            </div>
                        </div>
                        <div className="PricingPage__title">
                            {medication_management.medication_intake}
                        </div>
                    </div>
                    <div className="PricingPage__table--row">
                        <div className="PricingPage__table--left">
                            <div className="PricingPage__title">Standard Sessions</div>
                            <div className="PricingPage__description">
                                Regularly occurring sessions with your prescriber
                            </div>
                        </div>
                        <div className="PricingPage__title">
                            {medication_management.standard_sessions}
                        </div>
                    </div>
                </div>

                <Text
                    className="PricingPage__paragraph--note"
                    type={TextTypes.paragraph}
                    color={TextColors.grey}>
                    Note: price ranges represent cost variances based on licensure type of provider,
                    session duration, and your care needs. Your provider will recommend your session
                    duration.
                </Text>
                <Text
                    className="PricingPage__paragraph--contact"
                    type={TextTypes.paragraph}
                    color={TextColors.grey}>
                    If you have questions please contact <br />{" "}
                    <Link href="tel:8884543827">(888) 454-3827</Link> or{" "}
                    <Link href="mailto:support@aptihealth.com">support@aptihealth.com</Link>
                </Text>
            </div>
            <Footer />
        </>
    );
};

export default PricingPage;
