// Copyright aptihealth, inc. 2019 All Rights Reserved
export const BS_INITIAL_VALUES = {
    session_date: "",
    session_from: "",
    session_to: "",
    call_id: "",
    ICD_axis_1: "",
    ICD_axis_2: "",
    isSigned: false,
    provider_type: "",
    content: {},
};

export const BS_SCHEMA = [
    {
        name: "title",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "",
            placeholder: "Individual therapy",
        },
    },
    {
        name: "call_id",
        elementType: "select",
        elementConfig: {
            options: [],
            label: "Link Call",
            placeholder: "",
        },
    },
    {
        name: "session_date",
        elementType: "DatePicker",
        elementConfig: {
            type: "text",
            label: "Date",
            placeholder: "Session Date",
        },
    },
    {
        name: "session_from",
        elementType: "select",
        elementConfig: {
            sentenceCase: false,
            options: [],
            label: "From",
            placeholder: "From",
        },
    },
    {
        name: "session_to",
        elementType: "select",
        elementConfig: {
            options: [],
            label: "To",
            placeholder: "To",
        },
    },
    {
        name: "patient_report",
        elementType: "textarea",
        elementConfig: {
            type: "text",
            label: "Patient Report or Behavior",
            placeholder: "Patient Report",
        },
    },
    {
        name: "interventions",
        elementType: "textarea",
        elementConfig: {
            type: "text",
            label: "Interventions",
            placeholder: "Interventions",
        },
    },
    {
        name: "intervention_reaction",
        elementType: "textarea",
        elementConfig: {
            type: "text",
            label: "Intervention Reaction",
            placeholder: "Intervention Reaction",
        },
    },
    {
        name: "patient_task",
        elementType: "textarea",
        elementConfig: {
            type: "text",
            label: "Patient Task",
            placeholder: "Patient Task",
        },
    },
    {
        name: "ICD_axis_1",
        elementType: "reactselect",
        elementConfig: {
            options: [],
            label: "ICD-10 Primary Diagnosis",
            placeholder: "",
        },
    },
    {
        name: "ICD_axis_2",
        elementType: "reactselect",
        elementConfig: {
            options: [],
            label: "ICD-10 Secondary Diagnosis",
            placeholder: "",
        },
    },
    {
        name: "isSigned",
        elementType: "input",
        elementConfig: {
            displayName: "Click to Sign",
            type: "checkbox",
            classNames: "font-weight-bold",
        },
    },
];

export const P_INITIAL_VALUES = {
    session_date: "",
    session_from: "",
    session_to: "",
    patient_reported: "",
    prescriber_interventions: "",
    intervention_reaction: "",
    call_id: "",
    ICD_axis_1: "",
    ICD_axis_2: "",
    isSigned: false,
};

export const P_SCHEMA = [
    {
        name: "title",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "",
            placeholder: "Individual therapy",
        },
    },
    {
        name: "call_id",
        elementType: "select",
        elementConfig: {
            options: [],
            label: "Link Call",
            placeholder: "",
        },
    },
    {
        name: "session_date",
        elementType: "DatePicker",
        elementConfig: {
            type: "text",
            label: "Date",
            placeholder: "Session Date",
        },
    },
    {
        name: "session_from",
        elementType: "select",
        elementConfig: {
            options: [],
            label: "From",
            placeholder: "00:00",
        },
    },
    {
        name: "session_to",
        elementType: "select",
        elementConfig: {
            sentenceCase: false,
            options: [],
            label: "To",
            placeholder: "00:00",
        },
    },
    {
        name: "patient_reported",
        elementType: "textarea",
        elementConfig: {
            type: "text",
            label: "Patient Reported",
            placeholder: "Patient Reported",
        },
    },
    {
        name: "prescriber_interventions",
        elementType: "textarea",
        elementConfig: {
            type: "text",
            label: "Interventions",
            placeholder: "Interventions",
        },
    },
    {
        name: "intervention_reaction",
        elementType: "textarea",
        elementConfig: {
            type: "text",
            label: "Intervention Reaction",
            placeholder: "Intervention Reaction",
        },
    },
    {
        name: "ICD_axis_1",
        elementType: "reactselect",
        elementConfig: {
            options: [],
            label: "ICD-10 Primary Diagnosis",
            placeholder: "",
        },
    },
    {
        name: "ICD_axis_2",
        elementType: "reactselect",
        elementConfig: {
            options: [],
            label: "ICD-10 Secondary Diagnosis",
            placeholder: "",
        },
    },
    {
        name: "isSigned",
        elementType: "input",
        elementConfig: {
            displayName: "Click to Sign",
            type: "checkbox",
            classNames: "font-weight-bold",
        },
    },
];
