import "./styles.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "../Button";
import { ButtonTypes } from "../constants";
import { TextArea } from "../TextArea";
import { NPSSurveyWrapper } from "./NPSSurveyWrapper";

export const NPSScoreReason = ({ className, submitHandler, dismissHandler }) => {
    const [scoreReason, setScoreReason] = useState("");

    return (
        <NPSSurveyWrapper className={className} dismissHandler={dismissHandler}>
            <div className="NPSScoreReason--content_wrapper">
                <div className={"NPSScoreReason--label"}>
                    What&apos;s the main reason for your score?
                </div>
                <div className={"NPSScoreReason--sub_label"}>
                    Do not include patient identifying information.
                </div>
                <TextArea
                    className="NPSScoreReason--input"
                    placeholder="Please type here..."
                    onChange={(event) => {
                        setScoreReason(event.target.value);
                    }}
                />
                <div className="NPSScoreReason--submit_button_wrapper">
                    <Button
                        className="NPSScoreReason--submit_button"
                        buttonType={ButtonTypes.primaryV2}
                        onClick={() => submitHandler(scoreReason)}>
                        Submit
                    </Button>
                </div>
            </div>
        </NPSSurveyWrapper>
    );
};

NPSScoreReason.propTypes = {
    className: PropTypes.string,
    submitHandler: PropTypes.func.isRequired,
    dismissHandler: PropTypes.func.isRequired,
};

NPSScoreReason.defaultProps = {
    className: undefined,
    submitHandler: () => {},
    dismissHandler: () => {},
};
