import React from "react";
import { Form, Formik } from "formik";
import FormikInput from "../../../../UI/FormikInput";
import FormikInputFormat from "../../../../UI/formikMobileInput";
import Button from "../../../../UI/Button";
import * as yup from "yup";
import { mergeObjectOnlyExistingFields } from "../../../../../utils/objectUtils";
import { CustomForm } from "../../../../../component-library/CustomForm";

export const CompleteProfileEmergencyContact = (props) => {
    const { setCurrentCompleteProfileStep, submitHandler, profile, closeModal } = props;

    const renderForm = (formikProps) => {
        const formikInputProps = {
            errors: formikProps.errors,
            touched: formikProps.touched,
        };
        return (
            <Form>
                <div className="mx-auto px-0 mb-3">
                    <div className="px-0 row pb-5">
                        <>
                            <div className={"col-12"}>
                                <FormikInput
                                    key="emergency_contact_person"
                                    {...formikInputProps}
                                    formEl={{
                                        name: "emergency_contact_person",
                                        elementType: "input",
                                        elementConfig: {
                                            type: "text",
                                            label: "Emergency Contact Person",
                                            placeholder: "Enter Emergency Contact Person",
                                        },
                                    }}
                                />
                            </div>
                            <div className={"col-12"}>
                                <FormikInputFormat
                                    key="emergency_contact_number"
                                    {...formikInputProps}
                                    formEl={{
                                        name: "emergency_contact_number",
                                        elementType: "input",
                                        elementConfig: {
                                            type: "text",
                                            label: "Emergency Contact Number",
                                            placeholder: "Enter Emergency Contact Number",
                                        },
                                    }}
                                    value={
                                        formikProps &&
                                        formikProps.values["emergency_contact_number"]
                                    }
                                    onChange={async (val) => {
                                        await formikProps.setFieldValue(
                                            "emergency_contact_number",
                                            val.value,
                                        );
                                        formikProps.setFieldTouched("emergency_contact_number");
                                    }}
                                />
                            </div>
                        </>
                    </div>
                    <div className="px-0 row pb-5">
                        <div className={"d-flex justify-content-between w-100"}>
                            <Button
                                type="submit"
                                className="Btn Btn--otl-pri self-signup-btn m-auto"
                                onClick={() => setCurrentCompleteProfileStep("Address")}>
                                Back
                            </Button>
                            <Button type="submit" className="Btn Btn--pri self-signup-btn m-auto">
                                Save & Continue
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <>
            <CustomForm
                initialValues={mergeObjectOnlyExistingFields(CONTACT_INITIAL_VALUES, profile)}
                validationSchema={emergencyContactValidationSchema}
                onSubmit={(formData, actions) => {
                    submitHandler(formData, actions);
                    setCurrentCompleteProfileStep("Primary Care Physician");
                }}
                render={renderForm}
            />
        </>
    );
};

const CONTACT_INITIAL_VALUES = {
    username: "",
    emergency_contact_person: "",
    emergency_contact_number: "",
};

const emergencyContactValidationSchema = yup.object().shape({
    emergency_contact_person: yup
        .string()
        .required("Emergency Contact Person is required")
        .matches(/^[a-zA-Z-\s]+$/, "Numerals and special characters are not allowed"),
    emergency_contact_number: yup
        .string()
        .matches(/^\d{10}$/, "Emergency Contact number must be a valid US number (no dashes)")
        .required("Emergency contact number is required"),
});
