// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import { AssessmentIcon, MemberProfileActivityEntry } from "../../../component-library";
import { ICON_SIZES, ICON_TYPES } from "../../../constants/icons";

const AssessmentActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const { completed_by } = keyContent;

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Primary"
            title="Clinical Assessment Completed"
            subTitle={`Completed by ${completed_by}`}
            localDateTime={localCreatedTime}
            iconSrc="icons/assessment-icon-primary.svg"
            iconAlt="AssessmentCompletedIcon"
        />
    );
};

const GenericAssessmentActivity = (props) => {
    const { timestamp, keyContent } = props;
    const { assessment_type, status } = keyContent;

    let response = <></>;
    switch (status) {
        case "assigned":
            response = (
                <MemberProfileActivityEntry
                    headerClassName="apti-MemberProfileActivityEntry__Header__Primary"
                    title={`${assessment_type} Assigned`}
                    subTitle={`The ${assessment_type} was assigned`}
                    localDateTime={timestamp}
                    iconComponent={
                        <AssessmentIcon
                            iconSize={ICON_SIZES.LARGE}
                            iconType={ICON_TYPES.CIRCLE}
                            className="apti-MemberProfileActivityEntry__ContentContainer__Icon GenericAssessmentActivity-icon"
                        />
                    }
                />
            );
            break;
        case "in_progress":
            response = (
                <MemberProfileActivityEntry
                    headerClassName="apti-MemberProfileActivityEntry__Header__Primary"
                    title={`${assessment_type} Started`}
                    subTitle={`The ${assessment_type} was started`}
                    localDateTime={timestamp}
                    iconComponent={
                        <AssessmentIcon
                            iconSize={ICON_SIZES.LARGE}
                            iconType={ICON_TYPES.CIRCLE}
                            className="apti-MemberProfileActivityEntry__ContentContainer__Icon GenericAssessmentActivity-icon"
                        />
                    }
                />
            );
            break;
        case "completed":
            response = (
                <MemberProfileActivityEntry
                    headerClassName="apti-MemberProfileActivityEntry__Header__Primary"
                    title={`${assessment_type} Completed`}
                    subTitle={`The ${assessment_type} was completed`}
                    localDateTime={timestamp}
                    iconComponent={
                        <AssessmentIcon
                            iconSize={ICON_SIZES.LARGE}
                            iconType={ICON_TYPES.CIRCLE}
                            className="apti-MemberProfileActivityEntry__ContentContainer__Icon GenericAssessmentActivity-icon"
                        />
                    }
                />
            );
            break;
        default:
            break;
    }

    return response;
};

export default AssessmentActivity;
export { GenericAssessmentActivity };
