import React from "react";
import PropTypes from "prop-types";
import { createClassNameString } from "../utils";
import { TextColors, TextTypes } from "../constants";
import { Text } from "../Text";

import "./styles.scss";

export const Radio = ({
    className,
    labelClassName,
    radioClassName,
    label,
    name,
    value,
    checked,
    onClick,
    onChange,
    ...props
}) => {
    return (
        <label className={createClassNameString(["apti-CL apti-Radio--container", className])}>
            <input
                className={createClassNameString([
                    "apti-Radio",
                    !label && "no-label",
                    radioClassName,
                ])}
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onClick={onClick}
                onChange={onChange}
                {...props}
            />
            {label && (
                <Text
                    className={createClassNameString(["apti-Radio--label", labelClassName])}
                    type={TextTypes.label}
                    color={TextColors.grey}>
                    {label}
                </Text>
            )}
        </label>
    );
};

const radioProps = {
    children: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

const radioDefaultValues = {
    className: undefined,
    labelClassName: undefined,
    label: undefined,
    value: "",
    checked: false,
    onClick: () => {},
};

Radio.propTypes = radioProps;
Radio.defaultProps = radioDefaultValues;
