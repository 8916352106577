import React, { useState } from "react";
import { connect } from "react-redux";
import {
    Button,
    CheckboxOld,
    FormField,
    Input,
    Modal,
    Text,
    TextTypes,
} from "../../../../component-library/";
import images from "../../../../utils/images";
import moment from "moment";
import DatePicker from "../../../UI/DatePicker";
import PropTypes from "prop-types";
import { api } from "../../../../APIRequests";
import { toSentenceCase } from "../../../../utils/filters";
import { addToast, toastMessageTypes } from "../../../../redux/actions/toaster";
import CloseOnOutsideClick from "../../../Common/CloseOnOutsideClick/CloseOnOutsideClick";

const consentTypes = ["VERBAL", "WRITTEN"];

const ConsentModal = ({
    show,
    setShowModal,
    patientDetails,
    consentFormName,
    refreshConsentList,
    dispatch,
}) => {
    const [consentObtained, setConsentObtained] = useState(false);
    const [consentedDate, setConsentedDate] = useState(moment().utc());
    const [showDatepicker, setShowDatepicker] = useState(false);
    const [consentType, setConsentType] = useState("VERBAL");
    const [datepickerError, setDatepickerError] = useState(undefined);

    const setConsentDateToToday = () => {
        setConsentedDate(moment().utc());
        setDatepickerError(undefined);
    };

    const onDatepickerSelect = (newDate, eventType) => {
        const newConsentedDate = moment(newDate, "YYYY-MM-DD").utc();
        setConsentedDate(newConsentedDate);
        if (eventType !== "monthChange") {
            setShowDatepicker(false);
        }

        if (newConsentedDate.isAfter(moment().utc())) {
            setDatepickerError("Date must be in the past");
        } else {
            setDatepickerError(undefined);
        }
    };

    const submitHandler = async () => {
        const data = {
            response_given_by: patientDetails?.username,
            consent_type: consentType,
            consent_form_name: consentFormName,
            consent_response: true,
            response_given_by_guardian: false,
            timestamp: consentedDate.toISOString(),
        };
        let urlParams = { patientId: patientDetails?.username };

        await api.provider.add_consent({ data, urlParams });

        dispatch(
            addToast({
                message: "Consent submitted successfully.",
                messageType: toastMessageTypes.success,
            }),
        );

        setShowModal(false);
        refreshConsentList();
        setConsentObtained(false);
        setConsentedDate(moment().utc());
    };

    const renderVerbalContent = () => {
        return (
            <>
                <div className="my-4">
                    <Text type={TextTypes.paragraph} className="font-color-gray fs-14">
                        Obtain verbal consent before or during first visit after reading the below.
                        Parent or other person with legal authority needs to consent on behalf of a
                        minor.
                    </Text>
                </div>
                <div className="p-4 fs-16 apti-Consent--list">
                    <Text type={TextTypes.paragraph}>
                        By providing your{" "}
                        <Text type={TextTypes.paragraph}>
                            <a
                                className={"green link bold underline"}
                                href={"https://www.aptihealth.com/consent/"}
                                target="_blank"
                                rel="noreferrer">
                                consent to treatment via telehealth
                            </a>
                        </Text>
                        , you confirm that you are aware:
                    </Text>
                    <ul className="apti-Consent--list-text">
                        <li>
                            Of the potential advantages and disadvantages of telehealth, are given
                            the option of not participating in telehealth services, and information
                            regarding your right to request a change in service delivery mode at any
                            time.
                        </li>
                        <li>
                            That you will not be denied services if you do not consent to telehealth
                            services or request to receive services in-person.
                        </li>
                        <li>
                            That you can verify the license of the treating provider at the{" "}
                            <Text style={{ fontStyle: "italic" }} type={TextTypes.paragraph}>
                                New York State Office of the Professions
                            </Text>{" "}
                            website, op.nysed.gov/verification-search
                        </li>
                    </ul>
                    <Text type={TextTypes.paragraph}>
                        Do you authorize aptihealth to perform evaluation and treatment services and
                        procedures to advance your goals of treatment?
                    </Text>
                </div>

                <div className="apti-Consent--input mt-4 mb-2">
                    <div className="apti-Consent--input-checkbox align-self-center">
                        <CheckboxOld
                            onClick={() => setConsentObtained(!consentObtained)}
                            name="consentObtained"
                            checked={consentObtained}
                        />
                        <Text className="pl-2" type={TextTypes.paragraph}>
                            Consent obtained
                        </Text>
                    </div>

                    <FormField
                        className="apti-Consent--formContainer position-relative"
                        errorMessage={datepickerError}>
                        <Input
                            className="apti-Consent--formContainer-field"
                            onClick={() => setShowDatepicker(true)}
                            error={datepickerError}
                            value={consentedDate.format("MM/DD/YYYY")}
                        />
                        {showDatepicker && (
                            <CloseOnOutsideClick
                                className="apti-Consent--datepicker-verbal position-absolute"
                                setShowComponent={() => {
                                    setShowDatepicker(!showDatepicker);
                                }}>
                                <DatePicker
                                    externalCtx={consentedDate}
                                    onDaySelect={onDatepickerSelect}
                                />
                            </CloseOnOutsideClick>
                        )}
                    </FormField>
                </div>
            </>
        );
    };

    const renderWrittenContent = () => {
        return (
            <>
                <div className="my-4">
                    <Text type={TextTypes.paragraph} className="font-color-gray fs-14">
                        Record a consent previously obtained by a digital signature or in written
                        form.
                    </Text>
                </div>
                <div className="apti-Consent--input mt-4 mb-2">
                    <div className={"w-100 d-flex"}>
                        <FormField
                            className="apti-Consent--formContainer-written position-relative"
                            label={"Date of consent"}
                            errorMessage={datepickerError}>
                            <Input
                                className="apti-Consent--formContainer-field"
                                onClick={() => setShowDatepicker(true)}
                                error={datepickerError}
                                value={consentedDate.format("MM/DD/YYYY")}
                            />
                            {showDatepicker && (
                                <CloseOnOutsideClick
                                    setShowComponent={() => {
                                        setShowDatepicker(!showDatepicker);
                                    }}
                                    className={
                                        "apti-Consent--datepicker-written position-absolute"
                                    }>
                                    <DatePicker
                                        externalCtx={consentedDate}
                                        onDaySelect={onDatepickerSelect}
                                    />
                                </CloseOnOutsideClick>
                            )}
                        </FormField>
                        <Button
                            className="apti-Consent--button apti-Consent--button-today"
                            onClick={setConsentDateToToday}>
                            <Text
                                type={TextTypes.paragraph}
                                color="green"
                                style={{ textDecoration: "underline" }}>
                                Set to today
                            </Text>
                        </Button>
                    </div>
                </div>
            </>
        );
    };

    const navButtonRender = () => {
        return consentTypes.map((conType) => {
            const buttonClass =
                consentType === conType
                    ? "apti-Consent--button apti-Consent--button-selected w-50"
                    : "apti-Consent--button w-50";

            return (
                <Button
                    key={conType}
                    className={buttonClass}
                    value={toSentenceCase(conType)}
                    onClick={() => {
                        setConsentType(conType);
                        setShowDatepicker(false);
                        setConsentDateToToday();
                    }}>
                    {toSentenceCase(conType)}
                </Button>
            );
        });
    };

    const isSubmitDisabled = () => {
        return (consentType === "VERBAL" && !consentObtained) || datepickerError;
    };

    return (
        <>
            {show && (
                <Modal cardClassName="apti-Consent" show={true}>
                    <CloseOnOutsideClick
                        setShowComponent={() => {
                            if (!showDatepicker) {
                                setShowModal(!show);
                            }
                        }}>
                        <div className="p-4">
                            <div className={"d-flex justify-content-between create-note-header"}>
                                <Text
                                    type={TextTypes.paragraph}
                                    className={"create-new-note-text fw-bold mb-0"}>
                                    Add Consent to Treatment via Telehealth
                                </Text>
                                <img
                                    src={images("./icons/icon-close-outline.svg")}
                                    alt="consent x icon"
                                    onClick={() => setShowModal(false)}
                                    className={"pointer ml-5"}
                                />
                            </div>
                        </div>
                        <div className="p-4">
                            <div className={"d-flex"}>{navButtonRender()}</div>
                            {consentType === "VERBAL" && renderVerbalContent()}
                            {consentType === "WRITTEN" && renderWrittenContent()}

                            <div className="d-flex">
                                <Button
                                    buttonType="primary-v2-outline"
                                    className="apti-Consent--button-cancel"
                                    value={"Cancel"}
                                    onClick={() => {
                                        setShowModal(false);
                                    }}>
                                    Cancel
                                </Button>
                                <Button
                                    buttonType="primary-v2"
                                    className="apti-Consent--button-submit"
                                    value={"Record Consent"}
                                    onClick={submitHandler}
                                    disabled={isSubmitDisabled()}>
                                    Record Consent
                                </Button>
                            </div>
                        </div>
                    </CloseOnOutsideClick>
                </Modal>
            )}
        </>
    );
};

ConsentModal.propTypes = {
    show: PropTypes.bool,
    setShowModal: PropTypes.func,
    patientDetails: PropTypes.object,
    consentFormName: PropTypes.string,
    refreshConsentList: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    dispatch,
});

export default connect(null, mapDispatchToProps)(ConsentModal);
