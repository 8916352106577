import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useParams } from "react-router-dom";
import { BackToPatientProfile } from "../BackToPatientProfile";
import { useSelector } from "react-redux";
import _, { cloneDeep } from "lodash";
import { usePatient, usePatientDiagnosis } from "../../../../../utils/hooks/usePatient";
import { MemberHeader } from "../../../../../component-library/MemberHeader";
import { formatMobileNumber } from "../../../../../utils/filters";
import { calculateAge } from "../../../../../utils/momentUtils";
import { useHistory } from "react-router-dom";
import { ADVANCED_CARE_ACTIVITY_INITIAL_VALUES } from "./AdvancedCareActivityScheme";
import { AdvancedCareActivityForm } from "./AdvancedCareActivityForm";
import { isAuthorized } from "../../../../../redux/actions/auth";
import { admin_cse } from "../../../../../config/roles";

export const AdvancedCareActivityNewForm = () => {
    const { patientId } = useParams();
    const history = useHistory();
    const patient = usePatient(patientId, history.location.state?.patientDetails);
    const diagnosis = usePatientDiagnosis(patientId, history.location.state?.patientDiagnosis);
    const primaryDiagnosis = _.get(diagnosis, "ICD_10_axis_1_diagnosis", [])
        .map((icd_10) => icd_10["value"])
        .join(", ");
    const secondaryDiagnosis = _.get(diagnosis, "ICD_10_axis_2_diagnosis", [])
        .map((icd_10) => icd_10["value"])
        .join(", ");
    const current_user = useSelector((state) => state.auth.profile);
    const [initialValues, setInitialValues] = useState();

    // setup form state
    useEffect(() => {
        if (!patient || !current_user) {
            return;
        }
        const noteStateToClone = ADVANCED_CARE_ACTIVITY_INITIAL_VALUES;
        const localInitialValues = cloneDeep(noteStateToClone);
        localInitialValues.patient_id = patient.username;
        localInitialValues.patient_first_name = patient.first_name;
        localInitialValues.patient_last_name = patient.last_name;

        if (isAuthorized(admin_cse)) {
            localInitialValues.admin_name = current_user.name;
            localInitialValues.admin_id = current_user.username;
        } else {
            localInitialValues.provider_id = current_user.username;
            localInitialValues.provider_name = current_user.name;
            localInitialValues.provider_type = current_user.provider_type;
            localInitialValues.provider_cp_credentials = current_user.cp_credentials;
        }

        setInitialValues(localInitialValues);
    }, [patient, current_user]);

    return (
        <div className="AdvancedCareActivityNewForm-container">
            <div className="AdvancedCareActivityNewForm-wrapper">
                <BackToPatientProfile patientId={patientId} />

                {patient && current_user && diagnosis && (
                    <MemberHeader
                        className={"AdvancedCareActivityNewForm-profile"}
                        memberName={`${_.get(patient, "first_name", "")} ${_.get(
                            patient,
                            "last_name",
                            "",
                        )}`}
                        dob={_.get(patient, "dob")}
                        age={calculateAge(_.get(patient, "dob", ""))}
                        phoneNumber={formatMobileNumber(_.get(patient, "mobile"))}
                        dayOfCare={_.get(patient, "number_of_days_in_care", "")}
                        primaryDiagnosis={primaryDiagnosis === "" ? "None" : primaryDiagnosis}
                        secondaryDiagnosis={secondaryDiagnosis === "" ? "None" : secondaryDiagnosis}
                    />
                )}

                <AdvancedCareActivityForm
                    noteState={initialValues}
                    isEditing={true}
                    patientId={patient?.username}
                    nameToSign={current_user?.name}
                />
            </div>
        </div>
    );
};
