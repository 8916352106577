// Copyright aptihealth, inc. 2021 All Rights Reserved

import React from "react";
import "./style.scss";
import ReactTooltip from "react-tooltip";
import _ from "lodash";
import images from "../../../utils/images";

const showX = (toolTipRef, doNotShowX) => {
    if (doNotShowX) {
        return <></>;
    }

    return (
        <img
            className={"close-tooltip"}
            role={"button"}
            src={images("./icons/x-gry.png")}
            alt={"X"}
            onClick={() => {
                ReactTooltip.hide(toolTipRef);
            }}
        />
    );
};

const InputToolTip = (props) => {
    const { onKeyDown } = props;
    const toolTipId = props.id + "-tooltip";

    let toolTipRef = React.createRef();

    return (
        <>
            <input onKeyDown={onKeyDown} {...props.field} {...props} id={props.id} />
            <img
                data-for={toolTipId}
                data-tip="tooltip"
                role={"button"}
                onClick={() => {
                    ReactTooltip.show(toolTipRef);
                }}
                ref={(ref) => (toolTipRef = ref)}
                className={"tooltip-btn fw-bold text-center"}
                src={images("./icons/info.png")}
                alt={"i"}
            />
            <ReactTooltip
                className={_.get(props, "elementConfig.tooltipClass", "")}
                id={toolTipId}
                place="left"
                effect="solid"
                border={true}
                borderColor={"#5B57DC !important"}
                backgroundColor={"white"}
                textColor={"black"}>
                {showX(toolTipRef, props.elementConfig.doNotShowX)}
                {props.elementConfig.tooltipContents}
            </ReactTooltip>
        </>
    );
};

export default InputToolTip;
