const { isUserPCP } = require("../redux/actions/auth");

export const hasAccessToPaymentTab = () => {
    return !isUserPCP();
};

/**
 * Determines if a user should be shown a vulnerable page. A vulnerable page is one that can be
 * exploited, such as the insurance update and payment method pages. These can allow attackers to
 * check the validity of stolen credentials, for example
 * @param userStatus Status of the user, e.g., INACTIVE
 * @returns {boolean} If they user should be shown a vulnerable page
 */
export const shouldShowVulnerablePages = (userStatus) => {
    return userStatus !== "INACTIVE";
};
