// Copyright aptihealth, inc. 2021 All Rights Reserved

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { api } from "../../../APIRequests";
import { Footer } from "../../../component-library/Footer";
import { getQueryParams } from "../../../utils/filters";
import images from "../../../utils/images";
import Button from "../../UI/Button";
import "./styles.scss";

const Unsubscribe = (props) => {
    const [message, setMessage] = useState(null);

    const responseTranslationMap = {
        success: {
            message: "You’ve successfully unsubscribed from all aptihealth emails.",
            explanation: (
                <>
                    You’ll no longer receive emails from aptihealth, including session updates and
                    marketing communications. You will continue to receive account updates. If you’d
                    still like to receive certain types of email from us, you can manage your
                    communication preferences when logged into your aptihealth account under
                    Settings {">"} Notifications.{" "}
                </>
            ),
        },
        error: {
            message: "Your unsubscribe was not successful.",
            explanation: (
                <>
                    Your unsubscribe was not successful. Please contact our support team at{" "}
                    <a
                        className="a-link"
                        href="mailto: support@aptihealth.com?subject=Web Support Request">
                        support@aptihealth.com
                    </a>{" "}
                    for further assistance. You can also manage your communication preferences when
                    logged in to your aptihealth account under Settings {">"} Notifications.
                </>
            ),
        },
    };

    useEffect(() => {
        try {
            const id = getQueryParams("id", props.location.search);
            if (id) {
                const queryParams = { id };
                api.public
                    .update_preferences({ queryParams })
                    .then((response) => setMessage(responseTranslationMap["success"]))
                    .catch((e) => {
                        console.log(e);
                        setMessage(responseTranslationMap["error"]);
                    });
            }
        } catch (e) {
            console.log(e);
        }
    }, []);

    return (
        <>
            <div className={"unsub-content"}>
                <div className={"bg-purple p-md-5 px-3 py-4 d-flex justify-content-between"}>
                    <img
                        className={"unsub-logo"}
                        src={images("./logo/logo-and-tag.png")}
                        alt={"aptihealth"}
                    />
                    <div className={"mt-md-4 mt-0"}>
                        <Button
                            className={"Btn Btn--otl-sec Btn--sm-2"}
                            style={{ borderRadius: 7 }}
                            onClick={() => {
                                props.history.push("/auth/sign-in");
                            }}>
                            Log In
                        </Button>
                    </div>
                </div>
                <div className={"m-md-5 p-md-5 p-4"}>
                    {message && (
                        <>
                            <div className={"fs-lg-32 fs-20 fw-bold proxima mb-3"}>
                                {message["message"]}
                            </div>
                            <div className={"fs-lg-18 fs-16"}>{message["explanation"]}</div>
                        </>
                    )}
                </div>
            </div>
            <div className={"unsub-footer"}>
                <Footer />
            </div>
        </>
    );
};

export default withRouter(Unsubscribe);
