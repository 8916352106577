// Copyright aptihealth, inc. 2020 All Rights Reserved
import React from "react";
import "./styles.scss";
import CardSecondary from "../../UI/Card/CardSecondary";
import CardHeading from "../../UI/Headings/CardHeading";
import Button from "../../UI/Button";

const extractLabel = (practiceDetails) => {
    if (practiceDetails.is_spmi) {
        return "SPMI";
    } else if (practiceDetails.hcp_searchable) {
        return "HCP";
    }

    return "None";
};

const PracticeDetails = (props) => {
    let { practiceDetails, onclick, existingIntakeProviders, providers } = props;

    const defaultPCP = providers.find(
        (provider) => provider["value"] === practiceDetails["default_pcp_id"],
    );

    return (
        <div data-public={"true"}>
            <CardSecondary className="mx-auto container Practice__Details">
                <div className="d-flex justify-content-between py-4">
                    <CardHeading className="fs-18 m-0 px-3 px-lg-4" text="Practice Details" />
                    <Button
                        onClick={onclick}
                        className="ProviderProfile__ProfileDetailsCard-controls cursor mr-2 fs-14 fw-bold mb-3">
                        Edit
                    </Button>
                </div>
                <hr className="m-0 d-none d-md-block" />
                <div className=" px-3 px-lg-4">
                    <div className="row no-gutters mt-4">
                        <div className="col-12 col-lg-6">
                            <CardHeading text="Practice Name" className="fs-14 mx-0 mx-md-3" />
                            <p className="fs-14 mx-0 mx-md-3 mb-4">{practiceDetails.name}</p>
                        </div>
                        <div className="col-12 col-lg-6">
                            <CardHeading text="Practice Type" className="fs-14 mx-0 mx-md-3" />
                            <p className="fs-14 mx-0 mx-md-3 mb-4">
                                {practiceDetails.practice_type}
                            </p>
                        </div>
                    </div>
                    <div className="row no-gutters mt-4">
                        <div className="col-12 col-lg-6">
                            <CardHeading text="Phone Number" className="fs-14 mx-0 mx-md-3" />
                            <p className="fs-14 mx-0 mx-md-3 mb-4">
                                {practiceDetails.phone_number}
                            </p>
                        </div>
                        <div className="col-12 col-lg-6">
                            <CardHeading text="Fax Number" className="fs-14 mx-0 mx-md-3" />
                            <p className="fs-14 mx-0 mx-md-3 mb-4">{practiceDetails.fax_number}</p>
                        </div>
                    </div>
                    <div className="row no-gutters mt-4">
                        <div className="col-12 col-lg-6">
                            <CardHeading
                                text="General Email Address"
                                className="fs-14 mx-0 mx-md-3"
                            />
                            <p className="fs-14 mx-0 mx-md-3 mb-4">
                                {practiceDetails.general_email}
                            </p>
                        </div>
                        <div className="col-12 col-lg-6">
                            <CardHeading
                                text="Customer Success Manager"
                                className="fs-14 mx-0 mx-md-3"
                            />
                            <p className="fs-14 mx-0 mx-md-3 mb-4">
                                {practiceDetails.customer_success_manager}
                            </p>
                        </div>
                    </div>
                    <div className="row no-gutters mt-4">
                        <div className="col-12 col-lg-6">
                            <CardHeading
                                text="Office Manager Name"
                                className="fs-14 mx-0 mx-md-3"
                            />
                            <p className="fs-14 mx-0 mx-md-3 mb-4">
                                {practiceDetails.office_manager_name}
                            </p>
                        </div>
                        <div className="col-12 col-lg-6">
                            <CardHeading text="Practice Address" className="fs-14 mx-0 mx-md-3" />
                            <p className="fs-14 mx-0 mx-md-3 mb-4">
                                {practiceDetails.address.street +
                                    ", " +
                                    practiceDetails.address.city +
                                    ", " +
                                    practiceDetails.address.state +
                                    " " +
                                    practiceDetails.address.zip_code}
                            </p>
                        </div>
                    </div>

                    <div className="row no-gutters my-4">
                        <div className="col-12 col-lg-6">
                            <CardHeading
                                text="Practice Specialization"
                                className="fs-14 mx-0 mx-md-3"
                            />
                            <p className="fs-14 mx-0 mx-md-3 mb-4">
                                {extractLabel(practiceDetails)}
                            </p>
                        </div>
                    </div>
                    {practiceDetails.practice_type === "Primary Care" && (
                        <div className="row no-gutters my-4">
                            <div className="col-12 col-lg-6">
                                <CardHeading
                                    text="Intake Coordinator(s)"
                                    className="fs-14 mx-0 mx-md-3"
                                />
                                <ul className="fs-14 mx-0 mx-md-3 mb-4">
                                    {existingIntakeProviders &&
                                        existingIntakeProviders.map((intakeProvider) => {
                                            return <li>{intakeProvider.label}</li>;
                                        })}
                                </ul>
                            </div>

                            <div className="col-12 col-lg-6">
                                <CardHeading text="Default PCP" className="fs-14 mx-0 mx-md-3" />
                                <p className="fs-14 mx-0 mx-md-3 mb-4">
                                    {defaultPCP ? defaultPCP.display : "None"}
                                </p>
                            </div>
                        </div>
                    )}

                    <hr className="m-0" />
                </div>
            </CardSecondary>
        </div>
    );
};

export default PracticeDetails;
