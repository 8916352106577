import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";

export const FauxInput = ({
    children,
    className,
    placeholder,
    icon,
    onClick,
    onFocus,
    onBlur,
    ...props
}) => (
    <div
        className={createClassNameString(["apti-CL apti-FauxInput", className])}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        {...props}>
        <>
            {children.length &&
            children.reduce((previousValue, currentValue) => {
                switch (typeof currentValue) {
                    case "array":
                        return !!currentValue.length || previousValue;
                    default:
                        return !!currentValue || previousValue;
                }
            }).length !== 0
                ? children
                : placeholder}
            {icon}
        </>
    </div>
);

FauxInput.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    className: PropTypes.string,
    placeholder: PropTypes.string,
    icon: PropTypes.element,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
};

FauxInput.defaultProps = {
    children: <></>,
    className: undefined,
    placeholder: "",
    icon: <></>,
    onClick: () => {},
    onFocus: () => {},
    onBlur: () => {},
};
