// Copyright aptihealth, inc. 2021 All Rights Reserved

import images from "../../../utils/images";
import React from "react";
import "./styles.scss";
import { createClassNameString } from "../../../component-library";

export const BrandedHeader = ({ className }) => {
    return (
        <>
            <header
                className={createClassNameString(["header-container", className])}
                style={{ position: "relative" }}>
                <div className="header-container-inner d-flex justify-content-center">
                    <img
                        src={images("./common/apti_logo_w_tagline_v2.svg")}
                        alt="aptihealth"
                        className={"apti-logo-w-tagline py-2 mx-3"}
                    />
                </div>
            </header>
        </>
    );
};
