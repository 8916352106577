import React from "react";
import "./styles.scss";

export const BubbleFillButton = (props) => {
    const {
        optionLabelMap,
        question,
        onClick,
        isEditing,
        ratingKey,
        isButtonTextActive = false,
    } = props;

    const answerKey = ratingKey ? ratingKey : "answer";

    const bubbleFillRender = Object.entries(optionLabelMap).map(([key, value], index) => {
        const optionsClasses =
            question[answerKey] !== null && question[answerKey] === index
                ? "BubbleFill text-center d-inline-flex w-100 BubbleFill-active"
                : "BubbleFill text-center d-inline-flex w-100" +
                  (props.optionsClasses ? props.optionsClasses : "");
        const answerValue = parseInt(key);

        let handleClick;

        if (isEditing) {
            handleClick = (e) => {
                const dataAnswerValue = e.target.attributes.data_answer_value;
                const answer = parseInt(dataAnswerValue.value);
                onClick(answer);
            };
        } else {
            optionsClasses = optionsClasses + " disabled";
        }

        const buttonContent = isButtonTextActive ? (
            <span onClick={handleClick} data_answer_value={answerValue} className={optionsClasses}>
                {value}
            </span>
        ) : (
            <span onClick={handleClick} data_answer_value={answerValue} className={optionsClasses}>
                &nbsp;
            </span>
        );

        return (
            <div
                height={{ height: "initial" }}
                className={"cursor col-3 justify-content-center d-flex flex-column"}>
                {buttonContent}
            </div>
        );
    });

    return <div className="row h-100">{bubbleFillRender}</div>;
};
