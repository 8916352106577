import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { createClassNameString } from "../../component-library";
import { ICON_SIZES, ICON_TYPES } from "../../constants/icons";

export const EnvelopeIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 8C2 6.89543 2.89543 6 4 6H28C29.1046 6 30 6.89543 30 8V24C30 25.1046 29.1046 26 28 26H4C2.89543 26 2 25.1046 2 24V8ZM5.41421 8H26.5858L16.7071 17.8787C16.3166 18.2692 15.6834 18.2692 15.2929 17.8787L5.41421 8ZM4 9.41421V22.5858L10.5858 16L4 9.41421ZM5.41421 24H26.5858L20 17.4142L18.1213 19.2929C16.9497 20.4645 15.0503 20.4645 13.8787 19.2929L12 17.4142L5.41421 24ZM28 22.5858V9.41421L21.4142 16L28 22.5858Z"
                />
            </svg>
        );
    }

    return null;
};

EnvelopeIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

EnvelopeIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
