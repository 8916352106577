import * as yup from "yup";
import {
    getCareGoalAndObjectiveNoteVersion,
    getCareGoalAndObjectivesFromNote,
} from "../../WorkflowFunctions";

export const INTERVENTION_AND_PLAN_CHARACTER_LIMIT_ERROR = "Cannot enter more than 4000 characters";
export const INTERVENTION_AND_PLAN_CHARACTER_LIMIT = 4000;
const GOAL_SCHEMA = yup.object().shape({
    interventions_and_plans: yup
        .string()
        .max(INTERVENTION_AND_PLAN_CHARACTER_LIMIT, INTERVENTION_AND_PLAN_CHARACTER_LIMIT_ERROR),
});
export const INTERVENTION_AND_PLAN_SCHEMA = yup.object().shape({
    goal_1: GOAL_SCHEMA,
    goal_2: GOAL_SCHEMA,
    goal_3: GOAL_SCHEMA,
});

export const buildInterventionAndPlanFormFromGoalAndObjectives = (
    getGoalAndObjectiveReturn,
    providerType,
) => {
    const providerGoalsAndObjectives = getGoalAndObjectiveReturn[providerType];
    const goalAttributesToKeep = [
        "entity_type",
        "goal_id",
        "goal_start_timestamp",
        "goal_text",
        "goal_other_text",
        "goal_text_id",
        "objectives",
        "provider_type",
        "sk",
    ];
    const objectiveAttributesToKeep = [
        "entity_type",
        "goal_id",
        "goal_text_id",
        "objective_id",
        "objective_start_timestamp",
        "objective_target_timestamp",
        "objective_text",
        "objective_other_text",
        "objective_text_id",
        "provider_type",
        "sk",
    ];
    const goalAndObjectiveForm = providerGoalsAndObjectives.reduce(
        (formResult, goalAndObjective, index) => {
            const newGoal = goalAttributesToKeep.reduce((goalResult, goalAttribute) => {
                goalResult[goalAttribute] = goalAndObjective[goalAttribute];
                return goalResult;
            }, {});
            const newObjectives = newGoal.objectives.map((objective) => {
                return objectiveAttributesToKeep.reduce((objectiveResult, objectiveAttribute) => {
                    objectiveResult[objectiveAttribute] = objective[objectiveAttribute];
                    return objectiveResult;
                }, {});
            });
            newGoal.objectives = newObjectives;

            // setup form state
            newGoal["interventions_and_plans"] = "";

            formResult[`goal_${index + 1}`] = newGoal;
            return formResult;
        },
        {},
    );

    return goalAndObjectiveForm;
};

/**
 *
 * @param {*} goalsAndObjectives
 * @returns a list of errors that can be consumed by a formik
 * form
 */
export const getInterventionAndPlanFormErrors = (goalsAndObjectives) => {
    const errors = Object.keys(goalsAndObjectives).reduce((obj, goalNumber) => {
        if (
            goalsAndObjectives[goalNumber].interventions_and_plans.length >
            INTERVENTION_AND_PLAN_CHARACTER_LIMIT
        ) {
            obj[goalNumber] = {
                interventions_and_plans: INTERVENTION_AND_PLAN_CHARACTER_LIMIT_ERROR,
            };
        }
        return obj;
    }, {});

    return errors;
};

/**
 *
 * @param {*} noteContent
 * @returns true if the form has errors false otherwise
 */
export const checkInterventionAndPlanErrors = (noteContent) => {
    if (getCareGoalAndObjectiveNoteVersion(noteContent) != "2") {
        return false;
    }

    const goalsAndObjectives = getCareGoalAndObjectivesFromNote(noteContent, "2");
    const hasErrors = Boolean(
        Object.keys(getInterventionAndPlanFormErrors(goalsAndObjectives)).length,
    );

    return hasErrors;
};
