// PLEASE KEEP THIS FILE TO SIMPLE FUNCTIONS / CONSTANTS THAT DO NOT RELY ON IMPORTS
// SO JEST TESTS CAN USE THIS FILE

export const EVENT_BUBBLE_PADDING_CONSTANT = 14;
export const TEENS_AND_KIDS_DIAGNOSTIC_INTERVIEW_90791_ALLOTTED_TIME = 90;

/** mode for new schedule type (away, video-consultation etc) */
export const VIDEO_CONSULTATION = "video_consultation";
export const AWAY = "away";
export const AVAILABLE = "available";
export const DIAGNOSTIC_INTERVIEW_90791 = "diagnostic_interview_90791";
export const DIAGNOSTIC_INTERVIEW_90792 = "diagnostic_interview_90792";
export const _30_MIN_INDIVIDUAL_90832 = "30_min_individual_90832";
export const _45_MIN_INDIVIDUAL_90834 = "45_min_individual_90834";
export const _60_MIN_INDIVIDUAL_90837 = "60_min_individual_90837";
export const CRISIS_EVAL_90839 = "crisis_eval_90839";
export const CRISIS_EVAL_EXTENDED_90840 = "crisis_eval_extended_90840";
export const FAMILY_THERAPY_90847 = "family_psychotherapy_w_patient_90847";
export const FAMILY_THERAPY_WO_PATIENT_90846 = "family_psychotherapy_wo_patient_90846";
export const MEDICATION_MGMT_99212 = "medication_mgmt_99212";
export const MEDICATION_MGMT_99213 = "medication_mgmt_99213";
export const MEDICATION_MGMT_99214 = "medication_mgmt_99214";
export const NO_LINKED_CALL_0 = "no_linked_call_0";
export const INTERPRET_A5_OR_A30_96130 = "interpret_a5_and/or_a30_96130";
export const A30_ADMINISTRATION_96146 = "a30_administration_96146";
export const IN_APP_MESSAGING_98970 = "in_app_messaging_98970";
export const IN_APP_MESSAGING_98971 = "in_app_messaging_98971";
export const IN_APP_MESSAGING_98972 = "in_app_messaging_98972";
export const IN_APP_MESSAGING_99421 = "in_app_messaging_99421";
export const IN_APP_MESSAGING_99422 = "in_app_messaging_99422";
export const IN_APP_MESSAGING_99423 = "in_app_messaging_99423";
export const _5_10_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98966 =
    "_5_10_phone_assessment_and_mgmt_vs_med_discussion_98966";
export const _11_20_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98967 =
    "_11_20_phone_assessment_and_mgmt_vs_med_discussion_98967";
export const _21_30_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98968 =
    "_21_30_phone_assessment_and_mgmt_vs_med_discussion_98968";
export const CM_SERVICES_20_MINS_MINIMUM_MONTH =
    "CM_Services_min_20_mins_minimum/calendar_month_99484";
export const PHQ_OR_GAD_96127 = "phq_or_gad_96127";
export const OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204 =
    "office_or_other_outpatient_visit_w_new_patient_99204";
export const OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205 =
    "office_or_other_outpatient_visit_w_new_patient_99205";
export const ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215 =
    "established_patient_office_or_other_outpatient_services_99215";
export const NON_BILLABLE = "non_billable";
export const BUSY = "busy";

export const BEHAVIORAL = "BEHAVIORAL";
export const BEHAVIORAL_INTAKE = "BEHAVIORAL_INTAKE";
export const PRESCRIBE = "PRESCRIBE";
export const PCP = "PCP";
export const CARE_MANAGER = "CARE_MANAGER";

/**
 * a special event is defined as custom availability or away time
 * @param {*} eventType
 * @returns
 */
export const isSpecialEvent = (eventType) => {
    return eventType === AWAY || eventType === AVAILABLE;
};
