// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Fragment, useEffect, useState } from "react";
import "./styles.scss";
import {
    List,
    ListBody,
    ListHeader,
    ListItem,
    ListTitleBar,
    TitleBarItem,
} from "../../UI/ListView";
import Button from "../../UI/Button";
import FAB from "./FAB";
import { withRouter } from "react-router-dom";
import ContextMenu from "./ContextMenu";
import ScheduleListItemMobile from "./SheduleListItemMobile";
import ScheduleListItemDesktop from "./SheduleListItemDesktop";
import moment from "moment-timezone";
import images from "../../../utils/images";
import ListLoader from "./ListLoader";
import CancellationPolicy from "./CancellationPolicy";
import { CancellationPolicyPopup } from "./CancellationPolicyPopup";
import { connect } from "react-redux";
import { getPatientProfile } from "../../../redux/actions/auth";
import ScheduleDialog from "../CancellationPolicy/ScheduleDialog";
import SchedulingUnavailable from "./SchedulingUnavailable";
import SchedulingWaitlisted from "./SchedulingWaitlisted";
import { isCareTeamRestricted } from "../../../utils/scheduleUtils";
import { useCurrentUTCTime } from "../../../utils/hooks/useCurrentTime";

const AvailableSchedulesList = (props) => {
    let { schedules, deleteShedule, fetchUpdatedSchedules, isAddNewDisabled, scheduleLoaded } =
        props;
    const [popUpData, setPopUpData] = useState(null); // shows and hide three dots menu pop up
    const [scheduleToBeDeleted, setScheduleToBeDeleted] = useState(null); // sets the data of schedule to be deleted.
    const [type, setType] = useState("");
    const [showCancellationPolicy, setShowCancellationPolicy] = useState(false);
    const { currentTime } = useCurrentUTCTime({ interval: 5000 });

    const documentClickHandler = function (e) {
        let target = e.target;
        let isClickFromInside = target.closest(
            ".ASCH__Desktop-context-menu,.ASCH__Mobile-context-menu",
        )
            ? true
            : false;
        if (popUpData && !isClickFromInside) {
            setPopUpData(null);
        }
    };

    /**
     * TODO: optimise useEffect for attachment/detachment of click listener.
     * Currently event listener is attached and detached on every popUpData change
     * */
    useEffect(() => {
        document.addEventListener("click", documentClickHandler);
        return () => {
            document.removeEventListener("click", documentClickHandler);
        };
    }, [popUpData, props.profile.assessment_status]);

    const getPrettyTimeDetails = (scheduleTimestamp) => {
        let localScheduledDateTime = moment.utc(scheduleTimestamp, "YYYY-MM-DD HH:mm").local();
        let scheduledDate = localScheduledDateTime.format("DD MMM YYYY");
        let scheduledTime = localScheduledDateTime.format("HH:mm");
        let hour = scheduledTime.split(":")[0];
        let is_AM = hour < 12;
        let isMorning = hour >= 0 && hour <= 11;
        let isAfternoon = hour >= 12 && hour <= 16;
        let isEvening = hour >= 17 && hour <= 24;
        let isToday = localScheduledDateTime.isSame(new Date(), "day");

        return {
            scheduledDate,
            scheduledTime,
            is_AM,
            isToday,
            isMorning,
            isAfternoon,
            isEvening,
        };
    };

    const addNewSheduleHandler = () => {
        props.history.push("/app/schedule/assign?mode=new");
    };

    /**
     * Delete schedule related handlers
     */
    const showDeletePopUp = (sheduleDetails) => {
        setPopUpData(null);
        setType("cancel");
        setScheduleToBeDeleted(sheduleDetails);
    };

    const dismissDeletePopUp = () => {
        setScheduleToBeDeleted(null);
    };

    const deleteScheduleHandler = async (formData) => {
        try {
            /**
             * deleteSchedule() from props below
             * is the http call via `api.shared.remove_appointment()` in parent component.
             */
            setType("");
            setScheduleToBeDeleted(null);
            const callId = formData.callId;
            let response = callId && (await deleteShedule(callId, formData));
            fetchUpdatedSchedules();
            props.getPatientProfile();
        } catch (err) {
            console.log(err);
        }
    };

    const editScheduleHandler = ({ provider_username, callId, allotted_time }) => {
        props.history.push(
            "/app/schedule/assign?mode=edit" +
                "&provider_id=" +
                provider_username +
                "&call_id=" +
                callId +
                "&allotted_time=" +
                allotted_time,
        );
    };

    const editSchedule = (provider_username, callId, allotted_time, sheduleDetails) => {
        setPopUpData(null);
        setType("reschedule");
        setScheduleToBeDeleted(sheduleDetails);
    };

    const getClickedSheduleDetails = (callId) => {
        let filteredShedule = schedules.filter((shd) => {
            return shd.callId == callId;
        })[0]; // array is returned, so we select '0' indexed item
        return constructListItemData(filteredShedule);
    };

    const menuClickHandler = (e, callId, provider_username) => {
        let rect = e.target.getBoundingClientRect();
        let popUpLeft;
        if (window.innerWidth > 991 && window.innerWidth < 1300) {
            popUpLeft = rect.left - 120;
        } else {
            popUpLeft = rect.left + 35;
        }
        let popUpTop = rect.top - 40;
        let contextMenuSheduleDetails = getClickedSheduleDetails(callId);
        setPopUpData({
            actions: {
                delete: { caption: "Cancel", handler: showDeletePopUp },
                edit: { caption: "Reschedule", handler: editSchedule },
            },
            position: { top: popUpTop, left: popUpLeft },
            sheduleDetails: contextMenuSheduleDetails,
        });
    };

    const onInfoClickHandler = () => {
        setShowCancellationPolicy(!showCancellationPolicy);
    };

    const renderControls = () => {
        return (
            <div className="d-none d-lg-flex" style={{ height: 43 }}>
                {scheduleLoaded && !isSchedulingUnavailable() && (
                    <Button
                        disabled={isAddNewDisabled}
                        onClick={addNewSheduleHandler}
                        className="Btn Btn--pri Btn-sm">
                        Add New
                    </Button>
                )}
            </div>
        );
    };

    // A utility function to generate List item data
    const constructListItemData = (schedule) => {
        const {
            provider_name,
            event_type,
            provider_username,
            provider_role,
            timestamp,
            callId,
            provider_avatar,
            current_time,
            patient_username,
            allotted_time,
        } = schedule;
        const email = props.profile?.email;
        const { scheduledDate, scheduledTime, is_AM, isToday, isMorning, isAfternoon, isEvening } =
            getPrettyTimeDetails(timestamp);
        return {
            provider_avatar,
            email,
            configs: props.configs,
            callId,
            provider_username,
            provider_name,
            event_type,
            provider_role,
            menuClickHandler,
            scheduledDate,
            scheduledTime,
            timestamp,
            current_time,
            currentTime,
            patient_username,
            is_AM,
            allotted_time,
            isToday,
            isMorning,
            isAfternoon,
            isEvening,
        };
    };

    let listItems = (
        <Fragment>
            {schedules &&
                schedules.map((schedule) => {
                    let ListItemData = constructListItemData(schedule);

                    return (
                        <ListItem key={schedule.callId}>
                            {/* shown only on mobile devices */}
                            <ScheduleListItemMobile {...ListItemData} />
                            {/* shown only on desktop */}
                            <ScheduleListItemDesktop {...ListItemData} />
                        </ListItem>
                    );
                })}
        </Fragment>
    );

    const MobileAddBtnClass = isAddNewDisabled
        ? "ASCH__ShedulesEmpty-Add position-fixed justify-content-center align-items-center d-flex d-lg-none ASCH__ShedulesEmpty-Add--disable"
        : "ASCH__ShedulesEmpty-Add position-fixed justify-content-center align-items-center d-flex d-lg-none ASCH__ShedulesEmpty-Add--enable";

    const submitHandler = type === "cancel" ? deleteScheduleHandler : editScheduleHandler;

    const isSchedulingUnavailable = () => {
        const { assessment_status, is_wait_listed, assessment_config } = props?.profile || {};
        const { skip_a5_for_referral } = assessment_config || {};
        const isWaitlisted =
            is_wait_listed &&
            props?.profile?.care_team_members
                ?.map((member) => isCareTeamRestricted(member["type"], props.profile))
                ?.reduce((prevMember, currMember) => prevMember && currMember, true);
        if (skip_a5_for_referral) {
            return false;
        }
        return !assessment_status || isWaitlisted;
    };

    const isSchedulingRestricted = () => {
        return props.profile?.is_scheduling_restricted;
    };

    const renderSchedulingUnavailableComponent = () => {
        const { assessment_status, is_wait_listed } = props?.profile || {};
        const isWaitlisted =
            is_wait_listed &&
            props.profile?.care_team_members?.reduce((prevVal, member) => {
                return prevVal && isCareTeamRestricted(member["type"], props.profile);
            }, true);
        if (!assessment_status) {
            return <SchedulingUnavailable />;
        } else if (isWaitlisted) {
            return <SchedulingWaitlisted />;
        }
    };

    return (
        <>
            <div className="pt-2 position-relative">
                <CancellationPolicy onInfoClickHandler={onInfoClickHandler} />

                <ListBody>
                    <ListHeader
                        className="d-none d-lg-block"
                        title={"Schedule"}
                        render={renderControls}
                    />

                    {scheduleLoaded &&
                        isSchedulingUnavailable() &&
                        renderSchedulingUnavailableComponent()}

                    {scheduleLoaded && !isSchedulingUnavailable() && (
                        <>
                            <ListTitleBar>
                                <TitleBarItem classes="col-1" text="" />
                                <TitleBarItem classes="col-2" text="Name" />
                                <TitleBarItem classes="col-2" text="Specialist" />
                                <TitleBarItem classes="col-2" text="Date" />
                                <TitleBarItem classes="col-1" text="Time" />
                                <TitleBarItem classes="col-1" text="" />
                                <TitleBarItem classes="col-1" text="" />
                            </ListTitleBar>
                            <>{!schedules ? <ListLoader /> : listItems}</>
                        </>
                    )}
                </ListBody>
                <FAB />
                {popUpData && (
                    <ContextMenu
                        position={popUpData.position}
                        isEditDisabled={isSchedulingRestricted()}
                        actions={popUpData.actions}
                        sheduleDetails={popUpData.sheduleDetails}
                    />
                )}
                {scheduleToBeDeleted && (
                    <ScheduleDialog
                        type={type}
                        cancelHandler={dismissDeletePopUp}
                        activeScheduleDetails={scheduleToBeDeleted}
                        submitHandler={submitHandler}
                    />
                )}
            </div>

            {showCancellationPolicy && (
                <CancellationPolicyPopup
                    onClickHandler={onInfoClickHandler}
                    showCancellationPolicy={showCancellationPolicy}
                />
            )}
            <Button
                disabled={isAddNewDisabled}
                onClick={addNewSheduleHandler}
                className={MobileAddBtnClass}>
                <img className="img-fluid" src={images("./icons/add-white-btn.svg")} />
            </Button>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
        configs: state.configs,
    };
};

export default connect(mapStateToProps, { getPatientProfile })(withRouter(AvailableSchedulesList));
