// Copyright aptihealth, inc. 2019 All Rights Reserved

/**
 * Formats the phone number passed as string to the format `(xxx) xxx-xxxx`
 *
 * @param {string} val - the unformated phone number
 * @returns {string} The formatted phone number.
 */
export const formatMobileNumber = function (val) {
    if (val === "None") {
        return val;
    }

    let formattedVal = "";
    let firstNumber = val.substring(0, 3);
    let middleNumber = val.substring(3, 6);
    let lastNumber = val.substring(6, 10);
    if (firstNumber.length) {
        formattedVal = `(${firstNumber})`;
    }
    if (firstNumber.length && middleNumber.length) {
        formattedVal = `(${firstNumber}) ${middleNumber}`;
    }
    if (firstNumber.length && middleNumber.length && lastNumber.length) {
        formattedVal = `(${firstNumber}) ${middleNumber}-${lastNumber}`;
    }

    return formattedVal;
};
