// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import CardSecondary from "../../UI/Card/CardSecondary";
import CardHeading from "../../UI/Headings/CardHeading";
import { Link } from "react-router-dom";
import moment from "moment";

function AssociatedFiles(props) {
    const { viewAllLink, fileList, getFile } = props;
    return (
        <CardSecondary className="LatestReports PatientProfile__CardBetweenMargin mx-lg-auto px-3 px-lg-4">
            <div className="pt-2">
                <div className="py-4 d-flex justify-content-between">
                    <CardHeading className="fs-16 mx-3 my-0" text="Associated Files" />
                    <div className="PatientRatingCard__view-all-link-wpr fs-14">
                        <Link to={viewAllLink}>View All</Link>
                    </div>
                </div>
            </div>
            <hr className="m-0" />
            <div className="my-2">
                {fileList &&
                    fileList
                        .slice(0, 2)
                        .filter((file) => file)
                        .map((file) => {
                            return (
                                <div
                                    role="button"
                                    onClick={() => getFile(file.file_key)}
                                    key={file.file_key}
                                    className="LatestReports__download-btn d-flex justify-content-between align-items-center mx-2">
                                    <div className="my-3">
                                        <CardHeading
                                            text={file.file_key.substring(
                                                file.file_key.lastIndexOf("/") + 1,
                                            )}
                                            className="fs-14"
                                        />
                                        <p className="fs-14 my-0">
                                            {moment
                                                .utc(file.timestamp)
                                                .local()
                                                .format("MM/DD/YYYY HH:mm")}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
            </div>
        </CardSecondary>
    );
}

export default AssociatedFiles;
