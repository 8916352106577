// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import Button from "../../../components/UI/Button";
import { getAutomationDataAttr } from "../../../utils/automationUtils";

const SubmitControls = (props) => {
    const { cancelButtonText = "Cancel", cancelHandler, submitButtonText = "Save" } = props;
    return (
        <div className="PatientProfile__SubmitControls d-flex justify-content-around text-center justify-content-lg-end">
            <Button
                type="button"
                onClick={cancelHandler}
                className="Btn Btn--otl-pri Btn-sm mr-2 mr-lg-3 mb-lg-0"
                {...getAutomationDataAttr(props.testId ? `${props.testId}Cancel` : undefined)}>
                {cancelButtonText}
            </Button>
            <Button
                type="submit"
                className="Btn Btn--pri ml-2 Btn-sm"
                {...getAutomationDataAttr(props.testId ? `${props.testId}Save` : undefined)}>
                {submitButtonText}
            </Button>
        </div>
    );
};

export default SubmitControls;
