import React from "react";
import _ from "lodash";
import CardSecondary from "../../../UI/Card/CardSecondary";
import { ExpandingSelect } from "../../../Common/GuidedVideoCall/Steps/ProviderWorkflow/DisplayWorkflowStepWithVideoClient";

export const MENTAL_STATUS_QUESTIONS = {
    appearance: {
        label: "Appearance",
        type: "single",
        options: {
            healthy: {
                label: "Healthy",
                answer: false,
                order: 1,
            },
            sickly: {
                label: "Sickly",
                answer: false,
                order: 2,
            },
            neat: {
                label: "Neat",
                answer: false,
                order: 3,
            },
            disheveled: {
                label: "Disheveled",
                answer: false,
                order: 4,
            },
            inappropriate: {
                label: "Inappropriate",
                answer: false,
                order: 5,
            },
        },
    },
    behavior: {
        label: "Behavior",
        type: "multi",
        options: {
            cooperative: {
                label: "Cooperative",
                answer: false,
                order: 1,
            },
            ingratiating: {
                label: "Ingratiating",
                answer: false,
                order: 2,
            },
            apathetic: {
                label: "Apathetic",
                answer: false,
                order: 3,
            },
            defensive: {
                label: "Defensive",
                answer: false,
                order: 4,
            },
            aggressive: {
                label: "Aggressive",
                answer: false,
                order: 5,
            },
        },
    },
    affect: {
        label: "Affect",
        type: "multi",
        options: {
            appropriate: {
                label: "Appropriate",
                answer: false,
                order: 1,
            },
            broad: {
                label: "Broad",
                answer: false,
                order: 2,
            },
            restricted: {
                label: "Restricted",
                answer: false,
                order: 3,
            },
            flat: {
                label: "Flat",
                answer: false,
                order: 4,
            },
            inappropriate: {
                label: "Inappropriate",
                answer: false,
                order: 5,
            },
        },
    },
    mood: {
        label: "Mood",
        type: "single",
        options: {
            euthymic: {
                label: "Euthymic",
                answer: false,
                order: 1,
            },
            anxious: {
                label: "Anxious",
                answer: false,
                order: 2,
            },
            irritable: {
                label: "Irritable",
                answer: false,
                order: 3,
            },
            dysphoric: {
                label: "Dysphoric",
                answer: false,
                order: 4,
            },
            grief_stricken: {
                label: "Grief Stricken",
                answer: false,
                order: 5,
            },
        },
    },
    memory: {
        label: "Memory",
        type: "single",
        options: {
            none: {
                label: "No memory problems",
                answer: false,
                order: 1,
            },
            immediate: {
                label: "Immediate",
                answer: false,
                order: 2,
            },
            short_term: {
                label: "Short-term",
                answer: false,
                order: 3,
            },
            long_term: {
                label: "Long-term",
                answer: false,
                order: 4,
            },
            remote: {
                label: "Remote",
                answer: false,
                order: 5,
            },
        },
    },
    delusions: {
        label: "Delusions",
        type: "multi",
        options: {
            none: {
                label: "No delusions",
                answer: false,
                order: 1,
            },
            persecutory: {
                label: "Persecutory",
                answer: false,
                order: 2,
            },
            somatic: {
                label: "Somatic",
                answer: false,
                order: 3,
            },
            grandeur: {
                label: "Grandeur",
                answer: false,
                order: 4,
            },
            ideas_of_reference: {
                label: "Ideas of reference",
                answer: false,
                order: 5,
            },
        },
    },
    hallucinations: {
        label: "Hallucinations",
        type: "multi",
        options: {
            none: {
                label: "No hallucinations",
                answer: false,
                order: 1,
            },
            auditory: {
                label: "Auditory",
                answer: false,
                order: 2,
            },
            visual: {
                label: "Visual",
                answer: false,
                order: 3,
            },
            tactile: {
                label: "Tactile",
                answer: false,
                order: 4,
            },
            olfactory: {
                label: "Olfactory",
                answer: false,
                order: 5,
            },
            gustatory: {
                label: "Gustatory",
                answer: false,
                order: 6,
            },
        },
    },
    self_harm: {
        label: "Self-Harm",
        type: "single",
        options: {
            none: {
                label: "None",
                answer: false,
                order: 1,
            },
            ideation: {
                label: "Ideation",
                answer: false,
                order: 2,
            },
            plan: {
                label: "Plan",
                answer: false,
                order: 3,
            },
            intent: {
                label: "Intent",
                answer: false,
                order: 4,
            },
            recent: {
                label: "Recent Attempt",
                answer: false,
                order: 5,
            },
        },
    },
    other_harm: {
        label: "Other’s Harm",
        type: "single",
        options: {
            none: {
                label: "None",
                answer: false,
                order: 1,
            },
            ideation: {
                label: "Ideation",
                answer: false,
                order: 2,
            },
            plan: {
                label: "Plan",
                answer: false,
                order: 3,
            },
            intent: {
                label: "Intent",
                answer: false,
                order: 4,
            },
            recent: {
                label: "Recent Attempt",
                answer: false,
                order: 5,
            },
        },
    },
    judgement: {
        label: "Judgement",
        type: "single",
        options: {
            good: {
                label: "Good",
                answer: false,
                order: 1,
            },
            fair: {
                label: "Fair",
                answer: false,
                order: 2,
            },
            poor: {
                label: "Poor",
                answer: false,
                order: 3,
            },
            impaired: {
                label: "Impaired",
                answer: false,
                order: 4,
            },
            grossly_impaired: {
                label: "Grossly Impaired",
                answer: false,
                order: 5,
            },
        },
    },
};

export const BehavioralMentalStatus = (props) => {
    const { note, profileView, createEditView, editable, editHandler, showTitle } = props;

    let mentalStatus = note.content.mental_status;

    const [editCopy, setEditCopy] = React.useState(_.cloneDeep(mentalStatus));

    const editHandlerBuilder = (sectionLabel, deeperLabels = []) => {
        return (e, index, key) => {
            let editCopyClone = _.cloneDeep(editCopy);

            let deeperObject = editCopyClone;
            deeperLabels.forEach((label) => {
                deeperObject = deeperObject[label];
            });
            deeperObject[index][key] = !deeperObject[index][key];

            if (index === "none") {
                Object.entries(deeperObject).forEach(([sectionKey, value]) => {
                    if (sectionKey !== "none") {
                        value[key] = false;
                    }
                });
            } else {
                if (deeperObject["none"]) {
                    deeperObject["none"][key] = false;
                }
            }

            setEditCopy(editCopyClone);
            editHandler(sectionLabel, editCopyClone);
        };
    };

    let title = showTitle ? (
        <>
            <div>
                <div className="clr-sec fw-bold float-left">Mental Status</div>
            </div>
            <br />
        </>
    ) : (
        <div />
    );

    let innerContent;
    if (!editable) {
        innerContent = (
            <>
                {title}
                <div className="row print-wrap">
                    {Object.entries(editCopy).map(([key, value]) => {
                        let answer = "None";
                        let answerCombined = [];

                        Object.entries(value.options).map(([optionKey, optionValue]) => {
                            if (optionKey !== "none" && optionValue.answer) {
                                answerCombined.push(optionValue.label);
                            }
                        });

                        if (answerCombined.length > 0) {
                            answer = answerCombined.join(", ");
                        }

                        let colCss = profileView || createEditView ? "col-12" : "col-6";

                        return (
                            <div
                                key={key}
                                className={`${colCss} justify-content-center my-1 py-1 fs-14`}>
                                <label>
                                    <input
                                        type="checkbox"
                                        className="custom-checkbox mr-2"
                                        checked={answer !== "None"}
                                    />
                                    {value.label} - {answer}
                                </label>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    } else {
        innerContent = (
            <>
                {title}
                <div className="row">
                    {Object.entries(editCopy).map(([sectionKey, sectionValue]) => {
                        return (
                            <ExpandingSelect
                                className="ml-3 mb-2 mt-1 col-5"
                                label={sectionValue.label}
                                labelClass="clr-sec"
                                options={sectionValue.options}
                                onChange={editHandlerBuilder("mental_status", [
                                    sectionKey,
                                    "options",
                                ])}
                            />
                        );
                    })}
                </div>
            </>
        );
    }

    let content;
    if (!profileView) {
        content = (
            <CardSecondary className="mx-0 px-3 py-3 my-3 max-width" style={{ minHeight: 150 }}>
                {innerContent}
            </CardSecondary>
        );
    } else {
        content = (
            <div className="" style={{ minHeight: 150 }}>
                {innerContent}
            </div>
        );
    }

    return <>{content}</>;
};
