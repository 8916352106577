/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const EnvelopeIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Envelope"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 8C2 6.89543 2.89543 6 4 6H28C29.1046 6 30 6.89543 30 8V24C30 25.1046 29.1046 26 28 26H4C2.89543 26 2 25.1046 2 24V8ZM5.41421 8H26.5858L16.7071 17.8787C16.3166 18.2692 15.6834 18.2692 15.2929 17.8787L5.41421 8ZM4 9.41421V22.5858L10.5858 16L4 9.41421ZM5.41421 24H26.5858L20 17.4142L18.1213 19.2929C16.9497 20.4645 15.0503 20.4645 13.8787 19.2929L12 17.4142L5.41421 24ZM28 22.5858V9.41421L21.4142 16L28 22.5858Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Envelope"
                {...props}>
                <path d="M2.06774 7.48196C2.02356 7.64718 2 7.82084 2 8V24C2 24.1792 2.02356 24.3528 2.06776 24.5181L10.5858 16L2.06774 7.48196Z" />
                <path d="M3.482 25.9323C3.64722 25.9764 3.82086 26 4 26H28C28.1792 26 28.3528 25.9764 28.518 25.9323L20 17.4142L18.1213 19.2929C16.9498 20.4645 15.0503 20.4645 13.8787 19.2929L12 17.4142L3.482 25.9323Z" />
                <path d="M29.9323 24.518C29.9764 24.3528 30 24.1792 30 24V8C30 7.82086 29.9764 7.64722 29.9323 7.482L21.4142 16L29.9323 24.518Z" />
                <path d="M28.5181 6.06776C28.3528 6.02356 28.1792 6 28 6H4C3.82084 6 3.64718 6.02356 3.48196 6.06774L15.2929 17.8787C15.6834 18.2692 16.3166 18.2692 16.7071 17.8787L28.5181 6.06776Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Envelope"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM29 12.5C29.1344 12.5 29.2646 12.5177 29.3886 12.5508L20.5304 21.409C20.2375 21.7019 19.7626 21.7019 19.4697 21.409L10.6115 12.5508C10.7354 12.5177 10.8656 12.5 11 12.5H29ZM9.5 14C9.5 13.8656 9.51767 13.7354 9.55081 13.6115L15.9394 20L9.55082 26.3886C9.51767 26.2646 9.5 26.1344 9.5 26V14ZM11 27.5C10.8656 27.5 10.7354 27.4823 10.6115 27.4492L17 21.0607L18.409 22.4697C19.2877 23.3484 20.7123 23.3484 21.591 22.4697L23 21.0607L29.3885 27.4492C29.2646 27.4823 29.1344 27.5 29 27.5H11ZM30.4492 26.3885C30.4823 26.2646 30.5 26.1344 30.5 26V14C30.5 13.8656 30.4823 13.7354 30.4492 13.6115L24.0607 20L30.4492 26.3885Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

EnvelopeIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

EnvelopeIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
