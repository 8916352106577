import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Card, CheckboxOld, Image } from "../../../component-library";
import { updateCalendarFilters } from "../../../redux/actions/calendar";
import CloseOnOutsideClick from "../../Common/CloseOnOutsideClick/CloseOnOutsideClick";
import "./styles.scss";

const CalendarFilters = ({ calendar, updateCalendarFilters }) => {
    const { calendarFilters } = calendar;
    const { scheduledEvents, cancelledEvents, noShowEvents, rescheduledEvents } = calendarFilters;
    const [showDropdown, setShowDropdown] = useState(false);

    return (
        <CloseOnOutsideClick
            className={"d-flex align-items-center"}
            setShowComponent={setShowDropdown}>
            <Button
                className={"apti-CalendarFilters__FilterButton"}
                onClick={() => setShowDropdown(!showDropdown)}>
                <Image
                    src={"icons/calendar-filters.svg"}
                    className={"apti-ScheduleNavControls--icon"}
                    alt="calender-filters"
                />
            </Button>

            {showDropdown && (
                <Card className={"apti-CalendarFilters__Dropdown"}>
                    <div
                        className={"apti-CalendarFilters__Dropdown__Option"}
                        role={"button"}
                        onClick={() =>
                            updateCalendarFilters({
                                ...calendarFilters,
                                scheduledEvents: !scheduledEvents,
                            })
                        }>
                        <CheckboxOld checked={scheduledEvents} />
                        <span className={"apti-CalendarFilters__Dropdown__Option__Text"}>
                            Scheduled Events
                        </span>
                    </div>
                    <div
                        className={"apti-CalendarFilters__Dropdown__Option"}
                        role={"button"}
                        onClick={() =>
                            updateCalendarFilters({
                                ...calendarFilters,
                                cancelledEvents: !cancelledEvents,
                            })
                        }>
                        <CheckboxOld checked={cancelledEvents} />
                        <span className={"apti-CalendarFilters__Dropdown__Option__Text"}>
                            Cancellations
                        </span>
                    </div>
                    <div
                        className={"apti-CalendarFilters__Dropdown__Option"}
                        role={"button"}
                        onClick={() =>
                            updateCalendarFilters({
                                ...calendarFilters,
                                rescheduledEvents: !rescheduledEvents,
                            })
                        }>
                        <CheckboxOld checked={rescheduledEvents} />
                        <span className={"apti-CalendarFilters__Dropdown__Option__Text"}>
                            Reschedules
                        </span>
                    </div>
                    <div
                        className={"apti-CalendarFilters__Dropdown__Option"}
                        role={"button"}
                        onClick={() =>
                            updateCalendarFilters({
                                ...calendarFilters,
                                noShowEvents: !noShowEvents,
                            })
                        }>
                        <CheckboxOld checked={noShowEvents} />
                        <span className={"apti-CalendarFilters__Dropdown__Option__Text"}>
                            No-Shows
                        </span>
                    </div>
                </Card>
            )}
        </CloseOnOutsideClick>
    );
};

CalendarFilters.propTypes = {
    className: PropTypes.string,
};

CalendarFilters.defaultProps = {
    className: undefined,
};

const mapStateToProps = (state) => {
    return {
        calendar: state.calendar,
    };
};

const mapDispatchToProps = {
    updateCalendarFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarFilters);
