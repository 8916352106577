// Copyright aptihealth, inc. 2021 All Rights Reserved

import React, { useEffect, useState } from "react";
import "../style.scss";
import images from "../../../../../utils/images";
import _ from "lodash";
import ProfileDetailsCard from "../../../../Patient/Profile/ProfileDetailsCard";
import { formatMobileNumber } from "../../../../../utils/formInputUtils";

const makeAssociationRow = (association, props) => {
    const fullName = `${association.first_name} ${association.last_name}`;
    return (
        <tr>
            <td className="FamilyMemberWidget__name_column">
                <p className="FamilyMemberWidget__name_column_offset">{fullName}</p>
            </td>
            <td className="FamilyMemberWidget__email_column">{`${association.email}`}</td>
            <th className="FamilyMemberWidget__phone_column">
                {formatMobileNumber(association.mobile)}
            </th>
            <td className="FamilyMemberWidget__relationship_column">{`${association.relationship_type}`}</td>
        </tr>
    );
};

const makeHeader = () => {
    return (
        <tr>
            <th className="FamilyMemberWidget__name_column">
                <p className="FamilyMemberWidget__name_column_offset">Name</p>
            </th>
            <th className="FamilyMemberWidget__email_column">Email</th>
            <th className="FamilyMemberWidget__phone_column">Phone</th>
            <th className="FamilyMemberWidget__relationship_column">Relationship</th>
        </tr>
    );
};

const enumerateFamilyMembers = (associations, props) => {
    return (
        <table className="table table-sm">
            <thead className="FamilyMemberWidget__table_header">{makeHeader()}</thead>
            <tbody>
                {associations.map((association) => makeAssociationRow(association, props))}
            </tbody>
        </table>
    );
};

const FamilyMembersWidget = (props) => {
    const { patientDetails } = props;

    const associatedGuardians = patientDetails.enriched_associated_guardians;
    const associatedWards = patientDetails.enriched_associated_wards;
    // // Keep this code until server functionality is working
    // const associatedGuardians = [
    //   {
    //     first_name: "Ken",
    //     last_name: "Burns",
    //     email: "cnyberg@test.com",
    //     relationship_type: "parent",
    //     patient_id: "abc1234",
    //     mobile: "5551234567"
    //   },
    // ];
    // const associatedWards = [
    //   {
    //     first_name: "Wayland",
    //     last_name: "Smithers",
    //     email: "cnyberg@test.com",
    //     relationship_type: "child",
    //     patient_id: "abc4567",
    //     mobile: "5555101234"
    //   },
    // ];

    const combinedAssociations = associatedGuardians.concat(associatedWards);

    if (_.isEmpty(combinedAssociations)) {
        // TODO: Should we show the family section if there are no associated entities?
        return <></>;
    }

    return (
        <ProfileDetailsCard heading="Family Members" edit={false} doNotShowEditOrToggle={true}>
            <div className={"dashboard-widget-card dashboard-widget-card"}>
                {enumerateFamilyMembers(combinedAssociations, props)}
            </div>
        </ProfileDetailsCard>
    );
};

export default FamilyMembersWidget;
