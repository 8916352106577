// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import _uniqueId from "lodash/uniqueId";
import { toTitleCase } from "../../../../utils/filters";
import { SidebarListItem, SidebarSublist, SidebarSublistItem } from "../index";

const SidebarCollapsibleListItem = (props) => {
    const { title, subListItems, uid, accordionId } = props;

    let UID = _uniqueId("SidebarCollapsibleItem-");

    return (
        <div className="SidebarCollapsibleListItem">
            <button
                className="SidebarCollapsibleListItem__toggle"
                data-toggle="collapse"
                data-target={`.${uid}`}>
                <SidebarListItem title={toTitleCase(title)} />
            </button>
            <div className={`collapse ${uid}`} id={uid} data-parent={"#" + accordionId}>
                <SidebarSublist>
                    {subListItems.map((item) => {
                        const scrollTargetId = item.title.replace(/\s+/g, "-").toLowerCase();
                        return (
                            <span
                                key={scrollTargetId}
                                className="SidebarCollapsibleListItem__scroll-target d-block cursor"
                                data-scroll-target={scrollTargetId}>
                                <SidebarSublistItem title={toTitleCase(item.title)} />
                            </span>
                        );
                    })}
                </SidebarSublist>
            </div>
        </div>
    );
};

export default SidebarCollapsibleListItem;
