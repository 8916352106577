import React, { useEffect, useState } from "react";
import images from "../../../../utils/images";
import withRouter from "react-router-dom/es/withRouter";
import { CardPrimary } from "../../../UI/Card";
import { api } from "../../../../APIRequests";

const DischargeData = (props) => {
    const [dischargeDataItem, setDischargeDataItem] = useState(null);

    useEffect(() => {
        try {
            (async () => {
                let urlParams = { patientId: props.match.params.patientId };
                const queryParams = { note_id: props.match.params.dischargeId };
                const note = await api.shared.fetch_patient_progress_notes({
                    urlParams,
                    queryParams,
                });
                setDischargeDataItem(note.content);
            })();
        } catch (e) {
            console.log(e);
        }
    }, [props.match.params.patientId, props.match.params.dischargeId]);

    return (
        <>
            <div>
                <div className="backLink-wpr mx-auto mt-4 d-none d-lg-block">
                    <span className="PatientProfile-back-link" onClick={() => props.history.go(-1)}>
                        <img
                            className="img-fluid"
                            src={images("./common/solidBckBtn.svg")}
                            alt={"back"}
                        />
                        <p className="d-inline ml-2">Back to Medical Information</p>
                    </span>
                </div>
                <CardPrimary className="mx-auto my-0 my-lg-2" styles={{ minHeight: "35vh" }}>
                    <div className={"mx-3"}>
                        <span className={"fw-bold fs-18"}>Discharge</span>
                        <hr />
                        <div className="row">
                            <div className={"col-lg-6 col-12 mb-4"}>
                                <div className={"fw-bold mb-1"}>Discharge Type</div>
                                <div>{dischargeDataItem && dischargeDataItem.discharge_type}</div>
                            </div>
                            <div className={"col-lg-6 col-12 mb-4"}>
                                <div className={"fw-bold mb-1"}>Discharge Date</div>
                                <div>{dischargeDataItem && dischargeDataItem.discharge_date}</div>
                            </div>
                            <div className={"col-12 mb-4"}>
                                <div className={"fw-bold mb-1"}>Additional Information</div>
                                <div>
                                    {dischargeDataItem &&
                                        dischargeDataItem.additional_discharge_information}
                                </div>
                            </div>
                        </div>
                    </div>
                </CardPrimary>
            </div>
        </>
    );
};

export default withRouter(DischargeData);
