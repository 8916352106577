import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import { FauxInput } from "../FauxInput";
import { Chip } from "../Chip";
import { Info } from "../Info";

import "./styles.scss";

export const FileInput = ({
    className,
    files,
    infoContent,
    placeholder,
    disabled,
    infoPopoverPosition,
    onDelete,
    ...props
}) => {
    const [stateFiles, setStateFiles] = useState(files);

    useEffect(() => {
        if (files !== stateFiles) {
            setStateFiles(files);
        }
    }, [files]);

    const chipDeleteHandler = (e) => {
        const fileDeleted = e.target.attributes["data-label"].value;
        setStateFiles(stateFiles.filter((file) => file !== fileDeleted));
        onDelete(fileDeleted);
    };

    return (
        <FauxInput
            className={createClassNameString(["apti-FileInput", className])}
            placeholder={placeholder}
            disabled={disabled}
            icon={
                infoContent && (
                    <>
                        <div className="apti-FileInput--info-container">&nbsp;</div>
                        <Info className="apti-FileInput--info" position={infoPopoverPosition}>
                            {infoContent}
                        </Info>
                    </>
                )
            }
            {...props}>
            {stateFiles.map((file, index) => (
                <Chip key={index} label={file} onClick={chipDeleteHandler} />
            ))}
        </FauxInput>
    );
};

FileInput.propTypes = {
    className: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.string).isRequired,
    infoContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    infoPopoverPosition: PropTypes.oneOf(["left", "right", "top", "bottom"]),
    onDelete: PropTypes.func,
};

FileInput.defaultProps = {
    className: undefined,
    files: [],
    infoContent: undefined,
    placeholder: "",
    disabled: false,
    infoPopoverPosition: "right",
    onDelete: () => {},
};
