import React from "react";
import _ from "lodash";
import CardSecondary from "../../../UI/Card/CardSecondary";
import { RATING_OPTIONS_LABEL_MAP, SCREENING_OPTIONS_LABEL_MAP } from "./MemberProfileNotes";
import { BehavioralGoalsRating } from "./MemberProfileNotesBehavioralGoals";
import { arrayToIndexedObject } from "../../../../utils/arrayUtil";

export const SUBSTANCE_ABUSE_QUESTIONS = {
    substances: {
        label: "Substance Free Days",
        answer: null,
    },
    alcohol: {
        label: "Alcohol Free Days",
        answer: null,
    },
    other: {
        label: "Symptom Free Days",
        answer: null,
    },
};

export const NumberRating = (props) => {
    const { max, progress, onClick, isEditing, dimensions } = props;

    let actualDimensions = dimensions ? dimensions : 30;

    return (
        <div className="col-12 d-flex justify-content-around">
            {Array(max)
                .fill()
                .map((_, optionId) => {
                    optionId = parseInt(optionId);
                    let optionsClasses = "RatingOption";

                    if (progress.answer !== null && progress.answer === optionId) {
                        optionsClasses += " RatingOption-active text-white";
                    }
                    return (
                        <div style={{ margin: "0px 1px", color: "#F3F4F5" }}>
                            <button
                                style={{ height: actualDimensions, width: actualDimensions }}
                                id={`${optionId}`}
                                className={optionsClasses}
                                type={"button"}
                                value={optionId}
                                onClick={onClick}
                                disabled={!isEditing}>
                                {optionId}
                            </button>
                        </div>
                    );
                })}
        </div>
    );
};

export const BehavioralSymptomTrend = (props) => {
    let {
        note,
        editable,
        editHandler,
        profileView,
        patientAnswersEditable,
        showProvider,
        smallerDimensions,
    } = props;

    if (!editable) {
        patientAnswersEditable = false;
    }

    let dimensions = 30;
    if (smallerDimensions) {
        dimensions = 15;
    }

    let symptomTrends = _.get(note, "content.symptom_trends", {});
    let symptomTrendsSubstances = _.get(note, "content.symptom_trends_substances", []);

    const handleEditCopyChange = (e, index, key, isNumber = false) => {
        let editCopyClone = _.cloneDeep(symptomTrends);
        editCopyClone[index][key] = isNumber ? parseInt(e.target.value) : e.target.value;

        if (editHandler) {
            editHandler("symptom_trends", editCopyClone);
        }
    };

    const handleSubstancesEditCopyChange = (e, index, key, isCheckbox = false) => {
        if (!editable) {
            return;
        }

        let editCopyClone = _.cloneDeep(symptomTrendsSubstances);

        if (isCheckbox) {
            editCopyClone[index][key] = null;
        } else {
            editCopyClone[index][key] = parseInt(e.target.value);
        }

        if (editHandler) {
            editHandler("symptom_trends_substances", editCopyClone);
        }
    };

    let patientAnswerContent = (
        <>
            <div className="row">
                <div className="col-12">
                    <p className="clr-sec fw-bold">
                        Symptom Trend{" "}
                        {showProvider && (
                            <span className="clr-pris">
                                {" "}
                                - {note.signature} {note.session_date}
                            </span>
                        )}
                    </p>
                </div>
            </div>
            {Object.entries(symptomTrends)
                .sort((a, b) => (a[1].order && b[1].order ? a[1].order - b[1].order : 0))
                .map(([key, value]) => {
                    let labelOptions;

                    if (value.rating_labels) {
                        labelOptions = arrayToIndexedObject(value.rating_labels);
                    } else {
                        labelOptions =
                            value.type == null || value.type === "rating"
                                ? RATING_OPTIONS_LABEL_MAP
                                : SCREENING_OPTIONS_LABEL_MAP;
                    }

                    return (
                        <div>
                            <p className="fw-bold fs-12">{value.label}</p>
                            <div className="d-flex justify-content-start">
                                {Object.values(labelOptions).map((optionLabel) => (
                                    <div className="w-25 fs-10 fw-bold text-center">
                                        <div className="m-auto">{optionLabel}</div>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <BehavioralGoalsRating
                                    optionLabelMap={labelOptions}
                                    progress={value}
                                    optionsClasses={!profileView ? "RatingOption-bg-white" : ""}
                                    isEditing={patientAnswersEditable}
                                    containerClass="p-0"
                                    onClick={(e) => handleEditCopyChange(e, key, "answer", true)}
                                />
                            </div>
                        </div>
                    );
                })}
        </>
    );

    let providerAnswerContent = (
        <>
            {showProvider && (
                <span className="clr-sec fw-bold">
                    {" "}
                    {note.signature} {note.session_date}
                </span>
            )}
            {Object.entries(symptomTrendsSubstances).map(([key, value]) => {
                return (
                    <div className="row fs-12">
                        <div className="col-lg-3">
                            <p className="fw-bold mb-0">{value.label}</p>
                            <label>
                                <input
                                    type="checkbox"
                                    className="custom-checkbox"
                                    checked={value.answer == null}
                                    onClick={(e) =>
                                        handleSubstancesEditCopyChange(e, key, "answer", true)
                                    }
                                />
                                <span className={"ml-1"}>non/applicable</span>
                            </label>
                        </div>
                        <div className="col-lg-2 float-right">
                            <p>0 - 7 days</p>
                        </div>
                        <div className="col-lg-7 d-flex justify-content-start">
                            <NumberRating
                                max={8}
                                progress={value}
                                isEditing={editable}
                                dimensions={dimensions}
                                onClick={(e) => handleSubstancesEditCopyChange(e, key, "answer")}
                            />
                        </div>
                    </div>
                );
            })}
        </>
    );

    let desktopContent;

    if (!profileView) {
        let providerCss = "mx-0 px-3 py-3 my-3 w-100 max-width d-none d-md-none d-lg-block ";
        if (showProvider) {
            providerCss += "grey-container";
        }
        desktopContent = (
            <>
                <CardSecondary className="mx-0 px-3 py-3 my-3 w-100 max-width d-none d-md-none d-lg-block grey-container">
                    {patientAnswerContent}
                </CardSecondary>
                <CardSecondary className={providerCss}>{providerAnswerContent}</CardSecondary>
            </>
        );
    } else {
        desktopContent = (
            <div className="d-none d-md-none d-lg-block">
                {patientAnswerContent}
                {providerAnswerContent}
            </div>
        );
    }

    let mobileContent = (
        <CardSecondary className="mx-auto d-block d-md-block d-lg-none mt-3">
            <div className={!profileView ? "grey-container" : ""}>{patientAnswerContent}</div>

            {Object.entries(symptomTrendsSubstances).map(([key, value]) => {
                return (
                    <>
                        <p className="fw-bold fs-12">{value.label}</p>
                        <div>
                            <div className="float-left">
                                <input
                                    type="checkbox"
                                    checked={value.answer == null}
                                    onClick={(e) =>
                                        handleSubstancesEditCopyChange(e, key, "answer", true)
                                    }
                                />
                                <label>non/applicable</label>
                            </div>

                            <div className="float-right">
                                <p>0 - 7 days</p>
                            </div>
                        </div>
                        <NumberRating
                            max={8}
                            progress={value}
                            isEditing={editable}
                            dimensions={dimensions}
                            onClick={(e) => handleSubstancesEditCopyChange(e, key, "answer")}
                        />
                    </>
                );
            })}
        </CardSecondary>
    );

    return (
        <>
            {desktopContent}
            {mobileContent}
        </>
    );
};
