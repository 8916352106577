import React from "react";
import { connect } from "react-redux";
import CardSecondary from "../../UI/Card/CardSecondary";
import images from "../../../utils/images";
import { Link } from "react-router-dom";
import { providerRoute as provider } from "../../../config/roles";

const DraftNotesCard = (props) => {
    const { numberOfDraftNotes } = props;

    let subText = <p className="draft-note-sub-text fs-16">Great Job!</p>;

    if (numberOfDraftNotes > 0) {
        subText = (
            <Link to={provider + "/draft-notes"} className="draft-notes-view-all fs-16">
                View All
            </Link>
        );
    }

    return (
        <div>
            <CardSecondary className="DashboardViewCard py-2 px-3 px-lg-4">
                <div className="d-flex align-items-center justify-content-between">
                    <p className="fs-30 fs-xl-50 fw-bold mb-0">{numberOfDraftNotes}</p>
                    <img
                        className="draft-note-icon d-none d-md-block mt-2 mb-2 pt-1 pb-1"
                        src={images("./icons/draft-note.svg")}
                    />
                </div>
                <div className="d-flex flex-column">
                    <p className="fs-lg-20 fs-14 mt-3 mb-0">Number Of Draft Notes</p>
                    {subText}
                </div>
            </CardSecondary>
        </div>
    );
};

export default DraftNotesCard;
