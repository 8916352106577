// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { Form, Formik } from "formik";
import { EMERGENCY_SCHEMA } from "../../../containers/Dashboard/Patient/schema/PatientProfileInfoSchema";
import FormikInput from "../../UI/FormikInput";
import Button from "../../UI/Button";
import PatientProfileDetailsCard from "./PatientProfileDetailsCard";
import { renderTitleWithValue } from "./index";
import SubmitControls from "./SubmitControls";
import Hr from "../../UI/Hr";
import FormikInputFormat from "../../UI/formikMobileInput";
import * as yup from "yup";
import { RE_PHONE } from "../../../constants/regex";
import { CustomForm } from "../../../component-library/CustomForm";

/** Only mobile number format needs to be validated, no required fields */
const VALIDATION_SCHEMA = yup.object().shape({
    emergency_contact_number: yup
        .string()
        .matches(RE_PHONE, "Emergency Contact Number must be a valid US number"),
});

class PatientProfileEmergencyInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: props.edit,
        };
    }

    toggleEditEmergencyInfo = () => {
        this.setState((ps) => {
            return {
                edit: !ps.edit,
            };
        });
    };

    submitHandler = (formData, action) => {
        let data = { ...formData };
        this.setState((ps) => {
            return {
                edit: !ps.edit,
            };
        });
        this.props.submit(data, action);
    };

    renderEmergencyForm = (formikProps) => {
        return (
            <Form style={{ width: "100%" }}>
                <div className="mx-auto px-0 ">
                    <div className="container px-0">
                        <div className="row">
                            {EMERGENCY_SCHEMA.map((formEl) => {
                                return (
                                    <div key={formEl.name} className="col-12 col-lg-6">
                                        {formEl.name === "emergency_contact_number" ? (
                                            <FormikInputFormat
                                                value={
                                                    formikProps &&
                                                    formikProps.values.emergency_contact_number
                                                }
                                                onChange={async (val) => {
                                                    await formikProps.setFieldValue(
                                                        "emergency_contact_number",
                                                        val.value,
                                                    );
                                                    formikProps.setFieldTouched(
                                                        "emergency_contact_number",
                                                    );
                                                }}
                                                formEl={formEl}
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                            />
                                        ) : (
                                            <FormikInput
                                                formEl={formEl}
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                            />
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="mb-3 d-lg-none">
                    <Hr />
                </div>
                <SubmitControls cancelHandler={this.toggleEditEmergencyInfo} />
            </Form>
        );
    };

    render = () => {
        return (
            <PatientProfileDetailsCard
                onclick={this.toggleEditEmergencyInfo}
                edit={this.state.edit}
                type={"Edit"}
                heading="Emergency Contact information"
                isEditable>
                <div className="PatientProfile__basic-info no-gutters row pt-2">
                    {!this.state.edit &&
                        iterableEmergencyInfo(this.props.data).map((item) => {
                            return (
                                <div key={item.title} className="col-12 col-lg-6">
                                    {renderTitleWithValue(item.title, item.value)}
                                </div>
                            );
                        })}
                    {this.state.edit && (
                        <CustomForm
                            initialValues={this.props.data}
                            validationSchema={VALIDATION_SCHEMA}
                            onSubmit={this.submitHandler}
                            render={this.renderEmergencyForm}
                        />
                    )}
                </div>
            </PatientProfileDetailsCard>
        );
    };
}

export default PatientProfileEmergencyInfo;

export const iterableEmergencyInfo = (profileData) => [
    { title: "Emergency Contact Person", value: profileData.emergency_contact_person || "" },
    { title: "Emergency Contact Number", value: profileData.emergency_contact_number || "" },
];
