// Copyright aptihealth, inc. 2019 All Rights Reserved
import { combineReducers } from "redux";

import authReducer from "./authReducer";
import loaderReducer from "./loaderReducer";
import alertReducer from "./alertReducers";
import patientReducer from "./patientReducers";
import providerReducer from "./providerReducers";
import exceptionReportingReducer from "./exceptionReportReducers";
import a5Reducer from "./a5Reducers";
import dynamicRouteConfigReducer from "./dynamicRouteConfigReducer";
import configsReducer from "./configsReducer";
import { crisisRiskReducer } from "./reportReducers";
import { insuranceReducer } from "./insuranceReducers";
import { patientCardInfoReducer } from "./patientCardInfoReducers";
import { paymentProcessorReducer } from "./paymentProcessorReducers";
import { toasterReducer } from "./toasterReducers";
import calendarReducer from "./calendarReducer";
import featureFlagReducer from "./featureFlagReducer";

export default combineReducers({
    auth: authReducer,
    loader: loaderReducer,
    patient: patientReducer,
    provider: providerReducer,
    alert: alertReducer,
    dynamicRouteConfig: dynamicRouteConfigReducer,
    exceptionReport: exceptionReportingReducer,
    a5Screening: a5Reducer,
    configs: configsReducer,
    calendar: calendarReducer,
    reports: {
        crisisRisk: crisisRiskReducer,
    },
    insurance: insuranceReducer,
    patientCardInfo: patientCardInfoReducer,
    paymentProcessor: paymentProcessorReducer,
    toaster: toasterReducer,
    flags: featureFlagReducer,
});
