export const SIDE_EFFECT_QUESTIONS = {
    anxiety: {
        label: "Anxiety",
        answer: false,
        order: 1,
    },
    increased_appetite: {
        label: "Increased Appetite",
        answer: false,
        order: 2,
    },
    depression: {
        label: "Depression",
        answer: false,
        order: 3,
    },
    weight_gain: {
        label: "Weight Gain",
        answer: false,
        order: 4,
    },
    nausea: {
        label: "Nausea",
        answer: false,
        order: 5,
    },
    weight_loss: {
        label: "Weight Loss",
        answer: false,
        order: 6,
    },
    diarrhea: {
        label: "Diarrhea",
        answer: false,
        order: 7,
    },
    increased_glucose: {
        label: "Increased Glucose",
        answer: false,
        order: 8,
    },
    constipation: {
        label: "Constipation",
        answer: false,
        order: 9,
    },
    increased_cholesterol: {
        label: "Increased Cholesterol",
        answer: false,
        order: 10,
    },
    sexual: {
        label: "Sexual Side Effects",
        answer: false,
        order: 11,
    },
    movement_disorders: {
        label: "Movement Disorders",
        answer: false,
        order: 12,
    },
    daytime_sleepiness: {
        label: "Excessive Daytime Sleepiness",
        answer: false,
        order: 13,
    },
    tremors: {
        label: "Tremors",
        answer: false,
        order: 14,
    },
    sedation: {
        label: "Sedation",
        answer: false,
        order: 15,
    },
    bradykinesia: {
        label: "Bradykinesia",
        answer: false,
        order: 16,
    },
    insomnia: {
        label: "Insomnia",
        answer: false,
        order: 17,
    },
    akathisia: {
        label: "Akathisia",
        answer: false,
        order: 18,
    },
    discontinuation: {
        label: "Discontinuation Syndrome",
        answer: false,
        order: 19,
    },
    none: {
        label: "None",
        value: true,
        order: 20,
    },
};
