// Copyright aptihealth, inc. 2021 All Rights Reserved
import React, { useState } from "react";
import CardSecondary from "../../../UI/Card/CardSecondary";
import { connect } from "react-redux";
import "./styles.scss";
import ScheduleCall from "./ScheduleCall";
import moment from "moment-timezone";
import { getLocalTimezone } from "../../../../redux/reducers/authReducer";
import { Button, ButtonTypes, Link, LinkTypes } from "../../../../component-library";
import { getAutomationDataAttr } from "../../../../utils/automationUtils";


const ReferAnotherPatientOrScheduleAppointment = ({
    sharedWorkflowData,
    currentStepName,
    update_state_and_get_next_node,
}) => {
    // driven by availability component
    const [selectedAppointment, setSelectedAppointment] = useState();
    const timezone = getLocalTimezone();
    const workflow_state = sharedWorkflowData.workflow_instance.state;
    if (!workflow_state.patient_info) {
        workflow_state.patient_info = {
            first_name: "",
            last_name: "",
        };
    }

    return (
        <div className={"container hcp-mobile-container px-sm-3 py-sm-5"}>
            <CardSecondary className="HCPWorkflowShared-card">
                <div className="ScheduleCallOrSkip-header_container">
                    <div className="HCPWorkflowShared-header_container">
                        <h1 className="HCPWorkflowShared-title">
                            Would you like to schedule{" "}
                            <span style={{ display: "inline" }} data-private>
                                {
                                    sharedWorkflowData.workflow_instance.state.patient_info
                                        ?.first_name
                                }
                                ’s{" "}
                            </span>
                            first session?
                        </h1>
                        <p className="HCPWorkflowShared-sub_title">
                            You can do this now or let the patient schedule later.
                        </p>
                    </div>

                    <div className="ScheduleCallOrSkip-hr" style={{ width: "100%" }} />
                </div>

                <ScheduleCall
                    selectedAppointment={selectedAppointment}
                    setSelectedAppointment={setSelectedAppointment}
                    timezone={timezone}
                    sharedWorkflowData={sharedWorkflowData}
                    update_state_and_get_next_node={update_state_and_get_next_node}
                />

                <div className="ScheduleCallOrSkip-button_container">
                    <Button
                        disabled={!selectedAppointment}
                        buttonType={ButtonTypes.primaryV2}
                        onClick={async () => {
                            let dateStr = `
                                    ${selectedAppointment.year}-
                                    ${selectedAppointment.month}-
                                    ${selectedAppointment.day} 
                                    ${selectedAppointment.start_time}`;
                            let utcDatetime = moment
                                .tz(dateStr, "YYYY-MM-DD HH:mm", timezone)
                                .utc();

                            const data = {
                                save_call: true,
                                appointment_confirmed: true,
                                call_date: utcDatetime.format("YYYY-MM-DD"),
                                call_time: utcDatetime.format("HH:mm"),
                                call_provider_id: selectedAppointment["provider_username"],
                                call_allotted_time: selectedAppointment["allotted_time"],
                                call_timezone: timezone,
                                provider_name: selectedAppointment["provider_name"],
                            };

                            update_state_and_get_next_node(data, currentStepName);
                        }}
                        {...getAutomationDataAttr("HCPScheduleSessionButton")}>
                        Schedule Session
                    </Button>
                    <div className="ScheduleCallOrSkip-or_text_container">
                        <div className="ScheduleCallOrSkip-hr" style={{ width: 81 }} />
                        <p className="ScheduleCallOrSkip-or_text">OR</p>
                        <div className="ScheduleCallOrSkip-hr" style={{ width: 81 }} />
                    </div>

                    <Button
                        buttonType={ButtonTypes.underline}
                        className="ScheduleCallOrSkip-skip_step"
                        onClick={async () => {
                            const state = { save_call: false };
                            await update_state_and_get_next_node(state, currentStepName);
                        }}>
                        Skip this step
                    </Button>
                </div>
            </CardSecondary>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        configs: state.configs,
    };
};

export default connect(mapStateToProps)(ReferAnotherPatientOrScheduleAppointment);
