// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import CardSecondary from "../../UI/Card/CardSecondary";
import images from "../../../utils/images";
import { Link } from "react-router-dom";
import { api } from "../../../APIRequests";
import moment from "moment";

class TodaysSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            calls: this.props.calls,
        };
    }

    componentDidMount() {
        let todaysSchedule = this.state.calls;
        if (todaysSchedule) {
            todaysSchedule.forEach((call) => {
                if (call.patient_avatar) {
                    (async () => {
                        if (call.patient_avatar) {
                            let urlParams = { patientId: call.patient_username };
                            let data = { key: call.patient_avatar };
                            let cleanResponse = await api.shared.fetch_patient_avatar({
                                data,
                                urlParams,
                            });
                            if (cleanResponse && cleanResponse.message) {
                                todaysSchedule.forEach((c) => {
                                    if (call.patient_username === c.patient_username) {
                                        call.avatarUrl = cleanResponse.message;
                                    }
                                });
                                this.setState({
                                    calls: todaysSchedule,
                                });
                            }
                        }
                    })();
                }
            });
        }
    }

    render() {
        return (
            <CardSecondary className="DashboardViewCard d-flex flex-column justify-content-between TodaysSchedule-wpr px-3 px-lg-4">
                <div className="DashboardViewCard-todaySchedule py-3">
                    <p>
                        <strong>TODAY'S SCHEDULE</strong>
                    </p>
                    {this.state.calls &&
                        this.state.calls.slice(0, 3).map((call) => {
                            let LocalTime = moment(call.timestamp, "HH:mm").format("hh:mm A");
                            return (
                                <div
                                    key={call.callId}
                                    className="d-flex mb-2 align-items-center justify-content-between">
                                    {call.avatarUrl && (
                                        <img
                                            src={call.avatarUrl}
                                            width="40"
                                            alt=""
                                            className="img-fluid mr-3"
                                        />
                                    )}
                                    {!call.avatarUrl && (
                                        <img
                                            src={images("./common/avatar.png")}
                                            width="40"
                                            alt=""
                                            className="img-fluid mr-3"
                                        />
                                    )}
                                    <div data-private="true">{call.patient_name}</div>
                                    {/* {call.timestamp} */}
                                    {LocalTime}
                                </div>
                            );
                        })}
                    {!this.state.calls && (
                        <div className="d-flex align-items-center justify-content-between">
                            <div>No Calls Today</div>
                        </div>
                    )}
                </div>
                <div style={{ textAlign: "center" }} className="">
                    {this.state.calls && (
                        <div>
                            <hr className="m-0" />
                            <div className="py-3">
                                <Link to={"/app/schedule"} style={{ color: "#0CD0A7" }}>
                                    View All
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </CardSecondary>
        );
    }
}

export default TodaysSchedule;
