// Copyright aptihealth, inc. 2019 All Rights Reserved
import moment from "moment-timezone";

export const toSentenceCase = (text) => {
    if (!text) {
        return "";
    }
    let lowerCasedText = text.toLowerCase();
    let textArray = [...lowerCasedText];
    textArray[0] = textArray[0].toUpperCase();
    let transformedText = textArray.join("");
    return transformedText;
};

export const toTitleCase = (text) => {
    if (!text || (!text.trim() && !text.length > 0)) {
        return;
    }
    let wordsArray = text.split(" ");
    let wordsArrayModified = [...wordsArray.map((item) => toSentenceCase(item))];
    let transformedText = wordsArrayModified.join(" ");
    return transformedText;
};

/**
 * Returns query params fetched from url supporting maximum browsers.
 * @param {string} key - search key passes
 * @param {string} [receivedSearchString] - search string passed from router's location.search (optional)
 * @return {string} retrieved query parameter value.
 */
export const getQueryParams = function (key, receivedSearchString) {
    let searchString = "";
    if (receivedSearchString) {
        searchString = receivedSearchString;
    } else {
        searchString = window.location.search;
    }
    return decodeURIComponent(
        searchString.replace(
            new RegExp(
                "^(?:.*[&\\?]" +
                    encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
                    "(?:\\=([^&]*))?)?.*$",
                "i",
            ),
            "$1",
        ),
    );
};

/**
 * Returns the digits prefixed with '0' if digit is less than 10
 * @param {number} numberPassed - passed number to prefix with '0' digit
 * @return {string} - transformed number if required.
 */
export const prefixZero = function (numberPassed) {
    return String((numberPassed.toString().length == 1 ? "0" : 0) + numberPassed);
};

/* 

*/
export const fetchMilitaryTime = function (dateTime) {
    let time = dateTime.split(" ");
    return time[1];
};
export const fetchDate = function (dateTime) {
    let date = dateTime.split(" ");
    return date[0];
};

/**
 * Returns the Local timestring coverted from UTC timestring.
 * @param {string} timeString - UTC timeString passed for transformation
 * @param {string} passedFS - the format of the passed `timeString`.
 * @param {string} returnFS - the format to convert the received `timeString` to
 * @param {string} tz - tz which is assumed to be local
 * @return {string} - EST converted timestring
 *
 */
export const getLocalTimeString = function (timeString, passedFS, returnFS, tz) {
    let convertedTimeString = moment.utc(timeString, passedFS).tz(tz).format(returnFS);
    return convertedTimeString;
};

/**
 * https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
 * native js uuid
 */
export const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

/**
 * Modifies the property value which match the criteria passed as argument
 * Currently supports only `Objects` and does the shallow operation with only string value
 *
 * @param {object} source object in which to relplace the value of key (will ignore values other than string)
 * @param {string} searchStr the search string which becomes the criteria for match.
 * @param {string} replaceStr the string with which to replace.
 * @returns {object} returns the modified copy of the source object
 */
export const searchAndReplace = (source, searchStr, replaceStr) => {
    const clonedSource = { ...source };
    for (let key in clonedSource) {
        if (typeof clonedSource[key] === "string") {
            clonedSource[key] = clonedSource[key] === searchStr ? replaceStr : clonedSource[key];
        }
    }

    return clonedSource;
};

export const formatMobileNumber = (val) => {
    if (!val) {
        return;
    }
    let firstNumber = val.substring(0, 3);
    let middleNumber = val.substring(3, 6);
    let lastNumber = val.substring(6, 10);
    return `(${firstNumber}) ${middleNumber}-${lastNumber}`;
};

export const filterPractices = (
    practices,
    filterExp,
    addAllOption = true,
    optionAttribute = "label",
) => {
    let specialistPracticeOptions = practices.filter(filterExp).map((practice) => {
        const option = {
            value: practice.id,
        };
        option[optionAttribute] = practice.name;
        return option;
    });
    if (addAllOption) {
        specialistPracticeOptions.unshift({ value: "all_practices", label: "All" });
    }
    return specialistPracticeOptions;
};
