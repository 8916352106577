// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import TutorialScreen from "./TutorialScreen";

import "./Styles.scss";

class TutorialScreens extends Component {
    state = {
        firstSlide: true,
        secondSlide: false,
        thirdSlide: false,
        forthSlide: false,
        fifthSlide: false,
    };
    changeSlide = (slideNumber, action) => {
        if (slideNumber == "Slide1") {
            if (action == "next") {
                this.setState({
                    firstSlide: false,
                    secondSlide: true,
                    thirdSlide: false,
                    forthSlide: false,
                    fifthSlide: false,
                });
            } else {
                this.setState({
                    firstSlide: true,
                    secondSlide: false,
                    thirdSlide: false,
                    forthSlide: false,
                    fifthSlide: false,
                });
            }
        } else if (slideNumber == "Slide2") {
            if (action == "next") {
                this.setState({
                    firstSlide: false,
                    secondSlide: false,
                    thirdSlide: true,
                    forthSlide: false,
                    fifthSlide: false,
                });
            } else {
                this.setState({
                    firstSlide: true,
                    secondSlide: false,
                    thirdSlide: false,
                    forthSlide: false,
                    fifthSlide: false,
                });
            }
        } else if (slideNumber == "Slide3") {
            if (action == "next") {
                this.setState({
                    firstSlide: false,
                    secondSlide: false,
                    thirdSlide: false,
                    forthSlide: true,
                    fifthSlide: false,
                });
            } else {
                this.setState({
                    firstSlide: false,
                    secondSlide: true,
                    thirdSlide: false,
                    forthSlide: false,
                    fifthSlide: false,
                });
            }
        } else if (slideNumber == "Slide4") {
            if (action == "next") {
                this.setState({
                    firstSlide: false,
                    secondSlide: false,
                    thirdSlide: false,
                    forthSlide: false,
                    fifthSlide: true,
                });
            } else {
                this.setState({
                    firstSlide: false,
                    secondSlide: false,
                    thirdSlide: true,
                    forthSlide: false,
                    fifthSlide: false,
                });
            }
        } else if (slideNumber === "Slide5") {
            if (action === "next") {
                this.setState({
                    firstSlide: false,
                    secondSlide: false,
                    thirdSlide: false,
                    forthSlide: false,
                    fifthSlide: true,
                });
            } else {
                this.setState({
                    firstSlide: false,
                    secondSlide: false,
                    thirdSlide: false,
                    forthSlide: true,
                    fifthSlide: false,
                });
            }
        }
    };

    render() {
        let firstSlide, secondSlide, thirdSlide, forthSlide, fifthSlide;
        if (this.state.firstSlide) {
            {
                //First screen
                firstSlide = (
                    <TutorialScreen
                        Slide="Slide1"
                        previous="previous"
                        next="next"
                        Tutorial="Tutorial_1"
                        heading="Begin Your Journey with One Step"
                        text="The Initial Assessment provides our team the best way to help understand your personal situation. Pause at any time and the Assessment will restart until complete."
                        click={this.changeSlide}
                        btnText="Continue"
                        baseRoute={this.props.baseRoute}
                    />
                );
            }
        } else if (this.state.secondSlide) {
            {
                //Second Screen
                secondSlide = (
                    <TutorialScreen
                        Slide="Slide2"
                        previous="previous"
                        next="next"
                        Tutorial="Tutorial_2"
                        heading="You’ll Talk with Our Team Next"
                        text="After completing the Initial Assessment, we’ll work with you to identify a time for the Video Consult-ation. Our team will have a chance to talk one-to-one on your current feelings and concerns."
                        click={this.changeSlide}
                        btnText="Continue"
                        baseRoute={this.props.baseRoute}
                    />
                );
            }
        } else if (this.state.thirdSlide) {
            {
                //Third Slide
                thirdSlide = (
                    <TutorialScreen
                        Slide="Slide3"
                        previous="previous"
                        next="next"
                        Tutorial="Tutorial_3"
                        heading="A Way Forward"
                        text="When the aptihealth team has had a chance to review every detail, we’ll be able to propose a best path scenario. This may include a unique 90 Day Care Program."
                        click={this.changeSlide}
                        btnText="Continue"
                        baseRoute={this.props.baseRoute}
                    />
                );
            }
        } else if (this.state.forthSlide) {
            //Forth Slide
            forthSlide = (
                <TutorialScreen
                    Slide="Slide4"
                    previous="previous"
                    next="next"
                    Tutorial="Tutorial_4"
                    heading="Feel Better Than Ever"
                    text="Our team will consult with you & your Primary Care Provider to make sure we can help in the best way. We strive to set goals that make sense for you & improve your wellbeing every time you check-in."
                    click={this.changeSlide}
                    btnText="Continue"
                    baseRoute={this.props.baseRoute}
                />
            );
        } else if (this.state.fifthSlide) {
            //Fifth Slide
            fifthSlide = (
                <TutorialScreen
                    Slide="Slide5"
                    previous="previous"
                    next="next"
                    Tutorial="setting"
                    heading="System Check"
                    text="To get started run we will run a system check to ensure you can use the latest features."
                    click={this.changeSlide}
                    btnText="Continue"
                    baseRoute={this.props.baseRoute}
                />
            );
        }
        return (
            <div className="viewTutorial">
                <div className="container">
                    <div className="viewTutorial-card px-3 py-5 px-lg-5 mb-5">
                        {firstSlide}
                        {secondSlide}
                        {thirdSlide}
                        {forthSlide}
                        {fifthSlide}
                    </div>
                </div>
            </div>
        );
    }
}

export default TutorialScreens;
