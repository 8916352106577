import "../styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { getIn } from "formik";
import _ from "lodash";
import { createClassNameString } from "../../utils";
import { FormFieldError } from "../FormFieldError/FormFieldError";
import NumberFormat from "react-number-format";

// copied from src\components\Common\FormHelpers\FormikNumberInput.js
const dobNumberFormat = (val) => {
    let mainVal;
    let month = val.substring(0, 2);
    let date = val.substring(2, 4);
    let year = val.substring(4, 8);

    if (month.length === 1) {
        mainVal = `${month}M/DD/YYYY`;
    } else if (month.length === 2) {
        mainVal = `${month}/DD/YYYY`;
    }

    if (month.length && date.length === 1) {
        mainVal = `${month}/${date}D/YYYY`;
    } else if (month.length && date.length === 2) {
        mainVal = `${month}/${date}/YYYY`;
    }

    if (month.length && date.length && year.length === 1) {
        mainVal = `${month}/${date}/${year}YYY`;
    } else if (month.length && date.length && year.length === 2) {
        mainVal = `${month}/${date}/${year}YY`;
    } else if (month.length && date.length && year.length === 3) {
        mainVal = `${month}/${date}/${year}Y`;
    } else if (month.length && date.length && year.length === 4) {
        mainVal = `${month}/${date}/${year}`;
    }
    return mainVal;
};

export const FormDateField = ({
    name,
    title,
    subTitle,
    placeholder,
    customError,
    className,
    inputClassName,
    formikProps,
    // normally form fields do not error until after submission
    // because they have not been touched
    // setting this to true triggers the error after editing
    // for the first time
    triggerErrorOnChange = false,
    ...props
}) => {
    const errorMessage = getIn(formikProps.errors, name);
    const isTouched = getIn(formikProps.touched, name);
    const value = _.get(formikProps.values, name);

    const handleChange = async (event) => {
        await formikProps.setFieldValue(name, event.value);
        if (!isTouched && triggerErrorOnChange) {
            await formikProps.setFieldTouched(name, true);
        }
    };

    return (
        <div className={createClassNameString(["FormField-container", className])}>
            {(title || subTitle) && (
                <div data-public className="FormField-header_container">
                    {title && <h3 className="FormField-title">{title}</h3>}
                    {subTitle && <h3 className="FormField-subTitle">{subTitle}</h3>}
                </div>
            )}
            <div>
                <NumberFormat
                    format={dobNumberFormat}
                    className={createClassNameString([
                        "apti-CL apti-Input FormTextInput-input",
                        inputClassName,
                        isTouched && errorMessage && "error",
                    ])}
                    placeholder={placeholder}
                    onValueChange={handleChange}
                    value={value}
                    {...props}
                />
                {!customError && errorMessage && isTouched && (
                    <FormFieldError>{errorMessage}</FormFieldError>
                )}
                {/* lets the dev pick what is displayed below the text area */}
                {customError && customError(value, errorMessage, isTouched)}
            </div>
        </div>
    );
};

FormDateField.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    customError: PropTypes.func,
    formikProps: PropTypes.object,
    triggerErrorOnChange: PropTypes.bool,
};
