import "./styles.scss";
import React from "react";
import { FormField, RadioGroup, Button, ButtonTypes, RadioOld } from "../../../component-library";

export const CalendarDeleteSeriesModalContent = ({
    title,
    radioOptions,
    deleteSeriesOnChange,
    deleteSeriesValue,
}) => {
    return (
        <>
            <FormField labelClassName="CalendarDeleteModalRecurringContent--title" label={title}>
                <RadioGroup
                    name="delete_series"
                    onChange={deleteSeriesOnChange}
                    className="CalendarDeleteModalRecurringContent--radio_group">
                    <RadioOld
                        labelClassName="CalendarDeleteModalRecurringContent--radio_button"
                        checked={deleteSeriesValue === radioOptions[0].value}
                        label={radioOptions[0].label}
                        value={radioOptions[0].value}
                    />
                    <RadioOld
                        labelClassName="CalendarDeleteModalRecurringContent--radio_button"
                        checked={deleteSeriesValue === radioOptions[1].value}
                        label={radioOptions[1].label}
                        value={radioOptions[1].value}
                    />
                </RadioGroup>
            </FormField>
        </>
    );
};

export const CalendarDeleteModal = ({
    children,
    title,
    subTitle,
    showSubTitleOnNewLine,
    onCancelText,
    onCancel,
    disableSubmit,
    onSubmitText,
    onSubmit,
}) => {
    return (
        <div className="CalendarDeleteModal--body">
            <div className="CalendarDeleteModal--inner">
                <div className="CalendarDeleteModal--title_wrapper">
                    <h2 className="CalendarDeleteModal--title">{title}</h2>
                    {!showSubTitleOnNewLine && (
                        <p className="CalendarDeleteModal--sub_title">{subTitle}</p>
                    )}
                </div>
                {showSubTitleOnNewLine && (
                    <p className="CalendarDeleteModal--sub_title">{subTitle}</p>
                )}
                {children}
                <div className="CalendarDeleteModal--footer">
                    <Button onClick={onCancel} buttonType={ButtonTypes.primaryOutlineV2}>
                        {onCancelText}
                    </Button>
                    <Button
                        disabled={disableSubmit}
                        onClick={onSubmit}
                        buttonType={ButtonTypes.primaryV2}>
                        {onSubmitText}
                    </Button>
                </div>
            </div>
        </div>
    );
};
