// Copyright aptihealth, inc. 2019 All Rights Reserved
// This file is used to decide the base url for all roles

const ROLES_CONF = {
    "admin:admin": { exactRole: "admin", routeBase: "/app" },
    "admin:billing_partner": { exactRole: "bp", routeBase: "/app" },
    "admin:customer_success_executive": { exactRole: "cse", routeBase: "/app" },
    "admin:customer_success_manager": { exactRole: "csm", routeBase: "/app" },
    "provider:behavioral": { exactRole: "behavioral", routeBase: "/app" },
    "provider:pcp": { exactRole: "pcp", routeBase: "/app" },
    "provider:prescribe": { exactRole: "prescribe", routeBase: "/app" },    
    "provider:case_manager": { exactRole: "case_manager", routeBase: "/app" },
    "provider:peer": { exactRole: "peer", routeBase: "/app" },
    "user:patient": { exactRole: "patient", routeBase: "/app" },
    "system:workflow": { exactRole: "workflow", routeBase: "/app" },
};

export const admin_admin = "admin:admin";
export const admin_bp = "admin:billing_partner";
export const admin_cse = "admin:customer_success_executive";
export const admin_csm = "admin:customer_success_manager";
export const provider_behavioral = "provider:behavioral";
export const provider_pcp = "provider:pcp";
export const provider_prescribe = "provider:prescribe";
export const provider_case_manager = "provider:case_manager";
export const provider_peer = "provider:peer";
export const user_patient = "user:patient";
export const system_workflow = "system:workflow";

export const providerRoute = "/app";
export const patientRoute = "/app";
export const adminRoute = "/app";

//eventually there should not be any group specific routes
export const baseRoute = "/app";

export default ROLES_CONF;
