/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const ExitIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Exit"
                {...props}>
                <path d="M6 7C6 5.34315 7.34315 4 9 4H18V6H9C8.44772 6 8 6.44772 8 7V25C8 25.5523 8.44771 26 9 26H18V28H9C7.34315 28 6 26.6569 6 25V7Z" />
                <path d="M20.2929 9.29289C20.6834 8.90237 21.3166 8.90237 21.7071 9.29289L28.4142 16L21.7071 22.7071C21.3166 23.0976 20.6834 23.0976 20.2929 22.7071C19.9024 22.3166 19.9024 21.6834 20.2929 21.2929L24.5858 17L14 17C13.4477 17 13 16.5523 13 16C13 15.4477 13.4477 15 14 15L24.5858 15L20.2929 10.7071C19.9024 10.3166 19.9024 9.68342 20.2929 9.29289Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Exit"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 4C7.34315 4 6 5.34315 6 7V25C6 26.6569 7.34315 28 9 28H18V4H9ZM21.7071 9.29289C21.3166 8.90237 20.6834 8.90237 20.2929 9.29289C19.9024 9.68342 19.9024 10.3166 20.2929 10.7071L24.5858 15L14 15C13.4477 15 13 15.4477 13 16C13 16.5523 13.4477 17 14 17L24.5858 17L20.2929 21.2929C19.9024 21.6834 19.9024 22.3166 20.2929 22.7071C20.6834 23.0976 21.3166 23.0976 21.7071 22.7071L28.4142 16L27.7074 15.2932C27.7072 15.293 27.7077 15.2934 27.7074 15.2932L21.7071 9.29289Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Exit"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM12.5 13.25C12.5 12.0074 13.5074 11 14.75 11H21.5V19.25H18.5C18.0858 19.25 17.75 19.5858 17.75 20C17.75 20.4142 18.0858 20.75 18.5 20.75H21.5V29H14.75C13.5074 29 12.5 27.9926 12.5 26.75V13.25ZM21.5 20.75H26.4393L23.2197 23.9697C22.9268 24.2626 22.9268 24.7374 23.2197 25.0303C23.5126 25.3232 23.9874 25.3232 24.2803 25.0303L29.3107 20L24.2803 14.9697C23.9874 14.6768 23.5126 14.6768 23.2197 14.9697C22.9268 15.2626 22.9268 15.7374 23.2197 16.0303L26.4393 19.25H21.5V20.75Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

ExitIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

ExitIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
