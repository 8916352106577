// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";

const Marker = (props) => {
    const { status, caption, hideStart, hideEnd, withNavigation, index } = props;

    let trackLClasses = ["ProgressSlider__trackL", "position-absolute"];
    let trackRClasses = ["ProgressSlider__trackR", "position-absolute"];
    let bulletClasses = ["ProgressSlider__bullet", "position-absolute"];
    let captionClasses = ["ProgressSlider__caption", "mt-4", "position-absolute"];

    if (index == 0) {
        trackLClasses.push("ProgressSlider__trackL--width");
    }
    if (index == 2) {
        trackRClasses.push("ProgressSlider__trackR--width");
    }

    if (status === "done") {
        trackLClasses.push("ProgressSlider__trackL--active");
        trackRClasses.push("ProgressSlider__trackR--active");
        bulletClasses.push("ProgressSlider__bullet--done");
        captionClasses.push("ProgressSlider__caption--active");
    } else if (status === "active") {
        captionClasses.push("ProgressSlider__caption--active");
        trackLClasses.push("ProgressSlider__trackL--active");
        bulletClasses.push("ProgressSlider__bullet--active");
    }

    if (hideStart) {
        trackLClasses.push("ProgressSlider__trackL--hidden");
    }
    if (hideEnd) {
        trackRClasses.push("ProgressSlider__trackR--hidden");
    }

    return (
        <div className="ProgressSlider__Marker position-relative">
            <div className={trackLClasses.join(" ")}></div>
            <div className={trackRClasses.join(" ")}></div>
            <div className={bulletClasses.join(" ")}></div>
            <div className={captionClasses.join(" ")}>
                <div>{caption}</div>
            </div>
        </div>
    );
};

export default Marker;
