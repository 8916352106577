import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";
import { Button } from "../Button";

import "./styles.scss";

export const Ellipsis = ({
    className,

    leftOnClick,
    centerOnClick,
    rightOnClick,

    leftDisabled,
    centerDisabled,
    rightDisabled,
    ...props
}) => {
    const leftDisabledClassName = leftDisabled ? "inactive" : "";
    const centerDisabledClassName = centerDisabled ? "inactive" : "";
    const rightDisabledClassName = rightDisabled ? "inactive" : "";

    return (
        <div className={createClassNameString(["apti-CL apti-Ellipsis", className])}>
            <Button
                className={createClassNameString(["apti-Ellipsis--button", leftDisabledClassName])}
                onClick={leftOnClick}
            />
            <Button
                className={createClassNameString([
                    "apti-Ellipsis--button",
                    centerDisabledClassName,
                ])}
                onClick={centerOnClick}
            />
            <Button
                className={createClassNameString(["apti-Ellipsis--button", rightDisabledClassName])}
                onClick={rightOnClick}
            />
        </div>
    );
};

Ellipsis.propTypes = {
    className: PropTypes.string,
    leftOnClick: PropTypes.func,
    centerOnClick: PropTypes.func,
    rightOnClick: PropTypes.func,
    leftDisabled: PropTypes.bool,
    centerDisabled: PropTypes.bool,
    rightDisabled: PropTypes.bool,
};

Ellipsis.defaultProps = {
    className: undefined,
    leftOnClick: () => {},
    centerOnClick: () => {},
    rightOnClick: () => {},
    leftDisabled: false,
    centerDisabled: false,
    rightDisabled: false,
};
