// Copyright aptihealth, inc. 2019 All Rights Reserved

/**
 * Here we are ensuring that the dob of a person is realistic.
 * i.e falling between 1900 and current year.
 */
const dt = new Date();
const currentYear = dt.getFullYear().toString();
const d1 = String(Number(currentYear[2]) - 1); // getting digit at position 2 in format YYYY. Eg 1 in 2019
const d2 = currentYear[2];
const d3 = currentYear[3];
const RE_STR_DOB = `^(((01|03|05|07|08|10|12)(0[1-9]|1[0-9]|2[0-9]|3[01]))|((04|06|09|11)(0[1-9]|1[0-9]|2[0-9]|30))|(02(0[1-9]|1[0-9]|2[0-9])))(19\\d\\d|20[0-${d1}][0-9]|20[0-${d2}][0-${d3}])$`;
const RE_DOB = new RegExp(RE_STR_DOB);

const RE_PHONE = /^\d{10}$/;

export { RE_DOB, RE_PHONE };
