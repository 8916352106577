import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { createClassNameString } from "../../component-library";
import { ICON_SIZES, ICON_TYPES } from "../../constants/icons";

export const ExitIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}>
                <path d="M6 7C6 5.34315 7.34315 4 9 4H18V6H9C8.44772 6 8 6.44772 8 7V25C8 25.5523 8.44771 26 9 26H18V28H9C7.34315 28 6 26.6569 6 25V7Z" />
                <path d="M20.2929 9.29289C20.6834 8.90237 21.3166 8.90237 21.7071 9.29289L28.4142 16L21.7071 22.7071C21.3166 23.0976 20.6834 23.0976 20.2929 22.7071C19.9024 22.3166 19.9024 21.6834 20.2929 21.2929L24.5858 17L14 17C13.4477 17 13 16.5523 13 16C13 15.4477 13.4477 15 14 15L24.5858 15L20.2929 10.7071C19.9024 10.3166 19.9024 9.68342 20.2929 9.29289Z" />
            </svg>
        );
    }

    return null;
};

ExitIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

ExitIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
