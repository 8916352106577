import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";
import { Text } from "../Text";
import { TextColors, TextTypes } from "../constants";

export const SingleQuestionAnswerText = ({
    className,
    style,
    questionSetCategory,
    answers,
    answerClassName,
}) => {
    const classNamesArray = ["apti-CL apti-SingleQuestionAnswerText", className];

    function getFormattedAnswers(answers) {
        return (
            <Text
                className={createClassNameString([
                    "apti-SingleQuestionAnswerText--answer",
                    answerClassName,
                ])}>
                {answers.length > 0 && answers.join(", ")}
            </Text>
        );
    }

    return (
        <div className={createClassNameString(classNamesArray)} style={style}>
            <div className={"pb-3"}>
                <Text type={TextTypes.label} color={TextColors.purple}>
                    {questionSetCategory}
                </Text>
            </div>

            {Array.isArray(answers) && getFormattedAnswers(answers)}

            {!Array.isArray(answers) && (
                <div className="apti-SingleQuestionAnswerTextSubCategory--text_wrapper">
                    {Object.entries(answers).map(([subcategory, answers]) => {
                        return (
                            <div key={`apti-SingleQuestionAnswerTextSubCategory-${subcategory}`}>
                                <div>
                                    <Text type={TextTypes.label}>{subcategory}</Text>
                                </div>
                                {Array.isArray(answers) && getFormattedAnswers(answers)}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

SingleQuestionAnswerText.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    questionSetCategory: PropTypes.string,
    answers: PropTypes.object,
};

SingleQuestionAnswerText.defaultProps = {
    className: undefined,
    style: {},
    questionSetCategory: undefined,
    answers: {},
};
