// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useState } from "react";
import {
    Button,
    ButtonTypes,
    Dropdown,
    FormField,
    Modal,
    Text,
    TextColors,
    TextTypes,
} from "../../../component-library";

const reasonOptions = [
    { label: "Abused prescription practice", value: "Abused prescription practice" },
    { label: "Deceased", value: "Deceased" },
    {
        label: "Declined services/requested inactivation",
        value: "Declined services/requested inactivation",
    },
    { label: "Duplicate account", value: "Duplicate account" },
    {
        label: "Member and provider agree they have completed treatment",
        value: "Member and provider agree they have completed treatment",
    },
    {
        label: "Member feels they have completed treatment",
        value: "Member feels they have completed treatment",
    },
    { label: "Out-of-network insurance", value: "Out-of-network insurance" },
    { label: "Patient is outside of service area", value: "Patient is outside of service area" },
    {
        label: "Patient referred outside of aptihealth",
        value: "Patient referred outside of aptihealth",
    },
    {
        label: "Refusal to pay for services/commit to a payment plan",
        value: "Refusal to pay for services/commit to a payment plan",
    },
    { label: "Unacceptable behavior to staff", value: "Unacceptable behavior to staff" },
    { label: "Unresponsive to outreach attempts", value: "Unresponsive to outreach attempts" },
    {
        label: "Violation of No Show/Late Cancellation Policy",
        value: "Violation of No Show/Late Cancellation Policy",
    },
];

export const ChangePatientStatusPopUp = ({
    patientName,
    cancelHandler,
    confirmHandler,
    newPatientStatus,
    oldPatientStatus,
}) => {
    const [reasonSelected, setReasonSelected] = useState("");
    const [reasonDropdownValue, setReasonDropdownValue] = useState(null);

    return (
        <Modal className="ChangePatientStatus" show={true}>
            {newPatientStatus === "INACTIVE" ? (
                <div className="ChangePatientStatus__inactive">
                    <Text type={TextTypes.heading1}>You are inactivating {patientName}</Text>

                    <FormField label="Reason for Inactivation">
                        <Dropdown
                            placeholder="Select Reason for Inactivation"
                            value={reasonDropdownValue}
                            options={reasonOptions}
                            onChange={(e) => {
                                setReasonDropdownValue({
                                    value: e.target.value,
                                    label: e.target.value,
                                });
                                setReasonSelected(e.target.value);
                            }}
                        />
                    </FormField>

                    <div className="ChangePatientStatus__buttons">
                        <Button buttonType={ButtonTypes.primaryOutline} onClick={cancelHandler}>
                            Cancel
                        </Button>
                        <Button
                            buttonType={ButtonTypes.primary}
                            onClick={() => confirmHandler(reasonSelected)}
                            disabled={!reasonSelected}>
                            Inactivate Member
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="ChangePatientStatus__active">
                    <Text type={TextTypes.heading1}>Activate Member</Text>

                    <div className="ChangePatientStatus__text">
                        <Text type={TextTypes.paragraph} color={TextColors.grey}>
                            Are you sure you want to activate {patientName}?
                        </Text>
                        <Text type={TextTypes.paragraph} color={TextColors.grey}>
                            Their status will be changed to their previous active status:{" "}
                            {oldPatientStatus}
                        </Text>
                    </div>

                    <div className="ChangePatientStatus__buttons">
                        <Button buttonType={ButtonTypes.primaryOutline} onClick={cancelHandler}>
                            No, Cancel
                        </Button>
                        <Button buttonType={ButtonTypes.primary} onClick={() => confirmHandler()}>
                            Yes, Activate Member
                        </Button>
                    </div>
                </div>
            )}
        </Modal>
    );
};
