import moment from "moment";
import React from "react";
import { PageBreak, Text, TextTypes } from "../../../../component-library";

import "./styles.scss";

export default ({ addendum, timestamp, signature, credentials, style }) => (
    <div className="AddendumDisplay" style={style}>
        <div className="AddendumDisplay__top">
            <Text type={TextTypes.heading2}>Addendum</Text>
            <Text type={TextTypes.paragraph}>
                {moment.unix(timestamp).format("MM/DD/YYYY hh:mma")}
            </Text>
        </div>
        <PageBreak color="grey" />
        <Text className="AddendumDisplay__addendum" type={TextTypes.paragraph} layout="block">
            {addendum}
        </Text>
        {signature && credentials && (
            <div className="AddendumDisplay__bottom">
                <div className="AddendumDisplay__bottom--left">
                    <Text type={TextTypes.subtitle}>Signature</Text>
                    <Text type={TextTypes.signature}>{signature}</Text>
                </div>
                <div className="AddendumDisplay__bottom--right">
                    <Text type={TextTypes.subtitle}>Credentials</Text>
                    <Text type={TextTypes.paragraph}>{credentials}</Text>
                </div>
            </div>
        )}
    </div>
);
