// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { useState } from "react";
import "./../styles.scss";
import { EditableDischarge, EditableDischargeV2 } from "./EditableDischarge";
import { getReadonlyDischarge } from "./MemberProfileNotes";

const DischargeContent = (props) => {
    const { dischargeValues, setDischargeValues, editable, classes, version } = props;
    const [dischargeCount, setDischargeCount] = useState(1);
    const [dateIndex, setDateIndex] = useState(0);
    const [showDischargeDatePickers, setShowDischargeDatePickers] = useState([false]);

    const dischargeVersion = version ? version : "1";

    const renderDischargeContent = () => {
        if (editable) {
            const discharges = [];
            if (dischargeVersion === "1") {
                for (let i = 0; i < dischargeCount; i++) {
                    discharges.push(
                        <EditableDischarge
                            classes={classes}
                            index={i}
                            dischargeCount={dischargeCount}
                            setDischargeCount={setDischargeCount}
                            dischargeValues={dischargeValues}
                            setDischargeValues={setDischargeValues}
                            showDischargeDatePickers={showDischargeDatePickers}
                            setShowDischargeDatePickers={setShowDischargeDatePickers}
                            dateIndex={dateIndex}
                            setDateIndex={setDateIndex}
                        />,
                    );
                }
            } else {
                return (
                    <EditableDischargeV2
                        classes={classes}
                        dischargeValues={dischargeValues}
                        setDischargeValues={setDischargeValues}
                        showDischargeDatePickers={showDischargeDatePickers}
                        setShowDischargeDatePickers={setShowDischargeDatePickers}
                        dateIndex={dateIndex}
                        setDateIndex={setDateIndex}
                    />
                );
            }
            return discharges;
        } else {
            return dischargeValues.map((discharge, index) =>
                getReadonlyDischarge(discharge, index, classes),
            );
        }
    };

    return (
        <div className={classes && classes.card ? classes.card : null}>
            {renderDischargeContent()}
        </div>
    );
};

export default DischargeContent;
