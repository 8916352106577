// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as actionTypes from "../actions/actionTypes";
import { ALLOW_LOGIN_POP_UPS } from "../actions/actionTypes";

let initialState = {
    currentQuestion: null,
    selectedOptions: [],
    lastSelectedOption: null,
    assessmentStatus: null,
    assessmentCompleted: false,
    startRatingScale: false,
    scheduleList: [],
    activityList: [],
    allowInactivePopUp: false,
    allowWaitListPopUp: false,
    hasAssessmentAssigned: true,
};

const startAssessment = (state, action) => {
    return {
        ...state,
        currentQuestion: action.payload.startQuestion,
        assessmentPerc: action.payload.assessmentPerc,
        selectedOptions: [],
    };
};

const nextQuestion = (state, action) => {
    return {
        ...state,
        assessmentPerc: action.payload.assessmentPerc,
        currentQuestion: action.payload.nextQuestion,
        isLastQuestion: action.payload.isLast,
        selectedOptions: [],
        lastSelectedOption: null,
    };
};

const previousQuestion = (state, action) => {
    return {
        ...state,
        currentQuestion: action.payload.currentQuestion,
        assessmentPerc: action.payload.assessmentPerc,
        lastSelectedOption: null,
        selectedOptions: [],
    };
};

const optionSelected = (state, action) => {
    return {
        ...state,
        selectedOptions: action.payload.selectedOptions,
        lastSelectedOption: action.payload.selectedOptionFull,
    };
};

const setAssessmentStatus = (state, action) => {
    return {
        ...state,
        assessmentStatus: action.payload,
    };
};

const assessmentCompleted = (state, action) => {
    return {
        ...state,
        assessmentCompleted: true,
        startRatingScale: false,
        currentQuestion: null,
    };
};

const noAssessment = (state, action) => {
    return {
        ...state,
        hasAssessmentAssigned: false,
    };
};

const startRatingScale = (state, action) => {
    return {
        ...state,
        startRatingScale: true,
    };
};

const updateScheduleList = (state, action) => {
    return {
        ...state,
        scheduleList: action.payload.scheduleList,
    };
};

const updateActivityList = (state, action) => {
    return {
        ...state,
        activityList: [...state.activityList, ...action.payload.activityList],
    };
};

export const allowLoginPopUps = (state, action) => {
    return {
        ...state,
        allowInactivePopUp: action.payload.allowInactivePopUp,
        allowWaitListPopUp: action.payload.allowWaitListPopUp,
    };
};

const disableLoginPopUps = (state, action) => {
    return {
        ...state,
        allowInactivePopUp: false,
        allowWaitListPopUp: false,
    };
};

const logout = (state, action) => {
    return {
        ...initialState,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.START_ASSESSMENT:
            return startAssessment(state, action);
        case actionTypes.NEXT_QUESTION:
            return nextQuestion(state, action);
        case actionTypes.PREVIOUS_QUESTION:
            return previousQuestion(state, action);
        case actionTypes.OPTION_SELECTED:
            return optionSelected(state, action);
        case actionTypes.SET_ASSESSMENT_STATUS:
            return setAssessmentStatus(state, action);
        case actionTypes.START_RATING_SCALE:
            return startRatingScale(state, action);
        case actionTypes.ASSESSMENT_COMPLETED:
            return assessmentCompleted(state, action);
        case actionTypes.UPDATE_SCHEDULE_LIST:
            return updateScheduleList(state, action);
        case actionTypes.UPDATE_ACTIVITY_LIST:
            return updateActivityList(state, action);
        case actionTypes.ALLOW_LOGIN_POP_UPS:
            return allowLoginPopUps(state, action);
        case actionTypes.DISABLE_LOGIN_POP_UPS:
            return disableLoginPopUps(state, action);
        case actionTypes.NO_ASSESSMENT:
            return noAssessment(state, action);
        case actionTypes.LOG_OUT:
            return logout(state, action);
        default:
            return state;
    }
};

export default reducer;
