// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import X_BARReportView from "../../../components/Common/X-BARReportView";

class IBARReport extends Component {
    render() {
        return (
            <X_BARReportView
                {...this.props}
                reportType="ibar"
                patientId={this.props.match.params.patientId}
                location={this.props.location}
            />
        );
    }
}

export default IBARReport;
