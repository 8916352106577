import React, { Component } from "react";
import _ from "lodash";
import images from "../../../../utils/images";
import { api } from "../../../../APIRequests";
import ScreeningCard from "./ScreeningCard";
import AssessmentInProgress from "./AssessmentInProgress";
import { withRouter } from "react-router-dom";
import { getQueryParams } from "../../../../utils/filters";

const START_SCREENING = "Start Screening";
const SCREENING_IN_PROGRESS = "Screening In Progress";
const SCREENING_COMPLETED = "Screening Completed";
const DEFAULT = "default";
const ACTIVE = "active";
const DONE = "done";

class GenericWorkflowScreening extends Component {
    startMessage = [
        "Please take 5 minutes to answer questions that will help aptihealth better understand the struggles you may be having.",
        "Once you are done, your results will be sent to your provider. Your provider will review the results and help you decide if aptihealth can help.",
    ];
    endMessage = [
        "Thank you for taking the time to answer the questions. Your results have been sent to your provider, who will discuss next steps with you shortly.",
        "Please hand this device back to a staff member.",
    ];

    constructor(props) {
        super(props);
        this.state = {
            markerData: [
                {
                    status: ACTIVE,
                    caption: START_SCREENING,
                    component: (
                        <ScreeningCard
                            title="Complete your personal screening"
                            message={this.startMessage}
                            submitHandler={() => this.setWorkflowStep(1)}
                            submitText="Start Screening"
                            cancelText="Cancel"
                            cancelHandler={this.props.backHandler}
                        />
                    ),
                },
                {
                    status: DEFAULT,
                    caption: SCREENING_IN_PROGRESS,
                    component: (
                        <AssessmentInProgress
                            {...props}
                            nextStepHandler={this.setWorkflowStep}
                            prevStepHandler={this.setWorkflowStep}
                        />
                    ),
                },
                {
                    status: DEFAULT,
                    caption: SCREENING_COMPLETED,
                    component: (
                        <ScreeningCard
                            title="Success! You've completed your screening"
                            message={this.endMessage}
                            submitHandler={this.props.resetWorkflow}
                            submitText="Back to Home Screen"
                        />
                    ),
                },
            ],
        };
    }

    getWorkflowScreening = async () => {
        const options = { params: { showLoader: true } };
        const headers = {
            "X-On-Behalf-Of": this.props.sharedWorkflowData.workflow_instance.entity_id,
        };
        const data = {
            workflow_instance_sort_key: this.props.sharedWorkflowData.workflow_instance.sort_key,
        };
        return await api.workflow.screening({ options, data, headers });
    };

    setWorkflowInstanceData = async () => {
        const screeningResponse = await this.getWorkflowScreening();
        if (screeningResponse && screeningResponse["workflow"]) {
            this.props.setSharedWorkflowData({
                ...this.props.sharedWorkflowData,
                workflow_instance: screeningResponse["workflow"],
            });
        }
    };

    backToHomeScreen = () => {
        this.props.setSharedWorkflowData({});
        this.props.history.push(
            `/app/workflow?practiceId=${getQueryParams("practiceId", this.props.location.search)}`,
        );
    };

    setSharedData = (sharedData) => {
        this.setState({ sharedData });
    };

    setWorkflowStep = async (workflowStep) => {
        let markerData = [...this.state.markerData];
        if (workflowStep === 0) {
            markerData[0].status = ACTIVE;
            markerData[1].status = DEFAULT;
            markerData[2].status = DEFAULT;
        } else if (workflowStep === 1) {
            markerData[0].status = DONE;
            markerData[1].status = ACTIVE;
            markerData[2].status = DEFAULT;
        } else if (workflowStep === 2) {
            markerData[0].status = DONE;
            markerData[1].status = DONE;
            markerData[2].status = ACTIVE;
        }
        this.setState({
            markerData: markerData,
        });
    };

    getActiveComponent = (details) => {
        const activeStatus = this.state.markerData.find((marker) => marker.status === ACTIVE);
        let component = activeStatus.component;
        if (!activeStatus) {
            if (
                this.state.markerData[0].status === DONE &&
                this.state.markerData[1].status === DONE &&
                this.state.markerData[2].status === DONE
            ) {
                component = this.state.markerData[2].component;
            }
        }
        return component;
    };

    render() {
        return (
            <div>
                <div className="container px-3 d-flex flex-column">
                    <div className="container">{this.getActiveComponent()}</div>
                </div>
            </div>
        );
    }
}

export default withRouter(GenericWorkflowScreening);
