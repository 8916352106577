// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import { MemberProfileActivityEntry } from "../../../component-library";

export const EmailUpdatedActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const { old_email, new_email, admin_name, admin_id } = keyContent;

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Dark"
            title="Admin Update"
            subTitle={"Email Updated"}
            localDateTime={localCreatedTime}
            iconSrc="activate_timeline.svg"
            iconAlt="EmailUpdatedIcon">
            <div className={"fs-14"}>
                <div>{`Old Email: ${old_email}`}</div>
                <div>{`New Email: ${new_email}`}</div>
                <div>{`Action Taken By: ${admin_name} (${admin_id})`}</div>
            </div>
        </MemberProfileActivityEntry>
    );
};

export const PasswordResetActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const { admin_name, admin_id } = keyContent;

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Dark"
            title="Admin Update"
            subTitle={"Admin Password Reset"}
            localDateTime={localCreatedTime}
            iconSrc="activate_timeline.svg"
            iconAlt="PasswordResetIcon">
            <div className={"fs-14"}>
                <div>{`Action Taken By: ${admin_name} (${admin_id})`}</div>
            </div>
        </MemberProfileActivityEntry>
    );
};

export const PracticeChangedActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const {
        old_pcp_id,
        new_pcp_id,
        old_pcp_practice_id,
        new_pcp_practice_id,
        old_pcp_name,
        new_pcp_name,
        admin_name,
        admin_id,
    } = keyContent;

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Dark"
            title="Admin Update"
            subTitle={"Patient Practice Changed"}
            localDateTime={localCreatedTime}
            iconSrc="activate_timeline.svg"
            iconAlt="PracticeChangedIcon">
            <div className={"fs-14"}>
                <div>{`Old PCP Id: ${old_pcp_id}`}</div>
                <div>{`New PCP Id: ${new_pcp_id}`}</div>
                <div>{`Old PCP Practice Id: ${old_pcp_practice_id}`}</div>
                <div>{`New PCP Practice Id: ${new_pcp_practice_id}`}</div>
                <div>{`Old PCP Name: ${old_pcp_name}`}</div>
                <div>{`New PCP Name: ${new_pcp_name}`}</div>
                <div>{`Action Taken By: ${admin_name} (${admin_id})`}</div>
            </div>
        </MemberProfileActivityEntry>
    );
};
