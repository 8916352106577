// Copyright aptihealth, inc. 2021 All Rights Reserved
import React, { useEffect, useState } from "react";
import { api } from "../../../APIRequests";
import { connect } from "react-redux";
import { getPatientProfile } from "../../../redux/actions/auth";
import { AppointmentTimePicker } from "../../Common/Schedule/AppointmentTimePicker";
import Button from "../../UI/Button";
import { withRouter } from "react-router-dom";
import { getQueryParams } from "../../../utils/filters";
import { trackEvent } from "../../../utils/EventTrackingUtil";
import { TEENS_AND_KIDS_DIAGNOSTIC_INTERVIEW_90791_ALLOTTED_TIME } from "../../Provider/ScheduleAvailability/constants";
import images from "../../../utils/images";

const ScheduleCall = (props) => {
    const { nextStep } = props;
    const patientId = getQueryParams("patientId", props.location.search);

    const [intakeAppointments, setIntakeAppointments] = useState(null);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [numDaysToShow, setNumDaysToShow] = useState(7);

    useEffect(() => {
        const enrichedAssociatedWards = props.auth.profile.enriched_associated_wards;
        if (
            enrichedAssociatedWards &&
            enrichedAssociatedWards.find((x) => x.patient_id === patientId && x.schedule_video_call)
        ) {
            props.alreadyScheduled(patientId);
            return;
        }
        (async () => {
            // Note: In the absence of a more formal way to dynamically adjust the config from 45 minute DI
            // to 90 minute DI, the backend was expanded to support custom durations for appointments
            const data = {
                id: patientId,
                timezone: props.auth.timezone,
                override_meeting_duration: TEENS_AND_KIDS_DIAGNOSTIC_INTERVIEW_90791_ALLOTTED_TIME,
            };
            const availability = await api.patient.get_upcoming_intake_availability({ data });
            setIntakeAppointments(availability["upcoming_availability"]);
        })();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        handleResize();
    }, [intakeAppointments]);

    function handleResize() {
        const calendarBody = document.getElementById("calendar-body");
        const calendarColumns = document.getElementsByClassName("time-picker-day-btn");

        if (calendarBody && calendarColumns && calendarColumns[0]) {
            const columnWidth = calendarColumns[0].getBoundingClientRect().width;

            const calendarWidth = calendarBody.getBoundingClientRect().width;
            let maxColumns = Math.floor(calendarWidth / columnWidth);

            if (maxColumns > 7) {
                maxColumns = 7;
            } else if (maxColumns < 1) {
                maxColumns = 1;
            }

            setNumDaysToShow(maxColumns);
        }
    }

    const submit = async () => {
        const { year, month, day, start_time } = selectedAppointment;
        const local_date_time = `${year}-${month}-${day} ${start_time}`;

        const data = {
            local_date_time,
            provider_id: selectedAppointment["provider_username"],
            patient_id: patientId,
            allotted_time: selectedAppointment["allotted_time"],
            timezone: props.auth.timezone,
        };

        await api.schedule.post_schedule({ data });
        trackEvent("Submit Schedule for Child");
        await props.getPatientProfile();
        nextStep();
    };

    const today = new Date();
    const todaysDate = {
        day_of_week: today.toLocaleDateString("default", { weekday: "long" }),
        month_of_year: today.toLocaleString("default", { month: "long" }),
        day: today.getDate(),
        year: today.getFullYear(),
    };

    const timePickerProps = {
        schedulingData: intakeAppointments,
        setSchedulingData: setIntakeAppointments,
        todaysDate,
        patientDetails: {
            username: patientId,
            override_meeting_duration: TEENS_AND_KIDS_DIAGNOSTIC_INTERVIEW_90791_ALLOTTED_TIME,
        },
        selectedAppointment,
        setSelectedAppointment,
        className: "",
        numDaysToShow: numDaysToShow,
        maxHeight: 320,
        maxDayIndex: null,
    };

    return (
        <>
            <div className={"txt-sec fw-bold pt-2 mx-3 workflow-step-title"} data-public={"true"}>
                Step 5 of 5: Schedule A Therapist Consultation
            </div>

            <div className={"mx-4 my-3"} data-public={"true"}>
                <div className={"d-md-flex mx-3 my-5"}>
                    <div className={"program-details-icon-container"}>
                        <img
                            src={images("./patient/teens_and_kids_program/icn-calendar.svg")}
                            alt={"general info"}
                            className={"program-details-icon"}
                        />
                    </div>
                    <div className={"ml-4"}>
                        <div className={"txt-pri fw-bold fs-20"}>
                            Congratulations, you’ve referred your child to aptihealth!
                        </div>
                        <div>
                            Together, you will attend the therapist session from your{" "}
                            <span className={"fw-bold"}>child’s account.</span>
                        </div>
                    </div>
                </div>

                <div className={"time-picker-container mb-4"}>
                    {intakeAppointments && <AppointmentTimePicker {...timePickerProps} />}
                    {!intakeAppointments && (
                        <div className={"m-3 font-italic txt-gry"}>
                            Loading Therapist Availability...
                        </div>
                    )}
                </div>
                <div className={"d-flex justify-content-end pb-4"}>
                    <Button
                        className={"Btn Btn--pri"}
                        disabled={selectedAppointment === null}
                        onClick={submit}>
                        Continue
                    </Button>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps, { getPatientProfile })(withRouter(ScheduleCall));
