import React from "react";
import { useSelector } from "react-redux";
import { AVAILABLE, AWAY } from "../../../../constants/event";
import { CalendarAppointmentDatePicker } from "./CalendarAppointmentDatePicker";
import { CalendarAppointmentAllDayToggle } from "./CalendarAppointmentAllDayToggle";
import { FromToTimePicker, TimePicker } from "./CalendarAppointmentTimePicker";

export const CalendarAppointmentDateTimePicker = ({ menuPosition }) => {
    const appointmentSelections = useSelector((state) => state.calendar.appointmentSelections);
    const startTime = appointmentSelections.startTime;
    const endTime = appointmentSelections.endTime;
    const eventType = appointmentSelections.eventType;
    const shouldShowFromToPicker = [AVAILABLE, AWAY].includes(eventType);
    const isAllDayChecked = eventType === AWAY && startTime === "00:00" && endTime === "24:00";

    // shows the date and time picker side by side similar to the old calendar
    if (!shouldShowFromToPicker) {
        return (
            <div className="apti-CalendarAppointmentDateTimePicker--wrapper-container">
                <CalendarAppointmentDatePicker />
                <TimePicker menuPosition={menuPosition} />
            </div>
        );
    }

    // shows the date on a separate line and the new time picker style
    return (
        <>
            <CalendarAppointmentDatePicker />
            {shouldShowFromToPicker && !isAllDayChecked && (
                <FromToTimePicker menuPosition={menuPosition} />
            )}
            {eventType === AWAY && <CalendarAppointmentAllDayToggle />}
        </>
    );
};
