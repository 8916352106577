// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Button from "../../../UI/Button";
import images from "../../../../utils/images";

export const MemberProfileFiles = (props) => {
    const { viewAllLink, fileList, getFile } = props;
    return (
        <div className={"PatientProfile__ProfileDetailsCard my-3"}>
            <div className="d-flex justify-content-between medical-info-header p-3">
                <h1 className="fs-16 fw-bold ml-3 m-0">Associated Files</h1>
                <div className="PatientRatingCard__view-all-link-wpr fs-14 mr-3">
                    <Link to={viewAllLink}>View All</Link>
                </div>
            </div>
            <div className="px-4 pt-4 pb-1">
                <div className={"row mb-2 fw-bold d-lg-flex d-none"}>
                    <div className={"col-lg-6"}>File Name</div>
                    <div className={"col-lg-5"}>Uploaded Date</div>
                    <div className={"col-lg-1"} />
                </div>
                {fileList &&
                    fileList
                        .sort((fileA, fileB) => fileB.timestamp > parseInt(fileA.timestamp))
                        .slice(0, 2)
                        .filter((file) => file)
                        .map((file) => {
                            const fileName = file.file_key.substring(
                                file.file_key.lastIndexOf("/") + 1,
                            );
                            const timestamp = moment
                                .utc(file.timestamp)
                                .local()
                                .format("MM/DD/YYYY HH:mm");
                            return (
                                <>
                                    <div className={"row mb-2 d-lg-flex d-none"}>
                                        <div className={"col-lg-6"}>{fileName}</div>
                                        <div className={"col-lg-5"}>{timestamp}</div>
                                        <div className={"col-lg-1"}>
                                            <div className="cursor">
                                                <img
                                                    onClick={() => getFile(file.file_key)}
                                                    src={images("./common/download.png")}
                                                    alt="download"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mb-2 d-lg-none d-block"}>
                                        <div className={"d-flex justify-content-between"}>
                                            <div>
                                                <div className={"fw-bold mb-2"}>{fileName}</div>
                                                <div>{timestamp}</div>
                                            </div>
                                            <div className="cursor">
                                                <img
                                                    onClick={() => getFile(file.file_key)}
                                                    src={images("./common/download.png")}
                                                    alt="download"
                                                />
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                </>
                            );
                        })}
            </div>
            <hr className={"d-lg-block d-none mx-4"} />
            <div className={"d-flex justify-content-end pb-3 pr-4"}>
                <Button
                    onClick={props.showPopUp}
                    className="Btn Btn--pri Btn-sm ml-4 fs-16 text-center text-white">
                    Upload File
                </Button>
            </div>
        </div>
    );
};
