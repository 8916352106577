/*
Props = {
    column_options: [{display_name: string, field_accessor: {key: string | number, render_function}, col_size}];
    grid_data: []:
    generic_list_key: string
    list_title: string
    render_controls
}
*/
import React, { Component, useState } from "react";
import {
    List,
    ListBody,
    ListHeader,
    ListItem,
    ListTitleBar,
    TitleBarItem,
} from "../../UI/ListView";
import SearchBar from "../../UI/SearchBar";
import ListLoader from "../Loaders/ListLoader";

const GenericList = (props) => {
    const [searchText, setSearchText] = useState("");

    const {
        column_options,
        grid_data,
        generic_list_key,
        list_title,
        render_controls,
        is_searchable,
        titleBarItemClickHandler,
        sortOptions,
    } = props;

    const list_title_bar = column_options.map((column) => {
        let title = column.display_name;
        if (column.isSortable) {
            if (sortOptions && sortOptions.col_key === column.field_accessor.key) {
                title += sortOptions.sortAsc ? " ↑" : " ↓";
            } else {
                title += " ↓↑";
            }
        }

        return (
            <TitleBarItem
                key={column.display_name.trim()}
                classes={column.col_size}
                text={title}
                onClick={() => titleBarItemClickHandler(column)}
            />
        );
    });

    const filterList = (list) => {
        if (searchText === "") {
            return list;
        }
        const primaryFilter = searchText.replace(
            new RegExp("[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\-]", "g"),
            "\\$&",
        );
        const fieldNames = column_options
            .filter((col) => col.isSearchable)
            .map((col) => col.field_accessor.key);
        const filteredList = list.filter((row) => {
            const matches = fieldNames.map((fieldName) => {
                return new RegExp(primaryFilter.toLowerCase()).test(row[fieldName].toLowerCase());
            });

            return matches.reduce((prevValue, currentValue) => prevValue || currentValue);
        });

        return filteredList;
    };

    const updateSearch = (e) => {
        setSearchText(e.target.value);
    };

    const renderControls = () => {
        return (
            <div className="d-flex">
                {is_searchable && (
                    <SearchBar className="mr-lg-2" value={searchText} onchange={updateSearch} />
                )}
                {render_controls}
            </div>
        );
    };

    const renderGenericList = (listitems) => {
        const renderedListItems = listitems ? listitems : <ListLoader />;
        return (
            <ListBody classes={props.className} styles={{ maxWidth: 1300 }}>
                <ListHeader
                    className={props.headerClass}
                    title={list_title}
                    render={renderControls}
                    renderContainerClassName={"w-100"}
                />
                <ListTitleBar>{list_title_bar}</ListTitleBar>
                <List>{renderedListItems}</List>
            </ListBody>
        );
    };

    const renderRowData = (row, column) => {
        let render_field = column.field_accessor.render_function
            ? column.field_accessor.render_function(row)
            : row[column.field_accessor.key];

        const spanKey = row[column.field_accessor.key]
            ? row[column.field_accessor.key]
            : render_field;
        return (
            <span key={spanKey} className={column.col_size}>
                {render_field}
            </span>
        );
    };

    if (!grid_data) {
        return renderGenericList(grid_data);
    }

    const filteredGridData = filterList(grid_data);

    let listitems = filteredGridData.map((row) => {
        const row_values = column_options.map((column) => {
            return renderRowData(row, column);
        });
        return (
            <ListItem key={row[generic_list_key]}>
                <div className="d-flex d-lg-none justify-content-between">
                    <div className="w-75 d-flex fs-14 flex-column">{row_values}</div>
                </div>
                <div className="no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between">
                    {row_values}
                </div>
            </ListItem>
        );
    });

    return renderGenericList(listitems);
};

export default GenericList;
