/**
 * separates multi day time slots due to TZ conversion into separate slots
 * for easier processing
 *
 * NOTE: this assumes that a time frame can only be 24hrs and thus only span at most two days
 *
 * @param {import("moment").Moment} startTime
 * @param {import("moment").Moment} endTime
 */
const convertToMultiDaySlots = (startTime, endTime) => {
    // if the start and end day are on multiple days
    // break them up
    if (startTime.day() !== endTime.day()) {
        return [
            {
                startTime: startTime.clone(),
                endTime: startTime.clone().set({
                    hour: 23,
                    minute: 59,
                }),
            },
            {
                startTime: endTime.clone().set({
                    hour: 0,
                    minute: 0,
                }),
                endTime: endTime.clone(),
            },
        ];
    }
    // otherwise the slot stays the same
    else {
        return [
            {
                startTime: startTime.clone(),
                endTime: endTime.clone(),
            },
        ];
    }
};

export { convertToMultiDaySlots };
