// Copyright aptihealth, inc. 2021 All Rights Reserved

import React from "react";
import "./styles.scss";
import { Button, ButtonTypes, Image, Link, Text, TextTypes } from "../../../component-library";
import { api } from "../../../APIRequests";
import { connect } from "react-redux";
import CardSecondary from "../../UI/Card/CardSecondary";
import { BrandedHeader } from "../BrandedHeader";
import {
    CANCELLATION_POLICY_NAME,
    createAcknowledgement,
    OUT_OF_NETWORK_INSURANCE_POLICY_NAME,
    trackPolicyAcknowledgement,
    UNINSURED_POLICY_NAME,
} from "../../../utils/userAgreementUtil";
import { trackEvent } from "../../../utils/EventTrackingUtil";

const PolicyAcknowledgement = (props) => {
    const submitHandler = async () => {
        try {
            const acknowledgements = { ...props.profile.acknowledgements };
            acknowledgements[currentAcknowledgement] = await createAcknowledgement();

            const data = {
                username: props.profile.username,
                acknowledgements: acknowledgements,
            };

            await api.patient.update_profile({ data });

            props.acknowledged(currentAcknowledgement, acknowledgements[currentAcknowledgement]);
            trackPolicyAcknowledgement(currentAcknowledgement);
        } catch (e) {
            console.log(e);
        }
    };

    const acknowledgements = {
        [CANCELLATION_POLICY_NAME]: (
            <UpdatedCancellationPolicy effectiveDate={"9/24/2021"} submitHandler={submitHandler} />
        ),
        [UNINSURED_POLICY_NAME]: (
            <InsurancePolicy
                insurancePolicyName={UNINSURED_POLICY_NAME}
                submitHandler={submitHandler}
            />
        ),
        [OUT_OF_NETWORK_INSURANCE_POLICY_NAME]: (
            <InsurancePolicy
                insurancePolicyName={OUT_OF_NETWORK_INSURANCE_POLICY_NAME}
                submitHandler={submitHandler}
            />
        ),
    };

    const currentAcknowledgement = props.match.params.policy_name;

    return (
        <>
            <BrandedHeader />
            {acknowledgements[currentAcknowledgement]}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps, {})(PolicyAcknowledgement);

const UpdatedCancellationPolicy = (props) => {
    const content = (
        <>
            <div className={"fw-bold fs-20"}>Updated Cancellation Policy</div>
            <div className={"txt-gry pb-3"}>Effective as of {props.effectiveDate}</div>
            <div className={"pl-md-3"}>
                <div className={"pb-3"}>
                    {"With the growing need for behavioral healthcare and a national shortage of behavioral health " +
                        "specialists, it’s important that appointments not be skipped or cancelled so that aptihealth’s " +
                        "therapists and prescribers can spend that time with other members. In an effort to reduce the " +
                        "number of skipped or cancelled appointments, "}
                    <span className={"fw-bold"}>
                        we have implemented a new Cancellation Policy.
                    </span>
                </div>
                <div className={"pb-3"}>
                    <span className={"fw-bold"}>
                        {
                            "Effective immediately, aptihealth requires 24 hours’ notice when making appointment changes"
                        }
                    </span>
                    {", which includes the cancellation and rescheduling of appointments. Providing less than 24 hours’ " +
                        "notice, or skipping the appointment completely, will be considered a cancellation of the scheduled " +
                        "appointment."}
                </div>
                <div className={"pb-3"}>
                    {"If 3 cancellations occur within a 60-day period, we may consider ending " +
                        "your service with aptihealth. Of course, we understand that emergencies arise and will take " +
                        "that into consideration. Please communicate with your therapist directly and as soon as " +
                        "possible when an emergency occurs."}
                </div>
                <div className={"pb-3"}>
                    <span className={"fw-bold"}>
                        {"If you skip an appointment or fail to provide 24 hours’ notice of an appointment change, " +
                            "you may be charged."}
                    </span>
                    {" That fee will depend on the type of session: "}
                </div>
                <div className={"pb-3"}>
                    <ul className={"policy-list"}>
                        <li className={"policy-list-item"}>Therapist consultation: $150.00</li>
                        <li className={"policy-list-item"}>Medication consultation: $150.00</li>
                        <li className={"policy-list-item"}>Therapy session: $75.00</li>
                        <li className={"policy-list-item"}>
                            Medication management session: $95.00
                        </li>
                    </ul>
                </div>
                <div className={"pb-3"}>
                    {
                        "Making excessive appointment changes may result in a change of scheduling options."
                    }
                </div>
                <div className={"pb-3"}>
                    {"This policy will allow us to make our therapists and prescribers available to more " +
                        "members, so they can receive the same dedicated care that you do. We appreciate your " +
                        "respect of our team’s time and cooperation in helping us deliver timely care."}
                </div>
            </div>
            <div className={"d-flex justify-content-center my-5"}>
                <Button
                    buttonType={ButtonTypes.primary}
                    onClick={props.submitHandler}
                    data-e2e={"policy-acknowledgement-button"}>
                    Acknowledge
                </Button>
            </div>
        </>
    );

    return (
        <>
            <div className="container p-3">
                <CardSecondary className={"p-4"} style={{ maxWidth: "unset" }}>
                    {content}
                </CardSecondary>
            </div>
        </>
    );
};

const InsurancePolicy = (props) => {
    const title = "Your Insurance Information Was Recently Changed";

    const body = {
        [UNINSURED_POLICY_NAME]: (
            <>
                You have no insurance on file. You will be responsible for any fees associated with
                your care. Learn about&nbsp;
                <Link
                    href="/session-pricing"
                    onClick={() => trackEvent("patient-chart-no-insurance-modal-click-rates")}>
                    aptihealth's rates <Image src="open-in-tab.svg" />
                </Link>
            </>
        ),
        [OUT_OF_NETWORK_INSURANCE_POLICY_NAME]: (
            <>
                The insurance company may be out-of-network. You will be responsible for any fees
                associated with your care. Learn about&nbsp;
                <Link
                    href="/session-pricing"
                    onClick={() =>
                        trackEvent("patient-chart-insurance-not-listed-modal-click-rates")
                    }>
                    aptihealth's rates <Image src="open-in-tab.svg" />
                </Link>
            </>
        ),
    };

    const supportPhoneNumberLink = (
        <a className={"support-link"} href={"tel:8884543827"}>
            (888) 454-3827
        </a>
    );
    const supportEmail = "support@aptihealth.com";
    const supportEmailLink = (
        <a className={"support-link"} href={`mailto: ${supportEmail}?subject=Web Support Request`}>
            {supportEmail}
        </a>
    );
    const footer = (
        <>
            If you need assistance or have any questions please contact {supportPhoneNumberLink} or{" "}
            {supportEmailLink}
        </>
    );

    return (
        <div className="container insurance-policy-container">
            <CardSecondary className={"insurance-policy-card"}>
                <div className={"apti-InsurancePolicyAcknowledgement"}>
                    <Text
                        type={TextTypes.heading1}
                        className={"apti-InsurancePolicyAcknowledgement__title"}>
                        {title}
                    </Text>
                    <Text
                        type={TextTypes.paragraph}
                        className={"apti-InsurancePolicyAcknowledgement__text"}>
                        {body[props.insurancePolicyName]}
                    </Text>
                    <div className={"apti-InsurancePolicyAcknowledgement__button"}>
                        <Button buttonType={ButtonTypes.primary} onClick={props.submitHandler}>
                            Acknowledge
                        </Button>
                    </div>
                    <p className={"apti-InsurancePolicyAcknowledgement__footer"}>{footer}</p>
                </div>
            </CardSecondary>
        </div>
    );
};
