import { CATCH_ALL_INSURANCE_OPTIONS } from "../constants/insurnaceIconMap";

export function createClassNameString(classNameArray) {
    return classNameArray.filter((className) => !!className).join(" ");
}

export const mobileViewport = 420;
export const tabletViewport = 990;

export const loremIpsumWord = "Lorem ipsum";

export const loremIpsumSentence =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";

export const loremIpsumParagraph = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
Quam id leo in vitae turpis massa. Nec ullamcorper sit amet risus nullam eget felis eget.
In aliquam sem fringilla ut. Lorem mollis aliquam ut porttitor.`;

export const exampleOptions = [
    { label: "Practice 1", value: "practice-1" },
    { label: "Practice 2", value: "practice-2" },
    { label: "Practice 3", value: "practice-3" },
    { label: "Practice 4", value: "practice-4" },
];

export const insuranceCompaniesOptions = [
    { label: "Insurance 1", value: "insurance-1" },
    { label: "Insurance 2", value: "insurance-2" },
    { label: "Insurance 3", value: "insurance-3" },
    { label: "Insurance 4", value: "insurance-4" },
];

export const policyHolderOptions = [
    { label: "Self", value: "self" },
    { label: "Other", value: "other" },
];

export const stateOfInsurancePlanOptions = [
    { label: "Alabama", value: "alabama" },
    { label: "Alaska", value: "alaska" },
    { label: "etc.", value: "etc" },
];

export const generateRandomId = () => Math.floor(Math.random() * 1000000);

export const mappedChangeHandler = (handleChange, fieldName) => (e) => {
    let name, value;
    if (!e.target) {
        name = fieldName;
        value = e.value;
    } else {
        name = e.target.name;
        value = e.target.value;
    }

    handleChange({ ...e, target: { ...e.target, name, value } });
};

export const mapDropDownValue = (value) => ({
    label: value,
    value: value,
});

export const insuranceCompanyFilterOptions = (option, inputValue) => {
    if (CATCH_ALL_INSURANCE_OPTIONS.includes(option.label)) {
        return true;
    }

    const lowerCaseLabel = option.label.toLowerCase();
    const lowerCaseInputValue = inputValue.toLowerCase();

    return !!(
        lowerCaseLabel === lowerCaseInputValue || lowerCaseLabel.includes(lowerCaseInputValue)
    );
};

export const standardDropdownFilter = (option, inputValue) => {
    const lowerCaseLabel = option.label.toLowerCase();
    const lowerCaseInputValue = inputValue.toLowerCase();

    return !!(
        lowerCaseLabel === lowerCaseInputValue || lowerCaseLabel.includes(lowerCaseInputValue)
    );
};

export const capitalizeFirstLetter = (word) => word.charAt(0).toUpperCase() + word.slice(1);
