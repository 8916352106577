import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { CalendarEventBody } from "./CalendarEvent";
import { PatientSession } from "./PatientSession";
import { AwayTime } from "./AwayTime";
import { getPatientSessionStatus } from "../../../utils/calendar/event";
import { AVAILABLE, AWAY, BUSY } from "../../../constants/event";
import { Availability } from "./Availability";
import { hasOverlap } from "../../../utils/calendar/dates";
import { UnconfirmedCalendarEventBody } from "./CalendarEvent";
import { BusyEvent } from "./BusyEvent";

const eventToComponent = (event, availability) => {
    if (event.details?.event_type === AWAY) {
        return (
            <AwayTime
                startTime={event.startTime}
                endTime={event.endTime}
                position={event.position}
                details={event.details}
                label={event.details?.label}
            />
        );
    } else if (event.details?.event_type === AVAILABLE) {
        return (
            <Availability
                startTime={event.startTime}
                endTime={event.endTime}
                position={event.position}
                details={event.details}
            />
        );
    } else if (event.details?.event_type === BUSY) {
        return (
            <BusyEvent
                startTime={event.startTime}
                endTime={event.endTime}
                position={event.position}
                details={event.details}
            />
        );
    }
    // if the event has a patient name then it is a patient event
    else if (event.details?.patient_name) {
        const status = getPatientSessionStatus(event.details);
        const overlappingAvailabilitySlots = availability
            .filter((availableSlot) => {
                return hasOverlap(
                    availableSlot.startTime,
                    availableSlot.endTime,
                    event.startTime,
                    event.endTime,
                );
            })
            .map((availableSlot) => {
                return availableSlot.details;
            });

        return (
            <PatientSession
                startTime={event.startTime}
                endTime={event.endTime}
                position={event.position}
                patientName={event.details?.patient_name ?? "N/A"}
                status={status}
                details={event.details}
                availability={overlappingAvailabilitySlots}
            />
        );
    } else if (event.details?.unconfirmed === true) {
        return <UnconfirmedCalendarEventBody position={event.position} />;
    }
};

const EventGroup = ({ eventGroup }) => {
    const { position, events } = eventGroup;
    const availabilityInGroup = eventGroup.events.filter(
        (event) => event.details.event_type === AVAILABLE,
    );

    if (events.length - availabilityInGroup.length > 3) {
        return (
            <CalendarEventBody
                position={position}
                className="EventGroup-overflow__body"
                details={events.map((event) => event.details)}>
                <p className="EventGroup-overflow__title">
                    <b>{events.length} events</b> - select to see all
                </p>
            </CalendarEventBody>
        );
    }

    return <>{events.map((event) => eventToComponent(event, availabilityInGroup))}</>;
};

EventGroup.propTypes = {
    position: PropTypes.object,
    events: PropTypes.arrayOf(PropTypes.object),
};

export { EventGroup };
