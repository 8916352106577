// Copyright aptihealth, inc. 2021 All Rights Reserved
import React from "react";
import { Link } from "react-router-dom";
import { Image } from "../../../../component-library";
import images from "../../../../utils/images";

const CheckEmail = ({ emailTo }) => {
    return (
        <>
            <div className={"p-5"}>
                <div className={"fs-22 txt-pri fw-bold mb-5 text-center"}>You're almost there!</div>
                <div className={"text-center my-5"}>
                    <img
                        src={images("./signup/progress-3.png")}
                        alt={"Step 3/3"}
                        className={"signup-progress-bar"}
                    />
                </div>
                <div className={"my-2"}>
                    <div className={"text-center mx-auto"} style={{ maxWidth: 580 }}>
                        <p>
                            We sent an email to <b>{emailTo}</b>
                        </p>
                    </div>
                </div>
                <div className={"my-2 mt-4"}>
                    <div className={"text-center txt-gry mx-auto"} style={{ maxWidth: 580 }}>
                        <p>
                            Click the link in that email within 24 hours to continue your sign up.
                            If you don’t see it, you may need to check your spam folder.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CheckEmail;
