// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Fragment } from "react";

//components
import Button from "../../../../components/UI/Button";

//utils
import { toTitleCase } from "../../../../utils/filters";
import images from "../../../../utils/images";
import moment from "moment-timezone";

//constants
import { withRouter } from "react-router-dom";

const ArchivedReferralListItem = (props) => {
    const { patient, unarchiveBtnHandler } = props;

    const { created_date, referral_code, referral_status_reason, referral_status_reason_context } =
        patient;

    const createdDate = created_date
        ? moment(parseFloat(created_date) * 1000).format("MM/DD/YYYY")
        : null;

    const formatMobileNumber = (val) => {
        let firstNumber = val.substring(0, 3);
        let middleNumber = val.substring(3, 6);
        let lastNumber = val.substring(6, 10);
        return `(${firstNumber}) ${middleNumber}-${lastNumber}`;
    };

    let mobileListItem = (
        <>
            <div className="d-flex d-lg-none justify-content-between">
                <div
                    className="d-flex fs-14 flex-column"
                    style={{ width: "65%", wordWrap: "break-word" }}>
                    <span className="fs-16 fw-bold">
                        {toTitleCase(patient.first_name + " " + patient.last_name)}
                    </span>
                    <span>{patient.email}</span>
                    <span>{formatMobileNumber(patient.mobile)}</span>
                </div>
                <div className="d-flex fs-14 flex-column">
                    <div>
                        <span>{createdDate}</span>
                    </div>
                    <div>
                        <span>{referral_status_reason || ""}</span>
                    </div>
                    <div>
                        <span>{referral_status_reason_context || ""}</span>
                    </div>
                </div>
            </div>
            <div className="d-flex d-lg-none justify-content-between mt-4">
                <Button
                    className="Btn Btn--sec Btn-xs fs-12 "
                    onClick={() => props.history.push("/app/referral/" + patient["referral_code"])}>
                    View
                </Button>
            </div>
        </>
    );
    const name = toTitleCase(patient.first_name + " " + patient.last_name);
    let desktopListItem = (
        <div className="row no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between">
            <span
                className="col-2"
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                data-toggle="tooltip"
                data-placement="top"
                title={name}>
                {name}
            </span>
            <span
                className="col-2"
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                data-toggle="tooltip"
                data-placement="top"
                title={patient.email}>
                {patient.email}
            </span>
            <span className="col-2">{formatMobileNumber(patient.mobile)}</span>
            <span className="col-1">{createdDate}</span>
            <span className="col-1">{referral_status_reason || ""}</span>
            <span className="col-2">{referral_status_reason_context || ""}</span>
            <div className="col-1">
                <Button
                    className={"Btn Btn--otl-pri Btn-xs fs-12"}
                    disabled={patient.btnStatus}
                    onClick={() => unarchiveBtnHandler(referral_code)}>
                    Un-archive
                </Button>
            </div>
            <div className="col-1 d-flex d-flex justify-content-center">
                <div className="cursor ml-5">
                    <img
                        onClick={() =>
                            props.history.push("/app/referral/" + patient["referral_code"])
                        }
                        src={images("./common/view.png")}
                        alt="view icon"
                    />
                </div>
            </div>
        </div>
    );

    return (
        <Fragment>
            {mobileListItem}
            {desktopListItem}
        </Fragment>
    );
};

export default withRouter(ArchivedReferralListItem);
