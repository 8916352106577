// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import Profile from "../../../components/Provider/Profile";
import { Route, Switch } from "react-router-dom";
import { providerRoute } from "../../../config/roles";
import WorkingHours from "./WorkingHours";

const BASE_ROUTE = providerRoute + "/profile";
const hours = BASE_ROUTE + "/hours";

class ProviderProfile extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path={hours} render={(props) => <WorkingHours {...props} />} />
                    <Route path={BASE_ROUTE} render={(props) => <Profile {...props} />} />
                </Switch>
            </div>
        );
    }
}

export default ProviderProfile;
