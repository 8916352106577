import React from "react";

import { TextTypes, TextColors, ButtonTypes } from "../../constants";
import { Button } from "../../Button";
import { Text } from "../../Text";
import { Link } from "../../Link";

import "./styles.scss";

// Relates to: Self Signup

export const VerifyInsuranceNotConfigured = ({ title, step, insuranceName, onClick }) => {
    return (
        <div className="apti-VerifyInsurance">
            <Text type={TextTypes.heading1} color={TextColors.green}>
                {title}
            </Text>
            <Text className="details" style={{ color: TextColors.grey }}>
                Please contact <span className="bold">{insuranceName}</span> before your first
                appointment to confirm your coverage and cost of care.
            </Text>
            <Button className={ButtonTypes.primary} onClick={onClick}>
                Continue
            </Button>
            {/* <Text className="details" style={{color: TextColors.grey}}>
            If aptihealth’s services are not covered, you will be responsible for any fees associated with your care. <Link href={`/session-pricing`}>Learn about aptihealth’s rates.</Link>
            </Text> */}
        </div>
    );
};

VerifyInsuranceNotConfigured.defaultProps = {
    title: "Great news – you’re covered!",
    insuranceName: "HDEPO National HRA",
    onClick: () => {},
};
