// Copyright aptihealth, inc. 2019 All Rights Reserved
import { http, httpAuth } from "../utils/axios";
import { getGroupAuthType } from "../redux/actions/auth";
import { GROUP_AUTH_HEADER_NAME } from "../constants/axios";

const surveyRequests = {
    check_survey: async ({ options, data }) => {
        let conf = {
            method: "post",
            url: "/v1/survey/check_nps_survey",
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            data,
            ...options,
        };
        return httpAuth(conf);
    },
    submit_survey: async ({ options, data }) => {
        let conf = {
            method: "post",
            url: "/v2/survey/nps_survey",
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            data,
            ...options,
        };
        return httpAuth(conf);
    },
};

export default surveyRequests;
