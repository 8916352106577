import React from "react";
import { useSelector } from "react-redux";
import { AdvancedCareActivityForm } from "./AdvancedCareActivityForm";
import _ from "lodash";
import {
    ADVANCED_CARE_ACTIVITY_CONTENT_FIELDS_TO_TRANSFORM_TO_BOOLEAN,
    YES_NO_OPTIONS,
} from "./AdvancedCareActivityScheme";

export const AdvancedCareActivityViewOnlyForm = ({ note }) => {
    const transformNoteState = () => {
        const copyOfNote = _.cloneDeep(note);
        ADVANCED_CARE_ACTIVITY_CONTENT_FIELDS_TO_TRANSFORM_TO_BOOLEAN.forEach((item) => {
            const foundOption = YES_NO_OPTIONS.find(
                (option) => option.booleanValue === copyOfNote.content[item],
            );

            copyOfNote.content[item] = foundOption.value;
        });

        return copyOfNote;
    };

    return (
        <AdvancedCareActivityForm
            noteState={transformNoteState(note)}
            isEditing={false}
            patientId={note.patient_id}
        />
    );
};
