import React, { useState } from "react";
import { api } from "../../../../../APIRequests";
import { Portal } from "../../../../Common/Modal/Portal";

import { getPatientProfile } from "../../../../../redux/actions/auth";
import { connect } from "react-redux";
import { CompleteProfileAddress } from "./CompleteProfileAddress";
import { CompleteProfileEmergencyContact } from "./CompleteProfileEmergencyContact";
import { CompleteProfilePhysician } from "./CompleteProfilePhysician";
import CompleteProfileHeaderWidget from "./CompleteProfileHeaderWidget";

class CompleteProfileBodyComponent extends React.Component {
    render() {
        const {
            currentCompleteProfileStep,
            setCurrentCompleteProfileStep,
            updatedProfile,
            setUpdatedProfile,
            closeModal,
        } = this.props;

        const submitHandler = async (formData, actions) => {
            const mergedAccountData = { ...updatedProfile, ...formData };

            setUpdatedProfile(mergedAccountData);
            await api.patient.update_profile({ data: mergedAccountData });
            this.props.getPatientProfile();
        };

        const formProps = {
            setCurrentCompleteProfileStep,
            submitHandler,
            profile: updatedProfile,
            closeModal,
        };

        return (
            <div className={"workflow-container"}>
                {currentCompleteProfileStep === "Address" && (
                    <CompleteProfileAddress {...formProps} />
                )}
                {currentCompleteProfileStep === "Emergency Contact" && (
                    <CompleteProfileEmergencyContact {...formProps} />
                )}
                {currentCompleteProfileStep === "Primary Care Physician" && (
                    <CompleteProfilePhysician {...formProps} />
                )}
            </div>
        );
    }
}

const CompleteProfileBody = connect(null, { getPatientProfile })(CompleteProfileBodyComponent);

const CompleteProfileWidget = (props) => {
    const { profile } = props;
    const [currentCompleteProfileStep, setCurrentCompleteProfileStep] = useState("Address");
    const completeProfileSteps = ["Address", "Emergency Contact", "Primary Care Physician"];
    const [updatedProfile, setUpdatedProfile] = useState(profile);
    const [closeHandler, setCloseHandler] = useState(null);
    const closeModal = () => setCloseHandler(true);

    return (
        <Portal
            isBottomCloseActive={false}
            openButtonRender={<span>Complete aptihealth Profile</span>}
            headerRender={
                <CompleteProfileHeaderWidget
                    currentCompleteProfileStep={currentCompleteProfileStep}
                    completeProfileSteps={completeProfileSteps}
                />
            }
            triggerModalStateChange={closeHandler}>
            <CompleteProfileBody
                currentCompleteProfileStep={currentCompleteProfileStep}
                setCurrentCompleteProfileStep={setCurrentCompleteProfileStep}
                updatedProfile={updatedProfile}
                setUpdatedProfile={setUpdatedProfile}
                closeModal={closeModal}
            />
        </Portal>
    );
};

export default CompleteProfileWidget;
