import {
    ExpandingSelect,
    ExpandingTextArea,
    IndividualSymptomTrend,
} from "./DisplayWorkflowStepWithVideoClient";
import { BehavioralSymptomTrend } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotesSymptomTrend";
import React from "react";
import moment from "moment-timezone";
import CardSecondary from "../../../../UI/Card/CardSecondary";
import { BehavioralMentalStatus } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotesMentalStatus";
import { PrescribeLabFindings } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotesLabFindings";
import { GenericMultiselect } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotesGenericMultiselect";
import { RATING_OPTIONS_LABEL_MAP } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotes";
import { BehavioralGoalsRating } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotesBehavioralGoals";
import _ from "lodash";
import Select from "react-select";
import { joinDiagnosis } from "./WorkflowFunctions";

export const PrescribeSymptomTrends = (componentProps) => {
    let { state, noteEditHandler } = componentProps;
    let { progressNote } = state;

    return (
        <>
            <ExpandingTextArea
                label="How are you?"
                value={progressNote.content.symptom_trend_notes}
                onChange={(e) => {
                    noteEditHandler("symptom_trend_notes", e.target.value);
                }}
            />
            <IndividualSymptomTrend answer={progressNote.content.symptom_trends.overall} />
            {progressNote.content.last_behavioral_note && (
                <div className="m-3">
                    <BehavioralSymptomTrend
                        note={progressNote.content.last_behavioral_note}
                        editable={false}
                        patientAnswersEditable={false}
                        editHandler={noteEditHandler}
                        showProvider={true}
                    />
                </div>
            )}
        </>
    );
};

const PRESCRIPTION_MONITORING_LINKS = {
    "I-STOP": "https://commerce.health.state.ny.us/doh2/applinks/cspnp/singlePatientSearch",
};

export const RATING_OPTIONS_NONE_MUCH_LABEL_MAP = {
    0: "None",
    1: "Little",
    2: "Some",
    3: "Much",
    4: "Very Much",
};

const MEDICATION_IMPACT_SECTIONS = [
    {
        key: "benefits",
        labels: RATING_OPTIONS_NONE_MUCH_LABEL_MAP,
        title: "Medication Benefits",
    },
];

export const PrescribeMedicationImpact = (componentProps) => {
    let { state, noteEditHandler } = componentProps;
    let { progressNote, lastNotesOther } = state;

    let content = progressNote.content;
    let prescriptionMonitoring = content.prescription_monitoring;
    let medicationTrends = content.medication_trends;
    let medications = content.medications.filter((medication) => medication.med_changes !== "ADD");

    const clickIStop = () => {
        noteEditHandler("prescription_monitoring", {
            type: prescriptionMonitoring.type,
            last_viewed: moment.utc().format(),
            clicked: true,
        });
    };

    const handleMedicationChange = (e, index, key, labels) => {
        let editCopyClone = _.cloneDeep(medications);
        editCopyClone[index][key] = parseInt(e.target.value);
        editCopyClone[index][key + "_labels"] = labels;
        noteEditHandler("medications", editCopyClone);
    };

    let prescriptionMonitoringLastViewed;
    if (!prescriptionMonitoring.last_viewed) {
        prescriptionMonitoringLastViewed = "Not viewed yet ";
    } else {
        prescriptionMonitoringLastViewed = moment
            .utc(prescriptionMonitoring.last_viewed)
            .local()
            .format("MM/DD/YYYY");
    }

    return (
        <>
            <ExpandingTextArea
                label="Notes"
                value={content.medication_impact_notes}
                onChange={(e) => {
                    noteEditHandler("medication_impact_notes", e.target.value);
                }}
            />

            <CardSecondary className="px-3 py-2 m-3">
                <div className={"row"}>
                    <div className="col-6">
                        <p className="fw-bold float-left">{prescriptionMonitoring.type}</p>
                    </div>
                    <div className="col-6">
                        <a
                            onClick={clickIStop}
                            href={PRESCRIPTION_MONITORING_LINKS[prescriptionMonitoring.type]}
                            target="_blank"
                            className={"float-right"}
                            style={{
                                color: "#0000EE",
                                borderBottom: "1px",
                            }}
                            rel="noreferrer">
                            Open Link
                        </a>
                    </div>
                </div>
                <p>Last Viewed: {prescriptionMonitoringLastViewed}</p>
            </CardSecondary>

            {["frequency", "effectiveness", "problems", "stop"]
                .filter((key) => medicationTrends[key])
                .map((key) => (
                    <IndividualSymptomTrend answer={medicationTrends[key]} />
                ))}

            {medications.map((medication, index) => {
                return (
                    <CardSecondary className={"p-3 m-3"}>
                        <p className={"fw-bold my-2"}>Medication: {medication.medication_name}</p>
                        {MEDICATION_IMPACT_SECTIONS.map((section) => {
                            return (
                                <>
                                    <p className={"fw-bold my-2"}>{section.title}</p>
                                    <div className="col-12 d-flex justify-content-start">
                                        {Object.values(section.labels).map((optionLabel) => (
                                            <div className="w-25 fs-14 fw-bold text-center">
                                                <div className="m-auto" style={{ width: 50 }}>
                                                    {optionLabel}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-12 d-flex justify-content-start">
                                        <BehavioralGoalsRating
                                            optionLabelMap={section.labels}
                                            progress={medication}
                                            ratingKey={section.key}
                                            isEditing={true}
                                            onClick={(e) =>
                                                handleMedicationChange(
                                                    e,
                                                    index,
                                                    section.key,
                                                    section.labels,
                                                )
                                            }
                                        />
                                    </div>
                                </>
                            );
                        })}
                    </CardSecondary>
                );
            })}

            <GenericMultiselect
                note={progressNote}
                sectionKey="side_effects"
                labelClass="clr-sec"
                sectionLabel="Side Effect(s)"
                editable={true}
                className={"m-3"}
                editHandler={noteEditHandler}
                profileView={false}
                isCheckboxes={true}
            />
        </>
    );
};

export const PrescribeMedicalInformation = (componentProps) => {
    let { state, noteEditHandler } = componentProps;
    let { progressNote, lastNotesOther } = state;

    let content = progressNote.content;
    let lastBehavioralNote = progressNote.content.last_behavioral_note;

    let behavioralContent = <></>;
    if (lastBehavioralNote) {
        let providerInfo = lastBehavioralNote.signature + " " + lastBehavioralNote.session_date;
        behavioralContent = (
            <>
                <ExpandingSelect
                    label={`Changes/Updates  ${providerInfo}`}
                    labelClass="fw-bold clr-sec"
                    className="m-3 grey-container"
                    disabled
                    options={lastBehavioralNote.content.changes}
                />

                <ExpandingSelect
                    label={`Health Care Use  ${providerInfo}`}
                    labelClass="fw-bold clr-sec"
                    className="m-3 grey-container"
                    disabled
                    options={lastBehavioralNote.content.health_care_use}
                />
            </>
        );
    }

    return (
        <>
            <ExpandingTextArea
                label="Notes"
                value={content.medical_information_notes}
                onChange={(e) => {
                    noteEditHandler("medical_information_notes", e.target.value);
                }}
            />

            {behavioralContent}

            <ExpandingSelect
                label={"Medical Problems"}
                labelClass="fw-bold clr-sec"
                className="m-3 grey-container"
                disabled
                options={progressNote.content.medical_problems}
            />

            <PrescribeLabFindings
                note={progressNote}
                editable={true}
                editHandler={noteEditHandler}
            />
            <BehavioralMentalStatus
                note={progressNote}
                editable={true}
                editHandler={noteEditHandler}
                showTitle={true}
            />
        </>
    );
};

export const MED_CHANGES_OPTIONS = [
    { value: "INCREASE", label: "Increase" },
    { value: "DECREASE", label: "Decrease" },
    { value: "MAINTAIN", label: "Maintain" },
    { value: "DISCONTINUE", label: "Discontinue" },
];

export const PrescribeAssessmentPlan = (componentProps) => {
    let { state, noteEditHandler } = componentProps;
    let { progressNote, patientDiagnosis } = state;

    let content = progressNote.content;
    let diagnosisProgress = content.diagnosis_progress;
    let medications = content.medications;
    let allDiagnosis = joinDiagnosis(patientDiagnosis);

    const handleDiagnosisProgressChange = (e, index, key, isNumber = false) => {
        let editCopyClone = _.cloneDeep(diagnosisProgress);
        editCopyClone[index][key] = isNumber ? parseInt(e.target.value) : e.target.value;
        noteEditHandler("diagnosis_progress", editCopyClone);
    };

    const addMedication = () => {
        let editCopyClone = _.cloneDeep(medications);
        editCopyClone.push({
            date: moment.utc().format(),
            medication_name: "",
            dosage: "",
            amount: "",
            adherence: null,
            benefits: null,
            side_effect: null,
            med_changes: "ADD",
            diagnosis: [],
        });
        noteEditHandler("medications", editCopyClone);
    };

    const deleteMedication = (index) => {
        let editCopyClone = _.cloneDeep(medications);
        editCopyClone.splice(index, 1);
        noteEditHandler("medications", editCopyClone);
    };

    const handleMedicationChange = (e, index, key) => {
        let editCopyClone = _.cloneDeep(medications);
        editCopyClone[index][key] = e.target.value;
        noteEditHandler("medications", editCopyClone);
    };

    return (
        <>
            {diagnosisProgress.map((diagnosis, index) => {
                return (
                    <CardSecondary className="p-3 m-3 row">
                        <span className="col-12 fw-bold">{diagnosis.label}</span>
                        <div className="col-12 d-flex justify-content-start">
                            {Object.values(RATING_OPTIONS_LABEL_MAP).map((optionLabel) => (
                                <div className="w-25 fs-14 fw-bold text-center">
                                    <div className="m-auto" style={{ width: 50 }}>
                                        {optionLabel}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col-12 d-flex justify-content-start">
                            <BehavioralGoalsRating
                                optionLabelMap={RATING_OPTIONS_LABEL_MAP}
                                progress={diagnosis}
                                isEditing={true}
                                onClick={(e) =>
                                    handleDiagnosisProgressChange(e, index, "answer", true)
                                }
                            />
                        </div>

                        <span className={"fw-bold"}>Assessment</span>
                        <textarea
                            className="w-100"
                            value={diagnosis.assessment}
                            onChange={(e) => handleDiagnosisProgressChange(e, index, "assessment")}
                        />

                        <span className={"fw-bold"}>Plan</span>
                        <textarea
                            className="w-100"
                            value={diagnosis.plan}
                            onChange={(e) => handleDiagnosisProgressChange(e, index, "plan")}
                        />
                    </CardSecondary>
                );
            })}

            <div className="d-flex justify-content-center mt-3">
                <button className={"Btn Btn-sm Btn--pri"} onClick={addMedication}>
                    Add Medication
                </button>
            </div>

            {medications.map((medication, index) => {
                let isNew = medication.med_changes === "ADD";

                let medChangeOptions;
                let medChangeDefault;
                if (isNew) {
                    medChangeOptions = [{ value: "ADD", label: "Add" }];
                    medChangeDefault = medChangeOptions[0];
                } else {
                    medChangeOptions = MED_CHANGES_OPTIONS;
                    medChangeDefault = medChangeOptions.find(
                        (option) => option.value === medication.med_changes,
                    );
                }

                return (
                    <CardSecondary className={"p-3 m-3"}>
                        <p className={"fw-bold my-2"}>Medication</p>
                        <input
                            value={medication.medication_name}
                            className={"w-100"}
                            disabled={!isNew ? true : null}
                            onChange={(e) => handleMedicationChange(e, index, "medication_name")}
                        />
                        <p className={"fw-bold m-2"}>Dosage</p>
                        <input
                            value={medication.dosage}
                            className={"w-100"}
                            onChange={(e) => handleMedicationChange(e, index, "dosage")}
                        />

                        <div className={"d-flex my-2"}>
                            <div className={"fw-bold w-50"}>Medication Changes</div>
                            <div className={"w-50"}>
                                <Select
                                    className={"basic-multi-select w-100"}
                                    classNamePrefix="select"
                                    options={medChangeOptions}
                                    defaultValue={medChangeDefault}
                                    isDisabled={isNew ? true : null}
                                    onChange={(selectedOption) => {
                                        handleMedicationChange(
                                            { target: { value: selectedOption.value } },
                                            index,
                                            "med_changes",
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"d-flex my-2"}>
                            <div className={"fw-bold w-25"}>Diagnosis</div>
                            <div className={"w-75"}>
                                <Select
                                    className={"basic-multi-select w-100"}
                                    classNamePrefix="select"
                                    options={allDiagnosis}
                                    isMulti
                                    defaultValue={medication.diagnosis}
                                    onChange={(selectedOptions) => {
                                        handleMedicationChange(
                                            { target: { value: selectedOptions } },
                                            index,
                                            "diagnosis",
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        {isNew && (
                            <div className="d-flex justify-content-center">
                                <button
                                    className={"Btn Btn-sm Btn--err"}
                                    onClick={() => deleteMedication(index)}>
                                    Delete New Medication
                                </button>
                            </div>
                        )}
                    </CardSecondary>
                );
            })}

            <ExpandingSelect
                label={"What makes it hard to take your medication?"}
                labelClass="fw-bold clr-sec"
                className="m-3 grey-container"
                disabled
                options={progressNote.content.medication_difficulties}
            />

            <GenericMultiselect
                note={progressNote}
                sectionKey="adherence_interventions"
                labelClass="clr-sec"
                sectionLabel="Adherence Interventions"
                editable={true}
                className={"m-3"}
                editHandler={noteEditHandler}
                profileView={false}
                isCheckboxes={true}
            />
        </>
    );
};
