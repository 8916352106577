import { api } from "../../APIRequests";
import * as actionTypes from "./actionTypes";

const nextQuestion = (nextQuestion, isLast, completionPercentage) => {
    return {
        type: actionTypes.A5_NEXT_QUESTION,
        payload: { nextQuestion, isLast, completionPercentage },
    };
};

const previousQuestion = (payload) => {
    return {
        type: actionTypes.A5_PREVIOUS_QUESTION,
        payload,
    };
};

const selectOption = (options) => {
    return {
        type: actionTypes.A5_OPTION_SELECTED,
        payload: options,
    };
};

export const setA5Details = (payload) => {
    return {
        type: actionTypes.A5_UPDATE,
        payload: payload,
    };
};

export const getNextQuestion = (requestBody, headers) => (dispatch) => {
    const options = { params: { showLoader: true } };
    api.a5Screening
        .fetch_next_question({ data: requestBody, options, headers })
        .then((response) => {
            let data = response;
            let nextQ = data.question;
            let completionPercentage = data.percentage;
            let isLast = (nextQ && nextQ.is_last) || false;
            dispatch(nextQuestion(nextQ, isLast, completionPercentage));
        });
};

export const getQuestion = (requestBody, headers) => (dispatch) => {
    api.patient.get_next_question(requestBody).then((response) => {
        let data = response;
        let nextQ = data.question;
        let completionPercentage = data.percentage;
        let isLast = (nextQ && nextQ.is_last) || false;
        dispatch(setA5Details(response["question"]));
        dispatch(nextQuestion(nextQ, isLast, completionPercentage));
    });
};

export const submitQuestion = (requestBody, headers) => (dispatch) => {
    api.patient.submit_next_question(requestBody).then((response) => {
        let data = response;
        let nextQ = data.question;
        let completionPercentage = data.percentage ? Math.ceil(data.percentage) : data.percentage;
        let isLast = (nextQ && nextQ.is_last) || false;
        dispatch(nextQuestion(nextQ, isLast, completionPercentage));
    });
};

export const getPrevQuestion = (requestBody, headers) => (dispatch) => {
    api.patient.get_last_question(requestBody).then((response) => {
        let data = response;
        let nextQ = data.question;
        let completionPercentage = data.percentage;
        let isLast = (nextQ && nextQ.is_last) || false;
        dispatch(nextQuestion(nextQ, isLast, completionPercentage));
    });
};

export const updateA5Details = (requestBody, headers) => (dispatch) => {
    api.shared.update_a5_screening_details({ data: requestBody, headers }).then((response) => {
        dispatch(setA5Details(response));
    });
};

export const getPreviousQuestion = (requestBody, headers) => (dispatch) => {
    const options = { params: { showLoader: false } };
    api.a5Screening
        .fetch_previous_question({ data: requestBody, options, headers })
        .then((response) => {
            let data = response;
            let completionPercentage = data.percentage;
            let payload = {
                completionPercentage,
                currentQuestion: data.question,
                isLast: data.question.is_last,
            };
            dispatch(previousQuestion(payload));
        });
};

export const setSelectedOptions = (selectedOptions, selectedOptionFull) => (dispatch) => {
    dispatch(selectOption({ selectedOptions, selectedOptionFull }));
};
