// Copyright aptihealth, inc. 2019 All Rights Reserved
import { Form, getIn } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { api } from "../../../APIRequests";
import PracticeDetails from "../../../components/Admin/Practice/PracticeDetails";
import ProviderList from "../../../components/Admin/Practice/ProviderList";
import Button from "../../../components/UI/Button";
import CardSecondary from "../../../components/UI/Card/CardSecondary";
import FormikInput from "../../../components/UI/FormikInput";
import CardHeading from "../../../components/UI/Headings/CardHeading";
import FormikMobileInput from "../../../components/UI/formikMobileInput";
import { adminRoute } from "../../../config/roles";
import { setDynamicRouteConfiguration } from "../../../redux/actions/navbar";
import PatientsList from "../Providers/PatientsList";
import {
    PRACTICE_INITIAL_VALUES,
    PRACTICE_SCHEMA,
    PRACTICE_VALIDATION_SCHEMA,
} from "./schema/editPracticeSchema";

import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import Select from "react-select";
import { CustomForm } from "../../../component-library/CustomForm";
import InputError from "../../../components/UI/InputError";
import Label from "../../../components/UI/Label";
import { getAutomationDataAttr } from "../../../utils/automationUtils";

class Practice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            practiceId: this.props.match.params.practiceId,
            patientList: null,
            practiceDetails: null,
            intakeProviderOptions: [],
            existingIntakeProviders: [],
            defaultPCPOptions: [],
            practiceSchema: cloneDeep(PRACTICE_SCHEMA),
        };
    }

    async componentDidMount() {
        await this.getPractice();
        this.getProviderList();
        this.getPatientList();
        this.getIntakeProviders();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.practiceId !== this.props.match.params.practiceId) {
            this.setState({ practiceId: this.props.match.params.practiceId }, () =>
                this.componentDidMount(),
            );
        }
    }

    componentWillUnmount() {
        this.props.setDynamicRouteConfiguration({});
    }

    async getPractice() {
        let urlParams = { practiceId: this.state.practiceId };
        let cleanResponse = await api.shared.fetch_practice({ urlParams });
        cleanResponse.practice_specialization = this.extractLabel(cleanResponse);
        this.setState({
            practiceDetails: cleanResponse,
        });
    }

    getProviderList() {
        (async () => {
            let urlParams = { practiceId: this.state.practiceId };
            let data = { enrich_data: true };
            let providerList = await api.admin.fetch_practice_providers({ data, urlParams });

            const defaultPCPOptions = providerList
                .filter((provider) => provider["provider_type"] === "PCP")
                .map((provider) => {
                    return {
                        value: provider["username"],
                        display: provider["name"],
                    };
                });
            const practiceSchema = [...this.state.practiceSchema];
            practiceSchema.find(
                (element) => element.name === "default_pcp_id",
            ).elementConfig.options = defaultPCPOptions;
            this.setState({
                providerList: providerList,
                defaultPCPOptions: defaultPCPOptions,
                practiceSchema: practiceSchema,
            });
        })();
    }

    getPatientList() {
        (async () => {
            let urlParams = { practiceId: this.state.practiceId };
            let cleanResponse = await api.admin.fetch_patient_list({ urlParams });
            this.setState({
                patientList: cleanResponse.user,
            });
        })();
    }

    getIntakeProviders() {
        (async () => {
            const queryParams = { providerTypes: "BEHAVIORAL_INTAKE" };
            try {
                const response = await api.admin.fetch_providers({ queryParams });
                const intakeProviderOptions = response.map((provider) => {
                    return {
                        value: provider["username"],
                        label: provider["name"] + " - " + provider["practice"],
                    };
                });
                const existingIntakeProviders = intakeProviderOptions.filter(
                    (option) =>
                        this.state.practiceDetails.hasOwnProperty("intake_provider_ids") &&
                        this.state.practiceDetails["intake_provider_ids"].includes(option.value),
                );
                this.setState({ intakeProviderOptions, existingIntakeProviders });
            } catch (e) {
                console.log(e);
            }
        })();
    }

    editButtonHandler = () => {
        this.setState((ps) => {
            return { editPracticeDetails: !ps.editPracticeDetails };
        });
    };

    setHcpAndSpmiForPracticeObject = (formData) => {
        let newFormData = _.cloneDeep(formData);
        if ("practice_specialization" in newFormData) {
            const specialization = newFormData.practice_specialization;
            if (specialization.toLowerCase() === "hcp") {
                newFormData.hcp_searchable = true;
                newFormData.is_spmi = false;
            } else if (specialization.toLowerCase() === "spmi") {
                newFormData.hcp_searchable = false;
                newFormData.is_spmi = true;
            } else if (specialization.toLowerCase() === "none" || _.isNil(specialization)) {
                newFormData.hcp_searchable = false;
                newFormData.is_spmi = false;
            }

            delete newFormData.practice_specialization;
        }

        return newFormData;
    };

    extractLabel = (practiceDetails) => {
        if (practiceDetails.is_spmi) {
            return "SPMI";
        } else if (practiceDetails.hcp_searchable) {
            return "HCP";
        }

        return "None";
    };

    submitHandler = async (formData, actions) => {
        actions.setSubmitting(false);
        const data = { ...formData };
        data["intake_provider_ids"] = data["intake_provider_ids"]
            ? data["intake_provider_ids"].map((option) => (option.value ? option.value : option))
            : [];
        const queryParams = { practiceId: this.state.practiceId };
        const updatedData = this.setHcpAndSpmiForPracticeObject(data);

        api.admin
            .update_practice({ data: updatedData, queryParams })
            .then((response) => {
                const existingIntakeProviders = this.state.intakeProviderOptions.filter(
                    (option) =>
                        updatedData.hasOwnProperty("intake_provider_ids") &&
                        updatedData["intake_provider_ids"].includes(option.value),
                );

                // Make sure front-end and server are in sync on updatedData
                data.is_spmi = updatedData.is_spmi;
                data.hcp_searchable = updatedData.hcp_searchable;

                this.setState({ practiceDetails: data, existingIntakeProviders });
                this.editButtonHandler();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    renderForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 mb-3">
                    <div className="container px-0">
                        <div className="row">
                            {this.state.practiceSchema.map((formEl) => {
                                const testId = `EditPracticeInfo_${formEl.name}`;
                                if (formEl.elementType === "segment") {
                                    return (
                                        <h6 key={formEl.displayName} className="col-12">
                                            <b>{formEl.displayName}</b>
                                        </h6>
                                    );
                                } else if (formEl.name === "phone_number") {
                                    return (
                                        <div key={formEl.name} className={"col-12 col-lg-6"}>
                                            <FormikMobileInput
                                                value={
                                                    formikProps && formikProps.values.phone_number
                                                }
                                                onChange={async (val) => {
                                                    await formikProps.setFieldValue(
                                                        "phone_number",
                                                        val.value,
                                                    );
                                                    formikProps.setFieldTouched("phone_number");
                                                }}
                                                formEl={formEl}
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                                testId={testId}
                                            />
                                        </div>
                                    );
                                } else if (formEl.name === "intake_provider_ids") {
                                    const error = getIn(formikProps.errors, formEl.name);
                                    const touched = getIn(formikProps.touched, formEl.name);
                                    return (
                                        <>
                                            {this.state.practiceDetails.practice_type ===
                                                "Primary Care" && (
                                                <div
                                                    key={formEl.name}
                                                    className={"col-12 col-lg-6"}>
                                                    <Label>{formEl.elementConfig.label}</Label>
                                                    <Select
                                                        components={{
                                                            ClearIndicator: null,
                                                        }}
                                                        isMulti
                                                        options={this.state.intakeProviderOptions}
                                                        defaultValue={
                                                            this.state.existingIntakeProviders
                                                        }
                                                        className={"basic-multi-select"}
                                                        classNamePrefix="select"
                                                        onChange={async (selectedOptions) =>
                                                            await formikProps.setFieldValue(
                                                                formEl.name,
                                                                selectedOptions,
                                                            )
                                                        }
                                                    />
                                                    {error && touched ? (
                                                        <InputError classes={"custom-error"}>
                                                            {error}
                                                        </InputError>
                                                    ) : null}
                                                </div>
                                            )}
                                        </>
                                    );
                                } else {
                                    return (
                                        <div key={formEl.name} className={"col-12 col-lg-6"}>
                                            <FormikInput
                                                formEl={formEl}
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                                testId={testId}
                                            />
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>
                <div className="d-lg-flex d-block justify-content-center text-center justify-content-lg-end mb-2">
                    <Button
                        type="button"
                        onClick={this.editButtonHandler}
                        className="Btn Btn--otl-pri Btn-sm mr-lg-3 mb-3 mb-lg-0">
                        Cancel
                    </Button>
                    <Button type="submit" className="Btn Btn--pri Btn-sm">
                        Save
                    </Button>
                </div>
            </Form>
        );
    };

    render() {
        if (!this.state.practiceDetails || !this.state.providerList || !this.state.patientList) {
            return <div />;
        }

        return (
            <div className="container mt-lg-5 mt-3" {...getAutomationDataAttr("PracticePage")}>
                <div>
                    <div className="mx-auto fs-14 my-3 py-2">
                        <Link className="" to={adminRoute + "/practice"}>
                            <p className="d-inline ml-0">Practices</p>
                        </Link>
                        <span className="fs-14"> {">"} Practice Details</span>
                    </div>
                    <h3 className="fs-25 fw-bold mb-3 mb-lg-4">
                        {this.state.practiceDetails.name}
                    </h3>
                </div>
                {!this.state.editPracticeDetails && (
                    <PracticeDetails
                        practiceDetails={this.state.practiceDetails}
                        existingIntakeProviders={this.state.existingIntakeProviders}
                        providers={this.state.defaultPCPOptions}
                        onclick={this.editButtonHandler}
                    />
                )}
                {this.state.editPracticeDetails && (
                    <CardSecondary className="mx-auto container Practice__Details pb-2">
                        <div className="d-flex justify-content-between py-4">
                            <CardHeading
                                className="fs-18 m-0 px-3 px-lg-4"
                                text="Practice Details"
                            />
                        </div>
                        <CustomForm
                            initialValues={{
                                ...PRACTICE_INITIAL_VALUES,
                                ...this.state.practiceDetails,
                            }}
                            validationSchema={PRACTICE_VALIDATION_SCHEMA}
                            onSubmit={this.submitHandler}
                            render={this.renderForm}
                        />
                    </CardSecondary>
                )}

                <ProviderList {...this.props} providerList={this.state.providerList} />
                <PatientsList
                    {...this.props}
                    group_auth_type="admin"
                    patientList={this.state.patientList}
                    practiceId={this.props.match.params.practiceId}
                />
            </div>
        );
    }
}

export default connect(null, { setDynamicRouteConfiguration })(Practice);
