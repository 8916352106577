import React, {Component} from "react";
import {api} from "../APIRequests";


export class ErrorBoundaries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    async componentDidCatch(error, errorInfo) {
        console.log(`caught error: ${error}`);
        try {
            this.setState({ hasError: true });

            let report = {
                type: "frontend_error",
                description: `${error.name} ${error.message}`,
                context: {
                    error_name: error.name,
                    error_message: error.message,
                    original_error_stack: error.stack
                        .replaceAll("http://", "")
                        .replaceAll("https://", "")
                        .replaceAll("localhost:3000", ""),
                    error_component_stack: errorInfo.componentStack,
                },
            };
            await api.shared.post_exception_report({ report });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        if (this.state?.hasError) {
            return (
                <div className={"d-flex justify-content-center text-center"}>
                    <div className={"my-5 mx-2"} style={{ maxWidth: 450 }}>
                        <p className={"fw-bold fs-26 my-3"}>The website experienced a problem.</p>
                        <p className={"fs-18"}>
                            Please refresh the page. If the problem persists, please try again
                            later. We apologize for the inconvenience.
                        </p>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}
