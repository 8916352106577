import React from "react";
import { ActiveGoal } from "../../CarePlan/CareGoalsCard/CareGoalsCard";
import { Card, CustomForm, Text, TextTypes } from "../../../../../component-library";
import {
    INTERVENTION_AND_PLAN_SCHEMA,
    getInterventionAndPlanFormErrors,
} from "../../../../Common/GuidedVideoCall/Steps/ProviderWorkflow/InterventionAndPlan/InterventionAndPlanV2/InterventionAndPlanV2FormSchema";
import { InterventionAndPlanNoteField } from "./InterventionAndPlanNoteField";
import "./styles.scss";
import "../../../../../component-library/Link/styles.scss";
import _ from "lodash";
import { getAutomationDataAttr } from "../../../../../utils/automationUtils";

// NOTE: This component is used in a number of spots: guided session and viewing notes from the
// patients chart
export const MemberProfileGoalsAndObjectiveNoteDisplayV2 = ({
    editable,
    goalAndObjectiveProgress,
    editHandler,
    profileView,
    draftView,
}) => {
    const renderForm = (formikProps) => {
        // can only be true when the fields are editable
        const isEditingInternal = formikProps.values.isEditingInternal && editable;

        return (
            <>
                <div className="MemberProfileGoalsAndObjectiveNoteDisplayV2--header">
                    <Text
                        className="MemberProfileGoalsAndObjectiveNoteDisplayV2--title"
                        type={TextTypes.subtitle}>
                        Care Goals & Objectives
                    </Text>
                    {editable && (
                        <button
                            onClick={async () => {
                                await formikProps.setFieldValue(
                                    "isEditingInternal",
                                    !isEditingInternal,
                                );
                            }}
                            className="CarePlanIconButton--icon_button">
                            <p className="apti-LinkV2">{isEditingInternal ? "Save" : "Edit"}</p>
                        </button>
                    )}
                </div>
                {Object.keys(goalAndObjectiveProgress).map((goalNumber, index) => {
                    const goal = goalAndObjectiveProgress[goalNumber];
                    return (
                        <>
                            <div className="MemberProfileGoalsAndObjectiveNoteDisplayV2--goal_container">
                                <ActiveGoal
                                    className="MemberProfileGoalsAndObjectiveNoteDisplayV2--goal_view"
                                    key={goal.goal_id}
                                    goal={goal}
                                    hideObjectiveActions
                                    fieldsToHide={["objective_start_timestamp"]}
                                />
                                <div className="MemberProfileGoalsAndObjectiveNoteDisplayV2--form_container">
                                    <Text type={TextTypes.label}>Interventions & Plans</Text>
                                    <InterventionAndPlanNoteField
                                        editHandler={editHandler}
                                        editable={editable}
                                        goalNumber={goalNumber}
                                        formikProps={formikProps}
                                        isEditing={isEditingInternal}
                                    />
                                </div>
                            </div>
                            {/* hide hr for last element in the list */}
                            {index !== Object.keys(goalAndObjectiveProgress).length - 1 && (
                                <div className="InterventionAndPlanV2--hr" />
                            )}
                        </>
                    );
                })}
            </>
        );
    };

    const getCardClassName = () => {
        if (profileView) {
            return "MemberProfileGoalsAndObjectiveNoteDisplayV2--profile_view_container";
        } else if (draftView) {
            return "MemberProfileGoalsAndObjectiveNoteDisplayV2--draft_view_container";
        }

        return "MemberProfileGoalsAndObjectiveNoteDisplayV2--guided_session_container";
    };

    return (
        <Card className={getCardClassName()} {...getAutomationDataAttr('MemberProfileGoalsAndObjectiveNoteDisplayV2_goalsDisplay')}>
            <CustomForm
                initialValues={{ ...goalAndObjectiveProgress, isEditingInternal: false }}
                // NOTE: there is a validateOnMount parameter but this did not behave as expected
                // so we define an initial set of errors instead
                initialErrors={getInterventionAndPlanFormErrors(goalAndObjectiveProgress)}
                validationSchema={INTERVENTION_AND_PLAN_SCHEMA}
                validateOnChange={true}
                validateOnBlur={true}
                render={renderForm}
            />
        </Card>
    );
};
