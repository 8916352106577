// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component, Fragment } from "react";
import {
    List,
    ListBody,
    ListHeader,
    ListItem,
    ListTitleBar,
    TitleBarItem,
} from "../../UI/ListView";
import ListLoader from "../Loaders/ListLoader";
import { sortUsersByDateLastNameFirstName } from "../../../utils/sortingUtil";
import { Button, ButtonTypes } from "../../../component-library";
import SearchBar from "../../UI/SearchBar";
import { isAuthorized, isUserAdmin } from "../../../redux/actions/auth";
import moment from "moment-timezone";
import { filterPractices, toTitleCase } from "../../../utils/filters";
import Input from "../../UI/Input";
import Badge from "../../UI/Badge";
import images from "../../../utils/images";
import { Select } from "../../UI/StyledInput";
import ReactSelect from "react-select";
import { api } from "../../../APIRequests";
import "./styles.scss";

class A5ScreeningList extends Component {
    constructor(props) {
        super(props);
        let pageSize = 20;
        this.state = {
            screeningList: null,
            search: "",
            pageSize: pageSize,
            screeningsToShow: pageSize,
            screeningMap: null,
            isAllChecked: false,
            statusFilter: "All",
            pcpPracticeId: "",
            pcpPracticeOptions: null,
        };
    }

    componentDidMount = async () => {
        if (isUserAdmin()) {
            this.setState({ pcpPracticeOptions: await getPracticeList() });
        } else {
            this.getScreeningList();
        }
    };

    getScreeningList = async (pcpPracticeId) => {
        try {
            const queryParams = {};
            if (pcpPracticeId && pcpPracticeId !== "all_practices") {
                queryParams["practice_id"] = pcpPracticeId;
            }
            const response = await api.shared.fetch_a5_screening_list({ queryParams });
            const screeningMap = response.reduce((obj, item) => {
                obj[item["id"]] = { isChecked: false };
                return obj;
            }, {});
            this.setState({
                screeningList: response,
                screeningMap: screeningMap,
            });
        } catch (e) {
            console.log(e);
        }
    };

    updateScreeningButton = (item, button, buttonText) => {
        const screeningList = [...this.state.screeningList];
        const screening = screeningList.find((screening) => screening["id"] === item["id"]);
        if (screening) {
            screening[button + "Text"] = buttonText;
            screening[button + "Disabled"] = "disabled";
        }
        this.setState({ screeningList: screeningList });
    };

    resendScreening = async (item) => {
        try {
            const data = { ...item };
            await api.shared.resend_a5_screening({ data });
            this.updateScreeningButton(item, "resendBtn", "Sent");
        } catch (e) {
            console.log(e);
        }
    };

    reScreen = async (item) => {
        try {
            const data = { ...item };
            delete data["id"];
            delete data["answers"];
            delete data["status"];
            delete data["report_data"];
            await api.shared.create_a5_screening_details({ data });
            await this.getScreeningList();
            this.updateScreeningButton(item, "reScreenBtn", "Sent");
        } catch (e) {
            console.log(e);
        }
    };

    downloadA5Report = (item) => {
        window.open(item["report_data"]["url"]);
    };

    viewScreeningData = (item) => {
        this.props.history.push(
            `/app/a5_screenings/${item["id"]}?pcpPracticeId=${this.state.pcpPracticeId}`,
        );
    };

    archiveScreening = async (item) => {
        try {
            const data = { id: item["id"], status: "archived" };
            await api.shared.update_a5_screening_details({ data });
            await this.getScreeningList(this.state.pcpPracticeId);
        } catch (e) {
            console.log(e);
        }
    };

    referToA30 = (item) => {
        this.props.history.push(`/app/patient/add?screeningId=${item["id"]}`);
    };

    checkBoxHandler = (item) => {
        let screeningMap = { ...this.state.screeningMap };
        screeningMap[item["id"]].isChecked = !screeningMap[item["id"]].isChecked;
        this.setState({ screeningMap: screeningMap });
    };

    checkBoxAllBoxesHandler = () => {
        const screeningMap = {};
        const isAllChecked = !this.state.isAllChecked;
        for (let id of Object.keys({ ...this.state.screeningMap })) {
            screeningMap[id] = { isChecked: isAllChecked };
        }
        this.setState({ screeningMap: screeningMap, isAllChecked: isAllChecked });
    };

    getFilteredScreenings = (list) => {
        return list.filter((screening) => {
            let firstName = screening.first_name.toLowerCase();
            let lastName = screening.last_name.toLowerCase();
            let primaryFilter = this.state.search.replace(
                new RegExp("[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\-]", "g"),
                "\\$&",
            );
            const filteredScreeningFN = firstName.match(
                new RegExp("^" + primaryFilter.toLowerCase()),
            );
            const filteredScreeningLN = lastName.match(
                new RegExp("^" + primaryFilter.toLowerCase()),
            );
            return filteredScreeningFN || filteredScreeningLN;
        });
    };

    showMore = () =>
        this.setState({ screeningsToShow: this.state.screeningsToShow + this.state.pageSize });

    getShowMoreButton = () => {
        return (
            <div className="d-flex align-items-center justify-content-end py-4">
                <Button
                    className="Btn Btn--pri Btn-sm ml-4 text-center text-white"
                    onClick={this.showMore}>
                    Show More
                </Button>
            </div>
        );
    };

    transformStatus = (status) => {
        switch (status) {
            case "sent":
                return "SENT";
            case "in_progress":
                return "IN-PROGRESS";
            case "completed":
                return "COMPLETED";
            case "referred":
                return "REFERRED";
            case "registered":
                return "REGISTERED";
            case "archived":
                return "ARCHIVED";
            default: {
                return "";
            }
        }
    };

    getActionButton = (item) => {
        const style = { marginLeft: 70 };
        switch (item.status) {
            case "sent":
                return (
                    <Button
                        style={style}
                        disabled={item["resendBtnDisabled"] ? item["resendBtnDisabled"] : null}
                        onClick={() => this.resendScreening(item)}
                        className={"Btn Btn-xs fs-12 Btn--pri"}
                        type={"button"}>
                        {item["resendBtnText"] ? item["resendBtnText"] : "Resend"}
                    </Button>
                );
            case "completed":
                return (
                    <Button
                        style={style}
                        onClick={() => this.referToA30(item)}
                        className={"Btn Btn-xs fs-12 Btn--sec"}
                        disabled={
                            isUserAdmin() ||
                            !item.hasOwnProperty("report_data") ||
                            !item.report_data.hasOwnProperty("file_location")
                                ? "disabled"
                                : null
                        }
                        type={"button"}>
                        Refer
                    </Button>
                );
            case "archived":
                return (
                    <Button
                        style={style}
                        disabled={item["reScreenBtnDisabled"] ? item["reScreenBtnDisabled"] : null}
                        onClick={() => this.reScreen(item)}
                        className={"Btn Btn-xs fs-12 Btn--pri"}
                        type={"button"}>
                        {item["reScreenBtnText"] ? item["reScreenBtnText"] : "Re-Screen"}
                    </Button>
                );
            default:
                return "";
        }
    };

    getSecondaryActionButton = (item) => {
        const classes = "Btn Btn-xs fs-12 Btn--otl-pri";
        const style = { marginLeft: 70 };
        if (item.status === "completed") {
            return (
                <Button
                    style={style}
                    onClick={() => this.archiveScreening(item)}
                    className={classes}
                    type={"button"}>
                    Archive
                </Button>
            );
        } else {
            return "";
        }
    };

    getDownloadButton = (item) => {
        if (item.hasOwnProperty("report_data") && item["report_data"].hasOwnProperty("url")) {
            return (
                <div className="cursor ml-5">
                    <img
                        onClick={() => this.downloadA5Report(item)}
                        src={images("./common/download.png")}
                        alt="download"
                    />
                </div>
            );
        } else {
            return "";
        }
    };

    getScreeningCheckBox = (item) => {
        return (
            <Input
                checked={this.state.screeningMap[item["id"]]["isChecked"]}
                type="checkbox"
                name="screening_checkbox"
                className="AddPatientInput mr-2"
                disabled={item["status"] === "completed" ? "disabled" : null}
                onClick={() => this.checkBoxHandler(item)}
            />
        );
    };

    getRenderedListItem = (item) => {
        const patientName = item.first_name + " " + item.last_name;
        const dob = moment(item.dob, "MMDDYYYY").format("MM/DD/YYYY");
        const createDate = moment(parseFloat(item.created_date) * 1000).format("MM/DD/YYYY");
        let desktopListItem = (
            <div className="row no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between">
                <span className="col-2">{toTitleCase(patientName)}</span>
                <span className="col-1">{dob}</span>
                <span className="col-1">{item.mobile}</span>
                <span className="col-1">{createDate}</span>
                <div className="col-1">
                    <Badge type={"black"} text={this.transformStatus(item.status)} />
                </div>
                <div className="col-1 d-flex d-flex justify-content-center">
                    <div className="cursor ml-5">
                        <img
                            onClick={() => this.viewScreeningData(item)}
                            src={images("./common/view.png")}
                            alt="view icon"
                        />
                    </div>
                </div>
                <div className="col-1 d-flex d-flex justify-content-center">
                    {this.getDownloadButton(item)}
                </div>
                {/*<div className="col-1 d-flex justify-content-center">*/}
                {/*    {this.getScreeningCheckBox(item)}*/}
                {/*</div>*/}
                <div className="col-1 d-flex d-flex justify-content-center">
                    {this.getActionButton(item)}
                </div>
                <div className="col-1 d-flex d-flex justify-content-center">
                    {this.getSecondaryActionButton(item)}
                </div>
            </div>
        );

        let mobileListItem = (
            <div className="d-flex d-lg-none justify-content-between">
                <div
                    className="d-flex fs-14 flex-column"
                    style={{ width: "65%", wordWrap: "break-word" }}>
                    <span className="fs-16 fw-bold">{toTitleCase(patientName)}</span>
                    <span>{dob}</span>
                    <span>{item.mobile}</span>
                </div>
                <div className="d-flex fs-14 flex-column">
                    <div>
                        <Badge type={"black"} text={this.transformStatus(item.status)} />
                    </div>
                    <div>
                        <span>{createDate}</span>
                    </div>
                </div>
            </div>
        );

        return (
            <Fragment>
                {mobileListItem}
                {desktopListItem}
            </Fragment>
        );
    };

    getListItems = (filteredItems) => {
        return (
            <div>
                <Fragment>
                    {filteredItems &&
                        filteredItems.slice(0, this.state.screeningsToShow).map((screening, i) => {
                            return (
                                <ListItem
                                    key={screening.id}
                                    clickOnMobile
                                    clickHandler={() => this.viewScreeningData(screening)}>
                                    {this.getRenderedListItem(screening, i)}
                                </ListItem>
                            );
                        })}
                </Fragment>
                {filteredItems &&
                    this.state.screeningsToShow <= filteredItems.length &&
                    this.getShowMoreButton()}
            </div>
        );
    };

    updateSearch = (e) => {
        this.setState({ search: e.target.value });
    };

    statusFilterChangeHandler = (e) => {
        this.setState({ statusFilter: e.target.value });
    };

    pcpPracticeIdChangeHandler = async (e) => {
        if (e === null) {
            return;
        }
        const pcpPracticeId = e.value;
        this.setState({ pcpPracticeId: pcpPracticeId });
        await this.getScreeningList(pcpPracticeId);
    };

    isAllowedToAddNew = () => {
        return isAuthorized("provider:pcp") || isUserAdmin();
    };

    addButtonClickHandler = () => {
        this.props.history.push(`/app/a5_screenings/add?pcpPracticeId=${this.state.pcpPracticeId}`);
    };

    renderPrimaryControls = () => {
        return (
            <div className="d-flex render-controls">
                <SearchBar
                    className="mr-lg-2"
                    value={this.state.search}
                    onchange={this.updateSearch}
                />
                {this.isAllowedToAddNew() && (
                    <Button
                        buttonType={ButtonTypes.primaryV2}
                        onClick={this.addButtonClickHandler}
                        disabled={
                            isUserAdmin() && this.state.pcpPracticeId === "all_practices"
                                ? "disabled"
                                : null
                        }>
                        New Screening
                    </Button>
                )}
            </div>
        );
    };

    renderSecondaryControls = () => {
        return (
            <div
                className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-end"
                style={{
                    padding: 4,
                    borderBottom: "1px solid #AEAEAE",
                    borderTop: "1px solid #AEAEAE",
                }}>
                {/*<Button onClick={this.bulkArchive}*/}
                {/*        className="Btn d-none d-lg-block Btn--pri Btn-sm ml-4 fs-16 text-center text-white">*/}
                {/*    Archive</Button>*/}
                {isUserAdmin() && this.state.pcpPracticeOptions && (
                    <div>
                        <span className={"fs-14 mr-2"}>Filter by Practice: </span>
                        <div style={{ width: 280 }} className={"mr-lg-4"}>
                            <ReactSelect
                                isSearchable={true}
                                isClearable={true}
                                className={"react-select-box"}
                                classNamePrefix={"react-select-box"}
                                name="pcp_practice_id"
                                placeholder={"PCP Practice"}
                                onChange={this.pcpPracticeIdChangeHandler}
                                options={this.state.pcpPracticeOptions}
                            />
                        </div>
                    </div>
                )}
                <div>
                    <span className={"fs-14 mr-2"}>Sort Screenings by: </span>
                    <div style={{ width: 280 }} className={"mr-lg-4"}>
                        <Select
                            name="status"
                            changeHandler={this.statusFilterChangeHandler}
                            value={this.state.statusFilter}
                            options={[
                                { value: "All", display: "All" },
                                { value: "sent", display: "Sent" },
                                { value: "in_progress", display: "In-Progress" },
                                { value: "completed", display: "Completed" },
                                { value: "referred", display: "Referred" },
                                { value: "archived", display: "Archived" },
                            ]}
                        />
                    </div>
                </div>
            </div>
        );
    };

    getCheckAllCheckbox = () => {
        return (
            <Input
                checked={this.state.isAllChecked}
                type="checkbox"
                name="screening_checkbox"
                className="AddPatientInput mr-2"
                onClick={this.checkBoxAllBoxesHandler}
            />
        );
    };

    noOptionSelected = () => {
        return (
            <div style={{ width: "50%", margin: "20px auto", textAlign: "center" }}>
                Please select a Practice in the drop-down above to view Screenings
            </div>
        );
    };

    render() {
        let filteredScreeningList;
        if (this.state.screeningList) {
            filteredScreeningList = this.getFilteredScreenings(this.state.screeningList)
                .filter((screening) =>
                    this.state.statusFilter !== "All"
                        ? screening["status"] === this.state.statusFilter
                        : true,
                )
                .sort((s1, s2) => sortUsersByDateLastNameFirstName(s1, s2, "created_date"));
        }

        return (
            <div>
                <ListBody styles={{ maxWidth: 1300 }}>
                    <ListHeader title={"Screening"} render={this.renderPrimaryControls} />
                    {this.renderSecondaryControls()}
                    <ListTitleBar>
                        <TitleBarItem classes="col-2" text="Full Name" />
                        <TitleBarItem classes="col-1" text="Date of Birth" />
                        <TitleBarItem classes="col-1" text="Mobile Number" />
                        <TitleBarItem classes="col-1" text="Referred Date" />
                        <TitleBarItem classes="col-1" text="Status" />
                        <TitleBarItem classes="col-1" text="" />
                        <TitleBarItem classes="col-1" text="" />
                        <TitleBarItem classes="col-1" text="" />
                        <TitleBarItem classes="col-1" text="" />
                        {/*<TitleBarItem classes="col-1 d-flex justify-content-center" text={this.getCheckAllCheckbox()} />*/}
                    </ListTitleBar>
                    <List>
                        {this.state.screeningList
                            ? this.getListItems(filteredScreeningList)
                            : this.noOptionSelected()}
                    </List>
                </ListBody>
            </div>
        );
    }
}

export default A5ScreeningList;

export const getPracticeList = async (
    practiceType = "Primary Care",
    addAllOption = false,
    optionAttribute = "label",
) => {
    try {
        const options = { params: { showLoader: false } };
        const practices = await api.admin.fetch_all_practices({ options });
        return filterPractices(
            practices,
            (practice) => practice["practice_type"] === practiceType,
            addAllOption,
            optionAttribute,
        );
    } catch (e) {
        console.log(e);
    }
};
