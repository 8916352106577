// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component, Fragment } from "react";
import {
    List,
    ListBody,
    ListHeader,
    ListItem,
    ListTitleBar,
    TitleBarItem,
} from "../../../components/UI/ListView";
import SearchBar from "../../../components/UI/SearchBar";
import ListLoader from "../../../components/Common/Loaders/ListLoader";
import images from "../../../utils/images";
import { adminRoute } from "../../../config/roles";
import { api } from "../../../APIRequests";
import Badge from "../../../components/UI/Badge";

class CustomerSuccessManagerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerSuccessManagerList: null,
            search: "",
        };
    }

    componentDidMount = async () => {
        let options = { params: { showLoader: false } };
        let cleanResponse = await api.admin.fetch_all_csm({ options });
        this.setState({
            customerSuccessManagerList: cleanResponse,
        });
    };

    getListChildren = (csm) => {
        return (
            <Fragment>
                {/* for mobile devices */}
                <div className="d-flex d-lg-none justify-content-between">
                    <div
                        className="d-flex fs-14 flex-column"
                        style={{ width: "65%", wordWrap: "break-word" }}>
                        <span className="fs-16 fw-bold">{csm.name}</span>
                        <span className="fs-16 fw-bold">{csm.mobile}</span>
                        <span>{csm.email}</span>
                        <Badge type={"success"} text={"Active"} />
                    </div>
                </div>
                {/* for desktop */}
                <div className="row no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between">
                    <span className="col-3">{csm.name}</span>
                    <span className="col-4">{csm.email}</span>
                    <span className="col-3">{csm.mobile}</span>
                    <div className="col-2">
                        <Badge type={"success"} text={"Active"} />
                    </div>
                </div>
            </Fragment>
        );
    };

    updateSearch = (e) => {
        this.setState({ search: e.target.value });
    };

    renderControls = () => {
        return (
            <div className="d-flex">
                <SearchBar
                    className="mr-lg-2"
                    value={this.state.search}
                    onchange={this.updateSearch}
                />
            </div>
        );
    };

    render() {
        let filteredCustomerSuccessManagerList;
        if (this.state.customerSuccessManagerList) {
            filteredCustomerSuccessManagerList = this.state.customerSuccessManagerList
                .filter((csm) => {
                    return csm.name
                        .toLowerCase()
                        .match(new RegExp("^" + this.state.search.toLowerCase()));
                })
                .sort((csmA, csmB) => (csmA.name > csmB.name ? 1 : -1));
        }

        let listItems = (
            <Fragment>
                {filteredCustomerSuccessManagerList &&
                    filteredCustomerSuccessManagerList.map((csm) => {
                        return (
                            <ListItem
                                key={csm.username}
                                clickOnMobile
                                clickHandler={() => {
                                    this.props.history.push(
                                        adminRoute + "/admin/profile/" + csm.username,
                                    );
                                }}>
                                {this.getListChildren(csm)}
                            </ListItem>
                        );
                    })}
            </Fragment>
        );

        return (
            <div>
                <ListBody>
                    <ListHeader title={"Customer Success"} render={this.renderControls} />
                    <ListTitleBar>
                        <TitleBarItem classes="col-3" text="Name" />
                        <TitleBarItem classes="col-4" text="Email Address" />
                        <TitleBarItem classes="col-3" text="Phone Number" />
                        <TitleBarItem classes="col-2" text="Status" />
                    </ListTitleBar>
                    <List>
                        {this.state.customerSuccessManagerList ? listItems : <ListLoader />}
                    </List>
                </ListBody>
            </div>
        );
    }
}

export default CustomerSuccessManagerList;
