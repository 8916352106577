import React from "react";
import Button from "../../../UI/Button";
import WorkflowHeader from "./WorkflowHeader";

const ThankYouIntakeSchedule = ({ props }) => {
    return (
        <>
            <WorkflowHeader header={"Your consultation has been scheduled"} showImage={false} />
            <div className="workflow-container-sm d-flex justify-content-between">
                <div className={"p-lg-5 p-3"}>
                    <div className="row text-center">
                        You will receive an email shortly with details regarding what to expect for
                        your first video consultation. We look forward to talking to you soon!
                    </div>
                    <br />
                    <div className="text-center">
                        Please hand this device back to the staff member.
                    </div>
                    <br />
                    <div className="row">
                        <div className={"col-12 text-center"}>
                            <Button
                                type={"button"}
                                className={"Btn Btn--otl-gry Btn--sm-2 workflow-btn"}
                                onClick={() => {
                                    props.setSharedWorkflowData({});
                                    props.history.push("/app/workflow");
                                }}>
                                Back to Home Screen
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ThankYouIntakeSchedule;
