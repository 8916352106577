/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const CameraIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Camera"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.5241 16L27 19.7299V12.2701L22.5241 16ZM21 10C21 9.44772 20.5523 9 20 9H7C6.44772 9 6 9.44772 6 10V22C6 22.5523 6.44772 23 7 23H20C20.5523 23 21 22.5523 21 22V10ZM23 10C23 8.34315 21.6569 7 20 7H7C5.34315 7 4 8.34315 4 10V22C4 23.6569 5.34315 25 7 25H20C21.6569 25 23 23.6569 23 22V19L27.3598 22.6332C28.0111 23.1759 29 22.7128 29 21.865V10.135C29 9.2872 28.0111 8.82404 27.3598 9.36681L23 13V10Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Camera"
                {...props}>
                <path d="M7 7C5.34315 7 4 8.34315 4 10V22C4 23.6569 5.34315 25 7 25H20C21.6569 25 23 23.6569 23 22V19L27.3598 22.6332C28.0111 23.1759 29 22.7128 29 21.865V10.135C29 9.2872 28.0111 8.82404 27.3598 9.36681L23 13V10C23 8.34315 21.6569 7 20 7H7Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Camera"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM11 15.5C11 14.2574 12.0074 13.25 13.25 13.25H23C24.2426 13.25 25.25 14.2574 25.25 15.5V17.75L28.5199 15.0251C29.0084 14.618 29.75 14.9654 29.75 15.6013V24.3987C29.75 25.0346 29.0084 25.382 28.5199 24.9749L25.25 22.25V24.5C25.25 25.7426 24.2426 26.75 23 26.75H13.25C12.0074 26.75 11 25.7426 11 24.5V15.5Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

CameraIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

CameraIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
