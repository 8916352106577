// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import {
    HeaderAttribute,
    HeaderTitle,
    ReportsMainCollapse,
    SidebarList,
    SidebarListContainer,
    SidebarListItem,
    SquashedValues,
} from "../../../components/Common/ReportsView";
import Button from "../../../components/UI/Button";
import { getQueryParams, toTitleCase } from "../../../utils/filters";
import { api } from "../../../APIRequests";
import moment from "moment-timezone";
import { connect } from "react-redux";
import "./Styles.scss";
import { setDynamicRouteConfiguration } from "../../../redux/actions/navbar";
import images from "../../../utils/images";
import "../../../components/UI/BackLink/styles.scss";
import XBARReportCommentBox from "../../../components/Common/X-BARReportView/X-BARReportCommentBox";
import { viewScreeningAssociatedFile } from "../../../utils/fileUtil";

class A5Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: this.props.match.params.patientId,
            reportDate: "",
            reportUrl: "",
            screeningId: null,
            patientName: "",
            patientReport: null,
            screening: null,
            showCommentBox: false,
            ReportComment: null,
            commentError: null,
        };
    }
    componentDidMount = async () => {
        const dynamicRouteConfig = {};
        dynamicRouteConfig["menuSection"] = {
            icon: "./icons/back-arrow.svg",
            link: "/app/reports",
        };
        dynamicRouteConfig["logoSection"] = { title: "Report Details" };

        this.props.setDynamicRouteConfiguration(dynamicRouteConfig);

        let urlParams = { patientId: this.state.patientId };
        const options = { params: { showLoader: false } };
        const patientResponse = await api.shared.fetch_patient_details({ urlParams, options });
        const queryParams = { screening_id: patientResponse.user["a5_screening_id"] };
        const screeningResponse = await api.shared.fetch_a5_screening_details({ queryParams });
        this.setState({
            screeningId: patientResponse.user["a5_screening_id"],
            patientReport: screeningResponse["report_data"],
            screening: screeningResponse,
            reportDate: moment
                .unix(parseInt(patientResponse.user["reports"]["A5"]["timestamp"]))
                .format("MM/DD/YYYY HH:mm"),
            reportUrl: patientResponse.user["reports"]["A5"]["report_key"],
            patientName:
                patientResponse.user["first_name"] + " " + patientResponse.user["last_name"],
        });
    };

    componentWillUnmount() {
        this.props.setDynamicRouteConfiguration({});
    }

    HeaderContent = () => {
        let date = "",
            time = "";
        if (this.state.reportDate) {
            let dateParts = this.state.reportDate.split(" ");
            date = dateParts[0];
            time = moment.utc(dateParts[1], "HH:mm").local().format("hh:mm a");
        }
        return (
            <div className="row no-gutters pb-3" style={{ "border-bottom": "1px solid #B8B8B8" }}>
                <div className="col-12 mb-2">
                    <HeaderTitle text={this.state.patientName} />
                </div>

                <div className="col-12 mb-2">
                    <HeaderAttribute
                        attribute="Report Type"
                        value={<SquashedValues valueList={["A5", date, time]} />}
                    />
                </div>

                <div className="col-12 col-lg-6 mb-2 mb-lg-0">
                    <HeaderAttribute attribute="Patient Code" value={this.state.screeningId} />
                </div>

                <div className="IBARReport__HeaderControls col-12 col-lg-6 d-flex justify-content-lg-end">
                    <div className="mr-2 mr-lg-3">
                        <button
                            type="button"
                            onClick={async () => {
                                await viewScreeningAssociatedFile(
                                    this.state.screeningId,
                                    this.state.reportUrl,
                                );
                            }}
                            className="DownloadBtn">
                            <img
                                className="mr-2"
                                src={images("./icons/download-icon-pri.svg")}
                                alt="download btn icon"
                            />
                            <span>Download</span>
                        </button>
                    </div>
                    {/*<Button onClick={this.toggleCommentBox}*/}
                    {/*        style={{ 'min-height': '35px', 'padding': '4px 20px' }}*/}
                    {/*        className="Btn fw-300 fs-14 Btn--pri">*/}
                    {/*    Add Provider Comment*/}
                    {/*</Button>*/}
                </div>
            </div>
        );
    };

    getPhysicianDetails = () => {
        return (
            <div>
                <div className="row no-gutters py-3">
                    <div className={"fs-18 fw-bold col-12 mb-3 report-header"}>
                        Physician Details
                    </div>
                </div>
                <div className={"mx-1 row"}>
                    <div className={"col-lg-4 col-12 mb-3"}>
                        <span className={"fw-bold mr-2"}>Account Number:</span>
                        {this.state.screening && this.state.screening.header_details
                            ? this.state.screening.header_details.account_number
                            : ""}
                    </div>
                    <div className={"col-lg-4 col-12 mb-3"}>
                        <span className={"fw-bold mr-2"}>Physician Name:</span>
                        {this.state.screening && this.state.screening.header_details
                            ? this.state.screening.header_details.physician_name
                            : ""}
                    </div>
                    <div className={"col-lg-4 col-12 mb-3"}>
                        <span className={"fw-bold mr-2"}>Physician ID:</span>
                        {this.state.screening && this.state.screening.header_details
                            ? this.state.screening.header_details.physician_id
                            : ""}
                    </div>
                </div>
            </div>
        );
    };

    getPatientInfo = () => {
        return (
            <div>
                <div className="row no-gutters py-3">
                    <div className={"fs-18 fw-bold col-12 mb-3 report-header"}>Patient Info</div>
                </div>
                <div className={"mx-1 row"} style={{ "border-bottom": "1px solid #B8B8B8" }}>
                    <div className={"col-lg-4 col-12 mb-3"}>
                        <span className={"fw-bold mr-2"}>Age:</span>
                        {this.state.screening && this.state.screening.patient_details
                            ? this.state.screening.patient_details.age
                            : ""}
                    </div>
                    <div className={"col-lg-4 col-12 mb-3"}>
                        <span className={"fw-bold mr-2"}>Ethnicity:</span>
                        {this.state.screening && this.state.screening.patient_details
                            ? this.state.screening.patient_details.ethnicity
                            : ""}
                    </div>
                    <div className={"col-lg-4 col-12 mb-3"}>
                        <span className={"fw-bold mr-2"}>Marital Status:</span>
                        {this.state.screening && this.state.screening.patient_details
                            ? this.state.screening.patient_details.marital_status
                            : ""}
                    </div>
                    <div className={"col-lg-4 col-12 mb-3"}>
                        <span className={"fw-bold mr-2"}>Date of Birth:</span>
                        {this.state.screening && this.state.screening.patient_details
                            ? this.state.screening.patient_details.dob
                            : ""}
                    </div>
                    <div className={"col-lg-4 col-12 mb-3"}>
                        <span className={"fw-bold mr-2"}>Gender:</span>
                        {this.state.screening && this.state.screening.patient_details
                            ? this.state.screening.patient_details.gender
                            : ""}
                    </div>
                    <div className={"col-lg-4 col-12 mb-3"}>
                        <span className={"fw-bold mr-2"}>Current Living:</span>
                        {this.state.screening && this.state.screening.patient_details
                            ? this.state.screening.patient_details.persons_living
                            : ""}
                    </div>
                    <div className={"col-lg-4 col-12 mb-3"}>
                        <span className={"fw-bold mr-2"}>Current Housing:</span>
                        {this.state.screening && this.state.screening.patient_details
                            ? this.state.screening.patient_details.persons_living
                            : ""}
                    </div>
                    <div className={"col-lg-4 col-12 mb-3"}>
                        <span className={"fw-bold mr-2"}>Employed:</span>
                        {this.state.screening && this.state.screening.patient_details
                            ? this.state.screening.patient_details.hours_work_per_week
                            : ""}
                    </div>
                </div>
            </div>
        );
    };

    toggleCommentBox = () => {
        this.setState((ps) => {
            return {
                showCommentBox: !ps.showCommentBox,
                ReportComment: null,
                commentError: null,
            };
        });
    };

    submitComment = (e) => {
        e.preventDefault();
        let urlParams = { patientId: this.state.patientId };
        let data = {
            report_type: "A5",
            comment: this.state.ReportComment,
        };

        api.provider.set_provider_comment({ data, urlParams }).catch((err) => {
            console.log(err);
        });
        this.toggleCommentBox();
    };

    handleCommentChange = (e) => {
        const value = e.target.value;
        this.setState(
            {
                ReportComment: value,
            },
            this.validateComment,
        );
    };

    handleCommentBlur = () => {
        this.validateComment();
    };

    validateComment = () => {
        let value = this.state.ReportComment;
        if (!value || !value.trim()) {
            this.setState({
                commentError: "Comment is required",
            });
            return;
        }
        this.setState({
            commentError: null,
        });
    };

    render() {
        let backLinkText = "Back To Reports";
        if (getQueryParams("patient-id", this.props.location.search)) {
            backLinkText = "Back To Patient Profile";
        }

        return (
            <div className="OPSRReport container py-5">
                <div className="d-none d-lg-block mb-4">
                    <div onClick={() => this.props.history.goBack()} className="BackLink cursor">
                        <img src={images("./icons/solidBckBtn.svg")} alt="back btn" />
                        <span>{backLinkText}</span>
                    </div>
                </div>
                <header className="OPSRReport__Header">
                    <this.HeaderContent />
                </header>
                {this.getPhysicianDetails()}
                {this.getPatientInfo()}
                <section className="mt-3">
                    {this.state.showCommentBox && (
                        <XBARReportCommentBox
                            toggle={this.toggleCommentBox}
                            submit={this.submitComment}
                            ReportComment={this.state.ReportComment}
                            handleChange={this.handleCommentChange}
                            handleBlur={this.handleCommentBlur}
                            error={this.state.commentError}
                        />
                    )}
                </section>
                <section className="row no-gutters mt-4">
                    <aside className="col-3 pr-3 d-none d-lg-block">
                        {this.state.patientReport && this.getSidebarListContainer()}
                    </aside>
                    <main className="col-12 col-lg-9">
                        {this.state.patientReport && getMainA5Content(this.state.patientReport)}
                        <div className={"disclaimer p-2"}>
                            <span className={"fs-12"}>
                                <i>Disclaimer</i>
                            </span>
                            <p className={"fs-10"}>
                                This is not intended as a medical or other professional service, and
                                the use of the PSR or any other aptihealth materials is not intended
                                to create and does not create any medical or other professional
                                services relationship. Use of the PSR is not an adequate substitute
                                for obtaining medical or other professional advice, diagnosis or
                                treatment from a qualified licensed healthcare provider. The
                                aptihealth PSR is provided 'as is' without any warranties of any
                                kind, either express or implied, and aptihealth disclaims all
                                warranties including liability for indirect or consequential
                                damages. The PSR was developed by aptihealth, inc., & the assessment
                                is made available through the aptihealth account identified in the
                                PSR.
                            </p>
                            <p className={"fs-10"}>
                                This document contains private and confidential health information
                                protected by state and federal law. If you have received this
                                document in error, Please call (888) 454-3827
                            </p>
                        </div>
                    </main>
                </section>
            </div>
        );
    }

    getSidebarListContainer() {
        return (
            <SidebarListContainer>
                <div className="accordion" id={"accordionId"}>
                    <SidebarList>
                        <div className="SidebarCollapsibleListItem">
                            <button
                                className="SidebarCollapsibleListItem__toggle"
                                data-toggle="collapse"
                                data-target={".global_risk"}>
                                <SidebarListItem title={"Global Risk Severity"} />
                            </button>
                        </div>
                        <div className="SidebarCollapsibleListItem">
                            <button
                                className="SidebarCollapsibleListItem__toggle"
                                data-toggle="collapse"
                                data-target={".medical_problems"}>
                                <SidebarListItem title={"Medical Problems"} />
                            </button>
                        </div>
                        <div className="SidebarCollapsibleListItem">
                            <button
                                className="SidebarCollapsibleListItem__toggle"
                                data-toggle="collapse"
                                data-target={".past_treatment"}>
                                <SidebarListItem title={"Past Treatments"} />
                            </button>
                        </div>
                        <div className="SidebarCollapsibleListItem">
                            <button
                                className="SidebarCollapsibleListItem__toggle"
                                data-toggle="collapse"
                                data-target={".life_problems"}>
                                <SidebarListItem title={"Life Problems"} />
                            </button>
                        </div>
                        <div className="SidebarCollapsibleListItem">
                            <button
                                className="SidebarCollapsibleListItem__toggle"
                                data-toggle="collapse"
                                data-target={".substance_dependencies"}>
                                <SidebarListItem title={"Substance Dependencies"} />
                            </button>
                        </div>
                        <div className="SidebarCollapsibleListItem">
                            <button
                                className="SidebarCollapsibleListItem__toggle"
                                data-toggle="collapse"
                                data-target={".psychiatric_medications"}>
                                <SidebarListItem title={"Psychiatric Medications"} />
                            </button>
                        </div>
                        <div className="SidebarCollapsibleListItem">
                            <button
                                className="SidebarCollapsibleListItem__toggle"
                                data-toggle="collapse"
                                data-target={".treatment_recommendations"}>
                                <SidebarListItem title={"Treatment Recommendations"} />
                            </button>
                        </div>
                    </SidebarList>
                </div>
            </SidebarListContainer>
        );
    }
}

export default connect(null, {
    setDynamicRouteConfiguration,
})(A5Report);

export const getMainA5Content = (patientReport) => {
    return (
        <div>
            <ReportsMainCollapse
                title={"Global Risk Severity"}
                uid={"global_risk"}
                accordionId={"#accordionId"}>
                <div className="p-4">
                    <div className={"col-12"}>
                        <span className={"fs-16 font-weight-bold"}>Crisis</span>
                        {getRatingAnswer(patientReport.global_risk.crisis.severity)}
                    </div>
                </div>
                <div className="p-4">
                    <div className={"col-12"}>
                        <span className={"fs-16 font-weight-bold"}>Psychiatric</span>
                        {getRatingAnswer(patientReport.global_risk.psychiatric.severity)}
                    </div>
                </div>
                <div className="p-4">
                    <div className={"col-12"}>
                        <span className={"fs-16 font-weight-bold"}>Distress</span>
                        {getRatingAnswer(patientReport.global_risk.distress.severity)}
                    </div>
                </div>
            </ReportsMainCollapse>
            <ReportsMainCollapse
                title={"Medical Problems"}
                uid={"medical_problems"}
                accordionId={"#accordionId"}>
                <div className={"row p-4"}>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Thyroid</span>
                        {getYesNoAnswer(patientReport.medical_problems.thyroid.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Cardiac</span>
                        {getYesNoAnswer(patientReport.medical_problems.cardiac.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Blood Pressure</span>
                        {getYesNoAnswer(patientReport.medical_problems.blood_pressure.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Pulmonary</span>
                        {getYesNoAnswer(patientReport.medical_problems.pulmonary.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Diabetes</span>
                        {getYesNoAnswer(patientReport.medical_problems.diabetes.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Cancer</span>
                        {getYesNoAnswer(patientReport.medical_problems.cancer.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Liver / Hepatitis</span>
                        {getYesNoAnswer(patientReport.medical_problems.liver_hepatitis.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Chronic Pain</span>
                        {getYesNoAnswer(patientReport.medical_problems.chronic_pain.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>HIV / AIDS/ STD</span>
                        {getYesNoAnswer(patientReport.medical_problems.hiv_aids_std.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Autoimmune</span>
                        {getYesNoAnswer(patientReport.medical_problems.autoimmune.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Kidney / Bladder</span>
                        {getYesNoAnswer(patientReport.medical_problems.kidney_bladder.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Neurological</span>
                        {getYesNoAnswer(patientReport.medical_problems.neurological.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Allergies</span>
                        {getYesNoAnswer(patientReport.medical_problems.allergies.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Dental</span>
                        {getYesNoAnswer(patientReport.medical_problems.dental.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Other</span>
                        {getYesNoAnswer(
                            patientReport.medical_problems.other_medical_problems.answer,
                        )}
                    </div>
                </div>
            </ReportsMainCollapse>
            <ReportsMainCollapse
                title={"Past Treatments"}
                uid={"past_treatment"}
                accordionId={"#accordionId"}>
                <div className={"row p-4"}>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>INPT Mental Health</span>
                        {getYesNoAnswer(patientReport.past_treatment.inpt_mental_health.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Detox / Rehab</span>
                        {getYesNoAnswer(patientReport.past_treatment.detox_rehab.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Residential Care</span>
                        {getYesNoAnswer(patientReport.past_treatment.residential_care.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Outpatient Therapy</span>
                        {getYesNoAnswer(patientReport.past_treatment.outpatient_therapy.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Case Management</span>
                        {getYesNoAnswer(patientReport.past_treatment.case_management.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Crisis Services</span>
                        {getYesNoAnswer(patientReport.past_treatment.crisis_services.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Support Groups</span>
                        {getYesNoAnswer(patientReport.past_treatment.support_groups.answer)}
                    </div>
                </div>
            </ReportsMainCollapse>
            <ReportsMainCollapse
                title={"Life Problems"}
                uid={"life_problems"}
                accordionId={"#accordionId"}>
                <div className={"row p-4"}>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Health</span>
                        {getYesNoAnswer(patientReport.life_problems.health.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Substance Abuse</span>
                        {getYesNoAnswer(patientReport.life_problems.substance_abuse.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Mental Health</span>
                        {getYesNoAnswer(patientReport.life_problems.mental_health.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Legal</span>
                        {getYesNoAnswer(patientReport.life_problems.legal.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Work</span>
                        {getYesNoAnswer(patientReport.life_problems.work.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Relationships</span>
                        {getYesNoAnswer(patientReport.life_problems.relationships.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Abuse / Violence</span>
                        {getYesNoAnswer(patientReport.life_problems.abuse_violence.answer)}
                    </div>
                </div>
            </ReportsMainCollapse>
            <ReportsMainCollapse
                title={"Substance Dependency Issues"}
                uid={"substance_dependencies"}
                accordionId={"#accordionId"}>
                <div className={"row p-4"}>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Used More Than Wanted</span>
                        {getYesNoAnswer(
                            patientReport.substance_dependencies.use_more_than_wanted.answer,
                        )}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Not Able to Use Less</span>
                        {getYesNoAnswer(
                            patientReport.substance_dependencies.not_able_to_use_less.answer,
                        )}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Risked Work</span>
                        {getYesNoAnswer(patientReport.substance_dependencies.risked_work.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Upset Others</span>
                        {getYesNoAnswer(patientReport.substance_dependencies.upset_others.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Felt Guilty</span>
                        {getYesNoAnswer(patientReport.substance_dependencies.felt_guilty.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Missed Events</span>
                        {getYesNoAnswer(patientReport.substance_dependencies.missed_events.answer)}
                    </div>
                    <div className={"col-lg-6 col-12 mb-4"}>
                        <span className={"fs-16 font-weight-bold"}>Withdrawl / Hangover</span>
                        {getYesNoAnswer(
                            patientReport.substance_dependencies.withdrawal_hangover.answer,
                        )}
                    </div>
                </div>
            </ReportsMainCollapse>
            <ReportsMainCollapse
                title={"Psychiatric Medications"}
                uid={"psychiatric_medications"}
                accordionId={"#accordionId"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        {patientReport.additional_info.psychiatric_medications}
                    </div>
                </div>
            </ReportsMainCollapse>
            <ReportsMainCollapse
                title={"Treatment Recommendations"}
                uid={"treatment_recommendations"}
                accordionId={"#accordionId"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        {patientReport.additional_info.treatment_recommendations}
                    </div>
                </div>
            </ReportsMainCollapse>
        </div>
    );
};

export const getYesNoAnswer = (answer) => {
    return (
        <div className={"RatingScaleView__RatingControls row d-flex"}>
            <div className={"col-lg-4 col-md-3 col-6"} style={{ padding: 0 }}>
                <div
                    className={
                        "ratings-btn " + (answer === 1 ? "RatingScaleView__btn--active-5" : "")
                    }>
                    Yes
                </div>
            </div>
            <div className={"col-lg-4 col-md-3 col-6"} style={{ padding: 0 }}>
                <div
                    className={
                        "ratings-btn " + (answer === 0 ? "RatingScaleView__btn--active-5" : "")
                    }>
                    No
                </div>
            </div>
        </div>
    );
};

export const getRatingAnswer = (answer) => {
    return (
        <div>
            <div className={"RatingScaleView__RatingControls row"}>
                <div
                    style={{ textAlign: "center", padding: 0 }}
                    className={"col-lg-2 col-md-4 col-6 mb-4"}>
                    <div
                        className={
                            "ratings-btn " + (answer === 0 ? "RatingScaleView__btn--active-0" : "")
                        }>
                        0
                    </div>
                    <div className={"RatingScaleView__severity fs-12"}>No Problem</div>
                </div>
                <div
                    style={{ textAlign: "center", padding: 0 }}
                    className={"col-lg-2 col-md-4 col-6 mb-4"}>
                    <div
                        className={
                            "ratings-btn " + (answer === 1 ? "RatingScaleView__btn--active-1" : "")
                        }>
                        1
                    </div>
                    <div className={"RatingScaleView__severity fs-12 "}>Mild Problem</div>
                </div>
                <div
                    style={{ textAlign: "center", padding: 0 }}
                    className={"col-lg-2 col-md-4 col-6 mb-4"}>
                    <div
                        className={
                            "ratings-btn " + (answer === 2 ? "RatingScaleView__btn--active-2" : "")
                        }>
                        2
                    </div>
                    <div className={"RatingScaleView__severity fs-12"}>Mild to Moderate</div>
                </div>
                <div
                    style={{ textAlign: "center", padding: 0 }}
                    className={"col-lg-2 col-md-4 col-6 mb-4"}>
                    <div
                        className={
                            "ratings-btn " + (answer === 3 ? "RatingScaleView__btn--active-3" : "")
                        }>
                        3
                    </div>
                    <div className={"RatingScaleView__severity fs-12 "}>Moderate Problem</div>
                </div>
                <div
                    style={{ textAlign: "center", padding: 0 }}
                    className={"col-lg-2 col-md-4 col-6 mb-4"}>
                    <div
                        className={
                            "ratings-btn " + (answer === 4 ? "RatingScaleView__btn--active-4" : "")
                        }>
                        4
                    </div>
                    <div className={"RatingScaleView__severity fs-12"}>Moderate to Severe</div>
                </div>
                <div
                    style={{ textAlign: "center", padding: 0 }}
                    className={"col-lg-2 col-md-4 col-6 mb-4"}>
                    <div
                        className={
                            "ratings-btn " + (answer === 5 ? "RatingScaleView__btn--active-5" : "")
                        }>
                        5
                    </div>
                    <div className={"RatingScaleView__severity fs-12"}>Severe Problem</div>
                </div>
            </div>
        </div>
    );
};
