import React from "react";
import PropTypes from "prop-types";
import { ACUITY_INDEX } from "../../constants/acuity";
import { createClassNameString } from "../utils";
import "./styles.scss";

export const AcuityIndexTable = ({ className }) => (
    <div className={createClassNameString(["apti-AcuityIndexTable", className])}>
        <span className={"apti-AcuityIndexTable--bold"}>Acuity index</span>
        <span className={"apti-AcuityIndexTable--bold"}>Therapy sessions</span>
        <div className={"apti-AcuityIndexTable--divider"} />
        <span>
            4 <span className={"apti-AcuityIndexTable--grey"}>{ACUITY_INDEX[4].label}</span>
        </span>
        <span>{ACUITY_INDEX[4].sessions}</span>
        <div className={"apti-AcuityIndexTable--divider"} />
        <span>
            3 <span className={"apti-AcuityIndexTable--grey"}>{ACUITY_INDEX[3].label}</span>
        </span>
        <span>{ACUITY_INDEX[3].sessions}</span>
        <div className={"apti-AcuityIndexTable--divider"} />
        <span>
            2 <span className={"apti-AcuityIndexTable--grey"}>{ACUITY_INDEX[2].label}</span>
        </span>
        <span>{ACUITY_INDEX[2].sessions}</span>
        <div className={"apti-AcuityIndexTable--divider"} />
        <span>
            1 <span className={"apti-AcuityIndexTable--grey"}>{ACUITY_INDEX[1].label}</span>
        </span>
        <span>{ACUITY_INDEX[1].sessions}</span>
    </div>
);

AcuityIndexTable.propTypes = {
    className: PropTypes.string,
};

AcuityIndexTable.defaultProps = {
    className: undefined,
};
