// Copyright aptihealth, inc. 2019 All Rights Reserved
import { http, httpAuth } from "../utils/axios";

const authRequests = {
    new_id_token: function ({ data }) {
        let conf = { method: "post", url: "/v1/auth/new_id_token", data };
        return httpAuth(conf);
    },

    fetch_profile: function ({ options }) {
        let conf = {
            method: "post",
            url: "/v1/patient/profile",
            ...options,
        };
        return httpAuth(conf);
    },

    login: function ({ data }) {
        let conf = { method: "post", url: "/v1/auth/login", data };
        return http(conf);
    },

    sign_up: function ({ data, showLoader = false }) {
        let conf = {
            method: "post",
            url: "/v1/auth/signup",
            params: { showLoader },
            data,
        };
        return http(conf);
    },

    mail_forgot_pw_code: function ({ data }) {
        let conf = { method: "post", url: "/v1/auth/forgot_password", data };
        return http(conf);
    },

    reset_forgotten_pw: function ({ data }) {
        let conf = { method: "post", url: "/v1/auth/forgot_password_confirm", data };
        return http(conf);
    },

    auth_challenge: function ({ data }) {
        let conf = { method: "post", url: "/v1/auth/auth_challenge", data };
        return http(conf);
    },

    complete_auto_signup: function ({ data }) {
        let conf = { method: "post", url: "/v1/auth/complete_auto_signup", data };
        return http(conf);
    },

    /** X-custom-header v2 */
    change_password: function ({ data, accessToken = window.localStorage.getItem("accessToken") }) {
        let conf = {
            method: "post",
            url: "/v1/auth/change_password",
            data,
            params: { accessToken },
        };
        return httpAuth(conf);
    },

    validate_referral: function ({ data }) {
        let conf = { method: "post", url: "/v2/patient/referral_code", data };
        return http(conf);
    },

    verify_otc: function ({ data }) {
        let conf = { method: "post", url: "/v1/auth/verify_otc", data };
        return http(conf);
    },

    get_user_idp: function ({ data }) {
        let conf = { method: "post", url: "/v1/auth/get_user_idp", data };
        return http(conf);
    },

    custom_auth: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/auth/ext_user",
            data,
            params: { showLoader: false },
        };
        return http(conf);
    },
};

export default authRequests;
