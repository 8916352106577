// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import { toSentenceCase } from "../../utils/filters";

const PcpDetails = (props) => {
    let address = props.address;
    return (
        <div className="SignUp--step4__PcpDetails mb-5">
            <hr></hr>
            <h5 className="fs-17 fw-bold m-0 mt-4 p-0">Associated PCP Details</h5>
            <div className="d-flex justify-content-between mt-3">
                <span className="fs-15 fw-bold">Doctor Name</span>
                <span className="PcpDetails fs-15 fw-bold">{props.pcp_name}</span>
            </div>
            <div className="d-flex justify-content-between mt-3">
                <span className="fs-15 fw-bold">Referral code</span>
                <span className="PcpDetails fs-15 fw-bold">{props.referralCode}</span>
            </div>
            <div className="d-flex justify-content-between mt-3">
                <span className="fs-15 fw-bold">Address</span>
                <span className="PcpDetails fs-15 fw-bold">
                    {address.street +
                        ", " +
                        toSentenceCase(address.city) +
                        ", " +
                        toSentenceCase(address.state) +
                        " " +
                        address.zip_code}
                </span>
            </div>
        </div>
    );
};

export default PcpDetails;
