import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
import { createClassNameString } from "../../../component-library/";
import moment from "moment";

export const CalendarDateAxis = ({
    className,
    daysToDisplay,
    abbreviateDateFormat,
    highlightToday,
}) => {
    const isDateToday = (date) => date.isSame(moment().local(), "day");

    const buildWeekDayNameClassList = (dateToDisplay, highlightToday) => {
        const singleDayClasses = ["apti-CalendarDateAxis__Date__WeekDayName"];

        if (isDateToday(dateToDisplay) && highlightToday) {
            singleDayClasses.push("apti-CalendarDateAxis__Date__WeekDayNameHighlighted");
        }
        return singleDayClasses;
    };

    const formatWeekDayName = (date, abbreviateDateFormat) => {
        const abbreviatedWeekDayFormat = "ddd";
        const fullWeekDayFormat = "dddd";

        return date.format(abbreviateDateFormat ? abbreviatedWeekDayFormat : fullWeekDayFormat);
    };

    const formatMonthAndDate = (date, abbreviateDateFormat) => {
        const abbreviatedMonthFormat = "MMM";
        const fullMonthFormat = "MMMM";

        return date.format(`${abbreviateDateFormat ? abbreviatedMonthFormat : fullMonthFormat} D`);
    };

    return (
        <div className={createClassNameString(["apti-CL apti-CalendarDateAxis", className])}>
            {daysToDisplay.map((dateToDisplay) => {
                const singleDayClasses = buildWeekDayNameClassList(dateToDisplay, highlightToday);

                return (
                    <div
                        className={createClassNameString([
                            "apti-CalendarDateAxis__Date",
                            className,
                        ])}>
                        <span className={createClassNameString(singleDayClasses)}>
                            {formatWeekDayName(dateToDisplay, abbreviateDateFormat)}
                        </span>
                        <div className={"apti-CalendarDateAxis__Date__MonthAndDate"}>
                            {formatMonthAndDate(dateToDisplay, abbreviateDateFormat)}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

CalendarDateAxis.propTypes = {
    className: PropTypes.string,
    daysToDisplay: PropTypes.arrayOf(PropTypes.instanceOf(moment)),
    abbreviateDateFormat: PropTypes.bool,
    highlightToday: PropTypes.bool,
};

CalendarDateAxis.defaultProps = {
    className: undefined,
    daysToDisplay: [moment().local()],
    abbreviateDateFormat: false,
    highlightToday: false,
};
