import React from "react";

import { TextTypes, TextColors } from "../../constants";
import { Button } from "../../Button";
import { Text } from "../../Text";
import { ButtonTypes } from "../../constants";

import "./styles.scss";
import { clickAnalytics } from "../../../components/Common/InsuranceEligibility/signup_analytics";

// Relates to: Self Signup

export const VerifyInsurancePending = ({ title, step, insuranceName, onClick }) => {
    return (
        <div className="apti-VerifyInsurance">
            <Text type={TextTypes.heading1} color={TextColors.green}>
                {title}
            </Text>
            <Text className="details">
                <>
                    <div>We aren’t able to check your insurance coverage right now.</div>
                    <div>
                        Please contact{" "}
                        <a
                            onClick={() => clickAnalytics("self_signup", "pending", "email")}
                            className={"green bold link"}
                            href={"mailto:support@aptihealth.com"}>
                            {"support@aptihealth.com"}
                        </a>{" "}
                        or{" "}
                        <a
                            onClick={() => clickAnalytics("self_signup", "pending", "call")}
                            className={"green bold link"}
                            href={"tel:8884543827"}>
                            {"(888) 454-3827"}
                        </a>{" "}
                        before your first appointment to prevent billing issues.
                    </div>
                </>
            </Text>
            <Button className={ButtonTypes.primary} onClick={onClick}>
                Okay
            </Button>
        </div>
    );
};

VerifyInsurancePending.defaultProps = {
    title: "",
    insuranceName: "",
    onClick: () => {},
};
