// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import images from "../../../../utils/images";

const DownloadBtn = (props) => {
    const { url } = props;
    return (
        <a href={url}>
            <button type="button" href={url} target="_blank" className="DownloadBtn">
                <img
                    className="mr-2"
                    src={images("./icons/download-icon-pri.svg")}
                    alt="download btn icon"
                />
                <span>Download</span>
            </button>
        </a>
    );
};

export default DownloadBtn;
