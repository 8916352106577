import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import images from "../../../utils/images";
import { Link, withRouter } from "react-router-dom";
import { createClassNameString } from "../../../component-library";
import { connect } from "react-redux";

const OnBehalfOfStickyHeader = ({ className, ...props }) => {
    const calendarState = props.calendar;
    const providerName = calendarState.provider.name;
    const patientUsername = calendarState.onBehalfOfPatient;
    const patient = calendarState.patientMap[patientUsername];
    const patientName = `${patient?.first_name} ${patient?.last_name}`;

    return patient && providerName ? (
        <div className="apti-OnBehalfOfStickyHeader">
            <div
                className={createClassNameString([
                    "apti-CL apti-OnBehalfOfStickyHeader--container",
                    className,
                ])}>
                <Link
                    className="apti-OnBehalfOfStickyHeader--go-back-link"
                    onClick={() =>
                        props.history.push(`/app/patient/${patientUsername}/profile/v2`)
                    }>
                    <img className="img-fluid green" src={images("./icons/Chevron_Left.svg")} />
                    <span className="ml-1 apti-OnBehalfOfStickyHeader--go-back-link-text">
                        Back to {patientName} chart
                    </span>
                </Link>
                <div className="apti-OnBehalfOfStickyHeader--provider-bubble">
                    <p>Viewing {providerName}'s Calendar</p>
                </div>
            </div>
        </div>
    ) : null;
};

OnBehalfOfStickyHeader.propTypes = {
    className: PropTypes.string,
};

OnBehalfOfStickyHeader.defaultProps = {
    className: undefined,
};

const mapStateToProps = (state) => {
    return {
        calendar: state.calendar,
    };
};

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnBehalfOfStickyHeader));
