import "./styles.scss";
import React from "react";
import { createClassNameString } from "../utils";
import PropTypes from "prop-types";

export const CharacterCounter = ({ length, characterLimit, className }) => {
    const getCharacterCounterClassName = () => {
        if (length >= characterLimit - 10 && length <= characterLimit) {
            return "CharacterCounter-warning";
        } else if (length > characterLimit) {
            return "CharacterCounter-error";
        }
    };

    return (
        <p
            data-public
            className={createClassNameString([
                "CharacterCounter-text",
                getCharacterCounterClassName(),
                className,
            ])}>
            {length ?? 0}/{characterLimit}
        </p>
    );
};

CharacterCounter.propTypes = {
    length: PropTypes.number,
    characterLimit: PropTypes.number,
    className: PropTypes.string,
};
