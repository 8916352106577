// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import PropTypes from "prop-types";
import SidebarSublist from "./SidebarSublist";

const SidebarList = (props) => {
    let { children: listItems } = props;

    const renderListItems = (listItems) => {
        let renderedListItems = React.Children.map(listItems, (item) => {
            return <li>{item}</li>;
        });
        return renderedListItems;
    };

    return (
        <div>
            <ul className="SidebarList">{renderListItems(listItems)}</ul>
        </div>
    );
};

SidebarSublist.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SidebarList;
