// Copyright aptihealth, inc. 2019 All Rights Reserved

export const SCHEMA = [
    {
        name: "Monday",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
        },
        slots: [],
    },
    {
        name: "Tuesday",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
        },
        slots: [],
    },
    {
        name: "Wednesday",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
        },
        slots: [],
    },
    {
        name: "Thursday",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
        },
        slots: [],
    },
    {
        name: "Friday",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
        },
        slots: [],
    },
    {
        name: "Saturday",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
        },
        slots: [],
    },
    {
        name: "Sunday",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
        },
        slots: [],
    },
];
