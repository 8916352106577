import React from "react";
import { createClassNameString } from "../../../../component-library";
import { ICON_SIZES, ICON_TYPES } from "../../../../constants/icons";
import "./styles.scss";
import "../../../../component-library/Link/styles.scss";
import { getAutomationDataAttr } from "../../../../utils/automationUtils";

export const CarePlanIconButton = ({
    onClick,
    Icon,
    iconButtonClassName,
    iconClassName,
    text,
    testId,
}) => {
    return (
        <button
            onClick={onClick}
            className={createClassNameString([
                "CarePlanIconButton--icon_button",
                iconButtonClassName,
            ])}
            {...getAutomationDataAttr(testId)}>
            <Icon
                className={createClassNameString(["CarePlanIconButton--icon", iconClassName])}
                iconType={ICON_TYPES.OUTLINE}
                iconSize={ICON_SIZES.SMALL}
            />
            <p className="apti-LinkV2">{text}</p>
        </button>
    );
};
