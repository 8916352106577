// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component, Fragment } from "react";
import Button from "../UI/Button";
import ProgressBar from "../UI/ProgressBar";
import { connect } from "react-redux";
import {
    getNextQuestion,
    getPreviousQuestion,
    setSelectedOptions,
} from "../../redux/actions/a5Screening";
import { optionSelectHandler } from "../../containers/Dashboard/Patient/Assessment";
import { COMPLETED_STEP, START_STEP } from "./A5Screening";
import QuestionV2 from "../Patient/Assessment/QuestionV2";
import images from "../../utils/images";

class A5InProgress extends Component {
    handleNextQuestion = (singleChoiceOption = null) => {
        const options = singleChoiceOption
            ? [singleChoiceOption]
            : this.props.currentQuestion.options.filter((el) => {
                  return (
                      this.props.selectedOptions.find((index) => index === el.index) !== undefined
                  );
              });
        let requestBody = {
            id: this.props.a5Details.id,
            question_id: this.props.currentQuestion.question_id,
            options: options,
        };

        this.props.getNextQuestion(requestBody, { dob: this.props.a5Details.dob });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isLastQuestion && !this.props.currentQuestion) {
            this.props.nextStepHandler(COMPLETED_STEP);
        }
    }

    handlePrevQuestion = () => {
        if (this.props.completionPercentage === 0) {
            this.props.prevStepHandler(START_STEP);
        } else {
            this.props.getPreviousQuestion(
                { id: this.props.a5Details.id },
                { dob: this.props.a5Details.dob },
            );
        }
    };

    render() {
        return (
            <div>
                {this.props.currentQuestion && (
                    <Fragment>
                        <div className="container">
                            <div className="mt-4">
                                <span>A5 Screening</span>
                            </div>
                            <QuestionV2
                                classes={
                                    "col-12 col-lg-6 px-2 px-lg-2 col-lg-4 d-flex py-2 justify-content-center"
                                }
                                currentQuestion={this.props.currentQuestion}
                                selectOption={(option) => {
                                    if (this.props.currentQuestion.type === "single_choice") {
                                        this.handleNextQuestion(option);
                                    } else {
                                        optionSelectHandler(option, this);
                                    }
                                }}
                                selectedOptions={this.props.selectedOptions}
                                excludeOuterClass={true}
                            />
                            <div className="A5ButtonGroup d-flex h-100 align-items-center justify-content-between">
                                <Button
                                    type="button"
                                    className="Btn Btn--otl-pri mt-4"
                                    onClick={this.handlePrevQuestion}>
                                    Previous
                                </Button>
                                {this.props.currentQuestion.type === "multiple_choice" && (
                                    <Button
                                        type="button"
                                        className="Btn Btn--pri mt-4"
                                        disabled={
                                            this.props.selectedOptions.length === 0
                                                ? "disabled"
                                                : null
                                        }
                                        onClick={() => this.handleNextQuestion()}>
                                        {!this.props.isLastQuestion && "Next"}
                                        {this.props.isLastQuestion && "Finish"}
                                    </Button>
                                )}
                            </div>
                            <div className={"d-flex justify-content-end my-4"}>
                                <div className={"col-lg-4 col-12"} style={{ padding: 0 }}>
                                    <div className={"row"}>
                                        <div className={"col-lg-12 col-10"}>
                                            <div>
                                                <span>
                                                    <b>
                                                        {this.props.completionPercentage}% Completed
                                                    </b>
                                                </span>
                                            </div>
                                            <div className={"ProgressBar"} style={{ height: 6 }}>
                                                <div
                                                    className="ProgressBar__progress"
                                                    style={{
                                                        height: 6,
                                                        transform: `translate3d(${
                                                            this.props.completionPercentage + "%"
                                                        },0,0)`,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className={"d-lg-none col-2"}>
                                            <Button
                                                className={"scroll-up-btn"}
                                                onClick={() => window.scrollTo(0, 0)}>
                                                <img
                                                    className="img-fluid"
                                                    style={{ transform: "rotate(180deg)" }}
                                                    src={images("./common/chevron.svg")}
                                                    alt={"Scroll Up"}
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentQuestion: state.a5Screening.currentQuestion,
        completionPercentage: state.a5Screening.completionPercentage,
        selectedOptions: state.a5Screening.selectedOptions,
        lastSelectedOption: state.a5Screening.lastSelectedOption,
        isLastQuestion: state.a5Screening.isLastQuestion,
        a5Details: state.a5Screening.a5Details,
    };
};

export default connect(mapStateToProps, {
    getNextQuestion,
    getPreviousQuestion,
    setSelectedOptions,
})(A5InProgress);
