// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, {forwardRef, useEffect} from "react";
import { isAuthorized } from "../../../../redux/actions/auth";
import { useSelector } from "react-redux";
import withRouter from "react-router-dom/es/withRouter";
import { admin_cse } from "../../../../config/roles";
import ResetPatientPasswordCard from "./Admin/ResetPatientPasswordCard";
import ChangePatientEmailCard from "./Admin/ChangePatientEmailCard";
import VerifyPatientEmailCard from "./Admin/VerifyPatientEmailCard";
import ChangePatientPracticeCard from "./Admin/ChangePatientPracticeCard";
import OverridePatientAutocharge from "./Admin/OverridePatientAutocharge";
import RestrictPatientSchedulingCard from "./Admin/RestrictPatientSchedulingCard";
import FeatureGate from "../../../Common/FeatureGate/FeatureGate";
import { OPERATIONAL_RESTRICT_PATIENT_SCHEDULING } from "../../../../constants/featureFlags";

const MemberProfileAdmin = (props) => {

    const privateFlags = useSelector((state) => state.flags.privateFlags);
    const privateFlagsHaveLoaded = useSelector((state) => state.flags.privateFlagsHaveLoaded);


    useEffect(() => {
        if (!isAuthorized(admin_cse)) {
            props.history.push("/app/home");
        }
    }, []);

    return (
        <div className={"AdminTab"}>
            <div className={"mt-3 mb-3"}>
                <ChangePatientEmailCard
                    patientDetails={props.patientDetails}
                    updateState={props.updateState}
                />
            </div>
            <div className={"mb-3"}>
                <VerifyPatientEmailCard
                    patientDetails={props.patientDetails}
                    updateState={props.updateState}
                />
            </div>
            <div className={"mb-3"}>
                <ResetPatientPasswordCard
                    patientDetails={props.patientDetails}
                    updateState={props.updateState}
                />
            </div>
            <div className={"mb-3"}>
                <ChangePatientPracticeCard
                    patientDetails={props.patientDetails}
                    updateState={props.updateState}
                />
            </div>
            <div className={"mb-3"}>
                <OverridePatientAutocharge
                    patientDetails={props.patientDetails}
                    updateState={props.updateState}
                    selectedTabChangeHandler={props.selectedTabChangeHandler}
                />
            </div>
            <FeatureGate
                defaultFlagBehavior={false}
                flagName={OPERATIONAL_RESTRICT_PATIENT_SCHEDULING}
                enabled={privateFlags?.[OPERATIONAL_RESTRICT_PATIENT_SCHEDULING]}
                hasLoaded={privateFlagsHaveLoaded}
                alternateFlagDisabledBehavior={<></>}>
                <div className={"mb-3"} ref={props?.adminFocusRef}>
                    <RestrictPatientSchedulingCard
                        patientDetails={props.patientDetails}
                        refetchDetails={props.refetchDetails}
                    />
                </div>
            </FeatureGate>
        </div>
    );
};

export default withRouter(MemberProfileAdmin);
