// Copyright aptihealth, inc. 2021 All Rights Reserved
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import images from "../../../utils/images";
import "./styles.scss";
import { api } from "../../../APIRequests";
import DemographicEligibilityCheck from "./DemographicEligbilityCheck";
import Ineligible from "./Ineligible";
import IntakeScheduling from "./IntakeScheduling";
import AccountLookup from "./AccountLookup";
import ConfirmIdentity from "./ConfirmIdentity";
import ConfirmInsurance from "./ConfirmInsurance";
import { getQueryParams, uuidv4 } from "../../../utils/filters";
import GenericWorkflowScreening from "./Screening/GenericWorkflowScreening";
import ChooseNewOrExistingMemberWorkflow from "./ChooseNewOrExistingMemberWorkflow";
import MemberAuth from "./MemberAuth";
import EnterOTC from "./EnterOTC";
import PracticeFailure from "./PracticeFailure";

global.workflowAuthIdToken = null;
global.workflowAuthAccessToken = null;
global.workflowAuthRefreshToken = null;

const Workflow = (props) => {
    const [sharedWorkflowData, setSharedWorkflowData] = useState({});
    const [currentStepName, setCurrentStepName] = useState(null);
    const practiceId = getQueryParams("practiceId", props.location.search);

    const backHandler = async () => {
        const options = { params: { showLoader: true } };
        const data = {
            workflow_instance_entity_id: sharedWorkflowData["workflow_instance"].entity_id,
            workflow_instance_sort_key: sharedWorkflowData["workflow_instance"]["sort_key"],
        };
        const headers = {
            "X-On-Behalf-Of": sharedWorkflowData["workflow_instance"].entity_id,
        };
        const response = await api.workflow.undo_state_and_backtrack_one_node({
            options,
            data,
            headers,
        });

        if (
            response["workflow_instance"].first_node ===
                response["workflow_instance"].current_node_name &&
            !response["workflow_instance"].previous_workflow
        ) {
            resetWorkflow();
        } else {
            setSharedWorkflowData({
                ...sharedWorkflowData,
                workflow_instance_sort_key: response["workflow_instance"]["sort_key"],
                workflow_instance: response["workflow_instance"],
            });
        }
    };

    const resetWorkflow = () => {
        window.location = window.location;
    };

    const baseProps = {
        sharedWorkflowData: sharedWorkflowData,
        setSharedWorkflowData: setSharedWorkflowData,
        backHandler: backHandler,
        resetWorkflow: resetWorkflow,
    };

    const workflowSteps = {
        account_lookup: <AccountLookup {...baseProps} />,
        confirm_identity: <ConfirmIdentity {...baseProps} />,
        confirm_insurance: <ConfirmInsurance {...baseProps} />,
        demographic_data: <DemographicEligibilityCheck {...baseProps} />,
        screening: <GenericWorkflowScreening {...baseProps} />,
        ineligible: <Ineligible {...baseProps} />,
        schedule_appointment: <IntakeScheduling {...baseProps} />,
        choose_workflow: <ChooseNewOrExistingMemberWorkflow {...baseProps} />,
        member_auth: <MemberAuth {...baseProps} />,
        OTC: <EnterOTC {...baseProps} />,
        practice_failure: <PracticeFailure />,
    };

    useEffect(() => {
        (async () => {
            const options = { params: { showLoader: true } };
            if (!currentStepName) {
                const data = { practice_id: practiceId };
                const workflowResponse = await api.workflow.get_workflow({ options, data });
                if (workflowResponse) {
                    const data = {
                        workflow_name: workflowResponse.workflow_name,
                        workflow_config_entity_id: workflowResponse.entity_id,
                        entity_type: "session",
                        workflow_instance_entity_id: uuidv4(),
                    };
                    const response = await api.workflow.create_workflow_instance({ options, data });
                    setSharedWorkflowData({ ...sharedWorkflowData, workflow_instance: response });
                }
            } else if (
                !sharedWorkflowData.workflow_instance ||
                (currentStepName && !workflowSteps.hasOwnProperty(currentStepName))
            ) {
                setCurrentStepName(null);
                props.history.push(`/app/workflow?practiceId=${practiceId}`);
            }
        })();
    }, [currentStepName]);

    //display workflow step based on changes to workflow instance
    useEffect(() => {
        let stepName =
            sharedWorkflowData.workflow_instance &&
            sharedWorkflowData.workflow_instance.current_node_name;
        if (sharedWorkflowData.workflow_instance && !stepName) {
            stepName = sharedWorkflowData.workflow_instance.first_node;
        }
        if (stepName) {
            setCurrentStepName(stepName);
            props.history.push(`/app/workflow?practiceId=${practiceId}&step=${stepName}`);
        }
    }, [sharedWorkflowData.workflow_instance]);

    //display workflow step based on changes to nav buttons (go back/forward)
    useEffect(() => {
        const stepName = getQueryParams("step", props.location.search);
        if (
            !sharedWorkflowData.workflow_instance ||
            (stepName &&
                stepName !== currentStepName &&
                stepName === sharedWorkflowData.workflow_instance.first_node)
        ) {
            setCurrentStepName(null);
            setSharedWorkflowData({});
            props.history.push(`/app/workflow?practiceId=${practiceId}`);
        } else if (
            stepName &&
            stepName !== currentStepName &&
            stepName !== sharedWorkflowData.workflow_instance.first_node
        ) {
            setCurrentStepName(stepName);
            props.history.push(`/app/workflow?practiceId=${practiceId}&step=${stepName}`);
        }
    }, [getQueryParams("step", props.location.search)]);

    return (
        <>
            <div style={{ overflowX: "hidden" }} className={"self-signup-content"}>
                <div>
                    <header className={"brand-header"} style={{ position: "relative" }}>
                        <div className="brand-header-logo">
                            <span
                                className={"logo-and-insurance"}
                                style={{ margin: " auto", float: "none" }}>
                                <img
                                    src={images("./common/apti_logo_w_tagline.svg")}
                                    alt="aptihealth"
                                    className={"apti-img"}
                                />
                            </span>
                        </div>
                    </header>

                    {currentStepName &&
                        workflowSteps.hasOwnProperty(currentStepName) &&
                        workflowSteps[currentStepName]}
                </div>
            </div>
        </>
    );
};

export default withRouter(Workflow);
