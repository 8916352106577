import images from "../../../../utils/images";
import Button from "../../../UI/Button";
import React, { useEffect } from "react";
import { formatMobileNumber } from "../../../../utils/filters";
import { withRouter } from "react-router-dom";
import { api } from "../../../../APIRequests";
import { connect } from "react-redux";
import { showAlertWithAction } from "../../../../redux/actions/alerts";

const ConfirmIdentity = (props) => {
    const { sharedWorkflowData, setSharedWorkflowData } = props;

    const demographicData =
        sharedWorkflowData.workflow_instance && sharedWorkflowData.workflow_instance.state;

    const confirmIdentityHandler = async () => {
        try {
            const options = { params: { showLoader: true } };
            const data = {
                workflow_instance_sort_key: sharedWorkflowData.workflow_instance.sort_key,
                node_name: "confirm_identity",
                state: {
                    identity_confirmed: true,
                },
            };
            const headers = {
                "X-On-Behalf-Of": sharedWorkflowData.workflow_instance.entity_id,
            };
            const response = await api.workflow.update_state_and_get_next_node({
                options,
                data,
                headers,
            });
            setSharedWorkflowData({
                ...sharedWorkflowData,
                workflow_instance: response.workflow_instance,
            });
        } catch (e) {
            console.log(e);
            props.showAlertWithAction("Error confirming identity.");
        }
    };

    return (
        <div className={"p-lg-5 p-3"}>
            <div className={"fs-22 txt-pri fw-bold mb-5 text-center"}>Tell us about yourself</div>
            <div className={"text-center my-4"}>
                <img
                    src={images("./common/workflow/ed-workflow-1-confirm-id-active.png")}
                    alt={"Confirm Identity"}
                    className={"signup-progress-bar"}
                />
            </div>
            <div className={"workflow-container-sm workflow-container-border mb-5"}>
                <div className={"p-lg-5 p-3"}>
                    <div className={"row"}>
                        {sharedWorkflowData && sharedWorkflowData.workflow_instance && (
                            <>
                                <div className={"col-12 mb-4"}>
                                    {demographicData.first_name && demographicData.last_name && (
                                        <>
                                            <div className={"txt-gry fs-14"}>Name</div>
                                            <div className={"fw-bold fs-md-16 fs-14"}>
                                                {demographicData.first_name +
                                                    " " +
                                                    demographicData.last_name}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className={"col-12 mb-4"}>
                                    {demographicData.dob && (
                                        <>
                                            <div className={"txt-gry fs-14"}>Date of Birth</div>
                                            <div className={"fw-bold fs-md-16 fs-14"}>
                                                {demographicData.dob}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className={"col-12 mb-4"}>
                                    {demographicData.mobile && (
                                        <>
                                            <div className={"txt-gry fs-14"}>Phone Number</div>
                                            <div className={"fw-bold fs-md-16 fs-14"}>
                                                {demographicData.mobile.length === 10
                                                    ? formatMobileNumber(demographicData.mobile)
                                                    : demographicData.mobile}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className={"col-12 mb-4"}>
                                    {demographicData.email && (
                                        <>
                                            <div className={"txt-gry fs-14"}>Email Address</div>
                                            <div className={"fw-bold fs-md-16 fs-14"}>
                                                {demographicData.email}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className={"col-12 mb-1"}>
                                    {demographicData.state_of_residence && (
                                        <>
                                            <div className={"txt-gry fs-14"}>
                                                State of Residence
                                            </div>
                                            <div className={"fw-bold fs-md-16 fs-14"}>
                                                {demographicData.state_of_residence}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="workflow-container-sm d-flex justify-content-between">
                <Button
                    type={"button"}
                    className={"Btn Btn--otl-gry Btn--sm-2 workflow-btn"}
                    onClick={() => {
                        props.backHandler();
                    }}>
                    Back
                </Button>
                <Button
                    type={"button"}
                    className={"Btn Btn--pri Btn--sm-2 workflow-btn"}
                    onClick={confirmIdentityHandler}>
                    Confirm & Continue
                </Button>
            </div>
            <div className={"text-center my-4 txt-gry fs-14"}>
                <div className={"mb-2"}>
                    Not You?{" "}
                    <span
                        className={"fw-bold cursor"}
                        role={"button"}
                        onClick={() => {
                            props.backHandler();
                        }}>
                        Click Here
                    </span>
                </div>
                <div className={"mb-3"}>
                    Note: If the information above is incorrect, please locate a staff member for
                    additional assistance.
                </div>
            </div>
        </div>
    );
};

export default connect(null, { showAlertWithAction })(withRouter(ConfirmIdentity));
