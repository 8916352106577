import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Recaptcha from "react-recaptcha";
import InputError from "../../UI/InputError";

const Captcha = ({ setCaptchaVerificationToken, configs }) => {
    const [recaptchaError, setRecaptchaError] = useState(null);
    const [mounted, setMounted] = useState(false);
    const EDGE_CASE = 397;

    let reCaptchaRef = React.createRef();

    useEffect(() => {
        setTimeout(() => {
            setMounted(true);
        }, 500);

        if (configs.OVERRIDE_RECAPTCHA) {
            setCaptchaVerificationToken(true);
        }
    }, [configs.OVERRIDE_RECAPTCHA]);

    return (
        <div className={"my-3 text-center w-100"}>
            <div>
                {mounted && (
                    <Recaptcha
                        className="mx-auto recaptcha"
                        size={window.innerWidth < EDGE_CASE ? "compact" : "normal"}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        ref={reCaptchaRef}
                        verifyCallback={async (response) => {
                            setRecaptchaError(null);
                            setCaptchaVerificationToken(response);
                        }}
                    />
                )}
            </div>
            <div className={"text-center my-2"}>
                {recaptchaError && (
                    <InputError style={{ position: "relative" }}>{recaptchaError}</InputError>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        configs: state.configs,
    };
};

export default connect(mapStateToProps, {})(Captcha);
