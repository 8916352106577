// Copyright aptihealth, inc. 2019 All Rights Reserved
import { Form, Formik } from "formik";
import React, { Component } from "react";
import Button from "../../../../components/UI/Button";
import { providerRoute } from "../../../../config/roles";
import {
    INITIAL_CLINICIAN_NOTES_SCHEMA,
    OUTCOME_CLINICIAN_NOTES_SCHEMA,
} from "./schema/clinicalAssessmentFormSchema";
import { convertStatus, INITIAL, OUTCOME } from "./ClinicalAssessment";
import {
    INITIAL_CLINICAL_NOTES_VALIDATION_SCHEMA,
    OUTCOME_CLINICAL_NOTES_VALIDATION_SCHEMA,
} from "./schema/clinicalAssessmentValidation";
import FormikInput from "../../../../components/UI/FormikInput";
import "./styles.scss";
import { CustomForm } from "../../../../component-library/CustomForm";

class ClinitianNotes extends Component {
    constructor(props) {
        super(props);

        let formSchema, formValidation;
        switch (props.type) {
            case INITIAL: {
                formSchema = INITIAL_CLINICIAN_NOTES_SCHEMA;
                formValidation = INITIAL_CLINICAL_NOTES_VALIDATION_SCHEMA;
                break;
            }
            case OUTCOME: {
                formSchema = OUTCOME_CLINICIAN_NOTES_SCHEMA;
                formValidation = OUTCOME_CLINICAL_NOTES_VALIDATION_SCHEMA;
                break;
            }
        }

        this.state = {
            clinician_rating: props.vals,
            formSchema,
            formValidation,
        };
    }

    submitHandler = (formData, actions) => {
        let data = { ...this.props.data };
        data[convertStatus(this.props.statusArr[0])] = { ...formData };
        this.props.submitHandler(data, actions);
    };

    renderForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 ">
                    <fieldset disabled={this.props.done ? "disabled" : ""}>
                        <div className="container">
                            <div className="row clinicialTextbox-from-wpr">
                                {this.state.formSchema.map((formEl) => {
                                    return (
                                        <div key={formEl.name} className="col-12">
                                            <FormikInput
                                                grayBorder="borderGray"
                                                clinicialTextbox="clinicialTextbox"
                                                formEl={formEl}
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </fieldset>
                    <div className="d-flex justify-content-center justify-content-lg-end">
                        <div>
                            <Button
                                type="button"
                                onClick={() =>
                                    this.props.history.push(
                                        `/app/patient/${this.props.match.params.patientId}/profile`,
                                    )
                                }
                                className="Btn Btn--otl-pri Btn-sm mr-3">
                                Back
                            </Button>
                            <Button type="submit" className="Btn Btn--pri Btn-sm">
                                Continue
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        );
    };

    render() {
        return (
            <div>
                <CustomForm
                    initialValues={this.state.clinician_rating}
                    validationSchema={this.state.validationSchema}
                    onSubmit={this.submitHandler}
                    render={this.renderForm}
                />
            </div>
        );
    }
}

export default ClinitianNotes;
