import { BuildingIcon } from "../../../components/Icons/BuildingIcon";
import { ICON_SIZES, ICON_TYPES } from "../../../constants/icons";
import { ScreeningIcon } from "../../../components/Icons/ScreeningIcon";
import { UserIcon } from "../../../components/Icons/UserIcon";
import { ReportIcon } from "../../../components/Icons/ReportIcon";
import { ShieldIcon } from "../../../components/Icons/ShieldIcon";
import { SettingsIcon } from "../../../components/Icons/SettingsIcon";
import React from "react";
import { DashboardIcon } from "../../../components/Icons/DashboardIcon";
import { HomeIcon } from "../../../components/Icons/HomeIcon";
import { LoupeIcon } from "../../../components/Icons/LoupeIcon";
import {CalendarIcon} from "../../../components/Icons/CalendarIcon";
import {MessagesIcon} from "../../../components/Icons/MessagesIcon";

const mapLabelToComponent = (label, active) => {
    switch (label) {
    case "Home":
        return (
            <HomeIcon
                iconSize={ICON_SIZES.SMALL}
                className={`toolbar-icon ${active ? "active" : null}`}
                iconType={active ? ICON_TYPES.FILLED : ICON_TYPES.OUTLINE}
            />
        );
    case "Calendar":
    case "Schedule":
        return (
            <CalendarIcon
                iconSize={ICON_SIZES.SMALL}
                className={`toolbar-icon ${active ? "active" : null}`}
                iconType={active ? ICON_TYPES.FILLED : ICON_TYPES.OUTLINE}
            />
        );
    case "Dashboard":
        return (
            <DashboardIcon
                iconSize={ICON_SIZES.SMALL}
                className={`toolbar-icon ${active ? "active" : null}`}
                iconType={active ? ICON_TYPES.FILLED : ICON_TYPES.OUTLINE}
            />
        );
    case "Practices":
        return (
            <BuildingIcon
                iconSize={ICON_SIZES.SMALL}
                className={`toolbar-icon ${active ? "active" : null}`}
                iconType={active ? ICON_TYPES.FILLED : ICON_TYPES.OUTLINE}
            />
        );
    case "Screening":
        return (
            <ScreeningIcon
                iconSize={ICON_SIZES.SMALL}
                className={`toolbar-icon ${active ? "active" : null}`}
                iconType={active ? ICON_TYPES.FILLED : ICON_TYPES.OUTLINE}
            />
        );
    case "Customer Success":
    case "Patients":
        return (
            <UserIcon
                iconSize={ICON_SIZES.SMALL}
                className={`toolbar-icon ${active ? "active" : null}`}
                iconType={active ? ICON_TYPES.FILLED : ICON_TYPES.OUTLINE}
            />
        );
    case "Reports":
        return (
            <ReportIcon
                iconSize={ICON_SIZES.SMALL}
                className={`toolbar-icon ${active ? "active" : null}`}
                iconType={active ? ICON_TYPES.FILLED : ICON_TYPES.OUTLINE}
            />
        );
    case "Eligibility":
        return (
            <ShieldIcon
                iconSize={ICON_SIZES.SMALL}
                className={`toolbar-icon ${active ? "active" : null}`}
                iconType={active ? ICON_TYPES.FILLED : ICON_TYPES.OUTLINE}
            />
        );
    case "Messages":
        return (
            <MessagesIcon
                iconSize={ICON_SIZES.SMALL}
                className={`toolbar-icon ${active ? "active" : null}`}
                iconType={active ? ICON_TYPES.FILLED : ICON_TYPES.OUTLINE}
            />
        );
    case "Settings":
        return (
            <SettingsIcon
                iconSize={ICON_SIZES.SMALL}
                className={`toolbar-icon ${active ? "active" : null}`}
                iconType={active ? ICON_TYPES.FILLED : ICON_TYPES.OUTLINE}
            />
        );
    case "Search":
        return (
            <LoupeIcon
                iconSize={ICON_SIZES.SMALL}
                className="toolbar-icon loupe-icon"
                iconType={ICON_TYPES.OUTLINE}
            />
        );
    default:
        return null;
    }
};

export default mapLabelToComponent;
