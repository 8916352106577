import React from "react";
import { createClassNameString } from "../../utils";
import PropTypes from "prop-types";
import { TwoButtonCard } from "../../TwoButtonCard";
import { Text } from "../../Text";
import { Button } from "../../Button";

import "./styles.scss";

export const OnDemandWelcomeEmail = ({
    className,
    buttonLeftClick,
    buttonRightClick,
    email,
    ...props
}) => {
    const buttonLeft = (
        <Button
            className={"primary-outline apti-TwoButtonCard-button-center"}
            onClick={buttonLeftClick}>
            Cancel
        </Button>
    );

    const buttonRight = (
        <Button className={"primary apti-TwoButtonCard-button-center"} onClick={buttonRightClick}>
            Yes
        </Button>
    );

    return (
        <TwoButtonCard
            className={createClassNameString(["apti-OnDemandWelcomeEmail", className])}
            buttonsPosition={"apti-OnDemandWelcomeEmail--buttons"}
            buttonRight={buttonRight}
            buttonLeft={buttonLeft}
            {...props}>
            <div className={"text-center"}>
                <Text className={"apti-OnDemandWelcomeEmail--title"} color={"black"}>
                    Resend welcome email to {email}?
                </Text>
            </div>
        </TwoButtonCard>
    );
};

OnDemandWelcomeEmail.propTypes = {
    className: PropTypes.string,
    buttonLeftClick: PropTypes.func,
    buttonRightClick: PropTypes.func,
    email: PropTypes.string,
};

OnDemandWelcomeEmail.defaultProps = {
    className: undefined,
    buttonLeftClick: () => {},
    buttonRightClick: () => {},
    email: "",
};
