import React, { useState } from "react";
import { AddInsurance } from "../../AddInsurance";
import _ from "lodash";
import { CATCH_ALL_INSURANCE_OPTIONS } from "../../../constants/insurnaceIconMap";
import { createInsurancePolicyAcknowledgement } from "../../../utils/userAgreementUtil";
import { InsuranceAcknowledgementPopupHOC } from "../InsuranceAcknowledgementPopupHOC";
import { getCarrierMetadata } from "../../../components/Common/InsuranceEligibility/carrierMetadata";

export const SelfSignupInsuranceHOC = ({
    isPrimaryInsurance,
    formikProps,
    firstName,
    lastName,
    insuranceCompanyOptions,
    onFileDelete,
    submitAcknowledgementHandler,
    closeAcknowledgementHandler,
    showAcknowledgementPopup,
    requiredFields,
    ...props
}) => {
    const { handleChange, handleBlur, handleFocus, values, errors, touched } = formikProps;
    const { memberIdPlaceholder, memberIdTip, memberIdTooltipContents, groupIdTooltipContents } =
        getCarrierMetadata(values.primary_insurance.carrier);
    const [noInsurance, setNoInsurance] = useState(false);

    const mapDropDownValue = (value) => ({
        label: value,
        value: value,
    });

    const mappedValues = {
        insuranceCompany: mapDropDownValue(values.primary_insurance.carrier),
        policyHolder: values.primary_insurance.policy_holder_question,
        memberId: values.primary_insurance.member_id,
        groupId: values.primary_insurance.group_id,
        firstName: values.first_name,
        lastName: values.last_name,
        dob: values.dob,
    };

    const mappedNames = {
        insuranceCompany: "primary_insurance.carrier",
        policyRelationship: "primary_insurance.policy_relationship",
        policyHolder: "primary_insurance.policy_holder_question",
        policyHolderName: "primary_insurance.policy_holder_name",
        memberId: "primary_insurance.member_id",
        groupId: "primary_insurance.group_id",
        firstName: "first_name",
        lastName: "last_name",
        dob: "dob",
    };

    const mappedTouched = {
        insuranceCompany: _.get(touched, "primary_insurance.carrier"),
        policyHolder: _.get(touched, "primary_insurance.policy_holder_question"),
        memberId: _.get(touched, "primary_insurance.member_id"),
        groupId: _.get(touched, "primary_insurance.group_id"),
        firstName: _.get(touched, "first_name"),
        lastName: _.get(touched, "last_name"),
        dob: _.get(touched, "dob"),
    };

    const mappedErrors = {
        insuranceCompany: _.get(errors, "primary_insurance.carrier"),
        policyHolder: _.get(errors, "primary_insurance.policy_holder_question"),
        memberId: _.get(errors, "primary_insurance.member_id"),
        groupId: _.get(errors, "primary_insurance.group_id"),
        firstName: _.get(errors, "first_name"),
        lastName: _.get(errors, "last_name"),
        dob: _.get(errors, "dob"),
    };

    const generatePolicyHolderName = (e, value) => {
        let policyHolderName = "Other";
        if (value === "yes") {
            policyHolderName = `${firstName} ${lastName}`;
        }
        handleChange({
            ...e,
            target: { ...e.target, name: mappedNames["policyHolderName"], value: policyHolderName },
        });
    };

    const generatePolicyHolderRelationship = (e, value) => {
        let policyHolderRelationship = "Other";
        if (value === "yes") {
            policyHolderRelationship = "self";
        }
        handleChange({
            ...e,
            target: {
                ...e.target,
                name: mappedNames["policyRelationship"],
                value: policyHolderRelationship,
            },
        });
    };

    const mappedChangeHandler = (e) => {
        const { name, value } = e.target;
        const mappedName = mappedNames[name];
        let mappedValue = value;

        switch (name) {
            case "policyHolder":
                generatePolicyHolderName(e, value);
                generatePolicyHolderRelationship(e, value);
                break;
            case "insuranceCompany":
                if (CATCH_ALL_INSURANCE_OPTIONS.includes(value)) {
                    formikProps.resetForm();
                    setNoInsurance(true);
                } else {
                    setNoInsurance(false);
                }
                break;
        }

        handleChange({ ...e, target: { ...e.target, name: mappedName, value: mappedValue } });
    };

    return (
        <>
            <AddInsurance
                values={mappedValues}
                touched={mappedTouched}
                errors={mappedErrors}
                handleChange={mappedChangeHandler}
                handleBlur={handleBlur}
                handleFocus={handleFocus}
                insuranceCompanyOptions={insuranceCompanyOptions}
                isPrimaryInsurance={isPrimaryInsurance}
                hideUploadFile={true}
                noInsurance={noInsurance}
                requiredFields={requiredFields}
                memberIdPlaceholder={memberIdPlaceholder}
                memberIdTip={memberIdTip}
                memberIdTooltipContents={memberIdTooltipContents}
                groupIdTooltipContents={groupIdTooltipContents}
                {...props}
            />
            {showAcknowledgementPopup && (
                <InsuranceAcknowledgementPopupHOC
                    gaEventPrefix={"digitial-direct"}
                    acknowledgementType={mappedValues.insuranceCompany.value}
                    disabled={!showAcknowledgementPopup}
                    primaryOnClick={submitAcknowledgementHandler}
                    secondaryOnClick={closeAcknowledgementHandler}
                />
            )}
        </>
    );
};
