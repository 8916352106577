/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const ChevronUpIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="ChevronUp"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.29289 19.7071C7.68342 20.0976 8.31658 20.0976 8.70711 19.7071L16 12.4142L23.2929 19.7071C23.6834 20.0976 24.3166 20.0976 24.7071 19.7071C25.0976 19.3166 25.0976 18.6834 24.7071 18.2929L16 9.58579L7.29289 18.2929C6.90237 18.6834 6.90237 19.3166 7.29289 19.7071Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="ChevronUp"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.58579 20.4142C7.36683 21.1953 8.63317 21.1953 9.41421 20.4142L16 13.8284L22.5858 20.4142C23.3668 21.1953 24.6332 21.1953 25.4142 20.4142C26.1953 19.6332 26.1953 18.3668 25.4142 17.5858L16 8.17157L6.58579 17.5858C5.80474 18.3668 5.80474 19.6332 6.58579 20.4142Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="ChevronUp"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM15.0607 23.3107C14.4749 23.8964 13.5251 23.8964 12.9393 23.3107C12.3536 22.7249 12.3536 21.7751 12.9393 21.1893L20 14.1287L27.0607 21.1893C27.6464 21.7751 27.6464 22.7249 27.0607 23.3107C26.4749 23.8964 25.5251 23.8964 24.9393 23.3107L20 18.3713L15.0607 23.3107Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

ChevronUpIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

ChevronUpIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
