import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { createClassNameString } from "../../component-library";
import { ICON_SIZES, ICON_TYPES } from "../../constants/icons";

export const TrashCanIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 4C13.4477 4 13 4.44772 13 5H19C19 4.44772 18.5523 4 18 4H14ZM8 7C7.44772 7 7 7.44772 7 8V9H7.99343C7.99841 8.99996 8.00338 8.99996 8.00834 9H23.9917C23.9966 8.99996 24.0016 8.99996 24.0066 9H25V8C25 7.44772 24.5523 7 24 7H8ZM24.946 11H27V8C27 6.34315 25.6569 5 24 5H21C21 3.34315 19.6569 2 18 2H14C12.3431 2 11 3.34315 11 5H8C6.34315 5 5 6.34315 5 8V11H7.05401L7.94907 27.1109C8.00795 28.1708 8.88452 29 9.94599 29H22.054C23.1155 29 23.9921 28.1708 24.0509 27.1109L24.946 11ZM22.9429 11H9.0571L9.94599 27H22.054L22.9429 11ZM14 14C14.5523 14 15 14.4477 15 15V23C15 23.5523 14.5523 24 14 24C13.4477 24 13 23.5523 13 23V15C13 14.4477 13.4477 14 14 14ZM18 14C18.5523 14 19 14.4477 19 15V23C19 23.5523 18.5523 24 18 24C17.4477 24 17 23.5523 17 23V15C17 14.4477 17.4477 14 18 14Z"
                />
            </svg>
        );
    }

    return null;
};

TrashCanIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

TrashCanIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
