import React from "react";
import { createClassNameString } from "../../utils";
import PropTypes from "prop-types";
import { TwoButtonCard } from "../../TwoButtonCard";
import { Text } from "../../Text";
import { Button } from "../../Button";

import "./styles.scss";

export const GuidedSessionReconnect = ({
    className,
    buttonLeftClick,
    buttonRightClick,
    ...props
}) => {
    const buttonLeft = (
        <Button
            className={"primary-outline apti-TwoButtonCard-button-center"}
            onClick={buttonLeftClick}>
            Yes, Reconnect Me Now
        </Button>
    );

    const buttonRight = (
        <Button className={"primary apti-TwoButtonCard-button-center"} onClick={buttonRightClick}>
            No, My Session is Done
        </Button>
    );

    return (
        <TwoButtonCard
            className={createClassNameString(["apti-GuidedSessionReconnect", className])}
            buttonsPosition={"space-center"}
            buttonRight={buttonRight}
            buttonLeft={buttonLeft}
            {...props}>
            <div className={"text-center apti-TwoButtonCard-title"}>
                <Text className={"apti-TwoButtonCard-title-font"} color={"grey"}>
                    Hmm, it looks like your session ended. Do you need to reconnect?
                </Text>
            </div>
        </TwoButtonCard>
    );
};

GuidedSessionReconnect.propTypes = {
    className: PropTypes.string,
    buttonLeftClick: PropTypes.func,
    buttonRightClick: PropTypes.func,
};

GuidedSessionReconnect.defaultProps = {
    className: undefined,
    buttonLeftClick: () => {},
    buttonRightClick: () => {},
};
