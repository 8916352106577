// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import Hr from "../../UI/Hr";
import Button from "../../UI/Button";

const ProfileDetailsCard = (props) => {
    return (
        <div className="ProviderProfile__ProfileDetailsCard p-4 mb-3 mb-lg-4">
            <div className="d-flex justify-content-between">
                <h1 className="fs-16 fw-bold ml-2 mb-3 pb-1">{props.heading}</h1>
                {props.isEditable && (
                    <Button
                        onClick={props.onclick}
                        className={`ProviderProfile__ProfileDetailsCard-controls cursor mr-2 fs-14 fw-bold mb-3 ${
                            props.edit ? "d-none" : ""
                        }`}>
                        Edit
                    </Button>
                )}
            </div>
            <Hr />
            {props.children}
        </div>
    );
};

export default ProfileDetailsCard;
