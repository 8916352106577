import moment from "moment-timezone";

export const ENCOUNTER_INITIAL_VALUES = {
    encounter_date: moment().format("MM/DD/YYYY"),
    discharge_date: "",
    encounter_type: [],
    discharge_type: [],
    setting: [],
    setting_other: "",
    encounter_information: "",
    delete_marker: false,
};

export const ENCOUNTER_SCHEMA = (encounterIndex) => [
    {
        name: `content.encounters[${encounterIndex}].encounter_date`,
        relativeName: "encounter_date",
        elementType: "datePicker",
        elementClasses: "col-12 col-lg-4",
        inputClasses: "Input--sm",
        elementConfig: {
            type: "text",
            label: "Encounter Date",
            placeholder: moment().format("MM/DD/YYYY"),
        },
    },
    {
        name: `content.encounters[${encounterIndex}].discharge_date`,
        relativeName: "discharge_date",
        elementType: "datePicker",
        elementClasses: "col-12 col-lg-4",
        inputClasses: "Input--sm",
        elementConfig: {
            type: "text",
            label: "Discharge Date (if applicable)",
            placeholder: moment().format("MM/DD/YYYY"),
        },
    },
    {
        name: `content.encounters[${encounterIndex}].encounter_type`,
        relativeName: "encounter_type",
        elementType: "input",
        elementClasses: "col-6",
        inputClasses: "Input--sm",
        elementConfig: {
            type: "checkbox",
            label: "Encounter Type",
            options: [
                { value: "Psychiatric", display: "Psychiatric" },
                { value: "Medical", display: "Medical" },
                { value: "SUD", display: "SUD" },
            ],
        },
    },
    {
        name: `content.encounters[${encounterIndex}].discharge_type`,
        relativeName: "discharge_type",
        elementType: "input",
        elementClasses: "col-6",
        inputClasses: "Input--sm",
        elementConfig: {
            type: "checkbox",
            label: "Discharge Type (if applicable)",
            options: [
                { value: "Routine", display: "Routine" },
                { value: "AMA", display: "AMA" },
                { value: "Transfer", display: "Transfer" },
            ],
        },
    },
    {
        name: `content.encounters[${encounterIndex}].setting`,
        relativeName: "setting",
        elementType: "input",
        elementClasses: "col-12",
        inputClasses: "Input--sm",
        elementConfig: {
            type: "checkbox",
            label: "Setting",
            options: [
                { value: "Emergency Room", display: "Emergency Room" },
                { value: "Urgent Care", display: "Urgent Care" },
                { value: "Hospital Admission", display: "Hospital Admission" },
                { value: "Crisis", display: "Crisis" },
                { value: "Other (open text)", display: "Other (open text)", openText: true },
            ],
        },
    },
    {
        name: `content.encounters[${encounterIndex}].encounter_information`,
        elementType: "textarea",
        elementClasses: "col-12",
        elementConfig: {
            type: "textArea",
            label: "Encounter Information",
            placeholder: "Type Comment...",
        },
    },
];
