// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import {isConversationReadOnly} from "../../../../utils/chatUtils";
import ExpandableMessageInput from "../ExpandableMessageInput/ExpandableMessageInput";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

export const ChatFooter = (props) => {
    const {
        channel,
        isInactive,
        sendMessage,
    } = props;
    const profile = useSelector(state => state.auth.profile);

    const renderFooter = () => {
        if (isInactive) {
            return <div className="input-disabled">
                Your account is currently inactive. Looking to send a message?
                Please email us at{" "}
                <a
                    className={"support-email-link"}
                    href={"mailto:support@aptihealth.com"}>
                    support@aptihealth.com
                </a>{" "}
                or call us at{" "}
                <a className={"support-email-link"} href={"tel:8884543827"}>
                    888-454-3827
                </a>
                .{" "}
            </div>;
        } else if (isConversationReadOnly(profile?.care_team_members, channel)) {
            return <div className="input-disabled">
                {channel.otherMembers[0].nickname} is no longer on your care team. You
                can still view old messages, but you can't reply to this thread.
            </div>;
        }
        return <ExpandableMessageInput
            submitHandler={sendMessage}
            channel={channel}
        />;
    };

    return <div className="Messages__Chat__Footer">
        {renderFooter()}
    </div>;
};

ChatFooter.propTypes = {
    channel: PropTypes.object.isRequired,
    isInactive: PropTypes.bool.isRequired,
    sendMessage: PropTypes.func.isRequired,
};
