import _ from "lodash";

export const queryParamObjectToString = queryParamObject => {
    if (!_.isPlainObject(queryParamObject)) {
        return;
    }

    return Object.entries(queryParamObject)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
};

export const buildRequestURLWithQueryParamObject = (baseUrl, queryParamObject) => {
    let queryParamString = queryParamObjectToString(queryParamObject);
    if (queryParamString) {
        return `${baseUrl}?${queryParamString}`;
    } else {
        return baseUrl;
    }
};