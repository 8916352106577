import { useState } from "react";
import { useEffect } from "react";

export const useCalendarDrawerDirection = () => {
    const [direction, setDirection] = useState(window.innerWidth < 1090 ? "bottom" : "right");

    useEffect(() => {
        function handleResize() {
            setDirection(window.innerWidth < 1090 ? "bottom" : "right");
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return direction;
};
