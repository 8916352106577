// Copyright aptihealth, inc. 2021 All Rights Reserved

import React, { useEffect } from "react";
import {
    VerifyInsuranceAccepted,
    VerifyInsuranceDeclined,
} from "../../../../component-library/VerifyInsurance";
import { VerifyInsuranceNotConfigured } from "../../../../component-library/VerifyInsurance/VerifyInsuranceNotConfigured";
import { VerifyInsurancePending } from "../../../../component-library/VerifyInsurance/VerifyInsurancePending";
import { getCarrierMetadata } from "../../../Common/InsuranceEligibility/carrierMetadata";

const MOCK_CARRIER_NAME = "Other";

const Eligibility = (props) => {
    const { prevStep, nextStep, accountData, insuranceEligibilityResponse } = props;
    const insuranceInfo = { ...accountData.primary_insurance };
    const { carrier } = insuranceInfo;
    const carrierMetadata = getCarrierMetadata(
        carrier === MOCK_CARRIER_NAME ? insuranceEligibilityResponse.payer_name : carrier,
    );
    const { logo: carrierLogo, url: carrierUrl } = carrierMetadata;

    useEffect(() => {
        if (!insuranceEligibilityResponse && accountData.interested_in_guardianship) {
            nextStep(true);
        }
    }, []);

    if (!insuranceEligibilityResponse && accountData.interested_in_guardianship) {
        return <></>;
    }

    return (
        <>
            {insuranceEligibilityResponse["status"] === "accepted" && (
                <>
                    <VerifyInsuranceAccepted
                        title={"Great news – you’re covered!"}
                        insuranceName={carrier}
                        planName={insuranceEligibilityResponse["plan"]}
                        copayAmount={insuranceEligibilityResponse["copay"]}
                        onClick={nextStep}
                        insuranceLogo={carrierLogo}
                    />
                </>
            )}
            {insuranceEligibilityResponse["status"] === "declined" && (
                <>
                    <VerifyInsuranceDeclined
                        title={
                            "Unfortunately, your insurance does not cover aptihealth’s services."
                        }
                        prevStep={prevStep}
                        insuranceName={carrier}
                        insuranceURL={carrierUrl}
                        planName={insuranceEligibilityResponse["plan"]}
                    />
                </>
            )}
            {insuranceEligibilityResponse["status"] === "not_configured" && (
                <>
                    <VerifyInsuranceNotConfigured
                        title={"Your insurance still needs to be confirmed."}
                        onClick={nextStep}
                        insuranceName={carrier}
                        planName={insuranceEligibilityResponse["plan"]}
                    />
                </>
            )}
            {insuranceEligibilityResponse["status"] === "pending" && (
                <>
                    <VerifyInsurancePending
                        title={
                            <>
                                Oh no!
                                <br />
                                Something went wrong.
                            </>
                        }
                        onClick={nextStep}
                        insuranceName={carrier}
                        planName={insuranceEligibilityResponse["plan"]}
                    />
                </>
            )}
        </>
    );
};

export default Eligibility;
