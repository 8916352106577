import { getAutomationDataAttr } from "../../../../../utils/automationUtils";
import { noteV2Display } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotes";
import { PatientInfoWidget } from "./PatientInfoWidget";
import React, { useEffect } from "react";

export const Signoff = (componentProps) => {
    const { state, noteEditHandler, noteSubmitHandler, noteDraftSubmitHandler } = componentProps;
    const { patientDetails, progressNote, patientDiagnosis } = state;

    useEffect(() => {
        if (progressNote["call_type"]) {
            noteEditHandler("call_type", "", false);
        }
    }, []);

    let noteDisplay;

    noteDisplay = (
        <div className="m-auto">
            {noteV2Display(
                progressNote,
                true,
                noteEditHandler,
                { createEditView: true, overrideWidth: true },
                state.noteErrors,
            )}
        </div>
    );

    return (
        <div className="GuidedVideoCall__notes_screen">
            <PatientInfoWidget
                patientDetails={patientDetails}
                patientDiagnosis={patientDiagnosis}
                isLarger={true}
            />

            <div className="mt-3 ml-auto mr-auto fw-bold" style={{ maxWidth: "90%" }}>
                {progressNote.title}
            </div>

            {noteDisplay}

            <div className="d-flex justify-content-center my-3 mx-auto">
                <div className="position-relative text-center m-3">
                    <button
                        className="GuidedVideoCall__center-btn fs-14"
                        style={{ backgroundColor: "#5B57DC" }}
                        onClick={() => noteDraftSubmitHandler()}>
                        Save Draft
                    </button>
                </div>

                <div className="position-relative text-center m-3">
                    <button
                        className="GuidedVideoCall__center-btn fs-14"
                        onClick={() => noteSubmitHandler()}
                        {...getAutomationDataAttr("Signoff_signAndSubmit")}>
                        Sign & Submit
                    </button>
                </div>
            </div>
        </div>
    );
};
