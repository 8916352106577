// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Fragment } from "react";
import "./styles.scss";

const MenuPopUp = (props) => {
    const { position, actions } = props;
    const styles = {
        top: position ? `${position.top}px` : "50%",
        left: position ? `${position.left}px` : "50%",
        transform: !position && "translate(-50%,-50%)",
    };
    const mobileStyles = {
        bottom: "0",
        left: "0",
    };

    const desktopView = (
        <div style={styles} className="MenuPopUp position-fixed d-none d-lg-block">
            <ul>
                {Object.entries(actions).map(([action, options]) => {
                    return (
                        <li className="MenuPopUp__li my-3 px-3 cursor" onClick={options.handler}>
                            {options.caption}
                        </li>
                    );
                })}
            </ul>
        </div>
    );

    const mobileView = (
        <div className="Status__Mobile-context-menu-wpr d-block d-lg-none position-fixed">
            <div style={mobileStyles} className="Status__Mobile-context-menu position-absolute">
                {/* actions list */}
                <div className="Status__Mobile-context-menu-action-list-wpr">
                    <ul className="px-4">
                        {Object.entries(actions).map(([action, options]) => {
                            return (
                                <li className="py-3" onClick={options.handler}>
                                    {options.caption}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );

    return (
        <Fragment>
            {desktopView}
            {mobileView}
        </Fragment>
    );
};

export default MenuPopUp;
