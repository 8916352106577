// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import {
    CommentBox,
    DownloadBtn,
    HeaderAttribute,
    HeaderTitle,
    SquashedValues,
} from "../../../components/Common/ReportsView";
import Button from "../../../components/UI/Button";
import { getQueryParams, toTitleCase } from "../../../utils/filters";
import { api } from "../../../APIRequests";
import { severityData } from "../../../components/Provider/Reports/Constants";
import CardHeading from "../../../components/UI/Headings/CardHeading";
import moment from "moment-timezone";
import { connect } from "react-redux";
import "./Styles.scss";
import { setDynamicRouteConfiguration } from "../../../redux/actions/navbar";
import Ratings from "./PatientProfileView/Ratings";
import images from "../../../utils/images";
import "../../../components/UI/BackLink/styles.scss";

class IPSRReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: this.props.match.params.patientId,
            reportData: null,
            showCommentBox: false,
            ReportComment: null,
            commentError: null,
        };
    }
    componentDidMount() {
        const dynamicRouteConfig = {};
        dynamicRouteConfig["menuSection"] = {
            icon: "./icons/back-arrow.svg",
            link: "/app/reports",
        };
        dynamicRouteConfig["logoSection"] = { title: "Report Details" };

        this.props.setDynamicRouteConfiguration(dynamicRouteConfig);
        this.fetchIpsrReport();
    }

    componentWillUnmount() {
        this.props.setDynamicRouteConfiguration({});
        // document.removeEventListener('click', this.onDocumentClick)
    }

    toggleCommentBox = () => {
        this.setState((ps) => {
            return {
                showCommentBox: !ps.showCommentBox,
                ReportComment: null,
                commentError: null,
            };
        });
    };
    submitComment = (e) => {
        e.preventDefault();
        let urlParams = { patientId: this.state.patientId };
        let data = {
            report_type: "IPSR",
            comment: this.state.ReportComment,
        };

        api.provider.set_provider_comment({ data, urlParams }).catch((err) => {
            console.log(err);
        });
        this.toggleCommentBox();
    };

    handleCommentChange = (e) => {
        const value = e.target.value;
        this.setState(
            {
                ReportComment: value,
            },
            this.validateComment,
        );
    };

    handleCommentBlur = () => {
        this.validateComment();
    };

    validateComment = () => {
        let value = this.state.ReportComment;
        if (!value || !value.trim()) {
            this.setState({
                commentError: "Comment is required",
            });
            return;
        }
        this.setState({
            commentError: null,
        });
    };

    fetchIpsrReport = async () => {
        let urlParams = { patientId: this.state.patientId };
        let queryParams = { type: "ipsr" };
        let reports = await api.shared.fetch_report({ urlParams, queryParams });
        this.saveFetchedReportInState(reports);
    };
    saveFetchedReportInState = (reportData) => {
        this.setState({ reportData });
    };

    HeaderCommentBox = () => {
        return (
            <CommentBox
                cancel={this.toggleCommentBox}
                submit={this.submitComment}
                value={this.state.ReportComment}
                onInputChange={this.handleCommentChange}
                onInputBlur={this.handleCommentBlur}
                error={this.state.commentError}
                onInputFocus={this.handleCommentFocus}
                placeholder="Please enter your comment"
            />
        );
    };

    HeaderContent = () => {
        const {
            first_name,
            type,
            last_name,
            report_url,
            modified_at,
            patient_id,
            clinician_name,
            dob,
        } = this.state.reportData;
        const patientFullName = toTitleCase(first_name + " " + last_name);
        /** -----------
         * date of format `YYYY/MM/DD HH:mm:ss`
         * is split into ['YYYY/MM/DD','HH:mm:ss']
         */
        const splittedDate = modified_at.split(" ");
        /** --------- */
        const LocaleModified_time = moment.utc(splittedDate[1], "HH:mm").local().format("hh:mm a");

        return (
            <div className="row no-gutters pb-3" style={{ "border-bottom": "1px solid #B8B8B8" }}>
                {/* patient name */}
                <div className="col-12 mb-2">
                    <HeaderTitle text={patientFullName} />
                </div>
                {/* report type */}
                <div className="col-12 mb-2">
                    <HeaderAttribute
                        attribute="Report Type"
                        value={
                            <SquashedValues
                                valueList={[type, splittedDate[0], LocaleModified_time]}
                            />
                        }
                    />
                </div>
                {/* Clinician Name */}
                <div className="col-12 mb-2">
                    <HeaderAttribute attribute="Clinician Name" value={clinician_name} />
                </div>
                {/* DOB */}
                <div className="col-12 col-lg-6 mb-2 mb-lg-0">
                    <HeaderAttribute attribute="Date of Birth" value={dob} />
                </div>
                {/* header controls/actions */}
                <div className="IBARReport__HeaderControls col-12 col-lg-6 d-flex justify-content-lg-end">
                    <div className="mr-2 mr-lg-3">
                        <DownloadBtn url={report_url} />
                    </div>
                    <Button
                        onClick={this.toggleCommentBox}
                        style={{ "min-height": "35px", padding: "4px 20px" }}
                        className="Btn fw-300 fs-14 Btn--pri">
                        Add Provider Comment
                    </Button>
                </div>
            </div>
        );
    };

    renderRatings = () => {
        return (
            <div className="row no-gutters mt-4">
                {severityData.map((level) => {
                    return (
                        <div className="Reports__widget-wpr col-6 col-lg">
                            <label className="Reports__label m-0">
                                <span className={"Reports__mask"} />
                                <span className="Reports__value">{level.value}</span>
                                <span className="SCQBox__caption fs-12 fs-lg-14">
                                    {level.caption}
                                </span>
                            </label>
                        </div>
                    );
                })}
            </div>
        );
    };

    render() {
        let backLinkText = "Back To Reports";
        if (getQueryParams("patient-id", this.props.location.search)) {
            backLinkText = "Back To Patient Profile";
        }

        let content = (
            <div className="IPSRReport container py-5">
                <div className="d-none d-lg-block mb-4">
                    <div onClick={() => this.props.history.goBack()} className="BackLink cursor">
                        <img src={images("./icons/solidBckBtn.svg")} alt="back btn" />
                        <span>{backLinkText}</span>
                    </div>
                </div>
                <header className="IPSRReport__Header">
                    <this.HeaderContent />
                </header>
                {this.state.showCommentBox && (
                    <section className="mt-3">
                        <this.HeaderCommentBox />
                    </section>
                )}
                <div className="Reports-card my-4 mx-auto">
                    <ol className="mx-md-2 p-0 px-md-4 pt-md-4 pb-md-5 mb-0">
                        {this.state.reportData &&
                            renderIPSRQuestions(this.state.reportData.report_data)}
                    </ol>
                </div>
                <Ratings
                    {...this.props}
                    excludeName={true}
                    excludeBackLink={true}
                    isNestedComponent={true}
                />
            </div>
        );
        return this.state.reportData && content;
    }
}

export default connect(null, {
    setDynamicRouteConfiguration,
})(IPSRReport);

export const renderIPSRQuestions = (report_data) => {
    return report_data.sections.map((reportTitle) => {
        return reportTitle.type === "list" ? (
            <li className="mt-lg-5 mt-1">
                <CardHeading
                    text={toTitleCase(reportTitle.title)}
                    className="fs-18 d-inline-block"
                />
                <ul className="row IPSR__list-wpr">
                    {reportTitle.context.map((content) => {
                        return (
                            <li className="col-12 col-lg-6 IPSR__list-content pl-4 pl-lg-5">
                                {content}
                            </li>
                        );
                    })}
                </ul>
            </li>
        ) : (
            <li className="mt-lg-5 mt-1">
                <CardHeading text={reportTitle.title} className="fs-18 d-inline-block" />
                <p className="IPSR__list-content">{reportTitle.content}</p>
            </li>
        );
    });
};
