// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";

const CardPrimary = (props) => {
    let classes = "CardPrimary" + " " + (props.className || "");
    let styles = props.styles || {};
    return (
        <div className={classes} style={styles}>
            {props.children}
        </div>
    );
};

export default CardPrimary;
