import React, { useEffect, useState } from "react";
import _ from "lodash";
import PostSessionSurvey from "./PostSessionSurvey";
import { connect } from "react-redux";
import VideoCallRating from "../../../../components/Common/VideoCallRating";
import { getQueryParams } from "../../../../utils/filters";
import { api } from "../../../../APIRequests";
import { toastMessageTypes } from "../../../../redux/actions/toaster";

const PostSessionSurveyWrapper = (props) => {
    const [userConfigs, setUserConfigs] = useState(props.configs);
    const [questionSet, setQuestionSet] = useState(undefined);
    const isPostSessionSurveyFeatureEnabled = _.get(userConfigs, "POST_SESSION_SURVEY", false);
    const callId = getQueryParams("callId", props.location.search);

    const errorTypeConfig = {
        MultipleSubmissionException: {
            toastErrorType: toastMessageTypes.warning,
            errorCallback: () => props.history.push("/app/home"),
        },
    };

    useEffect(() => {
        (async () => {
            const question_set = await api.shared.get_post_session_survey_question_set(
                callId,
                errorTypeConfig,
            );
            setQuestionSet(question_set);
        })();
    }, []);

    useEffect(() => {
        setUserConfigs(props.configs);
    }, [props.configs]);

    if (!questionSet) {
        return <></>;
    }

    return isPostSessionSurveyFeatureEnabled ? (
        <PostSessionSurvey {...props} questionSet={questionSet[0]} callId={callId} />
    ) : (
        <VideoCallRating {...props} />
    );
};

const mapStateToProps = (state) => {
    return {
        configs: state.configs,
        authRedirectPath: state.auth.authRedirectPath,
    };
};

export default connect(mapStateToProps, {})(PostSessionSurveyWrapper);
