import React from "react";
import { MemberProfileGoalsAndObjectiveNoteDisplayV2 } from "./MemberProfileGoalsAndObjectiveNoteDisplayV2";
import { BehavioralGoals } from "../MemberProfileNotesBehavioralGoals";
import {
    getCareGoalAndObjectiveNoteVersion,
    getCareGoalAndObjectivesFromNote,
} from "../../../../Common/GuidedVideoCall/Steps/ProviderWorkflow/WorkflowFunctions";

/**
 * Displays the goals and objectives or old care goals
 * in notes and the guided session
 */
export const MemberProfileNoteGoalsAndObjectiveDisplay = ({
    note,
    content,
    editable,
    editHandler,
    profileView,
    draftView,
}) => {
    const version = getCareGoalAndObjectiveNoteVersion(content);
    const goalAndObjectiveProgress = getCareGoalAndObjectivesFromNote(content, version);

    if (!goalAndObjectiveProgress) {
        return <></>;
    }

    return (
        <>
            {version === "1" && (
                <BehavioralGoals
                    note={note}
                    editable={editable}
                    editHandler={editHandler}
                    profileView={profileView}
                />
            )}
            {/* hide the display if there are no goals and objectives */}
            {version === "2" && Object.keys(goalAndObjectiveProgress).length !== 0 && (
                <MemberProfileGoalsAndObjectiveNoteDisplayV2
                    editable={editable}
                    goalAndObjectiveProgress={goalAndObjectiveProgress}
                    editHandler={editHandler}
                    profileView={profileView}
                    draftView={draftView}
                />
            )}
        </>
    );
};
