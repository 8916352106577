// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import withPortal from "../../../hoc/withPortal";

const FAB = (props) => {
    return (
        <div className="position-fixed">
            <button type="button" className="ASCH__FAB">
                wfwed
            </button>
        </div>
    );
};

export default withPortal(FAB);
