import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";

export const PageBreak = ({ className, color, ...props }) => (
    <div
        className={createClassNameString(["apti-CL", "apti-PageBreak", className, color])}
        {...props}>
        &nbsp;
    </div>
);

PageBreak.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(["black", "grey"]),
};

PageBreak.defaultProps = {
    className: undefined,
    color: "black",
};
