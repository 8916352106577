/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const MessagesIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Messages"
                {...props}>
                <path d="M8.5 14.5C9.32843 14.5 10 13.8284 10 13C10 12.1716 9.32843 11.5 8.5 11.5C7.67157 11.5 7 12.1716 7 13C7 13.8284 7.67157 14.5 8.5 14.5Z" />
                <path d="M13 14.5C13.8284 14.5 14.5 13.8284 14.5 13C14.5 12.1716 13.8284 11.5 13 11.5C12.1716 11.5 11.5 12.1716 11.5 13C11.5 13.8284 12.1716 14.5 13 14.5Z" />
                <path d="M19 13C19 13.8284 18.3284 14.5 17.5 14.5C16.6716 14.5 16 13.8284 16 13C16 12.1716 16.6716 11.5 17.5 11.5C18.3284 11.5 19 12.1716 19 13Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.4491 20H19C20.0752 20 21.0668 19.4725 21.7696 18.7696C22.4725 18.0668 23 17.0752 23 16V10C23 8.92477 22.4725 7.93324 21.7696 7.23039C21.0668 6.52755 20.0752 6 19 6H7.00013C5.9249 6 4.93324 6.52755 4.23039 7.23039C3.52755 7.93324 3 8.92477 3 10V16C3 17.0752 3.52755 18.0668 4.23039 18.7696C5 19.5392 6 20 7 20V23.5C7 23.9152 7.25654 24.2872 7.64462 24.4347C8.0327 24.5823 8.47158 24.4747 8.74741 24.1644L12.4491 20ZM11.5509 18H19C19.4232 18 19.9319 17.7789 20.3554 17.3554C20.7789 16.9319 21 16.4232 21 16V10C21 9.57677 20.7789 9.06811 20.3554 8.64461C19.9319 8.2211 19.4232 8 19 8H7.00013C6.57683 8 6.06805 8.22116 5.64461 8.64461C5.2211 9.06811 5 9.57677 5 10V16C5 16.4232 5.2211 16.9319 5.64461 17.3554C6.10044 17.8112 6.61125 18 7 18H9V20.8698L11.5509 18Z"
                />
                <path d="M16 21H14V22C14 23.0752 14.5275 24.0668 15.2304 24.7696C15.9332 25.4725 16.9248 26 18 26H22.0858L24.2929 28.2071C24.5789 28.4931 25.009 28.5787 25.3827 28.4239C25.7564 28.2691 26 27.9045 26 27.5V25.8494C26.6804 25.6443 27.2917 25.2475 27.7696 24.7696C28.4725 24.0668 29 23.0752 29 22V20C29 18.9248 28.4725 17.9332 27.7696 17.2304C27.0668 16.5275 26.0752 16 25 16H24V18H25C25.4232 18 25.9319 18.2211 26.3554 18.6446C26.7789 19.0681 27 19.5768 27 20V22C27 22.4232 26.7789 22.9319 26.3554 23.3554C26.0787 23.6321 25.7462 23.837 25.4228 23.9345C24.7957 24.1235 24.3155 24.6011 24.1098 25.1955L23.5 24.5858C23.1249 24.2107 22.6162 24 22.0858 24H18C17.5768 24 17.0681 23.7789 16.6446 23.3554C16.2211 22.9319 16 22.4232 16 22V21Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Messages"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19 6C20.0752 6 21.0668 6.52755 21.7696 7.23039C22.4725 7.93324 23 8.92477 23 10V16C23 17.0752 22.4725 18.0668 21.7696 18.7696C21.0668 19.4725 20.0752 20 19 20H12.4491L8.74741 24.1644C8.47158 24.4747 8.0327 24.5823 7.64462 24.4347C7.25654 24.2872 7 23.9152 7 23.5V20C6 20 5 19.5392 4.23039 18.7696C3.52755 18.0668 3 17.0752 3 16V10C3 8.92477 3.52755 7.93324 4.23039 7.23039C4.93324 6.52755 5.9249 6 7.00013 6H19ZM10 13C10 13.8284 9.32843 14.5 8.5 14.5C7.67157 14.5 7 13.8284 7 13C7 12.1716 7.67157 11.5 8.5 11.5C9.32843 11.5 10 12.1716 10 13ZM14.5 13C14.5 13.8284 13.8284 14.5 13 14.5C12.1716 14.5 11.5 13.8284 11.5 13C11.5 12.1716 12.1716 11.5 13 11.5C13.8284 11.5 14.5 12.1716 14.5 13ZM17.5 14.5C18.3284 14.5 19 13.8284 19 13C19 12.1716 18.3284 11.5 17.5 11.5C16.6716 11.5 16 12.1716 16 13C16 13.8284 16.6716 14.5 17.5 14.5Z"
                />
                <path d="M19 22H14C14 23.0752 14.5275 24.0668 15.2304 24.7696C15.9332 25.4725 16.9248 26 18 26H22.0858L24.2929 28.2071C24.5789 28.4931 25.009 28.5787 25.3827 28.4239C25.7564 28.2691 26 27.9045 26 27.5V25.8494C26.6804 25.6443 27.2917 25.2475 27.7696 24.7696C28.4725 24.0668 29 23.0752 29 22V20C29 18.9248 28.4725 17.9332 27.7696 17.2304C27.0668 16.5275 26.0752 16 25 16C25 19.3137 22.3137 22 19 22Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Messages"
                {...props}>
                <path d="M14.375 18.875C14.9963 18.875 15.5 18.3713 15.5 17.75C15.5 17.1287 14.9963 16.625 14.375 16.625C13.7537 16.625 13.25 17.1287 13.25 17.75C13.25 18.3713 13.7537 18.875 14.375 18.875Z" />
                <path d="M17.75 18.875C18.3713 18.875 18.875 18.3713 18.875 17.75C18.875 17.1287 18.3713 16.625 17.75 16.625C17.1287 16.625 16.625 17.1287 16.625 17.75C16.625 18.3713 17.1287 18.875 17.75 18.875Z" />
                <path d="M22.25 17.75C22.25 18.3713 21.7463 18.875 21.125 18.875C20.5037 18.875 20 18.3713 20 17.75C20 17.1287 20.5037 16.625 21.125 16.625C21.7463 16.625 22.25 17.1287 22.25 17.75Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM24.3272 13.4228C23.8001 12.8957 23.0564 12.5 22.25 12.5H13.2501C12.4437 12.5 11.6999 12.8957 11.1728 13.4228C10.6457 13.9499 10.25 14.6936 10.25 15.5V20C10.25 20.8064 10.6457 21.5501 11.1728 22.0772C11.75 22.6544 12.5 23 13.25 23V25.625C13.25 25.9364 13.4424 26.2154 13.7335 26.326C14.0245 26.4367 14.3537 26.356 14.5606 26.1233L17.3368 23H22.25C23.0564 23 23.8001 22.6043 24.3272 22.0772C24.8543 21.5501 25.25 20.8064 25.25 20V15.5C25.25 14.6936 24.8543 13.9499 24.3272 13.4228ZM18.5 24.5H22.25C24.7353 24.5 26.75 22.4853 26.75 20C27.5564 20 28.3001 20.3957 28.8272 20.9228C29.3543 21.4499 29.75 22.1936 29.75 23V24.5C29.75 25.3064 29.3543 26.0501 28.8272 26.5772C28.4688 26.9356 28.0103 27.2333 27.5 27.3871V28.625C27.5 28.9283 27.3173 29.2018 27.037 29.3179C26.7568 29.434 26.4342 29.3698 26.2197 29.1553L24.5643 27.5H21.5C20.6936 27.5 19.9499 27.1043 19.4228 26.5772C18.8957 26.0501 18.5 25.3064 18.5 24.5Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

MessagesIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

MessagesIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
