// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import "./styles.scss";
import { createClassNameString } from "../../../component-library";

const Hr = ({ className }) => {
    return <hr className={createClassNameString(["Hr m-0", className])} />;
};

export default Hr;
