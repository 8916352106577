import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Badge from "../../UI/Badge";
import Button from "../../UI/Button";
import { api } from "../../../APIRequests";
import _ from "lodash";
import "./styles.scss";
import { createClassNameString } from "../../../component-library/utils";
import { ChevronLeftIcon, ChevronRightIcon } from "../../../component-library";
import { ICON_SIZES, ICON_TYPES } from "../../../constants/icons";

const adjustSlot = (timeSlots, maxDaySize) => {
    let acc = [];
    for (const i of Array(maxDaySize).keys()) {
        if (i < timeSlots.length) {
            acc.push(timeSlots[i]);
        } else {
            acc.push(null);
        }
    }

    return acc;
};

const MakeTimeSlotButton = ({
    timeSlotData,
    selectedAppointment,
    setSelectedAppointment,
    hourlyGrid,
}) => {
    if (timeSlotData == null) {
        return (
            <div className="my-2">
                <Button
                    type={"button"}
                    className={"Btn-xs Btn--otl-gry time-picker-day-btn"}
                    onClick={() => {}}
                    style={{
                        fontSize: "15px",
                        width: "125px",
                        borderRadius: "5px",
                        borderColor: "white",
                    }}>
                    {"-"}
                </Button>
            </div>
        );
    } else {
        const startTime = moment(timeSlotData.start_time, "HH:mm").format("h:mm");
        const endTime = moment(timeSlotData.end_time, "HH:mm").format("h:mm a");
        const slotIsCurrentlySelected =
            timeSlotData === selectedAppointment ? "Btn--sec text-white" : "Btn--otl-gry";
        const mobileOrDesktopBtns = hourlyGrid ? "mobile-btns" : "regular-btns";
        const btnClasses = `btn-base Btn Btn-xs ${slotIsCurrentlySelected} ${mobileOrDesktopBtns} time-picker-day-btn`;

        return (
            <div className="my-2">
                <Button
                    type={"button"}
                    className={btnClasses}
                    onClick={() => {
                        setSelectedAppointment(timeSlotData);
                    }}>
                    {`${startTime}-${endTime}`}
                </Button>
            </div>
        );
    }
};

const Day = ({
    oneDayData,
    maxDaySize,
    selectedAppointment,
    setSelectedAppointment,
    hourlyGrid,
}) => {
    const adjustedSlots = adjustSlot(oneDayData.slots, maxDaySize);
    return (
        <div
            className={"mb-3 calendar-col"}
            style={{ textAlign: "center" }}
            key={`${oneDayData.month_of_year}-${oneDayData.day}-col`}>
            {!hourlyGrid && (
                <>
                    <div className="row margin-center">
                        <div className={"txt-gry fs-14 margin-center"}>
                            {oneDayData.day_of_week}
                        </div>
                    </div>
                    <div className="row margin-center">
                        <div
                            className={
                                "fw-bold fs-md-16 fs-14 margin-center"
                            }>{`${oneDayData.month_of_year} ${oneDayData.day}`}</div>
                    </div>
                </>
            )}

            <br />
            <div className={hourlyGrid ? "display-grid__mobile" : ""}>
                {adjustedSlots.map((slot) => (
                    <MakeTimeSlotButton
                        timeSlotData={slot}
                        selectedAppointment={selectedAppointment}
                        setSelectedAppointment={setSelectedAppointment}
                        hourlyGrid={hourlyGrid}
                    />
                ))}
            </div>
        </div>
    );
};

const setOrGetMoreDaysThenSet = async (
    updatedIndex,
    schedulingData,
    setDayIndex,
    setSchedulingData,
    patientDetails,
    numDaysToShow,
    allowGetMoreDays,
    startDateRange,
    setStartDateRange,
) => {
    const patientId = _.get(patientDetails, "username", null);
    const overrideMeetingDuration = _.get(patientDetails, "override_meeting_duration");
    const browserTimezone = moment.tz.guess();

    if (!allowGetMoreDays || updatedIndex < schedulingData.length - 1 - numDaysToShow) {
        setStartDateRange(moment(startDateRange).add(numDaysToShow, "days").toDate());
        setDayIndex(updatedIndex);
    } else {
        const scheduleLastDay = schedulingData[schedulingData.length - 1];
        let lastDay = "";
        if (scheduleLastDay) {
            lastDay = `${scheduleLastDay.year}-${scheduleLastDay.month}-${scheduleLastDay.day}`;
        } else {
            lastDay = moment(startDateRange)
                .add(numDaysToShow - 1, "days")
                .format("YYYY-MM-DD");
        }
        const payload = {
            id: patientId,
            timezone: browserTimezone,
            last_date: lastDay,
        };
        if (overrideMeetingDuration != null) {
            payload["override_meeting_duration"] = overrideMeetingDuration;
        }
        const result = await api.patient.get_additional_days_for_patient_appointments({
            data: payload,
        });
        const updatedData = schedulingData.concat(result);
        setSchedulingData(updatedData);
        setDayIndex(updatedIndex);
        setStartDateRange(moment(startDateRange).add(numDaysToShow, "days").toDate());
    }
};

const DayScroll = ({
    dayIndex,
    setDayIndex,
    schedulingData,
    setSchedulingData,
    patientDetails,
    numDaysToShow,
    maxDayIndex,
    allowGetMoreDays,
    disableNextArrow,
    hourlyGrid,
    hideDateRange,
    startDateRange,
    setStartDateRange,
}) => {
    let endDateRangeText =
        numDaysToShow === 1
            ? ""
            : moment(startDateRange)
                  .add(numDaysToShow - 1, "days")
                  .format("MMMM DD, YYYY");
    let startDateRangeText =
        numDaysToShow === 1
            ? moment(startDateRange).format("MMMM DD, YYYY")
            : moment(startDateRange).format("MMMM DD - ");
    if (schedulingData.length > 0) {
        const startDay = schedulingData[dayIndex];
        if (startDay) {
            startDateRangeText =
                numDaysToShow === 1
                    ? `${startDay.month_of_year} ${startDay.day}, ${startDay.year}`
                    : `${startDay.month_of_year} ${startDay.day} - `;
        }
        if (numDaysToShow > 1 && dayIndex !== dayIndex + numDaysToShow - 1) {
            const endDay = schedulingData[dayIndex + numDaysToShow - 1];
            if (endDay) {
                endDateRangeText = `${endDay.month_of_year} ${endDay.day}, ${endDay.year}`;
            }
        }
    }
    const previousArrow = dayIndex > 0 ? "visible" : "hidden";

    let nextArrow;
    if (disableNextArrow) {
        nextArrow = "hidden";
    } else {
        nextArrow = maxDayIndex === null || dayIndex < maxDayIndex ? "visible" : "hidden";
    }

    return (
        <div className="calendar-day-controls">
            <div
                className={
                    hourlyGrid ? "d-flex align-items-center mt-3" : "d-flex align-items-center"
                }
                style={{ visibility: previousArrow }}>
                <Button
                    type={"button"}
                    className={"Btn--otl-gry chevron-btn"}
                    style={{ borderColor: "white" }}
                    onClick={() => {
                        let index = dayIndex - numDaysToShow;
                        if (index < 0) {
                            index = 0;
                        }
                        setStartDateRange(
                            moment(startDateRange).subtract(numDaysToShow, "days").toDate(),
                        );
                        setDayIndex(index);
                    }}>
                    <ChevronLeftIcon iconType={ICON_TYPES.OUTLINE} iconSize={ICON_SIZES.MEDIUM} />
                </Button>
            </div>
            <div className={hourlyGrid ? "mt-3" : ""}>
                <div
                    className={
                        hourlyGrid ? "fw-bold fs-md-16 fs-14 pt-1" : "fw-bold fs-md-16 fs-14 p-3"
                    }>
                    {!hideDateRange && startDateRangeText && (
                        <>
                            {startDateRangeText}
                            {endDateRangeText && (
                                <span style={{ whiteSpace: "nowrap" }}>{endDateRangeText}</span>
                            )}
                        </>
                    )}
                </div>
            </div>
            <div
                className={hourlyGrid ? "mt-3" : "d-flex align-items-center"}
                style={{ visibility: nextArrow }}>
                <Button
                    type={"button"}
                    className={"Btn--otl-gry chevron-btn"}
                    style={{ borderColor: "white" }}
                    onClick={() => {
                        setOrGetMoreDaysThenSet(
                            dayIndex + numDaysToShow,
                            schedulingData,
                            setDayIndex,
                            setSchedulingData,
                            patientDetails,
                            numDaysToShow,
                            allowGetMoreDays,
                            startDateRange,
                            setStartDateRange,
                        );
                    }}>
                    <ChevronRightIcon iconType={ICON_TYPES.OUTLINE} iconSize={ICON_SIZES.MEDIUM} />
                </Button>
            </div>
        </div>
    );
};

// For more information about scheduling data,
// Check out: get_upcoming_intake_availability
export const AppointmentTimePicker = ({
    schedulingData,
    setSchedulingData,
    todaysDate,
    patientDetails,
    selectedAppointment,
    setSelectedAppointment,
    className = "",
    numDaysToShow = 4,
    maxHeight = 250,
    maxDayIndex = 4,
    allowGetMoreDays = true,
    disableNextArrow,
    hourlyGrid = false,
    gridClassName,
    calendarBodyClassName,
    hideDateRange,
}) => {
    const [startDateRange, setStartDateRange] = useState(new Date());
    const [dayIndex, setDayIndex] = useState(0);
    //if the window size resets, reset the day index to 0 to avoid showing partial date range
    //on non-mobile widths (over 600px)
    useEffect(() => {
        window.addEventListener("resize", () => {
            if (window.innerWidth > 600) {
                setDayIndex(0);
            }
        });
    });
    const daysToShow = [...schedulingData].slice(dayIndex, dayIndex + numDaysToShow);
    const hasDate = daysToShow.every((day) => day != null);
    const daySizes = daysToShow.map((day) => (day && day.slots ? day.slots.length : 0));

    let maxDaySize = Math.max(...daySizes);

    if (maxDaySize === 0) {
        maxDaySize = 6;
    }
    return (
        <>
            <div data-public={"true"}>
                <div className={className + "workflow-intake-scheduling"}>
                    <div
                        className={createClassNameString([
                            hourlyGrid ? "pt-4" : "pt-4 px-4",
                            gridClassName,
                        ])}>
                        <div className="row fw-bold current-date-badge">
                            <Badge
                                type="light-grey"
                                style={{
                                    width: "unset",
                                    height: "unset",
                                    margin: "0 auto",
                                    padding: "3px",
                                }}
                                classes="px-2"
                                text={
                                    <>
                                        {`Today's Date: ${todaysDate.day_of_week}, `}
                                        <span style={{ whiteSpace: "nowrap" }}>
                                            {`${todaysDate.month_of_year} ${todaysDate.day}, ${todaysDate.year}`}
                                        </span>
                                    </>
                                }
                            />
                        </div>
                        {hasDate && daysToShow && (
                            <DayScroll
                                startDateRange={startDateRange}
                                dayIndex={dayIndex}
                                setDayIndex={setDayIndex}
                                schedulingData={schedulingData}
                                setSchedulingData={setSchedulingData}
                                patientDetails={patientDetails}
                                numDaysToShow={numDaysToShow}
                                maxDayIndex={maxDayIndex}
                                allowGetMoreDays={allowGetMoreDays}
                                disableNextArrow={disableNextArrow}
                                hourlyGrid={hourlyGrid}
                                setStartDateRange={setStartDateRange}
                                hideDateRange={hideDateRange}
                            />
                        )}

                        {daysToShow.length > 0 && (
                            <div
                                id={"calendar-body"}
                                className={createClassNameString([
                                    hourlyGrid
                                        ? ""
                                        : "d-flex " +
                                          (numDaysToShow === 1
                                              ? "justify-content-center"
                                              : "justify-content-between"),
                                    calendarBodyClassName,
                                ])}
                                style={{ overflowY: "scroll", maxHeight: maxHeight }}>
                                {daysToShow.map((dayData) => (
                                    <Day
                                        key={`${dayData.month}_${dayData.day}_${dayData.year}`}
                                        oneDayData={dayData}
                                        maxDaySize={maxDaySize}
                                        selectedAppointment={selectedAppointment}
                                        setSelectedAppointment={setSelectedAppointment}
                                        hourlyGrid={hourlyGrid}
                                    />
                                ))}
                            </div>
                        )}
                        {daysToShow.length === 0 && (
                            <div
                                id={"calendar-body"}
                                className="d-flex justify-content-center align-items-center"
                                style={{ height: maxHeight - 100 }}>
                                <p className="text-center">
                                    No sessions available in this date range.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
