// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import SchedulesAvailable from "./SchedulesAvailable";
import ScheduleInactive from "./ScheduleInactive";
import SchedulePage from "./SchedulePage";
class ScheduleRouter extends Component {
    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Route path={`${match.path}/available`} component={SchedulesAvailable} />
                <Route path={`${match.path}/assign`} component={SchedulePage} />
                <Route path={`${match.path}/inactive`} component={ScheduleInactive} />
                <Redirect to={`${match.path}/available`} />
            </Switch>
        );
    }
}

export default ScheduleRouter;
