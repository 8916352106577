import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { createClassNameString } from "../../component-library";
import { ICON_SIZES, ICON_TYPES } from "../../constants/icons";

export const LockIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}>
                <path d="M17 17C17 16.4477 16.5523 16 16 16C15.4477 16 15 16.4477 15 17V21C15 21.5523 15.4477 22 16 22C16.5523 22 17 21.5523 17 21V17Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 9V12H8C6.89543 12 6 12.8954 6 14V24C6 25.1046 6.89543 26 8 26H24C25.1046 26 26 25.1046 26 24V14C26 12.8954 25.1046 12 24 12H22V9C22 7.27275 21.4199 5.75567 20.3321 4.66789C19.2443 3.58011 17.7272 3 16 3C14.2728 3 12.7557 3.58011 11.6679 4.66789C10.5801 5.75567 10 7.27275 10 9ZM13.0821 6.08211C12.4199 6.74433 12 7.72725 12 9V12H20V9C20 7.72725 19.5801 6.74433 18.9179 6.08211C18.2557 5.41989 17.2728 5 16 5C14.7272 5 13.7443 5.41989 13.0821 6.08211ZM24 24V14H8L8 24H24Z"
                />
            </svg>
        );
    }

    return null;
};

LockIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

LockIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
