import * as yup from "yup";
import { STATES } from "../../../constants/states";

export const A5_COMPLETE_INITIAL_VALUES = {
    email: "",
    email_validation: "",
    mobile: "",
    address: {
        street: "",
        city: "",
        state: "",
        zip_code: "",
    },
    pcp_name: "",
};

export const A5_COMPLETE_SCHEMA = [
    {
        name: "email",
        elementType: "input",
        elementConfig: {
            type: "email",
            label: "Email",
            placeholder: "Email",
        },
    },
    {
        name: "email_validation",
        elementType: "input",
        elementConfig: {
            type: "email",
            label: "Re-Enter Email",
            placeholder: "Email",
        },
    },
    {
        name: "mobile",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Mobile",
            placeholder: "Mobile",
        },
    },
    {
        name: "address.street",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Street Address",
            placeholder: "Enter Street",
        },
    },
    {
        name: "address.city",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "City",
            placeholder: "Enter City",
        },
    },
    {
        name: "address.state",
        elementType: "select",
        elementConfig: {
            options: STATES,
            label: "State",
            placeholder: "Enter State",
        },
    },
    {
        name: "address.zip_code",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Zip",
            placeholder: "Enter zip",
        },
    },
    {
        name: "pcp_name",
        elementType: "select",
        elementConfig: {
            options: [],
            label: "Your physician",
            placeholder: "Your physician",
        },
    },
];

export const A5_COMPLETED_VALIDATION = yup.object().shape({
    email: yup.string().email("Enter a valid email address").required("Email is required"),
    email_validation: yup
        .string()
        .email("Enter a valid email address")
        .required("Email is required")
        .test("email-match", "Address Do Not Match. Please Check Email Spelling", function (value) {
            if (!value) {
                return false;
            }
            return this.parent.email === value;
        }),
    mobile: yup
        .string()
        .matches(/^\d{10}$/, "Contact Number must be a valid US number")
        .required("Mobile no. is required"),
    address: yup.object().shape({
        street: yup.string().required("Street is required"),
        city: yup.string().required("City is required"),
        state: yup.string().required("State is required"),
        zip_code: yup.string().required("Zip Code is required"),
    }),
    pcp_name: yup.string().required("PCP Name is required"),
});
