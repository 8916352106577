import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";
import { Card } from "../Card";
import { Button } from "../Button";
import { pluralizeWord } from "../../utils/languageGrammarUtils";
import _ from "lodash";
import { buildErrorMessages } from "./utils";
import { getAutomationDataAttr } from "../../utils/automationUtils";

export const InsuranceEligibilityRecord = ({
    insuranceType,
    status,
    copay,
    timestamp,
    statusDefinition,
    numberOfAttempts,
    transactionId,
    className,
    allowRetry,
    retryButtonOnClick,
    carrier,
    errors,
    plan,
    showDownloadRawResponse,
    rawResponseKey,
    downloadResponseButtonOnClick,
    ...props
}) => (
    <div>
        <Card className={createClassNameString(["insurance-eligibility-status-card", className])}>
            <div>
                <span>
                    <span className={"fw-bold"}>Insurance Company:</span> {carrier}
                </span>
            </div>
            <div>
                <span>
                    <span className={"fw-bold"}>Plan:</span> {plan}
                </span>
            </div>
            <div>
                <span {...getAutomationDataAttr("InsuranceEligibilityRecordStatus")}>
                    <span className={"fw-bold"}>Eligibility:</span> {insuranceType}: {status}{" "}
                    {timestamp ? "- " : ""}
                    {timestamp}
                </span>
            </div>
            <div>
                <span>
                    <span className={"fw-bold"}>Definition:</span> {statusDefinition}
                </span>
            </div>
            {copay !== null && copay !== undefined ? (
                <div>
                    <span>
                        <span className={"fw-bold"}>Copay:</span> {copay}
                    </span>
                </div>
            ) : (
                <></>
            )}
            <div>
                <span>
                    <span className={"fw-bold"}>No. of Attempts:</span> {numberOfAttempts}
                </span>
            </div>
            <div>
                <span>
                    <span className={"fw-bold"}>Control Number:</span> {transactionId}
                </span>
            </div>
            {Array.isArray(errors) && errors.length > 0 && (
                <span>
                    <span className={"fw-bold"}>{pluralizeWord(errors, "Error")}:</span>
                    <uL className={"errors-list"}>
                        {buildErrorMessages(errors).map((message) => {
                            return <li className={"error"}>{message}</li>;
                        })}
                    </uL>
                </span>
            )}
            <div className={"d-flex justify-content-end"}>
                {allowRetry && status === "Pending" && (
                    <Button
                        buttonType={"primary"}
                        onClick={retryButtonOnClick}
                        {...getAutomationDataAttr("RetryEligibilityCheckButton")}>
                        Retry Eligibility Check
                    </Button>
                )}
                {showDownloadRawResponse && rawResponseKey && (
                    <Button
                        buttonType={"secondary"}
                        onClick={downloadResponseButtonOnClick}
                        {...getAutomationDataAttr("DownloadRawResponseButton")}>
                        Download Raw Response
                    </Button>
                )}
            </div>
        </Card>
    </div>
);

InsuranceEligibilityRecord.propTypes = {
    insuranceType: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    copay: PropTypes.number,
    timestamp: PropTypes.string.isRequired,
    statusDefinition: PropTypes.string.isRequired,
    numberOfAttempts: PropTypes.number.isRequired,
    transactionId: PropTypes.string.isRequired,
    className: PropTypes.string,
    allowRetry: PropTypes.bool.isRequired,
    retryButtonOnClick: PropTypes.func,
    carrier: PropTypes.string.isRequired,
    plan: PropTypes.string,
    errors: PropTypes.array,
    showDownloadRawResponse: PropTypes.bool,
    rawResponseKey: PropTypes.string,
    downloadResponseButtonOnClick: PropTypes.func,
};

InsuranceEligibilityRecord.defaultProps = {
    insuranceType: "Primary",
    status: "",
    copay: null,
    timestamp: "",
    statusDefinition: "",
    numberOfAttempts: "",
    transactionId: "",
    className: undefined,
    allowRetry: false,
    carrier: "",
    plan: "",
    errors: [],
    retryButtonOnClick: () => {},
    showDownloadRawResponse: false,
    rawResponseKey: "",
    downloadResponseButtonOnClick: () => {},
};
