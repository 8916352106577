// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import Button from "../../../UI/Button";

const CommentBox = (props) => {
    const { submit, cancel, placeholder, value, onInputChange, error, onInputBlur } = props;
    const disableSubmit = !value || !value.trim() ? true : false;

    return (
        <div className="ReportsCommentBox w-100">
            <form onSubmit={submit}>
                <label className="ReportsCommentBox__label d-block fw-bold fs-14">
                    Provider Comment
                </label>
                <textarea
                    className="ReportsCommentBox__textarea w-100 fs-16 fs-bold"
                    name="IBARComment"
                    rows="3"
                    onChange={onInputChange}
                    onBlur={onInputBlur}
                    placeholder={placeholder}
                />
                {error && <div className="ReportsCommentBox__textarea-error">{error}</div>}
                <div className="d-flex mt-2 justify-content-around justify-content-lg-end">
                    <Button onClick={cancel} className="Btn mx-lg-2 Btn--otl-pri">
                        Cancel
                    </Button>
                    <Button disabled={disableSubmit} type="submit" className="Btn mx-lg-2 Btn--pri">
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default CommentBox;
