// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import "./styles.scss";
import { toTitleCase, searchAndReplace } from "../../../utils/filters";
import images from "../../../utils/images";
import PatientProfileInsuranceInfo from "./PatientProfileInsuranceInfo";
import PatientProfileInsuranceEligibility from "./PatientProfileInsuranceEligibility";
import { api } from "../../../APIRequests";
import store from "../../../redux/store";
import { updateReduxProfile } from "../../../redux/actions/auth";
import { CUSTOM_TOAST_ALERT } from "../../UI/Alert";
import { A5_V2_ASSESSMENT_TYPE } from "../../../constants/assessments";

import { showAlertWithCustomHTML } from "../../../redux/actions/alerts";
import { connect } from "react-redux";
import {featureFlagFunctionAsync} from "../../../utils/featureFlag";
import {OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG} from "../../../constants/featureFlags";

export const SEC = "SEC";
export const PRI = "PRI";

class PatientProfileInsurance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editBasic: false,
            profileData: props.profileData,
            editEmergency: false,
            editPriIns: false,
            editSecIns: false,
            insuranceImageTypes: {},
            insuranceDetails: {},
            noMatchCount: 0,
            pendingCount: 0,
            showNotConfiguredStatus: false,
        };
    }

    submitHandler = async (formData, actions, type) => {
        let data = { ...formData };

        data["90_day_care_plan"] = data.ninety_day_care;
        if (data.avatar === "") {
            delete data.avatar;
        }

        // intercept here to check if the user is a parent and update the assessment config
        if (data["guardianship_program_type"] == "PARENT_CHILD") {
            data["assessment_config"] = {
                default_assessment: A5_V2_ASSESSMENT_TYPE,
            };
        }

        await featureFlagFunctionAsync({
            flagName: OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG,
            enabled: this.props?.flags?.publicFlags?.[OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG],
            hasLoaded: this.props?.flags?.publicFlagsHaveLoaded,
            defaultEnabled: true,
            enabledBehavior: async () => {
                await this.check_eligibility(data, type);
            },
            alternateBehavior: () => {},
        });

        const keepEditEnabled = () => {
            if (
                (this.state.insuranceDetails.status === "no_match" &&
                    this.state.noMatchCount < 5) ||
                (this.state.insuranceDetails.state === "pending" && this.state.pendingCount < 2)
            ) {
                return true;
            }
            return false;
        };

        //update data object with most up-to-date insurance results from eligibility check
        data["chc_info"] = this.state.insuranceDetails;

        await api.patient
            .update_profile({ data })
            .then((response) => {
                actions.setSubmitting(false);
                store.dispatch(updateReduxProfile(data));
                this.setState({
                    profileData: data,
                });
                keepEditEnabled() ? this.setState({ editPriIns: true }) : this.disableEditMode();
                this.props.reloadImg(data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    check_eligibility = async (data, type) => {
        const insurance_data = {
            card_back: data.primary_insurance.card_back,
            card_front: data.primary_insurance.card_front,
            carrier: data.primary_insurance.carrier,
            group_id: data.primary_insurance.group_id,
            member_id: data.primary_insurance.member_id,
            policy_holder_name: data.primary_insurance.policy_holder_name,
            policy_dob: data.dob,
            state: data.primary_insurance.state,
            dob: data.dob,
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
        };

        if (type === PRI) {
            const toastJSX = (
                <>
                    <strong className="bold">We're checking your insurance coverage.</strong>
                    This may take a little while longer. Please keep this page open.
                </>
            );
            this.props.showAlertWithCustomHTML(toastJSX);

            await api.patient
                .fetch_insurance_eligibility_v2({ data: insurance_data })
                .then(async (data) => {
                    const updated_insurance_check = data;
                    updated_insurance_check["timestamp"] = new Date();
                    updated_insurance_check["carrier"] = insurance_data["carrier"];

                    if (data.status === "pending") {
                        this.setState((prevState) => {
                            return { pendingCount: prevState.pendingCount + 1 };
                        });
                    }

                    if (data.status === "no_match") {
                        this.setState((prevState) => {
                            return { noMatchCount: prevState.noMatchCount + 1 };
                        });
                    }

                    this.setState({
                        showNotConfiguredStatus: true,
                    });

                    this.props.showAlertWithCustomHTML(<></>, CUSTOM_TOAST_ALERT, true);
                    this.setState({
                        insuranceDetails: data,
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    disableEditMode = () => {
        this.setState((ps) => {
            return {
                editPriIns: false,
                editSecIns: false,
            };
        });
    };

    editHandler = (insType) => {
        switch (insType) {
            case PRI:
                this.setState((ps) => {
                    return {
                        editPriIns: !ps.editPriIns,
                        editSecIns: false,
                    };
                });
                break;
            case SEC:
                this.setState((ps) => {
                    return {
                        editPriIns: false,
                        editSecIns: !ps.editSecIns,
                    };
                });
                break;
            default:
                return;
        }
    };

    /**
     * This modifies the insurance data in case when insurance has `None` as the value
     * For input select to detect proper value in case when there is no data, the `None`
     * returned from backend appers as string to select input which is delusional.
     *
     */
    transformInsurance = (insuraceObj) => {
        if (!insuraceObj) {
            return null;
        }
        const modifiedIns = searchAndReplace({ ...insuraceObj }, "None", "");
        return modifiedIns;
    };

    renderShortDetails = ({ first_name, last_name, avatar }) => (
        <div className="PatientProfile__short-details">
            <div className="py-4 py-lg-0 d-flex flex-row flex-lg-column align-items-center justify-content-lg-center">
                <div className="PatientProfile__avatar-wpr position-relative mx-3 mx-lg-0 mt-lg-5 mb-lg-4">
                    <img
                        className="mx-auto d-block"
                        src={avatar || images("./common/avatar.png")}
                        alt="provider avatar"
                    />
                    {/* <div role="button" className="PatientProfile__edit-avatar-btn cursor position-absolute" /> */}
                </div>
                <div className="text-lg-center text-white ">
                    <div className="fw-bold fs-16 fs-lg-18 mb-lg-2">
                        {toTitleCase(first_name) + " " + toTitleCase(last_name)}
                    </div>
                </div>
            </div>
        </div>
    );

    renderAddInsuranceSection = (profileData, insType) => {
        const modifiedProfileData = profileData;
        switch (insType) {
            case PRI:
                modifiedProfileData.primary_insurance =
                    this.transformInsurance({ ...profileData.primary_insurance }) ||
                    profileData.primary_insurance;
            case SEC:
                modifiedProfileData.secondary_insurance =
                    this.transformInsurance({ ...profileData.secondary_insurance }) ||
                    profileData.secondary_insurance;
        }
        return (
            <PatientProfileInsuranceInfo
                {...this.props}
                data={modifiedProfileData}
                submit={this.submitHandler}
                edit={insType === PRI ? this.state.editPriIns : this.state.editSecIns}
                type={insType}
                onEdit={this.editHandler}
            />
        );
    };

    insuranceExists = (ins) => {
        if (ins) {
            let isIns = false;
            const keys = Object.keys(ins);
            keys.forEach((key) => {
                const value = ins[key];
                if (value && value !== "None") {
                    isIns = true;
                }
            });
            return isIns;
        }

        return false;
    };

    render() {
        const { profileData, editPriIns, editSecIns } = this.state;
        return (
            <div className="PatientProfile">
                <div className="row no-gutters justify-content-center">
                    <div className="col-12">
                        <PatientProfileInsuranceEligibility
                            data={profileData?.chc_info}
                            pendingCount={this.state.pendingCount}
                            showNotConfigured={this.state.showNotConfiguredStatus}
                        />
                        <div className={"mt-4"}>
                            <PatientProfileInsuranceInfo
                                {...this.props}
                                data={profileData}
                                submit={this.submitHandler}
                                edit={editPriIns}
                                onEdit={() => this.editHandler(PRI)}
                                type={PRI}
                                emptyTitle={"Add Your Insurance Information"}
                                emptyDetails={"Please add in your insurance information for care."}
                                editTitle={"Your Insurance Information"}
                                completedTitle={"Your Insurance Information"}
                            />
                        </div>
                        <div className={"mt-4"}>
                            <PatientProfileInsuranceInfo
                                {...this.props}
                                data={profileData}
                                submit={this.submitHandler}
                                edit={editSecIns}
                                onEdit={() => this.editHandler(SEC)}
                                type={SEC}
                                emptyTitle={"Add Secondary Insurance Information"}
                                emptyDetails={
                                    "If you have a secondary source of insurance, please add."
                                }
                                editTitle={"Your Secondary Insurance Information"}
                                completedTitle={"Your Secondary Insurance Information"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        flags: state.flags,
    };
};

export default connect(mapStateToProps, { showAlertWithCustomHTML })(PatientProfileInsurance);

/**
 * Below function returns the rendered details which can be reused.
 * @param {string} title - title of the value
 * @param {string} value - value to go below title
 */
export const renderTitleWithValue = function (title, value) {
    const formatMobileNumber = (val) => {
        let firstNumber = val.substring(0, 3);
        let middleNumber = val.substring(3, 6);
        let lastNumber = val.substring(6, 10);
        let formattedMobileNumber = `(${firstNumber}) ${middleNumber}-${lastNumber}`;
        return formattedMobileNumber;
    };

    return (
        <div className="mb-4">
            <div className="fs-14 fw-bold mb-2">
                <span>{title}</span>
            </div>
            <div className="fs-14">
                <span>{title == "Mobile" ? formatMobileNumber(value) : value}</span>
            </div>
        </div>
    );
};
