// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Fragment, useEffect, useState } from "react";
import {
    ReportsMainCollapse,
    CollapsibleParaContent,
    CollapsibleListContent,
} from "../ReportsView";

import { toTitleCase } from "../../../utils/filters";

const XBARReportMain = ({ reportData, accordionId }) => {
    const sections = reportData.report_data.sections;
    const [reportSections, setReportSections] = useState([]);

    useEffect(() => {
        setReportSections(renderAllMainCollapse(sections, accordionId));
    }, []);

    const getParaTypeContent = (title, content) => {
        return <CollapsibleParaContent title={toTitleCase(title)} text={content} />;
    };

    const getListTypeContent = (title, content) => {
        return <CollapsibleListContent title={toTitleCase(title)} content={content} />;
    };

    const getQATypeContent = (title, content) => {
        if (Array.isArray(content) && content.length > 0) {
            return (
                <div>
                    {content.map((item) => {
                        return (
                            <CollapsibleParaContent
                                title={toTitleCase(item.question)}
                                text={item.answer}
                            />
                        );
                    })}
                </div>
            );
        } else {
            return (
                <CollapsibleParaContent
                    title={toTitleCase(title)}
                    text="Clinician assessment is pending. When completed, this information will be populated here."
                />
            );
        }
    };

    const getLsQATypeContent = (title, content) => {
        if (Array.isArray(content) && content.length > 0) {
            return (
                <div>
                    {content.map((item) => {
                        return (
                            <CollapsibleParaContent
                                title={toTitleCase(item.statement)}
                                text={item.response}
                            />
                        );
                    })}
                </div>
            );
        } else {
            return (
                <CollapsibleParaContent
                    title={toTitleCase(title)}
                    text="Clinician assessment is pending. When completed, this information will be populated here."
                />
            );
        }
    };

    const renderMainCollapseContent = (subSection) => {
        let title = subSection.title;
        let scrollId = title.replace(/\s+/g, "-").toLowerCase();
        let type = subSection.type;
        let content =
            subSection.content ||
            "Clinician assessment is pending. When completed, this information will be populated here.";
        let renderedContent = (
            <p>
                Clinician assessment is pending. When completed, this information will be populated
                here.
            </p>
        );

        switch (type) {
            case "paragraph":
                renderedContent = getParaTypeContent(title, content);
                break;
            case "list":
                renderedContent = getListTypeContent(title, content);
                break;
            case "Q&A":
                renderedContent = getQATypeContent(title, content);
                break;
            case "LsQA":
                renderedContent = getLsQATypeContent(title, content);
                break;
            default:
                renderedContent = (
                    <p>
                        Clinician assessment is pending. When completed, this information will be
                        populated here.
                    </p>
                );
        }
        // wrapping extra `span` to add `id` for scrollable behavior from sidebar links.
        return <span id={scrollId}>{renderedContent}</span>;
    };

    const renderAllMainCollapse = (sections, accordionId) => {
        return sections.map((section) => {
            let sectionTitle = section.title;
            let subSections = section.sections;
            let uid = section.uid;
            return (
                <ReportsMainCollapse
                    title={toTitleCase(sectionTitle)}
                    uid={uid}
                    key={uid}
                    accordionId={`#${accordionId}`}>
                    {subSections.map((subSection) => {
                        const { uid } = subSection;
                        return (
                            <Fragment key={uid}>{renderMainCollapseContent(subSection)}</Fragment>
                        );
                    })}
                </ReportsMainCollapse>
            );
        });
    };

    return (
        <div className="accordion" id={accordionId}>
            {reportSections}
        </div>
    );
};

export default XBARReportMain;
