// Copyright aptihealth, inc. 2019 All Rights Reserved
import { STATES } from "../../../../constants/states";

export const RELATIONSHIP_TO_INSURED_OPTIONS = [
    { value: "self", display: "Self" },
    { value: "spouse", display: "Spouse" },
    { value: "child", display: "Child" },
    { value: "other", display: "Other" },
];

// elementType = segment/actionSegment are just a fancy names given to the form section divider mentioned in formSchema config
// this is just to allow array iterator to differentiate b/w a form field and some other stuff
// like sub form heading or a subform heading along with some action control btn.
export const INITIAL_VALUES = {
    email: "",
    email_validation: "",
    first_name: "",
    last_name: "",
    dob: "",
    gender: "",
    mobile: "",
    address: {
        street: "",
        city: "",
        state: "",
        zip_code: "",
    },
    emergency_name: "",
    emergency_contact_no: "",
    insurance: [
        {
            type: "primary_insurance",
            form: {
                state: "",
                carrier: "",
                card_front: "",
                card_back: "",
                member_id: "",
                group_id: "",
                policy_holder_name: "",
                policy_holder_question: "yes",
            },
        },
    ],
    pcp_id: "",
    default_child_practice_autofill: false,
    child_sms_consent: false,
    consent: [],
    medication_management_services: null,
    is_spmi: false,
};

export const INITIAL_VALUES_V2 = {
    email: "",
    email_validation: "",
    first_name: "",
    last_name: "",
    dob: "",
    gender: "",
    mobile: "",
    address: {
        street: "",
        city: "",
        state: "",
        zip_code: "",
    },
    emergency_name: "",
    emergency_contact_no: "",
    insurance: {
        state: "",
        carrier: "",
        card_front: "",
        card_back: "",
        member_id: "",
        group_id: "",
        policy_holder_name: "",
        policy_relationship: "",
        policy_holder_question: "yes",
    },
    pcp_id: "",
    default_child_practice_autofill: false,
    child_sms_consent: false,
    consent: [],
    medication_management_services: null,
    is_spmi: false,
};

export const BASIC_SCHEMA = [
    {
        name: "first_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "First Name",
            placeholder: "First name",
        },
    },
    {
        name: "last_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Last Name",
            placeholder: "Last name",
        },
    },
    {
        name: "dob",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Date of Birth",
            placeholder: "MM/DD/YYYY",
        },
    },
    {
        name: "gender",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "male", display: "Male" },
                { value: "female", display: "Female" },
            ],
            label: "Gender",
            placeholder: "Gender",
        },
    },
    {
        name: "mobile",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Mobile",
            placeholder: "Mobile",
        },
    },
    {
        name: "email",
        elementType: "input",
        elementConfig: {
            type: "email",
            label: "Email",
            placeholder: "Email",
        },
    },
    {
        name: "email_validation",
        elementType: "input",
        elementConfig: {
            type: "email",
            label: "Re-Enter Email",
            placeholder: "Email",
        },
    },
    // below object does not render form element
    // only marks the hook for nested form and displays heading
    {
        elementType: "segment",
        displayName: "Address",
    },
    {
        name: "address.street",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Street",
            placeholder: "Enter Street",
        },
    },
    {
        name: "address.city",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "City",
            placeholder: "Enter City",
        },
    },
    {
        name: "address.state",
        elementType: "select",
        elementConfig: {
            options: STATES,
            label: "State",
            placeholder: "Enter State",
        },
    },
    {
        name: "address.zip_code",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Zip",
            placeholder: "Enter zip",
        },
    },
    {
        name: "emergency_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Emergency Contact Person",
            placeholder: "Emergency Contact Person",
        },
    },
    {
        name: "emergency_contact_no",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Emergency Contact Number",
            placeholder: "Emergency Contact Number",
        },
    },
];

export const PATIENT_BASIC_SCHEMA = [
    {
        name: "first_name",
        elementType: "readOnly",
        elementConfig: {
            type: "text",
            label: "First Name",
            placeholder: "First name",
        },
    },
    {
        name: "last_name",
        elementType: "readOnly",
        elementConfig: {
            type: "text",
            label: "Last Name",
            placeholder: "Last name",
        },
    },
    {
        name: "dob",
        elementType: "readOnly",
        elementConfig: {
            type: "text",
            label: "Date of Birth",
            placeholder: "MM/DD/YYYY",
        },
    },
    {
        name: "gender",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "male", display: "Male" },
                { value: "female", display: "Female" },
            ],
            label: "Gender",
            placeholder: "Gender",
        },
    },
    {
        name: "mobile",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Mobile",
            placeholder: "Mobile",
        },
    },
    {
        name: "email",
        elementType: "input",
        elementConfig: {
            type: "email",
            label: "Email",
            placeholder: "Email",
        },
    },
    {
        name: "email_validation",
        elementType: "input",
        elementConfig: {
            type: "email",
            label: "Re-Enter Email",
            placeholder: "Email",
        },
    },
    // below object does not render form element
    // only marks the hook for nested form and displays heading
    {
        elementType: "segment",
        displayName: "Address",
    },
    {
        name: "address.street",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Street",
            placeholder: "Enter Street",
        },
    },
    {
        name: "address.city",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "City",
            placeholder: "Enter City",
        },
    },
    {
        name: "address.state",
        elementType: "select",
        elementConfig: {
            options: STATES,
            label: "State",
            placeholder: "Enter State",
        },
    },
    {
        name: "address.zip_code",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Zip",
            placeholder: "Enter zip",
        },
    },
    {
        name: "emergency_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Emergency Contact Person",
            placeholder: "Emergency Contact Person",
        },
    },
    {
        name: "emergency_contact_no",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Emergency Contact Number",
            placeholder: "Emergency Contact Number",
        },
    },
];

export const PRI_INSURANCE_SCHEMA = [
    // below object does not render form element
    // only marks the hook for nested form and displays heading
    {
        elementType: "segment",
        displayName: "Primary Insurance Information",
    },
    {
        name: "insurance[0].form.state",
        elementType: "select",
        elementConfig: {
            options: [],
            label: "State",
            placeholder: "Select State",
        },
    },
    {
        name: "insurance[0].form.carrier",
        elementType: "select",
        elementConfig: {
            sentenceCase: false,
            options: [],
            label: "Carrier",
            placeholder: "Select Carrier",
        },
    },
    {
        elementType: "InsuranceCardBtn",
        type: "pri",
    },
    // {
    //     name: 'insurance[0].form.card_front',
    //     elementType: 'select',
    //     elementConfig: {
    //         options: [{ value: 'one', display: 'One' },
    //         { value: 'two', display: 'Two' },
    //         ],
    //         label: 'Plan type',
    //         placeholder: 'Select Plan type'
    //     }
    // },
    {
        name: "insurance[0].form.member_id",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Member ID",
            placeholder: "Enter Member ID",
        },
    },
    {
        name: "insurance[0].form.policy_holder_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Policy Holder Name",
            placeholder: "Enter Policy Holder Name",
        },
    },
    {
        name: "insurance[0].form.policy_relationship",
        elementType: "select",
        elementConfig: {
            options: RELATIONSHIP_TO_INSURED_OPTIONS,
            label: "Relationship to Insured",
            placeholder: "Select Relationship",
        },
    },
    {
        name: "insurance[0].form.policy_dob",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Policy Holder Date of Birth",
            placeholder: "MM/DD/YYYY",
        },
    },
];

export const PRI_INSURANCE_SCHEMA_V2 = [
    // below object does not render form element
    // only marks the hook for nested form and displays heading
    {
        elementType: "segment",
        displayName: "Primary Insurance Information",
    },
    {
        name: "insurance.state",
        elementType: "select",
        elementConfig: {
            options: [],
            label: "State",
            placeholder: "Select State",
        },
    },
    {
        name: "insurance.carrier",
        elementType: "select",
        elementConfig: {
            sentenceCase: false,
            options: [],
            label: "Carrier",
            placeholder: "Select Carrier",
        },
    },
    {
        name: "insurance.member_id",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Member ID",
            placeholder: "Enter Member ID",
        },
    },
    {
        name: "insurance.policy_holder_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Policy Holder Name",
            placeholder: "Enter Policy Holder Name",
        },
    },
    {
        name: "insurance.policy_relationship",
        elementType: "select",
        elementConfig: {
            options: RELATIONSHIP_TO_INSURED_OPTIONS,
            label: "Relationship to Insured",
            placeholder: "Select Relationship",
        },
    },
    {
        name: "insurance.policy_dob",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Policy Holder Date of Birth",
            placeholder: "MM/DD/YYYY",
        },
    },
];

export const SEC_INSURANCE_SCHEMA = [
    // below object does not render form element
    // only marks the hook for nested form and displays heading
    {
        elementType: "actionSegment",
        action: {
            handler: (cb) => {
                cb();
            },
            name: "Remove",
            style: "Btn Btn--clr-err p-0",
        },
        displayName: "Secondary Insurance Information",
    },
    {
        name: "insurance[1].form.state",
        elementType: "select",
        elementConfig: {
            options: [],
            label: "State",
            placeholder: "Select State",
        },
    },
    {
        name: "insurance[1].form.carrier",
        elementType: "select",
        elementConfig: {
            sentenceCase: false,
            options: [],
            label: "Carrier",
            placeholder: "Select Carrier",
        },
    },
    {
        elementType: "InsuranceCardBtn",
        type: "sec",
    },
    // {
    //     name: 'insurance[1].form.plan_type',
    //     elementType: 'select',
    //     elementConfig: {
    //         options: [{ value: 'one', display: 'One' },
    //         { value: 'two', display: 'Two' },
    //         ],
    //         label: 'Plan type',
    //         placeholder: 'Enter Plan type'
    //     }
    // },
    {
        name: "insurance[1].form.member_id",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Member Id",
            placeholder: "Enter Member Id",
        },
    },
    {
        name: "insurance[1].form.policy_holder_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Policy Holder Name",
            placeholder: "Enter Policy Holder Name",
        },
    },
    {
        name: "insurance[1].form.policy_relationship",
        elementType: "select",
        elementConfig: {
            options: RELATIONSHIP_TO_INSURED_OPTIONS,
            label: "Relationship to Insured",
            placeholder: "Select Relationship",
        },
    },
    {
        name: "insurance[1].form.policy_dob",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Policy Holder Date of Birth",
            placeholder: "MM/DD/YYYY",
        },
    },
];

export const PATIENT_PCP_SCHEMA = [
    // below object does not render form element
    // only marks the hook for nested form and displays heading
    {
        elementType: "segment",
        displayName: "Primary Care Physician Information",
    },
    {
        name: "pcp_id",
        elementType: "select",
        elementConfig: {
            options: [],
            label: "Physician",
            placeholder: "Select Physician",
        },
    },
];

export const MED_MGMT_OPTIONS = {
    medication_management: {
        order: 1,
        label: "Medication Consult Requested",
    },
};
