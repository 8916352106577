// Copyright aptihealth, inc. 2022 All Rights Reserved
import React, { useEffect, useState } from "react";
import {
    List,
    ListBody,
    ListHeader,
    ListItem,
    ListTitleBar,
    TitleBarItem,
} from "../../UI/ListView";
import { api } from "../../../APIRequests";
import {
    Button,
    ButtonTypes,
    DateInput,
    Dropdown,
    FormField,
    Radio,
    RadioGroup,
} from "../../../component-library";
import _ from "lodash";
import DatePicker from "../../UI/DatePicker";
import "./styles.scss";
import moment from "moment-timezone";
import { showAlertWithAction } from "../../../redux/actions/alerts";
import { connect } from "react-redux";
import images from "../../../utils/images";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import * as yup from "yup";
import { STATUS_LOOKUP } from "../../../component-library/InsuranceEligibilityRecord/constants";
import { CustomForm } from "../../../component-library/CustomForm";
import { getAutomationDataAttr } from "../../../utils/automationUtils";

const InsuranceEligibilityQueueManagement = (props) => {
    const pendingOption = {
        value: "pending",
        label: "Pending",
    };

    const eligibilityStatusOptions = [
        {
            value: "accepted",
            label: "Accepted",
        },
        {
            value: "declined",
            label: "Declined",
        },
        {
            value: "no_match",
            label: "No Match",
        },
        {
            value: "not_configured",
            label: "Not Configured",
        },
        pendingOption,
    ];

    const sortOptions = [
        {
            value: "timestamp",
            label: "Transaction",
        },
        {
            value: "next_session_timestamp",
            label: "DI Timestamp",
        },
    ];

    const [carrierOptions, setCarrierOptions] = useState([]);
    const [listItems, setListItems] = useState([]);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    const [showStartDatePicker, setShowStartDatePicker] = useState(false);
    const [showEndDatePicker, setShowEndDatePicker] = useState(false);
    const initialSearchRequest = {
        status: pendingOption.value,
        index_name: "status_timestamp_gsi",
        sortBy: "timestamp",
        carrier: null,
        start: null,
        end: null,
    };
    const initialFormValues = {
        status: pendingOption,
        carrier: "",
        start: "",
        end: "",
        sortBy: sortOptions[0],
    };
    const eligibilitySearchFormValidation = yup.object().shape({
        status: Yup.string().required("Eligibility Status is required."),
        carrier: Yup.string(),
        start: Yup.string().test(
            "start-date-range-test",
            "Required and must be on or before End.",
            function (value) {
                if (!this.parent.end) {
                    return true;
                }
                return value <= this.parent.end;
            },
        ),
        end: Yup.string().test(
            "end-date-range-test",
            "Required and must be on or after Start.",
            function (value) {
                if (!this.parent.start) {
                    return true;
                }
                return value >= this.parent.start;
            },
        ),
        sortBy: Yup.string().required("Sort By is required"),
    });

    const [searchRequest, setSearchRequest] = useState(initialSearchRequest);

    useEffect(() => {
        (async () => {
            const response = await api.patient.fetch_insurance_list();
            const carriers = response.carriers || [];
            let options = carriers.map((carrier) => {
                return { value: carrier, label: carrier };
            });
            setCarrierOptions(options);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await search(initialSearchRequest, null, false);
        })();
    }, []);

    async function search(searchRequest, lastEvaluatedKey, append) {
        let data = _.cloneDeep(searchRequest);
        data["query_type"] = determineQueryType(data);
        data["index_name"] = determineIndexName(data["query_type"], searchRequest["sortBy"]);
        data["last_evaluated_key"] = lastEvaluatedKey;
        const searchResponse = await api.admin.fetch_insurance_eligibility_records({ data });
        const newItems = searchResponse.items || [];

        if (append) {
            const newList = listItems.concat(newItems);
            setListItems(newList);
        } else {
            setListItems(newItems);
        }

        const lastKey = searchResponse.last_evaluated_key;
        if (lastKey) {
            setLastEvaluatedKey(lastKey);
        } else {
            setLastEvaluatedKey(null);
        }
    }

    const determineQueryType = (searchRequest) => {
        if (
            searchRequest.status &&
            !searchRequest.start &&
            !searchRequest.end &&
            !searchRequest.carrier
        ) {
            return "status";
        }
        if (
            searchRequest.status &&
            !searchRequest.start &&
            !searchRequest.end &&
            searchRequest.carrier
        ) {
            return "status_and_carrier";
        }
        if (
            searchRequest.status &&
            searchRequest.start &&
            searchRequest.end &&
            !searchRequest.carrier
        ) {
            return "status_and_timestamp_between";
        }
        if (
            searchRequest.status &&
            searchRequest.start &&
            searchRequest.end &&
            searchRequest.carrier
        ) {
            return "status_and_carrier_and_timestamp_between";
        }
    };

    const determineIndexName = (queryType, sortBy) => {
        if (
            (queryType === "status" || queryType === "status_and_timestamp_between") &&
            sortBy === "timestamp"
        ) {
            return "status_timestamp_gsi";
        }
        if (
            (queryType === "status" || queryType === "status_and_timestamp_between") &&
            sortBy === "next_session_timestamp"
        ) {
            return "status_next_session_timestamp_gsi";
        }
        if (
            (queryType === "status_and_carrier" ||
                queryType === "status_and_carrier_and_timestamp_between") &&
            sortBy === "timestamp"
        ) {
            return "status_carrier_timestamp_gsi";
        }
        if (
            (queryType === "status_and_carrier" ||
                queryType === "status_and_carrier_and_timestamp_between") &&
            sortBy === "next_session_timestamp"
        ) {
            return "status_carrier_next_session_timestamp_gsi";
        }
    };

    const onDateChange = async (date, eventType, key, formikProps) => {
        if (eventType === "monthChange") {
            return;
        }

        const formattedDate = moment(date, "YYYY-MM-DD").format("MM/DD/YYYY");
        await formikProps.setFieldValue(key, formattedDate);
        formikProps.setFieldTouched(key);

        if (key === "start") {
            setShowStartDatePicker(false);
            return;
        }
        if (key === "end") {
            setShowEndDatePicker(false);
            return;
        }
    };

    const submitHandler = async (formData) => {
        const start =
            formData.start &&
            moment(`${formData.start} 00:00:00`, "MM/DD/YYYY HH:mm:ss")
                .utc()
                .format("YYYY-MM-DDTHH:mm:ss");
        const end =
            formData.end &&
            moment(`${formData.end} 23:59:59`, "MM/DD/YYYY HH:mm:ss")
                .utc()
                .format("YYYY-MM-DDTHH:mm:ss");

        const status = formData.status && formData.status.value;
        const carrier = formData.carrier && formData.carrier.value;
        const sortBy = formData.sortBy && formData.sortBy.value;
        const data = {
            status,
            carrier,
            start,
            end,
            sortBy,
        };
        setSearchRequest(data);
        await search(data, null, false);
    };

    const renderListFilters = () => {
        return (
            <CustomForm
                initialValues={initialFormValues}
                validationSchema={eligibilitySearchFormValidation}
                onSubmit={submitHandler}
                render={renderListFiltersForm}
            />
        );
    };

    const renderListFiltersForm = (formikProps) => {
        const { values, touched, errors } = formikProps;
        return (
            <Form>
                <div className={"w-100 fs-24 fw-bold mb-2"}>Eligibility</div>
                <div className={"d-lg-flex justify-content-between"}>
                    <div className={"row"}>
                        <div className="col-lg-2 col-12">
                            <FormField
                                label="Eligibility Status"
                                errorMessage={touched.status ? errors.status : ""}
                                toolTip={
                                    <div className={"txt-black"}>
                                        {Object.values(STATUS_LOOKUP).map((statusEntry) => {
                                            return (
                                                <div className={"mb-2"}>
                                                    <span className={"fw-bold"}>
                                                        {statusEntry.formattedStatus}:{" "}
                                                    </span>
                                                    <span>{statusEntry.definition}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                }
                                toolTipPosition={"right"}>
                                <Dropdown
                                    name={"status"}
                                    value={values.status}
                                    options={eligibilityStatusOptions}
                                    error={touched.status && errors.status}
                                    onChange={async (e) => {
                                        const option = eligibilityStatusOptions.find(
                                            (o) => o.value === e.target.value,
                                        );
                                        await formikProps.setFieldValue("status", option);
                                        formikProps.setFieldTouched("status");
                                    }}
                                    {...getAutomationDataAttr(
                                        "InsuranceEligibilitySearchStatus",
                                        "id",
                                    )}
                                />
                            </FormField>
                        </div>
                        <div className="col-lg-2 col-12">
                            <FormField
                                label="Insurance Company"
                                errorMessage={touched.carrier ? errors.carrier : ""}>
                                <Dropdown
                                    isClearable={true}
                                    name={"carrier"}
                                    value={values.carrier}
                                    options={carrierOptions}
                                    error={touched.carrier && errors.carrier}
                                    onChange={async (e) => {
                                        const option = carrierOptions.find(
                                            (o) => o.value === e.target.value,
                                        );
                                        if (option) {
                                            await formikProps.setFieldValue("carrier", option);
                                        } else {
                                            await formikProps.setFieldValue("carrier", "");
                                        }
                                        formikProps.setFieldTouched("carrier");
                                    }}
                                    {...getAutomationDataAttr(
                                        "InsuranceEligibilitySearchCarrier",
                                        "id",
                                    )}
                                />
                            </FormField>
                        </div>
                        <div className="col-lg-2 col-12">
                            <FormField
                                label="Sort By Timestamp"
                                errorMessage={touched.sortBy ? errors.sortBy : ""}>
                                <Dropdown
                                    name={"sortBy"}
                                    value={values.sortBy}
                                    options={sortOptions}
                                    error={touched.sortBy && errors.sortBy}
                                    onChange={async (e) => {
                                        const option = sortOptions.find(
                                            (o) => o.value === e.target.value,
                                        );
                                        await formikProps.setFieldValue("sortBy", option);
                                        formikProps.setFieldTouched("carrier");
                                    }}
                                    {...getAutomationDataAttr(
                                        "InsuranceEligibilitySearchSortBy",
                                        "id",
                                    )}
                                />
                            </FormField>
                        </div>
                        <div className="col-lg-2 col-12">
                            <FormField
                                label="Start Date"
                                errorMessage={touched.start ? errors.start : ""}>
                                <DateInput
                                    name="start"
                                    placeholder="MM/DD/YYYY"
                                    value={values.start}
                                    onChange={async (e) => {
                                        await formikProps.setFieldValue("start", e.formattedValue);
                                        formikProps.setFieldTouched("start");
                                    }}
                                    onClick={() => setShowStartDatePicker(!showStartDatePicker)}
                                    error={touched.start && errors.start}
                                    {...getAutomationDataAttr(
                                        "InsuranceEligibilitySearchStartDate",
                                    )}
                                />
                            </FormField>
                            {showStartDatePicker && (
                                <div className="search-eligibility-date-picker search-eligibility-date-picker--start">
                                    <DatePicker
                                        externalCtx={
                                            values.start && moment(values.start, "MM/DD/YYYY")
                                        }
                                        onDaySelect={(date, eventType) =>
                                            onDateChange(date, eventType, "start", formikProps)
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <div className="col-lg-2 col-12">
                            <FormField
                                label="End Date"
                                errorMessage={touched.end ? errors.end : ""}>
                                <DateInput
                                    name="end"
                                    placeholder="MM/DD/YYYY"
                                    value={values.end}
                                    onChange={async (e) => {
                                        await formikProps.setFieldValue("end", e.formattedValue);
                                        formikProps.setFieldTouched("end");
                                    }}
                                    onClick={() => setShowEndDatePicker(!showEndDatePicker)}
                                    error={touched.end && errors.end}
                                    {...getAutomationDataAttr("InsuranceEligibilitySearchEndDate")}
                                />
                            </FormField>
                            {showEndDatePicker && (
                                <div className="search-eligibility-date-picker">
                                    <DatePicker
                                        externalCtx={values.end && moment(values.end, "MM/DD/YYYY")}
                                        onDaySelect={(date, eventType) =>
                                            onDateChange(date, eventType, "end", formikProps)
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <div className="col-lg-2 col-12">
                            <div className="mt-lg-4 render-controls">
                                <Button
                                    buttonType={ButtonTypes.primaryV2}
                                    {...getAutomationDataAttr("InsuranceEligibilitySearchButton")}
                                    onClick={() => submitHandler(formikProps.values)}>
                                    Search
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        );
    };

    const renderListItems = () => {
        if (!listItems) {
            return <></>;
        }

        return listItems.map((item) => {
            const insuranceDetails = item.insurance_details || {};
            const fullName = `${insuranceDetails.first_name || ""}  ${
                insuranceDetails.last_name || ""
            }`;
            const timestamp = moment.utc(item.timestamp).local().format("M/D/YYYY h:mma");
            const nextSessionTimestamp =
                item.next_session_timestamp &&
                moment.utc(item.next_session_timestamp).local().format("M/D/YYYY h:mma");
            const status = eligibilityStatusOptions.find((o) => o.value === item.status).label;
            return (
                <ListItem key={item.id}>
                    <div
                        className="d-lg-none d-md-block d-sm-block"
                        onClick={() => {
                            window.open(`/app/eligibility/${item.id}`);
                        }}>
                        <div>{fullName}</div>
                        <div>Transaction Timestamp: {timestamp}</div>
                        <div>DI Timestamp: {nextSessionTimestamp}</div>
                        <div>{status}</div>
                        <div>{item.carrier}</div>
                    </div>

                    <div
                        className="d-lg-block d-md-none d-none"
                        {...getAutomationDataAttr("InsuranceEligibilitySearchResultRecord")}
                        id={item.id}>
                        <div className="row">
                            <span className={"col-lg-3 col-11"}>{fullName}</span>
                            <span className={"col-lg-2 col-11"}>{timestamp}</span>
                            <span className={"col-lg-2 col-11"}>{nextSessionTimestamp}</span>
                            <span className={"col-lg-2 col-11"}>{status}</span>
                            <span className={"col-lg-2 col-11"}>{item.carrier}</span>
                            <span className={"col-1"}>
                                <div className="cursor">
                                    <img
                                        onClick={() => window.open(`/app/eligibility/${item.id}`)}
                                        src={images("./common/view.png")}
                                        alt="view icon"
                                    />
                                </div>
                            </span>
                        </div>
                    </div>
                </ListItem>
            );
        });
    };

    return (
        <>
            <ListBody styles={{ maxWidth: 1300 }}>
                <ListHeader render={renderListFilters} renderContainerClassName={"w-100"} />
                <ListTitleBar>
                    <TitleBarItem classes="col-3" text="Patient Name" />
                    <TitleBarItem classes="col-2" text="Transaction Timestamp" />
                    <TitleBarItem classes="col-2" text="DI Timestamp" />
                    <TitleBarItem classes="col-2" text="Eligibility Status" />
                    <TitleBarItem classes="col-2" text="Insurance Company" />
                    <TitleBarItem classes="col-1" text="" />
                </ListTitleBar>
                <List>{renderListItems()}</List>
            </ListBody>
            {lastEvaluatedKey && (
                <div className={"d-flex justify-content-center"}>
                    <Button
                        buttonType={"primary"}
                        onClick={() => search(searchRequest, lastEvaluatedKey, true)}>
                        Show More
                    </Button>
                </div>
            )}
        </>
    );
};

export default connect(null, { showAlertWithAction })(InsuranceEligibilityQueueManagement);
