import React, { useEffect, useState } from "react";
import RouteSection from "../RouteSection";
import SelfSection from "../SelfSection";

const DesktopToolbar = (props) => {
    const [customRenderSection, setCustomRenderSection] = useState(props.customRenderSection);
    const [routeSection, setRouteSection] = useState(props.routeSection);
    const [selfSection, setSelfSection] = useState(props.selfSection);
    const [profile, setProfile] = useState(props.profile);

    useEffect(() => {
        setCustomRenderSection(props.customRenderSection);
        setRouteSection(props.routeSection);
        setSelfSection(props.selfSection);
        setProfile(props.profile);
    }, [props.customRenderSection, props.routeSection, props.selfSection, props.profile]);

    const renderCustomRenderSection = customRenderSection
        ? customRenderSection.map((section) => {
              if (section.isComponent) {
                  return (
                      <section.render
                          totalUnreadMessages={props.totalUnreadMessages}
                          {...section.componentProps}
                      />
                  );
              } else {
                  return section.render;
              }
          })
        : [];

    if (selfSection) {
        selfSection["profile"] = profile;
        selfSection["baseRoute"] = props.baseRoute;
    }

    return (
        <nav className="Toolbar__nav-desktop d-flex align-items-center justify-content-center">
            <div className={"Toolbar__nav-desktop-container d-flex align-items-center"}>
                {routeSection && (
                    <RouteSection
                        config={routeSection}
                        handleClickSearch={props.handleClickSearch}
                        totalUnreadMessages={props.totalUnreadMessages}></RouteSection>
                )}
                {renderCustomRenderSection}
                {selfSection && <SelfSection config={selfSection}></SelfSection>}
            </div>
        </nav>
    );
};

export default DesktopToolbar;
