// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";

const Body = (props) => {
    const baseClasses = "List__body mx-auto container p-lg-0 mb-4 ";
    const classes = props.classes ? baseClasses + props.classes : baseClasses;
    return (
        <div className={classes} style={props.styles}>
            {props.children}
        </div>
    );
};

export default Body;
