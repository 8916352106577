import React, { Component, Fragment, useRef, useState } from "react";

import "./styles.scss";

const ExpandableMessageInput = (props) => {
    const [currentMessageText, setCurrentMessageText] = useState("");
    const inputElement = useRef(null);

    const handleInput = (event) => {
        props.channel.startTyping();
        setCurrentMessageText(inputElement.current.innerText);
        props.channel.endTyping();
    };

    const resetMessageText = () => {
        setCurrentMessageText("");
        inputElement.current.textContent = "";
    };

    const handleTyping = (event) => {
        if (event.key === "Enter") {
            if (event.shiftKey) {
                return;
            }
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        if (currentMessageText === "\n") {
            resetMessageText();
            return;
        }
        props.submitHandler(currentMessageText);
        resetMessageText();
    };

    return (
        <div className="input-group">
            <p
                role="textbox"
                aria-label="Enter a message..."
                onInput={handleInput}
                contentEditable={true}
                onKeyDown={handleTyping}
                ref={inputElement}
                className="mb-0 h-auto form-control content-editable-input-container"
                data-e2e="Messages__messageInput"
            />
            <div className="input-group-append">
                <span
                    className="btn btn-outline-secondary"
                    onClick={handleSubmit}
                    data-e2e="Messages__sendMessageButton">
                    Send
                </span>
            </div>
        </div>
    );
};

export default ExpandableMessageInput;
