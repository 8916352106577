// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component, Fragment } from "react";
import { api } from "../../../APIRequests";
import images from "../../../utils/images";
import {
    List,
    ListBody,
    ListHeader,
    ListItem,
    ListTitleBar,
    TitleBarItem,
} from "../../../components/UI/ListView";
import moment from "moment";
import Button from "../../../components/UI/Button";
import UploadFile from "../../../components/Provider/PatientView/UploadFile";
import {
    getPatientAssociatedFiles,
    getReferralAssociatedFiles,
    getScreeningAssociatedFiles,
    uploadAssociatedFiles,
    UserFileTypes,
    viewPatientAssociatedFile,
    viewReferralAssociatedFile,
    viewScreeningAssociatedFile,
} from "../../../utils/fileUtil";
import { connect } from "react-redux";
import { addToast, toastMessageTypes } from "../../../redux/actions/toaster";

class FileList extends Component {
    state = { entity: null, id: null, filesToBeUploaded: [], fileType: null };

    componentDidMount = async () => {
        try {
            if (this.props.type === "patient") {
                let urlParams = { patientId: this.props.match.params.patientId };
                let response = await api.shared.fetch_patient_details({ urlParams });
                const files = await getPatientAssociatedFiles(this.props.match.params.patientId);
                this.setState({
                    entity: response.user,
                    files: files,
                    id: this.props.match.params.patientId,
                    viewFileFunction: viewPatientAssociatedFile,
                    fileType: UserFileTypes.ASSOCIATED_FILE_PATIENT,
                });
            } else if (this.props.type === "referral") {
                let queryParams = { referralCode: this.props.match.params.referralCode };
                let response = await api.shared.fetch_referral({ queryParams });
                const files = await getReferralAssociatedFiles(
                    this.props.match.params.referralCode,
                );
                this.setState({
                    entity: response,
                    files: files,
                    id: this.props.match.params.referralCode,
                    viewFileFunction: viewReferralAssociatedFile,
                    fileType: UserFileTypes.ASSOCIATED_FILE_REFERRAL,
                });
            } else if (this.props.type === "a5_screenings") {
                let queryParams = { screening_id: this.props.match.params.screening_id };
                let response = await api.shared.fetch_a5_screening_details({ queryParams });
                const files = await getScreeningAssociatedFiles(
                    this.props.match.params.screening_id,
                );
                this.setState({
                    entity: response,
                    files: files,
                    id: this.props.match.params.screening_id,
                    viewFileFunction: viewScreeningAssociatedFile,
                    fileType: UserFileTypes.ASSOCIATED_FILE_SCREENING,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    renderControls = () => {
        return (
            <div className="d-flex">
                <Button
                    onClick={() => this.setState({ showUploadFileCard: true })}
                    className="Btn d-none d-lg-block Btn--pri Btn-sm ml-4 fs-16 text-center text-white">
                    Upload
                </Button>
            </div>
        );
    };

    getFile = async (key) => {
        await this.state.viewFileFunction(this.state.id, key);
    };

    getListChildren = (file) => {
        let load_date = moment.utc(file.timestamp).local().format("MM/DD/YYYY HH:mm");
        let fileName = file.file_key.substring(file.file_key.lastIndexOf("/") + 1);

        return (
            <Fragment>
                {/*for mobile devices */}
                <div
                    onClick={() => this.getFile(file.key)}
                    className="d-flex d-lg-none justify-content-between">
                    <div className="w-75 d-flex fs-14 flex-column">
                        <span className="fs-16">{load_date}</span>
                        <span>{file.file_key.substring(file.file_key.lastIndexOf("/"))}</span>
                    </div>
                </div>
                {/* for desktop */}
                <div className="row no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between">
                    <span className="col-4">{load_date}</span>
                    <span className="col-6">{fileName}</span>
                    <div className="col-2 d-flex d-flex justify-content-center">
                        <div className="cursor ml-5">
                            <img
                                onClick={() => this.getFile(file.file_key)}
                                src={images("./common/view.png")}
                                alt="view icon"
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    onFileInputChange = (target) => {
        let filesToBeUploaded = [...this.state.filesToBeUploaded];
        if (target && target.files.length > 0) {
            for (let file of target.files) {
                filesToBeUploaded.push(file);
            }
            this.setState({ filesToBeUploaded });
        }
    };

    onFileFileDelete = (index) => {
        let changedFiles = [...this.state.filesToBeUploaded];
        changedFiles.splice(index, 1);
        this.setState({
            filesToBeUploaded: changedFiles,
        });
    };

    onInsertFiles = async () => {
        try {
            const newFiles = await uploadAssociatedFiles(
                this.state.filesToBeUploaded,
                this.props.type,
                this.state.id,
                this.state.fileType,
            );
            this.setState({
                files: [...newFiles, ...this.state.files],
                showUploadFileCard: false,
                filesToBeUploaded: [],
            });
        } catch (err) {
            console.log(err);
            this.props.addToast({
                message: err.message,
                messageType: toastMessageTypes.error_v2,
            });
        }
    };

    render() {
        let name = this.state.entity
            ? this.state.entity.first_name + " " + this.state.entity.last_name
            : "";
        let backLinkText;
        if (this.props.type === "patient") {
            backLinkText = "Back to Patient Profile";
        } else if (this.props.type === "referral") {
            backLinkText = "Back to Referral";
        } else if (this.props.type === "a5_screenings") {
            backLinkText = "Back to A5 Screening";
        }
        return (
            <div>
                <div>
                    <div className="backLink-wpr mx-auto mt-4 d-none d-lg-block pointer">
                        {this.state.entity && (
                            <div
                                className="PatientProfile-back-link"
                                onClick={() => this.props.history.goBack()}>
                                <img
                                    className="img-fluid"
                                    src={images("./common/solidBckBtn.svg")}
                                />
                                <p className="d-inline ml-2">{backLinkText}</p>
                            </div>
                        )}
                    </div>
                    <ListBody>
                        {this.state.showUploadFileCard && (
                            <UploadFile
                                hideModal={() => this.setState({ showUploadFileCard: false })}
                                files={this.state.filesToBeUploaded}
                                onInputChange={this.onFileInputChange}
                                handleDrop={this.onFileInputChange}
                                onFileRemove={this.onFileFileDelete}
                                onInsertFiles={this.onInsertFiles}
                            />
                        )}
                        {this.state.entity && (
                            <ListHeader title={name + "'s Files"} render={this.renderControls} />
                        )}
                        <ListTitleBar>
                            <TitleBarItem classes="col-4" text="Load Date" />
                            <TitleBarItem classes="col-6" text="File Name" />
                            <TitleBarItem classes="col-2" text="" />
                        </ListTitleBar>
                        <List>
                            {this.state.files &&
                                this.state.files &&
                                this.state.files
                                    .filter((file) => file)
                                    .map((file) => {
                                        return (
                                            <ListItem
                                                key={file.file_key}
                                                clickOnMobile
                                                clickHandler={() => console.log("clicked")}>
                                                {this.getListChildren(file)}
                                            </ListItem>
                                        );
                                    })}
                        </List>
                    </ListBody>
                </div>
            </div>
        );
    }
}

export default connect(null, { addToast })(FileList);
