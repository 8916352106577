import React from "react";
import PropTypes from "prop-types";

import { createClassNameString, tabletViewport } from "../utils";
import { TextTypes, PopoverPositions } from "../constants";

import { Text } from "../Text";
import { BrowseButton } from "../BrowseButton";
import { FileInput } from "../FileInput";

import "./styles.scss";

export const UploadFile = ({
    className,
    label,
    placeholder,
    required,
    files,
    infoContent,
    onBrowseButtonClicked,
    onFileDelete,
    disabled,
    ...props
}) => {
    const windowWidth = window.innerWidth;

    return (
        <div className={createClassNameString(["apti-UploadFile", className])} {...props}>
            <div>
                <Text className="apti-UploadFile--header" type={TextTypes.label}>
                    {label ? label : "Insurance Card Images"}
                </Text>
                {!required && (
                    <Text className="apti-UploadFile--header" type={TextTypes.paragraph}>
                        (Optional)
                    </Text>
                )}
            </div>
            <div className="apti-UploadFile--input">
                <BrowseButton onClick={onBrowseButtonClicked} disabled={disabled} />
                <FileInput
                    files={files}
                    placeholder={placeholder ? placeholder : "Upload Insurance Card Image"}
                    infoContent={infoContent}
                    infoPopoverPosition={
                        windowWidth < tabletViewport
                            ? PopoverPositions.left
                            : PopoverPositions.right
                    }
                    onDelete={onFileDelete}
                />
            </div>
        </div>
    );
};

UploadFile.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    files: PropTypes.arrayOf(PropTypes.string).isRequired,
    infoContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onBrowseButtonClicked: PropTypes.func.isRequired,
    onFileDelete: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

UploadFile.defaultProps = {
    className: undefined,
    label: undefined,
    placeholder: undefined,
    requried: false,
    files: [],
    infoContent: undefined,
    onBrowseButtonClicked: () => {},
    onFileDelete: () => {},
    disabled: false,
};
