// Copyright aptihealth, inc. 2020 All Rights Reserved
import React, { useEffect, useState } from "react";
import ScheduleEvent from "./ScheduleEvent";
import moment from "moment";
import { Link } from "react-router-dom";
import { adminRoute, providerRoute } from "../../../config/roles";
import images from "../../../utils/images";
import { createNewScheduledEvent, getExistingConflicts } from "./index";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { api } from "../../../APIRequests";
import { isUserAdmin } from "../../../redux/actions/auth";

const AddAppointment = (props) => {
    const [datePickerConf, setDatePickerConf] = useState(null);
    const [startTimeSlot, setStartTimeSlot] = useState(null);
    const [scheduleList, setScheduleList] = useState([]);
    const [selectedDate, setSelectedDate] = useState(moment());

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    useEffect(() => {
        getSpecificDateSchedule(selectedDate.format("YYYY-MM-DD HH:mm")).then();
    }, []);

    const backToSchedulePath = isUserAdmin()
        ? `${adminRoute}/schedule/${props.match.params.providerId}/view?view=list`
        : `${providerRoute}/schedule`;

    const newScheduleCreateHandler = async (requestBody, type, patientId) => {
        await createNewScheduledEvent(type, requestBody, patientId, props.timezone);
        props.history.push(backToSchedulePath);
    };

    const getSpecificDateSchedule = async (date) => {
        const startDate = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD HH:mm");
        const endDate = getNextDate(date);
        try {
            let response = await fetchSchedulesFromApi(startDate, endDate);
            updateSchedulesInState(response.schedule, response.away, date);
        } catch (err) {
            console.log(err);
        }
    };

    const updateSchedulesInState = (scheduleList, awayScheduleList, activeDate) => {
        let mergedSchedules = mergeAllSchedules(scheduleList, awayScheduleList);
        setSelectedDate(moment(activeDate, "YYYY-MM-DD HH:mm"));
        setScheduleList(mergedSchedules);
    };

    const mergeAllSchedules = (schedules, awaySchedules) => {
        let modifiedAwaySchedules = modifyAwaySchedules(awaySchedules);
        let modifiedVideoSchedules = modifyVideoSchedules(schedules);
        return modifiedVideoSchedules.concat(modifiedAwaySchedules);
    };

    const modifyVideoSchedules = (videoSchedules) => {
        return videoSchedules.map((item) => {
            item["type"] = "video_consultation";
            return item;
        });
    };

    const modifyAwaySchedules = (awaySchedules) => {
        return awaySchedules.map((item) => {
            return { ...item, type: "away" };
        });
    };

    const getNextDate = (selectedDate) => {
        return moment(selectedDate, "YYYY-MM-DD HH:mm").add(1, "days").format("YYYY-MM-DD HH:mm");
    };

    const fetchSchedulesFromApi = (start_date, end_date) => {
        const options = {
            queryParams: {
                local_start_date_time: start_date,
                local_end_date_time: end_date,
                provider_id: isUserAdmin() ? props.match.params.providerId : props.profile.username,
            },
        };
        return api.schedule.get_schedule({ options });
    };

    return (
        <>
            <div className={"back-to-schedule"}>
                <Link className="survey-back-link" to={backToSchedulePath}>
                    <img className="img-fluid" src={images("./common/solidBckBtn.svg")} />
                    <p className="d-inline ml-2">Back to Schedule</p>
                </Link>
            </div>
            <div
                className={"add-appointment position-relative mt-4 pr-5 pl-5"}
                data-public={"true"}>
                <span
                    style={{ "border-bottom": "#E5E4E4 solid 1px" }}
                    className="pb-4 pt-5 fs-18 fw-bold">
                    Add Appointment
                </span>
                <ScheduleEvent
                    dismiss={() => props.history.push(backToSchedulePath)}
                    datePickerConf={datePickerConf}
                    setDatePickerConf={setDatePickerConf}
                    patientId={null}
                    startTimeSlot={startTimeSlot}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    updateStartTimeSlot={(slotValue) => setStartTimeSlot(slotValue)}
                    onNewScheduleCreate={newScheduleCreateHandler}
                    existingConflicts={getExistingConflicts(scheduleList, selectedDate)}
                    newFormat={true}
                    getSpecificDateSchedule={getSpecificDateSchedule}
                    selectedDateHandler={(date) => setSelectedDate(date)}
                />
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        timezone: state.auth.timezone,
        profile: state.auth.profile,
    };
};

export default withRouter(connect(mapStateToProps)(AddAppointment));
