import React, { Component } from "react";
import { api } from "../../../APIRequests";
import PropTypes from "prop-types";

/**
 * @param {string} flagName
 * @param {boolean} enabled
 * @param {boolean} hasLoaded whether or not the flag is ready to be evaluated
 * prevents flashing between disabled and enabled state
 * @param {Component} alternateFlagDisabledBehavior the component to show if the flag is disabled
 * @param {boolean} defaultFlagBehavior if this value is true and the flagName is not in the
 * feature flag object that contains whether a flag is enabled/disabled the flag is enabled by default.
 */
export class FeatureGate extends Component {
    componentDidCatch(error, errorInfo) {
        const data = { flag_name: this?.props?.flagName, enabled: this?.props?.enabled };
        const options = { params: { showLoader: false } };
        api.featureFlag.post_feature_flag_error({ data, options });
        throw error;
    }

    renderDisabled = (<></>);

    render() {
        if (!this.props?.hasLoaded) {
            return this.renderDisabled;
        }
        const disabledBehavior = this.props?.alternateFlagDisabledBehavior ?? this.renderDisabled;

        const isEnabled =
            this?.props?.enabled ||
            (this?.props?.enabled === undefined && this?.props?.defaultFlagBehavior);

        return isEnabled ? this.props?.children : disabledBehavior;
    }
}

FeatureGate.propTypes = {
    flagName: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    alternateFlagDisabledBehavior: PropTypes.element.isRequired,
    hasLoaded: PropTypes.bool.isRequired,
    defaultFlagBehavior: PropTypes.bool.isRequired,
    children: PropTypes.element.isRequired,
};

export default FeatureGate;
