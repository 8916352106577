// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import images from "../../utils/images";

class Home extends Component {
    navStyles = {
        height: "60px",
        background: "white",
        boxShadow: "2px 2px 8px rgba(0,0,0,0.1)",
    };
    menuStyle = {
        borderRadius: "6px",
        padding: "10px 20px",
        background: "#0CD0A7",
        color: "white",
    };

    style = {
        color: "#0CD0A7",
        fontWeight: "100",
    };

    span = {
        color: "#08202C",
        fontWeight: "bold",
    };

    render() {
        return (
            <Fragment>
                <nav style={this.navStyles} className="d-flex align-items-center px-2 px-lg-5">
                    <ul className="mr-auto">
                        <li>
                            <img className="pl-3" alt="logo" src={images("./common/logo.svg")} />
                        </li>
                    </ul>
                    <ul className="ml-auto">
                        <li className="m-0">
                            <Link style={this.menuStyle} to="/auth/sign-in">
                                Login
                            </Link>
                        </li>
                    </ul>
                </nav>
                <main>
                    <div className="d-flex align-items-center justify-content-center mt-5 h-100">
                        <h1 style={this.style}>
                            <b>Welcome to </b>
                            <span style={this.span}>aptihealth</span>
                        </h1>
                    </div>
                </main>
            </Fragment>
        );
    }
}

export default Home;
