import React, { useState } from "react";
import PostSessionSurvey from "./PostSessionSurvey";
import PostSessionsSurveyConfirmation from "./PostSessionsSurveyConfirmation";
import PostSessionSurveyTutorial from "./PostSessionSurveyTutorial";
import { connect } from "react-redux";

const PostSessionSurveyContainer = (props) => {
    let initalStep = "postSessionSurveyTutorial";
    if (props.profile.pss_tutorial) {
        initalStep = "postSessionSurvey";
    }
    const [activeStep, setActiveStep] = useState(initalStep);

    const steps = {
        postSessionSurveyTutorial: (
            <PostSessionSurveyTutorial
                {...props}
                submit={() => setActiveStep("postSessionSurvey")}
            />
        ),
        postSessionSurvey: (
            <PostSessionSurvey
                {...props}
                submit={() => setActiveStep("postSessionsSurveyConfirmation")}
            />
        ),
        postSessionsSurveyConfirmation: <PostSessionsSurveyConfirmation />,
    };

    return <>{steps[activeStep]}</>;
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps)(PostSessionSurveyContainer);
