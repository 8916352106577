// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import Profile from "../../../components/Patient/Profile";
import { api } from "../../../APIRequests";
import { connect } from "react-redux";
import { setDynamicRouteConfiguration } from "../../../redux/actions/navbar";
import ProfileLoader from "../../../components/Common/Loaders/ProfileLoader";
import { loadPatientInsuranceImagesUrlToState } from "./PatientInsurance";

class PatientProfile extends Component {
    state = {
        patientProfile: null,
        patientAvatar: null,
        insuranceImageUrls: {
            pfUrl: null,
            pbUrl: null,
            sfUrl: null,
            sbUrl: null,
        },
    };

    componentDidMount() {
        const dynamicRouteConfig = {};
        dynamicRouteConfig["logoSection"] = { title: "Profile" };
        this.props.setDynamicRouteConfiguration(dynamicRouteConfig);
        this.getProfile();
    }
    componentWillUnmount() {
        this.props.setDynamicRouteConfiguration({});
    }

    getProfile = async () => {
        try {
            const options = { params: { showLoader: false } };
            let response = await api.patient.fetch_profile({ options });
            this.setState({
                patientProfile: response && response.user,
            });
            loadPatientInsuranceImagesUrlToState(response.user, this);
        } catch (err) {
            console.log(err);
        }
    };

    render() {
        return (
            <div className="container px-3">
                <h1 className="d-none d-lg-block fs-22 fw-bold my-4 py-2">Profile</h1>
                {this.state.patientProfile ? (
                    <Profile
                        profileData={this.state.patientProfile}
                        insuranceImageUrls={this.state.insuranceImageUrls}
                        reloadImg={(user) => loadPatientInsuranceImagesUrlToState(user, this)}
                    />
                ) : (
                    <ProfileLoader />
                )}
            </div>
        );
    }
}

export default connect(null, { setDynamicRouteConfiguration })(PatientProfile);
