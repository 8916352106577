// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import CardSecondary from "../../UI/Card/CardSecondary";

const AptihealthTips = (props) => {
    const { aptiTips } = props;
    return (
        <CardSecondary className="AptihealthTipsCard py-4 px-3 px-lg-4">
            <p className="fs-18 pb-1">
                <strong>{aptiTips.heading}</strong>
            </p>
            <p className="mb-2 fs-14">{aptiTips.sub_heading}</p>
            <p className="aptiTips-content fs-14">{aptiTips.content}</p>
        </CardSecondary>
    );
};

export default AptihealthTips;
