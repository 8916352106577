// Copyright aptihealth, inc. 2019 All Rights Reserved

import * as yup from "yup";
import { yup_password_factory } from "../../../../utils/yupSchemaUtils";
import { STATES } from "../../../../constants/states";

export const PROVIDER_INITIAL_VALUES = {
    name: "",
    Username: "",
    cp_credentials: "",
    npi_number: "",
    mobile: "",
    provider_type: "",
    practice: "",
    address: "",
};

export const PROVIDER_VALIDATION_SCHEMA = yup.object().shape({
    name: yup.string().required("Name is required"),
    Username: yup.string().email("Enter a valid email address").required("Email is required"),
    Password: yup_password_factory(),
    ConfirmPassword: yup
        .string()
        .required("Confirm password is required")
        .oneOf([yup.ref("Password"), null], "Password does not match"),
    cp_credentials: yup.string().required("CP credentials are required."),
    provider_type: yup.string().required("Provider type is required."),
    npi_number: yup.string().matches(/^\d{10}$/, "NPI number must be 10 digits long"),
    mobile: yup.string().matches(/^\d{10}$/, "Mobile Number must be a valid US number"),
    address: yup.string().required("Address is required"),
});

export const BUILD_PROVIDER_SCHEMA = (isIdpEnabled = false) => {
    let Schema = [
        {
            name: "name",
            elementType: "input",
            elementConfig: {
                type: "text",
                label: "Name",
                placeholder: "Name",
            },
        },
        {
            name: "Username",
            elementType: "input",
            elementConfig: {
                type: "text",
                label: "Email",
                placeholder: "Email",
            },
        },
        {
            name: "practice",
            elementType: "input",
            disabled: true,
            elementConfig: {
                type: "text",
                label: "Practice",
                placeholder: "Practice",
            },
        },
        {
            name: "cp_credentials",
            elementType: "input",
            elementConfig: {
                type: "text",
                label: "CP Credentials",
                placeholder: "CP Credentials",
            },
        },
        {
            name: "npi_number",
            elementType: "input",
            elementConfig: {
                type: "text",
                label: "NPI Number",
                placeholder: "NPI Number",
            },
        },
        {
            name: "mobile",
            elementType: "input",
            elementConfig: {
                type: "mobile",
                label: "Mobile",
                placeholder: "Mobile",
            },
        },
        {
            name: "provider_type",
            elementType: "select",
            elementConfig: {
                options: [
                    { value: "PCP", display: "Primary Care Provider" },
                    { value: "CARE_MANAGER", display: "Care Manager" },
                    { value: "BEHAVIORAL", display: "Behavioral Specialist" },
                    { value: "BEHAVIORAL_INTAKE", display: "Intake Behavioral Specialist" },
                    { value: "PRESCRIBE", display: "Prescriber" },
                    { value: "PEER", display: "Peer" },
                    { value: "CASE_MANAGER", display: "Case Manager" },
                ],
                label: "Provider Type",
                placeholder: "Select provider type",
            },
        },
        {
            name: "accept_new_patients",
            elementType: "input",
            elementConfig: {
                type: "checkbox",
                label: "Receive New Patients",
            },
        },
        {
            elementType: "segment",
            displayName: "Address",
        },
        {
            name: "address.street",
            elementType: "input",
            elementConfig: {
                type: "text",
                label: "Street",
                placeholder: "Enter Street",
            },
        },
        {
            name: "address.city",
            elementType: "input",
            elementConfig: {
                type: "text",
                label: "City",
                placeholder: "Enter City",
            },
        },
        {
            name: "address.state",
            elementType: "select",
            elementConfig: {
                options: STATES,
                label: "State",
                placeholder: "Enter State",
            },
        },
        {
            name: "address.zip_code",
            elementType: "input",
            elementConfig: {
                type: "text",
                label: "Zip",
                placeholder: "Enter zip",
            },
        },
        {
            name: "intake_practice_ids",
            elementType: "react-select",
            elementConfig: {
                options: [],
                label: "Intake Coordinator For Practice(s)",
                placeholder: "Intake Coordinator For Practice(s)",
            },
        },
    ];

    if (isIdpEnabled) {
        Schema = Schema.filter((el) => el.name !== "ConfirmPassword" && el.name !== "Password");
    }
    return Schema;
};

export const BUILD_PROVIDER_VALIDATION_SCHEMA = () => {
    let yupValidationObject = {
        name: yup.string().required("Name is required"),
        Username: yup.string().email("Enter a valid email address").required("Email is required"),
        cp_credentials: yup.string().required("CP credentials are required."),
        provider_type: yup.string().required("Provider type is required."),
        npi_number: yup.string().matches(/^\d{10}$/, "NPI number must be 10 digits long"),
        mobile: yup.string().matches(/^\d{10}$/, "Mobile Number must be a valid US number"),
        address: yup.string().required("Address is required"),
    };
    return yup.object().shape(yupValidationObject);
};
