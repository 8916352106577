// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import {
    BS_INITIAL_VALUES,
    BS_SCHEMA,
    P_INITIAL_VALUES,
    P_SCHEMA,
} from "./schema/newNoteFormSchema";
import { BS_VALIDATION_SCHEMA, P_VALIDATION_SCHEMA } from "./schema/newNoteValidation";
import { Form } from "formik";
import Button from "../../../../components/UI/Button";
import { api } from "../../../../APIRequests";
import { isAuthorized } from "../../../../redux/actions/auth";
import { getQueryParams } from "../../../../utils/filters";
import {
    createCommonNoteData,
    deleteNote,
    extractSessionDateData,
    getICD10Dataset,
    getNoteCard,
    getNoteFields,
    pickNoteDate,
    setBehavioralNoteData,
    setPrescriberNoteData,
} from "../../../../components/Provider/PatientView/ProgressNotes";
import moment from "moment-timezone";
import images from "../../../../utils/images";
import DeleteNote from "./DeleteNote";
import { getCompletedCalls } from "./NewNote";
import { connect } from "react-redux";
import { transformTimeSlotArray } from "../../../../components/Provider/ScheduleAvailability/ScheduleEvent";
import { timeSlotArray } from "../../../../components/Provider/ScheduleAvailability/constants";
import store from "../../../../redux/store";
import { showLoader } from "../../../../redux/actions/loader";

class ViewDraftNote extends Component {
    constructor(props) {
        super(props);
        let noteData = {};
        this.state = {
            id: this.props.match.params.noteId,
            note: null,
            behavioral: isAuthorized("provider:behavioral"),
            prescribe: isAuthorized("provider:prescribe"),
            dateContext: moment(),
            showDatePicker: false,
            patientId: this.props.match.params.patientId,
            patientName: "",
            showDeleteNote: false,
            completedCalls: [],
            isSigned: false,
            sessionFrom: "",
            sessionTo: "",
            timeSlotOptions: transformTimeSlotArray([...timeSlotArray]),
            ICD10: [],
        };
    }

    componentDidMount = async () => {
        try {
            getICD10Dataset(this);
            let urlParams = { patientId: this.state.patientId };
            const queryParams = { note_id: this.state.id };
            const response = await api.shared.fetch_patient_progress_notes({
                urlParams,
                queryParams,
            });
            let note = null;
            let newState = {};
            if (response) {
                note = response;
                newState["note"] = note;
                newState["title"] = note.title;
                newState["isSigned"] = !!note.signature;
            }
            const completedCalls = await getCompletedCalls(urlParams);
            if (note && note.call_id) {
                const call = completedCalls.find((el) => el["value"] === note.call_id);
                newState = { ...newState, ...extractSessionDateData(call.display, this, false) };
            }
            newState["completedCalls"] = completedCalls;
            this.setState(newState);
        } catch (e) {
            console.log(e);
        }
        document.addEventListener("change", this.formChangeHandler, {
            capture: true,
        });
    };

    getPickedDate = (dateString) => {
        pickNoteDate(this, dateString);
    };

    formChangeHandler = (e) => {
        if (e.target.name === "session_from") {
            this.setState({ sessionFrom: moment(e.target.value, "hh:mm a").format("HH:mm") });
        } else if (e.target.name === "session_to") {
            this.setState({ sessionTo: moment(e.target.value, "hh:mm a").format("HH:mm") });
        }
    };

    renderForm = (formikProps) => {
        this.noteData = formikProps.values;
        let schema;
        if (this.state.behavioral) {
            schema = BS_SCHEMA;
        } else {
            schema = P_SCHEMA;
        }
        const callIdIndex = schema.findIndex((el) => el["name"] === "call_id");
        if (callIdIndex) {
            schema[callIdIndex].elementConfig.options = this.state.completedCalls;
        }
        return (
            <Form>
                <div className="mx-auto px-0 ">
                    <div className="container">
                        {getNoteFields(formikProps, schema, this, true)}
                    </div>
                </div>
                <div className="d-flex justify-content-center justify-content-between ml-4 mr-4">
                    <Button
                        type="button"
                        onClick={() => this.setState({ showDeleteNote: true })}
                        className="d-none d-sm-inline Btn Btn--otl-err Btn-sm mr-3">
                        <img src={images("./icons/delete-trash.svg")} className="mr-1" alt="" />{" "}
                        Delete
                    </Button>
                    <div className="d-flex justify-content-center">
                        <Button
                            type="button"
                            onClick={() => this.submitHandler(this.noteData, {}, true)}
                            className="Btn Btn--otl-pri Btn-sm mr-3">
                            Cancel
                        </Button>
                        <Button type="submit" className="Btn Btn--pri Btn-sm">
                            Add Note
                        </Button>
                    </div>
                </div>
            </Form>
        );
    };

    submitHandler = async (formData, actions, draft) => {
        store.dispatch(showLoader());
        let data = await createCommonNoteData(this, formData, this.state.title, this.props.profile);
        if (this.state.behavioral) {
            setBehavioralNoteData(this, formData, this.state.title, data);
        } else {
            setPrescriberNoteData(this, formData, this.state.title, data);
        }

        data.id = this.state.id;
        let urlParams = { patientId: this.state.patientId };
        data.note_state = "complete";

        if (draft) {
            data.note_state = "draft";
        }

        api.provider
            .put_progress_note({ data, urlParams })
            .then((response) => {
                this.props.history.go(-1);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    setBehavioralSpecialistNote = (note) => {
        let vals = { ...BS_INITIAL_VALUES };
        vals.title = note.title;
        vals.session_date = note.session_date;
        vals.session_from = note.session_from;
        vals.session_to = note.session_to;
        vals.patient_report = note.patient_report;
        vals.patient_task = note.patient_task;
        vals.interventions = note.interventions;
        vals.intervention_reaction = note.intervention_reaction;
        vals.call_id = note.call_id ? note.call_id : "";
        vals.isSigned = !!note.signature;
        vals.ICD_axis_1 = note.ICD_10_axis_1_diagnosis;
        vals.ICD_axis_2 = note.ICD_10_axis_2_diagnosis;
        return vals;
    };

    setPrescriberNote = (note) => {
        let vals = { ...P_INITIAL_VALUES };
        vals.title = note.title;
        vals.session_date = note.session_date;
        vals.session_from = note.session_from;
        vals.session_to = note.session_to;
        vals.patient_reported = note.patient_reported;
        vals.prescriber_interventions = note.prescriber_interventions;
        vals.intervention_reaction = note.intervention_reaction;
        vals.call_id = note.call_id ? note.call_id : "";
        vals.isSigned = !!note.signature;
        vals.ICD_axis_1 = note.ICD_10_axis_1_diagnosis;
        vals.ICD_axis_2 = note.ICD_10_axis_2_diagnosis;
        return vals;
    };

    signatureClickHandler = async (e, formikProps) => {
        const isSigned = !this.state.isSigned;
        await formikProps.setFieldValue("isSigned", isSigned);
        formikProps.setFieldTouched("isSigned");
        this.setState({ isSigned: isSigned });
    };

    renderTitle = () => {
        return (
            <div className="ml-0 ml-lg-3">
                <b>{this.state.note.title}</b>
            </div>
        );
    };

    render() {
        let vals;
        let validation;
        if (this.state.note && this.state.note.provider_type.includes("BEHAVIORAL")) {
            vals = this.setBehavioralSpecialistNote(this.state.note);
        } else if (this.state.note && this.state.note.provider_type === "PRESCRIBE") {
            vals = this.setPrescriberNote(this.state.note);
        }

        if (this.state.behavioral) {
            validation = BS_VALIDATION_SCHEMA;
        } else {
            validation = P_VALIDATION_SCHEMA;
        }
        return (
            <div>
                {this.state.showDeleteNote && (
                    <DeleteNote
                        clickHandler={() =>
                            deleteNote(this, this.state.patientId, this.state.note.id)
                        }
                        close={() => this.setState({ showDeleteNote: false })}
                    />
                )}
                {this.state.note && getNoteCard(vals, validation, this)}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};
export default connect(mapStateToProps)(ViewDraftNote);
