// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";

const ListTitleBar = (props) => {
    return (
        <div
            className="List__Title-bar row no-gutters d-none d-lg-flex align-items-center justify-content-between"
            data-public="true">
            {props.children}
        </div>
    );
};

export default ListTitleBar;
