// Copyright aptihealth, inc. 2020 All Rights Reserved
import React, { useEffect } from "react";
import "../Practice/styles.scss";
import CardSecondary from "../../UI/Card/CardSecondary";
import CardHeading from "../../UI/Headings/CardHeading";
import Button from "../../UI/Button";
import { isAuthorized } from "../../../redux/actions/auth";
import { admin_cse } from "../../../config/roles";
import { toSentenceCase } from "../../../utils/filters";

const ProviderDetails = (props) => {
    let { providerDetails, onclick, existingIntakePractices } = props;

    return (
        <div>
            <CardSecondary className="mx-auto container Practice__Details">
                <div className="d-flex justify-content-between py-4">
                    <CardHeading className="fs-18 m-0 px-3 px-lg-4" text="Provider Details" />
                    <Button
                        onClick={onclick}
                        className="ProviderProfile__ProfileDetailsCard-controls cursor mr-2 fs-14 fw-bold mb-3">
                        Edit
                    </Button>
                </div>
                <hr className="m-0 d-none d-md-block" />
                <div className=" px-3 px-lg-4">
                    <div className="row no-gutters mt-4">
                        <div className="col-12 col-lg-6">
                            <CardHeading text="Provider Name" className="fs-14 mx-0 mx-md-3" />
                            <p className="fs-14 mx-0 mx-md-3 mb-4">{providerDetails.name}</p>
                        </div>
                        <div className="col-12 col-lg-6">
                            <CardHeading text="Email Address" className="fs-14 mx-0 mx-md-3" />
                            <p className="fs-14 mx-0 mx-md-3 mb-4">{providerDetails.email}</p>
                        </div>
                    </div>
                    <div className="row no-gutters mt-4">
                        <div className="col-12 col-lg-6">
                            <CardHeading text="CP Credentials" className="fs-14 mx-0 mx-md-3" />
                            <p className="fs-14 mx-0 mx-md-3 mb-4">
                                {providerDetails.cp_credentials}
                            </p>
                        </div>
                        <div className="col-12 col-lg-6">
                            <CardHeading text="NPI Number" className="fs-14 mx-0 mx-md-3" />
                            <p className="fs-14 mx-0 mx-md-3 mb-4">{providerDetails.npi_number}</p>
                        </div>
                    </div>
                    <div className="row no-gutters mt-4">
                        <div className="col-12 col-lg-6">
                            <CardHeading text="Mobile" className="fs-14 mx-0 mx-md-3" />
                            <p className="fs-14 mx-0 mx-md-3 mb-4">{providerDetails.mobile}</p>
                        </div>
                        <div className="col-12 col-lg-6">
                            <CardHeading text="Provider Type" className="fs-14 mx-0 mx-md-3" />
                            <p className="fs-14 mx-0 mx-md-3 mb-4">
                                {providerDetails.provider_type}
                            </p>
                        </div>
                    </div>
                    {providerDetails.provider_type === "BEHAVIORAL_INTAKE" && (
                        <div className="row no-gutters my-4">
                            <div className="col-12 col-lg-6">
                                <CardHeading
                                    text="Intake Coordinator For Practice(s)"
                                    className="fs-14 mx-0 mx-md-3"
                                />
                                <ul className="fs-14 mx-0 mx-md-3 mb-4">
                                    {existingIntakePractices &&
                                        existingIntakePractices.map((intakePractice) => {
                                            return <li>{intakePractice.label}</li>;
                                        })}
                                </ul>
                            </div>
                        </div>
                    )}
                    <hr className="m-0" />
                </div>
            </CardSecondary>
        </div>
    );
};

export default ProviderDetails;
