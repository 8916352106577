/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const ReportIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Report"
                {...props}>
                <path d="M10 22H19V20H10L10 22Z" />
                <path d="M19 18L10 18L10 16L19 16V18Z" />
                <path d="M12 14V12H10V14H12Z" />
                <path d="M15.5 10V14H13.5V10H15.5Z" />
                <path d="M19 14V7H17V14H19Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23 26H6V3H23V26ZM21 24V5H8V24H21Z"
                />
                <path d="M24 5H26V29H8V27H24V5Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Report"
                {...props}>
                <path d="M10 22H19V20H10L10 22Z" />
                <path d="M19 18L10 18L10 16L19 16V18Z" />
                <path d="M12 14V12H10V14H12Z" />
                <path d="M15.5 10V14H13.5V10H15.5Z" />
                <path d="M19 14V7H17V14H19Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23 26H6V3H23V26ZM21 24V5H8V24H21Z"
                />
                <path d="M24 5H26V29H8V27H24V5Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Report"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.75 26V11.75H14V26H23.75ZM22.25 13.25V18.5H20.75V13.25H22.25ZM19.625 18.5H18.125V15.5H19.625V18.5ZM17 17V18.5H15.5V17H17ZM22.25 21.5H15.5V20H22.25V21.5ZM15.5 24.5H22.25V23H15.5V24.5Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM25.25 27.5H12.5V10.25H25.25V27.5ZM27.5 11.75H26V28.25H14V29.75H27.5V11.75Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

ReportIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

ReportIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
