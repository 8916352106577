import React, { useEffect, useState } from "react";
import { ListBody, ListHeader } from "../../../UI/ListView";
import { Button, ButtonTypes, Text, TextTypes } from "../../../../component-library/";
import ConsentModal from "./ConsentModal";
import { api } from "../../../../APIRequests";
import PropTypes from "prop-types";
import { ConsentListItems } from "./ConsentListItems";
import Hr from "../../../UI/Hr";
import { connect } from "react-redux";

const MemberProfileConsentList = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [consentData, setConsentData] = useState([]);
    const [isViewOnly, setIsViewOnly] = useState(false);

    const getPatientConsent = async () => {
        const data = {};
        let urlParams = { patientId: props?.patientDetails?.username };
        const consents = await api.provider.get_patient_consents({ data, urlParams });
        setConsentData(consents);
    };

    useEffect(() => {
        const userType = props?.profile?.user_type
            ? props?.profile?.user_type
            : props?.profile?.provider_type;
        setIsViewOnly(userType !== "CSE" && userType !== "BEHAVIORAL_INTAKE");
        getPatientConsent();
    }, []);

    return (
        <ListBody classes="List__body_lg consent-list mb-3">
            <ListHeader title={"Consent"} />
            {!consentData || (consentData && consentData.length === 0) ? (
                <>
                    <div className="px-3 px-lg-4">
                        <Hr />
                    </div>
                    <div className="consent-empty-list">
                        <Text type={TextTypes.paragraph} className="font-color-gray">
                            No consents currently on file.
                        </Text>
                    </div>
                </>
            ) : (
                <ConsentListItems consentData={consentData} />
            )}
            {!isViewOnly && (
                <Button
                    buttonType={ButtonTypes.primaryOutlineV2}
                    onClick={() => setShowModal((show) => !show)}
                    className="m-3 m-lg-4">
                    Add
                </Button>
            )}
            <ConsentModal
                consentFormName={"CONSENT_TO_TREATMENT"}
                show={showModal}
                setShowModal={setShowModal}
                patientDetails={props?.patientDetails}
                refreshConsentList={getPatientConsent}
            />
        </ListBody>
    );
};

MemberProfileConsentList.propTypes = {
    patientDetails: PropTypes.object,
    profile: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps)(MemberProfileConsentList);
