import React, { Component } from "react";
import CardSecondary from "../../UI/Card/CardSecondary";
import "./styles.scss";
import { Rating } from "../../UI/Rating";
import Button from "../../UI/Button";
import { api } from "../../../APIRequests";
import images from "../../../utils/images";
import { connect } from "react-redux";
import { getPatientProfile } from "../../../redux/actions/auth";

class NPSSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rating: -1,
            comments: "",
            showSuccess: false,
        };
    }

    onNumberClick = async (rating) => {
        await this.setState({ rating });
    };

    onTextUpdate = (event) => {
        this.setState({ comments: event.target.value });
    };

    onSubmit = async () => {
        if (this.state.rating < 0) {
            return;
        }

        let data = {
            rating: this.state.rating,
            comments: this.state.comments,
        };

        await api.patient.send_nps_survey_answers({ data });

        this.setState({ showSuccess: true });

        setTimeout(() => {
            this.props.getPatientProfile();
        }, 5000);
    };

    render() {
        if (this.state.showSuccess) {
            return (
                <CardSecondary className="NPSSurveyComplete">
                    <img src={images("./icons/circle-tick-success.svg")} className="mt-5" />
                    <p className="mt-3">
                        <b>Feedback Submitted</b>
                    </p>
                    <p className="mb-5">Thank you for sharing your feedback with us!</p>
                </CardSecondary>
            );
        } else {
            return (
                <CardSecondary className="NPSSurvey">
                    <p className="mt-5">
                        <b>
                            How likely is it that you would recommend aptihealth to a friend or
                            colleague?
                        </b>
                    </p>
                    <div className="w-100">
                        <Rating
                            currentRating={-1}
                            totalRating={11}
                            onClick={this.onNumberClick}
                            type={"number"}
                        />
                        <div
                            style={{ width: 65 + "%" }}
                            className="flex-sm-row justify-content-sm-around fs-12">
                            <span className="float-left">Not likely</span>
                            <span className="float-right">Very Likely</span>
                        </div>
                    </div>

                    <p className="mt-3">
                        <b>Comments</b> (Optional)
                    </p>
                    <textarea
                        className="w-75"
                        placeholder="Write your comments here.."
                        onChange={this.onTextUpdate}
                    />
                    <div className="d-flex justify-content-center mb-3 mt-5">
                        <Button
                            type="submit"
                            className="Btn Btn--pri Btn-sm"
                            onClick={this.onSubmit}>
                            Submit
                        </Button>
                    </div>
                </CardSecondary>
            );
        }
    }
}

export default connect(null, {
    getPatientProfile,
})(NPSSurvey);
