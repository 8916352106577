import moment from "moment";
import { AVAILABLE, EVENT_BUBBLE_PADDING_CONSTANT } from "../../constants/event";
import { DateTimeWindow } from "../DateTimeWindow";

// NOTE: see Calendar/CalendarBackground/style.scss for variables
const TIME_AXIS_NOTCH = 4;
const LABEL_LINE_HEIGHT = 24;

/**
 * extract the time from a moment object
 * @param {import("moment").Moment} momentObj
 * @returns
 */
const extractTime = (momentObj) => {
    const time = momentObj.format("HH:mm");
    const timeParts = time.split(":");
    const hour = parseInt(timeParts[0]);
    const minute = parseInt(timeParts[1]);
    return {
        hour,
        minute,
    };
};

const getMinPosition = (value) => {
    return value >= 0 ? value : 0;
};

/**
 * NOTE: THIS DOES NOT INCLUDE UTC TRANSFORMATION TO BE USED FOR WORKING HOURS
 * calculates the vertical position of the event. only uses time
 * @param {import("moment").Moment} momentStart
 * @param {import("moment").Moment} momentEnd
 * @param {*} calendarRect
 * @returns
 */
const calculateVerticalPosition = (momentStart, momentEnd, calendarRect) => {
    const { hour: startHour, minute: startMinute } = extractTime(momentStart);

    const hourPosition = startHour * calendarRect.slot.height;
    const minPosition = (calendarRect.slot.height / 60) * startMinute;

    const positionTop = hourPosition + minPosition + calendarRect.calendar.top;
    const timeLength = momentEnd.diff(momentStart, "minutes");
    const height = calendarRect.slot.height * (timeLength / 60);
    return {
        top: positionTop,
        height: height,
    };
};

/**
 * calculates horiziontal position only using the dayIndex
 * @param {number} dayIndex 0 == Sunday, 1 == Monday etc.
 * @param {*} calendarRect
 * @returns
 */
const calculateHorizontalPosition = (dayIndex, calendarRect) => {
    const left = dayIndex * calendarRect.day.width + calendarRect.calendar.left;
    return {
        left: left,
        width: calendarRect.day.width,
    };
};

/**
 * Calculates the absolute positioning for a background day
 * it is likely that this cannot be reused for calendar events
 * because there appears to be small gap between the grid lines
 * and a calendar event present on the mockup
 * @param {import("moment").Moment} momentStart
 * @param {import("moment").Moment} momentEnd
 * @param {number} dayIndex
 * @param {*} calendarRect
 * @returns
 */
const calculateBackgroundDayPosition = (momentStart, momentEnd, dayIndex, calendarRect) => {
    if (!calendarRect) {
        return;
    }
    const verticalPosition = calculateVerticalPosition(momentStart, momentEnd, calendarRect);
    const horizontalPosition = calculateHorizontalPosition(dayIndex, calendarRect);
    const position = {
        ...verticalPosition,
        ...horizontalPosition,
    };

    return position;
};

/**
 * calculates the position for the current time line
 * @param {import("moment").Moment} currentTime
 * @param {*} calendarRect
 * @param {number} dayIndex
 * @returns
 */
const calculateCurrentTimePosition = (currentTime, calendarRect, dayIndex) => {
    if (!calendarRect) {
        return;
    }
    const verticalPosition = calculateVerticalPosition(currentTime, currentTime, calendarRect);
    delete verticalPosition.height;
    verticalPosition.top = verticalPosition.top - calendarRect.calendar.top;

    // if day view we want current time line the length of the calendar
    let horizontalPosition = {
        left: getMinPosition(calendarRect.calendar.left - TIME_AXIS_NOTCH),
        width: calendarRect.calendar.width + TIME_AXIS_NOTCH,
    };

    // if week view we only current time line the length of a single day
    if (dayIndex) {
        horizontalPosition = calculateHorizontalPosition(dayIndex, calendarRect);
    }

    const position = {
        ...verticalPosition,
        ...horizontalPosition,
    };

    return position;
};

/**
 * Determines if we should display current time line based on calendar view and current day
 * If yes, this returns the current time positions
 * If no, we return a null value
 *
 * @param {String} viewType
 * @param {import("moment").Moment} currentDaySelected
 * @param {[import("moment").Moment]} currentDaysWeekView
 * @param {import("moment").Moment} currentTime
 * @param {*} calendarRect
 * @returns
 */
const getCurrentTimePosition = (
    viewType,
    currentDaySelected,
    currentDaysWeekView,
    currentTime,
    calendarRect,
) => {
    if (viewType === "DAY") {
        const isToday = currentDaySelected
            ? currentDaySelected.isSame(moment().local(), "day")
            : true;

        return isToday && calculateCurrentTimePosition(currentTime, calendarRect);
    }

    if (viewType === "WEEK") {
        const dayIndex = currentDaysWeekView.findIndex((day) => {
            return day.isSame(moment().local(), "day");
        });

        return dayIndex !== -1 && calculateCurrentTimePosition(currentTime, calendarRect, dayIndex);
    }

    return false;
};

/**
 *
 * @param {*} workingHours
 * @param {import("moment").Moment[]} daysToDisplay
 * @param {*} calendarRect
 * @returns
 */
const calculateScrollPositionWorkingHours = (
    workingHours,
    daysToDisplay,
    calendarRect,
) => {
    if (!calendarRect) {
        return;
    }
    // get all starting times for valid working hours
    const allRelevantSlots = daysToDisplay
        // convert all relevant day start times to moment instances
        .map((day) => {
            const currentDay = day.format("dddd");
            const curWorkingHours = workingHours[currentDay];
            if (!curWorkingHours) {
                return null;
            }
            const allStartTimes = curWorkingHours.slots.map((slot) => {
                const start = moment(slot[0], "HH:mm");
                return start;
            });

            return allStartTimes;
        })
        // remove null values produces by the map
        .filter(Boolean)
        // turn moment[][] -> moment[]
        .flat()
        // sort in asc order
        .sort((a, b) => a.diff(b));
    // if no working hours are present we scroll to 6am
    let timeToScrollTo = moment("06:00", "HH:mm");
    // if there are elements in the list, get the earliest working hour
    if (allRelevantSlots.length) {
        timeToScrollTo = allRelevantSlots[0];
    }

    return calculateScrollPositionToTime(timeToScrollTo, calendarRect);
};

/**
 *
 * @param {import("moment").Moment} timeToScrollTo represented as HH:mm
 * @param {*} calendarRect
 */
const calculateScrollPositionToTime = (timeToScrollTo, calendarRect) => {
    if (!calendarRect) {
        return;
    }
    // calculate the earliest working hour scroll position
    const position = calculateVerticalPosition(timeToScrollTo, timeToScrollTo, calendarRect);
    const top = position.top + calendarRect.calendar.top - LABEL_LINE_HEIGHT / 2;

    return {
        top: getMinPosition(top),
    };
};

/**
 *
 * @param {object[]} events
 * @param {*} calendarRect
 */
const calculateEventPositions = (events, daysToDisplay, calendarRect) => {
    if (!calendarRect) {
        return [];
    }

    // relies on events being sorted
    const collidesWith = (positionA, positionB) => {
        const bottomA = positionA.top + positionA.height;
        const bottomB = positionB.top + positionB.height;
        return (
            bottomA > positionB.top && positionA.top < bottomB && positionB.left === positionA.left
        );
    };

    const calculatePosition = (startTime, endTime) => {
        const verticalPosition = calculateVerticalPosition(startTime, endTime, calendarRect);
        let dayIndex = startTime.day();
        if (daysToDisplay.length === 1) {
            dayIndex = 0;
        }
        const horizontalPosition = calculateHorizontalPosition(dayIndex, calendarRect);
        const position = {
            ...horizontalPosition,
            ...verticalPosition,
        };

        return position;
    };

    const sortByVerticalPosition = (eventA, eventB) => {
        if (eventA.position.left !== eventB.position.left) {
            return eventA.position.left - eventB.position.left;
        }

        const topDifference = eventA.position.top - eventB.position.top;
        return topDifference;
    };

    // separate custom availability from other events
    const customAvailability = events.filter((event) => event.details.event_type === AVAILABLE);
    const eventsExcludingAvailability = events.filter(
        (event) => event.details.event_type !== AVAILABLE,
    );

    // convert events to date time windows
    const customAvailabilityWindows = customAvailability.map((availability) => {
        return new DateTimeWindow(availability.startTime, availability.endTime, availability);
    });
    // exclude deleted events, deleted events do not lessen availability
    const eventWindowsExcludingAvailability = eventsExcludingAvailability
        .filter((event) => !event.details.deleted)
        .map((event) => {
            return new DateTimeWindow(event.startTime, event.endTime);
        });

    // reduce the availability windows by the event window
    // before we attach the position so we only display the get the minimum availability
    const newAvailabilityWindows = DateTimeWindow.reduceWindowsAByWindowsB(
        customAvailabilityWindows,
        eventWindowsExcludingAvailability,
    );

    // attach positions to events
    const eventsExcludingAvailabilityWithPosition = eventsExcludingAvailability.map((event) => {
        const position = calculatePosition(event.startTime, event.endTime);
        return {
            ...event,
            position,
        };
    });

    const newAvailabilityEventsWithPositions = newAvailabilityWindows
        // convert the windows back to events
        .map((dateTimeWindow) => {
            return {
                ...dateTimeWindow.information,
                startTime: dateTimeWindow.start,
                endTime: dateTimeWindow.end,
            };
        })
        // attach a position to the event
        .map((event) => {
            const position = calculatePosition(event.startTime, event.endTime);
            return {
                ...event,
                position,
            };
        });

    const eventGroups = [];
    // divide events into groups
    eventsExcludingAvailabilityWithPosition.sort(sortByVerticalPosition).forEach((event) => {
        const group = eventGroups.find((group) => {
            return collidesWith(event.position, group.position);
        });

        // if no group was found then we add a new event group
        if (!group) {
            eventGroups.push({
                events: [event],
                startTime: event.startTime,
                endTime: event.endTime,
                position: event.position,
            });
        }
        // if a group was found add to the group
        else {
            group.events.push(event);
            if (event.startTime < group.startTime) {
                group.startTime = event.startTime;
            }

            if (event.endTime > group.endTime) {
                group.endTime = event.endTime;
            }

            const newGroupPosition = calculatePosition(group.startTime, group.endTime);
            group.position = newGroupPosition;
        }
    });

    // assign availability to their appropriate groups
    newAvailabilityEventsWithPositions.sort(sortByVerticalPosition).forEach((availability) => {
        // find all groups that have overlap
        const groups = eventGroups.filter((group) => {
            return collidesWith(availability.position, group.position);
        });

        // if no groups are found create a new group
        if (groups.length === 0) {
            eventGroups.push({
                events: [availability],
                startTime: availability.startTime,
                endTime: availability.endTime,
                position: availability.position,
            });
        }
        // if at least one group was found
        // add the availability to the group
        // NOTE: it is important that available events exist at the end of the list so that it does
        // not throw off group calculations
        else {
            for (const group of groups) {
                group.events.push(availability);
            }
        }
    });

    // update the events with their correct positioning
    const updatedEventGroups = eventGroups.map((eventGroup) => {
        const numberOfEvents = eventGroup.events.length;
        // availability slots are included as part of the background
        // and should not impact the display of the group
        const numberOfAvailabilitySlots = eventGroup.events.reduce((accumulator, current) => {
            const isAvailable = current.details.event_type === AVAILABLE;
            return accumulator + Number(isAvailable);
        }, 0);
        // if there are more than three events in a group,
        // the group will be displayed as a single event
        // and the event positions are irrelevant
        if (numberOfEvents - numberOfAvailabilitySlots > 3) {
            eventGroup.position.width = eventGroup.position.width - EVENT_BUBBLE_PADDING_CONSTANT;
            return eventGroup;
        }

        // otherwise line the events side by side
        const widthOfEvent =
            calendarRect.day.width / (numberOfEvents - numberOfAvailabilitySlots) -
            EVENT_BUBBLE_PADDING_CONSTANT / numberOfEvents;
        const newEvents = eventGroup.events.map((event, index) => {
            const newEvent = { ...event };

            if (event.details.event_type === AVAILABLE) {
                newEvent.position = {
                    ...event.position,
                    width: calendarRect.day.width - EVENT_BUBBLE_PADDING_CONSTANT,
                    left: newEvent.position.left,
                };
            } else {
                newEvent.position = {
                    ...event.position,
                    width: widthOfEvent,
                    left: newEvent.position.left + widthOfEvent * index,
                };
            }

            return newEvent;
        });
        eventGroup.events = newEvents;

        return eventGroup;
    });

    return updatedEventGroups;
};

export {
    calculateVerticalPosition,
    calculateHorizontalPosition,
    calculateBackgroundDayPosition,
    calculateCurrentTimePosition,
    calculateScrollPositionWorkingHours,
    calculateEventPositions,
    getCurrentTimePosition,
    calculateScrollPositionToTime,
};
