// Copyright aptihealth, inc. 2019 All Rights Reserved
import moment from "moment-timezone";
import * as yup from "yup";
import { CATCH_ALL_INSURANCE_OPTIONS } from "../constants/insurnaceIconMap";

/**
 * @param {string} format - the date format which the input field `value` stores. Eg MM/DD/YYYY
 */
const formatDate = function (format) {
    return this.transform(function (value, originalValue) {
        value = moment(originalValue, format, true);
        if (!originalValue || !originalValue.trim()) {
            return null;
        }
        return value.toDate();
    });
};

const standardDobValidationSchemaFactory = (format, errorMap, min, max) => {
    return yup
        .date()
        .nullable()
        .typeError(errorMap["default"])
        .min(min, errorMap["min"])
        .max(max, errorMap["max"])
        .format(format);
};

const dobValidationSchemaFactory = function (
    format,
    errMsg = "Must be a valid date",
    min = "01/01/1900",
) {
    return yup
        .date()
        .nullable()
        .typeError(errMsg)
        .min(min, errMsg)
        .max(new Date(), errMsg)
        .format(format);
};

const adultDobValidationSchemaFactory = function (
    format,
    errMsg = "Must be a valid date",
    adultErrorMsg = "",
) {
    const adultMaximum = moment().subtract(18, "years").toDate();

    return yup
        .date()
        .nullable()
        .typeError(errMsg)
        .min("01/01/1880", errMsg)
        .max(adultMaximum, adultErrorMsg)
        .format(format);
};

const noInsuranceCarrierValidationSchemaFactory = function (fieldName, validation, isDate = false) {
    let baseValidation = yup.string();

    if (isDate) {
        baseValidation = yup.date();
    }

    return baseValidation.when(fieldName, {
        is: (carrier) => {
            return CATCH_ALL_INSURANCE_OPTIONS.includes(carrier);
        },
        then: baseValidation.optional().nullable(),
        otherwise: validation,
    });
};

const PASSWORD_REGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}()?“!@#%&/\\,><’:;|_~`=+\-])\S{8,99}$/;

const EXCEPT_SPECIAL_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S{8,99}$/;

const PASSWORD_REQUIREMENTS_FUN = (failed) => {
    let currentValue = failed.value;

    if (currentValue.match(EXCEPT_SPECIAL_PASSWORD_REGEX)) {
        return (
            "Password must contain valid special character from this list:" +
            " ^ $ * . [ ] { } ( ) ? \" ! @ # % & / \\ , > < ' : ; | _ ~ ` = + -"
        );
    }

    return (
        "Password must contain at least eight characters: " +
        "one uppercase, one lowercase, a digit, and one special character."
    );
};

const yup_password_factory = (requiredMessage = "Password is required") =>
    yup.string().required(requiredMessage).matches(PASSWORD_REGEX, PASSWORD_REQUIREMENTS_FUN);

export {
    formatDate,
    dobValidationSchemaFactory,
    adultDobValidationSchemaFactory,
    yup_password_factory,
    standardDobValidationSchemaFactory,
    noInsuranceCarrierValidationSchemaFactory,
};
