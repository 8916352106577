export const ADHERENCE_INTERVENTIONS_QUESTIONS = {
    benefits: {
        label: "Explain Benefits",
        answer: false,
        order: 1,
    },
    costs: {
        label: "Solve Costs/Availability",
        answer: false,
        order: 2,
    },
    side_effects: {
        label: "Discuss Side Effects",
        answer: false,
        order: 3,
    },
    shame: {
        label: "Address Shame/Stigma",
        answer: false,
        order: 4,
    },
    forgetting: {
        label: "Address Forgetting",
        answer: false,
        order: 5,
    },
    routine: {
        label: "Help Establish Routine",
        answer: false,
        order: 6,
    },
    dependency: {
        label: "Reassurance of Dependency",
        answer: false,
        order: 7,
    },
    repeat: {
        label: "Patient Repeat Instructions",
        answer: false,
        order: 8,
    },
};

export const ADHERENCE_LABEL_MAP = {
    0: "Less than 20%",
    1: "About 40%",
    2: "About 60%",
    3: "About 80%",
    4: "Almost 100%",
};
