import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import images from "../../../utils/images";
import _ from "lodash";
import moment from "moment";
import { Button } from "../../../component-library";
import { createClassNameString } from "../../../component-library";
import { updateSelectedDate, toggleDatePicker } from "../../../redux/actions/calendar";
import { connect } from "react-redux";

const ScheduleNavControls = ({ className, selectedDay, ...props }) => {
    const getToday = () => {
        const selectedDate = moment();
        props.updateSelectedDate(selectedDate);
    };

    /**
     * gets the previous day or week depending on the calendars view type
     */
    const getPrev = () => {
        const clonedSelectedDay = _.cloneDeep(props.calendar.selectedScheduleDate);
        clonedSelectedDay.subtract(props.calendar.viewType === "WEEK" ? 7 : 1, "day");
        props.updateSelectedDate(clonedSelectedDay);
    };

    /**
     * gets the next day or week depending on the calendars view type
     */
    const getNext = () => {
        const clonedSelectedDay = _.cloneDeep(props.calendar.selectedScheduleDate);
        clonedSelectedDay.add(props.calendar.viewType === "WEEK" ? 7 : 1, "day");
        props.updateSelectedDate(clonedSelectedDay);
    };

    return (
        <div className={createClassNameString(["apti-CL apti-ScheduleNavControls", className])}>
            <div className={createClassNameString(["apti-ScheduleNavControls--controls"])}>
                <Button
                    className={createClassNameString([
                        "apti-ScheduleNavControls--controls-today mr-3",
                    ])}
                    onClick={getToday}
                    buttonType={"primary-outline"}>
                    Today
                </Button>

                <button
                    className={createClassNameString([
                        "apti-ScheduleNavControls--controls-group-item",
                    ])}
                    onClick={getPrev}>
                    <img
                        src={images("./icons/Chevron_Left.svg")}
                        className={"apti-ScheduleNavControls--icon"}
                        alt="previous"
                    />
                </button>

                <button
                    className={createClassNameString([
                        "apti-ScheduleNavControls--controls-group-item",
                    ])}
                    onClick={getNext}>
                    <img
                        src={images("./icons/Chevron_Right.svg")}
                        className={"apti-ScheduleNavControls--icon"}
                        alt="next"
                    />
                </button>

                <button
                    className={createClassNameString([
                        "apti-ScheduleNavControls--controls-group-item",
                    ])}
                    onClick={props.toggleDatePicker}>
                    <img
                        className={createClassNameString([
                            "apti-ScheduleNavControls--controls-group-item-calendar apti-ScheduleNavControls--icon",
                        ])}
                        src={images("./icons/calendar_outline.svg")}
                        alt="calender"
                    />
                </button>
            </div>
        </div>
    );
};

ScheduleNavControls.propTypes = {
    className: PropTypes.string,
    selectedDay: PropTypes.instanceOf(moment),
};

ScheduleNavControls.defaultProps = {
    className: undefined,
    selectedDay: moment(),
};

const mapStateToProps = (state) => {
    return {
        calendar: state.calendar,
    };
};

const mapDispatchToProps = {
    updateSelectedDate,
    toggleDatePicker,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleNavControls);
