import { http, httpAuth } from "../utils/axios";

const featureFlagRequests = {
    get_feature_flag_set: function ({ data, options }) {
        let conf = {
            method: "post",
            url: "/v1/feature_flag/public",
            data,
            ...options,
        };
        return http(conf);
    },
    get_all_targetted_feature_flags: function ({ data, options }) {
        let conf = {
            method: "post",
            url: "/v1/feature_flag/private",
            data,
            ...options,
        };
        return httpAuth(conf);
    },
    post_feature_flag_error: function ({ data, options }) {
        let conf = {
            method: "post",
            url: "/v1/feature_flag/error",
            data,
            ...options,
        };
        return httpAuth(conf);
    },
};

export default featureFlagRequests;
