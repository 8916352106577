// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as actionTypes from "./actionTypes";
import { getGroupAuthType } from "./auth";
import { httpAuth } from "../../utils/axios";
import { GROUP_AUTH_HEADER_NAME } from "../../constants/axios";

export const updateCrisisRiskPatients = (data) => {
    return {
        type: actionTypes.UPDATE_CRISIS_RISK_REPORT,
        payload: {
            practiceId: data.practiceId,
            reports: data.reports,
            lastEvaluatedKey: data.last_evaluated_key,
        },
    };
};

export const fetchCrisisRiskPatients = ({ data, options, dispatch } = {}) => {
    const url = "/v1/admin/get_patients_with_crisis_risk";

    let conf = {
        url,
        method: "post",
        ...options,
        data,
        headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
    };
    return httpAuth(conf).then((response) => {
        dispatch(
            updateCrisisRiskPatients({
                ...response,
                practiceId: data.practiceId,
            }),
        );

        return response;
    });
};
