import React from "react";
import images from "../../../../utils/images";
import { createClassNameString } from "../../../../component-library";
import { useHistory } from "react-router-dom";

export const BackToPatientProfile = ({ patientId, className }) => {
    const history = useHistory();
    return (
        <div
            className={createClassNameString([
                "survey-back-link-wpr mr-auto fs-16 my-2 p-3 pl-lg-0 pr-lg-0",
                className,
            ])}>
            <span
                className="survey-back-link"
                onClick={() =>
                    history.push(`/app/patient/${patientId}/profile/v2?selectedTab=Notes`)
                }>
                <img className="img-fluid" src={images("./common/solidBckBtn.svg")} alt={"<"} />
                <p className="d-inline ml-2">Back to Patient Profile</p>
            </span>
        </div>
    );
};
