import React from "react";
import { createClassNameString } from "../../../../component-library";
import PropTypes from "prop-types";

export const memberProfileBadgeColorTypes = {
    active: "PatientProfile__ActiveStatus",
    inactive: "PatientProfile__InactiveStatus",
    waitListed: "PatientProfile__WaitListStatus",
    mostRecent: "PatientProfile__MostRecent",
};

export const MemberProfileHeaderBadge = ({ text, type }) => {
    return (
        <div className={createClassNameString(["PatientProfile__StatusBadge", type])}>{text}</div>
    );
};

MemberProfileHeaderBadge.propTypes = {
    text: PropTypes.string,
    type: PropTypes.oneOf([
        "PatientProfile__ActiveStatus",
        "PatientProfile__InactiveStatus",
        "PatientProfile__WaitListStatus",
        "PatientProfile__MostRecent",
    ]),
};

MemberProfileHeaderBadge.defaultProps = {
    text: "",
    type: "",
};
