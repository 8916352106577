// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import "../styles.scss";
import { VideoViewBase } from "../index";
import LocalVideo from "./LocalVideo";
import RemoteVideo from "./RemoteVideo";

export const PatientInRoomStep = (props) => {
    let content = (
        <>
            <div className="GuidedVideoCall__whole_screen_view">
                <RemoteVideo {...props} fullscreenMode={true} />
                <LocalVideo {...props} />
            </div>
        </>
    );
    return <VideoViewBase content={content} {...props} />;
};
