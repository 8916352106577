import React, { useState } from "react";

const LocalVideo = (props) => {
    const { localMedia } = props;
    let [hideSelfView, setHideSelfView] = useState(false);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    React.useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }

        window.addEventListener("resize", handleResize);

        return (_) => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    React.useLayoutEffect(() => {
        props.attachLocalTracks();
    }, []);

    let hideSelfViewBtn = (
        <div
            className={`d-none d-lg-block fw-bold NDCPG__text-gry ${hideSelfView ? "hide" : ""}`}
            onClick={() => setHideSelfView(!hideSelfView)}>
            {hideSelfView ? "Show" : "Hide"}
        </div>
    );

    let remote = props.remoteVideoRef.current;
    let placeholder = props.remoteVideoPlaceholderRef.current;
    let localVideoStyle = {};
    let hideStyle = {};
    if (remote || placeholder) {
        let ref = remote ? remote : placeholder;
        let boundingClient = ref.getBoundingClientRect();

        if (boundingClient.width > 578) {
            let width = Math.max(boundingClient.width / 4, 0);
            localVideoStyle = {
                left: boundingClient.x + 10,
                width: width,
            };
        }
    }

    return (
        <div
            className={`GuidedVideoCall__local ${hideSelfView ? "hide" : ""}`}
            style={localVideoStyle}>
            <div className="GuidedVideoCall__local_inner position-relative">
                {localMedia}
                <div className="GuidedVideoCall__local_btn_div" style={hideStyle}>
                    {hideSelfViewBtn}
                </div>
            </div>
        </div>
    );
};

export default React.memo(LocalVideo);
