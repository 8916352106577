// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { useEffect } from "react";
import images from "../../../utils/images";
import { withRouter, Link } from "react-router-dom";
import moment from "moment-timezone";
import { providerRoute } from "../../../config/roles";

const AwaySchedulePopUp = (props) => {
    const { details, duration, position, dismiss, showDeletePopUp, history } = props;
    const styles = {
        top: position ? `${position.top}` : "50%",
        left: position ? `${position.left}` : "50%",
        bottom: position ? `${position.bottom}` : "auto",
        transform: !position && "translate(-50%,-50%)",
    };
    const onDocumentClick = (e) => {
        let isClickFromActiveScheduleBtn = e.target.closest(".DailyWidget__timeslot-active-btn");
        let isClickFromDeletePopUp = e.target.closest(".ScheduleDeleteDialog");
        let isClickFromAwayPopUp = e.target.closest(".AwaySchedulePopUp");
        if (!isClickFromActiveScheduleBtn && !isClickFromAwayPopUp && !isClickFromDeletePopUp) {
            dismiss();
        }
    };

    useEffect(function () {
        document.addEventListener("click", onDocumentClick);
        /** detach click listener on unmoount */
        return function () {
            document.removeEventListener("click", onDocumentClick);
        };
    }, []);

    let scheduleDate = moment.utc(details.timestamp, "YYYY-MM-DD HH:mm").local();

    const onDeleteHandler = () => {
        let splittedTs = details.timestamp.split(" ");
        const slotToDelete = {
            date: splittedTs[0],
            slot: splittedTs[1],
        };
        showDeletePopUp(slotToDelete);
    };
    const controls = (
        <div className="AwaySchedulePopUp__header d-flex align-items-center justify-content-between">
            <div className="AwaySchedulePopUp__header-task-name  px-3 py-1 text-center fs-12 fs-lg-14 fw-bold">
                <span>Away</span>
            </div>
            <div className="AwaySchedulePopUp__header-controls-wpr d-flex align-items-center">
                <button
                    onClick={onDeleteHandler}
                    className="AwaySchedulePopUp__header-delete-btn"
                    type="button"></button>
                <button
                    onClick={dismiss}
                    className="AwaySchedulePopUp__header-close-btn"
                    type="button"
                />
            </div>
        </div>
    );

    const providerDetails = (
        <div className="AwaySchedulePopUp__patient-details my-4">
            <div>
                <img width="30" src={images("./common/avatar.png")} alt="patient's avatar" />
                <span className="ml-3 fs-14 fs-lg-18 fw-bold">{details.provider_name}</span>
            </div>
            <div className="fs-10 fs-lg-14 PSA__text-gry mt-2">
                <span className="AwaySchedulePopUp__call-date position-relative mr-4">
                    {scheduleDate.format("MMMM DD, YYYY")}
                </span>
                <span>{duration}</span>
            </div>
        </div>
    );
    return (
        <div style={styles} className="AwaySchedulePopUp p-3">
            {controls}
            {providerDetails}
        </div>
    );
};

export default withRouter(AwaySchedulePopUp);
