import React, { Component } from "react";
import { ENCOUNTER_SCHEMA } from "./EncounterSchema";
import FormikInput from "../../../../../UI/FormikInput";
import { cloneDeep } from "lodash";
import DatePickerWithDisplay from "../../../../../UI/DatePickerWithDisplay";
import images from "../../../../../../utils/images";

class EncounterForm extends Component {
    schema = cloneDeep(ENCOUNTER_SCHEMA(this.props.encounterIndex));

    render() {
        const encounter =
            this.props.formikProps.values.content.encounters[this.props.encounterIndex];
        const firstActiveEncounterIndex =
            this.props.formikProps.values.content.encounters.findIndex(
                (encounter) => !encounter.delete_marker,
            );
        return (
            <>
                {!encounter.delete_marker && (
                    <div className={"row m-0"}>
                        <div className="border-bottom col-12 my-2" />
                        <div
                            className={
                                this.props.encounterIndex === firstActiveEncounterIndex
                                    ? "d-none"
                                    : "w-100 d-flex justify-content-end"
                            }>
                            <div
                                className="close-button"
                                onClick={() =>
                                    this.props.removeEncounter(
                                        this.props.formikProps,
                                        this.props.encounterIndex,
                                    )
                                }>
                                <img src={images("./icons/grey-x-circle.svg")} alt={"Delete"} />
                            </div>
                        </div>
                        {this.schema.map((formEl) => {
                            if (formEl.elementConfig.type === "checkbox") {
                                formEl.onclick = (name, option) =>
                                    this.props.optionClickHandler(
                                        name,
                                        option,
                                        encounter[formEl.relativeName],
                                        this.props.formikProps,
                                    );

                                const openTextOption = formEl.elementConfig.options.find((option) =>
                                    option.hasOwnProperty("openText"),
                                );
                                if (openTextOption) {
                                    openTextOption.onChange = (e) => {
                                        const val = e.target.value;
                                        this.props.setFieldValue(
                                            `content.encounters[${this.props.encounterIndex}].setting_other`,
                                            val,
                                        );
                                    };
                                }
                            }

                            if (formEl.elementType === "datePicker") {
                                return (
                                    <div key={formEl.name} className={formEl.elementClasses}>
                                        <DatePickerWithDisplay
                                            formikProps={this.props.formikProps}
                                            formEl={formEl}
                                            dateStr={encounter[formEl.relativeName]}
                                            dateClickHandler={this.props.dateClickHandler}
                                        />
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={formEl.name} className={formEl.elementClasses}>
                                        <FormikInput
                                            formEl={formEl}
                                            errors={this.props.formikProps.errors}
                                            touched={this.props.formikProps.touched}
                                        />
                                    </div>
                                );
                            }
                        })}
                    </div>
                )}
            </>
        );
    }
}

export default EncounterForm;
