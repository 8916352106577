// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { Form, getIn } from "formik";
import FormikInput from "../../../../components/UI/FormikInput";
import { convertStatus, INITIAL, OUTCOME } from "./ClinicalAssessment";
import {
    INITIAL_CLINICAL_DIAGNOSIS_SCHEMA,
    OUTCOME_CLINICAL_DIAGNOSIS_SCHEMA,
} from "./schema/clinicalAssessmentFormSchema";
import {
    INITIAL_CLINICAL_DIAGNOSIS_VALIDATION_SCHEMA,
    OUTCOME_CLINICAL_DIAGNOSIS_VALIDATION_SCHEMA,
} from "./schema/clinicalAssessmentValidation";
import "./styles.scss";
import Select from "react-select";
import Label from "../../../../components/UI/Label";
import Button from "../../../../components/UI/Button";
import makeAnimated from "react-select/animated";
import InputError from "../../../../components/UI/InputError";
import { api } from "../../../../APIRequests";
import { getCompletedCalls } from "./NewNote";
import _ from "lodash";
import { CustomForm } from "../../../../component-library/CustomForm";

const animatedComponents = makeAnimated();

class ClinicianDignosis extends Component {
    constructor(props) {
        super(props);

        let formSchema,
            formValidation,
            initialVals = props.vals;
        if (!initialVals) {
            initialVals = {
                overall_assessment_impressions: "",
                diagnostic_impressions: "",
                recommendations: "",
            };
        }
        switch (props.type) {
            case INITIAL: {
                formSchema = INITIAL_CLINICAL_DIAGNOSIS_SCHEMA;
                formValidation = INITIAL_CLINICAL_DIAGNOSIS_VALIDATION_SCHEMA;
                initialVals["enroll"] = initialVals["enroll"] ? initialVals["enroll"] : "";
                initialVals["call_id"] = initialVals["call_id"] ? initialVals["call_id"] : "";
                initialVals["additional_diagnostic_activities_placeholder"] =
                    this.getActivityPlaceholderValues(initialVals);
                initialVals["ICD_10_diagnosis_axis_1"] = initialVals["ICD_10_diagnosis_axis_1"]
                    ? initialVals["ICD_10_diagnosis_axis_1"]
                    : [];
                break;
            }
            case OUTCOME: {
                formSchema = OUTCOME_CLINICAL_DIAGNOSIS_SCHEMA;
                formValidation = OUTCOME_CLINICAL_DIAGNOSIS_VALIDATION_SCHEMA;
                initialVals["pcp_consult_outcome"] = initialVals["pcp_consult_outcome"]
                    ? initialVals["pcp_consult_outcome"]
                    : "";
                break;
            }
        }

        this.state = {
            ICD10: [],
            clinician_diagnosis: initialVals,
            formSchema,
            formValidation,
            patientId: this.props.match.params.patientId,
        };
    }

    getActivityPlaceholderValues = (formVals) => {
        // Logic here is UI is disparate from backend values
        let activityPlaceholder = {
            only_clinical: {
                label: "Diagnostic Interview (90791)",
                value: false,
                activities: [],
            },
            a5_and_clinical: {
                label: "Diagnostic Interview and A5 Assessment (90791,96130)",
                value: false,
                activities: ["interpret_a5_and/or_a30_96130"],
            },
        };
        if (formVals["additional_diagnostic_activities"] != null) {
            activityPlaceholder["only_clinical"].value =
                formVals["additional_diagnostic_activities"].length === 0;
        }

        return activityPlaceholder;
    };

    async componentDidMount() {
        const response = await api.provider.fetch_ICD10_dataset();
        this.setState({ ICD10: response });
        if (this.props.type === INITIAL) {
            let urlParams = { patientId: this.state.patientId };
            const completedCalls = (await getCompletedCalls(urlParams)).filter((option) =>
                option.display.includes("Diagnostic Interview (90791)"),
            );
            const formSchema = [...this.state.formSchema];
            const callIdIndex = formSchema.findIndex((el) => el["name"] === "call_id");
            if (callIdIndex) {
                formSchema[callIdIndex].elementConfig.options = completedCalls;
            }
            this.setState({ completedCalls, formSchema });
        }
    }

    diagnosticActivityHandler = (options, optionKey, formikProps) => {
        let previousValue = options[optionKey].value;
        options[optionKey].value = !previousValue;

        // if the previous value is false, make sure other value is now false
        if (!previousValue) {
            Object.keys(options).forEach((key) => {
                if (key !== optionKey) {
                    options[key].value = previousValue;
                }
            });
        }

        formikProps.setFieldValue("additional_diagnostic_activities_placeholder", options);
    };

    getDiagnosticActivityElement = (formikProps, q) => {
        const error = getIn(formikProps.errors, q.name);
        const touched = getIn(formikProps.touched, q.name);
        const questionConfig = formikProps.values.additional_diagnostic_activities_placeholder;

        return (
            <>
                <div key={q.name} className={q.elementClasses}>
                    <Label>{q.elementConfig.label}</Label>
                    <div className={"mx-1"}>
                        {Object.entries(questionConfig).map(([optionKey, optionConfig]) => {
                            return (
                                <div
                                    className={"custom-control custom-checkbox col-12"}
                                    key={optionKey}>
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={optionKey}
                                        name={optionKey}
                                        disabled={this.props.done ? true : null}
                                        checked={
                                            questionConfig[optionKey] ? optionConfig.value : null
                                        }
                                        onClick={() => {
                                            this.diagnosticActivityHandler(
                                                questionConfig,
                                                optionKey,
                                                formikProps,
                                            );
                                        }}
                                    />
                                    <label
                                        className="custom-control-label fs-14"
                                        htmlFor={optionKey}>
                                        <span>{optionConfig.label}</span>
                                    </label>
                                </div>
                            );
                        })}
                        {error && touched ? (
                            <div className={"col-12 p-0"}>
                                <InputError
                                    style={{ position: "relative" }}
                                    classes={"custom-error"}>
                                    {error}
                                </InputError>
                            </div>
                        ) : null}
                    </div>
                </div>
            </>
        );
    };

    convertDiagnosticActivityPlaceholder = (placeholder) => {
        if (!placeholder) {
            return [];
        }

        const choice = Object.values(placeholder).find((optionConfig) => optionConfig.value);

        return choice ? choice.activities : [];
    };

    submitHandler = (formData, actions) => {
        const data = { ...this.props.data };
        const clonedFormData = _.cloneDeep(formData);
        clonedFormData.additional_diagnostic_activities = this.convertDiagnosticActivityPlaceholder(
            clonedFormData.additional_diagnostic_activities_placeholder,
        );
        delete clonedFormData.additional_diagnostic_activities_placeholder;
        data[convertStatus(this.props.statusArr[3])] = { ...clonedFormData };
        this.props.submitHandler(data, actions);
    };

    handlePCPConsultOutcome = async (selectedOptions, setFieldValue) => {
        selectedOptions &&
            (await setFieldValue(
                "pcp_consult_outcome",
                selectedOptions.map((option) => option.value),
            ));
    };

    renderForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 ">
                    <fieldset disabled={this.props.done ? "disabled" : ""}>
                        <div className="container">
                            <div className="row clinicialTextbox-from-wpr mb-4">
                                {this.state.formSchema.map((q) => {
                                    let cssClass = "clinicialTextbox";

                                    if (q.elementType === "reactselect") {
                                        const error = getIn(formikProps.errors, q.name);
                                        const touched = getIn(formikProps.touched, q.name);
                                        let options = [];

                                        if (q.name === "ICD_10_diagnosis_axis_1") {
                                            options = this.state.ICD10.filter(
                                                (category) => category.axis === "1",
                                            );
                                        }

                                        if (q.name === "ICD_10_diagnosis_axis_2") {
                                            options = this.state.ICD10.filter(
                                                (category) => category.axis === "2",
                                            );
                                        }

                                        return (
                                            <div key={q.name} className={"col-12 mb-3"}>
                                                <Label>{q.elementConfig.label}</Label>
                                                <Select
                                                    components={{
                                                        ClearIndicator: null,
                                                    }}
                                                    isDisabled={this.props.done ? true : null}
                                                    isMulti
                                                    options={options}
                                                    defaultValue={formikProps.values[q.name]}
                                                    className={"basic-multi-select"}
                                                    classNamePrefix="select"
                                                    onChange={(selectedOptions) =>
                                                        formikProps.setFieldValue(
                                                            q.name,
                                                            selectedOptions,
                                                        )
                                                    }
                                                />
                                                {error && touched ? (
                                                    <InputError classes={"custom-error"}>
                                                        {error}
                                                    </InputError>
                                                ) : null}
                                            </div>
                                        );
                                    }

                                    if (q.name === "pcp_consult_outcome") {
                                        let selectedOptions = [];

                                        if (this.props.done) {
                                            selectedOptions = q.elementConfig.options.filter(
                                                (option) => {
                                                    return this.state.clinician_diagnosis.pcp_consult_outcome.includes(
                                                        option.value,
                                                    );
                                                },
                                            );
                                        }

                                        return (
                                            <div key={q.name} className="col-12">
                                                <Label>{q.elementConfig.label}</Label>
                                                <Select
                                                    isMulti
                                                    options={q.elementConfig.options}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={async (selectedOptions) =>
                                                        await this.handlePCPConsultOutcome(
                                                            selectedOptions,
                                                            formikProps.setFieldValue,
                                                        )
                                                    }
                                                    defaultValue={selectedOptions}
                                                    components={animatedComponents}
                                                    isDisabled={this.props.done ? true : null}
                                                />
                                            </div>
                                        );
                                    }

                                    if (q.name === "additional_diagnostic_activities_placeholder") {
                                        return this.getDiagnosticActivityElement(formikProps, q);
                                    }

                                    return (
                                        <div key={q.name} className="col-12">
                                            <FormikInput
                                                grayBorder="borderGray"
                                                clinicialTextbox={cssClass}
                                                formEl={q}
                                                errors={formikProps.errors}
                                                disabled={this.props.done ? "disabled" : null}
                                                touched={formikProps.touched}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </fieldset>
                    <div className="d-flex justify-content-center justify-content-lg-end">
                        <div>
                            <Button
                                type="button"
                                onClick={this.props.goBack}
                                className="Btn Btn--otl-pri Btn-sm mr-3">
                                Back
                            </Button>
                            <Button type="submit" className="Btn Btn--pri Btn-sm">
                                {this.props.done ? "Continue" : "Submit"}
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        );
    };

    render() {
        return (
            <div>
                <CustomForm
                    initialValues={this.state.clinician_diagnosis}
                    validationSchema={this.state.formValidation}
                    onSubmit={this.submitHandler}
                    render={this.renderForm}
                />
            </div>
        );
    }
}

export default ClinicianDignosis;
