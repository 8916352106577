import React from "react";
import { BehavioralActions, BehavioralInterventions } from "../BehavioralWorkflowSteps";
import "./styles.scss";
import { WorkflowStepTransitionButtons } from "../WorkflowStepTransitionButtons";

export const BehavioralInterventionAndPlan = (props) => {
    return (
        <div className="BehavioralInterventionAndPlan--container">
            <BehavioralInterventions {...props} />
            <div>
                <BehavioralActions {...props} />
            </div>

            <WorkflowStepTransitionButtons
                onNextStep={() => {
                    props.jumpWorkflowStep(props.state.currentStep + 1);
                }}
                onPreviousStep={() => {
                    props.jumpWorkflowStep(props.state.currentStep - 1);
                }}
                currentStep={props.state.currentStep}
                workflow={props.state.workflow}
            />
        </div>
    );
};
