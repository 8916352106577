// Copyright aptihealth, inc. 2019 All Rights Reserved

export const IPSRData = {
    patient_id: "dc53f672-fedb-11e9-896e-bbfbc171d095",
    type: "IPSR",
    report: "report_link: eg: <https://aptihealth.com/reports/ipsr/abdf.pdf",
    modified_at: "05/12/2019",
    first_name: "John",
    last_name: "Doe",
    action: "Review Needed | Awaiting Review",
    clinician_name: "AB Health Care",
    report_data: {
        sections: [
            {
                title: "Crisis Risk",
                type: "list",
                content: [
                    "In the past year patient has thought of ending his/her life",
                    "Patient has 4 to 5 attempted suicides",
                ],
                severity: 2,
            },
            {
                title: "Behavioral Symptoms",
                type: "list",
                content: [
                    "Depression symptoms include little or no enjoyment",
                    "Most recent depression symptoms: occurred 1 or 3 months ago",
                ],
                severity: 3,
            },
            {
                title: "Addiction",
                type: "list",
                content: [
                    "Patient has tried marijuana or hashish",
                    "Last use of marijuana was 1 to 3 months",
                    "Usual amount is 1 joint or equivalent per day",
                    "Daily caffeine use is none",
                    "Daily cigarette use is less than 1/2 a pack",
                ],
                severity: 3,
            },
            {
                title: "Health (Self-report)",
                type: "list",
                content: [
                    "Patient reports current health is excellent",
                    "Patient reports none problems",
                    "Patient has history of none of the above",
                ],
                severity: 3,
            },
            {
                title: "Relationships",
                type: "list",
                content: [
                    "Experiences with current partner: no current partner or spouse",
                    "Current partner has had problems emotional issues",
                    "Relationship with child(ren) is bond with his/her child(ren) is very bad",
                    "Patient child(ren) have had problems drug",
                    "Patient feels close to feels close to no friends",
                    "Patient feels supported by unspecified people",
                ],
                severity: 3,
            },
            {
                title: "Motivation to Change",
                type: "list",
                content: [
                    "Past attempts to improve life have included: has dealt with past problems by planned changes",
                    "In the last 6 months patient thought more about his/her problems",
                    "In the next month patient is ready to think about changing his/her problems",
                ],
                severity: 3,
            },
            {
                title: "Past/Current Treatment",
                type: "list",
                content: [
                    "Past substance abuse treatment includes rehab",
                    "Most recent rehab was 1 to 3 months ago",
                    "Mental health treatment received includes day treatment",
                    "Most recent inpatient mental health treatment 1 to 3 months ago",
                    "Most recent day treatment 1 to 3 months ago",
                    "Most recent residential care 1 to 3 months ago",
                    "Most recent outpatient mental health counseling 1 to 3 months ago",
                    "Most recent case management services 1 to 3 months ago",
                    "Most recent crisis/emergency room services 1 to 3 months ago",
                ],
                severity: 3,
            },
            {
                title: "Current Medication",
                type: "list",
                content: ["None"],
                severity: 3,
            },
            {
                title: "Recommendations",
                type: "paragraph",
                content: "Jane would benefit from an elevation of medication.",
            },
        ],
    },
};

export const severityData = [
    {
        value: 0,
        caption: "No Problem",
    },
    {
        value: 1,
        caption: "Mild Problem",
    },
    {
        value: 2,
        caption: "Mild to Moderate",
    },
    {
        value: 3,
        caption: "Moderate Problem",
    },
    {
        value: 4,
        caption: "Moderate to Severe",
    },
    {
        value: 5,
        caption: "Severe Problem",
    },
];
