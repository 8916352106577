import React from "react";
import images from "../../../../../utils/images";
import "../../styles.scss";
import { createClassNameString } from "../../../../../component-library";
import { getAutomationDataAttr } from "../../../../../utils/automationUtils";

export const WorkflowStepTransitionButtons = ({
    className,
    onNextStep,
    onPreviousStep,
    currentStep,
    workflow,
}) => {
    return (
        <div
            className={createClassNameString([
                "GuidedVideoCall__guided_widget_footer d-flex justify-content-around w-100",
                className,
            ])}>
            <div
                className="my-1 mx-3 p-1 d-flex justify-content-around align-items-center"
                style={{
                    backgroundColor: "#6B7A81",
                    width: "35%",
                    borderRadius: 5,
                    textAlign: "right",
                }}
                onClick={() => onPreviousStep(currentStep)}
                {...getAutomationDataAttr("WorkflowStepTransitionButtons_prev")}>
                <div>
                    <img src={images("./common/back.svg")} />
                </div>
                <div>
                    <div>Previous Step</div>
                    <div className="fs-10">{workflow.steps[currentStep - 1].short}</div>
                </div>
            </div>
            <div
                className="my-1 mx-3 p-1 d-flex justify-content-around align-items-center"
                style={{
                    backgroundColor: "#0CD0A7",
                    width: "35%",
                    borderRadius: 5,
                    textAlign: "left",
                }}
                onClick={() => onNextStep(currentStep)}
                {...getAutomationDataAttr("WorkflowStepTransitionButtons_next")}>
                <div>
                    <div>Next Step</div>
                    <div className="fs-10">{workflow.steps[currentStep + 1].short}</div>
                </div>
                <div>
                    <img
                        src={images("./common/back.svg")}
                        style={{
                            height: 15,
                            width: 15,
                            transform: "rotate(180deg)",
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
