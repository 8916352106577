// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useState } from "react";
import Input from "../UI/Input";
import Button from "../UI/Button";
import { Link } from "react-router-dom";
import images from "../../utils/images";
import { Markup } from "interweave";

const ConcentScreen = (props) => {
    const [hasConcent, setConcent] = useState(false);
    const toggleConcent = () => {
        setConcent((prevState) => {
            return !prevState;
        });
    };

    return (
        <div className={"SignUp__PatientConcent " + props.fs ? props.fs : "fs-14"}>
            <div className="text-center my-5">
                <img className="img-fluid" src={images("./Consent.svg")} />
            </div>
            <h4 className="mb-4 fs-15">
                <b>Patient Consent</b>
            </h4>
            <span className="SignUp__PatientConcent-text">
                {!props.hideWelcome && <p>Welcome to the aptihealth!</p>}
                <p>
                    {props.content ? (
                        <Markup content={props.content} />
                    ) : (
                        "Please view, print or save the documents linked below. These documents are designed to inform you of your " +
                        "rights and obligations while using the aptihealth service. By accessing and using this service, you are " +
                        "agreeing to these Terms of Use. Read more the full documents & the two links below."
                    )}
                </p>
            </span>
            {!props.hideLinks && (
                <div>
                    <div className="PrivacyPolicy-link fw-bold fs-14 mb-4">
                        <Link to="/privacy-policy">View Privacy Policy</Link>
                    </div>
                    <div className="PrivacyPolicy-link fw-bold fs-14 mb-4">
                        <Link to="https://www.aptihealth.com/terms-and-conditions/">
                            View Terms and Conditions
                        </Link>
                    </div>
                </div>
            )}
            <div className="FormGroup pt-3">
                <div className="d-flex align-items-start">
                    <Input type="checkbox" name="agree" onClick={toggleConcent} className="Input" />
                    {!props.customConsentLabel ? (
                        <p className="fs-14 m-0 ml-3">
                            By checking this box and clicking Continue, I here by Agree and Consent
                            to the Terms & Conditions and the Privacy Policy.
                        </p>
                    ) : (
                        props.customConsentLabel()
                    )}
                </div>
            </div>
            <div className="my-4 text-center">
                <Button
                    disabled={!hasConcent}
                    className="Btn Btn--pri Btn-sm"
                    onClick={props.continue}>
                    Continue
                </Button>
            </div>
        </div>
    );
};

export default ConcentScreen;
