import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";

export const NotificationSection = ({ children, className, status, ...props }) => (
    <div
        className={createClassNameString(["apti-CL apti-NotificationSection", className, status])}
        {...props}>
        {children}
    </div>
);

NotificationSection.propTypes = {
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
    status: PropTypes.string,
};

NotificationSection.defaultProps = {
    children: <></>,
    className: undefined,
    status: undefined,
};
