// Copyright aptihealth, inc. 2019 All Rights Reserved

import { MemberProfileActivityEntry } from "../../../component-library";
import React from "react";
import { PATIENT_INACTIVATION_TYPE } from "../../../constants/patient";

export const MemberActivatedActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const { provider_name } = keyContent;

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Dark"
            title="Patient Activated"
            subTitle={`Activated by ${provider_name}`}
            localDateTime={localCreatedTime}
            iconSrc="activate_timeline.svg"
            iconAlt="PatientActivatedIcon"
        />
    );
};

export const MemberInactivatedActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const { provider_name, reason_for_inactivation, inactivation_type } = keyContent;

    const getTitle = () => {
        if (inactivation_type === PATIENT_INACTIVATION_TYPE.ACCESS_REVOKED) {
            return "Access Revoked";
        }

        return "Patient Inactivated";
    };

    const getSubTitle = () => {
        if (inactivation_type === PATIENT_INACTIVATION_TYPE.ACCESS_REVOKED) {
            return "Access Revoked by";
        }

        return "Inactivated by";
    };

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Dark"
            title={getTitle()}
            subTitle={`${getSubTitle()} ${provider_name}`}
            localDateTime={localCreatedTime}
            iconSrc="inactivated_timeline.svg"
            iconAlt="PatientInactivatedIcon">
            <div className={"fs-14"}>{`Reason: ${reason_for_inactivation}`}</div>
        </MemberProfileActivityEntry>
    );
};
