// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";

const HeaderAttribute = (props) => {
    const { attribute, value } = props;
    return (
        <div className="fs-16">
            <span className="fw-bold">{attribute}:&nbsp;</span>
            <span>{value}</span>
        </div>
    );
};

export default HeaderAttribute;
