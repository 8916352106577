import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";

export const Card = ({ children, className, ...props }) => (
    <div className={createClassNameString(["apti-CL apti-Card", className])} {...props}>
        {children}
    </div>
);

Card.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
    className: PropTypes.string,
};

Card.defaultProps = {
    children: <></>,
    className: undefined,
};
