// Copyright aptihealth, inc. 2021 All Rights Reserved

import React, { useState } from "react";
import images from "../../../utils/images";
import "./style.scss";

const ViewablePassword = (props) => {
    const { onKeyDown } = props;
    const [type, setType] = useState("password");

    const viewPasswordHandler = () => {
        if (type === "password") {
            setType("text");
        } else {
            setType("password");
        }
    };

    return (
        <>
            <input onKeyDown={onKeyDown} {...props.field} {...props} type={type}></input>
            <img
                onClick={viewPasswordHandler}
                src={
                    type === "password" ? images("./common/show.svg") : images("./common/hide.svg")
                }
                alt="view"
                className={"view-password"}
            />
        </>
    );
};

export default ViewablePassword;
