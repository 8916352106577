import React from "react";
import CardSecondary from "../../../../UI/Card/CardSecondary";
import ListViewNav from "../../../../UI/ListView/ListViewNav";
import images from "../../../../../utils/images";
import { carePlanToObjectives } from "./WorkflowFunctions";

export const PatientCarePlanWidget = (props) => {
    let { patientDetails, carePlanTips } = props;
    let carePlan = patientDetails.care_plan ? patientDetails.care_plan : {};
    let goalsObjectivesProgress = carePlanToObjectives(carePlan);
    const tabs = ["Care Plan Goals"];

    if (carePlanTips) {
        tabs.push("Care Tips");
    }

    const [tab, setTab] = React.useState("Care Plan Goals");

    let carePlanGoals = goalsObjectivesProgress.map((objective) => {
        return (
            <div className="col-4 fw-bold">
                <img
                    src={images("./icons/dot.svg")}
                    alt="dot"
                    className="mr-1"
                    style={{ width: 5, height: 5 }}
                />
                {objective.label}
            </div>
        );
    });

    let tips = (
        <ul className="w-100" style={{ columns: 2, listStyle: "disc" }}>
            {carePlanTips && carePlanTips.map((tip) => {
                return (
                    <li className="fs-14" style={{ listStyleType: "disc", display: "list-item" }}>
                        {tip}
                    </li>
                );
            })}
        </ul>
    );

    return (
        <div className="d-none d-md-none d-lg-block">
            <div className="GuidedVideoCall__care_plan_info d-lg-block d-md-block d-block">
                <CardSecondary
                    className="pt-3 mt-5 ml-auto h-100 mr-auto w-100 color-black "
                    style={{ borderRadius: 10 }}>
                    <ListViewNav
                        ListNavHandler={(tab) => setTab(tab)}
                        status={tab}
                        tabs={tabs}
                    />
                    <hr className={"mx-2"} />
                    <div className="row px-5 text-left">
                        {tab === "Care Plan Goals" && carePlanGoals}
                        {tab === "Care Tips" && tips}
                    </div>
                </CardSecondary>
            </div>
        </div>
    );
};
