// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import CommonMenu from "./commonMenu";
import PatientMenu from "./patientMenu";
import { isAuthorized } from "../../../../redux/actions/auth";

const ProfileMenu = (props) => {
    return isAuthorized("user:patient") ? <PatientMenu {...props} /> : <CommonMenu {...props} />;
};

export default ProfileMenu;
