import React, { useEffect, useState } from "react";
import CardSecondary from "../../../UI/Card/CardSecondary";
import moment from "moment-timezone";
import { toTitleCase } from "../../../../utils/filters";
import {
    MED_CHANGES_OPTIONS,
    RATING_OPTIONS_NONE_MUCH_LABEL_MAP,
} from "../../../Common/GuidedVideoCall/Steps/ProviderWorkflow/PrescribeWorkflowSteps";
import Select from "react-select";
import _, { cloneDeep } from "lodash";
import { BehavioralGoalsRatingLabelButtons } from "./MemberProfileNotesBehavioralGoals";
import { joinDiagnosis } from "../../../Common/GuidedVideoCall/Steps/ProviderWorkflow/WorkflowFunctions";
import Button from "../../../UI/Button";
import images from "../../../../utils/images";
import InputError from "../../../UI/InputError";

const medicationLabelMapper = (option, medication) => {
    let key = option.key;
    let answer = medication[key];

    if (answer == null) {
        return "N/A";
    }

    let labels = medication[key + "_labels"];

    if (labels == null) {
        labels = RATING_OPTIONS_NONE_MUCH_LABEL_MAP;
    }

    return labels[answer] != null ? labels[answer] : "N/A";
};

const LABEL_MAP = [
    {
        key: "date",
        label: "Date",
        col: 1,
        formatter: (option, medication) => moment.utc(medication.date).local().format("MM/DD/YYYY"),
    },
    { key: "medication_name", label: "Medication", col: 2 },
    { key: "dosage", label: "Dosage", col: 1 },
    { key: "amount", label: "Amount", col: 1 },
    { key: "adherence", label: "Adherence", col: 2, formatter: medicationLabelMapper },
    { key: "benefits", label: "Benefits", col: 1, formatter: medicationLabelMapper },
    { key: "side_effect", label: "Side Effects", col: 2, formatter: medicationLabelMapper },
    {
        key: "med_changes",
        label: "Med Changes",
        col: 2,
        formatter: (option, medication) => toTitleCase(medication.med_changes),
    },
];

export const PrescriberMedications = (props) => {
    const { note } = props;

    let medications =
        note.content.medications.length !== 0
            ? note.content.medications
            : [
                  {
                      date: null,
                      medication_name: null,
                      dosage: null,
                      amount: null,
                      adherence: null,
                      benefits: null,
                      side_effect: null,
                      med_changes: null,
                  },
              ];

    let content = (
        <>
            <div className="col-12">
                <p className="clr-sec fw-bold">Medications</p>
            </div>
            <div
                className="row fs-12"
                style={{ backgroundColor: "#0CD0A7", borderRadius: "5px 5px 0 0", height: 32 }}>
                {LABEL_MAP.map((option) => (
                    <div className={`fw-bold text-center text-white col-lg-${option.col} col-12`}>
                        <div className="m-auto">{option.label}</div>
                    </div>
                ))}
            </div>
            {medications.map((medication) => {
                return (
                    <div className="row fs-12">
                        {LABEL_MAP.map((option) => {
                            let result = "N/A";

                            if (medication[option.key] != null && medication[option.key] !== "") {
                                result = medication[option.key];
                                if (option.formatter) {
                                    result = option.formatter(option, medication);
                                }
                            }
                            return (
                                <div className={`col-lg-${option.col} col-12 text-center`}>
                                    {result}
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </>
    );

    let desktopContent = (
        <CardSecondary className="mx-auto px-3 py-3 my-3 d-none d-md-none d-lg-block">
            {content}
        </CardSecondary>
    );

    let mobileContent = (
        <div className="d-block d-md-block d-lg-none mt-3">
            <div className="d-flex justify-content-between">
                <p className="cmt-3 fw-bold clr-sec">Current Medications</p>
            </div>
            {medications.map((medication) => {
                return (
                    <CardSecondary className="mx-auto">
                        <div className="row m-2">
                            {LABEL_MAP.map((option) => {
                                return (
                                    <div
                                        className={"col-12"}
                                        style={{ height: 32, overflow: "hidden" }}>
                                        {option.label}: {medication[option.key]}
                                    </div>
                                );
                            })}
                        </div>
                    </CardSecondary>
                );
            })}
        </div>
    );

    return (
        <>
            {desktopContent}
            {mobileContent}
        </>
    );
};

export const PrescriberMedicationsV2 = (props) => {
    let { note, errors, editable, profileView } = props;
    const [medications, setMedications] = useState(_.cloneDeep(note.content.medications));

    const MEDICATION_IMPACT_SECTIONS = [
        {
            key: "benefits",
            labels: RATING_OPTIONS_NONE_MUCH_LABEL_MAP,
            title: "Medication Benefits",
        },
    ];

    let allDiagnosis = joinDiagnosis(note);
    let medicationContent = [];

    const handleMedicationChange = (e, index, key, labels) => {
        let clonedMedications = _.cloneDeep(medications);
        clonedMedications[index][key] = e.target.value;
        clonedMedications[index][key + "_labels"] = labels;
        setMedications(clonedMedications);
        props.editHandler("medications", clonedMedications);
    };

    const getDefaultMedicationObj = () => {
        return {
            date: undefined,
            medication_name: "",
            dosage: "",
            amount: undefined,
            adherence: undefined,
            benefits: undefined,
            side_effect: undefined,
            med_changes: "ADD",
        };
    };

    const addMedication = () => {
        const clonedMedications = cloneDeep(medications);
        clonedMedications.push(getDefaultMedicationObj());
        setMedications(clonedMedications);
        props.editHandler("medications", clonedMedications);
    };

    const removeMedication = (index) => {
        const clonedMedications = cloneDeep(medications);
        clonedMedications.splice(index, 1);
        setMedications(clonedMedications);
        props.editHandler("medications", clonedMedications);
    };

    const buildMedicationImpactSection = (medication, index) => {
        return MEDICATION_IMPACT_SECTIONS.map((section) => {
            medication[section.key] =
                typeof medication[section.key] === "string"
                    ? parseInt(medication[section.key])
                    : medication[section.key];
            return (
                <>
                    <span className="fw-bold">{section.title}</span>
                    <BehavioralGoalsRatingLabelButtons
                        optionLabelMap={section.labels}
                        progress={medication}
                        ratingKey={section.key}
                        isEditing={editable}
                        onClick={(e) =>
                            handleMedicationChange(e, index, section.key, section.labels)
                        }
                    />
                </>
            );
        });
    };

    const buildMedicationContent = () => {
        medicationContent = medications.map((medication, index) => {
            let isNew = medication.med_changes === "ADD";
            let title = isNew ? "New Medication" : "Medication(s)";

            let medChangeOptions;
            let medChangeDefault;
            if (isNew) {
                medChangeOptions = [{ value: "ADD", label: "Add" }];
                medChangeDefault = medChangeOptions[0];
            } else {
                medChangeOptions = MED_CHANGES_OPTIONS;
                medChangeDefault = medChangeOptions.find(
                    (option) => option.value === medication.med_changes,
                );
            }
            return (
                <>
                    <div className="clr-sec fw-bold px-3 py-2 d-flex flex-column-reverse">
                        <span>{title}</span>
                        {editable && isNew && (
                            <img
                                onClick={() => {
                                    removeMedication(index);
                                }}
                                className="cursor align-self-end"
                                width="8"
                                src={images("./common/cross.svg")}
                                alt=""
                            />
                        )}
                    </div>
                    <div className="border-bottom pb-3">
                        <div className="row px-4">
                            <div className="col-lg-4 col-12 col-sm-4">
                                <span>Name</span>
                                <input
                                    value={medication.medication_name}
                                    className={"w-100 form-control"}
                                    disabled={!isNew || !editable}
                                    onChange={(e) =>
                                        handleMedicationChange(e, index, "medication_name")
                                    }
                                />
                                {isNew && errors["medication_name"] ? (
                                    <InputError classes={"custom-error"}>
                                        {errors["medication_name"]}
                                    </InputError>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="col-lg-4 col-12 col-sm-4">
                                <span>Dosage</span>
                                <input
                                    value={medication.dosage}
                                    disabled={!editable}
                                    className={"w-100 form-control"}
                                    onChange={(e) => handleMedicationChange(e, index, "dosage")}
                                />
                                {isNew && errors["dosage"] ? (
                                    <InputError classes={"custom-error"}>
                                        {errors["dosage"]}
                                    </InputError>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="col-lg-4 col-12 col-sm-4">
                                {!isNew && buildMedicationImpactSection(medication, index)}
                            </div>
                        </div>
                        <div className="row px-4">
                            <div className="col-lg-4 col-12 col-sm-4">
                                <span>Medication Changes</span>
                                <Select
                                    className={"basic-multi-select w-100"}
                                    classNamePrefix="select"
                                    options={medChangeOptions}
                                    defaultValue={medChangeDefault}
                                    isDisabled={isNew || !editable}
                                    onChange={(selectedOption) => {
                                        handleMedicationChange(
                                            { target: { value: selectedOption.value } },
                                            index,
                                            "med_changes",
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-lg-4 col-12 col-sm-4">
                                <span>Diagnosis</span>
                                <Select
                                    className={"basic-multi-select w-100"}
                                    classNamePrefix="select"
                                    options={allDiagnosis}
                                    isMulti
                                    isDisabled={!editable}
                                    defaultValue={medication.diagnosis}
                                    onChange={(selectedOptions) => {
                                        handleMedicationChange(
                                            { target: { value: selectedOptions } },
                                            index,
                                            "diagnosis",
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            );
        });
    };

    useEffect(() => {
        note = props.note;
        setMedications(note.content.medications);
    }, [props.note.content]);

    buildMedicationContent();

    let buttonDisplay = editable ? "" : "d-none";
    let innerContent = (
        <>
            {medicationContent}
            <div className="d-flex justify-content-center">
                <Button
                    className={`Btn Btn--pri Btn-sm mt-3 mb-3 ${buttonDisplay}`}
                    onClick={addMedication}>
                    Add Medication
                </Button>
            </div>
        </>
    );

    let mobileContent = (
        <CardSecondary className="mx-auto d-block d-md-block d-lg-none mt-3">
            {innerContent}
        </CardSecondary>
    );

    let desktopContent = (
        <CardSecondary className="mx-auto px-3 py-3 my-3 d-none d-md-none d-lg-block">
            {innerContent}
        </CardSecondary>
    );

    if (profileView) {
        desktopContent = (
            <div className="mx-auto px-3 py-3 my-3 d-none d-md-none d-lg-block">{innerContent}</div>
        );
    }

    return (
        <>
            {desktopContent}
            {mobileContent}
        </>
    );
};
