import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";

export const Image = ({ className, src, alt, onClick, ...props }) => (
    <img
        className={createClassNameString(["apti-CL apti-Image", className])}
        src={require(`../../assets/images/${src}`)}
        alt={alt}
        onClick={onClick}
        {...props}
    />
);

Image.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

Image.defaultProps = {
    className: undefined,
    src: undefined,
    alt: "image",
    onClick: () => {},
};
