import React, { useRef } from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../../utils";
import "./styles.scss";
import { Text } from "../../Text";
import { TextColors } from "../../constants";

/**
 * @deprecated
 * DO NOT REPLICATE THIS PATTERN
 */
export const CheckboxOld = ({ version, ...props }) => {
    if (version === "v1") {
        return <CheckboxV1 {...props} />;
    } else if (version === "v2") {
        return <CheckboxV2 {...props} />;
    } else {
        console.error(`Invalid checkbox version ${version}`);
        return null;
    }
};

CheckboxOld.propTypes = {
    version: PropTypes.string,
};

CheckboxOld.defaultProps = {
    version: "v1",
};

const CheckboxV2 = ({
    className,
    name,
    disabled,
    onClick,
    onChange,
    onFocus,
    checked,
    value,

    label,
    labelClassName,
    labelColor,

    inputClassName,

    checkboxContainerClassName,

    ...props
}) => {
    // This adds the functionality of being able to select the button by clicking the text or the checkbox
    const onClickHandler = (e) => {
        onClick(e);
    };

    const inputElement = useRef();
    const checkboxContainerClass = disabled
        ? [
              "apti-CL",
              "apti-CheckboxContainer",
              "apti-CheckboxContainer--disabled",
              checkboxContainerClassName,
          ]
        : ["apti-CL", "apti-CheckboxContainer", checkboxContainerClassName];

    return (
        <div className={createClassNameString(["apti-CL", className])}>
            <div
                onClick={() => {
                    onClickHandler({ target: inputElement?.current });
                    onChange({ target: inputElement?.current });
                }}
                className={createClassNameString(checkboxContainerClass)}>
                <input
                    ref={inputElement}
                    className={createClassNameString(["apti-CL", "apti-Checkbox", inputClassName])}
                    type="checkbox"
                    name={name}
                    value={value}
                    checked={checked}
                    disabled={disabled}
                    onFocus={onFocus}
                    {...props}
                />
                {label && (
                    <Text color={labelColor} className={createClassNameString([labelClassName])}>
                        {label}
                    </Text>
                )}
            </div>
        </div>
    );
};

const CheckboxV1 = ({
    className,
    name,
    disabled,
    onClick,
    onChange,
    onFocus,
    checked,
    ...props
}) => {
    const onClickHandler = (e) => {
        onClick(e);
    };

    return (
        <input
            className={createClassNameString(["apti-CL", "apti-Checkbox", className])}
            type="checkbox"
            name={name}
            checked={checked}
            disabled={disabled}
            onClick={onClickHandler}
            onChange={onChange}
            onFocus={onFocus}
            {...props}
        />
    );
};

const checkboxV1PropTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    inputClassName: PropTypes.string,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    labelColor: PropTypes.oneOf([
        TextColors.grey,
        TextColors.green,
        TextColors.black,
        TextColors.red,
        TextColors.navy,
        TextColors.purple,
        TextColors.white,
    ]),
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
};

const defaultV1Props = {
    className: undefined,
    inputClassName: undefined,
    label: undefined,
    labelClassName: undefined,
    labelColor: TextColors.grey,
    name: "",
    value: undefined,
    checked: false,
    disabled: false,
    onClick: () => {},
    onChange: () => {},
    onFocus: () => {},
};

CheckboxV1.propTypes = checkboxV1PropTypes;
CheckboxV1.defaultProps = defaultV1Props;

CheckboxV2.propTypes = checkboxV1PropTypes;
CheckboxV2.defaultProps = defaultV1Props;
