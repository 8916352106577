/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const AssessmentIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Assessment"
                {...props}>
                <path d="M15 13H10L10 11H15V13Z" />
                <path d="M10 18H15V16H10V18Z" />
                <path d="M15 23H10L10 21H15V23Z" />
                <path d="M22.7071 10.7071L21.2928 9.29291L19 11.5858L18.2071 10.7929L16.7928 12.2071L19 14.4142L22.7071 10.7071Z" />
                <path d="M21.2928 14.2929L22.7071 15.7071L19 19.4142L16.7928 17.2071L18.2071 15.7929L19 16.5858L21.2928 14.2929Z" />
                <path d="M22.7071 20.7071L21.2928 19.2929L19 21.5858L18.2071 20.7929L16.7928 22.2071L19 24.4142L22.7071 20.7071Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 1.5C17.4806 1.5 18.7733 2.3044 19.4649 3.5H21C21.9319 3.5 22.715 4.13739 22.937 5H26V29H6V5H9.06301C9.28503 4.13739 10.0681 3.5 11 3.5H12.5351C13.2267 2.3044 14.5194 1.5 16 1.5ZM17.7337 4.50145L18.3113 5.5H21V6.5H11L11 5.5H13.6887L14.2663 4.50145C14.6152 3.89843 15.2622 3.5 16 3.5C16.7378 3.5 17.3848 3.89843 17.7337 4.50145ZM22.937 7C22.715 7.86262 21.9319 8.5 21 8.5H11C10.0681 8.5 9.28503 7.86261 9.06301 7H8V27H24V7H22.937Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Assessment"
                {...props}>
                <path d="M15 13H10L10 11H15V13Z" />
                <path d="M10 18H15V16H10V18Z" />
                <path d="M15 23H10L10 21H15V23Z" />
                <path d="M22.7071 10.7071L21.2928 9.29291L19 11.5858L18.2071 10.7929L16.7928 12.2071L19 14.4142L22.7071 10.7071Z" />
                <path d="M21.2928 14.2929L22.7071 15.7071L19 19.4142L16.7928 17.2071L18.2071 15.7929L19 16.5858L21.2928 14.2929Z" />
                <path d="M22.7071 20.7071L21.2928 19.2929L19 21.5858L18.2071 20.7929L16.7928 22.2071L19 24.4142L22.7071 20.7071Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 1.5C17.4806 1.5 18.7733 2.3044 19.4649 3.5H21C21.9319 3.5 22.715 4.13739 22.937 5H26V29H6V5H9.06301C9.28503 4.13739 10.0681 3.5 11 3.5H12.5351C13.2267 2.3044 14.5194 1.5 16 1.5ZM22.937 7C22.715 7.86262 21.9319 8.5 21 8.5H11C10.0681 8.5 9.28503 7.86261 9.06301 7H8V27H24V7H22.937Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Assessment"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.75 14.375C24.4489 14.375 25.0362 13.897 25.2027 13.25H26V28.25H14V13.25H14.7973C14.9638 13.897 15.5511 14.375 16.25 14.375H23.75ZM25.0303 16.0303L23.9696 14.9697L22.25 16.6894L21.6553 16.0947L20.5946 17.1553L22.25 18.8107L25.0303 16.0303ZM19.25 17.75H15.5V16.25H19.25V17.75ZM15.5 21.5H19.25V20H15.5V21.5ZM15.5 25.25H19.25V23.75H15.5V25.25ZM25.0303 19.7803L23.9696 18.7197L22.25 20.4394L21.6553 19.8447L20.5946 20.9053L22.25 22.5607L25.0303 19.7803ZM25.0303 23.5303L23.9696 22.4697L22.25 24.1894L21.6553 23.5947L20.5946 24.6553L22.25 26.3107L25.0303 23.5303Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM22.5987 10.625C22.0799 9.7283 21.1104 9.125 20 9.125C18.8896 9.125 17.9201 9.7283 17.4013 10.625H16.25C15.5511 10.625 14.9638 11.103 14.7973 11.75H12.5V29.75H27.5V11.75H25.2027C25.0362 11.103 24.4489 10.625 23.75 10.625H22.5987Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

AssessmentIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

AssessmentIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
