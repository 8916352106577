// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import ContentLoader from "react-content-loader";

export const DesktopCareTeamLoader = (props) => (
    <div className={props.classes}>
        <ContentLoader
            height={25}
            width={400}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="4" y="5" rx="11" ry="11" width="90" height="18" />
            <rect x="120" y="5" rx="11" ry="11" width="90" height="18" />
        </ContentLoader>
    </div>
);

export const MobileCareTeamLoader = (props) => (
    <div className={props.classes}>
        <ContentLoader
            height={40}
            width={250}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="5" y="5" rx="15" ry="15" width="150" height="30" />
        </ContentLoader>
    </div>
);
