import React from "react";

import { TutorialPortal } from "../../TutorialPortal";
import { Portal } from "../../Portal";
import { Button } from "../../Button";
import { StepSelector } from "../../StepSelector";
import { Ellipsis } from "../../Elipsis";

export const PostSessionSurveyTutorialHoc = ({
    className,
    currentStep,
    setCurrentStep,
    onSecondarySubmit,
    ...props
}) => {
    const steps = [
        {
            title: "We’ve updated our session rating!",
            subText:
                "Ready to rate your session? Before you do, click Get Started to learn about what’s changed.",
            primaryButtonComponent: (
                <Button
                    className={"apti-TutorialPortal--submit-btn"}
                    onClick={() => setCurrentStep(currentStep + 1)}>
                    Get Started
                </Button>
            ),
            primaryButtonComponentMobile: (
                <Button
                    className={"apti-TutorialPortal--submit-btn--mobile"}
                    onClick={() => setCurrentStep(currentStep + 1)}>
                    Get Started
                </Button>
            ),
            onSecondarySubmit: onSecondarySubmit,
            secondaryButtonText: "Skip",
            secondaryButtonTextMobile: "Skip",
            arrowPosition: null,
        },
        {
            title: "Tell us how you’re feeling",
            subText:
                "Our new questions help us learn more about the care you’re receiving. We’ll check in after each session to make sure you’re feeling supported.",
            primaryButtonComponent: (
                <StepSelector
                    className={"pss-tutorial-ss"}
                    currentStep={1}
                    totalSteps={3}
                    onRightClick={() => setCurrentStep(currentStep + 1)}
                />
            ),
            primaryButtonComponentMobile: (
                <div className="mobile-container">
                    <Button
                        className={"apti-TutorialPortal--submit-btn--mobile"}
                        onClick={() => setCurrentStep(currentStep + 1)}>
                        Continue
                    </Button>
                    <Ellipsis leftDisabled={true} />
                </div>
            ),
            onSecondarySubmit: onSecondarySubmit,
            secondaryButtonText: "Skip",
            secondaryButtonTextMobile: "Skip",
            arrowPosition: "left-top",
        },
        {
            title: "Make a selection",
            subText:
                "We’ve given you more options! Select a box that best indicates how you feel. Once selected, the box will turn purple.",
            mobileSubText:
                "We’ve given you more options! Slide each box to a point on the scale based on how you’re feeling. Once selected, the bar will turn purple.",
            primaryButtonComponent: (
                <StepSelector
                    className={"pss-tutorial-ss"}
                    currentStep={2}
                    totalSteps={3}
                    onRightClick={() => setCurrentStep(currentStep + 1)}
                    onLeftClick={() => setCurrentStep(currentStep - 1)}
                />
            ),
            primaryButtonComponentMobile: (
                <div className="mobile-container">
                    <Button
                        className={"apti-TutorialPortal--submit-btn--mobile"}
                        onClick={() => setCurrentStep(currentStep + 1)}>
                        Continue
                    </Button>
                    <Ellipsis centerDisabled={true} />
                </div>
            ),
            onSecondarySubmit: onSecondarySubmit,
            secondaryButtonText: "Skip",
            secondaryButtonTextMobile: "Skip",
            arrowPosition: "top-left",
        },
        {
            title: "Submit your response",
            subText:
                "All done? Once you’ve answered each question, click submit to share your feedback.",
            primaryButtonComponent: (
                <StepSelector
                    className={"pss-tutorial-ss"}
                    currentStep={3}
                    totalSteps={3}
                    onLeftClick={() => setCurrentStep(currentStep - 1)}
                />
            ),
            primaryButtonComponentMobile: (
                <div className="mobile-container">
                    <Button
                        className={"apti-TutorialPortal--submit-btn--mobile"}
                        onClick={onSecondarySubmit}>
                        Close
                    </Button>
                    <Ellipsis rightDisabled={true} />
                </div>
            ),
            onSecondarySubmit: onSecondarySubmit,
            secondaryButtonText: "Close",
            secondaryButtonTextMobile: null,
            arrowPosition: "top-center",
        },
    ];

    return (
        <>
            {currentStep < 3 && (
                <Portal>
                    <div className="overlay" />
                </Portal>
            )}
            <TutorialPortal
                className={className}
                title={steps[currentStep].title}
                subText={steps[currentStep].subText}
                mobileSubText={steps[currentStep].mobileSubText}
                onSecondarySubmit={steps[currentStep].onSecondarySubmit}
                secondaryButtonText={steps[currentStep].secondaryButtonText}
                arrowPosition={steps[currentStep].arrowPosition}
                secondaryButtonTextMobile={steps[currentStep].secondaryButtonTextMobile}>
                {steps[currentStep].primaryButtonComponent}
                {steps[currentStep].primaryButtonComponentMobile}
            </TutorialPortal>
        </>
    );
};
