// Copyright aptihealth, inc. 2019 All Rights Reserved

import { http } from "../../../utils/axios";
import { api } from "../../../APIRequests";
import { getQueryParams } from "../../../utils/filters";
import { showAlert } from "../../../redux/actions/alerts";
import store from "../../../redux/store";
import { uploadInsuranceImageFile, UserFileTypes } from "../../../utils/fileUtil";

export const fetchPatientList = ({ options }) => {
    return api.provider.fetch_patient_list({ options });
};

export const uploadAnonymousInsuranceImage = (imgData, attr, cb) => {
    const { file, fileName } = imgData;
    try {
        uploadInsuranceImageFile(file, fileName, null, UserFileTypes.ANONYMOUS_INSURANCE_IMAGE)
            .then((base64Img) => {
                cb(attr, { url: fileName, preview: base64Img });
            })
            .catch((err) => {
                showAlert(err.message, store.dispatch);
                console.log(err);
            });
    } catch (err) {
        showAlert(err.message, store.dispatch);
        console.log(err);
    }
};

//deprecated use fileUtil
export const getFile = (patientId, key) => {
    let urlParams = { patientId };
    let data = { key: key };
    api.shared
        .fetch_patient_file({ data, urlParams })
        .then((response) => {
            let url = response.message;
            if (url) {
                window.open(url);
            }
        })
        .catch((err) => {
            let errMsg =
                (err.response && err.response.data.results.message) ||
                "Some unexpected error occured";
            console.log(errMsg);
        });
};

export const getScreeningAnswers = async (type, props, state, cb) => {
    try {
        let urlParams = {
            patientId: state.patientId,
            type: type,
            day: getQueryParams("day", props.location.search.trim()),
        };
        let response = await api.shared.fetch_patient_phq9_gad7({ urlParams });
        if (response && response.result) {
            cb(response.result);
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * Transforms question list received from api to the form
 * expected by submit api.
 *
 * @param {Array.<{id: number, question: string}>} qArray Array of question objects
 *
 * @param answers response from previously submitted screening that we wish to display
 */
export const transformScreeningQuestionsArray = (qArray, answers) => {
    let transformedQuestionsArray;
    if (!answers) {
        transformedQuestionsArray = qArray.map((q) => {
            return { question_id: q.id, answer: null, question: q.question };
        });
    } else {
        transformedQuestionsArray = qArray.map((q, i) => {
            return { question_id: q.id, answer: parseInt(answers[i].rating), question: q.question };
        });
    }
    return transformedQuestionsArray;
};
