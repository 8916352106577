import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import {createClassNameString} from "../../component-library";
import {ICON_SIZES, ICON_TYPES} from "../../constants/icons";

export const ScreeningIcon = ({className, iconType, iconSize, ...props}) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path fillRule="evenodd" clipRule="evenodd" d="M10.0249 22C10.1846 20.0976 11.0999 18.5335 12.4328 17.6864C13.125 17.2465 13.9298 17 14.8 17H16H17.2C18.0769 17 18.8789 17.249 19.5658 17.6877C20.9107 18.5466 21.8142 20.1325 21.9745 22C21.9914 22.197 22 22.3972 22 22.6V24H10V22.6C10 22.3967 10.0084 22.1965 10.0249 22ZM17.2 19C18.4425 19 19.7441 20.0492 19.9667 22H12.0326C12.2549 20.0121 13.5768 19 14.8 19H17.2Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M16 17C15.5784 17 15.1743 16.9255 14.8 16.7889C14.0261 16.5064 13.3799 15.9587 12.9715 15.2557C12.6717 14.7396 12.5 14.1398 12.5 13.5C12.5 11.567 14.067 10 16 10C17.933 10 19.5 11.567 19.5 13.5C19.5 14.1395 19.3285 14.739 19.0289 15.2549C18.6205 15.9583 17.9741 16.5063 17.2 16.7889C16.8257 16.9255 16.4216 17 16 17ZM17.5 13.5C17.5 14.3284 16.8284 15 16 15C15.1716 15 14.5 14.3284 14.5 13.5C14.5 12.6716 15.1716 12 16 12C16.8284 12 17.5 12.6716 17.5 13.5Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M19.4649 3.5C18.7733 2.3044 17.4806 1.5 16 1.5C14.5194 1.5 13.2267 2.3044 12.5351 3.5H11C10.0681 3.5 9.28503 4.13739 9.06301 5H6V29H26V5H22.937C22.715 4.13739 21.9319 3.5 21 3.5H19.4649ZM18.3113 5.5L17.7337 4.50145C17.3848 3.89843 16.7378 3.5 16 3.5C15.2622 3.5 14.6152 3.89843 14.2663 4.50145L13.6887 5.5H11L11 6.5H21V5.5H18.3113ZM21 8.5C21.9319 8.5 22.715 7.86262 22.937 7H24V27H8V7H9.06301C9.28503 7.86261 10.0681 8.5 11 8.5H21Z" />
            </svg>
        );
    } else if (iconType === ICON_TYPES.FILLED) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path d="M19.5 13.5C19.5 15.433 17.933 17 16 17C14.067 17 12.5 15.433 12.5 13.5C12.5 11.567 14.067 10 16 10C17.933 10 19.5 11.567 19.5 13.5Z" />
                <path d="M16 17H17.2C19.975 17 22 19.4937 22 22.6V24H10V22.6C10 19.4062 12.0813 17 14.8 17H16Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M19.4649 3.5C18.7733 2.3044 17.4806 1.5 16 1.5C14.5194 1.5 13.2267 2.3044 12.5351 3.5H11C10.0681 3.5 9.28503 4.13739 9.06301 5H6V29H26V5H22.937C22.715 4.13739 21.9319 3.5 21 3.5H19.4649ZM21 8.5C21.9319 8.5 22.715 7.86262 22.937 7H24V27H8V7H9.06301C9.28503 7.86261 10.0681 8.5 11 8.5H21Z" />
            </svg>
        );
    }

    return null;
};

ScreeningIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

ScreeningIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
