import React from "react";
import Input from "../../components/UI/Input";
import _ from "lodash";
import "./styles.scss";

export const ConsentSMS = ({ consentEvent, children, className, type, color, ...props }) => (
    <span className="justified-center">
        <div>
            <Input
                className="custom-checkbox mr-2 mt-1"
                style={{
                    "min-height": "16px",
                    "min-width": "16px",
                    "max-height": "1rem",
                    "max-width": "1rem",
                }}
                type={"checkbox"}
                onClick={() => consentEvent()}
            />
        </div>
        <span className="fs-15 txt-gry">
            I consent to receive marketing, appointment reminders, and other calls and text messages
            (SMS/MMS) from AptiHealth, healthcare providers with whom I am matched by AptiHealth, or
            any of their agents or representatives at the phone number I have provided, even if that
            number is registered on a corporate, state, or national do not call registry. I
            understand that my consent to such calls and messages is not required to use
            AptiHealth’s services and that I may revoke my consent at any time. Message and data
            rates may apply. Message frequency varies.
        </span>
    </span>
);

ConsentSMS.defaultProps = {
    consentEvent: () => {},
};
