/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const LoupeIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Loupe"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 14.5C24 19.7467 19.7467 24 14.5 24C9.25329 24 5 19.7467 5 14.5C5 9.25329 9.25329 5 14.5 5C19.7467 5 24 9.25329 24 14.5ZM22 14.5C22 18.6421 18.6421 22 14.5 22C10.3579 22 7 18.6421 7 14.5C7 10.3579 10.3579 7 14.5 7C18.6421 7 22 10.3579 22 14.5Z"
                />
                <path d="M28.7071 27.2929L22.7071 21.2929C22.3166 20.9024 21.6834 20.9024 21.2929 21.2929C20.9024 21.6834 20.9024 22.3166 21.2929 22.7071L27.2929 28.7071C27.6834 29.0976 28.3166 29.0976 28.7071 28.7071C29.0976 28.3166 29.0976 27.6834 28.7071 27.2929Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Loupe"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.5065 21.8606C18.8696 23.1979 16.7785 24 14.5 24C9.25329 24 5 19.7467 5 14.5C5 9.25329 9.25329 5 14.5 5C19.7467 5 24 9.25329 24 14.5C24 16.7785 23.1979 18.8696 21.8606 20.5065C22.2895 20.4667 22.7323 20.6109 23.0607 20.9393L29.0607 26.9393C29.6464 27.5251 29.6464 28.4749 29.0607 29.0607C28.4749 29.6464 27.5251 29.6464 26.9393 29.0607L20.9393 23.0607C20.6109 22.7323 20.4667 22.2895 20.5065 21.8606ZM21 14.5C21 18.0899 18.0899 21 14.5 21C10.9101 21 8 18.0899 8 14.5C8 10.9101 10.9101 8 14.5 8C18.0899 8 21 10.9101 21 14.5Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Loupe"
                {...props}>
                <path d="M18.875 23.75C21.5674 23.75 23.75 21.5674 23.75 18.875C23.75 16.1826 21.5674 14 18.875 14C16.1826 14 14 16.1826 14 18.875C14 21.5674 16.1826 23.75 18.875 23.75Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM18.875 26C20.5839 26 22.1522 25.3984 23.3798 24.3954C23.35 24.7171 23.4582 25.0492 23.7045 25.2955L28.2045 29.7955C28.6438 30.2348 29.3562 30.2348 29.7955 29.7955C30.2348 29.3562 30.2348 28.6438 29.7955 28.2045L25.2955 23.7045C25.0492 23.4582 24.7171 23.35 24.3954 23.3798C25.3984 22.1522 26 20.5839 26 18.875C26 14.94 22.81 11.75 18.875 11.75C14.94 11.75 11.75 14.94 11.75 18.875C11.75 22.81 14.94 26 18.875 26Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

LoupeIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

LoupeIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
