import "../styles.scss";
import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { getIn } from "formik";
import _ from "lodash";
import { createClassNameString } from "../../utils";
import { FormFieldError } from "../FormFieldError/FormFieldError";
import { Dropdown } from "../../Dropdown";

export const FormMultiSelect = ({
    name,
    title,
    subTitle,
    placeholder,
    customError,
    className,
    dropDownClassName,
    formikProps,
    // normally form fields do not error until after submission
    // because they have not been touched
    // setting this to true triggers the error after editing
    // for the first time
    triggerErrorOnChange = false,
    disabled,
    options,
    dataPrivate,
}) => {
    const errorMessage = getIn(formikProps.errors, name);
    const isTouched = getIn(formikProps.touched, name);
    const value = _.get(formikProps.values, name);

    const handleChange = async (event) => {
        const newValue = event.target.value?.map((item) => item.value) ?? [];
        await formikProps.setFieldValue(name, newValue);

        if (!isTouched && triggerErrorOnChange) {
            await formikProps.setFieldTouched(name, true);
        }
    };

    const selectedOptions = options.filter((item) => {
        return value.includes(item.value);
    });

    return (
        <div className={createClassNameString(["FormField-container", className])}>
            {(title || subTitle) && (
                <div data-public className="FormField-header_container">
                    {title && <h3 className="FormField-title">{title}</h3>}
                    {subTitle && <h3 className="FormField-subTitle">{subTitle}</h3>}
                </div>
            )}
            <div>
                <span data-private={dataPrivate}>
                    <Dropdown
                        className={createClassNameString(["FormMultiSelect", dropDownClassName])}
                        error={isTouched && errorMessage}
                        value={selectedOptions}
                        options={options}
                        placeholder={placeholder}
                        onChange={handleChange}
                        isDisabled={disabled}
                        isMulti={true}
                    />
                </span>
                {!customError && errorMessage && isTouched && (
                    <FormFieldError>{errorMessage}</FormFieldError>
                )}
                {/* lets the dev pick what is displayed below the text area */}
                {customError && customError(value, errorMessage, isTouched)}
            </div>
        </div>
    );
};

FormMultiSelect.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    customError: PropTypes.func,
    formikProps: PropTypes.object,
    disabled: PropTypes.bool,
};
