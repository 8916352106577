// Copyright aptihealth, inc. 2021 All Rights Reserved
import React, { useEffect, useState } from "react";
import { api } from "../../../../APIRequests";
import moment from "moment-timezone";
import { AppointmentTimePicker } from "../../Schedule/AppointmentTimePicker";
import _ from "lodash";

const ScheduleCall = ({
    sharedWorkflowData,
    selectedAppointment,
    setSelectedAppointment,
    timezone,
}) => {
    const patientId = _.get(sharedWorkflowData, "workflow_instance.state.patient_id");
    const patientCreatedDateTime = _.get(
        sharedWorkflowData,
        "workflow_instance.state.patient_created_date_time",
    );

    const [intakeAppointments, setIntakeAppointments] = useState(null);

    const maxDaysToShow = 7;
    const [numDaysToShow, setNumDaysToShow] = useState(maxDaysToShow);

    useEffect(() => {
        (async () => {
            const patientCreatedTimestamp = moment.unix(patientCreatedDateTime);

            const now = moment();
            let startTimestamp = patientCreatedTimestamp.add(2, "days");

            startTimestamp = now < startTimestamp ? startTimestamp : now;

            const data = {
                id: patientId,
                timezone: timezone,
                start_iso_timestamp: startTimestamp.toISOString(),
                end_iso_timestamp: moment
                    .unix(patientCreatedDateTime)
                    .add(7, "days")
                    .subtract(45, "minutes")
                    .toISOString(),
            };
            const availability = await api.patient.get_upcoming_intake_availability({ data });
            setIntakeAppointments(availability["upcoming_availability"]);
        })();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        handleResize();
    }, [intakeAppointments]);

    function handleResize() {
        const calendarBody = document.getElementById("calendar-body");
        const calendarColumns = document.getElementsByClassName("calendar-col");

        if (calendarBody && calendarColumns && calendarColumns[0]) {
            const columnWidth = Math.min(calendarColumns[0].getBoundingClientRect().width, 126);

            const calendarWidth = calendarBody.getBoundingClientRect().width;

            let columnsToDisplay = Math.floor(calendarWidth / columnWidth);

            if (columnsToDisplay > 7) {
                columnsToDisplay = 7;
            } else if (columnsToDisplay <= 3) {
                columnsToDisplay = 1;
            } else {
                columnsToDisplay = 5;
            }

            // Additional context: specifically as it relates to HCP
            // We want to show a calendar component for the next 7 days starting
            // two days after today (5 day total window)
            // Generally, this will return a 5 day window
            // Rather than rely on hardcoding time windows, we set `allowGetMoreDays`
            // and `disableNextArrow` then define the number of days and maxDayIndex both
            // as the length of the response

            // when the browser resizes, for some reason React forgets the intakeAppointments
            // to get around this, if it's resizing, reset it to 5 on the catch
            let actualAppointmentSize;
            try {
                actualAppointmentSize = intakeAppointments.length;
            } catch (error) {
                actualAppointmentSize = 5;
            }
            const maxDayIndex = Math.min(actualAppointmentSize, columnsToDisplay);

            setNumDaysToShow(maxDayIndex);
        }
    }

    const today = new Date();
    const todaysDate = {
        day_of_week: today.toLocaleDateString("default", { weekday: "long" }),
        month_of_year: today.toLocaleString("default", { month: "long" }),
        day: today.getDate(),
        year: today.getFullYear(),
    };

    const timePickerProps = {
        schedulingData: intakeAppointments,
        setSchedulingData: setIntakeAppointments,
        todaysDate,
        patientDetails: { username: patientId },
        selectedAppointment,
        setSelectedAppointment,
        className: "",
        numDaysToShow: numDaysToShow,
        maxHeight: 320,
        maxDayIndex: null,
        allowGetMoreDays: true,
        disableNextArrow: false,
    };

    return (
        <div className={"ScheduleCall-container"}>
            {intakeAppointments && (
                <AppointmentTimePicker
                    {...timePickerProps}
                    gridClassName="ScheduleCall-time_picker_grid"
                    calendarBodyClassName="ScheduleCall-calendar_body"
                />
            )}
            {!intakeAppointments && (
                <div className={"m-3 font-italic txt-gry"}>Loading Therapist Availability...</div>
            )}
        </div>
    );
};

export default ScheduleCall;
