import React from "react";
import { withRouter } from "react-router-dom";
import { api } from "../../../../../APIRequests";
import { Button, Card } from "../../../../../component-library";
import CardHeading from "../../../../UI/Headings/CardHeading";
import Hr from "../../../../UI/Hr";

const actionDescription =
    "Resend the patient’s email verification to the email address on file. Note email " +
    "verification links expire after 24 hours.";

const VerifyPatientEmailCard = (props) => {
    const patientId = props.match.params.patientId;
    const isEmailVerified = props.patientDetails.email_verified;

    const onClick = async () => {
        await api.admin.trigger_email_verification_email({ data: { member_id: patientId } });
    };

    return (
        <div className={"AdminTab__VerifyPatientEmailCard"}>
            <Card className={"p-3 p-lg-4 mb-3 mb-lg-4"}>
                <CardHeading className="fs-16 m-0 mb-3" text={"Patient Email Verification"} />
                <Hr />
                <div className="no-gutters row w-100">
                    <div className="mt-4">
                        <p className={"admin-card-description"}>{actionDescription}</p>
                        <p className={"mb-1 font-weight-bold"}>Status</p>
                        <div className="fs-14">
                            <span>{isEmailVerified ? "Email verified" : "Email not verified"}</span>
                        </div>
                        <Button
                            className="Btn Btn--pri Btn-sm mt-3"
                            onClick={onClick}
                            disabled={isEmailVerified}
                            buttonType={"primary-outline"}>
                            Resend Verification Email
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default withRouter(VerifyPatientEmailCard);
