// import * as yup from "yup";
// import {dobValidationSchemaFactory} from "../../../utils/yupSchemaUtils";

import { transformTimeSlotArray } from "../../../ScheduleAvailability/ScheduleEvent";
import {
    allottedTimeFactory,
    CM_SERVICES_20_MINS_MINIMUM_MONTH,
    PHQ_OR_GAD_96127,
    timeSlotArray,
} from "../../../ScheduleAvailability/constants";

export const CARE_COORDINATION_CALL_FOCUS_OPTIONS = {
    "Member Engagement": false,
    Medication: false,
    SUD: false,
    "Suicide Ideations/Crisis": false,
    "Symptom Management": false,
    Medical: false,
    SDoH: false,
    Other: false,
};

export const DISCHARGE_SCHEMA = [
    {
        name: "discharge_type",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "ED", display: "ED" },
                { value: "Urgent Care", display: "Urgent Care" },
                { value: "BH In-Patient", display: "BH In-Patient" },
                { value: "Medical In-Patient", display: "Medical In-Patient" },
                { value: "SUD Inpatient", display: "SUD Inpatient" },
                { value: "N/A", display: "N/A" },
                { value: "Other", display: "Other" },
            ],
            label: "Discharge Type",
            placeholder: "Select",
        },
    },
    {
        name: "discharge_date",
        elementType: "datepicker",
        elementConfig: {
            type: "text",
            label: "Discharge Date",
            placeholder: "01/01/2020",
        },
    },
    {
        name: "additional_discharge_information",
        elementType: "textarea",
        elementConfig: {
            type: "textArea",
            label: "Additional Discharge Information",
            placeholder: "Type comment...",
        },
    },
];

export const CARE_COORDINATION_SCHEMA = [
    {
        name: "session_date",
        elementType: "input",
        elementClasses: "col-12 col-lg-2",
        elementConfig: {
            type: "text",
            label: "Call Started At",
            placeholder: "01/01/2020",
        },
    },
    {
        name: "session_from",
        elementType: "select",
        elementClasses: "col-12 col-lg-1",
        elementConfig: {
            sentenceCase: false,
            options: transformTimeSlotArray(timeSlotArray),
            label: "",
            placeholder: "12:00 pm",
        },
    },
    {
        name: "call_type",
        elementType: "select",
        elementClasses: "col-9",
        elementConfig: {
            label: "CPT Description",
            placeholder: "Select",
            options: [
                {
                    value: CM_SERVICES_20_MINS_MINIMUM_MONTH,
                    display: `99484: CM Services; min 20 mins minimum/calendar month (${allottedTimeFactory(
                        CM_SERVICES_20_MINS_MINIMUM_MONTH,
                    )} minutes)`,
                },
                { value: PHQ_OR_GAD_96127, display: "96127: PHQ or GAD" },
            ],
        },
    },
    {
        name: "content.purpose_of_call",
        elementType: "select",
        elementClasses: "col-12",
        elementConfig: {
            options: [
                { value: "Monthly", display: "Monthly" },
                { value: "Referral", display: "Referral" },
                { value: "Clinical Team Meeting", display: "Clinical Team Meeting" },
                { value: "PHQ9", display: "PHQ9" },
                { value: "PCP Coordination", display: "PCP Coordination" },
                { value: "Other", display: "Other" },
            ],
            label: "Purpose of Call",
            placeholder: "Select",
        },
    },
    {
        name: "content.additional_information",
        elementType: "textarea",
        elementClasses: "col-12",
        elementConfig: {
            type: "textArea",
            label: "Additional Information",
            placeholder: "Type comment...",
        },
    },
    {
        name: "content.call_outcome",
        elementType: "select",
        elementClasses: "col-12 col-lg-2",
        elementConfig: {
            options: [
                { value: "Attempted", display: "Attempted" },
                { value: "Completed", display: "Completed" },
            ],
            label: "Call Outcome",
            placeholder: "Select",
        },
    },
    {
        name: "content.plan",
        elementType: "select",
        elementClasses: "col-12 col-lg-9",
        elementConfig: {
            options: [
                { value: "Call Monthly", display: "Call Monthly" },
                { value: "Prescriber Consult", display: "Prescriber Consult" },
                { value: "PCP Consult", display: "PCP Consult" },
                { value: "Clinical Team Meeting", display: "Clinical Team Meeting" },
                { value: "Other", display: "Other" },
            ],
            label: "Plan",
            placeholder: "Select",
        },
    },
    {
        name: "content.call_focus",
        elementType: "input",
        elementClasses: "col-12",
        elementConfig: {
            type: "checkbox",
            options: CARE_COORDINATION_CALL_FOCUS_OPTIONS,
            label: "Call Focus",
            placeholder: "",
        },
    },
    {
        name: "content.patient_outcome_answer",
        elementClasses: "col-12 col-lg-7",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Patient Assessment",
            placeholder: "",
        },
    },
    {
        name: "content.discharges",
        elementType: "discharges",
        elementConfig: {
            label: "",
            placeholder: "",
        },
    },
    {
        name: "ICD_10_axis_1_diagnosis",
        elementClasses: "col-12 col-lg-6",
        elementType: "reactselect",
        elementConfig: {
            options: [],
            label: "ICD-10 Primary Diagnosis",
            placeholder: "",
        },
    },
    {
        name: "ICD_10_axis_2_diagnosis",
        elementClasses: "col-12 col-lg-6",
        elementType: "reactselect",
        elementConfig: {
            options: [],
            label: "ICD-10 Secondary Diagnosis",
            placeholder: "",
        },
    },
    {
        name: "signature",
        elementType: "input",
        elementClasses: "col-12 col-lg-7",
        elementConfig: {
            hideTopLabel: true,
            displayName: "Click to Sign",
            type: "checkbox",
            label: "Signature",
            classNames: "font-weight-bold",
        },
    },
    {
        name: "provider_cp_credentials",
        elementType: "input",
        elementClasses: "col-12 col-lg-3",
        elementConfig: {
            type: "text",
            label: "Credentials",
            placeholder: "",
        },
    },
];
