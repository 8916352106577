// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import { SidebarListContainer, SidebarList, SidebarCollapsibleListItem } from "../ReportsView";

import { toTitleCase } from "../../../utils/filters";

const XBARReportSidebar = ({ sidebarData, reportType, accordionId }) => {
    return (
        <SidebarListContainer>
            <div className="accordion" id={accordionId}>
                <SidebarList>
                    {sidebarData.map((item) => {
                        return (
                            <SidebarCollapsibleListItem
                                accordionId={accordionId}
                                key={item.uid}
                                uid={item.uid}
                                title={toTitleCase(item.title)}
                                subListItems={item.sections}
                            />
                        );
                    })}
                </SidebarList>
            </div>
        </SidebarListContainer>
    );
};

export default XBARReportSidebar;
