import React from "react";

const renderIcon = function (props, route, src, alt) {
    return (
        <img
            className="cursor"
            key={route}
            onClick={() => props.history.push(route)}
            src={src}
            alt={alt}
        />
    );
};

/*
    classMap is an object that lists out classNames and their upper threshold limit in order
    classMap = {
        <class_name>: <threshold_upper_limit>
    }

*/
const renderDefaultBackgroundColorBasedOnThresh = function (threshold, classMap) {
    let classes = "";
    for (let key of Object.keys(classMap)) {
        if (threshold < classMap[key]) {
            classes = key;
            break;
        }
    }
    return classes;
};

const insuranceListToSelectFormat = (insuranceList) =>
    insuranceList.map((insurance) => {
        return {
            value: insurance.value,
            label: insurance.display,
        };
    });

const insuranceListToSelectFormatV2 = (insuranceList) =>
    insuranceList.map((insurance) => {
        return {
            value: insurance,
            label: insurance,
        };
    });

export {
    renderIcon,
    renderDefaultBackgroundColorBasedOnThresh,
    insuranceListToSelectFormat,
    insuranceListToSelectFormatV2,
};
