// Copyright aptihealth, inc. 2021 All Rights Reserved
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "../styles.scss";
import { Form, Formik } from "formik";
import FormikInput from "../../../UI/FormikInput";
import Button from "../../../UI/Button";
import { api } from "../../../../APIRequests";
import * as yup from "yup";
import { yup_password_factory } from "../../../../utils/yupSchemaUtils";
import { showAlertWithAction } from "../../../../redux/actions/alerts";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import jwt_decode from "jwt-decode";
import { CustomForm } from "../../../../component-library/CustomForm";

const MemberAuth = (props) => {
    const { sharedWorkflowData, setSharedWorkflowData } = props;

    const [loginAttempts, setLoginAttempts] = useState(0);
    const [loginError, setLoginError] = useState(null);

    const authenticateMember = async (authData) => {
        const creds = {
            USERNAME: authData.email,
            PASSWORD: authData.password,
        };

        try {
            const response = await api.auth.login({ data: creds });
            if (!isEmpty(response["ChallengeParameters"])) {
                throw Error();
            }
            let idToken = response.AuthenticationResult.IdToken;

            global.workflowAuthIdToken = idToken;
            global.workflowAuthAccessToken = response.AuthenticationResult.AccessToken;
            global.workflowAuthRefreshToken = response.AuthenticationResult.RefreshToken;

            return jwt_decode(idToken)["cognito:username"];
        } catch (e) {
            console.log(e);
            setLoginError(
                "Email or password is incorrect. Please try again or select Get sign in help",
            );
            return null;
        }
    };

    const submitHandler = async (formData) => {
        setLoginAttempts((prev) => prev + 1);
        try {
            const username = await authenticateMember(formData);
            const options = { params: { showLoader: true } };
            const data = {
                workflow_instance_entity_id: sharedWorkflowData.workflow_instance.entity_id,
                workflow_instance_sort_key: sharedWorkflowData.workflow_instance.sort_key,
                node_name: "member_auth",
                state: {
                    number_of_sign_in_attempts: loginAttempts + 1,
                },
            };

            if (username) {
                data.state.username = username;
                data.state.member_auth_complete = true;
                data.state.member_auth_success = true;
            }

            const response = await api.workflow.update_state_and_get_next_node({ options, data });
            setSharedWorkflowData({
                ...sharedWorkflowData,
                workflow_instance: response.workflow_instance,
            });
        } catch (e) {
            console.log(e);
            props.showAlertWithAction("Error updating workflow.");
        }
    };

    const getHelpClickHandler = async () => {
        const options = { params: { showLoader: true } };
        const data = {
            workflow_instance_entity_id: sharedWorkflowData.workflow_instance.entity_id,
            workflow_instance_sort_key: sharedWorkflowData.workflow_instance.sort_key,
            node_name: "member_auth",
            state: {
                is_help_needed: true,
                member_auth_complete: true,
                request_to_sign_in_with_credentials: false,
                member_auth_success: false,
            },
        };
        const response = await api.workflow.update_state_and_get_next_node({ options, data });
        setSharedWorkflowData({
            ...sharedWorkflowData,
            workflow_instance: response.workflow_instance,
        });
    };

    const renderForm = (formikProps) => {
        const formikInputProps = {
            inputClasses: "Input--sm",
            errors: formikProps.errors,
            touched: formikProps.touched,
        };
        return (
            <Form>
                <div className="mx-auto px-0">
                    <div className="px-0 row pb-3">
                        <div className={"col-12"}>
                            <FormikInput
                                {...formikInputProps}
                                formEl={{
                                    name: "email",
                                    elementType: "input",
                                    elementConfig: {
                                        type: "email",
                                        label: "Email Address",
                                        placeholder: "Enter Email Address",
                                    },
                                }}
                            />
                        </div>

                        <div className={"col-12"}>
                            <FormikInput
                                {...formikInputProps}
                                formEl={{
                                    name: "password",
                                    elementType: "input",
                                    elementConfig: {
                                        type: "password",
                                        label: "Password",
                                        placeholder: "Enter Password",
                                    },
                                }}
                            />
                            {loginError ? (
                                renderLogInError()
                            ) : (
                                <p style={{ color: "#6C757D", "font-size": "16px" }}>
                                    Note: If you have not set-up a password yet, select ‘Get sign in
                                    help’ below
                                </p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between flex-column align-items-center">
                    <Button
                        type={"submit"}
                        className={"Btn Btn--pri Btn--sm-2 workflow-btn-lg"}
                        key={"submit"}>
                        Sign in
                    </Button>
                    <div
                        style={{ color: "#6C757D" }}
                        className={"d-flex justify-content-center align-items-center pt-4 pb-4"}>
                        <hr className={"lines mr-3"} />
                        or
                        <hr className={"lines ml-3"} />
                    </div>
                    <Button
                        onClick={getHelpClickHandler}
                        className={"Btn Btn--otl-pri Btn--sm-2 workflow-btn-lg"}
                        type={"button"}>
                        Get sign in help
                    </Button>
                </div>
            </Form>
        );
    };

    const renderLogInError = () => {
        if (!loginError) {
            return null;
        }

        const errors = loginError.split(". ");

        return errors.map((error) => {
            return (
                <p className="mb-0" style={{ color: "#E20E20", "font-size": "14px" }}>
                    {error}.
                </p>
            );
        });
    };

    return (
        <div className={"p-lg-5 p-3"}>
            <div className={"fs-22 txt-pri fw-bold mb-5 text-center mt-5"}>
                Welcome! Sign in to continue
            </div>
            <div className={"workflow-container-sm"}>
                {sharedWorkflowData && sharedWorkflowData.workflow_instance && (
                    <>
                        <CustomForm
                            initialValues={{
                                email: "",
                                password: "",
                            }}
                            validationSchema={validation}
                            onSubmit={submitHandler}
                            render={renderForm}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

const validation = yup.object().shape({
    email: yup.string().email("Enter a valid email address").required("Email is required"),
    password: yup_password_factory(),
});

export default connect(null, { showAlertWithAction })(withRouter(MemberAuth));
