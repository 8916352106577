import React from "react";
import { BehavioralReviewLastNotes, PrescribeReviewLastNotes } from "./ReviewLastNotes";
import { DisplayWorkflowStepWithVideoClient } from "./DisplayWorkflowStepWithVideoClient";
import {
    BehavioralActions,
    BehavioralExploration,
    BehavioralInterventions,
    BehavioralSymptomTrends,
} from "./BehavioralWorkflowSteps";
import { ScheduleNextCall } from "./ScheduleNextCall";
import { Signoff } from "./Signoff";
import { enrichBehavioralNoteContent, enrichPrescribeNoteContent } from "./WorkflowFunctions";
import { withRouter } from "react-router-dom";
import images from "../../../../../utils/images";
import { providerTypeToDefaultEvent } from "../../../../Provider/PatientView/MemberProfile/MemberProfileHeader";
import {
    _30_MIN_INDIVIDUAL_90832,
    _45_MIN_INDIVIDUAL_90834,
    _60_MIN_INDIVIDUAL_90837,
    DIAGNOSTIC_INTERVIEW_90791,
    DIAGNOSTIC_INTERVIEW_90792,
    ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215,
    FAMILY_THERAPY_90847,
    MEDICATION_MGMT_99212,
    MEDICATION_MGMT_99213,
    MEDICATION_MGMT_99214,
    FAMILY_THERAPY_WO_PATIENT_90846,
    OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
    OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
} from "../../../../Provider/ScheduleAvailability/constants";
import {
    PrescribeMedicalInformation,
    PrescribeMedicationImpact,
    PrescribeAssessmentPlan,
    PrescribeSymptomTrends,
} from "./PrescribeWorkflowSteps";
import { InterventionAndPlan } from "./InterventionAndPlan/InterventionAndPlan";

/*
    This file is used to give a high level overview of the guided session functionality
    to orient yourself with all the files/components
 
    VideoClient src/containers/VideoClient/index.js 
        this component sets up global functionality specific to the video call
        such as audio/video, and the workflow state based on the call event_type/cpt
        code

        GuidedVideoCall src/components/Common/GuidedVideoCall/index.js
            responsible for displaying the high level steps needed for 
            the video call. It shows the following flow to a provider
                1. AllowAVStep src/components/Common/GuidedVideoCall/Steps/AllowAVStep.js
                2. PreviewStep src/components/Common/GuidedVideoCall/Steps/PreviewStep.js
                3. ProviderVideoWorkflowStep src/components/Common/GuidedVideoCall/Steps/ProviderVideoWorkflowStep.js
                    (called ProviderVideoWorkflow in the GuidedVideoCall file)

            After the third step has been reached the workflow specific steps relevant to 
            the call's cpt code/event type take effect, and all of the steps are rendered inside
            the ProviderVideoWorkflowStep component

            NOTE: ProviderVideoWorkflowStep maintains state
            separate from VideoClient and GuidedVideoCall such as its own step counter
            that marks what workflow step the provider is on
 */

/*
   The schema for a workflow is defined below. The specific steps and details are broken
   out into their own associated functions based on event type/provider type

    {
        "steps": {
            header: string (optional)
                the string that is displayed in VideoViewBaseComponent
                if omitted the header is not displayed,
            caption: string (optional)
                the string that is displayed in the GuidedVideoSection within
                the DisplayWorkflowStepWithVideoClient src/components/Common/GuidedVideoCall/Steps/ProviderWorkflow/DisplayWorkflowStepWithVideoClient.js)
            short: string (required)
                the string that is displayed in the GuidedVideoSection within the GuidedCallProgress
                component,
            shortClassName: string (optional)
                allows modifications to the workflow step arrows at the top of the guided session
            component: react component (required)
                the component that is formed within VideoViewBaseComponent src/components/Common/GuidedVideoCall/index.js
                typically this will be DisplayWorkflowStepWithVideoClient,
            subComponent: react component (optional depending on the component used)
                typically the child component of component,
            startCall: bool (optional)
                runs code specific to starting the call when the workflow step is incremented 
                see ProviderVideoWorkflowStep for how its used,
            carePlanTips: str[]
                Used to display tips on how the care plan
                should be filled out see the CarePlanWidget src/components/Common/GuidedVideoCall/Steps/ProviderWorkflow/CarePlanWidget/CarePlanWidget.js,
            saveNote: bool (optional)
                runs code specific to saving the note when the workflow step is incremented
                see ProviderVideoWorkflowStep for how its used,
            customTransition: bool (optional)
                when true hides the next/prev buttons on the guided session
                when false we assume that the component/subComponent will add the next/prev steps
                see DisplayWorkflowStepWithVideoClient for how its used,
            endCall: bool (optional)
                runs code specific to ending the call when the workflow step is incremented
                see ProviderVideoWorkflowStep for how its used,
            isDisplayingGuidedSession: bool (optional)
                a bandaid to adjust the guided session height to account for the tool bar offset
                when the video client is shown see src/components/Common/GuidedVideoCall/index.js
        },
        "details": {

        }
    }

    */
export const BEHAVIORAL_NOTE_V2_WORKFLOW_STEPS = () => [
    {
        header: "Review Previous Session Summary",
        short: "Review",
        component: BehavioralReviewLastNotes,
    },
    {
        caption: "Symptom Trends",
        short: "Symptoms",
        subTitle: "Document the patient’s current symptoms, including severity, duration, intensity, symptom trend, and impact on functioning.",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: BehavioralSymptomTrends,
        isDisplayingGuidedSession: true,
        startCall: true
    },
    {
        caption: "Exploration",
        short: "Exploration",
        subTitle: "Document any changes (e.g., home, work, relationships, healthcare use, etc.) correlating/affecting symptoms.",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: BehavioralExploration,
        isDisplayingGuidedSession: true,
        saveNote: true
    },
    {
        caption: "Interventions & Plans",
        subTitle: "Document therapeutic techniques aligned with evidence-based practices used during the session and strategies recommended between sessions.",
        short: "Interventions/Plans",
        shortClassName: "interventions_and_plans",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: InterventionAndPlan,
        customTransition: true,
        saveNote: true,
        isDisplayingGuidedSession: true,
    },
    {
        caption: "Schedule Next Appointment",
        short: "Schedule",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: ScheduleNextCall,
        saveNote: true,
        isDisplayingGuidedSession: true,
        customTransition: true
    },
    {
        header: "Review Session Summary",
        short: "Sign-Off",
        component: Signoff,
        endCall: true,
        saveNote: true,
    },
];

export const BEHAVIORAL_NOTE_V2_WORKFLOW_DETAILS = () => {
    return {
        viewLastNoteSelf: true,
        viewLastNoteOther: "PRESCRIBE",
        createNote: true,
        noteVersion: 2,
        noteEnricher: enrichBehavioralNoteContent,
        relevantPatientQuestionSections: {
            symptomTrends: [0, 3, 4],
            changes: [1],
            healthCareUse: [2],
        },
        iconComponents: [],
        startingStep: -1,
        endCallShouldRoute: false,
    };
};

export const BEHAVIORAL_CLINICAL_ASSESSMENT_WORKFLOW_DETAILS = () => {
    return {
        viewLastNoteSelf: false,
        viewLastNoteOther: null,
        createNote: false,
        createClinicalAssessmentNote: true,
        iconComponents: [],
        startingStep: 0,
        endCallShouldRoute: true,
        skipEndCallModal: false,
        showNoShowModalOnly: true,
    };
};

export const PRESCRIBE_NOTE_V2_WORKFLOW_STEPS = () => [
    {
        header: "Review Previous Session Summary",
        short: "Review",
        component: PrescribeReviewLastNotes,
    },
    {
        caption: "Symptom Trends",
        short: "Symptoms",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: PrescribeSymptomTrends,
        startCall: true,
        carePlanTips: [
            "Explore overall progress",
            "Note symptoms/frequency/duration/intensity",
            "Identify triggers or precipitants impacting symptoms",
            "Explore patient feedback",
            " Complete prescriber rating of symptom duration",
        ],
    },
    {
        caption: "Medication Impact",
        short: "Medication",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: PrescribeMedicationImpact,
        saveNote: true,
        carePlanTips: [
            "Review current impact of medications",
            "Review patient report re: medication experiences",
            "Utilize I-STOP if clinically warranted",
            "Complete prescriber rating regarding benefit and side effects",
        ],
    },
    {
        caption: "Medical Information",
        short: "Medical",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: PrescribeMedicalInformation,
        saveNote: true,
        carePlanTips: [
            "Note Psychosocial changes",
            "Explore healthcare use",
            "Review labs as indicated",
            "Complete mental status ",
        ],
    },
    {
        caption: "Assessment & Plan",
        short: "Assessment",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: PrescribeAssessmentPlan,
        saveNote: true,
        carePlanTips: [
            "Rate patient progress across each diagnosis",
            "Complete assessment and plan",
            "Enter medication ( medication initiated or adjusted, dosage & change)",
            "Address medication adherence and check adherence interventions",
        ],
    },
    {
        caption: "Schedule Next Appointment",
        short: "Schedule",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: ScheduleNextCall,
        saveNote: true,
        customTransition: true,
        carePlanTips: ["Try to maintain regular appointment times ", "Confirm next appointment"],
    },
    {
        header: "Review Previous Session Summary",
        short: "Review",
        component: Signoff,
    },
];

export const PRESCRIBE_NOTE_V2_WORKFLOW_DETAILS = () => {
    return {
        viewLastNoteSelf: true,
        viewLastNoteOther: "BEHAVIORAL",
        createNote: true,
        noteVersion: 2,
        noteEnricher: enrichPrescribeNoteContent,
        relevantPatientQuestionSections: {
            symptomTrends: [0],
            medicationTrends: [1, 2, 3, 4],
            medicationDifficulties: [5],
            medicalProblems: [6],
        },
        iconComponents: [],
        startingStep: -1,
        endCallShouldRoute: false,
    };
};

export const DEFAULT_WORKFLOW_STEPS = () => [
    {
        caption: "Video Consultation",
        short: "Consultation",
        component: DisplayWorkflowStepWithVideoClient,
        fullScreen: true,
        startCall: true,
    },
];

export const DEFAULT_WORKFLOW_DETAILS = () => {
    return {
        viewLastNoteSelf: false,
        viewLastNoteOther: null,
        createNote: true,
        noteVersion: 1,
        iconComponents: [],
        startingStep: 0,
        endCallShouldRoute: true,
        skipEndCallModal: true,
    };
};

export const getCPTWorkflow = (eventType, provider) => {
    let processedEventType = eventType;

    if (!processedEventType) {
        processedEventType = providerTypeToDefaultEvent(provider);
    }

    switch (processedEventType) {
        case _30_MIN_INDIVIDUAL_90832:
        case _45_MIN_INDIVIDUAL_90834:
        case _60_MIN_INDIVIDUAL_90837:
        case OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204:
        case OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205:
        case FAMILY_THERAPY_WO_PATIENT_90846:
        case FAMILY_THERAPY_90847: {
            return {
                steps: BEHAVIORAL_NOTE_V2_WORKFLOW_STEPS(),
                details: BEHAVIORAL_NOTE_V2_WORKFLOW_DETAILS(),
            };
        }

        case DIAGNOSTIC_INTERVIEW_90791: {
            return {
                steps: DEFAULT_WORKFLOW_STEPS(),
                details: BEHAVIORAL_CLINICAL_ASSESSMENT_WORKFLOW_DETAILS(),
            };
        }

        case DIAGNOSTIC_INTERVIEW_90792:
        case MEDICATION_MGMT_99212:
        case MEDICATION_MGMT_99213:
        case MEDICATION_MGMT_99214:
        case ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215: {
            return {
                steps: PRESCRIBE_NOTE_V2_WORKFLOW_STEPS(),
                details: PRESCRIBE_NOTE_V2_WORKFLOW_DETAILS(),
            };
        }

        default: {
            return {
                steps: DEFAULT_WORKFLOW_STEPS(),
                details: DEFAULT_WORKFLOW_DETAILS(),
            };
        }
    }
};
