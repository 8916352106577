// Copyright aptihealth, inc. 2021 All Rights Reserved
import React, { useEffect } from "react";
import Button from "../../../UI/Button";
import { withRouter } from "react-router-dom";
import { api } from "../../../../APIRequests";
import { getQueryParams } from "../../../../utils/filters";

const Ineligible = (props) => {
    const { sharedWorkflowData, setSharedWorkflowData } = props;

    const eligibilityData =
        sharedWorkflowData.workflow_instance && sharedWorkflowData.workflow_instance.state;
    const practiceId = getQueryParams("practiceId", props.location.search);

    return (
        <div className={"p-lg-5 p-3 mt-5"}>
            <div className={"fs-22 txt-pri fw-bold mt-5 mb-2 text-center"}>
                We’re sorry, you’re not eligible
            </div>
            <div className={"workflow-container-xs mb-4"}>
                <div className={"p-lg-5 p-3 fs-14 txt-gry text-center"}>
                    {eligibilityData && (
                        <div>
                            <div className={"mb-4"}>
                                {eligibilityData &&
                                    eligibilityData.hasOwnProperty("eligible_dob") &&
                                    eligibilityData.eligible_dob === false && (
                                        <>
                                            At this time, aptihealth’s programs are designed for
                                            individuals ages 18 or older.
                                        </>
                                    )}
                            </div>
                            <div className={"mb-4"}>
                                {eligibilityData &&
                                    eligibilityData.hasOwnProperty("eligible_state_of_residence") &&
                                    eligibilityData.eligible_state_of_residence === false && (
                                        <>
                                            At this time, aptihealth does not have licensed
                                            providers in your state.
                                        </>
                                    )}
                            </div>
                            <div className={"mb-4"}>
                                Please hand this device back to a staff member who will help you
                                find mental healthcare from another provider.
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="workflow-container-sm d-flex justify-content-center">
                <Button
                    type={"button"}
                    className={"Btn Btn--pri Btn--sm-2 workflow-btn-lg"}
                    onClick={() => {
                        setSharedWorkflowData({});
                        props.history.push(`/app/workflow?practiceId=${practiceId}`);
                    }}>
                    Back to Home Screen
                </Button>
            </div>
        </div>
    );
};

export default withRouter(Ineligible);
