import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Portal } from "../../../../components/Common/Modal/Portal";
import { BubbleFillButton } from "../../../../components/UI/BubbleFill";
import Button from "../../../../components/UI/Button";

const GenericQuestionaire = (props) => {
    const {
        questionList = [],
        labelMap = {},
        displayAsModal = false,
        modalOpenButton,
        questionaireInfoTitle,
        questionaireInfoDescription,
        title,
        submitFn,
    } = props;

    const [questionsList, setQuestionsList] = useState(questionList);
    const [modalTrigger, setModalTrigger] = useState(null);

    let isValid = true;

    questionsList.every((question) => {
        if (question["answer"] == null) {
            isValid = false;
        }
        return isValid;
    });

    useEffect(() => {
        setQuestionsList(props.questionList);
    }, [props.questionList]);

    const optionSelectHandler = (answer_value, index) => {
        const clonedQuestionList = _.cloneDeep(questionsList);
        clonedQuestionList[index] = { ...clonedQuestionList[index], answer: answer_value };
        setQuestionsList(clonedQuestionList);
    };

    const handleSubmit = async () => {
        const data = {
            screening: questionsList.map(({ id, answer }) => {
                return { question_id: id, rating: answer };
            }),
        };
        await submitFn(data);
        setModalTrigger(!modalTrigger);
        const clonedQuestionList = _.cloneDeep(questionsList);
        clonedQuestionList.forEach((question) => delete question["answer"]);
        setQuestionsList(clonedQuestionList);
    };

    const questionaireInfoRender = (
        <div className="row my-3 border-top border-bottom py-2">
            <div className="col-7 d-flex flex-column justify-content-center">
                <div className="fw-bold">{questionaireInfoTitle}</div>
                <div className="font-color-gray fs-14">{questionaireInfoDescription}</div>
            </div>
            <div className="col-5">
                <div className="row justify-content-start fs-14">
                    {Object.values(labelMap).map((optionLabel) => (
                        <div className="col-3 fw-bold justify-content-center d-flex text-center">
                            <span className="m-auto">{optionLabel}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    const questionRender = questionsList.map((question, index) => {
        question["label"] = question.question;
        const label = (
            <div className="col-7 fw-bold">
                {index + 1}. {question.question}
            </div>
        );
        const rating = (
            <div className="col-5">
                <BubbleFillButton
                    isButtonTextActive={false}
                    onClick={(answer_value) => optionSelectHandler(answer_value, index)}
                    optionLabelMap={labelMap}
                    question={question}
                    isEditing={true}></BubbleFillButton>
            </div>
        );

        return (
            <div className="row py-3">
                {label}
                {rating}
            </div>
        );
    });

    const questionaireFooter = (
        <div className="row mt-5">
            <div className="w-100 d-flex justify-content-end">
                <Button
                    className="Btn Btn--otl-pri Btn-sm mr-3"
                    onClick={() => setModalTrigger(!modalTrigger)}>
                    Cancel
                </Button>
                <Button
                    disabled={!isValid}
                    className="js-GAD7__SubmitBtn Btn Btn--pri Btn-sm"
                    onClick={handleSubmit}>
                    Submit
                </Button>
            </div>
        </div>
    );

    if (displayAsModal && modalOpenButton) {
        return (
            <Portal
                className="generic_questionaire_modal"
                isBottomCloseActive={false}
                triggerModalStateChange={modalTrigger}
                openButtonRender={modalOpenButton}
                isTopCloseActive={false}>
                <div className="container">
                    <div className="row w-100 justify-content-start fw-bold">{title}</div>
                    {questionaireInfoRender}
                    {questionRender}
                    {questionaireFooter}
                </div>
            </Portal>
        );
    }

    return (
        <div className="container">
            {questionaireInfoRender}
            {questionRender}
        </div>
    );
};

export default GenericQuestionaire;
