// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import { withRouter } from "react-router-dom";
import Button from "../UI/Button";
import CardSecondary from "../UI/Card/CardSecondary";
import images from "../../utils/images";

const InstantCall = (props) => {
    const { calls } = props;

    return calls
        .filter((call) => call.hasOwnProperty("is_instant") && call["is_instant"])
        .map((call) => {
            const { provider_name, callId, patient_username } = call;
            const queryParams = `?callId=${callId}`;
            return (
                <CardSecondary
                    key={callId}
                    className="mt-4 d-flex d-block p-3 instant-call row mx-1">
                    <div className={"col-3 col-lg-1 mt-2"}>
                        <img
                            src={images("./icons/call-large-green.svg")}
                            alt="Menu"
                            className="img-fluid"
                        />
                    </div>
                    <div className={"col-9 col-lg-6 mt-2"}>
                        <h4>
                            <b>You have an incoming call from {provider_name}.</b>
                        </h4>
                        <p className="fs-15">
                            Please click on the <b>Join Call</b> button to receive the call.
                        </p>
                    </div>
                    <div className="p-lg-3 d-flex align-items-center col-12  col-lg-5 justify-content-end">
                        <Button
                            className="Btn Btn--pri"
                            onClick={() =>
                                props.history.push({
                                    pathname: "/app/video-call",
                                    search: queryParams,
                                })
                            }>
                            Join Call
                        </Button>
                    </div>
                </CardSecondary>
            );
        });
};

export default withRouter(InstantCall);
