import React from "react";
import PropTypes from "prop-types";

import { Button } from "../Button";
import { ButtonTypes } from "../constants";

import { createClassNameString } from "../utils";

import "./styles.scss";

export const Toast = ({
    children,
    className,
    toastId,
    messageType,
    dismissMessage,
    hideDismissButton,
    hideReportButton,
    dismissTimeout,
    requestId,
    onDismissButtonClick,
    onReportButtonClick,
    ...props
}) => {
    if (dismissTimeout) {
        setTimeout(() => {
            onDismissButtonClick({ toastId });
        }, dismissTimeout);
    }

    return (
        <div className={createClassNameString(["apti-Toast", className, messageType])} {...props}>
            <div className={"apti-Toast__message-contents"}>
                {children}
                {requestId && <div>Request ID: {requestId}</div>}
            </div>
            <div className={"apti-Toast__button-group"}>
                {!hideReportButton && (
                    <Button
                        className="apti-Toast__button"
                        buttonType={ButtonTypes.primary}
                        onClick={() => onReportButtonClick()}>
                        Report
                    </Button>
                )}
                {!hideDismissButton && (
                    <Button
                        className="apti-Toast__button"
                        buttonType={ButtonTypes.primary}
                        onClick={() => onDismissButtonClick({ toastId })}>
                        {dismissMessage}
                    </Button>
                )}
            </div>
        </div>
    );
};

Toast.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    className: PropTypes.string,
    messageType: PropTypes.oneOf(["success", "warning", "error", "error_v2", "success_v2"]),
    dismissMessage: PropTypes.string,
    hideDismissButton: PropTypes.bool,
    hideReportButton: PropTypes.bool,
    dismissTimeout: PropTypes.number,
    requestId: PropTypes.string,
    onReportButtonClick: PropTypes.func,
};

Toast.defaultProps = {
    className: undefined,
    messageType: "success",
    dismissMessage: "Dismiss",
    hideDismissButton: false,
    hideReportButton: true,
    dismissTimeout: 10000,
    requestId: undefined,
    onReportButtonClick: () => {
        console.log("button click not implemented");
    },
};
