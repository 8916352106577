/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const BidirectionalChevronVerticalIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="BidirectionalChevronVertical"
                {...props}>
                <path d="M11.7071 12.707C11.3166 13.0975 10.6834 13.0975 10.2929 12.707C9.90237 12.3165 9.90237 11.6833 10.2929 11.2928L16 5.58569L21.7071 11.2928C22.0976 11.6833 22.0976 12.3165 21.7071 12.707C21.3166 13.0975 20.6834 13.0975 20.2929 12.707L16 8.41412L11.7071 12.707Z" />
                <path d="M20.2929 19.2928C20.6834 18.9023 21.3166 18.9023 21.7071 19.2928C22.0976 19.6833 22.0976 20.3165 21.7071 20.707L16 26.4141L10.2929 20.707C9.90237 20.3165 9.90237 19.6833 10.2929 19.2928C10.6834 18.9023 11.3166 18.9023 11.7071 19.2928L16 23.5857L20.2929 19.2928Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="BidirectionalChevronVertical"
                {...props}>
                <path d="M12.2071 12.9999C10.8708 12.9999 10.2015 11.3842 11.1465 10.4392L16 5.58569L20.8536 10.4392C21.7985 11.3842 21.1293 12.9999 19.7929 12.9999H12.2071Z" />
                <path d="M19.7929 18.9999C21.1293 18.9999 21.7985 20.6156 20.8536 21.5606L16 26.4141L11.1464 21.5606C10.2015 20.6156 10.8708 18.9999 12.2071 18.9999L19.7929 18.9999Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="BidirectionalChevronVertical"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM16.3598 15.8296C15.6511 16.5383 16.1531 17.7501 17.1553 17.7501H22.8447C23.8469 17.7501 24.3489 16.5383 23.6402 15.8296L20 12.1895L16.3598 15.8296ZM23.6402 24.1706C24.3489 23.4619 23.8469 22.2501 22.8447 22.2501H17.1553C16.1531 22.2501 15.6511 23.4619 16.3598 24.1706L20 27.8108L23.6402 24.1706Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

BidirectionalChevronVerticalIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

BidirectionalChevronVerticalIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
