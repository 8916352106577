// Copyright aptihealth, inc. 2019 All Rights Reserved

export const PROVIDER_BASIC_SCHEMA = [
    {
        name: "name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Full Name",
            placeholder: "Full Name",
        },
    },
    {
        name: "provider_type",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Provider Type",
            placeholder: "Provider Type",
        },
    },
    {
        name: "email",
        elementType: "input",
        elementConfig: {
            type: "email",
            label: "Email",
            placeholder: "Email",
        },
    },
    {
        name: "mobile",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Mobile",
            placeholder: "Mobile",
        },
    },
    {
        name: "cp_credentials",
        elementType: "input",
        elementConfig: {
            type: "email",
            label: "CP Credential",
            placeholder: "CP Credential",
        },
    },
    {
        name: "npi_number",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "NPI Number",
            placeholder: "NPI Number",
        },
    },
    {
        name: "practice",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Practice",
            placeholder: "Practice",
        },
    },
];
