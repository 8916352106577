// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useEffect } from "react";
import ReactDOM from "react-dom";

let portalRoot = document.getElementById("portal-root");

const withPortal = (WrappedComponent) => {
    const HOC = (props) => {
        let mountEl = document.createElement("div");

        useEffect(function () {
            portalRoot.appendChild(mountEl);
            return function () {
                portalRoot.removeChild(mountEl);
            };
        }, []);

        return ReactDOM.createPortal(<WrappedComponent {...props} />, mountEl);
    };

    return HOC;
};

export default withPortal;
