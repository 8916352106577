import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { createClassNameString } from "../../component-library";
import { ICON_SIZES, ICON_TYPES } from "../../constants/icons";

export const PrinterIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.FILLED) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}>
                <path d="M20 9L12 9V7L20 7V9Z" />
                <path d="M12 13L20 13V11L12 11V13Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 13V3H8V13H7C4.79086 13 3 14.7909 3 17V23C3 24.1046 3.89543 25 5 25H8V29H24V25H27C28.1046 25 29 24.1046 29 23V17C29 14.7909 27.2091 13 25 13H24ZM22 5H10V15H22V5ZM22 25H10V27H22V25ZM24.5 20C24.5 20.8284 23.8284 21.5 23 21.5C22.1716 21.5 21.5 20.8284 21.5 20C21.5 19.1716 22.1716 18.5 23 18.5C23.8284 18.5 24.5 19.1716 24.5 20Z"
                />
            </svg>
        );
    } else if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}>
                <path d="M12 13L20 13V11L12 11V13Z" />
                <path d="M20 9L12 9V7L20 7V9Z" />
                <path d="M23 21.5C23.8284 21.5 24.5 20.8284 24.5 20C24.5 19.1716 23.8284 18.5 23 18.5C22.1716 18.5 21.5 19.1716 21.5 20C21.5 20.8284 22.1716 21.5 23 21.5Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 3V13H25C27.2091 13 29 14.7909 29 17V23C29 24.1046 28.1046 25 27 25H24V29H8V25H5C3.89543 25 3 24.1046 3 23V17C3 14.7909 4.79086 13 7 13H8V3H24ZM22 15H10V5H22V15ZM24 17V15H25C26.1046 15 27 15.8954 27 17V23H5L5 17C5 15.8954 5.89543 15 7 15H8V17H24ZM10 25H22V27H10V25Z"
                />
            </svg>
        );
    }

    return null;
};

PrinterIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

PrinterIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
