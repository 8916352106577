import React, { useState } from "react";
import { FormField, Input } from "../../../../component-library";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "../../../UI/DatePicker";
import moment from "moment";
import { updateSelectedDate } from "../../../../redux/actions/calendar";
import CloseOnOutsideClick from "../../../Common/CloseOnOutsideClick/CloseOnOutsideClick";

export const CalendarAppointmentDatePicker = () => {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const dispatch = useDispatch();
    const selectedDate = useSelector((state) => state.calendar.selectedScheduleDate) || moment();

    const onDatePickerSelect = (newDate, changeType) => {
        if (changeType !== "monthChange") {
            const clonedSelectedDay = moment(newDate, "YYYY-MM-DD").local();
            dispatch(updateSelectedDate(clonedSelectedDay));
            setShowDatePicker(false);
        }
    };

    return (
        <FormField
            style={{ position: "relative" }}
            className="apti-CalendarAppointmentDateTimePicker--wrapped-fields"
            label="Date">
            <Input
                className="apti-CalendarAppointmentDateTimePicker--input-wrapper"
                onClick={() => setShowDatePicker(true)}
                inputClassName="apti-CalendarAppointmentDateTimePicker--input"
                placeholder="Date"
                value={selectedDate.format("MMMM DD, YYYY")}
            />
            {showDatePicker && (
                <CloseOnOutsideClick setShowComponent={setShowDatePicker}>
                    <div className="apti-CalendarAppointmentDateTimePicker--date-picker">
                        <DatePicker externalCtx={selectedDate} onDaySelect={onDatePickerSelect} />
                    </div>
                </CloseOnOutsideClick>
            )}
        </FormField>
    );
};
