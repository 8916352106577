// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component, Fragment } from "react";
import Accordion from "../../UI/Accordion/Accordion";
import CardSecondary from "../../UI/Card/CardSecondary";
import Button from "../../UI/Button";
import ContentLoader from "react-content-loader";
import {
    loadPatientInsuranceImagesUrlToState,
    loadReferralInsuranceImagesUrlToState,
} from "../../../containers/Dashboard/Patient/PatientInsurance";
import { connect } from "react-redux";
import images from "../../../utils/images";
import Input from "../../UI/Input";
import { api } from "../../../APIRequests";
import { formatMobileNumber } from "../../../utils/formInputUtils";

class CommonUserDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            insuranceImageUrls: null,
            insuranceImageTypes: null,
            expandedImg: null,
            newEmail: "",
            editEmail: false,
        };
    }

    componentDidMount() {
        (async () => {
            if (this.props.isReferral) {
                loadReferralInsuranceImagesUrlToState(this.props.details, this);
            } else {
                loadPatientInsuranceImagesUrlToState(this.props.details, this);
            }
        })();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.details.email === "" && this.props.details.email !== "") {
            this.componentDidMount();
        }
    }

    Loader = () => (
        <ContentLoader
            height={500}
            width={350}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="125" y="30" rx="3" ry="3" width="150" height="10" />
            <rect x="125" y="60" rx="3" ry="3" width="150" height="10" />
            <circle cx="45" cy="50" r="30" />
            <rect x="25" y="160" rx="3" ry="3" width="70" height="10" />
            <rect x="25" y="190" rx="3" ry="3" width="70" height="10" />
            <rect x="25" y="250" rx="3" ry="3" width="70" height="10" />
            <rect x="25" y="280" rx="3" ry="3" width="70" height="10" />
            <rect x="25" y="340" rx="3" ry="3" width="70" height="10" />
            <rect x="25" y="370" rx="3" ry="3" width="70" height="10" />
            <rect x="25" y="430" rx="3" ry="3" width="70" height="10" />
            <rect x="25" y="460" rx="3" ry="3" width="70" height="10" />
        </ContentLoader>
    );

    render() {
        /** no http calls in render */

        return (
            <Fragment>
                {this.state.expandedImg && (
                    <div className="ExpandedImagePopUp position-fixed d-flex align-items-center justify-content-center">
                        <div className="ExpandedImagePopUp__inner-wide d-flex flex-column text-center position-absolute">
                            <h2 className="fs-17 fw-bold mt-4">Card Image</h2>
                            <embed
                                className="PatientProfile__insurance-image-preview-expand"
                                src={this.state.expandedImg}
                                alt=""
                            />
                            <div className="ExpandedImagePopUp__btn-wpr d-flex justify-content-center pt-3 my-4">
                                <Button
                                    onClick={() => this.expandImg(null)}
                                    type="button"
                                    className="Btn Btn--otl-pri Btn-sm mx-2">
                                    Close
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                {this.props.details ? (
                    <div>
                        <CardSecondary className="mx-lg-auto mt-4 mt-lg-5 px-3 px-lg-4">
                            {this.props.children}
                            <hr className="m-0" />
                            <div className="px-3 mt-4">
                                {this.props.details && this.setDetails(this.props.details)}
                            </div>
                        </CardSecondary>
                    </div>
                ) : (
                    <div>
                        <CardSecondary className="mx-lg-auto mt-4 mt-lg-5 px-3 px-lg-4">
                            {this.Loader()}
                        </CardSecondary>
                    </div>
                )}
            </Fragment>
        );
    }

    updateReferralEmail = async (referralCode) => {
        let data = {};
        data["referralCode"] = referralCode;
        data["email"] = this.state.newEmail;
        const response = await api.shared.update_referral({ data });
        this.props.getReferral();
    };

    setDetails = (details) => {
        if (details.email !== "" && this.state.newEmail === "" && !this.state.editEmail) {
            this.setState({ newEmail: details.email });
        }
        return (
            <div>
                <div>
                    <div className="mb-4">
                        <p className="fw-bold mb-2 fs-14">Date of Birth</p>
                        <p className="mb-0 fs-14">{details.dob}</p>
                    </div>
                    <div className="mb-4">
                        <p className="fw-bold mb-2 fs-14">Gender</p>
                        <p className="mb-0 fs-14">{details.gender}</p>
                    </div>
                    <div className="mb-4">
                        <p className="fw-bold mb-2 fs-14">Mobile Number</p>
                        <p className="mb-0 fs-14">{formatMobileNumber(details.mobile)}</p>
                    </div>
                    <div className="mb-4">
                        <p className="fw-bold mb-2 fs-14">Email</p>
                        <div className="d-flex align-items-center">
                            {this.state.editEmail ? (
                                <Input
                                    className="w-100"
                                    value={this.state.newEmail}
                                    onChange={(e) => this.setState({ newEmail: e.target.value })}
                                />
                            ) : (
                                <p className="mb-0 fs-14">{details.email}</p>
                            )}
                            {this.props.isReferral ? (
                                !this.state.editEmail ? (
                                    <div className="cursor">
                                        <img
                                            className="ml-2"
                                            onClick={() =>
                                                this.setState({ editEmail: !this.state.editEmail })
                                            }
                                            src={images("./icons/edit-pencil.svg")}
                                            alt="view icon"
                                        />
                                    </div>
                                ) : (
                                    <div className="cursor">
                                        <img
                                            className="ml-2"
                                            onClick={() => {
                                                if (details.email === this.state.newEmail) {
                                                    this.setState({ editEmail: false });
                                                    return;
                                                }

                                                this.updateReferralEmail(details.referralCode).then(
                                                    () =>
                                                        this.setState({
                                                            editEmail: !this.state.editEmail,
                                                        }),
                                                );
                                            }}
                                            src={images("./icons/add-icon.svg")}
                                            alt="view icon"
                                        />
                                    </div>
                                )
                            ) : null}
                        </div>
                    </div>
                    <div className="mb-2">
                        <p className="fw-bold mb-2 fs-16">Address</p>
                    </div>
                    <div className="mb-2">
                        <p className="fw-bold mb-2 fs-14">Street</p>
                        <p className="mb-0 fs-14">{details.address.street}</p>
                    </div>
                    <div className="mb-2">
                        <p className="fw-bold mb-2 fs-14">City</p>
                        <p className="mb-0 fs-14">{details.address.city}</p>
                    </div>
                    <div className="mb-2">
                        <p className="fw-bold mb-2 fs-14">State</p>
                        <p className="mb-0 fs-14">{details.address.state}</p>
                    </div>
                    <div className="mb-2">
                        <p className="fw-bold mb-2 fs-14">Zip</p>
                        <p className="mb-0 fs-14">{details.address.zip_code}</p>
                    </div>
                </div>
                <hr className="m-0" />
                <div className="accordion" id="AptiAccordion">
                    <Accordion
                        text="Emergency Contact"
                        info={this.getEmergencyContact(this.props.details)}
                        ariaControls="collapseOne"
                        dataTarget="#collapseOne"
                        contentId="collapseOne"
                        ariaLabelledby="headingOne"
                    />
                    <hr className="m-0" />
                    {this.state.insuranceImageUrls && (
                        <Accordion
                            text="Primary Insurance Information"
                            info={this.getPrimaryInsuranceInformation(
                                this.props.details,
                                this.state.insuranceImageUrls,
                            )}
                            ariaControls="collapseTwo"
                            dataTarget="#collapseTwo"
                            contentId="collapseTwo"
                            ariaLabelledby="headingTwo"
                        />
                    )}
                    {details.secondary_insurance.member_id &&
                        details.secondary_insurance.carrier &&
                        details.secondary_insurance.state && (
                            <>
                                <hr className="m-0" />
                                {this.state.insuranceImageUrls && (
                                    <Accordion
                                        text="Secondary Insurance Information"
                                        info={this.getSecondaryInsuranceInformation(
                                            this.props.details,
                                            this.state.insuranceImageUrls,
                                        )}
                                        ariaControls="collapseThree"
                                        dataTarget="#collapseThree"
                                        contentId="collapseThree"
                                        ariaLabelledby="headingThree"
                                    />
                                )}{" "}
                            </>
                        )}
                </div>
            </div>
        );
    };

    getEmergencyContact = (details) => {
        return [
            { property: "Person Name", value: details.emergency_contact_person },
            { property: "Contact Number", value: details.emergency_contact_number },
        ];
    };

    expandImg = (url) => {
        this.setState({ expandedImg: url });
    };

    getPrimaryInsuranceInformation = (details, insuranceImgUrls) => {
        return [
            { property: "Insurance Company", value: details.primary_insurance.carrier },
            { property: "Member ID", value: details.primary_insurance.member_id },
            { property: "Group ID", value: details.primary_insurance.group_id },
            { property: "State of Insurance Plan", value: details.primary_insurance.state },
            {
                property: "Policy Holder Date of Birth",
                value: details.primary_insurance.policy_dob,
            },
            { property: "Policy Holder", value: details.primary_insurance.policy_holder_name },
            {
                property: "Insurance Card Images",
                value: [insuranceImgUrls.pfUrl, insuranceImgUrls.pbUrl],
                type: "img",
                onclick: this.expandImg,
            },
        ];
    };

    getSecondaryInsuranceInformation = (details, insuranceImgUrls) => {
        return [
            { property: "Insurance Company", value: details.secondary_insurance.carrier },
            { property: "Member ID", value: details.secondary_insurance.member_id },
            { property: "Group ID", value: details.secondary_insurance.group_id },
            { property: "State of Insurance Plan", value: details.secondary_insurance.state },
            {
                property: "Policy Holder Date of Birth",
                value: details.secondary_insurance.policy_dob,
            },
            { property: "Policy Holder", value: details.secondary_insurance.policy_holder_name },
            {
                property: "Insurance Card Images",
                value: [insuranceImgUrls.sfUrl, insuranceImgUrls.sbUrl],
                type: "img",
                onclick: this.expandImg,
            },
        ];
    };
}

const mapStateToProps = (state) => {
    return {
        timezone: state.auth.timezone,
    };
};

export default connect(mapStateToProps)(CommonUserDetails);
