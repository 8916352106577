/**
 * converts a number to a number with 2 decimal places if applicable
 * @param {number} value
 * @returns {number | string} returns a string if the value is a decimal so that
 * the trailing 0/0's are correctly maintained
 */
export const convertNumberToCurrency = (value) => {
    // if the number is a decimal round it to two decimal places
    if (value % 1 !== 0) {
        return value.toFixed(2);
    }

    // otherwise return the value
    return value;
};
