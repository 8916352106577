import React, { useEffect, useState } from "react";
import "./styles.scss";

const GenericSlider = (props) => {
    const { optionLabelMap, progress, onClick, ratingKey, labelsEnabled = false } = props;
    const [isActive, setIsActive] = useState(false);

    const answerKey = ratingKey ? ratingKey : "answer";
    const answer = progress[answerKey] || progress[answerKey] === 0 ? progress[answerKey] : 2;

    let inputClasses = isActive
        ? "form-range slider-range-active w-100"
        : "form-range slider-range w-100";

    if (props.forceActive) {
        inputClasses = "form-range slider-range-active w-100";
    }

    useEffect(() => {
        if (progress[answerKey] === null) {
            setIsActive(false);
        } else {
            setIsActive(true);
        }
    }, [progress]);

    return (
        <>
            <div className={"row"}>
                <div className={"col"}>
                    <input
                        type="range"
                        value={answer}
                        onChange={(e) => {
                            onClick(e);
                        }}
                        className={inputClasses}
                        min={0}
                        max={4}
                    />
                </div>
            </div>
            <div className={"row flex-nowrap"}>
                {Object.keys(optionLabelMap).map((optionId, index, thisArray) => {
                    // When shrinking down to small screen we only want to display
                    // Strongly Disagree, Neutral, and Strongly Agree
                    if (index % 2 !== 0) {
                        return null;
                    }

                    let labelClass = "d-flex justify-content-center";
                    if (index === 0) {
                        labelClass += " mr-4";
                    } else if (index === thisArray.length - 1) {
                        labelClass += " ml-4";
                    }

                    optionId = parseInt(optionId);
                    return (
                        labelsEnabled && (
                            <div className={"col pss-label-col"}>
                                {
                                    <label className={labelClass}>
                                        {optionLabelMap[optionId]}
                                    </label>
                                }
                            </div>
                        )
                    );
                })}
            </div>
        </>
    );
};

export default GenericSlider;
