// Copyright aptihealth, inc. 2021 All Rights Reserved
import React from "react";
import { eventTypeDisplayNameFactory } from "../../../ScheduleAvailability/ActiveScheduleDetails";
import moment from "moment";

const CareCommunicationView = (props) => {
    const { note } = props;

    const renderSecondaryDiagnosis = () => {
        if (!note.ICD_10_axis_2_diagnosis) {
            return <div>None</div>;
        }

        if (note.ICD_10_axis_2_diagnosis.length > 0) {
            return note.ICD_10_axis_2_diagnosis.map((diagnosis) => <div>{diagnosis.value}</div>);
        }

        return <div>None</div>;
    };

    return (
        <div className={"w-100"}>
            <div className={"row print-wrap"}>
                <div className={"col-12 fw-bold"}>Care Communication Note</div>

                <div className="border-bottom col-12 my-2" />

                <div className={"col-lg-3 mb-2"}>
                    <div className={"fw-bold"}>Communication Type</div>
                    <div>{note.content.communication_type}</div>
                </div>

                <div className={"col-lg-3 mb-2"}>
                    <div className={"fw-bold"}>Date & Time</div>
                    <div>
                        {moment(
                            note.session_date + " " + note.session_from,
                            "MM/DD/YYYY hh:mm",
                        ).format("MM/DD/YYYY hh:mm a")}
                    </div>
                </div>

                <div className={"col-lg-3 mb-2"}>
                    <div className={"fw-bold"}>CPT Description</div>
                    <div>{eventTypeDisplayNameFactory(note.call_type)}</div>
                </div>

                <div className={"col-lg-3 mb-2"}>
                    <div className={"fw-bold"}>Reason For Communication</div>
                    {note.content.reason_for_communication.map((reason) => {
                        if (
                            reason === "Other (open text)" &&
                            note.content.reason_for_communication_other
                        ) {
                            reason = "Other: " + note.content.reason_for_communication_other;
                        }
                        return <div>{reason}</div>;
                    })}
                </div>

                {note.content.encounters.map((encounter) => {
                    return (
                        <>
                            <div className="border-bottom col-12 my-2" />

                            <div className={"col-lg-3 mb-2"}>
                                <div className={"fw-bold"}>Encounter Date</div>
                                <div>{encounter.encounter_date}</div>
                            </div>

                            {encounter.discharge_date && (
                                <div className={"col-lg-3 mb-2"}>
                                    <div className={"fw-bold"}>Discharge Date</div>
                                    <div>{encounter.discharge_date}</div>
                                </div>
                            )}

                            <div className={"col-lg-3 mb-2"}>
                                <div className={"fw-bold"}>Encounter Type</div>
                                {encounter.encounter_type.map((type) => (
                                    <div>{type}</div>
                                ))}
                            </div>

                            {encounter.discharge_type.length > 0 && (
                                <div className={"col-lg-3 mb-2"}>
                                    <div className={"fw-bold"}>Discharge Type</div>
                                    {encounter.discharge_type.map((type) => (
                                        <div>{type}</div>
                                    ))}
                                </div>
                            )}

                            <div className={"col-lg-3 mb-2"}>
                                <div className={"fw-bold"}>Setting</div>
                                {encounter.setting.map((setting) => {
                                    if (
                                        setting === "Other (open text)" &&
                                        encounter.setting_other
                                    ) {
                                        setting = "Other: " + encounter.setting_other;
                                    }
                                    return <div>{setting}</div>;
                                })}
                            </div>

                            <div className={"col-lg-12 mb-2"}>
                                <div className={"fw-bold"}>Encounter Information</div>
                                <div>{encounter.encounter_information}</div>
                            </div>
                        </>
                    );
                })}

                <div className="border-bottom col-12 my-2" />

                <div className={"col-lg-12 mb-2"}>
                    <div className={"fw-bold"}>Communication & Plan</div>
                    <div>{note.content.communication_and_plan}</div>
                </div>

                <div className={"col-lg-6 mb-2"}>
                    <div className={"fw-bold"}>Primary Diagnosis</div>
                    {note.ICD_10_axis_1_diagnosis &&
                        note.ICD_10_axis_1_diagnosis.length > 0 &&
                        note.ICD_10_axis_1_diagnosis.map((diagnosis) => (
                            <div>{diagnosis.value}</div>
                        ))}
                    {note.ICD_10_axis_1_diagnosis && note.ICD_10_axis_1_diagnosis.length === 0 && (
                        <div>None</div>
                    )}
                    {!note.ICD_10_axis_1_diagnosis && <div>None</div>}
                </div>

                <div className={"col-lg-6 mb-2"}>
                    <div className={"fw-bold"}>Secondary Diagnosis</div>
                    {renderSecondaryDiagnosis()}
                </div>
            </div>
        </div>
    );
};

export default CareCommunicationView;
