import React, { useEffect, useState } from "react";
import Button from "../../../../UI/Button";
import { AppointmentTimePicker } from "../../../../Common/Schedule/AppointmentTimePicker";
import { isNil } from "lodash-es";
import moment from "moment-timezone";
import { api } from "../../../../../APIRequests";
import _ from "lodash";

const prettyTimeArea = (
    consultationToConfirm,
    buttonText,
    buttonAction,
    patientInfoText,
    infoTextColor,
    isReschedule,
    setCurrentState,
) => {
    const clonedConsultationToConfirm = _.cloneDeep(consultationToConfirm);
    const cancelClasses = isReschedule ? "justify-content-between" : "justify-content-center";

    if (!consultationToConfirm) {
        return;
    }

    if (!clonedConsultationToConfirm.end_time.includes("M")) {
        clonedConsultationToConfirm.end_time = moment(
            clonedConsultationToConfirm.end_time,
            "HH:mm",
        ).format("h:mm a");
    }

    if (!clonedConsultationToConfirm.start_time.includes("M")) {
        clonedConsultationToConfirm.start_time = moment(
            clonedConsultationToConfirm.start_time,
            "HH:mm",
        ).format("h:mm a");
    }

    return (
        <div className={"container"}>
            {renderPatientName(patientInfoText, infoTextColor)}
            <div className="row pl-4">
                <div className="col-6">
                    <div className="row">
                        <div className={"txt-gry fs-14"}>Date</div>
                    </div>
                    <div className="row">
                        <div
                            className={
                                "fw-bold fs-md-16 fs-14"
                            }>{`${clonedConsultationToConfirm.day_of_week}, ${clonedConsultationToConfirm.month_of_year} ${clonedConsultationToConfirm.day}, ${clonedConsultationToConfirm.year}`}</div>
                    </div>
                    <br />
                </div>
                <div className="col-2"></div>
                <div className="col-4">
                    <div className="row">
                        <div className={"txt-gry fs-14"}>Time</div>
                    </div>
                    <div className="row">
                        <div
                            className={
                                "fw-bold fs-md-16 fs-14"
                            }>{`${clonedConsultationToConfirm.start_time} - ${clonedConsultationToConfirm.end_time}`}</div>
                    </div>
                    <br />
                </div>
            </div>

            <div className="row pl-4">
                <div className="col-6">
                    <div className="row">
                        <div className={"txt-gry fs-14"}>Intake Coordinator</div>
                    </div>
                    <div className="row">
                        <div className={"fw-bold fs-md-16 fs-14"}>
                            {clonedConsultationToConfirm.provider_name}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row my-4">
                <div className="col-12">
                    <div className={`d-flex ${cancelClasses}`}>
                        {renderOptionalBackButton(
                            isReschedule,
                            setCurrentState,
                            "schedulingRequired",
                        )}
                        <Button
                            type={"button"}
                            style={{ backgroundColor: "#0CD0A7" }}
                            className={"Btn Btn--sec Btn--sm-2 workflow-btn"}
                            onClick={buttonAction}>
                            {buttonText}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const renderPatientName = (text, styleColor = null) => {
    const style = styleColor ? { color: styleColor } : {};
    return (
        <div style={style} className="row pl-2 mb-4">
            <div className="col-12">
                <span className="fw-bold">Patient Name:</span>
                <span>{text}</span>
            </div>
        </div>
    );
};

const renderOptionalBackButton = (isReschedule, setCurrentState, newState) => {
    return (
        isReschedule && (
            <Button
                type={"button"}
                className={"Btn Btn--gry Btn--sm-2 workflow-btn"}
                onClick={() => setCurrentState(newState)}>
                Back
            </Button>
        )
    );
};

const intakeMissedComponents = (patientDetails, selectedIntakeCall, setCurrentState) => {
    const patientInfoText = ` ${patientDetails.first_name} was a no-show for the following consultation:`;
    return (
        <>
            {prettyTimeArea(
                selectedIntakeCall,
                "Reschedule",
                () => {
                    setCurrentState("schedulingRequired");
                },
                patientInfoText,
                "red",
            )}
        </>
    );
};

const callDetailsToDateData = (callDetails) => {
    let timezone = moment.tz.guess();
    let combinedDateTimeStr = `${callDetails.year}-${callDetails.month}-${callDetails.day} ${callDetails.start_time}`;
    let utcDatetime = moment.tz(combinedDateTimeStr, "YYYY-MM-DD HH:mm", timezone).utc();

    return {
        timezone,
        date: utcDatetime.format("YYYY-MM-DD"),
        time: utcDatetime.format("HH:mm"),
    };
};

const rescheduleCall = async (currentCallId, callDetails) => {
    let dateTimeData = callDetailsToDateData(callDetails);

    const queryParams = { timezone: dateTimeData["timezone"] };

    if (!currentCallId) {
        const data = {
            date: dateTimeData["date"],
            time: dateTimeData["time"],
            timestamp: dateTimeData["date"] + " " + dateTimeData["time"],
            providerId: callDetails["provider_username"],
            allotted_time: callDetails["allotted_time"],
        };
        const urlParams = { patientId: callDetails["patient_username"], ...queryParams };
        const videoResponse = await api.provider.set_video_consultation_schedule({
            data,
            urlParams,
        });

        return videoResponse;
    }
    const data = {
        call_id: currentCallId,
        timezone: dateTimeData["timezone"],
        local_date_time: `${callDetails.year}-${callDetails.month}-${callDetails.day} ${callDetails.start_time}`,
        provider_id: callDetails["provider_username"],
        allotted_time: callDetails["allotted_time"],
        patient_id: callDetails["patient_username"],
    };
    const videoResponse = await api.schedule.put_schedule({ data });

    videoResponse["call_id"] = videoResponse["callId"];

    return videoResponse["call_details"];
};

const maybeDisabledButton = (
    selectedIntakeCall,
    setCurrentState,
    setSelectedDatePickerSlot,
    isReschedule,
) => {
    const cancelClasses = isReschedule ? "justify-content-between" : "justify-content-center";
    return (
        <div className={`d-flex ${cancelClasses}`}>
            {renderOptionalBackButton(isReschedule, setCurrentState, "reschedule")}
            <Button
                disabled={isNil(selectedIntakeCall)}
                type={"button"}
                className={"Btn Btn--pri Btn--sm-2 workflow-btn"}
                onClick={() => {
                    setCurrentState("confirmSchedule");
                    setSelectedDatePickerSlot(selectedIntakeCall);
                }}>
                Continue
            </Button>
        </div>
    );
};

export const schedulingRequiredComponents = (
    schedulingData,
    setSchedulingData,
    patientDetails,
    selectedDatePickerSlot,
    setSelectedDatePickerSlot,
    setCurrentState,
    isReschedule,
) => {
    const today = new Date();
    const todaysDate = {
        day_of_week: today.toLocaleDateString("default", { weekday: "long" }),
        month_of_year: today.toLocaleString("default", { month: "long" }),
        day: today.getDate(),
        year: today.getFullYear(),
    };

    const componentData = {
        schedulingData,
        setSchedulingData,
        todaysDate,
        patientDetails,
        selectedAppointment: selectedDatePickerSlot,
        setSelectedAppointment: setSelectedDatePickerSlot,
        className: "workflow-container-md workflow-container-border mb-5 ",
    };

    return (
        <>
            <AppointmentTimePicker {...componentData} />
            <div className="row">
                <div className="col-12">
                    {maybeDisabledButton(
                        selectedDatePickerSlot,
                        setCurrentState,
                        setSelectedDatePickerSlot,
                        isReschedule,
                    )}
                </div>
            </div>
            <br />
        </>
    );
};

const intakeCompleteComponent = (
    patientDetails,
    selectedIntakeCall,
    closeModal,
    isRescheduling,
) => {
    let text;
    if (isRescheduling) {
        text = "";
    } else {
        text = `${patientDetails.first_name} has already attended an aptihealth consultation. Please speak with the patient to discuss the consultation outcome.`;
    }

    return (
        <>
            <div>{text}</div>
            {prettyTimeArea(selectedIntakeCall, "Close", () => {
                closeModal();
            })}
            <div className="row text-center"></div>
        </>
    );
};

const confirmScheduleComponent = (
    patientDetails,
    selectedIntakeCall,
    selectedDatePickerSlot,
    setCurrentState,
    initializeExistingPatientIntakeModal,
) => {
    const patientInfoText = ` ${patientDetails.first_name} ${patientDetails.last_name}`;

    return (
        <>
            {prettyTimeArea(
                selectedDatePickerSlot,
                "Confirm & Schedule",
                async () => {
                    const callId = selectedIntakeCall ? selectedIntakeCall.call_id : null;
                    await rescheduleCall(callId, selectedDatePickerSlot);
                    initializeExistingPatientIntakeModal();
                },
                patientInfoText,
                null,
                true,
                setCurrentState,
            )}
            <div className="row text-center"></div>
        </>
    );
};

const rescheduleComponent = (patientDetails, selectedIntakeCall, setCurrentState) => {
    const patientInfoText = ` ${patientDetails.first_name} ${patientDetails.last_name}`;
    return (
        <>
            {prettyTimeArea(
                selectedIntakeCall,
                "Reschedule",
                () => {
                    setCurrentState("schedulingRequired");
                },
                patientInfoText,
            )}
            <div className="row text-center"></div>
        </>
    );
};

export const ExistingPatientIntakeBody = ({
    patientDetails,
    currentState,
    setCurrentState,
    intakeAppointments,
    setIntakeAppointments,
    closeModal,
    selectedIntakeCall,
    initializeExistingPatientIntakeModal,
}) => {
    const [selectedDatePickerSlot, setSelectedDatePickerSlot] = useState(null);
    const [isReschedule, setIsReschedule] = useState(false);

    useEffect(() => {
        setIsReschedule(selectedIntakeCall);
    }, selectedIntakeCall);

    const canGoBack = isReschedule;
    const lookup = {
        schedulingRequired: () =>
            schedulingRequiredComponents(
                intakeAppointments,
                setIntakeAppointments,
                patientDetails,
                selectedDatePickerSlot,
                setSelectedDatePickerSlot,
                setCurrentState,
                canGoBack,
            ),
        reschedule: () => rescheduleComponent(patientDetails, selectedIntakeCall, setCurrentState),
        intakeComplete: () =>
            intakeCompleteComponent(patientDetails, selectedIntakeCall, closeModal, isReschedule),
        intakeMissed: () =>
            intakeMissedComponents(patientDetails, selectedIntakeCall, setCurrentState),
        confirmSchedule: () =>
            confirmScheduleComponent(
                patientDetails,
                selectedIntakeCall,
                selectedDatePickerSlot,
                setCurrentState,
                initializeExistingPatientIntakeModal,
            ),
        loading: () => <div>Data is loading...</div>,
    };

    return lookup[currentState]();
};
