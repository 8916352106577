// Copyright aptihealth, inc. 2019 All Rights Reserved
import "./styles.scss";

import React, { Component, Fragment } from "react";
import { api } from "../../../APIRequests";
import { ListHeader, ListItem } from "../../UI/ListView";
import images from "../../../utils/images";
import { Link } from "react-router-dom";
import CardSecondary from "../../UI/Card/CardSecondary";
import jwt_decode from "jwt-decode";
import DatePicker from "../../UI/DatePicker";
import FormikInput from "../../UI/FormikInput";
import { CardPrimary } from "../../UI/Card";
import { Formik } from "formik";
import { isAuthorized } from "../../../redux/actions/auth";
import { getQueryParams } from "../../../utils/filters";
import { patientIdChange } from "./index";
import moment from "moment-timezone";
import { allottedTimeFactory } from "../ScheduleAvailability/constants";
import { eventTypeLogicalNameFactory } from "../ScheduleAvailability/ActiveScheduleDetails";
import Select from "react-select";
import Label from "../../UI/Label";
import InputError from "../../UI/InputError";
import { getIn } from "formik";
import { connect } from "react-redux";
import Button from "../../UI/Button";
import { CustomForm } from "../../../component-library/CustomForm";

class ProgressNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: null,
            patientId: this.props.match.params.patientId,
            numSessions: 0,
            isAuthorizedToCreateNotes:
                isAuthorized("provider:behavioral") || isAuthorized("provider:prescribe"),
        };
    }

    async componentDidMount() {
        await getProgressNotes(this, 2);
    }

    componentDidUpdate = patientIdChange.bind(this);

    render() {
        const { patientDetails } = this.props;
        let listItems = (
            <Fragment>
                {this.state.notes &&
                    this.state.notes.map((note) => {
                        let noteId = note.note_id ? note.note_id : note.id;
                        return (
                            <ListItem
                                className="ProgressNoteItem"
                                key={note.title}
                                clickOnMobile
                                clickHandler={() => viewNote(this, noteId, note)}>
                                {getNoteListChildren(
                                    this,
                                    note,
                                    patientDetails,
                                    false,
                                    this.props.profile,
                                )}
                            </ListItem>
                        );
                    })}
            </Fragment>
        );
        return (
            this.state.notes && (
                <CardSecondary className="DashboardViewCard PatientProfile__CardBetweenMargin py-2 px-3 px-lg-4">
                    <br />
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="fs-18 mx-3 my-0">
                            <span>
                                <strong>Progress Note</strong>
                            </span>
                        </div>
                        <Link
                            to={`/app/patient/${this.state.patientId}/note`}
                            className="PatientProfile-viewAll fs-14 fw-bold">
                            View All
                        </Link>
                    </div>
                    <br />
                    <hr className="m-0" />
                    <ListItem className="ProgressNoteItem no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between">
                        <span className="col-3">
                            <strong>Date</strong>
                        </span>
                        <span className="col-4">
                            <strong>Therapy</strong>
                        </span>
                        <span className="col-4">
                            <strong>Note Type</strong>
                        </span>
                        <span className="col-1"> </span>
                    </ListItem>
                    {this.state.notes && listItems}
                    {/* {!this.state.notes ?
                    (<div className="d-flex align-items-center justify-content-between">
                        <div>No Progress Notes</div>
                    </div>): listItems } */}
                    <hr className="m-0" />
                    <br />
                    <div className={"d-block text-right"}>
                        <Link
                            to={`/app/patient/${this.state.patientId}/note/add?numSessions=${this.state.numSessions}`}>
                            <Button
                                disabled={!this.state.isAuthorizedToCreateNotes}
                                className="Btn Btn--pri Btn-sm ml-4 text-center text-white">
                                Add Note
                            </Button>
                        </Link>
                    </div>
                    <br />
                </CardSecondary>
            )
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};
export default connect(mapStateToProps, {})(ProgressNotes);

export const getProgressNotes = async (that, limit, drafts) => {
    let urlParams = { patientId: that.state.patientId };
    let numSessions = 0;
    let response = await api.shared.fetch_patient_progress_notes({ urlParams });
    if (response) {
        let notes = response
            .filter((note) => note.note_type == null || note.note_type === "Video Consultation")
            .sort(
                (a, b) =>
                    moment(b.session_date + " " + b.session_from, "MM/DD/YYYY HH:mm") -
                    moment(a.session_date + " " + a.session_from, "MM/DD/YYYY HH:mm"),
            );

        if (drafts) {
            notes = notes.filter((note) => {
                return note.note_state === "draft";
            });
        } else {
            notes = notes.filter((note) => {
                return note.note_state !== "draft";
            });
        }
        numSessions = notes.length;
        if (limit && notes.length >= limit) {
            notes = notes.slice(0, limit);
        }
        that.setState({
            notes: notes,
            numSessions: numSessions,
        });
    }
};

export const getICD10Dataset = (that) => {
    api.provider
        .fetch_ICD10_dataset()
        .then((response) => {
            that.setState({
                ICD10: response,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const showDatePicker = (that) => {
    that.setState({
        showDatePicker: !that.state.showDatePicker,
    });
};

function getNoteColSizes(isFullPage, draft) {
    if (draft) {
        return { date: "col-2", title: "col-4", type: "col-3" };
    }
    return isFullPage
        ? { date: "col-2", title: "col-4", type: "col-2", signature: "col-2" }
        : { date: "col-3", title: "col-4", type: "col-3" };
}

export const getNoteListChildren = (that, note, patientDetail, draft, isFullPage, principle) => {
    let colSizes = getNoteColSizes(isFullPage, draft);
    let date = note.session_date;
    let type = "";
    if (note.provider_type && note.provider_type.startsWith("BEHAVIORAL")) {
        type = "Behavioral Note";
    } else if (note.provider_type && note.provider_type === "PRESCRIBE") {
        type = "Prescriber Note";
    }
    let noteId = note.note_id ? note.note_id : note.id;
    return (
        <Fragment>
            {/* for mobile devices */}
            <div className="d-flex d-lg-none justify-content-between">
                <div className="w-75 d-flex fs-14 flex-column">
                    <span>{date}</span>
                    <span>{note.title}</span>
                    <span>{type}</span>
                    {isFullPage && <span>{note.signature}</span>}
                </div>
                {draft && (
                    <div className="d-flex justify-content-center">
                        <div className="cursor">
                            <img
                                onClick={() =>
                                    that.setState({ showDeleteNote: true, draftNoteId: noteId })
                                }
                                src={images("./icons/delete-trash.svg")}
                                alt="view icon"
                            />
                        </div>
                    </div>
                )}
            </div>
            {/* for desktop */}
            <div className="no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between">
                <span className={colSizes.date}>{date}</span>
                <span className={colSizes.title}>{note.title}</span>
                <span className={colSizes.type}>{type}</span>
                {isFullPage && !draft && (
                    <span className={colSizes.signature}>{note.signature}</span>
                )}
                <div className="col-1 d-flex justify-content-center">
                    <div className="cursor">
                        <img
                            onClick={() =>
                                that.props.history.push(
                                    `/app/patient/${that.state.patientId}/export_notes?noteIds=${noteId}`,
                                )
                            }
                            src={images("./icons/print.svg")}
                            alt="print icon"
                            style={{ height: "55px" }}
                        />
                    </div>
                </div>
                {draft && note.created_by === principle.username ? (
                    <>
                        <div className="col-1 d-flex justify-content-center">
                            <div className="cursor">
                                <img
                                    onClick={() => viewDraftNote(that, noteId, note)}
                                    src={images("./icons/edit-pencil.svg")}
                                    alt="view icon"
                                />
                            </div>
                        </div>
                        <div className="col-1 d-flex justify-content-center">
                            <div className="cursor">
                                <img
                                    onClick={() =>
                                        that.setState({ showDeleteNote: true, draftNoteId: noteId })
                                    }
                                    src={images("./icons/delete-trash.svg")}
                                    alt="view icon"
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {draft && <div className="col-1" />}
                        <div className="col-1 d-flex justify-content-center">
                            <div className="cursor">
                                <img
                                    onClick={() => viewNote(that, noteId, note)}
                                    src={images("./common/view.png")}
                                    alt="view icon"
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Fragment>
    );
};

const formatDate = (date) => {
    let monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();

    return monthNames[monthIndex] + " " + day + ", " + year;
};

export const setSessionDate = (that) => {
    let dateStr = "";
    if (that.state.note) {
        dateStr = that.state.note.session_date;
    } else {
        dateStr = new Date().toISOString().split("T")[0];
        that.getPickedDate(dateStr);
    }
    return formatDate(new Date(dateStr));
};

export function extractSessionDateData(linkedCallValue, that, setDate = true) {
    let data = {};
    if (
        linkedCallValue &&
        !["No Linked Call", "Non Billable Encounter"].includes(linkedCallValue)
    ) {
        const linkedCallParts = linkedCallValue.split(" - ");
        const eventType = eventTypeLogicalNameFactory(linkedCallParts[0]);
        const dateTimeStr = linkedCallParts[1].toLowerCase();
        const dateTimeParts = dateTimeStr.split(" ");
        if (setDate) {
            that.getPickedDate(dateTimeParts[0]);
        }
        const time = dateTimeParts[1] + " " + dateTimeParts[2];
        const sessionFrom = moment(time, "hh:mm a").format("HH:mm");
        const sessionTo = moment(time, "hh:mm a").add(allottedTimeFactory(eventType), "minutes");
        const sessionToValue = sessionTo.format("HH:mm");
        let timeSlotOptions = [...that.state.timeSlotOptions];
        timeSlotOptions.push(
            { value: sessionFrom, display: time },
            { value: sessionToValue, display: sessionTo.format("hh:mm a") },
        );
        timeSlotOptions = timeSlotOptions.sort(
            (slotA, slotB) =>
                moment(slotA.value, "HH:mm").valueOf() - moment(slotB.value, "HH:mm").valueOf(),
        );
        data = {
            sessionFrom: sessionFrom,
            sessionTo: sessionToValue,
            timeSlotOptions: timeSlotOptions,
        };
    }
    return data;
}

const linkedCallChangeHandler = (e, that) => {
    const linkedCallValue = e.target.innerHTML;
    that.setState(extractSessionDateData(linkedCallValue, that));
};

export const getNoteFields = (formikProps, schema, that, draft = false) => {
    if (that.state.sessionFrom) {
        formikProps.values["session_from"] = that.state.sessionFrom;
    }
    if (that.state.sessionTo) {
        formikProps.values["session_to"] = that.state.sessionTo;
    }
    schema.map((el) => {
        if (
            (el.name === "session_from" || el.name === "session_to") &&
            that.state.timeSlotOptions
        ) {
            el.elementConfig.options = that.state.timeSlotOptions;
        }
        return el;
    });
    return (
        <Fragment>
            <div className="mx-auto px-0 ">
                <div className="container">
                    <div className="row">
                        {that.state.showDatePicker && (
                            <div className="PrScheduleAvailability__DatePicker-wpr-absolute NoteDatePicker">
                                <div className={"NoteDatePickerWrapper"}>
                                    <DatePicker
                                        externalCtx={moment(
                                            that.state.note.session_date,
                                            "MM/DD/YYYY",
                                        )}
                                        onDaySelect={that.getPickedDate}
                                    />
                                </div>
                            </div>
                        )}
                        {schema.map((formEl) => {
                            if (formEl.elementType === "input" && formEl.name === "title") {
                                return (
                                    <div key={formEl.name} className="col-12">
                                        <div className="d-flex justify-content-start">
                                            <div style={{ marginTop: 35, paddingRight: 15 }}>
                                                <label>
                                                    <b>{draft ? "Draft" : "New"} Note:</b>
                                                </label>
                                            </div>
                                            <div className="col col-lg-5">
                                                <FormikInput
                                                    formEl={formEl}
                                                    errors={formikProps.errors}
                                                    touched={formikProps.touched}
                                                />
                                            </div>
                                            {draft && (
                                                <img
                                                    onClick={() =>
                                                        that.setState({
                                                            showDeleteNote: true,
                                                            draftNoteId: that.state.note.id,
                                                        })
                                                    }
                                                    src={images("./icons/delete-trash.svg")}
                                                    className="d-inline d-sm-none"
                                                    alt=""
                                                />
                                            )}
                                        </div>
                                        <hr className="d-none d-lg-block" />
                                    </div>
                                );
                            } else if (formEl.elementType === "DatePicker") {
                                return (
                                    <div key={formEl.name} className="col-12 col-lg-6">
                                        <div>
                                            <label className={"Label"}>Session Date</label>
                                        </div>
                                        <div className={"CustomSelect__wpr d-flex flex-column"}>
                                            {that.state.edit && (
                                                <button
                                                    type="button"
                                                    disabled={that.state.edit ? "" : "disabled"}
                                                    className={
                                                        "CustomSelect__btn cursor d-flex align-items-center text-left"
                                                    }
                                                    onClick={() => showDatePicker(that)}>
                                                    <span>{setSessionDate(that)}</span>
                                                </button>
                                            )}
                                            {!that.state.edit && (
                                                <button
                                                    type="button"
                                                    className={
                                                        "CustomSelect__btn cursor d-flex align-items-center text-left"
                                                    }
                                                    onClick={() => showDatePicker(that)}>
                                                    <span>{setSessionDate(that)}</span>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                );
                            } else if (
                                formEl.elementType === "select" &&
                                formEl.name !== "call_id"
                            ) {
                                return (
                                    <div key={formEl.name} className={"col-6 col-lg-3"}>
                                        <FormikInput
                                            formEl={formEl}
                                            errors={formikProps.errors}
                                            touched={formikProps.touched}
                                        />
                                    </div>
                                );
                            } else if (formEl.elementType === "reactselect") {
                                const error = getIn(formikProps.errors, formEl.name);
                                const touched = getIn(formikProps.touched, formEl.name);
                                let options = [];

                                if (formEl.name === "ICD_axis_1") {
                                    options = that.state.ICD10.filter(
                                        (category) => category.axis === "1",
                                    );
                                }

                                if (formEl.name === "ICD_axis_2") {
                                    options = that.state.ICD10.filter(
                                        (category) => category.axis === "2",
                                    );
                                }

                                return (
                                    <div key={formEl.name} className={"col-lg-6 col-12"}>
                                        <Label>{formEl.elementConfig.label}</Label>
                                        <Select
                                            components={{
                                                ClearIndicator: null,
                                            }}
                                            isMulti
                                            options={options}
                                            defaultValue={formikProps.values[formEl.name]}
                                            className={"basic-multi-select"}
                                            classNamePrefix="select"
                                            onChange={(selectedOptions) =>
                                                formikProps.setFieldValue(
                                                    formEl.name,
                                                    selectedOptions,
                                                )
                                            }
                                        />
                                        {error && touched ? (
                                            <InputError classes={"custom-error"}>
                                                {error}
                                            </InputError>
                                        ) : null}
                                    </div>
                                );
                            } else if (
                                formEl.elementType === "select" &&
                                formEl.name === "call_id"
                            ) {
                                formEl.onchange = (e) => linkedCallChangeHandler(e, that);
                                return (
                                    <div key={formEl.name} className={"col-12"}>
                                        <FormikInput
                                            formEl={formEl}
                                            errors={formikProps.errors}
                                            touched={formikProps.touched}
                                        />
                                    </div>
                                );
                            } else if (formEl.name === "isSigned") {
                                formEl.onclick = (e) => that.signatureClickHandler(e, formikProps);
                                formEl.elementConfig.checked = that.state.isSigned;
                                return (
                                    <div className="col-12 mb-3">
                                        <div className={"d-flex"}>
                                            <FormikInput
                                                value={formikProps && formikProps.values.isSigned}
                                                formEl={formEl}
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                                checked={that.state.isSigned}
                                            />
                                        </div>
                                        {that.state.isSigned && (
                                            <input
                                                disabled={"disabled"}
                                                className={"Input my-2 col-lg-6 col-12"}
                                                value={
                                                    jwt_decode(window.localStorage.getItem("token"))
                                                        .name
                                                }
                                            />
                                        )}
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={formEl.name} className="col-12 col-lg-6">
                                        <FormikInput
                                            formEl={formEl}
                                            errors={formikProps.errors}
                                            touched={formikProps.touched}
                                        />
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export const deleteNote = (that, patientId, noteId) => {
    let data = { id: noteId };
    let urlParams = { patientId: patientId };
    api.provider
        .delete_progress_note({ data, urlParams })
        .then((response) => {
            that.setState({ showDeleteNote: false });
            that.props.history.push(`/app/patient/${patientId}/note`);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const viewNote = (that, id, note) => {
    let version = "";

    if (note.version && note.version === 2) {
        version = "v2";
    }
    that.props.history.push(`/app/patient/${that.state.patientId}/note/${id}/${version}`);
};

export const viewDraftNote = (that, id, note) => {
    let version = "";

    if (note.version && note.version === 2) {
        version = "v2/";
    }
    that.props.history.push(`/app/patient/${that.state.patientId}/note/${id}/${version}draft`);
};

const getPracticeName = async (practiceId) => {
    let practiceName = "";
    try {
        if (practiceId) {
            const urlParams = { practiceId: practiceId };
            const response = await api.shared.fetch_practice({ urlParams });
            practiceName = response.name;
        }
    } catch (e) {
        console.log(e);
    }
    return practiceName;
};

export const createCommonNoteData = async (that, formData, title, profile) => {
    const linkedCall =
        that.state.completedCalls &&
        that.state.completedCalls.find((call) => call["value"] === formData.call_id);

    return {
        title: formData.title,
        session_date: that.state.note.session_date,
        session_from: formData.session_from,
        session_to: formData.session_to,
        signature: that.state.isSigned ? jwt_decode(window.localStorage.getItem("token")).name : "",
        completed_date_time: moment.utc().toISOString(),
        provider_cp_credentials: profile ? profile.cp_credentials : "",
        provider_npi_number: profile ? profile.npi_number : "",
        provider_practice:
            profile && profile.practice_id ? await getPracticeName(profile.practice_id) : "",
        intervention_reaction: formData.intervention_reaction,
        call_id: formData.call_id,
        call_name: linkedCall ? linkedCall["display"] : "",
        ICD_10_axis_1_diagnosis: formData["ICD_axis_1"],
        ICD_10_axis_2_diagnosis: formData["ICD_axis_2"],
    };
};

export const setBehavioralNoteData = (that, formData, title, data) => {
    data.patient_report = formData.patient_report;
    data.patient_task = formData.patient_task;
    data.interventions = formData.interventions;
};

export const setPrescriberNoteData = (that, formData, title, data) => {
    data.patient_reported = formData.patient_reported;
    data.prescriber_interventions = formData.prescriber_interventions;
};

export const getNoteCard = (vals, validation, that) => {
    return (
        <div>
            <div className="survey-back-link-wpr mx-auto fs-16 my-2 p-3 pl-lg-0 pr-lg-0">
                <span className="survey-back-link" onClick={() => that.props.history.go(-1)}>
                    <img className="img-fluid" src={images("./common/solidBckBtn.svg")} />
                    <p className="d-inline ml-2">Back to Patient Profile</p>
                </span>
            </div>
            <CardPrimary className="mx-auto my-0 my-lg-5">
                {/* <div className="d-flex"> */}
                <div className="d-flex justify-content-around justify-content-lg-between align-items-center">
                    {that.renderTitle()}
                    {that.state.note && (
                        <ListHeader render={that.renderControls} style={{ padding: 0 }} />
                    )}
                </div>
                <hr className="mx-3 mx-lg-auto" />
                <div>
                    <CustomForm
                        initialValues={vals}
                        validationSchema={validation}
                        onSubmit={that.submitHandler}
                        render={that.renderForm}
                    />
                </div>
                {/* </div> */}
            </CardPrimary>
        </div>
    );
};

export const pickNoteDate = (that, dateStr) => {
    let newNote = { ...that.state.note };
    let parts = dateStr.split("-");
    newNote.session_date = parts[1] + "/" + parts[2] + "/" + parts[0];
    that.setState({
        showDatePicker: false,
        note: newNote,
    });
};
