// Copyright aptihealth, inc. 2021 All Rights Reserved
import React from "react";
import Button from "../../../UI/Button";
import { formatMobileNumber } from "../../../../utils/formInputUtils";
import moment from "moment";
import images from "../../../../utils/images";
import { trackEvent } from "../../../../utils/EventTrackingUtil";

const ConfirmPrimaryAccountInfo = (props) => {
    const { prevStep, nextStep, accountData } = props;

    return (
        <>
            <div className={"p-5"}>
                <div className={"fs-22 txt-pri fw-bold mb-5 text-center"}>Is this correct?</div>
                <div className={"text-center my-5"}>
                    <img
                        src={images("./signup/progress-1.png")}
                        alt={"Step 1/3"}
                        className={"signup-progress-bar"}
                    />
                </div>
                <div className={"brand-popup-inner-card"} style={{ maxWidth: 500 }}>
                    <div className={"d-lg-flex justify-content-lg-between d-block"}>
                        <div className={"my-2"} style={{ minWidth: 300 }}>
                            <div>Name</div>
                            <div className={"fw-bold"}>
                                {accountData &&
                                    `${accountData.first_name} ${accountData.last_name}`}
                            </div>
                        </div>

                        <div className={"my-2"} style={{ minWidth: 300 }}>
                            <div>Phone Number</div>
                            <div className={"fw-bold"}>
                                {accountData && formatMobileNumber(accountData.mobile)}
                            </div>
                        </div>
                    </div>

                    <div className={"d-lg-flex justify-content-lg-between d-block"}>
                        <div className={"my-2"} style={{ minWidth: 300 }}>
                            <div>Date of Birth</div>
                            <div className={"fw-bold"}>
                                {accountData &&
                                    accountData.dob &&
                                    moment(accountData.dob, "MMDDYYYY").format("MM/DD/YYYY")}
                            </div>
                        </div>

                        <div className={"my-2"} style={{ minWidth: 300 }}>
                            <div>Email Address</div>
                            <div className={"fw-bold"}>{accountData && accountData.email}</div>
                        </div>
                    </div>

                    <div className={"d-flex justify-content-between mt-5"}>
                        <div>
                            <Button
                                type={"button"}
                                className={"Btn Btn--otl-gry Btn--sm-2 self-signup-btn"}
                                onClick={prevStep}>
                                Edit
                            </Button>
                        </div>
                        <div>
                            <Button
                                type={"button"}
                                className={"Btn Btn--pri Btn--sm-2 self-signup-btn"}
                                onClick={() => {
                                    trackEvent("Self Sign-up Create Account");
                                    nextStep();
                                }}>
                                Yes
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfirmPrimaryAccountInfo;
