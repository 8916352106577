import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { createClassNameString } from "../../utils";
import { ICON_SIZES, ICON_TYPES } from "../../../constants/icons";

const getViewBox = (iconName, iconType) => {
    if (iconName === "Bhs" && iconType === ICON_TYPES.CIRCLE) {
        return "0 0 41 41";
    } else if (iconType === ICON_TYPES.CIRCLE) {
        return "0 0 40 40";
    }

    return "0 0 32 32";
};

export const IconWrapper = ({ className, iconName, iconSize, iconType, children, ...props }) => {
    const viewBox = getViewBox(iconName, iconType);

    return (
        <svg
            className={createClassNameString([iconSize, className])}
            viewBox={viewBox}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            {children}
        </svg>
    );
};

IconWrapper.propTypes = {
    className: PropTypes.string,
    iconName: PropTypes.string,
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

IconWrapper.defaultProps = {
    className: undefined,
    iconSize: ICON_SIZES.MEDIUM,
    iconType: ICON_TYPES.OUTLINE,
};
