// Copyright aptihealth, inc. 2019 All Rights Reserved
import { httpBackground } from "../utils/axios";

const trackingRequests = {
    email: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/tracking/email",
            data,
        };
        return httpBackground(conf);
    },
};

export default trackingRequests;
