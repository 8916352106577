import { useEffect, useState } from "react";
import { calculateScrollPositionWorkingHours } from "../calendar/positions";

export const useCalendarScroll = (calendarRect, daysToDisplay, workingHours) => {
    const [hasScrolledForFirstTime, setHasScrolledForFirstTime] = useState(false);
    const firstDayOfWeek = daysToDisplay?.at(0)?.format("YYYY-MM-DD") ?? "";

    // used to trigger the initial scroll on page load
    useEffect(() => {
        if (!hasScrolledForFirstTime && calendarRect && workingHours && daysToDisplay.length > 0) {
            const scrollTo = calculateScrollPositionWorkingHours(
                workingHours,
                daysToDisplay,
                calendarRect,
            );
            window.scrollTo({
                ...scrollTo,
                behavior: "instant",
            });
            setHasScrolledForFirstTime(true);
        }
    }, [calendarRect, workingHours, daysToDisplay]);

    // used to trigger the scroll when the day of the week has changed
    // we only change based on the first day because it will always update
    // when the date controls are used
    useEffect(() => {
        if (hasScrolledForFirstTime) {
            const scrollTo = calculateScrollPositionWorkingHours(
                workingHours,
                daysToDisplay,
                calendarRect,
            );
            window.scrollTo({
                ...scrollTo,
                behavior: "smooth",
            });
        }
    }, [firstDayOfWeek]);

    return {
        hasScrolledForFirstTime,
    };
};
