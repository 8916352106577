// Copyright aptihealth, inc. 2021 All Rights Reserved
import images from "../../../../utils/images";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import * as yup from "yup";
import { dobValidationSchema } from "../../../../containers/Dashboard/Providers/schema/newPatientValidation";
import FormikInputFormat from "../../../UI/formikMobileInput";
import FormikInput from "../../../UI/FormikInput";
import Button from "../../../UI/Button";
import moment from "moment";
import { api } from "../../../../APIRequests";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { showAlertWithAction } from "../../../../redux/actions/alerts";
import { CustomForm } from "../../../../component-library/CustomForm";

const AccountLookup = (props) => {
    const { sharedWorkflowData, setSharedWorkflowData } = props;

    const submitHandler = async (formData, actions) => {
        try {
            const options = { params: { showLoader: true } };
            const data = {
                dob: moment(formData.dob, "MMDDYYYY").format("MM/DD/YYYY"),
                email: formData.email,
                workflow_instance_entity_id: sharedWorkflowData.workflow_instance.entity_id,
                workflow_instance_sort_key: sharedWorkflowData.workflow_instance.sort_key,
            };
            const response = await api.shared.account_lookup({ options, data });
            setSharedWorkflowData({
                ...sharedWorkflowData,
                workflow_instance: response.workflow_instance,
            });
        } catch (e) {
            console.log(e);
            props.showAlertWithAction("Error looking up account.");
        }
    };

    const renderForm = (formikProps) => {
        const formikInputProps = {
            inputClasses: "Input--sm",
            errors: formikProps.errors,
            touched: formikProps.touched,
        };
        return (
            <Form>
                <div className="mx-auto px-0 mb-3">
                    <div className="px-0 row pb-5">
                        <div className={"col-12"}>
                            <FormikInputFormat
                                {...formikInputProps}
                                formEl={{
                                    name: "dob",
                                    elementType: "input",
                                    elementConfig: {
                                        type: "text",
                                        label: "Date of Birth",
                                        placeholder: "MM/DD/YYYY",
                                    },
                                }}
                                value={formikProps && formikProps.values["dob"]}
                                onChange={async (val) => {
                                    await formikProps.setFieldValue("dob", val.value);
                                    formikProps.setFieldTouched("dob");
                                }}
                            />
                        </div>

                        <div className={"col-12"}>
                            <FormikInput
                                {...formikInputProps}
                                formEl={{
                                    name: "email",
                                    elementType: "input",
                                    elementConfig: {
                                        type: "email",
                                        label: "Email",
                                        placeholder: "Enter Email",
                                    },
                                }}
                            />
                        </div>

                        <div className={"col-12"}>
                            <FormikInput
                                {...formikInputProps}
                                formEl={{
                                    name: "email_validation",
                                    elementType: "input",
                                    elementConfig: {
                                        type: "email",
                                        label: "Confirm Email",
                                        placeholder: "Re-enter Email",
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between">
                    <Button
                        type={"button"}
                        className={"Btn Btn--otl-gry Btn--sm-2 workflow-btn"}
                        onClick={props.backHandler}>
                        Back
                    </Button>
                    <Button type={"submit"} className={"Btn Btn--pri Btn--sm-2 workflow-btn"}>
                        Confirm
                    </Button>
                </div>
            </Form>
        );
    };

    return (
        <div className={"p-lg-5 p-3"}>
            <div className={"fs-22 txt-pri fw-bold mb-5 text-center"}>
                Let's check your eligibility
            </div>
            <div className={"text-center my-5"}>
                <img
                    src={images("./common/workflow/eligibility-check-active.png")}
                    alt={"Eligibility Check"}
                />
            </div>
            <div className={"workflow-container-sm"}>
                {sharedWorkflowData && sharedWorkflowData.workflow_instance && (
                    <>
                        <CustomForm
                            initialValues={{
                                dob: "",
                                email: "",
                                email_validation: "",
                            }}
                            validationSchema={accountLookUpValidation}
                            onSubmit={submitHandler}
                            render={renderForm}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default connect(null, { showAlertWithAction })(withRouter(AccountLookup));

const accountLookUpValidation = yup.object().shape({
    dob: dobValidationSchema.required("Date of birth is required"),
    email: yup.string().email("Enter a valid email address").required("Email is required"),
    email_validation: yup
        .string()
        .email("Enter a valid email address")
        .required("Email is required")
        .test(
            "email-match",
            "Email address does not match, please check spelling",
            function (value) {
                if (!value) {
                    return false;
                }
                return this.parent.email === value;
            },
        ),
});
