// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import {isConversationReadOnly} from "../../../../utils/chatUtils";
import PropTypes from "prop-types";
import images from "../../../../utils/images";
import {useSelector} from "react-redux";

export const ChatHeader = (props) => {
    const {
        channel,
        isMobile,
        showMenu,
        switchMobileView,
    } = props;
    const profile = useSelector(state => state.auth.profile);

    return <div className="Messages__Chat__Header">
        { isMobile && (
            <img
                src={images("./icons/back-arrow.svg")}
                onClick={switchMobileView}
                alt="back-arrow"
                className="back-arrow"
            />
        )}
        <div className="d-flex justify-content-between w-100">
            <div>
                <img
                    src={channel.coverImage}
                    alt="avatar"
                    className="avatar"
                />
                <span>
                    <b>
                        {channel.otherMembers.map((user) => user.nickname).join()}
                    </b>
                </span>
            </div>

            { !isConversationReadOnly(profile?.care_team_members, channel) &&
                <div
                    style={{ "marginRight": "10px", cursor: "pointer" }}
                    className={"align-self-center"}>
                    <img
                        role="button"
                        onClick={showMenu}
                        className="cursor"
                        src={images("./icons/three-dots-menu.svg")}
                        alt="three dots menu"
                    />
                </div>
            }
        </div>
    </div>;
};

ChatHeader.propTypes = {
    channel: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    showMenu: PropTypes.func.isRequired,
    switchMobileView: PropTypes.func.isRequired,
};
