/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const CaseManagerIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="CaseManager"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.4631 6.91058C11.7772 7.22516 12.9803 8.09429 13.827 9.56162C15.1636 11.8776 16.6031 14.3706 17.7185 16.3021C18.1713 17.0862 18.5707 17.7778 18.888 18.3275C19.3984 19.2115 19.5324 20.1263 19.3156 20.9507C19.1017 21.7644 18.5656 22.4106 17.8857 22.7905C17.2051 23.1709 16.3707 23.2901 15.5553 23.047C14.7313 22.8013 14.004 22.2102 13.4828 21.3075L8.38913 12.485C8.11298 12.0067 8.27686 11.3951 8.75515 11.119C9.23344 10.8428 9.84503 11.0067 10.1212 11.485L15.2149 20.3075C15.5179 20.8325 15.8599 21.0508 16.1268 21.1304C16.4024 21.2126 16.6803 21.173 16.91 21.0447C17.1405 20.9158 17.3127 20.7032 17.3813 20.4422C17.4471 20.1919 17.438 19.816 17.156 19.3275C16.8387 18.778 16.4394 18.0865 15.9867 17.3025C14.8713 15.3709 13.4315 12.8775 12.0948 10.5613C11.4999 9.53046 10.735 9.03218 9.99748 8.85562C9.24127 8.67459 8.43155 8.81255 7.74188 9.2035C7.05198 9.59459 6.52474 10.2146 6.29972 10.9441C6.08064 11.6544 6.12258 12.5494 6.71038 13.567C7.37145 14.7115 9.06495 17.5975 10.9447 20.8009C11.8817 22.3978 12.8651 24.0736 13.7898 25.6519C14.9489 27.6299 17.8135 27.6454 18.9901 25.6836L25.1584 15.3996C27.7994 10.6516 21.7312 6.13313 17.9018 10.3106C17.5286 10.7178 16.896 10.7453 16.4889 10.3721C16.0818 9.99888 16.0543 9.36632 16.4275 8.9592C21.8759 3.01543 30.7066 9.57893 26.8967 16.3888L26.8893 16.402L20.7053 26.7123C18.7447 29.9811 13.9888 29.9474 12.0643 26.663C11.1433 25.0913 10.1627 23.4201 9.227 21.8255C7.34386 18.6163 5.64307 15.7178 4.97852 14.5673C4.13605 13.1088 3.992 11.6404 4.38856 10.3546C4.77918 9.08818 5.66923 8.07944 6.75558 7.46361C7.84219 6.84765 9.16772 6.60047 10.4631 6.91058Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="CaseManager"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.074 4.82396C11.388 5.13854 12.5911 6.00767 13.4379 7.475C14.7744 9.79102 16.214 12.2839 17.3293 14.2155C17.7821 14.9996 18.1815 15.6912 18.4989 16.2409C19.0092 17.1249 19.1432 18.0396 18.9264 18.8641C18.7125 19.6778 18.1764 20.324 17.4966 20.7039C16.816 21.0843 15.9815 21.2035 15.1661 20.9604C14.3421 20.7146 13.6148 20.1236 13.0937 19.2209L7.99997 10.3984C7.72382 9.92009 7.8877 9.3085 8.36599 9.03236C8.84428 8.75622 9.45587 8.92009 9.73202 9.39839L14.8257 18.2209C15.1288 18.7458 15.4708 18.9642 15.7377 19.0438C16.0132 19.1259 16.2912 19.0864 16.5209 18.958C16.7514 18.8292 16.9235 18.6166 16.9922 18.3555C17.058 18.1053 17.0488 17.7294 16.7668 17.2409C16.4496 16.6914 16.0503 15.9999 15.5975 15.2159C14.4821 13.2843 13.0423 10.7909 11.7056 8.47466C11.1107 7.44383 10.3459 6.94556 9.60832 6.769C8.85211 6.58797 8.04239 6.72593 7.35272 7.11688C6.66281 7.50797 6.13558 8.12794 5.91056 8.8575C5.69148 9.5678 5.73342 10.4627 6.32122 11.4804C6.98228 12.6248 8.67577 15.5109 10.5555 18.7143C11.481 20.2915 10.5948 18.8788 11.5625 20.4077C12.6858 22.1824 13.9532 22.9062 15.9922 22.9062C19.7201 22.9062 22.5414 18.958 20.3579 15.4164C18.7296 12.7754 15.9126 8.10425 15.4895 7.37768C17.0938 5.1875 19.4375 4.64062 20.8281 4.64062C24.3086 4.64062 27.2031 7.50781 27.2031 11.125C27.2031 12.0059 26.9688 13.2266 26.3516 14.2656L20.3044 24.5303C18.3622 27.8324 13.575 27.8186 11.6409 24.5179C10.7299 22.9633 9.76189 21.3137 8.83781 19.7389C6.9547 16.5297 5.25392 13.6312 4.58936 12.4807C3.74689 11.0221 3.60284 9.55374 3.9994 8.26803C4.39002 7.00156 5.28007 5.99282 6.36642 5.37699C7.45303 4.76103 8.77856 4.51385 10.074 4.82396Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="CaseManager"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM18.0784 13.6062C17.4433 12.5058 16.541 11.8539 15.5554 11.618C14.5839 11.3854 13.5897 11.5708 12.7748 12.0327C11.96 12.4946 11.2925 13.2512 10.9995 14.201C10.7021 15.1653 10.8101 16.2666 11.442 17.3605C11.9403 18.2233 13.2156 20.3966 14.6277 22.8031L14.6283 22.8041C15.3214 23.9852 16.0474 25.2225 16.7306 26.3884C18.1812 28.8639 21.7717 28.8743 23.2283 26.3977L27.7636 18.6992C28.2265 17.9199 28.4023 17.0044 28.4023 16.3438C28.4023 13.6309 26.2314 11.4805 23.6211 11.4805C22.5781 11.4805 20.8203 11.8906 19.6171 13.5333C19.9344 14.0782 22.0472 17.5816 23.2684 19.5623C24.9061 22.2185 22.79 25.1797 19.9941 25.1797C18.4648 25.1797 17.5143 24.6368 16.6718 23.3057C16.3104 22.7347 16.2938 22.7108 16.2825 22.6846C16.2711 22.6583 16.265 22.6295 15.9166 22.0357L15.9152 22.0333C14.5059 19.6317 13.2365 17.4683 12.7409 16.6103C12.3 15.8471 12.2686 15.1759 12.4329 14.6431C12.6017 14.096 12.9971 13.631 13.5145 13.3377C14.0318 13.0444 14.639 12.941 15.2062 13.0768C15.7594 13.2092 16.333 13.5829 16.7792 14.356C17.7817 16.0932 18.8616 17.9632 19.6981 19.4119L20.5751 20.9307C20.7866 21.297 20.7935 21.579 20.7441 21.7667C20.6926 21.9625 20.5635 22.1219 20.3906 22.2185C20.2183 22.3148 20.0099 22.3444 19.8032 22.2828C19.603 22.2231 19.3465 22.0594 19.1192 21.6657L15.299 15.0488C15.0919 14.6901 14.6332 14.5672 14.2745 14.7743C13.9157 14.9814 13.7928 15.4401 13.9999 15.7988L17.8202 22.4157C18.2111 23.0927 18.7566 23.536 19.3746 23.7203C19.9861 23.9027 20.6119 23.8132 21.1224 23.5279C21.6323 23.243 22.0344 22.7583 22.1948 22.1481C22.3574 21.5297 22.2569 20.8437 21.8741 20.1807L20.997 18.6616C20.1604 17.213 19.0808 15.3433 18.0784 13.6062Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

CaseManagerIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

CaseManagerIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
