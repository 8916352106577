// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";

const TimeSlotTile = (props) => {
    const slotsAvailableText = `${
        [null, 0].includes(props.slotsCount) ? "No" : props.slotsCount
    } session${props.slotsCount === 1 ? "" : "s"} available`;
    const slotsText = props.timeSlotsLoading ? "Loading..." : slotsAvailableText;
    return (
        <div className="Schedule__TimeSlot-tile d-none d-lg-inline-block text-center mb-3">
            <h6 className="Schedule__TimeSlot-day fs-15 mb-1">{props.isToday ? "Today" : ""}</h6>
            <h6 className="Schedule__TimeSlot-day fs-15 mb-2">{props.dateStr}</h6>
            <p className="Schedule__TimeSlot-slots fs-11 m-0">{slotsText}</p>
        </div>
    );
};

export default TimeSlotTile;
