import * as yup from "yup";

export const WAIT_LIST_INITIAL_VALUES = {
    wait_list_patient_category: undefined,
    wait_list_reason: [],
    wait_list_no_longer_schedule_with_roles: undefined,
    wait_list_requires_urgent_placement: false,
    isCommentActive: false,
    wait_list_comment: "",
};

export const PATIENT_OPTIONS = [
    { value: "di_no_completed", label: "New patient with completed DI" },
    { value: "established_patient", label: "Established patient" },
];
const PATIENT_OPTIONS_VALUES = PATIENT_OPTIONS.map((item) => item.value);

export const CAN_NO_LONGER_SCHEDULE_WITH_ROLES = [
    { checked: true, value: "BEHAVIORAL_INTAKE", label: "Intake", disabled: true },
    { checked: false, value: "PRESCRIBE", label: "Prescriber" },
    { checked: false, value: "BEHAVIORAL", label: "BHS" },
];

const CAN_NO_LONGER_SCHEDULE_WITH_ROLES_VALUES = CAN_NO_LONGER_SCHEDULE_WITH_ROLES.map(
    (item) => item.value,
);

export const NON_DI_PATIENT_REASONS = [
    { checked: false, value: "No BHS after intake", label: "No BHS after intake" },
    { checked: false, value: "No prescriber after intake", label: "No prescriber after intake" },
    { checked: false, value: "Insurance change", label: "Insurance change" },
    { checked: false, value: "Teens and Kids program", label: "Teens and Kids program" },
    { checked: false, value: "Other", label: "Other" },
];

export const ESTABLISHED_PATIENT_REASONS = [
    { checked: false, value: "Provider departure", label: "Provider departure" },
    { checked: false, value: "Insurance change", label: "Insurance change" },
    { checked: false, value: "Patient request", label: "Patient request" },
    { checked: false, value: "Medication management", label: "Medication management" },
    { checked: false, value: "Teens and Kids program", label: "Teens and Kids program" },
    { checked: false, value: "Other", label: "Other" },
];

const WAIT_LIST_REASON_VALUES = [...NON_DI_PATIENT_REASONS, ...ESTABLISHED_PATIENT_REASONS].map(
    (item) => item.value,
);

export const WAIT_LIST_VALIDATION = yup.object().shape({
    wait_list_patient_category: yup
        .string()
        .oneOf(PATIENT_OPTIONS_VALUES)
        .required("Question is required"),
    wait_list_reason: yup
        .array()
        .of(yup.string())
        .test("values-in-list", "Please select a valid answer", (values) => {
            return values.every((value) => WAIT_LIST_REASON_VALUES.includes(value));
        })
        .required("Question is required"),
    wait_list_no_longer_schedule_with_roles: yup
        .array()
        .nullable()
        .of(yup.string())
        .test("values-in-list", "Please select a valid answer", (values) => {
            if (values === undefined) {
                return true;
            }
            return values.every((value) =>
                CAN_NO_LONGER_SCHEDULE_WITH_ROLES_VALUES.includes(value),
            );
        }),
    wait_list_requires_urgent_placement: yup.bool().required("Question is required"),
    wait_list_comment: yup.string().max(500, "500 character limit"),
});
