// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import PropTypes from "prop-types";

const CollapsibleParaContent = (props) => {
    const { title, text } = props;

    return (
        <div className="CollapsibleParaContent mb-3 pb-3 pb-lg-4">
            <h2 className="fs-18 fw-bold">{title}</h2>
            <p className="fs-14 m-0">{text}</p>
        </div>
    );
};

CollapsibleParaContent.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default CollapsibleParaContent;
