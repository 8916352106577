// Copyright aptihealth, inc. 2022 All Rights Reserved
import React, { useState } from "react";
import ProfileDetailsCard from "./ProfileDetailsCard";
import AcceptedAgeRangeModal from "./AcceptedAgeRangeModal";
import { provider_case_manager, provider_peer } from "../../../config/roles";
import { isAuthorized } from "../../../redux/actions/auth";
import { getAutomationDataAttr } from "../../../utils/automationUtils";

const AcceptedAgeRange = (props) => {
    const { acceptedAgeRanges, setAcceptedAgeRanges, noBoxShadow } = props;

    const [showAcceptedAgeRangeModal, setShowAcceptedAgeRangeModal] = useState(false);

    return (
        <>
            {showAcceptedAgeRangeModal && (
                <AcceptedAgeRangeModal
                    acceptedAgeRanges={acceptedAgeRanges}
                    setAcceptedAgeRanges={setAcceptedAgeRanges}
                    closeModal={() => setShowAcceptedAgeRangeModal(false)}
                />
            )}
            <ProfileDetailsCard
                heading="Accepted Age Range"
                onclick={() => setShowAcceptedAgeRangeModal(true)}
                edit={isAuthorized(provider_case_manager) || isAuthorized(provider_peer)}
                noBoxShadow={noBoxShadow}
                testId={"AcceptedAgeRange"}>
                <div
                    className="ProviderProfile__states-to-practice my-2 pt-4 px-2 d-flex flex-wrap"
                    data-public={"true"}>
                    {acceptedAgeRanges.map((acceptedAgeRange, index) => {
                        return (
                            <AgeRangeDisplay
                                key={index}
                                min={acceptedAgeRange.min}
                                max={acceptedAgeRange.max}
                            />
                        );
                    })}
                </div>
            </ProfileDetailsCard>
        </>
    );
};

export default AcceptedAgeRange;

const AgeRangeDisplay = (props) => {
    return (
        <div className={"d-flex mb-4"}>
            <div className={"d-flex flex-column pr-4"}>
                <label className="fs-14 fw-bold">Lowest Age</label>
                <span {...getAutomationDataAttr("AcceptedAgeRangeMin")}>{props.min}</span>
            </div>
            <div className={"d-flex flex-column pr-4"}>
                <label className="fs-14 fw-bold">Highest Age</label>
                <span {...getAutomationDataAttr("AcceptedAgeRangeMax")}>{props.max}</span>
            </div>
            <div className={"vertical-break pr-4"} />
        </div>
    );
};
