import React from "react";
import CardSecondary from "../../../UI/Card/CardSecondary";
import InputError from "../../../UI/InputError";
import { Select } from "../../../UI/StyledInput";
import { eventTypeDisplayNameFactory } from "../../ScheduleAvailability/ActiveScheduleDetails";
import {
    _30_MIN_INDIVIDUAL_90832,
    _45_MIN_INDIVIDUAL_90834,
    _60_MIN_INDIVIDUAL_90837,
    allottedTimeFactory,
    BEHAVIORAL,
    BEHAVIORAL_INTAKE,
    DIAGNOSTIC_INTERVIEW_90792,
    ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215,
    FAMILY_THERAPY_90847,
    FAMILY_THERAPY_WO_PATIENT_90846,
    MEDICATION_MGMT_99212,
    MEDICATION_MGMT_99213,
    MEDICATION_MGMT_99214,
    OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
    OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
    PRESCRIBE,
} from "../../ScheduleAvailability/constants";
import moment from "moment";
import { connect } from "react-redux";
import { serviceLocationOptions } from "./constants";
import { getAutomationDataAttr } from "../../../../utils/automationUtils";

export const GUIDED_SESSION_CPT_OPTIONS = [
    {
        value: _30_MIN_INDIVIDUAL_90832,
        display: `30 Minute Individual (90832) (${allottedTimeFactory(
            _30_MIN_INDIVIDUAL_90832,
        )} minutes)`,
        provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE],
    },
    {
        value: _45_MIN_INDIVIDUAL_90834,
        display: `45 Minute Individual (90834) (${allottedTimeFactory(
            _45_MIN_INDIVIDUAL_90834,
        )} minutes)`,
        provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE],
    },
    {
        value: _60_MIN_INDIVIDUAL_90837,
        display: `60 Minute Individual (90837) (${allottedTimeFactory(
            _60_MIN_INDIVIDUAL_90837,
        )} minutes)`,
        provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE],
    },
    {
        value: FAMILY_THERAPY_90847,
        display: `Family Therapy w/ Patient (90847) (${allottedTimeFactory(
            FAMILY_THERAPY_90847,
        )} minutes)`,
        provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE],
    },
    {
        value: FAMILY_THERAPY_WO_PATIENT_90846,
        display: `Family Therapy w/o Patient (90846) (${allottedTimeFactory(
            FAMILY_THERAPY_WO_PATIENT_90846,
        )} minutes)`,
        provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE],
    },
    {
        value: OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
        display: `Office or Other Outpatient Visit w/ New Patient (99204) (${allottedTimeFactory(
            OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
        )} minutes)`,
        provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE],
    },
    {
        value: OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
        display: `Office or Other Outpatient Visit w/ New Patient (99205) (${allottedTimeFactory(
            OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
        )} minutes)`,
        provider_types: [BEHAVIORAL],
    },
    {
        value: DIAGNOSTIC_INTERVIEW_90792,
        display: `Diagnostic Interview (90792) (${allottedTimeFactory(
            DIAGNOSTIC_INTERVIEW_90792,
        )} minutes)`,
        provider_types: [PRESCRIBE],
    },
    {
        value: MEDICATION_MGMT_99212,
        display: `Medication Management (99212) (${allottedTimeFactory(
            MEDICATION_MGMT_99212,
        )} minutes)`,
        provider_types: [PRESCRIBE],
    },
    {
        value: MEDICATION_MGMT_99213,
        display: `Medication Management (99213) (${allottedTimeFactory(
            MEDICATION_MGMT_99213,
        )} minutes)`,
        provider_types: [PRESCRIBE],
    },
    {
        value: MEDICATION_MGMT_99214,
        display: `Medication Management (99214) (${allottedTimeFactory(
            MEDICATION_MGMT_99214,
        )} minutes)`,
        provider_types: [PRESCRIBE],
    },
    {
        value: ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215,
        display: `${eventTypeDisplayNameFactory(
            ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215,
        )}`,
        provider_types: [PRESCRIBE],
    },
];

const NotesDuration = (props) => {
    const { note, editable, profileView, editHandler, errors } = props;

    const handleSelection = async (e) => {
        if (!editable) {
            return;
        }
        const newSessionTo = moment(note.session_from, "HH:mm")
            .add(allottedTimeFactory(e.target.value), "minutes")
            .format("HH:mm");

        const newNoteTitle =
            note.title.split(" - ")[0] +
            ` - (${moment(note["session_from"], "HH:mm").format("hh:mm A")}-${moment(
                newSessionTo,
                "HH:mm",
            ).format("hh:mm A")})`;

        editHandler("call_type", e.target.value, false);
        editHandler("session_to", newSessionTo, false);
        editHandler("title", newNoteTitle, false);
        editHandler("call_name", eventTypeDisplayNameFactory(e.target.value), false);
    };

    const handleServiceLocationSelection = (e) => {
        editHandler("service_location", e.target.value, false);
    };

    const timerFormat = (callTimer) => {
        let formattedTime = "";
        if (callTimer) {
            const timeParts = callTimer.split(":").map((timePart) => parseInt(timePart));
            if (timeParts.length === 3) {
                if (timeParts[0] > 0) {
                    formattedTime += `${timeParts[0]} hr `;
                }
                formattedTime += `${timeParts[1]} min `;
                formattedTime += `${timeParts[2]} seconds`;
            } else if (timeParts.length === 2) {
                formattedTime += `${timeParts[0]} min `;
                formattedTime += `${timeParts[1]} seconds`;
            }
        }
        return formattedTime;
    };

    const options = props.profile?.provider_type
        ? GUIDED_SESSION_CPT_OPTIONS.filter((option) =>
              option.provider_types.includes(props.profile.provider_type),
          )
        : [];

    // const call_duration = note['content'] && note['content'].hasOwnProperty('call_timer') &&
    //                         timerFormat(note['content']['call_timer']);

    const isPrescriber = props.profile?.provider_type === PRESCRIBE;
    const innerContentTitle = `Call Duration${isPrescriber ? " and Location" : ""}`;

    let innerContent = (
        <>
            <div>
                <div className="clr-sec fw-bold px-3 py-3">{innerContentTitle}</div>
            </div>
            <div className="row px-4">
                {editable && (
                    <>
                        <div className="col-lg-7 col-12 pb-3">
                            <span className={"font-weight-bold"}>Select CPT Code</span>
                            <Select
                                name="call_type"
                                changeHandler={handleSelection}
                                value={note["call_type"]}
                                options={options}
                                testId="NotesDuration_cptCode"
                            />
                            {errors["call_type"] ? (
                                <InputError classes={"custom-error"}>
                                    {errors["call_type"]}
                                </InputError>
                            ) : (
                                ""
                            )}
                        </div>
                        {isPrescriber && (
                            <div className="col-lg-7 col-12 pb-3">
                                <span className={"font-weight-bold"}>Select Service Location</span>
                                <Select
                                    name="service_location"
                                    changeHandler={handleServiceLocationSelection}
                                    value={note.service_location}
                                    options={serviceLocationOptions}
                                />
                                {errors.service_location ? (
                                    <InputError classes={"custom-error"}>
                                        {errors.service_location}
                                    </InputError>
                                ) : (
                                    ""
                                )}
                            </div>
                        )}
                    </>
                )}
                {!editable && (
                    <div className="fs-12">
                        <div className="col-12 mb-1">
                            <div className={"fw-bold"}>CPT Code</div>
                            <div>{eventTypeDisplayNameFactory(note["call_type"])}</div>
                        </div>
                        {note.service_location && (
                            <div className="col-12 mb-1">
                                <div className={"fw-bold"}>Service Location</div>
                                <div>{note.service_location}</div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
    let content;

    if (!profileView) {
        content = (
            <CardSecondary className="mx-0 px-3 py-3 my-3 max-width" style={{ minHeight: 200 }}>
                {innerContent}
            </CardSecondary>
        );
    } else {
        content = (
            <div className="border-top-note border-right-note" style={{ minHeight: 150 }}>
                {innerContent}
            </div>
        );
    }

    return <>{content}</>;
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps)(NotesDuration);
