// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import {
    BS_INITIAL_VALUES,
    BS_SCHEMA,
    P_INITIAL_VALUES,
    P_SCHEMA,
} from "./schema/newNoteFormSchema";
import { Form } from "formik";
import Button from "../../../../components/UI/Button";
import { api } from "../../../../APIRequests";
import { getQueryParams } from "../../../../utils/filters";
import { isAuthorized } from "../../../../redux/actions/auth";
import {
    createCommonNoteData,
    getNoteCard,
    getNoteFields,
    pickNoteDate,
    setBehavioralNoteData,
    setPrescriberNoteData,
    setSessionDate,
} from "../../../../components/Provider/PatientView/ProgressNotes";
import moment from "moment-timezone";
import { connect } from "react-redux";
import store from "../../../../redux/store";
import { showLoader } from "../../../../redux/actions/loader";
import { durationToString } from "../../../../utils/momentUtils";

class ViewNote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.noteId,
            note: null,
            behavioral: isAuthorized("provider:behavioral"),
            prescribe: isAuthorized("provider:prescribe"),
            dateContext: moment(),
            showDatePicker: false,
            saveFunction: api.provider.put_progress_note,
            patientId: this.props.match.params.patientId,
            patientName: "",
        };
    }

    componentDidMount = async () => {
        try {
            let urlParams = { patientId: this.state.patientId };
            const options = { params: { showLoader: false } };
            const patientResponse = await api.shared.fetch_patient_details({ urlParams, options });
            const user = patientResponse.user;
            const queryParams = { note_id: this.state.id };
            const response = await api.shared.fetch_patient_progress_notes({
                urlParams,
                queryParams,
            });
            if (response) {
                if (response) {
                    this.setState({
                        patientName: user.first_name + " " + user.last_name,
                        note: response,
                        title: response.title,
                    });
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    submitHandler = async (formData, actions) => {
        store.dispatch(showLoader());
        let data = await createCommonNoteData(this, formData, this.state.title, this.props.profile);
        if (this.state.behavioral) {
            setBehavioralNoteData(this, formData, this.state.title, data);
        } else {
            setPrescriberNoteData(this, formData, this.state.title, data);
        }
        data.id = this.state.id;
        let urlParams = { patientId: this.state.patientId };
        this.state
            .saveFunction({ data, urlParams })
            .then((response) => {
                this.props.history.push(`/app/patient/${this.state.patientId}/profile`);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    setBehavioralSpecialistNote = (note) => {
        let vals = { ...BS_INITIAL_VALUES };
        vals.title = note.title;
        vals.session_date = note.session_date;
        vals.session_from = note.session_from;
        vals.session_to = note.session_to;
        vals.patient_report = note.patient_report;
        vals.patient_task = note.patient_task;
        vals.interventions = note.interventions;
        vals.intervention_reaction = note.intervention_reaction;
        return vals;
    };

    setPrescriberNote = (note) => {
        let vals = { ...P_INITIAL_VALUES };
        vals.title = note.title;
        vals.session_date = note.session_date;
        vals.session_from = note.session_from;
        vals.session_to = note.session_to;
        vals.patient_reported = note.patient_reported;
        vals.prescriber_interventions = note.prescriber_interventions;
        vals.intervention_reaction = note.intervention_reaction;
        return vals;
    };

    getPickedDate = (dateString) => {
        pickNoteDate(this, dateString);
    };

    renderTitle = () => {
        return (
            <div className="ml-0 ml-lg-3">
                <b>{this.state.note.title}</b>
            </div>
        );
    };

    renderForm = (formikProps) => {
        let schema;
        if (this.state.note && !this.state.note.prescriber_interventions) {
            schema = BS_SCHEMA;
        } else if (this.state.note && this.state.note.prescriber_interventions) {
            schema = P_SCHEMA;
        }
        let dateStr = setSessionDate(this);
        return (
            <Form>
                {this.state.note && this.state.edit && getNoteFields(formikProps, schema, this)}
                {this.state.note && !this.state.edit && (
                    <div style={{ padding: 20 }}>
                        {renderSessionMetaData(dateStr, this.state.note, this.state.patientName)}
                        {!this.state.note.prescriber_interventions &&
                            renderBehavioralData(this.state.note)}
                        {this.state.note.prescriber_interventions &&
                            renderPrescribeData(this.state.note)}
                    </div>
                )}
                {this.state?.note?.provider_cp_credentials ? (
                    <div className="col-12 col-lg-6">
                        <div>
                            <label className="Label">Provider Credentials</label>
                            <p>{this.state?.note?.provider_cp_credentials}</p>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <div className="d-flex justify-content-center justify-content-lg-end">
                    {this.state.edit && (
                        <div>
                            <Button
                                type="button"
                                onClick={() =>
                                    this.props.history.push(
                                        `/app/patient/${this.state.patientId}/profile`,
                                    )
                                }
                                className="Btn Btn--otl-pri Btn-sm mr-3">
                                Cancel
                            </Button>
                            <Button type="submit" className="Btn Btn--pri Btn-sm">
                                Update Note
                            </Button>
                        </div>
                    )}
                </div>
            </Form>
        );
    };

    renderControls = () => {};

    render() {
        let vals;
        if (this.state.note && !this.state.note.prescriber_interventions) {
            vals = this.setBehavioralSpecialistNote(this.state.note);
        } else if (this.state.note && this.state.note.prescriber_interventions) {
            vals = this.setPrescriberNote(this.state.note);
        }
        return <div>{this.state.note && getNoteCard(vals, null, this)}</div>;
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};
export default connect(mapStateToProps)(ViewNote);

export const renderSessionMetaData = (dateStr, note, patientName) => {
    let timing = "";
    let totalDuration = "";
    if (note.session_date && note.session_to && note.session_from) {
        timing = note.session_from + " to " + note.session_to;
        let momentTo = moment(note.session_date + " " + note.session_to);
        let momentFrom = moment(note.session_date + " " + note.session_from);
        let diff = moment.duration(momentTo.diff(momentFrom));
        totalDuration = durationToString(diff);
    }

    return (
        <div className="row">
            <div className="col-12 col-lg-6">
                <div>
                    <label className="Label">Patient Name</label>
                    <p>{patientName}</p>
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div>
                    <label className="Label">Linked Call</label>
                    <p>{note["call_name"]}</p>
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div>
                    <label className="Label">Session Date</label>
                    <p>{dateStr}</p>
                </div>
            </div>
            <div className="col-6 col-lg-3">
                <div>
                    <label className="Label">Timing</label>
                    <p>{timing}</p>
                </div>
            </div>
            <div className="col-6 col-lg-3">
                <div>
                    <label className="Label">Duration</label>
                    <p>{totalDuration}</p>
                </div>
            </div>
        </div>
    );
};

export const renderBehavioralData = (note) => {
    return (
        <div className="row">
            <div className="col-12 col-lg-6">
                <div>
                    <label className="Label">Patient Report or Behavior</label>
                    <p>{note.patient_report}</p>
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div>
                    <label className="Label">Interventions</label>
                    <p>{note.interventions}</p>
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div>
                    <label className="Label">Intervention Reaction</label>
                    <p>{note.intervention_reaction}</p>
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div>
                    <label className="Label">Patient Task</label>
                    <p>{note.patient_task}</p>
                </div>
            </div>
            {getSignatureFields(note)}
        </div>
    );
};

export const getSignatureFields = (note) => {
    return (
        <>
            <div className="col-12 col-lg-6 mt-3">
                <div>
                    <label className="Label">ICD-10 Primary Diagnosis</label>
                    <div>
                        {note.ICD_10_axis_1_diagnosis ? (
                            <ul>{renderICD10(note.ICD_10_axis_1_diagnosis)}</ul>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-6 mt-3">
                <div>
                    <label className="Label">ICD-10 Secondary Diagnosis</label>
                    <div>
                        {note.ICD_10_axis_2_diagnosis ? (
                            <ul>{renderICD10(note.ICD_10_axis_2_diagnosis)}</ul>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
            {/* {note?.signature ? <div className={'col-lg-6 mb-2 mt-3'}>
                    <div className={'fw-bold'}>Signature</div>
                    <div className='w-100 fs-24 border-bottom'
                        style={{fontFamily: 'Apple Chancery, cursive'}}>
                        {note.signature}
                    </div>
        </div>: ' '}
        {note?.completed_date_time ? <div className="col-12 col-lg-6 mt-3">
            <div>
                <label className='Label'>Signature Date</label>
                <p>{note.completed_date_time ?
                    moment(note.completed_date_time).format('MM/DD/YYYY') :
                    ''}
                </p>
            </div>
        </div>: ''}
        
        {note?.provider_cp_credentials ? <div className={'col-lg-6 mb-2 mt-3'}>
                <div className={'fw-bold'}>Credentials</div>
                <div>{note.provider_cp_credentials}</div>
            </div> : '' }
            {note?.provider_practice ?
                    <div className="col-12 col-lg-6 mt-3">
                    <div>
                        <label className='Label'>Provider Practice</label>
                        <p>{note.provider_practice }
                        </p>
                    </div>
                </div> : ''} */}
        </>
    );
};

export const renderPrescribeData = (note) => {
    return (
        <div className="row">
            <div className="col-12 col-lg-6">
                <div>
                    <label className="Label">Patient Reported</label>
                    <p>{note.patient_reported}</p>
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div>
                    <label className="Label">Prescriber Interventions or Education</label>
                    <p>{note.prescriber_interventions}</p>
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div>
                    <label className="Label">Intervention or Education Reaction</label>
                    <p>{note.intervention_reaction}</p>
                </div>
            </div>
            {getSignatureFields(note)}
        </div>
    );
};

export const renderICD10 = (data) => {
    if (!data) {
        return <></>;
    }

    return data.map((diagnosis) => <li className={"ICD10-items"}>{diagnosis.value}</li>);
};
