import React, { useRef } from "react";
import PropTypes from "prop-types";
import { createClassNameString } from "../../utils";
import { TextColors, TextTypes } from "../../constants";
import { Text } from "../../Text";

import "./styles.scss";

export const RadioOld = ({ version, ...props }) => {
    if (version === "v1") {
        return <RadioV1 {...props} />;
    } else if (version === "v2") {
        return <RadioV2 {...props} />;
    } else {
        console.error(`Invalid radio version ${version}`);
        return null;
    }
};

RadioOld.propTypes = {
    version: PropTypes.string,
};

RadioOld.defaultProps = {
    version: "v1",
};

export const RadioV2 = ({
    className,
    labelClassName,
    radioClassName,
    label,
    name,
    value,
    checked,
    onClick,
    onChange,
    ...props
}) => {
    // This adds the functionality of being able to select the button by clicking the text or the checkbox
    const inputElement = useRef();

    return (
        <div
            onClick={() => {
                onClick({ target: inputElement?.current });
                onChange({ target: inputElement?.current });
            }}
            className={createClassNameString(["apti-CL apti-Radio--container", className])}>
            <input
                ref={inputElement}
                className={createClassNameString([
                    "apti-Radio",
                    !label && "no-label",
                    radioClassName,
                ])}
                type="radio"
                name={name}
                value={value}
                checked={checked}
                {...props}
            />
            {label && (
                <Text
                    className={createClassNameString(["apti-Radio--label", labelClassName])}
                    type={TextTypes.label}
                    color={TextColors.grey}>
                    {label}
                </Text>
            )}
        </div>
    );
};

export const RadioV1 = ({
    className,
    labelClassName,
    radioClassName,
    label,
    name,
    value,
    checked,
    onClick,
    onChange,
    ...props
}) => (
    <div className={createClassNameString(["apti-CL apti-Radio--container", className])}>
        <input
            className={createClassNameString(["apti-Radio", !label && "no-label", radioClassName])}
            type="radio"
            name={name}
            value={value}
            checked={checked}
            onClick={onClick}
            onChange={onChange}
            {...props}
        />
        {label && (
            <Text
                className={createClassNameString(["apti-Radio--label", labelClassName])}
                type={TextTypes.label}
                color={TextColors.grey}>
                {label}
            </Text>
        )}
    </div>
);

const radioV1PropTypes = {
    children: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

const radioV1DefaultValues = {
    className: undefined,
    labelClassName: undefined,
    label: undefined,
    value: "",
    checked: false,
    onClick: () => {},
};

RadioV1.propTypes = radioV1PropTypes;
RadioV1.defaultProps = radioV1DefaultValues;

RadioV2.propTypes = radioV1PropTypes;
RadioV2.defaultProps = radioV1DefaultValues;
