import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { createClassNameString } from "../../component-library";
import { ICON_TYPES, ICON_SIZES } from "../../constants/icons";

export const CloseIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}>
                <path d="M8.70713 7.29289C8.31661 6.90237 7.68345 6.90237 7.29292 7.29289C6.9024 7.68342 6.9024 8.31658 7.29292 8.70711L14.5858 16L7.29289 23.2929C6.90237 23.6834 6.90237 24.3166 7.29289 24.7071C7.68342 25.0977 8.31658 25.0977 8.70711 24.7071L16 17.4142L23.2929 24.7071C23.6834 25.0976 24.3166 25.0976 24.7071 24.7071C25.0977 24.3166 25.0977 23.6834 24.7071 23.2929L17.4142 16L24.7071 8.70714C25.0976 8.31661 25.0976 7.68345 24.7071 7.29292C24.3166 6.9024 23.6834 6.9024 23.2929 7.29292L16 14.5858L8.70713 7.29289Z" />
            </svg>
        );
    }

    return null;
};

CloseIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

CloseIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
