import React from "react";

import { TextTypes, TextColors } from "../../../constants";
import { Text } from "../../../Text";
import { NotificationSection } from "../../../NotificationSection";

import "./styles.scss";

// Relates to: Profile Insurance Status

export const Accepted = ({ copay, carrier, dateChecked }) => {
    return (
        <NotificationSection className={"accepted"}>
            <Text className={"bold mb-3"}>Great news – you’re covered!</Text>
            {!copay && (
                <Text className="details mb-3">
                    <>
                        <div className="mb-3">
                            Your plan may have coinsurance or deductibles. Refer to your insurance
                            card or contact <span className="bold">{carrier}</span> for details.
                        </div>
                        <div>Coverage results based on last insurance check: {dateChecked}.</div>
                    </>
                </Text>
            )}

            {copay > 0 && (
                <Text>
                    <>
                        <div className="mb-3">
                            Your plan has a <span className="bold">${copay}</span> copay for
                            sessions. Contact {carrier} to learn if your benefit plan involves
                            additional costs.
                        </div>
                        <div>Coverage results based on last insurance check: {dateChecked}.</div>
                    </>
                </Text>
            )}
        </NotificationSection>
    );
};

Accepted.defaultProps = {
    copay: null,
    dateChecked: "",
    carrier: "",
};
