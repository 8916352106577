import { Form, Formik, getIn } from "formik";
import Label from "../../UI/Label";
import Select from "react-select";
import InputError from "../../UI/InputError";
import Button from "../../UI/Button";
import React, { useEffect, useState } from "react";
import CardSecondary from "../../UI/Card/CardSecondary";
import CardHeading from "../../UI/Headings/CardHeading";
import { api } from "../../../APIRequests";
import _ from "lodash";
import * as Yup from "yup";
import { CustomForm } from "../../../component-library/CustomForm";

export const ProviderPracticeDetails = (props) => {
    const [providerDetails, setProviderDetails] = useState(props.providerDetails);

    const [editProviderPracticeInfo, setEditProviderPracticeInfo] = useState(false);
    const [allPracticeOptions, setAllPracticeOptions] = useState();
    const [practicesById, setPracticesById] = useState({});

    // filtered additional practices is all practices minus the default practice
    const [filteredAdditionalPracticeIds, setFilteredAdditionalPracticeIds] = useState([]);
    const [additionalPracticesInputDefault, setAdditionalPracticesInputDefault] = useState(null);

    useEffect(() => {
        (async () => {
            const options = { params: { showLoader: false } };
            const queryParams = { fields: "ids" };
            const response = await api.admin.fetch_all_practices({ options, queryParams });
            const allPracticeOptions = response.map((practice) => {
                return {
                    value: practice["id"],
                    label: practice["name"],
                };
            });
            setAllPracticeOptions(allPracticeOptions);
            const practicesById = response.reduce((obj, item) => {
                obj[item["id"]] = item;
                return obj;
            }, {});
            setPracticesById(practicesById);

            const filteredAdditionalPracticeIds = filterAdditionalPractices(
                providerDetails.practice_ids,
            );

            setFilteredAdditionalPracticeIds(filteredAdditionalPracticeIds);

            setAdditionalPracticesInputDefault(
                getAdditionalPracticesInputDefault(filteredAdditionalPracticeIds, practicesById),
            );
        })();
    }, []);

    const defaultPracticeInput = (formikProps) => {
        const inputName = "default_practice_id";
        const error = getIn(formikProps.errors, inputName);
        const touched = getIn(formikProps.touched, inputName);
        return (
            <>
                <Label>Default Practice</Label>
                <Select
                    components={{ ClearIndicator: null }}
                    options={allPracticeOptions}
                    defaultValue={{
                        value: providerDetails.practice_id,
                        label: providerDetails.practice,
                    }}
                    className={"basic-multi-select"}
                    classNamePrefix="select"
                    onChange={async (selectedOptions) =>
                        await formikProps.setFieldValue(inputName, selectedOptions)
                    }
                />
                {error && touched ? (
                    <InputError classes={"custom-error"}>{error}</InputError>
                ) : null}
            </>
        );
    };

    const getAdditionalPracticesInputDefault = (filteredAdditionalPracticeIds, practicesById) => {
        let defaultValue = null;
        if (filteredAdditionalPracticeIds) {
            defaultValue = filteredAdditionalPracticeIds
                .map((id) => {
                    const practice = practicesById[id];
                    if (!practice) {
                        return;
                    }
                    return {
                        value: practice.id,
                        label: practice.name,
                    };
                })
                .filter((value) => value !== undefined);
        }
        return defaultValue;
    };

    const additionalPracticesInput = (formikProps) => {
        const inputName = "additional_practices";
        const error = getIn(formikProps.errors, inputName);
        const touched = getIn(formikProps.touched, inputName);
        return (
            <>
                <Label>Additional Practices</Label>
                <Select
                    components={{ ClearIndicator: null }}
                    options={allPracticeOptions}
                    defaultValue={additionalPracticesInputDefault}
                    className={"basic-multi-select"}
                    classNamePrefix="select"
                    isMulti
                    onChange={async (selectedOptions) =>
                        await formikProps.setFieldValue(inputName, selectedOptions)
                    }
                />
                {error && touched ? (
                    <InputError classes={"custom-error"}>{error}</InputError>
                ) : null}
            </>
        );
    };

    const renderForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 mb-3">
                    <div className="container px-0">
                        <div className="row">
                            <div className={"col-12 col-lg-6"}>
                                {defaultPracticeInput(formikProps)}
                            </div>
                            {providerDetails.provider_type === "CARE_MANAGER" && (
                                <div className={"col-12 col-lg-6"}>
                                    {additionalPracticesInput(formikProps)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="d-lg-flex d-block justify-content-center text-center justify-content-lg-end">
                    <Button
                        type="button"
                        onClick={() => setEditProviderPracticeInfo(!editProviderPracticeInfo)}
                        className="Btn Btn--otl-pri Btn-sm mr-lg-3 mb-3 mb-lg-0">
                        Cancel
                    </Button>
                    <Button type="submit" className="Btn Btn--pri Btn-sm">
                        Save
                    </Button>
                </div>
            </Form>
        );
    };

    const onSubmit = (formData) => {
        const defaultPracticeId = _.get(formData, "default_practice_id.value");
        const defaultPracticeName = _.get(formData, "default_practice_id.label");
        const additionalPracticeIds = [defaultPracticeId];

        const additionalPractices = _.get(formData, "additional_practices");
        if (additionalPractices) {
            for (let additionalPractice of additionalPractices) {
                additionalPracticeIds.push(_.get(additionalPractice, "value"));
            }
        }

        const data = {
            default_practice_id: defaultPracticeId,
            provider_id: _.get(providerDetails, "username"),
            practice: defaultPracticeName,
            additional_practice_ids: additionalPracticeIds,
        };

        api.admin
            .change_provider_practice({ data })
            .then((response) => {
                setEditProviderPracticeInfo(!editProviderPracticeInfo);

                const clonedProviderDetails = { ...providerDetails };
                clonedProviderDetails["practice"] = _.get(response, "practice");
                clonedProviderDetails["practice_id"] = _.get(response, "practice_id");
                clonedProviderDetails["practice_ids"] = additionalPracticeIds;
                setProviderDetails(clonedProviderDetails);

                const filteredAdditionalPracticeIds = filterAdditionalPractices(
                    clonedProviderDetails["practice_ids"],
                );
                setFilteredAdditionalPracticeIds(filteredAdditionalPracticeIds);
                setAdditionalPracticesInputDefault(
                    getAdditionalPracticesInputDefault(
                        filteredAdditionalPracticeIds,
                        practicesById,
                    ),
                );
            })
            .catch((err) => {
                console.log(err);
            });
    };

    if (!providerDetails) {
        return <></>;
    }

    const filterAdditionalPractices = (practiceIds) => {
        return practiceIds.filter((id) => id !== providerDetails.practice_id);
    };

    const additionalPracticeNames = (practiceIds) => {
        const practiceNames = practiceIds
            .map((id) => {
                const practice = practicesById[id];
                if (!practice) {
                    return;
                }
                return practice.name;
            })
            .filter((name) => name !== undefined);

        return (
            <>
                {practiceNames.map((name) => (
                    <p className="fs-14 mx-0 mx-md-3 mb-4">{name}</p>
                ))}
            </>
        );
    };

    const validation = Yup.object().shape({
        default_practice_id: Yup.string().required("Default Practice is required."),
        additional_practices: Yup.array().of(Yup.string()).nullable(),
    });

    return (
        <div data-public={"true"}>
            <CardSecondary className="mx-auto container Practice__Details">
                <div className="d-flex justify-content-between py-4">
                    <CardHeading className="fs-18 m-0 px-3 px-lg-4" text="Practice Details" />
                    <Button
                        onClick={() => setEditProviderPracticeInfo(!editProviderPracticeInfo)}
                        className="ProviderProfile__ProfileDetailsCard-controls cursor mr-2 fs-14 fw-bold mb-3">
                        Edit
                    </Button>
                </div>
                <hr className="m-0 d-none d-md-block" />
                {!editProviderPracticeInfo && (
                    <div className="py-2 px-3 px-lg-4">
                        <div className="row no-gutters mt-4">
                            <div className="col-12 col-lg-6">
                                <CardHeading
                                    text="Default Practice"
                                    className="fs-14 mx-0 mx-md-3"
                                />
                                <p className="fs-14 mx-0 mx-md-3 mb-4">
                                    {providerDetails.practice}
                                </p>
                            </div>
                            {providerDetails.provider_type === "CARE_MANAGER" && (
                                <div className="col-12 col-lg-6">
                                    <CardHeading
                                        text="Additional Practices"
                                        className="fs-14 mx-0 mx-md-3"
                                    />
                                    {additionalPracticeNames(filteredAdditionalPracticeIds)}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {editProviderPracticeInfo && (
                    <div className="py-4 px-2 px-lg-2">
                        <CustomForm
                            initialValues={{
                                default_practice_id: {
                                    value: _.get(providerDetails, "practice_id"),
                                    label: _.get(providerDetails, "practice"),
                                },
                                additional_practices: additionalPracticesInputDefault,
                            }}
                            validationSchema={validation}
                            onSubmit={onSubmit}
                            render={renderForm}
                        />
                    </div>
                )}
            </CardSecondary>
        </div>
    );
};
