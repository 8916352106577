// Copyright aptihealth, inc. 2019 All Rights Reserved

import * as yup from "yup";
import { dobValidationSchemaFactory } from "../../../utils/yupSchemaUtils";

export const A5_START_INITIAL_VALUES = {
    first_name: "",
    middle_name: "",
    last_name: "",
    gender: "",
    dob: "",
    ethnicity: "",
    marital_status: "",
    current_house: "",
    persons_living: "",
    hours_work_per_week: "",
};

export const A5_START_SCHEMA = [
    {
        name: "first_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "First Name",
            placeholder: "First Name",
        },
    },
    {
        name: "middle_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Middle Name",
            placeholder: "Middle Name",
        },
    },
    {
        name: "last_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Last Name",
            placeholder: "Last Name",
        },
    },
    {
        name: "gender",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "male", display: "Male" },
                { value: "female", display: "Female" },
            ],
            label: "Gender",
            placeholder: "Gender",
        },
    },
    {
        name: "dob",
        elementType: "DatePicker",
        elementConfig: {
            type: "text",
            label: "Date of Birth",
            placeholder: "MM/DD/YYYY",
        },
    },
    {
        name: "ethnicity",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "american_indian", display: "American Indian" },
                { value: "african_american", display: "African American" },
                { value: "alaska_native", display: "Alaska Native" },
                { value: "asian", display: "Asian" },
                { value: "white", display: "White" },
                { value: "hispanic_latino", display: "Hispanic/Latino" },
                { value: "pacific_islander", display: "Pacific Islander" },
                { value: "multiracial", display: "Multiracial" },
                { value: "other", display: "Other" },
            ],
            label: "Ethnicity",
            placeholder: "Ethnicity",
        },
    },
    {
        name: "marital_status",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "single", display: "Single" },
                { value: "married", display: "Married" },
                { value: "separated", display: "Separated" },
                { value: "divorced", display: "Divorced" },
                { value: "remarried", display: "Remarried" },
                { value: "widowed", display: "Widowed" },
                { value: "other", display: "Other" },
            ],
            label: "Marital Status",
            placeholder: "Marital Status",
        },
    },
    {
        name: "current_house",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "own_house_condo", display: "Own House/Condo" },
                { value: "rent_house", display: "Rent House" },
                { value: "rent_apartment", display: "Rent Apartment" },
                { value: "someones_house", display: "Someone Else’s Home" },
                { value: "supported_housing", display: "Supported Housing" },
                { value: "shelter", display: "Shelter" },
                { value: "homeless", display: "Homeless" },
                { value: "other", display: "Other" },
            ],
            label: "Current Housing",
            placeholder: "Current Housing",
        },
    },
    {
        name: "persons_living",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "alone", display: "Alone" },
                { value: "spouse", display: "Spouse" },
                { value: "spouse_children", display: "Spouse/Children" },
                { value: "partner", display: "Partner" },
                { value: "partner_children", display: "Partner/Children" },
                { value: "children", display: "Children" },
                { value: "parents", display: "Parent(s)" },
                { value: "other_family", display: "Other Family" },
                { value: "friends", display: "Friends" },
                { value: "other", display: "Other" },
            ],
            label: "Who I currently live with:",
            placeholder: "Who I currently live with:",
        },
    },
    {
        name: "hours_work_per_week",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "no_work", display: "Do not work" },
                { value: "lt_20", display: "Less than 20 hours" },
                { value: "gt_20_lte_40", display: "21-40 hours" },
                { value: "gt_40_lte_50", display: "41-50 hours" },
                { value: "gt_50_lte_60", display: "51-60" },
                { value: "gt_60", display: "More than 60 hours" },
            ],
            label: "Hours I work in a week:",
            placeholder: "Hours I work in a week",
        },
    },
];

export const A5_START_VALIDATION_SCHEMA = yup.object().shape({
    first_name: yup
        .string()
        .required("First Name is required")
        .matches(/^[a-zA-Z-]+$/, "Digits, space & special characters are not allowed"),
    middle_name: yup
        .string()
        .matches(/^[a-zA-Z-]+$/, "Digits, space & special characters are not allowed"),
    last_name: yup
        .string()
        .required("Last Name is required")
        .matches(/^[a-zA-Z-]+$/, "Digits, space & special characters are not allowed"),
    gender: yup.string().required("Gender is required"),
    dob: dobValidationSchemaFactory("MMDDYYYY").required("Required"),
    ethnicity: yup.string().required("Ethnicity is required"),
    marital_status: yup.string().required("Marital Status is required"),
    current_house: yup.string().required("Current Housing is required"),
    persons_living: yup.string().required("Person(s) I live with is required"),
    hours_work_per_week: yup.string().required("Hours I work in a week is required"),
});
