// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { Form, Formik, getIn } from "formik";
import { Link } from "react-router-dom";
import images from "../../../utils/images";
import { CardPrimary } from "../../../components/UI/Card";
import FormikInput from "../../../components/UI/FormikInput";
import Button from "../../../components/UI/Button";
import {
    PRACTICE_INITIAL_VALUES,
    PRACTICE_VALIDATION_SCHEMA,
    PRACTICE_SCHEMA,
} from "./schema/newPracticeSchema";
import { api } from "../../../APIRequests";
import Label from "../../../components/UI/Label";
import Select from "react-select";
import InputError from "../../../components/UI/InputError";
import _ from "lodash";
import { CustomForm } from "../../../component-library/CustomForm";

class NewPractice extends Component {
    state = {
        intakeProviderOptions: [],
    };
    async componentDidMount() {
        const queryParams = { providerTypes: "BEHAVIORAL_INTAKE" };
        try {
            const response = await api.admin.fetch_providers({ queryParams });
            const intakeProviderOptions = response.map((provider) => {
                return {
                    value: provider["username"],
                    label: provider["name"] + " - " + provider["practice"],
                };
            });
            this.setState({ intakeProviderOptions });
        } catch (e) {
            console.log(e);
        }
    }

    setHcpAndSpmiForPracticeObject = (formData) => {
        let newFormData = _.cloneDeep(formData);
        if ("practice_specialization" in newFormData) {
            const specialization = newFormData.practice_specialization;
            if (specialization.toLowerCase() === "hcp") {
                newFormData.hcp_searchable = true;
                newFormData.is_spmi = false;
            } else if (specialization.toLowerCase() === "spmi") {
                newFormData.hcp_searchable = false;
                newFormData.is_spmi = true;
            } else if (specialization.toLowerCase() === "none" || _.isNil(specialization)) {
                newFormData.hcp_searchable = false;
                newFormData.is_spmi = false;
            }

            delete newFormData.practice_specialization;
        }

        return newFormData;
    };

    submitHandler = async (formData, actions) => {
        actions.setSubmitting(false);
        try {
            if (!formData["fax_number"]) {
                formData["fax_number"] = "None";
            }
            formData["additional_providers"] = [];
            formData["intake_provider_ids"] = formData["intake_provider_ids"]
                ? formData["intake_provider_ids"].map((option) => option.value)
                : null;
            let response = await api.admin.post_practice({
                data: this.setHcpAndSpmiForPracticeObject(formData),
            });
            this.props.history.push("/app/practice");
        } catch (e) {
            console.log(e);
        }
    };

    renderForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 ">
                    <div className="container">
                        <div className="mx-auto px-0 ">
                            <div className="container">
                                <div className="row">
                                    {PRACTICE_SCHEMA.map((formEl) => {
                                        const testId = `NewPracticeInfo_${formEl.name}`;
                                        if (formEl.elementType === "segment") {
                                            return (
                                                <h6 key={formEl.displayName} className="col-12">
                                                    <b>{formEl.displayName}</b>
                                                </h6>
                                            );
                                        } else if (formEl.name === "intake_provider_ids") {
                                            const error = getIn(formikProps.errors, formEl.name);
                                            const touched = getIn(formikProps.touched, formEl.name);
                                            return (
                                                <>
                                                    {formikProps.values["practice_type"] ===
                                                        "Primary Care" && (
                                                        <div
                                                            key={formEl.name}
                                                            className={"col-12 col-lg-6"}>
                                                            <Label>
                                                                {formEl.elementConfig.label}
                                                            </Label>
                                                            <Select
                                                                components={{
                                                                    ClearIndicator: null,
                                                                }}
                                                                isMulti
                                                                options={
                                                                    this.state.intakeProviderOptions
                                                                }
                                                                defaultValue={this.state.intakeProviderOptions.filter(
                                                                    (option) =>
                                                                        formikProps.values[
                                                                            formEl.name
                                                                        ].includes(option.value),
                                                                )}
                                                                className={"basic-multi-select"}
                                                                classNamePrefix="select"
                                                                onChange={async (selectedOptions) =>
                                                                    await formikProps.setFieldValue(
                                                                        formEl.name,
                                                                        selectedOptions
                                                                            ? selectedOptions
                                                                            : [],
                                                                    )
                                                                }
                                                            />
                                                            {error && touched ? (
                                                                <InputError
                                                                    classes={"custom-error"}>
                                                                    {error}
                                                                </InputError>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                </>
                                            );
                                        } else {
                                            return (
                                                <div key={formEl.name} className="col-12 col-lg-6">
                                                    <FormikInput
                                                        formEl={formEl}
                                                        errors={formikProps.errors}
                                                        touched={formikProps.touched}
                                                        testId={testId}
                                                    />
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center justify-content-lg-end">
                    <Button
                        type="button"
                        onClick={() => this.props.history.push("/app/practice")}
                        className="Btn Btn--otl-pri Btn-sm mr-3">
                        Cancel
                    </Button>
                    <Button type="submit" className="Btn Btn--pri Btn-sm">
                        Add Practice
                    </Button>
                </div>
            </Form>
        );
    };

    render() {
        return (
            <div>
                <div className="survey-back-link-wpr mx-auto fs-16 my-3 py-2">
                    <Link className="survey-back-link" to={"/app/practice"}>
                        <img className="img-fluid" src={images("./common/solidBckBtn.svg")} />
                        <p className="d-inline ml-2">Back to Practice List</p>
                    </Link>
                </div>
                <CardPrimary className="mx-auto" style={{ marginTop: 0 }}>
                    <div>
                        <CustomForm
                            initialValues={PRACTICE_INITIAL_VALUES}
                            validationSchema={PRACTICE_VALIDATION_SCHEMA}
                            onSubmit={this.submitHandler}
                            render={this.renderForm}
                        />
                    </div>
                </CardPrimary>
            </div>
        );
    }
}

export default NewPractice;
