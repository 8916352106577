import React, { Component } from "react";
import { connect } from "react-redux";
import { Fragment } from "react";
import { SCQ } from "../../../../containers/Dashboard/Patient/Assessment";
import images from "../../../../utils/images";
import { QuestionV2 } from "../../../Patient/Assessment";
import { COMPLETED_STEP, START_STEP } from "../../../Screening/A5Screening";
import Button from "../../../UI/Button";
import {
    submitQuestion,
    getQuestion,
    getPrevQuestion,
} from "../../../../redux/actions/a5Screening";
import { api } from "../../../../APIRequests";

class AssessmentInProgress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assessmentDetails: this.props.assessmentDetails,
            currentQuestion: this.props.currentQuestion,
            isLastQuestion: this.props.isLastQuestion,
            completionPercentage: this.props.completionPercentage,
            selectedOptions: this.props.selectedOptions ? this.props.selectedOptions : [],
            selectedOptionFull: this.props.selectedOptionFull ? this.props.selectedOptionFull : [],
            lastSelectedOption: this.props.lastSelectedOption,
        };
    }

    workflowState =
        this.props.sharedWorkflowData &&
        this.props.sharedWorkflowData.workflow_instance &&
        this.props.sharedWorkflowData.workflow_instance.state;

    componentDidMount() {
        this.getQuestion({
            entity_id: this.props.sharedWorkflowData.workflow_instance.entity_id,
            entity_type: "workflow",
            assessment_type: this.workflowState.assessment_type,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.isLastQuestion && !this.state.currentQuestion) {
            this.props.nextStepHandler(COMPLETED_STEP);
        }
    }

    handleNextQuestion = (singleChoiceOption = null) => {
        const options = singleChoiceOption
            ? [singleChoiceOption]
            : this.state.currentQuestion.options.filter((el) => {
                  return (
                      this.state.selectedOptions.find((index) => index === el.index) !== undefined
                  );
              });
        let requestBody = {
            id: this.state.assessmentDetails.id,
            question_id: this.state.currentQuestion.question_id,
            options: options,
            entity_type: "workflow",
            assessment_type: this.workflowState.assessment_type,
        };
        this.submitQuestion({
            ...requestBody,
            entity_id: this.props.sharedWorkflowData.workflow_instance.entity_id,
        });
    };

    handlePrevQuestion = () => {
        if (this.state.completionPercentage === 0) {
            this.props.prevStepHandler(START_STEP);
        } else {
            this.getPrevQuestion({
                entity_id: this.props.sharedWorkflowData.workflow_instance.entity_id,
                entity_type: "workflow",
                assessment_type: this.workflowState.assessment_type,
            });
        }
    };

    setSelectedOptions = (selectedOptions, selectedOptionFull) => {
        this.setState({ selectedOptions, selectedOptionFull });
    };

    setAssessmentDetails = (assessmentDetails) => {
        this.setState({ assessmentDetails });
    };

    setNextQuestion = (currentQuestion, isLastQuestion, completionPercentage) => {
        this.setState({
            currentQuestion,
            isLastQuestion,
            completionPercentage,
            selectedOptions: [],
            selectedOptionFull: [],
        });
    };

    optionSelectHandler = (option) => {
        let oldSelectedOptions = this.state.selectedOptions.slice();
        let index = null;
        let foundOption = oldSelectedOptions.find((optionIndex, i) => {
            index = i;
            return optionIndex == option.index;
        });
        let isNoneType = option.type && option.type == "None";
        let isSCQ = this.state.currentQuestion.type == SCQ;
        let isLastSelectedNoneType =
            this.state.lastSelectedOption &&
            this.state.lastSelectedOption.type &&
            this.state.lastSelectedOption.type == "None";

        if (isSCQ || isNoneType || isLastSelectedNoneType) {
            foundOption !== undefined
                ? (oldSelectedOptions = [])
                : (oldSelectedOptions = [option.index]);
        } else {
            foundOption !== undefined
                ? oldSelectedOptions.splice(index, 1)
                : oldSelectedOptions.push(option.index);
        }
        this.setSelectedOptions(oldSelectedOptions, option);
    };

    getQuestion = (data) => {
        const options = { params: { showLoader: true } };
        const headers = {
            dob: this.workflowState.dob,
            "X-Entity-Type": this.workflowState.assessment_type,
        };
        api.workflow.get_next_question({ data, options, headers }).then((response) => {
            let data = response;
            let nextQ = data.question;
            let completionPercentage = data.percentage
                ? Math.ceil(data.percentage)
                : data.percentage;
            let isLast = (nextQ && nextQ.is_last) || false;
            this.setAssessmentDetails(response["question"]);
            this.setNextQuestion(nextQ, isLast, completionPercentage);
        });
    };

    submitQuestion = (data) => {
        const options = { params: { showLoader: true } };
        const headers = {
            dob: this.workflowState.dob,
            "X-Entity-Type": this.workflowState.assessment_type,
        };
        api.workflow.submit_next_question({ data, options, headers }).then((response) => {
            let data = response;
            let nextQ = data.question;
            let completionPercentage = data.percentage
                ? Math.ceil(data.percentage)
                : data.percentage;
            let isLast = (nextQ && nextQ.is_last) || false;
            this.setNextQuestion(nextQ, isLast, completionPercentage);
        });
    };

    getPrevQuestion = (data) => {
        const options = { params: { showLoader: true } };
        const headers = {
            dob: this.workflowState.dob,
            "X-Entity-Type": this.workflowState.assessment_type,
        };
        api.workflow.get_last_question({ data, options, headers }).then((response) => {
            let data = response;
            let nextQ = data.question;
            let completionPercentage = data.percentage
                ? Math.ceil(data.percentage)
                : data.percentage;
            let isLast = (nextQ && nextQ.is_last) || false;
            this.setNextQuestion(nextQ, isLast, completionPercentage);
        });
    };

    render() {
        return (
            <div>
                {this.state.currentQuestion && (
                    <Fragment>
                        <div className="container">
                            <div className="mt-4">
                                {this.state.assessmentDetails && (
                                    <span>
                                        {this.state.assessmentDetails["assessment_type"]} Screening
                                    </span>
                                )}
                            </div>
                            <QuestionV2
                                classes={
                                    "col-12 col-lg-6 px-2 px-lg-2 col-lg-4 d-flex py-2 justify-content-center"
                                }
                                currentQuestion={this.state.currentQuestion}
                                selectOption={(option) => {
                                    if (this.state.currentQuestion.type === "single_choice") {
                                        this.handleNextQuestion(option);
                                    } else {
                                        this.optionSelectHandler(option);
                                    }
                                }}
                                selectedOptions={this.state.selectedOptions}
                                excludeOuterClass={true}
                            />
                            <div className="A5ButtonGroup d-flex h-100 align-items-center justify-content-between">
                                <Button
                                    type="button"
                                    className="Btn Btn--otl-pri mt-4"
                                    onClick={this.handlePrevQuestion}>
                                    Previous
                                </Button>
                                {this.state.currentQuestion.type === "multiple_choice" && (
                                    <Button
                                        type="button"
                                        className="Btn Btn--pri mt-4"
                                        disabled={
                                            this.state.selectedOptions.length === 0
                                                ? "disabled"
                                                : null
                                        }
                                        onClick={() => this.handleNextQuestion()}>
                                        {!this.state.isLastQuestion && "Next"}
                                        {this.state.isLastQuestion && "Finish"}
                                    </Button>
                                )}
                            </div>
                            <div className={"d-flex justify-content-end my-4"}>
                                <div className={"col-lg-4 col-12"} style={{ padding: 0 }}>
                                    <div className={"row"}>
                                        <div className={"col-lg-12 col-10"}>
                                            <div>
                                                <span>
                                                    <b>
                                                        {this.state.completionPercentage}% Completed
                                                    </b>
                                                </span>
                                            </div>
                                            <div className={"ProgressBar"} style={{ height: 6 }}>
                                                <div
                                                    className="ProgressBar__progress"
                                                    style={{
                                                        height: 6,
                                                        transform: `translate3d(${
                                                            this.state.completionPercentage + "%"
                                                        },0,0)`,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className={"d-lg-none col-2"}>
                                            <Button
                                                className={"scroll-up-btn"}
                                                onClick={() => window.scrollTo(0, 0)}>
                                                <img
                                                    className="img-fluid"
                                                    style={{ transform: "rotate(180deg)" }}
                                                    src={images("./common/chevron.svg")}
                                                    alt={"Scroll Up"}
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        );
    }
}

export default AssessmentInProgress;
