// Copyright aptihealth, inc. 2019 All Rights Reserved
/**
 * This component supports only IBAR and OBAR Report view.
 * The "X-" in the component and file name suggests any of the following : "I-", "O-".
 * The view in both type of report looks same.
 * Hence the same logic and fuctionality is used.
 * However if in future, designs in both deviate from each other
 * then introduce new ui elements in this component and
 * show/hide them based on `reportType` received in `props`.
 *
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { setDynamicRouteConfiguration } from "../../../redux/actions/navbar";
import { api } from "../../../APIRequests";
import _uniqueId from "lodash/uniqueId";

import XBARReportHeader from "./X-BARReportHeader";
import XBARReportCommentBox from "./X-BARReportCommentBox";
import XBARReportMain from "./X-BARReportMain";
import XBARReportSidebar from "./X-BARReportSidebar";
import images from "../../../utils/images";
import "../../UI/BackLink/styles.scss";
import { getQueryParams } from "../../../utils/filters";

/**------------------------------------ */
/**
 * Report types to pass as query params
 * Saving them to constants to reuse.
 */
const OBAR = "obar";
const IBAR = "ibar";
/**------------------------------------ */

class X_BARReportView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportData: null,
            sidebarData: null,
            showCommentBox: false,
            ReportComment: null,
            commentError: null,
        };
        this.mainSectionRef = React.createRef();
    }

    /**
     * Here we are creating a scrollable behavior on cliking of sidebar link.
     * When link is clicked, the `data-scroll-target` attribute is fetched and the
     * corresponding section on right shall be scrolled to the top of
     * scrollable div
     */
    onDocumentClick = (e) => {
        const target = this.getEventTarget(e);
        const clickedScrollLink = target.closest(".SidebarCollapsibleListItem__scroll-target");

        if (clickedScrollLink) {
            const mainSection = this.mainSectionRef.current;
            const targetedScroll = mainSection.querySelector(
                ".ReportsMainCollapse__content.show .ReportsMainCollapse__content-scrollable",
            );
            const targetedSubSectionId = clickedScrollLink.getAttribute("data-scroll-target");
            const targetedSubSection = targetedScroll.querySelector(`#${targetedSubSectionId}`);
            const topOffset = targetedSubSection.offsetTop;
            // subtracting a bit of pixels so as to not graze the scrollable window's top;
            targetedScroll.scrollTo(0, topOffset - 10);
        }
    };

    /** Returning event target in cross browser fashion */
    getEventTarget = (e) => {
        e = e || window.event;
        return e.target || e.srcElement;
    };

    componentDidMount() {
        const dynamicRouteConfig = {};

        dynamicRouteConfig["menuSection"] = {
            icon: "./icons/back-arrow.svg",
            link: "/app/reports",
        };
        dynamicRouteConfig["logoSection"] = { title: "Report Details" };
        setDynamicRouteConfiguration(dynamicRouteConfig);

        /** adding click event listener to document */
        document.addEventListener("click", this.onDocumentClick);
        this.fetchReport();
    }

    componentWillUnmount() {
        setDynamicRouteConfiguration({});

        document.removeEventListener("click", this.onDocumentClick);
    }

    /**
     * Async API call to fetch IBAR/OBAR reports data.
     */
    fetchReport = async () => {
        const { reportType, patientId } = this.props;
        let urlParams = { patientId };
        let queryParams = { type: reportType };
        let reports = await api.shared.fetch_report({ urlParams, queryParams });
        let sections = reports.report_data.sections;
        /**
         *  Here we choose to mutate coz at this point no UI rendering logic
         *  is run and once this function block exits then no mutation will be caused again.
         *  Hence safe to mutate.
         */
        mutateSectionsWithUid(sections, reportType);
        let sidebarMappingConf = this.configureSidebarMappedNavigation(sections);
        /** saving fetched report in state */
        this.saveFetchedReportInState(reports, sidebarMappingConf);
    };

    configureSidebarMappedNavigation = (sections) => {
        let sidebarMappedReports = sections.map((section) => {
            let subSections = section.sections;
            let modifiedSubSections = subSections.map((item) => {
                return { title: item.title, uid: item.uid };
            });
            return {
                title: section.title,
                uid: section.uid,
                sections: modifiedSubSections,
            };
        });
        return sidebarMappedReports;
    };

    saveFetchedReportInState = (reportData, sidebarData) => {
        this.setState({ reportData, sidebarData });
    };

    toggleCommentBox = () => {
        this.setState((ps) => {
            return {
                showCommentBox: !ps.showCommentBox,
                ReportComment: null,
                commentError: null,
            };
        });
    };

    submitComment = (e) => {
        e.preventDefault();
        let urlParams = { patientId: this.props.patientId };
        let data = {
            report_type: this.props.reportType.toUpperCase(),
            comment: this.state.ReportComment,
        };

        api.provider
            .set_provider_comment({ data, urlParams })
            .then((cleanResponse) => {})
            .catch((err) => {
                console.log(err);
            });
        this.toggleCommentBox();
    };

    handleCommentChange = (e) => {
        const value = e.target.value;
        this.setState(
            {
                ReportComment: value,
            },
            this.validateComment,
        );
    };

    handleCommentBlur = () => {
        this.validateComment();
    };

    validateComment = () => {
        let value = this.state.ReportComment;
        if (!value || !value.trim()) {
            this.setState({
                commentError: "Comment is required",
            });
            return;
        }
        this.setState({
            commentError: null,
        });
    };

    render() {
        // destructuring props;
        const { reportType } = this.props;
        // destructuring state
        const { sidebarData, reportData } = this.state;

        const reportClass = `${reportType}Report`;
        const sidebarAccordionId = `${reportType}-SidebarAccordian`;
        const mainAccordionId = `${reportType}-MainAccordion`;

        let backLinkText = "Back To Reports";
        if (getQueryParams("patient-id", this.props.location.search)) {
            backLinkText = "Back To Patient Profile";
        }

        let content = (
            <div className={`${reportClass} container py-5`}>
                {/* Report Header */}
                <header className={`${reportClass}__Header`}>
                    <div className="d-none d-lg-block mb-4">
                        <div
                            onClick={() => this.props.history.goBack()}
                            className="BackLink cursor">
                            <img src={images("./icons/solidBckBtn.svg")} alt="back btn" />
                            <span>{backLinkText}</span>
                        </div>
                    </div>
                    {reportData && (
                        <XBARReportHeader
                            toggleCommentBox={this.toggleCommentBox}
                            reportData={reportData}
                        />
                    )}
                </header>
                {/* Report comment box */}
                <section className="mt-3">
                    {this.state.showCommentBox && (
                        <XBARReportCommentBox
                            toggle={this.toggleCommentBox}
                            submit={this.submitComment}
                            ReportComment={this.state.ReportComment}
                            handleChange={this.handleCommentChange}
                            handleBlur={this.handleCommentBlur}
                            error={this.state.commentError}
                        />
                    )}
                </section>
                {/* Report body */}
                <section className="row no-gutters mt-4">
                    {/* Report sidebar (only visible on desktop) */}
                    <aside className="col-3 pr-3 d-none d-lg-block">
                        {reportData && (
                            <XBARReportSidebar
                                sidebarData={sidebarData}
                                reportType={reportType}
                                accordionId={sidebarAccordionId}
                            />
                        )}
                    </aside>
                    {/* Reports main collapsible content */}
                    <main ref={this.mainSectionRef} className="col-12 col-lg-9">
                        {reportData && (
                            <XBARReportMain reportData={reportData} accordionId={mainAccordionId} />
                        )}
                    </main>
                </section>
            </div>
        );

        return this.state.reportData && content;
    }
}

export default connect(null, {
    setDynamicRouteConfiguration,
})(X_BARReportView);

/**
 * Mutates the original `sections` array
 * with the `uid` key in objects items.
 * Also it recursively mutates sub-sections
 * array objects with `uid`.
 *
 * @param {Object[]} sections - Array of objects containing report content
 * @param reportType
 */
export const mutateSectionsWithUid = (sections, reportType) => {
    sections.forEach((section) => {
        section["uid"] = _uniqueId(`uid-${reportType}-`);
        if (section.sections && Array.isArray(section.sections) && section.sections.length > 0) {
            /** Recursive call if sub-section array found */
            mutateSectionsWithUid(section.sections, reportType);
        }
    });
};
