// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { useEffect, useState } from "react";
import "./../styles.scss";
import Button from "../../../UI/Button";
import images from "../../../../utils/images";
import { Form, Formik, getIn } from "formik";
import FormikInput from "../../../UI/FormikInput";
import * as yup from "yup";
import { connect } from "react-redux";
import moment from "moment";
import withRouter from "react-router-dom/es/withRouter";
import { RatingOptions } from "../../../Common/GuidedVideoCall/Steps/QuestionsStep";
import Label from "../../../UI/Label";
import { cloneDeep } from "lodash";
import InputError from "../../../UI/InputError";
import DatePicker from "../../../UI/DatePicker";
import {
    _11_20_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98967,
    _21_30_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98968,
    _30_MIN_INDIVIDUAL_90832,
    _45_MIN_INDIVIDUAL_90834,
    _5_10_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98966,
    allottedTimeFactory,
    CRISIS_EVAL_90839,
    timeSlotArray,
} from "../../ScheduleAvailability/constants";
import { transformTimeSlotArray } from "../../ScheduleAvailability/ScheduleEvent";
import { api } from "../../../../APIRequests";
import Select from "react-select";
import DischargeContent from "./DischargeContent";
import { transformNoteTitleBasedOnType } from "./MemberProfileNotes";
import { CustomForm } from "../../../../component-library/CustomForm";

const CheckInPopUp = (props) => {
    const { closeHandler, updateState, notes, patientDiagnosis } = props;
    const [isSigned, setIsSigned] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);
    //TODO Removed query param dependency, but component appears deprecated so not re-sourcing patientName
    const patientNameParts = ["", ""];
    const [dischargeValues, setDischargeValues] = useState([]);
    const [icd10Axis1, setIcd10Axis1] = useState([]);
    const [icd10Axis2, setIcd10Axis2] = useState([]);

    const CHECK_IN_INITIAL_VALUES = {
        patient_id: props.match.params.patientId,
        patient_first_name: patientNameParts[0],
        patient_last_name: patientNameParts[1],
        provider_id: props.profile.username,
        provider_type: props.profile.provider_type,
        signature: props.profile?.name,
        provider_cp_credentials: props.profile.cp_credentials,
        session_from: "12:00",
        session_to: "12:30",
        session_date: moment().format("MM/DD/YYYY"),
        call_type: "",
        version: 3,
        note_type: "Check-in",
        content: {
            call_purpose: "",
            comment: "",
            call_outcome: "",
            plan: "",
            patient_outcome_answer: "",
            call_focus: { ...CALL_FOCUS_OPTIONS },
            outbound_inbound: "",
            discharges: "",
        },
        ICD_10_axis_1_diagnosis: patientDiagnosis ? patientDiagnosis.ICD_10_axis_1_diagnosis : [],
        ICD_10_axis_2_diagnosis: patientDiagnosis ? patientDiagnosis.ICD_10_axis_2_diagnosis : [],
    };

    useEffect(function () {
        (async () => {
            const response = await api.provider.fetch_ICD10_dataset();
            setIcd10Axis1(response.filter((icd) => icd.axis === "1"));
            setIcd10Axis2(response.filter((icd) => icd.axis === "2"));
        })();
    }, []);

    const submitHandler = async (formData, actions) => {
        formData["content"]["patient_outcome_label"] =
            RATING_OPTION_LABEL_MAP[formData["content"]["patient_outcome_answer"]];
        formData["title"] = transformNoteTitleBasedOnType(formData);
        formData["session_to"] = moment(formData["session_from"], "HH:mm")
            .add(allottedTimeFactory(formData["call_type"]), "minutes")
            .format("HH:mm");
        formData["content"]["discharges"] = dischargeValues;

        const urlParams = { patientId: props.match.params.patientId };
        const note = await api.provider.post_progress_note({ urlParams, data: formData });
        const noteData = [note, ...notes];
        const newState = { noteData };
        if (formData["content"]["call_outcome"] !== "Attempted") {
            newState["patientDiagnosis"] = {
                ICD_10_axis_1_diagnosis: formData["ICD_10_axis_1_diagnosis"],
                ICD_10_axis_2_diagnosis: formData["ICD_10_axis_2_diagnosis"],
            };
        }
        updateState(newState);
        closeHandler();
    };

    const ratingAnswerOnClick = async (questionSet, questionId, answer, formikProps) => {
        await formikProps.setFieldValue("content.patient_outcome_answer", answer);
        formikProps.setFieldTouched("content.patient_outcome_answer");
    };

    const checkBoxHandler = async (existingCallFocus, optionKey, formikProps) => {
        let newCallFocus = cloneDeep(existingCallFocus);
        newCallFocus[optionKey] = !newCallFocus[optionKey];
        await formikProps.setFieldValue("content.call_focus", newCallFocus);
        formikProps.setFieldTouched("content.call_focus");
    };

    async function signatureClickHandler(formikProps) {
        const newIsSigned = !isSigned;
        setIsSigned(newIsSigned);
        if (newIsSigned) {
            await formikProps.setFieldValue("signature", props.profile.name);
            formikProps.setFieldTouched("signature");
        } else {
            await formikProps.setFieldValue("signature", "");
        }
    }

    const renderForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 mb-3">
                    <div className="container px-0">
                        <div className="row">
                            {showDatePicker && (
                                <div className="PrScheduleAvailability__DatePicker-wpr-absolute NoteDatePicker">
                                    <div className={"CheckInDatePickerWrapper"}>
                                        <DatePicker
                                            externalCtx={moment(
                                                formikProps && formikProps.values.session_date,
                                                "MM/DD/YYYY",
                                            )}
                                            onDaySelect={(date, eventType) => {
                                                const parts = date.split("-");
                                                formikProps.values.session_date =
                                                    parts[1] + "/" + parts[2] + "/" + parts[0];
                                                if (eventType !== "monthChange") {
                                                    setShowDatePicker(false);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {CHECK_IN_SCHEMA.map((formEl) => {
                                const error = getIn(formikProps.errors, formEl.name);
                                const touched = getIn(formikProps.touched, formEl.name);
                                if (formEl.name === "content.patient_outcome_answer") {
                                    return (
                                        <>
                                            {formikProps.values.content.call_outcome ===
                                                "Completed" && (
                                                <div
                                                    key={formEl.name}
                                                    className={formEl.elementClasses}>
                                                    <Label>{formEl.elementConfig.label}</Label>
                                                    {getPatientOutcomeRatings(
                                                        RATING_OPTION_LABEL_MAP,
                                                        formikProps.values.content
                                                            .patient_outcome_answer,
                                                        ratingAnswerOnClick,
                                                        false,
                                                        formikProps,
                                                    )}
                                                    {error && touched ? (
                                                        <div
                                                            className={"col-12 p-0"}
                                                            style={{
                                                                marginTop: -15,
                                                                marginLeft: 5,
                                                            }}>
                                                            <InputError
                                                                style={{ position: "relative" }}
                                                                classes={"custom-error"}>
                                                                {error}
                                                            </InputError>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            )}
                                        </>
                                    );
                                } else if (formEl.name === "session_date") {
                                    return (
                                        <div key={formEl.name} className="col-12 col-lg-4">
                                            <div>
                                                <label className={"Label"}>Call Date</label>
                                            </div>
                                            <div className={"CustomSelect__wpr d-flex flex-column"}>
                                                <button
                                                    type="button"
                                                    className={
                                                        "CustomSelect__btn cursor d-flex align-items-center text-left"
                                                    }
                                                    onClick={() => setShowDatePicker(true)}>
                                                    <span>
                                                        {formikProps &&
                                                            formikProps.values.session_date}
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    );
                                } else if (formEl.name === "content.call_focus") {
                                    return (
                                        <>
                                            {formikProps.values.content.call_outcome ===
                                                "Completed" && (
                                                <div
                                                    key={formEl.name}
                                                    className={formEl.elementClasses}>
                                                    <Label>{formEl.elementConfig.label}</Label>
                                                    <div className={"row mx-1 mb-3"}>
                                                        {getCallFocusCheckboxes(
                                                            formikProps.values.content.call_focus,
                                                            checkBoxHandler,
                                                            false,
                                                            formikProps,
                                                        )}
                                                        {error && touched ? (
                                                            <div className={"col-12 p-0"}>
                                                                <InputError
                                                                    style={{ position: "relative" }}
                                                                    classes={"custom-error"}>
                                                                    {error}
                                                                </InputError>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    );
                                } else if (formEl.elementType === "discharges") {
                                    return (
                                        <DischargeContent
                                            editable
                                            dischargeValues={dischargeValues}
                                            setDischargeValues={setDischargeValues}
                                        />
                                    );
                                } else if (formEl.name.includes("ICD_10_axis")) {
                                    return (
                                        <div key={formEl.name} className={"col-12 mb-3"}>
                                            <Label>{formEl.elementConfig.label}</Label>
                                            <Select
                                                components={{
                                                    ClearIndicator: null,
                                                }}
                                                isMulti
                                                options={
                                                    formEl.name === "ICD_10_axis_1_diagnosis"
                                                        ? icd10Axis1
                                                        : icd10Axis2
                                                }
                                                defaultValue={formikProps.values[formEl.name]}
                                                className={"basic-multi-select"}
                                                classNamePrefix="select"
                                                onChange={async (selectedOptions) =>
                                                    await formikProps.setFieldValue(
                                                        formEl.name,
                                                        selectedOptions,
                                                    )
                                                }
                                            />
                                            {error && touched ? (
                                                <InputError classes={"custom-error"}>
                                                    {error}
                                                </InputError>
                                            ) : null}
                                        </div>
                                    );
                                } else {
                                    if (formEl.name === "signature") {
                                        formEl.onchange = formEl.onclick = () =>
                                            signatureClickHandler(formikProps);
                                    }
                                    return (
                                        <div key={formEl.name} className={formEl.elementClasses}>
                                            <FormikInput
                                                formEl={formEl}
                                                disabled={
                                                    formEl.name === "provider_cp_credentials"
                                                        ? "disabled"
                                                        : null
                                                }
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                            />
                                            {formEl.name === "signature" && (
                                                <div
                                                    className="w-100 fs-24 border-bottom"
                                                    style={{
                                                        fontFamily: "Apple Chancery, cursive",
                                                    }}>
                                                    {"X " + (isSigned ? props.profile.name : "")}
                                                </div>
                                            )}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>
                <div className="d-lg-flex d-block justify-content-center text-center justify-content-lg-end">
                    <Button type="submit" className="Btn Btn--pri Btn-sm">
                        Save Check-in
                    </Button>
                </div>
            </Form>
        );
    };

    return (
        <>
            <div className="check-in-popup-outer">
                <div className="check-in-popup-inner p-4">
                    <div className={"d-flex justify-content-between"}>
                        <h5 className="fw-bold txt-sec text-left fs-17 pb-4 m-0">Check-in</h5>
                        <div className="close-button" onClick={closeHandler}>
                            <img src={images("./icons/cross.svg")} />
                        </div>
                    </div>
                    <CustomForm
                        initialValues={CHECK_IN_INITIAL_VALUES}
                        validationSchema={CHECK_IN_VALIDATION}
                        onSubmit={submitHandler}
                        render={renderForm}
                    />
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};
export default connect(mapStateToProps)(withRouter(CheckInPopUp));

export const getCallFocusCheckboxes = (
    options,
    checkBoxHandler,
    isDisabled = false,
    formikProps = {},
) => {
    return (
        <>
            {Object.entries(options)
                .sort()
                .map(([optionKey, optionValue]) => {
                    return (
                        <div
                            className={"custom-control custom-checkbox col-12 col-lg-4"}
                            key={optionKey}>
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id={optionKey}
                                name={optionKey}
                                disabled={isDisabled ? "disabled" : null}
                                checked={options[optionKey] ? optionValue : null}
                                onClick={() => {
                                    checkBoxHandler(options, optionKey, formikProps);
                                }}
                            />
                            <label className="custom-control-label" htmlFor={optionKey}>
                                <span>{optionKey}</span>
                            </label>
                        </div>
                    );
                })}
        </>
    );
};

export const getPatientOutcomeRatings = (
    ratingOptionLabelMap,
    answer,
    ratingAnswerOnClick,
    isDisabled = false,
    formikProps = {},
) => {
    return (
        <>
            <div className={"d-flex justify-content-between"}>
                {Object.values(ratingOptionLabelMap).map((optionLabel) => (
                    <div className={"w-100 text-center fw-bold fs-12"}>{optionLabel}</div>
                ))}
            </div>
            <RatingOptions
                optionLabelMap={ratingOptionLabelMap}
                questionSet={"patient_outcome"}
                questionId={"patient_outcome"}
                questionData={{ answer: answer }}
                ratingAnswerOnClick={(questionSet, questionId, optionId) =>
                    ratingAnswerOnClick(questionSet, questionId, optionId, formikProps)
                }
                optionWrapperStyle={{ margin: "0px 5px" }}
                optionStyle={{ height: 15 }}
                disabled={isDisabled}
            />
        </>
    );
};

export const RATING_OPTION_LABEL_MAP = {
    0: "Much Worse",
    1: "Slightly Worse",
    2: "Same",
    3: "Slightly Better",
    4: "Much Better",
};

export const CALL_FOCUS_OPTIONS = {
    "Appointment Reminder": false,
    Medication: false,
    SUD: false,
    "Suicide Ideations/Crisis": false,
    "Symptom Management": false,
    Medical: false,
    SDoH: false,
    Other: false,
};

export const CHECK_IN_SCHEMA = [
    {
        name: "content.outbound_inbound",
        elementType: "select",
        elementClasses: "col-12 col-lg-4",
        elementConfig: {
            options: [
                { value: "Outbound", display: "Outbound" },
                { value: "Incoming", display: "Incoming" },
            ],
            label: "Outbound or Incoming",
            placeholder: "Select",
        },
    },
    {
        name: "session_date",
        elementType: "DatePicker",
        elementClasses: "col-12 col-lg-3",
        elementConfig: {
            type: "text",
            label: "Call Date",
            placeholder: moment().format("MM/DD/YYYY"),
        },
    },
    {
        name: "session_from",
        elementType: "select",
        elementClasses: "col-12 col-lg-3",
        elementConfig: {
            sentenceCase: false,
            options: transformTimeSlotArray(timeSlotArray),
            label: "Call Started At",
            placeholder: "12:00 pm",
        },
    },
    {
        name: "call_type",
        elementType: "select",
        elementClasses: "col-12 col-lg-7",
        elementConfig: {
            options: [
                {
                    value: _30_MIN_INDIVIDUAL_90832,
                    display: `30 Minute Individual (90832) (${allottedTimeFactory(
                        _30_MIN_INDIVIDUAL_90832,
                    )} minutes)`,
                },
                {
                    value: _45_MIN_INDIVIDUAL_90834,
                    display: `45 Minute Individual (90834) (${allottedTimeFactory(
                        _45_MIN_INDIVIDUAL_90834,
                    )} minutes)`,
                },
                {
                    value: CRISIS_EVAL_90839,
                    display: `Crisis Evaluation (90839) (${allottedTimeFactory(
                        CRISIS_EVAL_90839,
                    )} minutes)`,
                },
                {
                    value: _5_10_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98966,
                    display: `5-10 Min Phone Assmt. and Mgmt. (98966) (${allottedTimeFactory(
                        _5_10_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98966,
                    )} minutes)`,
                },
                {
                    value: _11_20_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98967,
                    display: `11-20 Min Phone Assmt. and Mgmt. (98967) (${allottedTimeFactory(
                        _11_20_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98967,
                    )} minutes)`,
                },
                {
                    value: _21_30_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98968,
                    display: `21-30 Min Phone Assmt. and Mgmt. (98968) (${allottedTimeFactory(
                        _21_30_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98968,
                    )} minutes)`,
                },
            ],
            label: "Call CPT Code Linking",
            placeholder: "Select",
        },
    },
    {
        name: "content.call_purpose",
        elementType: "select",
        elementClasses: "col-12 col-lg-5",
        elementConfig: {
            options: [
                { value: "Daily Check-in", display: "Daily Check-in" },
                { value: "Respond to call", display: "Respond to call" },
            ],
            label: "Purpose of Call",
            placeholder: "Select",
        },
    },
    {
        name: "content.comment",
        elementType: "textarea",
        elementClasses: "col-12",
        elementConfig: {
            type: "textArea",
            label: "Comment",
            placeholder: "Type comment...",
        },
    },
    {
        name: "content.call_outcome",
        elementType: "select",
        elementClasses: "col-12 col-lg-4",
        elementConfig: {
            options: [
                { value: "Attempted", display: "Attempted" },
                { value: "Completed", display: "Completed" },
            ],
            label: "Call Outcome",
            placeholder: "Select",
        },
    },
    {
        name: "content.plan",
        elementType: "select",
        elementClasses: "col-12 col-lg-4",
        elementConfig: {
            options: [
                { value: "Call Tomorrow", display: "Call Tomorrow" },
                { value: "Prescriber Consult", display: "Prescriber Consult" },
                { value: "PCP Consult", display: "PCP Consult" },
                { value: "Other", display: "Other" },
            ],
            label: "Plan",
            placeholder: "Select",
        },
    },
    {
        name: "content.call_focus",
        elementType: "input",
        elementClasses: "col-12",
        elementConfig: {
            type: "checkbox",
            options: CALL_FOCUS_OPTIONS,
            label: "Call Focus",
            placeholder: "",
        },
    },
    {
        name: "content.patient_outcome_answer",
        elementClasses: "col-12 col-lg-8",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Patient Outcome",
            placeholder: "",
        },
    },
    {
        name: "content.discharges",
        elementType: "discharges",
        elementConfig: {
            label: "",
            placeholder: "",
        },
    },
    {
        name: "ICD_10_axis_1_diagnosis",
        elementClasses: "col-12 col-lg-6",
        elementType: "reactselect",
        elementConfig: {
            options: [],
            label: "ICD-10 Primary Diagnosis",
            placeholder: "",
        },
    },
    {
        name: "ICD_10_axis_2_diagnosis",
        elementClasses: "col-12 col-lg-6",
        elementType: "reactselect",
        elementConfig: {
            options: [],
            label: "ICD-10 Secondary Diagnosis",
            placeholder: "",
        },
    },
    {
        name: "signature",
        elementType: "input",
        elementClasses: "col-12 col-lg-7",
        elementConfig: {
            hideTopLabel: true,
            displayName: "Click to Sign",
            type: "checkbox",
            label: "Signature",
            classNames: "font-weight-bold",
        },
    },
    {
        name: "provider_cp_credentials",
        elementType: "input",
        elementClasses: "col-12 col-lg-3",
        elementConfig: {
            type: "text",
            label: "Credentials",
            placeholder: "",
        },
    },
];

const CHECK_IN_VALIDATION = yup.object().shape({
    session_date: yup.string().required("Required"),
    session_from: yup.string().required("Required"),
    call_type: yup.string().required("Required"),
    signature: yup.string().required("Required"),
    provider_cp_credentials: yup.string().required("Required"),
    content: yup.object().shape({
        outbound_inbound: yup.string().required("Required"),
        call_purpose: yup.string().required("Required"),
        call_outcome: yup.string().required("Required"),
        plan: yup.string().required("Required"),
        patient_outcome_answer: yup.string().when("call_outcome", {
            is: (call_outcome) => call_outcome === "Completed",
            then: yup.string().required("Required"),
        }),
        call_focus: yup.object().when("call_outcome", {
            is: (call_outcome) => call_outcome === "Completed",
            then: yup
                .object({
                    "Appointment Reminder": yup.boolean(),
                    Medication: yup.boolean(),
                    SUD: yup.boolean(),
                    "Suicide Ideations/Crisis": yup.boolean(),
                    "Symptom Management": yup.boolean(),
                    Medical: yup.boolean(),
                    SDoH: yup.boolean(),
                    Other: yup.boolean(),
                })
                .test("call_focus_test", null, (obj) => {
                    if (Object.values(obj).find((optionValue) => optionValue)) {
                        return true;
                    }
                    return new yup.ValidationError("Required", null, "content.call_focus");
                }),
        }),
    }),
    ICD_10_axis_1_diagnosis: yup
        .string()
        .nullable()
        .when("content.call_outcome", {
            is: (call_outcome) => call_outcome === "Completed",
            then: yup.string().required("Required"),
        }),
});

export const enrichNoteWithPreviousDiagnosis = (previousNotes, currentNote) => {
    if (previousNotes) {
        let filteredNotes = previousNotes.filter(
            (note) =>
                (note.ICD_10_axis_1_diagnosis && note.ICD_10_axis_1_diagnosis.length > 0) ||
                (note.ICD_10_axis_2_diagnosis && note.ICD_10_axis_2_diagnosis.length > 0),
        );
        if (filteredNotes.length > 0) {
            let lastNote = filteredNotes[0];
            currentNote["ICD_10_axis_1_diagnosis"] = lastNote.ICD_10_axis_1_diagnosis;
            currentNote["ICD_10_axis_2_diagnosis"] = lastNote.ICD_10_axis_2_diagnosis;
        }
    }
};
