// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";

const CardHeading = (props) => {
    let classes = "Heading Heading--card  text-dark fw-bold";
    if (props.className) {
        classes = "Heading Heading--card fw-bold" + " " + props.className;
    }
    return <h3 className={classes}>{props.text}</h3>;
};

export default CardHeading;
