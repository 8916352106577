// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Fragment, useState } from "react";
import FormikInput from "../UI/FormikInput";
import Button from "../UI/Button";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import images from "../../utils/images";
import CardSecondary from "../../components/UI/Card/CardSecondary";
import CardHeading from "../../components/UI/Headings/CardHeading";
import { connect } from "react-redux";
import { changePassword, logOut } from "../../redux/actions/auth";
import { yup_password_factory } from "../../utils/yupSchemaUtils";
import { CustomForm } from "../../component-library/CustomForm";
import { getAutomationDataAttr } from "../../utils/automationUtils";

const ChangePassword = (props) => {
    const { baseRoute } = props;
    const [popUp, showPopup] = useState(false);

    const changePassword = (form, actions) => {
        let formCopy = Object.assign({}, form);
        delete formCopy.confirmNewPassword;
        props.changePassword(formCopy, (success) => {
            actions.setSubmitting(false);
            if (success) {
                actions.resetForm();
                showPopup((prevState) => {
                    return true;
                });
            }
        });
    };
    const logOut = () => {
        props.logOut({ history: props.history });
    };

    const forgotPassword = () => {
        const redirect = "/auth/forgot-password";
        const useRedirectScreen = true;
        props.logOut({
            redirectPath: redirect,
            useRedirectScreen,
            history: props.history,
        });
    };

    const validationSchema = Yup.object().shape({
        current_password: yup_password_factory("Current Password is required"),
        new_password: yup_password_factory(),
        confirmNewPassword: Yup.string()
            .required("Confirm password is required")
            .oneOf([Yup.ref("new_password"), null], "Password does not match"),
    });
    //Add Old Password New Password and Confirm new Password

    return (
        <Fragment>
            {popUp && (
                <div className="popup" {...getAutomationDataAttr("ChangePasswordSuccessPopUp")}>
                    <div className="container">
                        <div className="popup-card px-3 py-5 px-lg-5 text-center">
                            <div className="d-flex justify-content-center mb-4">
                                <img
                                    className="img-fluid"
                                    src={images("./common/successCheck.svg")}
                                />
                            </div>
                            <p className="fw-bold">Password Change Successfully!</p>
                            <p className="my-3">
                                Your password has been reset successfully. Please login with your
                                new password
                            </p>
                            <Button
                                className="Btn Btn--pri Btn-sm"
                                onClick={logOut}
                                {...getAutomationDataAttr("ChangePasswordSuccessOkButton")}>
                                OK
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            <div className="container px-3">
                <h1 className="d-none d-lg-block fs-22 fw-bold my-4 py-2">Password</h1>
                <CustomForm
                    validationSchema={validationSchema}
                    onSubmit={changePassword}
                    initialValues={{
                        current_password: "",
                        new_password: "",
                        confirmNewPassword: "",
                    }}
                    render={(formikProps) => (
                        <form onSubmit={formikProps.handleSubmit}>
                            <CardSecondary className="PatientProfile__ProfileDetailsCard p-3 p-lg-3 mb-3 mb-lg-4">
                                <CardHeading
                                    text="Change Password"
                                    className="patient-profile-purple fs-16 pt-2 mb-0 d-none d-lg-block"
                                />
                                <hr className="m-0 d-none d-md-block my-1" />
                                <div className="row pt-3 pt-lg-0">
                                    <div className="col-12 col-lg-6">
                                        <FormikInput
                                            errors={formikProps.errors}
                                            touched={formikProps.touched}
                                            formEl={{
                                                name: "current_password",
                                                elementType: "input",
                                                elementConfig: {
                                                    type: "password",
                                                    label: "Current Password",
                                                    placeholder: "Enter Current Password",
                                                },
                                            }}
                                            testId={"ChangePasswordCurrentPassword"}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <FormikInput
                                            errors={formikProps.errors}
                                            touched={formikProps.touched}
                                            formEl={{
                                                name: "new_password",
                                                elementType: "input",
                                                elementConfig: {
                                                    type: "password",
                                                    label: "New Password",
                                                    placeholder: "Enter New Password",
                                                },
                                            }}
                                            testId={"ChangePasswordNewPassword"}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <FormikInput
                                            errors={formikProps.errors}
                                            touched={formikProps.touched}
                                            formEl={{
                                                name: "confirmNewPassword",
                                                elementType: "input",
                                                elementConfig: {
                                                    type: "password",
                                                    label: "Confirm New Password",
                                                    placeholder: "Confirm New Password",
                                                },
                                            }}
                                            testId={"ChangePasswordConfirmNewPassword"}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <Button
                                            onClick={forgotPassword}
                                            className="Btn Btn-link txt-sec"
                                            style={{ textDecoration: "none", margin: 0 }}>
                                            Forgot Password?
                                        </Button>
                                    </div>
                                </div>
                                <div className="justify-content-end d-none d-md-flex pb-2">
                                    <Link to={baseRoute + "/settings"}>
                                        <Button className="Btn Btn--otl-pri mx-3">Cancel</Button>
                                    </Link>
                                    <Button
                                        disabled={formikProps.isSubmitting}
                                        type="submit"
                                        className="Btn Btn--pri mx-3"
                                        {...getAutomationDataAttr("SubmitChangePasswordButton")}>
                                        Confirm
                                    </Button>
                                </div>
                            </CardSecondary>
                            <div className="ChangePassword-Btn-Wpr text-center d-flex justify-content-between d-block d-md-none mt-4">
                                <Link to={baseRoute + "/settings"}>
                                    <Button className="Btn Btn--otl-pri">Cancel</Button>
                                </Link>
                                <div>
                                    <Button
                                        disabled={formikProps.isSubmitting}
                                        type="submit"
                                        className="Btn Btn--pri">
                                        Confirm
                                    </Button>
                                </div>
                            </div>
                        </form>
                    )}
                />
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps, { changePassword, logOut })(ChangePassword);
