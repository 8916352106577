// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as yup from "yup";

export const CARE_MANAGER_VALIDATION_SCHEMA = yup.object().shape({
    name: yup.string().required("Name is required"),
    provider_type: yup.string().required("Provider Type is required"),
    email: yup.string().email("Enter a valid email address").required("Email is required"),
    mobile: yup
        .string()
        .matches(/^\d{10}$/, "Contact Number must be a valid US number")
        .required("Mobile no. is required"),
    cp_credentials: yup.string().required("CP Credential is required"),
    npi_number: yup.string().matches(/^\d{10}$/, "NPI number must be 10 digits long"),
    practice: yup.string().required("Practice is required"),
});

export const VALIDATION_SCHEMA = yup.object().shape({
    name: yup.string().required("Name is required"),
    provider_type: yup.string().required("Provider Type is required"),
    email: yup.string().email("Enter a valid email address").required("Email is required"),
    mobile: yup
        .string()
        .matches(/^\d{10}$/, "Contact Number must be a valid US number")
        .required("Mobile no. is required"),
    cp_credentials: yup.string().required("CP Credential is required"),
    npi_number: yup
        .string()
        .matches(/^\d{10}$/, "NPI number must be 10 digits long")
        .required("NPI Number is required"),
    practice: yup.string().required("Practice is required"),
});
