// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import images from "../../../utils/images";
import "./styles.scss";
import { Button, CustomForm, Text, TextColors, TextTypes } from "../../../component-library";
import Hr from "../../UI/Hr";
import { api } from "../../../APIRequests";
import { Form } from "formik";
import {
    CAN_NO_LONGER_SCHEDULE_WITH_ROLES,
    ESTABLISHED_PATIENT_REASONS,
    NON_DI_PATIENT_REASONS,
    PATIENT_OPTIONS,
    WAIT_LIST_INITIAL_VALUES,
    WAIT_LIST_VALIDATION,
} from "./WaitlistFormScheme";
import { WaitlistFormRadioField } from "./WaitlistFormRadioField";
import { WaitlistFormCheckbox } from "./WaitlistFormCheckbox";
import { WaitlistFormToggle } from "./WaitlistFormToggle";
import { usePatient } from "../../../utils/hooks/usePatient";
import { WaitlistFormField } from "./WaitlistFormField";

// NOTE: THIS PATTERN WAS COPIED FROM the care management form
export const WaitlistForm = () => {
    const history = useHistory();
    const { patientId } = useParams();

    const patientDetails = usePatient(patientId);

    const preventEnterFormSubmission = (event) => {
        if (event.key === "Enter" || event.code === "Enter") {
            event.preventDefault();
        }
    };

    const renderForm = (formikProps) => {
        const wait_list_patient_category = formikProps.values.wait_list_patient_category;
        const isCommentActive = formikProps.values.isCommentActive;

        return (
            <Form onKeyDown={preventEnterFormSubmission}>
                <div className="WaitlistPage">
                    <Link
                        className="WaitlistPage--header-link"
                        to={`/app/patient/${patientDetails.username}/profile/v2?practiceId=${patientDetails.pcp_practice_id}`}
                        data-public={"true"}>
                        <img className="img-fluid green" src={images("./icons/Chevron_Left.svg")} />
                        <Text className={"WaitlistPage--comment-label"} color={TextColors.greenV2}>
                            Patient's Chart
                        </Text>
                    </Link>

                    <div className="WaitlistPage--wrapper">
                        <div className={"WaitlistPage--header-text"}>
                            <Text type={TextTypes.heading1}>
                                Why are we waitlisting {patientDetails?.first_name}?
                            </Text>
                        </div>
                        <div className="WaitlistPage--form">
                            <div>
                                <Hr />
                                <div className="WaitlistPage--question">
                                    <div className={"mb-2"}>
                                        <Text className={"font-weight-bold"}>
                                            {patientDetails?.first_name} is...
                                        </Text>
                                    </div>
                                    <WaitlistFormRadioField
                                        formikProps={formikProps}
                                        handleChange={async (event) => {
                                            const value = event.target.value;
                                            let wait_list_no_longer_schedule_with_roles =
                                                value === "di_no_completed"
                                                    ? undefined
                                                    : ["BEHAVIORAL_INTAKE"];
                                            // reset the parts of the form that are not shared so
                                            // the user cannot end up in an invalid
                                            // while preventing them from losing dat
                                            await formikProps.setValues((prev) => ({
                                                ...prev,
                                                wait_list_reason:
                                                    WAIT_LIST_INITIAL_VALUES.wait_list_reason,
                                                wait_list_no_longer_schedule_with_roles:
                                                    wait_list_no_longer_schedule_with_roles,
                                            }));
                                        }}
                                        name={"wait_list_patient_category"}
                                        radioOptions={PATIENT_OPTIONS}
                                    />
                                </div>
                            </div>

                            {wait_list_patient_category !== undefined && (
                                <div>
                                    <Hr />
                                    <div className="WaitlistPage--question">
                                        <div>
                                            <Text className={"font-weight-bold"}>Reason</Text>
                                        </div>
                                        {wait_list_patient_category === "di_no_completed" && (
                                            <div>
                                                <Text color={TextColors.grey}>
                                                    While on a waitlist, the patient will be
                                                    prevented from scheduling additional intake
                                                    appointments.
                                                </Text>
                                            </div>
                                        )}
                                        <WaitlistFormCheckbox
                                            name={"wait_list_reason"}
                                            formikProps={formikProps}
                                            checkboxClassName={"d-flex mt-3"}
                                            checkboxOptions={
                                                wait_list_patient_category === "established_patient"
                                                    ? ESTABLISHED_PATIENT_REASONS
                                                    : NON_DI_PATIENT_REASONS
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            {wait_list_patient_category === "established_patient" && (
                                <div>
                                    <Hr />
                                    <div className="WaitlistPage--question">
                                        <Text className={"font-weight-bold w-100"}>
                                            Following providers should not be available to the
                                            patient
                                        </Text>
                                        <div>
                                            <Text color={TextColors.grey}>
                                                While on a waitlist, the patient will be prevented
                                                from scheduling with all provider types that are
                                                checked.
                                            </Text>
                                        </div>

                                        <WaitlistFormCheckbox
                                            formikProps={formikProps}
                                            name={"wait_list_no_longer_schedule_with_roles"}
                                            checkboxWrapperClassName={"d-flex flex-wrap"}
                                            checkboxClassName={"mt-3 w-50"}
                                            checkboxOptions={CAN_NO_LONGER_SCHEDULE_WITH_ROLES}
                                        />
                                    </div>
                                </div>
                            )}

                            {wait_list_patient_category !== undefined && (
                                <div>
                                    <Hr />
                                    <div
                                        className={
                                            "WaitlistPage--question WaitlistPage--urgent-toggle"
                                        }>
                                        <WaitlistFormToggle
                                            formikProps={formikProps}
                                            name={"wait_list_requires_urgent_placement"}
                                        />
                                        <Text className={"pb-1"} color={TextColors.grey}>
                                            Needs urgent placement
                                        </Text>
                                    </div>
                                </div>
                            )}

                            {!isCommentActive && wait_list_patient_category !== undefined && (
                                <Text
                                    onClick={async () => {
                                        await formikProps.setFieldValue("isCommentActive", true);
                                    }}
                                    className={"WaitlistPage--comment-label"}
                                    color={TextColors.greenV2}>
                                    Add comment
                                </Text>
                            )}

                            {isCommentActive && wait_list_patient_category !== undefined && (
                                <div className={"WaitlistPage--comment-container"}>
                                    <div className="WaitlistPage--comment-title">
                                        <Text className={"font-weight-bold"}>Comments</Text>
                                        <Text className={"font-weight-medium"}>{"(Optional)"}</Text>
                                    </div>

                                    <Text color={TextColors.grey}>
                                        E.g. provider gender preference, hours, etc.
                                    </Text>
                                    <WaitlistFormField
                                        formikProps={formikProps}
                                        name={"wait_list_comment"}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="WaitlistPage--button">
                            <Button
                                onClick={() =>
                                    history.push(
                                        `/app/patient/${patientDetails.username}/profile/v2?practiceId=${patientDetails.pcp_practice_id}`,
                                    )
                                }
                                buttonType={"primary-v2-outline mr-1 WaitlistPage--button-cancel"}>
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                disabled={wait_list_patient_category === undefined}
                                buttonType={"primary-v2 ml-1 WaitlistPage--button-submit"}>
                                Waitlist Patient
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <>
            {patientDetails && (
                <CustomForm
                    initialValues={WAIT_LIST_INITIAL_VALUES}
                    validationSchema={WAIT_LIST_VALIDATION}
                    onSubmit={async (formData) => {
                        // extract data that we want to submit
                        const {
                            wait_list_reason,
                            wait_list_requires_urgent_placement,
                            wait_list_patient_category,
                        } = formData;

                        // get wait list comment value
                        let wait_list_comment = formData.wait_list_comment;
                        if (!formData.isCommentActive || formData.wait_list_comment === "") {
                            wait_list_comment = undefined;
                        }

                        const wait_list_no_longer_schedule_with_roles =
                            formData.wait_list_patient_category === "di_no_completed"
                                ? ["PRESCRIBE", "BEHAVIORAL_INTAKE", "BEHAVIORAL"]
                                : formData?.wait_list_no_longer_schedule_with_roles;

                        // create object that only contains object we want to
                        // submit
                        const data = {
                            wait_list_reason,
                            wait_list_requires_urgent_placement,
                            wait_list_no_longer_schedule_with_roles,
                            wait_list_comment,
                            wait_list_patient_category,
                        };

                        await api.patient.add_to_wait_list({
                            data,
                            queryParams: {
                                patient_username: patientDetails.username,
                            },
                        });

                        history.push(
                            `/app/patient/${patientDetails.username}/profile/v2?practiceId=${patientDetails.pcp_practice_id}`,
                        );
                    }}
                    render={renderForm}
                />
            )}
        </>
    );
};
