// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as yup from "yup";

export const BS_VALIDATION_SCHEMA = yup.object().shape({
    // session_date: yup.string().required('Session Date is required'),
    session_from: yup.string().required("From is required"),
    session_to: yup.string().required("To is required"),
    patient_report: yup.string().required("Patient Report is required"),
    patient_task: yup.string().required("Patient Task is required"),
    interventions: yup.string().required("Interventions are required"),
    intervention_reaction: yup.string().required("Intervention Reaction is required"),
    call_id: yup.string().required("Linked Call is required"),
    isSigned: yup.bool().required("Signature is required").oneOf([true], "Signature is required"),
    ICD_axis_1: yup.string().required("ICD-10 Primary Diagnosis is required"),
});

export const P_VALIDATION_SCHEMA = yup.object().shape({
    // session_date: yup.string().required('Session Date is required'),
    session_from: yup.string().required("From is required"),
    session_to: yup.string().required("To is required"),
    patient_reported: yup.string().required("Patient Report is required"),
    prescriber_interventions: yup.string().required("Interventions are required"),
    intervention_reaction: yup.string().required("Intervention Reaction is required"),
    call_id: yup.string().required("Linked Call is required"),
    isSigned: yup.bool().required("Signature is required").oneOf([true], "Signature is required"),
    ICD_axis_1: yup.string().required("ICD-10 Primary Diagnosis is required"),
});
