import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import { Card } from "../../../component-library";
import { createClassNameString } from "../../../component-library";
import { Button } from "../../../component-library";
import { NAVBAR_OPTIONS } from "./constants";
import moment from "moment";
import ScheduleNavControls from "../ScheduleNavControls/ScheduleNavControls";
import _ from "lodash";
import {
    toggleDatePicker,
    updateSelectedDate,
    updateCalendarView,
    isOnBehalfOfProviderPatientScheduling,
} from "../../../redux/actions/calendar";
import DatePicker from "../../../components/UI/DatePicker";
import { connect, useDispatch } from "react-redux";
import CalendarFilters from "../CalendarFilters/CalendarFilters";
import CloseOnOutsideClick from "../../Common/CloseOnOutsideClick/CloseOnOutsideClick";
import OnBehalfOfStickyHeader from "../OnBehalfOfStickyHeader/OnBehalfOfStickyHeader";

const ScheduleNav = ({ className, view, selectedDay, ...props }) => {
    const dispatch = useDispatch();

    const isSchedulingOnBehalfOf = dispatch(isOnBehalfOfProviderPatientScheduling());

    const viewChangeHandler = async (event) => {
        const timeRange = event.target.value;

        // Use Redux Action & Reducer to push dates array to store to be used in other schedule components
        props.updateCalendarView(timeRange);
        // this preforms side effects like fetching the schedule for the view
        props.updateSelectedDate(props.calendar.selectedScheduleDate);
    };

    const buttonRender = () => {
        return NAVBAR_OPTIONS.map((option) => {
            const buttonClass =
                option.value === props.calendar.viewType
                    ? "apti-ScheduleNav--button apti-ScheduleNav--button-selected"
                    : "apti-ScheduleNav--button";

            return (
                <Button className={buttonClass} value={option.value} onClick={viewChangeHandler}>
                    {option.label} View
                </Button>
            );
        });
    };

    const onDatePickerSelect = (newDate, changeType) => {
        if (changeType !== "monthChange") {
            const clonedSelectedDay = moment(newDate, "YYYY-MM-DD").local();
            props.updateSelectedDate(clonedSelectedDay);
            props.toggleDatePicker();
        }
    };

    return (
        <div className="apti-ScheduleNav">
            {isSchedulingOnBehalfOf && <OnBehalfOfStickyHeader />}
            <Card
                className={createClassNameString([
                    "apti-CL apti-ScheduleNav--card px-3",
                    className,
                ])}>
                <div className={"d-flex"}>
                    <ScheduleNavControls />
                    {!isSchedulingOnBehalfOf && <CalendarFilters />}
                </div>
                <div className={"apti-ScheduleNav--button-group"}>{buttonRender()}</div>
                {props.calendar.showDatePicker && (
                    <CloseOnOutsideClick
                        className={"d-flex align-items-center"}
                        setShowComponent={props.toggleDatePicker}>
                        <div className="apti-ScheduleNavControls__DatePicker-wpr">
                            <DatePicker
                                externalCtx={props.calendar.selectedScheduleDate}
                                onDaySelect={onDatePickerSelect}
                            />
                        </div>
                    </CloseOnOutsideClick>
                )}
            </Card>
        </div>
    );
};

ScheduleNav.propTypes = {
    className: PropTypes.string,
    view: PropTypes.string.isRequired,
    selectedDay: PropTypes.instanceOf(moment),
};

ScheduleNav.defaultProps = {
    className: undefined,
    view: "DAY",
    selectedDay: moment().local(),
};

const mapStateToProps = (state) => {
    return {
        calendar: state.calendar,
    };
};

const mapDispatchToProps = {
    updateSelectedDate,
    updateCalendarView,
    toggleDatePicker,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleNav);
