// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { Form, Formik } from "formik";
import Button from "../../UI/Button";
import CardPrimary from "../../UI/Card/CardPrimary";
import {
    A5_START_INITIAL_VALUES,
    A5_START_SCHEMA,
    A5_START_VALIDATION_SCHEMA,
} from "./a5StartSchema";
import FormikInput from "../../UI/FormikInput";
import { cloneDeep } from "lodash";
import FormikInputFormat from "../../UI/formikMobileInput";
import { connect } from "react-redux";
import { updateA5Details } from "../../../redux/actions/a5Screening";
import { IN_PROGRESS_STEP } from "../A5Screening";
import { CustomForm } from "../../../component-library/CustomForm";

class A5Start extends Component {
    submitHandler = async (formData, actions) => {
        try {
            actions.setSubmitting(false);
            formData["id"] = this.props.screeningId;
            const data = { ...formData };
            this.props.updateA5Details(data, { dob: formData["dob"] });
            this.props.nextStepHandler(IN_PROGRESS_STEP);
        } catch (e) {
            console.log(e);
        }
    };

    renderForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 ">
                    <div className="container">
                        <div className="mx-auto px-0 ">
                            <div className="container">
                                <div className="row">
                                    {A5_START_SCHEMA.map((field) => {
                                        if (field.name === "dob") {
                                            return (
                                                <div key={field.name} className="col-12 col-lg-6">
                                                    <FormikInputFormat
                                                        value={
                                                            formikProps && formikProps.values.dob
                                                        }
                                                        onChange={async (val) => {
                                                            await formikProps.setFieldValue(
                                                                "dob",
                                                                val.value,
                                                            );
                                                            formikProps.setFieldTouched("dob");
                                                        }}
                                                        formEl={field}
                                                        errors={formikProps.errors}
                                                        touched={formikProps.touched}
                                                    />
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div key={field.name} className="col-12 col-lg-6 ">
                                                    <FormikInput
                                                        formEl={field}
                                                        errors={formikProps.errors}
                                                        touched={formikProps.touched}
                                                    />
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center justify-content-lg-end">
                    <Button type="submit" className="Btn Btn--pri mt-4">
                        Begin Screening Now
                    </Button>
                </div>
            </Form>
        );
    };

    render() {
        const initialValues = this.props.a5Details
            ? transformDetailsToInitialValues(this.props.a5Details)
            : cloneDeep(A5_START_INITIAL_VALUES);
        return (
            <div>
                <h1>Hello!</h1>
                <CardPrimary className="mx-auto" style={{ marginTop: 0 }}>
                    <CustomForm
                        initialValues={initialValues}
                        validationSchema={A5_START_VALIDATION_SCHEMA}
                        onSubmit={this.submitHandler}
                        render={this.renderForm}
                    />
                </CardPrimary>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        a5Details: state.a5Screening.a5Details,
    };
};

export default connect(mapStateToProps, {
    updateA5Details,
})(A5Start);

export const transformDetailsToInitialValues = (details) => {
    return {
        first_name: details.first_name ? details.first_name : "",
        middle_name: details.middle_name ? details.middle_name : "",
        last_name: details.last_name ? details.last_name : "",
        gender: details.gender ? details.gender : "",
        dob: details.dob ? details.dob : "",
        ethnicity: details.ethnicity ? details.ethnicity : "",
        marital_status: details.marital_status ? details.marital_status : "",
        current_house: details.current_house ? details.current_house : "",
        persons_living: details.persons_living ? details.persons_living : "",
        hours_work_per_week: details.hours_work_per_week ? details.hours_work_per_week : "",
    };
};
