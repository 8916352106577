// Copyright aptihealth, inc. 2019 All Rights Reserved

import * as actionTypes from "../actions/actionTypes";

export const setDynamicRouteConfiguration = (dynamicRouteConfig) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_DYNAMIC_ROUTE_CONFIGURATION,
        payload: { ...dynamicRouteConfig },
    });
};

export const setOmnisearchDisplayStatus = (omnisearchDisplayStatus) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_OMNISEARCH_DISPLAY_STATUS,
        payload: omnisearchDisplayStatus,
    });
};
