import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import {createClassNameString} from "../../component-library";
import {ICON_SIZES, ICON_TYPES} from "../../constants/icons";

export const DashboardIcon = ({className, iconType, iconSize, ...props}) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path fillRule="evenodd" clipRule="evenodd" d="M13 15H6C4.89543 15 4 14.1046 4 13V6C4 4.89543 4.89543 4 6 4H13C14.1046 4 15 4.89543 15 6V13C15 14.1046 14.1046 15 13 15ZM13 13H6V6H13V13Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M13 28H6C4.89543 28 4 27.1046 4 26V19C4 17.8954 4.89543 17 6 17H13C14.1046 17 15 17.8954 15 19V26C15 27.1046 14.1046 28 13 28ZM13 26H6V19H13V26Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M19 15H26C27.1046 15 28 14.1046 28 13V6C28 4.89543 27.1046 4 26 4H19C17.8954 4 17 4.89543 17 6V13C17 14.1046 17.8954 15 19 15ZM19 13H26V6H19V13Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M26 28H19C17.8954 28 17 27.1046 17 26V19C17 17.8954 17.8954 17 19 17H26C27.1046 17 28 17.8954 28 19V26C28 27.1046 27.1046 28 26 28ZM26 26H19V19H26V26Z" />
            </svg>

        );
    } else if (iconType === ICON_TYPES.FILLED) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path d="M6 15H13C14.1046 15 15 14.1046 15 13V6C15 4.89543 14.1046 4 13 4H6C4.89543 4 4 4.89543 4 6V13C4 14.1046 4.89543 15 6 15Z"/>
                <path d="M6 28H13C14.1046 28 15 27.1046 15 26V19C15 17.8954 14.1046 17 13 17H6C4.89543 17 4 17.8954 4 19V26C4 27.1046 4.89543 28 6 28Z"/>
                <path d="M26 15H19C17.8954 15 17 14.1046 17 13V6C17 4.89543 17.8954 4 19 4H26C27.1046 4 28 4.89543 28 6V13C28 14.1046 27.1046 15 26 15Z"/>
                <path d="M19 28H26C27.1046 28 28 27.1046 28 26V19C28 17.8954 27.1046 17 26 17H19C17.8954 17 17 17.8954 17 19V26C17 27.1046 17.8954 28 19 28Z"/>
            </svg>
        );
    }

    return null;
};

DashboardIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

DashboardIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
