// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import { List, ListBody, ListHeader } from "../../UI/ListView";
import Spinner from "../../UI/Spinner";
import images from "../../../utils/images";

class AddMessageChannel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contacts: props.contacts,
            filteredContacts: props.contacts,
        };
    }

    // Very rudimentary filtering for first pass
    handleFiltering = (event) => {
        let searchValue = event.target.value;
        this.setState((state) => {
            let filteredContacts = state.contacts.filter((contact) =>
                contact.nickname.toLowerCase().includes(searchValue.toLowerCase()),
            );
            return {
                filteredContacts,
            };
        });
    };

    renderBackButton = () => {
        // Don't allow exit until at least 1 channel is picked
        if (this.props.channels.length === 0) {
            return <div />;
        }

        return (
            <img
                className="img-fluid mr-3"
                onClick={this.props.switchView}
                src={images("./common/cross.svg")}
                alt="exit"
            />
        );
    };

    render() {
        const { handleSelect } = this.props;

        return (
            <div className="Messages__AddMessageChannel">
                <ListBody>
                    <div className="Messages__AddMessageChannel__Header">
                        <span className="mb-3 mt-3 ml-3 mb-lg-0 fs-18 fw-bold">
                            Direct Messages
                        </span>
                        {this.renderBackButton()}
                    </div>
                    <div className="input-group input-group-lg List__Header">
                        <input
                            onChange={this.handleFiltering}
                            className="form-control"
                            placeholder="Find or start a conversation"
                            aria-label="Find or start a conversation"
                        />
                    </div>
                    <List>
                        {this.state.filteredContacts &&
                            this.state.filteredContacts.map((contact, index) => {
                                let avatar_url = contact.avatar_url
                                    ? contact.avatar_url
                                    : images("./common/avatar.png");

                                return (
                                    <li
                                        key={index}
                                        onClick={() => {
                                            handleSelect(contact);
                                        }}
                                        className="card Messages__AddMessageChannel__Contact">
                                        <div>
                                            <img src={avatar_url} alt="avatar" />
                                        </div>
                                        <div>
                                            <span>{contact.nickname}</span>
                                        </div>
                                    </li>
                                );
                            })}
                    </List>
                </ListBody>
            </div>
        );
    }
}

export default AddMessageChannel;
