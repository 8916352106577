import React, { useEffect, useState } from "react";
import CardSecondary from "../../../../../components/UI/Card/CardSecondary";
import _ from "lodash";
import { POST_SESSION_SURVEY_LABEL_MAP } from "../../../../../components/Provider/PatientView/MemberProfile/MemberProfileNotes";
import GenericSlider from "../../../../../components/Common/GenericSlider/GenericSlider";

import "./styles.scss";
import { BehavioralGoalsRating } from "../../../../../components/Provider/PatientView/MemberProfile/MemberProfileNotesBehavioralGoals";

const PostSessionSurveyQuestion = (props) => {
    const { handleEditChange, surveyQuestion, questionIndex, className, tutorialLightUp } = props;
    const [question, setQuestion] = useState(surveyQuestion);

    useEffect(() => {
        setQuestion(surveyQuestion);
    }, [surveyQuestion]);

    const label = _.get(question, "label", "");

    const desktopDisplay = (
        <div className={"BehavioralGoalsRating--pss"}>
            <BehavioralGoalsRating
                optionLabelMap={POST_SESSION_SURVEY_LABEL_MAP}
                height={25}
                labelsEnabled={true}
                progress={question}
                isEditing={true}
                onClick={(e) => handleEditChange(e, questionIndex, "answer")}
            />
        </div>
    );

    const mobileDisplay = (
        <div className={"GenericSlider--pss"}>
            <GenericSlider
                optionLabelMap={POST_SESSION_SURVEY_LABEL_MAP}
                height={25}
                labelsEnabled={true}
                progress={question}
                isEditing={true}
                forceActive={props.forceActive}
                onClick={(e) => handleEditChange(e, questionIndex, "answer")}
            />
        </div>
    );

    const tutorialCardOverlayLightUp = tutorialLightUp ? "tutorial-card-overlay-light-up" : "";

    return (
        <CardSecondary className={`px-2 py-2 my-4 ${className} ${tutorialCardOverlayLightUp}`}>
            {props.tutorialComponent}
            <div className={"container"}>
                <div className={"row mb-2"}>
                    <div className={"col"}>
                        <label className={"fw-bold"}>{label}</label>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col"}>
                        {desktopDisplay}
                        {mobileDisplay}
                    </div>
                </div>
            </div>
        </CardSecondary>
    );
};

export default PostSessionSurveyQuestion;
