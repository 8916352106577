import moment from "moment";
import * as yup from "yup";

const nameRegex = /^[a-zA-Z\ \-\_]+$/;
const nameRegexErrorMsg = "Numerals and select & special characters are not allowed";

export const firstNameValidation = yup
    .string()
    .required("First name is required")
    .matches(nameRegex, nameRegexErrorMsg);

export const lastNameValidation = yup
    .string()
    .required("Last name is required")
    .matches(nameRegex, nameRegexErrorMsg);

export const genderValidation = yup.string().required("Gender is required");

const emailRegex = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const emailValidation = yup
    .string()
    .required("Email is required")
    .matches(emailRegex, "Enter a valid email address");

const dateOfBirthErrorMsg = "Must be a valid date";

export const dateOfBirthValidation = yup
    .date()
    .nullable()
    .typeError(dateOfBirthErrorMsg)
    .min("01/01/1900", dateOfBirthErrorMsg)
    .test(">18", "To register a child for care you must be at least 18 years old", (value) => {
        return moment().diff(moment(value), "years") >= 18;
    })
    .format("MMDDYYYY");

const MaxDateValidationMsg = "Date can't be in the future!";

export const MaxDateValidation = yup
    .date()
    .typeError(MaxDateValidationMsg)

    .test(">=0", MaxDateValidationMsg, (value) => {
        return moment().diff(moment(value), "days", true) >= 0;
    })
    .format("MM/DD/YYYY");

const mobileRegex = /^\d{10}$/;

export const mobileValidation = yup
    .string()
    .required("Phone Number is required")
    .matches(mobileRegex, "Contact number must be a valid US number");
