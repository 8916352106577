// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import Button from "../../UI/Button";
import FormikInput from "../../UI/FormikInput";
import { Form } from "formik";
import { isObject } from "lodash";
import { CustomForm } from "../../../component-library/CustomForm";

const AdmitPatientPopup = (props) => {
    const { hidePopup, admitPatient, fullName } = props;

    let displayName = fullName;

    if (isObject(fullName)) {
        displayName = `${fullName.f_name} ${fullName.l_name}`;
    }

    let renderForm = (formikProps) => {
        return (
            <Form>
                <div className="PatientStatusPopUp__btn-wpr  d-flex justify-content-center pt-3 my-4">
                    <Button
                        type="button"
                        className="Btn Btn--otl-pri Btn-sm mx-2"
                        onClick={hidePopup}>
                        Cancel
                    </Button>
                    <Button type="submit" className="Btn Btn--pri Btn-sm mx-2">
                        Yes Admit
                    </Button>
                </div>
                <FormikInput
                    formEl={{
                        name: "note",
                        elementType: "textarea",
                        elementConfig: {
                            type: "textarea",
                            label: "Note",
                            placeholder: "Note",
                        },
                    }}
                    errors={formikProps.errors}
                    touched={formikProps.touched}
                />
            </Form>
        );
    };

    return (
        <div className="admitPatientPopUp position-fixed d-flex align-items-center justify-content-center">
            <div className="PatientStatusPopUp__inner d-flex flex-column text-center position-absolute px-4">
                <h2 className="fs-17 fw-bold mt-4">Admit to 90 Day Care</h2>
                <span className="fs-15 mt-2">
                    Are you sure you want to admit{" "}
                    <span className="fw-bold mt-1 pt-1">{displayName}</span> in 90 day care program?{" "}
                </span>
                <CustomForm onSubmit={admitPatient} render={renderForm} initialValues={{}} />
            </div>
        </div>
    );
};

export default AdmitPatientPopup;
