import React, { useEffect, useState } from "react";
import { MemberHeader } from "../../MemberHeader";
import { api } from "../../../APIRequests";
import { formatMobileNumber } from "../../../utils/filters";
import { calculateAge } from "../../../utils/momentUtils";

import _ from "lodash";

export const MemberHeaderHOC = ({ className, memberId, ...props }) => {
    const [memberData, setMemberData] = useState({});
    const [memberDiagnosis, setMemberDiagnosis] = useState({});

    const getMemberProfile = async () => {
        try {
            let urlParams = { patientId: memberId };
            const patientResponse = await api.shared.fetch_patient_details({ urlParams });
            setMemberData(patientResponse.user);
        } catch (err) {
            console.log(err);
        }
    };

    const getMemberDiagnosis = async () => {
        try {
            let urlParams = { patientId: memberId };
            const diagnosisResponse = await api.shared.get_patient_diagnosis({ urlParams });
            setMemberDiagnosis(diagnosisResponse);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        (async () => {
            await getMemberProfile();
            await getMemberDiagnosis();
        })();
    }, []);

    const primaryDiagnosis = _.get(memberDiagnosis, "ICD_10_axis_1_diagnosis", [])
        .map((icd_10) => icd_10["value"])
        .join(", ");
    const secondaryDiagnosis = _.get(memberDiagnosis, "ICD_10_axis_2_diagnosis", [])
        .map((icd_10) => icd_10["value"])
        .join(", ");

    if (_.isEmpty(memberData)) {
        return null;
    }

    return (
        <MemberHeader
            className={className}
            memberName={`${_.get(memberData, "first_name", "")} ${_.get(
                memberData,
                "last_name",
                "",
            )}`}
            dob={_.get(memberData, "dob")}
            age={calculateAge(_.get(memberData, "dob", ""))}
            phoneNumber={formatMobileNumber(_.get(memberData, "mobile"))}
            dayOfCare={_.get(memberData, "number_of_days_in_care", "")}
            primaryDiagnosis={primaryDiagnosis === "" ? "None" : primaryDiagnosis}
            secondaryDiagnosis={secondaryDiagnosis === "" ? "None" : secondaryDiagnosis}
            {...props}
        />
    );
};
