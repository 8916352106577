import React from "react";
import SkipInsuranceEligibility from "./SkipInsuranceEligibility/SkipInsuranceEligibility";
import InsuranceEligibilityV2 from "./InsuranceEligibilityV2/InsuranceEligibilityV2";
import { useSelector } from "react-redux";
import FeatureGate from "../FeatureGate/FeatureGate";
import {OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG} from "../../../constants/featureFlags";

const InsuranceEligibilityWrapper = ({
    submissionAttempts,
    insuranceDetails,
    eligibilityCallback,
    entryPoint,
    setEligibilityMessage = () => {},
    accountData,
    setRequiredFields = (rf) => {},
    ...props
}) => {
    const publicFlags = useSelector((state) => state.flags.publicFlags);
    const publicFlagsHaveLoaded = useSelector((state) => state.flags.publicFlagsHaveLoaded);

    return <FeatureGate
        defaultFlagBehavior={true}
        flagName={OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG}
        enabled={publicFlags?.[OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG]}
        hasLoaded={publicFlagsHaveLoaded}
        alternateFlagDisabledBehavior={
            <SkipInsuranceEligibility
                submissionAttempts={submissionAttempts}
                eligibilityCallback={eligibilityCallback}
            />
        }>
        <InsuranceEligibilityV2
            props={props}
            submissionAttempts={submissionAttempts}
            insuranceDetails={insuranceDetails}
            accountData={accountData}
            eligibilityCallback={eligibilityCallback}
            entryPoint={entryPoint}
            setEligibilityMessage={setEligibilityMessage}
            setRequiredFields={setRequiredFields}
        />
    </FeatureGate>;
};

export default InsuranceEligibilityWrapper;
