// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import "./styles.scss";
import { DEFAULT_DATA_ATTR_NAME, getAutomationDataAttr } from "../../../utils/automationUtils";

const Button = (props) => {
    const testId = props[DEFAULT_DATA_ATTR_NAME] || undefined;

    return (
        <button type={props.type} {...props} {...getAutomationDataAttr(testId)}>
            {props.children}
        </button>
    );
};

export default Button;
