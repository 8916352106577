import React from "react";
import images from "../../../../utils/images";
import CardSecondary from "../../../UI/Card/CardSecondary";
import _ from "lodash";
import { TextArea } from "../../../UI/StyledInput";

export const GenericSectionNotes = (props) => {
    const { note, sectionKey, sectionLabel, editable, editHandler, profileView, renderExtra } =
        props;

    let sectionNote = note.content[sectionKey];

    const [isEditing, setIsEditing] = React.useState(false);
    const [editCopy, setEditCopy] = React.useState(_.cloneDeep(sectionNote));

    let innerContent = (
        <>
            <div>
                <div className="clr-sec fw-bold float-left d-flex">
                    {sectionLabel} {renderExtra && renderExtra()}
                </div>
                <div className="float-right">
                    {editable && !isEditing && (
                        <img
                            src={images("./icons/edit-2.svg")}
                            alt="Edit Goals, Objectives, Progress"
                            style={{
                                width: "30px",
                                height: "30px",
                            }}
                            onClick={() => setIsEditing((curr) => !curr)}
                        />
                    )}
                    {editable && isEditing && (
                        <img
                            src={images("./icons/save.svg")}
                            alt="Save"
                            style={{
                                width: "30px",
                                height: "30px",
                            }}
                            onClick={() => {
                                setIsEditing((curr) => !curr);
                                if (editHandler) {
                                    editHandler("");
                                }
                            }}
                        />
                    )}
                </div>
            </div>
            <br />
            <div>
                {!isEditing ? (
                    <p className="mt-2 text-area-overflow">{sectionNote}</p>
                ) : (
                    <TextArea
                        value={editCopy}
                        onChange={(e) => {
                            setEditCopy(e.target.value);
                            if (editHandler) {
                                editHandler(sectionKey, e.target.value);
                            }
                        }}
                    />
                )}
            </div>
        </>
    );

    let content;
    if (!profileView) {
        content = (
            <CardSecondary className="mx-0 px-3 py-3 my-3 max-width" style={{ minHeight: 300 }}>
                {innerContent}
            </CardSecondary>
        );
    } else {
        content = (
            <div className="" style={{ minHeight: 300 }}>
                {innerContent}
            </div>
        );
    }

    return <>{content}</>;
};
