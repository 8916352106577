/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const BellIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Bell"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 15C24 16.6372 24.67 18.9445 26.5588 20.2762C27.1663 20.7046 27.3815 21.6185 26.8558 22.1441L26.2928 22.7071C26.1053 22.8946 25.851 23 25.5857 23H6.41417C6.14895 23 5.8946 22.8946 5.70706 22.7071L5.14408 22.1441C4.61843 21.6185 4.8336 20.7046 5.44114 20.2762C7.32986 18.9445 7.99996 16.6372 7.99996 15V11C7.99996 7 11 4 16 4C21 4 24 7 24 12V15ZM22 15C22 16.7324 22.585 19.1809 24.343 21H7.65688C9.4149 19.1809 9.99996 16.7324 9.99996 15V11C9.99996 9.52252 10.5416 8.32412 11.4703 7.47988C12.4105 6.6252 13.8993 6 16 6C18.0989 6 19.5434 6.62183 20.4607 7.53921C21.3781 8.45659 22 9.90102 22 12V15Z"
                />
                <path d="M12 24C12 24.9631 12.3199 25.9588 13.0026 26.7269C13.7016 27.5133 14.7286 28 16 28C17.2714 28 18.2984 27.5133 18.9974 26.7269C19.6801 25.9588 20 24.9631 20 24H18C18 24.5369 17.8199 25.0412 17.5026 25.3981C17.2016 25.7367 16.7286 26 16 26C15.2714 26 14.7984 25.7367 14.4974 25.3981C14.1801 25.0412 14 24.5369 14 24H12Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Bell"
                {...props}>
                <path d="M26.5588 20.2762C24.67 18.9445 24 16.6372 24 15V12C24 7 21 4 16 4C11 4 7.99996 7 7.99996 11V15C7.99996 16.6372 7.32986 18.9445 5.44114 20.2762C4.8336 20.7046 4.61843 21.6185 5.14408 22.1441L5.70706 22.7071C5.8946 22.8946 6.14895 23 6.41417 23H25.5857C25.851 23 26.1053 22.8946 26.2928 22.7071L26.8558 22.1441C27.3815 21.6185 27.1663 20.7046 26.5588 20.2762Z" />
                <path d="M12 24C12 24.9631 12.3199 25.9588 13.0025 26.7269C13.7016 27.5133 14.7286 28 16 28C17.2713 28 18.2983 27.5133 18.9974 26.7269C19.6801 25.9588 20 24.9631 20 24H12Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Bell"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM26 19.25C26 20.4779 26.5026 22.2083 27.9191 23.2072C28.3748 23.5285 28.5362 24.2139 28.1419 24.6081L27.7197 25.0303C27.579 25.171 27.3883 25.25 27.1894 25.25H12.8107C12.6118 25.25 12.421 25.171 12.2804 25.0303L11.8581 24.6081C11.4639 24.2139 11.6253 23.5285 12.0809 23.2072C13.4975 22.2083 14 20.4779 14 19.25V16.25C14 13.25 16.25 11 20 11C23.75 11 26 13.25 26 17V19.25ZM17.752 28.0451C17.24 27.4691 17 26.7223 17 26H23C23 26.7223 22.7601 27.4691 22.2481 28.0451C21.7238 28.635 20.9535 29 20 29C19.0465 29 18.2763 28.635 17.752 28.0451Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

BellIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

BellIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
