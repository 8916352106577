/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const ChevronRightIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="ChevronRight"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.2929 24.7071C11.9024 24.3166 11.9024 23.6834 12.2929 23.2929L19.5858 16L12.2929 8.70711C11.9024 8.31658 11.9024 7.68342 12.2929 7.29289C12.6834 6.90237 13.3166 6.90237 13.7071 7.29289L22.4142 16L13.7071 24.7071C13.3166 25.0976 12.6834 25.0976 12.2929 24.7071Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="ChevronRight"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5858 25.4142C10.8047 24.6332 10.8047 23.3668 11.5858 22.5858L18.1716 16L11.5858 9.41421C10.8047 8.63317 10.8047 7.36683 11.5858 6.58579C12.3668 5.80474 13.6332 5.80474 14.4142 6.58579L23.8284 16L14.4142 25.4142C13.6332 26.1953 12.3668 26.1953 11.5858 25.4142Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="ChevronRight"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M40 20C40 8.95431 31.0457 -3.91405e-07 20 -8.74228e-07C8.95431 -1.35705e-06 1.35705e-06 8.9543 8.74228e-07 20C3.91405e-07 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20ZM16.4393 24.9393C15.8536 25.5251 15.8536 26.4749 16.4393 27.0607C17.0251 27.6464 17.9749 27.6464 18.5607 27.0607L25.6213 20L18.5607 12.9393C17.9749 12.3536 17.0251 12.3536 16.4393 12.9393C15.8536 13.5251 15.8536 14.4749 16.4393 15.0607L21.3787 20L16.4393 24.9393Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

ChevronRightIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

ChevronRightIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
