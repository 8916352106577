import React from "react";
import "./styles.scss";

const AccessingPHIDisclaimer = (props) => {
    return (
        <>
            <div className={"accessing-phi-disclaimer"}>
                <div className={"px-3 py-1"}>
                    You are now accessing protected health information and access is being logged.
                </div>
            </div>
        </>
    );
};

export default AccessingPHIDisclaimer;
