// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import ContentLoader from "react-content-loader";

const ListLoader = () => (
    <ContentLoader
        height={500}
        width={900}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb">
        <rect x="0" y="25" rx="3" ry="3" width="100" height="10" />
        <rect x="225" y="25" rx="3" ry="3" width="100" height="10" />
        <rect x="525" y="25" rx="3" ry="3" width="100" height="10" />
        <rect x="750" y="25" rx="3" ry="3" width="100" height="10" />
        <rect x="875" y="25" rx="3" ry="3" width="30" height="10" />
        <rect x="0" y="90" rx="3" ry="3" width="100" height="10" />
        <rect x="225" y="90" rx="3" ry="3" width="100" height="10" />
        <rect x="525" y="90" rx="3" ry="3" width="100" height="10" />
        <rect x="750" y="90" rx="3" ry="3" width="100" height="10" />
        <rect x="875" y="90" rx="3" ry="3" width="30" height="10" />
        <rect x="0" y="145" rx="3" ry="3" width="100" height="10" />
        <rect x="225" y="145" rx="3" ry="3" width="100" height="10" />
        <rect x="525" y="145" rx="3" ry="3" width="100" height="10" />
        <rect x="750" y="145" rx="3" ry="3" width="100" height="10" />
        <rect x="875" y="145" rx="3" ry="3" width="30" height="10" />
        <rect x="0" y="200" rx="3" ry="3" width="100" height="10" />
        <rect x="225" y="200" rx="3" ry="3" width="100" height="10" />
        <rect x="525" y="200" rx="3" ry="3" width="100" height="10" />
        <rect x="750" y="200" rx="3" ry="3" width="100" height="10" />
        <rect x="875" y="200" rx="3" ry="3" width="30" height="10" />
    </ContentLoader>
);
export default ListLoader;
