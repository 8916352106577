import { ButtonTypes } from "../constants";

export const defaultNPSRatingButtons = [
    { label: "0", value: 0, buttonType: ButtonTypes.primaryOutlineV2 },
    { label: "1", value: 1, buttonType: ButtonTypes.primaryOutlineV2 },
    { label: "2", value: 2, buttonType: ButtonTypes.primaryOutlineV2 },
    { label: "3", value: 3, buttonType: ButtonTypes.primaryOutlineV2 },
    { label: "4", value: 4, buttonType: ButtonTypes.primaryOutlineV2 },
    { label: "5", value: 5, buttonType: ButtonTypes.primaryOutlineV2 },
    { label: "6", value: 6, buttonType: ButtonTypes.primaryOutlineV2 },
    { label: "7", value: 7, buttonType: ButtonTypes.primaryOutlineV2 },
    { label: "8", value: 8, buttonType: ButtonTypes.primaryOutlineV2 },
    { label: "9", value: 9, buttonType: ButtonTypes.primaryOutlineV2 },
    { label: "10", value: 10, buttonType: ButtonTypes.primaryOutlineV2 },
];
