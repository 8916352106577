import LogRocket from "logrocket";

// The purpose of this map is to map the group_type of a patient or referral to the ReferralPath user
// trait in LogRocket to help easily tag and identify different referral conversions
const GROUP_TYPE_TO_REFERRAL_PATH = {
    "patient": "PCP Referral",
    "teens_kids_program": "Teens and Kids Program",
    "patient_self_signup": "Self Sign Up",
    "hcp_program": "HCP Referral",
    "patient_auto_signup": "Embrace Referral"
};

export const tagReferralPath = (groupType) => {
    LogRocket.identify(null, {
        ReferralPath: getUserReferralPath(groupType)
    });
};


export const getUserReferralPath = (groupType) => {
    /**
    * PCP referrals do not return a group_type on the referral record.
    * Any Patient missing a group type can be assumed to be PCP referral, 
    * when the system was first created PCP referral was the only referral  
    * path and the concept of group_type did not exist, meaning we can safely    
    * assuming any patient missing group_type is also a PCP referral
    **/
    return GROUP_TYPE_TO_REFERRAL_PATH[groupType] || GROUP_TYPE_TO_REFERRAL_PATH["patient"];
};