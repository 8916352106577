// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useEffect } from "react";
import images from "../../../utils/images";
import { Link, withRouter } from "react-router-dom";
import moment from "moment-timezone";
import { providerRoute } from "../../../config/roles";
import { getGroupAuthType, isAuthorized } from "../../../redux/actions/auth";
import {
    _11_20_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98967,
    _21_30_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98968,
    _30_MIN_INDIVIDUAL_90832,
    _45_MIN_INDIVIDUAL_90834,
    _5_10_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98966,
    _60_MIN_INDIVIDUAL_90837,
    A30_ADMINISTRATION_96146,
    CM_SERVICES_20_MINS_MINIMUM_MONTH,
    CRISIS_EVAL_90839,
    CRISIS_EVAL_EXTENDED_90840,
    DIAGNOSTIC_INTERVIEW_90791,
    DIAGNOSTIC_INTERVIEW_90792,
    ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215,
    FAMILY_THERAPY_90847,
    IN_APP_MESSAGING_98970,
    IN_APP_MESSAGING_98971,
    IN_APP_MESSAGING_98972,
    IN_APP_MESSAGING_99421,
    IN_APP_MESSAGING_99422,
    IN_APP_MESSAGING_99423,
    INTERPRET_A5_OR_A30_96130,
    MEDICATION_MGMT_99212,
    MEDICATION_MGMT_99213,
    MEDICATION_MGMT_99214,
    NO_LINKED_CALL_0,
    PHQ_OR_GAD_96127,
    VIDEO_CONSULTATION,
    FAMILY_THERAPY_WO_PATIENT_90846,
    OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
    OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
    NON_BILLABLE,
} from "./constants";

const ActiveScheduleDetails = (props) => {
    const { details, duration, position, dismiss, showDeletePopUp, history } = props;
    const { callId, patient_username, patient_name, series_data } = details;

    const DISPLAY_DAYS = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];
    const DISPLAY_WEEKS_MAP = {
        0: "Week",
        1: "2 Weeks",
        2: "3 Weeks",
        3: "4 Weeks",
        5: "6 Weeks",
        7: "8 Weeks",
        11: "12 Weeks",
    };

    const styles = {
        top: position ? `${position.top}` : "50%",
        left: position ? `${position.left}` : "50%",
        bottom: position ? `${position.bottom}` : "auto",
        transform: !position && "translate(-50%,-50%)",
    };

    const onDocumentClick = (e) => {
        let isClickFromActiveScheduleBtn = e.target.closest(".DailyWidget__timeslot-active-btn");
        let isClickFromDeletePopUp = e.target.closest(".ScheduleDialog");
        let isClickFromSchedulesPopUp = e.target.closest(".ActiveScheduleDetails");
        if (
            !isClickFromActiveScheduleBtn &&
            !isClickFromSchedulesPopUp &&
            !isClickFromDeletePopUp
        ) {
            dismiss();
        }
    };

    useEffect(function () {
        document.addEventListener("click", onDocumentClick);
        /** detach click listener on unmoount */
        return function () {
            document.removeEventListener("click", onDocumentClick);
        };
    }, []);

    let scheduleDate = moment.utc(details.timestamp, "YYYY-MM-DD HH:mm").local();

    const onDeleteHandler = () => {
        showDeletePopUp(details.callId);
    };

    const getReoccurrenceTitle = () => {
        if (series_data && series_data.is_reoccurring) {
            const days = series_data.frequency_days
                .sort()
                .map((day) => DISPLAY_DAYS[day])
                .join(", ");
            const week = DISPLAY_WEEKS_MAP[series_data.frequency_weeks];
            const numberOfOccurrences =
                series_data.number_of_occurrences === 1
                    ? "1 Session"
                    : `${series_data.number_of_occurrences} Sessions`;
            return `Reoccurs every ${week} on ${days} for ${numberOfOccurrences}`;
        }
    };

    const renderButtons = () => {
        if (scheduleDate.isAfter(moment())) {
            return (
                <>
                    {!details.deleted && (
                        <button
                            onClick={onDeleteHandler}
                            className="ActiveScheduleDetails__header-delete-btn"
                            type="button"
                        />
                    )}
                    {getGroupAuthType() === "provider" && (
                        <button
                            className="ActiveScheduleDetails__header-edit-btn"
                            onClick={() =>
                                props.history.push({
                                    pathname: "/app/schedule/event",
                                    search: `call_id=${details.callId}&provider_id=${details.provider_username}&allotted_time=${details.allotted_time}`,
                                    state: { details },
                                })
                            }
                        />
                    )}
                </>
            );
        }

        return null;
    };

    const controls = (
        <div className="ActiveScheduleDetails__header d-flex align-items-center justify-content-between">
            {!details.deleted && (
                <div className="ActiveScheduleDetails__header-task-name  px-3 py-1 text-center fs-12 fs-lg-14 fw-bold">
                    <span>
                        {details.event_type
                            ? eventTypeDisplayNameFactory(details.event_type)
                            : "Video Consultation"}
                    </span>
                </div>
            )}
            {details.deleted && (
                <div className="ActiveScheduleDetails__header-task-name ActiveScheduleDetails__header-task-name-cancelled  px-3 py-1 text-center fs-12 fs-lg-14 fw-bold">
                    <span>Cancelled</span>
                </div>
            )}
            <div className="ActiveScheduleDetails__header-controls-wpr d-flex align-items-center">
                <div>{renderButtons()}</div>
                <button
                    onClick={dismiss}
                    className="ActiveScheduleDetails__header-close-btn"
                    type="button"
                />
            </div>
        </div>
    );

    const patientDetails = (
        <div className="ActiveScheduleDetails__patient-details my-4">
            <div>
                <img width="30" src={images("./common/avatar.png")} alt="patient's avatar" />
                <span
                    onClick={() =>
                        props.history.push(`/app/patient/${patient_username}/profile/v2`)
                    }
                    className="cursor link ml-3 fs-14 fs-lg-18 fw-bold">
                    {details.patient_name}
                </span>
            </div>
            <div className="fs-10 fs-lg-14 PSA__text-gry mt-2 border-top pt-2">
                {getReoccurrenceTitle()}
            </div>
            <div className="fs-10 fs-lg-14 PSA__text-gry mt-1">
                <span className="ActiveScheduleDetails__call-date position-relative mr-4">
                    {scheduleDate.format("MMMM DD, YYYY")}
                </span>
                <span>{duration}</span>
            </div>
        </div>
    );

    const callDetails = (
        <div className="ActiveScheduleDetails__call-details">
            {getGroupAuthType() === "provider" && !details.deleted && (
                <div className="d-flex mb-3">
                    <div>
                        <img src={images("./icons/video-cam.svg")} alt="video icon" />
                    </div>
                    <div className="fs-12 fs-lg-14 ml-3">
                        <div className="fw-bold mb-1 mb-lg-2">
                            <span>Join Video Call</span>
                        </div>
                        <div>
                            <Link
                                to={{ pathname: "/app/video-call", search: `?callId=${callId}` }}
                                className="ActiveScheduleDetails__call-details-link">
                                Click here to start call
                            </Link>
                            {/* <img className="ml-2" src={images('./icons/copy.svg')} alt="" /> */}
                        </div>
                    </div>
                </div>
            )}

            <div className="d-flex mb-3">
                <div>
                    <img src={images("./icons/mail-otl.svg")} alt="mail icon" />
                </div>
                <div className="fs-12 fs-lg-14 ml-3">
                    <div className="fw-bold mb-1 mb-lg-2">
                        <span>Guests</span>
                    </div>
                    <div>
                        <span className="PSA__text-gry">
                            {details.patient_email + ", " + details.provider_email}
                        </span>
                    </div>
                </div>
            </div>

            {/* Below commented code shall be later activated */}
            {/* <div className="d-flex mb-3">
                <div>
                    <img src={images('./icons/bell-otl.svg')} alt="bell icon" />
                </div>
                <div className="fs-12 fs-lg-14 ml-3">
                    <div className="fw-bold mb-1 mb-lg-2"><span>Notifications</span></div>
                    <div><span className="PSA__text-gry">10 minutes before</span></div>
                </div>
            </div> */}

            {/* <div className="d-flex mb-3">
                <div>
                    <img src={images('./icons/description.svg')} alt="description icon" />
                </div>
                <div className="fs-12 fs-lg-14 ml-3">
                    <div className="fw-bold mb-1 mb-lg-2"><span>Description</span></div>
                    <div>
                        <span className="PSA__text-gry">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text.
                        </span>
                    </div>
                </div>
            </div> */}
        </div>
    );

    return (
        <div style={styles} className="ActiveScheduleDetails p-3">
            <div className="ml-2 pl-lg-3">
                {controls}
                {patientDetails}
                {callDetails}
            </div>
        </div>
    );
};

export default withRouter(ActiveScheduleDetails);

export const eventTypeDisplayMini = (eventType, isFirstSession) => {
    switch (eventType) {
        case MEDICATION_MGMT_99212:
        case MEDICATION_MGMT_99213:
        case MEDICATION_MGMT_99214:
            return isFirstSession
                ? "Medication Management Consultation"
                : "Medication Management Session";
        default:
            return isFirstSession ? "Therapist Consultation" : "Therapy Session";
    }
};

export const eventTypeDisplayNameFactory = (type) => {
    switch (type) {
        case DIAGNOSTIC_INTERVIEW_90791:
            return "Diagnostic Interview (90791)";
        case DIAGNOSTIC_INTERVIEW_90792:
            return "Diagnostic Interview (90792)";
        case _30_MIN_INDIVIDUAL_90832:
            return "30 Minute Individual (90832)";
        case _45_MIN_INDIVIDUAL_90834:
            return "45 Minute Individual (90834)";
        case _60_MIN_INDIVIDUAL_90837:
            return "60 Minute Individual (90837)";
        case CRISIS_EVAL_90839:
            return "Crisis Evaluation (90839)";
        case CRISIS_EVAL_EXTENDED_90840:
            return "Crisis Evaluation Extended (90840)";
        case FAMILY_THERAPY_WO_PATIENT_90846:
            return "Family Therapy w/o Patient (90846)";
        case FAMILY_THERAPY_90847:
            return "Family Therapy w/ Patient (90847)";
        case OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204:
            return "Office or Other Outpatient Visit w/ New Patient (99204)";
        case OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205:
            return "Office or Other Outpatient Visit w/ New Patient (99205)";
        case MEDICATION_MGMT_99212:
            return "Medication Management (99212)";
        case MEDICATION_MGMT_99213:
            return "Medication Management (99213)";
        case MEDICATION_MGMT_99214:
            return "Medication Management (99214)";
        case NO_LINKED_CALL_0:
            return "No Linked Call";
        case INTERPRET_A5_OR_A30_96130:
            return "Interpret A5 and/or A30 + (96130)";
        case A30_ADMINISTRATION_96146:
            return "A30 administration (96146)";
        case IN_APP_MESSAGING_98970:
            return "In App Messaging (98970)";
        case IN_APP_MESSAGING_98971:
            return "In App Messaging (98971)";
        case IN_APP_MESSAGING_98972:
            return "In App Messaging (98972)";
        case IN_APP_MESSAGING_99421:
            return "In App Messaging (99421)";
        case IN_APP_MESSAGING_99422:
            return "In App Messaging (99422)";
        case IN_APP_MESSAGING_99423:
            return "In App Messaging (99423)";
        case VIDEO_CONSULTATION:
            return isAuthorized("provider:prescribe")
                ? "Diagnostic Interview (90792)"
                : "30 Minute Individual (90832)";
        case _5_10_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98966:
            return "5-10 Min Phone Assmt. and Mgmt. (98966)";
        case _11_20_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98967:
            return "11-20 Min Phone Assmt. and Mgmt. (98967)";
        case _21_30_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98968:
            return "21-30 Min Phone Assmt. and Mgmt. (98968)";
        case CM_SERVICES_20_MINS_MINIMUM_MONTH:
            return "99484: CM Services; min 20 mins minimum/calendar month";
        case PHQ_OR_GAD_96127:
            return "PHQ or GAD (96127)";
        case ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215:
            return "Established Patient Office or Other Outpatient Services (99215)";
        case NON_BILLABLE:
            return "Non Billable";
        case "busy":
            return "Busy";
        default:
            return "Unknown event name";
    }
};

export const eventTypeLogicalNameFactory = (type) => {
    switch (type) {
        case "Diagnostic Interview (90791)":
            return DIAGNOSTIC_INTERVIEW_90791;
        case "Diagnostic Interview (90792)":
            return DIAGNOSTIC_INTERVIEW_90792;
        case "30 Minute Individual (90832)":
            return _30_MIN_INDIVIDUAL_90832;
        case "45 Minute Individual (90834)":
            return _45_MIN_INDIVIDUAL_90834;
        case "60 Minute Individual (90837)":
            return _60_MIN_INDIVIDUAL_90837;
        case "Crisis Evaluation (90839)":
            return CRISIS_EVAL_90839;
        case "Crisis Evaluation Extended (90840)":
            return CRISIS_EVAL_EXTENDED_90840;
        case "Family Therapy w/ Patient (90847)":
            return FAMILY_THERAPY_90847;
        case "Family Therapy w/o Patient (90846)":
            return FAMILY_THERAPY_WO_PATIENT_90846;
        case "Office or Other Outpatient Visit w/ New Patient (99204)":
            return OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204;
        case "Office or Other Outpatient Visit w/ New Patient (99205)":
            return OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205;
        case "Medication Management (99212)":
            return MEDICATION_MGMT_99212;
        case "Medication Management (99213)":
            return MEDICATION_MGMT_99213;
        case "Medication Management (99214)":
            return MEDICATION_MGMT_99214;
        case "5-10 Minutes Telephone Assessment and Management VS Medical Discussion (98966)":
            return _5_10_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98966;
        case "11-20 Minutes Telephone Assessment and Management VS Medical Discussion (98967)":
            return _11_20_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98967;
        case "21-30 Minutes Telephone Assessment and Management VS Medical Discussion (98968)":
            return _21_30_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98968;
        case "99484: CM Services; min 20 mins minimum/calendar month":
            return CM_SERVICES_20_MINS_MINIMUM_MONTH;
        case "Established Patient Office or Other Outpatient Services (99215)":
            return ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215;
        default:
            return isAuthorized("provider:prescribe")
                ? DIAGNOSTIC_INTERVIEW_90792
                : _30_MIN_INDIVIDUAL_90832;
    }
};
