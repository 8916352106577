import React, { useState } from "react";
import _ from "lodash";
import { Button } from "../../../component-library/";
import "./styles.scss";

export const SelectableList = ({ currentlySelectedItems, displayList, updateSelectedItems }) => {
    const [selectedItems, setSelectedItems] = useState(currentlySelectedItems);

    const handleSelection = (index) => {
        const tempSelected = _.cloneDeep(selectedItems);

        if (Array.isArray(selectedItems) && selectedItems.includes(index)) {
            tempSelected.splice(tempSelected.indexOf(index), 1);
        } else {
            tempSelected.push(index);
        }

        setSelectedItems(tempSelected);
        if (updateSelectedItems) {
            updateSelectedItems(tempSelected);
        }
    };
    return displayList.map((value, index) => {
        const isSelectedClass =
            Array.isArray(selectedItems) && selectedItems.includes(index)
                ? "apti-CalendarAppointmentPicker--ReoccurrenceDetails__active-list"
                : "apti-CalendarAppointmentPicker--ReoccurrenceDetails__inactive-list";

        const columnRender = (
            <Button
                key={`${value}` + index}
                onClick={() => handleSelection(index)}
                className={
                    "apti-CalendarAppointmentPicker--ReoccurrenceDetails py-2 " + isSelectedClass
                }>
                {value}
            </Button>
        );
        return columnRender;
    });
};
