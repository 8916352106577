// Copyright aptihealth, inc. 2019 All Rights Reserved

import { MemberProfileActivityEntry } from "../../../component-library";
import React from "react";

export const ReportGeneratedActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const { report_type } = keyContent;

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Secondary"
            title="Report Generated"
            subTitle={`${report_type} is In Progress`}
            localDateTime={localCreatedTime}
            iconSrc="icons/report-icon-secondary.svg"
            iconAlt="ReportGeneratedIcon"
        />
    );
};

export const ReportCompletedActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const { report_type, completed_by } = keyContent;

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Primary"
            title="Report Completed"
            subTitle={`${report_type} is Completed`}
            localDateTime={localCreatedTime}
            iconSrc="icons/report-icon-primary.svg"
            iconAlt="ReportCompletedIcon">
            <div className={"fs-14"}>{completed_by && `Completed by ${completed_by}`}</div>
        </MemberProfileActivityEntry>
    );
};
