import React, { useEffect, useState } from "react";
import ThankYouIntakeSchedule from "./ThankYouIntakeSchedule";
import ConfirmIntakeConsultation from "./ConfirmIntakeConsultation";
import ScheduleIntakeConsultation from "./ScheduleIntakeConsultation";
import WorkflowHeader from "./WorkflowHeader";
import { api } from "../../../../APIRequests";

import _ from "lodash";
import { withRouter } from "react-router-dom";
import moment from "moment-timezone";

const getSchedulingData = async (sharedWorkflowData) => {
    const data = {
        workflow_instance_sort_key: sharedWorkflowData.workflow_instance.sort_key,
        timezone: moment.tz.guess(),
    };

    const options = { params: { showLoader: true } };

    const headers = {
        "X-On-Behalf-Of": sharedWorkflowData.workflow_instance.entity_id,
        "X-On-Behalf-Of-Entity-Type": "patient",
    };

    return api.workflow.get_schedule_info({ options, data, headers });
};

const LoadingIntakeSchedule = (props) => {
    return <WorkflowHeader header={"Loading scheduling data..."} showImage={false} />;
};

const DynamicIntakeSchedulingArea = ({
    appToDisplay,
    setAppToDisplay,
    consultationToConfirm,
    setConsultationToConfirm,
    alreadyScheduled,
    setAlreadyScheduled,
    schedulingData,
    setSchedulingData,
    props,
    addToInAppHistory,
    withinAppHistory,
    setWithinAppHistory,
    goBackInAppHistory,
    workflowInstance,
    setSharedWorkflowData,
}) => {
    const lookup = {
        loading: () => <LoadingIntakeSchedule setAppToDisplay={setAppToDisplay} />,
        thankYou: () => <ThankYouIntakeSchedule props={props} />,
        confirmConsultation: () => (
            <ConfirmIntakeConsultation
                schedulingData={schedulingData}
                consultationToConfirm={consultationToConfirm}
                setConsultationToConfirm={setConsultationToConfirm}
                alreadyScheduled={alreadyScheduled}
                setAlreadyScheduled={setAlreadyScheduled}
                appToDisplay={appToDisplay}
                setAppToDisplay={setAppToDisplay}
                addToInAppHistory={addToInAppHistory}
                withinAppHistory={withinAppHistory}
                setWithinAppHistory={setWithinAppHistory}
                goBackInAppHistory={goBackInAppHistory}
                workflowInstance={workflowInstance}
            />
        ),
        scheduleConsultation: () => (
            <ScheduleIntakeConsultation
                consultationToConfirm={consultationToConfirm}
                setConsultationToConfirm={setConsultationToConfirm}
                alreadyScheduled={alreadyScheduled}
                setAlreadyScheduled={setAlreadyScheduled}
                schedulingData={schedulingData}
                appToDisplay={appToDisplay}
                setAppToDisplay={setAppToDisplay}
                setSchedulingData={setSchedulingData}
                addToInAppHistory={addToInAppHistory}
                withinAppHistory={withinAppHistory}
                setWithinAppHistory={setWithinAppHistory}
                goBackInAppHistory={goBackInAppHistory}
                workflowInstance={workflowInstance}
            />
        ),
    };

    return lookup[appToDisplay]();
};

const addToInAppHistory = (
    appToDisplay,
    setAppToDisplay,
    alreadyScheduled,
    setAlreadyScheduled,
    consultationToConfirm,
    setConsultationToConfirm,
    withinAppHistory,
    setWithinAppHistory,
) => {
    const funcToRestoreThisState = () => {
        setAppToDisplay(appToDisplay);
        setAlreadyScheduled(alreadyScheduled);
        setConsultationToConfirm(consultationToConfirm);
    };
    withinAppHistory.push(funcToRestoreThisState);
    setWithinAppHistory(withinAppHistory);
};

const goBackInAppHistory = (withinAppHistory, setWithinAppHistory) => {
    let f = withinAppHistory.pop();
    f();
    setWithinAppHistory(withinAppHistory);
};

const IntakeScheduling = (props) => {
    const { sharedWorkflowData, setSharedWorkflowData } = props;
    let workflowInstance = sharedWorkflowData.workflow_instance;

    // appToDisplay can we loading | thankYou | confirmConsultation | scheduleConsultation
    const [appToDisplay, setAppToDisplay] = useState("loading");
    const [schedulingData, setSchedulingData] = useState({});
    const [consultationToConfirm, setConsultationToConfirm] = useState(null);
    const [alreadyScheduled, setAlreadyScheduled] = useState(false);

    const priorAppFunc = () => {
        props.history.goBack();
        setSharedWorkflowData({});
    };
    const [withinAppHistory, setWithinAppHistory] = useState([priorAppFunc]);

    useEffect(() => {
        getSchedulingData(sharedWorkflowData)
            .then((response) => {
                const data = response;
                //data?.intake_appointment_data?.appointment_scheduled
                setSchedulingData(data);
                if (_.get(data, "intake_appointment_data.appointment_scheduled", false)) {
                    setConsultationToConfirm(
                        _.get(data, "intake_appointment_data.upcoming_appointments[0]"),
                    );
                    setAlreadyScheduled(true);
                    setAppToDisplay("confirmConsultation");
                } else {
                    setAppToDisplay("scheduleConsultation");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <>
            <DynamicIntakeSchedulingArea
                appToDisplay={appToDisplay}
                setAppToDisplay={setAppToDisplay}
                consultationToConfirm={consultationToConfirm}
                setConsultationToConfirm={setConsultationToConfirm}
                alreadyScheduled={alreadyScheduled}
                setAlreadyScheduled={setAlreadyScheduled}
                schedulingData={schedulingData}
                setSchedulingData={setSchedulingData}
                props={props}
                addToInAppHistory={addToInAppHistory}
                withinAppHistory={withinAppHistory}
                setWithinAppHistory={setWithinAppHistory}
                goBackInAppHistory={goBackInAppHistory}
                workflowInstance={workflowInstance}
                setSharedWorkflowData={setSharedWorkflowData}
            />
        </>
    );
};

export default withRouter(IntakeScheduling);
