// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import CardSecondary from "../../../components/UI/Card/CardSecondary";
import moment from "moment-timezone";
import Input from "../../../components/UI/Input";

const PatientReferralInfo = (props) => {
    const { isWide } = props;
    const classNames = (isWide ? "col-lg-6 col-12" : "col-12") + " mb-4";

    const formatRegistrationDate = (patientDetails) => {
        if (!patientDetails.registration_date) {
            return "";
        }

        try {
            return moment(patientDetails.registration_date).format("MM/DD/YYYY");
        } catch (e) {
            console.log(e);
            return "";
        }
    };

    return (
        <CardSecondary
            className="mx-lg-auto mt-2 mt-lg-3 px-3 pb-3 px-lg-4"
            style={isWide ? { maxWidth: "100%" } : {}}>
            <div className="pt-4 pb-1">
                <span className={"fw-bold fs-16"}>Patient Referral Info</span>
            </div>
            <hr className="mb-4" />
            <div className={"row"}>
                <div className={classNames}>
                    <p className="fw-bold mb-2 fs-14">Referral Code</p>
                    <p className="mb-0 fs-14">{props.referralCode}</p>
                </div>
                <div className={classNames}>
                    <p className="fw-bold mb-2 fs-14">PCP Name</p>
                    <p className="mb-0 fs-14">{props.pcpName}</p>
                </div>
                {props.patientDetails && props.patientDetails.pcp_location && (
                    <div className={classNames}>
                        <p className="fw-bold mb-2 fs-14">PCP Location</p>
                        <p className="mb-0 fs-14">{props.patientDetails.pcp_location}</p>
                    </div>
                )}
                {props.patientDetails && props.patientDetails.hasOwnProperty("no_pcp") && (
                    <div className={classNames}>
                        <p className="fw-bold mb-2 fs-14">No PCP Indicated</p>
                        <Input
                            className="Input"
                            type={"checkbox"}
                            disabled={"disabled"}
                            checked={props.patientDetails.no_pcp ? "checked" : null}
                        />
                    </div>
                )}

                <div className={classNames}>
                    <p className="fw-bold mb-2 fs-14">Referral Date</p>
                    <p className="mb-0 fs-14">
                        {props.referralCreatedDate
                            ? moment.unix(props.referralCreatedDate).format("MM/DD/YYYY")
                            : ""}
                    </p>
                </div>
                <div className={classNames}>
                    <p className="fw-bold mb-2 fs-14">Practice Name</p>
                    <p className="mb-0 fs-14">{props.practiceName}</p>
                </div>
                {props.patientDetails && (
                    <div className={classNames}>
                        <p className="fw-bold mb-2 fs-14">Date Added</p>
                        <p className="mb-0 fs-14">
                            {props.patientDetails.created_date &&
                                moment.unix(props.patientDetails.created_date).format("MM/DD/YYYY")}
                        </p>
                    </div>
                )}

                {props.patientDetails && (
                    <div className={classNames}>
                        <p className="fw-bold mb-2 fs-14">Referring Provider</p>
                        <p className="mb-0 fs-14">{props.patientDetails.referred_pcp_name}</p>
                    </div>
                )}

                {props.patientDetails && (
                    <div className={classNames}>
                        <p className="fw-bold mb-2 fs-14">Registration Date</p>
                        <p className="mb-0 fs-14">{formatRegistrationDate(props.patientDetails)}</p>
                    </div>
                )}
            </div>
        </CardSecondary>
    );
};

export default PatientReferralInfo;
