import * as yup from "yup";

export const PAYMENT_MODAL_INITIAL_VALUES = {
    amount: undefined,
    comment: undefined,
};

export const COMMENT_CHAR_LIMIT = 255;

export const PAYMENT_MODAL_VALIDATION = yup.object().shape({
    amount: yup
        .string()
        .required("Please enter an amount")
        .test("valueCheck", "Subtotal cannot exceed $300", (value) => {
            const numberValue = Number(value);
            return numberValue <= 300;
        })
        .test("valueCheck", "Subtotal cannot be less than $0", (value) => {
            const numberValue = Number(value);
            return numberValue > 0;
        }),
    comment: yup.string().max(COMMENT_CHAR_LIMIT, "Limit text to 255 characters"),
});
