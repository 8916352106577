import "./styles.scss";
import React from "react";
import { getIn } from "formik";
import { Text, TextArea, TextColors, TextTypes } from "../../../component-library";

// NOTE: THIS PATTERN WAS COPIED FROM the care management form
export const WaitlistFormField = ({ formikProps, name }) => {
    const errorMessage = getIn(formikProps.errors, name);
    const isTouched = getIn(formikProps.touched, name);

    const handleChange = async (event) => {
        await formikProps.setFieldValue(name, event.target.value);
    };

    return (
        <>
            <TextArea
                className={"WaitlistPage--comment-text-area w-100"}
                spanClasses="w-100"
                ignoreEnterSubmit={true}
                onChange={handleChange}
                classes={"large"}
            />
            {errorMessage && isTouched && (
                <Text className="pt-2" type={TextTypes.small} color={TextColors.red}>
                    {errorMessage}
                </Text>
            )}
        </>
    );
};
