// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import {
    BS_INITIAL_VALUES,
    BS_SCHEMA,
    P_INITIAL_VALUES,
    P_SCHEMA,
} from "./schema/newNoteFormSchema";
import { BS_VALIDATION_SCHEMA, P_VALIDATION_SCHEMA } from "./schema/newNoteValidation";
import { Form } from "formik";
import Button from "../../../../components/UI/Button";
import { api } from "../../../../APIRequests";
import { isAuthorized } from "../../../../redux/actions/auth";
import { getQueryParams } from "../../../../utils/filters";
import {
    createCommonNoteData,
    formChangeHandler,
    getNoteCard,
    getNoteFields,
    pickNoteDate,
    setBehavioralNoteData,
    setPrescriberNoteData,
    getICD10Dataset,
} from "../../../../components/Provider/PatientView/ProgressNotes";
import moment from "moment-timezone";
import { eventTypeDisplayNameFactory } from "../../../../components/Provider/ScheduleAvailability/ActiveScheduleDetails";
import { connect } from "react-redux";
import { transformTimeSlotArray } from "../../../../components/Provider/ScheduleAvailability/ScheduleEvent";
import { timeSlotArray } from "../../../../components/Provider/ScheduleAvailability/constants";
import store from "../../../../redux/store";
import { showLoader } from "../../../../redux/actions/loader";

export async function getCompletedCalls(urlParams, filter = (call) => true) {
    const response = await api.provider.fetch_completed_calls({ urlParams });
    const completedCalls = response
        .sort(
            (callA, callB) =>
                moment(callB["timestamp"], "YYYY-MM-DD HH:mm").valueOf() -
                moment(callA["timestamp"], "YYYY-MM-DD HH:mm").valueOf(),
        )
        .filter(filter)
        .map((call) => {
            let display = call.event_type
                ? eventTypeDisplayNameFactory(call.event_type) + " - "
                : "Video Consultation - ";
            display += moment
                .utc(call["timestamp"], "YYYY-MM-DD HH:mm")
                .local()
                .format("YYYY-MM-DD hh:mm A");
            return {
                value: call["callId"],
                display: display,
                event_type: call.event_type,
                timestamp: call["timestamp"],
            };
        });

    completedCalls.unshift({ value: "no_linked_call", display: "No Linked Call" });
    completedCalls.unshift({ value: "non_billable", display: "Non Billable Encounter" });
    return completedCalls;
}

class NewNote extends Component {
    constructor(props) {
        super(props);
        let session =
            parseInt(getQueryParams("numSessions", this.props.location.search.trim())) + 1;
        let title = "Individual therapy # " + session;
        this.state = {
            title: title,
            behavioral: isAuthorized("provider:behavioral"),
            prescribe: isAuthorized("provider:prescribe"),
            patientId: this.props.match.params.patientId,
            completedCalls: [],
            isSigned: false,
            sessionFrom: "",
            sessionTo: "",
            timeSlotOptions: transformTimeSlotArray([...timeSlotArray]),
            ICD10: [],
        };
    }

    componentDidMount = async () => {
        try {
            getICD10Dataset(this);
            let urlParams = { patientId: this.state.patientId };
            const completedCalls = await getCompletedCalls(urlParams);
            this.setState({ completedCalls: completedCalls });
        } catch (e) {
            console.log(e);
        }
        document.addEventListener("change", this.formChangeHandler, {
            capture: true,
        });
    };

    formChangeHandler = (e) => {
        if (e.target.name === "session_from") {
            this.setState({ sessionFrom: moment(e.target.value, "hh:mm a").format("HH:mm") });
        } else if (e.target.name === "session_to") {
            this.setState({ sessionTo: moment(e.target.value, "hh:mm a").format("HH:mm") });
        }
    };

    submitHandler = async (formData, actions, draft) => {
        store.dispatch(showLoader());
        let data = await createCommonNoteData(this, formData, this.state.title, this.props.profile);
        if (this.state.behavioral) {
            setBehavioralNoteData(this, formData, this.state.title, data);
        } else {
            setPrescriberNoteData(this, formData, this.state.title, data);
        }

        if (draft) {
            data.note_state = "draft";
        }

        let urlParams = { patientId: this.state.patientId };
        api.provider
            .post_progress_note({ data, urlParams })
            .then((response) => {
                this.props.history.go(-1);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    cancelClickHandler = () => {
        this.submitHandler(this.noteData, {}, true);

        if (this.state.behavioral) {
            if (
                this.noteData.patient_report === "" &&
                this.noteData.patient_task === "" &&
                this.noteData.intervention_reaction === "" &&
                this.noteData.interventions === ""
            ) {
                this.props.history.go(-1);
                return;
            }
        } else {
            if (
                this.noteData.patient_reported === "" &&
                this.noteData.prescriber_interventions === "" &&
                this.noteData.intervention_reaction === ""
            ) {
                this.props.history.go(-1);
                return;
            }
        }
    };

    getPickedDate = (dateString) => {
        pickNoteDate(this, dateString);
    };

    signatureClickHandler = async (e, formikProps) => {
        const isSigned = !this.state.isSigned;
        await formikProps.setFieldValue("isSigned", isSigned);
        formikProps.setFieldTouched("isSigned");
        this.setState({ isSigned: isSigned });
    };

    renderForm = (formikProps) => {
        this.noteData = formikProps.values;
        let schema;
        if (this.state.behavioral) {
            schema = BS_SCHEMA;
        } else {
            schema = P_SCHEMA;
        }
        const callIdIndex = schema.findIndex((el) => el["name"] === "call_id");
        if (callIdIndex) {
            schema[callIdIndex].elementConfig.options = this.state.completedCalls;
        }
        return (
            <Form>
                <div className="mx-auto px-0 ">
                    <div className="container">{getNoteFields(formikProps, schema, this)}</div>
                </div>
                <div className="d-flex justify-content-center justify-content-lg-end">
                    <Button
                        type="button"
                        onClick={() => {
                            this.cancelClickHandler();
                        }}
                        className="Btn Btn--otl-pri Btn-sm mr-3">
                        Save Draft
                    </Button>
                    <Button type="submit" className="Btn Btn--pri Btn-sm">
                        Add Note
                    </Button>
                </div>
            </Form>
        );
    };

    renderTitle = () => {
        return (
            <div className="ml-0 ml-lg-3">
                <b>{this.state.title}</b>
            </div>
        );
    };

    render() {
        let vals;
        let validation;
        if (this.state.behavioral) {
            vals = BS_INITIAL_VALUES;
            validation = BS_VALIDATION_SCHEMA;
        } else {
            vals = P_INITIAL_VALUES;
            validation = P_VALIDATION_SCHEMA;
        }
        vals["title"] = this.state.title;
        return <div>{getNoteCard(vals, validation, this)}</div>;
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};
export default connect(mapStateToProps)(NewNote);
