// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { api } from "../../../../APIRequests";
import withRouter from "react-router-dom/es/withRouter";
import moment from "moment-timezone";
import images from "../../../../utils/images";
import _ from "lodash";

const MemberProfilePatientDiagnosis = (props) => {
    const Icd10ListsToString = (icdList1, icdList2) => {
        let primary = "None";
        let secondary = "None";

        if (icdList1 && icdList1.length) {
            primary = icdList1.map((icd_10) => icd_10["value"]).join(", ");
        }

        if (icdList2 && icdList2.length) {
            secondary = icdList2.map((icd_10) => icd_10["value"]).join(", ");
        }

        return (
            <>
                <p className={"mb-0 fs-14"}>
                    <span className={"fw-bold"}>Primary Diagnosis: </span>
                    {primary}
                </p>
                <p className={"mb-0 fs-14"}>
                    <span className={"fw-bold"}>Secondary Diagnosis: </span>
                    {secondary}
                </p>
            </>
        );
    };

    const Icd10ListsToScrollableList = (icdList1, icdList2) => {
        return <ScrollableDiagnosis icdPrimary={icdList1} icdSecondary={icdList2} />;
    };

    const transformer = () => {
        switch (props.result) {
            case "string":
                return Icd10ListsToString;
            case "list":
                return Icd10ListsToScrollableList;
            default:
                return Icd10ListsToString;
        }
    };

    return (
        <>
            {props.patientDiagnosis
                ? transformer()(
                      props.patientDiagnosis["ICD_10_axis_1_diagnosis"],
                      props.patientDiagnosis["ICD_10_axis_2_diagnosis"],
                  )
                : "..."}
        </>
    );
};

export default withRouter(MemberProfilePatientDiagnosis);

export const ScrollableDiagnosis = (props) => {
    let { icdPrimary, icdSecondary } = props;
    let [sliceNum, setSliceNum] = React.useState(0);

    if (!icdPrimary.length) {
        icdPrimary = ["None"];
    }

    if (!icdSecondary.length) {
        icdSecondary = ["None"];
    }

    let icdMain = [
        <span className={"fw-bold"}>Primary Diagnosis: </span>,
        ...icdPrimary,
        <span className={"fw-bold"}>Secondary Diagnosis: </span>,
        ...icdSecondary,
    ];

    const moveSlice = (movement) => {
        let totalSlices = icdMain.length;
        let newSlice = sliceNum + movement;
        if (newSlice >= totalSlices) {
            setSliceNum(totalSlices - newSlice);
        } else if (newSlice < 0) {
            setSliceNum(totalSlices - 2);
        } else {
            setSliceNum(newSlice);
        }
    };

    return (
        <>
            <div className="float-left fs-14" style={{ width: "80%" }}>
                <p className={"mb-0"}>
                    {icdMain.slice(sliceNum, sliceNum + 1).map((icd_10) => {
                        if (React.isValidElement(icd_10) || typeof icd_10 === "string") {
                            return icd_10;
                        } else {
                            return (
                                <>
                                    <div>{icd_10["code"] + " - " + icd_10["text"]}</div>
                                </>
                            );
                        }
                    })}
                    {icdMain.slice(sliceNum + 1, sliceNum + 2).map((icd_10) => {
                        if (React.isValidElement(icd_10) || typeof icd_10 === "string") {
                            return icd_10;
                        } else {
                            return (
                                <>
                                    <div>{icd_10["code"] + " - " + icd_10["text"]}</div>
                                </>
                            );
                        }
                    })}
                </p>
            </div>
            <div className="float-right mr-1" style={{ width: "15%" }}>
                <div onClick={() => moveSlice(-2)}>
                    <img
                        src={images("./icons/cal_prev.svg")}
                        style={{ transform: "rotate(90deg)" }}
                    />
                </div>
                <div onClick={() => moveSlice(2)}>
                    <img
                        src={images("./icons/cal_prev.svg")}
                        style={{ transform: "rotate(-90deg)" }}
                    />
                </div>
            </div>
        </>
    );
};

export const getPatientDiagnosis = (notes) => {
    let noteDiagnosis = getLatestDiagnosisFromNotes(notes);
    return noteDiagnosis.latestDiagnosis;
};

export const getLatestDiagnosisFromNotes = (notes) => {
    let latestDiagnosis = {
            ICD_10_axis_1_diagnosis: [],
            ICD_10_axis_2_diagnosis: [],
        },
        diagnosisDate = null;
    if (notes && notes.length > 0) {
        let latestDiagnosisNote = notes.find(
            (note) =>
                (note.hasOwnProperty("ICD_10_axis_1_diagnosis") ||
                    note.hasOwnProperty("ICD_10_axis_2_diagnosis")) &&
                note.note_state !== "draft" &&
                (!note.hasOwnProperty("content") || note.content.call_outcome !== "Attempted"),
        );
        if (latestDiagnosisNote) {
            latestDiagnosis = {
                ICD_10_axis_1_diagnosis: latestDiagnosisNote.ICD_10_axis_1_diagnosis,
                ICD_10_axis_2_diagnosis: latestDiagnosisNote.ICD_10_axis_2_diagnosis
                    ? latestDiagnosisNote.ICD_10_axis_2_diagnosis
                    : [],
            };
            diagnosisDate = moment.unix(parseFloat(latestDiagnosisNote.created_date));
        }
    }
    return { latestDiagnosis, diagnosisDate };
};
