import React, { useEffect, useState } from "react";
import { Form } from "formik";
import _ from "lodash";

import { buildDefaultHCPPatientFormValues } from "../../FormHelpers/MemberInfo/ValueBuilder";
import { HCP_VALIDATION_SCHEMA } from "../../FormHelpers/MemberInfo/ValidatorBuilder";
import { connect } from "react-redux";
import { showAlertWithAction } from "../../../../redux/actions/alerts";
import { CustomForm } from "../../../../component-library/CustomForm";
import { FormTextInput } from "../../../../component-library/FormFields/FormTextInput/FormTextInput";
import CardSecondary from "../../../UI/Card/CardSecondary";
import { FormPhoneNumberField } from "../../../../component-library/FormFields/FormPhoneNumberField/FormPhoneNumberField";
import { FormSelect } from "../../../../component-library/FormFields/FormSelect/FormSelect";
import { api } from "../../../../APIRequests";
import { Button, ButtonTypes, CheckboxV2, Text } from "../../../../component-library";
import { FormDateField } from "../../../../component-library/FormFields/FormDateField/FormDateField";
import { useLocation } from "react-router-dom";
import { Checkbox } from "../../../../component-library/Checkbox/Checkbox";

const HCPPatientInfo = ({
    currentStepName,
    update_state_and_get_next_node,
    // redux state
    configs,
}) => {
    const location = useLocation();
    const has_referred_already = new URLSearchParams(location.search).get(
        "referring_another_patient",
    );

    const [carrierOptions, setCarrierOptions] = useState([]);

    useEffect(() => {
        const getInsuranceList = async () => {
            const options = { params: { showLoader: false } };
            const result = await api.patient.fetch_insurance_list({ options });
            const carriersOptionResults = result.carriers.map((carriers) => {
                return { value: carriers, label: carriers };
            });
            setCarrierOptions(carriersOptionResults);
        };

        getInsuranceList();
    }, []);

    const renderForm = (formikProps) => {
        return (
            <Form className="HCPPatientInfo-form" enableReinitialize>
                <div className="HCPWorkflowShared-form_fields">
                    <FormTextInput
                        className="HCPWorkflowShared-form_field"
                        title="Patient’s Legal First Name"
                        name="first_name"
                        formikProps={formikProps}
                        triggerErrorOnChange
                        data-private
                    />
                    <FormTextInput
                        className="HCPWorkflowShared-form_field"
                        title="Patient’s Legal Last Name"
                        name="last_name"
                        formikProps={formikProps}
                        triggerErrorOnChange
                        data-private
                    />
                    <FormDateField
                        className="HCPWorkflowShared-form_field"
                        title="Date of Birth"
                        name="dob"
                        formikProps={formikProps}
                        placeholder="MM/DD/YYYY"
                        triggerErrorOnChange
                        data-private
                    />
                    <FormPhoneNumberField
                        className="HCPWorkflowShared-form_field"
                        title={
                            <>
                                <Text className={"font-weight-bold"}>{"Mobile Phone"}</Text>{" "}
                                <Text className={"font-weight-medium"}>{"(Optional)"}</Text>
                            </>
                        }
                        placeholder="(555)-555-5555"
                        name="mobile"
                        formikProps={formikProps}
                        triggerErrorOnChange
                        data-private
                    />
                    <FormTextInput
                        className="HCPWorkflowShared-form_field"
                        title="Patient’s Email "
                        name="email"
                        formikProps={formikProps}
                        triggerErrorOnChange
                        data-private
                    />
                    <FormTextInput
                        className="HCPWorkflowShared-form_field"
                        title="Confirm Patient’s Email"
                        name="email_validation"
                        formikProps={formikProps}
                        triggerErrorOnChange
                        data-private
                    />
                    <div className="HCPPatientInfo-has_insurance_info-container HCPWorkflowShared-form_field">
                        <FormSelect
                            className="HCPWorkflowShared-form_field"
                            title="Insurance Company"
                            placeholder="Search Insurance Company"
                            name="insurance_company"
                            options={carrierOptions}
                            formikProps={formikProps}
                            disabled={formikProps.values.does_not_have_insurance_information}
                            triggerErrorOnChange
                            dataPrivate={true}
                            testId={"HCPPatientInsuranceDropdown"}
                        />

                        <Checkbox
                            onClick={async () => {
                                if (!formikProps.values.does_not_have_insurance_information) {
                                    // reset insurance company field
                                    await formikProps.setFieldValue("insurance_company", "");
                                }
                                await formikProps.setFieldValue(
                                    "does_not_have_insurance_information",
                                    !formikProps.values.does_not_have_insurance_information,
                                );

                                if (!formikProps.touched?.does_not_have_insurance_information) {
                                    await formikProps.setFieldTouched(
                                        "does_not_have_insurance_information",
                                        true,
                                    );
                                }
                            }}
                            value={formikProps.values.does_not_have_insurance_information}
                            checked={formikProps.values.does_not_have_insurance_information}
                            label="I don’t have the patient’s insurance information"
                            labelClassName="HCPPatientInfo-has_insurance_info-label"
                            checkboxContainerClassName="HCPPatientInfo-has_insurance_info-checkbox_container"
                            data-private
                        />
                    </div>
                    <div className="HCPPatientInfo-state_of_residence-container HCPWorkflowShared-form_field">
                        <FormTextInput
                            className="HCPWorkflowShared-form_field"
                            title="State of Residence"
                            placeholder="State of Residence"
                            name="state_of_residence"
                            formikProps={formikProps}
                            readOnly
                        />
                        <p className="HCPPatientInfo-state_of_residence-text">
                            The patient must be located in New York to be referred.
                        </p>
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <Button
                        buttonType={ButtonTypes.primaryV2}
                        // button is disabled if the form has not been touched yet
                        // or if there are errors
                        disabled={!formikProps.dirty || Object.keys(formikProps.errors).length}
                        type="submit"
                        className="Btn Btn--pri self-signup-btn">
                        Submit Referral
                    </Button>
                </div>
            </Form>
        );
    };

    const submitHandler = async (formData) => {
        const initialValues = buildDefaultHCPPatientFormValues();
        const formDataToSubmit = {
            ...initialValues,
            first_name: formData.first_name,
            last_name: formData.last_name,
            email: formData.email,
            email_validation: formData.email_validation,
            dob: formData.dob,
            mobile: formData.mobile,
            primary_insurance: {
                state: "New York",
                carrier: formData.insurance_company,
                card_front: "",
                card_back: "",
                member_id: "",
                group_id: "",
                policy_holder_name: "",
                policy_holder_question: "yes",
            },
        };
        formDataToSubmit["dob"] = `${formDataToSubmit["dob"].substring(0, 2)}/${formDataToSubmit[
            "dob"
        ].substring(2, 4)}/${formDataToSubmit["dob"].substring(4, 8)}`;
        formDataToSubmit["group_type"] = "hcp_program";

        // if we pass the empty string for this field then it will get ignored
        // by our entity creation logic
        if (formDataToSubmit.mobile === "") {
            formDataToSubmit.mobile = "None";
        }

        await update_state_and_get_next_node(
            {
                does_not_have_insurance_information: formData.does_not_have_insurance_information,
                patient_info: formDataToSubmit,
            },
            currentStepName,
            <span data-public>
                Success!{" "}
                <span style={{ display: "inline" }} data-private>
                    {formData.first_name}
                </span>
                ’s referral has been submitted.
            </span>,
        );
    };

    return (
        <div className={"container hcp-mobile-container px-sm-3 py-sm-5"}>
            <CardSecondary className="HCPWorkflowShared-card">
                <div className="HCPWorkflowShared-header_container">
                    <h1 className="HCPWorkflowShared-title">
                        {has_referred_already ? "Ready to refer another patient?" : "Nearly there!"}
                    </h1>
                    <p className="HCPWorkflowShared-sub_title">
                        {has_referred_already
                            ? "We've got your information saved. Just enter the patient's information and you're good to go."
                            : "Enter the patient’s information to submit the referral."}
                    </p>
                </div>
                <CustomForm
                    initialValues={{
                        first_name: "",
                        last_name: "",
                        email: "",
                        email_validation: "",
                        dob: "",
                        mobile: "",
                        insurance_company: "",
                        state_of_residence: "New York",
                        does_not_have_insurance_information: false,
                    }}
                    validationSchema={HCP_VALIDATION_SCHEMA()}
                    onSubmit={submitHandler}
                    render={renderForm}
                />
            </CardSecondary>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        configs: state.configs,
    };
};

export default connect(mapStateToProps, { showAlertWithAction })(HCPPatientInfo);
