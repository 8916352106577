// Copyright aptihealth, inc. 2019 All Rights Reserved

import jwt_decode from "jwt-decode";
import * as actionTypes from "./actionTypes";
import { showAlert } from "./alerts";
import { api } from "../../APIRequests";
import isEmpty from "lodash/isEmpty";
import { TOAST_ALERT_2, TOAST_SUCCESS, TOAST_SUCCESS_2 } from "../../components/UI/Alert";
/*
 * The base route for the logged in user is decided in logIn action.
 * The ROLES_CONF file contains the config for setting base route
 * eg. if role is provider:pcp then base route will be /provider/*
 */
import ROLES_CONF, {
    admin_admin,
    admin_bp,
    admin_cse,
    admin_csm,
    user_patient,
} from "../../config/roles";
import { allowLoginPopUps } from "./patient";
import { clearFlagInterval, loadTargetedFlags } from "./featureFlag";
import { uploadInsuranceImageFile, UserFileTypes } from "../../utils/fileUtil";

const authSuccess = (
    token,
    refreshToken,
    accessToken,
    redirectPath,
    role,
    daysUntilPasswordReset,
) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        refreshToken,
        accessToken,
        idToken: token,
        role: role,
        redirectPath: redirectPath,
        daysUntilPasswordReset: daysUntilPasswordReset,
    };
};

const pwResetRequired = (cleanResponse) => {
    let idToken = cleanResponse.AuthenticationResult.IdToken;
    let refreshToken = cleanResponse.AuthenticationResult.RefreshToken;
    let accessToken = cleanResponse.AuthenticationResult.AccessToken;
    return {
        type: actionTypes.PW_RESET_REQUIRED,
        refreshToken,
        accessToken,
        idToken: idToken,
        errorType: "PasswordResetRequired",
    };
};

const authFail = (error, errorType) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error,
        errorType,
    };
};

const authChallenge = (response) => {
    return {
        type: actionTypes.AUTH_CHALLENGE,
        response: response,
        redirectPath: "/auth/challenge",
    };
};

export const profileFetchSuccess = (profileData) => {
    return {
        type: actionTypes.PROFILE_FETCH_SUCCESS,
        profile: profileData,
    };
};

const profileFetchFail = () => {
    return {
        type: actionTypes.PROFILE_FETCH_FAIL,
    };
};
const referralStart = () => {
    return {
        type: actionTypes.REFERRAL_START,
    };
};

const referralSuccess = (formData) => {
    return {
        type: actionTypes.REFERRAL_SUCCESS,
        formData: formData,
    };
};
const referralFail = () => {
    return {
        type: actionTypes.REFERRAL_FAIL,
    };
};

const signUpSuccess = () => {
    return {
        type: actionTypes.SIGNUP_SUCCESS,
    };
};
const signUpFail = () => {
    return {
        type: actionTypes.SIGNUP_FAIL,
    };
};

const getToken = (token, refreshToken, accessToken, redirectPath, role) => {
    return {
        type: actionTypes.LOAD_TOKEN,
        idToken: token,
        refreshToken: refreshToken,
        accessToken: accessToken,
        role: role,
        redirectPath: redirectPath,
    };
};

export const updateJWT = ({ token, refreshToken, accessToken }) => {
    return {
        type: actionTypes.UPDATE_JWT,
        token,
        refreshToken,
        accessToken,
    };
};

const dispatchLogOut = (redirectPath = "/") => {
    return {
        type: actionTypes.LOG_OUT,
        redirectPath,
    };
};

const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];

const initSessionTimeout = () => (dispatch) => {
    const sessionTimerId = "timer-" + new Date().getTime();
    localStorage.setItem(sessionTimerId, "0");
    updateAllSessionTimers("0");
    const sessionTimer = setInterval(() => {
        const elapsedTime = parseInt(localStorage.getItem(sessionTimerId)) + 1;
        localStorage.setItem(sessionTimerId, `${elapsedTime}`);
        const sessionTimeoutMinutes = 60;
        const sessionTimeoutWarning = (sessionTimeoutMinutes - 5) * 60;
        const sessionTimeout = sessionTimeoutMinutes * 60 - 1; // - 1 sec to avoid overlapping with a background request
        if (elapsedTime === sessionTimeoutWarning) {
            showAlert("You are about to be logged out due to inactivity.", dispatch);
        }
        if (elapsedTime === sessionTimeout) {
            dispatch(logOut());
            showAlert(
                `You have been logged out due to ${sessionTimeoutMinutes} minutes of inactivity`,
                dispatch,
            );
        }
    }, 1000);

    dispatch({
        type: actionTypes.SET_SESSION_TIMER,
        sessionTimer: sessionTimer,
        sessionTimerId: sessionTimerId,
    });

    for (let event of events) {
        window.addEventListener(event, resetSessionTimeout);
    }
};

const updateAllSessionTimers = (idleTime) => {
    Object.keys(localStorage)
        .filter((key) => key.startsWith("timer-"))
        .forEach((key) => localStorage.setItem(key, idleTime));
};

export const resetSessionTimeout = () => {
    updateAllSessionTimers("0");
};

export const getRoleFromToken = (token) => {
    return token["cognito:groups"][0];
};

export const getRole = () => {
    if (!window.localStorage.getItem("token")) {
        return false;
    }
    return getRoleFromToken(jwt_decode(window.localStorage.getItem("token")));
};

export const isUserPCP = () => {
    if (!window.localStorage.getItem("token")) {
        return false;
    }
    return getRoleFromToken(jwt_decode(window.localStorage.getItem("token"))) === "provider:pcp";
};

export const isUserBehavioral = () => {
    if (!window.localStorage.getItem("token")) {
        return false;
    }
    return (
        getRoleFromToken(jwt_decode(window.localStorage.getItem("token"))) === "provider:behavioral"
    );
};

export const isAuthorized = (group) => {
    if (!window.localStorage.getItem("token")) {
        return false;
    }
    return getRoleFromToken(jwt_decode(window.localStorage.getItem("token"))) === group;
};

export const isUserAdmin = () => {
    return (
        isAuthorized(admin_admin) ||
        isAuthorized(admin_csm) ||
        isAuthorized(admin_cse) ||
        isAuthorized(admin_bp)
    );
};

export const isUserPeerOrCM = () => {
    return isAuthorized("provider:peer") || isAuthorized("provider:case_manager");
};

export const isUserPatient = () => {
    return isAuthorized(user_patient);
};

export const getGroupAuthType = () => {
    return getRoleFromToken(jwt_decode(window.localStorage.getItem("token"))).split(":")[0];
};

export const getUsernameFromToken = () => {
    let idToken = window.localStorage.getItem("token");
    if (idToken) {
        return jwt_decode(idToken)["cognito:username"];
    }
};

export const getUserSubType = (profile) => {
    if (!profile) {
        return;
    }

    const groupAuthType = getGroupAuthType();
    if (!groupAuthType) {
        return;
    }

    switch (groupAuthType) {
        case "user":
            return "PATIENT";
        case "provider":
            return profile.provider_type;
        case "admin":
            return profile.user_type;
        default:
            return;
    }
};

export const loadToken = () => (dispatch) => {
    let idToken = window.localStorage.getItem("token");
    let refreshToken = window.localStorage.getItem("refreshToken");
    let accessToken = window.localStorage.getItem("accessToken");
    let role = null;
    if (idToken) {
        let decodedToken = jwt_decode(idToken);
        role = getRoleFromToken(decodedToken);
        const redirectPath = ROLES_CONF[role].routeBase;
        dispatch(getToken(idToken, refreshToken, accessToken, redirectPath, role));
        dispatch(initSessionTimeout());
        dispatch(loadTargetedFlags(decodedToken?.username));
    } else {
        return;
    }
};

export const refreshAccessToken = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        let data = {
            id_token: window.localStorage.getItem("token"),
            refresh_token: window.localStorage.getItem("refreshToken"),
        };
        api.auth
            .new_id_token({ data })
            .then((cleanResponse) => {
                dispatch({
                    type: "REFRESH_ACCESS_TOKEN",
                    payload: {
                        IdToken: cleanResponse.AuthenticationResult.IdToken,
                        AccessToken: cleanResponse.AuthenticationResult.AccessToken,
                    },
                });
                resolve(cleanResponse.AuthenticationResult.IdToken);
            })
            .catch((err) => {
                showAlert(err.message, dispatch);
                dispatch(clearFlagInterval());
                dispatch(dispatchLogOut());
                reject(err);
            });
    });
};

export const getPatientProfile =
    (showLoader = false) =>
    (dispatch) => {
        const options = { params: { showLoader } };
        return api.auth
            .fetch_profile({ options })
            .then((cleanResponse) => {
                dispatch(profileFetchSuccess(cleanResponse.user));
            })
            .catch((err) => {
                dispatch(profileFetchFail());
            });
    };

export const getProviderProfile = (providerId) => (dispatch) => {
    const options = { params: { showLoader: false } };
    const queryParams = { providerId };
    api.provider
        .fetch_provider_profile({ options, queryParams })
        .then((cleanResponse) => {
            dispatch(profileFetchSuccess(cleanResponse.user));
        })
        .catch((err) => {
            dispatch(profileFetchFail());
        });
};

export const updateReduxProfile = (profile) => (dispatch) => {
    dispatch(profileFetchSuccess(profile));
};

export const updateProviderProfile = (data, providerId) => (dispatch) => {
    const queryParams = { providerId: providerId };
    api.provider
        .update_profile({ data, queryParams })
        .then((response) => {
            dispatch(profileFetchSuccess(data));
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAdminProfile = () => (dispatch) => {
    const options = { params: { showLoader: false } };
    api.admin
        .fetch_admin_profile({ options })
        .then((cleanResponse) => {
            dispatch(profileFetchSuccess(cleanResponse));
        })
        .catch((err) => {
            dispatch(profileFetchFail());
        });
};

function doAuthentication(cleanResponse, dispatch) {
    let role = null;
    if (isEmpty(cleanResponse["ChallengeParameters"])) {
        let idToken = cleanResponse.AuthenticationResult.IdToken;
        let refreshToken = cleanResponse.AuthenticationResult.RefreshToken;
        let accessToken = cleanResponse.AuthenticationResult.AccessToken;
        let decodedToken = jwt_decode(idToken);
        role = getRoleFromToken(decodedToken);
        const redirectPath = ROLES_CONF[role].routeBase;
        const days_until_reset = cleanResponse.days_until_reset;
        dispatch(
            authSuccess(idToken, refreshToken, accessToken, redirectPath, role, days_until_reset),
        );
        dispatch(initSessionTimeout());
        dispatch(loadTargetedFlags(decodedToken?.username));
    } else {
        dispatch(authChallenge(cleanResponse));
    }
}

export const logIn = (creds) => (dispatch) => {
    return api.auth
        .login({ data: creds })
        .then((cleanResponse) => {
            if (
                Number(cleanResponse.days_until_reset) <= 0 &&
                isEmpty(cleanResponse["ChallengeParameters"])
            ) {
                dispatch(pwResetRequired(cleanResponse));
            } else {
                doAuthentication(cleanResponse, dispatch);
            }
            if (isAuthorized(user_patient)) {
                dispatch(allowLoginPopUps());
            }
        })
        .catch((err) => {
            dispatch(authFail(err.message, err.type));
            showAlert(err.message, dispatch);
        });
};

export const authChallengeRequest = (formData) => (dispatch) => {
    api.auth
        .auth_challenge({ data: formData })
        .then((cleanResponse) => {
            doAuthentication(cleanResponse, dispatch);
        })
        .catch((err) => {
            dispatch(authFail(err.message));
            showAlert(err.message, dispatch);
        });
};

export const clearAuthChallenge = () => (dispatch) => {
    dispatch({
        type: actionTypes.CLEAR_AUTH_CHALLENGE,
    });
};

export const completeAutoSignUp = (formData) => (dispatch) => {
    api.auth
        .complete_auto_signup({ data: formData })
        .then((cleanResponse) => {
            doAuthentication(cleanResponse, dispatch);
        })
        .catch((err) => {
            dispatch(authFail(err.message));
            showAlert(err.message, dispatch);
        });
};

export const workflowSetPassword =
    ({ options, data }) =>
    (dispatch) => {
        api.shared
            .set_password({ options, data })
            .then((cleanResponse) => {
                const auth_data = cleanResponse.auth_data;
                doAuthentication(auth_data, dispatch);
            })
            .catch((err) => {
                dispatch(authFail(err.message));
                showAlert(err.message, dispatch);
            });
    };

export const logOut =
    ({ redirectPath = "/", useRedirectScreen = false, history = null } = {}) =>
    (dispatch) => {
        if (useRedirectScreen && history) {
            history.replace("/redirect");
        }

        dispatch(clearFlagInterval());
        dispatch(dispatchLogOut(redirectPath));
        // prevents the current page from re-rendering trying to access
        // state information that is wiped by dispatching the above actions
        if (history && !useRedirectScreen) {
            history.replace("/auth/sign-in");
        }

        for (let event of events) {
            window.removeEventListener(event, resetSessionTimeout);
        }
    };

export const signUp = (formData) => (dispatch) => {
    api.auth
        .sign_up({ data: formData })
        .then((cleanResponse) => {
            dispatch(
                signUpSuccess(),
            );
            dispatch(
                logIn({
                    USERNAME: formData.Username,
                    PASSWORD: formData.Password,
                }),
            );
        })
        .catch((err) => {
            dispatch(signUpFail());
            showAlert(err.message, dispatch);
        });
};

export const showEmailVerified = (showEmailVerified) => (dispatch) => {
    if (showEmailVerified) {
        showAlert("Email Address Verified. Please Login", dispatch, TOAST_SUCCESS_2);
    }
    dispatch({
        type: actionTypes.SHOW_EMAIL_VERIFIED,
        showEmailVerified: showEmailVerified,
    });
    dispatch(allowLoginPopUps(true));
};

export const showSuccessfulSubmission = () => (dispatch) => {
    showAlert(
        "Thanks, you will be notified when your child becomes eligible.",
        dispatch,
        TOAST_SUCCESS_2,
    );
};

export const mailResetPasswordCode = (email) => (dispatch) => {
    api.auth
        .mail_forgot_pw_code({ data: { Username: email } })
        .then((cleanResponse) => {
            dispatch({ type: actionTypes.RS_PW_CODE_SENT, payload: email });
            showAlert(
                "Please check your email and follow the instructions to reset your password.",
                dispatch,
                TOAST_ALERT_2,
            );
        })
        .catch((err) => {
            showAlert(err.message, dispatch);
        });
};

export const alreadyHaveACode = (email) => (dispatch) => {
    dispatch({ type: actionTypes.RS_PW_CODE_SENT, payload: email });
};

export const resetForgotPwStep = () => (dispatch) => {
    dispatch({ type: actionTypes.RS_FORGOT_PW_STEPS });
};
export const resetPw = (requestObject, cb) => (dispatch) => {
    api.auth
        .reset_forgotten_pw({ data: requestObject })
        .then((cleanResponse) => {
            showAlert(
                "You’ve successfully reset your password! Log in with your credentials below.",
                dispatch,
                TOAST_ALERT_2,
            );
            cb(true);
        })
        .catch((err) => {
            showAlert(err.message, dispatch);
            cb(false);
        });
};

export const changePassword = (requestObject, cb) => (dispatch) => {
    api.auth
        .change_password({ data: requestObject })
        .then((cleanResponse) => {
            cb(true);
        })
        .catch((err) => {
            showAlert(err.message, dispatch);
            cb(false);
        });
};

export const setAuthAfterPasswordReset =
    (requestObject, accessToken, cleanResponse) => (dispatch) => {
        api.auth
            .change_password({ data: requestObject, accessToken })
            .then((success) => {
                doAuthentication(cleanResponse, dispatch);
            })
            .catch((err) => {
                showAlert(err.message, dispatch);
            });
    };

export const hideRsPwSuccess = () => (dispatch) => {
    dispatch({ type: actionTypes.HIDE_RS_PW_SUCCESS });
};

export const validateReferral = (referralCode, cb) => (dispatch) => {
    api.auth
        .validate_referral({ data: { referralCode } })
        .then((cleanResponse) => {
            if (cleanResponse) {
                dispatch(referralSuccess(cleanResponse.user));
                cb();
            } else {
                dispatch(referralFail());
            }
        })
        .catch((err) => {
            dispatch(referralFail());
            showAlert(err.message, dispatch);
        });
};

export const uploadPatientInsuranceImage = (imgData, attr, cb, entityId) => (dispatch) => {
    const { file, fileName } = imgData;

    const onSuccessCallback = (base64Img) => {
        showAlert("Image uploaded", dispatch, TOAST_SUCCESS);
        switch (attr) {
            case "pf":
                dispatch({
                    type: actionTypes.SET_FRONT_PRI_INS_IMAGE,
                    payload: { url: fileName, preview: base64Img },
                });
                break;
            case "pb":
                dispatch({
                    type: actionTypes.SET_BACK_PRI_INS_IMAGE,
                    payload: { url: fileName, preview: base64Img },
                });
                break;
            case "sf":
                dispatch({
                    type: actionTypes.SET_FRONT_SEC_INS_IMAGE,
                    payload: { url: fileName, preview: base64Img },
                });
                break;
            case "sb":
                dispatch({
                    type: actionTypes.SET_BACK_SEC_INS_IMAGE,
                    payload: { url: fileName, preview: base64Img },
                });
                break;
            default:
                dispatch({
                    type: actionTypes.SET_FRONT_PRI_INS_IMAGE,
                    payload: { url: fileName, preview: base64Img },
                });
        }
        cb(attr);
    };

    try {
        uploadInsuranceImageFile(file, fileName, entityId, UserFileTypes.PATIENT_INSURANCE_IMAGE)
            .then(onSuccessCallback)
            .catch((err) => {
                showAlert(err.message, dispatch);
            });
    } catch (err) {
        showAlert(err.message, dispatch);
    }
};

export const unsetInsImage = (attr) => (dispatch) => {
    switch (attr) {
        case "pf":
            dispatch({ type: actionTypes.UNSET_FRONT_PRI_INS_IMAGE });
            break;
        case "pb":
            dispatch({ type: actionTypes.UNSET_BACK_PRI_INS_IMAGE });
            break;

        case "sf":
            dispatch({ type: actionTypes.UNSET_FRONT_SEC_INS_IMAGE });
            break;

        case "sb":
            dispatch({ type: actionTypes.UNSET_BACK_SEC_INS_IMAGE });
            break;

        case "pri":
            dispatch({ type: actionTypes.UNSET_PRI_INS_IMAGES });
            break;

        case "sec":
            dispatch({ type: actionTypes.UNSET_SEC_INS_IMAGES });
            break;

        default:
            return;
    }
};

export const logInWithExternalProvider = (data) => (dispatch) => {
    api.auth
        .custom_auth({ data })
        .then((cleanResponse) => {
            doAuthentication(cleanResponse, dispatch);
        })
        .catch((err) => {
            dispatch(authFail(err.message));
            showAlert(err.message, dispatch);
        });
};
