// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component, Fragment } from "react";
import QuestionnaireLayout from "../../../../components/Common/QuestionnaireLayout";
import { RatingSixBox } from "../../../../components/Common/QuestionnaireWidgets";
import RatingScaleView from "../../../../components/Common/RatingScaleView";
import { connect } from "react-redux";
import { setDynamicRouteConfiguration } from "../../../../redux/actions/navbar";
import { providerRoute } from "../../../../config/roles";
import { getQueryParams } from "../../../../utils/filters";
import { api } from "../../../../APIRequests";

class Ratings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: this.props.match.params.patientId,
            patientName: "",
        };
    }
    componentDidMount = async () => {
        let link = `${providerRoute}/patient/${this.state.patientId}/profile`;

        const dynamicRouteConfig = {};
        dynamicRouteConfig["menuSection"] = { icon: "./icons/back-arrow.svg", link };
        dynamicRouteConfig["logoSection"] = { title: "Patient Rating" };

        this.props.setDynamicRouteConfiguration(dynamicRouteConfig);

        try {
            let urlParams = { patientId: this.state.patientId };
            const options = { params: { showLoader: false } };
            let response = await api.shared.fetch_patient_details({ urlParams, options });
            if (response.user) {
                this.setState({
                    patientName: response.user.first_name + " " + response.user.last_name,
                });
            }
            if (response.user && response.user.rating_scale) {
                this.setState({
                    ratingQuestions: response.user.rating_scale,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    componentWillUnmount() {
        this.props.setDynamicRouteConfiguration({});
    }

    render() {
        let headerRightContent = (
            <div className="fw-bold mx-2 ml-lg-auto mt-2 mb-4 px-lg-2">
                <span>
                    Patient Name: <span className="txt-sec">{this.state.patientName}</span>
                </span>
            </div>
        );

        return (
            <div>
                {this.state.ratingQuestions && (
                    <RatingScaleView
                        questions={this.state.ratingQuestions}
                        mainHeading="Patient Rating"
                        headerRightContent={!this.props.excludeName ? headerRightContent : null}
                        readOnly
                        isNestedComponent={this.props.isNestedComponent}
                        backLinkConf={
                            !this.props.excludeBackLink
                                ? {
                                      path: `${providerRoute}/patient/${this.state.patientId}/profile`,
                                      text: "Back to Patient Profile",
                                  }
                                : null
                        }
                    />
                )}
            </div>
        );
    }
}

export default connect(null, {
    setDynamicRouteConfiguration,
})(Ratings);
