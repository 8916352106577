import * as actionTypes from "./actionTypes";
import { http } from "../../utils/axios";

export const updateInsuranceList = (data) => {
    return {
        type: actionTypes.UPDATE_INSURANCE_LIST,
        payload: {
            insuranceList: data,
        },
    };
};

// ----------- Thunks --------------

export const fetchInsuranceList = (options) => (dispatch) => {
    let conf = {
        method: "post",
        url: "/v2/patient/insurance",
        ...options,
    };

    return http(conf).then((data) => {
        dispatch(updateInsuranceList(data));
        return data;
    });
};
