// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import { Formik } from "formik";
import FormikInput from "../UI/FormikInput";
import Button from "../UI/Button";
import * as Yup from "yup";
import { authChallengeRequest } from "../../redux/actions/auth";
import { connect } from "react-redux";
import { AuthWrapper } from "./index";
import { yup_password_factory } from "../../utils/yupSchemaUtils";
import { CustomForm } from "../../component-library/CustomForm";

const validation = Yup.object().shape({
    NEW_PASSWORD: yup_password_factory(),
    ConfirmPassword: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref("NEW_PASSWORD"), null], "Password does not match"),
});

const AuthChallenge = (props) => {
    let setNewPasswordHandler = (form, actions) => {
        let formCopy = Object.assign({}, form);
        delete formCopy["ConfirmPassword"];
        formCopy["USERNAME"] = props.username;
        formCopy["Session"] = props.session;
        props.authChallengeRequest(formCopy);
    };

    return (
        <AuthWrapper showBackLink={true}>
            <section className="ResetPassword">
                <CustomForm
                    validationSchema={validation}
                    onSubmit={setNewPasswordHandler}
                    initialValues={{ NEW_PASSWORD: "", ConfirmPassword: "" }}
                    render={(formikProps) => (
                        <form onSubmit={formikProps.handleSubmit}>
                            <FormikInput
                                errors={formikProps.errors}
                                touched={formikProps.touched}
                                formEl={{
                                    name: "NEW_PASSWORD",
                                    elementType: "input",
                                    elementConfig: {
                                        type: "password",
                                        label: "New Password",
                                        placeholder: "Enter new password",
                                    },
                                }}
                            />
                            <FormikInput
                                errors={formikProps.errors}
                                touched={formikProps.touched}
                                formEl={{
                                    name: "ConfirmPassword",
                                    elementType: "input",
                                    elementConfig: {
                                        type: "password",
                                        label: "Confirm New Password",
                                        placeholder: "Confirm New Password",
                                    },
                                }}
                            />
                            <div className="ResetPassword__submit-btn-wpr d-flex justify-content-center">
                                <Button
                                    disabled={formikProps.isSubmitting}
                                    type="submit"
                                    className="Btn Btn--pri Btn-sm mt-5 ">
                                    Change Password
                                </Button>
                            </div>
                        </form>
                    )}
                />
            </section>
        </AuthWrapper>
    );
};

const mapStateToProps = (state) => {
    return {
        username: state.auth.username,
        session: state.auth.session,
    };
};

export default connect(mapStateToProps, { authChallengeRequest })(AuthChallenge);
