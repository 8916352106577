import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { createClassNameString } from "../../component-library";
import { ICON_SIZES, ICON_TYPES } from "../../constants/icons";

export const CardIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}>
                <path d="M19 21H25V19H19V21Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 22C3 23.6569 4.34315 25 6 25H26C27.6569 25 29 23.6569 29 22V10C29 8.34315 27.6569 7 26 7H6C4.34315 7 3 8.34315 3 10V22ZM26 9H6C5.44772 9 5 9.44772 5 10V11H27V10C27 9.44772 26.5523 9 26 9ZM27 22V14H5V22C5 22.5523 5.44772 23 6 23H26C26.5523 23 27 22.5523 27 22Z"
                />
            </svg>
        );
    }

    return null;
};

CardIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

CardIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
