export const STATES = [
    // State restricted to New York - AT1-2135
    // { value: 'Alabama', display: 'Alabama' },
    // { value: 'Alaska', display: 'Alaska' },
    // { value: 'Arizona', display: 'Arizona' },
    // { value: 'Arkansas', display: 'Arkansas' },
    // { value: 'California', display: 'California' },
    // { value: 'Colorado', display: 'Colorado' },
    // { value: 'Connecticut', display: 'Connecticut' },
    // { value: 'Delaware', display: 'Delaware' },
    // { value: 'District of Columbia', display: 'District of Columbia' },
    // { value: 'Florida', display: 'Florida' },
    // { value: 'Georgia', display: 'Georgia' },
    // { value: 'Hawaii', display: 'Hawaii' },
    // { value: 'Idaho', display: 'Idaho' },
    // { value: 'Illinois', display: 'Illinois' },
    // { value: 'Indiana', display: 'Indiana' },
    // { value: 'Iowa', display: 'Iowa' },
    // { value: 'Kansas', display: 'Kansas' },
    // { value: 'Kentucky', display: 'Kentucky' },
    // { value: 'Louisiana', display: 'Louisiana' },
    // { value: 'Maine', display: 'Maine' },
    // { value: 'Maryland', display: 'Maryland' },
    // { value: 'Massachusetts', display: 'Massachusetts' },
    // { value: 'Michigan', display: 'Michigan' },
    // { value: 'Minnesota', display: 'Minnesota' },
    // { value: 'Mississippi', display: 'Mississippi' },
    // { value: 'Missouri', display: 'Missouri' },
    // { value: 'Montana', display: 'Montana' },
    // { value: 'Nebraska', display: 'Nebraska' },
    // { value: 'Nevada', display: 'Nevada' },
    // { value: 'New Hampshire', display: 'New Hampshire' },
    // { value: 'New Jersey', display: 'New Jersey' },
    // { value: 'New Mexico', display: 'New Mexico' },
    { value: "New York", display: "New York" },
    // { value: 'North Carolina', display: 'North Carolina' },
    // { value: 'North Dakota', display: 'North Dakota' },
    // { value: 'Ohio', display: 'Ohio' },
    // { value: 'Oklahoma', display: 'Oklahoma' },
    // { value: 'Oregon', display: 'Oregon' },
    // { value: 'Pennsylvania', display: 'Pennsylvania' },
    // { value: 'Rhode Island', display: 'Rhode Island' },
    // { value: 'South Carolina', display: 'South Carolina' },
    // { value: 'South Dakota', display: 'South Dakota' },
    // { value: 'Tennessee', display: 'Tennessee' },
    // { value: 'Texas', display: 'Texas' },
    // { value: 'Utah', display: 'Utah' },
    // { value: 'Vermont', display: 'Vermont' },
    // { value: 'Virginia', display: 'Virginia' },
    // { value: 'Washington', display: 'Washington' },
    // { value: 'West Virginia', display: 'West Virginia' },
    // { value: 'Wisconsin', display: 'Wisconsin' },
    // { value: 'Wyoming', display: 'Wyoming' },
];

export const ALL_STATES = [
    // State restricted to New York - AT1-2135
    { value: "Alabama", display: "Alabama" },
    { value: "Alaska", display: "Alaska" },
    { value: "Arizona", display: "Arizona" },
    { value: "Arkansas", display: "Arkansas" },
    { value: "California", display: "California" },
    { value: "Colorado", display: "Colorado" },
    { value: "Connecticut", display: "Connecticut" },
    { value: "Delaware", display: "Delaware" },
    { value: "District of Columbia", display: "District of Columbia" },
    { value: "Florida", display: "Florida" },
    { value: "Georgia", display: "Georgia" },
    { value: "Hawaii", display: "Hawaii" },
    { value: "Idaho", display: "Idaho" },
    { value: "Illinois", display: "Illinois" },
    { value: "Indiana", display: "Indiana" },
    { value: "Iowa", display: "Iowa" },
    { value: "Kansas", display: "Kansas" },
    { value: "Kentucky", display: "Kentucky" },
    { value: "Louisiana", display: "Louisiana" },
    { value: "Maine", display: "Maine" },
    { value: "Maryland", display: "Maryland" },
    { value: "Massachusetts", display: "Massachusetts" },
    { value: "Michigan", display: "Michigan" },
    { value: "Minnesota", display: "Minnesota" },
    { value: "Mississippi", display: "Mississippi" },
    { value: "Missouri", display: "Missouri" },
    { value: "Montana", display: "Montana" },
    { value: "Nebraska", display: "Nebraska" },
    { value: "Nevada", display: "Nevada" },
    { value: "New Hampshire", display: "New Hampshire" },
    { value: "New Jersey", display: "New Jersey" },
    { value: "New Mexico", display: "New Mexico" },
    { value: "New York", display: "New York" },
    { value: "North Carolina", display: "North Carolina" },
    { value: "North Dakota", display: "North Dakota" },
    { value: "Ohio", display: "Ohio" },
    { value: "Oklahoma", display: "Oklahoma" },
    { value: "Oregon", display: "Oregon" },
    { value: "Pennsylvania", display: "Pennsylvania" },
    { value: "Rhode Island", display: "Rhode Island" },
    { value: "South Carolina", display: "South Carolina" },
    { value: "South Dakota", display: "South Dakota" },
    { value: "Tennessee", display: "Tennessee" },
    { value: "Texas", display: "Texas" },
    { value: "Utah", display: "Utah" },
    { value: "Vermont", display: "Vermont" },
    { value: "Virginia", display: "Virginia" },
    { value: "Washington", display: "Washington" },
    { value: "West Virginia", display: "West Virginia" },
    { value: "Wisconsin", display: "Wisconsin" },
    { value: "Wyoming", display: "Wyoming" },
];
