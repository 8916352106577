import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Text, TextTypes, Card, Toggle } from "../../../../../component-library";
import Hr from "../../../../UI/Hr";
import { api } from "../../../../../APIRequests";
import CardHeading from "../../../../UI/Headings/CardHeading";
import { addToast, toastMessageTypes } from "../../../../../redux/actions/toaster";

import "./styles.scss";

const ACTION_DESCRIPTION =
    "Real-time eligibility check at the time of service determines a patient’s financial responsibility. \
By default, patients are charged automatically. If we don’t want to auto-charge this patient, turn off this toggle. \
Whenever insurance info is updated, automatic charging resets to on.";
const DISABLED_DESCRIPTION =
    "This insurance carrier is not configured for real time eligibility checking";

const NOT_CONFIGURED_INSURANCE_STATUS = "not_configured";

const OverridePatientAutocharge = (props) => {
    const patientId = props.match.params.patientId;
    const patientCarrier = props.patientDetails?.primary_insurance?.carrier;
    const ENABLED_CARRIER_PAYWALL_CONFIG = props.configs?.ENABLED_CARRIER_PAYWALL?.enabled_carriers;
    const { automatic_payment_enabled, insurance_status } = props.patientDetails;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [automaticPaymentStatus, setAutomaticPaymentStatus] = useState(automatic_payment_enabled);

    const submitChangeAutomaticPaymentStatus = async (automaticPaymentStatus) => {
        const changePaymentData = {
            patient_id: patientId,
            automatic_payment_enabled: automaticPaymentStatus,
        };

        await api.admin.change_automatic_payment_enabled({ data: changePaymentData });

        setAutomaticPaymentStatus(automaticPaymentStatus);
        const newPatientDetails = {
            ...props.patientDetails,
            automatic_payment_enabled: automaticPaymentStatus,
        };
        const activityResponse = await api.shared.fetch_patient_activity_feed({
            urlParams: { patientId },
        });
        const newTimelineData = { ...props.timelineData };
        newTimelineData["activities"] = activityResponse.results;
        props.updateState({ patientDetails: newPatientDetails, timelineData: newTimelineData });
    };

    const handleChange = async () => {
        const submittedAutomaticPaymentStatus = !automaticPaymentStatus;
        setAutomaticPaymentStatus(submittedAutomaticPaymentStatus);

        setIsSubmitting(true);
        await submitChangeAutomaticPaymentStatus(submittedAutomaticPaymentStatus);
        setIsSubmitting(false);

        props.dispatch(
            addToast({
                message: "Update saved.",
                messageType: toastMessageTypes.success,
            }),
        );
    };

    const isToggleDisabled = (notConfigured, isCarrierEnabledForPaywall, isSubmitting) => {
        let isDisabled = true;

        if (!notConfigured) {
            isDisabled = false;
        }

        if (notConfigured && isCarrierEnabledForPaywall) {
            isDisabled = false;
        }

        if (isSubmitting) {
            isDisabled = true;
        }

        return isDisabled;
    };

    const isCarrierEnabledForPaywall = ENABLED_CARRIER_PAYWALL_CONFIG[patientCarrier];
    const notConfigured = insurance_status === NOT_CONFIGURED_INSURANCE_STATUS;
    const isDisabled = isToggleDisabled(notConfigured, isCarrierEnabledForPaywall, isSubmitting);

    return (
        <div className={"AdminTab__OverridePatientAutocharge"}>
            <Card className={"p-3 p-lg-4 mb-3 mb-lg-4"}>
                <div className="d-flex justify-content-between">
                    <CardHeading className="fs-16 m-0 mb-3" text="Payment Processing" />
                </div>
                <Hr />
                <div className="no-gutters row w-100">
                    <div className="mt-4">
                        <p className={"admin-card-description mb-0"}>
                            {notConfigured ? DISABLED_DESCRIPTION : ACTION_DESCRIPTION}
                        </p>
                        <div className={"mt-3"} style={{ display: "flex", alignItems: "center" }}>
                            <Toggle
                                checked={isDisabled ? false : automaticPaymentStatus}
                                disabled={isDisabled}
                                onChange={handleChange}
                                style={{ marginRight: 10 }}
                            />
                            <Text
                                type={TextTypes.paragraph}
                                style={isDisabled ? { opacity: 0.6 } : {}}>
                                Automatic Charging
                            </Text>
                        </div>
                    </div>
                </div>
                <div className="no-gutters row w-100" style={{ marginTop: 5 }}>
                    <Text
                        className="OverridePatientAutocharge__link"
                        type={TextTypes.link}
                        onClick={() => props.selectedTabChangeHandler("Medical")}>
                        Manage Insurance
                    </Text>
                </div>
            </Card>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    dispatch,
});

const mapStateToProps = (state) => {
    return {
        configs: state.configs,
        flags: state.flags,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OverridePatientAutocharge));
