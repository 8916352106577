/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const EllipsisVerticalIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="EllipsisVertical"
                {...props}>
                <path d="M16 7.75C15.0335 7.75 14.25 8.5335 14.25 9.5C14.25 10.4665 15.0335 11.25 16 11.25C16.9665 11.25 17.75 10.4665 17.75 9.5C17.75 8.5335 16.9665 7.75 16 7.75Z" />
                <path d="M16 14.25C15.0335 14.25 14.25 15.0335 14.25 16C14.25 16.9665 15.0335 17.75 16 17.75C16.9665 17.75 17.75 16.9665 17.75 16C17.75 15.0335 16.9665 14.25 16 14.25Z" />
                <path d="M16 20.75C15.0335 20.75 14.25 21.5335 14.25 22.5C14.25 23.4665 15.0335 24.25 16 24.25C16.9665 24.25 17.75 23.4665 17.75 22.5C17.75 21.5335 16.9665 20.75 16 20.75Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="EllipsisVertical"
                {...props}>
                <path d="M16 7C14.6193 7 13.5 8.11929 13.5 9.5C13.5 10.8807 14.6193 12 16 12C17.3807 12 18.5 10.8807 18.5 9.5C18.5 8.11929 17.3807 7 16 7Z" />
                <path d="M16 13.5C14.6193 13.5 13.5 14.6193 13.5 16C13.5 17.3807 14.6193 18.5 16 18.5C17.3807 18.5 18.5 17.3807 18.5 16C18.5 14.6193 17.3807 13.5 16 13.5Z" />
                <path d="M16 20C14.6193 20 13.5 21.1193 13.5 22.5C13.5 23.8807 14.6193 25 16 25C17.3807 25 18.5 23.8807 18.5 22.5C18.5 21.1193 17.3807 20 16 20Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="EllipsisVertical"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20.25 13.25C19.2145 13.25 18.375 14.0895 18.375 15.125C18.375 16.1605 19.2145 17 20.25 17C21.2855 17 22.125 16.1605 22.125 15.125C22.125 14.0895 21.2855 13.25 20.25 13.25ZM20.25 18.125C19.2145 18.125 18.375 18.9645 18.375 20C18.375 21.0355 19.2145 21.875 20.25 21.875C21.2855 21.875 22.125 21.0355 22.125 20C22.125 18.9645 21.2855 18.125 20.25 18.125ZM18.375 24.875C18.375 23.8395 19.2145 23 20.25 23C21.2855 23 22.125 23.8395 22.125 24.875C22.125 25.9105 21.2855 26.75 20.25 26.75C19.2145 26.75 18.375 25.9105 18.375 24.875Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

EllipsisVerticalIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

EllipsisVerticalIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
