import { useState } from "react";
import { CalendarDeleteModal, CalendarDeleteSeriesModalContent } from "./CalendarDeleteModal";
import "./styles.scss";
import React from "react";

const recurringRadioOptions = [
    {
        label: "This event",
        value: "this_event",
    },
    {
        label: "This and future events",
        value: "this_and_future_events",
    },
];

export const RECURRING_AVAILABILITY_VALUE = recurringRadioOptions[1].value;

const CalendarDeleteAvailabilityModalRecurring = ({ subTitleText, onCancel, onSubmit }) => {
    const [deleteSeries, setDeleteSeries] = useState(undefined);

    const deleteSeriesOnChange = (e) => {
        setDeleteSeries(e.target.value);
    };

    return (
        <CalendarDeleteModal
            title="You're cancelling recurring custom availability"
            subTitle={subTitleText}
            onCancel={onCancel}
            onCancelText="Keep Availability"
            onSubmit={() => {
                onSubmit(deleteSeries);
            }}
            onSubmitText={
                deleteSeries === recurringRadioOptions[1].value
                    ? "Cancel Future Events"
                    : "Cancel Event"
            }
            disableSubmit={!deleteSeries}>
            <CalendarDeleteSeriesModalContent
                title="Cancel"
                deleteSeriesValue={deleteSeries}
                deleteSeriesOnChange={deleteSeriesOnChange}
                radioOptions={recurringRadioOptions}
            />
        </CalendarDeleteModal>
    );
};

const CalendarDeleteAvailabilityModalSingle = ({ subTitleText, onCancel, onSubmit }) => {
    return (
        <CalendarDeleteModal
            title="You're cancelling custom availability"
            subTitle={subTitleText}
            showSubTitleOnNewLine={true}
            onCancel={onCancel}
            onCancelText="Keep Availability"
            onSubmit={onSubmit}
            onSubmitText="Cancel Event"
        />
    );
};

export const CalendarDeleteAvailabilityModal = ({
    subTitleText,
    onCancel,
    onSubmit,
    isRecurring,
}) => {
    return (
        <>
            {isRecurring && (
                <CalendarDeleteAvailabilityModalRecurring
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    subTitleText={subTitleText}
                />
            )}
            {!isRecurring && (
                <CalendarDeleteAvailabilityModalSingle
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    subTitleText={subTitleText}
                />
            )}
        </>
    );
};
