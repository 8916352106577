import * as actionTypes from "./actionTypes";
import { TOAST_ERROR } from "../../components/UI/Alert";

export const setExceptionReporting = (exceptionReportConf) => {
    return {
        type: actionTypes.SET_EXCEPTION_REPORT,
        exceptionReportConf,
    };
};

export const unsetExceptionReporting = () => {
    return {
        type: actionTypes.UNSET_EXCEPTION_REPORT,
    };
};

export const showExceptionReporting = (dispatch, requestId) => {
    dispatch(
        setExceptionReporting({
            requestId,
            dismiss: () => {
                dispatch(unsetExceptionReporting());
            },
        }),
    );
};
