// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { useState } from "react";
import DatePicker from "../../UI/DatePicker";
import { daySelectHandler } from "../../../containers/Dashboard/Patient/SchedulePage";
import { TimeSlot } from "../../Patient/Schedule";
import moment from "moment-timezone";

const ScheduleWidgets = (props) => {
    return (
        <div className="Schedule__widgets col-12 col-lg-10 p-lg-4 pb-lg-5">
            <div className="row no-gutters mx-lg-3">
                <div className="col-12 col-lg-5">
                    <h4 className="d-none d-lg-block fs-18 mt-2 mb-4">
                        <b>Select a date</b>
                    </h4>
                    <DatePicker
                        mobileCardView
                        loadingSlots={props.timeSlotsLoading}
                        noOfSlots={props.timeSlots && props.timeSlots.length}
                        onDaySelect={(date) => daySelectHandler(date, props.that)}
                        externalCtx={props.selectedDate}
                    />
                </div>
                <div className="col-12 col-lg-7 px-3 px-lg-0">
                    <TimeSlot
                        conf={props.timeSlotConf}
                        timezone={props.timezone}
                        click={props.onTimeSlotClick}
                        onaddingSlot={props.onAddingSlot}
                        timeSlots={props.timeSlots}
                        timeSlotsLoading={props.timeSlotsLoading}
                        dateStr={props.dateStr}
                        eventType={props.eventType}
                        onEventTypeChange={props.onEventTypeChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default ScheduleWidgets;
