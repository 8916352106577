// Copyright aptihealth, inc. 2021 All Rights Reserved
import React, { Component } from "react";

import { Form, Formik, getIn } from "formik";
import Button from "../../../../../components/UI/Button";
import { api } from "../../../../../APIRequests";
import { getQueryParams } from "../../../../../utils/filters";
import moment from "moment-timezone";
import { connect } from "react-redux";
import images from "../../../../../utils/images";
import { CardPrimary } from "../../../../UI/Card";
import FormikInput from "../../../../UI/FormikInput";
import Label from "../../../../UI/Label";
import InputError from "../../../../UI/InputError";
import { cloneDeep, range } from "lodash";
import { default as ReactSelect } from "react-select";
import { withRouter } from "react-router-dom";
import {
    CARE_COMMUNICATION_INITIAL_VALUES,
    CARE_COMMUNICATION_SCHEMA,
    CARE_COMMUNICATION_VALIDATION,
} from "./CareCommunicationFormSchema";
import { ENCOUNTER_INITIAL_VALUES } from "./Encounter/EncounterSchema";
import EncounterForm from "./Encounter/EncounterForm";
import DatePickerWithDisplay from "../../../../UI/DatePickerWithDisplay";
import { allottedTimeFactory } from "../../../ScheduleAvailability/constants";
import CardSecondary from "../../../../UI/Card/CardSecondary";
import GenericQuestionaire from "../../../../../containers/Dashboard/Patient/questionaires/GenericQuestionaire";
import { SCREENING_OPTIONS_LABEL_MAP } from "../MemberProfileNotes";
import { MemberHeaderHOC } from "../../../../../component-library/hocs/MemberHeaderHOC";
import "./styles.scss";
import { CustomForm } from "../../../../../component-library/CustomForm";

class CareCommunicationForm extends Component {
    state = {
        patientDiagnosis: null,
        initialValues: null,
        schema: null,
        icd10Axis1: null,
        icd10Axis2: null,
        patientId: this.props.match.params.patientId,
        patientName: "",
        questionList: null,
    };
    async componentDidMount() {
        const icd10response = await api.provider.fetch_ICD10_dataset();
        const newState = {
            icd10Axis1: icd10response.filter((icd) => icd.axis === "1"),
            icd10Axis2: icd10response.filter((icd) => icd.axis === "2"),
        };
        const urlParams = { patientId: this.props.match.params.patientId };
        newState["patientDiagnosis"] = await api.shared.get_patient_diagnosis({ urlParams });

        const patientDetails = await api.shared.fetch_patient_details({ urlParams });
        newState["patientName"] =
            patientDetails.user.first_name + " " + patientDetails.user.last_name;

        this.setState({
            questionList: {
                gad: (await api.patient.fetch_GAD7_questions())["question"],
                phq: (await api.patient.fetch_PHQ9_questions())["question"],
            },
        });

        const initialValues = cloneDeep(CARE_COMMUNICATION_INITIAL_VALUES);
        const providerType = this.props.profile.provider_type;
        initialValues.patient_id = this.state.patientId;
        initialValues.patient_first_name = patientDetails.user.first_name;
        initialValues.patient_last_name = patientDetails.user.last_name;
        initialValues.provider_id = this.props.profile.username;
        initialValues.provider_type = providerType;
        initialValues.provider_cp_credentials = this.props.profile.cp_credentials;
        initialValues.provider_name = this.props.profile.name;
        initialValues.session_date = moment().format("MM/DD/YYYY");
        initialValues.ICD_10_axis_1_diagnosis =
            newState["patientDiagnosis"].ICD_10_axis_1_diagnosis;
        initialValues.ICD_10_axis_2_diagnosis =
            newState["patientDiagnosis"].ICD_10_axis_2_diagnosis;
        initialValues.content.encounters = [];
        newState["initialValues"] = initialValues;

        const schema = cloneDeep(CARE_COMMUNICATION_SCHEMA);
        const cptCodesFormEl = schema.find((formEl) => formEl.name === "call_type");
        cptCodesFormEl.elementConfig.options = cptCodesFormEl.elementConfig.options.filter(
            (option) => option.provider_types.includes(providerType),
        );
        newState["schema"] = schema;
        this.setState(newState);
    }

    addEncounter = async (formikProps) => {
        const updatedEncounters = [...formikProps.values.content.encounters];
        updatedEncounters.push(cloneDeep(ENCOUNTER_INITIAL_VALUES));
        await formikProps.setFieldValue("content.encounters", updatedEncounters);
    };

    removeEncounter = async (formikProps, encounterIndex) => {
        const updatedEncounters = [...formikProps.values.content.encounters];
        updatedEncounters[encounterIndex].delete_marker = true;
        updatedEncounters[encounterIndex].encounter_type = ["none"];
        updatedEncounters[encounterIndex].setting = ["none"];
        await formikProps.setFieldValue("content.encounters", updatedEncounters);
    };

    signatureClickHandler = async (name, formikProps) => {
        if (!formikProps.values.signature) {
            await formikProps.setFieldValue(name, formikProps.values.provider_name);
        } else {
            await formikProps.setFieldValue(name, "");
        }
    };

    optionClickHandler = async (name, option, options, formikProps) => {
        const selectedOptions = [...options];
        if (selectedOptions.includes(option.value)) {
            selectedOptions.splice(selectedOptions.indexOf(option.value), 1);
            option.checked = false;
        } else {
            selectedOptions.push(option.value);
            option.checked = true;
        }
        await formikProps.setFieldValue(name, selectedOptions);
    };

    setFieldValue = async (name, value, formikProps) => {
        await formikProps.setFieldValue(name, value);
    };

    dateClickHandler = async (dateStr, eventType, formEl, formikProps) => {
        const parts = dateStr.split("-");
        await formikProps.setFieldValue(formEl.name, parts[1] + "/" + parts[2] + "/" + parts[0]);
    };

    reasonForCommunicationClickHandler = (name, option, formikProps) => {
        const existing_reasons = formikProps.values.content.reason_for_communication;
        if (
            option.value === "Acute Care Encounter" &&
            !existing_reasons.includes(option.value) &&
            !formikProps.values.content.encounters.filter((encounter) => !encounter.delete_marker)
                .length
        ) {
            this.addEncounter(formikProps);
        } else if (
            option.value === "Acute Care Encounter" &&
            existing_reasons.includes(option.value)
        ) {
            range(formikProps.values.content.encounters.length).forEach((encounterIndex) =>
                this.removeEncounter(formikProps, encounterIndex),
            );
        }
        this.optionClickHandler(name, option, existing_reasons, formikProps);
    };

    preventEnterFormSubmission = (e) => {
        if (e.key === "Enter" || e.code === "Enter") {
            e.preventDefault();
        }
    };

    renderForm = (formikProps) => {
        return (
            <>
                <Form onKeyDown={this.preventEnterFormSubmission}>
                    <div className="row">
                        {this.state.schema.map((formEl) => {
                            if (formEl.name === "content.reason_for_communication") {
                                formEl.onclick = (name, option) =>
                                    this.reasonForCommunicationClickHandler(
                                        name,
                                        option,
                                        formikProps,
                                    );
                                const openTextOption = formEl.elementConfig.options.find((option) =>
                                    option.hasOwnProperty("openText"),
                                );
                                openTextOption.onChange = async (e) => {
                                    const val = e.target.value;
                                    await formikProps.setFieldValue(
                                        "content.reason_for_communication_other",
                                        val,
                                    );
                                };
                            }

                            const error = getIn(formikProps.errors, formEl.name);
                            const touched = getIn(formikProps.touched, formEl.name);
                            if (formEl.elementType === "encounters") {
                                const encounterSelected =
                                    formikProps.values.content.reason_for_communication.includes(
                                        "Acute Care Encounter",
                                    );
                                if (encounterSelected) {
                                    return (
                                        <>
                                            <div key={formEl.name}>
                                                {formikProps.values.content.encounters.map(
                                                    (encounter, encounterIndex) => {
                                                        return (
                                                            <>
                                                                <EncounterForm
                                                                    encounterIndex={encounterIndex}
                                                                    formikProps={formikProps}
                                                                    optionClickHandler={
                                                                        this.optionClickHandler
                                                                    }
                                                                    dateClickHandler={(
                                                                        dateStr,
                                                                        eventType,
                                                                        formEl,
                                                                    ) =>
                                                                        this.dateClickHandler(
                                                                            dateStr,
                                                                            eventType,
                                                                            formEl,
                                                                            formikProps,
                                                                        )
                                                                    }
                                                                    removeEncounter={
                                                                        this.removeEncounter
                                                                    }
                                                                    setFieldValue={(name, value) =>
                                                                        this.setFieldValue(
                                                                            name,
                                                                            value,
                                                                            formikProps,
                                                                        )
                                                                    }
                                                                />
                                                            </>
                                                        );
                                                    },
                                                )}
                                                <div
                                                    className={
                                                        "d-flex justify-content-center mb-2"
                                                    }>
                                                    <Button
                                                        type="button"
                                                        onClick={() =>
                                                            this.addEncounter(formikProps)
                                                        }
                                                        className="Btn Btn--pri Btn-sm-2">
                                                        Add Additional Encounter
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="border-bottom col-12 my-2" />
                                        </>
                                    );
                                } else {
                                    return <></>;
                                }
                            } else if (formEl.name.includes("ICD_10_axis")) {
                                return (
                                    <div key={formEl.name} className={"col-12 col-lg-6 mb-3"}>
                                        <Label>{formEl.elementConfig.label}</Label>
                                        <ReactSelect
                                            components={{ ClearIndicator: null }}
                                            isMulti
                                            options={
                                                formEl.name === "ICD_10_axis_1_diagnosis"
                                                    ? this.state.icd10Axis1
                                                    : this.state.icd10Axis2
                                            }
                                            defaultValue={formikProps.values[formEl.name]}
                                            className={"basic-multi-select"}
                                            classNamePrefix="select"
                                            onChange={async (selectedOptions) =>
                                                await formikProps.setFieldValue(
                                                    formEl.name,
                                                    selectedOptions,
                                                )
                                            }
                                        />
                                        {error && touched ? (
                                            <InputError classes={"custom-error"}>
                                                {error}
                                            </InputError>
                                        ) : null}
                                    </div>
                                );
                            } else if (formEl.name === "signature") {
                                formEl.onclick = (name) =>
                                    this.signatureClickHandler(name, formikProps);
                                return (
                                    <div key={formEl.name} className={formEl.elementClasses}>
                                        <FormikInput
                                            formEl={formEl}
                                            errors={formikProps.errors}
                                            touched={formikProps.touched}
                                        />
                                        <div
                                            className="w-100 fs-24 border-bottom"
                                            style={{ fontFamily: "Apple Chancery, cursive" }}>
                                            {"X " + formikProps.values.signature}
                                        </div>
                                    </div>
                                );
                            } else if (formEl.elementType === "datePicker") {
                                return (
                                    <div key={formEl.name} className={formEl.elementClasses}>
                                        <DatePickerWithDisplay
                                            formikProps={formikProps}
                                            formEl={formEl}
                                            dateStr={formikProps.values[formEl.name]}
                                            dateClickHandler={(dateStr, eventType, formEl) =>
                                                this.dateClickHandler(
                                                    dateStr,
                                                    eventType,
                                                    formEl,
                                                    formikProps,
                                                )
                                            }
                                            touched={() =>
                                                formikProps.setFieldTouched(formEl.name, true)
                                            }
                                        />
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={formEl.name} className={formEl.elementClasses}>
                                        <FormikInput
                                            errorClasses={formEl.errorClasses}
                                            inputClasses={formEl.inputClasses}
                                            formEl={formEl}
                                            errors={formikProps.errors}
                                            touched={formikProps.touched}
                                            disabled={formEl.disabled}
                                        />
                                    </div>
                                );
                            }
                        })}
                    </div>
                    <div className="d-flex justify-content-center justify-content-lg-end">
                        <Button type="submit" className="Btn Btn--pri">
                            Save Note
                        </Button>
                    </div>
                </Form>
            </>
        );
    };

    submitHandler = async (formData, actions) => {
        if (
            !formData.content.reason_for_communication.includes("Acute Care Encounter") &&
            formData.content.encounters
        ) {
            formData.content.encounters = [];
        } else {
            formData.content.encounters = formData.content.encounters.filter(
                (encounter) => !encounter.delete_marker,
            );
        }
        formData.session_from = moment(
            formData.session_from_hr +
                ":" +
                formData.session_from_min +
                " " +
                formData.session_from_am_pm,
            "HH:mm a",
        ).format("HH:mm");

        formData["session_to"] = moment(formData["session_from"], "HH:mm")
            .add(allottedTimeFactory(formData["call_type"]), "minutes")
            .format("HH:mm");

        formData.is_billable = formData.call_type !== "non_billable";

        const urlParams = { patientId: this.state.patientId };
        await api.provider.post_progress_note({ urlParams, data: formData });
        this.props.history.push(
            `/app/patient/${this.state.patientId}/profile/v2?selectedTab=Notes`,
        );
    };

    renderGADPHQCards = (value, index) => {
        const { cardTitle, cardDescription, questionList } = value;

        if (!questionList) {
            return;
        }

        const openButtonRender = (
            <CardSecondary key={cardTitle} className="mx-lg-auto mx-3 my-3 p-3 d-flex cursor">
                <div className="col-11">
                    <div className="row">
                        <b>{cardTitle}</b>
                    </div>
                    <div className="row txt-gry">{cardDescription}</div>
                </div>
                <div className="col-1 d-flex ">
                    <img
                        src={images("./common/right-chevron.svg")}
                        alt="note icon"
                        className={"new-note-chevron align-self-center"}
                        style={{ maxHeight: "22px" }}
                    />
                </div>
            </CardSecondary>
        );
        return (
            <GenericQuestionaire
                displayAsModal={true}
                modalOpenButton={openButtonRender}
                labelMap={SCREENING_OPTIONS_LABEL_MAP}
                {...value}
            />
        );
    };

    generateQuestionaireCards = () => [
        {
            questionList: this.state.questionList.phq,
            submitFn: async (data) =>
                await api.patient.submit_PHQ9_questions({
                    data: {
                        ...data,
                        billable_data: {
                            provider_username: this.props.profile.username,
                            provider_name: this.props.profile.name,
                            screening_type: "phq",
                            patient_name: this.state.patientName,
                        },
                        patient_id: this.state.patientId,
                    },
                }),
            title: "Clinician Supported PHQ-9",
            questionaireInfoTitle: "Patient Health Questionnaire - 9",
            questionaireInfoDescription:
                "Over the past 2 weeks, how often have you been bothered by any of the following problems?",
            cardTitle: "Verbally Administer a Patient Health Questionnaire - 9 (PHQ-9)",
            cardDescription:
                "Complete the screening with the member, this records a billing event and saves the results to their progres trends",
        },
        {
            questionList: this.state.questionList.gad,
            submitFn: async (data) =>
                await api.patient.submit_GAD7_questions({
                    data: {
                        ...data,
                        billable_data: {
                            provider_username: this.props.profile.username,
                            provider_name: this.props.profile.name,
                            screening_type: "gad",
                            patient_name: this.state.patientName,
                        },
                        patient_id: this.state.patientId,
                    },
                }),
            title: "Clinician Supported GAD-7",
            questionaireInfoTitle: "General Anxiety Disorder - 7",
            questionaireInfoDescription:
                "How often have they been bothered by the following over the past 2 weeks?",
            cardTitle: "Verbally Administer a General Anxiety Disorder - 7 (GAD-7)",
            cardDescription:
                "Complete the screening with the member, this records a billing event and saves the results to their progres trends",
        },
    ];

    render() {
        return (
            <div>
                <div className="survey-back-link-wpr mx-auto fs-16 my-2 p-3 pl-lg-0 pr-lg-0">
                    <span
                        className="survey-back-link"
                        onClick={() =>
                            this.props.history.push(
                                `/app/patient/${this.state.patientId}/profile/v2?selectedTab=Notes`,
                            )
                        }>
                        <img
                            className="img-fluid"
                            src={images("./common/solidBckBtn.svg")}
                            alt={"<"}
                        />
                        <p className="d-inline ml-2">Back to Patient Profile</p>
                    </span>
                </div>
                <div className={"d-flex justify-content-center"}>
                    <MemberHeaderHOC
                        className={"cc-member-header"}
                        memberId={this.state.patientId}
                    />
                </div>
                <CardPrimary className="mx-lg-auto mx-3 my-1">
                    <div
                        className="d-flex justify-content-around justify-content-lg-between align-items-center"
                        data-e2e="CareCommunicationForm__careCommunicationTitle">
                        <b>Care Communication Note</b>
                    </div>
                    <hr className="mx-3 mx-lg-auto" />
                    <div>
                        {this.state.initialValues && (
                            <CustomForm
                                initialValues={this.state.initialValues}
                                validationSchema={CARE_COMMUNICATION_VALIDATION}
                                validateOnChange={true}
                                validateOnBlur={true}
                                render={this.renderForm}
                                onSubmit={this.submitHandler}
                            />
                        )}
                    </div>
                </CardPrimary>
                {this.state.questionList &&
                    this.generateQuestionaireCards().map(this.renderGADPHQCards)}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};
export default connect(mapStateToProps)(withRouter(CareCommunicationForm));
