// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as actionTypes from "./actionTypes";
import { TOAST_ERROR, EXTENSIBLE_TOAST_ERROR, CUSTOM_TOAST_ALERT } from "../../components/UI/Alert";
import { setExceptionReporting, unsetExceptionReporting } from "./exceptionReporting";

const setAlert = (alertConf) => {
    return {
        type: actionTypes.SET_ALERT,
        alertConf: alertConf,
    };
};

const unsetAlert = () => {
    return {
        type: actionTypes.UNSET_ALERT,
    };
};

export const showAlert = (message, dispatch, type = TOAST_ERROR, requestId = null) => {
    dispatch(
        setAlert({
            type,
            dismiss: () => {
                dispatch(unsetAlert());
            },
            message,
            requestId,
            showExceptionReporting: () => {
                dispatch(
                    setExceptionReporting({
                        requestId,
                        dismiss: () => {
                            dispatch(unsetExceptionReporting());
                        },
                    }),
                );
            },
        }),
    );
};

export const showAlertWithAction =
    (message, type = TOAST_ERROR) =>
    (dispatch) => {
        dispatch(
            setAlert({
                type,
                dismiss: () => {
                    dispatch(unsetAlert());
                },
                message,
            }),
        );
    };

export const showAlertWithActionAndCustomButton =
    (message, buttonAction, buttonText, type = EXTENSIBLE_TOAST_ERROR) =>
    (dispatch) => {
        dispatch(
            setAlert({
                type,
                dismiss: () => {
                    dispatch(unsetAlert());
                },
                message,
                buttonText,
                buttonAction,
            }),
        );
    };

export const showAlertWithCustomHTML =
    (customHTML, type = CUSTOM_TOAST_ALERT, dismiss = false, customClass = undefined) =>
    (dispatch) => {
        if (dismiss) {
            dispatch(unsetAlert());
        } else {
            dispatch(
                setAlert({
                    type,
                    dismiss: () => {
                        dispatch(unsetAlert());
                    },
                    customHTML,
                    customClass
                }),
            );
        }
    };
