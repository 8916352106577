import * as yup from "yup";

export const CARE_MANAGEMENT_INITIAL_VALUES = {
    patient_id: "",
    patient_first_name: "",
    patient_last_name: "",
    provider_id: "",
    provider_type: "",
    provider_name: "",
    signature: "",
    signature_date: "",
    provider_cp_credentials: "",
    is_billable: false,
    is_processed: false,
    version: 7,
    note_type: "Care Management",
    title: "Care Management Assessment",
    content: {
        review: {
            evaluation: "",
            understanding_of_diagnosis: "",
            difficulties_navigating_health_system: "",
            can_be_assisted_with_life_planning_activities: "",
        },
        cultural_impact: {
            family_cultural_views: "",
            health_care_approach_discouraged: "",
        },
        care_resources: {
            help_to_improve_life: "",
            do_others_help_with_life_activities: "",
            elaborate_assistance_needed: "",
            life_improving_goals: "",
        },
        conclusion: {
            tier_1: {
                risk_of_violence: "",
                homelessness_or_housing_insecurity: "",
                shelter: "",
                health_emergency: "",
                emergency_food_needs: "",
                other: "",
            },
            tier_2: {
                urgent_health_conditions: "",
                no_access_medical: "",
                no_access_food: "",
                recent_admission_or_ed: "",
                other: "",
            },
            tier_3: {
                chronic_condition: "",
                home_goods: "",
                adls: "",
                financial: "",
                relationships_social: "",
                legal_problems: "",
                leisure: "",
                educational: "",
                employment: "",
                limitations: "",
                other: "",
            },
            summary: "",
        },
    },
};

export const CAN_BE_ASSISTED_WITH_LIFE_PLANNING_ACTIVITIES_OPTIONS = [
    {
        label: "Yes – Patient provided with education",
        value: "Yes - Patient provided with education",
    },
    {
        label: "No – Patient declined education",
        value: "No - Patient declined education",
    },
    {
        label: "Not appropriate",
        value: "Not appropriate",
    },
];

const CAN_BE_ASSISTED_WITH_LIFE_PLANNING_ACTIVITIES_ANSWERS =
    CAN_BE_ASSISTED_WITH_LIFE_PLANNING_ACTIVITIES_OPTIONS.map((item) => item.value);

export const HELP_TO_IMPROVE_LIFE_OPTIONS = [
    {
        label: "Patient is independent",
        value: "Patient is independent",
    },
    {
        label: "Needs minor help",
        value: "Needs minor help",
    },
    {
        label: "Needs some help",
        value: "Needs some help",
    },
    {
        label: "Needs significant help",
        value: "Needs significant help",
    },
];

const HELP_TO_IMPROVE_LIFE_ANSWERS = HELP_TO_IMPROVE_LIFE_OPTIONS.map((item) => item.value);

export const DO_OTHERS_HELP_WITH_LIFE_ACTIVITIES_OPTIONS = [
    {
        label: "Not applicable",
        value: "Not applicable",
    },
    {
        label: "Unclear",
        value: "Unclear",
    },
    {
        label: "Do not assist",
        value: "Do not assist",
    },
    {
        label: "Unlikely to assist",
        value: "Unlikely to assist",
    },
    {
        label: "Assist",
        value: "Assist",
    },
];

const DO_OTHERS_HELP_WITH_LIFE_ACTIVITIES_ANSWERS = DO_OTHERS_HELP_WITH_LIFE_ACTIVITIES_OPTIONS.map(
    (item) => item.value,
);

export const CONCLUSION_RATINGS = ["Not at all", "Very little", "Somewhat", "Much", "Very much"];

export const CARE_MANAGEMENT_VALIDATION = yup.object().shape({
    signature: yup.string().required("Question is required"),
    content: yup.object().shape({
        review: yup.object().shape({
            evaluation: yup.string().required("Question is required").nullable(),
            understanding_of_diagnosis: yup.string().required("Question is required").nullable(),
            difficulties_navigating_health_system: yup
                .string()
                .required("Question is required")
                .nullable(),
            can_be_assisted_with_life_planning_activities: yup
                .string()
                .required("Question is required")
                .nullable()
                .oneOf(
                    CAN_BE_ASSISTED_WITH_LIFE_PLANNING_ACTIVITIES_ANSWERS,
                    `The answer must match one of: ${CAN_BE_ASSISTED_WITH_LIFE_PLANNING_ACTIVITIES_ANSWERS.join(
                        ", ",
                    )}`,
                ),
        }),
        cultural_impact: yup.object().shape({
            family_cultural_views: yup.string().required("Question is required").nullable(),
            health_care_approach_discouraged: yup
                .string()
                .required("Question is required")
                .nullable(),
        }),
        care_resources: yup.object().shape({
            help_to_improve_life: yup
                .string()
                .required("Question is required")
                .nullable()
                .oneOf(
                    HELP_TO_IMPROVE_LIFE_ANSWERS,
                    `The answer must match one of: ${HELP_TO_IMPROVE_LIFE_ANSWERS.join(", ")}`,
                ),
            do_others_help_with_life_activities: yup
                .string()
                .required("Question is required")
                .nullable()
                .oneOf(
                    DO_OTHERS_HELP_WITH_LIFE_ACTIVITIES_ANSWERS,
                    `The answer must match one of: ${HELP_TO_IMPROVE_LIFE_ANSWERS.join(", ")}`,
                ),
            elaborate_assistance_needed: yup.string().required("Question is required").nullable(),
            life_improving_goals: yup.string().required("Question is required").nullable(),
        }),
        conclusion: yup.object().shape({
            tier_1: yup.object().shape({
                risk_of_violence: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                homelessness_or_housing_insecurity: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                shelter: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                health_emergency: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                emergency_food_needs: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                other: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
            }),
            tier_2: yup.object().shape({
                urgent_health_conditions: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                no_access_medical: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                no_access_food: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                recent_admission_or_ed: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                other: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
            }),
            tier_3: yup.object().shape({
                chronic_condition: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                home_goods: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                adls: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                financial: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                relationships_social: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                legal_problems: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                leisure: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                educational: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                employment: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                limitations: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
                other: yup
                    .string()
                    .required("Question is required")
                    .nullable()
                    .oneOf(
                        CONCLUSION_RATINGS,
                        `The answer must match one of: ${CONCLUSION_RATINGS.join(", ")}`,
                    ),
            }),
            summary: yup.string().required("Question is required").nullable(),
        }),
    }),
});
