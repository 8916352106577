// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import { Form, Formik } from "formik";
import Button from "../../UI/Button";
import CardPrimary from "../../UI/Card/CardPrimary";
import FormikInput from "../../UI/FormikInput";
import { cloneDeep } from "lodash";
import FormikInputFormat from "../../UI/formikMobileInput";
import { connect } from "react-redux";
import { getNextQuestion, setA5Details } from "../../../redux/actions/a5Screening";
import { IN_PROGRESS_STEP } from "../A5Screening";
import { Link, withRouter } from "react-router-dom";
import { api } from "../../../APIRequests";
import Recaptcha from "react-recaptcha";
import InputError from "../../UI/InputError";
import CardHeading from "../../UI/Headings/CardHeading";
import "../styles.scss";
import { A5ConsentScreen } from "../../Authentication";
import {
    A5_START_INITIAL_VALUES,
    A5_START_SCHEMA,
    A5_START_VALIDATION_SCHEMA,
} from "./a5StartSchema";
import { transformDetailsToInitialValues } from "./A5Start";
import { CustomForm } from "../../../component-library/CustomForm";

class A5PublicStart extends Component {
    state = {
        recaptchaVerified: false,
        recaptchaError: "",
        consented: false,
        formData: null,
    };

    constructor(props) {
        super(props);
        this._reCaptchaRef = React.createRef();
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ mounted: true });
        }, 500);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        window.scrollTo(0, 0);
    }

    formHandler = async (formData, actions) => {
        actions.setSubmitting(false);
        if (!this.state.recaptchaVerified) {
            this.setState({ recaptchaError: "reCAPTCHA is Required" });
            return;
        }
        this.setState({ formData: formData });
    };

    consentHandler = async () => {
        try {
            const data = { ...this.state.formData };
            data["pcp_practice_name"] = this.props.pcpPracticeName.split("_").join(" ");
            const response = await api.a5Screening.create_public_a5_screening({ data });
            this.props.setA5Details(response);
            const headers = { dob: this.props.a5Details.dob };
            this.props.getNextQuestion({ id: this.props.a5Details.id }, headers);
            this.props.nextStepHandler(IN_PROGRESS_STEP);
            this.props.history.push(
                `/a5-screening?id=${this.props.a5Details.id}&public=true&practiceName=${this.props.pcpPracticeName}`,
            );
        } catch (e) {
            console.log(e);
        }
    };

    renderForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 ">
                    <div className="row">
                        {A5_START_SCHEMA.map((field) => {
                            if (field.name === "dob") {
                                return (
                                    <div key={field.name} className="col-12 col-lg-6">
                                        <FormikInputFormat
                                            value={formikProps && formikProps.values.dob}
                                            onChange={async (val) => {
                                                await formikProps.setFieldValue("dob", val.value);
                                                formikProps.setFieldTouched("dob");
                                            }}
                                            formEl={field}
                                            errors={formikProps.errors}
                                            touched={formikProps.touched}
                                        />
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={field.name} className="col-12 col-lg-6 ">
                                        <FormikInput
                                            formEl={field}
                                            errors={formikProps.errors}
                                            touched={formikProps.touched}
                                        />
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6 text-center">
                        {this.state.mounted && (
                            <Recaptcha
                                className="mx-auto recaptcha"
                                style={{ width: 300 }}
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                ref={this._reCaptchaRef}
                                verifyCallback={this.recaptchaCallback}
                            />
                        )}
                        <div>
                            {this.state.recaptchaError && (
                                <InputError style={{ position: "relative" }}>
                                    {this.state.recaptchaError}
                                </InputError>
                            )}
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 text-center mt-lg-0 mt-3">
                        <Button
                            type="submit"
                            className="Btn Btn--pri mx-auto"
                            style={{ maxHeight: 48, padding: "10px 20px 10px 20px" }}>
                            Begin Screening Now
                        </Button>
                    </div>
                </div>
            </Form>
        );
    };

    recaptchaCallback = () => {
        this.setState({ recaptchaError: "", recaptchaVerified: true });
    };

    render() {
        const initialValues = this.props.a5Details
            ? transformDetailsToInitialValues(this.props.a5Details)
            : cloneDeep(A5_START_INITIAL_VALUES);
        return (
            <div className="row">
                <div className={"col-12"}>
                    <h1>Hello!</h1>
                </div>
                <div className={"col-12 col-lg-7"}>
                    <CardPrimary styles={{ marginTop: 0, minHeight: 400 }}>
                        {!this.state.formData && (
                            <div>
                                <CardHeading className="fs-16 m-0" text={"Basic Info"} />
                                <hr className="m-2" />
                                <CustomForm
                                    initialValues={initialValues}
                                    validationSchema={A5_START_VALIDATION_SCHEMA}
                                    onSubmit={this.formHandler}
                                    render={this.renderForm}
                                />
                            </div>
                        )}
                        {this.state.formData && (
                            <div>
                                <CardHeading
                                    className="fs-16 m-0"
                                    text={"Disclaimer and Consent"}
                                />
                                <hr className="m-2" />
                                <A5ConsentScreen
                                    continue={this.consentHandler}
                                    hideLinks={true}
                                    hideWelcome={true}
                                    fs={"fs-16"}
                                    content={
                                        "This online screening tool is informational and is not a diagnostic " +
                                        "instrument. It has been made available to you by your healthcare provider to " +
                                        "support your emotional health needs. If you agree to take this screening, " +
                                        "your healthcare provider will receive a notification that you have started the " +
                                        "screening but will receive <u>no</u> other information. After you review your results, " +
                                        "you are encouraged to send your results to your healthcare provider so if recommended, " +
                                        "they can support you with a quick connection to personalized, goal-oriented therapy. " +
                                        "aptihealth, inc., disclaims any liability, loss, or risk incurred as a consequence, " +
                                        "directly or indirectly, from the use and application of this screening tool."
                                    }
                                    customConsentLabel={() => {
                                        return (
                                            <p className="ml-2">
                                                By checking this box and clicking Continue, you
                                                hereby agree and consent:
                                                <div className="ml-2">
                                                    1. You are 18 years or older
                                                </div>
                                                <div className="ml-2">
                                                    2. To the terms and conditions outlined above
                                                    and the
                                                    <Link
                                                        className="PrivacyPolicy-link fw-bold fs-14 ml-1 mb-4"
                                                        onClick={() => {
                                                            window.open(
                                                                "https://www.aptihealth.com/privacy",
                                                            );
                                                        }}>
                                                        Privacy Policy
                                                    </Link>
                                                </div>
                                            </p>
                                        );
                                    }}
                                />
                            </div>
                        )}
                    </CardPrimary>
                </div>
                <div className={"col-12 col-lg-5 PublicStartScreen"} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        a5Details: state.a5Screening.a5Details,
    };
};

export default connect(mapStateToProps, {
    setA5Details,
    getNextQuestion,
})(withRouter(A5PublicStart));
