// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import DashboardView from "../../../components/Provider/Dashboard";
import { api } from "../../../APIRequests";

class Home extends Component {
    state = {
        usersCount: null,
    };
    componentDidMount() {
        api.provider
            .fetch_patient_count()
            .then((cleanResponse) => {
                let usersCount = cleanResponse.user;
                this.setState({
                    usersCount: usersCount,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }
    render() {
        return (
            <div className="container mt-lg-5 mt-3">
                <DashboardView {...this.props} patient_count={this.state.usersCount} />
            </div>
        );
    }
}

export default Home;
