import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import {createClassNameString} from "../../component-library";
import {ICON_SIZES, ICON_TYPES} from "../../constants/icons";

export const HomeIcon = ({className, iconType, iconSize, ...props}) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path fillRule="evenodd" clipRule="evenodd" d="M16 6.73312L26 14.5109V28H18V20C18 19.2713 17.7367 18.7983 17.3982 18.4974C17.0412 18.1801 16.537 18 16 18C15.4631 18 14.9589 18.1801 14.6019 18.4974C14.2633 18.7983 14 19.2713 14 20V28H6.00004V14.5109L16 6.73312ZM8.00004 15.4891V26H12V20C12 18.7286 12.4867 17.7016 13.2732 17.0026C14.0412 16.3199 15.037 16 16 16C16.9631 16 17.9589 16.3199 18.7269 17.0026C19.5133 17.7016 20 18.7286 20 20V26H24V15.4891L16 9.26685L8.00004 15.4891Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M16 2.22455L28.6207 12.2159C29.0538 12.5587 29.1269 13.1877 28.7841 13.6207C28.4413 14.0537 27.8124 14.1268 27.3793 13.784L16 4.77542L4.62074 13.784C4.18773 14.1268 3.5588 14.0537 3.21599 13.6207C2.87319 13.1877 2.94632 12.5587 3.37934 12.2159L16 2.22455Z" />
            </svg>

        );
    } else if (iconType === ICON_TYPES.FILLED) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path fillRule="evenodd" clipRule="evenodd" d="M16 6.73312L26 14.5109V28H18V20C18 19.2713 17.7367 18.7983 17.3982 18.4974C17.0412 18.1801 16.537 18 16 18C15.4631 18 14.9589 18.1801 14.6019 18.4974C14.2633 18.7983 14 19.2713 14 20V28H6.00004V14.5109L16 6.73312Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M16 2.22455L28.6207 12.2159C29.0538 12.5587 29.1269 13.1877 28.7841 13.6207C28.4413 14.0537 27.8124 14.1268 27.3793 13.784L16 4.77542L4.62074 13.784C4.18773 14.1268 3.5588 14.0537 3.21599 13.6207C2.87319 13.1877 2.94632 12.5587 3.37934 12.2159L16 2.22455Z" />
            </svg>

        );
    }

    return null;
};

HomeIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

HomeIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
