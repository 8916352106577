import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { Card } from "../Card";
import { CloseIcon } from "../../components/Icons/CloseIcon";
import { ICON_SIZES, ICON_TYPES } from "../../constants/icons";
import { createClassNameString } from "../utils";

export const NPSSurveyWrapper = ({ dismissHandler, className, children }) => {
    return (
        <Card className={createClassNameString(["apti-CL NPSSurveyWrapper", className])}>
            <div className="NPSSurveyWrapper--dismiss_wrapper">
                <CloseIcon
                    onClick={dismissHandler}
                    className={"NPSSurveyWrapper--dismiss_icon"}
                    iconSize={ICON_SIZES.SMALL}
                    iconType={ICON_TYPES.OUTLINE}
                />
            </div>
            {children}
        </Card>
    );
};

NPSSurveyWrapper.propTypes = {
    className: PropTypes.string,
    dismissHandler: PropTypes.func.isRequired,
};

NPSSurveyWrapper.defaultProps = {
    className: undefined,
    dismissHandler: () => {
        console.log("No Dismiss handler");
    },
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};
