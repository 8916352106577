import * as actionTypes from "./actionTypes";
import { api } from "../../APIRequests";
import moment from "moment";

export const updatePatientCardInfo = ({
    lastFourDigits,
    expirationDate,
    username,
    patientCardLoaded,
}) => {
    return {
        type: actionTypes.UPDATE_PATIENT_CARD_INFO,
        payload: {
            lastFourDigits,
            expirationDate,
            username,
            patientCardLoaded,
        },
    };
};

export const updatePatientPaywallInfo = ({
    showPaywall,
    isSoftPaywall,
    updatePaymentRequired,
    lastFailedChargeDate,
}) => {
    return {
        type: actionTypes.UPDATE_PATIENT_PAYWALL_INFO,
        payload: {
            showPaywall,
            isSoftPaywall,
            updatePaymentRequired,
            lastFailedChargeDate,
        },
    };
};

export const fetchPatientCardInfo = () => (dispatch) => {
    const options = { showLoader: true };
    api.payment.fetchPatientCardInfo({ options }).then((data) => {
        const firstResult = data[0];

        if (firstResult) {
            dispatch(
                updatePatientCardInfo({
                    username: firstResult?.username,
                    lastFourDigits: firstResult?.last_four_digits,
                    expirationDate: firstResult?.expiration_date,
                    patientCardLoaded: true,
                }),
            );
        } else {
            dispatch(
                updatePatientCardInfo({
                    patientCardLoaded: true,
                }),
            );
        }
    });
};

export const putPatientCardInfo =
    ({
        lastFourDigits,
        expirationDate,
        cardToken,
        workflow,
        onSuccessCallback = () => {},
        onErrorCallback = () => {},
        finallyCallback = () => {},
    }) =>
    (dispatch, getState) => {
        const options = {};
        api.payment
            .putPatientCardInfo({
                options,
                data: {
                    last_four_digits: lastFourDigits,
                    expiration_date: expirationDate,
                    token: cardToken,
                    type: "CREDIT_CARD",
                    workflow,
                    client: "WEB",
                },
            })
            .then((response) => {
                const formattedExpDate = moment(expirationDate, "MMYY").format("YYYY-MM-DD");
                dispatch(
                    updatePatientCardInfo({
                        lastFourDigits: lastFourDigits,
                        expirationDate: formattedExpDate,
                    }),
                );

                dispatch(
                    updatePatientPaywallInfo({
                        showPaywall: false,
                        isSoftPaywall: false,
                        updatePaymentRequired: false,
                        lastFailedChargeDate: null,
                    }),
                );
                onSuccessCallback(response);
            })
            .catch((err) => {
                onErrorCallback(err);
                return err;
            })
            .finally(finallyCallback);
    };

export const fetchPatientPaywall = () => (dispatch) => {
    const options = { showLoader: true };

    api.payment.fetchPatientPaywall({ options }).then((data) => {
        const isSoftPaywall = data.paywall && data.type === "soft_paywall";

        dispatch(
            updatePatientPaywallInfo({
                showPaywall: data.paywall,
                isSoftPaywall,
                updatePaymentRequired: data.paywall && data.type === "hard_paywall_update_payment",
                lastFailedChargeDate: data.last_failed_transaction_date,
            }),
        );
    });
};
