// Copyright aptihealth, inc. 2021 All Rights Reserved

import React, { useEffect, useState } from "react";
import "./styles.scss";
import Button from "../../UI/Button";
import { Form } from "formik";
import * as yup from "yup";
import FormikInput from "../../UI/FormikInput";
import { consentToTerms } from "./index";
import _ from "lodash";
import moment from "moment";
import { getUsernameFromToken } from "../../../redux/actions/auth";
import { trackEvent } from "../../../utils/EventTrackingUtil";
import { connect } from "react-redux";
import { CustomForm } from "../../../component-library/CustomForm";

const ConsentToTreatment = (props) => {
    const { consent, setConsent, nextStep, changeWorkFlowStep, clientIp } = props;
    const [initialSignature, setInitialSignature] = useState(undefined);

    useEffect(() => {
        if (!consent["eula"] || !consent["privacy"] || !consent["hipaa"]) {
            changeWorkFlowStep(consentToTerms);
        }
        const signature = props.profile.first_name + " " + props.profile.last_name;
        setInitialSignature(signature);
    }, []);

    const submit = (formData, actions) => {
        const newConsent = _.cloneDeep(consent);
        newConsent["treatment"] = [
            {
                date: moment.utc().toISOString(),
                client: "web",
                consent_given_by: getUsernameFromToken() || "self",
                client_ip: clientIp,
                signature: formData.signature,
            },
        ];

        setConsent(newConsent);
        trackEvent("Add Your Child’s Information");
        nextStep();
    };

    const renderForm = (formikProps) => {
        const formikInputProps = {
            errors: formikProps.errors,
            touched: formikProps.touched,
            inputClasses: "Input--sm signature-font d-none",
            containerClasses: "pb-0",
        };
        return (
            <Form>
                <FormikInput
                    value={initialSignature}
                    formEl={{
                        name: "signature",
                        elementType: "input",
                        elementConfig: {
                            label: "Signature",
                            placeholder: "Type Name",
                        },
                    }}
                    {...formikInputProps}
                />

                <div className="border-bottom-note">
                    <div className={""}>
                        <div
                            className="fs-24"
                            style={{ fontFamily: "Apple Chancery, cursive" }}
                            data-private={"true"}>
                            {formikProps.values["signature"]}
                        </div>
                    </div>
                </div>

                <div className={"d-flex justify-content-end pb-4 mt-4"}>
                    <Button
                        type={"submit"}
                        disabled={formikProps.values["signature"] ? null : "disabled"}
                        className={"Btn Btn--pri"}>
                        Sign and Continue
                    </Button>
                </div>
            </Form>
        );
    };

    if (!initialSignature) {
        return <></>;
    }

    return (
        <>
            <div className={"txt-sec fw-bold pt-2 mx-3 workflow-step-title"} data-public={"true"}>
                Step 2 of 5: Consent to Treatment
            </div>

            <div className={"mx-4 my-3"} data-public={"true"}>
                <div className={"fw-bold fs-16 mx-4"}>
                    Please review and sign the following agreement
                </div>

                <div className={"consent-to-treatment-container mx-4 mb-4 mt-3"}>
                    <div className={"consent-to-treatment"}>
                        <div className={"fw-bold mb-3"}>CONSENT TO TREATMENT VIA TELEHEALTH</div>

                        <div className={"mb-3"}>
                            The purpose of this form is to provide you with information about
                            treatment via telehealth and to obtain your informed consent.
                        </div>

                        <div className={"mb-3"}>
                            <span className={"fw-bold"}>CONSENT FOR TREATMENT:</span> By signing
                            this document, I do hereby consent to, request and authorize aptihealth
                            Medical, PLLC (“aptihealth”), its medical practices and providers
                            including physicians, allied health professionals, mental health
                            counselors, clinical social workers, social workers, nurses, and other
                            qualified personnel to perform evaluation and treatment services and
                            procedures as may be indicated to advance me toward the goals of
                            treatment and when necessary or required by Law, in accordance with the
                            judgment of the attending medical practitioner(s). I acknowledge that no
                            warranty of guarantee can be made by anyone concerning the results of
                            treatments, counseling, assessment or screenings, examinations or
                            procedures.
                        </div>

                        <div className={"mb-3"}>
                            I acknowledge I have the right to be informed of:
                            <ul className={"ul-unset"}>
                                <li className={"li-unset"}>
                                    The nature of the treatment, including other care, treatments,
                                    or medications
                                </li>
                                <li className={"li-unset"}>
                                    Potential benefits, risks or side effects of the treatment
                                </li>
                                <li className={"li-unset"}>
                                    The likelihood of achieving treatment goals
                                </li>
                                <li className={"li-unset"}>
                                    Reasonable alternatives and the relevant risks, benefits and
                                    side effects related to such alternatives, including the
                                    possible results of not receiving care or treatment
                                </li>
                                <li className={"li-unset"}>
                                    Any independent medical research or significant economic
                                    interests your provider may have related to the performance of
                                    the proposed treatment.
                                </li>
                            </ul>
                        </div>

                        <div className={"mb-3"}>
                            <span className={"fw-bold"}>NATURE OF TELEHEALTH:</span> Telehealth is
                            the use of electronic information and communication technologies to
                            enable a healthcare provider and a patient at different locations to
                            share medical information for the purpose of improving patient care. The
                            information you provide may be used for diagnosis, therapy, medication
                            management, follow-up and/or patient education. The delivery of
                            healthcare via telehealth allows the patient and provider to establish a
                            relationship, much as they would during a traditional face-to-face
                            appointment. For example, your telehealth encounter may include
                            interaction through and with the use of some of the following:
                            synchronous video (e.g. videoconferencing) and/or asynchronous
                            technology, such as store-and-forward technology to exchange medical
                            data and secure messaging portal communication. Telehealth is not to be
                            used for urgent or emergency consults, nor is it a replacement for
                            primary care.
                        </div>

                        <div className={"mb-3"}>
                            <span className={"fw-bold"}>BENEFITS & RISKS:</span> The benefits of
                            telehealth include improved access to health services and care,
                            including the expertise of specialists and consultants that may not
                            otherwise be available to you. There are potential risks to using
                            telehealth technology, including but not limited to, interruptions to
                            the connection, images and other information transmitted may not be
                            clear enough to be useful for the consultation, unauthorized access, and
                            technical difficulties. Other potential risks to using telehealth
                            services include breach of privacy of protected health information due
                            to security breaches or failures, as well as adverse drug interactions,
                            allergic reactions, complications, or other errors due to a patient’s
                            failure to provide complete medical information or records.
                        </div>

                        <div className={"mb-3"}>
                            <span className="italics">
                                I understand that the provider or I may discontinue my telehealth
                                visit if the telehealth technologies are not adequate for the
                                situation, if the information obtained via telehealth was not
                                sufficient or if telehealth is inappropriate for any reason.
                            </span>
                        </div>

                        <div className={"mb-3"}>
                            <span className={"fw-bold"}>ALTERNATIVES:</span> Alternative methods of
                            care, such as in-person services, may be available to you through
                            aptihealth or another provider. In some cases, in-person services may
                            not be available and aptihealth will help you to locate an alternate
                            provider.
                        </div>

                        <div className={"mb-3"}>
                            <span className="italics">
                                I acknowledge that I may choose an alternative at any time.
                            </span>
                        </div>

                        <div className={"mb-3"}>
                            <span className={"fw-bold"}>YOUR PRIVACY RIGHTS:</span> aptihealth uses
                            security protocols to protect the confidentiality of your patient health
                            information. Information about your medical and psychiatric health is
                            protected by federal laws: The Health Insurance Portability and
                            Accountability Act of 1996 and its subsequent amendments (“HIPAA”).
                            Personal information that identifies you or contains protected health
                            information will not be disclosed to any third party without your
                            consent, except as authorized by law for the purposes of consultation,
                            treatment, payment/billing, and certain administrative purposes, or as
                            otherwise set forth in aptihealth’s Notice of Privacy Practices.
                        </div>

                        <div className={"fw-bold mb-3"}>
                            EMERGENCIES: In the event of an urgent health issue or concern, you must
                            seek care in-person, at a facility or provider equipped to deliver
                            urgent or emergent care.
                        </div>

                        <div className={"fw-bold mb-3"}>
                            <span style={{ textDecoration: "underline" }}>
                                IF YOU ARE THINKING ABOUT SUICIDE OR ARE WORRIED ABOUT A FAMILY
                                MEMBER, FRIEND, OR LOVED ONE COMMITTING SUICIDE or believe you may
                                harm yourself or others or that your child may harm herself or
                                himself or others, you must immediately call 911 or take your child
                                to the nearest emergency room for help.
                            </span>
                              You may also call 988 or dial 1-800-273-8255 to reach the Suicide &
                            Crisis Lifeline (formerly known as the National Suicide Prevention
                            Lifeline), which provides free and confidential emotional support to
                            people in suicidal crisis or emotional distress 24 hours a day, 7 days a
                            week, across the United States.
                        </div>

                        <div className={"fw-bold mb-3"}>LICENSE VERIFICATION:</div>

                        <div className={"mb-3"}>
                            To verify a mental health provider’s license in New York State, you can
                            visit the New York State Department of Health website. The website
                            provides a Verification of Information page that explains how to request
                            verification of NYS certification. You will need to provide a signed
                            consent from the provider, and certification information will not be
                            supplied over the phone.
                        </div>

                        <div className={"mb-3"}>
                            RECOMMENDED: Alternatively, you can visit the Office of the Professions
                            website at nysed.gov. The website provides a Verification Search page
                            that allows you to search for license verification information. The
                            database is updated daily and is considered to be a secure, primary
                            source for license verification. By entering the name of a provider in a
                            search by “licensee name” from ALL Professions ALL, and clicking GO, you
                            can verify the licensing status of any provider.
                        </div>

                        <div className={"mb-3"}>
                            <span className={"fw-bold"}>BY SIGNING THIS FORM, I UNDERSTAND </span>
                            that telehealth involves the use of electronic information and
                            communication technologies by a healthcare provider to deliver services
                            to a patient when the patient is located at a different site than the
                            provider, and I hereby consent to aptihealth providers delivering
                            services to me via telehealth.
                        </div>

                        <div className={"mb-3"}>
                            I have been given the opportunity to ask questions regarding the
                            telehealth technology. I understand that this visit will not be the same
                            as an in-person visit due to the fact that I will not be in the same
                            physical location as the healthcare provider at the distant site. I
                            further understand that the provider will determine whether telehealth
                            is appropriate for me at this time. I understand that I may benefit from
                            telehealth, but that results cannot be guaranteed.
                        </div>

                        <div className={"mb-3"}>
                            The provider will inform me who will be present at the provider’s
                            location during the telehealth service, and I have the right to exclude
                            anyone from being present, if I so choose. I understand that the laws
                            that protect privacy and the confidentiality of medical information also
                            apply to telehealth. I understand that I have the right to inspect and
                            obtain copies of all information received and recorded during any
                            telehealth session, subject to the policies of the providers involved in
                            my care. I may be charged a fee for copies of records in accordance with
                            applicable State rules.
                        </div>

                        <div className={"mb-3"}>
                            I understand that I have the right to withhold or withdraw my consent to
                            the use of telehealth at any time, without affecting my right to future
                            care. I may revoke my consent orally or in writing at any time by
                            contacting aptihealth.
                        </div>

                        <div className={"mb-3"}>
                            I understand that a Provider’s primary responsibility is my child’s best
                            interest. I understand that a Provider is not agreeing to be an expert
                            witness or to testify on my behalf or on the behalf of any other
                            individual in a deposition, court proceeding, or in any other way. I
                            understand that a Provider may or may not meet with me, my attorney, or
                            any other party or attorney in any custodial or divorce proceeding at
                            her sole discretion.
                        </div>

                        <div className={"fw-bold mb-3"}>IF PATIENT IS UNDER THE AGE OF 18:</div>

                        <div className={"mb-3"}>
                            I, as the parent or legal guardian of my child, certify that I have read
                            this document, that it has been fully explained to me and that I
                            understand its contents, and hereby agree to all terms and conditions
                            set forth above on behalf of my child and acknowledge the receipt of a
                            copy if requested. I hereby authorize that photocopies of this form to
                            be valid as the original.
                        </div>

                        <div className={"mb-3"}>
                            Your signature on this form indicates that:
                            <ul className={"ul-unset"}>
                                <li className={"li-unset"}>
                                    You have read and understand the information provided in this
                                    form;
                                </li>
                                <li className={"li-unset"}>
                                    Your provider has adequately explained to you the recommended
                                    treatment, along with the risks, benefits, and alternatives;
                                </li>
                                <li className={"li-unset"}>
                                    You have had a chance to ask your providers questions;
                                </li>
                                <li className={"li-unset"}>
                                    You have received all the information you desire concerning the
                                    treatment; and
                                </li>
                                <li className={"li-unset"}>
                                    You authorize and consent to the performance of the treatment.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className={"mx-5"}>
                    <CustomForm
                        initialValues={{
                            signature: initialSignature,
                        }}
                        validationSchema={validation}
                        onSubmit={submit}
                        render={renderForm}
                    />
                </div>
            </div>
        </>
    );
};

const validation = yup.object().shape({
    signature: yup.string().required("Signature is required"),
});

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps, {})(ConsentToTreatment);
