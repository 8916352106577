import React from "react";
import "./styles.scss";
import { createClassNameString } from "../../../../component-library";

export const CarePlanEmptyContent = ({ text, containerClassName }) => {
    return (
        <div
            className={createClassNameString([
                "CarePlanEmptyContentCard--container",
                containerClassName,
            ])}>
            <p className="CarePlanEmptyContentCard--text">{text}</p>
        </div>
    );
};
