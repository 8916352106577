// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import { Settings } from "../../../components/Common/SettingsView";
import CardSecondary from "../../../components/UI/Card/CardSecondary";
import Logout from "../../../components/Common/SettingsView/Logout";
import { logOut } from "../../../redux/actions/auth";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class SettingsPage extends Component {
    render() {
        return (
            <div className="container">
                <CardSecondary className=" mx-lg-auto mt-lg-5 mt-3 mb-3 mb-lg-3">
                    <Settings baseRoute={this.props.baseRoute} />
                </CardSecondary>
                <CardSecondary className="mt-3 mx-lg-auto">
                    <Logout onLogOut={() => this.props.logOut({ history: this.props.history })} />
                </CardSecondary>
            </div>
        );
    }
}

export default connect(null, { logOut })(withRouter(SettingsPage));
