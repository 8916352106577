import React from "react";
import "../styles.scss";

const GuidedCallActionIcons = (props) => {
    return (
        <>
            <div className="GuidedVideoCall__Progress d-flex flex-row align-items-center justify-content-center">
                {props.workflow.details.iconComponents.map((component) => {
                    return component(props);
                })}
            </div>
        </>
    );
};

export default React.memo(GuidedCallActionIcons);
