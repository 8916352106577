// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import "./styles.scss";
import images from "../../../utils/images";

/**
 * This is the progress bar component
 * four props can be passed now, height, pri, sec
 * prop 'now' is mandatory
 *
 */

const ProgressBar = (props) => {
    let { now, height, currentMarker, currentMarkerTitle } = props;

    let classes = "ProgressBar";
    if (props.className) {
        classes = classes + " " + props.className;
    }

    let styles_inner = {
        height,
        transform: `translate3d(${now + "%"},0,0)`,
    };
    let styles_outer = {
        height,
    };

    const currentMarkerStyle = {
        "max-width": 75,
        "margin-left": `calc(${now}% - ${currentMarker > 9 ? "26" : "22"}px)`,
        "margin-bottom": -10,
        "font-size": 12,
    };

    return (
        <div>
            {currentMarker && currentMarkerTitle && (
                <div>
                    <div style={currentMarkerStyle}>
                        <b>{currentMarkerTitle}</b>
                    </div>
                    <img
                        src={images("./common/green-chevron.svg")}
                        alt=""
                        style={{ marginLeft: `calc(${now}% - 8px)`, marginBottom: -5 }}
                    />
                </div>
            )}
            <div className={classes} style={styles_outer}>
                <div className="ProgressBar__progress" style={styles_inner} />
            </div>
        </div>
    );
};

export default ProgressBar;
