/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const BuildingIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Building"
                {...props}>
                <path d="M10 8H7V11H10V8Z" />
                <path d="M7 14H10V17H7V14Z" />
                <path d="M10 20H7V23H10V20Z" />
                <path d="M12 8H15V11H12V8Z" />
                <path d="M15 14H12V17H15V14Z" />
                <path d="M12 20H15V23H12V20Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19 8C19 5.79086 17.2091 4 15 4H7C4.79086 4 3 5.79086 3 8V28H19V8ZM17 8C17 6.89543 16.1046 6 15 6H7C5.89543 6 5 6.89543 5 8V26H17V8Z"
                />
                <path d="M25.5 13H23.5V15H25.5V13Z" />
                <path d="M23.5 17H25.5V19H23.5V17Z" />
                <path d="M25.5 21H23.5V23H25.5V21Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29 13C29 10.7909 27.2091 9 25 9H24C21.7909 9 20 10.7909 20 13V28H29V13ZM27 26V13C27 11.8954 26.1046 11 25 11H24C22.8954 11 22 11.8954 22 13V26H27Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Building"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19 8V28H3V8C3 5.79086 4.79086 4 7 4H15C17.2091 4 19 5.79086 19 8ZM10 8H7V11H10V8ZM10 14H7V17H10V14ZM7 20H10V23H7V20ZM15 8H12V11H15V8ZM12 14H15V17H12V14ZM15 20H12V23H15V20Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29 13V28H20V13C20 10.7909 21.7909 9 24 9H25C27.2091 9 29 10.7909 29 13ZM23.5 13H25.5V15H23.5V13ZM23.5 17H25.5V19H23.5V17ZM25.5 21H23.5V23H25.5V21Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Building"
                {...props}>
                <path d="M13.25 14H15.5V16.25H13.25V14Z" />
                <path d="M13.25 18.5H15.5V20.75H13.25V18.5Z" />
                <path d="M15.5 23H13.25V25.25H15.5V23Z" />
                <path d="M17 14H19.25V16.25H17V14Z" />
                <path d="M19.25 18.5H17V20.75H19.25V18.5Z" />
                <path d="M17 23H19.25V25.25H17V23Z" />
                <path d="M27.125 17.75H25.625V19.25H27.125V17.75Z" />
                <path d="M27.125 20.75H25.625V22.25H27.125V20.75Z" />
                <path d="M25.625 23.75H27.125V25.25H25.625V23.75Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM22.25 29V14C22.25 12.3431 20.9069 11 19.25 11H13.25C11.5931 11 10.25 12.3431 10.25 14V29H22.25ZM29.75 29V17.75C29.75 16.0931 28.4069 14.75 26.75 14.75H26C24.3431 14.75 23 16.0931 23 17.75V29H29.75Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

BuildingIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

BuildingIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
