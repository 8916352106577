// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useState } from "react";
import Hr from "../../UI/Hr";
import Button from "../../UI/Button";
import Toggle from "../../UI/Toggle";
import images from "../../../utils/images";
import Switch from "react-switch";

const PatientProfileDetailsCard = (props) => {
    let isMobile = window.innerWidth <= 640;

    const renderToggle = () => {
        return isMobile ? (
            <Switch
                checked={props.selected}
                onChange={props.onclick}
                className={"mb-2"}
                checkedIcon={false}
                uncheckedIcon={false}
            />
        ) : (
            <Toggle
                selected={props.selected}
                toggleSelected={props.onclick}
                testId={props.testId}
            />
        );
    };
    return (
        <div className={"PatientProfile__ProfileDetailsCard p-3 p-lg-3 mb-3 mb-lg-4"}>
            <div className="d-flex justify-content-between">
                <h1 className={"patient-profile-purple align-self-center mb-1 fs-16 fw-bold pb-1"}>
                    {props.heading}
                </h1>
                {props.type === "Edit" && (
                    <img
                        onClick={props.onclick}
                        src={images("./icons/green-edit-icon.svg")}
                        alt="edit icon"
                        style={{ marginTop: "-10px" }}
                    />
                )}
                {props.type === "Toggle" && renderToggle()}
                {props.type === "Required" && (
                    <div className="toggle-container mb-2 required-label">{"Required"}</div>
                )}
            </div>
            <Hr />
            {props.children}
        </div>
    );
};

export default PatientProfileDetailsCard;
