import React, { useState } from "react";
import images from "../../../../utils/images";
import moment from "moment";
import { durationToString } from "../../../../utils/momentUtils";
import Button from "../../../UI/Button";
import "../styles.scss";
import { api } from "../../../../APIRequests";
import { isAuthorized } from "../../../../redux/actions/auth";
import _ from "lodash";

const RemoteVideo = (props) => {
    const {
        remoteTracks,
        remoteMedia,
        redirectToMessageProviderDialog,
        toggleAudioBtn,
        endCall,
        toggleVideoBtn,
        allottedTime,
        timer,
        participantDisconnected,
        fullscreenMode,
        callDetails,
        note,
        workflow,
    } = props;
    const isUserPatient = isAuthorized("user:patient");
    const [showEndCallModal, setShowEndCallModal] = useState(false);
    const [showNoShowModal, setShowNoShowModal] = useState(false);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });
    const existingNoShowReason = note ? note.content.patient_no_show_reason : "";
    const [noShowReason, setNoShowReason] = React.useState(existingNoShowReason);

    React.useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }

        window.addEventListener("resize", handleResize);

        return (_) => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    React.useLayoutEffect(() => {
        props.attachRemoteTracks();
    }, []);

    const updateNoteToBeNoShow = async () => {
        if (workflow.details.showNoShowModalOnly) {
            const noShowArray = _.get(note, "content.patient_no_show_array");

            const no_show = {
                patient_no_show: true,
                patient_no_show_reason: noShowReason,
                patient_no_show_time: moment.utc().format(),
                provider_id: callDetails.provider_username,
                call_id: callDetails.callId,
            };

            if (!noShowArray) {
                note.content.patient_no_show_array = [no_show];
            } else {
                if (
                    note.content.patient_no_show_array.find(
                        (element) => element.call_id === callDetails.callId,
                    )
                ) {
                    return;
                }

                note.content.patient_no_show_array.push(no_show);
            }
        } else {
            note.is_billable = false;
            note.content.patient_no_show = true;
            note.content.patient_no_show_reason = noShowReason;
        }

        try {
            const urlParams = { patientId: callDetails.patient_username };
            return api.provider.put_progress_note({ urlParams, data: note });
        } catch (e) {
            console.log(e);
        }
    };

    let remote = props.remoteVideoRef.current;
    let placeholder = props.remoteVideoPlaceholderRef.current;
    let timerStyle = {};
    let buttonGrpStyle = {};

    let endCallBtn = (
        <button className="GuidedVideoCall__controls-btn GuidedVideoCall__controls-btn--endcall">
            <img
                className="mx-3 d-block cursor"
                onClick={() => {
                    if (isUserPatient || workflow.details.skipEndCallModal) {
                        if (remoteTracks.length === 0 && !participantDisconnected) {
                            redirectToMessageProviderDialog();
                            return;
                        }

                        endCall();
                        return;
                    }
                    if (workflow.details.showNoShowModalOnly) {
                        setShowNoShowModal(true);
                        return;
                    }
                    setShowEndCallModal(true);
                }}
                src={images("./icons/end-call-white.svg")}
                style={{ width: "65px", "margin-top": "5px" }}
                alt="endCall"
            />
        </button>
    );

    let additional;
    if (remoteTracks.length === 0 && !participantDisconnected) {
        if (isUserPatient) {
            additional = (
                <div className="GuidedVideoCall__awaiting_message order-1 order-lg-12 fw-bold text-white fs-18">
                    <p>
                        <span className="fs-20">
                            <strong>Awaiting your session to start</strong>
                        </span>
                        <span>Please hang tight while the other party joins.</span>
                    </p>
                </div>
            );
        } else {
            additional =
                workflow["details"] && workflow["details"]["skipEndCallModal"] ? (
                    <div className="GuidedVideoCall__awaiting_message order-1 order-lg-12 fw-bold text-white fs-18">
                        <p>
                            <span className="fs-20 mb-2">
                                <strong>Awaiting the patient to join.</strong>
                            </span>
                            <span className="fs-14 fw-normal mb-2">
                                Please hang tight while the other party joins. If the patient does
                                not join, please end the call
                            </span>
                        </p>
                    </div>
                ) : (
                    <div className="GuidedVideoCall__awaiting_message order-1 order-lg-12 fw-bold text-white fs-18">
                        <p>
                            <span className="fs-14 fw-normal mb-2">
                                Please hang tight while the other party joins. If the patient does
                                not join
                            </span>
                            <span className="fs-14 fw-normal">
                                end the call below and select the patient did not attend
                            </span>
                        </p>
                    </div>
                );
        }
    }

    let allottedDuration = moment.duration(allottedTime, "minutes");

    return (
        <>
            <div className="GuidedVideoCall__timer" style={timerStyle}>
                {timer} /{" "}
                <span style={{ color: "#BACED7" }}>{durationToString(allottedDuration)}</span>
            </div>

            <div className="GuidedVideoCall__remote m-auto">
                {remoteTracks.length > 0 ? (
                    remoteMedia
                ) : (
                    <div
                        className="GuidedVideoCall__remote_placeholder m-auto"
                        ref={props.remoteVideoPlaceholderRef}
                    />
                )}
            </div>

            <div
                className="GuidedVideoCall__controls d-flex d-lg-flex align-items-center justify-content-center"
                style={buttonGrpStyle}>
                <div className="GuidedVideoCall__controls-inner d-flex">
                    {toggleAudioBtn}
                    {endCallBtn}
                    {toggleVideoBtn}
                </div>
            </div>
            {showEndCallModal && (
                <div className={"GuidedVideoCall__launcher_modal"}>
                    <div className={"GuidedVideoCall__launcher_modal-card"}>
                        <img
                            className="GuidedVideoCall__close-btn"
                            src={images("./icons/cross.svg")}
                            role="button"
                            onClick={() => setShowEndCallModal(false)}
                            alt="close"
                        />
                        <p className={"launcher-title"}>End Call</p>
                        <div className={"GuidedVideoCall__launcher_modal-card-body"}>
                            <div className={"left-body"}>
                                <div>
                                    <p className={"fw-bold mb-4"}>Review Draft Note</p>
                                    <p className={"text"}>
                                        You will be able to review the summary of the note and save
                                        it as a draft or sign and submit.
                                    </p>
                                </div>
                                <div className={"buttons-container"}>
                                    <Button
                                        type="button"
                                        className="Btn Btn--sec review-note"
                                        onClick={endCall}>
                                        <img
                                            className={"review-note-icon"}
                                            src={images("./icons/check-in-icon.svg")}
                                        />
                                        Review Note
                                    </Button>
                                    <p className={"small-text pt-1"}>
                                        This will exit the call and save a draft of your session
                                    </p>
                                </div>
                            </div>
                            <div className={"right-body"}>
                                <div>
                                    <p className={"fw-bold mb-4"}>Patient Did Not Attend</p>
                                    <p className={"text mb-0"}>
                                        If your patient did not attend the call you can end the call
                                        here. Recording a <b>No-Show</b> on the session.
                                    </p>
                                    <input
                                        className={"my-2"}
                                        placeholder="No Show Reason"
                                        type="text"
                                        value={noShowReason}
                                        onChange={(e) => setNoShowReason(e.target.value)}
                                    />
                                </div>
                                <div className={"buttons-container"}>
                                    <Button
                                        type="button"
                                        className="Btn Btn--err"
                                        onClick={() => {
                                            updateNoteToBeNoShow().then(() => {
                                                props.history.push({
                                                    pathname: `/app/patient/${callDetails.patient_username}/profile/v2?selectedTab=Notes`,
                                                });
                                            });
                                        }}>
                                        <img
                                            style={{ "margin-right": "10px" }}
                                            src={images("./icons/white-x-circle.svg")}
                                        />
                                        No-Show
                                    </Button>
                                    <p className={"small-text pt-1"}>
                                        This will exit the call and record a no show
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showNoShowModal && (
                <div className={"GuidedVideoCall__launcher_modal"}>
                    <div className={"GuidedVideoCall__launcher_modal-card"}>
                        <img
                            className="GuidedVideoCall__close-btn"
                            src={images("./icons/cross.svg")}
                            role="button"
                            onClick={() => setShowNoShowModal(false)}
                            alt="close"
                        />
                        <p className={"launcher-title"}>End Call</p>
                        <div className={"GuidedVideoCall__launcher_modal-card-body"}>
                            <div className={"left-body"}>
                                <div>
                                    <p className={"fw-bold mb-4"}>End Call</p>
                                    <p className={"text"}>
                                        Once the call is complete, please complete the Initial
                                        Clinical Assessment for the member
                                    </p>
                                </div>
                                <div className={"buttons-container"}>
                                    <Button
                                        type="button"
                                        className="Btn Btn--sec review-note"
                                        onClick={endCall}>
                                        <img
                                            className={"review-note-icon"}
                                            src={images("./icons/check-in-icon.svg")}
                                        />
                                        End Call
                                    </Button>
                                    <p className={"small-text pt-1"}>This will exit the call</p>
                                </div>
                            </div>
                            <div className={"right-body"}>
                                <div>
                                    <p className={"fw-bold mb-4"}>Patient Did Not Attend</p>
                                    <p className={"text mb-0"}>
                                        If your patient did not attend the call you can end the call
                                        here. Recording a <b>No-Show</b> on the session.
                                    </p>
                                    <input
                                        className={"my-2"}
                                        placeholder="No Show Reason"
                                        type="text"
                                        value={noShowReason}
                                        onChange={(e) => setNoShowReason(e.target.value)}
                                    />
                                </div>
                                <div className={"buttons-container"}>
                                    <Button
                                        type="button"
                                        className="Btn Btn--err"
                                        onClick={() => {
                                            updateNoteToBeNoShow().then(() => {
                                                props.history.push({
                                                    pathname: `/app/patient/${callDetails.patient_username}/profile/v2?selectedTab=Notes`,
                                                });
                                            });
                                        }}>
                                        <img
                                            style={{ "margin-right": "10px" }}
                                            src={images("./icons/white-x-circle.svg")}
                                        />
                                        No-Show
                                    </Button>
                                    <p className={"small-text pt-1"}>
                                        This will exit the call and record a no show
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {additional}

            <div className="GuidedVideoCall__mobile_warning d-block d-md-none d-lg-none text-white">
                ⓘ Looking for the full experience? Log in via desktop.
            </div>
        </>
    );
};

export default React.memo(RemoteVideo);
