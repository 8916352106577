import React from "react";
import { CarePlanCard } from "../CarePlanCard";
import {
    HorizontalAnswerBubblesQuestionSet,
    SingleQuestionAnswerBubble,
    SingleQuestionAnswerText,
    Text,
    TextColors,
    TextTypes,
} from "../../../../../component-library";

/**
 * there is no difference between the A5_V2 and A5-R display from the clients point of view
 * so only one component is necessary
 */
export const A5AssessmentDisplay = ({ assessmentSummary }) => {
    return (
        <div className="A5AssessmentDisplay--grid">
            <div className="A5AssessmentDisplay--column">
                <div className="A5AssessmentDisplay--question_container">
                    <div className="A5AssessmentDisplay--hr" />
                    <HorizontalAnswerBubblesQuestionSet
                        questionSetCategory="Life Problems"
                        questionSetOptions={[
                            "No problem",
                            "Mild",
                            "Mild- Moderate",
                            "Moderate",
                            "Moderate- Severe",
                            "Severe",
                        ]}
                        answers={assessmentSummary?.["Life Problems"]}
                        optionColStyle={{ width: 34, marginRight: 1, paddingBottom: 10 }}
                        questionAnswerClassName="align-items-center"
                    />
                </div>
            </div>

            <div className="A5AssessmentDisplay--column">
                <div className="A5AssessmentDisplay--question_container">
                    <div className="A5AssessmentDisplay--hr" />
                    <SingleQuestionAnswerBubble
                        questionSetCategory={"Readiness to Change"}
                        questionSetOptions={[
                            "Not at all",
                            "Very little",
                            "Somewhat",
                            "Much",
                            "Very Much",
                        ]}
                        answerIds={assessmentSummary?.["Readiness to Change"]}
                    />
                </div>
                <div className="A5AssessmentDisplay--question_container">
                    <div className="A5AssessmentDisplay--hr" />
                    <SingleQuestionAnswerText
                        questionSetCategory={"Medical Problems"}
                        answers={assessmentSummary?.["Medical Problems"]}
                    />
                </div>

                <div className="A5AssessmentDisplay--question_container">
                    <div className="A5AssessmentDisplay--hr" />
                    <SingleQuestionAnswerText
                        questionSetCategory={"Traumatic Events"}
                        answers={assessmentSummary?.["Traumatic Events"]}
                    />
                </div>
            </div>

            <div className="A5AssessmentDisplay--symptoms_column">
                <div className="A5AssessmentDisplay--question_container">
                    <div className="A5AssessmentDisplay--hr" />
                    <SingleQuestionAnswerText
                        questionSetCategory="Symptoms"
                        answers={assessmentSummary?.["Symptoms"]}
                        answerClassName={
                            // only reduce text size if not None
                            !Array.isArray(assessmentSummary?.["Symptoms"]) &&
                            "A5AssessmentDisplay--symptom_answer_text"
                        }
                    />
                </div>
            </div>
        </div>
    );
};
