import React, { useState } from "react";
import { CarePlanCard } from "../CarePlanCard";
import { CarePlanIconButton } from "../CarePlanIconButton";
import {
    Button,
    ButtonSizes,
    ButtonTypes,
    Checkbox,
    CustomForm,
    EditIcon,
    Modal,
} from "../../../../../component-library";
import { CloseIcon } from "../../../../Icons/CloseIcon";
import { ICON_SIZES, ICON_TYPES } from "../../../../../constants/icons";
import { Form } from "formik";
import * as yup from "yup";
import { api } from "../../../../../APIRequests";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../../../../redux/actions/loader";
import { localTimestampFromStandardTimestamp } from "../../../../../utils/momentUtils";
import { FormFieldError } from "../../../../../component-library/FormFields/FormFieldError/FormFieldError";
import "./styles.scss";
import { CharacterCounter } from "../../../../../component-library/CharacterCounter/CharacterCounter";
import { FormTextInput } from "../../../../../component-library/FormFields/FormTextInput/FormTextInput";
import { addToast, toastMessageTypes } from "../../../../../redux/actions/toaster";
import { ProviderTypes } from "../../../../../constants/user";
import CloseOnOutsideClick from "../../../../Common/CloseOnOutsideClick/CloseOnOutsideClick";
import { XSS_REGEX } from "../../../../../constants/security";
import { getAutomationDataAttr } from "../../../../../utils/automationUtils";
import { isAuthorized, isUserAdmin } from "../../../../../redux/actions/auth";
import { admin_cse } from "../../../../../config/roles";

const DESCRIPTION_TEXT = "What positive qualities does the patient possess?";
const OTHER_OPTION_ID = "S.OTHER";

export const PatientStrengths = (props) => {
    const [showModal, setShowModal] = useState(false);
    const profile = useSelector((state) => state.auth.profile);
    const { existingStrengths, patientStrengthOptions, refreshStrengths } = props;
    const dispatch = useDispatch();

    const onModalSubmission = async (patientStrengths, otherFreeText) => {
        dispatch(showLoader(true));
        const response = await api.carePlan.update_patient_strengths({
            data: {
                current_version: existingStrengths.current_version,
                patient_strengths: patientStrengths,
                patient_strengths_other_free_text: otherFreeText,
            },
            urlParams: { patientId: props.patientId },
            params: { showLoader: false },
            errorTypeConfig: {
                CarePlanInvalidVersionException: {
                    toastErrorType: toastMessageTypes.error_v2,
                    toastDismissTimeout: 300000, // 5 minutes in millis
                },
            },
        });
        await refreshStrengths();
        dispatch(hideLoader());
        closeModal();

        dispatch(
            addToast({
                message: response?.message || "Updated",
                messageType: toastMessageTypes.success_v2,
                dismissTimeout: 1000,
            }),
        );
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const renderExistingPatientStrengths = () => {
        const {
            patient_strengths,
            patient_strengths_other_free_text,
            updated_by_name,
            updated_timestamp,
        } = existingStrengths;

        const formattedStrengths = patient_strengths
            .map((strengthId) => {
                const strengthOption = patientStrengthOptions.find(
                    (option) => option.strength_id === strengthId,
                );
                if (strengthOption.strength_id === OTHER_OPTION_ID) {
                    return patient_strengths_other_free_text;
                }
                return strengthOption.strength_text;
            })
            .join(", ");

        return (
            <div
                className={"PatientGoalsStrengthsCautions--content"}
                {...getAutomationDataAttr("PatientStrengths_existingStrengths")}>
                <p className={"PatientGoalsStrengthsCautions--readonly-text"}>
                    {formattedStrengths}
                </p>
                <div className="PatientGoalsStrengthsCautions--meta-data">
                    <p className="PatientGoalsStrengthsCautions--meta-data-text">
                        Last Updated by {updated_by_name}
                    </p>
                    <p className="PatientGoalsStrengthsCautions--meta-data-text">
                        {localTimestampFromStandardTimestamp(updated_timestamp, "M/D/YYYY")}
                    </p>
                </div>
            </div>
        );
    };

    const renderNoPatientStrengthsSpecified = () => {
        const isAuthorized = isAuthorizedToEdit();
        const noPermissionText =
            "Authorized members of the patient's aptihealth care team can update patient strengths.";
        const hasPermissionText = "Choose “Add” to add strengths.";
        const textBackgroundClass = `PatientGoalsStrengthsCautions--empty${
            isAuthorized ? "" : " no-permission"
        }`;

        return (
            <div
                className={"PatientGoalsStrengthsCautions--empty-container"}
                {...getAutomationDataAttr("PatientStrengths_emptyStrengths")}>
                <div className={textBackgroundClass}>
                    <p className={"PatientGoalsStrengthsCautions--empty-text"}>
                        {isAuthorized ? hasPermissionText : noPermissionText}
                    </p>
                </div>
            </div>
        );
    };

    const isAuthorizedToEdit = () => {
        if (isAuthorized(admin_cse)) {
            return profile?.is_edit_care_plan_enabled;
        } else {
            return (
                props.isProviderOnCareTeam &&
                profile?.provider_type &&
                [
                    ProviderTypes.BEHAVIORAL,
                    ProviderTypes.CASE_MANAGER,
                    ProviderTypes.PEER,
                    ProviderTypes.PRESCRIBE,
                ].includes(profile.provider_type)
            );
        }
    };

    return (
        <CarePlanCard
            title="Patient Strengths"
            testId={"PatientStrengths_container"}
            className="PatientGoalsStrengthsCautions--element-container"
            actions={
                isAuthorizedToEdit() && (
                    <CarePlanIconButton
                        testId={"PatientStrengths_editButton"}
                        onClick={() => setShowModal(true)}
                        text={existingStrengths?.patient_strengths?.length ? "Edit" : "Add"}
                        Icon={EditIcon}
                        iconClassName="MemberProfileCarePlan--icon"
                        iconButtonClassName="MemberProfileCarePlan--icon-button"
                    />
                )
            }>
            <p className="PatientGoalsStrengthsCautions--subtitle">{DESCRIPTION_TEXT}</p>
            {existingStrengths?.patient_strengths?.length
                ? renderExistingPatientStrengths()
                : renderNoPatientStrengthsSpecified()}
            {showModal && (
                <PatientStrengthsModal
                    show={showModal}
                    onClose={closeModal}
                    patientStrengthOptions={patientStrengthOptions}
                    onModalSubmission={onModalSubmission}
                    existingStrengths={existingStrengths}
                />
            )}
        </CarePlanCard>
    );
};

const PatientStrengthsModal = (props) => {
    return (
        <Modal
            className="PatientGoalsStrengthsCautions--modal"
            show={props.show}
            cardClassName="PatientGoalsStrengthsCautions--modal_dialog">
            <CloseOnOutsideClick setShowComponent={props.onClose}>
                <CloseIcon
                    onClick={props.onClose}
                    className={"PatientGoalsStrengthsCautions--modal_close_icon"}
                    iconSize={ICON_SIZES.SMALL}
                    iconType={ICON_TYPES.OUTLINE}
                />
                <PatientStrengthsForm
                    onClose={props.onClose}
                    onModalSubmission={props.onModalSubmission}
                    patientStrengthOptions={props.patientStrengthOptions}
                    existingStrengths={props.existingStrengths}
                />
            </CloseOnOutsideClick>
        </Modal>
    );
};

const PatientStrengthsForm = (props) => {
    const { existingStrengths, patientStrengthOptions } = props;
    const {
        patient_strengths: initialPatientStrengths,
        patient_strengths_other_free_text: initialOtherFreeText,
    } = existingStrengths;

    const maxOtherTextCharacters = 280;
    const patientStrengthsSchema = yup.object().shape({
        patientStrengths: yup.array().of(yup.string()),
        otherFreeText: yup.string().when("patientStrengths", (strengths, schema) => {
            return strengths.includes(OTHER_OPTION_ID)
                ? yup
                      .string()
                      .required("Other reason must be specified")
                      .max(
                          maxOtherTextCharacters,
                          `Limit text to ${maxOtherTextCharacters} characters`,
                      )
                      .test("special characters", "Special characters are not allowed", (value) => {
                          return !XSS_REGEX.test(value);
                      })
                : schema;
        }),
    });

    const checkboxHandler = async (formikProps, selectedOption) => {
        const updatedPatientStrengths = new Set(formikProps.values.patientStrengths);
        let updatedOtherFreeText = formikProps.values.otherFreeText;

        if (updatedPatientStrengths.has(selectedOption.strength_id)) {
            updatedPatientStrengths.delete(selectedOption.strength_id);

            // Reset the other text data
            if (selectedOption.strength_id === OTHER_OPTION_ID) {
                updatedOtherFreeText = "";
            }
        } else {
            updatedPatientStrengths.add(selectedOption.strength_id);
        }

        await formikProps.setValues({
            patientStrengths: Array.from(updatedPatientStrengths),
            otherFreeText: updatedOtherFreeText,
        });
    };

    const renderOtherTextInput = (formikProps, formikInputProps) => {
        return (
            <>
                <FormTextInput
                    {...formikInputProps}
                    name="otherFreeText"
                    formikProps={formikProps}
                    readOnly={!formikProps?.values?.patientStrengths?.includes(OTHER_OPTION_ID)}
                    testId={"PatientStrengths_otherTextInput"}
                    triggerErrorOnChange
                />
                <CharacterCounter
                    className={"PatientGoalsStrengthsCautions--input_character_counter"}
                    length={formikProps.values?.otherFreeText?.length}
                    characterLimit={maxOtherTextCharacters}
                />
            </>
        );
    };

    const renderOptions = (formikProps) => {
        const formikInputProps = {
            inputClasses: "Input--sm",
            errors: formikProps.errors,
            touched: formikProps.touched,
        };

        const options = patientStrengthOptions.map((option, index) => {
            return (
                <div key={index}>
                    <Checkbox
                        onClick={async () => checkboxHandler(formikProps, option)}
                        name={"patientStrengths"}
                        value={option.strength_id}
                        checked={formikProps.values.patientStrengths.includes(option.strength_id)}
                        label={option.strength_text}
                        testId={`PatientStrengths_input_${option.strength_id}`}
                    />
                    {option.strength_id === OTHER_OPTION_ID &&
                        renderOtherTextInput(formikProps, formikInputProps)}
                </div>
            );
        });

        const splitIndex = Math.ceil(options.length / 2);

        return (
            <>
                <div className={"PatientStrengths--modal-options"}>
                    {options.slice(0, splitIndex)}
                </div>
                <div className={"PatientStrengths--modal-options"}>
                    {options.slice(splitIndex, options.length)}
                </div>
            </>
        );
    };

    const renderForm = (formikProps) => {
        return (
            <Form>
                <div className="PatientGoalsStrengthsCautions--modal_form">
                    <div data-public className="FormField-header_container">
                        <p className="PatientGoalsStrengthsCautions--modal_subtitle">
                            {DESCRIPTION_TEXT}
                        </p>
                    </div>

                    <div className={"PatientStrengths--modal-option-container"}>
                        {renderOptions(formikProps)}
                    </div>
                    {formikProps.errors?.patientStrengths && (
                        <FormFieldError>{formikProps.errors.patientStrengths}</FormFieldError>
                    )}

                    <div className="PatientGoalsStrengthsCautions-button_wrapper">
                        <Button
                            type="submit"
                            buttonSize={ButtonSizes.medium}
                            buttonType={ButtonTypes.primaryV2}>
                            Save
                        </Button>
                        <Button
                            type="button"
                            onClick={props.onClose}
                            buttonType={ButtonTypes.primaryOutlineV2}
                            buttonSize={ButtonSizes.medium}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <div className="PatientGoalsStrengthsCautions--modal_internal">
            <div className="PatientGoalsStrengthsCautions--modal_header">
                <h1 className="PatientGoalsStrengthsCautions--modal_title">
                    {`${initialPatientStrengths?.length ? "Edit" : "Add"} Patient Strengths`}
                </h1>
            </div>
            <CustomForm
                initialValues={{
                    patientStrengths: [...initialPatientStrengths],
                    otherFreeText: initialOtherFreeText ?? "",
                }}
                validationSchema={patientStrengthsSchema}
                validateOnChange={true}
                validateOnBlur={true}
                render={renderForm}
                onSubmit={async (formData) => {
                    await props.onModalSubmission(
                        formData.patientStrengths,
                        formData.otherFreeText,
                    );
                }}
            />
        </div>
    );
};
