// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import "./styles.scss";
const ListViewNav = (props) => {
    let classes = "ListNav__item fs-18 fs-lg-14 fw-bold d-inline";

    const { status, tabs } = props;
    return props.centered ? (
        <div
            className="ListNav__centered d-flex d-lg-block justify-content-center"
            data-public="true">
            <ul className="d-flex justify-content-center">
                {tabs.map((listTextTitle, index) => {
                    let isActive = listTextTitle === status;
                    return (
                        <li
                            key={index}
                            className={`${classes} centered ${isActive ? "active" : ""}`}
                            onClick={() => props.ListNavHandler(listTextTitle)}>
                            {listTextTitle}
                        </li>
                    );
                })}
            </ul>
        </div>
    ) : (
        <ul className="ListNav d-flex d-lg-block justify-content-center" data-public="true">
            {tabs.map((listTextTitle, index) => {
                let isActive = listTextTitle === status;
                return (
                    <li
                        key={index}
                        className={`${classes} ${isActive ? "active" : ""}`}
                        onClick={() => props.ListNavHandler(listTextTitle)}>
                        {listTextTitle}
                    </li>
                );
            })}
        </ul>
    );
};

export default ListViewNav;
