/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const EditIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Edit"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.0858 8.91415C30.8668 8.13311 30.8668 6.86678 30.0858 6.08573L26.9142 2.91416C26.1331 2.13311 24.8668 2.13311 24.0858 2.91416L8.23568 18.7642C8.08268 18.9172 7.98344 19.1157 7.95284 19.3299L7.18854 24.68C7.09426 25.34 7.65994 25.9057 8.31991 25.8114L13.67 25.0471C13.8842 25.0165 14.0827 24.9172 14.2357 24.7642L30.0858 8.91415ZM13 23.1225L9.357 23.6429L9.87742 20H11V22H13V23.1225ZM25.6715 10.5L15 21.1715V20H13V18H11.8284L22.5 7.32843L25.6715 10.5ZM27.0857 9.08575L23.9142 5.91417L25.5 4.32837L28.6716 7.49994L27.0857 9.08575Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Edit"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.0858 10.9142C27.8668 10.1331 27.8668 8.86678 27.0858 8.08573L23.9142 4.91416C23.1331 4.13311 21.8668 4.13311 21.0858 4.91416L5.23568 20.7642C5.08268 20.9172 4.98344 21.1157 4.95284 21.3299L4.18854 26.68C4.09426 27.34 4.65994 27.9057 5.31991 27.8114L10.67 27.0471C10.8842 27.0165 11.0827 26.9172 11.2357 26.7642L27.0858 10.9142ZM10 25.1225L6.357 25.6429L6.87742 22H8V24H10V25.1225ZM23.5 14.5L17.5 8.5L19 6.99992L25 12.9999L23.5 14.5Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Edit"
                {...props}>
                <path d="M12.7678 27.2322L15.5 26.8419V26.0001H14V24.5001H13.1581L12.7678 27.2322Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM28.3143 14.0644C28.9001 14.6501 28.9001 15.5999 28.3143 16.1857L26.75 17.75L22.25 13.25L23.8143 11.6857C24.4001 11.0999 25.3499 11.0999 25.9356 11.6857L28.3143 14.0644ZM25.625 18.875L16.4268 28.0732C16.312 28.188 16.1631 28.2624 16.0025 28.2854L11.9899 28.8586C11.495 28.9293 11.0707 28.505 11.1414 28.0101L11.7146 23.9975C11.7376 23.8369 11.812 23.688 11.9268 23.5732L22.25 13.25L21.125 14.3751L25.625 18.875Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

EditIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

EditIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
