import { useCallback, useEffect, useRef } from "react";

/**
 * Hook used to debounce a callback
 * @param callback callback to debounce
 * @param delay amount of time to wait before resolving callback
 * @return {*} return value of callback
 */
export const useDebounce = (callback, delay) => {
    const timerId = useRef();

    const clearTimeoutIfPresent = () => {
        if (timerId.current) {
            clearTimeout(timerId.current);
        }
    };

    const debouncedCallback = useCallback(
        (...args) => {
            return new Promise((resolve) => {
                clearTimeoutIfPresent();
                timerId.current = setTimeout(async () => {
                    resolve(await callback(...args));
                }, delay);
            });
        },
        [callback, delay],
    );

    // Make sure to clean up stale timers on unmount
    useEffect(() => {
        return () => {
            clearTimeoutIfPresent();
        };
    }, []);

    return debouncedCallback;
};
