// Copyright aptihealth, inc. 2019 All Rights Reserved

/** ------------------------------------------
 * 90 Day care status values
 */
export const NOT_ASSIGNED = "not_assigned";
export const NOT_STARTED = "not_started";
export const IN_PROGRESS = "in_progress";
export const AWAITING_REVIEW = "awaiting_review";
export const OUTCOME_ASSESSMENT_COMPLETE = "outcome_assessment_complete";
export const COMPLETED = "completed";
/** ---------------------------------------- */

// progress slider status constants
export const DEFAULT = "default";
export const DONE = "done";
export const ACTIVE = "active";

export const transformMarkerData = (markerData, status) => {
    switch (status) {
        case NOT_STARTED:
            markerData[0].status = ACTIVE;
            markerData[1].status = DEFAULT;
            markerData[2].status = DEFAULT;
            break;
        case IN_PROGRESS:
            markerData[0].status = DONE;
            markerData[1].status = ACTIVE;
            markerData[2].status = DEFAULT;
            break;
        case AWAITING_REVIEW:
            markerData[0].status = DONE;
            markerData[1].status = DONE;
            markerData[2].status = ACTIVE;
            break;
        case OUTCOME_ASSESSMENT_COMPLETE:
        case COMPLETED:
            markerData[0].status = DONE;
            markerData[1].status = DONE;
            markerData[2].status = DONE;
            break;
        default:
    }
};
