import React from "react";
import * as ReactDOM from "react-dom";
import PropTypes from "prop-types";

export const Portal = ({ children, portalRoot }) => {
    const root = document.getElementById(portalRoot);

    return root ? ReactDOM.createPortal(children, root) : null;
};

Portal.propTypes = {
    children: PropTypes.element.isRequired,
    portalRoot: PropTypes.string,
};

Portal.defaultProps = {
    children: <></>,
    portalRoot: "root",
};
