import "./styles.scss";
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { CalendarBackgroundTimeAxis } from "./CalendarBackgroundTimeAxis";
import { timeSlots } from "../../../constants/calendar";
import { openAppointmentPicker } from "../../../redux/actions/calendar";
import { connect } from "react-redux";
import moment from "moment/moment";


/**
 *
 * @param {number} dayNumber represents the order of days displayed on the screen
 * since Sunday is the first slot on the mockups Sunday has dayNumber 1
 * @returns
 */
export const getDaySlotId = (dayNumber) => {
    return `CalendarDaySlot-${dayNumber}`;
};
    
const getSlotTimeClicked = (className, slot) => {
    if (className.includes("secondHalf")) {
        const nextSlot = moment(slot, "HH:mm").add(30, "minutes");;
        return nextSlot.format("HH:mm");
    }

    return slot;
};

const CalendarBackground = forwardRef(function CalendarBackground(
    { daysToDisplay, calendarRect, calendar, openAppointmentPicker },
    ref,
) {
    // loop through all days
    const calendarDays = daysToDisplay.map((day, index) => {
        const dayNumber = index + 1;
        // create a slot for each hour in the day
        const timeSlotElements = timeSlots.map((slot) => {
            // first slot is a different class because we do not want borers of adjacent slots to run into each other
            // to do this we set border-left: 0 for the slot and border-left: 1px for the first-slot
            const timeSlotClassName =
                dayNumber === 1 ? "CalendarDaySlot__first-slot" : "CalendarDaySlot__slot";
            const timeSlotElement = (
                <div
                    onClick={(e) => {
                        // Set new selected dates
                        openAppointmentPicker({
                            openDrawer: true,
                            selectedScheduleDate: calendar.selectedScheduleDates[dayNumber - 1],
                            selectedScheduleTime: getSlotTimeClicked(e.target.className, slot.slot)
                        });
                    }}
                    className={timeSlotClassName}
                >
                    <div className="firstHalf slotChunk"/>
                    <div className="secondHalf slotChunk"/>
                </div>
            );
            return timeSlotElement;
        });

        return (
            <div id={getDaySlotId(dayNumber)} className="CalendarDaySlot__day">
                {timeSlotElements}
            </div>
        );
    });

    return (
        // a single day is displayed differently because it is supposed to take up the full screen no matter what
        // for a week day the view horizontally scrolls at different screen widths
        <div
            ref={ref}
            className={
                daysToDisplay.length === 1
                    ? "CalendarBackground__body__single-day"
                    : "CalendarBackground__body"
            }>
            <CalendarBackgroundTimeAxis calendarRect={calendarRect} />
            {calendarDays}
        </div>
    );
});

CalendarBackground.propTypes = {
    numberOfDays: PropTypes.number,
};

CalendarBackground.defaultProps = {
    numberOfDays: 1,
};

const mapStateToProps = (state) => {
    return {
        calendar: state.calendar,
    };
};

const mapDispatchToProps = {
    openAppointmentPicker,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    CalendarBackground,
);
