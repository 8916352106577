import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";
import { Card } from "../Card";

import "./styles.scss";

export const MemberHeader = ({
    className,
    memberName,
    dob,
    age,
    phoneNumber,
    dayOfCare,
    primaryDiagnosis,
    secondaryDiagnosis,
}) => {
    return (
        <div className={createClassNameString(["apti-CL apti-MemberHeader", className])}>
            <Card className={"apti-MemberHeader--card"}>
                <div className={"apti-MemberHeader--container"}>
                    <div className={"apti-MemberHeader--left-container"}>
                        <p className={"apti-MemberHeader--name"}>{memberName}</p>
                        <div className={"apti-MemberHeader--left-sub-container"}>
                            <span className={"apti-MemberHeader--dob"}>
                                {dob} ({age})
                            </span>
                            <span> • </span>
                            <span className={"apti-MemberHeader--phone-number"}>{phoneNumber}</span>
                            <span> • </span>
                            <span className={"apti-MemberHeader--day-of-care"}>
                                Day {dayOfCare} of care
                            </span>
                        </div>
                    </div>
                    <div className={"vertical-break"} />
                    <div className={"apti-MemberHeader--right-container"}>
                        <div className={"apti-MemberHeader--primary-diagnosis-container"}>
                            <span className={"apti-MemberHeader--primary-diagnosis-title"}>
                                Primary Diagnosis:{" "}
                            </span>
                            <span>{primaryDiagnosis}</span>
                        </div>
                        <div className={"apti-MemberHeader--secondary-diagnosis-container"}>
                            <span className={"apti-MemberHeader--secondary-diagnosis-title"}>
                                Secondary Diagnosis:{" "}
                            </span>
                            <span>{secondaryDiagnosis}</span>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
};

MemberHeader.propTypes = {
    className: PropTypes.string,
    memberName: PropTypes.string,
    dob: PropTypes.string,
    age: PropTypes.string,
    phoneNumber: PropTypes.string,
    dayOfCare: PropTypes.string,
    primaryDiagnosis: PropTypes.string,
    secondaryDiagnosis: PropTypes.string,
};

MemberHeader.defaultProps = {
    className: undefined,
    memberName: "",
    dob: "",
    age: "",
    phoneNumber: "",
    dayOfCare: "",
    primaryDiagnosis: "",
    secondaryDiagnosis: "",
};
