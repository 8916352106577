// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import _ from "lodash";
import withRouter from "react-router-dom/es/withRouter";
import {
    uploadAssociatedFiles,
    UserFileTypes,
    viewPatientAssociatedFile,
} from "../../../../utils/fileUtil";
import UploadFile from "./../UploadFile";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "../../../../redux/actions/loader";
import BasicInfo from "../../../Patient/Profile/BasicInfo";
import EmergencyInfo from "../../../Patient/Profile/EmergencyInfo";
import { loadPatientInsuranceImagesUrlToState } from "../../../../containers/Dashboard/Patient/PatientInsurance";
import ImageFullPreview from "../../../Common/ImageFullPreview";
import { PRI, SEC } from "../../../Patient/Profile";
import { MemberProfileFiles } from "./MemberProfileFiles";
import { api } from "../../../../APIRequests";
import InsuranceInfo from "../../../Patient/Profile/InsuranceInfo";
import moment from "moment-timezone";
import MemberProfileDischargeDataList from "./MemberProfileDischargeDataList";
import CardSecondary from "../../../UI/Card/CardSecondary";
import CardHeading from "../../../UI/Headings/CardHeading";
import { MED_MGMT_OPTIONS } from "../../../../containers/Dashboard/Providers/schema/newPatientFormSchema";
import Input from "../../../UI/Input";
import FamilyMembersWidget from "./Widget/FamilyMembersWidget";
import { InsuranceEligibilityRecord } from "../../../../component-library";
import {
    formatInsuranceType,
    formatTimestamp,
    getFormattedStatus,
    getStatusClass,
    getStatusDefinition,
    showRawResponse,
} from "../../../../component-library/InsuranceEligibilityRecord/utils";
import { isAuthorized } from "../../../../redux/actions/auth";
import MemberProfileConsentList from "./MemberProfileConsentList";
import { addToast, toastMessageTypes } from "../../../../redux/actions/toaster";

class MemberProfileMedical extends Component {
    state = {
        patientId: this.props.match.params.patientId,
        showUploadFileCard: false,
        insFullPreviewUrl: null,
        insFullPreviewContentType: null,
        fileName: null,
        editBasicInfo: false,
        editEmergencyInfo: false,
        editPriIns: false,
        editSecIns: false,
        patientDetails: this.props.patientDetails,
        schema: {},
        insuranceImageUrls: this.props.data.insuranceImageUrls
            ? this.props.data.insuranceImageUrls
            : {
                  pfUrl: null,
                  pbUrl: null,
                  sfUrl: null,
                  sbUrl: null,
              },
        insuranceImgageTypes: {
            pfType: null,
            pbType: null,
            sfType: null,
            sbType: null,
        },
        eligibilityDetails: {},
        filesToBeUploaded: [],
        acknowledgements: {},
    };

    componentDidMount() {
        if (
            !this.state.insuranceImageUrls.pfUrl ||
            !this.state.insuranceImageUrls.pbUrl ||
            !this.state.insuranceImageUrls.sfUrl ||
            !this.state.insuranceImageUrls.sbUrl
        ) {
            loadPatientInsuranceImagesUrlToState(this.props.patientDetails, this);
            this.setState({
                eligibilityDetails: this.props.patientDetails.primary_eligibility_record,
            });
        }
    }

    addAcknowledgement = (policyName, acknowledgement) => {
        const { patientDetails } = this.state;
        if (patientDetails.acknowledgements && !patientDetails.acknowledgements[policyName]) {
            const acknowledgements = {
                ...this.state.acknowledgements,
                [policyName]: acknowledgement,
            };
            this.setState({ acknowledgements });
        }
    };

    submitHandler = async (formData, actions, editForm, schema) => {
        const data = { ...formData };
        const constructedData = {};
        schema.forEach((field) => {
            let name = field.name;
            if (name && name.split(".").length > 1) {
                let path = name.split(".");
                let value = _.get(data, path);
                _.setWith(constructedData, path, value);
            } else if (name) {
                constructedData[name] = formData[name];
            }
        });
        constructedData["username"] = formData["username"];
        const urlParams = { patientId: this.state.patientId };

        if (formData["mobile"] !== this.state.patientDetails.mobile) {
            if (this.state.patientDetails?.preferences?.is_sms_verified) {
                data["preferences"]["is_sms_verified"] = false;
            }
        }

        await api.provider.update_patient_details({ data: constructedData, urlParams });
        actions.setSubmitting(false);
        let newState = { patientDetails: data };
        newState[editForm] = false;
        this.setState(newState);
        this.props.updateState(newState);
    };

    insuranceSubmitHandler = async (formData, actions) => {
        const data = { ...formData };
        data.acknowledgements = {
            ...this.state.patientDetails.acknowledgements,
            ...this.state.acknowledgements,
        };

        const insurance_data = {
            card_back: data.primary_insurance?.card_back,
            card_front: data.primary_insurance?.card_front,
            carrier: data.primary_insurance?.carrier,
            group_id: data.primary_insurance?.group_id,
            member_id: data.primary_insurance?.member_id,
            policy_holder_name: data.primary_insurance?.policy_holder_name,
            policy_dob: this.state.patientDetails.dob,
            state: data.primary_insurance?.state,
            dob: this.state.patientDetails?.dob,
            email: this.props.patientDetails.email,
            first_name: this.state.patientDetails.first_name,
            last_name: this.state.patientDetails.last_name,
        };

        if (formData["primary_insurance"]) {
            await api.provider
                .check_and_fetch_eligibility_record({ data: insurance_data })
                .then(async (data) => {
                    const updated_insurance_check = data;
                    this.setState({
                        eligibilityDetails: updated_insurance_check,
                    });
                    this.props.updateState({ eligibilityDetails: updated_insurance_check });
                })
                .catch((err) => {
                    console.error(err);
                });
        }

        data["username"] = this.state.patientId;
        const urlParams = { patientId: this.state.patientId };

        await api.provider.update_patient_details({ data: data, urlParams });
        actions.setSubmitting(false);
        const updatedPatient = {
            ...this.state.patientDetails,
            ...formData,
            acknowledgements: data.acknowledgements,
        };
        let newState = {
            patientDetails: updatedPatient,
        };

        if (formData["primary_insurance"]) {
            newState["editPriIns"] = !this.state.editPriIns;
        }

        if (formData["secondary_insurance"]) {
            newState["editSecIns"] = !this.state.editSecIns;
        }

        this.setState(newState);
        this.props.updateState(newState);
    };

    onFileInputChange = (target) => {
        let filesToBeUploaded = [...this.state.filesToBeUploaded];
        if (target && target.files.length > 0) {
            for (let file of target.files) {
                filesToBeUploaded.push(file);
            }
            this.setState({ filesToBeUploaded });
        }
    };

    onFileFileDelete = (index) => {
        let changedFiles = [...this.state.filesToBeUploaded];
        changedFiles.splice(index, 1);
        this.setState({
            filesToBeUploaded: changedFiles,
        });
    };

    onInsertFiles = async () => {
        this.props.showLoader();
        this.setState({ showUploadFileCard: false });

        try {
            const newFiles = await uploadAssociatedFiles(
                this.state.filesToBeUploaded,
                "patient",
                this.state.patientId,
                UserFileTypes.ASSOCIATED_FILE_PATIENT,
            );
            this.setState({ filesToBeUploaded: [] });
            this.props.updateState({ files: [...newFiles, ...this.props.files] });
        } catch (err) {
            console.log(err);
            this.props.addToast({
                message: err.message,
                messageType: toastMessageTypes.error_v2,
            });
        }

        this.props.hideLoader();
    };

    render() {
        let dischargeDataList;

        if (this.props.noteData) {
            dischargeDataList = this.props.noteData
                .filter((note) => note.note_type === "Discharge")
                .sort(
                    (a, b) =>
                        moment(b.content.discharge_date, "MM/DD/YYYY") -
                        moment(a.content.discharge_date, "MM/DD/YYYY"),
                );
        }

        return (
            <>
                {this.state.showUploadFileCard && (
                    <UploadFile
                        hideModal={() => this.setState({ showUploadFileCard: false })}
                        files={this.state.filesToBeUploaded}
                        onInputChange={this.onFileInputChange}
                        handleDrop={this.onFileInputChange}
                        onFileRemove={this.onFileFileDelete}
                        onInsertFiles={this.onInsertFiles}
                    />
                )}

                {this.state.insFullPreviewUrl && (
                    <ImageFullPreview
                        url={this.state.insFullPreviewUrl}
                        contentType={this.state.insFullPreviewContentType}
                        close={() => this.setState({ insFullPreviewUrl: null })}
                    />
                )}

                {this.props.patientDetails.medication_management_services && (
                    <CardSecondary style={{ maxWidth: "100%" }} className={"pt-4 px-4 mt-3"}>
                        <div className="d-flex align-items-center justify-content-between py-4">
                            <CardHeading
                                text="Medication Management Services"
                                className="fs-16 mx-3 my-0"
                            />
                        </div>
                        <hr className="m-0" />
                        <div className="py-4">
                            <ul className={"mx-3"}>
                                {this.props.patientDetails.medication_management_services.map(
                                    (service) => (
                                        <>
                                            {MED_MGMT_OPTIONS[service] && (
                                                <li key={service}>
                                                    <Input
                                                        checked="checked"
                                                        type="checkbox"
                                                        style={{ height: 16 }}
                                                        className="AddPatientInput m-2"
                                                        disabled
                                                    />
                                                    {MED_MGMT_OPTIONS[service].label}
                                                </li>
                                            )}
                                        </>
                                    ),
                                )}
                            </ul>
                        </div>
                    </CardSecondary>
                )}

                <MemberProfileFiles
                    showPopUp={() => this.setState({ showUploadFileCard: true })}
                    viewAllLink={`/app/patient/${this.state.patientId}/file`}
                    fileList={this.props.files}
                    getFile={(key) => viewPatientAssociatedFile(this.state.patientId, key)}
                />

                <BasicInfo
                    {...this.props}
                    data={this.state.patientDetails}
                    submit={(formData, actions, schema) =>
                        this.submitHandler(formData, actions, "editBasicInfo", schema)
                    }
                    edit={this.state.editBasicInfo}
                />

                <MemberProfileConsentList patientDetails={this.state.patientDetails} />

                <FamilyMembersWidget {...this.props} />

                {this.props.noteData && (
                    <MemberProfileDischargeDataList dischargeDataList={dischargeDataList} />
                )}

                <EmergencyInfo
                    {...this.props}
                    data={this.state.patientDetails}
                    submit={(formData, actions, schema) =>
                        this.submitHandler(formData, actions, "editEmergencyInfo", schema)
                    }
                    edit={this.state.editEmergencyInfo}
                />

                <InsuranceInfo
                    {...this.props}
                    data={{
                        username: this.state.patientId,
                    }}
                    insuranceImageUrls={this.state.insuranceImageUrls}
                    submit={(formData, actions) => this.insuranceSubmitHandler(formData, actions)}
                    edit={this.state.editPriIns}
                    onEdit={() =>
                        this.setState({
                            editPriIns: !this.state.editPriIns,
                            editSecIns: false,
                        })
                    }
                    type={PRI}
                    emptyTitle={"Add Insurance Information"}
                    emptyDetails={"The member does not have any insurance information."}
                    editTitle={"Your Insurance Information"}
                    completedTitle={"Insurance Information"}
                    isEditing={this.state.editPriIns}
                    onEditButtonClick={() =>
                        this.setState({
                            editPriIns: !this.state.editPriIns,
                            editSecIns: false,
                        })
                    }
                    onAddButtonClick={() =>
                        this.setState({
                            editPriIns: !this.state.editPriIns,
                            editSecIns: false,
                        })
                    }
                    onCancelButtonClick={() =>
                        this.setState({ editPriIns: !this.state.editPriIns })
                    }
                    onFrontImageClick={() =>
                        this.setState({
                            insFullPreviewUrl: this.state.insuranceImageUrls.pfUrl,
                            insFullPreviewContentType: this.state.insuranceImageTypes.pfType,
                        })
                    }
                    onBackImageClick={() =>
                        this.setState({
                            insFullPreviewUrl: this.state.insuranceImageUrls.pbUrl,
                            insFullPreviewContentType: this.state.insuranceImageTypes.pbType,
                        })
                    }
                    insurance={_.get(this.state.patientDetails, "primary_insurance")}
                    addAcknowledgement={this.addAcknowledgement}
                />

                {this.state.eligibilityDetails && (
                    <div className={"mt-2 mb-4"}>
                        <InsuranceEligibilityRecord
                            className={getStatusClass(this.state.eligibilityDetails.status)}
                            insuranceType={formatInsuranceType(
                                this.state.eligibilityDetails.insurance_type,
                            )}
                            status={getFormattedStatus(this.state.eligibilityDetails.status)}
                            copay={this.state.eligibilityDetails.copay}
                            statusDefinition={getStatusDefinition(
                                this.state.eligibilityDetails.status,
                            )}
                            numberOfAttempts={this.state.eligibilityDetails.number_of_attempts}
                            transactionId={this.state.eligibilityDetails.control_number}
                            timestamp={formatTimestamp(this.state.eligibilityDetails.timestamp)}
                            allowRetry={false}
                            carrier={this.state.eligibilityDetails.carrier}
                            plan={this.state.eligibilityDetails.plan}
                            errors={this.state.eligibilityDetails.errors}
                            showDownloadRawResponse={
                                isAuthorized("admin:customer_success_executive") &&
                                this.state.eligibilityDetails.raw_response_exists
                            }
                            rawResponseKey={this.state.eligibilityDetails.raw_response_key}
                            downloadResponseButtonOnClick={() =>
                                showRawResponse(this.state.eligibilityDetails)
                            }
                        />
                    </div>
                )}

                <InsuranceInfo
                    {...this.props}
                    data={{
                        username: this.state.patientId,
                    }}
                    insuranceImageUrls={this.state.insuranceImageUrls}
                    submit={(formData, actions) => this.insuranceSubmitHandler(formData, actions)}
                    type={SEC}
                    edit={this.state.editSecIns}
                    onEdit={() =>
                        this.setState({
                            editPriIns: false,
                            editSecIns: !this.state.editSecIns,
                        })
                    }
                    emptyTitle={"Add Secondary Insurance Information"}
                    emptyDetails={"The member does not have any insurance information."}
                    editTitle={"Secondary Insurance Information"}
                    completedTitle={"Secondary Insurance Information"}
                    isEditing={this.state.editSecIns}
                    onEditButtonClick={() =>
                        this.setState({
                            editPriIns: false,
                            editSecIns: !this.state.editSecIns,
                        })
                    }
                    onAddButtonClick={() =>
                        this.setState({
                            editPriIns: false,
                            editSecIns: !this.state.editSecIns,
                        })
                    }
                    onCancelButtonClick={() =>
                        this.setState({ editSecIns: !this.state.editSecIns })
                    }
                    onFrontImageClick={() =>
                        this.setState({
                            insFullPreviewUrl: this.state.insuranceImageUrls.sfUrl,
                            insFullPreviewContentType: this.state.insuranceImageTypes.sfType,
                        })
                    }
                    onBackImageClick={() =>
                        this.setState({
                            insFullPreviewUrl: this.state.insuranceImageUrls.sbUrl,
                            insFullPreviewContentType: this.state.insuranceImageTypes.sbType,
                        })
                    }
                    insurance={_.get(this.state.patientDetails, "secondary_insurance")}
                    addAcknowledgement={this.addAcknowledgement}
                />
            </>
        );
    }
}

export default connect(null, { showLoader, hideLoader, addToast })(
    withRouter(MemberProfileMedical),
);
