// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { useEffect, useState } from "react";
// import Button from '../UI/Button';
import { Button } from "../../component-library/Button";
import FormikInput from "../UI/FormikInput";
import { Formik, Form, Field } from "formik";
import { Link } from "react-router-dom";
import { getQueryParams } from "../../utils/filters";
import * as yup from "yup";
import { AuthWrapper } from "./index";
import { deviceDetect } from "react-device-detect";
import { isSupportedBrowser } from "../Common/SystemCheck/index";
import parser from "ua-parser-js";
import { trackEvent } from "../../utils/EventTrackingUtil";
import { CustomForm } from "../../component-library/CustomForm";

export const initialValues = {
    USERNAME: "",
};

export const formSchema = [
    {
        name: "USERNAME",
        pureName: "username",
        elementType: "input",
        elementConfig: {
            labelClasses: "login-label",
            type: "email",
            label: "Email",
            placeholder: "Enter Email",
        },
    },
];

export const signInSchema = yup.object().shape({
    USERNAME: yup.string().required("Email is required").email("Enter valid email"),
});

const SignIn = (props) => {
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);

    let isEmailVerified =
        getQueryParams("isEmailVerified", props.location.search.trim()) === "true";

    useEffect(function () {
        props.resetPwSteps();
        if (isEmailVerified) {
            props.emailVerified();
        }
    }, []);

    const email_schema = yup.object({
        email: yup.string().email(),
    });

    useEffect(() => {
        email_schema.isValid({ email }).then((valid) => setValidEmail(valid));
    }, [email]);

    const formikInputProps = {
        inputClasses: "Input--sm",
    };

    let form = (
        <CustomForm
            initialValues={initialValues}
            onSubmit={props.submit}
            validationSchema={signInSchema}
            render={(props) => (
                <Form
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}>
                    <div>
                        {formSchema.map((formEl) => {
                            return (
                                <FormikInput
                                    {...formikInputProps}
                                    key={formEl.name}
                                    errors={props.errors}
                                    touched={props.touched}
                                    formEl={formEl}
                                />
                            );
                        })}

                        <div className="d-flex justify-content-center m-lg-5 my-3">
                            <Button
                                type="submit"
                                className={`Btn Btn--pri Btn-sm Btn-login my-3 ${
                                    validEmail && email.length > 0 ? "primary" : "gray"
                                }`}
                                onClick={() => trackEvent("Initiate login")}>
                                Continue
                            </Button>
                        </div>
                        {getSignUpLink()}
                    </div>
                </Form>
            )}
        />
    );

    function logInCard() {
        return (
            <>
                <p className="login-welcome">Welcome back!</p>
                <div style={{}}>{form}</div>
            </>
        );
    }

    let deviceInfo = deviceDetect();
    let message = (
        <>
            Sorry, we don't currently support this browser. Please try using another browser like{" "}
            <a href={"https://www.mozilla.org/en-US/firefox/new/"}>Firefox (download here)</a> or{" "}
            <a href={"https://www.google.com/chrome/"}>Chrome (download here)</a>.
        </>
    );
    const os = deviceInfo.osName || deviceInfo.os;
    if (os === "iOS") {
        message = "Sorry, we don't currently support this browser. Please try using Safari.";
    }

    return (
        <AuthWrapper showBackLink={false}>
            {!isSupportedBrowser(deviceDetect(), parser()) && (
                <div className="SnackBar p-2 position-fixed SnackBar--warning">
                    <div className="SnackBar__wpr d-flex align-items-md-center">
                        <div className="ml-1 ml-md-3">
                            <span className="fs-14">{message}</span>
                            <br />
                        </div>
                    </div>
                </div>
            )}
            {logInCard()}
        </AuthWrapper>
    );
};

export default SignIn;

export function getSignUpLink() {
    return (
        <div className="Auth__switch">
            <p className="text-center not-a-member my-3">
                <span style={{ color: "Grey" }}>Not a member? </span>
                <Link to="/auth/self-signup" className="cursor underline_hover">
                    <b
                        style={{ display: "inline-block", color: "#0CD0A7", fontWeight: "700" }}
                        onClick={() => {
                            trackEvent("Redirect to registration from login");
                        }}>
                        Join Now
                    </b>
                </Link>
            </p>
        </div>
    );
}
