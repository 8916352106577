// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import moment, { localeData } from "moment-timezone";
import { providerRoute } from "../../../config/roles";
import images from "../../../utils/images";
import ScheduleEvent from "./ScheduleEvent";
import ListHeader from "../../UI/ListView/ListHeader";
import CardPrimary from "../../UI/Card/CardPrimary";
import { Link } from "react-router-dom";
import { api } from "../../../APIRequests";
import { connect } from "react-redux";
import { createNewScheduledEvent, getExistingConflicts } from "./index";
import { getQueryParams } from "../../../utils/filters";
import _, { isEmpty } from "lodash";
import { showAlertWithAction } from "../../../redux/actions/alerts";

class EditEvent extends Component {
    constructor(props) {
        super(props);
        let details = this.props.location.state ? this.props.location.state.details : {};
        let localDate = moment.utc(details.timestamp, "YYYY-MM-DD HH:mm").local();
        this.state = {
            time: localDate.format("HH:mm"),
            datePickerConf: null,
            selectedDate: localDate,
            showDatePicker: false,
            details: details,
            scheduleList: [],
        };
    }

    async componentDidMount() {
        let callId = getQueryParams("call_id", this.props.match.search);
        if (!callId) {
            this.props.showAlertWithAction("Invalid Request. Missing required field: callId.");
            return;
        }
        let data = {
            call_id: callId,
        };
        const tokenResponse = await api.shared.fetch_twilio_token({ data });
        const details = tokenResponse.call_details;
        const localDate = moment.utc(details.timestamp, "YYYY-MM-DD HH:mm").local();

        let startDate = moment.utc(details.timestamp, "YYYY-MM-DD  HH:mm");
        let endDate = _.cloneDeep(startDate).add(1, "days");
        let start_date = startDate.utc().format("YYYY-MM-DD");
        let end_date = endDate.utc().format("YYYY-MM-DD");
        const options = {
            queryParams: {
                start_date_time: start_date,
                end_date_time: end_date,
                provider_id: this.state.providerId,
                show_deleted: true,
            },
        };
        const response = await api.schedule.get_schedule({ options });
        const scheduleList = response.schedule;
        const newDetails = scheduleList.find((schedule) => schedule.callId === callId);
        this.setState({
            details: newDetails,
            selectedDate: localDate,
            time: localDate.format("HH:mm"),
            scheduleList,
        });
    }

    setDatePickerConf = (type) => {
        this.setState((ps) => {
            return {
                datePickerConf: type,
                showDatePicker: !ps.showDatePicker,
            };
        });
    };

    dismiss = () => this.props.history.push(providerRoute + "/schedule");

    updateHandler = async (requestBody, type, patientId) => {
        const { timezone } = this.props;
        const { details } = this.state;
        if (!details || isEmpty(details)) {
            this.props.showAlertWithAction("Missing call details to update.");
            return;
        }
        const splittedTs = details.timestamp.split(" ");
        const data = {
            date: splittedTs[0],
            slot: splittedTs[1],
        };
        /** Delete and create schedule on the bases of Action type */
        if (type !== "away") {
            const queryParams = { callId: this.state.details.callId };
            const data = { rescheduled_as: requestBody.event_type };
            await api.shared.remove_appointment({ queryParams, data });
            await createNewScheduledEvent(type, requestBody, patientId, timezone);
        } else {
            await api.provider.set_away_schedule({ data, queryParams: { method: "delete" } });
            await api.provider.set_away_schedule({
                data: requestBody,
                queryParams: { method: "create" },
            });
        }
        this.dismiss();
    };

    setStartTimeSlot = (slotValue) => {
        this.setState({
            time: slotValue,
        });
    };

    render() {
        if (!this.state.details) {
            return <></>;
        }
        return (
            <div>
                <div className="survey-back-link-wpr mx-auto fs-16 my-3 py-2">
                    <Link className="survey-back-link" to={providerRoute + "/schedule"}>
                        <img className="img-fluid" src={images("./common/solidBckBtn.svg")} />
                        <p className="d-inline ml-2">Back to Schedule</p>
                    </Link>
                </div>
                <CardPrimary className="mx-auto" style={{ marginTop: 0 }}>
                    <div>
                        <ListHeader title="Edit Appointment" />
                    </div>
                    <hr className="d-none d-lg-block" />
                    <div>
                        {this.state.details && !isEmpty(this.state.details) && (
                            <ScheduleEvent
                                edit
                                datePickerConf={this.state.datePickerConf}
                                setDatePickerConf={this.setDatePickerConf}
                                dismiss={this.dismiss}
                                patientId={this.state.details.patient_username}
                                slot={this.state.details.timestamp}
                                startTimeSlot={this.state.time}
                                selectedDate={this.state.selectedDate}
                                updateStartTimeSlot={this.setStartTimeSlot}
                                onNewScheduleCreate={this.updateHandler}
                                existingConflicts={getExistingConflicts(
                                    this.state.scheduleList.filter(
                                        (event) => event.callId !== this.state.details.callId,
                                    ),
                                    this.state.selectedDate,
                                )}
                                selectedDateHandler={(date) =>
                                    this.setState({ selectedDate: date })
                                }
                                getSpecificDateSchedule={this.getSpecificDateSchedule}
                                seriesData={this.state.details.series_data}
                                eventType={this.state.details.event_type}
                                callDetails={this.state.details}
                            />
                        )}
                    </div>
                </CardPrimary>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
        timezone: state.auth.timezone,
    };
};

export default connect(mapStateToProps, { showAlertWithAction })(EditEvent);
