import * as actionTypes from "./actionTypes";
import { api } from "../../APIRequests";

let privateInterval = undefined;
let publicInterval = undefined;

const flagsLoaded = (flags) => {
    return {
        type: actionTypes.FLAGS_LOADED,
        ...flags,
    };
};

const flagIntervalReset = (flags) => {
    return {
        type: actionTypes.CLEAR_FLAG_INTERVALS,
        flags: { ...flags },
    };
};

export const loadFlags = () => async (dispatch) => {
    getFeatureFlagsPub().then((transformedConfigs) => {
        dispatch(flagsLoaded({ publicFlags: transformedConfigs }));
    });

    publicInterval = setInterval(async () => {
        if (document.hidden) {
            console.log("Document is currently hidden. Waiting to fetch public feature flags.");
            return;
        }
        getFeatureFlagsPub().then((transformedConfigs) => {
            dispatch(flagsLoaded({ publicFlags: transformedConfigs }));
        });
    }, 300000);
};

export const loadTargetedFlags = (target) => async (dispatch) => {
    getFeatureFlagsPrivate(target).then((transformedConfigs) => {
        dispatch(flagsLoaded({ privateFlags: transformedConfigs }));
    });

    privateInterval = setInterval(async () => {
        if (document.hidden) {
            console.log("Document is currently hidden. Waiting to fetch private feature flags.");
            return;
        }
        getFeatureFlagsPrivate(target).then((transformedConfigs) => {
            dispatch(flagsLoaded({ privateFlags: transformedConfigs }));
        });
    }, 300000);
};

export const clearFlagInterval = () => async (dispatch) => {
    clearInterval(privateInterval);
    dispatch(flagIntervalReset());
};

const getFeatureFlagsPub = async () => {
    const data = { flag_set: "WEB_PUBLIC_FLAG_NAMES" };
    const options = { params: { showLoader: false } };
    return api.featureFlag.get_feature_flag_set({ data, options });
};

const getFeatureFlagsPrivate = async (target) => {
    const data = { flag_set: "WEB_PRIVATE_FLAG_NAMES" };
    const options = { params: { showLoader: false } };
    return api.featureFlag.get_all_targetted_feature_flags({ data, options });
};
