import moment from "moment-timezone";
import CardSecondary from "../../../UI/Card/CardSecondary";
import Badge from "../../../UI/Badge";
import images from "../../../../utils/images";
import React from "react";
import CardHeading from "../../../UI/Headings/CardHeading";
import Button from "../../../UI/Button";
import { isAuthorized } from "../../../../redux/actions/auth";
import { provider_case_manager, provider_peer } from "../../../../config/roles";

export const OutreachAddCard = (props) => {
    let [showOutreachPopup, setShowOutreachPopup] = React.useState(false);

    return (
        <>
            <CardSecondary
                style={{ maxWidth: "none" }}
                className="PatientProfile__CreatePlan-wpr PatientProfile__CardBetweenMargin mx-xl-auto py-4 d-xl-flex d-block justify-content-between align-items-center px-3 px-xl-4">
                <div className="d-flex align-items-center">
                    <img
                        src={images("./common/purple-white-mail.svg")}
                        alt="mail"
                        className="PatientProfile__90DaysPlan img-fluid"
                        width="39px"
                    />
                    <CardHeading
                        text="Warm Contact"
                        className="text-white d-lg-inline-block ml-2 pl-2 mr-0 mr-lg-5 fs-16"
                    />
                </div>

                {!showOutreachPopup &&
                    !isAuthorized(provider_case_manager) &&
                    !isAuthorized(provider_peer) && (
                        <div className="ml-auto">
                            <div className="d-block text-right">
                                <Button
                                    className={"Btn Btn--pri Btn-sm"}
                                    onClick={() => setShowOutreachPopup(true)}>
                                    Add Warm Contact Attempt
                                </Button>
                            </div>
                        </div>
                    )}
            </CardSecondary>
            {showOutreachPopup && (
                <OutreachForm setShowOutreachPopup={setShowOutreachPopup} {...props} />
            )}
        </>
    );
};

export const OutreachForm = (props) => {
    let [contacted, setContacted] = React.useState(true);
    let [note, setNote] = React.useState("");

    const submitOutreachAttempt = () => {
        const outreachAttempt = {
            contacted: contacted,
            note: note,
        };
        props.submitOutreachAttempt(outreachAttempt);
        props.setShowOutreachPopup(false);
    };

    return (
        <CardSecondary
            style={{ backgroundColor: "white", borderRadius: 3, padding: 20, maxWidth: "none" }}>
            <div className="my-3">
                <b>Contact Attempt</b>
            </div>

            <div className="row my-3">
                <div className="ml-3 mr-2">
                    <label>
                        <input
                            type="radio"
                            value={false}
                            onChange={(e) => setContacted(true)}
                            checked={contacted}
                            className={"mr-2"}
                        />
                        Successfully Contacted
                    </label>
                </div>
                <div className="mx-2">
                    <label>
                        <input
                            type="radio"
                            value={false}
                            onChange={(e) => setContacted(false)}
                            checked={!contacted}
                            className={"mr-2"}
                        />
                        Contact Unsuccessful
                    </label>
                </div>
            </div>

            <div className="my-3">
                <b>Notes</b>
                <br />
                <textarea
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    className="w-100"
                />
            </div>
            <div className="d-lg-flex d-block justify-content-center text-center justify-content-lg-start">
                <Button
                    type="button"
                    onClick={() => props.setShowOutreachPopup(false)}
                    className="Btn Btn--otl-pri Btn-sm mr-lg-3 mb-3 mb-lg-0">
                    Cancel
                </Button>
                <Button
                    type="submit"
                    className="Btn Btn--pri Btn-sm"
                    onClick={() => submitOutreachAttempt()}>
                    Add
                </Button>
            </div>
        </CardSecondary>
    );
};

export const OutreachEntries = (props) => {
    const entries = Object.entries(props.outreach);
    const numEntries = entries.length;

    return (
        <div className="my-3">
            {entries
                .sort((a, b) => b[0].localeCompare(a[0]))
                .map(([entry_date, entry_val], i) => {
                    let utcDate = moment.utc(entry_date).local();
                    let dateStr = utcDate.format("MM/DD/YYYY hh:mm a");

                    return (
                        <CardSecondary
                            className={"mx-auto mb-3"}
                            key={entry_date}
                            style={{ maxWidth: "none" }}>
                            <div
                                className="p-1 d-lg-flex align-items-center justify-content-between"
                                style={{ height: 64 }}>
                                <h2 className="fs-16 fw-bold m-0 ml-3 p-0">
                                    Contact Attempt {numEntries - i}
                                </h2>
                                <Badge
                                    classes={"ml-2 mr-3"}
                                    style={{
                                        padding: 10,
                                        borderRadius: 10,
                                        width: 150,
                                        marginRight: 40,
                                    }}
                                    type={entry_val["contacted"] ? "secondary" : "dark-red"}
                                    text={
                                        entry_val["contacted"]
                                            ? "Successfully Contacted"
                                            : "Contact Unsuccessful"
                                    }
                                />
                            </div>
                            <div className={"p-4"}>
                                <div className={"d-flex justify-content-between"}>
                                    <div className={"d-flex"}>
                                        <img
                                            src={images("./common/white-purple-calendar.svg")}
                                            alt="calendar"
                                            className="PatientProfile__90DaysPlan img-fluid"
                                            width="39px"
                                        />
                                        <div className={"ml-2"}>
                                            <b>{dateStr.split(" ")[0]}</b>
                                            <br />
                                            {dateStr.split(" ")[1]} {dateStr.split(" ")[2]}
                                        </div>
                                    </div>
                                    {entry_val["created_by_name"] && (
                                        <div>
                                            <div>
                                                <b>Created By</b>
                                            </div>
                                            <div>
                                                <span
                                                    style={{
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                    }}
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title={entry_val["created_by_name"]}>
                                                    {entry_val["credential"]
                                                        ? `${entry_val["created_by_name"]}, ${entry_val["credential"]}`
                                                        : entry_val["created_by_name"]}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {entry_val["note"] && (
                                    <>
                                        <hr />
                                        <div>
                                            <b>Notes</b>
                                            <br />
                                            {entry_val["note"]}
                                        </div>
                                    </>
                                )}
                            </div>
                        </CardSecondary>
                    );
                })}
        </div>
    );
};
