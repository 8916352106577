import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { api } from "../../../APIRequests";
import store from "../../../redux/store";
import { hideLoader, showLoader } from "../../../redux/actions/loader";
import { CreditCardAgreementScrollableTextModalHOC } from "../../../component-library/hocs/CreditCardAgreementScrollableTextModalHOC";
import { createAcknowledgement, CREDIT_CARD_AUTHORIZATION_POLICY_NAME } from "../../../utils/userAgreementUtil";
import { withRouter } from "react-router-dom";
import { addToast, toastMessageTypes } from "../../../redux/actions/toaster";

import { Button, ButtonTypes, Card, Link, Text, TextColors, TextTypes } from "../../../component-library";

import "./styles.scss";
import { displayElavonCreditCardModal, parsePaymentResponse } from "../../../utils/PaymentProvider/elavon";
import { NotificationSection } from "../../../component-library/NotificationSection/NotificationSection";
import {
    fetchPatientPaywall,
    putPatientCardInfo,
    updatePatientPaywallInfo
} from "../../../redux/actions/patientCardInfo";
import { bindActionCreators } from "redux";
import moment from "moment";
import { profileFetchSuccess } from "../../../redux/actions/auth";
import Captcha from "../Captcha/Captcha";
import { BrandedHeader } from "../BrandedHeader";

const PAYMENT_ERROR_MESSAGE = "Your card couldn't be added. Please try again.";
const WORKFLOW = "PAYWALL";

const PaymentPaywall = ({
    dispatch,
    history,

    profile,
    isSoftPaywall,
    updatePaymentRequired,
    lastFailedChargeDate,

    paywallCompleted,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [captchaVerificationToken, setCaptchaVerificationToken] = useState(false);

    const { first_name, last_name, username, acknowledgements } = profile;

    const onBackendError = () => {
        dispatch(
            addToast({
                message:
                    "Error adding card on file: Next time you sign in, we’ll ask you to try again.",
                messageType: toastMessageTypes.success,
            }),
        );
        paywallCompleted();
    };
    const onError = () => {
        setErrorMessage(PAYMENT_ERROR_MESSAGE);
    };
    const onCancelled = () => {
        setErrorMessage(PAYMENT_ERROR_MESSAGE);
    };
    const onDeclined = () => {
        setErrorMessage(PAYMENT_ERROR_MESSAGE);
    };
    const onApproval = (response) => {
        dispatch(
            putPatientCardInfo({
                username: username,
                workflow: WORKFLOW,
                ...parsePaymentResponse(response),
                onErrorCallback: onBackendError,
            }),
        );
        paywallCompleted();

        dispatch(
            addToast({
                message: "New card added.",
                messageType: toastMessageTypes.success,
            }),
        );
    };

    const closeModal = () => {
        setShowModal(false);
        setCaptchaVerificationToken(null);
    };

    const handleElavonCreditCardModalException = (e) => {
        // Allow user to temporarily progress if error is outside their control
        if (e?.code >= 500) {
            paywallCompleted();
            dispatch(
                updatePatientPaywallInfo({
                    showPaywall: false,
                }),
            );
        }
        throw e;
    };

    const onCreditCardTncSubmit = async () => {
        store.dispatch(showLoader());
        const data = {
            username: username,
            acknowledgements: {
                ...acknowledgements,
                [CREDIT_CARD_AUTHORIZATION_POLICY_NAME]: await createAcknowledgement(),
            },
        };
        store.dispatch(hideLoader());
        store.dispatch(
            profileFetchSuccess({
                ...profile,
                ...data,
            }),
        );
        await api.patient.update_profile({ data });
        closeModal();
        try {
            await displayElavonCreditCardModal(
                dispatch,
                username,
                first_name,
                last_name,
                () => {
                }, // setErrorMessage
                WORKFLOW,
                onError,
                onCancelled,
                onDeclined,
                onApproval,
                false,
                captchaVerificationToken
            );
        } catch(e) {
            handleElavonCreditCardModalException(e);
        }
        setCaptchaVerificationToken(null);
    };

    useEffect(() => {
        if (isSoftPaywall === undefined) {
            fetchPatientPaywall();
        }
        if (isSoftPaywall) {
            api.patient.update_profile({
                data: {
                    ...profile,
                    soft_paywall: Date.now(),
                },
            });
        }
    }, [isSoftPaywall]);

    const captchaComponent = <Captcha setCaptchaVerificationToken={setCaptchaVerificationToken} />;

    return (
        <>
            <BrandedHeader></BrandedHeader>
            <CreditCardAgreementScrollableTextModalHOC
                show={showModal}
                onCancel={closeModal}
                onSubmit={onCreditCardTncSubmit}
                captchaComponent={captchaComponent}
                captchaCompleted={captchaVerificationToken}
            />
            <Card className="PaymentPaywall">
                <Text className="PaymentPaywall__title" type={TextTypes.heading1} layout="block">
                    {updatePaymentRequired ? "Update payment method" : "Add a payment method"}
                </Text>
                {errorMessage && (
                    <NotificationSection className="PaymentPaywall__error" status="declined">
                        <Text type={TextTypes.label}>{errorMessage}</Text>
                    </NotificationSection>
                )}
                {updatePaymentRequired ? (
                    <>
                        {lastFailedChargeDate && (
                            <Text
                                className="PaymentPaywall__charge-date"
                                type={TextTypes.paragraph}
                                layout="block">
                                We were unable to charge your card on file on{" "}
                                {moment(lastFailedChargeDate).format("MM/DD/YYYY")}.
                            </Text>
                        )}
                        <Text
                            className="PaymentPaywall__failure-text"
                            type={TextTypes.paragraph}
                            layout="block">
                            If your card on file is disabled or expired, please update your payment
                            information. If your card on file is still valid and you've resolved
                            processing issues with your bank, please add the card again.
                        </Text>
                    </>
                ) : (
                    <Text
                        className="PaymentPaywall__add-text"
                        type={TextTypes.paragraph}
                        layout="block">
                        Based on your benefits, you may have additional costs associated with your
                        care. Adding a payment method to your account is required to connect with a
                        provider.
                    </Text>
                )}

                <Button
                    className={`PaymentPaywall__button ${!isSoftPaywall ? "hard-pay" : "soft-pay"}`}
                    buttonType={ButtonTypes.primaryV2}
                    onClick={() => {
                        window.grecaptcha.reset();
                        setShowModal(true);
                    }}>
                    {updatePaymentRequired ? "Update Card" : "Add Card"}
                </Button>

                {isSoftPaywall && (
                    <Text
                        className="PaymentPaywall__softpay-text"
                        type={TextTypes.paragraph}
                        color={TextColors.grey}>
                        Don’t have your card handy?{" "}
                        <Text color={TextColors.greenV2} className={"fw-bold text-decoration-underline cursor"} onClick={() => paywallCompleted()}>
                            Skip this step.
                        </Text>{" "}
                        <br />
                        Next time you sign in, your account will be locked until you add a payment
                        method.
                    </Text>
                )}
                <Text className={"align-self-baseline apti-cool-gray-600 fw-bold"}>
                    Crisis Support
                </Text>
                <Text
                    className="PaymentPaywall__disclaimer align-self-baseline"
                    type={TextTypes.paragraph}
                    color={TextColors.grey}
                    layout="block">
                    If you are in a life-threatening situation, call or text the <Link className={"apti-green-v2 text-decoration-underline"} targert={"_blank"} href="https://988lifeline.org">Suicide and Crisis Lifeline</Link>  at <Link className={"apti-green-v2 text-decoration-underline"} href="tel:988">988</Link>.
                </Text>
                <Text className={"align-self-baseline mt-3 apti-cool-gray-600 fw-bold"}>
                    Billing Support
                </Text>
                <Text
                    className="PaymentPaywall__disclaimer"
                    type={TextTypes.paragraph}
                    color={TextColors.grey}
                    layout="block">
                    For urgent support from the aptihealth
                    team, please reach out to our front office at{" "}
                    <Link className={"apti-green-v2 text-decoration-underline"} href="tel:888-454-3827">(888) 454-3827</Link>. For all other credit
                    card or payment related questions, contact our billing team at{" "}
                    <Link className={"apti-green-v2 text-decoration-underline"} href="mailto:support@aptihealth.com">support@aptihealth.com</Link>
                </Text>
            </Card>
        </>
    );
};

const mapStateToProps = (state) => ({
    profile: state.auth.profile,
    isSoftPaywall: state.patientCardInfo.isSoftPaywall,
    updatePaymentRequired: state.patientCardInfo.updatePaymentRequired,
    lastFailedChargeDate: state.patientCardInfo.lastFailedChargeDate
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    ...bindActionCreators({ fetchPatientPaywall }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PaymentPaywall));
