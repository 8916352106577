// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import "../../styles.scss";
import { VideoViewBase } from "../../index";
import { isAuthorized } from "../../../../../redux/actions/auth";
import { getAutomationDataAttr } from "../../../../../utils/automationUtils";

export const ProviderWelcomeStep = (props) => {
    const { startWorkflow } = props;

    let header = <div className="fw-bold text-white fs-14 fs-lg-18">Welcome!</div>;

    let content = "";
    if (isAuthorized("provider:behavioral")) {
        content = (
            <div className="position-relative GuidedVideoCall__wrapper_2">
                <div className="GuidedVideoCall__welcome_message">
                    <p className="fs-24 fw-bold">
                        To start this session, please carefully review the information below.
                    </p>

                    <ul className="fw-bold fs-16 mt-5">
                        <li>Orient to patient, diagnosis, medications</li>
                        <li>Review goals/last actions/plan between sessions</li>
                        <li>Review BHS Substance use rating - if applicable</li>
                        <li>Optional</li>
                    </ul>
                    <ul className="fs-14 mt-1 split">
                        <li>Review Symptom Trends</li>
                        <li>Review Exploration</li>
                        <li>Changes/Updates</li>
                        <li>Healthcare Use</li>
                        <li>Mental status</li>
                    </ul>
                    <span
                        className="GuidedVideoCall__center-btn fs-14 mt-5 text-center cursor"
                        onClick={() => startWorkflow(0)}
                        {...getAutomationDataAttr("ProviderWelcomeStep_beginSession")}>
                        Begin Session
                    </span>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="position-relative GuidedVideoCall__wrapper_2">
                <div className="GuidedVideoCall__welcome_message">
                    <p className="fs-24 fw-bold">
                        To start this session, please carefully review the information below.
                    </p>

                    <ul className="fw-bold fs-16 mt-5">
                        <li>Last Note</li>
                        <li>Symptom Trend</li>
                        <li>Medication Impact</li>
                        <li>Medical Information</li>
                        <li>Assessment</li>
                        <li>Plan</li>
                    </ul>
                    <span
                        className="GuidedVideoCall__center-btn fs-14 mt-5 text-center cursor"
                        onClick={() => startWorkflow(0)}>
                        Begin Session
                    </span>
                </div>
            </div>
        );
    }

    return <VideoViewBase header={header} content={content} {...props} />;
};
