// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as actionTypes from "../actions/actionTypes";

let initialState = {
    providerId: null,
    scheduleCallList: {
        startDate: null,
        endDate: null,
        showDeleted: null,
        callList: [],
        awayList: [],
    },
};

const logout = (state, action) => {
    return {
        ...initialState,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PROVIDER_UPDATE_SCHEDULE_CALL_LIST:
            return {
                ...state,
                ...action.data,
            };
        case actionTypes.LOG_OUT:
            return logout(state, action);
        default:
            return state;
    }
};

export default reducer;
