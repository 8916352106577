import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

import { createClassNameString } from "../utils";
import { Text } from "../Text";
import { ButtonTypes, TextColors, TextTypes } from "../constants";
import { Button } from "../Button";

export const Popup = ({
    children,
    className,
    title,
    subtitle,
    primaryText,
    primaryOnClick,
    secondaryText,
    secondaryOnClick,
    footer,
    disabled,
    ...props
}) => (
    <div
        className={createClassNameString([
            className,
            "apti-Popup",
            disabled ? "disabled" : "enabled",
        ])}
        {...props}>
        <div className={createClassNameString([className, "apti-Popup__container"])}>
            <div className="apti-Popup__content">
                <Text
                    type={TextTypes.heading1}
                    className={createClassNameString([className, "apti-Popup__title"])}>
                    {title}
                </Text>
                <Text
                    type={TextTypes.subtitle}
                    className={createClassNameString([className, "apti-Popup__subtitle"])}
                    color={TextColors.grey}>
                    {subtitle}
                </Text>
                {children}
                <div className={createClassNameString([className, "apti-Popup__buttons"])}>
                    <Button buttonType={ButtonTypes.primaryOutline} onClick={secondaryOnClick}>
                        {secondaryText}
                    </Button>
                    <Button buttonType={ButtonTypes.primary} onClick={primaryOnClick}>
                        {primaryText}
                    </Button>
                </div>
                <p className={createClassNameString([className, "apti-Popup__footer"])}>{footer}</p>
            </div>
        </div>
    </div>
);

Popup.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    className: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    primaryOnClick: PropTypes.func.isRequired,
    secondaryOnClick: PropTypes.func.isRequired,
    footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    disabled: PropTypes.bool,
};

Popup.defaultProps = {
    children: <></>,
    className: undefined,
    title: "",
    subtitle: "",
    primaryOnClick: () => {},
    primaryText: "Confirm",
    secondaryOnClick: () => {},
    secondaryText: "Cancel",
    footer: "",
    disabled: false,
};
