import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { Button } from "../Button";
import { defaultNPSRatingButtons } from "./constant";
import { NPSSurveyWrapper } from "./NPSSurveyWrapper";

export const NPSRating = ({ className, submitHandler, dismissHandler, buttons, featureArea }) => {
    return (
        <NPSSurveyWrapper className={className} dismissHandler={dismissHandler}>
            <div className={"apti-NPSRating--label"}>
                How likely is it that you would recommend{" "}
                <span className={"font-weight-bold"}>{featureArea} features in aptihealth</span>
                &nbsp;to a colleague?
            </div>
            <div className={"apti-NPSRating--input-group"}>
                {buttons.map((button) => {
                    return (
                        <Button
                            key={button.value}
                            style={{ flexBasis: `${1 / buttons.length}%` }}
                            className={"apti-NPSRating--input"}
                            value={button.value}
                            onClick={submitHandler}
                            buttonType={button.buttonType}>
                            {button.label}
                        </Button>
                    );
                })}
            </div>
        </NPSSurveyWrapper>
    );
};

NPSRating.propTypes = {
    className: PropTypes.string,
    featureArea: PropTypes.string,
    submitHandler: PropTypes.func.isRequired,
    dismissHandler: PropTypes.func.isRequired,
    buttons: PropTypes.array.isRequired,
};

NPSRating.defaultProps = {
    className: undefined,
    submitHandler: () => {},
    dismissHandler: () => {},
    buttons: defaultNPSRatingButtons,
    featureArea: "",
};
