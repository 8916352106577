import React, { useState } from "react";
import {
    Button,
    ButtonTypes,
    Card,
    ErrorCard,
    Image,
    Text,
    TextColors,
    TextTypes,
} from "../../../../component-library";
import { CreditCardAgreementScrollableTextModalHOC } from "../../../../component-library/hocs/CreditCardAgreementScrollableTextModalHOC";
import {
    createAcknowledgement,
    CREDIT_CARD_AUTHORIZATION_POLICY_NAME,
} from "../../../../utils/userAgreementUtil";
import { api } from "../../../../APIRequests";
import store from "../../../../redux/store";
import { hideLoader, showLoader } from "../../../../redux/actions/loader";
import { preventModalPageScroll } from "../../../../component-library/ScrollableTextModal";
import { getAutomationDataAttr } from "../../../../utils/automationUtils";

const CardSection = ({
    profile,
    lastFourDigits,
    expirationDate,
    isExpired,
    errorMessage,
    onAddCardButtonClick,
    onReplaceCardButtonClick,
    captchaComponent,
    captchaCompleted,
    resetCaptcha,
}) => {
    const [showModal, setShowModal] = useState(false);

    const closeModal = () => {
        setShowModal(false);
        resetCaptcha();
        preventModalPageScroll(false);
    };

    const onCreditCardTncSubmit = async () => {
        store.dispatch(showLoader());
        const data = {
            username: profile.username,
            acknowledgements: {
                ...profile.acknowledgements,
                [CREDIT_CARD_AUTHORIZATION_POLICY_NAME]: await createAcknowledgement(),
            },
        };
        store.dispatch(hideLoader());
        await api.patient.update_profile({ data });
        closeModal();
        lastFourDigits ? onReplaceCardButtonClick() : onAddCardButtonClick();
    };

    return (
        <>
            <CreditCardAgreementScrollableTextModalHOC
                show={showModal}
                onCancel={closeModal}
                onSubmit={onCreditCardTncSubmit}
                captchaComponent={captchaComponent}
                captchaCompleted={captchaCompleted}
            />
            <Card className="CardSection">
                <Text
                    className="CardSection__title"
                    type={TextTypes.paragraph}
                    color={TextColors.grey}>
                    This card will be used to process payments such as copayments for sessions and
                    deductibles.
                </Text>
                {errorMessage && (
                    <ErrorCard className="CardSection__error">
                        <Text type={TextTypes.subtitle} color={TextColors.red}>
                            {errorMessage}
                        </Text>
                    </ErrorCard>
                )}
                {lastFourDigits ? (
                    <div className="CardSection__card-info">
                        <div className="CardSection__card-img--container">
                            <Image
                                className="CardSection__card-img--exists"
                                src="credit-card-md.svg"
                                alt="Credit Card"
                            />
                        </div>
                        <div className="CardSection__card-info--container">
                            <Text className="CardSection__number" type={TextTypes.subtitle}>
                                Card ending in {lastFourDigits}
                            </Text>
                            <Text
                                className={`CardSection__expiration${isExpired ? " expired" : ""}`}
                                type={TextTypes.paragraph}
                                color={isExpired ? TextColors.red : TextColors.black}>
                                Expires {expirationDate.format("MM/YYYY")}
                            </Text>
                        </div>
                    </div>
                ) : (
                    <div className="CardSection__card-info--empty">
                        <Image
                            className="CardSection__card-img--empty"
                            src="credit-card-lg.svg"
                            alt="Credit Card"
                        />
                        <Text type={TextTypes.subtitle} color={TextColors.grey}>
                            Please add a new card
                        </Text>
                    </div>
                )}
                <div className="CardSection__button--container">
                    <Button
                        buttonType={ButtonTypes.primaryV2}
                        onClick={() => {
                            window.grecaptcha.reset();
                            preventModalPageScroll(true);
                            setShowModal(true);
                        }}
                        {...getAutomationDataAttr("CreditCardAddUpdateButton")}>
                        <Image
                            className="CardSection__card-img--icon"
                            src="credit-card-icon.svg"
                            alt="Credit Card"
                        />
                        {lastFourDigits ? "Replace Card" : "Add Card"}
                    </Button>
                </div>
            </Card>
        </>
    );
};

export default CardSection;
