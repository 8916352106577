// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import Input from "../Input";
import InputError from "../InputError";
import Label from "../Label";
import { Field, getIn } from "formik";
import FormikInput from "./index";
import { getAutomationDataAttr } from "../../../utils/automationUtils";

const GenericInput = (props) => {
    const {
        formEl,
        errors,
        touched,
        disabled,
        inputClasses,
        errorClasses,
        containerClasses,
        change,
        testId,
    } = props;

    let errorData = getIn(errors, formEl.name);
    let isTouched = getIn(touched, formEl.name);
    const className = `Input ${props.inputBorderDisabled} ${props.grayBorder} ${
        errorData && isTouched ? "is-invalid" : ""
    } ${inputClasses ? inputClasses : ""}`;

    let extraProps = {};

    if (change) {
        extraProps["onChange"] = change;
    }

    if (testId) {
        extraProps = { ...extraProps, ...getAutomationDataAttr(testId) };
    }

    return (
        <div className={`FormGroup ${props.clinicialTextbox} ${containerClasses}`}>
            {!formEl.elementConfig.hideTopLabel && (
                <Label classes={formEl.elementConfig.labelClasses}>
                    {formEl.elementConfig.label}
                </Label>
            )}
            <Field
                name={formEl.name}
                id={formEl.id}
                elementConfig={formEl.elementConfig}
                type={formEl.elementConfig.type}
                disabled={disabled}
                onchange={formEl.onchange}
                autocomplete={formEl?.autocomplete ?? "on"}
                onclick={formEl.onclick}
                checked={formEl.elementConfig.checked}
                elementtype={formEl.elementType}
                options={formEl.elementConfig.options}
                component={Input}
                className={className}
                placeholder={formEl.elementConfig.placeholder}
                {...extraProps}
            />
            {errorData && isTouched ? (
                <InputError classes={errorClasses ? errorClasses : null}>{errorData}</InputError>
            ) : null}
        </div>
    );
};

export default GenericInput;
