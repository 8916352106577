import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { createClassNameString } from "../../component-library";
import { ICON_SIZES, ICON_TYPES } from "../../constants/icons";

export const BellIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 15C24 16.6372 24.67 18.9445 26.5588 20.2762C27.1663 20.7046 27.3815 21.6185 26.8558 22.1441L26.2928 22.7071C26.1053 22.8946 25.851 23 25.5857 23H6.41417C6.14895 23 5.8946 22.8946 5.70706 22.7071L5.14408 22.1441C4.61843 21.6185 4.8336 20.7046 5.44114 20.2762C7.32986 18.9445 7.99996 16.6372 7.99996 15V11C7.99996 7 11 4 16 4C21 4 24 7 24 12V15ZM22 15C22 16.7324 22.585 19.1809 24.343 21H7.65688C9.4149 19.1809 9.99996 16.7324 9.99996 15V11C9.99996 9.52252 10.5416 8.32412 11.4703 7.47988C12.4105 6.6252 13.8993 6 16 6C18.0989 6 19.5434 6.62183 20.4607 7.53921C21.3781 8.45659 22 9.90102 22 12V15Z"
                />
                <path d="M12 24C12 24.9631 12.3199 25.9588 13.0026 26.7269C13.7016 27.5133 14.7286 28 16 28C17.2714 28 18.2984 27.5133 18.9974 26.7269C19.6801 25.9588 20 24.9631 20 24H18C18 24.5369 17.8199 25.0412 17.5026 25.3981C17.2016 25.7367 16.7286 26 16 26C15.2714 26 14.7984 25.7367 14.4974 25.3981C14.1801 25.0412 14 24.5369 14 24H12Z" />
            </svg>
        );
    }

    return null;
};

BellIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

BellIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
