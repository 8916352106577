import moment from "moment";

export const DISPLAY_DAYS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

export const DISPLAY_WEEKS_MAP = [
    { value: 0, label: "Week" },
    { value: 1, label: "2 Weeks" },
    { value: 2, label: "3 Weeks" },
    { value: 3, label: "4 Weeks" },
    { value: 5, label: "6 Weeks" },
    { value: 7, label: "8 Weeks" },
    { value: 11, label: "12 Weeks" },
];

export const AWAY_DURATIONS = [
    { value: "15", label: "15 min" },
    { value: "30", label: "30 min" },
    { value: "60", label: "1 hour" },
];

export const transformTimeSlotArray = (tsArray) => {
    return tsArray.map((ts) => {
        return transformTimeSlot(ts);
    });
};

export const transformTimeSlot = (slot) => {
    let localTime = moment(slot, "HH:mm").format("hh:mm a");
    return { value: slot, label: localTime };
};

const formatTime = (time) => {
    return time < 10 ? "0" + time : "" + time;
};

const getTimeSlotArray = () => {
    const timeSlotArray = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let min = 0; min < 60; min += 15) {
            timeSlotArray.push(formatTime(hour) + ":" + formatTime(min));
        }
    }
    return timeSlotArray;
};

export const timeSlotArray = getTimeSlotArray();

export const buildNumberOfOccurrences = () => {
    const sessionDisplay = [];
    for (let index = 1; index <= 15; index++) {
        const sessionLabel = index > 1 ? "sessions" : "session";
        sessionDisplay.push({ value: index, label: `${index} ${sessionLabel}` });
    }
    return sessionDisplay;
};

export const numberOfOccurrencesArray = buildNumberOfOccurrences();

export const transformValueToDropDown = (list, value) =>
    list.find((element) => element.value === value);
