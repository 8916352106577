import { Form } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { api } from "../../../../../APIRequests";
import {
    Button,
    ButtonSizes,
    ButtonTypes,
    CustomForm,
    Input,
    Text,
} from "../../../../../component-library";
import { CharacterCounter } from "../../../../../component-library/CharacterCounter/CharacterCounter";
import { FormFieldError } from "../../../../../component-library/FormFields/FormFieldError/FormFieldError";
import { FormRadioGroup } from "../../../../../component-library/FormFields/FormRadioGroup/FormRadioGroup";
import { FormSelect } from "../../../../../component-library/FormFields/FormSelect/FormSelect";
import { FormTextInput } from "../../../../../component-library/FormFields/FormTextInput/FormTextInput";
import { AlertIcon } from "../../../../../component-library/Icons/components/AlertIcon";
import { ICON_SIZES, ICON_TYPES } from "../../../../../constants/icons";
import { hideLoader, showLoader } from "../../../../../redux/actions/loader";
import { convertToStandardTimeStampFormat } from "../../../../../utils/momentUtils";
import CloseOnOutsideClick from "../../../../Common/CloseOnOutsideClick/CloseOnOutsideClick";
import DatePicker from "../../../../UI/DatePicker";
import {
    ADD_GOAL_VALIDATION,
    ADD_OBJECTIVE_VALIDATION,
    COMPLETE_OR_REMOVE_OBJECTIVE_VALIDATION,
    EDIT_OBJECTIVE_VALIDATION,
    OTHER_TEXT,
} from "./CareGoalsValidation";
import "./styles.scss";
import { addToast, toastMessageTypes } from "../../../../../redux/actions/toaster";

const LAST_OBJECTIVE_GOAL_STATUS_OPTIONS = [
    {
        label: "Achieve Goal",
        value: "completed",
    },
    {
        label: "Remove Goal",
        value: "cancelled",
    },
    {
        label: "Continue Goal (must add new objective)",
        value: "continue_goal",
    },
];

const OBJECTIVE_STATUS = {
    IN_PROGRESS: "in-progress",
    NOT_ACHIEVED: "not-achieved",
    ACHIEVED: "achieved",
};

const filterActiveObjectives = (objectives, objectiveOptions) => {
    const activeObjectiveTextIds = [];
    objectives.forEach((objective) => {
        activeObjectiveTextIds.push(objective.objective_text_id);
    });

    let result = [];
    objectiveOptions.forEach((objectiveOption) => {
        if (
            objectiveOption.label === OTHER_TEXT ||
            !activeObjectiveTextIds.includes(objectiveOption.value)
        ) {
            result.push(objectiveOption);
        }
    });

    return result;
};

export const AddGoalForm = (props) => {
    const dispatch = useDispatch();

    const patientName = `${props.patientDetails.first_name} ${props.patientDetails.last_name}`;
    const providerLabelFormatted = props.providerLabel.toLowerCase();
    const modalTitle = `Add ${providerLabelFormatted} goal for ${patientName}`;

    const renderForm = (formikProps) => {
        const [showGoalOther, setShowGoalOther] = useState(false);
        const [showObjectiveOther, setShowObjectiveOther] = useState(false);
        const [showDatePicker, setShowDatePicker] = useState(false);
        const [selectedDate, setSelectedDate] = useState(moment().startOf("day").add(90, "days"));
        const datePickerError =
            formikProps.errors?.objective_target_date && formikProps.touched?.objective_target_date;

        // Used to reset objective selection when goal text changes
        const [selectedGoalTextId, setSelectedGoalTextId] = useState(
            formikProps.values.goal_text_id,
        );

        useEffect(() => {
            const goalText =
                props.providerGoalOptionsMap?.[formikProps.values.goal_text_id] || null;
            formikProps.setFieldValue("goal_text", goalText);
            formikProps.setFieldValue("objective_text_id", "");
            setSelectedGoalTextId(formikProps.values.goal_text_id);

            if (goalText === OTHER_TEXT) {
                formikProps.resetForm({
                    values: {
                        goal_text_id: formikProps.values.goal_text_id,
                        goal_text: goalText,
                        goal_other_text: "",
                        objective_target_date: formikProps.values.objective_target_date,
                        objective_text_id: "0",
                        objective_text: OTHER_TEXT,
                        objective_other_text: "",
                    },
                });
                setShowGoalOther(true);
                setShowObjectiveOther(true);
            } else {
                setShowGoalOther(false);
                setShowObjectiveOther(false);
                formikProps.setFieldValue("goal_other_text", "");
            }
            formikProps.setTouched({}, false);
            props.setAllowOverflow(false);
        }, [formikProps.values.goal_text_id]);

        useEffect(() => {
            const objectivesKey =
                props.providerObjectiveOptionsMap?.[formikProps.values.goal_text_id] || {};
            const objectiveText = objectivesKey?.[formikProps.values.objective_text_id] || null;
            formikProps.setFieldValue("objective_text", objectiveText);

            if (objectiveText === OTHER_TEXT) {
                setShowObjectiveOther(true);
                formikProps.setTouched({}, false);
            } else {
                setShowObjectiveOther(false);
                formikProps.setFieldValue("objective_other_text", "");
            }
        }, [formikProps.values.objective_text_id]);

        const updateDate = (newDate, changeType) => {
            if (changeType !== "monthChange") {
                const clonedSelectedDay = moment(newDate, "YYYY-MM-DD").local();
                setSelectedDate(clonedSelectedDay);
                setShowDatePicker(false);
            }
        };

        useEffect(() => {
            const setObjectiveTargetDate = async () => {
                await formikProps.setFieldValue("objective_target_date", selectedDate);
            };
            setObjectiveTargetDate();
        }, [selectedDate]);

        return (
            <Form>
                <div className="CareGoalsCard--modal_form">
                    <FormSelect
                        id="CareGoalsCard_AddGoal_GoalInput"
                        className={"CareGoalsCard--add_goal_select"}
                        title="Goal"
                        placeholder="Search goals"
                        name="goal_text_id"
                        options={props.providerGoalOptions}
                        formikProps={formikProps}
                        triggerErrorOnChange
                    />
                    {/* Other goal NOT selected */}
                    {formikProps.values.goal_text_id && !showGoalOther && (
                        <FormRadioGroup
                            key={selectedGoalTextId}
                            id="CareGoalsCard_AddGoal_ObjectiveInput"
                            className="AdvancedCareActivityForm-form_field"
                            title="Objective"
                            placeholder="Search objectives"
                            name="objective_text_id"
                            options={
                                props.providerObjectiveOptions[formikProps.values.goal_text_id]
                            }
                            formikProps={formikProps}
                            triggerErrorOnChange
                        />
                    )}
                    {/* Other goal SELECTED */}
                    {formikProps.values.goal_text_id && showGoalOther && (
                        <div className="CareGoalsCard--other_input_container">
                            <FormTextInput
                                id="CareGoalsCard_AddGoal_GoalOtherInput"
                                className="AdvancedCareActivityForm-form_field"
                                title="Specify Goal"
                                name="goal_other_text"
                                formikProps={formikProps}
                                maxLength={250}
                                triggerErrorOnChange
                            />
                            <CharacterCounter
                                className={"CareGoalsCard--input_character_counter"}
                                length={formikProps.values?.goal_other_text?.length}
                                characterLimit={250}
                            />
                        </div>
                    )}
                    {/* Other goal SELECTED OR other objective SELECTED */}
                    {formikProps.values.goal_text_id && showObjectiveOther && (
                        <div className="CareGoalsCard--other_input_container">
                            <FormTextInput
                                id="CareGoalsCard_AddGoal_ObjectiveOtherInput"
                                className="CareGoalsCard--other_input"
                                title="Specify Objective"
                                name="objective_other_text"
                                formikProps={formikProps}
                                maxLength={250}
                                triggerErrorOnChange
                            />
                            <CharacterCounter
                                className={"CareGoalsCard--other_input_character_counter"}
                                length={formikProps.values?.objective_other_text?.length}
                                characterLimit={250}
                            />
                        </div>
                    )}
                    {/* General fields to show once goal picked */}
                    {formikProps.values.goal_text_id && (
                        <div className="FormField-container">
                            <div data-public className="FormField-header_container">
                                <h3 className="FormField-title">Objective Target Date</h3>
                                <h3 className="FormField-subTitle">
                                    Expected completion date (default: 90 days from today)
                                </h3>
                            </div>
                            <div>
                                {showDatePicker && (
                                    <CloseOnOutsideClick
                                        setShowComponent={setShowDatePicker}
                                        className={"CareGoalsCard--relative"}>
                                        <div className="CareGoalsCard--date-picker-top">
                                            <DatePicker
                                                externalCtx={selectedDate}
                                                onDaySelect={updateDate}
                                            />
                                        </div>
                                    </CloseOnOutsideClick>
                                )}
                                <Input
                                    className="CareGoalsCard--date_input_wrapper"
                                    onClick={() => setShowDatePicker(true)}
                                    inputClassName="CareGoalsCard--date_input"
                                    placeholder="Date"
                                    value={selectedDate.format("MMMM DD, YYYY")}
                                    error={datePickerError}
                                />
                            </div>
                            {datePickerError && (
                                <FormFieldError>
                                    {formikProps.errors?.objective_target_date}
                                </FormFieldError>
                            )}
                        </div>
                    )}
                    <div className="AdvancedCareActivityForm-button_wrapper">
                        <Button
                            id="CareGoalsCard_AddGoal_SubmitButton"
                            type="submit"
                            buttonSize={ButtonSizes.medium}
                            buttonType={ButtonTypes.primaryV2}>
                            Add
                        </Button>
                        <Button
                            id="CareGoalsCard_AddGoal_CancelButton"
                            type="button"
                            onClick={() => {
                                formikProps.resetForm();
                                props.onClose();
                            }}
                            buttonType={ButtonTypes.primaryOutlineV2}
                            buttonSize={ButtonSizes.medium}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <div className="CareGoalsCard--modal_internal">
            <div className="CareGoalsCard--modal_header">
                <h1 className="CareGoalsCard--modal_title">{modalTitle}</h1>
            </div>
            <CustomForm
                initialValues={{
                    goal_id: "",
                    goal_text_id: "",
                    goal_other_text: "",
                    objective_text_id: "",
                    objective_other_text: "",
                    objective_target_date: null,
                }}
                validationSchema={ADD_GOAL_VALIDATION}
                validateOnChange={true}
                validateOnBlur={true}
                render={renderForm}
                onSubmit={async (formData) => {
                    const objective_start_time = moment();
                    const data = {
                        goal_text_id: formData.goal_text_id,
                        goal_other_text: formData.goal_other_text,
                        objective_text_id: formData.objective_text_id,
                        objective_other_text: formData.objective_other_text,
                        objective_start_date:
                            convertToStandardTimeStampFormat(objective_start_time),
                        objective_target_date: convertToStandardTimeStampFormat(
                            formData.objective_target_date,
                        ),
                    };

                    dispatch(showLoader(true));
                    await api.carePlan.add_objective({
                        data,
                        urlParams: { patientId: props.patientDetails.username },
                        params: { showLoader: false },
                    });
                    await props.onModalSubmission();
                    dispatch(hideLoader());
                }}
            />
        </div>
    );
};

export const AddObjectiveForm = (props) => {
    const dispatch = useDispatch();
    const { goal, objective, objectiveCloseMode } = props;

    const goalText = goal?.goal_other_text || goal.goal_text;
    const isCloseAndAddObjective = objectiveCloseMode != null;

    const filteredObjectiveOptions = filterActiveObjectives(
        goal.objectives,
        props.providerObjectiveOptions[goal.goal_text_id],
    );

    const renderForm = (formikProps) => {
        const isGoalOther = goal.goal_text === OTHER_TEXT;
        const [showObjectiveOther, setShowObjectiveOther] = useState(isGoalOther);
        const [showDatePicker, setShowDatePicker] = useState(false);
        const [selectedDate, setSelectedDate] = useState(moment().startOf("day").add(90, "days"));
        const datePickerError =
            formikProps.errors?.objective_target_date && formikProps.touched?.objective_target_date;

        useEffect(() => {
            const objectivesKey = props.providerObjectiveOptionsMap?.[goal.goal_text_id] || {};
            const objectiveText = objectivesKey?.[formikProps.values.objective_text_id] || null;
            formikProps.setFieldValue("objective_text", objectiveText);

            if (objectiveText === OTHER_TEXT || goal.goal_text === OTHER_TEXT) {
                setShowObjectiveOther(true);
            } else {
                setShowObjectiveOther(false);
                formikProps.setFieldValue("objective_other_text", "");
            }
        }, [formikProps.values.objective_text_id]);

        const updateDate = (newDate, changeType) => {
            if (changeType !== "monthChange") {
                const clonedSelectedDay = moment(newDate, "YYYY-MM-DD").local();
                setSelectedDate(clonedSelectedDay);
                setShowDatePicker(false);
            }
        };

        useEffect(() => {
            const setObjectiveTargetDate = async () => {
                await formikProps.setFieldValue("objective_target_date", selectedDate);
            };
            setObjectiveTargetDate();
        }, [selectedDate]);

        return (
            <Form>
                <div className="CareGoalsCard--modal_form">
                    <div data-public className="FormField-header_container">
                        <h3 className="FormField-title">Goal</h3>
                        <p className="text-break">{goalText}</p>
                    </div>

                    {/* Regular goal */}
                    {!isGoalOther && (
                        <FormRadioGroup
                            id="CareGoalsCard_AddObjective_ObjectiveInput"
                            className="AdvancedCareActivityForm-form_field"
                            title="Objective"
                            placeholder="Search objectives"
                            name="objective_text_id"
                            options={filteredObjectiveOptions}
                            formikProps={formikProps}
                            triggerErrorOnChange
                        />
                    )}
                    {/* Other goal OR other objective SELECTED */}
                    {showObjectiveOther && (
                        <div className="CareGoalsCard--other_input_container">
                            <FormTextInput
                                id="CareGoalsCard_AddObjective_ObjectiveOtherInput"
                                className="AdvancedCareActivityForm-form_field"
                                title="Specify Objective"
                                name="objective_other_text"
                                formikProps={formikProps}
                                maxLength={250}
                                triggerErrorOnChange
                            />
                            <CharacterCounter
                                className={"CareGoalsCard--other_input_character_counter"}
                                length={formikProps.values?.objective_other_text?.length}
                                characterLimit={250}
                            />
                        </div>
                    )}

                    <div className="FormField-container">
                        <div data-public className="FormField-header_container">
                            <h3 className="FormField-title">Objective Target Date</h3>
                            <h3 className="FormField-subTitle">
                                Expected completion date (default: 90 days from today)
                            </h3>
                        </div>
                        <div>
                            {showDatePicker && (
                                <CloseOnOutsideClick
                                    setShowComponent={setShowDatePicker}
                                    className={"CareGoalsCard--relative"}>
                                    <div className="CareGoalsCard--date-picker-top">
                                        <DatePicker
                                            externalCtx={selectedDate}
                                            onDaySelect={updateDate}
                                        />
                                    </div>
                                </CloseOnOutsideClick>
                            )}
                            <Input
                                className="CareGoalsCard--date_input_wrapper"
                                onClick={() => setShowDatePicker(true)}
                                inputClassName="CareGoalsCard--date_input"
                                placeholder="Date"
                                value={selectedDate.format("MMMM DD, YYYY")}
                                error={datePickerError}
                            />
                        </div>
                        {datePickerError && (
                            <FormFieldError>
                                {formikProps.errors?.objective_target_date}
                            </FormFieldError>
                        )}
                    </div>

                    <div className="AdvancedCareActivityForm-button_wrapper">
                        <Button
                            id="CareGoalsCard_AddObjective_SubmitButton"
                            type="submit"
                            buttonSize={ButtonSizes.medium}
                            buttonType={ButtonTypes.primaryV2}>
                            Add
                        </Button>
                        {isCloseAndAddObjective && (
                            <Button
                                id="CareGoalsCard_AddObjective_CancelButton"
                                type="button"
                                onClick={() => {
                                    formikProps.resetForm();
                                    if (objectiveCloseMode === OBJECTIVE_STATUS.ACHIEVED) {
                                        props.onCompleteObjectiveClick(goal, objective);
                                    } else if (
                                        objectiveCloseMode === OBJECTIVE_STATUS.NOT_ACHIEVED
                                    ) {
                                        props.onRemoveObjectiveClick(goal, objective);
                                    } else {
                                        props.onClose();
                                    }
                                }}
                                buttonType={ButtonTypes.primaryOutlineV2}
                                buttonSize={ButtonSizes.medium}>
                                Back
                            </Button>
                        )}
                        {!isCloseAndAddObjective && (
                            <Button
                                id="CareGoalsCard_AddObjective_CancelButton"
                                type="button"
                                onClick={() => {
                                    formikProps.resetForm();
                                    props.onClose();
                                }}
                                buttonType={ButtonTypes.primaryOutlineV2}
                                buttonSize={ButtonSizes.medium}>
                                Cancel
                            </Button>
                        )}
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <div className="CareGoalsCard--modal_internal">
            <div className="CareGoalsCard--modal_header">
                <h1 className="CareGoalsCard--modal_title">Add objective</h1>
            </div>
            <CustomForm
                initialValues={{
                    goal_id: goal.goal_id,
                    goal_text_id: goal.goal_text_id,
                    goal_other_text: goal.goal_other_text,
                    goal_text: goal.goal_text,
                    objective_text_id: goal.goal_text === OTHER_TEXT ? "0" : "",
                    objective_other_text: "",
                    objective_target_date: null,
                }}
                validationSchema={ADD_OBJECTIVE_VALIDATION}
                validateOnChange={true}
                validateOnBlur={true}
                render={renderForm}
                onSubmit={async (formData) => {
                    const objective_start_time = moment();

                    if (isCloseAndAddObjective) {
                        const data = {
                            goal_id: goal.goal_id,
                            updated_objective: {
                                objective_id: objective.objective_id,
                                objective_status: props.objectiveCloseMode,
                            },
                            new_objective: {
                                objective_text_id: formData.objective_text_id,
                                objective_other_text: formData.objective_other_text,
                                objective_target_date: convertToStandardTimeStampFormat(
                                    formData.objective_target_date,
                                ),
                            },
                        };

                        dispatch(showLoader(true));
                        const response = await api.carePlan.update_and_add_objective({
                            data,
                            urlParams: { patientId: props.patientDetails.username },
                            params: { showLoader: false },
                        });
                        await props.onModalSubmission();
                        dispatch(hideLoader());
                        dispatch(
                            addToast({
                                message: response?.message || "Updated",
                                messageType: toastMessageTypes.success_v2,
                                dismissTimeout: 1000,
                            }),
                        );
                        return;
                    }

                    // NOTE: right now only free text objectives can belong to free text
                    // goals
                    const objectiveTextId =
                        goal.goal_text == OTHER_TEXT ? "0" : formData.objective_text_id;

                    const data = {
                        goal_id: goal.goal_id,
                        goal_text_id: goal.goal_text_id,
                        goal_other_text: goal.goal_other_text,
                        objective_text_id: objectiveTextId,
                        objective_other_text: formData.objective_other_text,
                        objective_start_date:
                            convertToStandardTimeStampFormat(objective_start_time),
                        objective_target_date: convertToStandardTimeStampFormat(
                            formData.objective_target_date,
                        ),
                    };

                    dispatch(showLoader(true));
                    await api.carePlan.add_objective({
                        data,
                        urlParams: { patientId: props.patientDetails.username },
                        params: { showLoader: false },
                    });
                    await props.onModalSubmission();
                    dispatch(hideLoader());
                }}
            />
        </div>
    );
};

export const EditObjectiveForm = (props) => {
    const dispatch = useDispatch();
    const { goal, objective } = props;

    const goalText = goal?.goal_other_text || goal.goal_text;
    const objectiveText = objective?.objective_other_text || objective.objective_text;

    const renderForm = (formikProps) => {
        const [showDatePicker, setShowDatePicker] = useState(false);
        const [selectedDate, setSelectedDate] = useState(
            moment.utc(objective.objective_target_timestamp),
        );
        const datePickerError =
            formikProps.errors?.objective_target_date && formikProps.touched?.objective_target_date;

        const updateDate = (newDate, changeType) => {
            if (changeType !== "monthChange") {
                const clonedSelectedDay = moment(newDate, "YYYY-MM-DD").local();
                setSelectedDate(clonedSelectedDay);
                setShowDatePicker(false);
            }
        };

        useEffect(() => {
            const setObjectiveTargetDate = async () => {
                await formikProps.setFieldValue("objective_target_date", selectedDate);
            };
            setObjectiveTargetDate();
        }, [selectedDate]);

        return (
            <Form>
                <div className="CareGoalsCard--modal_form">
                    <div data-public className="FormField-header_container">
                        <h3 className="FormField-title">Goal</h3>
                        <p className="text-break">{goalText}</p>
                    </div>

                    <div data-public className="FormField-header_container">
                        <h3 className="FormField-title">Objective</h3>
                        <p className="text-break">{objectiveText}</p>
                    </div>

                    <div className="FormField-container">
                        <div data-public className="FormField-header_container">
                            <h3 className="FormField-title">Objective Target Date</h3>
                            <h3 className="FormField-subTitle">
                                Expected completion date (default: 90 days from today)
                            </h3>
                        </div>
                        <div>
                            {showDatePicker && (
                                <CloseOnOutsideClick
                                    setShowComponent={setShowDatePicker}
                                    className={"CareGoalsCard--fixed"}>
                                    <div className="CareGoalsCard--date-picker-top">
                                        <DatePicker
                                            externalCtx={selectedDate}
                                            onDaySelect={updateDate}
                                        />
                                    </div>
                                </CloseOnOutsideClick>
                            )}
                            <Input
                                className="CareGoalsCard--date_input_wrapper"
                                onClick={() => setShowDatePicker(true)}
                                inputClassName="CareGoalsCard--date_input"
                                placeholder="Date"
                                value={selectedDate.format("MMMM DD, YYYY")}
                                error={datePickerError}
                            />
                        </div>

                        {datePickerError && (
                            <FormFieldError>
                                {formikProps.errors?.objective_target_date}
                            </FormFieldError>
                        )}
                    </div>

                    <div className="AdvancedCareActivityForm-button_wrapper">
                        <Button
                            id="CareGoalsCard_EditObjective_SubmitButton"
                            type="submit"
                            buttonSize={ButtonSizes.medium}
                            buttonType={ButtonTypes.primaryV2}>
                            Save
                        </Button>
                        <Button
                            id="CareGoalsCard_EditObjective_CancelButton"
                            type="button"
                            onClick={() => {
                                formikProps.resetForm();
                                props.onClose();
                            }}
                            buttonType={ButtonTypes.primaryOutlineV2}
                            buttonSize={ButtonSizes.medium}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <div className="CareGoalsCard--modal_internal">
            <div className="CareGoalsCard--modal_header">
                <h1 className="CareGoalsCard--modal_title">Edit objective target date</h1>
            </div>
            <CustomForm
                initialValues={{
                    objective_start_date: objective.objective_start_timestamp,
                }}
                validationSchema={EDIT_OBJECTIVE_VALIDATION}
                validateOnChange={true}
                validateOnBlur={true}
                render={renderForm}
                onSubmit={async (formData) => {
                    const data = {
                        goal_id: goal.goal_id,
                        updated_objective: {
                            objective_id: objective.objective_id,
                            objective_target_date_updated: convertToStandardTimeStampFormat(
                                formData.objective_target_date,
                            ),
                        },
                    };

                    dispatch(showLoader(true));
                    const response = await api.carePlan.update_objective_meta_data({
                        data,
                        urlParams: { patientId: props.patientDetails.username },
                        params: { showLoader: false },
                    });
                    await props.onModalSubmission();
                    dispatch(hideLoader());
                    dispatch(
                        addToast({
                            message: response?.message || "Updated",
                            messageType: toastMessageTypes.success_v2,
                            dismissTimeout: 1000,
                        }),
                    );
                }}
            />
        </div>
    );
};

export const CompleteObjectiveForm = (props) => {
    const dispatch = useDispatch();

    const { goal, objective } = props;

    const goalText = goal?.goal_other_text || goal.goal_text;
    const objectiveText = objective?.objective_other_text || objective.objective_text;

    const isLastObjective = goal.objectives.length === 1;

    const renderForm = (formikProps) => {
        return (
            <Form>
                <div className="CareGoalsCard--modal_form">
                    <div data-public className="FormField-header_container">
                        <h3 className="FormField-title">Objective</h3>
                        <p className="text-break">{objectiveText}</p>
                    </div>

                    {isLastObjective && (
                        <div className="CareGoalsCard--last_objective_warning_container">
                            <div className="CareGoalsCard--last_objective_warning_banner">
                                <AlertIcon
                                    className={"CareGoalsCard--last_objective_warning_icon"}
                                    iconType={ICON_TYPES.OUTLINE}
                                    iconSize={ICON_SIZES.SMALL}
                                />
                                <Text className={"CareGoalsCard--last_objective_warning_text"}>
                                    You’re updating the last active objective for its goal
                                </Text>
                            </div>

                            <FormRadioGroup
                                id="CareGoalsCard_CompleteObjective_GoalStatusInput"
                                className="AdvancedCareActivityForm-form_field text-break"
                                title={`Select a new status for the goal “${goalText}”: `}
                                name="new_goal_status"
                                options={LAST_OBJECTIVE_GOAL_STATUS_OPTIONS}
                                formikProps={formikProps}
                                triggerErrorOnChange
                            />
                        </div>
                    )}

                    <div className="AdvancedCareActivityForm-button_wrapper">
                        <Button
                            id="CareGoalsCard_CompleteObjective_SubmitButton"
                            type="submit"
                            buttonSize={ButtonSizes.medium}
                            buttonType={ButtonTypes.primaryV2}>
                            Mark Achieved
                        </Button>
                        <Button
                            id="CareGoalsCard_CompleteObjective_CancelButton"
                            type="button"
                            onClick={() => {
                                formikProps.resetForm();
                                props.onClose();
                            }}
                            buttonType={ButtonTypes.primaryOutlineV2}
                            buttonSize={ButtonSizes.medium}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <div className="CareGoalsCard--modal_internal">
            <div className="CareGoalsCard--modal_header">
                <h1 className="CareGoalsCard--modal_title">Achieve objective?</h1>
                <h2 className="CareGoalsCard--modal_subtitle">
                    This objective will be marked as achieved and removed from view in the care
                    plan.
                </h2>
            </div>
            <CustomForm
                initialValues={{ isLastObjective: isLastObjective, new_goal_status: "" }}
                validationSchema={COMPLETE_OR_REMOVE_OBJECTIVE_VALIDATION}
                validateOnChange={true}
                validateOnBlur={true}
                render={renderForm}
                onSubmit={async (formData) => {
                    if (isLastObjective) {
                        if (formData.new_goal_status === "continue_goal") {
                            props.onCloseAndAddObjective(
                                goal,
                                objective,
                                OBJECTIVE_STATUS.ACHIEVED,
                            );
                        } else {
                            const data = {
                                goal_id: goal.goal_id,
                                goal_updated_status: formData.new_goal_status,
                                updated_objective: {
                                    objective_id: objective.objective_id,
                                    objective_status: OBJECTIVE_STATUS.ACHIEVED,
                                },
                            };

                            dispatch(showLoader(true));
                            const response = await api.carePlan.update_goal_and_objective({
                                data,
                                urlParams: { patientId: props.patientDetails.username },
                                params: { showLoader: false },
                            });
                            await props.onModalSubmission();
                            dispatch(hideLoader());
                            dispatch(
                                addToast({
                                    message: response?.message || "Updated",
                                    messageType: toastMessageTypes.success_v2,
                                    dismissTimeout: 1000,
                                }),
                            );
                        }

                        return;
                    }

                    const data = {
                        goal_id: goal.goal_id,
                        updated_objective: {
                            objective_id: objective.objective_id,
                            objective_status: OBJECTIVE_STATUS.ACHIEVED,
                        },
                    };

                    dispatch(showLoader(true));
                    const response = await api.carePlan.update_objective({
                        data,
                        urlParams: { patientId: props.patientDetails.username },
                        params: { showLoader: false },
                    });
                    await props.onModalSubmission();
                    dispatch(hideLoader());
                    dispatch(
                        addToast({
                            message: response?.message || "Updated",
                            messageType: toastMessageTypes.success_v2,
                            dismissTimeout: 1000,
                        }),
                    );
                }}
            />
        </div>
    );
};

export const RemoveObjectiveForm = (props) => {
    const dispatch = useDispatch();

    const { goal, objective } = props;

    const goalText = goal?.goal_other_text || goal.goal_text;
    const objectiveText = objective?.objective_other_text || objective.objective_text;

    const isLastObjective = goal.objectives.length === 1;

    const renderForm = (formikProps) => {
        return (
            <Form>
                <div className="CareGoalsCard--modal_form">
                    <div data-public className="FormField-header_container">
                        <h3 className="FormField-title">Objective</h3>
                        <p className="text-break">{objectiveText}</p>
                    </div>

                    {isLastObjective && (
                        <div className="CareGoalsCard--last_objective_warning_container">
                            <div className="CareGoalsCard--last_objective_warning_banner">
                                <AlertIcon
                                    className={"CareGoalsCard--last_objective_warning_icon"}
                                    iconType={ICON_TYPES.OUTLINE}
                                    iconSize={ICON_SIZES.SMALL}
                                />
                                <Text className={"CareGoalsCare--last_objective_warning_text"}>
                                    You’re updating the last active objective for its goal
                                </Text>
                            </div>

                            <FormRadioGroup
                                id="CareGoalsCard_CompleteObjective_GoalStatusInput"
                                className="AdvancedCareActivityForm-form_field text-break"
                                title={`Select a new status for the goal “${goalText}”: `}
                                name="new_goal_status"
                                options={LAST_OBJECTIVE_GOAL_STATUS_OPTIONS}
                                formikProps={formikProps}
                                triggerErrorOnChange
                            />
                        </div>
                    )}

                    <div className="AdvancedCareActivityForm-button_wrapper">
                        <Button
                            id="CareGoalsCard_CompleteObjective_SubmitButton"
                            type="submit"
                            buttonSize={ButtonSizes.medium}
                            buttonType={ButtonTypes.primaryV2}>
                            Remove Objective
                        </Button>
                        <Button
                            id="CareGoalsCard_CompleteObjective_CancelButton"
                            type="button"
                            onClick={() => {
                                formikProps.resetForm();
                                props.onClose();
                            }}
                            buttonType={ButtonTypes.primaryOutlineV2}
                            buttonSize={ButtonSizes.medium}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <div className="CareGoalsCard--modal_internal">
            <div className="CareGoalsCard--modal_header">
                <h1 className="CareGoalsCard--modal_title">Remove objective?</h1>
                <h1 className="CareGoalsCard--modal_subtitle">
                    This objective will be removed from the care plan.
                </h1>
            </div>
            <CustomForm
                initialValues={{ isLastObjective: isLastObjective, new_goal_status: "" }}
                validationSchema={COMPLETE_OR_REMOVE_OBJECTIVE_VALIDATION}
                validateOnChange={true}
                validateOnBlur={true}
                render={renderForm}
                onSubmit={async (formData) => {
                    if (isLastObjective) {
                        if (formData.new_goal_status === "continue_goal") {
                            props.onCloseAndAddObjective(
                                goal,
                                objective,
                                OBJECTIVE_STATUS.NOT_ACHIEVED,
                            );
                        } else {
                            const data = {
                                goal_id: goal.goal_id,
                                goal_updated_status: formData.new_goal_status,
                                updated_objective: {
                                    objective_id: objective.objective_id,
                                    objective_status: OBJECTIVE_STATUS.NOT_ACHIEVED,
                                },
                            };
                            dispatch(showLoader(true));
                            const response = await api.carePlan.update_goal_and_objective({
                                data,
                                urlParams: { patientId: props.patientDetails.username },
                                params: { showLoader: false },
                            });
                            await props.onModalSubmission();
                            dispatch(hideLoader());
                            dispatch(
                                addToast({
                                    message: response?.message || "Removed",
                                    messageType: toastMessageTypes.success_v2,
                                    dismissTimeout: 1000,
                                }),
                            );
                        }

                        return;
                    }

                    const data = {
                        goal_id: goal.goal_id,
                        updated_objective: {
                            objective_id: objective.objective_id,
                            objective_status: OBJECTIVE_STATUS.NOT_ACHIEVED,
                        },
                    };

                    dispatch(showLoader(true));
                    const response = await api.carePlan.update_objective({
                        data,
                        urlParams: { patientId: props.patientDetails.username },
                        params: { showLoader: false },
                    });
                    await props.onModalSubmission();
                    dispatch(hideLoader());
                    dispatch(
                        addToast({
                            message: response?.message || "Removed",
                            messageType: toastMessageTypes.success_v2,
                            dismissTimeout: 1000,
                        }),
                    );
                }}
            />
        </div>
    );
};
