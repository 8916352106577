// Copyright aptihealth, inc. 2021 All Rights Reserved

import React, { useEffect, useState } from "react";
import { AptihealthIcon } from "../../../../component-library/Icons/components/AptihealthIcon";
import { BhsIcon } from "../../../../component-library/Icons/components/BhsIcon";
import { CaseManagerIcon } from "../../../../component-library/Icons/components/CaseManagerIcon";
import { IntakeIcon } from "../../../../component-library/Icons/components/IntakeIcon";
import { PcpIcon } from "../../../../component-library/Icons/components/PcpIcon";
import { PeerIcon } from "../../../../component-library/Icons/components/PeerIcon";
import { PrescriberIcon } from "../../../../component-library/Icons/components/PrescriberIcon";
import { BuildingIcon } from "../../../../component-library/Icons/components/BuildingIcon";
import { ICON_SIZES, ICON_TYPES } from "../../../../constants/icons";
import images from "../../../../utils/images";
import "../styles.scss";


const CareTeamWidget = (props) => {
    const { profile, sendBirdContacts } = props;
    const [careTeamMembers, setCareTeamMembers] = useState(null);

    useEffect(() => {
        const careTeam =
            profile && profile.care_team_members
                ? [...profile.care_team_members]
                : [{ type: "No Members" }];
        careTeam.push({ type: "Member Support" });
        setCareTeamMembers(careTeam);
    }, [profile]);

    const ACTION_CONFIG = {
        Message: {
            name: "Message",
            icon: "./icons/common/message/message-ter-none-2.svg",
            clickHandler: (memberId) => openMessage(memberId),
        },
        Email: {
            name: "Email",
            icon: "./icons/common/email/email-ter-white.svg",
            clickHandler: () => openEmail(),
        },
    };

    const CARE_TEAM_MEMBER_ROLE_CONFIG = {
        PCP: {
            title: "Primary Care Physician",
            description: (
                <>
                    Your <b>primary care physician</b> will collaborate closely with your therapist
                    and prescriber. Your provider has access to your assessments and care notes so
                    the physical care they provide supports your behavioral healthcare.
                </>
            ),
            icon: <PcpIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconAptiNavy"}/>,
        },
        "Care Manager": {
            title: "Care Manager",
            description: (
                <>
                    Your primary care physician’s in-office <b>care manager</b> will support your
                    physical and behavioral healthcare needs.
                </>
            ),
            icon: <BuildingIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconAptiNavy"}/>,
        },
        Prescriber: {
            title: "Prescriber",
            description: (
                <>
                    Your licensed <b>prescriber</b> will meet with you regularly to help manage your
                    medication and adjust your prescriptions to ensure you’re meeting your care
                    goals.
                </>
            ),
            icon: <PrescriberIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconAptiNavy"}/>,
            action: ACTION_CONFIG["Message"],
        },
        "Behavioral Specialist": {
            title: "Therapist",
            description: (
                <>
                    Your licensed <b>therapist</b> will meet with you regularly for video therapy to
                    help you set and track your goals. They are also available for support between
                    sessions using our in-app messaging.
                </>
            ),
            icon: <BhsIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconAptiNavy"}/>,
            action: ACTION_CONFIG["Message"],
        },
        "Intake Behavioral Specialist": {
            title: "Intake Coordinator",
            description: (
                <>
                    Your <b>intake coordinator</b> will meet with you for your first session to
                    review your goals for your first 90 days of therapy. They’ll match you with a
                    licensed therapist and a licensed prescriber, if needed.
                </>
            ),
            icon: <IntakeIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconAptiNavy"}/>,
            action: ACTION_CONFIG["Message"],
        },
        "Member Support": {
            title: "Member Support",
            description: (
                <>
                    aptihealth’s <b>member support</b> team is available by email to assist with
                    questions you have throughout your care journey. Dedicated to providing the
                    support you need, they’ll respond to your emails promptly.
                </>
            ),
            icon: <AptihealthIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconAptiNavy"}/>,
            action: ACTION_CONFIG["Email"],
        },
        "Case Manager": {
            title: "Case Manager",
            description: (
                <>
                    Your <b>case manager</b> will work with you to develop and coordinate your care plan,
                    ensuring you have access to resources and support. They’ll check in to assess
                    your progress and adjust your plan as needed.
                </>
            ),
            icon: <CaseManagerIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconAptiNavy"}/>,
            action: ACTION_CONFIG["Message"],
        },
        "Peer": {
            title: "Peer",
            description: (
                <>
                    Your <b>peer specialist</b> offers understanding and practical advice based on their
                    own experience. They’ll contact you regularly to provide encouragement, check
                    in on progress, and help problem-solve.
                </>
            ),
            icon:<PeerIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconAptiNavy"}/>,
            action: ACTION_CONFIG["Message"],
        },
        "No Members": {
            title: "You Are Being Matched to Your Care Team",
            description: (
                <>
                    Members of your personalized care team, including a licensed therapist and a
                    licensed prescriber, if needed, will appear here.
                </>
            ),
            icon: <AptihealthIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconAptiNavy"}/>,
        },
    };

    const openMessage = (memberId) => {
        props.history.push(`/app/messages?memberId=${memberId}`);
    };

    const openEmail = () => {
        window.location.href = "mailto:support@aptihealth.com?subject=Web Support Request";
    };

    const allowSendMessage = (memberConfig, sendBirdContacts, member) => {
        return (
            memberConfig["action"] &&
            (memberConfig["title"] !== "Prescriber" ||
                (sendBirdContacts[member["provider_id"]] &&
                    sendBirdContacts[member["provider_id"]]["shouldShow"]))
        );
    };

    const buildCareTeamMemberDescription = (member) => {
        const memberConfig =
            member && member["type"] && member["type"] !== "Intake Behavioral Specialist"
                ? CARE_TEAM_MEMBER_ROLE_CONFIG[member["type"]]
                : null;
        if (memberConfig) {
            return (
                <div
                    className={"col-lg-4 col-md-6 col-12"}
                    key={`care-team-member-${member["type"]}-${member["name"]}`}>
                    <div className={"d-flex p-2 mb-2"}>
                        <div className={"px-2"}>
                            {memberConfig["icon"]}
                        </div>
                        <div className={"px-2"}>
                            <div className={"txt-ter fw-bold"}>
                                {member["name"]
                                    ? `${memberConfig["title"]}, ${member["name"]}`
                                    : memberConfig["title"]}
                            </div>
                            <div className={"fs-14 txt-gry"}>{memberConfig["description"]}</div>
                            {allowSendMessage(memberConfig, sendBirdContacts, member) && (
                                <div
                                    className={"fw-bold txt-ter fs-14 d-flex cursor"}
                                    role={"button"}
                                    onClick={() =>
                                        memberConfig["action"]["clickHandler"](
                                            member["provider_id"],
                                        )
                                    }>
                                    <img src={images(memberConfig["action"]["icon"])} alt={""} />
                                    <div className={"mt-1 ml-1"}>
                                        {memberConfig["action"]["name"]}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    };

    return (
        <div className={"mb-3 pb-md-3 pb-0"}>
            <div className={"fs-22 txt-gry fw-bold pb-2"}>Care Team</div>
            <div className={"py-3 dashboard-widget-card dashboard-widget-card-xl"}>
                <div className={"row"}>
                    {profile &&
                        careTeamMembers &&
                        sendBirdContacts &&
                        careTeamMembers.map(buildCareTeamMemberDescription)}
                </div>
            </div>
        </div>
    );
};

export default CareTeamWidget;
