// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Fragment } from "react";
import "./styles.scss";
import Marker from "./Marker";

/** --------------------------------------------------------------------------------------
 * Example of marker data
 *
 * @props : "markerData" is the array of objects of the form `{ status: 'done', caption: 'yyyy'}`
 * @props : "slide" is a boolean (not in implementaion right now)
 *
 *
 * Example of markerData
 * markerData = [
 *      { status: 'done', caption: 'Start Assessment' },
 *      { status: 'active', caption: 'Schedule Video Consult' },
 *      { status: 'default', caption: 'Initial Video Consult' },
 *  ]
 *
 * Note: There are as of now three 'status' namely "done", "active", "default"
 *
 *--------------------------------------------------------------------------------------*/

const ProgressSlider = (props) => {
    let { markerData, slide } = props;
    return (
        <>
            {!props.WithNavigationArrow && (
                <div className="d-flex w-100 justify-content-center mx-auto">
                    {markerData.map((marker, i) => {
                        switch (i) {
                            case 0:
                                return (
                                    <Marker
                                        key={i}
                                        hideStart
                                        status={marker.status}
                                        caption={marker.caption}
                                    />
                                );
                            case markerData.length - 1:
                                return (
                                    <Marker
                                        key={i}
                                        hideEnd
                                        status={marker.status}
                                        caption={marker.caption}
                                    />
                                );
                            default:
                                return (
                                    <Marker
                                        key={i}
                                        status={marker.status}
                                        caption={marker.caption}
                                    />
                                );
                        }
                    })}
                </div>
            )}
            {props.WithNavigationArrow && (
                <div className="ProgressSlider__Navigator d-flex w-100 justify-content-center mx-auto">
                    {markerData.map((marker, i) => {
                        return (
                            <Marker
                                key={i}
                                status={marker.status}
                                caption={marker.caption}
                                index={i}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default ProgressSlider;
