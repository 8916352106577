// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useEffect, useState } from "react";
import _ from "lodash";
import ScheduleActionDialog from "./ScheduleActionDialog";
import ScheduleReviewDialog from "./ScheduleReviewDialog";
import "./styles.scss";

const ScheduleDialog = (props) => {
    const { cancelHandler, submitHandler } = props;

    const [currentStep, setCurrentStep] = useState("scheduleActionDialog");
    const [activeScheduleDetails, setActiveScheduleDetails] = useState(props.activeScheduleDetails);
    const [dialogType, setDialogType] = useState(props.type);

    const callTimeStamp = _.get(activeScheduleDetails, "timestamp", "");
    const providerName = _.get(activeScheduleDetails, "provider_name");
    const eventType = _.get(activeScheduleDetails, "event_type", "");
    const providerId = _.get(activeScheduleDetails, "provider_username", "");

    useEffect(() => {
        setActiveScheduleDetails(props.activeScheduleDetails);
        setDialogType(props.type);
    }, [props.activeScheduleDetails, props.type]);

    if (!activeScheduleDetails) {
        return <></>;
    }

    const typeConfigMap = {
        cancel: {
            titleFormat: "cancel",
            buttonFormat: "Continue",
            feeFormat: "cancelling",
            actionSubmitHandler: () => setCurrentStep("reviewDialog"),
        },
        reschedule: {
            titleFormat: "reschedule",
            buttonFormat: "Continue to Reschedule",
            feeFormat: "rescheduling",
            actionSubmitHandler: () => submitHandler(activeScheduleDetails),
        },
    };

    const sharedProps = {
        activeScheduleDetails,
        cancelHandler,
        submitHandler,
        typeConfigMap,
        callTimeStamp,
        providerName,
        eventType,
        setCurrentStep,
        currentStep,
        providerId,
        type: dialogType,
        callId: activeScheduleDetails.callId,
    };

    const stepMap = {
        scheduleActionDialog: <ScheduleActionDialog {...sharedProps} />,
        reviewDialog: <ScheduleReviewDialog {...sharedProps} />,
    };

    return (
        <div className="ScheduleDialog position-fixed d-flex align-items-center justify-content-center">
            <div className="ScheduleDialog__inner p-5 d-flex flex-column text-center position-absolute">
                {_.get(stepMap, currentStep)}
            </div>
        </div>
    );
};

export default ScheduleDialog;
