import React from "react";
import { createClassNameString } from "../../../../component-library";
import "./styles.scss";
import { CardHeading } from "../../../UI/Headings";
import { getAutomationDataAttr } from "../../../../utils/automationUtils";

export const CarePlanCard = ({ children, className, title, actions, testId }) => {
    // Card secondary does not match styles in mockup
    return (
        <div
            className={createClassNameString(["CarePlanCard--container", className])}
            {...getAutomationDataAttr(testId)}>
            <div className="CarePlanCard--header">
                <CardHeading text={title} className="CarePlanCard--title" />
                {actions}
            </div>
            {children}
        </div>
    );
};
