// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import "./styles.scss";

const Circle = (props) => {
    let classes =
        "Circle d-flex align-items-center justify-content-center fs-12 " +
        (props.classes ? props.classes : "");
    switch (props.type) {
        case "green":
            classes = classes + " Circle--green";
            break;
        case "yellow":
            classes = classes + " Circle--yellow";
            break;
        case "red":
            classes = classes + " Circle--red";
            break;
        default:
            classes = classes + " Circle--green";
    }
    return <div className={classes} style={props.style} />;
};

export default Circle;
