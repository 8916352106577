import "./styles.scss";
import images from "../../../utils/images";
import { withRouter } from "react-router-dom";
import React from "react";
import { ICON_SIZES, ICON_TYPES } from "../../../constants/icons";
import { PrinterIcon } from "../../Icons/PrinterIcon";
import { EditIcon } from "../../Icons/EditIcon";
import { TrashCanIcon } from "../../Icons/TrashCanIcon";
import { CloseIcon } from "../../Icons/CloseIcon";

const NoteActions = (props) => {
    const {
        note,
        isEditable,
        patientId,
        setShowDeleteNotePopUp,
        setDraftNoteId,
        isEditing,
        setIsEditing,
    } = props;

    return (
        <>
            <div className="NoteActions--wrapper">
                {isEditable && !isEditing && (
                    <>
                        <div
                            onClick={() => {
                                if (note?.note_type === "Care Management") {
                                    setIsEditing(true);
                                    return;
                                }

                                const showV2 = note?.version && note?.version === 2;
                                props.history.push(
                                    `/app/patient/${patientId}/note/${note.id}/${
                                        showV2 ? "v2/" : ""
                                    }draft`,
                                );
                            }}
                            alt="edit"
                            className="NoteActions--icon_button">
                            <EditIcon
                                className={"NoteActions--edit_icon"}
                                iconType={ICON_TYPES.OUTLINE}
                                iconSize={ICON_SIZES.SMALL}
                            />
                            <p className="NoteActions--icon_text NoteActions--edit_text">Edit</p>
                        </div>

                        <div
                            onClick={() => {
                                props.history.push(
                                    `/app/patient/${patientId}/export_notes?noteIds=${note.id}`,
                                );
                            }}
                            alt="print"
                            className="NoteActions--icon_button">
                            <PrinterIcon
                                className={"NoteActions--print_icon"}
                                iconType={ICON_TYPES.OUTLINE}
                                iconSize={ICON_SIZES.SMALL}
                            />
                            <p className="NoteActions--icon_text NoteActions--print_text">Print</p>
                        </div>

                        <div
                            onClick={() => {
                                setShowDeleteNotePopUp(true);
                                setDraftNoteId(note.id);
                            }}
                            alt="delete"
                            className="NoteActions--icon_button">
                            <TrashCanIcon
                                className={"NoteActions--delete_icon"}
                                iconType={ICON_TYPES.OUTLINE}
                                iconSize={ICON_SIZES.SMALL}
                            />
                            <p className="NoteActions--icon_text NoteActions--delete_text">
                                Delete Draft
                            </p>
                        </div>
                    </>
                )}
                {isEditing && (
                    <div
                        onClick={() => {
                            setIsEditing(false);
                        }}
                        alt="discard changes"
                        className="NoteActions--icon_button">
                        <CloseIcon
                            className={"NoteActions--discard_icon"}
                            iconType={ICON_TYPES.OUTLINE}
                            iconSize={ICON_SIZES.SMALL}
                        />
                        <p className="NoteActions--icon_text NoteActions--discard_text">
                            Discard Changes
                        </p>
                    </div>
                )}
            </div>
        </>
    );
};

export default withRouter(NoteActions);
