import _ from "lodash";
import moment from "moment";
import {
    eventTypeDisplayMini,
    eventTypeDisplayNameFactory,
} from "../../Provider/ScheduleAvailability/ActiveScheduleDetails";
import Button from "../../UI/Button";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import { api } from "../../../APIRequests";
import images from "../../../utils/images";

const ScheduleActionDialog = (props) => {
    const {
        cancelHandler,
        type,
        callTimeStamp,
        providerName,
        eventType,
        typeConfigMap,
        providerId,
    } = props;
    const dateTimeFormat = "dddd MMMM DD • hh:mm A";

    const [timeDifferenceInHours, setTimeDifferenceInHours] = useState(0);
    const [cancellationCost, setCancellationCost] = useState(0);
    const [typeConfig, setTypeConfig] = useState(typeConfigMap);
    const [eventName, setEventName] = useState("");

    const getCancellationPolicy = () => {
        (async () => {
            const data = { data: { event_name: eventType } };
            const config = await api.patient.get_cancellation_policy(data);
            const timeDifferenceInMinutes = _.get(config, "time_difference", 0);
            const timeDifferenceInHours = moment.duration(timeDifferenceInMinutes, "m").asHours();
            setTimeDifferenceInHours(timeDifferenceInHours);
            setCancellationCost(_.get(config, "event_config.cancellation_amount", 0));
        })();
    };

    const getPatientCompletedFirstSessionForType = () => {
        (async () => {
            const minifiedEventName = eventType.includes("medication_mgmt")
                ? "medication_mgmt"
                : eventType;
            const data = { data: { event_name: minifiedEventName, provider_id: providerId } };
            const { is_first_session } =
                await api.patient.get_has_patient_competed_first_session_for_type(data);
            setEventName(eventTypeDisplayMini(eventType, is_first_session));
        })();
    };

    useEffect(() => {
        getCancellationPolicy();
        getPatientCompletedFirstSessionForType();
    }, [eventType]);

    useEffect(() => {
        setTypeConfig(props.typeConfigMap);
    }, [props.typeConfigMap]);

    if (!eventType) {
        return <></>;
    }

    const formattedSelectedTime = moment
        .utc(callTimeStamp, "YYYY-MM-DD HH:mm")
        .local()
        .format(dateTimeFormat);

    const isWithinTimeDifference =
        moment().diff(
            moment(formattedSelectedTime, dateTimeFormat).subtract(timeDifferenceInHours, "h"),
            "h",
        ) >= 0;

    const headingText = `Are you sure you want to ${_.get(
        typeConfig,
        `${type}.titleFormat`,
    )} your appointment?`;
    const submitHandler = _.get(typeConfig, `${type}.actionSubmitHandler`);

    const messageJsx = (
        <div className="my-4 fs-15 EventProviderText fw-bold">
            <div className="row">
                <div className="col">
                    {eventName} with {providerName}
                </div>
            </div>
            <div className="row">
                <div className="col">{formattedSelectedTime}</div>
            </div>
        </div>
    );

    const cancellationFeeText = (
        <>
            <div className="fs-15 mt-4 justify-content-center ScheduleDialog__subtext">
                <span>
                    Note: You are {_.get(typeConfig, `${type}.feeFormat`)} an appointment with less
                    than {timeDifferenceInHours} hours' notice.
                    <span className="fw-bold">This may result in a ${cancellationCost} fee.</span>
                </span>
            </div>
        </>
    );

    const providerAvailabilityText = (
        <>
            <div className="fs-15 mt-2 justify-content-center ScheduleDialog__subtext">
                <span>
                    Earliest reschedule option would be next calendar day pending providers
                    availability.
                </span>
            </div>
        </>
    );

    return (
        <>
            <div className="row mb-4">
                <div className="col">
                    <h2 className="fs-17 fw-bold">{headingText}</h2>
                </div>
            </div>
            <Button type="button" className="close modal-close" onClick={cancelHandler}>
                <span aria-hidden="true">
                    <img src={images("./icons/grey-x-circle.svg")} />
                </span>
            </Button>
            {messageJsx}
            <div className="ScheduleDialog__btn-wpr my-4 d-flex justify-content-between">
                <Button
                    onClick={submitHandler}
                    type="button"
                    className="Btn Btn--pri Btn-sm col w-100 CancelCallButton"
                    data-e2e={`ScheduleDialogButton-${type}`}>
                    {_.get(typeConfigMap, `${type}.buttonFormat`)}
                </Button>
                <Button
                    onClick={cancelHandler}
                    type="button"
                    className="col w-100 Btn Btn--otl-pri Btn-sm KeepCallButton">
                    Keep Appointment
                </Button>
            </div>
            {isWithinTimeDifference && cancellationFeeText}
            {isWithinTimeDifference && type === "reschedule" && providerAvailabilityText}
        </>
    );
};

export default ScheduleActionDialog;
