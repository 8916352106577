// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import images from "../../../utils/images";

export default ({ onInfoClickHandler }) => (
    <div onClick={onInfoClickHandler} className="CancellationPolicy">
        <span className="CancellationPolicy__title">Cancellation Policy</span>
        <img className="CancellationPolicy__image" src={images("./icons/info.png")} alt="i" />
    </div>
);
