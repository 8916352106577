import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import { Button } from "../Button";
import { Image } from "../Image";

import "./styles.scss";

export const Chip = ({ className, label, onClick, ...props }) => (
    <div className={createClassNameString(["apti-CL apti-Chip", className])} {...props}>
        <Button className="apti-Chip--button" onClick={onClick}>
            <Image
                className="apti-Chip--icon"
                src="icons/delete_button.svg"
                alt="X"
                data-label={label}
            />
        </Button>
        <label className="apti-Chip--label">{label}</label>
    </div>
);

Chip.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

Chip.defaultProps = {
    className: undefined,
    label: "",
    onClick: () => {},
};
