// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import CardSecondary from "../../UI/Card/CardSecondary";
import images from "../../../utils/images";
import { Link } from "react-router-dom";
import { providerRoute as provider } from "../../../config/roles";
import { isUserPCP } from "../../../redux/actions/auth";

const DashboardViewCard = (props) => {
    let title = props.title ? props.title : "Number of Patients in Platform";
    return (
        <div>
            <CardSecondary className="DashboardViewCard py-2 px-3 px-lg-4">
                <div className="d-flex align-items-center justify-content-between">
                    <p className="fs-30 fs-xl-50 fw-bold mb-0">{props.patient_count}</p>
                    <img
                        className="d-none d-md-block mt-2 mb-2 pt-1 pb-1"
                        src={images("./provider/brain.svg")}
                    />
                    {isUserPCP() && (
                        <Link to={provider + "/patient/add"} className="d-md-none d-block">
                            <img
                                className="mt-2 mb-2 pt-1 pb-1"
                                src={images("./provider/Oval.svg")}
                            />
                        </Link>
                    )}
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <p className="CardText fs-lg-20 fs-14 mt-3">{title}</p>
                    {isUserPCP() && (
                        <Link to={provider + "/patient/add"}>
                            <img
                                className="d-md-block d-none"
                                src={images("./provider/Oval.svg")}
                            />
                        </Link>
                    )}
                </div>
            </CardSecondary>
        </div>
    );
};

export default DashboardViewCard;
