// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import Input from "../UI/Input";
import InputError from "../UI/InputError";
import Button from "../UI/Button";
import { Link } from "react-router-dom";
import Label from "../UI/Label";
import { AuthWrapper } from "./index";

const Referral = (props) => {
    return (
        <AuthWrapper showArt={props.showSvgArt} refFromOuter={props.scrlWprRef}>
            <div className="SignUp--step1">
                <div className="FormGroup">
                    <Label>Provider Referral Code</Label>
                    <Input
                        required
                        onChange={props.referralChange}
                        className={
                            "Input " + (props.referralForm.errors.length > 0 ? "is-invalid" : "")
                        }
                        elementtype="input"
                        type="text"
                        name="referral"
                    />
                    {props.referralForm.errors.length > 0 ? (
                        <InputError>{props.referralForm.errors[0]}</InputError>
                    ) : null}
                </div>
                <p className="text-left">
                    You'll need a provider code from your Primary Care Physician to move forward.
                    Please ask your doctor about aptihealth by calling, emailing, texting or
                    visiting them.
                </p>
                <div className="mb-5 mt-4 text-center">
                    <Button
                        type="submit"
                        className="Btn Btn--pri Btn-sm"
                        onClick={props.onReferralSubmit}>
                        Continue
                    </Button>
                </div>
                <div className="Auth__switch">
                    {/* option to switch between login and signup */}
                    <p className="text-center">
                        <span>Already have an Account? </span>
                        <Link to="/auth/sign-in" className="cursor">
                            <b>Sign In</b>
                        </Link>
                    </p>
                </div>
            </div>
        </AuthWrapper>
    );
};

export default Referral;
