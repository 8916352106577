// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import "./styles.scss";
import images from "../../../utils/images";
import { Form, Formik } from "formik";
import { VALIDATION_SCHEMA } from "../../../containers/Dashboard/Admin/schema/adminProfileBasicValidation";
import { ADMIN_BASIC_SCHEMA } from "../../../containers/Dashboard/Admin/schema/adminProfileBasicSchema";
import FormikInput from "../../UI/FormikInput";
import Button from "../../UI/Button";
import { api } from "../../../APIRequests";
import ProfileDetailsCard from "./ProfileDetailsCard";
import ProfileLoader from "../../Common/Loaders/ProfileLoader";
import FormikInputFormat from "../../UI/formikMobileInput";
import { CustomForm } from "../../../component-library/CustomForm";

class Profile extends Component {
    state = {
        editBasic: false,
        profileData: null,
        adminAvatar: null,
    };

    iterableBasicInfo = () => [
        { title: "Full Name", value: this.state.profileData.name || "" },
        { title: "Customer Success Type", value: this.state.profileData.user_type },
        { title: "Email", value: this.state.profileData.email || "" },
        { title: "Mobile", value: this.state.profileData.mobile || "" },
    ];

    componentWillUnmount() {}

    componentDidMount() {
        /** Fetch admin's profile  */
        getProfile(this)
            .then((response) => {
                const options = { params: { showLoader: false } };
                api.admin
                    .fetch_all_practices({ options })
                    .then((cleanResponse) => {
                        this.setState({
                            practiceList: cleanResponse,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    formatMobileNumber = (val) => {
        let firstNumber = val.substring(0, 3);
        let middleNumber = val.substring(3, 6);
        let lastNumber = val.substring(6, 10);
        let formattedMobileNumber = `(${firstNumber}) ${middleNumber}-${lastNumber}`;
        return formattedMobileNumber;
    };

    /**
     * Below function returns the rendered details which can be reused.
     * @param {string} title - title of the value
     * @param {string} value - value to go below title
     */
    renderTitleWithValue = function (title, value) {
        return (
            <div className="mb-4">
                <div className="fs-14 fw-bold mb-2">
                    <span>{title}</span>
                </div>
                <div className="fs-14">
                    <span>{title == "Mobile" ? this.formatMobileNumber(value) : value}</span>
                </div>
            </div>
        );
    };

    shortDetails = () => (
        <div className="AdminProfile__short-details">
            <div className="py-4 py-lg-0 d-flex flex-row flex-lg-column align-items-center justify-content-lg-center">
                <div className="AdminProfile__avatar-wpr position-relative mx-3 mx-lg-0 mt-lg-5 mb-lg-4">
                    <img
                        className="mx-auto d-block"
                        src={this.props.avatar || images("./common/avatar.png")}
                        alt="Customer Success avatar"
                    />
                    {/* <div role="button" className="AdminProfile__edit-avatar-btn cursor position-absolute" /> */}
                </div>
                <div className="text-lg-center text-white ">
                    <div className="fw-bold fs-16 fs-lg-18 mb-lg-2">
                        {this.state.profileData.name}
                    </div>
                    <div className="fs-14">{this.state.profileData.user_type}</div>
                </div>
            </div>
        </div>
    );

    toggleEditBasicInfo = () => {
        this.setState((ps) => {
            return {
                editBasic: !ps.editBasic,
            };
        });
    };

    renderForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 ">
                    <div className="container px-0">
                        <div className="row">
                            {ADMIN_BASIC_SCHEMA.map((formEl) => {
                                return (
                                    <div key={formEl.name} className={"col-12 col-lg-6"}>
                                        {formEl.name == "user_type" ? (
                                            <FormikInput
                                                disabled
                                                inputBorderDisabled="border-0 px-0"
                                                formEl={formEl}
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                            />
                                        ) : formEl.name == "mobile" ? (
                                            <FormikInputFormat
                                                value={formikProps && formikProps.values.mobile}
                                                onChange={async (val) => {
                                                    await formikProps.setFieldValue(
                                                        "mobile",
                                                        val.value,
                                                    );
                                                    formikProps.setFieldTouched("mobile");
                                                }}
                                                formEl={formEl}
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                            />
                                        ) : (
                                            <FormikInput
                                                formEl={formEl}
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                            />
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="d-lg-flex d-block justify-content-center text-center justify-content-lg-end">
                    <Button
                        type="button"
                        onClick={() => this.toggleEditBasicInfo()}
                        className="Btn Btn--otl-pri Btn-sm mr-lg-3 mb-3 mb-lg-0">
                        Cancel
                    </Button>
                    <Button type="submit" className="Btn Btn--pri Btn-sm">
                        Save
                    </Button>
                </div>
            </Form>
        );
    };

    submitHandler = () => {
        this.toggleEditBasicInfo();
    };

    basicInfo = () => (
        <ProfileDetailsCard
            onclick={this.toggleEditBasicInfo}
            edit={this.state.editBasic}
            heading="Basic Information"
            isEditable>
            <div className="AdminProfile__basic-info row no-gutters my-2 pt-4 px-2">
                {!this.state.editBasic &&
                    this.iterableBasicInfo().map((item) => {
                        return (
                            <div className="col-12 col-lg-6">
                                {this.renderTitleWithValue(item.title, item.value)}
                            </div>
                        );
                    })}
                {this.state.editBasic && (
                    <CustomForm
                        initialValues={this.state.profileData}
                        validationSchema={VALIDATION_SCHEMA}
                        onSubmit={this.submitHandler}
                        render={this.renderForm}
                    />
                )}
            </div>
        </ProfileDetailsCard>
    );
    associatedPractice = () => (
        <ProfileDetailsCard heading="Associated Practice">
            <div className="AdminProfile__basic-info row no-gutters my-2 pt-4 px-2">
                {this.state.practiceList &&
                    this.state.practiceList.map((item) => {
                        return (
                            <div className="col-12 col-lg-6">
                                {this.renderTitleWithValue(item.name, item.general_email)}
                            </div>
                        );
                    })}
            </div>
        </ProfileDetailsCard>
    );

    render() {
        return (
            <div>
                <div className="container px-3">
                    <h1 className="d-none d-lg-block fs-22 fw-bold my-4 py-2">My Profile</h1>
                    {this.state.profileData ? (
                        <div className="AdminProfile" data-public={"true"}>
                            <div className="row no-gutters">
                                <div className="col-12 col-lg-3 mt-3 mt-lg-0 mb-3 mb-lg-0">
                                    {this.state.profileData && this.shortDetails()}
                                </div>
                                <div className="col-12 col-lg-9 px-lg-4">
                                    {this.state.profileData && this.basicInfo()}
                                    {this.state.profileData && this.associatedPractice()}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <ProfileLoader />
                    )}
                </div>
            </div>
        );
    }
}

export default Profile;

export const getProfile = async (that) => {
    try {
        const options = { params: { showLoader: false } };
        let response = await api.admin.fetch_admin_profile({ options });
        let profileData = response && response;

        that.setState({
            profileData: profileData,
        });
    } catch (err) {
        console.log(err);
    }
};
