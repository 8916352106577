// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { Form, Formik } from "formik";
import FormikInput from "../../UI/FormikInput";
import Button from "../../UI/Button";
import { Link } from "react-router-dom";
import images from "../../../utils/images";
import { CardPrimary } from "../../UI/Card";
import CardHeading from "../../UI/Headings/CardHeading";
import {
    A5_DETAILS_INITIAL_VALUES,
    A5_DETAILS_SCHEMA,
    A5_DETAILS_VALIDATION,
} from "./a5ScreeningDetailsSchema";
import FormikInputFormat from "../../UI/formikMobileInput";
import { api } from "../../../APIRequests";
import { cloneDeep } from "lodash";
import { getQueryParams } from "../../../utils/filters";
import { isUserAdmin } from "../../../redux/actions/auth";
import { getPracticeList } from "../A5ScreeningList";
import AssociatedFiles from "../../Provider/PatientView/AssociatedFiles";
import { viewScreeningAssociatedFile, getScreeningAssociatedFiles } from "../../../utils/fileUtil";
import { CustomForm } from "../../../component-library/CustomForm";

class A5ScreeningDetails extends Component {
    constructor(props) {
        super(props);
        const isAdd = this.props.match.params.screening_id === "add";
        const pcpPracticeId = getQueryParams("pcpPracticeId", props.location.search.trim());
        const a5Details = cloneDeep(A5_DETAILS_INITIAL_VALUES);
        if (pcpPracticeId && pcpPracticeId !== "all_practices") {
            a5Details["pcp_practice_id"] = pcpPracticeId;
        }
        this.state = {
            isAdd: isAdd,
            edit: isAdd,
            pcpPracticeId: pcpPracticeId,
            a5Details: a5Details,
            practiceOptions: null,
            providerOptions: null,
            schema: cloneDeep(A5_DETAILS_SCHEMA),
            files: [],
        };
    }

    componentDidMount = async () => {
        let existingPcpPracticeId = await this.getScreeningDetails();
        const files = await getScreeningAssociatedFiles(this.props.match.params.screening_id);
        this.setState({ files });
        if (isUserAdmin()) {
            this.setPracticeOptions(await getPracticeList("Primary Care", false, "display"));
        }
        this.getPracticeProviders(existingPcpPracticeId);
        document.addEventListener("change", this.practiceChangeHandler, {
            capture: true,
        });
    };

    practiceChangeHandler = async (e) => {
        if (e.target.name === "pcp_practice_id") {
            try {
                const pcpPracticeId = e.target.value;
                this.setState({ pcpPracticeId: pcpPracticeId });
                const response = await this.adminsGetPracticeProviders(pcpPracticeId);
                this.setProviderOptions(response);
            } catch (e) {
                console.log(e);
            }
        }
    };

    getPcpPracticeId = (existingPcpPracticeId) => {
        let pcpPracticeId;
        if (existingPcpPracticeId) {
            pcpPracticeId = existingPcpPracticeId;
        } else if (this.state.pcpPracticeId !== "all_practices") {
            pcpPracticeId = this.state.pcpPracticeId;
        }
        return pcpPracticeId;
    };

    getPracticeProviders = async (existingPcpPracticeId) => {
        try {
            let response, pcpPracticeId;
            pcpPracticeId = this.getPcpPracticeId(existingPcpPracticeId);
            if (isUserAdmin() && pcpPracticeId) {
                response = await this.adminsGetPracticeProviders(pcpPracticeId);
            } else if (!isUserAdmin()) {
                response = await api.provider.fetch_practice_members();
            }
            if (response) {
                this.setProviderOptions(response);
            }
        } catch (e) {
            console.log(e);
        }
    };

    async adminsGetPracticeProviders(pcpPracticeId) {
        const urlParams = { practiceId: pcpPracticeId };
        return api.admin.fetch_practice_providers({ urlParams });
    }

    setPracticeOptions = async (options) => {
        let schema = A5_DETAILS_SCHEMA.map((el) => {
            if (el.name === "pcp_practice_id") {
                el.elementConfig.options = options;
            }
            return el;
        });
        this.setState({ schema: schema, practiceOptions: options });
    };

    setProviderOptions = (response) => {
        const options = response
            .filter((member) => member.provider_type === "PCP")
            .map((member) => {
                return { value: member.username, display: member.name };
            });
        let schema = A5_DETAILS_SCHEMA.map((el) => {
            if (el.name === "pcp_id") {
                el.elementConfig.options = options;
            }
            return el;
        });
        this.setState({ schema: schema, providerOptions: options });
    };

    async getScreeningDetails() {
        try {
            if (!this.state.isAdd) {
                const queryParams = { screening_id: this.props.match.params.screening_id };
                const response = await api.shared.fetch_a5_screening_details({ queryParams });
                this.setState({ a5Details: response });
                return response["pcp_practice_id"];
            }
        } catch (e) {
            console.log(e);
        }
    }

    createHandler = async (formData, actions) => {
        actions.setSubmitting(false);
        try {
            let data = { ...formData };
            await api.shared.create_a5_screening_details({ data });
            this.props.history.push("/app/a5_screenings");
        } catch (e) {
            console.log(e);
        }
    };

    updateHandler = async (formData, actions) => {
        actions.setSubmitting(false);
        try {
            formData["id_"] = formData["id"];
            let data = { ...formData };
            await api.shared.update_a5_screening_details({ data });
            this.props.history.push("/app/a5_screenings");
        } catch (e) {
            console.log(e);
        }
    };

    editHandler = () => this.setState({ edit: !this.state.edit });

    renderDisabledView = () => {
        return (
            <div className="mx-auto px-0 ">
                <div className="container">
                    <div className="row">
                        {this.getDisabledFields(this.getPracticeName(), this.getProviderName())}
                        <div className="col-12 col-lg-6">
                            <div>
                                <label className="Label">Notification Type</label>
                                <p>{this.state.a5Details.notification_type}</p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center justify-content-lg-end">
                        <Button
                            type="button"
                            onClick={() => this.props.history.push("/app/a5_screenings")}
                            className="Btn Btn--otl-pri Btn-sm mr-3">
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            onClick={this.editHandler}
                            className="Btn Btn--pri Btn-sm">
                            Edit Screening
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    getPracticeName() {
        let practiceName = "";
        if (isUserAdmin() && this.state.practiceOptions) {
            const practice = this.state.practiceOptions.find(
                (practice) => practice["value"] === this.state.a5Details.pcp_practice_id,
            );
            if (practice) {
                practiceName = practice.display;
            }
        }
        return practiceName;
    }

    getProviderName() {
        let providerName = "";
        if (this.state.providerOptions) {
            const provider = this.state.providerOptions.find(
                (provider) => provider["value"] === this.state.a5Details.pcp_id,
            );
            if (provider) {
                providerName = provider.display;
            }
        }
        return providerName;
    }

    getDisabledFields(practiceName, providerName) {
        return (
            <>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">First Name</label>
                        <p>{this.state.a5Details.first_name}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">Last Name</label>
                        <p>{this.state.a5Details.last_name}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">Date of Birth</label>
                        <p>{this.state.a5Details.dob}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">Gender</label>
                        <p>{this.state.a5Details.gender}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">Email</label>
                        <p>{this.state.a5Details.email}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">Mobile</label>
                        <p>{this.state.a5Details.mobile}</p>
                    </div>
                </div>
                <h6 className="col-12">
                    <b>Address</b>
                </h6>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">Street</label>
                        <p>{this.state.a5Details.address && this.state.a5Details.address.street}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">City</label>
                        <p>{this.state.a5Details.address && this.state.a5Details.address.city}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">State</label>
                        <p>{this.state.a5Details.address && this.state.a5Details.address.state}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">Zip Code</label>
                        <p>
                            {this.state.a5Details.address && this.state.a5Details.address.zip_code}
                        </p>
                    </div>
                </div>
                {isUserAdmin() && (
                    <div className="col-12 col-lg-6">
                        <div>
                            <label className="Label">Practice</label>
                            <p>{practiceName}</p>
                        </div>
                    </div>
                )}
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">Primary Care Provider</label>
                        <p>{providerName}</p>
                    </div>
                </div>
            </>
        );
    }

    renderAddForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 ">
                    <div className="container">
                        <div className="row">
                            {this.state.schema.map((formEl) => {
                                if (formEl.elementType === "segment") {
                                    return (
                                        <h6 key={formEl.displayName} className="col-12">
                                            <b>{formEl.displayName}</b>
                                        </h6>
                                    );
                                } else if (formEl.name === "pcp_practice_id" && !isUserAdmin()) {
                                    return <div />;
                                } else {
                                    return (
                                        <div
                                            key={formEl.name}
                                            className={
                                                formEl.elementType === "textarea"
                                                    ? "col-12"
                                                    : formEl.name === "dob" ||
                                                      formEl.name === "gender"
                                                    ? "col-6"
                                                    : "col-12 col-lg-6"
                                            }>
                                            {formEl.name === "email" &&
                                            this.state.patientDetails ? (
                                                <FormikInput
                                                    disabled
                                                    inputBorderDisabled="border-0 px-0"
                                                    formEl={formEl}
                                                    errors={formikProps.errors}
                                                    touched={formikProps.touched}
                                                />
                                            ) : formEl.name === "mobile" ? (
                                                <FormikInputFormat
                                                    value={formikProps && formikProps.values.mobile}
                                                    onChange={async (val) => {
                                                        await formikProps.setFieldValue(
                                                            "mobile",
                                                            val.value,
                                                        );
                                                        formikProps.setFieldTouched("mobile");
                                                    }}
                                                    formEl={formEl}
                                                    errors={formikProps.errors}
                                                    touched={formikProps.touched}
                                                />
                                            ) : formEl.name === "dob" ? (
                                                <FormikInputFormat
                                                    value={formikProps && formikProps.values.dob}
                                                    onChange={async (val) => {
                                                        await formikProps.setFieldValue(
                                                            "dob",
                                                            val.value,
                                                        );
                                                        formikProps.setFieldTouched("dob");
                                                    }}
                                                    formEl={formEl}
                                                    errors={formikProps.errors}
                                                    touched={formikProps.touched}
                                                />
                                            ) : (
                                                <FormikInput
                                                    formEl={formEl}
                                                    errors={formikProps.errors}
                                                    touched={formikProps.touched}
                                                />
                                            )}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>
                {this.getFormButtons()}
            </Form>
        );
    };

    renderEditForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 ">
                    <div className="container">
                        <div className="row">
                            {this.getDisabledFields(this.getPracticeName(), this.getProviderName())}
                            {this.state.schema.map((formEl) => {
                                if (formEl.name === "notification_type") {
                                    return (
                                        <div key={formEl.name} className={"col-12 col-lg-6"}>
                                            <FormikInput
                                                formEl={formEl}
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                            />
                                        </div>
                                    );
                                } else {
                                    return <div />;
                                }
                            })}
                        </div>
                    </div>
                </div>
                {this.getFormButtons()}
            </Form>
        );
    };

    getFormButtons() {
        return (
            <div className="d-flex justify-content-center justify-content-lg-end">
                <Button
                    type="button"
                    onClick={() => this.props.history.push("/app/a5_screenings")}
                    className="Btn Btn--otl-pri Btn-sm mr-3">
                    Cancel
                </Button>
                <Button type="submit" className="Btn Btn--pri Btn-sm">
                    Submit
                </Button>
            </div>
        );
    }

    render() {
        return (
            <div>
                <div className="survey-back-link-wpr mx-auto fs-16 my-3 py-2">
                    <Link className="survey-back-link" to={"/app/a5_screenings"}>
                        <img className="img-fluid" src={images("./common/solidBckBtn.svg")} />
                        <p className="d-inline ml-2">Back to Screening List</p>
                    </Link>
                </div>
                <CardPrimary className="mx-auto" style={{ marginTop: 0 }}>
                    <div className="mx-md-2 p-0 p-md-2 mb-0">
                        <CardHeading text="Screening Details" className="fs-18" />
                    </div>
                    <hr className="m-0 d-none d-md-block" />
                    <br />
                    <div>
                        {!(this.state.edit && this.state.a5Details) && this.renderDisabledView()}
                        {this.state.edit && (
                            <CustomForm
                                initialValues={this.state.a5Details}
                                validationSchema={A5_DETAILS_VALIDATION}
                                onSubmit={
                                    this.state.isAdd ? this.createHandler : this.updateHandler
                                }
                                render={this.state.isAdd ? this.renderAddForm : this.renderEditForm}
                            />
                        )}
                    </div>
                </CardPrimary>
                {!this.state.edit && (
                    <AssociatedFiles
                        viewAllLink={`/app/a5_screenings/${this.state.a5Details.id}/file`}
                        fileList={this.state.files}
                        getFile={(key) => {
                            viewScreeningAssociatedFile(this.state.a5Details.id, key);
                        }}
                    />
                )}
            </div>
        );
    }
}

export default A5ScreeningDetails;
