import "./styles.scss";
import React, { useEffect, useState } from "react";
import { getCurrentTimePosition } from "../../../utils/calendar/positions";
import moment from "moment";
import { connect } from "react-redux";

const CalendarCurrentTime = ({ calendarRect, calendar }) => {
    // lives here because currentTime needs to be shared by the CalendarBackground
    // and the CalendarCurrentTime components
    const [currentTime, setCurrentTime] = useState(moment().local());

    useEffect(() => {
        // Updates current time axis every minute (60000 milliseconds)
        const interval = setInterval(() => {
            setCurrentTime(moment().local());
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const currentTimePosition = getCurrentTimePosition(calendar.viewType, calendar.selectedScheduleDate, calendar.selectedScheduleDates, currentTime, calendarRect);

    return currentTimePosition 
        ? <div className="CalendarCurrentTime__time" style={currentTimePosition} />
        : null;
};

const mapStateToProps = (state) => {
    return {
        calendar: state.calendar,
    };
};

export default connect(mapStateToProps)(CalendarCurrentTime);