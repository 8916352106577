import { trackEvent } from "../../../utils/EventTrackingUtil";

const entrypoint_to_name_map = {
    self_signup: "digital-direct",
    teens_kids_program: "teens-and-kids",
    referral_signup: "referred-patient-registration",
    hcp_signup: "hcp",
};

const generic_messages = {
    accepted: {
        copay: "-eligibility-accepted-with-copay",
        nocopay: "-eligibility-accepted-without-copay",
    },
    declined: "-eligibility-declined",
    no_match: "-eligibility-no-match-",
    pending: "-eligibility-pending-",
    not_configured: "-eligibility-not-configured",
};

const generic_click_messages = {
    // back to insurance screen or clicking link to payers site
    declined: {
        back: "-back-to-insurance",
        payor: "-click-payer-site",
    },
    no_match: {
        email: "-email-support",
        call: "-call-support",
    },
    pending: {
        email: "-email-support",
        call: "-call-support",
    },
};

const format_message_by_entrypoint = ({ entryPoint, status, copay, submissionAttempts }) => {
    let message;
    let ga_name = entrypoint_to_name_map[entryPoint];

    if (status === "accepted") {
        message = copay
            ? ga_name + generic_messages[status]["copay"]
            : ga_name + generic_messages[status]["nocopay"];
    } else {
        message = ga_name + generic_messages[status];
    }

    if (status === "no_match" || status === "pending") {
        message = message + submissionAttempts;
    }
    return message;
};

const format_message_by_entrypoint_click = (entryPoint, status, clickOrigin) => {
    let message = entrypoint_to_name_map[entryPoint];
    message += "-" + status.replace("_", "-");
    message += generic_click_messages[status][clickOrigin];
    return message;
};

export const analytics = ({ patientEligibility, submissionAttempts, entryPoint }) => {
    const status = patientEligibility["status"];
    const copay = patientEligibility["copay"];
    const message = format_message_by_entrypoint({ entryPoint, status, copay, submissionAttempts });
    trackEvent(message);
};

//click origin is the click events next step: back (i.e. going back to fix ins), payor (clicking payors site), email (apti), call (apti)
export const clickAnalytics = (entryPoint, status, clickOrigin) => {
    const message = format_message_by_entrypoint_click(entryPoint, status, clickOrigin);
    trackEvent(message);
};
