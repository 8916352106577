import React from "react";
import { BehavioralInterventionAndPlan } from "./BehavioralInterventionAndPlan";
import {
    getCareGoalAndObjectiveNoteVersion,
    getCareGoalAndObjectivesFromNote,
} from "../WorkflowFunctions";
import { InterventionAndPlanV2 } from "./InterventionAndPlanV2/InterventionAndPlanV2";

/**
 * Displays the intervention and plan step in the behavioral workflow
 */
export const InterventionAndPlan = (props) => {
    const noteContent = props.state.progressNote?.content;
    const version = getCareGoalAndObjectiveNoteVersion(noteContent);
    const goalsObjectivesProgress = getCareGoalAndObjectivesFromNote(noteContent, version);

    return (
        <>
            {version === "1" && <BehavioralInterventionAndPlan {...props} />}
            {version === "2" && (
                <InterventionAndPlanV2
                    goalsObjectivesProgress={goalsObjectivesProgress}
                    progressNote={props.state.progressNote}
                    updateNoteContent={props.updateNoteContent}
                    currentWorkflowStep={props.state.currentStep}
                    jumpWorkflowStep={props.jumpWorkflowStep}
                    workflow={props.state.workflow}
                    noteEditHandler={props.noteEditHandler}
                />
            )}
        </>
    );
};
