import "./styles.scss";
import React, { forwardRef } from "react";
import { CalendarNonWorkingHours } from "./CalendarNonWorkingHours";
import { CalendarWorkingHours } from "./CalendarWorkingHours";
import CalendarBackground from "./CalendarBackground";
import { useCalendarDimensions } from "../../../utils/hooks/useCalendarDimensions";
import { CalendarDateAxis } from "../CalendarDateAxis";
import { useCalendarScroll } from "../../../utils/hooks/useCalendarScroll";
import { NPSUpdatedCalendar } from "../../NPS/NPSUpdatedCalendar";
import FeatureGate from "../../Common/FeatureGate/FeatureGate";
import { UPDATED_CALENDAR_NPS_FLAG } from "../../../constants/featureFlags";
import { useSelector } from "react-redux";

export const CalendarContainer = forwardRef(function CalendarContainer(
    { workingHours, daysToDisplay, children, className },
    refs,
) {
    const { calendarBackgroundRef, stickyHeaderRef } = refs;
    const privateFlags = useSelector((state) => state.flags.privateFlags);
    const privateFlagsHaveLoaded = useSelector((state) => state.flags.privateFlagsHaveLoaded);
    const { calendarRect } = useCalendarDimensions(calendarBackgroundRef, stickyHeaderRef);
    const { hasScrolledForFirstTime } = useCalendarScroll(
        calendarRect,
        daysToDisplay,
        workingHours,
    );
    return (
        <div
            className={"CalendarContainer__body"}
            style={{
                opacity: hasScrolledForFirstTime ? 100 : 0,
            }}>
            <FeatureGate
                flagName={UPDATED_CALENDAR_NPS_FLAG}
                defaultFlagBehavior={true}
                enabled={privateFlags?.[UPDATED_CALENDAR_NPS_FLAG]}
                alternateFlagDisabledBehavior={null}
                hasLoaded={privateFlagsHaveLoaded}>
                <NPSUpdatedCalendar />
            </FeatureGate>
            <div
                ref={stickyHeaderRef}
                className={`CalendarContainer__sticky-header-container ${className}`}>
                <CalendarDateAxis
                    className={daysToDisplay.length === 1 ? "day" : "week"}
                    daysToDisplay={daysToDisplay}
                />
            </div>
            <CalendarBackground
                ref={calendarBackgroundRef}
                daysToDisplay={daysToDisplay}
                calendarRect={calendarRect}
            />
            <CalendarNonWorkingHours
                calendarRect={calendarRect}
                numberOfDays={daysToDisplay.length}
            />
            <CalendarWorkingHours
                calendarRect={calendarRect}
                daysToDisplay={daysToDisplay}
                workingHours={workingHours}
            />
            {children}
        </div>
    );
});
