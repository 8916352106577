import React from "react";
import CardSecondary from "../../../../../UI/Card/CardSecondary";
import "./styles.scss";
import { ExternalLinkIcon, Text, TextTypes } from "../../../../../../component-library";
import { getAutomationDataAttr } from "../../../../../../utils/automationUtils";
import { ICON_SIZES, ICON_TYPES } from "../../../../../../constants/icons";

export const GoalAndObjectiveWidget = ({ goalsObjectivesProgress, patientDetails }) => {
    const renderGoalsAndObjectives = () => {
        if (goalsObjectivesProgress && Object.keys(goalsObjectivesProgress).length !== 0) {
            const goals_and_objectives_components = Object.keys(goalsObjectivesProgress).map(
                (key) => {
                    const goal = goalsObjectivesProgress[key];
                    const goalTitle = goal.goal_other_text ? goal.goal_other_text : goal.goal_text;

                    return (
                        <div
                            className={`GoalAndObjectiveWidget--goal_container_${
                                Object.keys(goalsObjectivesProgress).length
                            }_goals`}
                            key={goal.goal_id}
                            {...getAutomationDataAttr(
                                `GoalAndObjectiveWidget_${goal.goal_text_id}`,
                            )}>
                            <div className="GoalAndObjectiveWidget--goal_title">{goalTitle}</div>
                            <div className="GoalAndObjectiveWidget--objectives_container">
                                {goal.objectives.map((objective) => {
                                    const objectiveText = objective.objective_other_text
                                        ? objective.objective_other_text
                                        : objective.objective_text;
                                    return (
                                        <div
                                            className="GoalAndObjectiveWidget--objective"
                                            key={objective.objective_text_id}
                                            {...getAutomationDataAttr(
                                                `GoalAndObjectiveWidget_${goal.goal_text_id}_${objective.objective_text_id}`,
                                            )}>
                                            {objectiveText}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                },
            );

            const temp = goals_and_objectives_components[1];
            goals_and_objectives_components[1] = goals_and_objectives_components[2];
            goals_and_objectives_components[2] = temp;

            return (
                <div
                    className="GoalAndObjectiveWidget--goals_container"
                    {...getAutomationDataAttr("GoalAndObjectiveWidget_goalsDisplay")}>
                    {goals_and_objectives_components}
                </div>
            );
        } else {
            return (
                <div className={"GoalAndObjectiveWidget--empty_state"}>
                    <button
                        onClick={() => {
                            const url = `/app/patient/${patientDetails.username}/profile/v2?selectedTab=Care%20Plan&openLegacyGoals=true`;
                            window.open(url, "_blank");
                        }}
                        className="GoalAndObjectiveWidget--icon_button">
                        <p className="apti-LinkV2">View Legacy Goals</p>
                        <ExternalLinkIcon
                            className="GoalAndObjectiveWidget--icon"
                            iconType={ICON_TYPES.OUTLINE}
                            iconSize={ICON_SIZES.SMALL}
                        />
                    </button>
                </div>
            );
        }
    };

    return (
        <CardSecondary className="GoalAndObjectiveWidget--card">
            <Text className="GoalAndObjectiveWidget--title" type={TextTypes.subtitle}>
                Care Goals & Objectives
            </Text>
            {renderGoalsAndObjectives()}
        </CardSecondary>
    );
};
