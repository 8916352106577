/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const LockIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Lock"
                {...props}>
                <path d="M17 17C17 16.4477 16.5523 16 16 16C15.4477 16 15 16.4477 15 17V21C15 21.5523 15.4477 22 16 22C16.5523 22 17 21.5523 17 21V17Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 9V12H8C6.89543 12 6 12.8954 6 14V24C6 25.1046 6.89543 26 8 26H24C25.1046 26 26 25.1046 26 24V14C26 12.8954 25.1046 12 24 12H22V9C22 7.27275 21.4199 5.75567 20.3321 4.66789C19.2443 3.58011 17.7272 3 16 3C14.2728 3 12.7557 3.58011 11.6679 4.66789C10.5801 5.75567 10 7.27275 10 9ZM13.0821 6.08211C12.4199 6.74433 12 7.72725 12 9V12H20V9C20 7.72725 19.5801 6.74433 18.9179 6.08211C18.2557 5.41989 17.2728 5 16 5C14.7272 5 13.7443 5.41989 13.0821 6.08211ZM24 24V14H8L8 24H24Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Lock"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 12V9C10 7.27275 10.5801 5.75567 11.6679 4.66789C12.7557 3.58011 14.2728 3 16 3C17.7272 3 19.2443 3.58011 20.3321 4.66789C21.4199 5.75567 22 7.27275 22 9V12H24C25.1046 12 26 12.8954 26 14V24C26 25.1046 25.1046 26 24 26H8C6.89543 26 6 25.1046 6 24V14C6 12.8954 6.89543 12 8 12H10ZM12 9C12 7.72725 12.4199 6.74433 13.0821 6.08211C13.7443 5.41989 14.7272 5 16 5C17.2728 5 18.2557 5.41989 18.9179 6.08211C19.5801 6.74433 20 7.72725 20 9V12H12V9ZM16 16C16.5523 16 17 16.4477 17 17V21C17 21.5523 16.5523 22 16 22C15.4477 22 15 21.5523 15 21V17C15 16.4477 15.4477 16 16 16Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Lock"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM15.5 14.75V17H14C13.1716 17 12.5 17.6716 12.5 18.5V26C12.5 26.8284 13.1716 27.5 14 27.5H26C26.8284 27.5 27.5 26.8284 27.5 26V18.5C27.5 17.6716 26.8284 17 26 17H24.5V14.75C24.5 13.4546 24.0649 12.3168 23.2491 11.5009C22.4332 10.6851 21.2954 10.25 20 10.25C18.7046 10.25 17.5668 10.6851 16.7509 11.5009C15.9351 12.3168 15.5 13.4546 15.5 14.75ZM17.8116 12.5616C17.3149 13.0582 17 13.7954 17 14.75V17H23V14.75C23 13.7954 22.6851 13.0582 22.1884 12.5616C21.6918 12.0649 20.9546 11.75 20 11.75C19.0454 11.75 18.3082 12.0649 17.8116 12.5616ZM20.75 20.75C20.75 20.3358 20.4142 20 20 20C19.5858 20 19.25 20.3358 19.25 20.75V23.75C19.25 24.1642 19.5858 24.5 20 24.5C20.4142 24.5 20.75 24.1642 20.75 23.75V20.75Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

LockIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

LockIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
