import React, { useState } from "react";
import TimeSlotPicker from "./TimeSlotPicker";
import Button from "../../../UI/Button";
import WorkflowHeader from "./WorkflowHeader";

const ScheduleIntakeConsultation = ({
    consultationToConfirm,
    setConsultationToConfirm,
    alreadyScheduled,
    setAlreadyScheduled,
    schedulingData,
    appToDisplay,
    setAppToDisplay,
    setSchedulingData,
    addToInAppHistory,
    withinAppHistory,
    setWithinAppHistory,
    goBackInAppHistory,
    workflowInstance,
}) => {
    const [dayIndex, setDayIndex] = useState(0);
    const [selectedAppointment, setSelectedAppointment] = useState(consultationToConfirm);

    return (
        <>
            <WorkflowHeader header={"Schedule your care consultation"} showImage={true} />
            <TimeSlotPicker
                schedulingData={schedulingData}
                dayIndex={dayIndex}
                setDayIndex={setDayIndex}
                setConsultationToConfirm={setConsultationToConfirm}
                setAppToDisplay={setAppToDisplay}
                selectedAppointment={selectedAppointment}
                setSelectedAppointment={setSelectedAppointment}
                setSchedulingData={setSchedulingData}
                workflowInstance={workflowInstance}
            />

            <div className="workflow-container-sm d-flex justify-content-between">
                <Button
                    type={"button"}
                    className={"Btn Btn--otl-gry Btn--sm-2 workflow-btn"}
                    onClick={() => {
                        goBackInAppHistory(withinAppHistory, setWithinAppHistory);
                    }}>
                    Back
                </Button>
                <Button
                    disabled={!selectedAppointment}
                    type={"button"}
                    className={"Btn Btn--pri Btn--sm-2 workflow-btn"}
                    onClick={() => {
                        setConsultationToConfirm(selectedAppointment);
                        addToInAppHistory(
                            appToDisplay,
                            setAppToDisplay,
                            alreadyScheduled,
                            setAlreadyScheduled,
                            selectedAppointment,
                            setConsultationToConfirm,
                            withinAppHistory,
                            setWithinAppHistory,
                        );

                        setSelectedAppointment(null);
                        setAppToDisplay("confirmConsultation");
                    }}>
                    Continue
                </Button>
            </div>
        </>
    );
};

export default ScheduleIntakeConsultation;
