import {
    List,
    ListBody,
    ListHeader,
    ListItem,
    ListTitleBar,
    TitleBarItem,
} from "../../../UI/ListView";
import ListLoader from "../../../Common/Loaders/ListLoader";
import React from "react";
import images from "../../../../utils/images";
import withRouter from "react-router-dom/es/withRouter";

const MemberProfileDischargeDataList = (props) => {
    const { dischargeDataList } = props;

    const viewDischargeDataItem = (dischargeDataItemId) =>
        props.history.push(
            `/app/patient/${props.match.params.patientId}/discharge/${dischargeDataItemId}`,
        );

    const desktopListItem = (dischargeDataItem) => (
        <div className="row no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between">
            <span className="col-3">{dischargeDataItem.content.discharge_date}</span>
            <span className="col-3">{dischargeDataItem.content.discharge_type}</span>
            <span className="col-5" />
            <div className="col-1 d-flex d-flex justify-content-center">
                <div className="cursor ml-5">
                    <img
                        onClick={() => viewDischargeDataItem(dischargeDataItem.id)}
                        src={images("./common/view.png")}
                        alt="view icon"
                    />
                </div>
            </div>
        </div>
    );

    const mobileListItem = (dischargeDataItem) => (
        <div className="d-flex d-lg-none justify-content-between">
            <div className="d-flex fs-14 flex-column" style={{ wordWrap: "break-word" }}>
                <span className="fs-16 fw-bold">{dischargeDataItem.content.discharge_date}</span>
                <span>{dischargeDataItem.content.discharge_type}</span>
            </div>
        </div>
    );

    return (
        <>
            <ListBody classes="List__body_lg discharge-list mt-4">
                <ListHeader title={"Discharge Data"} />
                <ListTitleBar>
                    <TitleBarItem classes="col-3" text="Date" />
                    <TitleBarItem classes="col-3" text="Type" />
                    <TitleBarItem classes="col-5" text="" />
                    <TitleBarItem classes="col-1" text="" />
                </ListTitleBar>
                <List>
                    {dischargeDataList === null && <ListLoader />}
                    {dischargeDataList !== null &&
                        dischargeDataList.map((dischargeDataItem, i) => {
                            return (
                                <ListItem
                                    key={"dischargeData" + i}
                                    clickOnMobile
                                    clickHandler={() =>
                                        viewDischargeDataItem(dischargeDataItem.id)
                                    }>
                                    {mobileListItem(dischargeDataItem)}
                                    {desktopListItem(dischargeDataItem)}
                                </ListItem>
                            );
                        })}
                </List>
            </ListBody>
        </>
    );
};

export default withRouter(MemberProfileDischargeDataList);
