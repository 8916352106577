import React, { useEffect, useMemo, useRef } from "react";
import { CalendarContainer } from "./CalendarContainer/CalendarContainer";
import moment from "moment";
import PropTypes from "prop-types";
import { scheduleToEvents } from "../../utils/calendar/event";
import { calculateEventPositions } from "../../utils/calendar/positions";
import { EventGroup } from "./CalendarEvents";
import { useCalendarDimensions } from "../../utils/hooks/useCalendarDimensions";
import ScheduleNav from "./ScheduleNav/ScheduleNav";
import CalendarDrawer from "./CalendarDrawer/CalendarDrawer";
import {
    closeDrawer,
    isOnBehalfOfProviderPatientScheduling,
    resetAppointmentSelections,
    resetCalendarFilters,
    resetSchedulingMode,
    updateCalendarView,
    updateSelectedDate,
    updateTimezone,
} from "../../redux/actions/calendar";
import { connect, useDispatch } from "react-redux";
import { useCalendarDrawerDirection } from "../../utils/hooks/useCalendarDrawerDirection";
import { getLocalTimezone } from "../../redux/reducers/authReducer";
import { createClassNameString } from "../../component-library";

const Calendar = ({
    workingHours,
    schedule,
    unconfirmedEvent,
    daysToDisplay,
    patientDropdownOptions,
    ...props
}) => {
    const calendarBackgroundRef = useRef();
    const stickyHeaderRef = useRef();
    const { calendarRect } = useCalendarDimensions(calendarBackgroundRef, stickyHeaderRef);
    const dispatch = useDispatch();
    const isSchedulingOnBehalfOf = dispatch(isOnBehalfOfProviderPatientScheduling());
    // this class name is used to manually adjust the calendar components
    // to account for the header as the calendar becomes sticky
    // the amount of adjustment needed is dependent on the scheduling mode
    const className = isSchedulingOnBehalfOf ? "SchedulingBehalfOf" : "CalendarInPage";

    useEffect(() => {
        let prevTz = props.calendar.timezone;
        const checkTzChange = () => {
            const currentTz = getLocalTimezone();
            if (currentTz !== prevTz) {
                props.updateTimezone(currentTz);
            }
        };
        const interval = setInterval(checkTzChange, 5000);
        return () => {
            clearInterval(interval);
        };
    }, [props.calendar.timezone]);

    useEffect(() => {
        // when the calendar unmounts, the appointment drawer should be closed
        // and the appointment selection should be reset to avoid stale state
        return () => {
            const selectedDate = moment();
            dispatch(updateCalendarView("DAY"));
            dispatch(updateSelectedDate(selectedDate));
            dispatch(resetSchedulingMode());
            dispatch(resetCalendarFilters());
            dispatch(resetAppointmentSelections());
            dispatch(closeDrawer());
        };
    }, []);

    const createEventGroups = (scheduledEvents) => {
        const events = scheduleToEvents(scheduledEvents, props.calendar.timezone, daysToDisplay);
        return calculateEventPositions(events, daysToDisplay, calendarRect);
    };

    const calendarEvents = useMemo(() => {
        const eventGroups = createEventGroups(schedule);

        // Created separately so it can overlap over event groups
        if (unconfirmedEvent) {
            eventGroups.push(...createEventGroups([unconfirmedEvent]));
        }

        const eventGroupComponents = eventGroups.map((eventGroup) => {
            return <EventGroup eventGroup={eventGroup} />;
        });

        return eventGroupComponents;
    }, [schedule, calendarRect, daysToDisplay, props.calendar.timezone]);

    const direction = useCalendarDrawerDirection();
    let openClass = props.calendar.openDrawer ? "open" : "closed";
    const drawerComponent = (
        <CalendarDrawer
            className={`apti-CalendarAppointmentPickerDrawer-${openClass} ${className}`}
            direction={direction}
            calendarRect={calendarRect}
        />
    );

    return (
        <div className={"Calendar"}>
            <ScheduleNav className={className} />
            <div
                className={createClassNameString([
                    "CalendarWrapper",
                    // the right border of the calendar
                    // is usually added by the day slots, but
                    // when the calendar drawer is open we must
                    // add a border here because it cannot be added to
                    // the calendar drawer since it is sticky and
                    // and therefore it cannot always fill the entire
                    // and have a border around it
                    props.calendar.openDrawer ? "CalendarWrapper--add_border" : null,
                ])}>
                <CalendarContainer
                    ref={{ calendarBackgroundRef, stickyHeaderRef }}
                    className={className}
                    workingHours={workingHours}
                    daysToDisplay={props.calendar.selectedScheduleDates}>
                    {calendarEvents}
                </CalendarContainer>
                {direction === "right" && (
                    <div
                        className={`sticky-drawer-container apti-CalendarAppointmentPickerDrawer-${openClass} ${className}`}>
                        {drawerComponent}
                    </div>
                )}
            </div>
            {direction === "bottom" && drawerComponent}
        </div>
    );
};

Calendar.propTypes = {
    workingHours: PropTypes.object,
    schedule: PropTypes.arrayOf(PropTypes.object),
    unconfirmedEvent: PropTypes.object,
    daysToDisplay: PropTypes.arrayOf(PropTypes.instanceOf(moment)),
};

Calendar.defaultProps = {
    schedule: [],
    unconfirmedEvent: null,
    daysToDisplay: [moment().local()],
};

const mapStateToProps = (state) => {
    return {
        calendar: state.calendar,
    };
};

const mapDispatchToProps = {
    updateTimezone,
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
