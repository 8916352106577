// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import PropTypes from "prop-types";

/**
 * If this component is iterated over some array then pass the unique `id` prop
 * `id` must be somethong that uniquely identifies the SCQBox component on a single view.
 * default value of `id` is 0
 *
 * @param {Object} props
 */

const SCQBox = (props) => {
    const {
        questionStatement,
        optionConf,
        onOptionSelect,
        OptionsArr,
        formConf, //used if this component is wrapped with `<form>` element with submit input
        id = 0,
        readOnly,
        answer,
    } = props;

    const inputChangeHandler = (e) => {
        let value = e.target.value;
        onOptionSelect(value, id);
    };

    const renderWidget = (value, caption, checked) => {
        return (
            <div className="SCQBox__widget-wpr col-6 col-lg" key={value}>
                <label className="SCQBox__label m-0">
                    {checked && (
                        <input
                            type="radio"
                            value={value}
                            onChange={inputChangeHandler}
                            name={formConf ? formConf.name : "SCQBox" + id}
                            checked
                            disabled={readOnly}
                        />
                    )}
                    {!checked && (
                        <input
                            type="radio"
                            value={value}
                            onChange={inputChangeHandler}
                            name={formConf ? formConf.name : "SCQBox" + id}
                            disabled={readOnly}
                        />
                    )}
                    <span className="SCQBox__mask" />
                    <span className="SCQBox__value">{value}</span>
                    <span className="SCQBox__caption fs-12 fs-lg-14">{caption}</span>
                </label>
            </div>
        );
    };

    return (
        <div className="SCQBox mb-4 mb-lg-5 pb-lg-3">
            <div>
                <p className="fs-16 fw-bold">{id + ". " + questionStatement}</p>
            </div>
            <div className="SCQBox__inner row no-gutters">
                {OptionsArr.map((choice) => {
                    let checked = false;
                    if (answer !== undefined && answer !== null) {
                        checked = choice === Number(answer);
                    }
                    return renderWidget(
                        optionConf[choice].value,
                        optionConf[choice].caption,
                        checked,
                    );
                })}
            </div>
        </div>
    );
};

SCQBox.propTypes = {
    questionStatement: PropTypes.string.isRequired,
    optionConf: PropTypes.array.isRequired,
    onOptionSelect: PropTypes.func.isRequired,
    formConf: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SCQBox.defaultProps = {
    id: 0,
};

export default SCQBox;
