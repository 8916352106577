// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { providerRoute } from "../../../../config/roles";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { api } from "../../../../APIRequests";
import { Link, withRouter } from "react-router-dom";
import images from "../../../../utils/images";
import { CardPrimary } from "../../../../components/UI/Card";
import ProgressSlider from "../../../../components/UI/ProgressSlider";
import { ACTIVE, DEFAULT, DONE } from "../../../../components/Patient/NinetyDayCareProgressGuide";
import { getQueryParams } from "../../../../utils/filters";
import ClinicianNotes from "./ClinitianNotes";
import ClinicianRating from "./ClinicianRating";
import MentalStatusQuestions from "./MentalStatusQuestions";
import ClinicianDignosis from "./ClinicianDiagnosis";
import Hr from "../../../../components/UI/Hr";

// clinical assessment types to be fetched form query params of url
export const INITIAL = "initial";
export const OUTCOME = "outcome";

export const convertStatus = (status) => status.toLowerCase().split(" ").join("_");

const transformMarkerData = (status, statusArr, markerData) => {
    let data = [...markerData];
    switch (status) {
        case statusArr[0]:
            data[0].status = ACTIVE;
            data[1].status = DEFAULT;
            data[2].status = DEFAULT;
            data[3].status = DEFAULT;
            break;
        case statusArr[1]:
            data[0].status = DONE;
            data[1].status = ACTIVE;
            data[2].status = DEFAULT;
            data[3].status = DEFAULT;
            break;
        case statusArr[2]:
            data[0].status = DONE;
            data[1].status = DONE;
            data[2].status = ACTIVE;
            data[3].status = DEFAULT;
            break;
        case statusArr[3]:
            data[0].status = DONE;
            data[1].status = DONE;
            data[2].status = DONE;
            data[3].status = ACTIVE;

            break;
        default:
    }
    return data;
};

class ClinicalAssessment extends Component {
    constructor(props) {
        super(props);
        this.type = getQueryParams("type");
        this.statusArr = [
            "Clinician Notes",
            "Clinician Rating",
            "Mental Status Questions",
            "Clinician Diagnosis",
        ];
        this.state = {
            status: null,
            assessmentData: null,
            markerData: [
                { status: "default", caption: this.statusArr[0] },
                { status: "default", caption: this.statusArr[1] },
                { status: "default", caption: this.statusArr[2] },
                { status: "default", caption: this.statusArr[3] },
            ],
            done: false,
            patientId: this.props.match.params.patientId,
            patientName: "",
        };
    }

    setStateAfterComponentDidMount = (response, done, status, markerData, patientName) => {
        this.setState({
            ...this.makePersistentData(response),
            assessmentData: { ...response },
            status,
            markerData,
            done,
            patientName,
            cp_credentials: this.props?.profile?.cp_credentials,
            cp_name: this.props?.profile?.name,
        });
    };

    makePersistentData = (response) => {
        const clinician_notes =
            response && response.clinician_notes ? { ...response.clinician_notes } : {};
        const clinician_rating =
            response && response.clinician_rating ? { ...response.clinician_rating } : {};
        const mental_status_questions =
            response && response.mental_status_questions
                ? [...response.mental_status_questions]
                : [];
        const clinician_diagnosis =
            response && response.clinician_diagnosis ? { ...response.clinician_diagnosis } : {};

        return {
            clinician_notes,
            clinician_rating,
            mental_status_questions,
            clinician_diagnosis,
        };
    };

    async componentDidMount() {
        const urlParams = { patientId: this.state.patientId };
        const queryParams = { type: this.type || "initial" };
        const response = await api.shared.fetch_patient_clinical_assessment({
            urlParams,
            queryParams,
        });
        const content = response ? response.content : {};
        let props = this.setFormProps(response, content);
        let marker = transformMarkerData(props.status, this.statusArr, this.state.markerData);
        const patientResponse = await api.shared.fetch_patient_details({
            urlParams: { patientId: this.state.patientId },
        });
        const patientName = patientResponse.user.first_name + " " + patientResponse.user.last_name;
        this.setStateAfterComponentDidMount(content, props.done, props.status, marker, patientName);
    }

    callSubmitApi = (data, nextStatus, actions) => {
        this.setState({
            ...this.makePersistentData(data),
            assessmentData: data,
        });

        const urlParams = { patientId: this.state.patientId };
        const browserTimezone = moment.tz.guess();
        const queryParams = {
            type: this.type || INITIAL,
            isComplete: this.state.status === "Clinician Diagnosis",
            browserTimezone,
        };

        api.provider
            .put_patient_clinical_assessment({ data, urlParams, queryParams })
            .then((response) => {
                if (actions && actions.setSubmitting) {
                    actions.setSubmitting(false);
                }
                if (!queryParams.isComplete) {
                    this.goToStatus(nextStatus, 3);
                } else {
                    this.props.history.push(
                        `/app/patient/${this.props.match.params.patientId}/profile/v2?selectedTab=Timeline`,
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    submitHandler = (formData, actions) => {
        actions.setSubmitting(false);
        let nextStatus = this.statusArr[this.statusArr.indexOf(this.state.status) + 1];
        if (!this.state.done) {
            let data = {
                ...formData,
                cp_credentials: this.props?.profile?.cp_credentials,
                cp_name: this.props?.profile?.name,
            };
            this.callSubmitApi(data, nextStatus, actions);
        } else {
            this.goToStatus(nextStatus, 3);
        }
    };

    severitySubmitHandler = (e) => {
        e.preventDefault();
        let nextStatus = this.statusArr[this.statusArr.indexOf(this.state.status) + 1];
        if (!this.state.done) {
            let formData = new FormData(e.currentTarget);
            let entries = formData.entries();
            const rating_type = convertStatus(this.statusArr[1]); // 'Outcome Clinician Rating' or 'Clinician Rating'
            let rating_data = {};
            for (let entry of entries) {
                rating_data[entry[0]] = entry[1];
            }
            const data = {
                ...this.state.assessmentData,
                [rating_type]: rating_data,
            };
            this.callSubmitApi(data, nextStatus, null);
        } else {
            this.goToStatus(nextStatus, 3);
        }
    };

    setFormProps(response, content) {
        let props = {
            status: this.statusArr[0],
            done: false,
        };
        if (response) {
            if (response.hasOwnProperty("completed_date_time")) {
                props.done = true;
            } else if (content.hasOwnProperty(convertStatus(this.statusArr[2]))) {
                props.status = this.statusArr[3];
            } else if (content.hasOwnProperty(convertStatus(this.statusArr[1]))) {
                props.status = this.statusArr[2];
            } else if (content.hasOwnProperty(convertStatus(this.statusArr[0]))) {
                props.status = this.statusArr[1];
            }
        }
        return props;
    }

    goToStatus(status, limit) {
        if (this.statusArr[limit] !== this.state.status) {
            let marker = transformMarkerData(status, this.statusArr, this.state.markerData);
            this.setState({
                status: status,
                markerData: marker,
            });
        } else {
            this.props.history.go(-2);
        }
    }

    goBack = () => {
        let prevStatus = this.statusArr[this.statusArr.indexOf(this.state.status) - 1];
        this.goToStatus(prevStatus, 0);
    };

    render() {
        return (
            <div className="ClinicalAssessment container">
                <div className="survey-back-link-wpr mx-auto fs-16 my-3 py-2">
                    <Link className="survey-back-link" onClick={() => this.props.history.go(-2)}>
                        <img className="img-fluid" src={images("./common/solidBckBtn.svg")} />
                        <p className="d-inline ml-2">Back to Patient Profile</p>
                    </Link>
                </div>
                <div className=" mx-auto my-5" style={{ paddingBottom: 30 }}>
                    {this.state.status && <ProgressSlider markerData={this.state.markerData} />}
                </div>
                <CardPrimary
                    className="ClinicalAssessment__card mx-auto"
                    styles={{ marginTop: "81px", boxShadow: "0 4px 14px 0 rgba(0, 0, 0, 0.09)" }}>
                    {this.state.status && (
                        <div>
                            <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-between">
                                <div className="fs-18 mx-3 my-0 mb-1 mb-lg-0">
                                    <span>
                                        <strong>{this.state.status}</strong>
                                    </span>
                                </div>
                                <div className="fs-18 mx-3 my-0 mb-2 mb-lg-0">
                                    <span>
                                        <strong>
                                            Patient:{" "}
                                            <span style={{ color: "#5b57dc" }}>
                                                {this.state.patientName}
                                            </span>
                                        </strong>
                                    </span>
                                </div>
                            </div>
                            <div className="my-3 mx-3 mx-lg-0">
                                <Hr />
                            </div>
                            <div>
                                {this.state.status === this.statusArr[0] && (
                                    <ClinicianNotes
                                        {...this.props}
                                        type={this.type}
                                        statusArr={this.statusArr}
                                        submitHandler={this.submitHandler}
                                        vals={this.state.clinician_notes}
                                        done={this.state.done}
                                        goBack={this.goBack}
                                        data={{ ...this.state.assessmentData }}
                                    />
                                )}

                                {this.state.status === this.statusArr[1] && (
                                    <ClinicianRating
                                        {...this.props}
                                        statusArr={this.statusArr}
                                        vals={this.state.clinician_rating}
                                        done={this.state.done}
                                        goBack={this.goBack}
                                        submitHandler={this.severitySubmitHandler}
                                    />
                                )}
                                {this.state.status === this.statusArr[2] && (
                                    <MentalStatusQuestions
                                        {...this.props}
                                        statusArr={this.statusArr}
                                        submitHandler={this.submitHandler}
                                        vals={this.state.mental_status_questions}
                                        done={this.state.done}
                                        goBack={this.goBack}
                                        data={{ ...this.state.assessmentData }}
                                    />
                                )}
                                {this.state.status === this.statusArr[3] && (
                                    <ClinicianDignosis
                                        {...this.props}
                                        type={this.type}
                                        statusArr={this.statusArr}
                                        submitHandler={this.submitHandler}
                                        vals={this.state.clinician_diagnosis}
                                        done={this.state.done}
                                        goBack={this.goBack}
                                        data={{ ...this.state.assessmentData }}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </CardPrimary>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};
export default connect(mapStateToProps)(withRouter(ClinicalAssessment));
