// Copyright aptihealth, inc. 2019 All Rights Reserved
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import $ from "jquery/dist/jquery.slim.min.js";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import "popper.js/dist/popper.min";
import "./assets/sass/main.scss";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ErrorBoundaries } from "./containers/ErrorBoundaries";
import LogRocket from "logrocket";

const app = (
    <ErrorBoundaries>
        <Provider store={store}>
            <App />
        </Provider>
    </ErrorBoundaries>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

function sanitize(obj, allowedFields) {
    try {
        if (Array.isArray(obj)) {
            // Handle array type
            return obj.map((element) => sanitize(element, allowedFields));
        } else if (typeof obj === "object" && obj !== null) {
            // Handle object type
            let result = {};
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (allowedFields.includes(key)) {
                        // If it's an allowed field and its value is an object or array,
                        // continue the sanitization process on that value.
                        result[key] = sanitize(obj[key], allowedFields);
                    } else {
                        result[key] = "***";
                    }
                }
            }
            return result;
        } else if (typeof obj === "string" && obj && obj.includes("{") && obj.includes("}")) {
            try {
                return sanitize(JSON.parse(obj), allowedFields);
            } catch (e) {
                return obj;
            }
        } else {
            // Handle base case
            return obj;
        }
    } catch (e) {
        return obj;
    }
}

const ALLOWED_HEADER_FIELDS = ["Accept", "content-type"];

const ALLOWED_BODY_FIELDS = [
    // commonly used top level field
    "data",

    // error response fields
    "error",
    "code",
    "request_id",

    // patient profile fields
    "user",
    "care_team_members",
    "days_until_reset",
    "assessment_status",
    "schedule_video_call",
    "initial_video_consult",
    "initial_prescriber_call",
    "assessment_started_date",
    "assessment_completed_date",
    "schedule_video_call_date_time",
    "initial_video_consult_date_time",
    "initial_prescriber_call_date_time",
    "registration_device_type",
    "interested_in_guardianship",
    "referrer_domain",
    "referral_source",
    "group_type",
    "ninety_day_care",
    "referred_date",
    "registration_date",
    "assessment_config",
    "default_assessment",
    "automatic_payment_enabled",
    "has_copay",
    "has_active_card",
    "eligibility_transaction_accepted",
    "primary_insurance",
    "carrier",
    "pcp_id",
    "pcp_practice_id",
    "referral_id",
    "preferences",
    "time_zone",
    "is_sms_enabled",
    "email_subscriptions",
    "account_updates",
    "session_updates",
    "marketing_communications",

    // common ids
    "user_id",
    "patient_id",
    "provider_id",
    "patient_username",
    "provider_username",
    "username",
    "entity_id",

    // audit fields
    "created_by",
    "created_date",
    "updated_by",
    "updated_date",

    // calendar event fields
    "call_id",
    "callId",
    "timestamp",
    "event_type",
    "allotted_time",
    "time_slots",
    "provider_name",
    "date",
    "time",
    "provider_type",
    "timezone",

    // common status/type fields
    "type",
    "status",

    // workflow engine
    "workflow_name",
    "workflow_config_entity_id",
    "workflow_instance_entity_id",
    "workflow_instance_sort_key",
    "node_name",
];

if (process.env.REACT_APP_ENABLE_LOG_ROCKET === "true") {
    LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID, {
        dom: {
            inputSanitizer: true,
            textSanitizer: true,
        },
        network: {
            requestSanitizer: (request) => {
                request.headers = sanitize(request.headers, ALLOWED_HEADER_FIELDS);
                request.body = sanitize(request.body, ALLOWED_BODY_FIELDS);
                return request;
            },
            responseSanitizer: (response) => {
                response.headers = sanitize(response.headers, ALLOWED_HEADER_FIELDS);
                response.body = sanitize(response.body, ALLOWED_BODY_FIELDS);
                return response;
            },
        },
        rootHostname: "aptihealth.com",
    });
}
