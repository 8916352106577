import React from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/es/withRouter";

import { api } from "../../../../APIRequests";
import { PSSProviderNoShow } from "../../../../component-library";

import "../styles.scss";

const ProviderMessageStep = (props) => {
    const { callDetails, profile } = props;

    const { callId, patient_username, provider_username } = callDetails;

    const { pcp_practice_id } = profile;

    if (!callDetails) {
        return <></>;
    }

    let recordedMetric = {
        pk: "provider_no_show",
        entity_id: callId,
        entity_type: "call",
        segments: [
            {
                segment_id: patient_username,
                segment_type: "patient",
            },
            {
                segment_id: provider_username,
                segment_type: "provider",
            },
            {
                segment_id: pcp_practice_id,
                segment_type: "practice",
            },
        ],
    };

    const onClickConfirm = () => {
        recordedMetric.rank = "message_provider";
        api.metrics.create_metric(recordedMetric);
        props.history.push(`/app/messages?memberId=${callDetails.provider_username}`);
    };

    const onClickCancel = () => {
        recordedMetric.rank = "no_action";
        api.metrics.create_metric(recordedMetric);
        props.history.push("/app/home");
    };

    return (
        <div className={"d-flex justify-content-center align-items-center my-4"}>
            <PSSProviderNoShow
                className="ProviderMessageStep"
                onClickConfirm={onClickConfirm}
                onClickCancel={onClickCancel}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};
export default connect(mapStateToProps)(withRouter(ProviderMessageStep));
