// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as actionTypes from "./actionTypes";
import { api } from "../../APIRequests";
import { showAlert } from "./alerts";
import { showLoader, hideLoader } from "./loader";
import { getGroupAuthType } from "./auth";
import { httpAuth } from "../../utils/axios";
import moment from "moment";

export const addNewPatient = (formData, cb) => (dispatch) => {
    api.provider
        .create_new_patient({ data: formData })
        .then((cleanResponse) => {
            cb(cleanResponse);
        })
        .catch((err) => {
            cb(false);
            showAlert(err.message, dispatch);
        });
};

export const updateProviderScheduleCallList = (data) => {
    return {
        type: actionTypes.PROVIDER_UPDATE_SCHEDULE_CALL_LIST,
        data,
    };
};

// ----------- Thunks --------------

export const fetchProviderScheduleCallList =
    ({ queryParams } = {}) =>
    (dispatch) => {
        const options = {
            params: { showLoader },
            queryParams: {
                local_start_date_time: queryParams.start_date,
                local_end_date_time: queryParams.end_date,
                timezone: moment.tz.guess(),
                show_deleted: true,
                show_unjoinable: queryParams.show_deleted,
            },
        };

        if (queryParams.provider_id) {
            options.queryParams.provider_id = queryParams.provider_id;
        }

        return api.schedule.get_schedule({ options }).then((data) => {
            dispatch(
                updateProviderScheduleCallList({
                    providerId: queryParams.provider_id,
                    scheduleCallList: {
                        startDate: queryParams.start_date,
                        endDate: queryParams.end_date,
                        showDeleted: queryParams.show_deleted,
                        callList: data.schedule,
                        awayList: data.away,
                    },
                }),
            );
        });
    };
