import { Redirect, Route, Switch } from "react-router-dom";
import Home from "../Home/Home";
import AccountSetup from "../../components/Common/Workflow/AccountSetup";
import Authentication from "../Authentication";
import privacyPolicy from "../../components/Common/PrivacyPolicy";
import A5Screening from "../../components/Screening/A5Screening";
import Unsubscribe from "../../components/Common/Unsubscribe";
import Workflow from "../../components/Common/Workflow";
import React from "react";
import Dashboard from "../Dashboard";
import RedirectSplashScreen from "../redirectSplashScreen";
import PricingPage from "../PublicPages/PricingPage";
import AuthChallenge from "../../components/Authentication/AuthChallenge";
import HCPWorkflow from "../../components/Common/Workflow/HCPWorkflow";
import EmailVerification from "../../components/Common/EmailVerfication";
import MobileAddPayment from "../../components/Common/MobileAddPayment";

export const STANDARD_UNAUTHENTICATED_ROUTES = (allowRedirect, props) => (
    <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/account-setup" component={AccountSetup} />
        <Route path="/auth" component={Authentication} />
        <Route path="/privacy-policy" component={privacyPolicy} />
        <Route path="/session-pricing" component={PricingPage} />
        <Route path="/a5-screening" component={A5Screening} />
        <Route path="/unsubscribe" component={Unsubscribe} />
        <Route path="/email-verification" component={EmailVerification} />
        <Route path="/app/workflow" component={Workflow} />
        <Route path="/app/mobile-add-payment" component={MobileAddPayment} />
        <Route
            path={"/redirect"}
            component={(compProps) => (
                <RedirectSplashScreen {...compProps} redirectPath={props.auth.authRedirectPath} />
            )}
        />
        <Route path="/app/hcp-workflow" component={HCPWorkflow} />
        {allowRedirect && <Redirect to="/auth" />}
    </Switch>
);

export const STANDARD_AUTHENTICATED_ROUTES = (allowRedirect, props) => (
    <Switch>
        <Route
            path={props.auth.authRedirectPath}
            render={(compProps) => <Dashboard {...compProps} role={props.auth.role} />}
        />
        <Route
            path={"/redirect"}
            component={(compProps) => (
                <RedirectSplashScreen {...compProps} redirectPath={props.auth.authRedirectPath} />
            )}
        />
        <Route path="/privacy-policy" component={privacyPolicy} />
        <Route path="/session-pricing" component={PricingPage} />
        <Route path="/unsubscribe" component={Unsubscribe} />
        <Redirect from="/auth" to={props.auth.authRedirectPath} />
        <Redirect to={"/redirect"} />
    </Switch>
);

export const STANDARD_CHALLENGE_ROUTES = (allowRedirect, props) => (
    <Switch>
        <Route path="/auth/challenge" component={AuthChallenge} />
        {allowRedirect && <Redirect to={props.auth.authRedirectPath} />}
    </Switch>
);

export const processRouteInitData = (allowRedirect, props) => {
    if (props.auth.role) {
        return STANDARD_AUTHENTICATED_ROUTES(allowRedirect, props);
    }

    if (props.auth.challengeName) {
        return STANDARD_CHALLENGE_ROUTES(allowRedirect, props);
    }

    return STANDARD_UNAUTHENTICATED_ROUTES(allowRedirect, props);
};
