// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";

const SquashedValues = (props) => {
    const { valueList, classList } = props;
    return (
        <span className="SquashedValues">
            {valueList
                .filter((value) => value)
                .map((value) => {
                    return <span key={value}>{value}</span>;
                })}
        </span>
    );
};

export default SquashedValues;
