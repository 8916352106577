import React from "react";
import { Popup } from "../../Popup";
import { Link } from "../../Link";
import { Image } from "../../Image";
import PropTypes from "prop-types";
import "./styles.scss";
import {
    OUT_OF_NETWORK_INSURANCE_OPTION,
    UNINSURED_OPTION,
} from "../../../constants/insurnaceIconMap";
import { trackEvent } from "../../../utils/EventTrackingUtil";

const supportPhoneNumberLink = (
    <a className={"support-link"} href={"tel:8884543827"}>
        (888) 454-3827
    </a>
);
const supportEmail = "support@aptihealth.com";
const supportEmailLink = (
    <a className={"support-link"} href={`mailto: ${supportEmail}?subject=Web Support Request`}>
        {supportEmail}
    </a>
);
const footer = (
    <>
        If you have any questions please contact
        <br />
        {supportPhoneNumberLink} or {supportEmailLink}
    </>
);

const acknowledgementText = {
    [UNINSURED_OPTION]: {
        title: "Don’t have health insurance?",
        subtitle: (eventPrefix) => (
            <>
                You will be responsible for any associated fees. Learn about&nbsp;
                <Link
                    href="/session-pricing"
                    onClick={() => trackEvent(`${eventPrefix}-no-insurance-modal-click-rates`)}>
                    aptihealth's rates <Image src="open-in-tab.svg" />
                </Link>
            </>
        ),
    },
    [OUT_OF_NETWORK_INSURANCE_OPTION]: {
        title: "Can’t find the insurance you are looking for?",
        subtitle: (eventPrefix) => (
            <>
                The insurance company may be out-of-network. You will be responsible for any
                associated fees. Learn about&nbsp;
                <Link
                    href="/session-pricing"
                    onClick={() =>
                        trackEvent(`${eventPrefix}-insurance-not-listed-modal-click-rates`)
                    }>
                    aptihealth's rates <Image src="open-in-tab.svg" />
                </Link>
            </>
        ),
    },
};

export const InsuranceAcknowledgementPopupHOC = ({
    className,
    gaEventPrefix,
    acknowledgementType,
    primaryOnClick,
    secondaryOnClick,
    disabled,
    ...props
}) => {
    return (
        <Popup
            className={className}
            title={acknowledgementText[acknowledgementType].title}
            subtitle={acknowledgementText[acknowledgementType].subtitle(gaEventPrefix)}
            primaryText={"Acknowledge"}
            primaryOnClick={primaryOnClick}
            secondaryText={"Return to Insurance"}
            secondaryOnClick={secondaryOnClick}
            footer={footer}
            disabled={disabled}
            {...props}
        />
    );
};

InsuranceAcknowledgementPopupHOC.propTypes = {
    acknowledgementType: PropTypes.oneOf([UNINSURED_OPTION, OUT_OF_NETWORK_INSURANCE_OPTION])
        .isRequired,
    primaryOnClick: PropTypes.func.isRequired,
};
