/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const EyeIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Eye"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22 16C22 19.3137 19.3137 22 16 22C12.6863 22 10 19.3137 10 16C10 12.6863 12.6863 10 16 10C19.3137 10 22 12.6863 22 16ZM20 16C20 18.2091 18.2091 20 16 20C13.7909 20 12 18.2091 12 16C12 13.7909 13.7909 12 16 12C18.2091 12 20 13.7909 20 16Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 16.0007C9 4.00006 23 4.00015 30 16.0006C23 28.0001 9 28.0001 2 16.0007ZM16 23.0002C11.8497 23.0002 7.51463 20.7868 4.35316 16.0006C7.51467 11.2138 11.8498 9.00024 16 9.00025C20.1502 9.00025 24.4853 11.2138 27.6468 16.0006C24.4854 20.7868 20.1503 23.0002 16 23.0002Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Eye"
                {...props}>
                <path d="M22 16C22 19.3137 19.3137 22 16 22C12.6863 22 10 19.3137 10 16C10 12.6863 12.6863 10 16 10C19.3137 10 22 12.6863 22 16Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 16.0007C9 4.00006 23 4.00015 30 16.0006C23 28.0001 9 28.0001 2 16.0007ZM16 23.0002C11.8497 23.0002 7.51463 20.7868 4.35316 16.0006C7.51467 11.2138 11.8498 9.00024 16 9.00024C20.1502 9.00025 24.4853 11.2138 27.6468 16.0006C24.4854 20.7868 20.1503 23.0002 16 23.0002Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Eye"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.2649 20.0005C13.636 23.5902 16.8873 25.2502 20 25.2502C23.1127 25.2502 26.364 23.5902 28.7351 20.0005C26.364 16.4104 23.1127 14.7502 20 14.7502C16.8873 14.7502 13.636 16.4104 11.2649 20.0005ZM20 24.5001C22.4853 24.5001 24.5 22.4853 24.5 20.0001C24.5 17.5148 22.4853 15.5001 20 15.5001C17.5147 15.5001 15.5 17.5148 15.5 20.0001C15.5 22.4853 17.5147 24.5001 20 24.5001Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM9.5 20.0006C14.75 11.0001 25.25 11.0002 30.5 20.0005C25.25 29.0001 14.75 29.0001 9.5 20.0006Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

EyeIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

EyeIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
