import moment from "moment";
import { useState, useEffect } from "react";

/**
 *
 * @param {Object} args
 * @param {int} args.interval time in ms
 * @returns a string representation of the current time formatted in
 * YYYY-MM-DD HH:mm
 */
export const useCurrentUTCTime = ({ interval }) => {
    const [currentTime, setCurrentTimestamp] = useState(moment().utc().format("YYYY-MM-DD HH:mm"));

    useEffect(() => {
        const updateInterval = setInterval(() => {
            setCurrentTimestamp(moment().utc().format("YYYY-MM-DD HH:mm"));
        }, interval);

        return () => clearInterval(updateInterval);
    }, [interval]);

    return { currentTime };
};
