// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";

const CardSecondary = (props) => {
    let classes = "CardSeconday position-relative";
    if (props.className) {
        classes = classes + " " + props.className;
    }

    return (
        <div onClick={props.onClick || null} className={classes} style={props.style}>
            {props.children}
        </div>
    );
};

export default CardSecondary;
