// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import PropTypes from "prop-types";
import {isConversationReadOnly} from "../../../../utils/chatUtils";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

export const Channel = (props) => {
    const { channel, lastMessage, onSelectChannel, unreadMessageCount } = props;
    const { coverImage, otherMembers, url } = channel;
    const history = useHistory();
    const profile = useSelector(state => state.auth.profile);


    let nameOnClick, nameClass;
    if (profile?.provider_type) {
        const memberId = otherMembers?.length && otherMembers[0].userId;
        const memberProfileLink = `/app/patient/${memberId}/profile/v2`;
        nameOnClick = () => history.push(memberProfileLink);
        nameClass = "chat-member-name";
    }

    const formattedLastMessage = lastMessage || {
        message: "No messages sent yet!",
        formattedTime: "",
    };

    const channelOpacity = isConversationReadOnly(profile?.care_team_members, channel) ? 0.6 : 1.0;

    const userAvatar = <div>
        <img
            src={coverImage}
            alt="avatar"
            className="avatar"
        />
        {unreadMessageCount > 0 && (
            <div className="number-circle">
                {unreadMessageCount}
            </div>
        )}
    </div>;

    const channelText = <div className="card-text">
        <span className={nameClass} onClick={nameOnClick}>
            <strong>
                {otherMembers.map((user) => user.nickname).join()}
            </strong>
        </span>
        <p
            className={`fs-12 ${formattedLastMessage.message.trim() === "" ? "mb-4 pb-2" : ""}`}>
            {formattedLastMessage.message}
        </p>
    </div>;

    const lastMessageTimeText = <div className="fs-11 card-time mt-1 text-right">
        {formattedLastMessage.formattedTime}
    </div>;

    return <li key={url} style={{opacity: channelOpacity}}>
        <div
            className="card card-body card-grid"
            onClick={() => {
                onSelectChannel(channel);
            }}
            data-e2e="Messages__channel"
        >
            { userAvatar }
            { channelText }
            { lastMessageTimeText }
        </div>
    </li>;
};

Channel.propTypes = {
    channel: PropTypes.object.isRequired,
    lastMessage: PropTypes.object.isRequired,
    onSelectChannel: PropTypes.func.isRequired,
    unreadMessageCount: PropTypes.number.isRequired,
};
