import { Text } from "../../../component-library";
import React from "react";

export const WaitListModal = () => {

    const _emailClick = () => {
        window.open("mailto:support@aptihealth.com");
    };

    return (
        <div className="text-align-center mb-5 mx-5">
            <p className={"mb-3"}>
                <Text type="heading-1">You’re on the Waitlist</Text>
            </p>

            <p className={"pb-2"}>
                <Text type="paragraph" color="grey">
                    Hi there. We’re working on finding a provider that’s the best match for you. It
                    may take some time to assemble your care team, and we appreciate your patience.
                </Text>
            </p>
            <p className={"pb-2"}>
                <Text type="paragraph" color="grey">
                    If you have a medical or life-threatening emergency, please call 911, go to the
                    nearest emergency room, or contact the National Suicide Prevention Lifeline at
                    988.
                </Text>
            </p>
            <p>
                <Text type="paragraph" color="grey">
                    For questions about waitlist status, contact our team at&nbsp;
                </Text>
                <Text
                    className="patient-contact-link"
                    type="paragraph"
                    onClick={_emailClick}>
                    support@aptihealth.com
                </Text>
                <Text type="paragraph" color="grey">.</Text>
            </p>
        </div>
    );
};
