import _ from "lodash";
import moment from "moment/moment";
import { getAllottedTime } from "./util";

export const createDatesToDisplay = (selectedDate, viewType) => {
    const datesToDisplay = [];
    if (viewType === "WEEK") {
        let day = _.cloneDeep(selectedDate).startOf("WEEK");
        const endDate = _.cloneDeep(selectedDate).endOf("WEEK");

        while (day <= endDate) {
            const clonedDay = _.cloneDeep(day);
            datesToDisplay.push(clonedDay);
            day = day.add(1, "d");
        }
    } else {
        datesToDisplay.push(selectedDate);
    }

    return datesToDisplay;
};

/**
 *
 * @param {import("moment").Moment} startTime
 * @param {import("moment").Moment} endTime
 * @param {Number} minutes
 * @returns
 */
export const breakTimeIntoXMinuteIntervals = (startTime, endTime, minutes = 15) => {
    let times = [];
    // build up a list of conflicts
    let currentTime = startTime;
    while (currentTime < endTime) {
        times.push(currentTime.format("HH:mm"));
        currentTime.add(minutes, "minutes");
    }

    return times;
};

/**
 * create a list of times that are unavailable on a schedule
 * @param {Object[]} events
 * @param {import("moment").Moment} selectedDate
 * @param {(import("moment").Moment, import("moment").Moment) => *} transformer a function that transforms the resulting conflicts that occur on the same
 * day as the selected date
 * @returns
 */
export const getExistingConflicts = (
    events,
    selectedDate,
    transformer = breakTimeIntoXMinuteIntervals,
) => {
    const conflictsOnTheSameDay = events.filter((event) => {
        const eventTime = moment.utc(event["timestamp"], "YYYY-MM-DD HH:mm").local();
        // check if the event happens on the current day
        const isSameDay = eventTime.format("YYYY-MM-DD") === selectedDate.format("YYYY-MM-DD");
        // return events that are on the same day and are not deleted
        return isSameDay && !event.deleted;
    });

    // creates an array of arrays of conflict times in the format HH:MM
    const conflictArray = conflictsOnTheSameDay.map((event) => {
        const startTime = moment.utc(event["timestamp"], "YYYY-MM-DD HH:mm").local();
        const allottedTime = event["allotted_time"] ?? getAllottedTime(event["type"]);
        const endTime = moment
            .utc(event["timestamp"], "YYYY-MM-DD HH:mm")
            .local()
            .add(allottedTime, "minutes");

        return transformer(startTime, endTime);
    });

    return conflictArray;
};

/**
 * prevents scheduling an appointment that overlaps with
 * with an existing appointment
 * @param {string[][]} conflicts each list is the duration of an appointment spread
 * out into 15 minute intervals. Each time is in the format HH:MM. see getExistingConflicts
 * for more details
 * @param {*} allottedTimeForNewEvent the amount of time the event will last for
 * @returns a new array set of conflicts that are padded
 */
export const addConflictPadding = (conflicts, allottedTimeForNewEvent) => {
    const paddedConflicts = conflicts.map((conflict) => {
        // start time for the event is the first item in the array
        const startTime = moment(conflict[0], "HH:mm");
        // gets the lower bound for times where something can be scheduled
        // without overlap
        const noConflictLowerBound = moment(conflict[0], "HH:mm").subtract(
            allottedTimeForNewEvent - 15,
            "minutes",
        );
        // creates conflict slots for the time in between noConflictLowerBound
        // and the actual startTime
        const totalConflicts = breakTimeIntoXMinuteIntervals(noConflictLowerBound, startTime);
        conflict.push(...totalConflicts);

        return conflict;
    });

    return paddedConflicts;
};

export const transformFrequencyDaysForServer = (seriesData) => {
    if (seriesData && seriesData["frequencyDays"]) {
        return seriesData["frequencyDays"].map((day) => {
            if (day === 0) {
                return 6;
            }
            return day - 1;
        });
    }
};

/**
 * assumes same day
 * @param {import("moment").Moment} startTimeA
 * @param {import("moment").Moment} endTimeA
 * @param {import("moment").Moment} startTimeB
 * @param {import("moment").Moment} endTimeB
 */
export const hasOverlap = (startTimeA, endTimeA, startTimeB, endTimeB) => {
    const doesNotHaveOverlap =
        (startTimeB.isSameOrAfter(endTimeA) || endTimeB.isSameOrBefore(startTimeA)) &&
        !startTimeA.isSame(startTimeB) &&
        !endTimeA.isSame(endTimeB);
    return !doesNotHaveOverlap;
};

/**
 * When selecting a to time we want to allow selection up the the start of an event but not
 * at the end of an event, here we take each conflict and remove the start and add a 15 minute padding
 * @param {string[][]} conflicts each list is the duration of an appointment spread
 * out into 15 minute intervals. Each time is in the format HH:MM. see getExistingConflicts
 * for more details
 * @returns a new array set of conflicts that are padded
 */
export const addToTimePadding = (conflicts) => {
    return conflicts.map((conflict) => {
        conflict.shift();
        if (conflict && conflict.length) {
            let lastTime = conflict[conflict.length - 1];
            if (lastTime !== "24:00") {
                lastTime = moment(lastTime, "HH:mm").add(15, "minutes");
                conflict.push(lastTime.format("HH:mm"));
            }
        }
        return conflict;
    });
};

export const getDurationAsHours = (startTime, endTime) => {
    return moment.duration(moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"))).asHours();
};

export const isStartTimeBeforeEndTime = (startTime, endTime) => {
    return moment(startTime, "HH:mm").isBefore(moment(endTime, "HH:mm"));
};

export const getSlotsThatExtendToNextDay = (alloted_time) => {
    // Get the current date midnight
    const midnight = moment().add(1, "day").startOf("day");

    // Subtract 45 minutes from midnight
    const resultTime = moment(midnight).subtract(alloted_time, "minutes");

    const filteredSlots = breakTimeIntoXMinuteIntervals(resultTime, midnight);

    return filteredSlots;
};

// filters drop down options based on the label and not the value
// we do this because the value is represented in 24hr time
export const filterTimePickerOptionsByLabel = (option, inputValue) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
};
