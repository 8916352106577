import React from "react";
import {
    HorizontalAnswerBubblesQuestionSet,
    SingleQuestionAnswerBubble,
    SingleQuestionAnswerText,
    Text,
    TextColors,
    TextTypes,
} from "../../../../../component-library";

export const A5V1AssessmentDisplay = ({ assessmentSummary }) => {
    return (
        <div className="A5AssessmentDisplay--grid">
            <div className="A5AssessmentDisplay--column">
                <div className="A5AssessmentDisplay--question_container">
                    <div className="A5AssessmentDisplay--hr" />

                    <SingleQuestionAnswerBubble
                        questionSetCategory={"Life Problems"}
                        questionSetOptions={[
                            "Health",
                            "Substance Abuse",
                            "Mental Health",
                            "Legal",
                            "Work",
                            "Getting along with others",
                            "Abuse/Violence",
                            "None of the Above",
                        ]}
                        answerIds={assessmentSummary?.["Life Problems"]}
                    />
                </div>
            </div>
            <div className="A5AssessmentDisplay--column">
                <div className="A5AssessmentDisplay--question_container">
                    <div className="A5AssessmentDisplay--hr" />
                    <SingleQuestionAnswerText
                        questionSetCategory={"Medical Problems"}
                        answers={assessmentSummary?.["Medical Problems"]}
                    />
                </div>
            </div>
            <div className="A5AssessmentDisplay--symptoms_column">
                <div className="A5AssessmentDisplay--question_container">
                    <div className="A5AssessmentDisplay--hr" />
                    <SingleQuestionAnswerText
                        questionSetCategory="Symptoms"
                        answers={assessmentSummary?.["Symptoms"]}
                        answerClassName={
                            // only reduce text size if not None
                            !Array.isArray(assessmentSummary?.["Symptoms"]) &&
                            "A5AssessmentDisplay--symptom_answer_text"
                        }
                    />
                </div>
            </div>
        </div>
    );
};
