export const ICON_TYPES = {
    OUTLINE: "outline",
    FILLED: "filled",
    CIRCLE: "circle"
};

export const ICON_SIZES = {
    SMALL: "IconSmall",
    MEDIUM: "IconMedium",
    DEFAULT: "IconMedium",
    LARGE: "IconLarge"
};
