// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import CardSecondary from "../../UI/Card/CardSecondary";
import images from "../../../utils/images";
import { Link } from "react-router-dom";
import moment from "moment";

class UpcomingVideoCall extends Component {
    cardClickHandler(e, videoUrl) {
        this.props.history.push(videoUrl);
    }

    renderNextCall = (nextCall) => {
        return (
            <div>
                <CardSecondary className="DashboardViewCard py-2 px-3 px-lg-4">
                    <div
                        className="d-flex align-items-center justify-content-between cursorOnMobile"
                        onClick={(e) =>
                            window.innerWidth < 992
                                ? this.cardClickHandler(e, nextCall.videoUrl)
                                : null
                        }>
                        <p className="fs-30 fs-xl-50 fw-bold mb-0">{nextCall.LocalTime}</p>
                        <img
                            alt="calendar icon"
                            className="d-none d-md-block mt-2 mb-2 pt-1 pb-1"
                            src={images("./provider/calendar_icon.svg")}
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <p className="CardText fs-lg-16 fs-13 mt-3">
                            Upcoming Video Call with{" "}
                            <strong className="text-break" data-private="true">
                                {nextCall.patient_name}
                            </strong>
                        </p>
                        <Link disabled={!nextCall.isVideoCallActive} to={nextCall.videoUrl}>
                            <img
                                alt="start call icon"
                                src={images("./provider/start_call_chevron.svg")}
                            />
                        </Link>
                    </div>
                </CardSecondary>
            </div>
        );
    };

    renderNoCall = () => {
        return (
            <CardSecondary className="DashboardViewCard py-2 px-3 px-lg-4">
                <div style={{ float: "right" }}>
                    <img
                        alt="calendar icon"
                        className="d-none d-md-block mt-2 mb-2 pt-1 pb-1"
                        src={images("./provider/calendar_icon.svg")}
                    />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <p className="CardText fs-lg-16 fs-13 mt-3">No Upcoming Video Calls</p>
                </div>
            </CardSecondary>
        );
    };

    render() {
        if (this.props.nextCall) {
            let call = this.props.nextCall;
            let shortUsername = call.patient_name;
            if (shortUsername.includes(" ")) {
                shortUsername = shortUsername.substr(0, shortUsername.indexOf(" "));
            }
            let isVideoCallActive = false;
            if (Date.now() >= moment(call.timestamp, "YYYY/M/D H:mm").valueOf()) {
                isVideoCallActive = true;
            }
            let callTime = call.timestamp;
            let nextCall = {
                callId: call.callId,
                shortUsername: shortUsername,
                fullUserName: call.patient_name,
                patient_name: call.patient_name,
                isVideoCallActive: isVideoCallActive,
                callTime: callTime,
                patient_username: call.patient_username,
                LocalTime: moment(callTime, "HH:mm").format("hh:mm A"),
                videoUrl: "/app/video-call?callId=" + call.callId,
            };
            return this.renderNextCall(nextCall);
        } else {
            return this.renderNoCall();
        }
    }
}

export default UpcomingVideoCall;
