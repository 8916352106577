// Copyright aptihealth, inc. 2019 All Rights Reserved
import { httpAuth, http } from "../utils/axios";
import { getGroupAuthType, getUsernameFromToken } from "../redux/actions/auth";
import isEmpty from "lodash/isEmpty";
import { GROUP_AUTH_HEADER_NAME } from "../constants/axios";

const OmnisearchQuestions = {
    fetch_provider_schedule_call_list: function ({ options } = {}) {
        /** if { queryParams not passed i.e no arguments, then assigning empty object `{}`} */
        let url = "/v1/search/omnisearch_questions";
        let conf = {
            url,
            method: "post",
            ...options,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    answerOmnisearch: function (omnisearchQuestion) {
        let url = "/v1/search/omnisearch_answer";
        let conf = {
            url,
            method: "post",
            data: omnisearchQuestion,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
};
export default OmnisearchQuestions;
