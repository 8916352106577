// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import { getAutomationDataAttr } from "../../../utils/automationUtils";

const ListItem = (props) => {
    let classes = "List__List-item mb-3 mb-lg-0" + " " + (props.className || "");
    let listItemJsx = <li className={classes}>{props.children}</li>;

    if (props.clickOnMobile && props.clickHandler) {
        classes += " cursorOnMobile";
        listItemJsx = (
            <li
                onClick={(e) => (window.innerWidth < 992 ? props.clickHandler(e) : null)}
                className={classes}
                {...getAutomationDataAttr(props.testId)}>
                {props.children}
            </li>
        );
    }

    return listItemJsx;
};

export default ListItem;
