// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import ProgressSlider from "../UI/ProgressSlider";
import A5Start from "./a5Start/A5Start";
import A5InProgress from "./A5InProgress";

import { getQueryParams } from "../../utils/filters";
import Brand from "../Navigation/Toolbar/Brand";
import A5VerifyIdentity from "./A5VerifyIdentity";
import { connect } from "react-redux";
import { getNextQuestion } from "../../redux/actions/a5Screening";
import A5PublicStart from "./a5Start/A5PublicStart";
import A5Completed from "./a5Complete/A5Completed";
import images from "../../utils/images";
import A5InProgressV2 from "./A5InProgressV2";
import { Footer } from "../../component-library/Footer";

const START_SCREENING = "Start Screening";
const SCREENING_IN_PROGRESS = "Screening In Progress";
const SCREENING_COMPLETED = "Screening Completed";
const DEFAULT = "default";
const ACTIVE = "active";
const DONE = "done";

export const START_STEP = 0;
export const IN_PROGRESS_STEP = 1;
export const COMPLETED_STEP = 2;

class A5Screening extends Component {
    screeningId = getQueryParams("id", this.props.location.search.trim());
    pcpPracticeName = getQueryParams("pcp_practice_name", this.props.location.search.trim());
    constructor(props) {
        super(props);
        this.state = {
            markerData: [
                {
                    status: ACTIVE,
                    caption: START_SCREENING,
                    component: this.screeningId ? (
                        <A5Start
                            nextStepHandler={this.setWorkflowStep}
                            screeningId={this.screeningId}
                        />
                    ) : (
                        <A5PublicStart
                            nextStepHandler={this.setWorkflowStep}
                            pcpPracticeName={this.pcpPracticeName}
                        />
                    ),
                },
                {
                    status: DEFAULT,
                    caption: SCREENING_IN_PROGRESS,
                    component: {
                        "A5 UX V1": (
                            <A5InProgress
                                nextStepHandler={this.setWorkflowStep}
                                prevStepHandler={this.setWorkflowStep}
                            />
                        ),
                        "A5 UX V2": (
                            <A5InProgressV2
                                nextStepHandler={this.setWorkflowStep}
                                prevStepHandler={this.setWorkflowStep}
                            />
                        ),
                    },
                },
                {
                    status: DEFAULT,
                    caption: SCREENING_COMPLETED,
                    component: <A5Completed />,
                },
            ],
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        window.scrollTo(0, 0);
    }

    setWorkflowStep = (workflowStep) => {
        let markerData = [...this.state.markerData];
        if (workflowStep === 0) {
            markerData[0].status = ACTIVE;
            markerData[1].status = DEFAULT;
            markerData[2].status = DEFAULT;
        } else if (workflowStep === 1) {
            markerData[0].status = DONE;
            markerData[1].status = ACTIVE;
            markerData[2].status = DEFAULT;
        } else if (workflowStep === 2) {
            markerData[0].status = DONE;
            markerData[1].status = DONE;
            markerData[2].status = DONE;
        }
        this.setState({
            markerData: markerData,
        });
    };

    getActiveComponent = (details) => {
        let component = this.state.markerData[0].component;
        const activeStatus = this.state.markerData.find((marker) => marker.status === ACTIVE);
        if (activeStatus && activeStatus.caption !== SCREENING_IN_PROGRESS) {
            component = activeStatus.component;
        } else if (activeStatus && activeStatus.caption === SCREENING_IN_PROGRESS) {
            if (details["config"] && details["config"]["A5 UX V2"]) {
                component = activeStatus.component["A5 UX V2"];
            } else {
                component = activeStatus.component["A5 UX V1"];
            }
        }
        if (!activeStatus) {
            if (
                this.state.markerData[0].status === DONE &&
                this.state.markerData[1].status === DONE &&
                this.state.markerData[2].status === DONE
            ) {
                component = this.state.markerData[2].component;
            }
        }
        return component;
    };

    render() {
        return (
            <div>
                <div className="container px-3 d-flex flex-column">
                    <div className={"screening-logo"}>
                        <Brand />
                    </div>
                    {(this.state.markerData[0].status === ACTIVE ||
                        this.state.markerData[2].status === DONE) && (
                        <div
                            className={
                                this.state.markerData[2].status === DONE
                                    ? "summary-progress-bar-wpr"
                                    : ""
                            }>
                            <div className="mx-auto mt-4 mb-5 my-lg-5">
                                <ProgressSlider markerData={this.state.markerData} />
                            </div>
                        </div>
                    )}
                    <div className="container">
                        {!this.props.a5Details && this.screeningId && (
                            <A5VerifyIdentity
                                screeningId={this.screeningId}
                                nextStepHandler={this.setWorkflowStep}
                            />
                        )}
                        {(this.props.a5Details || this.pcpPracticeName) &&
                            this.getActiveComponent(this.props.a5Details)}
                    </div>
                </div>
                {this.state.markerData[2].status === DONE && <Footer />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        a5Details: state.a5Screening.a5Details,
    };
};

export default connect(mapStateToProps, {
    getNextQuestion,
})(A5Screening);
