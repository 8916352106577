const timeSlots = [
    { slot: "00:00" },
    { slot: "01:00" },
    { slot: "02:00" },
    { slot: "03:00" },
    { slot: "04:00" },
    { slot: "05:00" },
    { slot: "06:00" },
    { slot: "07:00" },
    { slot: "08:00" },
    { slot: "09:00" },
    { slot: "10:00" },
    { slot: "11:00" },
    { slot: "12:00" },
    { slot: "13:00" },
    { slot: "14:00" },
    { slot: "15:00" },
    { slot: "16:00" },
    { slot: "17:00" },
    { slot: "18:00" },
    { slot: "19:00" },
    { slot: "20:00" },
    { slot: "21:00" },
    { slot: "22:00" },
    { slot: "23:00" },
];

export { timeSlots };
