// Copyright aptihealth, inc. 2021 All Rights Reserved
import images from "../../../../utils/images";
import { Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";
import FormikInput from "../../../UI/FormikInput";
import Button from "../../../UI/Button";
import { withRouter } from "react-router-dom";
import { yup_password_factory } from "../../../../utils/yupSchemaUtils";
import { CustomForm } from "../../../../component-library/CustomForm";

const SetPassword = (props) => {
    const { setSectionToShow, setPassword } = props;

    const submitHandler = (formData) => {
        setPassword(formData.password);
        setSectionToShow("member_agreement");
    };

    const renderForm = (formikProps) => {
        const formikInputProps = {
            inputClasses: "Input--sm",
            errors: formikProps.errors,
            touched: formikProps.touched,
        };
        return (
            <Form>
                <div className="mx-auto px-0 mb-3">
                    <div className="px-0 row pb-5">
                        <div className={"col-12"}>
                            <FormikInput
                                {...formikInputProps}
                                formEl={{
                                    name: "password",
                                    elementType: "input",
                                    elementConfig: {
                                        type: "password",
                                        label: "Create Password",
                                        placeholder: "Password",
                                    },
                                }}
                            />
                        </div>

                        <div className={"col-12"}>
                            <FormikInput
                                {...formikInputProps}
                                formEl={{
                                    name: "password_validation",
                                    elementType: "input",
                                    elementConfig: {
                                        type: "password",
                                        label: "Confirm Password",
                                        placeholder: "Re-enter Password",
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <Button type={"submit"} className={"Btn Btn--pri workflow-btn-lg"}>
                        Create & Continue
                    </Button>
                </div>
            </Form>
        );
    };

    return (
        <>
            <div className={"fs-22 txt-pri fw-bold mb-5 text-center"}>
                Welcome back! Please create your password
            </div>
            <div className={"text-center my-5"}>
                <img
                    src={images("./common/workflow/create-password.png")}
                    alt={"Create Password"}
                />
            </div>
            <div className={"workflow-container-sm"}>
                <CustomForm
                    initialValues={{
                        password: "",
                        password_validation: "",
                    }}
                    validationSchema={accountSetupValidation}
                    onSubmit={submitHandler}
                    render={renderForm}
                />
            </div>
        </>
    );
};

export default withRouter(SetPassword);

const accountSetupValidation = yup.object().shape({
    password: yup_password_factory(),
    password_validation: yup
        .string()
        .required("Password Validation is required")
        .test("password-match", "Password does not match, please check spelling", function (value) {
            if (!value) {
                return false;
            }
            return this.parent.password === value;
        }),
});
