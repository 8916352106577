// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import { connect } from "react-redux";
import Notifications from "../../../components/Patient/Profile/Notifications";
import { api } from "../../../APIRequests";

class PatientNotifications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            profileData: null,
        };
    }

    componentDidMount() {
        this.getProfile();
    }

    getProfile = async () => {
        try {
            const options = { params: { showLoader: false } };
            let response = await api.patient.fetch_profile({ options });
            this.setState({
                profileData: response && response.user,
            });
        } catch (err) {
            console.log(err);
        }
    };

    render() {
        const { profileData } = this.state;
        return <>{profileData && <Notifications profileData={profileData} />}</>;
    }
}

export default PatientNotifications;
