// Copyright aptihealth, inc. 2021 All Rights Reserved

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { AptihealthIcon } from "../../../../component-library/Icons/components/AptihealthIcon";
import { BhsIcon } from "../../../../component-library/Icons/components/BhsIcon";
import { IntakeIcon } from "../../../../component-library/Icons/components/IntakeIcon";
import { PrescriberIcon } from "../../../../component-library/Icons/components/PrescriberIcon";
import { ICON_SIZES, ICON_TYPES } from "../../../../constants/icons";
import { getCanStartCall } from "../../../../utils/calendar/util";
import { useCurrentUTCTime } from "../../../../utils/hooks/useCurrentTime";
import images from "../../../../utils/images";
import { allottedTimeFactory } from "../../../Provider/ScheduleAvailability/constants";
import Button from "../../../UI/Button";
import { buildCallDate, buildCallTimeInfo, buildCallTitle } from "../index";
import "../styles.scss";

const NextSessionWidget = (props) => {
    const { scheduleList } = props;
    const [nextSession, setNextSession] = useState(null);
    const { currentTime } = useCurrentUTCTime({ interval: 5000 });

    useEffect(() => {
        if (scheduleList && scheduleList.length) {
            setNextSession(scheduleList[0]);
        } else {
            setNextSession(null);
        }
    }, [scheduleList]);

    const startCall = (callId, provider_name, patient_username) => {
        props.history.push(`/app/video-call?callId=${callId}`);
    };

    const getCallIcon = (providerRole) => {
        switch (providerRole) {
            case "PRESCRIBE":
                return <PrescriberIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconWhite"}/>;
            case "BEHAVIORAL":
                return <BhsIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconWhite"}/>;
            case "BEHAVIORAL_INTAKE":
                return <IntakeIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconWhite"}/>;
            default:
                return <AptihealthIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconWhite"}/>;
        }
    };

    const renderNoNextSession = () => {
        return (
            <div className={"text-center mt-lg-4 mt-md-2 mt-0 mx-4"}>
                <div className="fs-16 fw-bold">No Session Scheduled</div>
                <div className="fs-14 lh-18">
                    Once scheduled, the date and time of your next session will appear here.
                </div>
            </div>
        );
    };

    const renderNextSession = (nextSession) => {
        const {
            callId,
            provider_role,
            timestamp,
            allotted_time,
            event_type,
            provider_name,
            patient_username,
        } = nextSession;
        const allottedTime = allotted_time ? allotted_time : allottedTimeFactory(event_type);

        return (
            <>
                <div className={"d-flex mt-2"}>
                    <div className={"pr-2"}>
                        {getCallIcon(provider_role)}
                    </div>
                    <div>
                        <div className="fs-16 fw-bold lh-18 mb-1">
                            {buildCallTitle(provider_role, event_type)} with{" "}
                            <span style={{ whiteSpace: "nowrap" }}>{provider_name}</span>
                        </div>
                        <div className="fs-14 lh-15">
                            {buildCallDate(timestamp)} {window.innerWidth > 1200 ? "· " : " "}
                            <span style={{ whiteSpace: "nowrap" }}>
                                {buildCallTimeInfo(timestamp, allottedTime)}
                            </span>
                            <div>{allottedTime} minutes</div>
                        </div>
                    </div>
                </div>
                <div className="d-flex mt-2 justify-content-center pb-lg-0 pb-2">
                    {getCanStartCall(currentTime, timestamp, allottedTime) && (
                        <Button
                            className="Btn Btn--pri Btn-xs Btn-xs-2 Btn-rounded Btn-otl-white Btn-transparent fw-bold"
                            style={{ textDecoration: "none", margin: 0 }}
                            onClick={() => startCall(callId, provider_name, patient_username)}>
                            <div className={"txt-white d-flex"}>
                                <img src={images("./icons/common/start-video.svg")} alt={""} />
                                <div className={"ml-1 mt-1"}>Start Visit</div>
                            </div>
                        </Button>
                    )}
                </div>
            </>
        );
    };

    return (
        <>
            <div
                className={
                    "dashboard-widget-card dashboard-widget-card-sm dashboard-widget-card-pri px-lg-3 px-2 py-2 mb-3 " +
                    "text-white"
                }>
                <div className={"fs-lg-22 fs-20 fw-bold mb-1 d-inline-flex"}>Your Next Session</div>
                {!nextSession && renderNoNextSession()}
                {nextSession && renderNextSession(nextSession)}
            </div>
        </>
    );
};

export default withRouter(NextSessionWidget);
