import "./styles.scss";
import React, { useEffect, useState } from "react";
import { PaymentHistory } from "./PaymentHistory";
import PropTypes from "prop-types";
import { PaymentModal } from "./PaymentModal";
import { CardOnFile } from "./CardOnFile";
import { usePaymentMethod } from "../../../../../utils/hooks/usePatient";
import moment from "moment/moment";
import { addToast, toastMessageTypes } from "../../../../../redux/actions/toaster";
import { api } from "../../../../../APIRequests";

const extractMostRecentPaymentMethodInformation = (paymentMethods) => {
    if (!paymentMethods) {
        return undefined;
    }

    const paymentMethod = paymentMethods.at(0);
    const cardEndsIn = paymentMethod?.last_four_digits;
    const expiresOn = moment(paymentMethod?.expiration_date, "YYYY-MM-DD HH:mm:ss");
    const dateAdded = moment.utc(paymentMethod?.timestamp, "YYYY-MM-DD HH:mm:ss").local();
    const hasCardExpired = moment().isAfter(expiresOn);

    return {
        hasCardOnFile: Boolean(paymentMethod),
        cardEndsIn,
        expiresOn,
        dateAdded,
        hasCardExpired,
    };
};

export const Payment = ({ patientDetails }) => {
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    const paymentMethodsResult = usePaymentMethod(patientDetails.username);
    const paymentMethodInformation =
        extractMostRecentPaymentMethodInformation(paymentMethodsResult);
    const patientId = patientDetails.username;

    const [transactionHistoryResult, setTransactionHistory] = useState({
        items: [],
        last_evaluated_key: null,
    });

    useEffect(() => {
        const fetchInitialTransactionHistory = async () => {
            const result = await getTransactionHistory(patientId);
            setTransactionHistory(result);
        };

        fetchInitialTransactionHistory();
    }, [patientId]);

    const getTransactionHistory = async (patientId, lastEvaluatedKey = null) => {
        if (!patientId) {
            return { items: [], last_evaluated_key: null };
        }

        const options = { params: { showLoader: true } };

        // Pass the lastEvaluatedKey to the API call
        const data = {
            patient_id: patientId,
            last_evaluated_key: lastEvaluatedKey,
        };

        try {
            return await api.payment.fetchTransactionHistory({
                options,
                data: data,
            });
        } catch (error) {
            console.error("Error fetching transaction history:", error);
            return { error: "Error", items: [], last_evaluated_key: null };
        }
    };

    const loadMore = async () => {
        try {
            if (!transactionHistoryResult || !transactionHistoryResult.last_evaluated_key) {
                return;
            }

            const result = await getTransactionHistory(
                patientId,
                transactionHistoryResult.last_evaluated_key,
            );

            setTransactionHistory((prevTransactionHistory) => ({
                items: [...(prevTransactionHistory?.items || []), ...result.items],
                last_evaluated_key: result.last_evaluated_key,
            }));
        } catch (error) {
            console.error("Error loading more transaction history:", error);
        }
    };

    const handleUpdateTransactionHistory = async () => {
        const result = await getTransactionHistory(patientId);

        if (result.error) {
            addToast({
                message: "Error getting payment history.",
                messageType: toastMessageTypes.error_v2,
            });
        }

        setTransactionHistory(result);
    };

    return (
        <div className="PaymentScreen-container">
            <CardOnFile
                hasLoaded={Boolean(paymentMethodsResult)}
                hasCardOnFile={paymentMethodInformation?.hasCardOnFile}
                cardEndsIn={paymentMethodInformation?.cardEndsIn}
                dateAdded={paymentMethodInformation?.dateAdded.format("MM/DD/YYYY")}
                expiresOn={paymentMethodInformation?.expiresOn.format("MM/YYYY")}
                hasCardExpired={paymentMethodInformation?.hasCardExpired}
            />
            <PaymentHistory
                patientId={patientDetails.username}
                patientFirstName={patientDetails.first_name}
                openPaymentModal={() => setIsPaymentModalOpen(true)}
                hasCardOnFile={paymentMethodInformation?.hasCardOnFile}
                isCardExpired={paymentMethodInformation?.hasCardExpired}
                transactionHistory={transactionHistoryResult}
                onLoadMore={loadMore}
            />

            <PaymentModal
                patientId={patientDetails.username}
                isOpen={isPaymentModalOpen}
                setIsOpen={setIsPaymentModalOpen}
                updateTransactionHistory={handleUpdateTransactionHistory}
            />
        </div>
    );
};

Payment.propTypes = {
    patientDetails: PropTypes.object,
};
