import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";
import ReactTooltip from "react-tooltip";

export const Popover = ({
    toolTipId,
    children,
    className,
    position,
    show,
    effect,
    border,
    backgroundColor,
    textColor,
    borderColor,
    ...props
}) => (
    <ReactTooltip
        className={createClassNameString(["apti-CL apti-Popover", className])}
        id={toolTipId}
        place={position}
        effect={effect}
        border={border}
        borderColor={borderColor}
        backgroundColor={backgroundColor}
        textColor={textColor}
        {...props}>
        {children}
    </ReactTooltip>
);

Popover.propTypes = {
    toolTipId: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    className: PropTypes.string,
    position: PropTypes.oneOf(["left", "right", "top", "bottom"]),
    show: PropTypes.bool,
    effect: PropTypes.string,
    border: PropTypes.bool,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    borderColor: PropTypes.string,
};

Popover.defaultProps = {
    children: <></>,
    className: undefined,
    position: "left",
    show: false,
    effect: "solid",
    border: true,
    backgroundColor: "white",
    textColor: "#668391",
    borderColor: "#5B57DC",
};
