import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";

export const AnswerBubble = ({ className, style, filled }) => {

    const classNamesArray = ["apti-CL apti-AnswerBubble", className];
    if (filled) {
        classNamesArray.push("filled");
    }

    return <div className={createClassNameString(classNamesArray)} style={style}></div>;
};

AnswerBubble.propTypes = {
    className: PropTypes.string,
    filled: PropTypes.bool,
    style: PropTypes.object
};

AnswerBubble.defaultProps = {
    className: undefined,
    filled: false,
    style: {}
};
