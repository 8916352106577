/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const StrikethroughIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Strikethrough"
                {...props}>
                <path d="M22.7824 5.95816V8.61925C20.4393 7.04603 18.3305 6.25941 16.4561 6.25941C15.0669 6.25941 13.8954 6.63598 12.9414 7.38912C11.9874 8.14226 11.5105 9.07113 11.5105 10.1757C11.5105 10.9623 11.795 11.6904 12.364 12.3598C12.7765 12.8194 13.6909 13.3662 15.1074 14H30V16H2V14H10.5523C10.4619 13.905 10.3796 13.8102 10.3054 13.7155C9.53556 12.6946 9.15063 11.5649 9.15063 10.3264C9.15063 8.56904 9.87029 7.0795 11.3096 5.85774C12.7657 4.61925 14.5314 4 16.6067 4C18.4979 4 20.5565 4.65272 22.7824 5.95816Z" />
                <path d="M18.1867 18H22.3178C23.1129 19.0836 23.5105 20.2914 23.5105 21.6234C23.5105 23.4142 22.7824 24.9289 21.3264 26.1674C19.8703 27.3891 18.0711 28 15.9289 28C13.6025 28 11.2929 27.2971 9 25.8912V23.4812C11.728 24.887 14.1464 25.59 16.2552 25.59C17.6778 25.59 18.841 25.2134 19.7448 24.4603C20.6653 23.7071 21.1255 22.7448 21.1255 21.5732C21.1255 20.7699 20.8243 20.0335 20.2218 19.364C19.8836 18.9679 19.2053 18.5132 18.1867 18Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Strikethrough"
                {...props}>
                <path d="M24.2706 20.8995C24.2706 22.3612 23.8939 23.6261 23.1405 24.6943C22.3985 25.7625 21.3584 26.5833 20.0204 27.1567C18.6824 27.7189 17.1476 28 15.416 28C14.9325 28 14.3366 27.9663 13.6283 27.8988C12.9311 27.8426 11.9979 27.6739 10.8285 27.3928C9.67041 27.1117 8.4617 26.6957 7.20239 26.1448V20.4104C8.38299 21.1413 9.54111 21.7484 10.6767 22.2319C11.8236 22.7154 13.0492 22.9571 14.3535 22.9571C15.5566 22.9571 16.3324 22.7604 16.681 22.3668C17.0408 21.9621 17.2207 21.5854 17.2207 21.2368C17.2207 20.6072 16.9171 20.0787 16.3099 19.6514C15.7027 19.2129 14.8201 18.7632 13.662 18.3022C13.4264 18.203 13.1961 18.1022 12.9711 18H23.5891C24.0434 18.8054 24.2706 19.7719 24.2706 20.8995Z" />
                <path d="M16.4954 12.9895C17.2453 13.341 17.9326 13.6778 18.5575 14H30V16H2V14H7.81691C7.2723 13.0774 7 11.9984 7 10.7632C7 9.50387 7.30921 8.36824 7.92762 7.35629C8.54603 6.3331 9.49051 5.51792 10.7611 4.91075C12.0316 4.30358 13.5833 4 15.416 4C16.7203 4 17.9234 4.1293 19.0253 4.38791C20.1384 4.63528 21.0548 4.91637 21.7744 5.2312C22.494 5.54603 22.9888 5.79339 23.2586 5.9733V11.4547C22.3029 10.7688 21.2347 10.156 20.0541 9.6163C18.8848 9.0766 17.6311 8.80675 16.293 8.80675C15.416 8.80675 14.7807 8.9754 14.3872 9.31272C13.9937 9.65004 13.7969 10.0661 13.7969 10.5608C13.7969 11.0218 13.9937 11.4209 14.3872 11.7583C14.7807 12.0956 15.4835 12.506 16.4954 12.9895Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Strikethrough"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95428 0 0 8.95431 0 20C0 31.0457 8.95428 40 20 40ZM25.3554 26.5207C25.9204 25.7196 26.2029 24.7709 26.2029 23.6746C26.2029 22.8289 26.0325 22.1041 25.6918 21.5H17.7283C17.897 21.5767 18.0698 21.6522 18.2465 21.7266C19.1151 22.0724 19.777 22.4097 20.2324 22.7386C20.6878 23.059 20.9155 23.4554 20.9155 23.9276C20.9155 24.189 20.7806 24.4715 20.5107 24.7751C20.2493 25.0703 19.6674 25.2178 18.7651 25.2178C17.7869 25.2178 16.8677 25.0365 16.0076 24.6739C15.1558 24.3113 14.2872 23.8559 13.4018 23.3078V27.6086C14.3463 28.0218 15.2528 28.3338 16.1214 28.5446C16.9984 28.7554 17.6984 28.8819 18.2212 28.9241C18.7524 28.9747 19.1994 29 19.562 29C20.8607 29 22.0118 28.7892 23.0153 28.3675C24.0188 27.9375 24.7988 27.3219 25.3554 26.5207ZM21.9181 18.5C21.4495 18.2584 20.934 18.0057 20.3716 17.7421C19.6126 17.3795 19.0856 17.0717 18.7904 16.8187C18.4952 16.5657 18.3477 16.2663 18.3477 15.9206C18.3477 15.5495 18.4952 15.2375 18.7904 14.9845C19.0856 14.7316 19.562 14.6051 20.2198 14.6051C21.2233 14.6051 22.1636 14.8074 23.0406 15.2122C23.926 15.617 24.7272 16.0766 25.444 16.591V12.48C25.2416 12.345 24.8705 12.1595 24.3308 11.9234C23.7911 11.6873 23.1038 11.4765 22.269 11.2909C21.4426 11.097 20.5402 11 19.562 11C18.1874 11 17.0237 11.2277 16.0708 11.6831C15.1179 12.1384 14.4095 12.7498 13.9457 13.5172C13.4819 14.2762 13.25 15.1279 13.25 16.0724C13.25 16.9988 13.4542 17.808 13.8627 18.5H9.5V20H30.5V18.5H21.9181Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

StrikethroughIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

StrikethroughIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
