import React from "react";

import { TextTypes, TextColors } from "../../constants";
import { Button } from "../../Button";
import { Text } from "../../Text";
import { Image } from "../../Image";

import "./styles.scss";

// Relates to: Self Signup

export const VerifyInsuranceAccepted = ({
    title,
    insuranceLogo,
    insuranceName,
    planName,
    copayAmount,
    onClick,
}) => {
    return (
        <div className="apti-VerifyInsurance">
            <Text type={TextTypes.heading1} color={TextColors.green}>
                {title}
            </Text>
            {insuranceLogo && (
                <Image className={"logo"} src={insuranceLogo} alt="insurance logo"></Image>
            )}
            <Text style={{ color: TextColors.grey }}>{planName}</Text>

            {copayAmount > 0 && (
                <>
                    <Text className={"copayTitle"}>
                        Your plan has a <Text className={"bold"}>{"$" + copayAmount}</Text> copay.
                    </Text>
                </>
            )}
            {copayAmount === null && (
                <>
                    <Text className={"copayDescription"}>
                        Your plan may have coinsurance or deductibles. Refer to your insurance card
                        or contact <Text className={"bold"}>{insuranceName}</Text> for details.
                    </Text>
                </>
            )}

            <Button buttonType={"primary"} onClick={onClick}>
                Continue
            </Button>

            {copayAmount !== null && (
                <>
                    <Text className={"copay-disclaimer"} style={{ color: TextColors.grey }}>
                        Based on your benefits, you may have additional costs associated with your
                        care. Please contact{" "}
                        <span style={{ fontWeight: 900 }}>{insuranceName}</span> for more
                        information.
                    </Text>
                </>
            )}
        </div>
    );
};

VerifyInsuranceAccepted.defaultProps = {
    onClick: () => {},
    title: "",
    insuranceLogo: "",
    planName: "",
    insuranceName: "",
    copayAmount: null,
};
