import { GROUP_AUTH_HEADER_NAME } from "../constants/axios";
import { getGroupAuthType } from "../redux/actions/auth";
import { httpAuth } from "../utils/axios";

const workingHourRequests = {
    saveWorkingHours: ({ workingHours, username }) => {
        let conf = {
            method: "post",
            url: "/v1/working_hours",
            data: {
                working_hours: workingHours,
                username,
            },
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
};

export default workingHourRequests;
