import _ from "lodash";
import CardSecondary from "../../../../UI/Card/CardSecondary";
import { TextArea } from "../../../../UI/StyledInput";
import React from "react";
import { RATING_OPTIONS_LABEL_MAP } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotes";
import { BehavioralGoalsRating } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotesBehavioralGoals";
import { BehavioralSymptomTrend } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotesSymptomTrend";
import { ExpandingSelect, ExpandingTextArea } from "./DisplayWorkflowStepWithVideoClient";
import { BehavioralMentalStatus } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotesMentalStatus";

export const BehavioralSymptomTrends = (componentProps) => {
    let { state, noteEditHandler } = componentProps;
    let { progressNote } = state;

    return (
        <>
            <ExpandingTextArea
                label="How are you?"
                value={progressNote.content.symptom_trend_notes}
                onChange={(e) => {
                    noteEditHandler("symptom_trend_notes", e.target.value);
                }}
            />
            <div className="m-3">
                <BehavioralSymptomTrend
                    note={progressNote}
                    editable={true}
                    patientAnswersEditable={false}
                    editHandler={noteEditHandler}
                />
            </div>
        </>
    );
};

export const BehavioralExploration = (componentProps) => {
    let { state, noteEditHandler } = componentProps;
    let { progressNote } = state;

    const editHandlerBuilder = (sectionLabel, deeperLabels = []) => {
        return (e, index, key) => {
            let original = progressNote.content[sectionLabel];
            let editCopyClone = _.cloneDeep(original);

            let deeperObject = editCopyClone;
            deeperLabels.forEach((label) => {
                deeperObject = deeperObject[label];
            });
            deeperObject[index][key] = !deeperObject[index][key];

            if (index === "none") {
                Object.entries(deeperObject).forEach(([sectionKey, value]) => {
                    if (sectionKey !== "none") {
                        value[key] = false;
                    }
                });
            } else {
                if (deeperObject["none"]) {
                    deeperObject["none"][key] = false;
                }
            }

            noteEditHandler(sectionLabel, editCopyClone);
        };
    };

    return (
        <>
            <ExpandingTextArea
                label="Notes"
                value={progressNote.content.exploration_notes}
                onChange={(e) => {
                    noteEditHandler("exploration_notes", e.target.value);
                }}
            />
            <ExpandingSelect
                label="Changes/Updates"
                labelClass="fw-bold"
                className="m-3 grey-container"
                disabled
                options={progressNote.content.changes}
                onChange={editHandlerBuilder("changes")}
            />

            <ExpandingSelect
                label="Health Care Use"
                labelClass="fw-bold"
                className="m-3 grey-container"
                disabled
                options={progressNote.content.health_care_use}
                onChange={editHandlerBuilder("health_care_use")}
            />

            <BehavioralMentalStatus
                note={progressNote}
                editable={true}
                editHandler={noteEditHandler}
                showTitle={false}
            />
        </>
    );
};

export const BehavioralInterventions = (componentProps) => {
    let { state, noteEditHandler } = componentProps;
    let { progressNote } = state;
    let goalsObjectivesProgress = progressNote.content.goals_objectives_progress;

    const handleChange = (e, index, key, isNumber = false) => {
        let editCopyClone = _.cloneDeep(goalsObjectivesProgress);
        editCopyClone[index][key] = isNumber ? parseInt(e.target.value) : e.target.value;
        noteEditHandler("goals_objectives_progress", editCopyClone);
    };

    return (
        <CardSecondary className="m-3">
            <div
                className="row justify-content-between mx-0 py-2 mb-2 text-white"
                style={{ backgroundColor: "#0CD0A7", borderRadius: "5px 5px 0 0" }}>
                <p className="col-lg-5 fw-bold">Goals and Objectives</p>
                <div className="col-lg-7 d-flex justify-content-start">
                    <div className="col-12 d-flex justify-content-start">
                        {Object.values(RATING_OPTIONS_LABEL_MAP).map((optionLabel) => (
                            <div className="w-25 fs-14 fw-bold text-center">
                                <div className="m-auto" style={{ width: 50 }}>
                                    {optionLabel}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {goalsObjectivesProgress.map((progress, index) => {
                return (
                    <>
                        <div className="row px-3 p-1 justify-content-between">
                            <div className="col-lg-5 fw-bold">{progress.label} - Intervention</div>
                            <div className="col-lg-7 d-flex justify-content-start">
                                <BehavioralGoalsRating
                                    optionLabelMap={RATING_OPTIONS_LABEL_MAP}
                                    progress={progress}
                                    isEditing={true}
                                    onClick={(e) => handleChange(e, index, "answer", true)}
                                />
                            </div>
                        </div>
                        <div className="mx-auto t-2 px-3 d-flex justify-content-between mb-2">
                            <TextArea
                                spanClasses="w-100"
                                value={goalsObjectivesProgress[index].actions}
                                onChange={(e) => handleChange(e, index, "actions")}
                                classes={"large"}
                            />
                        </div>
                    </>
                );
            })}
        </CardSecondary>
    );
};

export const BehavioralActions = (componentProps) => {
    let { state, noteEditHandler } = componentProps;
    let { progressNote } = state;
    let goalsObjectivesProgress = progressNote.content.goals_objectives_progress;

    const handleChange = (e, index, key, isNumber = false) => {
        let editCopyClone = _.cloneDeep(goalsObjectivesProgress);
        editCopyClone[index][key] = isNumber ? parseInt(e.target.value) : e.target.value;
        noteEditHandler("goals_objectives_progress", editCopyClone);
    };

    return goalsObjectivesProgress.map((progress, index) => {
        return (
            <CardSecondary className="m-3">
                <div className="row px-3 pt-3 pb-1 justify-content-between">
                    <div className="col-lg-5 fw-bold">{progress.label} - Plan</div>
                </div>
                <div className="mx-auto t-2 px-3 pt-1 pb-3 d-flex justify-content-between mb-2">
                    <TextArea
                        spanClasses="w-100"
                        value={goalsObjectivesProgress[index].plan}
                        onChange={(e) => handleChange(e, index, "plan")}
                        classes={"large"}
                    />
                </div>
            </CardSecondary>
        );
    });
};
