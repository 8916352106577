import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";
import { getAutomationDataAttr } from "../../utils/automationUtils";

export const Text = ({ children, className, type, color, layout, testId, ...props }) => (
    <span
        className={createClassNameString(["apti-CL apti-Text", className, type, color, layout])}
        {...getAutomationDataAttr(testId)}
        {...props}>
        {children}
    </span>
);

Text.propTypes = {
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
    type: PropTypes.string,
    color: PropTypes.string,
    layout: PropTypes.oneOf(["block", "inline"]),
    testId: PropTypes.string,
};

Text.defaultProps = {
    children: "",
    className: undefined,
    type: undefined,
    color: undefined,
    layout: "inline",
    testId: undefined,
};
