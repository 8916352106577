// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component, Fragment } from "react";
import Button from "../UI/Button";
import ProgressBar from "../UI/ProgressBar";
import { Question } from "../Patient/Assessment";
import { connect } from "react-redux";
import {
    getNextQuestion,
    getPreviousQuestion,
    setSelectedOptions,
} from "../../redux/actions/a5Screening";
import { optionSelectHandler } from "../../containers/Dashboard/Patient/Assessment";
import { COMPLETED_STEP, START_STEP } from "./A5Screening";

class A5InProgress extends Component {
    handleNextQuestion = () => {
        let requestBody = {
            id: this.props.a5Details.id,
            question_id: this.props.currentQuestion.question_id,
            options: this.props.currentQuestion.options.filter((el) => {
                return this.props.selectedOptions.find((index) => index === el.index) !== undefined;
            }),
        };
        this.props.getNextQuestion(requestBody, { dob: this.props.a5Details.dob });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isLastQuestion && !this.props.currentQuestion) {
            this.props.nextStepHandler(COMPLETED_STEP);
        }
    }

    handlePrevQuestion = () => {
        if (this.props.completionPercentage === 0) {
            this.props.prevStepHandler(START_STEP);
        } else {
            this.props.getPreviousQuestion(
                { id: this.props.a5Details.id },
                { dob: this.props.a5Details.dob },
            );
        }
    };

    render() {
        return (
            <div>
                {this.props.currentQuestion && (
                    <Fragment>
                        <div className="container">
                            <div className="my-4">
                                <span>A5 Screening</span>
                            </div>
                            <ProgressBar now={this.props.completionPercentage} height="12px" />
                            <Question
                                classes={
                                    "col-12 col-lg-6 px-2 px-lg-2 col-lg-4 d-flex py-2 justify-content-center"
                                }
                                currentQuestion={this.props.currentQuestion}
                                selectOption={(option) => optionSelectHandler(option, this)}
                                selectedOptions={this.props.selectedOptions}
                                excludeOuterClass={true}
                            />
                            <div className="A5ButtonGroup d-flex h-100 align-items-center justify-content-between">
                                <Button
                                    type="button"
                                    className="Btn Btn--otl-pri mt-4"
                                    onClick={this.handlePrevQuestion}>
                                    Previous
                                </Button>
                                <Button
                                    type="button"
                                    className="Btn Btn--pri mt-4"
                                    disabled={
                                        this.props.selectedOptions.length === 0 ? "disabled" : null
                                    }
                                    onClick={this.handleNextQuestion}>
                                    {!this.props.isLastQuestion && "Next"}
                                    {this.props.isLastQuestion && "Finish"}
                                </Button>
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentQuestion: state.a5Screening.currentQuestion,
        completionPercentage: state.a5Screening.completionPercentage,
        selectedOptions: state.a5Screening.selectedOptions,
        lastSelectedOption: state.a5Screening.lastSelectedOption,
        isLastQuestion: state.a5Screening.isLastQuestion,
        a5Details: state.a5Screening.a5Details,
    };
};

export default connect(mapStateToProps, {
    getNextQuestion,
    getPreviousQuestion,
    setSelectedOptions,
})(A5InProgress);
