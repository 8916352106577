import "./styles.scss";
import React, { useState } from "react";
import CloseOnOutsideClick from "../../components/Common/CloseOnOutsideClick/CloseOnOutsideClick";
import images from "../../utils/images";
import DatePicker from "../../components/UI/DatePicker";
import moment from "moment";
import { createClassNameString } from "../utils";

// This component was originally taken from MemberProfileNotes.js component
export const DateInputPicker = ({
    show,
    showCallback = () => {},
    hideCallback = () => {},
    inputValue,
    setInputValue,
    displayValue = "",
    wrapperClassName,
    displayValueClassName,
}) => {
    // maintain internal state unless external state is provided
    // allows for maximum flexibility
    const [showInternalPicker, setShowInternalPicker] = useState(false);
    const showPicker = show ?? showInternalPicker;

    const onShow = () => {
        // if no show value is provided then internal state is updated
        if (typeof show === "undefined" || show === null) {
            setShowInternalPicker(true);
        }
        showCallback();
    };

    const onHide = () => {
        if (typeof show === "undefined" || show === null) {
            setShowInternalPicker(false);
        }
        hideCallback();
    };

    const onFlip = () => {
        if (!showPicker) {
            onShow();
        } else {
            onHide();
        }
    };

    return (
        <div className={wrapperClassName}>
            <CloseOnOutsideClick
                setShowComponent={() => {
                    if (showPicker) {
                        onHide();
                    }
                }}>
                <div
                    className={"DateInputPicker--display_wrapper"}
                    style={{ cursor: "pointer" }}
                    role={"button"}
                    onClick={onFlip}>
                    <div
                        className={createClassNameString([
                            "DateInputPicker--display_value",
                            displayValueClassName,
                        ])}>
                        {displayValue}
                    </div>
                    <img
                        alt="calendar icon"
                        style={{ width: 25 }}
                        src={images("./icons/calender-icon.svg")}
                    />
                </div>
                {showPicker && (
                    <div className={"DateInputPicker--picker_wrapper"}>
                        <DatePicker
                            externalCtx={inputValue}
                            loadingSlots={false}
                            onDaySelect={(date, eventType) => {
                                if (eventType !== "monthChange") {
                                    onHide();
                                }

                                const momentDate = moment(date, "YYYY-MM-DD");
                                setInputValue(momentDate);
                            }}
                        />
                    </div>
                )}
            </CloseOnOutsideClick>
        </div>
    );
};
