// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import "./styles.scss";
import { api } from "../../../APIRequests";
import { getPatientProfile } from "../../../redux/actions/auth";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AddMessageChannel from "./AddMessageChannel";
import Spinner from "../../UI/Spinner";
import images from "../../../utils/images";
import moment from "moment-timezone";
import { isAuthorized } from "../../../redux/actions/auth";
import { getQueryParams } from "../../../utils/filters";
import { MessagesContainer } from "./MessagesContainer";

class MessagesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            channels: [],
            nextChannelPageQuery: null,
            loadingMoreChannels: false,
            isMobile: window.innerWidth <= 640,
            mobileView: "CHANNEL_SELECT",
            initialLoadComplete: false,
            isInactive: false,
        };
    }

    async componentDidMount() {
        const { getPatientProfile } = this.props;
        if (isAuthorized("user:patient")) {
            await getPatientProfile();
        }

        if (
            isAuthorized("user:patient") &&
            this.props.profile !== undefined &&
            this.props.profile.status === "INACTIVE"
        ) {
            this.setState({ isInactive: true });
        }
        await this.fetchMessagingInfo();
        await this.processChannels();
        window.addEventListener("resize", this.handleResize);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.profile === undefined &&
            this.props.profile !== undefined &&
            this.props.profile.status === "INACTIVE"
        ) {
            this.setState({ isInactive: true });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        this.state.client.removeAllChannelHandlers();
        this.state.client.disconnect();
    }

    fetchMessagingInfo = async () => {
        const options = { params: { showLoader: false } };
        let user = await api.messaging.fetch_user({ options });
        let contacts = await api.messaging.fetch_contacts({ options });
        let client = api.messaging.create_client();
        await api.messaging.connect_to_sendbird(client, user.user_id, user.access_token);
        const { channels, nextChannelPageQuery } = await api.messaging.get_paginated_channels(
            client,
        );
        const processedChannels = channels.map((channel) => this.processChannel(user, channel));

        this.setState({
            client,
            user,
            contacts,
            channels: processedChannels,
            nextChannelPageQuery,
        });

        return user;
    };

    processChannels = async () => {
        let selectedChannel, isAddingChannel;
        const memberId = getQueryParams("memberId", this.props.location.search);
        let foundChannel;
        const channels = this.state.channels;
        if (memberId) {
            foundChannel = channels.find(
                (channel) =>
                    channel.otherMembersMap &&
                    Object.keys(channel.otherMembersMap).length === 1 &&
                    channel.otherMembersMap.hasOwnProperty(memberId),
            );
        }

        if (memberId && !foundChannel) {
            await this.handleDirectMessageSelection({ user_id: memberId });
            this.setState({
                initialLoadComplete: true,
            });
            return;
        } else if (foundChannel) {
            selectedChannel = foundChannel;
            isAddingChannel = false;
        } else if (channels.length > 0) {
            selectedChannel = channels[0];
            isAddingChannel = false;
        } else {
            selectedChannel = null;
            isAddingChannel = true;
        }

        this.setState({
            selectedChannel,
            isAddingChannel,
            initialLoadComplete: true,
        });
    };

    handleResize = () => {
        this.setState((state) => {
            let isMobile = window.innerWidth <= 640;
            if (state.isMobile !== isMobile) {
                return {
                    isMobile,
                    mobileView: "CHANNEL_SELECT",
                };
            }
        });
    };

    processChannel = (user, channel) => {
        channel.otherMembers = channel.members.filter((member) => member.userId !== user.user_id);

        channel.otherMembersMap = channel.otherMembers.reduce((map, member) => {
            map[member.userId] = member;
            return map;
        }, {});

        if (channel.otherMembers.length === 1) {
            let otherMember = channel.otherMembers[0];
            channel.coverImage =
                otherMember.profileUrl !== ""
                    ? otherMember.profileUrl
                    : images("./common/avatar.png");
        }

        if (channel.lastMessage) {
            let createdAt = moment(channel.lastMessage.createdAt);
            let format = createdAt.isSame(moment(), "day") ? "hh:mm A" : "MM/DD/YY hh:mm A";
            channel.lastMessage.formattedTime = createdAt.format(format);
        }

        return channel;
    };

    showMoreChannels = async () => {
        const { nextChannelPageQuery, user } = this.state;

        this.setState({ loadingMoreChannels: true });

        const { channels: newChannels, nextChannelPageQuery: newNextChannelPageQuery } =
            await api.messaging.get_next_channel_page(nextChannelPageQuery);
        const processedChannels = newChannels.map((channel) => this.processChannel(user, channel));

        this.setState({
            channels: [...this.state.channels, ...processedChannels],
            nextChannelPageQuery: newNextChannelPageQuery,
            loadingMoreChannels: false,
        });
    };

    switchMessagesOrAddChannelView = () => {
        this.setState((state) => {
            // If there are no channels do not allow to leave addChannelView
            let isAddingChannel = this.state.channels.length === 0 ? true : !state.isAddingChannel;

            return {
                isAddingChannel,
            };
        });
    };

    handleDirectMessageSelection = async (contact) => {
        let userIds = [this.state.user.user_id, contact.user_id];
        let upsertChannel = await api.messaging.upsert_distinct_channel(this.state.client, userIds);
        let channels = Array.from(this.state.channels);

        // Put selected channel at the top
        let existingChannelIndex = channels.findIndex(
            (channel) => channel.url === upsertChannel.channel.url,
        );
        if (upsertChannel.isCreated || existingChannelIndex === -1) {
            let newChannel = this.processChannel(this.state.user, upsertChannel.channel);
            channels.unshift(newChannel);
        } else {
            let processedExistingChannel = this.processChannel(
                this.state.user,
                upsertChannel.channel,
            );
            channels.splice(existingChannelIndex, 1);
            channels.unshift(processedExistingChannel);
        }

        this.setState({
            selectedChannel: upsertChannel.channel,
            channels,
            isAddingChannel: false,
            mobileView: "CHAT",
        });
    };

    onSelectChannel = (channel) => {
        this.setState({
            selectedChannel: channel,
            mobileView: "CHAT",
        });
    };

    onMessageReceived = (updatedChannel) => {
        const nonUpdatedChannels = Array.from(this.state.channels).filter(
            (channel) => channel.url !== updatedChannel.url,
        );
        const processedUpdatedChannel = this.processChannel(this.state.user, updatedChannel);
        this.setState({
            channels: [processedUpdatedChannel, ...nonUpdatedChannels]
        });
    };

    render() {
        if (!this.state.initialLoadComplete) {
            return <Spinner />;
        }

        if (this.state.isAddingChannel) {
            return (
                <AddMessageChannel
                    contacts={this.state.contacts}
                    channels={this.state.channels}
                    switchView={this.switchMessagesOrAddChannelView}
                    handleSelect={this.handleDirectMessageSelection}
                />
            );
        }

        return <MessagesContainer
            channels={this.state.channels}
            client={this.state.client}
            fetchMessagingInfo={this.fetchMessagingInfo}
            isInactive={this.state.isInactive}
            isMobile={this.state.isMobile}
            loadingMoreChannels={this.state.loadingMoreChannels}
            mobileView={this.state.mobileView}
            nextChannelPageQuery={this.state.nextChannelPageQuery}
            onSelectChannel={this.onSelectChannel}
            selectedChannel={this.state.selectedChannel}
            showMoreChannels={this.showMoreChannels}
            switchMessagesOrAddChannelView={this.switchMessagesOrAddChannelView}
            user={this.state.user}
            switchMobileView={() => this.setState({ mobileView: "CHANNEL_SELECT" })}
            onMessageReceived={this.onMessageReceived}
        />;
    }
}
const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
        flags: state.flags,
    };
};

export default connect(mapStateToProps, {
    getPatientProfile,
})(withRouter(MessagesView));
