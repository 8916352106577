// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import "./styles.scss";
import RatingControls from "./RatingControls";
import Hr from "../../UI/Hr";
import Button from "../../UI/Button";
import { Link } from "react-router-dom";
import images from "../../../utils/images";

const RatingScaleView = (props) => {
    const {
        questions,
        mainHeading,
        headerRightContent,
        onRating,
        submit,
        allowSubmit,
        readOnly,
        backLinkConf,
        isNestedComponent,
    } = props;

    return (
        <div className={isNestedComponent ? "RatingScaleView" : "container RatingScaleView"}>
            {backLinkConf && (
                <div className="d-none d-lg-block mt-4 pb-3">
                    <Link to={backLinkConf.path || "/"} className="RatingScaleView__back-btn">
                        <img
                            className="mr-2"
                            src={images("./icons/solidBckBtn.svg")}
                            alt="back btn"
                        />
                        <span>{backLinkConf.text || "Go back"}</span>
                    </Link>
                </div>
            )}
            <div className="RatingScaleView__card my-3 my-lg-4 p-3">
                <div className="RatingScaleView__header d-flex align-items-center">
                    <div className="d-none d-lg-block float-left">
                        <h1 className="fs-18 fw-bold mx-2 mt-2 mb-4 px-lg-2">{mainHeading}</h1>
                    </div>
                    {headerRightContent}
                </div>

                <div className="mx-lg-2 mt-4 pt-2 px-lg-2">
                    {questions.map((q, i) => {
                        return (
                            <div key={q.question_id} className="mb-2 mb-lg-4 pb-2">
                                <div className="fs-16 fw-bold mb-3 mb-lg-4">
                                    <span>{i + 1 + " " + q.question}</span>
                                </div>
                                {readOnly && (
                                    <RatingControls id={q.question_id} rating={q.rating} readOnly />
                                )}
                                {!readOnly && (
                                    <RatingControls
                                        id={q.question_id}
                                        rating={q.rating}
                                        onRatingClick={onRating}
                                    />
                                )}
                            </div>
                        );
                    })}
                    {!readOnly && (
                        <>
                            <Hr />
                            <div className="RatingScaleView__submit-wpr pt-4 pb-2 d-flex justify-content-center justify-content-lg-end">
                                <Button
                                    disabled={!allowSubmit}
                                    type="button"
                                    onClick={submit}
                                    className="Btn Btn--pri Btn-sm">
                                    Submit
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RatingScaleView;
