/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const CalendarIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Calendar"
                {...props}>
                <path d="M11 17H8V14H11V17Z" />
                <path d="M11 24H8V21H11V24Z" />
                <path d="M14.5 17H17.5V14H14.5V17Z" />
                <path d="M17.5 24H14.5V21H17.5V24Z" />
                <path d="M21 17H24V14H21V17Z" />
                <path d="M24 24H21V21H24V24Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 24C4 26.2091 5.79086 28 8 28H24C26.2091 28 28 26.2091 28 24V8C28 5.79086 26.2091 4 24 4H8C5.79086 4 4 5.79086 4 8V24ZM24 6H8C6.89543 6 6 6.89543 6 8V10L26 10V8C26 6.89543 25.1046 6 24 6ZM26 24V12L6 12V24C6 25.1046 6.89543 26 8 26H24C25.1046 26 26 25.1046 26 24Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Calendar"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 8V24C4 26.2091 5.79086 28 8 28H24C26.2091 28 28 26.2091 28 24V8C28 5.79086 26.2091 4 24 4H8C5.79086 4 4 5.79086 4 8ZM24 6H8C6.89543 6 6 6.89543 6 8V10H26V8C26 6.89543 25.1046 6 24 6ZM11 17H8V14H11V17ZM11 24H8V21H11V24ZM14.5 17H17.5V14H14.5V17ZM17.5 24H14.5V21H17.5V24ZM21 17H24V14H21V17ZM24 24H21V21H24V24Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Calendar"
                {...props}>
                <g>
                    <path d="M12.5 14C12.5 13.1716 13.1716 12.5 14 12.5H26C26.8284 12.5 27.5 13.1716 27.5 14V15.5H12.5V14Z" />
                    <path d="M14 20.75H16.25V18.5H14V20.75Z" />
                    <path d="M14 26H16.25V23.75H14V26Z" />
                    <path d="M21.125 18.5V20.75H18.875V18.5H21.125Z" />
                    <path d="M18.875 23.75V26H21.125V23.75H18.875Z" />
                    <path d="M26 20.75H23.75V18.5H26V20.75Z" />
                    <path d="M23.75 26H26V23.75H23.75V26Z" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM14 29C12.3431 29 11 27.6569 11 26V14C11 12.3431 12.3431 11 14 11H26C27.6569 11 29 12.3431 29 14V26C29 27.6569 27.6569 29 26 29H14Z"
                    />
                </g>
            </IconWrapper>
        );
    }

    return null;
};

CalendarIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

CalendarIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
