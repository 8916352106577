import images from "../../../../utils/images";
import Button from "../../../UI/Button";
import React from "react";
import { withRouter } from "react-router-dom";
import { api } from "../../../../APIRequests";
import { connect } from "react-redux";
import { showAlertWithAction } from "../../../../redux/actions/alerts";
import "./styles.scss";
import { getQueryParams } from "../../../../utils/filters";

const ChooseNewOrExistingMemberWorkflow = (props) => {
    const practiceId = getQueryParams("practiceId", props.location.search);

    const { sharedWorkflowData, setSharedWorkflowData } = props;

    const submitHandler = async (isExistingUser) => {
        try {
            const options = { params: { showLoader: true } };
            const data = {
                workflow_instance_entity_id: sharedWorkflowData.workflow_instance.entity_id,
                workflow_instance_sort_key: sharedWorkflowData.workflow_instance.sort_key,
                node_name: "choose_workflow",
                state: {
                    practice_id: practiceId,
                    is_existing_user: isExistingUser,
                },
            };
            const response = await api.workflow.update_state_and_get_next_node({ options, data });
            setSharedWorkflowData({
                ...sharedWorkflowData,
                workflow_instance: response.workflow_instance,
            });
        } catch (e) {
            console.log(e);
            props.showAlertWithAction("Error updating workflow.");
        }
    };

    return (
        <div>
            <div className={"your-fast-track-banner"} />

            <div className={"purple-banner"}>
                <div
                    className={"workflow-container-md text-center py-4"}
                    style={{ color: "white" }}>
                    With aptihealth, you’re quickly connected with an expert care team that helps
                    you to make positive life changes and feel better within 90 days.
                </div>
            </div>

            <div className={"self-signup-inner-content"}>
                <div className={"p-lg-5 p-3"}>
                    <div className={"workflow-container-lg mb-5 text-center"}>
                        <div className={"fs-22 txt-pri fw-bold mb-4 text-center"}>
                            Let’s begin the screening process
                        </div>

                        <div className={"d-flex justify-content-center"}>
                            <div className={"fs-20 txt-gry"} style={{ maxWidth: 600 }}>
                                aptihealth’s short screening takes only 5 minutes and can help you
                                and your doctor decide if video therapy is right for you.
                            </div>
                        </div>

                        <div className={"d-flex justify-content-center"}>
                            <div className={"mb-4 fs-20 txt-gry pb-4"} style={{ maxWidth: 600 }}>
                                Ready to take the next step?
                            </div>
                        </div>

                        <div className={"pb-5"}>
                            <Button
                                type={"button"}
                                className={"Btn Btn--pri workflow-btn-lg"}
                                onClick={() => submitHandler(false)}>
                                I'm a new patient
                            </Button>
                        </div>

                        <div>
                            <Button
                                type={"button"}
                                className={"Btn Btn--otl-gry workflow-btn-lg"}
                                onClick={() => submitHandler(true)}>
                                I already have an account
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(null, { showAlertWithAction })(
    withRouter(ChooseNewOrExistingMemberWorkflow),
);
