// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";

const TextArea = (props) => {
    const { name, value, rows, cols, onChange } = props;
    return (
        <span className={"TextArea " + props.spanClasses}>
            <textarea
                id={props.ids}
                className={"TextArea__input " + props.classes}
                name={name}
                rows={rows || 3}
                cols={cols || 50}
                value={value}
                onChange={onChange}
                placeholder={props.placeholder || ""}
                disabled={props.disabled}
            />
        </span>
    );
};

export default TextArea;
