import React from "react";

import { getIn } from "formik";
import "./styles.scss";
import { CheckboxOld, Text, TextColors, TextTypes } from "../../../component-library";
import _ from "lodash";

// NOTE: THIS PATTERN WAS COPIED FROM the care management form
export const WaitlistFormCheckbox = ({
    checkboxOptions = [],
    name,
    formikProps,
    checkboxWrapperClassName,
    checkboxClassName,
}) => {
    const errorMessage = getIn(formikProps.errors, name);
    const isTouched = getIn(formikProps.touched, name);
    const checkedValues = formikProps.values[name];

    const handleChange = async (event, isChecked) => {
        const value = event.target.value;
        let newCheckedValue = _.cloneDeep(checkedValues);

        // if the checkbox is not currently checked
        // check it
        if (!isChecked) {
            newCheckedValue.push(value);
        }
        // if it is checked remove it from the checked values
        else {
            newCheckedValue = newCheckedValue.filter((item) => item !== value);
        }

        await formikProps.setFieldValue(name, newCheckedValue);
    };
    return (
        <>
            <div className={checkboxWrapperClassName}>
                {checkboxOptions.map((option) => {
                    const checked = checkedValues?.includes(option.value);
                    return (
                        <CheckboxOld
                            key={option.value}
                            version="v2"
                            name={option.value}
                            label={option.label}
                            value={option.value}
                            disabled={option.disabled ?? false}
                            checked={checked}
                            onClick={(e) => handleChange(e, checked)}
                            className={checkboxClassName}
                            labelClassName={"ml-2"}
                        />
                    );
                })}
            </div>

            {errorMessage && isTouched && (
                <Text className="pt-2" type={TextTypes.small} color={TextColors.red}>
                    {errorMessage}
                </Text>
            )}
        </>
    );
};
