import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { createClassNameString } from "../../component-library";
import { ICON_SIZES, ICON_TYPES } from "../../constants/icons";

export const CameraIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.5241 16L27 19.7299V12.2701L22.5241 16ZM21 10C21 9.44772 20.5523 9 20 9H7C6.44772 9 6 9.44772 6 10V22C6 22.5523 6.44772 23 7 23H20C20.5523 23 21 22.5523 21 22V10ZM23 10C23 8.34315 21.6569 7 20 7H7C5.34315 7 4 8.34315 4 10V22C4 23.6569 5.34315 25 7 25H20C21.6569 25 23 23.6569 23 22V19L27.3598 22.6332C28.0111 23.1759 29 22.7128 29 21.865V10.135C29 9.2872 28.0111 8.82404 27.3598 9.36681L23 13V10Z"
                />
            </svg>
        );
    }

    return null;
};

CameraIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

CameraIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
