// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import GuidedCallProgress from "../GuidedCallProgress";
import GuidedCallActionIcons from "../GuidedCallActionIcons";

const GuidedVideoSection = (props) => {
    const workflow = props.workflow ? (
        <ul className="Toolbar__menu d-none d-lg-flex h-100 ml-0 ml-xl-5 w-100">
            <div className="d-flex w-100 justify-content-between">
                <GuidedCallProgress workflow={props.workflow} currentStep={props.currentStep} />
                <GuidedCallActionIcons showLauncher={props.showLauncher} {...props} />
            </div>
        </ul>
    ) : null;

    return workflow;
};

export default GuidedVideoSection;
