import { api } from "../APIRequests";

export const reportCreditCardError = async (description, error_message) => {
    try {
        let report = {
            type: "credit_card_frontend_error",
            description: description,
            context: {
                error_message: error_message,
            },
        };
        await api.shared.post_exception_report({ report });
    } catch (e) {
        console.log(e);
    }
};
