// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useState } from "react";
import Hr from "../../UI/Hr";
import Button from "../../UI/Button";
import Toggle from "../../UI/Toggle";

const ProfileDetailsCard = (props) => {
    const { editButtonText = "Edit" } = props;

    return (
        <div className="PatientProfile__ProfileDetailsCard p-3 p-lg-4 mb-3 mb-lg-4">
            <div className="d-flex justify-content-between">
                <h1 className="fs-16 fw-bold ml-2 mb-3 pb-1">{props.heading}</h1>
                {props.type !== "Toggle" && !props.doNotShowEditOrToggle && (
                    <Button
                        onClick={props.onclick}
                        className={`ProviderProfile__ProfileDetailsCard-controls cursor mr-2 fs-14 fw-bold mb-3 ${
                            props.edit ? "d-none" : ""
                        }`}>
                        {editButtonText}
                    </Button>
                )}
                {props.type === "Toggle" && !props.doNotShowEditOrToggle && (
                    <Toggle selected={props.selected} toggleSelected={props.onclick} />
                )}
            </div>
            {props.type !== "Toggle" && <Hr />}
            {props.children}
        </div>
    );
};

export default ProfileDetailsCard;
