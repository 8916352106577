// Copyright aptihealth, inc. 2019 All Rights Reserved
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { Footer } from "../../../component-library/Footer";
import InsuranceEligibilityQueueManagement from "../../../components/Admin/InsuranceEligibilityQueueManagement";
import InsuranceEligibilityRecordManagement from "../../../components/Admin/InsuranceEligibilityRecordManagement";
import ChangePassword from "../../../components/Authentication/ChangePassword";
import A5ScreeningDetails from "../../../components/Common/A5ScreeningDetails";
import A5ScreeningList from "../../../components/Common/A5ScreeningList";
import PrivacyPolicy from "../../../components/Common/PrivacyPolicy";
import TutorialScreens from "../../../components/Common/TutorialScreen";
import Toolbar from "../../../components/Navigation/Toolbar";
import DraftNotesTable from "../../../components/Provider/DraftNotes";
import MemberProfile from "../../../components/Provider/PatientView/MemberProfile";
import DischargeData from "../../../components/Provider/PatientView/MemberProfile/DischargeData";
import PrintNoteView from "../../../components/Provider/PatientView/MemberProfile/PrintNoteView";
import Profile from "../../../components/Provider/Profile";
import AddAppointment from "../../../components/Provider/ScheduleAvailability/AddAppointment";
import { admin_admin, admin_bp, admin_cse, adminRoute, baseRoute } from "../../../config/roles";
import Layout from "../../../hoc/Layout/Layout";
import { getAdminProfile, isAuthorized } from "../../../redux/actions/auth";
import GAD7 from "../Patient/GAD7";
import PHQ9 from "../Patient/PHQ9";
import A5Report from "../Providers/A5Report";
import FileList from "../Providers/FileList";
import IBARReport from "../Providers/IBARReport";
import IPSRReport from "../Providers/IPSRReport";
import NewReport from "../Providers/NewReport";
import OBARReport from "../Providers/OBARReport";
import OPSRReport from "../Providers/OPSRReport";
import OnBehalfOfSchedule from "../Providers/OnBehalfOfSchedule";
import Patient from "../Providers/Patient";
import CareTeamList from "../Providers/PatientProfileView/CareTeamList";
import ClinicalAssessment from "../Providers/PatientProfileView/ClinicalAssessment";
import NewNote from "../Providers/PatientProfileView/NewNote";
import ProgressNoteList from "../Providers/PatientProfileView/ProgressNoteList";
import Ratings from "../Providers/PatientProfileView/Ratings";
import ViewDraftNote from "../Providers/PatientProfileView/ViewDraftNote";
import ViewDraftNoteV2 from "../Providers/PatientProfileView/ViewDraftNoteV2";
import ViewNote from "../Providers/PatientProfileView/ViewNote";
import PatientsList from "../Providers/PatientsList";
import ReferralView from "../Providers/ReferralView";
import Reports from "../Providers/Reports";
import SchedulePageV2 from "../Providers/SchedulePageV2";
import WorkingHours from "../Providers/WorkingHours";
import Settings from "../common/SettingsPage";
import ViewFullNote from "../common/ViewFullNote";
import AdminProfile from "./AdminProfile";
import CustomerSuccessManagerList from "./CustomerSuccessManagerList";
import NewPractice from "./NewPractice";
import NewProvider from "./NewProvider";
import OmnisearchList from "./OmnisearchList";
import Practice from "./Practice";
import PracticeList from "./PracticeList";
import Provider from "./Provider";
import mapLabelToComponent from "../common/mapLabelToComponent";
import { WaitlistForm } from "../../../components/Common/WaitlistForm/WaitlistForm";
import { AdvancedCareActivityNewForm } from "../../../components/Provider/PatientView/MemberProfile/AdvancedCareActivity/AdvancedCareActivityNewForm";
import { hasAccessToCreateAdvancedCareActivityNote } from "../../../components/Provider/PatientView/MemberProfile/AdvancedCareActivity/AdvancedCareActivityScheme";

class Admin extends Component {
    DEFAULT_ROUTE_SECTION_OPTIONS = [
        {
            label: "Practices",
            icon: "NavMenu__practices-icon",
            mobileIcon: "practices.svg",
            route: adminRoute + "/practice",
        },
        {
            label: "Screening",
            icon: "NavMenu__screening-icon",
            mobileIcon: "screening.svg",
            route: adminRoute + "/a5_screenings",
        },
        {
            label: "Reports",
            icon: "NavMenu__reports-icon",
            mobileIcon: "reports.svg",
            route: adminRoute + "/reports",
        },
        {
            label: "Settings",
            icon: "NavMenu__settings-icon",
            mobileIcon: "settings.svg",
            route: adminRoute + "/settings",
        },
    ];

    ADMIN_OR_CSE_ROUTE_SECTION_OPTIONS = [
        {
            label: "Practices",
            icon: "NavMenu__practices-icon",
            mobileIcon: "practices.svg",
            route: adminRoute + "/practice",
            iconComponent: mapLabelToComponent("Practices", false),
            activeIconComponent: mapLabelToComponent("Practices", true),
        },
        {
            label: "Screening",
            icon: "NavMenu__screening-icon",
            mobileIcon: "screening.svg",
            route: adminRoute + "/a5_screenings",
            iconComponent: mapLabelToComponent("Screening", false),
            activeIconComponent: mapLabelToComponent("Screening", true),
        },
        {
            label: "Customer Success",
            icon: "NavMenu__patients-icon",
            mobileIcon: "customer_success.svg",
            route: adminRoute + "/customer_success",
            iconComponent: mapLabelToComponent("Customer Success", false),
            activeIconComponent: mapLabelToComponent("Customer Success", true),
        },
        {
            label: "Add New Practice",
            icon: "NavMenu__settings-icon",
            mobileIcon: "add_new.svg",
            mobileOnly: true,
            route: adminRoute + "/practice/add",
        },
        {
            label: "Reports",
            icon: "NavMenu__reports-icon",
            mobileIcon: "reports.svg",
            route: adminRoute + "/reports",
            iconComponent: mapLabelToComponent("Reports", false),
            activeIconComponent: mapLabelToComponent("Reports", true),
        },
        {
            label: "Eligibility",
            icon: "NavMenu__eligibility-icon",
            mobileIcon: "eligibility-icon-navy.svg",
            route: adminRoute + "/eligibility",
            iconComponent: mapLabelToComponent("Eligibility", false),
            activeIconComponent: mapLabelToComponent("Eligibility", true),
        },
        {
            label: "Search",
            route: adminRoute + "/search",
            iconComponent: mapLabelToComponent("Search", false),
        },
    ];

    BILLING_PARTNER_ROUTE_SECTION_OPTIONS = [
        {
            label: "Reports",
            icon: "NavMenu__reports-icon",
            mobileIcon: "reports.svg",
            route: adminRoute + "/reports",
        },
        {
            label: "Settings",
            icon: "NavMenu__settings-icon",
            mobileIcon: "settings.svg",
            route: adminRoute + "/settings",
        },
        {
            label: "Search",
            route: adminRoute + "/search",
            iconComponent: mapLabelToComponent("Search", false),
        },
    ];

    get_route_section_options() {
        if (isAuthorized(admin_cse) || isAuthorized(admin_admin)) {
            return this.ADMIN_OR_CSE_ROUTE_SECTION_OPTIONS;
        } else if (isAuthorized(admin_bp)) {
            return this.BILLING_PARTNER_ROUTE_SECTION_OPTIONS;
        }
        return this.DEFAULT_ROUTE_SECTION_OPTIONS;
    }

    ROUTE_SECTION_OPTIONS = this.get_route_section_options();

    ADMIN_BASE_REDIRECT_ROUTE = isAuthorized(admin_bp)
        ? `${adminRoute}/reports`
        : `${adminRoute}/practice`;

    navbarConfiguration = {
        routeSection: {
            options: this.ROUTE_SECTION_OPTIONS,
        },
        customRenderSection: [],
        selfSection: {},
    };

    componentDidMount() {
        this.props.getAdminProfile();
    }

    render() {
        return (
            <Layout>
                <Toolbar
                    dynamicRouteConfig={{}}
                    navbarConfiguration={this.navbarConfiguration}
                    baseRoute={baseRoute}
                    enableSearch={true}></Toolbar>
                <Switch>
                    {isAuthorized(admin_cse) && (
                        <Route
                            path={adminRoute + "/patient/:patientId/waitlist_form"}
                            component={WaitlistForm}
                        />
                    )}
                    <Route
                        path={adminRoute + "/settings"}
                        render={() => <Settings baseRoute={adminRoute} />}
                    />
                    <Route
                        path={adminRoute + "/schedule/:providerId/view"}
                        render={(props) => <SchedulePageV2 {...props} />}
                    />
                    <Route
                        path={adminRoute + "/schedule/:providerId/add"}
                        component={AddAppointment}
                    />
                    <Route
                        path={adminRoute + "/schedule/:providerId"}
                        component={OnBehalfOfSchedule}
                    />
                    <Route
                        path={adminRoute + "/profile/hours/:providerId"}
                        render={(props) => <WorkingHours {...props} baseRoute={adminRoute} />}
                    />
                    <Route
                        path={adminRoute + "/profile/:providerId"}
                        render={(props) => <Profile {...props} baseRoute={adminRoute} />}
                    />
                    <Route path={adminRoute + "/profile"} component={AdminProfile} />
                    <Route path={adminRoute + "/reports/add"} component={NewReport} />
                    <Route
                        path={adminRoute + "/reports"}
                        render={(props) => <Reports {...props} baseRoute={adminRoute} />}
                    />
                    <Route
                        path={adminRoute + "/privacy-policy"}
                        render={() => <PrivacyPolicy baseRoute={adminRoute} />}
                    />
                    <Route
                        path={adminRoute + "/tutorial"}
                        render={() => <TutorialScreens baseRoute={adminRoute} />}
                    />
                    <Route
                        path={adminRoute + "/change-password"}
                        render={(props) => <ChangePassword {...props} baseRoute={adminRoute} />}
                    />
                    <Route path={adminRoute + "/practice/add"} component={NewPractice} />
                    <Route
                        path={adminRoute + "/practice/:practiceId/provider/add"}
                        render={(props) => <NewProvider {...props} baseRoute={adminRoute} />}
                    />
                    <Route
                        path={
                            adminRoute + "/practice/:practiceId/provider/:providerId/patientsList"
                        }
                        render={(props) => (
                            <PatientsList
                                practiceId={props.match.practiceId}
                                providerId={props.match.providerId}
                                {...props}
                                baseRoute={adminRoute}
                            />
                        )}
                    />
                    <Route
                        path={adminRoute + "/practice/:practiceId/provider/:providerId"}
                        render={(props) => <Provider {...props} baseRoute={adminRoute} />}
                    />
                    <Route
                        path={adminRoute + "/practice/:practiceId"}
                        render={(props) => <Practice {...props} baseRoute={adminRoute} />}
                    />
                    <Route
                        path={adminRoute + "/practice"}
                        render={(props) => <PracticeList {...props} baseRoute={adminRoute} />}
                    />
                    <Route
                        path={adminRoute + "/search"}
                        render={(props) => <OmnisearchList {...props} baseRoute={adminRoute} />}
                    />
                    <Route
                        path={adminRoute + "/patient/:patientId/file"}
                        render={(props) => <FileList {...props} type={"patient"} />}
                    />
                    <Route path={adminRoute + "/patient/:patientId/note/add"} component={NewNote} />
                    <Route
                        path={adminRoute + "/patient/:patientId/note/:noteId/v2/draft"}
                        component={ViewDraftNoteV2}
                    />
                    <Route
                        path={adminRoute + "/patient/:patientId/note/:noteId/draft"}
                        component={ViewDraftNote}
                    />
                    <Route
                        path={adminRoute + "/patient/:patientId/export_notes"}
                        component={PrintNoteView}
                    />
                    <Route
                        path={adminRoute + "/patient/:patientId/note/:noteId/v2"}
                        component={ViewFullNote}
                    />
                    <Route
                        path={adminRoute + "/patient/:patientId/note/:noteId"}
                        component={ViewNote}
                    />
                    <Route
                        path={adminRoute + "/patient/:patientId/note"}
                        component={ProgressNoteList}
                    />
                    {hasAccessToCreateAdvancedCareActivityNote() && (
                        <Route
                            path={adminRoute + "/patient/:patientId/advanced_care_activity"}
                            component={AdvancedCareActivityNewForm}
                        />
                    )}
                    <Route
                        path={adminRoute + "/patient/:patientId/clinical_assessment"}
                        component={ClinicalAssessment}
                    />
                    <Route
                        path={adminRoute + "/patient/:patientId/profile/v2"}
                        component={MemberProfile}
                    />
                    <Route
                        path={adminRoute + "/patient/:patientId/care_team"}
                        component={CareTeamList}
                    />
                    <Route
                        path={adminRoute + "/patient/:patientId/report/a5"}
                        component={A5Report}
                    />
                    <Route
                        path={adminRoute + "/patient/:patientId/report/ibar"}
                        component={IBARReport}
                    />
                    <Route
                        path={adminRoute + "/patient/:patientId/report/ipsr"}
                        component={IPSRReport}
                    />
                    <Route
                        path={adminRoute + "/patient/:patientId/report/obar"}
                        component={OBARReport}
                    />
                    <Route
                        path={adminRoute + "/patient/:patientId/report/opsr"}
                        component={OPSRReport}
                    />
                    <Route path={adminRoute + "/patient/:patientId/ratings"} component={Ratings} />
                    <Route path={adminRoute + "/patient/:patientId/phq-9"} component={PHQ9} />
                    <Route path={adminRoute + "/patient/:patientId/gad-7"} component={GAD7} />
                    <Route path={adminRoute + "/patient/:patientId/report"} component={Reports} />
                    <Route
                        path={adminRoute + "/patient/:patientId/discharge/:dischargeId"}
                        component={DischargeData}
                    />
                    <Route path={adminRoute + "/patient/:patientId"} component={Patient} />
                    {(isAuthorized(admin_cse) || isAuthorized(admin_admin)) && (
                        <Route
                            path={adminRoute + "/customer_success"}
                            component={CustomerSuccessManagerList}
                        />
                    )}
                    <Route
                        path={adminRoute + "/a5_screenings/:screening_id/file"}
                        render={(props) => <FileList {...props} type={"a5_screenings"} />}
                    />
                    <Route
                        path={adminRoute + "/a5_screenings/:screening_id"}
                        component={A5ScreeningDetails}
                    />
                    <Route path={adminRoute + "/a5_screenings"} component={A5ScreeningList} />
                    <Route
                        path={adminRoute + "/referral/:referralCode/file"}
                        render={(props) => <FileList {...props} type={"referral"} />}
                    />
                    <Route path={adminRoute + "/referral/:referralCode"} component={ReferralView} />
                    <Route
                        path={adminRoute + "/draft-notes/:providerId"}
                        component={DraftNotesTable}
                    />
                    {(isAuthorized(admin_cse) || isAuthorized(admin_admin)) && (
                        <Route
                            path={adminRoute + "/eligibility/:id"}
                            component={InsuranceEligibilityRecordManagement}
                        />
                    )}
                    {(isAuthorized(admin_cse) || isAuthorized(admin_admin)) && (
                        <Route
                            path={adminRoute + "/eligibility"}
                            component={InsuranceEligibilityQueueManagement}
                        />
                    )}
                    <Redirect from={adminRoute} to={this.ADMIN_BASE_REDIRECT_ROUTE} />
                </Switch>
                <Footer />
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

Admin.propTypes = {
    url: PropTypes.string,
    profile: PropTypes.object,
    history: PropTypes.object,
    getAdminProfile: PropTypes.func,
};

export default connect(mapStateToProps, { getAdminProfile })(Admin);
