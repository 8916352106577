// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";

const SchedulingUnavailable = (props) => {
    return (
        <>
            <div className={"m-auto scheduling-not-allowed-desc"}>
                <div className={"fw-bold fs-24 mb-3"}>No Sessions Scheduled</div>

                <div>
                    You’ll need to answer some questions before you can schedule your first session.
                    Navigate to your Home screen to get started!
                </div>
            </div>
        </>
    );
};

export default SchedulingUnavailable;
