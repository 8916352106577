// Copyright aptihealth, inc. 2019 All Rights Reserved
import { httpAuth } from "../utils/axios";
import { getGroupAuthType } from "../redux/actions/auth";
import { GROUP_AUTH_HEADER_NAME } from "../constants/axios";

export const carePlanRequests = {
    get_goal_and_objective_text: ({ urlParams, params }) => {
        let url = `/v1/care-plan/${urlParams.patientId}/get_goal_and_objective_text`;
        let conf = {
            method: "post",
            url: url,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            params,
        };
        return httpAuth(conf);
    },
    get_goals_and_objectives: ({ urlParams, params }) => {
        let url = `/v1/care-plan/${urlParams.patientId}/get_goals_and_objectives`;
        let conf = {
            method: "post",
            url: url,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            params,
        };
        return httpAuth(conf);
    },

    add_objective: ({ data, urlParams, params }) => {
        let url = `/v1/care-plan/${urlParams.patientId}/add_objective`;
        let conf = {
            method: "post",
            url: url,
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            params,
        };
        return httpAuth(conf);
    },
    update_and_add_objective: ({ data, urlParams, params }) => {
        let url = `/v1/care-plan/${urlParams.patientId}/update_and_add_objective`;
        let conf = {
            method: "post",
            url: url,
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            params,
        };
        return httpAuth(conf);
    },

    update_goal_and_objective: ({ data, urlParams, params }) => {
        let url = `/v1/care-plan/${urlParams.patientId}/update_goal_and_objective`;
        let conf = {
            method: "post",
            url: url,
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            params,
        };
        return httpAuth(conf);
    },

    update_objective_meta_data: ({ data, urlParams, params }) => {
        let url = `/v1/care-plan/${urlParams.patientId}/update_objective_meta_data`;
        let conf = {
            method: "post",
            url: url,
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            params,
        };
        return httpAuth(conf);
    },

    update_objective: ({ data, urlParams, params }) => {
        let url = `/v1/care-plan/${urlParams.patientId}/update_objective`;
        let conf = {
            method: "post",
            url: url,
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            params,
        };
        return httpAuth(conf);
    },
    get_modality_text: ({ urlParams, params }) => {
        let url = `/v1/care-plan/${urlParams.patientId}/get_modality_text`;
        let conf = {
            method: "post",
            url: url,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            params,
        };
        return httpAuth(conf);
    },
    change_modality: ({ data, urlParams, params }) => {
        let url = `/v1/care-plan/${urlParams.patientId}/change_modality`;
        let conf = {
            method: "post",
            url: url,
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            params,
        };
        return httpAuth(conf);
    },

    get_patient_strength_and_caution_options: ({ urlParams, params }) => {
        const conf = {
            method: "post",
            url: `/v1/care-plan/${urlParams.patientId}/get_patient_strength_and_caution_options`,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            params,
        };
        return httpAuth(conf);
    },

    update_patient_articulated_goals: ({ data, urlParams, params, errorTypeConfig }) => {
        const conf = {
            method: "post",
            url: `/v1/care-plan/${urlParams.patientId}/update_patient_articulated_goals`,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            params,
            data,
            errorTypeConfig,
        };
        return httpAuth(conf);
    },

    get_patient_articulated_goals: ({ urlParams, params }) => {
        const conf = {
            method: "post",
            url: `/v1/care-plan/${urlParams.patientId}/get_patient_articulated_goals`,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            params,
        };
        return httpAuth(conf);
    },

    update_patient_strengths: ({ data, urlParams, params, errorTypeConfig }) => {
        const conf = {
            method: "post",
            url: `/v1/care-plan/${urlParams.patientId}/update_patient_strengths`,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            params,
            data,
            errorTypeConfig,
        };
        return httpAuth(conf);
    },

    get_patient_strengths: ({ urlParams, params }) => {
        const conf = {
            method: "post",
            url: `/v1/care-plan/${urlParams.patientId}/get_patient_strengths`,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            params,
        };
        return httpAuth(conf);
    },

    update_patient_medical_cautions: ({ data, urlParams, params, errorTypeConfig }) => {
        const conf = {
            method: "post",
            url: `/v1/care-plan/${urlParams.patientId}/update_patient_medical_cautions`,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            params,
            data,
            errorTypeConfig,
        };
        return httpAuth(conf);
    },

    get_patient_medical_cautions: ({ urlParams, params }) => {
        const conf = {
            method: "post",
            url: `/v1/care-plan/${urlParams.patientId}/get_patient_medical_cautions`,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            params,
        };
        return httpAuth(conf);
    },
};
