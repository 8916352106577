// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useEffect, useState } from "react";
import Button from "../../../components/UI/Button";
import { Select } from "../../UI/StyledInput";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { eventTypeDisplayMini } from "./ActiveScheduleDetails";
import { FormField, RadioGroup, RadioOld } from "../../../component-library";
import { AVAILABLE } from "../../../constants/event";
import {
    CalendarDeleteAvailabilityModal,
    RECURRING_AVAILABILITY_VALUE,
} from "../../Calendar/CalendarModals/CalendarDeleteAvailabilityModal";

const AWAY = "away";
const VIDEO = "video";

const CONFLICT_SELF = "conflict_self";
const CONFLICT_MEMBER = "conflict_member";
const OTHER = "other";

const CONFLICT_SELF_LABEL = "I have a conflict with this time";
const CONFLICT_MEMBER_LABEL = "The member has a conflict with this time";
const OTHER_LABEL = "Other";

const VIDEO_CALL_RECURRING_TITLE = "Cancel Session?";

const SELECTED_SESSION = "selected_session";
const SELECTED_SESSION_LABEL = "This session";

const SELECTED_AND_REOCCURRING_SESSIONS = "selected_and_reoccurring_sessions";
const SELECTED_AND_REOCCURRING_SESSIONS_LABEL = "This and all reoccurring sessions";

const TWENTY_FOUR_NOTICE_YES = "yes";
const TWENTY_FOUR_NOTICE_NO = "no";

const TWENTY_FOUR_NOTICE_YES_LABEL = "Yes";
const TWENTY_FOUR_NOTICE_NO_LABEL = "No";

const DELETE_SERIES_OPTIONS = [
    { value: SELECTED_SESSION, display: SELECTED_SESSION_LABEL },
    { value: SELECTED_AND_REOCCURRING_SESSIONS, display: SELECTED_AND_REOCCURRING_SESSIONS_LABEL },
];

const REASON_OPTIONS = [
    { value: CONFLICT_SELF, display: CONFLICT_SELF_LABEL },
    { value: CONFLICT_MEMBER, display: CONFLICT_MEMBER_LABEL },
    { value: OTHER, display: OTHER_LABEL },
];

const TWENTY_FOUR_NOTICE_OPTIONS = [
    { value: TWENTY_FOUR_NOTICE_YES, display: TWENTY_FOUR_NOTICE_YES_LABEL },
    { value: TWENTY_FOUR_NOTICE_NO, display: TWENTY_FOUR_NOTICE_NO_LABEL },
];

const seriesRadioOptions = [
    {
        label: SELECTED_SESSION_LABEL,
        value: SELECTED_SESSION,
    },
    {
        label: SELECTED_AND_REOCCURRING_SESSIONS_LABEL,
        value: SELECTED_AND_REOCCURRING_SESSIONS,
    },
];

const CancelSeriesFormField = ({ type, deleteSeriesOnChange, deleteSeries }) => {
    return (
        <>
            <FormField label={VIDEO_CALL_RECURRING_TITLE}>
                <div className="ScheduleDeleteDialog__input-title--divider" />
                <RadioGroup
                    name="delete_series"
                    onChange={deleteSeriesOnChange}
                    className={`ScheduleDeleteDialog__rd${
                        type === AVAILABLE ? "--availability" : ""
                    }`}>
                    <RadioOld
                        label={seriesRadioOptions[0].label}
                        value={seriesRadioOptions[0].value}
                    />
                    <RadioOld
                        label={seriesRadioOptions[1].label}
                        value={seriesRadioOptions[1].value}
                    />
                </RadioGroup>
                <div className="ScheduleDeleteDialog__sel">
                    <Select
                        className={"ScheduleDeleteDialog__sel--select"}
                        options={DELETE_SERIES_OPTIONS}
                        value={deleteSeries}
                        placeholder={{ display: "Select" }}
                        changeHandler={deleteSeriesOnChange}
                    />
                </div>
            </FormField>
        </>
    );
};

const ScheduleDeleteDialog = (props) => {
    const { scheduleDelete, cancelDelete, type, callId, provider } = props;

    const [cancellationReason, setCancellationReason] = useState(undefined);
    const [deleteSeries, setDeleteSeries] = useState(undefined);
    const [twentyFourNotice, setTwentyFourNotice] = useState(undefined);
    const [formComplete, setFormComplete] = useState(false);

    let callInfo;

    if (type === VIDEO) {
        callInfo = provider.scheduleCallList.callList.find((call) => call.callId === callId);
    }

    if (type === AWAY && !callId) {
        callInfo = {};
    }

    if (!callInfo) {
        // check if call exists in the viewable events on screen
        const calendarEvents = props.calendar?.events ?? [];
        callInfo = calendarEvents.find((call) => call.callId === callId);
        // if it does not it must exist in the list of selected events
        if (!callInfo) {
            const selectedEvents = props.calendar?.selectedEvents ?? [];
            callInfo = selectedEvents.find((call) => call.callId === callId);
        }
    }

    const { timestamp, event_type, patient_name, series_id } = callInfo;

    const dateTimeLocal = moment.utc(timestamp, "YYYY-MM-DD HH:mm").local();

    useEffect(() => {
        setFormComplete(isFormComplete());
    }, [cancellationReason, deleteSeries, twentyFourNotice]);

    const isFormComplete = () => {
        const deleteSeriesComplete = series_id ? deleteSeries : true;
        const cancellationReasonComplete =
            cancellationReason === CONFLICT_MEMBER
                ? cancellationReason && twentyFourNotice
                : cancellationReason;

        return deleteSeriesComplete && cancellationReasonComplete;
    };

    const reasonOnChange = (e) => {
        setCancellationReason(e.target.value);
        setTwentyFourNotice(undefined);
    };

    const deleteSeriesOnChange = (e) => {
        setDeleteSeries(e.target.value);
    };

    const twentyFourNoticeOnChange = (e) => {
        setTwentyFourNotice(e.target.value);
    };

    const scheduleDeleteHandler = (deleteSeriesArg) => {
        let internalDeleteSeries = deleteSeriesArg;
        if (!internalDeleteSeries) {
            internalDeleteSeries = deleteSeries;
        }
        const shouldDeleteSeries =
            internalDeleteSeries === SELECTED_AND_REOCCURRING_SESSIONS ||
            internalDeleteSeries === RECURRING_AVAILABILITY_VALUE;
        scheduleDelete(cancellationReason, twentyFourNotice, shouldDeleteSeries);
    };

    const renderEventType = () => {
        return eventTypeDisplayMini(event_type, false);
    };

    const renderCancellationReasonFormField = () => {
        return (
            <>
                <FormField label={"What is the reason for the cancellation?"}>
                    <div className="ScheduleDeleteDialog__input-title--divider" />
                    <RadioGroup
                        name="cancellation_reason"
                        onChange={reasonOnChange}
                        className="ScheduleDeleteDialog__rd">
                        <RadioOld label={CONFLICT_SELF_LABEL} value={CONFLICT_SELF} />
                        <RadioOld label={CONFLICT_MEMBER_LABEL} value={CONFLICT_MEMBER} />
                        {render24HourNoticeFormField()}
                        <RadioOld label={OTHER_LABEL} value={OTHER} />
                    </RadioGroup>
                    {render24HourNoticeSelect()}
                </FormField>
            </>
        );
    };

    const render24HourNoticeFormField = () => {
        return (
            <>
                <FormField
                    label={"Did member provide 24 hours' notice?"}
                    className={`ScheduleDeleteDialog__rd--24${
                        cancellationReason === CONFLICT_MEMBER ? " active" : ""
                    }`}>
                    <RadioGroup name={"twenty_four_notice"} onChange={twentyFourNoticeOnChange}>
                        <RadioOld
                            label={TWENTY_FOUR_NOTICE_YES_LABEL}
                            value={TWENTY_FOUR_NOTICE_YES}
                        />
                        <RadioOld
                            label={TWENTY_FOUR_NOTICE_NO_LABEL}
                            value={TWENTY_FOUR_NOTICE_NO}
                        />
                    </RadioGroup>
                </FormField>
            </>
        );
    };

    const render24HourNoticeSelect = () => {
        return (
            <>
                <div className="ScheduleDeleteDialog__sel">
                    <Select
                        className={"ScheduleDeleteDialog__sel--select"}
                        options={REASON_OPTIONS}
                        value={cancellationReason}
                        placeholder={{ display: "Select a reason" }}
                        changeHandler={reasonOnChange}
                    />
                    <div
                        className={`ScheduleDeleteDialog__sel--24${
                            cancellationReason === CONFLICT_MEMBER ? " active" : ""
                        }`}>
                        <h3 className="ScheduleDeleteDialog__input-title">
                            Did member provide 24 hours' notice?
                        </h3>
                        <Select
                            className={"ScheduleDeleteDialog__sel--select"}
                            options={TWENTY_FOUR_NOTICE_OPTIONS}
                            value={twentyFourNotice}
                            placeholder={{ display: "Select" }}
                            changeHandler={twentyFourNoticeOnChange}
                        />
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            {type === VIDEO && event_type !== AVAILABLE && (
                <div className="ScheduleDeleteDialog position-fixed d-flex align-items-center justify-content-center">
                    <div
                        className={`ScheduleDeleteDialog__inner--vid${
                            cancellationReason === CONFLICT_MEMBER ? " active" : ""
                        } d-flex flex-column text-center position-absolute`}>
                        <h2 className="ScheduleDeleteDialog__title">
                            You are cancelling the following session:
                        </h2>
                        <p className="ScheduleDeleteDialog__info">
                            {renderEventType()} with {patient_name}
                            <br />
                            {dateTimeLocal.format("MMMM D, YYYY h:mm a")}
                        </p>
                        {series_id && (
                            <CancelSeriesFormField
                                type={type}
                                deleteSeries={deleteSeries}
                                deleteSeriesOnChange={deleteSeriesOnChange}
                            />
                        )}
                        {renderCancellationReasonFormField()}
                        <div className="ScheduleDeleteDialog__btn-wpr d-flex">
                            <Button
                                onClick={cancelDelete}
                                type="button"
                                className="Btn Btn--otl-pri Btn-sm">
                                Keep Session
                            </Button>
                            <Button
                                disabled={!formComplete}
                                onClick={() => scheduleDeleteHandler()}
                                type="button"
                                className="Btn Btn--pri Btn-sm">
                                Cancel Session
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {type === AWAY && (
                <div className="ScheduleDeleteDialog position-fixed d-flex align-items-center justify-content-center">
                    <div className="ScheduleDeleteDialog__inner d-flex flex-column text-center position-absolute">
                        <h2 className="fs-17 fw-bold mt-4">Delete Away Slot</h2>
                        <span className="fs-15 mt-2">
                            Are you sure you want to delete the slot?
                        </span>
                        <div className="ScheduleDeleteDialog__btn-wpr d-flex justify-content-center pt-3 mt-4">
                            <Button
                                onClick={scheduleDeleteHandler}
                                type="button"
                                className="Btn Btn--otl-pri Btn-sm">
                                Yes
                            </Button>
                            <Button
                                onClick={cancelDelete}
                                type="button"
                                className="Btn Btn--pri Btn-sm">
                                No
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {event_type === AVAILABLE && (
                <CalendarDeleteAvailabilityModal
                    subTitleText={dateTimeLocal.format("MMMM D, YYYY h:mm a")}
                    isRecurring={Boolean(series_id)}
                    onCancel={cancelDelete}
                    onSubmit={scheduleDeleteHandler}
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        provider: state.provider,
        calendar: state.calendar,
    };
};

export default connect(mapStateToProps)(ScheduleDeleteDialog);
