import React from "react";
import "./styles.scss";
import { Checkbox, Text, TextColors, TextTypes } from "../../../../../component-library";
import { getIn } from "formik";
import _ from "lodash";
import moment from "moment/moment";

export const CareManagementSignatureField = ({ formikProps, name, nameToSign, isViewOnly }) => {
    const errorMessage = getIn(formikProps.errors, name);
    const isTouched = getIn(formikProps.touched, name);
    const signature = formikProps.values[name];

    const handleChange = async (event) => {
        let value = "";
        let date = "";
        if (event.target.checked) {
            value = nameToSign;
            date = moment().format("MM/DD/YYYY");
        }

        await formikProps.setFieldValue(name, value);
        await formikProps.setFieldValue("signature_date", date);
    };

    return (
        <>
            {isViewOnly && (
                <div className="CareManagementSignatureField--view_only_wrapper">
                    <p className="CareManagementFieldShared--title">Signature</p>
                    <p
                        style={{
                            fontFamily:
                                signature === ""
                                    ? "Helvetica, sans-serif"
                                    : "Apple Chancery, cursive",
                            fontSize: signature === "" ? undefined : 18,
                        }}>
                        {signature === "" ? "None" : signature}
                    </p>
                </div>
            )}
            {!isViewOnly && (
                <div className="CareManagementSignatureField--wrapper">
                    <label className="CareManagementSignatureField--click_to_sign_wrapper">
                        <Checkbox
                            checked={_.get(formikProps.values, name) !== ""}
                            disabled={isViewOnly}
                            onClick={handleChange}
                        />
                        <div>
                            <p className="CareManagementFieldShared--title">Click to Sign</p>
                            {errorMessage && isTouched && (
                                <Text type={TextTypes.small} color={TextColors.red} layout="block">
                                    {errorMessage}
                                </Text>
                            )}
                        </div>
                    </label>
                    <div
                        className="CareManagementSignatureField--signature_line"
                        style={{ fontFamily: "Apple Chancery, cursive" }}>
                        {"X " + formikProps.values[name]}
                    </div>
                </div>
            )}
        </>
    );
};
