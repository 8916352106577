import "./styles.scss";
import React, { useState } from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";
import { FormFieldError } from "../FormFields/FormFieldError/FormFieldError";

export const CurrencyInput = ({
    name,
    step,
    value,
    errorMessage,
    className,

    onChange,
    onFocus,
    onBlur,
    ...props
}) => {
    // since the dollar sign is not part of the input component
    // we need to maintain focus ourselves
    const [isInputFocused, setIsInputFocused] = useState(false);

    const onChangeInternal = (event) => {
        const value = event.target.value;
        if (value.includes(".")) {
            const valueSplit = value.split(".");
            const decimal = valueSplit[1];

            // prevents user from entering in a value that
            // has more than 2 decimal places
            if (decimal.length > 2) {
                return;
            }
        }

        if (onChange) {
            onChange(event);
        }
    };

    const onFocusInternal = () => {
        setIsInputFocused(true);
        if (onFocus) {
            onFocus();
        }
    };

    const onBlurInternal = () => {
        setIsInputFocused(false);
        if (onBlur) {
            onBlur();
        }
    };

    return (
        <div className={createClassNameString(["CurrencyInput-container", className])}>
            <div
                className={createClassNameString([
                    "CurrencyInput-text_container",
                    errorMessage && "CurrencyInput-text_container-error",
                    isInputFocused && "CurrencyInput-text_container-focused",
                ])}>
                <p
                    data-public
                    className={createClassNameString([
                        "CurrencyInput-dollar_sign",
                        errorMessage && "CurrencyInput-dollar_sign-error",
                    ])}>
                    $
                </p>
                <input
                    className={createClassNameString([
                        "CurrencyInput-input",
                        isInputFocused && "CurrencyInput-input-focused",
                    ])}
                    data-public
                    name={name}
                    type="number"
                    placeholder={"0.00"}
                    step={step}
                    value={value}
                    onChange={onChangeInternal}
                    onFocus={onFocusInternal}
                    onBlur={onBlurInternal}
                    {...props}
                />
            </div>
            {errorMessage && <FormFieldError>{errorMessage}</FormFieldError>}
        </div>
    );
};

CurrencyInput.propTypes = {
    name: PropTypes.string,
    step: PropTypes.string,
    // intentionally required, in order for
    // the dollar sign to default to the correct style
    // we need context of what the value is
    value: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    className: PropTypes.string,

    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
};

CurrencyInput.defaultProps = {
    name: "",
    step: ".01",
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
};
