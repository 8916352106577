import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { createClassNameString } from "../../../component-library/";
import { useDispatch } from "react-redux";
import { updateSelectedEvents } from "../../../redux/actions/calendar";
import { getAutomationDataAttr } from "../../../utils/automationUtils";

const CalendarEventBody = ({ position, className, children, hasBadge, details, testId }) => {
    const dispatch = useDispatch();
    return (
        <div
            className={createClassNameString([
                hasBadge ? "CalendarEvent__body-w-badge" : "CalendarEvent__body",
                className,
            ])}
            style={position}
            onClick={() => {
                dispatch(updateSelectedEvents(details));
            }}
            {...getAutomationDataAttr(testId)}
            >
            {children}
        </div>
    );
};

const UnconfirmedCalendarEventBody = ({ position, className }) => {
    const combinedClassName = createClassNameString([
        "CalendarEvent__body",
        "UnconfirmedCalendarEvent__body",
        className,
    ]);
    return <div className={combinedClassName} style={position} />;
};

const CalendarEventTitle = ({ title, className }) => {
    return <p className={createClassNameString(["CalendarEvent__title", className])}>{title}</p>;
};

const CalendarEventTime = ({ className, startTime, endTime, strikeTimes }) => {
    let formattedEndTime = endTime.format("h:mm");
    // Moment handled 12 midnight as 11:59
    if (formattedEndTime === "11:59") {
        formattedEndTime = "12:00";
    }
    return (
        <div
            className={createClassNameString([
                "CalendarEvent__time",
                strikeTimes ? "CalendarEvent__time-struck" : undefined,
                className,
            ])}>
            {/* these are broken up to match responsiveness in the mockup */}
            {/* this is intentionally an en dash */}
            <p className="CalendarEvent__time-item">
                {startTime.format("h:mm")}&thinsp;{startTime.format("A")}
            </p>
            <p className="CalendarEvent__time-item">{" – "}</p>
            <p className="CalendarEvent__time-item">
                {formattedEndTime}&thinsp;{endTime.format("A")}
            </p>
        </div>
    );
};

const CalendarEventBadge = ({ text, className }) => {
    return (
        <div className="CalendarEvent__badge-wrapper">
            <p className={createClassNameString(["CalendarEvent__badge", className])}>{text}</p>
        </div>
    );
};

CalendarEventBody.propTypes = {
    position: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
    hasBadge: PropTypes.bool,
};

CalendarEventTitle.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
};

CalendarEventTime.propTypes = {
    className: PropTypes.string,
    startTime: PropTypes.instanceOf(moment),
    endTime: PropTypes.instanceOf(moment),
    strikeTimes: PropTypes.bool,
};

CalendarEventBadge.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
};

export {
    CalendarEventBody,
    CalendarEventTime,
    CalendarEventTitle,
    CalendarEventBadge,
    UnconfirmedCalendarEventBody,
};
