import React, { Component } from "react";
import images from "../../utils/images";
import { AuthWrapper } from "./index";

class PatientSignUpSuccess extends Component {

    render() {
        return (
            <AuthWrapper showArt={this.props.showSvgArt} refFromOuter={this.props.scrlWprRef}>
                <div className={"d-flex justify-content-center pt-3 mb-5"}>
                    <img
                        className="img-fluid"
                        alt={"Sign up success"}
                        src={images("./authentication/sign-up-success.svg")}
                    />
                </div>
                <h6>
                    <b>Account Created Successfully</b>
                </h6>
                <p className="mt-4 fs-14">Thank you for filling out the information.</p>
                <p className="mt-4 fs-14">
                    Your account is now being created. You’ll be redirected to your personalized
                    Home dashboard momentarily.
                </p>
            </AuthWrapper>
        );
    }
}

export default PatientSignUpSuccess;
