import React, { Component } from "react";
import { api } from "../../../../APIRequests";
import { connect } from "react-redux";
import { noteV2Display } from "../../../../components/Provider/PatientView/MemberProfile/MemberProfileNotes";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { updateNote } from "./NewNoteV2";
import EditNoteWrapper from "./EditNoteWrapper";
import { PRESCRIBE } from "../../../../components/Provider/ScheduleAvailability/constants";
import {
    checkInterventionAndPlanErrors,
} from "../../../../components/Common/GuidedVideoCall/Steps/ProviderWorkflow/InterventionAndPlan/InterventionAndPlanV2/InterventionAndPlanV2FormSchema";

class ViewDraftNoteV2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            patientId: this.props.match.params.patientId,
            noteId: this.props.match.params.noteId,
            noteErrors: {},
        };
    }

    componentDidMount = async () => {
        try {
            let urlParams = { patientId: this.state.patientId };
            const queryParams = { note_id: this.state.noteId };
            let patient = (await api.shared.fetch_patient_details({ urlParams })).user;
            let note = await api.shared.fetch_patient_progress_notes({ urlParams, queryParams });
            this.setState({ patient, note });
        } catch (e) {
            console.log(e);
        }
    };

    editHandler = (key, value, nested = true) => {
        this.setState((prevState) => {
            return {
                note: updateNote(prevState.note, nested, key, value),
            };
        });
    };

    signNote = async () => {
        let { note } = this.state;
        note["signature"] = this.props.profile.name;
        note["completed_date_time"] = moment().format();
        await this.setState({ note });
    };

    saveNoteHandler = async (note) => {
        try {
            const urlParams = { patientId: this.state.patientId };
            return api.provider.put_progress_note({ urlParams, data: note });
        } catch (e) {
            console.log(e);
        }
    };

    noteDraftValidation = () => {
        // runs the validation for intervention and plans
        // because it is the only blocking validator
        const hasInterventionAndPlanError = checkInterventionAndPlanErrors(
            this.state.note?.content,
        );

        return !hasInterventionAndPlanError;
    };

    noteDraftSubmitHandler = async () => {
        if (!this.noteDraftValidation()) {
            return;
        }
        let note = this.state.note;
        await this.saveNoteHandler(note);
        this.props.history.goBack(-1);
    };

    noteValidation = () => {
        let progressNote = this.state.note;
        const noteErrors = {};
        if (
            !progressNote["ICD_10_axis_1_diagnosis"] ||
            progressNote["ICD_10_axis_1_diagnosis"].length === 0
        ) {
            noteErrors["ICD_10_axis_1_diagnosis"] = "ICD-10 Primary Diagnosis is required";
        }

        if (!progressNote["call_type"]) {
            noteErrors["call_type"] = "CPT Code is required";
        }

        if (this.props.profile.provider_type === PRESCRIBE && !progressNote["service_location"]) {
            noteErrors["service_location"] = "Service Location is required";
        }

        if (checkInterventionAndPlanErrors(progressNote?.content)) {
            // the error message here has no impact, the error is displayed by
            // formik
            noteErrors["goals_objectives_progress_v2"] = "goals_objectives_progress_v2_error";
        }

        this.setState({ noteErrors });
        return Object.keys(noteErrors).length === 0;
    };

    noteSubmitHandler = async () => {
        if (!this.noteValidation()) {
            return;
        }
        await this.signNote();
        let note = this.state.note;
        note["note_state"] = "complete";
        await this.saveNoteHandler(note);
        this.props.history.goBack(-1);
    };

    render() {
        const { note, patient } = this.state;
        if (!note) {
            return <div />;
        }

        return (
            <>
                <EditNoteWrapper
                    patient={patient}
                    note={note}
                    noteDraftSubmitHandler={this.noteDraftSubmitHandler}
                    noteSubmitHandler={this.noteSubmitHandler}>
                    {noteV2Display(
                        note,
                        true,
                        this.editHandler,
                        { createEditView: true, draftView: true },
                        this.state.noteErrors,
                    )}
                </EditNoteWrapper>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default withRouter(connect(mapStateToProps)(ViewDraftNoteV2));
