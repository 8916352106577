import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import {createClassNameString} from "../../component-library";
import {ICON_SIZES, ICON_TYPES} from "../../constants/icons";

export const ReportIcon = ({className, iconType, iconSize, ...props}) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path d="M10 22H19V20H10L10 22Z" />
                <path d="M19 18L10 18L10 16L19 16V18Z" />
                <path d="M12 14V12H10V14H12Z" />
                <path d="M15.5 10V14H13.5V10H15.5Z" />
                <path d="M19 14V7H17V14H19Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M23 26H6V3H23V26ZM21 24V5H8V24H21Z" />
                <path d="M24 5H26V29H8V27H24V5Z" />
            </svg>

        );
    } else if (iconType === ICON_TYPES.FILLED) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path d="M19 22H10L10 16L19 16V22Z" />
                <path d="M12 14V12H10V14H12Z" />
                <path d="M15.5 10V14H13.5V10H15.5Z" />
                <path d="M19 14V7H17V14H19Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M23 26H6V3H23V26ZM21 24V5H8V24H21Z" />
                <path d="M24 5H26V29H8V27H24V5Z" />
            </svg>
        );
    }

    return null;
};

ReportIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

ReportIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
