import React from "react";
import NavMenuItem from "../../NavMenuItem";

const ActionSection = (props) => {
    const options = props.config.options.map((option, index) => {
        let messagesNotification = null;
        if (option.label === "Messages" && props.totalUnreadMessages > 0) {
            messagesNotification = <div className={"messagesNotification"}></div>;
        }

        const extraClassNames = index === 0 ? "mr-3" : "mr-1";
        return (
            <NavMenuItem className={`${extraClassNames} mr-lg-2 mb-3 mb-lg-0`} route={option.route}>
                <span className={"mr-1 " + option.icon} alt=""></span>
                {messagesNotification}
            </NavMenuItem>
        );
    });

    return <div className="d-flex align-items-center ml-auto">{options}</div>;
};

export default ActionSection;
