// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, {useEffect, useState} from "react";
import { api } from "../../../../APIRequests";
import images from "../../../../utils/images";
import moment from "moment-timezone";
import {showLoader, hideLoader} from "../../../../redux/actions/loader";
import {useDispatch} from "react-redux";
import {ChatBody} from "./ChatBody";
import {ChatFooter} from "./ChatFooter";
import {ChatHeader} from "./ChatHeader";
import {ChatMenu} from "./ChatMenu";
import PropTypes from "prop-types";

export const Chat = (props) => {
    const messagesEndRef = React.useRef(null);
    const lastSeenRef = React.useRef(null);
    const messageRefs = [];

    const { client, isInactive, isMobile, refetch, switchMobileView, user } = props;

    // Ref to the channel is needed to avoid stale closures, i.e., functions defined at render time
    // having the value of the props.channel that's set at render time
    const channelRef = React.useRef(props.channel);

    const [noMoreMessages, setNoMoreMessages] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [messages, setMessages] = useState([]);
    const [previousTopMessage, setPreviousTopMessage] = useState(null);
    const [query, setQuery] = useState(null);
    const [unreadIndex, setUnreadIndex] = useState(null);

    const dispatch = useDispatch();

    const onMessageReceived = (messagedChannel, message) => {
        if (messagedChannel.url === channelRef.current.url) {
            messagedChannel.markAsRead();
            addMessageToList(message);
            messagesEndRef.current.scrollIntoView();
        }
    };

    const onReadReceiptUpdated = (readChannel) => {
        if (readChannel.url === channelRef.current.url) {
            setMessages(messages => {
                return Array.from(messages).map((message) =>
                    processMessage(user, readChannel, message),
                );
            });
        }
    };

    useEffect(() => {
        return () => {
            client.removeChannelHandler("MESSAGE_CHAT_HANDLER");
        };
    }, []);

    useEffect(() => {
        channelRef.current = props.channel;
        (async () => {
            await resetChannel(props.channel);
        })();
    }, [props.channel]);

    useEffect(() => {
        if (previousTopMessage) {
            scrollToMessage(previousTopMessage);
        }
    }, [messages]);

    const handleScroll = async (event) => {
        if (!query) {
            return;
        }
        //If scrolled to top and we know there might be more messages
        if (event.target.scrollTop === 0 && !noMoreMessages) {
            let previousMessageListQuery = channelRef.current.createPreviousMessageListQuery();
            previousMessageListQuery.limit = 30;
            let previousMessages = (await api.messaging.get_messages(previousMessageListQuery)).map(
                (message) => processMessage(user, channelRef.current, message),
            );

            if (previousMessages.length === 0) {
                setNoMoreMessages(true);
            } else {
                // Allows us to track previous top message to scroll to once previous messages are loaded
                setMessages(messages => {
                    const currentMessages = Array.from(messages);
                    setPreviousTopMessage(currentMessages[0]);
                    return previousMessages.concat(currentMessages);
                });
            }
        }
    };

    const scrollToMessage = (message) => {
        let messageRef = messageRefs[message.messageId];
        if (messageRef) {
            messageRef.current.scrollIntoView();
        }
    };

    const resetChannel = async (channel) => {
        let previousMessageListQuery = channel.createPreviousMessageListQuery();
        let trueLimit = channel.unreadMessageCount > 30 ? channel.unreadMessageCount : 30;
        previousMessageListQuery.limit = trueLimit <= 100 ? trueLimit : 100;

        let previousMessages = (await api.messaging.get_messages(previousMessageListQuery)).map((message) =>
            processMessage(user, channel, message),
        );

        while (trueLimit > 100) {
            previousMessageListQuery.limit = 100;
            let currentMessages = Array.from(previousMessages);
            let previousMessages = (await api.messaging.get_messages(previousMessageListQuery)).map((message) =>
                processMessage(user, channel, message),
            );
            previousMessages = previousMessages.concat(currentMessages);
            trueLimit -= 100;
        }

        let currentUnreadIndex = null;

        if (channel.unreadMessageCount > 0) {
            let additionalUnread = 0;
            for (let i = previousMessages.length - channel.unreadMessageCount; i < previousMessages.length; i++) {
                if (previousMessages[i].sentByMe) {
                    additionalUnread++;
                }
            }

            currentUnreadIndex = previousMessages.length - channel.unreadMessageCount - additionalUnread;
        }

        channel.markAsRead();

        if (channel.otherMembers.length === 1) {
            channel.coverUrl = channel.otherMembers[0].profileUrl !== ""
                    ? channel.otherMembers[0].profileUrl
                    : images("./common/avatar.png");
        }

        setMessages(previousMessages);
        setNoMoreMessages(false);
        setPreviousTopMessage(null);
        setUnreadIndex(currentUnreadIndex);

        const ChannelHandler = new client.ChannelHandler();
        ChannelHandler.onMessageReceived = onMessageReceived;
        ChannelHandler.onReadReceiptUpdated = onReadReceiptUpdated;
        client.addChannelHandler("MESSAGE_CHAT_HANDLER", ChannelHandler);

        if (lastSeenRef.current) {
            lastSeenRef.current.scrollIntoView();
        } else {
            messagesEndRef.current.scrollIntoView();
        }
    };

    const sendMessage = async (messageText) => {
        if (messageText !== "") {
            let message = await api.messaging.send_message(channelRef.current, messageText);
            addMessageToList(message);
            messagesEndRef.current.scrollIntoView();
        }
    };

    const processMessage = (user, channel, message) => {
        message.sentByMe = message._sender.userId === user.user_id;

        let createdAt = moment(message.createdAt);
        let format = createdAt.isSame(moment(), "day") ? "hh:mm A" : "MM/DD/YY hh:mm A";
        message.formattedTime = createdAt.format(format);

        if (message.sentByMe) {
            message.outer_class = "d-flex flex-row-reverse";
            message.inner_class = "bubble bubble-me";
            message.isRead = channel.getReadReceipt(message) === 0;
        } else {
            message.displayName = message._sender.nickname;
            message.outer_class = "d-flex flex-row";
            message.inner_class = "bubble bubble-other";
        }

        messageRefs[message.messageId] = React.createRef();

        return message;
    };

    const addMessageToList = (message) => {
        const processedMessage = processMessage(user, channelRef.current, message);
        setMessages(messages => messages.concat(processedMessage));
    };

    const archiveConversation = async () => {
        dispatch(showLoader());
        channelRef.current.hide(false, true);
        await refetch();
        setShowMenu(false);
        dispatch(hideLoader());
    };

    return <>
        { showMenu &&
            <ChatMenu
                archiveConversation={archiveConversation}
                hideMenu={() => setShowMenu(false)}
            />
        }

        <ChatHeader
            channel={props.channel}
            isMobile={isMobile}
            showMenu={() => setShowMenu(true)}
            switchMobileView={switchMobileView}
        />

        <ChatBody
            channel={props.channel}
            handleScroll={handleScroll}
            lastSeenRef={lastSeenRef}
            messageRefs={messageRefs}
            messages={messages}
            messagesEndRef={messagesEndRef}
            unreadIndex={unreadIndex}
        />

        <ChatFooter
            channel={props.channel}
            isInactive={isInactive}
            sendMessage={sendMessage}
        />
    </>;
};

Chat.propTypes = {
    client: PropTypes.object.isRequired,
    channel: PropTypes.object.isRequired,
    isInactive: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    refetch: PropTypes.func.isRequired,
    switchMobileView: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};
