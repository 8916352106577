import _ from "lodash";
import moment, { default as momentTZ } from "moment-timezone";
import {
    timeSlotArray,
    transformTimeSlotArray,
} from "../../components/Calendar/CalendarAppointmentPicker/calendarAppointmentPickerUtils";
import { transformFrequencyDaysForServer } from "../../components/Provider/ScheduleAvailability/ReoccurrenceDetails";
import {
    _45_MIN_INDIVIDUAL_90834,
    AVAILABLE,
    AWAY,
    BEHAVIORAL,
    BEHAVIORAL_INTAKE,
    DIAGNOSTIC_INTERVIEW_90791,
    DIAGNOSTIC_INTERVIEW_90792,
    isSpecialEvent,
    PRESCRIBE,
    TEENS_AND_KIDS_DIAGNOSTIC_INTERVIEW_90791_ALLOTTED_TIME,
} from "../../constants/event";
import { isUserAdmin, isUserPeerOrCM } from "../../redux/actions/auth";
import { addToast, toastMessageTypes } from "../../redux/actions/toaster";
import { getLocalTimezone } from "../../redux/reducers/authReducer";
import { DateTimeWindow } from "../DateTimeWindow";
import {
    addConflictPadding,
    addToTimePadding,
    breakTimeIntoXMinuteIntervals,
    getExistingConflicts,
    getSlotsThatExtendToNextDay,
} from "./dates";
import {
    allottedTimeFactory,
    DEFAULT_BEHAVIORAL_SCHEDULE_OPTIONS,
    DEFAULT_PRESCRIBER_SCHEDULE_OPTIONS,
} from "./event";
import { validateAutomatedTestingFeatureFlag } from "../featureFlag";

export const getAllottedTime = (type, isChild) => {
    if (type && type === DIAGNOSTIC_INTERVIEW_90791 && isChild) {
        return TEENS_AND_KIDS_DIAGNOSTIC_INTERVIEW_90791_ALLOTTED_TIME;
    }

    return allottedTimeFactory(type);
};

export const isChild = (patient) => {
    if (!patient || !patient.dob) {
        return false;
    }

    try {
        const patientAgeInYears = moment().diff(moment(patient.dob, "MM/DD/YYYY"), "years");
        return patientAgeInYears < 18;
    } catch (e) {
        console.log(e);
        return false;
    }
};

export const getBehavioralScheduleOptions = async (patient) => {
    if (isChild(patient)) {
        const options = _.cloneDeep(DEFAULT_BEHAVIORAL_SCHEDULE_OPTIONS);
        const index = options.findIndex((obj) => obj.value === DIAGNOSTIC_INTERVIEW_90791);
        options[
            index
        ].label = `Diagnostic Interview (90791) (${TEENS_AND_KIDS_DIAGNOSTIC_INTERVIEW_90791_ALLOTTED_TIME} minutes)`;
        return options;
    }
    return DEFAULT_BEHAVIORAL_SCHEDULE_OPTIONS;
};

export const getAdminScheduleOptions = async (provider, patient) => {
    let options = [];
    if (provider?.providerType === PRESCRIBE) {
        options = DEFAULT_PRESCRIBER_SCHEDULE_OPTIONS;
    } else {
        options = await getBehavioralScheduleOptions(patient);
    }
    return options.filter((option) => option.value !== AVAILABLE);
};

export const getPeerOrCmScheduleOptions = async (provider, patient) => {
    let options = [];
    if (provider?.providerType === PRESCRIBE) {
        options = DEFAULT_PRESCRIBER_SCHEDULE_OPTIONS;
    } else {
        options = await getBehavioralScheduleOptions(patient);
    }
    return options.filter((option) => ![AWAY, AVAILABLE].includes(option.value));
};

export const getDefaultScheduleTypeOptions = async (provider, patient) => {
    const isUserBehavioral =
        provider?.providerType === BEHAVIORAL || provider.providerType === BEHAVIORAL_INTAKE;
    const isUserPrescriber = provider?.providerType === PRESCRIBE;

    if (isUserAdmin()) {
        return await getAdminScheduleOptions(provider, patient);
    } else if (isUserPeerOrCM()) {
        return await getPeerOrCmScheduleOptions(provider, patient);
    } else if (isUserPrescriber) {
        return DEFAULT_PRESCRIBER_SCHEDULE_OPTIONS;
    } else if (isUserBehavioral) {
        return await getBehavioralScheduleOptions(patient);
    } else {
        return [{ value: AWAY, display: "Away" }];
    }
};

export const getDefaultEventTypeByUserType = (providerType) => {
    if (!providerType) {
        return;
    }

    if (providerType === BEHAVIORAL) {
        return _45_MIN_INDIVIDUAL_90834;
    } else if (providerType === BEHAVIORAL_INTAKE) {
        return DIAGNOSTIC_INTERVIEW_90791;
    } else if (providerType === PRESCRIBE) {
        return DIAGNOSTIC_INTERVIEW_90792;
    } else {
        return AWAY;
    }
};

export const validateAvailableAppointmentSelections = (
    appointmentSelections,
    appointmentSelectionErrors,
) => {
    const internalAppointmentSelectionErrors = _.clone(appointmentSelectionErrors);

    if (!appointmentSelections.startTime) {
        internalAppointmentSelectionErrors.startTime = "Start time is required";
    }

    if (!appointmentSelections.endTime) {
        internalAppointmentSelectionErrors.endTime = "End time is required";
    }

    if (appointmentSelections.startTime && appointmentSelections.endTime) {
        const allottedTime = moment(appointmentSelections.endTime, "HH:mm").diff(
            moment(appointmentSelections.startTime, "HH:mm"),
            "minutes",
        );
        if (allottedTime <= 0) {
            internalAppointmentSelectionErrors.startTime = "Please enter a valid time range.";
            internalAppointmentSelectionErrors.endTime = "";
        }
    }

    return internalAppointmentSelectionErrors;
};

/**
 *
 * @param {import("moment").Moment[]} timeArray
 * @returns
 */
export const removeBackToBackOverlaps = (timeArray) => {
    // sort the conflicts in ascending order
    const sortedConflicts = timeArray.sort((a, b) => {
        return a.diff(b);
    });

    // create a list of maximum time windows
    // combining any back to back time windows (ex. 9-5 5-6 -> 9-6)
    // this will make all pairs valid time windows
    const timeWindows = [];
    let skipIndex = -1;
    sortedConflicts.forEach((item, index, array) => {
        // skip all
        if (skipIndex === index) {
            return;
        }
        const nextIndex = index + 1;

        // only add times that are not back to back time windows
        // or is the last in the array
        if (nextIndex < array.length) {
            if (!item.isSame(array[nextIndex])) {
                // if the current is not the same as the next
                // add it
                timeWindows.push(item);
            }
            // if the current is the same as the next
            // skip both values
            else {
                skipIndex = nextIndex;
            }
        }
        // if is the last in the array add it
        else {
            timeWindows.push(item);
        }
    });

    return timeWindows;
};

/**
 *
 * @param {import("moment").Moment[]} timeArray
 * @returns
 */
export const createPairsOfTimes = (timeArray) => {
    if (timeArray.length % 2 !== 0) {
        console.error("createPairsOfTimes: Invalid time window!");
        return [];
    }
    // group the times together as pairs to get a time window
    const pairedTimeWindows = [];
    for (let i = 0; i < timeArray.length; i += 2) {
        if (i + 1 < timeArray.length) {
            pairedTimeWindows.push([timeArray[i], timeArray[i + 1]]);
        }
    }

    return pairedTimeWindows;
};

/**
 *
 * @param {import("moment").Moment} date
 * @param {object} workingHours
 * @param {object[]} availableTimes
 */
export const getSelectableTimesAvailable = (date, workingHours, availableTimes, awayTimes) => {
    // get the working hours for the selected day of the week
    const selectedDayOfWeek = date.format("dddd");
    const relevantWorkingHoursSlots = workingHours[selectedDayOfWeek]?.slots || [];

    // for every working hour slot in the selected day
    // create a momentJs representation
    const workingHourWindows = relevantWorkingHoursSlots.map((slot) => {
        const startTime = momentTZ(slot[0], "HH:mm");
        const endTime = momentTZ(slot[1], "HH:mm");
        return new DateTimeWindow(startTime, endTime);
    });

    // get all available slots that can extend a working hour slot
    // NOTE: available slots cannot have overlap! this is why the proceeding
    // sort approach works
    const availableWindows = getExistingConflicts(availableTimes, date, (startTime, endTime) => {
        return new DateTimeWindow(
            moment(startTime.format("HH:mm"), "HH:mm"),
            moment(endTime.format("HH:mm"), "HH:mm"),
        );
    });

    const awayWindows = getExistingConflicts(awayTimes, date, (startTime, endTime) => {
        return new DateTimeWindow(
            moment(startTime.format("HH:mm"), "HH:mm"),
            moment(endTime.format("HH:mm"), "HH:mm"),
        );
    });

    const conflictWindows = DateTimeWindow.combineOverlappingWindows([
        ...workingHourWindows,
        ...availableWindows,
        ...awayWindows,
    ]);

    const startTimes = conflictWindows.map((item) => item.start.format("HH:mm"));
    const selectableTimes = conflictWindows
        .map((timeWindow) => {
            return breakTimeIntoXMinuteIntervals(timeWindow.start, timeWindow.end);
        })
        .flat();

    // build the list of selectable times
    const fromConflicts = selectableTimes;
    // filter out the start times so they are not considered as conflicts
    // and appear on the to slots
    const toConflicts = selectableTimes.filter((time) => !startTimes.includes(time));

    const allTimes = transformTimeSlotArray(timeSlotArray);

    const selectableTimeSlotsFrom = allTimes.filter((timeSlot) => {
        return !fromConflicts.includes(timeSlot.value);
    });

    const selectableTimeSlotsTo = allTimes.filter((timeSlot) => {
        return !toConflicts.includes(timeSlot.value);
    });

    return {
        fromSlots: selectableTimeSlotsFrom,
        toSlots: selectableTimeSlotsTo,
    };
};

/**
 * @param {Array} events
 * @param {import("moment").Moment} date
 */
export const getSelectableTimesAway = (events, date) => {
    // get a list of times that is not available on the users schedule
    const conflicts = getExistingConflicts(events, date);
    // For each conflict we want to be able to select up to the start
    // of a conflict and not allow selection of the end of a conflict
    const paddedConflicts = addToTimePadding(conflicts);
    const flattenedToConflicts = paddedConflicts.flat();
    const rawToSlots = [...timeSlotArray];
    // We don't want to be able to select 00:00 as a to time
    rawToSlots.shift();
    // Moment handles 24:00 as 00:00 which are functionally equal,
    // Here we just switch 00:00 to 24:00 if its a conflict, if not
    // We add 24:00 as a to option
    if (flattenedToConflicts.includes("00:00")) {
        flattenedToConflicts.splice(flattenedToConflicts.indexOf("00:00"), 1);
        flattenedToConflicts.push("24:00");
    } else {
        rawToSlots.push("24:00");
    }

    const selectableToTimeSlots = transformTimeSlotArray(rawToSlots).filter((timeSlot) => {
        return !flattenedToConflicts.includes(timeSlot.value);
    });

    return {
        fromSlots: getSelectableTimes({ events, AWAY, date, noPadding: true }),
        toSlots: selectableToTimeSlots,
    };
};

/**
 * Get selectable times for a specific event type and date.
 *
 * @param {object} options
 * @param {Array} options.events
 * @param {string} options.eventType
 * @param {import("moment").Moment} options.date
 * @param {object} options.patient
 * @returns {Array} An array of selectable times.
 */
export const getSelectableTimes = ({
    events,
    eventType,
    allottedTime = null,
    date,
    patient,
    noPadding,
}) => {
    let nonConflictingEvents = events;
    // remove events that do not create conflicts
    if (eventType !== AWAY) {
        // available events are conflicts for away events
        nonConflictingEvents = events.filter((event) => event.event_type !== AVAILABLE);
    }

    // get a list of times that is not available on the users schedule
    const conflicts = getExistingConflicts(nonConflictingEvents, date);
    // get the allotted time
    let internalAllottedTime = allottedTime;
    if (!allottedTime) {
        internalAllottedTime = getAllottedTime(eventType, isChild(patient));
    }

    const conflictsWithPadding = noPadding
        ? conflicts
        : addConflictPadding(conflicts, internalAllottedTime);
    const flattenedConflicts = conflictsWithPadding.flat();
    const slotsThatExtendToNextDay = getSlotsThatExtendToNextDay(internalAllottedTime);
    const unavailableTimeSlots = [...flattenedConflicts, ...slotsThatExtendToNextDay];
    // filter out unavailable times
    const selectableTimeSlots = transformTimeSlotArray(timeSlotArray).filter((timeSlot) => {
        return !unavailableTimeSlots.includes(timeSlot.value);
    });

    return selectableTimeSlots;
};

export const showRescheduleAppointmentToast = (result, dispatch) => {
    if (result.message) {
        dispatch(
            addToast({
                message: result.message,
                messageType: toastMessageTypes.warning,
            }),
        );
    }
};

export const showDeleteAppointmentToast = (result, dispatch) => {
    if (result.delete_appointment && result.delete_appointment !== "Success") {
        dispatch(
            addToast({
                message: result.delete_appointment,
                messageType: toastMessageTypes.warning,
            }),
        );
    }
};

/**
 * Building request body for add and update appointment requests
 *
 * @param {object} seriesData
 * @param {string} time
 * @param {string} date
 * @param {string} eventType
 * @param {object} patient
 * @param {string} providerId
 * @param {string} startTime
 * @param {string} endTime
 * @param {string} label
 */
export const buildAddUpdateRequest = (
    seriesData,
    time,
    date,
    eventType,
    patient,
    providerId,
    startTime,
    endTime,
    label,
) => {
    const clonedSeriesData = _.cloneDeep(seriesData);
    if (clonedSeriesData && clonedSeriesData.frequencyDays) {
        clonedSeriesData.frequencyDays = transformFrequencyDaysForServer(clonedSeriesData);
    }

    const requestBody = {
        patient_id: patient?.patient_id,
        local_date_time: `${date} ${time}`,
        timezone: getLocalTimezone(),
        event_type: eventType,
        allotted_time: getAllottedTime(eventType, isChild(patient)),
        seriesData: clonedSeriesData,
        provider_id: providerId,
    };

    if (isSpecialEvent(eventType)) {
        const allotted_time = moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"), "minutes");
        requestBody.allotted_time = allotted_time;
        delete requestBody.patient_id;
        eventType === AWAY && label && (requestBody.label = label);
    }

    return requestBody;
};

export const filterCalendarEvents = (events, calendarFilters) => {
    const isScheduledEvent = (event) => event.cancellation_reason === undefined && !event?.deleted;
    const isCancelledEvent = (event) =>
        event?.deleted && !isNoShowEvent(event) && !isRescheduleEvent(event);
    const isNoShowEvent = (event) =>
        event.cancellation_reason !== undefined &&
        event.cancellation_reason.reason_type === "no_show";
    const isRescheduleEvent = (event) => Boolean(event.rescheduled_as);

    const filterCalendarEvent = (event, calendarFilters) =>
        (isScheduledEvent(event) && calendarFilters.scheduledEvents) ||
        (isCancelledEvent(event) && calendarFilters.cancelledEvents) ||
        (isNoShowEvent(event) && calendarFilters.noShowEvents) ||
        (isRescheduleEvent(event) && calendarFilters.rescheduledEvents);

    return events.filter((event) => filterCalendarEvent(event, calendarFilters));
};

export function getCanStartCall(currentTime, callStartTime, callDuration, email, configs) {
    if (validateAutomatedTestingFeatureFlag(configs?.OVERRIDE_CALL_START_TIME, email)) {
        return true;
    }
    const currentTimestamp = moment(currentTime, "YYYY-MM-DD HH:mm");

    const fifteenMinutesBefore = moment(callStartTime, "YYYY-MM-DD HH:mm").subtract(15, "minutes");
    const endOfCall = moment(callStartTime, "YYYY-MM-DD HH:mm").add(callDuration, "minutes");

    const result = currentTimestamp.isBetween(fifteenMinutesBefore, endOfCall, undefined, "[]");
    return result;
}
