import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useClickOutside } from "../../../utils/hooks/useClickOutside";

const CloseOnOutsideClick = ({ children, setShowComponent, className }) => {
    const closeableComponentRef = useRef(null);

    useClickOutside(closeableComponentRef, () => {
        setShowComponent(false);
    });

    return (
        <div className={className ?? ""} ref={closeableComponentRef}>
            {children}
        </div>
    );
};

CloseOnOutsideClick.propTypes = {
    children: PropTypes.node,
    setShowComponent: PropTypes.func.isRequired,
};

CloseOnOutsideClick.defaultProps = {
    children: <></>,
};

export default CloseOnOutsideClick;
