import React from "react";

import { TextTypes, TextColors } from "../../../constants";
import { Link } from "../../../Link";
import { Text } from "../../../Text";
import { NotificationSection } from "../../../NotificationSection";

import "./styles.scss";

// Relates to: Profile Insurance Status

export const NoMatch = ({ copay, carrier, dateChecked }) => {
    return (
        <NotificationSection className={"declined"}>
            <Text className={"bold mb-3"}>We couldn’t verify your insurance.</Text>

            <Text>
                <>
                    <div className="mb-3">
                        Double check that the information below is correct and try again.
                    </div>
                    <div className="mb-3">
                        If your info looks correct, contact{" "}
                        <Link href="mailto:support@aptihealth.com">support@aptihealth.com</Link> or{" "}
                        <Link href="tel:8884543827">(888) 454-3827</Link> before your next
                        appointment to prevent billing issues.
                    </div>
                    <div>Checked: {dateChecked}</div>
                </>
            </Text>
        </NotificationSection>
    );
};

NoMatch.defaultProps = {
    copay: null,
    dateChecked: "",
    carrier: "",
};
