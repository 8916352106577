import React from "react";
import _ from "lodash";
import CardSecondary from "../../../UI/Card/CardSecondary";

export const GenericMultiselect = (props) => {
    const {
        note,
        sectionKey,
        sectionLabel,
        editable,
        profileView,
        labelClass,
        editHandler,
        isCheckboxes,
        noneSelectedMessage,
    } = props;

    let sectionMultiselect = note.content[sectionKey];

    const handleEditCopyChange = (e, index, key) => {
        if (!editable) {
            return;
        }

        let editCopyClone = _.cloneDeep(note.content[sectionKey]);
        editCopyClone[index][key] = !editCopyClone[index][key];

        if (index === "none") {
            Object.entries(editCopyClone).forEach(([sectionKey, value]) => {
                if (sectionKey !== "none") {
                    value[key] = false;
                }
            });
        } else if (editCopyClone["none"] != null) {
            editCopyClone["none"][key] = false;
        }

        if (editHandler) {
            editHandler(sectionKey, editCopyClone);
        }
    };

    const isNoneSelected =
        noneSelectedMessage != null &&
        Object.values(sectionMultiselect).every((section) => !section.answer);

    let innerContent = (
        <>
            <div>
                <div className={`fw-bold float-left ${labelClass}`}>{sectionLabel}</div>
            </div>
            <br />
            {isNoneSelected && <div className={"fs-12"}>{noneSelectedMessage}</div>}

            <div className="row print-wrap">
                {Object.entries(sectionMultiselect)
                    .sort((a, b) => (a[1].order && b[1].order ? a[1].order - b[1].order : 0))
                    .map(([key, value]) => {
                        let style = {
                            cursor: editable ? "pointer" : "default",
                            borderRadius: 3,
                        };
                        if (value.answer) {
                            style.border = 0;
                        }

                        if (isCheckboxes) {
                            return (
                                <div
                                    key={key}
                                    className="col-6 pr-0 my-1 py-1 fs-12"
                                    onClick={(e) => {
                                        handleEditCopyChange(e, key, "answer");
                                    }}>
                                    <input
                                        type="checkbox"
                                        checked={sectionMultiselect[key].answer ? true : null}
                                    />
                                    <span className="ml-2">{value.label}</span>
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    key={key}
                                    className="col-lg-4 col-md-4 col-6 justify-content-center my-1 py-1 fs-12">
                                    <button
                                        onClick={(e) => {
                                            handleEditCopyChange(e, key, "answer");
                                        }}
                                        value={key}
                                        className={
                                            "w-100 fw-normal text-break text-center cursor-none " +
                                            (value.answer
                                                ? "Btn--sec activeOptionBtn"
                                                : "Btn--otl-gry inactiveOptionBtn")
                                        }
                                        style={style}>
                                        {value.label}
                                    </button>
                                </div>
                            );
                        }
                    })}
            </div>
        </>
    );

    let content;

    if (!profileView) {
        content = (
            <CardSecondary
                className={"mx-0 px-3 py-3 my-3 max-width " + props.className}
                style={{ minHeight: 150 }}>
                {innerContent}
            </CardSecondary>
        );
    } else {
        content = (
            <div className={props.className} style={{ minHeight: 150 }}>
                {innerContent}
            </div>
        );
    }

    return <>{content}</>;
};
