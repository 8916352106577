// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { useEffect, useState } from "react";
import { Button } from "../../component-library/Button";
import { Card } from "../../component-library/Card";
import FormikInput from "../UI/FormikInput";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { getQueryParams } from "../../utils/filters";
import * as yup from "yup";
import { AuthWrapper } from "./index";
import { deviceDetect } from "react-device-detect";
import { isSupportedBrowser } from "../Common/SystemCheck/index";
import parser from "ua-parser-js";
import { trackEvent } from "../../utils/EventTrackingUtil";
import { CustomForm } from "../../component-library/CustomForm";

export const initialValues = {
    PASSWORD: "",
};

export const formSchema = [
    {
        name: "PASSWORD",
        pureName: "password",
        elementType: "input",
        elementConfig: {
            labelClasses: "login-label",
            type: "viewablePassword",
            label: "Password",
            placeholder: "Enter Password",
        },
    },
];

export const signInSchema = yup.object().shape({
    PASSWORD: yup.string().required("Password is required"),
});

const CompleteSignIn = (props) => {
    let isEmailVerified =
        getQueryParams("isEmailVerified", props.location.search.trim()) === "true";

    const [pass, setPass] = useState("");

    useEffect(function () {
        if (!props.completeSignIn) {
            props.history.push("/auth/sign-in");
        }

        if (isEmailVerified) {
            props.emailVerified();
        }
    }, []);

    const formikInputProps = {
        inputClasses: "Input--sm complete-sign-in-input",
    };
    let form = (
        <CustomForm
            initialValues={initialValues}
            onSubmit={props.submit}
            validationSchema={signInSchema}
            render={(props) => (
                <Form
                    onChange={(e) => {
                        setPass(e.target.value);
                    }}>
                    <div>
                        {formSchema.map((formEl) => {
                            return (
                                <FormikInput
                                    {...formikInputProps}
                                    key={formEl.name}
                                    errors={props.errors}
                                    touched={props.touched}
                                    formEl={formEl}
                                />
                            );
                        })}

                        <div className="d-flex justify-content-center m-lg-5 py-3">
                            <Button
                                type="submit"
                                className={`Btn Btn--pri Btn-sm Btn-login ${
                                    pass.length >= 1 ? "primary" : "grey"
                                }`}
                                onClick={() => {
                                    trackEvent("Logging in with password from login");
                                }}>
                                Log In
                            </Button>
                        </div>
                        <div className="d-flex justify-content-center my-3">
                            <Link
                                to="/auth/forgot-password"
                                className="Auth__forgotPassword underline_hover"
                                style={{ fontSize: "14px" }}
                                onClick={() => {
                                    trackEvent("Redirect to forgot password from login");
                                }}>
                                Forgot Password?
                            </Link>
                        </div>
                    </div>
                </Form>
            )}
        />
    );

    function logInCard() {
        return (
            <>
                <p className="login-welcome">Welcome back!</p>
                <div>{form}</div>
            </>
        );
    }

    function accountLockedCard() {
        return (
            <>
                <Card className="borderless-card">
                    <p className="login-welcome">Account Locked</p>
                    <p className="py-lg-2" style={{ color: "gray" }}>
                        Your account has reached the maximum number of failed login attempts and has
                        been temporarily locked. Please select the Send Reset Code button below to
                        send a code to
                        <span style={{ color: "#5c57db", fontWeight: "700" }}>
                            {" " + props.email}
                        </span>
                    </p>
                    <div className="ResetPassword__submit-btn-wpr d-flex">
                        <Button
                            type="submit"
                            className="Btn Btn--pri Btn-sm mt-2 ml-auto account-locked-button"
                            onClick={props.resetCodeHandler}>
                            Send Reset Code
                        </Button>
                    </div>
                    <Link to="/auth/sign-in" style={{ marginTop: "6px" }}>
                        <b className="link-to-sign-in" style={{ display: "inline-block" }}>
                            Return to Login
                        </b>
                    </Link>
                </Card>
            </>
        );
    }

    let deviceInfo = deviceDetect();
    let message = (
        <>
            Sorry, we don't currently support this browser. Please try using another browser like{" "}
            <a href={"https://www.mozilla.org/en-US/firefox/new/"}>Firefox (download here)</a> or{" "}
            <a href={"https://www.google.com/chrome/"}>Chrome (download here)</a>.
        </>
    );
    const os = deviceInfo.osName || deviceInfo.os;
    if (os === "iOS") {
        message = "Sorry, we don't currently support this browser. Please try using Safari.";
    }

    return (
        <AuthWrapper showBackLink={!props.accountLocked}>
            {!isSupportedBrowser(deviceDetect(), parser()) && (
                <div className="SnackBar p-2 position-fixed SnackBar--warning">
                    <div className="SnackBar__wpr d-flex align-items-md-center">
                        <div className="ml-1 ml-md-3">
                            <span className="fs-14">{message}</span>
                            <br />
                        </div>
                    </div>
                </div>
            )}
            {props.accountLocked && props.email === props.lastSubmittedEmail
                ? accountLockedCard()
                : logInCard()}
        </AuthWrapper>
    );
};

export function getSignUpLink() {
    return (
        <div className="Auth__switch">
            <p className="text-center my-2">
                <span>Not a member? </span>
                <Link to="/auth/self-signup" className="cursor">
                    <b style={{ display: "inline-block", color: "#0CD0A7", fontWeight: "700" }}>
                        Join Now
                    </b>
                </Link>
            </p>
        </div>
    );
}

export default CompleteSignIn;
