import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../../utils";
import { TextTypes } from "../../constants";

import { InsuranceField } from "./_/InsuranceField";
import { Text } from "../../Text";
import { Image } from "../../Image";
import { PageBreak } from "../../PageBreak";

import "./styles.scss";

export const CompletedProfileInsurance = ({
    className,
    title,

    insuranceCompany,
    policyHolder,
    memberId,
    groupId,
    stateOfInsurancePlan,
    policyHolderName,
    policyHolderDOB,

    insuranceCardImgFrontSrc,
    insuranceCardImgBackSrc,
    onInsuranceCardImgFrontClick,
    onInsuranceCardImgBackClick,

    onEditButtonClick,
    isEditable,
    ...props
}) => (
    <div
        className={createClassNameString(["apti-CompletedProfileInsurance", className])}
        {...props}>
        <div className="apti-CompletedProfileInsurance--header">
            <Text type={TextTypes.heading2}>{title}</Text>
            {isEditable && (
                <Image src="icons/green-edit-icon.svg" alt="edit" onClick={onEditButtonClick} />
            )}
        </div>
        <PageBreak />
        <div className="apti-CompletedProfileInsurance--body">
            <div className="apti-CompletedProfileInsurance--left">
                <InsuranceField title="Insurance Company">{insuranceCompany}</InsuranceField>
                <InsuranceField title="Member ID">{memberId}</InsuranceField>
                <InsuranceField title="Insurance Card Images">
                    <div
                        className="apti-CompletedProfileInsurance--image-container"
                        data-private={"true"}
                    >
                        {insuranceCardImgFrontSrc && (
                            <img
                                className="apti-CompletedProfileInsurance--image"
                                src={insuranceCardImgFrontSrc}
                                onClick={onInsuranceCardImgFrontClick}
                            />
                        )}
                        {insuranceCardImgBackSrc && (
                            <img
                                className="apti-CompletedProfileInsurance--image"
                                src={insuranceCardImgBackSrc}
                                onClick={onInsuranceCardImgBackClick}
                            />
                        )}
                    </div>
                </InsuranceField>
                <InsuranceField title="Policy Holder Name">{policyHolderName}</InsuranceField>
            </div>
            <div className="apti-CompletedProfileInsurance--right">
                <InsuranceField title="Policy Holder">{policyHolder}</InsuranceField>
                <InsuranceField title="Group ID">{groupId}</InsuranceField>
                <InsuranceField title="State of Insurance Plan">
                    {stateOfInsurancePlan}
                </InsuranceField>
                <InsuranceField title="Policy Holder Date of Birth">
                    {policyHolderDOB}
                </InsuranceField>
            </div>
        </div>
    </div>
);

CompletedProfileInsurance.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,

    insuranceCompany: PropTypes.string.isRequired,
    policyHolder: PropTypes.string.isRequired,
    memberId: PropTypes.string.isRequired,
    groupId: PropTypes.string.isRequired,
    stateOfInsurancePlan: PropTypes.string.isRequired,
    policyHolderName: PropTypes.string.isRequired,
    policyHolderDOB: PropTypes.string.isRequired,

    insuranceCardImgFrontSrc: PropTypes.string.isRequired,
    insuranceCardImgBackSrc: PropTypes.string.isRequired,
    onInsuranceCardImgFrontClick: PropTypes.func,
    onInsuranceCardImgBackClick: PropTypes.func,

    isEditable: PropTypes.bool.isRequired,
    onEditButtonClick: PropTypes.func.isRequired,
};

CompletedProfileInsurance.defaultProps = {
    className: undefined,
    title: "",

    insuranceCompany: "",
    policyHolder: "",
    memberId: "",
    groupId: "",
    stateOfInsurancePlan: "",
    policyHolderName: "",
    policyHolderDOB: "",

    insuranceCardImgFrontSrc: null,
    insuranceCardImgBackSrc: null,
    onInsuranceCardImgFrontClick: () => {},
    onInsuranceCardImgBackClick: () => {},

    onEditButtonClick: "",
    isEditable: true,
};
