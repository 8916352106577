import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import {createClassNameString} from "../../component-library";
import {ICON_SIZES, ICON_TYPES} from "../../constants/icons";

export const MessagesIcon = ({className, iconType, iconSize, ...props}) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path d="M8.5 14.5C9.32843 14.5 10 13.8284 10 13C10 12.1716 9.32843 11.5 8.5 11.5C7.67157 11.5 7 12.1716 7 13C7 13.8284 7.67157 14.5 8.5 14.5Z" />
                <path d="M13 14.5C13.8284 14.5 14.5 13.8284 14.5 13C14.5 12.1716 13.8284 11.5 13 11.5C12.1716 11.5 11.5 12.1716 11.5 13C11.5 13.8284 12.1716 14.5 13 14.5Z" />
                <path d="M19 13C19 13.8284 18.3284 14.5 17.5 14.5C16.6716 14.5 16 13.8284 16 13C16 12.1716 16.6716 11.5 17.5 11.5C18.3284 11.5 19 12.1716 19 13Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12.4491 20H19C20.0752 20 21.0668 19.4725 21.7696 18.7696C22.4725 18.0668 23 17.0752 23 16V10C23 8.92477 22.4725 7.93324 21.7696 7.23039C21.0668 6.52755 20.0752 6 19 6H7.00013C5.9249 6 4.93324 6.52755 4.23039 7.23039C3.52755 7.93324 3 8.92477 3 10V16C3 17.0752 3.52755 18.0668 4.23039 18.7696C5 19.5392 6 20 7 20V23.5C7 23.9152 7.25654 24.2872 7.64462 24.4347C8.0327 24.5823 8.47158 24.4747 8.74741 24.1644L12.4491 20ZM11.5509 18H19C19.4232 18 19.9319 17.7789 20.3554 17.3554C20.7789 16.9319 21 16.4232 21 16V10C21 9.57677 20.7789 9.06811 20.3554 8.64461C19.9319 8.2211 19.4232 8 19 8H7.00013C6.57683 8 6.06805 8.22116 5.64461 8.64461C5.2211 9.06811 5 9.57677 5 10V16C5 16.4232 5.2211 16.9319 5.64461 17.3554C6.10044 17.8112 6.61125 18 7 18H9V20.8698L11.5509 18Z" />
                <path d="M16 21H14V22C14 23.0752 14.5275 24.0668 15.2304 24.7696C15.9332 25.4725 16.9248 26 18 26H22.0858L24.2929 28.2071C24.5789 28.4931 25.009 28.5787 25.3827 28.4239C25.7564 28.2691 26 27.9045 26 27.5V25.8494C26.6804 25.6443 27.2917 25.2475 27.7696 24.7696C28.4725 24.0668 29 23.0752 29 22V20C29 18.9248 28.4725 17.9332 27.7696 17.2304C27.0668 16.5275 26.0752 16 25 16H24V18H25C25.4232 18 25.9319 18.2211 26.3554 18.6446C26.7789 19.0681 27 19.5768 27 20V22C27 22.4232 26.7789 22.9319 26.3554 23.3554C26.0787 23.6321 25.7462 23.837 25.4228 23.9345C24.7957 24.1235 24.3155 24.6011 24.1098 25.1955L23.5 24.5858C23.1249 24.2107 22.6162 24 22.0858 24H18C17.5768 24 17.0681 23.7789 16.6446 23.3554C16.2211 22.9319 16 22.4232 16 22V21Z" />
            </svg>

        );
    } else if (iconType === ICON_TYPES.FILLED) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path fillRule="evenodd" clipRule="evenodd" d="M19 6C20.0752 6 21.0668 6.52755 21.7696 7.23039C22.4725 7.93324 23 8.92477 23 10V16C23 17.0752 22.4725 18.0668 21.7696 18.7696C21.0668 19.4725 20.0752 20 19 20H12.4491L8.74741 24.1644C8.47158 24.4747 8.0327 24.5823 7.64462 24.4347C7.25654 24.2872 7 23.9152 7 23.5V20C6 20 5 19.5392 4.23039 18.7696C3.52755 18.0668 3 17.0752 3 16V10C3 8.92477 3.52755 7.93324 4.23039 7.23039C4.93324 6.52755 5.9249 6 7.00013 6H19ZM10 13C10 13.8284 9.32843 14.5 8.5 14.5C7.67157 14.5 7 13.8284 7 13C7 12.1716 7.67157 11.5 8.5 11.5C9.32843 11.5 10 12.1716 10 13ZM14.5 13C14.5 13.8284 13.8284 14.5 13 14.5C12.1716 14.5 11.5 13.8284 11.5 13C11.5 12.1716 12.1716 11.5 13 11.5C13.8284 11.5 14.5 12.1716 14.5 13ZM17.5 14.5C18.3284 14.5 19 13.8284 19 13C19 12.1716 18.3284 11.5 17.5 11.5C16.6716 11.5 16 12.1716 16 13C16 13.8284 16.6716 14.5 17.5 14.5Z" />
                <path d="M19 22H14C14 23.0752 14.5275 24.0668 15.2304 24.7696C15.9332 25.4725 16.9248 26 18 26H22.0858L24.2929 28.2071C24.5789 28.4931 25.009 28.5787 25.3827 28.4239C25.7564 28.2691 26 27.9045 26 27.5V25.8494C26.6804 25.6443 27.2917 25.2475 27.7696 24.7696C28.4725 24.0668 29 23.0752 29 22V20C29 18.9248 28.4725 17.9332 27.7696 17.2304C27.0668 16.5275 26.0752 16 25 16C25 19.3137 22.3137 22 19 22Z" />
            </svg>

        );
    }

    return null;
};

MessagesIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

MessagesIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
