import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";
import {ButtonSizes} from "../constants";

export const Button = ({
    children,
    className,
    name,
    type,
    disabled,
    buttonType,
    onClick,
    buttonSize,
    ...props
}) => {
    const classNameArray = ["apti-CL apti-Button", className, buttonType];
    if (buttonSize !== ButtonSizes.normal) {
        classNameArray.push(buttonSize);
    }
    return (
        <button
            className={createClassNameString(classNameArray)}
            name={name}
            type={type}
            disabled={disabled}
            onClick={onClick}
            {...props}>
            {children}
        </button>
    );
};

Button.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string])
        .isRequired,
    className: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.oneOf(["button", "reset", "submit"]),
    disabled: PropTypes.bool,
    buttonType: PropTypes.oneOf([
        "primary",
        "primary-v2",
        "primary-outline",
        "primary-v2-outline",
        "secondary",
        "secondary-outline",
        "label",
    ]),
    size: PropTypes.oneOf(["small", "normal"]),
    onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
    children: <></>,
    className: undefined,
    name: "",
    type: "button",
    disabled: false,
    buttonType: undefined,
    size: "normal",
    onClick: () => {},
};
