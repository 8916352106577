// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component, Fragment } from "react";
import {
    List,
    ListBody,
    ListHeader,
    ListItem,
    ListTitleBar,
    TitleBarItem,
} from "../../../../components/UI/ListView";
import SearchBar from "../../../../components/UI/SearchBar";
import { Link } from "react-router-dom";
import { providerRoute } from "../../../../config/roles";
import { setDynamicRouteConfiguration } from "../../../../redux/actions/navbar";
import { connect } from "react-redux";
import {
    getNoteListChildren,
    getProgressNotes,
    viewNote,
    viewDraftNote,
    deleteNote,
} from "../../../../components/Provider/PatientView/ProgressNotes";
import { getQueryParams } from "../../../../utils/filters";
import DeleteNote from "./DeleteNote";
import { isAuthorized } from "../../../../redux/actions/auth";
import Button from "../../../../components/UI/Button";

class ProgressNoteList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: [],
            search: "",
            patientId: this.props.match.params.patientId,
            showDraftsTab: false,
            showDeleteNote: false,
            draftNoteId: "",
            isAuthorizedToCreateNotes:
                isAuthorized("provider:behavioral") || isAuthorized("provider:prescribe"),
        };
    }

    componentDidMount() {
        getProgressNotes(this, false, this.state.showDraftsTab);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.showDraftsTab !== prevState.showDraftsTab) {
            getProgressNotes(this, false, this.state.showDraftsTab);
        }
    }

    componentWillUnmount() {
        this.props.setDynamicRouteConfiguration({});
    }

    renderControls = () => {
        let numSessions = this.state.notes ? this.state.notes.length : 0;
        return (
            <div className="d-flex">
                <SearchBar
                    className="mr-lg-2"
                    value={this.state.search}
                    onchange={this.updateSearch}
                />
                <Link
                    to={`/app/patient/${this.state.patientId}/note/add?numSessions=${numSessions}`}>
                    <Button
                        disabled={!this.state.isAuthorizedToCreateNotes}
                        className="Btn d-none d-lg-block Btn--pri Btn-sm ml-4 fs-16 text-center text-white">
                        Add Note
                    </Button>
                </Link>
            </div>
        );
    };

    updateSearch = (e) => {
        this.setState({ search: e.target.value });
    };

    render() {
        const { patientDetails } = this.props.location;
        let filteredNotes = this.state.notes;

        let listItems = (
            <Fragment>
                {filteredNotes &&
                    filteredNotes.map((note) => {
                        let noteId = note.note_id ? note.note_id : note.id;
                        return (
                            <ListItem
                                key={note.title}
                                clickOnMobile
                                clickHandler={(e) => {
                                    if (e.target.tagName === "IMG") {
                                        return;
                                    }

                                    if (this.state.showDraftsTab) {
                                        viewDraftNote(this, noteId, note);
                                        return;
                                    }

                                    viewNote(this, noteId, note);
                                }}>
                                {getNoteListChildren(
                                    this,
                                    note,
                                    patientDetails,
                                    this.state.showDraftsTab,
                                    true,
                                    this.props.profile,
                                )}
                            </ListItem>
                        );
                    })}
            </Fragment>
        );

        const draftActiveClass = this.state.showDraftsTab ? "active" : "";
        const allActiveClass = this.state.showDraftsTab ? "" : "active";
        const therapyCol = this.state.showDraftsTab ? "col-3" : "col-2";
        return (
            <div>
                {this.state.showDeleteNote && (
                    <DeleteNote
                        clickHandler={() =>
                            deleteNote(this, this.state.patientId, this.state.draftNoteId)
                        }
                        close={() => this.setState({ showDeleteNote: false })}
                    />
                )}
                <ListBody>
                    <ListHeader title={"Progress Note"} render={this.renderControls} />
                    <div className="d-flex align-items-center notes-tab justify-content-sm-start justify-content-center">
                        <span
                            onClick={() => this.setState({ showDraftsTab: false })}
                            className={`${allActiveClass} fs-14 fw-bold`}>
                            All Notes
                        </span>
                        <span
                            onClick={() => this.setState({ showDraftsTab: true })}
                            className={`${draftActiveClass} fs-14 fw-bold`}>
                            Draft Notes
                        </span>
                    </div>
                    <ListTitleBar>
                        <TitleBarItem classes="col-2" text="Date" />
                        <TitleBarItem classes="col-4" text="Therapy" />
                        <TitleBarItem classes={therapyCol} text="Note Type" />
                        {!this.state.showDraftsTab ? (
                            <TitleBarItem classes="col-2" text="Posted By" />
                        ) : (
                            <TitleBarItem classes="col-1" text="" />
                        )}
                        <TitleBarItem classes="col-2" text="" />
                    </ListTitleBar>
                    <List>{this.state.notes && listItems}</List>
                </ListBody>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps, { setDynamicRouteConfiguration })(ProgressNoteList);
