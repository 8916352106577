// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";

const HeaderTitle = (props) => {
    const { text } = props;

    return <span className="fw-bold fs-26">{text}</span>;
};

export default HeaderTitle;
