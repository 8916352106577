import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";
export const LinkTypes = {
    link: "apti-Link",
    linkV2: "apti-LinkV2",
    linkV2NoUnderline: "apti-LinkV2-no_underline",
};

export const Link = ({ children, className, href, target, type, onClick, ...props }) => (
    <a
        className={createClassNameString(["apti-CL", type, className])}
        href={href}
        target={target}
        onClick={onClick}
        {...props}>
        {children}
    </a>
);

Link.propTypes = {
    children: PropTypes.oneOf([PropTypes.string, PropTypes.element]).isRequired,
    type: PropTypes.oneOf(["apti-Link", "apti-LinkV2", "apti-LinkV2-no_underline"]),
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    target: PropTypes.string,
    onClick: PropTypes.func,
};

Link.defaultProps = {
    children: "",
    className: undefined,
    type: LinkTypes.link,
    href: "",
    target: "_blank",
    onClick: () => {},
};
