import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
import { Formik } from "formik";

export const CustomForm = ({ initialValues, validationSchema, onSubmit, render, ...props }) => (
    <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        {...props}>
        {(formikProps) => render(formikProps)}
    </Formik>
);

CustomForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    validationSchema: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    render: PropTypes.func.isRequired,
};
