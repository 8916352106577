// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getPatientAge } from "../../../../containers/Dashboard/Providers/schema/newPatientValidation";
import { getProviderProfile, logOut } from "../../../../redux/actions/auth";
import { http } from "../../../../utils/axios";
import { usePopUpVisibility } from "../../../../utils/hooks";
import images from "../../../../utils/images";
import "./profile-menu-styles.scss";

import { ICON_SIZES, ICON_TYPES } from "../../../../constants/icons";
import { AddUserIcon } from "../../../Icons/AddUserIcon";
import { AptihealthIcon } from "../../../Icons/AptihealthIcon";
import { BellIcon } from "../../../Icons/BellIcon";
import { CameraIcon } from "../../../Icons/CameraIcon";
import { CardIcon } from "../../../Icons/CardIcon";
import { ChevronDownIcon } from "../../../Icons/ChevronDownIcon";
import { DocumentIcon } from "../../../Icons/DocumentIcon";
import { EnvelopeIcon } from "../../../Icons/EnvelopeIcon";
import { ExitIcon } from "../../../Icons/ExitIcon";
import { LockIcon } from "../../../Icons/LockIcon";
import { ShieldIcon } from "../../../Icons/ShieldIcon";
import { TourIcon } from "../../../Icons/TourIcon";
import { UserIcon } from "../../../Icons/UserIcon";
import { shouldShowVulnerablePages } from "../../../../utils/authUtils";
import { getAutomationDataAttr } from "../../../../utils/automationUtils";

const PatientMenu = (props) => {
    const [avatar, setAvatar] = useState(null);
    const [popupWasOpened, setPopupWasOpened] = useState(false);
    const { ref: popUpRef, isPopUpVisible, setIsPopUpVisible } = usePopUpVisibility(false);
    const isMobile = window.innerWidth <= 640;

    const fetchAvatar = function () {
        return http.post("/v1/patient/get_image", {
            key: props.profile.avatar,
        });
    };

    useEffect(function () {
        (async () => {
            if (props.profile && props.profile.avatar) {
                let cleanResponse = await fetchAvatar();
                if (cleanResponse && cleanResponse.url) {
                    setAvatar(cleanResponse.url);
                }
            }
        })();
    }, []);

    const dismissMenu = () => {
        setIsPopUpVisible(false);
    };

    const toggleProfileMenu = () => {
        isMobile
            ? props.history.push(props.baseRoute + "/settings")
            : setIsPopUpVisible(!isPopUpVisible);
    };

    const handleProfile = () => {
        dismissMenu();
        props.history.push(props.baseRoute + "/profile");
    };

    const handlePassword = () => {
        dismissMenu();
        props.history.push(props.baseRoute + "/change-password");
    };

    const handleAddChild = () => {
        dismissMenu();
        props.history.push(props.baseRoute + "/teens-and-kids/program-details");
    };

    const handleNotification = () => {
        dismissMenu();
        props.history.push(props.baseRoute + "/patient-notifications");
    };

    const handleTutorials = () => {
        dismissMenu();
        props.history.push(props.baseRoute + "/tutorial");
    };

    const handleSystemCheck = () => {
        dismissMenu();
        props.history.push(props.baseRoute + "/system-check");
    };

    const handleAVCheck = () => {
        dismissMenu();
        props.history.push(props.baseRoute + "/av-check");
    };

    const handleInsurance = () => {
        dismissMenu();
        props.history.push(props.baseRoute + "/patient-insurance");
    };

    const handlePayment = () => {
        dismissMenu();
        props.history.push(props.baseRoute + "/patient-payment");
    };

    const handleLogout = () => {
        dismissMenu();
        props.logOut({ history: props.history });
    };

    let name = props.profile ? props.profile.first_name + " " + props.profile.last_name : "";

    const profilePic = (height, width) => {
        return (
            <div className="Toolbar__ProfileMenu-avatar pr-2">
                {avatar && (
                    <img src={avatar} style={{ height: height, width: width }} alt="avatar" />
                )}
                {!avatar && (
                    <img
                        src={images("./common/avatar.png")}
                        style={{ height: height, width: width }}
                        alt="avatar"
                    />
                )}
            </div>
        );
    };

    const displayAddChild = () => {
        return 18 < getPatientAge(props.profile.dob);
    };

    useEffect(() => {
        if (!popupWasOpened && isPopUpVisible) {
            setPopupWasOpened(true);
        }
    }, [isPopUpVisible]);

    const profileDropdown = (
        <div style={{ display: popupWasOpened ? "block" : "none" }}>
            <div
                className={`Toolbar__ProfileMenu-dropdown d-flex flex-column px-4 py-2 fs-14 mr-3 ${
                    isPopUpVisible ? "open" : "close"
                } ${!isPopUpVisible ? "disabled-dropdown" : ""}`}>
                <div className="d-flex flex-column patient-dropdown-section pb-2 mt-2">
                    <span className="patient-menu-subsection">ACCOUNT</span>
                    <div
                        className="patient-dropdown-text menu-option-item"
                        onClick={() => handleProfile()}>
                        <UserIcon
                            iconType={ICON_TYPES.OUTLINE}
                            iconSize={ICON_SIZES.SMALL}
                            className="patient-item-icon"
                        />
                        <span>Profile</span>
                    </div>
                    <div
                        className="patient-dropdown-text menu-option-item"
                        {...getAutomationDataAttr("ProfileMenuChangePassword")}
                        onClick={() => handlePassword()}>
                        <LockIcon
                            iconType={ICON_TYPES.OUTLINE}
                            iconSize={ICON_SIZES.SMALL}
                            className="patient-item-icon"
                        />
                        <span>Password</span>
                    </div>
                    <div
                        className="patient-dropdown-text menu-option-item"
                        onClick={() => handleInsurance()}>
                        <ShieldIcon
                            iconType={ICON_TYPES.OUTLINE}
                            iconSize={ICON_SIZES.SMALL}
                            className="patient-item-icon"
                        />
                        <span>Insurance</span>
                    </div>
                    {shouldShowVulnerablePages(props.profile.status) && (
                        <div
                            className="patient-dropdown-text menu-option-item"
                            onClick={() => handlePayment()}>
                            <CardIcon
                                iconType={ICON_TYPES.OUTLINE}
                                iconSize={ICON_SIZES.SMALL}
                                className="patient-item-icon"
                            />
                            <span>Payment</span>
                        </div>
                    )}
                    {displayAddChild() && (
                        <div
                            className="patient-dropdown-text menu-option-item"
                            onClick={() => handleAddChild()}>
                            <AddUserIcon
                                iconType={ICON_TYPES.OUTLINE}
                                iconSize={ICON_SIZES.SMALL}
                                className="patient-item-icon"
                            />
                            <span>Add Child</span>
                        </div>
                    )}
                    <div
                        className="patient-dropdown-text menu-option-item"
                        onClick={() => handleNotification()}>
                        <BellIcon
                            iconType={ICON_TYPES.OUTLINE}
                            iconSize={ICON_SIZES.SMALL}
                            className="patient-item-icon"
                        />
                        <span>Notifications</span>
                    </div>
                </div>
                <div className="d-flex flex-column patient-dropdown-section pb-2 mt-2">
                    <span className="patient-menu-subsection">SUPPORT</span>
                    <a
                        className={"patient-dropdown-links"}
                        href={"https://www.aptihealth.com/about/"}
                        target="_blank"
                        rel="noreferrer">
                        <div className="patient-dropdown-text menu-option-item">
                            <AptihealthIcon
                                iconType={ICON_TYPES.OUTLINE}
                                iconSize={ICON_SIZES.SMALL}
                                className="patient-item-icon"
                            />
                            <span>About aptihealth</span>
                        </div>
                    </a>
                    <a
                        className={"patient-dropdown-links"}
                        href="mailto:support@aptihealth.com?subject=Web Support Request"
                        target="_top">
                        <div className="patient-dropdown-text menu-option-item">
                            <EnvelopeIcon
                                iconType={ICON_TYPES.OUTLINE}
                                iconSize={ICON_SIZES.SMALL}
                                className="patient-item-icon"
                            />
                            <span>Member Support</span>
                        </div>
                    </a>
                    <div
                        className="patient-dropdown-text menu-option-item"
                        onClick={() => handleTutorials()}>
                        <TourIcon
                            iconType={ICON_TYPES.OUTLINE}
                            iconSize={ICON_SIZES.SMALL}
                            className="patient-item-icon"
                        />
                        <span>Tutorials</span>
                    </div>
                    <div
                        className="patient-dropdown-text menu-option-item"
                        onClick={() => handleSystemCheck()}>
                        <CameraIcon
                            iconType={ICON_TYPES.OUTLINE}
                            iconSize={ICON_SIZES.SMALL}
                            className="patient-item-icon"
                        />
                        <span>System & Video Check</span>
                    </div>
                </div>
                <div className="d-flex flex-column patient-dropdown-section pb-2 mt-2">
                    <span className="patient-menu-subsection">LEGAL</span>
                    <a
                        className={"patient-dropdown-links menu-option-item"}
                        href="https://www.aptihealth.com/hipaaprivacy"
                        target="_blank"
                        rel="noreferrer">
                        <div className="patient-dropdown-text menu-option-item">
                            <DocumentIcon
                                iconType={ICON_TYPES.OUTLINE}
                                iconSize={ICON_SIZES.SMALL}
                                className="patient-item-icon"
                            />
                            <span>HIPAA Notice</span>
                        </div>
                    </a>
                </div>

                <div
                    className="menu-option-item w-100 d-flex align-items-center cursor log-out-section"
                    onClick={handleLogout}>
                    <ExitIcon
                        iconType={ICON_TYPES.OUTLINE}
                        iconSize={ICON_SIZES.SMALL}
                        className="log-out-icon"
                    />
                    <span className="ml-1">Log Out</span>
                </div>
            </div>
        </div>
    );

    const profileMenuItem = (
        <div
            onClick={toggleProfileMenu}
            className="Toolbar__ProfileMenu  cursor d-flex align-items-center">
            <div
                className="Toolbar__ProfileMenu-name-wpr d-lg-flex flex-column d-md-none d-sm-none d-none align-content-end"
                style={{ marginTop: 0 }}>
                <span
                    className="fs-16 txt-ellipsis NavUserNameSpanPatient"
                    style={{ whiteSpace: "nowrap" }}>
                    {name}
                </span>
            </div>
            <div
                className="Toolbar__ProfileMenu-caret ml-2 d-lg-block d-md-none d-sm-none d-none"
                {...getAutomationDataAttr("ProfileMenu")}>
                <ChevronDownIcon className="Toolbar__ChevronDown" iconSize={ICON_SIZES.SMALL} />
            </div>
            <div
                className="Toolbar__ProfileMenu-name-wpr d-block d-lg-none d-flex flex-column align-items-center"
                style={{ marginTop: 0, fill: "#FFFFFF" }}>
                <UserIcon iconType={ICON_TYPES.OUTLINE} iconSize={ICON_SIZES.SMALL} />
                <span
                    className="ToolbarUserName fs-16 txt-ellipsis"
                    style={{ whiteSpace: "nowrap" }}>
                    {name}
                </span>
            </div>
        </div>
    );

    return (
        <div ref={popUpRef} className="position-relative">
            {props.profile ? (
                profileMenuItem
            ) : (
                <span className="Toolbar__ProfileMenu-placeholder" />
            )}
            {profileDropdown}
        </div>
    );
};

export default connect(null, { logOut, getProviderProfile })(withRouter(PatientMenu));
