// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import CardSecondary from "../../UI/Card/CardSecondary";
import images from "../../../utils/images";
import { CardHeading } from "../../UI/Headings";
import { Link } from "react-router-dom";
import { providerRoute } from "../../../config/roles";

const ClinicalAssessmentCard = (props) => {
    const { type } = props;
    let cardText = "";
    if (type === "initial") {
        cardText = " Clinical Assessment for the Patient";
    } else {
        cardText = " Clinical Outcome Assessment for the Patient";
    }
    return (
        <CardSecondary
            style={{ maxWidth: "100%" }}
            className="PatientProfile__CreatePlan-wpr-white PatientProfile__CardBetweenMargin
            mx-lg-auto py-4 d-xl-flex d-block justify-content-between align-items-center px-3 px-lg-4">
            <div className="d-flex align-items-center">
                <img
                    src={images("./common/clinical_assessment_icon.svg")}
                    alt="90 Days Plan"
                    className="PatientProfile__90DaysPlan img-fluid"
                    width="39px"
                />
                <CardHeading
                    text={(props.done ? "View" : "Complete") + cardText}
                    className="d-lg-inline-block ml-2 pl-2 fs-14 fs-lg-16"
                />
            </div>
            <div className="ml-auto mt-3 mt-lg-0">
                <div className="d-block text-right">
                    <Link
                        style={{ color: "white" }}
                        className="ClinicalAssessment_Btn Btn Btn--pri Btn-sm"
                        to={`${providerRoute}/patient/${props.patientId}/clinical_assessment?type=${type}`}>
                        Clinical Assessment
                    </Link>
                </div>
            </div>
        </CardSecondary>
    );
};

export default ClinicalAssessmentCard;
