// Copyright aptihealth, inc. 2019 All Rights Reserved

import authRequests from "./auth";
import patientRequests from "./patient";
import metricsRequests from "./metrics";
import providerRequests from "./provider";
import sharedRequests from "./shared";
import adminRequests from "./admin";
import messagingRequests from "./messaging";
import a5ScreeningRequests from "./a5Screening";
import searchRequests from "./search";
import trackingRequests from "./tracking";
import publicRequests from "./public";
import workflowRequests from "./workflow";
import paymentRequests from "./payment";
import obfuscationRequests from "./obfuscation";
import workingHourRequests from "./workingHours";
import scheduleRequests from "./schedule";
import featureFlagRequests from "./featureFlag";
import { carePlanRequests } from "./care_plan";

export const api = {
    auth: authRequests,
    messaging: messagingRequests,
    metrics: metricsRequests,
    patient: patientRequests,
    provider: providerRequests,
    workingHours: workingHourRequests,
    shared: sharedRequests,
    admin: adminRequests,
    a5Screening: a5ScreeningRequests,
    search: searchRequests,
    tracking: trackingRequests,
    public: publicRequests,
    workflow: workflowRequests,
    payment: paymentRequests,
    obfusctaion: obfuscationRequests,
    schedule: scheduleRequests,
    featureFlag: featureFlagRequests,
    carePlan: carePlanRequests,
};
