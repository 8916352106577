// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Fragment } from "react";

//components
import Input from "../../../../components/UI/Input";
import Button from "../../../../components/UI/Button";

//utils
import { toTitleCase } from "../../../../utils/filters";
import images from "../../../../utils/images";
import moment from "moment-timezone";

//constants
import { withRouter } from "react-router-dom";
import { getAutomationDataAttr } from "../../../../utils/automationUtils";

const PendingPatientListItem = (props) => {
    const {
        index,
        userType,
        patient,
        setPatientDetails,
        badgeStyle,
        resendBtnHandler,
        archiveBtnHandler,
    } = props;

    const {
        patient_id,
        first_name,
        last_name,
        patient_status,
        created_date,
        contacted,
        referral_status,
    } = patient;

    const patientName = first_name + " " + last_name;
    const name = toTitleCase(patient.first_name + " " + patient.last_name);
    const createdDate = created_date
        ? moment(parseFloat(created_date) * 1000).format("MM/DD/YYYY")
        : null;

    const formatMobileNumber = (val) => {
        let firstNumber = val.substring(0, 3);
        let middleNumber = val.substring(3, 6);
        let lastNumber = val.substring(6, 10);
        return `(${firstNumber}) ${middleNumber}-${lastNumber}`;
    };

    let mobileListItem = (
        <>
            <div className="d-flex d-lg-none justify-content-between">
                <div
                    className="d-flex fs-14 flex-column"
                    style={{ width: "65%", wordWrap: "break-word" }}>
                    <span className="fs-16 fw-bold">{name}</span>
                    <span>{patient.email}</span>
                    <span>{formatMobileNumber(patient.mobile)}</span>
                </div>
                <div className="d-flex fs-14 flex-column">
                    <div>
                        <span>{createdDate}</span>
                    </div>
                    <div>{contacted ? "Contacted" : null}</div>
                </div>
            </div>
            <div className="d-flex d-lg-none justify-content-between mt-4">
                <Button
                    className={"Btn Btn--otl-pri Btn-xs fs-12"}
                    disabled={patient.btnStatus}
                    id={`archiveBtn-${patient["referral_code"]}`}
                    onClick={() => archiveBtnHandler(patient["referral_code"], name)}>
                    Archive
                </Button>
                <Button
                    className={`Btn Btn--pri Btn-xs fs-12 ${patient.btnClr}`}
                    disabled={patient.btnStatus}
                    onClick={() => resendBtnHandler(patient.email)}>
                    {patient.btnText}
                </Button>
                <Button
                    className="Btn Btn--sec Btn-xs fs-12 "
                    onClick={() => props.history.push("/app/referral/" + patient["referral_code"])}>
                    View
                </Button>
            </div>
        </>
    );
    let desktopListItem = (
        <div className="row no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between">
            <span
                className="col-2"
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                data-toggle="tooltip"
                data-placement="top"
                title={name}
                {...getAutomationDataAttr("PendingPatientListItemPatientName")}>
                {name}
            </span>
            <span
                className="col-3"
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                data-toggle="tooltip"
                data-placement="top"
                title={patient.email}>
                {patient.email}
            </span>
            <span className="col-2">{formatMobileNumber(patient.mobile)}</span>
            <span className="col-1">{createdDate}</span>

            <span className="col-1">
                {contacted && (
                    <Input
                        type="checkbox"
                        name="contacted"
                        checked={contacted ? "checked" : null}
                        disabled
                        className="AddPatientInput ml-3"
                    />
                )}
            </span>
            <div className="col-1">
                <Button
                    className={"Btn Btn--otl-pri Btn-xs fs-12"}
                    disabled={patient.btnStatus}
                    onClick={() => archiveBtnHandler(patient["referral_code"], name)}>
                    Archive
                </Button>
            </div>
            <div className="col-1">
                <Button
                    className={`Btn Btn-xs fs-12 ${patient.btnClr}`}
                    disabled={patient.btnStatus}
                    onClick={() => resendBtnHandler(patient.email)}>
                    {patient.btnText}
                </Button>
            </div>

            <div className="col-1 d-flex d-flex justify-content-center">
                <div className="cursor ml-5">
                    <img
                        onClick={() =>
                            props.history.push("/app/referral/" + patient["referral_code"])
                        }
                        {...getAutomationDataAttr("ViewPendingPatient")}
                        src={images("./common/view.png")}
                        alt="view icon"
                    />
                </div>
            </div>
        </div>
    );

    return (
        <Fragment>
            {mobileListItem}
            {desktopListItem}
        </Fragment>
    );
};

export default withRouter(PendingPatientListItem);
