import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import { createClassNameString } from "../utils";

import "./styles.scss";

// Uses a styled version of 'react-select', see https://react-select.com

export const Dropdown = ({
    children,
    className,

    name,
    value,
    error,

    getValue,
    setValue,

    options,
    defaultOptions,
    cacheOptions,
    loadOptions,

    isDisabled,
    isLoading,
    isFocused,

    isMulti,
    isSearchable,

    placeholder,
    loadingMessage,
    noOptionsMessage,

    menuPlacement,
    menuPosition,

    onChange,
    onBlur,
    onFocus,
    onKeyDown,

    filterOptions,
    isClearable,
    ...props
}) => {
    // Since react-select does not provider a standard event to onChange
    // we are created a faux event here to pass into onChange
    const handleChange = (event) => {
        if (!event) {
            onChange({
                target: {
                    name: name,
                    value: null,
                },
            });
        }
        // handles multi select
        else if (Array.isArray(event)) {
            onChange({
                target: {
                    name: name,
                    value: event,
                },
            });
        }
        // if not multi select and event has a truthy value
        else {
            const value = event.value;
            onChange({
                target: {
                    name: name,
                    value: value,
                },
            });
        }
    };

    const safeGetValue = () => {
        if (Array.isArray(value)) {
            return value;
        }
        if (value && value.value !== null && typeof value.value !== "undefined") {
            return value;
        }

        return null;
    };
    return (
        <Select
            className={createClassNameString([
                "apti-CL",
                "apti-Dropdown",
                className,
                error && "error",
            ])}
            classNamePrefix="react-select"
            name={name}
            value={safeGetValue()}
            getValue={getValue}
            setValue={setValue}
            options={options}
            defaultOptions={defaultOptions}
            cacheOptions={cacheOptions}
            loadOptions={loadOptions}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isFocused={isFocused}
            isMulti={isMulti}
            isSearchable={isSearchable}
            placeholder={placeholder}
            loadingMessage={loadingMessage}
            noOptionsMessage={noOptionsMessage}
            menuPlacement={menuPlacement}
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            filterOption={filterOptions}
            isClearable={isClearable}
            menuPosition={menuPosition}
            {...props}>
            {children}
        </Select>
    );
};

Dropdown.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.element,
    ]),
    className: PropTypes.string,

    name: PropTypes.string,
    value: PropTypes.object,
    error: PropTypes.bool,

    getValue: PropTypes.func,
    setValue: PropTypes.func,

    options: PropTypes.array.isRequired,
    defaultOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    cacheOptions: PropTypes.bool,
    loadOptions: PropTypes.array,

    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isFocused: PropTypes.bool,

    isMulti: PropTypes.bool,
    isSearchable: PropTypes.bool,

    placeholder: PropTypes.string,
    loadingMessage: PropTypes.string,
    noOptionsMessage: PropTypes.string,

    menuPlacement: PropTypes.oneOf(["bottom", "top"]),

    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    filterOptions: PropTypes.func,
    isClearable: PropTypes.bool,
};

Dropdown.defaultProps = {
    children: <></>,
    className: undefined,

    name: "",
    value: undefined,
    error: false,

    getValue: () => {},
    setValue: () => {},

    options: [],
    defaultOptions: [],
    cacheOptions: false,
    loadOptions: undefined,

    isDisabled: false,
    isLoading: false,
    isFocused: false,

    isMulti: false,
    isSearchable: true,

    placeholder: undefined,
    loadingMessage: undefined,
    noOptionsMessage: undefined,

    menuPlacement: "bottom",

    onChange: () => {},
    onBlur: () => {},
    onFocus: () => {},
    onKeyDown: () => {},
    filterOptions: undefined,
    isClearable: false,
};
