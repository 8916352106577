import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button } from "../Button";

import "./styles.scss";
import { createClassNameString } from "../utils";
import { Image } from "../Image";

export const StepSelector = ({
    className,
    currentStep,
    totalSteps,
    onLeftClick,
    onRightClick,
    ...props
}) => {
    const leftArrowDisabled = currentStep === 1;
    const rightArrowDisabled = currentStep === totalSteps;

    return (
        <div className={createClassNameString(["apti-StepSelector", className])}>
            <Button
                className={leftArrowDisabled && "disabled"}
                onClick={leftArrowDisabled ? undefined : onLeftClick}>
                <Image src={"icons/common/chevron-left-blue.svg"} />
            </Button>
            <div className={"apti-StepSelector--text"}>
                {currentStep} of {totalSteps}
            </div>
            <Button
                className={rightArrowDisabled && "disabled"}
                onClick={rightArrowDisabled ? undefined : onRightClick}>
                <Image src={"icons/common/chevron-right-blue.svg"} />
            </Button>
        </div>
    );
};

StepSelector.propTypes = {
    currentStep: PropTypes.string,
    totalSteps: PropTypes.string,
    className: PropTypes.string,
};

StepSelector.defaultProps = {
    currentStep: "",
    totalSteps: "",
    className: undefined,
};
