import React, { useEffect, useState } from "react";
import "./styles.scss";
import images from "../../../utils/images";

const ImageFullPreview = ({ url, close, wideView, contentType }) => {
    let imgClass = wideView ? "ImageFullPreview__img-wpr-wide" : "ImageFullPreview__img-wpr";

    return (
        <div className="ImageFullPreview">
            <div className="ImageFullPreview__inner position-absolute">
                <div className={`${imgClass} p-2`}>
                    <img
                        className="mb-2 ImageFullPreview__close-btn"
                        src={images("./icons/cross.svg")}
                        role="button"
                        onClick={close}
                        alt="close"
                    />
                    <div className="w-100 h-100" data-private={"true"}>
                        {((contentType && contentType.includes("pdf")) ||
                            contentType === undefined) && (
                            <object className="img-fluid" data={url} width="100%" height="100%" />
                        )}
                        {contentType && !contentType.includes("pdf") && (
                            <img className="img-fluid" src={url} width="100%" height="100%" />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageFullPreview;
