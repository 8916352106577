// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component, useState } from "react";
import { api } from "../../../APIRequests";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { getQueryParams } from "../../../utils/filters";
import CardSecondary from "../../../components/UI/Card/CardSecondary";
import images from "../../../utils/images";
import Button from "../../../components/UI/Button";
import { Link } from "react-router-dom";
import { daySelectHandler } from "../Patient/SchedulePage";
import ScheduleWidgets from "../../../components/Common/Schedule/ScheduleWidgets";
import {
    _30_MIN_INDIVIDUAL_90832,
    _45_MIN_INDIVIDUAL_90834,
    allottedTimeFactory,
    DIAGNOSTIC_INTERVIEW_90792,
} from "../../../components/Provider/ScheduleAvailability/constants";
import { eventTypeDisplayNameFactory } from "../../../components/Provider/ScheduleAvailability/ActiveScheduleDetails";
import { showAlertWithAction } from "../../../redux/actions/alerts";

class OnBehalfOfSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            providerId: this.props.match.params.providerId,
            patientId: getQueryParams("patientId", this.props.location.search),
            patientName: "",
            providerName: getQueryParams("providerName", this.props.location.search),
            providerType: getQueryParams("providerType", this.props.location.search),
            timeSlots: null,
            dateStr: "",
            selectedTimeSlot: null,
            timeSlotsLoading: false,
            isDisabled: true,
            slotsCount: 0,
            selectedDate: null,
            appointmentDetail: null,
            patientAvatar: images("./common/avatar.png"),
            providerAvatar: images("./common/avatar.png"),
            eventType:
                getQueryParams("providerType", props.location.search) === "Prescriber"
                    ? DIAGNOSTIC_INTERVIEW_90792
                    : _45_MIN_INDIVIDUAL_90834,
        };
    }

    /**
     * Used to catch navigations to pages rending this component with incorrect URL parameters. This can happen in
     * edge cases where a user opens the page as one user type (say CSE), logs in as another user type (say BHS) and
     * reloads the page. Users types may have overlapping routes with different parameter requirements.
     * @returns {boolean} Whether or not URL parameters are valid
     */
    urlParamsValid = () => {
        if (!this.state.patientId || !this.state.providerId) {
            this.props.showAlertWithAction("Patient and provider must be selected when scheduling");
            return false;
        }
        return true;
    };

    componentDidMount = async () => {
        if (!this.urlParamsValid()) {
            return;
        }

        this.resetDaySelection();
        let patient = await api.shared.fetch_patient_details({
            urlParams: { patientId: this.state.patientId },
        });
        let patientAvatar = patient.user.avatar_url
            ? patient.user.avatar_url
            : images("./common/avatar.png");
        let provider = await api.provider.fetch_provider_profile({
            queryParams: { providerId: this.state.providerId },
        });
        let providerAvatar = provider.user.avatar_url
            ? provider.user.avatar_url
            : images("./common/avatar.png");
        let patientName = patient.user["first_name"] + " " + patient.user["last_name"];
        this.setState({
            patientAvatar,
            providerAvatar,
            patientName,
        });
    };

    getAvailableSchedules = (dateString, providerId) => {
        if (!this.urlParamsValid()) {
            return;
        }

        let urlParams = {
            dateString,
            tz: this.props.timezone,
            providerId,
            patientId: this.state.patientId,
            providerType:
                this.state.providerType === "Behavioral Specialist" ? "BEHAVIORAL" : "PRESCRIBE",
            allottedTime: allottedTimeFactory(this.state.eventType),
            eventType: this.state.eventType,
        };
        const options = { params: { showLoader: true } };
        return api.shared.available_schedules({ urlParams, options });
    };

    scheduleCall = () => {
        const { timezone } = this.props;
        const combinedDateTime = this.state.selectedDate + " " + this.state.selectedTimeSlot;

        const data = {
            local_date_time: moment
                .tz(combinedDateTime, "YYYY-MM-DD hh:mm a", timezone)
                .format("YYYY-MM-DD HH:mm"),
            timezone,
            provider_id: this.state.providerId,
            patient_id: this.state.patientId,
            event_type: this.state.eventType,
            allotted_time: allottedTimeFactory(this.state.eventType),
        };
        return api.schedule.post_schedule({ data });
    };

    onTimeSlotClick = (slotValue) => {
        this.setState({
            selectedTimeSlot: slotValue,
            isDisabled: false,
        });
    };

    onEventTypeChange = (eventType) => {
        this.setState(
            {
                eventType: eventType,
            },
            () => this.resetDaySelection(),
        );
    };

    onAddingSlot = () => {
        this.scheduleCall()
            .then((cleanResponse) => {
                let appointmentDetail = {
                    providerName: this.state.providerName,
                    patientName: this.state.patientName,
                };
                this.setState({
                    appointmentDetail: appointmentDetail,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    resetDaySelection() {
        let today = moment().tz(this.props.timezone).format("YYYY-MM-DD");
        let searchDate = this.state.selectedDate ? this.state.selectedDate : today;
        daySelectHandler(searchDate, this);
    }

    scheduleSuccessPopUp = (appointmentDetail) => {
        const eventDisplay = eventTypeDisplayNameFactory(this.state.eventType)
            ? eventTypeDisplayNameFactory(this.state.eventType)
            : "Initial Consultation";
        return (
            <div className="overlay">
                <div className="ScheduleSuccess-wpr">
                    <CardSecondary className="mx-lg-auto">
                        <div className="px-5 py-4 text-center">
                            <img
                                src={images("./common/successCheck.svg")}
                                alt="success"
                                className="mb-4"
                            />
                            <p className="ScheduleSuccess__text fs-17 fw-bold">
                                Appointment Scheduled
                            </p>
                            <p className="ScheduleSuccess__text fs-15 mb-4">
                                {" "}
                                {eventDisplay} between {appointmentDetail.patientName} &{" "}
                                {appointmentDetail.providerName} has been successfully scheduled.
                            </p>
                            <Button
                                className="Btn Btn--pri"
                                onClick={() => this.props.history.goBack()}>
                                OK
                            </Button>
                        </div>
                    </CardSecondary>
                </div>
            </div>
        );
    };

    render() {
        let timeSlotConf = {
            selectedTimeSlot: this.state.selectedTimeSlot,
            isDisabled: this.state.isDisabled,
            selectedDate: this.state.selectedDate,
        };

        return (
            <div>
                <div className="Schedule__wpr p-lg-4 mx-auto row no-gutters">
                    <div style={{ width: "100%" }} className="col-10 pb-2">
                        <Link
                            className="PatientProfile-back-link"
                            onClick={() => this.props.history.goBack()}>
                            <img
                                className="img-fluid"
                                src={images("./common/solidBckBtn.svg")}
                                alt={"Back"}
                            />
                            <p className="d-inline ml-2">Back to Care Team</p>
                        </Link>
                        <CardSecondary
                            style={{ maxWidth: "100%" }}
                            className="PatientProfile__CreatePlan-wpr py-1 px-3 px-xl-4 d-block">
                            <div className="d-flex align-items-center">
                                <img
                                    src={this.state.patientAvatar}
                                    alt="Patient Avatar"
                                    className="PatientProfile__90DaysPlan img-fluid"
                                    width="55px"
                                />
                                <img
                                    src={this.state.providerAvatar}
                                    alt="Provider Avatar"
                                    style={{ marginLeft: -20 }}
                                    className="PatientProfile__90DaysPlan img-fluid"
                                    width="55px"
                                />
                                <div>
                                    <p
                                        className="text-white fs-18"
                                        style={{ marginLeft: 15, marginTop: 20 }}>
                                        <b>
                                            {this.state.patientName +
                                                " & " +
                                                this.state.providerName}
                                        </b>
                                    </p>
                                    <p
                                        className="text-white fs-16"
                                        style={{ marginLeft: 15, marginTop: -15 }}>
                                        Video Consultation between Patient and{" "}
                                        {this.state.providerType}
                                    </p>
                                </div>
                            </div>
                        </CardSecondary>
                    </div>
                    {this.state.appointmentDetail &&
                        this.scheduleSuccessPopUp(this.state.appointmentDetail)}
                    <ScheduleWidgets
                        timeSlotsLoading={this.state.timeSlotsLoading}
                        timeSlots={this.state.timeSlots}
                        that={this}
                        timeSlotConf={timeSlotConf}
                        timezone={this.props.timezone}
                        onTimeSlotClick={this.onTimeSlotClick}
                        onAddingSlot={this.onAddingSlot}
                        dateStr={this.state.dateStr}
                        eventType={this.state.eventType}
                        onEventTypeChange={this.onEventTypeChange}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        timezone: state.auth.timezone,
    };
};

export default connect(mapStateToProps, { showAlertWithAction })(OnBehalfOfSchedule);
