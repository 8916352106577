// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";

const Header = (props) => {
    let classes = "List__Header" + " " + props.className || "";
    let renderContainerClassName = props.renderContainerClassName
        ? props.renderContainerClassName
        : "";
    return (
        <div className={classes} style={props.style}>
            <div
                className="List__header-inner d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between"
                data-public="true">
                {props.title && <span className="mb-3 mb-lg-0 fs-18 fw-bold">{props.title}</span>}
                <div className={renderContainerClassName}>{props.render && props.render()}</div>
            </div>
        </div>
    );
};

export default Header;
