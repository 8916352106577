// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { useEffect, useState } from "react";
import { deviceDetect } from "react-device-detect";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import parser from "ua-parser-js";
import { api } from "../../../APIRequests";
import { getRole } from "../../../redux/actions/auth";
import images from "../../../utils/images";
import Button from "../../UI/Button";
import "./styles.scss";

const SystemCheck = (props) => {
    const [deviceInfo, setDeviceInfo] = useState(null);
    const [uaParser, setUaParser] = useState(null);
    const [osInfo, setOsInfo] = useState(null);
    const [browserInfo, setBrowserInfo] = useState(null);
    const [internetConnectionInfo, setInternetConnectionInfo] = useState(null);

    useEffect(() => {
        const deviceInfo = deviceDetect();
        setDeviceInfo(deviceInfo);
        const uaParser = parser();
        setUaParser(uaParser);
        if (deviceInfo.isBrowser) {
            setOsInfo(deviceInfo.osName + " " + deviceInfo.osVersion);
            setBrowserInfo(deviceInfo.browserName + " " + deviceInfo.browserMajorVersion);
        } else {
            setOsInfo(deviceInfo.os + " " + deviceInfo.osVersion);
            setBrowserInfo(uaParser.browser.name + " " + uaParser.browser.major);
        }
        setInternetConnectionInfo(navigator.onLine ? "Online" : "Offline");
    }, []);

    const submitSystemCheck = async () => {
        try {
            const preferences = props.profile.hasOwnProperty("preferences")
                ? props.profile.preferences
                : {};
            preferences["system"] = {
                device_info: deviceInfo,
                screen: {
                    width: window.screen.width,
                    height: window.screen.height,
                    devicePixelRatio: Math.round(window.devicePixelRatio * 100),
                },
                internet_connection_info: {
                    on_line: internetConnectionInfo === "Online",
                },
            };
            const data = { preferences, username: props.profile.username };
            const currentUserRole = getRole();
            if (currentUserRole.startsWith("provider")) {
                await api.provider.update_profile({ data });
            } else if (currentUserRole.startsWith("user")) {
                await api.patient.update_profile({ data });
            }
            props.history.push("/app/av-check");
        } catch (e) {
            console.log(e);
            props.history.push("/app/av-check");
        }
    };

    const isSupportedOs = () =>
        ["Windows", "Mac", "iOS", "Android"].find((os) => osInfo && os === osInfo.split(" ")[0]);

    return (
        <>
            <div className="viewTutorial" data-public="true">
                <div className="container">
                    <div className="viewTutorial-card px-3 py-5 px-lg-5 mb-5">
                        <div className={"text-center"}>
                            <img src={images("./common/setting.png")} alt="aptihealth" />
                            <div className={"mx-lg-5 mx-1 mt-3 mb-5 text-center fs-lg-16 fs-14"}>
                                <div className={"text-left ml-lg-5 ml-3"}>
                                    <div className={"my-3"}>
                                        {isSupportedOs() && (
                                            <img
                                                src={images("./common/green-check-2.svg")}
                                                alt="pass"
                                            />
                                        )}
                                        {!isSupportedOs() && (
                                            <img
                                                src={images("./common/red-cross.svg")}
                                                alt="fail"
                                            />
                                        )}
                                        <span className={"ml-3 fw-bold"}>OS - </span>
                                        <span>{osInfo ? osInfo : "Unable to Detect"}</span>
                                    </div>
                                    {deviceInfo && (
                                        <div className={"my-3"}>
                                            {isSupportedBrowser(deviceInfo, uaParser) && (
                                                <img
                                                    src={images("./common/green-check-2.svg")}
                                                    alt="pass"
                                                />
                                            )}
                                            {!isSupportedBrowser(deviceInfo, uaParser) && (
                                                <img
                                                    src={images("./common/red-cross.svg")}
                                                    alt="fail"
                                                />
                                            )}
                                            <span className={"ml-3 fw-bold"}>Browser - </span>
                                            <span>
                                                {browserInfo ? browserInfo : "Unable to Detect"}
                                            </span>
                                        </div>
                                    )}
                                    <div className={"my-3"}>
                                        {internetConnectionInfo === "Online" && (
                                            <img
                                                src={images("./common/green-check-2.svg")}
                                                alt="pass"
                                            />
                                        )}
                                        {internetConnectionInfo !== "Online" && (
                                            <img
                                                src={images("./common/red-cross.svg")}
                                                alt="fail"
                                            />
                                        )}
                                        <span className={"ml-3 fw-bold"}>
                                            Internet Connection -{" "}
                                        </span>
                                        <span>
                                            {internetConnectionInfo
                                                ? internetConnectionInfo
                                                : "Unable to Detect"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={"txt-gry fs-12 my-3"}>
                                If you have any issues please contact support at
                                support@aptihealth.com.
                            </div>
                            <Button
                                className="Btn Btn--pri Btn-sm fs-16 text-center text-white"
                                onClick={submitSystemCheck}>
                                Next
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};
export default connect(mapStateToProps)(withRouter(SystemCheck));

export const isSupportedBrowser = (deviceInfo, uaParser) => {
    const minSupportedBrowserVersions = {
        Chrome: 84,
        Safari: 12,
        "Mobile Safari": 12,
        Firefox: 80,
    };

    const browserName = deviceInfo.browserName || uaParser.browser.name;
    const browserVersion = deviceInfo.browserMajorVersion || uaParser.browser.major;

    let isSupported =
        deviceInfo &&
        minSupportedBrowserVersions.hasOwnProperty(browserName) &&
        parseInt(browserVersion) >= minSupportedBrowserVersions[browserName];
    const os = deviceInfo.osName || deviceInfo.os;
    if (os === "iOS" && browserName === "Chrome") {
        isSupported = false;
    }
    return isSupported;
};
