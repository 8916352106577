import React, { useEffect, useState } from "react";

import "./styles.scss";
import GenericList from "../../Common/GenericList/GenericList";
import { api } from "../../../APIRequests";
import moment from "moment-timezone";
import images from "../../../utils/images";
import _ from "lodash";

const DraftNotesTable = (props) => {
    const [notes, setNotes] = useState([]);
    const providerId = _.get(props, "match.params.providerId");

    useEffect(() => {
        (async () => {
            const data = {};
            if (providerId) {
                data["provider_id"] = providerId;
            }
            const response = await api.provider.fetch_provider_draft_notes(data);
            setNotes(response);
        })();
    }, []);

    const column_options = [
        {
            display_name: "Patient Name",
            field_accessor: { key: "name", render_function: "" },
            col_size: "col-4",
        },
        {
            display_name: "Date of Service",
            field_accessor: { key: "date_of_service", render_function: "" },
            col_size: "col-3",
        },
        {
            display_name: "Last Updated",
            field_accessor: { key: "updated_date", render_function: "" },
            col_size: "col-3",
        },
        {
            display_name: "",
            field_accessor: { key: "icon", render_function: "" },
            col_size: "col-1",
        },
    ];

    return (
        <div>
            <h3 className="fs-25 fw-bold draft-notes-title">Draft Notes</h3>
            <GenericList
                className={"draft-notes-table"}
                headerClass={"draft-notes-table-header"}
                column_options={column_options}
                grid_data={formatDraftNotes(notes, props.history)}
                generic_list_key={"date_of_service"}
            />
        </div>
    );
};

export const formatDraftNotes = (notes, router) => {
    return notes
        .map((note) => {
            const updated_date = note.updated_date || note.created_date;
            return {
                name: `${note.patient_first_name} ${note.patient_last_name}`,
                date_of_service: moment(
                    `${note.session_date} ${note.session_from}`,
                    "MM/DD/YYYY HH:mm",
                ).format("MM/DD/YYYY hh:mma"),
                updated_date: moment.unix(updated_date).format("MM/DD/YYYY hh:mma"),
                icon: (
                    <img
                        src={images("./icons/eye.svg")}
                        alt="eye"
                        className={"pointer"}
                        onClick={() =>
                            router.push(`/app/patient/${note.patient_id}/note/${note.id}/v2`)
                        }
                    />
                ),
            };
        })
        .sort(
            (a, b) =>
                new Date(moment(b.date_of_service, "MM/DD/YYYY hh:mma").toISOString()) -
                new Date(moment(a.date_of_service, "MM/DD/YYYY hh:mma").toISOString()),
        );
};

export default DraftNotesTable;
