import * as actionTypes from "../actions/actionTypes";

const initialState = {
    publicFlags: undefined,
    publicFlagsHaveLoaded: false,
    privateFlags: undefined,
    privateFlagsHaveLoaded: false,
};

const flagsLoadedHandler = (state, action) => {
    const newState = {
        ...state,
        ...action,
    };

    if (Object.keys(action).includes("privateFlags")) {
        newState.privateFlagsHaveLoaded = true;
    }

    if (Object.keys(action).includes("publicFlags")) {
        newState.publicFlagsHaveLoaded = true;
    }

    return newState;
};

export const publicFlagsLoaded = (flags) => {
    return flags.publicFlagsHaveLoaded;
};

export const privateFlagsLoaded = (flags) => {
    return flags.privateFlagsHaveLoaded;
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FLAGS_LOADED:
            return flagsLoadedHandler(state, action);
        case actionTypes.CLEAR_FLAG_INTERVALS:
            return flagsLoadedHandler(state, action);
        default:
            return state;
    }
};

export default reducer;
