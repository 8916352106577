import { useEffect, useState } from "react";
import surveyRequests from "../../APIRequests/survey";

export const useCheckNPS = (surveyType) => {
    const [shouldShowNPS, setShouldShowNPS] = useState(false);

    const recordAnswer = () => {
        setShouldShowNPS(false);
    };

    const checkSurvey = async () => {
        const result = await surveyRequests.check_survey({
            options: { params: { showLoader: false } },
            data: {
                survey_type: surveyType,
            },
        });

        setShouldShowNPS(result.show_survey);
    };

    useEffect(() => {
        checkSurvey();
    }, []);

    return {
        shouldShowNPS,
        recordAnswer,
    };
};
