// Copyright aptihealth, inc. 2021 All Rights Reserved

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logInWithExternalProvider } from "../../redux/actions/auth";
import { AuthWrapper } from "./index";
import { isSupportedBrowser } from "../Common/SystemCheck";
import { deviceDetect } from "react-device-detect";
import parser from "ua-parser-js";
import { Button } from "../../component-library";
import { getQueryParams } from "../../utils/filters";
import { trackEvent } from "../../utils/EventTrackingUtil";
import images from "../../utils/images";
import { showAlertWithAction } from "../../redux/actions/alerts";
import { api } from "../../APIRequests";

const SSO = (props) => {
    const [idpOptions, setIdpOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [stepMessage, setStepMessage] = useState("");

    const translateIdPValue = (idp = "default") => {
        const idp_config = {
            CDPHP: {
                idpFriendlyName: "CDPHP",
                idpImgUrl: "./logo/CDPHP_lg.png",
            },
            IanBallardDevOkta: {
                idpFriendlyName: "Ian's IdP Practice",
                idpImgUrl: "./common/IanBallardDevOkta.png",
            },
            TrinityHealth: {
                idpFriendlyName: "Trinity Health",
                idpImgUrl: "./logo/Trinity_Health_lg.png",
            },
            AlbanyMedAD: {
                idpFriendlyName: "Albany Med Health System",
                idpImgUrl: "./common/apti_logo_w_tagline.svg",
            },
            default: {
                idpFriendlyName: "Default IdP Practice",
                idpImgUrl: "./common/apti_logo_w_tagline.svg",
            },
        };
        return idp_config[idp] ? idp_config[idp] : idp_config["default"];
    };

    useEffect(() => {
        (async () => {
            if (!localStorage.getItem("ssoInProgress")) {
                props.history.push("/auth/sign-in");
            }

            const url = props.location.hash.replace("#", "?");
            const code = getQueryParams("code", url);
            setIdpOptions([JSON.parse(localStorage.getItem("idpOptions"))]);

            if (props.location.search && props.location.search.includes("error")) {
                props.showAlertWithAction(
                    "An error has occurred during SSO authorization. Please contact your IT team for support.",
                );

                try {
                    const report = {
                        description: props.location.search,
                        context: {
                            idpPrincipleUser: localStorage.getItem("idpPrincipleUser"),
                        },
                    };
                    await api.shared.post_exception_report({ report });
                } catch (e) {
                    console.log(e);
                }

                localStorage.removeItem("idpPrincipleUser");
                return;
            }

            if (!localStorage.getItem("idpLogoutUrl") && !code) {
                redirectToIdp();
            }
            if (code) {
                finishIdpLogin(code);
            }
        })();
    }, []);

    const beginLoginProcess = (url) => {
        trackEvent("Continue to IdP");
        setIsLoading(false);
        setStepMessage("Checking with our server...");
        localStorage.removeItem("idpLogoutUrl");
        window.location = url;
    };

    const redirectToIdp = () => {
        setStepMessage("Redirecting to login portal...");
        setIsLoading(false);
        window.location = localStorage.getItem("idpLoginUrl");
    };

    const finishIdpLogin = (code) => {
        setIsLoading(false);
        setStepMessage("Logging in with SSO...");
        localStorage.removeItem("idpOptions");
        localStorage.removeItem("idpLoginUrl");
        localStorage.removeItem("ssoInProgress");
        localStorage.removeItem("idpPrincipleUser");
        props.logInWithExternalProvider({ code });
    };

    function loginWithIdp() {
        return (
            <div className={"mt-4"}>
                {isLoading && localStorage.getItem("idpLogoutUrl") && (
                    <>
                        <div>
                            {idpOptions.map((idp) => {
                                const translatedIdpValues = translateIdPValue(idp.idp);
                                return (
                                    <>
                                        <p className="login-welcome">Single Sign-On (SSO)</p>
                                        <p className="sso-preamble py-3">
                                            Select Continue to sign in to aptihealth using <br />{" "}
                                            your {translatedIdpValues.idpFriendlyName} credentials.
                                        </p>
                                        <div key={`idp-${translatedIdpValues.idpFriendlyName}`}>
                                            {translatedIdpValues.idpImgUrl && (
                                                <img
                                                    src={images(translatedIdpValues.idpImgUrl)}
                                                    alt={translatedIdpValues.idpFriendlyName}
                                                    className={"sso-idp-img apti-img"}
                                                />
                                            )}
                                            <div style={{ textAlign: "center", color: "Grey" }}>
                                                {translatedIdpValues.idpFriendlyName}
                                            </div>
                                            <div className={"my-3 d-flex justify-content-center"}>
                                                <Button
                                                    className={
                                                        "Btn Btn--pri Btn-login primary my-3"
                                                    }
                                                    onClick={() => {
                                                        beginLoginProcess(
                                                            localStorage.getItem("idpLogoutUrl"),
                                                        );
                                                    }}>
                                                    Continue
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                        <p className="sso-help-desk my-3">
                            Need help? Contact your IT help desk for support.
                        </p>
                    </>
                )}

                {stepMessage.length > 0 && (
                    <>
                        <div className="sso-redirect-message-wrapper">
                            <h2 className="sso-redirect-message my-3">{stepMessage}</h2>
                        </div>
                    </>
                )}
            </div>
        );
    }

    let deviceInfo = deviceDetect();
    let message = (
        <>
            Sorry, we don't currently support this browser. Please try using another browser like{" "}
            <a href={"https://www.mozilla.org/en-US/firefox/new/"}>Firefox (download here)</a> or{" "}
            <a href={"https://www.google.com/chrome/"}>Chrome (download here)</a>.
        </>
    );
    const os = deviceInfo.osName || deviceInfo.os;
    if (os === "iOS") {
        message = "Sorry, we don't currently support this browser. Please try using Safari.";
    }
    const showBackLink = localStorage.getItem("idpLoginUrl") && isLoading;

    return (
        <>
            <AuthWrapper showBackLink={showBackLink}>
                {!isSupportedBrowser(deviceDetect(), parser()) && (
                    <div className="SnackBar p-2 position-fixed SnackBar--warning">
                        <div className="SnackBar__wpr d-flex align-items-md-center">
                            <div className="ml-1 ml-md-3">
                                <span className="fs-14">{message}</span>
                                <br />
                            </div>
                        </div>
                    </div>
                )}
                {loginWithIdp()}
            </AuthWrapper>
        </>
    );
};

export default connect(null, { logInWithExternalProvider, showAlertWithAction })(withRouter(SSO));
