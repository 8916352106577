// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { Form, Formik } from "formik";
import { COMPLETED_STEP, IN_PROGRESS_STEP, START_STEP } from "./A5Screening";
import CardPrimary from "../UI/Card/CardPrimary";
import Button from "../UI/Button";
import FormikInputFormat from "../UI/formikMobileInput";
import * as yup from "yup";
import { dobValidationSchemaFactory } from "../../utils/yupSchemaUtils";
import { getNextQuestion, setA5Details } from "../../redux/actions/a5Screening";
import { connect } from "react-redux";
import { api } from "../../APIRequests";
import { CustomForm } from "../../component-library/CustomForm";

const schema = [
    {
        name: "dob",
        elementType: "DatePicker",
        elementConfig: {
            type: "text",
            label: "Please enter your Date of Birth to confirm your identity prior to accessing the aptihealth Behavioral Health Screening.",
            placeholder: "MM/DD/YYYY",
        },
    },
];

const validation = yup.object().shape({
    dob: dobValidationSchemaFactory("MMDDYYYY").required("Required"),
});

class A5VerifyIdentity extends Component {
    submitHandler = async (formData, actions) => {
        try {
            actions.setSubmitting(false);
            const queryParams = { screening_id: this.props.screeningId };
            const headers = { dob: formData["dob"] };
            let response = await api.shared.fetch_a5_screening_details({ queryParams, headers });
            this.props.setA5Details(response);
            if (response && response["status"] === "sent") {
                const data = { id: this.props.screeningId };
                this.props.getNextQuestion(data, headers);
                this.props.nextStepHandler(START_STEP);
            } else if (
                response &&
                response["status"] === "in_progress" &&
                !this.isLastQuestionAnswered(response)
            ) {
                const data = { id: this.props.screeningId };
                this.props.getNextQuestion(data, headers);
                this.props.nextStepHandler(IN_PROGRESS_STEP);
            } else if (
                (response && response["status"] === "completed") ||
                this.isLastQuestionAnswered(response)
            ) {
                this.props.nextStepHandler(COMPLETED_STEP);
            }
        } catch (e) {
            console.log(e);
        }
    };

    isLastQuestionAnswered = (data) => {
        return (
            data.hasOwnProperty("answers") &&
            data["answers"].hasOwnProperty("question_counter") &&
            data["answers"]["question_counter"] === "MP.1"
        );
    };

    renderForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 ">
                    <div className="container">
                        <div className="mx-auto px-0 ">
                            <div className="container">
                                <div className="row">
                                    {schema.map((field) => {
                                        return (
                                            <div key={field.name} className="col-12 col-lg-6">
                                                <FormikInputFormat
                                                    value={formikProps && formikProps.values.dob}
                                                    onChange={async (val) => {
                                                        await formikProps.setFieldValue(
                                                            "dob",
                                                            val.value,
                                                        );
                                                    }}
                                                    formEl={field}
                                                    errors={formikProps.errors}
                                                    touched={formikProps.touched}
                                                    classes={"screening-mobile-err"}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center justify-content-lg-end">
                    <Button type="submit" className="Btn Btn--pri mt-4">
                        Submit
                    </Button>
                </div>
            </Form>
        );
    };

    render() {
        return (
            <div>
                <CardPrimary className="A5VerifyIdentity mx-auto" style={{ marginTop: 0 }}>
                    <CustomForm
                        initialValues={{ dob: "" }}
                        validationSchema={validation}
                        onSubmit={this.submitHandler}
                        render={this.renderForm}
                    />
                </CardPrimary>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        a5Details: state.a5Screening.a5Details,
    };
};

export default connect(mapStateToProps, {
    setA5Details,
    getNextQuestion,
})(A5VerifyIdentity);
