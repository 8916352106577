import React, { useEffect, useState } from "react";
import _ from "lodash";

const OneTimeCodeInput = (props) => {
    const { codeLength, setOtc, errors, setErrors } = props;

    const [otcChars, setOtcChars] = useState(null);

    useEffect(() => {
        const initialChars = _.range(1, codeLength + 1).reduce((obj, id) => {
            obj[`otcChar${id}`] = "";
            return obj;
        }, {});
        setOtcChars(initialChars);
    }, []);

    const getFullOTC = (chars) => {
        return _.range(1, codeLength + 1)
            .map((id) => chars[`otcChar${id}`])
            .join("");
    };

    const handleKeyDown = (e) => {
        const charId = parseInt(e.target.id.slice(-1));
        const currentInputElement = document.getElementById(e.target.id);
        if (
            e.key === "Backspace" &&
            charId !== 0 &&
            currentInputElement &&
            !currentInputElement.value
        ) {
            const prevInputId = "otcChar" + (charId - 1);
            const prevInputElement = document.getElementById(prevInputId);
            if (prevInputElement) {
                prevInputElement.focus();
            }
        }
    };

    const handleOTCChange = (e) => {
        const { maxLength, value, id } = e.target;

        const newOtcChars = { ...otcChars };
        newOtcChars[`${id}`] = value;
        setOtcChars(newOtcChars);
        setOtc(getFullOTC(newOtcChars));
        setErrors([]);

        const regex = /[a-zA-Z0-9]/g;
        const found = value.match(regex);

        const charId = parseInt(id.slice(-1));

        if (value.length >= maxLength && found) {
            if (charId !== codeLength) {
                const nextInputId = "otcChar" + (charId + 1);
                const nextInputElement = document.getElementById(nextInputId);
                if (nextInputElement) {
                    nextInputElement.focus();
                }
            }
        } else if (value.length >= maxLength && !found) {
            const currentInputElement = document.getElementById(id);
            if (currentInputElement) {
                currentInputElement.value = "";
            }
        }
    };

    return (
        <>
            <div className={"w-100 d-flex justify-content-center"}>
                {_.range(1, codeLength + 1).map((id) => (
                    <input
                        className={"otc-char"}
                        style={id === codeLength ? { marginRight: 0 } : null}
                        type="text"
                        id={`otcChar${id}`}
                        maxLength={1}
                        onKeyDown={handleKeyDown}
                        onChange={handleOTCChange}
                    />
                ))}
            </div>

            <div className={"mt-4 txt-error text-center col-12"}>
                {errors.map((error) => error.map((errorStatement) => <div>{errorStatement}</div>))}
            </div>
        </>
    );
};

export default OneTimeCodeInput;

export const transformErrors = (errors) => {
    const errorMap = {
        INVALID_OTC: ["Invalid one-time code."],
        ERROR: ["Unknown error occurred."],
        ATTEMPT_AFTER_EXPIRED: [
            "One-time code has expired.",
            "Please ask a staff member for a new code.",
        ],
    };

    return errors.map((error) => errorMap[error]);
};
