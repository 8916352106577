// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";

import GenericInput from "./GenericInput";
import MobileInput from "./MobileInput"; // This component uses `react-number-input`

const FormikInput = (props) => {
    const {
        formEl: { elementConfig },
    } = props;

    switch (elementConfig.type) {
        case "mobile":
            return <MobileInput {...props} />;
        default:
            return <GenericInput {...props} />;
    }
};

export default FormikInput;
