// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import ContentLoader from "react-content-loader";

const ProfileLoader = () => {
    const ProfileContentLoader = () => (
        <ContentLoader
            height={400}
            width={900}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="25" y="100" rx="5" ry="5" width="150" height="10" />
            <rect x="375" y="100" rx="5" ry="5" width="150" height="10" />
            <rect x="25" y="120" rx="5" ry="5" width="150" height="10" />
            <rect x="375" y="120" rx="5" ry="5" width="150" height="10" />
            <rect x="25" y="180" rx="5" ry="5" width="150" height="10" />
            <rect x="375" y="180" rx="5" ry="5" width="150" height="10" />
            <rect x="25" y="200" rx="5" ry="5" width="150" height="10" />
            <rect x="375" y="200" rx="5" ry="5" width="150" height="10" />
            <rect x="25" y="260" rx="5" ry="5" width="150" height="10" />
            <rect x="375" y="260" rx="5" ry="5" width="150" height="10" />
            <rect x="25" y="285" rx="5" ry="5" width="150" height="10" />
            <rect x="375" y="285" rx="5" ry="5" width="150" height="10" />
        </ContentLoader>
    );

    const ProfileAvtarLoader = () => (
        <ContentLoader
            height={560}
            width={400}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="100" y="350" rx="3" ry="3" width="200" height="10" />
            <rect x="100" y="320" rx="3" ry="3" width="200" height="10" />
            <circle cx="200" cy="200" r="90" />
        </ContentLoader>
    );

    return (
        <div className="container px-3">
            <div className="PatientProfile">
                <div className="row">
                    <div className="col-12 col-lg-3 mt-3 mt-lg-0 mb-3 mb-lg-0">
                        <div className="PatientProfile__ProfileDetailsCard">
                            {ProfileAvtarLoader()}
                        </div>
                    </div>
                    <div className="col-12 col-lg-9 mt-3 mt-lg-0 mb-3 mb-lg-0">
                        <div className="PatientProfile__ProfileDetailsCard">
                            {ProfileContentLoader()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileLoader;
