import _ from "lodash";
import { A5_ASSESSMENT_TYPE, A5_V2_ASSESSMENT_TYPE } from "../constants/assessments";

export const getMemberAssessmentType = (profile) => {
    const default_assessment = _.get(profile, "assessment_config.default_assessment", false);
    if (default_assessment) {
        return default_assessment;
    }

    return _.get(profile, "assessment_config.assessment_type", A5_V2_ASSESSMENT_TYPE);
};
