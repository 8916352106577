// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import "./styles.scss";
const Badge = (props) => {
    let classes =
        "Badge d-flex align-items-center justify-content-center fs-12 " +
        (props.classes ? props.classes : "");
    switch (props.type) {
        case "primary":
            classes = classes + " " + "text-white Badge--primary";
            break;
        case "secondary":
            classes = classes + " " + "text-white Badge--secondary";
            break;
        case "success":
            classes = classes + " " + "text-white Badge--success";
            break;
        case "progress":
            classes = classes + " " + "Badge--progress";
            break;
        case "danger":
            classes = classes + " " + "text-white Badge--danger";
            break;
        case "grey":
            classes = classes + " " + "text-white Badge--grey";
            break;
        case "light-grey":
            classes = classes + " " + "Badge--light-grey";
            break;
        case "orange":
            classes = classes + " " + "text-white Badge--orange";
            break;
        case "black":
            classes = classes + " " + "Badge--black";
            break;
        case "dark-red":
            classes = classes + " Badge--dark-red";
            break;
        default:
            classes = classes + " " + "text-white Badge--primary";
    }

    return (
        <div className={classes} style={props.style} onClick={props.onClick || null}>
            <span>{props.text}</span>
        </div>
    );
};

export default Badge;
