// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import PropTypes from "prop-types";

const CollapsibleListContent = (props) => {
    const { title, content } = props;

    const CollapsibleListItem = function ({ name, value }) {
        return (
            <li className="CollapsibleListContent__list-item mb-3">
                <h5 className="fs-14 m-0">{name}</h5>
                <p className="fs-14 m-0 mt-2">{value || "no content available"}</p>
            </li>
        );
    };

    return (
        <div className="CollapsibleListContent mb-3 pb-3 pb-lg-4">
            <h2 className="fs-18 fw-bold mb-3">{title}</h2>
            <ul className="CollapsibleListContent__list">
                {content.length > 0 &&
                    content.map((item) => {
                        return <CollapsibleListItem name={item.label} value={item.value} />;
                    })}
            </ul>
        </div>
    );
};

export default CollapsibleListContent;

CollapsibleListContent.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
            value: PropTypes.string,
        }),
    ),
};
