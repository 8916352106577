// Copyright aptihealth, inc. 2021 All Rights Reserved

import React from "react";
import moment from "moment";
import { getUsernameFromToken } from "../../../redux/actions/auth";
import Button from "../../UI/Button";
import Input from "../../UI/Input";
import _ from "lodash";
import { trackEvent } from "../../../utils/EventTrackingUtil";

export const ConsentToTermsOfUse = (props) => {
    const { consent, setConsent, consentGivenBy, clientIp, campaignId, practiceId } = props;

    const openDoc = (url) => {
        window.open(url);
    };

    const userConsentGiven = async (consented, agreements) => {
        const newConsent = _.cloneDeep(consent);

        for (const agreement of agreements) {
            newConsent[agreement] = consented
                ? [
                      {
                          date: moment.utc().toISOString(),
                          client: "web",
                          consent_given_by: getUsernameFromToken() || "self",
                          client_ip: clientIp,
                      },
                  ]
                : null;
        }

        setConsent(newConsent);
    };

    const trackHcpEvent = (message) => {
        if (campaignId == "hcp_welcome_v1") {
            trackEvent(message, { practiceId: practiceId });
        }
    };

    const renderDocLink = (text, onClick) => {
        return (
            <Button
                className={"Btn-link txt-pri fw-bold"}
                style={{ margin: 0, whiteSpace: "nowrap" }}
                onClick={onClick}>
                {text}
            </Button>
        );
    };

    const EulaLink = () => {
        return renderDocLink("Terms and Conditions of Use", () =>
            openDoc("https://www.aptihealth.com/terms-and-conditions"),
        );
    };

    const PrivacyPolicyLink = () => {
        return renderDocLink("Privacy Policy", () => openDoc("https://www.aptihealth.com/privacy"));
    };

    const HipaaNoticeLink = () => {
        return renderDocLink("HIPAA Notice of Privacy Practices", () =>
            openDoc("https://www.aptihealth.com/hipaaprivacy"),
        );
    };

    const consentToEulaAndPrivacy = async () => {
        trackHcpEvent("HCP User Consents to Privacy Policy and EULA");
        await userConsentGiven(!consent["eula"] && !consent["privacy"], ["eula", "privacy"]);
    };

    const consentToHipaaNotice = async () => {
        trackHcpEvent("HCP User consents to HIPAA Policy");
        await userConsentGiven(!consent["hipaa"], ["hipaa"]);
    };

    const consentToHipaaAndEulaNotice = async () => {
        trackHcpEvent("HCP User consents to HIPAA Policy");
        trackHcpEvent("HCP User Consents to Privacy Policy and EULA");
        await userConsentGiven(!consent["eula"] && !consent["privacy"] && !consent["hipaa"], [
            "eula",
            "privacy",
            "hipaa",
        ]);
    };

    return (
        <>
            {consentGivenBy === "PARENT" ? (
                <div className={"mb-4 d-flex"}>
                    <Input
                        className="custom-checkbox mr-2 mt-1"
                        style={{
                            "min-height": "16px",
                            "min-width": "16px",
                            "max-height": "1rem",
                            "max-width": "1rem",
                        }}
                        type={"checkbox"}
                        onClick={consentToHipaaAndEulaNotice}
                    />
                    <div>
                        By checking the box and hitting continue, (i) I acknowledge I have read the{" "}
                        <HipaaNoticeLink /> and (ii) I consent to AptiHealth's processing of
                        information relating to me or my child as described by the{" "}
                        <PrivacyPolicyLink />.
                    </div>
                </div>
            ) : (
                <>
                    <div className={"mb-4 d-flex"}>
                        <Input
                            className="custom-checkbox mr-2 mt-1"
                            style={{
                                "min-height": "16px",
                                "min-width": "16px",
                                "max-height": "1rem",
                                "max-width": "1rem",
                            }}
                            type={"checkbox"}
                            onClick={consentToEulaAndPrivacy}
                            data-e2e={"referral-eula-notice-checkbox"}
                        />
                        <div>
                            By checking the box and clicking continue,
                            {consentGivenBy === "PARENT" ? " on behalf of my child, " : " "}
                            I acknowledge that I have read and agree to aptihealth's <EulaLink />{" "}
                            and consent to AptiHealth's processing of information relating to{" "}
                            {consentGivenBy === "PARENT" ? "my child" : "me"} in the{" "}
                            <PrivacyPolicyLink />.
                        </div>
                    </div>
                    <div className={"mb-4 d-flex"}>
                        <Input
                            className="custom-checkbox mr-2 mt-1"
                            style={{
                                "min-height": "16px",
                                "min-width": "16px",
                                "max-height": "1rem",
                                "max-width": "1rem",
                            }}
                            type={"checkbox"}
                            onClick={consentToHipaaNotice}
                            data-e2e={"referral-hipaa-notice-checkbox"}
                        />
                        <div>
                            By checking this box,
                            {consentGivenBy === "PARENT" ? " on behalf of my child, " : " "}
                            I acknowledge that I have received the <HipaaNoticeLink />.
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
