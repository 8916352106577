import { uuidv4 } from "./filters";

const isEventTrackingEnabled = () => process.env.REACT_APP_EVENT_TRACKING_ENABLED === "true";
const sessionId = uuidv4();

/**
 * Tracks events with Segment.
 *
 * @param eventName name of the event
 * @param eventProperties (optional) properties associated to the event. ex: {
 *    plan: "Pro Annual",
      accountType: "Facebook"
 * }
 * @return
 */
export const trackEvent = (eventName, eventProperties = {}) => {
    if (!isEventTrackingEnabled()) {
        return;
    }
    eventProperties = {
        app: "aptihealth-web",
        env: process.env.REACT_APP_ENV,
        sessionId: sessionId,
        ...eventProperties,
    };
    try {
        window.analytics.track(eventName, eventProperties);
    } catch (e) {
        console.log(e);
        console.log(eventProperties);
    }
};

export const trackPage = () => {
    if (!isEventTrackingEnabled()) {
        return;
    }
    const eventProperties = {
        app: "aptihealth-web",
        env: process.env.REACT_APP_ENV,
        sessionId: sessionId,
    };
    try {
        window.analytics.page(eventProperties);
    } catch (e) {
        console.log(e);
        console.log(eventProperties);
    }
};
