import {
    noteV1Display,
    noteV2Display,
    noteV3Display,
    noteV4Display,
} from "../../Provider/PatientView/MemberProfile/MemberProfileNotes";
import CareCommunicationView from "../../Provider/PatientView/MemberProfile/CareCommunication/CareCommunicationView";
import CaseManagementView from "../../Provider/PatientView/MemberProfile/CaseManagement/CaseManagementView";
import React from "react";
import moment from "moment/moment";
import { CareManagementForm } from "../../Provider/PatientView/MemberProfile/CareManagement/CareManagementForm";
import CardSecondary from "../../UI/Card/CardSecondary";
import { useSelector } from "react-redux";
import { AdvancedCareActivityViewOnlyForm } from "../../Provider/PatientView/MemberProfile/AdvancedCareActivity/AdvancedCareActivityViewOnlyForm";

const renderNoteTitle = (note) => {
    if (!note || note.title === "Care Communication Note" || note.note_type === "Care Management") {
        return;
    }
    return note.title;
};

const renderPatientMetaData = (note, patient, patientName) => {
    if (!patient || !note?.note_type || note?.note_type === "Care Management") {
        return;
    }

    return (
        <>
            {patientName} (Date of Birth : {patient.dob})
        </>
    );
};

const NoteDisplay = ({ note, children, patient, patientName }) => {
    if (note?.note_type === "Care Management" || note?.note_type === "Advanced Care Activity") {
        return <div className="mx-auto my-3 p-3">{children}</div>;
    }

    return (
        <CardSecondary className="mx-auto my-3 p-3">
            <div className="mt-3 ml-auto mr-auto fw-bold" style={{ maxWidth: 978 }}>
                {renderPatientMetaData(note, patient, patientName)}
            </div>
            <div className="mt-3 ml-auto mr-auto fw-bold" style={{ maxWidth: 978 }}>
                {renderNoteTitle(note)}
            </div>
            <hr />
            {children}
            <div className="row d-flex flex-wrap justify-content-between ml-auto mr-auto">
                {note?.signature ? (
                    <div className={"mb-2 mt-3"}>
                        <div className={"fw-bold"}>Signature</div>
                        <div
                            className="fs-20 border-bottom"
                            style={{ fontFamily: "Apple Chancery, cursive" }}>
                            {note.signature}
                        </div>
                    </div>
                ) : (
                    " "
                )}
                {note?.completed_date_time ? (
                    <div className="mb-2 mt-3">
                        <div>
                            <label className="Label">Signature Date</label>
                            <p>
                                {note.completed_date_time
                                    ? moment(note.completed_date_time).format("MM/DD/YYYY")
                                    : ""}
                            </p>
                        </div>
                    </div>
                ) : (
                    ""
                )}

                {note?.provider_cp_credentials ? (
                    <div className={"mb-2 mt-3"}>
                        <div className={"fw-bold"}>Credentials</div>
                        <div>{note.provider_cp_credentials}</div>
                    </div>
                ) : (
                    ""
                )}
                {note?.provider_practice ? (
                    <div className="mb-2 mt-3">
                        <div>
                            <label className="Label">Provider Practice</label>
                            <p>{note.provider_practice}</p>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </CardSecondary>
    );
};

const GenericNoteView = ({ patient, note, patientName, isEditing, setIsEditing, refetchNote }) => {
    const profileName = useSelector((state) => state.auth.profile?.name);

    const renderNote = (patientName, noteData) => {
        if (!noteData) {
            return;
        }

        let display;

        try {
            if (!noteData.version || noteData.version === 1) {
                display = noteV1Display(noteData, patientName);
            } else if (noteData.version === 2) {
                display = noteV2Display(noteData, false, null, { profileView: true });
            } else if (noteData.version === 3) {
                display = noteV3Display(noteData);
            } else if (noteData.version === 4) {
                display = noteV4Display(noteData);
            } else if (noteData.version === 5 && noteData.note_type !== "Discharge") {
                display = <CareCommunicationView note={noteData} />;
            } else if (note.note_type === "Care Management") {
                return (
                    <CareManagementForm
                        noteState={{ ...noteData, signature: noteData.signature ?? "" }}
                        isEditing={isEditing}
                        nameToSign={noteData.provider_name ?? profileName}
                        patientId={noteData.patient_id}
                        setIsEditing={setIsEditing}
                        refetchNote={refetchNote}
                    />
                );
            } else if (note.note_type === "Advanced Care Activity") {
                return <AdvancedCareActivityViewOnlyForm note={noteData} />;
            } else if (note.version === 6) {
                // Case Management
                display = <CaseManagementView note={note} />;
            }
        } catch (e) {
            console.log(e);
        }

        return display;
    };

    return (
        <NoteDisplay note={note} patient={patient} patientName={patientName}>
            <div className="m-auto" style={{ maxWidth: 978 }}>
                {renderNote(patientName, note)}
            </div>
        </NoteDisplay>
    );
};

export default GenericNoteView;
