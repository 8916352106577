import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { calculateBackgroundDayPosition } from "../../../utils/calendar/positions";
import moment from "moment";

export const CalendarWorkingHours = ({ workingHours, calendarRect, daysToDisplay }) => {
    const createWorkingSlot = (slot, dayNumber) => {
        const start = moment(slot[0], "HH:mm");
        const end = moment(slot[1], "HH:mm");
        const position = calculateBackgroundDayPosition(start, end, dayNumber, calendarRect);
        return <div className="CalendarWorkingHours__slot" style={position} />;
    };

    const createWorkingDay = (workingHourSlots, currentDayIndex, numberOfDays) => {
        if (numberOfDays === 1) {
            // if the number of days is 1 then we render working hours
            // in the first day no matter what
            const workingDayElements = workingHourSlots.slots.map((slot) => {
                const workingSlot = createWorkingSlot(slot, 0);
                return workingSlot;
            });

            return workingDayElements;
        } else if (numberOfDays === 7) {
            // if the number of days is 7 then we render working hours
            // based on the current day index
            const workingDayElements = workingHourSlots.slots.map((slot) => {
                const workingSlot = createWorkingSlot(slot, currentDayIndex);
                return workingSlot;
            });

            return workingDayElements;
        } else {
            return null;
        }
    };

    // recalculate every time
    const workingHourElements = () => {
        if (!calendarRect) {
            return null;
        }
        const numberOfDays = daysToDisplay.length;
        const workingHourDayElements = daysToDisplay
            .map((day) => {
                const currentDay = day.format("dddd");
                const currentDayIndex = day.day();
                const curWorkingHours = workingHours?.[currentDay];
                // if there are no working hours for the day or the working hours are not
                // enabled continue
                if (!curWorkingHours) {
                    return null;
                }

                return createWorkingDay(curWorkingHours, currentDayIndex, numberOfDays);
            })
            .filter(Boolean)
            .flat();

        return workingHourDayElements;
    };

    return <>{workingHourElements()}</>;
};

CalendarWorkingHours.propTypes = {
    workingHours: PropTypes.object,
    numberOfDays: PropTypes.number,
    daysToDisplay: PropTypes.arrayOf(PropTypes.instanceOf(moment)),
};

CalendarWorkingHours.defaultProps = {
    workingHours: {},
    numberOfDays: 1,
    daysToDisplay: [moment().local()],
};
