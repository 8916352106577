import { useEffect, useState } from "react";
import { api } from "../../APIRequests";

export const useCareTeam = (patientId, existingCareTeam, queryParams = {}) => {
    const [careTeam, setCareTeam] = useState([]);
    const getCareTeam = async () => {
        // if a existing patient is passed in
        // we do not need to make another network call
        if (existingCareTeam) {
            setCareTeam(existingCareTeam);
            return;
        }

        if (!patientId) {
            return;
        }
        const options = { params: { showLoader: true } };
        let urlParams = { patientId: patientId };
        const response = await api.shared.fetch_patient_careteam({
            urlParams,
            options,
            queryParams,
        });
        setCareTeam(response);
    };

    useEffect(() => {
        getCareTeam();
    }, [patientId]);

    return { careTeamMembers: careTeam, refreshCareTeam: getCareTeam };
};

export const useGoalAndObjectiveText = ({ patientId }) => {
    const [goalAndObjectiveText, setGoalAndObjectiveText] = useState([]);
    useEffect(() => {
        const getGoalAndObjectiveText = async () => {
            if (!patientId) {
                return;
            }
            const options = { params: { showLoader: true } };
            let urlParams = { patientId: patientId };
            const response = await api.carePlan.get_goal_and_objective_text({ urlParams, options });
            setGoalAndObjectiveText(response);
        };

        getGoalAndObjectiveText();
    }, [patientId]);

    return goalAndObjectiveText;
};

export const usePatientGoalAndObjectives = ({ patientId }) => {
    const [goalAndObjectives, setGoalAndObjectives] = useState([]);
    const getGoalAndObjectives = async ({ showLoader }) => {
        if (!patientId) {
            return;
        }
        const params = { showLoader };
        let urlParams = { patientId: patientId };
        const response = await api.carePlan.get_goals_and_objectives({ urlParams, params });
        setGoalAndObjectives(response);
    };

    useEffect(() => {
        getGoalAndObjectives({ showLoader: true });
    }, [patientId]);

    return { goalAndObjectives, refreshState: getGoalAndObjectives };
};

export const useModalityText = ({ patientId }) => {
    const [modalityText, setModalityText] = useState({});
    useEffect(() => {
        const getModalityText = async () => {
            if (!patientId) {
                return;
            }
            const options = { params: { showLoader: true } };
            let urlParams = { patientId: patientId };
            const response = await api.carePlan.get_modality_text({ urlParams, options });
            setModalityText(response);
        };

        getModalityText();
    }, [patientId]);

    return modalityText;
};
