/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const PhoneDownIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="PhoneDown"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.3417 21.8521L30.173 19.2119C30.8828 18.4844 31 17.5234 31 17C31 15.7727 30.481 14.9908 28.0886 13.4626C24.4816 11.1586 20.2469 10 16 10C11.5 10 7.51836 11.1586 3.91139 13.4626C1.51897 14.9908 1 15.7727 1 17C1 17.5547 1.10938 18.5156 1.82703 19.2119L4.65829 21.8521C5.48716 22.681 7.29231 22.6366 8.32329 21.8521C9.1223 21.2442 9.78235 20.8794 10.8072 20.4104C12.3668 19.6967 12.7178 18.5866 12.7178 16.8844C13.7253 16.6065 15 16.5 16 16.5C17 16.5 18.2747 16.6065 19.2822 16.8844C19.2822 18.5866 19.6332 19.6967 21.1928 20.4104C22.2177 20.8794 22.8777 21.2442 23.6767 21.8521C24.7077 22.6366 26.4359 22.758 27.3417 21.8521ZM3.21149 17.7683L6.03747 20.4036C6.04427 20.4064 6.05345 20.4099 6.06529 20.4138C6.13259 20.4361 6.2467 20.4584 6.39876 20.4572C6.73375 20.4545 7.00124 20.3449 7.11225 20.2605C8.06457 19.5359 8.8641 19.1002 9.97491 18.5918C10.4183 18.3889 10.5075 18.2279 10.5498 18.1412C10.6308 17.9752 10.7178 17.6407 10.7178 16.8844V15.3614L12.186 14.9564C13.4303 14.6132 14.9011 14.5 16 14.5C17.0989 14.5 18.5697 14.6132 19.814 14.9564L21.2822 15.3614V16.8844C21.2822 17.6407 21.3692 17.9752 21.4502 18.1412C21.4925 18.2279 21.5817 18.3889 22.0251 18.5918C23.1359 19.1002 23.9354 19.5359 24.8878 20.2605C25.0382 20.3749 25.3108 20.48 25.6011 20.4865C25.8358 20.4918 25.9271 20.4344 25.936 20.4295L25.9522 20.4132L28.7625 17.7926C28.8336 17.7121 28.8926 17.6041 28.9358 17.459C28.9849 17.2938 29 17.1236 29 17C29 16.8355 28.9826 16.7726 28.9761 16.7521C28.9717 16.7385 28.9563 16.6899 28.8753 16.588C28.6672 16.326 28.184 15.8967 27.012 15.1481C23.7458 13.0617 19.8889 12 16 12C11.8697 12 8.26367 13.0557 4.98802 15.1481C3.81601 15.8967 3.33277 16.326 3.12472 16.588C3.04375 16.6899 3.02833 16.7383 3.02397 16.7519C3.01745 16.7724 3 16.8355 3 17C3 17.1561 3.01665 17.3311 3.0626 17.4867C3.1042 17.6277 3.15721 17.7127 3.21149 17.7683ZM6.02195 20.3964L6.02327 20.3971L6.02195 20.3964Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="PhoneDown"
                {...props}>
                <path d="M28.0886 13.4626C30.481 14.9908 31 15.7727 31 17C31 17.5234 30.8828 18.4844 30.173 19.2119L27.3417 21.8521C26.4359 22.758 24.7077 22.6366 23.6767 21.8521C22.8777 21.2442 22.2177 20.8794 21.1928 20.4104C19.6332 19.6967 19.2822 18.5866 19.2822 16.8844C18.2747 16.6065 17 16.5 16 16.5C15 16.5 13.7253 16.6065 12.7178 16.8844C12.7178 18.5866 12.3668 19.6967 10.8072 20.4104C9.78235 20.8794 9.1223 21.2442 8.32329 21.8521C7.29231 22.6366 5.48716 22.681 4.65829 21.8521L1.82703 19.2119C1.10938 18.5156 1 17.5547 1 17C1 15.7727 1.51897 14.9908 3.91139 13.4626C7.51836 11.1586 11.5 10 16 10C20.2469 10 24.4816 11.1586 28.0886 13.4626Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="PhoneDown"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM31.25 20.75C31.25 19.8296 30.8608 19.2431 29.0665 18.0969C26.3612 16.3689 23.1852 15.5 20 15.5C16.625 15.5 13.6388 16.3689 10.9335 18.0969C9.13923 19.2431 8.75 19.8296 8.75 20.75C8.75 21.166 8.83203 21.8867 9.37027 22.409L11.4937 24.3891C12.1154 25.0108 13.4692 24.9774 14.2425 24.3891C14.8417 23.9331 15.3368 23.6596 16.1054 23.3078C17.2751 22.7725 17.5384 21.94 17.5384 20.6633C18.2939 20.4549 19.25 20.375 20 20.375C20.75 20.375 21.7061 20.4549 22.4616 20.6633C22.4616 21.94 22.7249 22.7725 23.8946 23.3078C24.6632 23.6596 25.1583 23.9331 25.7575 24.3891C26.5308 24.9774 27.8269 25.0685 28.5063 24.3891L30.6297 22.409C31.1621 21.8633 31.25 21.1426 31.25 20.75Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

PhoneDownIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

PhoneDownIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
