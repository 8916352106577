/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const AptihealthIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Aptihealth"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M28 25.3187C28 26.7199 26.9055 27.9565 25.4492 27.9565L19.0533 27.9565C17.5973 27.9565 16.5029 26.7198 16.5029 25.3187L16.5029 21.3625C16.5029 19.7521 15.9291 18.1323 14.6923 16.4863C13.4355 14.8138 12.3019 14.2047 11.3118 14.1995L7.25787 14.1995C5.96085 14.1995 5 13.0985 5 11.868L5 6.37492C5 5.14421 5.96091 4.04343 7.25787 4.04343L12.4468 4.04343C13.7438 4.04343 14.7049 5.14409 14.7049 6.37492L14.7049 10.5728C14.8433 11.6899 15.4473 12.8371 16.6631 14.0135C17.9308 15.2401 19.4026 15.8674 21.1206 15.9243L25.4493 15.9243C26.9054 15.9243 28 17.1607 28 18.5621L28 25.3187ZM25.4492 25.9565C25.6994 25.9565 26 25.7196 26 25.3187L26 18.5621C26 18.161 25.6995 17.9243 25.4492 17.9243L21.089 17.9243L21.0735 17.9238C19.7346 17.8821 18.4977 17.5651 17.372 16.9791C18.1152 18.3897 18.5029 19.8528 18.5029 21.3625L18.5029 25.3187C18.5029 25.7198 18.8036 25.9565 19.0533 25.9565L25.4492 25.9565ZM13.2215 12.6198C12.9558 12.0153 12.7829 11.3893 12.711 10.7416L12.7049 10.6865L12.7049 6.37492C12.7049 6.14041 12.534 6.04343 12.4468 6.04343L7.25787 6.04343C7.17093 6.04343 7 6.14029 7 6.37492L7 11.868C7 12.1026 7.17098 12.1995 7.25787 12.1995L11.3187 12.1995C11.983 12.2024 12.6169 12.3491 13.2215 12.6198Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Aptihealth"
                {...props}>
                <path d="M21.1046 16.9243C19.1268 16.8627 17.4145 16.132 15.9677 14.7322C14.6178 13.426 13.8635 12.059 13.7049 10.6312L13.7049 6.37493C13.7049 5.64226 13.1389 5.04344 12.4468 5.04344L7.25787 5.04344C6.56592 5.04344 6 5.64226 6 6.37493L6 11.868C6 12.6006 6.56591 13.1995 7.25787 13.1995L11.3143 13.1995C12.7585 13.2059 14.1509 14.1012 15.4917 15.8856C16.8325 17.6699 17.5029 19.4955 17.5029 21.3625L17.5029 25.3187C17.5029 26.2198 18.2004 26.9565 19.0533 26.9565L25.4492 26.9565C26.3024 26.9565 27 26.2198 27 25.3187L27 18.5621C27 17.6609 26.3024 16.9243 25.4492 16.9243L21.1046 16.9243Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Aptihealth"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM19.9758 19.0491C21.0609 20.099 22.3451 20.647 23.8284 20.6932L27.0869 20.6932C27.7268 20.6932 28.25 21.2456 28.25 21.9215L28.25 26.989C28.25 27.6648 27.7268 28.2173 27.0869 28.2173L22.29 28.2173C21.6503 28.2173 21.1272 27.6648 21.1272 26.989V24.0218C21.1272 22.6216 20.6244 21.2524 19.6188 19.9142C18.6132 18.5759 17.5688 17.9044 16.4857 17.8996L13.4434 17.8996C12.9244 17.8996 12.5 17.4504 12.5 16.901L12.5 12.7812C12.5 12.2317 12.9244 11.7826 13.4434 11.7826L17.3351 11.7826C17.8542 11.7826 18.2786 12.2317 18.2786 12.7812L18.2786 15.9734C18.3976 17.0442 18.9634 18.0695 19.9758 19.0491Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

AptihealthIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

AptihealthIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
