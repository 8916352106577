// Copyright aptihealth, inc. 2021 All Rights Reserved

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Link, PageBanner, Text, TextColors, TextTypes } from "../../../../component-library";
import { AptihealthIcon } from "../../../../component-library/Icons/components/AptihealthIcon";
import { BhsIcon } from "../../../../component-library/Icons/components/BhsIcon";
import { IntakeIcon } from "../../../../component-library/Icons/components/IntakeIcon";
import { PrescriberIcon } from "../../../../component-library/Icons/components/PrescriberIcon";
import { ICON_SIZES, ICON_TYPES } from "../../../../constants/icons";
import images from "../../../../utils/images";
import { allottedTimeFactory } from "../../../Provider/ScheduleAvailability/constants";
import Button from "../../../UI/Button";
import ScheduleDialog from "../../CancellationPolicy/ScheduleDialog";
import { buildCallDate, buildCallTimeInfo, buildCallTitle } from "../index";
import "../styles.scss";

const ScheduleWidget = (props) => {
    const { scheduleList, profile } = props;
    const [scheduledCalls, setScheduledCalls] = useState([]);
    const [showRescheduleModal, setShowRescheduleModal] = useState(null);
    const [selectedCall, setSelectedCall] = useState(null);

    useEffect(() => {
        setScheduledCalls(
            scheduleList
                .filter((call) => !call.hasOwnProperty("is_instant") || !call["is_instant"])
                .slice(0, 2),
        );
    }, [scheduleList]);

    const rescheduleCall = (callId, providerId, allottedTime) =>
        props.history.push(
            "/app/schedule/assign?mode=edit&provider_id=" +
                providerId +
                "&call_id=" +
                callId +
                "&allotted_time=" +
                allottedTime,
        );

    const getCallIcon = (providerRole) => {
        switch (providerRole) {
            case "PRESCRIBE":
                return <PrescriberIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconAptiNavy"}/>;
            case "BEHAVIORAL":
                return <BhsIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconAptiNavy"}/>;
            case "BEHAVIORAL_INTAKE":
                return <IntakeIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconAptiNavy"}/>;
            default:
                return <AptihealthIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconAptiNavy"}/>;
        }
    };

    const shouldAllowScheduling = (profile) => {
        return (
            profile &&
            profile.assessment_status &&
            profile.status !== "INACTIVE" &&
            !profile.is_wait_listed &&
            !profile.is_scheduling_restricted
        );
    };

    const renderNoCalls = (allowScheduling) => {
        return (
            <div
                className={"dashboard-widget-card dashboard-widget-card-xs text-center p-md-4 p-3"}>
                <div className="fs-22 fw-bold">No Upcoming Sessions</div>
                <div className="txt-gry mb-md-3 mb-1">
                    Future sessions with your aptihealth care team will appear here.
                </div>
                {allowScheduling && (
                    <Button
                        className="Btn Btn--pri Btn-xs Btn-xs-2 Btn-rounded Btn--otl-pri Btn-transparent fw-bold"
                        style={{ textDecoration: "none", padding: "1px 10px" }}
                        onClick={() => props.history.push("/app/schedule/assign?mode=new")}>
                        <div className={"txt-white d-flex"}>
                            <img
                                src={images("./icons/common/schedule/schedule-pri-none.svg")}
                                alt={""}
                            />
                            <div className={"ml-1 mt-md-1 mt-0"}>Schedule</div>
                        </div>
                    </Button>
                )}
            </div>
        );
    };

    const getAllottedTime = (allotted_time, event_type) => {
        return allotted_time ? allotted_time : allottedTimeFactory(event_type);
    };

    const renderCalls = (scheduledCalls, shouldAllowRescheduling) => {
        return (
            <div className={"row"}>
                <div className="col-12">
                    <div className="dashboard-widget-card dashboard-widget-card-xs ">
                        {scheduledCalls.map((call) => {
                            const {
                                callId,
                                provider_role,
                                timestamp,
                                allotted_time,
                                event_type,
                                provider_username,
                                provider_name,
                            } = call;
                            const allottedTime = getAllottedTime(allotted_time, event_type);
                            const borderClass = scheduledCalls.length < 2 ? "" : "border-bottom";
                            return (
                                <div key={callId} className={"my-lg-0 my-2 px-lg-4 px-2"}>
                                    <div className={`d-flex py-4 ${borderClass}`}>
                                        <div className={"pr-2"}>
                                            {getCallIcon(provider_role)}
                                        </div>
                                        <div>
                                            <div className="fs-16 fw-bold">
                                                {buildCallTitle(provider_role, event_type)} with{" "}
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                    {provider_name}
                                                </span>
                                            </div>
                                            <div className="fs-14 txt-gry">
                                                {buildCallDate(timestamp)}
                                                {window.innerWidth > 320 ? " · " : " "}
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                    {buildCallTimeInfo(timestamp, allottedTime)}
                                                </span>
                                                {window.innerWidth > 400 ? " · " : " "}
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                    {allottedTime} minutes
                                                </span>
                                            </div>
                                            <div className="d-flex mt-lg-2 mt-0">
                                                {shouldAllowRescheduling && <Button
                                                    className="Btn-link txt-pri fw-bold"
                                                    style={{ textDecoration: "none", margin: 0 }}
                                                    onClick={() => {
                                                        setShowRescheduleModal(true);
                                                        setSelectedCall(call);
                                                    }}>
                                                    <div className={"d-flex"}>
                                                        <img
                                                            src={images(
                                                                "./icons/common/schedule/schedule-pri-none.svg",
                                                            )}
                                                            alt={""}
                                                        />
                                                        <div className={"ml-1 mt-1"}>
                                                            Reschedule
                                                        </div>
                                                    </div>
                                                </Button>}
                                            </div>
                                            {showRescheduleModal && (
                                                <ScheduleDialog
                                                    type={"reschedule"}
                                                    cancelHandler={() => {
                                                        setShowRescheduleModal(false);
                                                        setSelectedCall(null);
                                                    }}
                                                    activeScheduleDetails={selectedCall}
                                                    submitHandler={() =>
                                                        rescheduleCall(
                                                            selectedCall.callId,
                                                            selectedCall.provider_username,
                                                            getAllottedTime(
                                                                selectedCall.allotted_time,
                                                                selectedCall.event_type,
                                                            ),
                                                        )
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };

    const restrictSchedulingBanner = (profile?.is_scheduling_restricted && <PageBanner className={"ScheduleRestrictWidget d-flex flex-column margin-center"}>
        <Text className={"align-self-baseline"} type={TextTypes.label} color={TextColors.black}>
            Your scheduling options have changed
        </Text>
        <Text className={"align-self-baseline"} type={TextTypes.paragraph} color={TextColors.black}>
            To schedule/reschedule therapy sessions, contact your provider. To schedule/reschedule
            medication management sessions, contact <Link href="mailto:support@aptihealth.com" className={"apti-green-v2"}>
                support@aptihealth.com
            </Link>
        </Text>
    </PageBanner>);

    return (
        <div className={"pb-1"}>
            <div className={"fs-22 txt-gry fw-bold pb-2"}>Schedule</div>
            {restrictSchedulingBanner}
            {scheduledCalls &&
                scheduledCalls.length === 0 &&
                renderNoCalls(shouldAllowScheduling(profile))}
            {scheduledCalls && scheduledCalls.length !== 0 && renderCalls(scheduledCalls, profile && !profile.is_scheduling_restricted)}
        </div>
    );
};

export default withRouter(ScheduleWidget);
