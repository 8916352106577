export const ACUITY_INDEX = {
    1: {
        label: "Mild",
        sessions: "1 per month",
    },
    2: {
        label: "Moderate",
        sessions: "2 per month",
    },
    3: {
        label: "Intense",
        sessions: "1 per week",
    },
    4: {
        label: "Severe",
        sessions: "1-2 per week",
    },
};
