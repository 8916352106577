import React from "react";
import images from "../../../../utils/images";

const WorkflowHeader = ({ header, showImage }) => {
    if (showImage) {
        return (
            <>
                <div className="row">
                    <div className={"col-12"}>
                        <div className={"schedule-intake-header"}>{header}</div>
                    </div>
                </div>
                <div className="row">
                    <div className={"col-12"}>
                        <img
                            className={"workflow-status-header-image"}
                            src={images("./confirm_identity_to_schedule_consultation_workflow.png")}
                            alt="confirm identity to schedule consultation workflow diagram"
                        />
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="row">
                    <div className={"col-12"}>
                        <div className={"schedule-intake-header"}>{header}</div>
                    </div>
                </div>
            </>
        );
    }
};

export default WorkflowHeader;
