export const sortUsersByDateLastNameFirstName = (userA, userB, dateName) => {
    let comparison = sortByDate(userA, userB, dateName);
    if (comparison === 0) {
        comparison = sortByLastNameFirstName(userA, userB);
    }
    return comparison;
};

export const sortByLastNameFirstName = (userA, userB) => {
    let comparison;
    // sort alphabetically last_name, first_name
    if (userA.last_name > userB.last_name) {
        comparison = 1;
    } else if (userA.last_name === userB.last_name) {
        if (userA.first_name > userB.first_name) {
            comparison = 1;
        } else {
            comparison = -1;
        }
    } else {
        comparison = -1;
    }
    return comparison;
};

export const sortByDate = (patientA, patientB, dateName) => {
    //sort created_date desc
    return (
        (patientB[dateName] ? parseFloat(patientB[dateName]) : 0) -
        (patientA[dateName] ? parseFloat(patientA[dateName]) : 0)
    );
};
