import React from "react";

import { TextTypes, TextColors } from "../../../constants";
import { Link } from "../../../Link";
import { Text } from "../../../Text";
import { NotificationSection } from "../../../NotificationSection";

import "./styles.scss";

// Relates to: Profile Insurance Status

export const Pending = ({ dateChecked }) => {
    return (
        <NotificationSection className={"pending"}>
            <Text className={"bold mb-3"}>Please contact us.</Text>

            <Text>
                <>
                    <div className="mb-3">
                        We saved your new insurance info but had an issue checking your coverage.
                    </div>
                    <div className="mb-3">
                        Contact{" "}
                        <Link href="mailto:support@aptihealth.com">support@aptihealth.com</Link> or{" "}
                        <Link href="tel:8884543827">(888) 454-3827</Link> before your next
                        appointment to prevent billing issues.
                    </div>
                    <div>Checked: {dateChecked}</div>
                </>
            </Text>
        </NotificationSection>
    );
};

Pending.defaultProps = {
    dateChecked: "",
};
