// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import Logo from "../../../UI/Logo";

const Brand = (props) => {
    return (
        <div className="Toolbar__brand mx-auto mx-lg-0">
            <div className="Toolbar__brand-wpr">
                <Logo {...props} />
            </div>
        </div>
    );
};

export default Brand;
