// Copyright aptihealth, inc. 2019 All Rights Reserved
import { getGroupAuthType } from "../redux/actions/auth";
import { GROUP_AUTH_HEADER_NAME } from "../constants/axios";
import { http, httpAuth } from "../utils/axios";

const patientRequests = {
    fetch_insurance_list: function ({ options } = {}) {
        let conf = {
            method: "post",
            url: "/v2/patient/insurance",
            ...options,
        };
        return http(conf);
    },
    get_upcoming_intake_appointments: function ({ options, data } = {}) {
        let conf = {
            method: "post",
            url: "/v1/referral/get_upcoming_intake_appointments",
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            data,
            ...options,
        };
        return httpAuth(conf);
    },
    get_provider_meta_data: function ({ options } = {}) {
        let conf = {
            method: "post",
            url: "/v1/patient/provider_metadata",
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            ...options,
        };
        return httpAuth(conf);
    },
    get_patient_meta_data: function ({ options, headers } = {}) {
        let conf = {
            method: "post",
            url: "/v1/patient/patient_metadata",
            headers: headers,
            ...options,
        };
        return httpAuth(conf);
    },
    get_all_past_intake_appointments: function ({ options, data } = {}) {
        let conf = {
            method: "post",
            url: "/v1/referral/get_all_past_intake_appointments",
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            data,
            ...options,
        };
        return httpAuth(conf);
    },
    get_upcoming_intake_availability: function ({ options, data } = {}) {
        let conf = {
            method: "post",
            url: "/v1/referral/get_upcoming_intake_availability",
            data,
            ...options,
        };
        return http(conf);
    },
    get_additional_days_for_patient_appointments: function ({ options, data } = {}) {
        let conf = {
            method: "post",
            url: "/v1/referral/get_additional_days_for_patient_appointments",
            data,
            ...options,
        };
        return http(conf);
    },
    fetch_profile: function ({ options } = {}) {
        let conf = {
            method: "post",
            url: "/v1/patient/profile",
            ...options,
        };
        return httpAuth(conf);
    },

    update_profile: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/patient/update_profile",
            data,
        };
        return httpAuth(conf);
    },

    update_preferences: function (data) {
        let conf = {
            method: "post",
            url: "/v1/patient/update_preferences",
            data,
        };

        return httpAuth(conf);
    },

    update_email_preferences: function (data) {
        let conf = {
            method: "post",
            url: "/v1/patient/update_email_preferences",
            data,
        };

        return httpAuth(conf);
    },

    append_acknowledgement: function ({ options, data } = {}) {
        let conf = {
            method: "post",
            url: "/v1/patient/append_acknowledgement",
            data,
            ...options,
        };
        return httpAuth(conf);
    },

    /**
     * Schedule related api calls
     */

    // Handles matching and scheduling in 1 call. To be removed in future scheduling work
    schedule_call: function ({ data, queryParams, headers }) {
        let conf = {
            method: "post",
            url: `/v2/patient/schedule_call?timezone=${encodeURIComponent(queryParams.timezone)}`,
            data,
        };

        if (headers != null) {
            conf["headers"] = headers;
        }

        return httpAuth(conf);
    },

    get_supplementary_patient_scheduling_data: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/patient/get_supplementary_patient_scheduling_data",
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            data,
        };
        return httpAuth(conf);
    },

    /**
     * Assessment related api calls
     */
    fetch_assessment_status: function ({ options } = {}) {
        let conf = {
            method: "post",
            url: "/v1/patient/dashboard",
            ...options,
        };
        return httpAuth(conf);
    },

    submit_question: function ({ data }) {
        let conf = { method: "post", url: "/v1/patient/assessment/submit_question", data };
        return httpAuth(conf);
    },

    start_outcome_assessment: function () {
        let conf = { method: "post", url: "/v1/patient/outcome-assessment/start" };
        return httpAuth(conf);
    },

    fetch_next_outcome_question: function ({ data }) {
        let conf = { method: "post", url: "/v1/patient/outcome-assessment/next", data };
        return httpAuth(conf);
    },
    fetch_previous_outcome_question: function () {
        let conf = { method: "post", url: "/v1/patient/outcome-assessment/previous" };
        return httpAuth(conf);
    },

    get_next_question: function ({ data, options, headers, errorTypeConfig }) {
        let conf = {
            data,
            method: "post",
            url: "/v1/patient/assessment/get_question",
            options,
            headers,
            errorTypeConfig,
        };
        return httpAuth(conf);
    },

    get_last_question: function ({ data, options, headers }) {
        let conf = {
            data,
            method: "post",
            url: "/v1/patient/assessment/last_question",
            options,
            headers,
        };
        return httpAuth(conf);
    },

    submit_next_question: function ({ data, options, headers }) {
        let conf = {
            data,
            method: "post",
            url: "/v1/patient/assessment/submit_question",
            options,
            headers,
        };
        return httpAuth(conf);
    },

    assign_assessment: function ({ data, options, headers }) {
        let conf = {
            data,
            method: "post",
            url: "/v1/patient/assessment/assign_assessment",
            options,
            headers,
        };
        return httpAuth(conf);
    },
    fetch_prescriber_survey_question: function () {
        let conf = { method: "post", url: "/v1/survey/prescriber_question" };
        return httpAuth(conf);
    },
    fetch_evaluator_survey_question: function () {
        let conf = { method: "post", url: "/v1/survey/evaluator_question" };
        return httpAuth(conf);
    },
    send_prescriber_survey_answer: function ({ data }) {
        let conf = { method: "post", url: "/v1/survey/prescriber_survey", data };
        return httpAuth(conf);
    },
    send_evaluator_survey_answer: function ({ data }) {
        let conf = { method: "post", url: "/v1/survey/evaluator_survey", data };
        return httpAuth(conf);
    },
    send_nps_survey_answers: function ({ data }) {
        let conf = { method: "post", url: "/v1/survey/nps_survey", data };
        return httpAuth(conf);
    },
    submit_rating_scale_questions: function ({ data }) {
        const conf = { method: "post", url: "/v1/patient/assessment/post_rating_scale", data };
        return httpAuth(conf);
    },
    fetch_PHQ9_questions: function () {
        let conf = { method: "post", url: "/v2/screening/phq_question" };
        return httpAuth(conf);
    },
    submit_PHQ9_questions: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v2/screening/phq_screening",
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
    fetch_GAD7_questions: function () {
        let conf = { method: "post", url: "/v2/screening/gad_question" };
        return httpAuth(conf);
    },
    submit_GAD7_questions: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v2/screening/gad_screening",
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
    fetch_insurance_eligibility_v2: function ({ options, data } = {}) {
        let conf = {
            method: "post",
            url: "/v1/insurance_eligibility/check_eligibility",
            ...options,
            data,
        };
        return http(conf);
    },
    fetch_activity: function ({ options, urlParams } = {}) {
        const urlQueryString = urlParams ? new URLSearchParams(urlParams) : "";
        let conf = {
            method: "post",
            url: `/v1/patient/activity?${urlQueryString}`,
            ...options,
        };
        return httpAuth(conf);
    },

    get_cancellation_policy: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/patient/get_cpt_code_cancellation_policy",
            data,
        };
        return httpAuth(conf);
    },

    get_has_patient_competed_first_session_for_type: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/patient/get_has_patient_competed_first_session_for_type",
            data,
        };
        return httpAuth(conf);
    },

    add_to_wait_list: function ({ data, queryParams }) {
        let conf = {
            method: "post",
            url: `/v1/patient/wait_list?method=add&patient_username=${queryParams.patient_username}`,
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    remove_from_wait_list: function ({ queryParams }) {
        let conf = {
            method: "post",
            url: `/v1/patient/wait_list?method=remove&patient_username=${queryParams.patient_username}`,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
};

export default patientRequests;
