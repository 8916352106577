import "./styles.scss";
import React, { useEffect, useRef, useState } from "react";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { adminRoute } from "../../../config/roles";
import { withRouter } from "react-router-dom";
import { api } from "../../../APIRequests";
import Badge from "../../UI/Badge";
import images from "../../../utils/images";
import { toTitleCase } from "../../../utils/filters";
import Fuse from "fuse.js";
import { useDebounce } from "../../../utils/debounce";
import { getAutomationDataAttr } from "../../../utils/automationUtils";

let SEARCH_OPTIONS = [
    {
        label: "PRACTICE",
        options: [],
    },
    {
        label: "PATIENT",
        options: [],
    },
    {
        label: "PROVIDER",
        options: [],
    },
    {
        label: "REFERRAL",
        options: [],
    },
    {
        label: "QUESTIONS",
        options: [],
    },
];

const Omnisearch = (props) => {
    const [searchText, setSearchText] = useState("");
    const [isFocused, setIsFocused] = useState(props.startFocused ? props.startFocused : false);
    const [omnisearchQuestions, setOmnisearchQuestions] = useState([]);
    const inputRef = useRef(null);

    useEffect(() => {
        setOmnisearchQuestions(props.questions);
    }, []);

    const onInputChange = (query, { action }) => {
        if (action !== "set-value" && action !== "menu-close") {
            setSearchText(query);
            return query;
        }

        return searchText;
    };

    useEffect(() => {
        if (isFocused && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isFocused]);

    const handleSelection = async (selectedOption) => {
        if (inputRef.current) {
            inputRef.current.focus();
        }

        if (!selectedOption) {
            return;
        }

        let route;
        let search;
        switch (selectedOption.type) {
            case "Practice": {
                route = `${adminRoute}/practice/${selectedOption.value.id}`;
                break;
            }

            case "Patient": {
                route = `${adminRoute}/patient/${selectedOption.value.username}/profile/v2`;
                break;
            }

            case "Provider": {
                route = `${adminRoute}/practice/${selectedOption.value.practice_id}/provider/${selectedOption.value.username}`;

                break;
            }

            case "Referral": {
                route = `${adminRoute}/referral/${selectedOption.value.referralCode}`;

                break;
            }

            case "Question": {
                route = `${adminRoute}/search`;
                search = `?questionId=${selectedOption.value.questionId}&detailLevel=${
                    selectedOption.value.detail_level
                }&importantFields=${selectedOption.value.important_fields.join(",")}`;
                break;
            }
        }

        if (route != null) {
            props.history.replace({ pathname: route, search });
            setSearchText("");
            if (props.externalToggle) {
                props.externalToggle();
            }
        }
    };

    const topNFuzzySearch = (stuff, keys, threshold, query, takeN) => {
        // Fuzzy search for questions
        const fuzzySearchOptions = {
            includeScore: true,
            keys: keys,
            ignoreLocation: true,
            threshold: threshold,
        };
        const fuzzySearch = new Fuse(stuff, fuzzySearchOptions);

        const fuzzyQuestions = fuzzySearch.search(query);

        // Sort by score ascending (lower score is higher match)
        fuzzyQuestions.sort((a, b) => a.score > b.score);

        return fuzzyQuestions.map((question) => question.item).slice(0, takeN);
    };

    const handleSearch = async (query) => {
        // Not enough data to guess at the question
        if (query.length < 3) {
            return [];
        }

        let data = {
            query,
        };

        const options = { params: { showLoader: false } };
        let response = await api.search.search_omni({ data, options });

        let searchOptions = SEARCH_OPTIONS.slice();
        searchOptions[0].options = response["practices"].map((practice) => {
            return {
                label: `${practice.name}`,
                value: practice,
                detail_level: practice.detail_level,
                type: "Practice",
            };
        });
        searchOptions[1].options = response["patients"].map((patient) => {
            return {
                label: `${patient.first_name} ${patient.last_name}`,
                value: patient,
                detail_level: patient.detail_level,
                type: "Patient",
            };
        });
        searchOptions[2].options = response["providers"].map((provider) => {
            return {
                label: `${provider.name}`,
                value: provider,
                detail_level: provider.detail_level,
                type: "Provider",
            };
        });
        searchOptions[3].options = response["referrals"].map((referral) => {
            return {
                label: `${referral.first_name} ${referral.last_name}`,
                value: referral,
                detail_level: referral.detail_level,
                type: "Referral",
            };
        });

        const omniQuestions = omnisearchQuestions.map((osQuestion) => {
            return {
                label: osQuestion.label,
                value: osQuestion,
                detail_level: osQuestion.detail_level,
                type: "Question",
            };
        });

        // Only top 5 matching.
        searchOptions[4].options = topNFuzzySearch(omniQuestions, ["label"], 0.9, query, 5);

        return searchOptions;
    };

    const formatOptionLabel = (option) => {
        let { label, type, value } = option;
        let icon,
            badge = undefined;

        if (type === "Practice") {
            icon = (
                <img
                    src={images("./common/suitcase.svg")}
                    alt="patient-avatar"
                    className="avatar"
                />
            );
            badge = <div />;
        }

        if (type === "Patient") {
            icon = (
                <img src={images("./common/avatar.png")} alt="patient-avatar" className="avatar" />
            );
            badge = (
                <div className="d-flex badge-group">
                    <Badge type="light-grey" text={type} />
                    <Badge type="success" text={toTitleCase(value.status)} />
                </div>
            );
        }

        if (type === "Provider") {
            icon = (
                <img src={images("./common/avatar.png")} alt="patient-avatar" className="avatar" />
            );
            let provider_type = value.provider_type;
            if (value.provider_type !== "PCP") {
                provider_type = toTitleCase(provider_type.replace("_", " "));
            }
            badge = (
                <div className="d-flex badge-group">
                    <Badge type="light-grey" classes={"Badge--provider"} text={provider_type} />
                </div>
            );
        }

        if (type === "Referral") {
            icon = (
                <img src={images("./common/avatar.png")} alt="patient-avatar" className="avatar" />
            );
            badge = (
                <div className="d-flex badge-group">
                    <Badge type="light-grey" text={value.referral_status} />
                </div>
            );
        }

        return (
            <div
                className="d-flex justify-content-between"
                {...getAutomationDataAttr(`OmnisearchResult${type}`)}>
                <div className="d-flex">
                    {icon}
                    <div>{label}</div>
                </div>
                {badge}
            </div>
        );
    };

    const dropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <img
                    src={images("./common/search.svg")}
                    onClick={() => setIsFocused(!isFocused)}
                    alt="search icon"
                />
            </components.DropdownIndicator>
        );
    };

    const noOptionsMessage = (props) => {
        return (
            <components.NoOptionsMessage {...props}>
                <img
                    className="m-2"
                    src={images("./common/no-search-results.svg")}
                    alt="no results"
                />
                <div className="m-2">We couldn’t find anything matching your search.</div>
            </components.NoOptionsMessage>
        );
    };

    const displayNoOptions = searchText === "" ? () => null : noOptionsMessage;
    const nonFocusedSelect = (
        <span className={"mt-2 mr-2 search-icon"} onClick={() => setIsFocused(!isFocused)}></span>
    );

    const debounceLoadOptions = useDebounce((query) => {
        if (searchText) {
            return handleSearch(query);
        }
    }, 500);

    const focusedSelect = (
        <AsyncSelect
            id="omnisearch-input"
            ref={inputRef}
            placeholder="Search"
            className="Omnisearch mr-2"
            menuPosition="fixed"
            components={{
                DropdownIndicator: dropdownIndicator,
                NoOptionsMessage: noOptionsMessage,
            }}
            noOptionsMessage={displayNoOptions}
            inputValue={searchText}
            value={searchText}
            formatOptionLabel={formatOptionLabel}
            loadOptions={debounceLoadOptions}
            onChange={handleSelection}
            onInputChange={onInputChange}
            onSelectResetsInput={false}
            onBlurResetsInput={false}
            isSearchable={true}
            isClearable={true}
        />
    );

    let renderedOutput = isFocused ? focusedSelect : nonFocusedSelect;

    if (props.searchBarOnly) {
        renderedOutput = focusedSelect;
    }

    return renderedOutput;
};

export default withRouter(Omnisearch);
