// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import "./styles.scss";
import images from "../../../utils/images";

const SearchBar = (props) => {
    let classes = "";
    if (props.className) {
        classes = props.className;
    }
    return (
        <div className={"SearchBar d-flex align-items-center justify-content-between " + classes}>
            <input
                className="SearchBar__input"
                type="text"
                placeholder="Search"
                value={props.value}
                onChange={props.onchange}
                data-private={"true"}
            />
            <div role="button" className="SearchBar__btn">
                <img className="pl-2 mb-1" src={images("./common/search.svg")} alt="search icon" />
            </div>
        </div>
    );
};

export default SearchBar;
