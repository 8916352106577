import React from "react";
import "./styles.scss";
import { useParams } from "react-router-dom";
import { BackToPatientProfile } from "../BackToPatientProfile";
import { useEffect } from "react";
import { useState } from "react";
import { CARE_MANAGEMENT_INITIAL_VALUES } from "./CaseManagementFormScheme";
import { useSelector } from "react-redux";
import _, { cloneDeep } from "lodash";
import { usePatient, usePatientDiagnosis } from "../../../../../utils/hooks/usePatient";
import { MemberHeader } from "../../../../../component-library/MemberHeader";
import { formatMobileNumber } from "../../../../../utils/filters";
import { calculateAge } from "../../../../../utils/momentUtils";
import { CareManagementForm } from "./CareManagementForm";
import { useHistory } from "react-router-dom";

// this component is used when a new care management assessment is filled out
export const CareManagementNewForm = () => {
    const { patientId } = useParams();
    const history = useHistory();
    const patient = usePatient(patientId, history.location.state?.patientDetails);
    const diagnosis = usePatientDiagnosis(patientId, history.location.state?.patientDiagnosis);
    const primaryDiagnosis = _.get(diagnosis, "ICD_10_axis_1_diagnosis", [])
        .map((icd_10) => icd_10["value"])
        .join(", ");
    const secondaryDiagnosis = _.get(diagnosis, "ICD_10_axis_2_diagnosis", [])
        .map((icd_10) => icd_10["value"])
        .join(", ");
    const provider = useSelector((state) => state.auth.profile);
    const [initialValues, setInitialValues] = useState();

    // setup form state
    useEffect(() => {
        if (!patient || !provider) {
            return;
        }
        const noteStateToClone = CARE_MANAGEMENT_INITIAL_VALUES;
        const localInitialValues = cloneDeep(noteStateToClone);
        localInitialValues.patient_id = patient.username;
        localInitialValues.patient_first_name = patient.first_name;
        localInitialValues.patient_last_name = patient.last_name;

        localInitialValues.provider_id = provider.username;
        localInitialValues.provider_name = provider.name;
        localInitialValues.provider_type = provider.provider_type;
        localInitialValues.provider_cp_credentials = provider.cp_credentials;
        setInitialValues(localInitialValues);
    }, [patient, provider]);

    return (
        <div className="CareManagementForm--container">
            <div className="CareManagementForm--wrapper">
                <BackToPatientProfile patientId={patientId} />

                {patient && provider && diagnosis && (
                    <MemberHeader
                        className={"CareManagementForm--profile"}
                        memberName={`${_.get(patient, "first_name", "")} ${_.get(
                            patient,
                            "last_name",
                            "",
                        )}`}
                        dob={_.get(patient, "dob")}
                        age={calculateAge(_.get(patient, "dob", ""))}
                        phoneNumber={formatMobileNumber(_.get(patient, "mobile"))}
                        dayOfCare={_.get(patient, "number_of_days_in_care", "")}
                        primaryDiagnosis={primaryDiagnosis === "" ? "None" : primaryDiagnosis}
                        secondaryDiagnosis={secondaryDiagnosis === "" ? "None" : secondaryDiagnosis}
                    />
                )}
                <CareManagementForm
                    noteState={initialValues}
                    isEditing={true}
                    patientId={patient?.username}
                    nameToSign={provider?.name}
                />
            </div>
        </div>
    );
};
