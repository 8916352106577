import React, { useEffect, useState } from "react";
import _, { set } from "lodash";

import { Portal } from "../../../../Common/Modal/Portal";
import images from "../../../../../utils/images";
import { ExistingPatientIntakeBody } from "./ExistingPatientIntakeBody";
import { ExistingPatientIntakeHeader } from "./ExistingPatientIntakeHeader";
import { api } from "../../../../../APIRequests";

const notNullNotEmpty = (obj, path) => {
    const value = _.get(obj, path);
    return !(_.isNil(value) || _.isEmpty(value));
};

const hasPatientAttendedIntake = (patientDetails) => {
    if (notNullNotEmpty(patientDetails, "initial_clinical_assessment_complete")) {
        return patientDetails.initial_clinical_assessment_complete;
    }
    return false;
};

const getFutureIntakeCalls = async (id, timezone) => {
    try {
        const data = { id: id, timezone: timezone };
        const appointments = await api.patient.get_upcoming_intake_appointments({ data });
        return _.get(appointments, "upcoming_appointments");
    } catch (e) {}
};

const getPastIntakeCalls = async (id, timezone) => {
    try {
        const data = { id: id, timezone: timezone };
        const appointments = await api.patient.get_all_past_intake_appointments({ data });
        return _.get(appointments, "past_appointments");
    } catch (e) {}
};

const getIntakeAppointments = async (id, timezone) => {
    try {
        const data = { id: id, timezone: timezone };
        const availability = await api.patient.get_upcoming_intake_availability({ data });
        return _.get(availability, "upcoming_availability");
    } catch (err) {
        console.log(err);
    }
};

/**
 * A React component holding out a calendar icon. On click will trigger a self-contained patient intake modal.
 * The input of this will be the patient object, corresponding to a single instance of a patient as saved in the
 * DynamoDB patient table.
 * On init, a call (useEffect) will be called to hydrate the modal with all patient behavioral intake calls,
 * past or future, if any exist.
 * This data will then walk the following decision tree (first matching in this order)
 *   * If patient has NOT ATTENDED a behavioral intake session and does NOT have one scheduled (state1)
 *   * If patient has NOT ATTENDED a behavioral intake session and DOES HAVE one scheduled (state2)
 *   * If patient HAS ATTENDED a behavioral intake session (state3)
 *   * If patient has NOT ATTENDED a behavioral intake session and HAD a behavioral intake scheduled in the past
 * Once the state has been determined, the modal will internally direct to the intended section.
 *   * schedulingRequired  -> the scheduling component is opened. The scheduling component requires to know if an appointment
 *                            is already scheduled and details about upcoming potential appoinmtments (available at API call)
 *                            https://xd.adobe.com/view/3b4a558e-4054-42b8-8074-afb5cdf92fe0-6e19/screen/024b271b-602f-442c-9b3c-517e004ca9fa/?x_product=xd-jira%2F2.0
 *                            slide 4
 *   * rescedule -> Display details about the future appointment with a button to reschedule which opens the modal
 *                            outlined in state1
 *                            https://xd.adobe.com/view/3b4a558e-4054-42b8-8074-afb5cdf92fe0-6e19/screen/c574ded9-78e0-4ef5-8406-71488e621a84/?x_product=xd-jira%2F2.0
 *                            slide 7
 *   * intakeComplete      -> Display details about the patient intake appointment that already occured, most visual elements
 *                            mirror the opening modal seen in state2
 *                            https://xd.adobe.com/view/3b4a558e-4054-42b8-8074-afb5cdf92fe0-6e19/screen/2b3aded2-b2c7-476b-85c6-c1ceb8f588d3/?x_product=xd-jira%2F2.0
 *                            slide 9
 *   * intakeMissed        -> Display details about the patient intake appointment that the patient failed to attend. Most
 *                            visual elements mirror the opening modal outlined in state2
 *                            https://xd.adobe.com/view/3b4a558e-4054-42b8-8074-afb5cdf92fe0-6e19/screen/57ed4bb6-c507-4132-9b90-2633e057c9ba/?x_product=xd-jira%2F2.0
 *                            slide 10
 * Note that the loading of events is a time-intensive call, so a state5, loading, will need to be created
 * for a better user experience alerting the user something is happening
 * @param {object} patientDetails - This is a JSON object corresponding to an entry in the patientTable.
 * @param {boolean} openModalOnRender - Should modal be automatically rendered
 * @param {JSX.Element} openButtonRender - Element displayed as the link to open the modal
 */
export const ExistingPatientIntake = ({ patientDetails, openModalOnRender, openButtonRender }) => {
    // Start off as state 5 (loading)
    const [currentState, setCurrentState] = useState("loading");
    const [isModalOpen, setIsModalOpen] = useState(null);

    // Start off with calls as null, this component will re-render any time all calls resets
    const [allPastIntakeCalls, setAllPastIntakeCalls] = useState(null);
    const [allFutureIntakeCalls, setAllFutureIntakeCalls] = useState(null);
    const [intakeAppointments, setIntakeAppointments] = useState(null);
    const [selectedIntakeCall, setSelectedIntakeCall] = useState({ timeStatus: null, call: null });
    const [closeHandler, setCloseHandler] = useState(null);

    const getCurrentIntakeCall = (futureIntakeCalls, pastIntakeCalls) => {
        if (futureIntakeCalls.length > 0) {
            const cloneFutureIntakeCalls = _.cloneDeep(futureIntakeCalls);
            return { timeStatus: "FUTURE", call: cloneFutureIntakeCalls.pop() };
        }

        if (pastIntakeCalls.length > 0) {
            const clonePastIntakeCalls = _.cloneDeep(pastIntakeCalls);
            return { timeStatus: "PAST", call: clonePastIntakeCalls.pop() };
        }

        if (futureIntakeCalls.length === 0) {
            return { timeStatus: null, call: null };
        }
    };

    const handleFutureCalls = (currentIntakeCall) => {
        if (currentIntakeCall["call_id"]) {
            setCurrentState("reschedule");
        } else {
            setCurrentState("confirmSchedule");
        }
    };

    const initializeExistingPatientIntakeModal = async () => {
        const patientId = _.get(patientDetails, "username", null);
        const patientTimezone = _.get(patientDetails, "preferences.time_zone", null);

        const patientHasAttendedIntake = hasPatientAttendedIntake(patientDetails);

        const futureIntakeCalls = await getFutureIntakeCalls(patientId, patientTimezone);
        const pastIntakeCalls = await getPastIntakeCalls(patientId, patientTimezone);
        const allIntakeAppointments = await getIntakeAppointments(patientId, patientTimezone);

        setAllFutureIntakeCalls(futureIntakeCalls);
        setAllPastIntakeCalls(pastIntakeCalls);
        setIntakeAppointments(allIntakeAppointments);

        const currentIntakeCall = getCurrentIntakeCall(futureIntakeCalls, pastIntakeCalls);
        setSelectedIntakeCall(currentIntakeCall["call"]);

        if (patientHasAttendedIntake) {
            setCurrentState("intakeComplete");
            return;
        }

        if (!currentIntakeCall["timeStatus"]) {
            setCurrentState("schedulingRequired");
            return;
        }

        if (currentIntakeCall["timeStatus"] && currentIntakeCall["timeStatus"] === "FUTURE") {
            handleFutureCalls(currentIntakeCall["call"]);
            return;
        }

        if (currentIntakeCall["timeStatus"] && currentIntakeCall["timeStatus"] === "PAST") {
            setCurrentState("intakeMissed");
            return;
        }
    };

    const closeModal = () => {
        setCloseHandler(!closeHandler);
    };

    useEffect(() => {
        initializeExistingPatientIntakeModal();
    }, []);

    const existingPatientIntakePayload = {
        patientDetails,
        currentState,
        setCurrentState,
        intakeAppointments,
        setIntakeAppointments,
        closeModal,
        selectedIntakeCall,
        initializeExistingPatientIntakeModal,
    };

    const onModalOpen = () => {
        initializeExistingPatientIntakeModal();
        setIsModalOpen(!isModalOpen);
    };

    if (!allFutureIntakeCalls || !allPastIntakeCalls || !intakeAppointments) {
        return <></>;
    }

    return (
        <Portal
            className={"schedule-intake-modal"}
            isOpenOnRender={openModalOnRender}
            isBottomCloseActive={false}
            onModalOpen={onModalOpen}
            openButtonRender={openButtonRender}
            headerRender={<ExistingPatientIntakeHeader {...existingPatientIntakePayload} />}
            triggerModalStateChange={closeHandler}>
            <ExistingPatientIntakeBody {...existingPatientIntakePayload} />
        </Portal>
    );
};
