/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const MicrophoneIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Microphone"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.0357 3C15.0885 3 14.1801 3.36279 13.5103 4.00856C12.8405 4.65433 12.4642 5.53019 12.4642 6.44345V15.628C12.4642 16.5412 12.8405 17.4171 13.5103 18.0629C14.1801 18.7086 15.0885 19.0714 16.0357 19.0714C16.9829 19.0714 17.8913 18.7086 18.561 18.0629C19.2308 17.4171 19.6071 16.5412 19.6071 15.628V6.44345C19.6071 5.53019 19.2308 4.65433 18.561 4.00856C17.8913 3.36279 16.9829 3 16.0357 3ZM14.8985 5.44834C15.188 5.16915 15.5958 5 16.0357 5C16.4755 5 16.8833 5.16915 17.1729 5.44834C17.4599 5.72513 17.6071 6.08466 17.6071 6.44345V15.628C17.6071 15.9868 17.4599 16.3463 17.1729 16.6231C16.8833 16.9023 16.4755 17.0714 16.0357 17.0714C15.5958 17.0714 15.188 16.9023 14.8985 16.6231C14.6114 16.3463 14.4642 15.9868 14.4642 15.628V6.44345C14.4642 6.08466 14.6114 5.72513 14.8985 5.44834Z"
                />
                <path d="M9 12.8215C9 12.2692 8.55228 11.8215 8 11.8215C7.44772 11.8215 7 12.2692 7 12.8215V15.2023C7 16.4231 7.23181 17.6326 7.68302 18.7623C8.13425 19.892 8.79653 20.9208 9.63376 21.7891C10.4711 22.6574 11.4671 23.3482 12.5659 23.8202C13.3561 24.1597 14.1878 24.3809 15.0358 24.4784V27H11.5715C11.0192 27 10.5715 27.4477 10.5715 28C10.5715 28.5523 11.0192 29 11.5715 29H20.5001C21.0524 29 21.5001 28.5523 21.5001 28C21.5001 27.4477 21.0524 27 20.5001 27H17.0358V24.4784C17.8837 24.3809 18.7154 24.1596 19.5055 23.8202C20.6044 23.3482 21.6004 22.6574 22.4377 21.7891C23.2749 20.9208 23.9372 19.892 24.3884 18.7623C24.8396 17.6326 25.0714 16.4231 25.0714 15.2023V12.8215C25.0714 12.2692 24.6237 11.8215 24.0714 11.8215C23.5191 11.8215 23.0714 12.2692 23.0714 12.8215V15.2023C23.0714 16.1703 22.8875 17.128 22.5311 18.0205C22.1746 18.9129 21.6531 19.7214 20.998 20.4008C20.3429 21.0802 19.5672 21.617 18.7162 21.9826C17.8729 22.3448 16.9709 22.5324 16.0606 22.5357H16.0109C15.1005 22.5324 14.1985 22.3448 13.3553 21.9826C12.5042 21.617 11.7285 21.0802 11.0735 20.4008C10.4183 19.7214 9.89678 18.9129 9.54035 18.0205C9.18389 17.128 9 16.1703 9 15.2023V12.8215Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Microphone"
                {...props}>
                <path d="M13.5103 4.00856C14.1801 3.36279 15.0885 3 16.0357 3C16.9829 3 17.8913 3.36279 18.561 4.00856C19.2308 4.65433 19.6071 5.53019 19.6071 6.44345V15.628C19.6071 16.5412 19.2308 17.4171 18.561 18.0629C17.8913 18.7086 16.9829 19.0714 16.0357 19.0714C15.0885 19.0714 14.1801 18.7086 13.5103 18.0629C12.8405 17.4171 12.4642 16.5412 12.4642 15.628V6.44345C12.4642 5.53019 12.8405 4.65433 13.5103 4.00856Z" />
                <path d="M9 12.8215C9 12.2692 8.55228 11.8215 8 11.8215C7.44772 11.8215 7 12.2692 7 12.8215V15.2023C7 16.4231 7.23181 17.6326 7.68302 18.7623C8.13425 19.892 8.79653 20.9208 9.63376 21.7891C10.4711 22.6574 11.4671 23.3482 12.5659 23.8202C13.3561 24.1597 14.1878 24.3809 15.0358 24.4784V27H11.5715C11.0192 27 10.5715 27.4477 10.5715 28C10.5715 28.5523 11.0192 29 11.5715 29H20.5001C21.0524 29 21.5001 28.5523 21.5001 28C21.5001 27.4477 21.0524 27 20.5001 27H17.0358V24.4784C17.8837 24.3809 18.7154 24.1596 19.5055 23.8202C20.6044 23.3482 21.6004 22.6574 22.4377 21.7891C23.2749 20.9208 23.9372 19.892 24.3884 18.7623C24.8396 17.6326 25.0714 16.4231 25.0714 15.2023V12.8215C25.0714 12.2692 24.6237 11.8215 24.0714 11.8215C23.5191 11.8215 23.0714 12.2692 23.0714 12.8215V15.2023C23.0714 16.1703 22.8875 17.128 22.5311 18.0205C22.1746 18.9129 21.6531 19.7214 20.998 20.4008C20.3429 21.0802 19.5672 21.617 18.7162 21.9826C17.8729 22.3448 16.9709 22.5324 16.0606 22.5357H16.0109C15.1005 22.5324 14.1985 22.3448 13.3553 21.9826C12.5042 21.617 11.7285 21.0802 11.0735 20.4008C10.4183 19.7214 9.89678 18.9129 9.54035 18.0205C9.18389 17.128 9 16.1703 9 15.2023V12.8215Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Microphone"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20.0267 10.25C19.3163 10.25 18.635 10.5221 18.1327 11.0064C17.6304 11.4907 17.3482 12.1476 17.3482 12.8326V19.721C17.3482 20.4059 17.6304 21.0628 18.1327 21.5471C18.635 22.0315 19.3163 22.3036 20.0267 22.3036C20.7371 22.3036 21.4185 22.0315 21.9208 21.5471C22.4231 21.0628 22.7053 20.4059 22.7053 19.721V12.8326C22.7053 12.1476 22.4231 11.4907 21.9208 11.0064C21.4185 10.5221 20.7371 10.25 20.0267 10.25ZM14 16.8661C14.4142 16.8661 14.75 17.2019 14.75 17.6161V19.4017C14.75 20.1277 14.8879 20.846 15.1553 21.5154C15.4226 22.1846 15.8137 22.791 16.3051 23.3006C16.7964 23.8101 17.3782 24.2128 18.0164 24.4869C18.6489 24.7586 19.3254 24.8993 20.0081 24.9018H20.0454C20.7282 24.8993 21.4047 24.7586 22.0371 24.4869C22.6754 24.2128 23.2572 23.8101 23.7485 23.3006C24.2398 22.791 24.631 22.1846 24.8983 21.5154C25.1656 20.846 25.3036 20.1277 25.3036 19.4017V17.6161C25.3036 17.2019 25.6394 16.8661 26.0536 16.8661C26.4678 16.8661 26.8036 17.2019 26.8036 17.6161V19.4017C26.8036 20.3173 26.6297 21.2245 26.2913 22.0717C25.9529 22.919 25.4562 23.6906 24.8282 24.3418C24.2003 24.9931 23.4533 25.5112 22.6291 25.8652C22.0365 26.1197 21.4128 26.2857 20.7768 26.3588V28.25H23.3751C23.7893 28.25 24.1251 28.5858 24.1251 29C24.1251 29.4142 23.7893 29.75 23.3751 29.75H16.6786C16.2644 29.75 15.9286 29.4142 15.9286 29C15.9286 28.5858 16.2644 28.25 16.6786 28.25H19.2768V26.3588C18.6408 26.2857 18.0171 26.1197 17.4244 25.8652C16.6003 25.5112 15.8533 24.9931 15.2253 24.3418C14.5974 23.6906 14.1007 22.919 13.7623 22.0717C13.4239 21.2245 13.25 20.3173 13.25 19.4017V17.6161C13.25 17.2019 13.5858 16.8661 14 16.8661Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

MicrophoneIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

MicrophoneIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
