import React, { useState } from "react";
import moment from "moment/moment";
import { calculateScrollPositionToTime } from "../../../utils/calendar/positions";
import { updateSelectedDateV2 } from "../../../redux/actions/calendar";
import { getCancellationReason, getPatientSessionStatusV2 } from "../../../utils/calendar/event";
import { useDispatch } from "react-redux";

const CancellationInfo = ({ event, calendarRect }) => {
    const {
        patient_name: patientName,
        patient_username: patientUsername,
        provider_name: providerName,
        provider_username: providerUsername,
        deleted: cancelled,
        cancellation_reason: cancellationReason,
        rescheduled_to_call: rescheduledToCall,
        timezone: tmz,
        updated_by: updatedBy,
        created_date: createdDate,
        rescheduled_as: rescheduledAs,
    } = event || {};

    const NO_SHOW_REASON_MAX_LENGTH = 60;
    const dispatch = useDispatch();
    const [showFullReason, setShowFullReason] = useState(false);

    // Dates
    const parseDate = (timestamp, timezone, outputFormat = "MM/DD/YY hh:mm A") => {
        let utcDate;

        if (timestamp.includes(".")) {
            utcDate = moment.unix(parseFloat(timestamp));
        } else {
            utcDate = moment.utc(timestamp, "YYYY-MM-DD HH:mm");
        }

        const timezoneDate = utcDate.clone().tz(timezone);

        return timezoneDate.format(outputFormat);
    };

    // Reschedules
    const handleReScheduledViewClick = () => {
        const time = moment(rescheduledToCall.timestamp);
        const correctedTime = time.clone().subtract(6, "hours");
        const isDateShifted = correctedTime.date() !== time.date();
        const adjustedTime = isDateShifted ? time.clone().subtract(5 % 24, "hours") : correctedTime;
        const scrollTo = calculateScrollPositionToTime(adjustedTime, calendarRect);

        window.scrollTo({
            ...scrollTo,
            behavior: "smooth",
        });

        dispatch(
            updateSelectedDateV2(
                moment(adjustedTime.format("YYYY-MM-DD"), "YYYY-MM-DD"),
                rescheduledToCall.rescheduled_to_call_id,
            ),
        );
    };

    // Cancellations
    const renderCancellationBy = () => {
        // No show data will show cancelled by as patient but we want to
        // use the provider name as they were the one who marked it as no-show
        if (cancellationReason && cancellationReason.reason_type === "no_show") {
            return providerName;
        }

        // Logic for new events
        if (cancellationReason && cancellationReason.cancelled_by_name) {
            return cancellationReason.cancelled_by_name;
        }

        // Logic for previous events
        // Do logic to check if patient, provider or admin cancelled
        if (cancellationReason && cancellationReason.cancelled_by === patientUsername) {
            return patientName;
        } else if (cancellationReason && cancellationReason.cancelled_by === providerUsername) {
            return providerName;
        } else {
            return "-";
        }
    };

    const renderCancellationTimeStamp = () => {
        return parseDate(cancellationReason.timestamp, tmz);
    };
    const renderCancellationDetails = () => {
        const cancelledByUserType = cancellationReason?.cancelled_by_user_type;

        return getCancellationReason(
            cancelledByUserType.split(":")[0],
            cancellationReason.reason_type,
        );
    };

    const isSMSCancellation = () => {
        return cancellationReason && cancellationReason.reason_type === "sms";
    };

    // Auto cancellations
    const renderAutoCancellationBy = () => {
        return "System";
    };

    //No Show
    const noShowreasonText = event.no_show_reason ? event.no_show_reason : "-";

    const toggleShowMore = () => {
        setShowFullReason(!showFullReason);
    };

    const renderNoShowReason = () => {
        if (noShowreasonText.length > NO_SHOW_REASON_MAX_LENGTH) {
            return (
                <span>
                    {showFullReason
                        ? noShowreasonText
                        : `${noShowreasonText.slice(0, NO_SHOW_REASON_MAX_LENGTH)}...`}
                    <span
                        className="apti-CalendarAppointmentView--no-show-reason"
                        onClick={toggleShowMore}>
                        <strong> {showFullReason ? "Less" : "More"}</strong>
                    </span>
                </span>
            );
        } else {
            return <span>&quot;{noShowreasonText}&quot;</span>;
        }
    };

    // ReSchedules
    const renderReScheduledBy = () => {
        if (rescheduledToCall) {
            return rescheduledToCall.rescheduled_by;
        }

        if (updatedBy === patientUsername) {
            return patientName;
        } else if (updatedBy === providerUsername) {
            return providerName;
        }

        return "-";
    };

    // Status
    const renderAppointmentStatus = () => {
        const status = getPatientSessionStatusV2(event);

        return status.text;
    };

    const renderNewReScheduledTime = () => {
        return rescheduledToCall ? parseDate(rescheduledToCall.timestamp, tmz) : "-";
    };

    const renderReScheduledCreationTime = () => {
        return parseDate(createdDate, tmz);
    };

    return (
        <div>
            {cancellationReason &&
            cancellationReason.reason_type === "no_show" &&
            cancelled &&
            !rescheduledAs ? (
                <div>
                    <div className={"apti-CalendarAppointmentView--appointmentStatusContainer"}>
                        <span className={"apti-CalendarAppointmentView--appointmentStatus"}>
                            {renderAppointmentStatus()}
                        </span>
                    </div>
                    <div
                        className={
                            "apti-CalendarAppointmentView--appointmentCancellationContainer d-flex"
                        }>
                        <span>
                            Marked as No-Show by {renderCancellationBy()} on{" "}
                            {renderCancellationTimeStamp()}
                            .<br />
                        </span>

                        <span>Reason: {renderNoShowReason()}</span>

                        <span
                            className={
                                "apti-CalendarAppointmentView--appointmentCancellationContainerSeparator"
                            }></span>
                    </div>
                </div>
            ) : "rescheduled_as" in event ? (
                <div>
                    <div className={"apti-CalendarAppointmentView--appointmentStatusContainer"}>
                        <span className={"apti-CalendarAppointmentView--appointmentStatus"}>
                            {renderAppointmentStatus()}
                        </span>
                    </div>
                    {rescheduledToCall && (
                        <div
                            className={
                                "apti-CalendarAppointmentView--appointmentRescheduleContainer d-flex"
                            }>
                            <div
                                className={
                                    "apti-CalendarAppointmentView--appointmentRescheduleBox"
                                }>
                                <span
                                    className={
                                        "apti-CalendarAppointmentView--appointmentRescheduleNewDate"
                                    }>
                                    New Time: {renderNewReScheduledTime()}
                                </span>

                                <a
                                    onClick={() => handleReScheduledViewClick(event)}
                                    className={
                                        "apti-CalendarAppointmentView--appointmentRescheduleView"
                                    }>
                                    View
                                </a>
                            </div>
                            <span
                                className={
                                    "apti-CalendarAppointmentView--appointmentRescheduleDetails"
                                }>
                                Rescheduled by {renderReScheduledBy()} on{" "}
                                {renderReScheduledCreationTime()}.
                            </span>

                            <span
                                className={
                                    "apti-CalendarAppointmentView--appointmentCancellationContainerSeparator"
                                }></span>
                        </div>
                    )}
                </div>
            ) : cancellationReason && cancellationReason.reason_type === "auto" ? (
                <div>
                    <div className={"apti-CalendarAppointmentView--appointmentStatusContainer"}>
                        <span className={"apti-CalendarAppointmentView--appointmentStatus"}>
                            {renderAppointmentStatus()}
                        </span>
                    </div>
                    <div
                        className={
                            "apti-CalendarAppointmentView--appointmentCancellationContainer d-flex"
                        }>
                        <span>
                            Auto-cancelled by {renderAutoCancellationBy()} on{" "}
                            {renderCancellationTimeStamp()}
                            .<br />
                        </span>

                        <span
                            className={
                                "apti-CalendarAppointmentView--appointmentCancellationContainerSeparator"
                            }></span>
                    </div>
                </div>
            ) : cancellationReason && cancellationReason.cancelled_by ? (
                <div>
                    <div className={"apti-CalendarAppointmentView--appointmentStatusContainer"}>
                        <span className={"apti-CalendarAppointmentView--appointmentStatus"}>
                            {renderAppointmentStatus()}
                        </span>
                    </div>
                    <div
                        className={
                            "apti-CalendarAppointmentView--appointmentCancellationContainer d-flex"
                        }>
                        <span>
                            Cancelled by {renderCancellationBy()} on {renderCancellationTimeStamp()}
                            .<br />
                        </span>

                        {!isSMSCancellation() && (
                            <span>Reason: &quot;{renderCancellationDetails()}.&quot;</span>
                        )}

                        <span
                            className={
                                "apti-CalendarAppointmentView--appointmentCancellationContainerSeparator"
                            }></span>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default CancellationInfo;
