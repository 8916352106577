// Copyright aptihealth, inc. 2019 All Rights Reserved
import { isAuthorized } from "../../../redux/actions/auth";

/**
 * @deprecated This module is deprecated and all constants have been moved to their appropriate constants file.
 * Please see the following files for more details
 * - src\constants\calendar
 * - src\utils\calendar\event.js
 * @module DeprecatedModule
 */

export const timeSlots = [
    { slot: "00:00" },
    { slot: "01:00" },
    { slot: "02:00" },
    { slot: "03:00" },
    { slot: "04:00" },
    { slot: "05:00" },
    { slot: "06:00" },
    { slot: "07:00" },
    { slot: "08:00" },
    { slot: "09:00" },
    { slot: "10:00" },
    { slot: "11:00" },
    { slot: "12:00" },
    { slot: "13:00" },
    { slot: "14:00" },
    { slot: "15:00" },
    { slot: "16:00" },
    { slot: "17:00" },
    { slot: "18:00" },
    { slot: "19:00" },
    { slot: "20:00" },
    { slot: "21:00" },
    { slot: "22:00" },
    { slot: "23:00" },
];

/**
 * This will be used in `NewSchedulePopUp` component
 */

const formatTime = (time) => {
    return time < 10 ? "0" + time : "" + time;
};

const getTimeSlotArray = () => {
    const timeSlotArray = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let min = 0; min < 60; min += 15) {
            timeSlotArray.push(formatTime(hour) + ":" + formatTime(min));
        }
    }
    return timeSlotArray;
};

const getHourArray = () => {
    const timeSlotArray = [];
    for (let hour = 1; hour <= 12; hour++) {
        const formattedHour = formatTime(hour);
        timeSlotArray.push({ value: formattedHour, display: formattedHour });
    }
    return timeSlotArray;
};

const getMinuteArray = () => {
    const timeSlotArray = [];
    for (let min = 0; min < 60; min++) {
        const formattedMin = formatTime(min);
        timeSlotArray.push({ value: formattedMin, display: formattedMin });
    }
    return timeSlotArray;
};

/**
 * @deprecated
 */
export const timeSlotArray = getTimeSlotArray();
/**
 * @deprecated
 */
export const hourArray = getHourArray();
/**
 * @deprecated
 */
export const minuteArray = getMinuteArray();
/**
 * @deprecated
 */

export const TEENS_AND_KIDS_DIAGNOSTIC_INTERVIEW_90791_ALLOTTED_TIME = 90;
/**
 * @deprecated
 */

/** mode for new schedule type (away, video-consultation etc) */
export const VIDEO_CONSULTATION = "video_consultation";
/**
 * @deprecated
 */
export const AWAY = "away";
/**
 * @deprecated
 */
export const AVAILABLE = "available";
/**
 * @deprecated
 */
export const DIAGNOSTIC_INTERVIEW_90791 = "diagnostic_interview_90791";
/**
 * @deprecated
 */
export const DIAGNOSTIC_INTERVIEW_90792 = "diagnostic_interview_90792";
/**
 * @deprecated
 */
export const _30_MIN_INDIVIDUAL_90832 = "30_min_individual_90832";
/**
 * @deprecated
 */
export const _45_MIN_INDIVIDUAL_90834 = "45_min_individual_90834";
/**
 * @deprecated
 */
export const _60_MIN_INDIVIDUAL_90837 = "60_min_individual_90837";
/**
 * @deprecated
 */
export const CRISIS_EVAL_90839 = "crisis_eval_90839";
/**
 * @deprecated
 */
export const CRISIS_EVAL_EXTENDED_90840 = "crisis_eval_extended_90840";
/**
 * @deprecated
 */
export const FAMILY_THERAPY_90847 = "family_psychotherapy_w_patient_90847";
/**
 * @deprecated
 */
export const FAMILY_THERAPY_WO_PATIENT_90846 = "family_psychotherapy_wo_patient_90846";
/**
 * @deprecated
 */
export const MEDICATION_MGMT_99212 = "medication_mgmt_99212";
/**
 * @deprecated
 */
export const MEDICATION_MGMT_99213 = "medication_mgmt_99213";
/**
 * @deprecated
 */
export const MEDICATION_MGMT_99214 = "medication_mgmt_99214";
/**
 * @deprecated
 */
export const NO_LINKED_CALL_0 = "no_linked_call_0";
/**
 * @deprecated
 */
export const INTERPRET_A5_OR_A30_96130 = "interpret_a5_and/or_a30_96130";
/**
 * @deprecated
 */
export const A30_ADMINISTRATION_96146 = "a30_administration_96146";
/**
 * @deprecated
 */
export const IN_APP_MESSAGING_98970 = "in_app_messaging_98970";
/**
 * @deprecated
 */
export const IN_APP_MESSAGING_98971 = "in_app_messaging_98971";
/**
 * @deprecated
 */
export const IN_APP_MESSAGING_98972 = "in_app_messaging_98972";
/**
 * @deprecated
 */
export const IN_APP_MESSAGING_99421 = "in_app_messaging_99421";
/**
 * @deprecated
 */
export const IN_APP_MESSAGING_99422 = "in_app_messaging_99422";
/**
 * @deprecated
 */
export const IN_APP_MESSAGING_99423 = "in_app_messaging_99423";
/**
 * @deprecated
 */
export const _5_10_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98966 =
    "_5_10_phone_assessment_and_mgmt_vs_med_discussion_98966";
/**
 * @deprecated
 */
export const _11_20_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98967 =
    "_11_20_phone_assessment_and_mgmt_vs_med_discussion_98967";
/**
 * @deprecated
 */
export const _21_30_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98968 =
    "_21_30_phone_assessment_and_mgmt_vs_med_discussion_98968";
/**
 * @deprecated
 */
export const CM_SERVICES_20_MINS_MINIMUM_MONTH =
    "CM_Services_min_20_mins_minimum/calendar_month_99484";
/**
 * @deprecated
 */
export const PHQ_OR_GAD_96127 = "phq_or_gad_96127";
/**
 * @deprecated
 */
export const OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204 =
    "office_or_other_outpatient_visit_w_new_patient_99204";
/**
 * @deprecated
 */
export const OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205 =
    "office_or_other_outpatient_visit_w_new_patient_99205";
/**
 * @deprecated
 */
export const ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215 =
    "established_patient_office_or_other_outpatient_services_99215";
/**
 * @deprecated
 */
export const NON_BILLABLE = "non_billable";
/**
 * @deprecated
 */
export const allottedTimeFactory = (type) => {
    switch (type) {
        case AWAY:
            return 30;
        case VIDEO_CONSULTATION:
            return 30;
        case DIAGNOSTIC_INTERVIEW_90791:
            return 45;
        case DIAGNOSTIC_INTERVIEW_90792:
            return 60;
        case _30_MIN_INDIVIDUAL_90832:
            return 30;
        case _45_MIN_INDIVIDUAL_90834:
            return 45;
        case _60_MIN_INDIVIDUAL_90837:
            return 60;
        case FAMILY_THERAPY_WO_PATIENT_90846:
            return 45;
        case CRISIS_EVAL_90839:
            return isAuthorized("provider:prescribe") ? 15 : 45;
        case CRISIS_EVAL_EXTENDED_90840:
            return 75;
        case FAMILY_THERAPY_90847:
            return 45;
        case MEDICATION_MGMT_99212:
            return 15;
        case MEDICATION_MGMT_99213:
            return 15;
        case MEDICATION_MGMT_99214:
            return 30;
        case _5_10_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98966:
            return 10;
        case _11_20_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98967:
            return 20;
        case _21_30_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98968:
            return 30;
        case CM_SERVICES_20_MINS_MINIMUM_MONTH:
            return 20;
        case OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204:
            return 60;
        case OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205:
            return 75;
        case ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215:
            return 45;
        default:
            return 30;
    }
};

/**
 * @deprecated
 */
export const BEHAVIORAL = "BEHAVIORAL";
/**
 * @deprecated
 */
export const BEHAVIORAL_INTAKE = "BEHAVIORAL_INTAKE";
/**
 * @deprecated
 */
export const PRESCRIBE = "PRESCRIBE";
/**
 * @deprecated
 */
export const PCP = "PCP";
/**
 * @deprecated
 */
export const CARE_MANAGER = "CARE_MANAGER";

/**
 * @deprecated
 */
export const DEFAULT_PRESCRIBER_SCHEDULE_OPTIONS = [
    // NOTE: these are label not display. won't work in ShedulePage
    { value: AWAY, label: "Away" },
    { value: AVAILABLE, label: "Available" },
    {
        value: DIAGNOSTIC_INTERVIEW_90792,
        label: `Diagnostic Interview (90792) (${allottedTimeFactory(
            DIAGNOSTIC_INTERVIEW_90792,
        )} minutes)`,
    },
    {
        value: MEDICATION_MGMT_99212,
        label: `Medication Management (99212) (${allottedTimeFactory(
            MEDICATION_MGMT_99212,
        )} minutes)`,
    },
    {
        value: MEDICATION_MGMT_99213,
        label: `Medication Management (99213) (${allottedTimeFactory(
            MEDICATION_MGMT_99213,
        )} minutes)`,
    },
    {
        value: MEDICATION_MGMT_99214,
        label: `Medication Management (99214) (${allottedTimeFactory(
            MEDICATION_MGMT_99214,
        )} minutes)`,
    },
    {
        value: CRISIS_EVAL_90839,
        label: `Crisis Evaluation (90839) (${allottedTimeFactory(CRISIS_EVAL_90839)} minutes)`,
    },
    {
        value: ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215,
        label: `Established Patient Office or Other Outpatient Services (99215) (${allottedTimeFactory(
            ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215,
        )} minutes)`,
    },
];

/**
 * @deprecated
 */
export const DEFAULT_BEHAVIORAL_SCHEDULE_OPTIONS = [
    { value: AWAY, label: "Away" },
    { value: AVAILABLE, label: "Available" },
    {
        value: DIAGNOSTIC_INTERVIEW_90791,
        label: `Diagnostic Interview (90791) (${allottedTimeFactory(
            DIAGNOSTIC_INTERVIEW_90791,
        )} minutes)`,
    },
    {
        value: _30_MIN_INDIVIDUAL_90832,
        label: `30 Minute Individual (90832) (${allottedTimeFactory(
            _30_MIN_INDIVIDUAL_90832,
        )} minutes)`,
    },
    {
        value: _45_MIN_INDIVIDUAL_90834,
        label: `45 Minute Individual (90834) (${allottedTimeFactory(
            _45_MIN_INDIVIDUAL_90834,
        )} minutes)`,
    },
    {
        value: _60_MIN_INDIVIDUAL_90837,
        label: `60 Minute Individual (90837) (${allottedTimeFactory(
            _60_MIN_INDIVIDUAL_90837,
        )} minutes)`,
    },
    {
        value: FAMILY_THERAPY_90847,
        label: `Family Therapy w/ Patient (90847) (${allottedTimeFactory(
            FAMILY_THERAPY_90847,
        )} minutes)`,
    },
    {
        value: FAMILY_THERAPY_WO_PATIENT_90846,
        label: `Family Therapy w/o Patient (90846) (${allottedTimeFactory(
            FAMILY_THERAPY_WO_PATIENT_90846,
        )} minutes)`,
    },
    {
        value: CRISIS_EVAL_90839,
        label: `Crisis Evaluation (90839) (${allottedTimeFactory(CRISIS_EVAL_90839)} minutes)`,
    },
    {
        value: CRISIS_EVAL_EXTENDED_90840,
        label: `Crisis Evaluation Extended (90840) (${allottedTimeFactory(
            CRISIS_EVAL_EXTENDED_90840,
        )} minutes)`,
    },
    {
        value: OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
        label: `Office or Other Outpatient Visit w/ New Patient (99204) (${allottedTimeFactory(
            OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
        )} minutes)`,
    },
    {
        value: OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
        label: `Office or Other Outpatient Visit w/ New Patient (99205) (${allottedTimeFactory(
            OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
        )} minutes)`,
    },
];
