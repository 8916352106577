// all utility functions that dont fit anywhere else
// and interact with https://developer.mozilla.org/en-US/docs/Web/API

import { toastMessageTypes } from "../redux/actions/toaster";

/**
 *
 * @param {*} text
 * @param {*} addToast the addToast redox method defined in
 * redux/actions/toaster wrapped in a dispatch
 */
export const copyToClipboard = async (text, addToast) => {
    await navigator.clipboard.writeText(text);

    addToast({
        message: "Copied to clipboard.",
        messageType: toastMessageTypes.success_v2,
    });
};
