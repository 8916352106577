/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const PcpIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Pcp"
                {...props}>
                <path d="M24 15.5C24.8284 15.5 25.5 14.8284 25.5 14C25.5 13.1716 24.8284 12.5 24 12.5C23.1716 12.5 22.5 13.1716 22.5 14C22.5 14.8284 23.1716 15.5 24 15.5Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.25 3.5C4.73122 3.5 3.5 4.73122 3.5 6.25C3.5 7.38152 4.18339 8.35343 5.15994 8.7755C5.20222 10.3537 5.39715 12.1703 6.06869 13.6899C6.46849 14.5946 7.05452 15.4349 7.91372 16.0467C8.51063 16.4718 9.20688 16.7633 10.0041 16.9037C10.0244 18.6384 10.1465 21.2239 10.8789 23.5341C11.3187 24.9212 12.0011 26.2864 13.0823 27.3124C14.1877 28.3613 15.6531 29 17.5225 29C19.3604 29 20.8073 28.4394 21.9111 27.5107C22.9974 26.5967 23.687 25.3813 24.1301 24.1631C24.8187 22.2698 24.9622 20.2201 24.9921 18.9016C27.2783 18.4413 29 16.4217 29 14C29 11.2386 26.7614 9 24 9C21.2386 9 19 11.2386 19 14C19 16.4158 20.7133 18.4314 22.991 18.8982C22.9607 20.1245 22.8283 21.891 22.2505 23.4795C21.8839 24.4875 21.359 25.3614 20.6235 25.9803C19.9055 26.5844 18.9233 27 17.5225 27C16.153 27 15.1838 26.5494 14.459 25.8616C13.71 25.1509 13.1666 24.132 12.7854 22.9297C12.1491 20.9228 12.028 18.621 12.0052 16.9477C12.925 16.8312 13.7179 16.5227 14.3863 16.0467C15.2455 15.4349 15.8316 14.5946 16.2314 13.6899C16.8919 12.1953 17.0913 10.4133 17.1379 8.85351C18.221 8.48423 19 7.45809 19 6.25C19 4.73122 17.7688 3.5 16.25 3.5C14.7312 3.5 13.5 4.73122 13.5 6.25C13.5 7.37354 14.1738 8.33971 15.1393 8.76649C15.0968 10.2294 14.9159 11.7188 14.402 12.8815C14.1074 13.5482 13.7212 14.0651 13.2262 14.4176C12.7394 14.7642 12.0805 15 11.15 15C10.2196 15 9.56062 14.7642 9.07383 14.4176C8.57887 14.0651 8.19267 13.5482 7.89802 12.8815C7.39335 11.7395 7.20985 10.2824 7.16313 8.84477C8.23307 8.46825 9 7.44869 9 6.25C9 4.73122 7.76878 3.5 6.25 3.5ZM5.5 6.25C5.5 5.83579 5.83579 5.5 6.25 5.5C6.66421 5.5 7 5.83579 7 6.25C7 6.66421 6.66421 7 6.25 7C5.83579 7 5.5 6.66421 5.5 6.25ZM24 17H24.0012C25.6575 16.9993 27 15.6565 27 14C27 12.3431 25.6569 11 24 11C22.3431 11 21 12.3431 21 14C21 15.6569 22.3431 17 24 17ZM15.5 6.25C15.5 5.83579 15.8358 5.5 16.25 5.5C16.6642 5.5 17 5.83579 17 6.25C17 6.66421 16.6642 7 16.25 7C15.8358 7 15.5 6.66421 15.5 6.25Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Pcp"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 6.25C9 7.44869 8.23307 8.46825 7.16313 8.84477C7.20985 10.2824 7.39335 11.7395 7.89802 12.8815C8.19267 13.5482 8.57887 14.0651 9.07383 14.4176C9.56062 14.7642 10.2196 15 11.15 15C12.0805 15 12.7394 14.7642 13.2262 14.4176C13.7212 14.0651 14.1074 13.5482 14.402 12.8815C14.8966 11.7622 15.0828 10.3403 15.134 8.93074C13.9118 8.65103 13 7.55699 13 6.25C13 4.73122 14.2312 3.5 15.75 3.5C17.2688 3.5 18.5 4.73122 18.5 6.25C18.5 7.26012 17.9554 8.14303 17.1438 8.62114C17.1101 10.2343 16.9249 12.1205 16.2314 13.6899C15.8316 14.5946 15.2455 15.4349 14.3863 16.0467C13.7179 16.5227 12.925 16.8312 12.0052 16.9477C12.028 18.621 12.1491 20.9228 12.7854 22.9297C13.1666 24.132 13.71 25.1509 14.459 25.8616C15.1838 26.5494 16.153 27 17.5225 27C18.9233 27 19.9055 26.5844 20.6235 25.9803C21.359 25.3614 21.8839 24.4875 22.2505 23.4795C22.9144 21.6543 22.9903 19.5941 22.9989 18.3882C20.9955 17.9331 19.5 16.1412 19.5 14C19.5 11.5147 21.5147 9.5 24 9.5C26.4853 9.5 28.5 11.5147 28.5 14C28.5 16.1419 27.0036 17.9342 24.9992 18.3887C24.9919 19.6373 24.916 22.0021 24.1301 24.1631C23.687 25.3813 22.9974 26.5967 21.9111 27.5107C20.8073 28.4394 19.3604 29 17.5225 29C15.6531 29 14.1877 28.3613 13.0823 27.3124C12.0011 26.2864 11.3187 24.9212 10.8789 23.5341C10.1465 21.2239 10.0244 18.6384 10.0041 16.9037C9.20688 16.7633 8.51063 16.4718 7.91372 16.0467C7.05452 15.4349 6.46849 14.5946 6.06869 13.6899C5.39715 12.1703 5.20222 10.3537 5.15994 8.7755C4.18339 8.35343 3.5 7.38152 3.5 6.25C3.5 4.73122 4.73122 3.5 6.25 3.5C7.76878 3.5 9 4.73122 9 6.25Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Pcp"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM13.3723 14.6336C14.1748 14.3512 14.75 13.5865 14.75 12.6875C14.75 11.5484 13.8266 10.625 12.6875 10.625C11.5484 10.625 10.625 11.5484 10.625 12.6875C10.625 13.5361 11.1375 14.2651 11.87 14.5816C11.9017 15.7652 12.0479 17.1277 12.5515 18.2674C12.8514 18.946 13.2909 19.5762 13.9353 20.035C14.383 20.3538 14.9052 20.5724 15.503 20.6778C15.5183 21.9788 15.6099 23.9179 16.1592 25.6506C16.489 26.6909 17.0008 27.7148 17.8117 28.4843C18.6408 29.2709 19.7399 29.75 21.1419 29.75C22.5203 29.75 23.6055 29.3295 24.4333 28.633C25.248 27.9476 25.7653 27.036 26.0976 26.1223C26.687 24.5016 26.744 22.728 26.7494 21.7915C28.2527 21.4507 29.375 20.1064 29.375 18.5C29.375 16.636 27.864 15.125 26 15.125C24.136 15.125 22.625 16.636 22.625 18.5C22.625 20.1059 23.7466 21.4498 25.2492 21.7912C25.2427 22.6956 25.1858 24.2407 24.6879 25.6096C24.4129 26.3656 24.0193 27.0211 23.4676 27.4852C22.9291 27.9383 22.1925 28.25 21.1419 28.25C20.1148 28.25 19.3879 27.912 18.8442 27.3962C18.2825 26.8631 17.8749 26.099 17.5891 25.1973C17.1118 23.6921 17.021 21.9657 17.0039 20.7107C17.6937 20.6234 18.2884 20.392 18.7897 20.035C19.4342 19.5762 19.8737 18.946 20.1735 18.2674C20.6937 17.0904 20.8326 15.6757 20.8578 14.4659C21.4665 14.1073 21.875 13.4451 21.875 12.6875C21.875 11.5484 20.9516 10.625 19.8125 10.625C18.6734 10.625 17.75 11.5484 17.75 12.6875C17.75 13.6677 18.4338 14.4883 19.3505 14.6981C19.3121 15.7552 19.1725 16.8217 18.8015 17.6611C18.5805 18.1612 18.2909 18.5488 17.9197 18.8132C17.5546 19.0732 17.0603 19.25 16.3625 19.25C15.6647 19.25 15.1705 19.0732 14.8054 18.8132C14.4341 18.5488 14.1445 18.1612 13.9235 17.6611C13.545 16.8046 13.4074 15.7118 13.3723 14.6336Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

PcpIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

PcpIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
