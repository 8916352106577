// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import InputError from "../InputError";
import Label from "../Label";
import { getIn } from "formik";
import NumberFormat from "react-number-format";
import { getAutomationDataAttr } from "../../../utils/automationUtils";

// This component wraps formik input along with error and label components
const formikMobileInput = (props) => {
    const {
        value,
        onChange,
        disabled,
        formEl,
        errors,
        touched,
        classes,
        inputClasses,
        formatType,
        testId,
    } = props;

    const numberFormat = (val) => {
        let mainVal;
        if (
            formEl.name === "dob" ||
            formEl.name === "insurance[0].form.policy_dob" ||
            formEl.name === "insurance[1].form.policy_dob" ||
            formEl.name === "primary_insurance.policy_dob" ||
            formEl.name === "date" ||
            formEl.name === "secondary_insurance.policy_dob" ||
            formatType === "MM/DD/YYYY"
        ) {
            let month = val.substring(0, 2);
            let date = val.substring(2, 4);
            let year = val.substring(4, 8);

            if (month.length === 1) {
                mainVal = `${month}M/DD/YYYY`;
            } else if (month.length === 2) {
                mainVal = `${month}/DD/YYYY`;
            }

            if (month.length && date.length === 1) {
                mainVal = `${month}/${date}D/YYYY`;
            } else if (month.length && date.length === 2) {
                mainVal = `${month}/${date}/YYYY`;
            }

            if (month.length && date.length && year.length === 1) {
                mainVal = `${month}/${date}/${year}YYY`;
            } else if (month.length && date.length && year.length === 2) {
                mainVal = `${month}/${date}/${year}YY`;
            } else if (month.length && date.length && year.length === 3) {
                mainVal = `${month}/${date}/${year}Y`;
            } else if (month.length && date.length && year.length === 4) {
                mainVal = `${month}/${date}/${year}`;
            }
        } else if (formEl.name === "time") {
            let hours = val.substring(0, 2);
            let mins = val.substring(2, 4);

            if (hours.length) {
                mainVal = `${hours}`;
            }
            if (hours.length && mins.length) {
                mainVal = `${hours}:${mins}`;
            }
        } else {
            let firstNumber = val.substring(0, 3);
            let middleNumber = val.substring(3, 6);
            let lastNumber = val.substring(6, 10);
            if (firstNumber.length) {
                mainVal = `(${firstNumber})`;
            }
            if (firstNumber.length && middleNumber.length) {
                mainVal = `(${firstNumber}) ${middleNumber}`;
            }
            if (firstNumber.length && middleNumber.length && lastNumber.length) {
                mainVal = `(${firstNumber}) ${middleNumber}-${lastNumber}`;
            }
        }
        return mainVal;
    };

    let errorData = getIn(errors, formEl.name);
    let isTouched = getIn(touched, formEl.name);
    const className = `Input ${errorData && isTouched ? "is-invalid" : ""} ${
        inputClasses ? inputClasses : ""
    }`;

    return (
        <div className={"FormGroup"}>
            {!formEl.elementConfig.hideTopLabel && (
                <Label classes={formEl.elementConfig.labelClasses}>
                    {formEl.elementConfig.label}
                </Label>
            )}

            <NumberFormat
                type={formEl.elementConfig.type}
                disabled={disabled}
                onValueChange={(val) => onChange(val)}
                format={numberFormat}
                elementtype={formEl.elementType}
                value={value}
                options={formEl.elementConfig.options}
                className={className}
                placeholder={formEl.elementConfig.placeholder}
                {...getAutomationDataAttr(testId)}
            />
            {errorData && isTouched ? <InputError classes={classes}>{errorData}</InputError> : null}
        </div>
    );
};

export default formikMobileInput;
