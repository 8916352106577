// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { cloneDeep } from "lodash";
import { api } from "../../../APIRequests";
import { Form, Formik } from "formik";
import FormikInput from "../../UI/FormikInput";
import FormikInputFormat from "../../UI/formikMobileInput";
import Button from "../../UI/Button";
import CardSecondary from "../../UI/Card/CardSecondary";
import RatingControls from "../../Common/RatingScaleView/RatingControls";
import { connect } from "react-redux";
import {
    A5_COMPLETE_INITIAL_VALUES,
    A5_COMPLETE_SCHEMA,
    A5_COMPLETED_VALIDATION,
} from "./a5CompleteSchema";
import Input from "../../UI/Input";
import images from "../../../utils/images";
import WistiaEmbed from "./WistiaEmbed";
import { Markup } from "interweave";
import { getQueryParams } from "../../../utils/filters";
import { withRouter } from "react-router-dom";
import Header from "./A5CompletedHeader";
import SuicidePreventionPopUp from "./SuicidePreventionPopUp";
import { CustomForm } from "../../../component-library/CustomForm";

class A5Completed extends Component {
    state = {
        isPublic: getQueryParams("public", this.props.location.search.trim()),
        schema: cloneDeep(A5_COMPLETE_SCHEMA),
        disabled: false,
        inCrisis: false,
        practiceName: null,
        patientReport: {
            crisis_risk: {
                answer: 0,
                total_sev: 0,
                content: "",
                action: "",
            },
            psychiatric: {
                answer: 0,
                total_sev: 0,
                content: "",
                action: "",
            },
            distress: {
                answer: 0,
                total_sev: 0,
                content: "",
                action: "",
            },
            substance_use: {
                answer: 0,
                total_sev: 0,
                content: "",
                action: "",
            },
            urgency: false,
        },
    };

    componentDidMount = async () => {
        const queryParams = { screeningId: this.props.a5Details.id };
        const headers = { dob: this.props.a5Details["dob"] };
        const options = { params: { showLoader: true } };

        let practiceName = getQueryParams("practiceName", this.props.location.search.trim());
        if (!practiceName) {
            const response = await api.a5Screening.get_practice_name({
                queryParams: { practiceId: this.props.a5Details.pcp_practice_id },
                options,
            });
            practiceName = response.message;
        } else {
            practiceName = practiceName.split("_").join(" ");
        }
        this.setState({ practiceName });

        if (this.props.a5Details && this.props.a5Details.status !== "completed") {
            queryParams["genReport"] =
                this.props.a5Details.status !== "completed" && !this.state.isPublic;
            const response = await api.a5Screening.complete_a5_screening({
                queryParams,
                options,
                headers,
            });
            const patientReport = response["patient_report"];
            this.setState({ patientReport: patientReport, inCrisis: patientReport.urgency });
        }

        const providerNames = await api.a5Screening.get_provider_names({
            queryParams,
            options,
            headers,
        });
        const providerOptions = providerNames.map((provider) => {
            return { value: provider, display: provider };
        });
        const schema = [...this.state.schema];
        for (let el of schema) {
            if (el.name === "pcp_name") {
                el.elementConfig.options = providerOptions;
            }
        }

        this.setState({ schema: schema });
    };

    formHandler = async (formData, actions) => {
        actions.setSubmitting(false);
        try {
            const data = { ...formData };
            const queryParams = { screeningId: this.props.a5Details.id };
            const headers = { dob: this.props.a5Details["dob"] };
            const options = { params: { showLoader: true } };
            await api.a5Screening.notify_provider({ queryParams, options, headers, data });
            this.setState({ disabled: true });
        } catch (e) {
            console.log(e);
        }
    };

    renderForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 ">
                    {!this.state.disabled && (
                        <div className="row">
                            {this.state.schema.map((field) => {
                                if (field.name === "mobile") {
                                    return (
                                        <div key={field.name} className="col-12 col-lg-6">
                                            <FormikInputFormat
                                                value={formikProps && formikProps.values.mobile}
                                                onChange={async (val) => {
                                                    await formikProps.setFieldValue(
                                                        "mobile",
                                                        val.value,
                                                    );
                                                    formikProps.setFieldTouched("mobile");
                                                }}
                                                formEl={field}
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                            />
                                        </div>
                                    );
                                } else {
                                    const classes =
                                        field.name !== "email" && field.name !== "address.street"
                                            ? "col-lg-6"
                                            : "";
                                    return (
                                        <div key={field.name} className={"col-12 " + classes}>
                                            <FormikInput
                                                formEl={field}
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                            />
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    )}
                </div>
                <div className="mt-3">
                    <Button
                        type="submit"
                        disabled={this.state.disabled ? "disabled" : null}
                        className="Btn Btn--pri mx-auto"
                        style={{ maxHeight: 48, padding: "10px 20px 10px 20px" }}>
                        {this.state.disabled ? "Sent!" : "Send Results"}
                    </Button>
                    <div className="fs-16 mt-2">
                        By completing this form and clicking “Send Results,” you consent to sending
                        your A5 Screening results directly to your provider through the secure
                        aptihealth platform.
                    </div>
                </div>
            </Form>
        );
    };

    render() {
        return (
            <div>
                <Header name={this.state.practiceName} isPublic={this.state.isPublic} />
                {this.state.inCrisis && (
                    <SuicidePreventionPopUp
                        onCloseHandler={() => this.setState({ inCrisis: false })}
                    />
                )}
                <CardSecondary
                    className="row CompletedScreenTitleCard-Header"
                    style={{ marginLeft: 0 }}>
                    <div
                        className="col-12 d-lg-none d-inline-block summary-check-wpr"
                        style={{ padding: 0 }}>
                        <img
                            className="img-fluid summary-check"
                            src={images("./icons/complete-check-mobile.svg")}
                        />
                    </div>
                    <div className="col-lg-8 col-12 py-3">
                        <div className="p-2 fs-22 summary-header">
                            <b>Your Mental Health Screening Results</b>
                        </div>
                        <div className="p-2" style={{ fontWeight: 600 }}>
                            Congratulations on completing the aptihealth A5 Screening! This
                            screening provides you with a personalized report to help you understand
                            your emotional health and how mental health symptoms may be impacting
                            your life.
                        </div>
                        <div className="p-2" style={{ fontWeight: 600 }}>
                            If you are experiencing problems or your summary report recommends
                            therapy in one of the four areas, please complete the form below to
                            share your results with your healthcare provider. By sharing your
                            results, your healthcare provider will help quickly connect you to
                            personalized, goal-oriented therapy to help you feel better.
                        </div>
                    </div>
                    <div className="col-lg-4 d-lg-inline-block d-none" style={{ padding: 0 }}>
                        <img
                            className="img-fluid summary-check"
                            src={images("./icons/complete-check.svg")}
                        />
                    </div>
                </CardSecondary>
                <CardSecondary
                    className="row p-3 my-2 CompletedScreenTitleCard"
                    style={{ marginLeft: 0 }}>
                    <div className="p-2 fs-22 summary-header">
                        <b>About Your Summary</b>
                    </div>
                    <div className="p-2">
                        Your A5 Screening Summary and recommended actions are highlighted below. The
                        four sections reflect thoughts about harming self or others, specific
                        emotional difficulties, distress you may be feeling, and possible problems
                        with substances used. The scale is from 0 to 5, with 5 reflecting severe
                        difficulties in your life. After you review your summary report, please
                        complete the form below to send your results to your healthcare provider.
                    </div>
                </CardSecondary>
                <div className={"row"} style={{ marginLeft: 0 }}>
                    <div className={"col-lg-6 col-12"}>
                        <div className="pt-2 fs-22 summary-header">
                            <b>Hurt Myself/Others</b>
                        </div>
                        <CardSecondary className="p-3 my-2">
                            <div className="p-4">
                                <RatingControls
                                    id={1}
                                    rating={this.state.patientReport.crisis_risk.answer}
                                    readOnly
                                    severityOnTop={true}
                                />
                            </div>
                            <div className="p-3">
                                <div>
                                    <b>Summary</b>
                                </div>
                                <Markup content={this.state.patientReport.crisis_risk.content} />
                                <div className={"mt-2"}>
                                    <b>Action</b>
                                </div>
                                <Markup content={this.state.patientReport.crisis_risk.action} />
                            </div>
                        </CardSecondary>
                    </div>
                    <div className={"col-lg-6 col-12"}>
                        <div className="pt-2 fs-22 summary-header">
                            <b>Psychiatric Severity Score</b>
                        </div>
                        <CardSecondary className="p-3 my-2">
                            <div className="p-4">
                                <RatingControls
                                    id={1}
                                    rating={this.state.patientReport.psychiatric.answer}
                                    readOnly
                                    severityOnTop={true}
                                />
                            </div>
                            <div>
                                <div>
                                    <b>Summary</b>
                                </div>
                                <Markup content={this.state.patientReport.psychiatric.content} />
                                <div className={"mt-2"}>
                                    <b>Action</b>
                                </div>
                                <Markup content={this.state.patientReport.psychiatric.action} />
                            </div>
                        </CardSecondary>
                    </div>
                    <div className={"col-lg-6 col-12"}>
                        <div className="pt-2 fs-22 summary-header">
                            <b>Distress Score</b>
                        </div>
                        <CardSecondary className="p-3 my-2">
                            <div className="p-4">
                                <RatingControls
                                    id={1}
                                    rating={this.state.patientReport.distress.answer}
                                    readOnly
                                    severityOnTop={true}
                                />
                            </div>
                            <div>
                                <div>
                                    <b>Summary</b>
                                </div>
                                <Markup content={this.state.patientReport.distress.content} />
                                <div className={"mt-2"}>
                                    <b>Action</b>
                                </div>
                                <Markup content={this.state.patientReport.distress.action} />
                            </div>
                        </CardSecondary>
                    </div>
                    <div className={"col-lg-6 col-12"}>
                        <div className="pt-2 fs-22 summary-header">
                            <b>Substance Use</b>
                        </div>
                        <CardSecondary className="p-3 my-2">
                            <div className="p-4">
                                <RatingControls
                                    id={1}
                                    rating={this.state.patientReport.substance_use.answer}
                                    readOnly
                                    severityOnTop={true}
                                />
                            </div>
                            <div>
                                <div>
                                    <b>Summary</b>
                                </div>
                                <Markup content={this.state.patientReport.substance_use.content} />
                                <div className={"mt-2"}>
                                    <b>Action</b>
                                </div>
                                <Markup content={this.state.patientReport.substance_use.action} />
                            </div>
                        </CardSecondary>
                    </div>
                </div>
                {this.state.isPublic && (
                    <div className={"row"} style={{ marginLeft: 0 }} id={"shareResults"}>
                        <CardSecondary className="col-12 p-3 my-2 CompletedScreenTitleCard">
                            <div className={"row"}>
                                <div className={"col-lg-6 col-12 p-3"}>
                                    <div className="pt-2 fs-22 pb-3 summary-header">
                                        <b>Share These Results With Your Healthcare Provider:</b>
                                    </div>
                                    <div className="p-2">
                                        To share the results of your mental health screening with
                                        your healthcare provider through the secure aptihealth
                                        platform, please complete the form below.
                                    </div>
                                    <CustomForm
                                        initialValues={cloneDeep(A5_COMPLETE_INITIAL_VALUES)}
                                        validationSchema={A5_COMPLETED_VALIDATION}
                                        onSubmit={this.formHandler}
                                        render={this.renderForm}
                                    />
                                </div>
                                <div className={"col-lg-6 col-12 p-3"}>
                                    <div className="pt-2">
                                        <div className={"fs-22 summary-header"}>
                                            <b>Hannah’s Inspiring Journey</b>
                                        </div>
                                        Learn about Hannah’s inspiring journey to better mental
                                        health.
                                        <WistiaEmbed hashedId="35ae79eexf" />
                                    </div>
                                </div>
                            </div>
                        </CardSecondary>
                    </div>
                )}
                <CardSecondary className="col-12 p-3 my-2 CompletedScreenTitleCard">
                    <div className="p-2 fs-22 summary-header">
                        <b>Questions?</b>
                    </div>
                    <div className="p-2">Contact us at (888) 454-3827.</div>
                </CardSecondary>
                <CardSecondary className="col-12 p-3 my-2 CompletedScreenTitleCard">
                    <div className="p-2 fs-22 summary-header">
                        <b>About aptihealth:</b>
                    </div>
                    <div className="p-2">
                        We’re on a mission to revolutionize behavioral healthcare to ensure people
                        who need it have access to personalized care, without delay. aptihealth
                        quickly connects you with personalized care to make positive changes and
                        improve your life.
                    </div>
                </CardSecondary>
                <CardSecondary className="col-12 p-3 my-2 CompletedScreenTitleCard">
                    <div className="p-2 fs-22 summary-header">
                        <b>We’ll support you with:</b>
                    </div>
                    <div className="p-2">
                        <ul className="mx-3 mb-3" style={{ listStyle: "unset" }}>
                            <li style={{ listStyleType: "unset" }}>
                                Expert care from a therapist matched to your specific needs
                            </li>
                            <li style={{ listStyleType: "unset" }}>
                                Convenient, personalized therapy focused on your goals
                            </li>
                            <li style={{ listStyleType: "unset" }}>
                                Easy-to-schedule online therapy appointments
                            </li>
                            <li style={{ listStyleType: "unset" }}>
                                A commitment to feeling better within 90 days
                            </li>
                            <li style={{ listStyleType: "unset" }}>
                                Treatment coordinated with your healthcare provider
                            </li>
                            <li style={{ listStyleType: "unset" }}>
                                A dedicated care team to help you every step of the way
                            </li>
                        </ul>
                    </div>
                </CardSecondary>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        a5Details: state.a5Screening.a5Details,
    };
};

export default connect(mapStateToProps, {})(withRouter(A5Completed));
