import React from "react";
import PropTypes from "prop-types";
import { List, ListItem, ListTitleBar, TitleBarItem } from "../../../UI/ListView";
import { capitalizeFirstLetter } from "../../../../component-library/utils";
import moment from "moment";
import momentTZ from "moment-timezone";

export const ConsentListItems = ({ consentData }) => {
    let sortedConsentData = consentData
        ? consentData.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
        : [];

    const buildListItemData = (consent) => {
        return [
            `${capitalizeFirstLetter(
                consent.consent_type.toLowerCase(),
            )} consent to treatment via Telehealth`,
            moment.utc(consent.timestamp).tz(momentTZ.tz.guess()).format("MM/DD/YYYY"),
            consent?.consent_asked_by_name,
        ];
    };

    const desktopListItem = (consent) => {
        const listItemData = buildListItemData(consent);
        return (
            <div className="row no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between">
                <span className="col-6">{listItemData[0]}</span>
                <span className="col-3">{listItemData[1]}</span>
                <span className="col-3">{listItemData[2]}</span>
            </div>
        );
    };

    const mobileListItem = (consent) => {
        const listItemData = buildListItemData(consent);
        return (
            <div className="d-flex d-lg-none justify-content-between">
                <div className="d-flex fs-14 flex-column" style={{ wordWrap: "break-word" }}>
                    <p>{listItemData[0]}</p>
                    <p>{listItemData[1]}</p>
                    <p>{listItemData[2]}</p>
                </div>
            </div>
        );
    };

    return (
        <>
            <ListTitleBar>
                <TitleBarItem classes="col-6" text="" />
                <TitleBarItem classes="col-3" text="Date obtained" />
                <TitleBarItem classes="col-3" text="Recorded by" />
            </ListTitleBar>
            <List>
                {sortedConsentData.map((consent) => {
                    return (
                        <ListItem key={consent.sort_key}>
                            {desktopListItem(consent)}
                            {mobileListItem(consent)}
                        </ListItem>
                    );
                })}
            </List>
        </>
    );
};

ConsentListItems.propTypes = {
    consentData: PropTypes.array,
};
