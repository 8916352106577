// Copyright aptihealth, inc. 2021 All Rights Reserved

import React from "react";
import "../styles.scss";
import images from "../../../../utils/images";
import _ from "lodash";
import { GUARDIANSHIP_PROGRAM_TYPE } from "../../../Common/InterestInGuardianship";

const FamilyMembersWidget = (props) => {
    const { profile } = props;

    const associated_wards = _.get(profile, "enriched_associated_wards", []);
    const associated_guardians = _.get(profile, "enriched_associated_guardians", []);
    const familyMembers = [...associated_wards, ...associated_guardians];

    const displayAddChild = () => {
        return associated_wards.length > 0;
    };

    const renderFamilyMembers = (familyMembers) => {
        return familyMembers.map((familyMember, i) => {
            let borderClass = "border-top";
            if (i === 0) {
                borderClass = "";
            }
            return (
                <div className={`d-flex justify-content-between py-3 ${borderClass}`}>
                    <div className="d-flex">
                        <img
                            src={images("./icons/family-member-icon.svg")}
                            alt="Icon"
                            className={"pr-4"}
                        />
                        <div data-private={"true"}>
                            <div className={"fw-bold fs-18 txt-navy"}>
                                {familyMember.first_name} {familyMember.last_name}
                            </div>
                            <div className={"fs-16 txt-gry"}>{familyMember.email}</div>
                        </div>
                    </div>
                    <div className="d-md-block d-none">
                        <div className={"fw-bold fs-16 txt-navy"}>For Support Contact</div>
                        <div className={"fs-16 txt-gry"}>(518) 238-6028</div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className={"mb-3 pb-md-3 pb-0"}>
            <div className={"d-flex justify-content-between pb-2"}>
                <div className={"fs-22 txt-gry fw-bold"}>Family Members</div>
                {displayAddChild() && (
                    <div
                        className={"d-flex pointer align-items-center"}
                        onClick={() => props.history.push("/app/teens-and-kids/program-details")}>
                        <img
                            src={images("./icons/plus-circle-icon-2.png")}
                            alt="Icon"
                            style={{ width: "16px", height: "16px" }}
                        />
                        <div className={"txt-gry fs-16 fw-bold d-flex align-items-center ml-1"}>
                            Add Child
                        </div>
                    </div>
                )}
            </div>
            <div className={"px-4 dashboard-widget-card"}>{renderFamilyMembers(familyMembers)}</div>
        </div>
    );
};

export default FamilyMembersWidget;
