import _ from "lodash";
import * as yup from "yup";
import { XSS_REGEX } from "../../../constants/security";
import { PROVIDER_STATUS } from "../../../constants/provider";

export const PROVIDER_INACTIVATION_REASON_OTHER_TEXT_ID = "PROVIDER_INACTIVATION_REASON.OTHER";
export const PROVIDER_INACTIVATION_REASON_OTHER_TEXT_PROMPT = "Other (Specify...)";
export const PROVIDER_INACTIVATION_REASON_OTHER_TEXT_CHARACTER_LIMIT = 250;

export const PROVIDER_STATUS_OPTIONS = [
    { label: "Active", value: PROVIDER_STATUS.ACTIVE },
    { label: "Inactive", value: PROVIDER_STATUS.INACTIVE },
];

export const PROVIDER_STATUS_DETAILS_SCHEMA = yup.object().shape({
    status: yup.string().required("Provider Status is required"),
    inactivation_reason_text_id: yup.string().when("status", {
        is: PROVIDER_STATUS.INACTIVE,
        then: yup.string().required("Inactivation Reason is required"),
    }),
    inactivation_reason_other_text: yup.string().when("inactivation_reason_text_id", {
        is: PROVIDER_INACTIVATION_REASON_OTHER_TEXT_ID,
        then: yup
            .string()
            .test("special characters", "Special characters are not allowed", (value) => {
                return !XSS_REGEX.test(value);
            })
            .max(
                PROVIDER_INACTIVATION_REASON_OTHER_TEXT_CHARACTER_LIMIT,
                `Limit text to ${PROVIDER_INACTIVATION_REASON_OTHER_TEXT_CHARACTER_LIMIT} characters`,
            )
            .required(`${PROVIDER_INACTIVATION_REASON_OTHER_TEXT_PROMPT} is required`),
    }),
});
