import { httpAuth } from "../utils/axios";

const searchRequests = {
    search_omni: function ({ data, options }) {
        let conf = {
            method: "post",
            url: "/v1/search/omni",
            ...options,
            data,
        };
        return httpAuth(conf);
    },
};

export default searchRequests;
