import React from "react";
import { CalendarEventBody, CalendarEventTime, CalendarEventTitle } from "./CalendarEvent";
import "./styles.scss";

export const BusyEvent = ({ position, startTime, endTime, details }) => {
    return (
        <CalendarEventBody className="PatientSession__body" position={position} details={[details]}>
            <CalendarEventTitle className={"PatientSession__title"} title={"Busy"} />
            <CalendarEventTime startTime={startTime} endTime={endTime} />
        </CalendarEventBody>
    );
};
