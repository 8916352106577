import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { ButtonTypes, Link, LinkTypes, createClassNameString } from "../../../component-library/";
import {
    transformValueToDropDown,
    DISPLAY_DAYS,
    DISPLAY_WEEKS_MAP,
    numberOfOccurrencesArray,
} from "./calendarAppointmentPickerUtils";
import { TextTypes } from "../../../component-library/";
import { Text } from "../../../component-library/";
import { FormField } from "../../../component-library/";
import { Dropdown } from "../../../component-library/";
import { Toggle } from "../../../component-library/";
import { Button } from "../../../component-library/";
import { Input } from "../../../component-library/";
import { Image } from "../../../component-library/";
import { AcuityIndexTable } from "../../../component-library/AcuityIndexTable";

import "./styles.scss";
import {
    addAppointment,
    updateAppointmentEventType,
    updateAppointmentFrequencyDays,
    updateAppointmentFrequencyWeeks,
    updateAppointmentIsReoccurring,
    updateAppointmentNumberOfOccurrences,
    updateAppointmentPatient,
    fetchEventTypeOptions,
    resetAppointmentSelections,
    closeDrawer,
    editAppointment,
    checkEditUntouched,
    updateAppointmentLabel,
    isOnBehalfOfProviderPatientScheduling,
} from "../../../redux/actions/calendar";
import { connect, useDispatch } from "react-redux";
import { getDefaultEventTypeByUserType } from "../../../utils/calendar/util";
import { CalendarAppointmentDateTimePicker } from "./CalendarAppointmentDateTimePicker/CalendarAppointmentDateTimePicker";
import { AVAILABLE, AWAY, isSpecialEvent } from "../../../constants/event";
import { isUserAdmin } from "../../../redux/actions/auth";
import { SelectableList } from "./SelectableList";
import { ACUITY_INDEX } from "../../../constants/acuity";

const CalendarAppointmentPicker = ({ className, menuPosition, ...props }) => {
    const patientDropdownOptions = props.calendar.patientDropdownOptions;
    const eventType = props.calendar.appointmentSelections.eventType;
    const reoccurring = props.calendar.appointmentSelections.seriesData.isReoccurring;
    const frequencyDays = props.calendar.appointmentSelections.seriesData.frequencyDays;
    const frequencyWeeks = props.calendar.appointmentSelections.seriesData.frequencyWeeks;
    const numberOfOccurrences = props.calendar.appointmentSelections.seriesData.numberOfOccurrences;
    const callId = props.calendar.appointmentSelections.callId; // If callId present appointment is being edited
    const errors = props.calendar.appointmentSelectionErrors;
    const providerName = props?.calendar?.provider?.name;
    const providerId = props?.calendar?.provider?.providerId;
    const acuityScore = props.calendar.appointmentSelections.acuityScore;
    const label = props.calendar.appointmentSelections?.label;

    const [showAcuityIndexTable, setShowAcuityIndexTable] = useState(false);

    const awayAppointmentTypeCondition = eventType && eventType === AWAY;
    const isUserAdminCondition = isUserAdmin();
    const availableAppointmentTypeCondition = eventType && eventType === AVAILABLE;
    const showInvitePatient = !awayAppointmentTypeCondition && !availableAppointmentTypeCondition;
    const showWorkingHourSuggestion = availableAppointmentTypeCondition;
    const dispatch = useDispatch();
    const isSchedulingOnBehalfOf = dispatch(isOnBehalfOfProviderPatientScheduling());

    useEffect(() => {
        props.checkEditUntouched();
    }, [props.calendar?.appointmentSelections]);

    useEffect(() => {
        if (!callId) {
            const defaultEventType = getDefaultEventTypeByUserType(
                props.calendar?.provider?.providerType,
            );
            props.updateAppointmentEventType(defaultEventType);
        }
    }, [props.calendar?.provider?.providerType]);

    const onAddClick = () => {
        if (callId) {
            const previousEvent = props.calendar.selectedEvent;
            props.editAppointment(previousEvent);
        } else {
            props.addAppointment();
        }
    };

    const onCancelClick = () => {
        props.resetAppointmentSelections();
        props.closeDrawer();
    };

    return (
        <div
            className={createClassNameString([
                "apti-CalendarAppointmentPicker px-4 pb-3",
                className,
            ])}>
            <div>
                <FormField label="Select Appointment Type" errorMessage={errors.eventType}>
                    <Dropdown
                        isDisabled={
                            callId !== null &&
                            isSpecialEvent(props.calendar?.selectedEvent?.event_type)
                        }
                        error={errors.eventType !== null}
                        name={"appointmentType"}
                        placeholder={"Select Appointment Type"}
                        value={transformValueToDropDown(props.calendar.eventTypeOptions, eventType)}
                        options={props.calendar.eventTypeOptions}
                        onChange={(value) => {
                            props.updateAppointmentEventType(value.target.value);
                        }}
                        menuPosition={menuPosition}
                    />
                </FormField>
                {awayAppointmentTypeCondition && (
                    <FormField
                        className=""
                        label={
                            <>
                                Label <span className={"fw-normal"}>(Optional)</span>
                            </>
                        }>
                        <Input
                            placeholder="Out Of Office"
                            name="awayLabel"
                            value={label}
                            error={errors.label}
                            onChange={(e) => props.updateAppointmentLabel(e.target.value)}
                        />
                    </FormField>
                )}
                {showInvitePatient && (
                    <>
                        <FormField label="Invite Patient" errorMessage={errors.patientId}>
                            <Dropdown
                                isDisabled={callId !== null || isSchedulingOnBehalfOf}
                                error={errors.patientId !== null}
                                name={"invitePatient"}
                                value={transformValueToDropDown(
                                    patientDropdownOptions,
                                    props.calendar.appointmentSelections.patientId,
                                )}
                                options={patientDropdownOptions}
                                placeholder={"Invite Patient"}
                                onChange={(value) => {
                                    const patient = props.calendar.patientMap[value.target.value];
                                    props.fetchEventTypeOptions(props.calendar.provider, patient);
                                    props.updateAppointmentPatient(patient);
                                }}
                                menuPlacement={window.innerWidth > 680 ? "bottom" : "top"}
                            />
                        </FormField>
                        {acuityScore && (
                            <div className={"apti-CalendarAppointmentPicker--acuityContainer"}>
                                <div
                                    className={"apti-CalendarAppointmentPicker--acuityText"}
                                    onClick={() => setShowAcuityIndexTable(!showAcuityIndexTable)}>
                                    <span>
                                        Acuity Index: {acuityScore}{" "}
                                        <span>{ACUITY_INDEX[acuityScore]?.label}</span>
                                    </span>
                                    <Image
                                        className={`apti-CalendarAppointmentPicker--acuityChevron ${
                                            showAcuityIndexTable && "chevron-flip"
                                        }`}
                                        src={"icons/chevron_up.svg"}
                                        alt={"chevron"}
                                    />
                                </div>
                                {showAcuityIndexTable && (
                                    <AcuityIndexTable className={"mt-3 mb-3"} />
                                )}
                            </div>
                        )}
                    </>
                )}
                <CalendarAppointmentDateTimePicker menuPosition={menuPosition} />
            </div>
            <div>
                <div className="d-flex py-2">
                    <Toggle
                        className={"mr-2"}
                        checked={reoccurring}
                        onClick={() => {
                            props.updateAppointmentIsReoccurring(!reoccurring);
                        }}
                    />
                    <Text
                        className="apti-FormField--label d-flex align-items-center"
                        type={TextTypes.label}>
                        Reoccurring
                    </Text>
                </div>
                {reoccurring && (
                    <div className="apti-CalendarAppointmentPicker--recurring-container p-3 mb-2">
                        <div className="apti-CalendarAppointmentPicker--wrapper-container">
                            <FormField
                                className="apti-CalendarAppointmentPicker--field apti-CalendarAppointmentPicker--wrapped-fields"
                                label="Repeat Every">
                                <Dropdown
                                    name="frequencyWeeks"
                                    options={DISPLAY_WEEKS_MAP}
                                    value={transformValueToDropDown(
                                        DISPLAY_WEEKS_MAP,
                                        frequencyWeeks,
                                    )}
                                    placeholder={"Repeat Every"}
                                    onChange={(value) => {
                                        const frequencyWeeks = DISPLAY_WEEKS_MAP.find(
                                            (o) => o.value === value.target.value,
                                        );
                                        props.updateAppointmentFrequencyWeeks(frequencyWeeks.value);
                                    }}
                                />
                            </FormField>
                            <FormField
                                className="apti-CalendarAppointmentPicker--field apti-CalendarAppointmentPicker--wrapped-fields"
                                label="For">
                                <Dropdown
                                    name={"numberOfOccurrences"}
                                    placeholder={"For"}
                                    onChange={(value) => {
                                        const numberOfOccurrences = numberOfOccurrencesArray.find(
                                            (o) => o.value === value.target.value,
                                        );
                                        props.updateAppointmentNumberOfOccurrences(
                                            numberOfOccurrences.value,
                                        );
                                    }}
                                    options={numberOfOccurrencesArray}
                                    value={transformValueToDropDown(
                                        numberOfOccurrencesArray,
                                        numberOfOccurrences,
                                    )}
                                />
                            </FormField>
                        </div>
                        <div
                            style={{ gap: 4 }}
                            className="d-flex mx-0 w-100 flex-wrap justify-content-between">
                            <SelectableList
                                updateSelectedItems={(newState) => {
                                    props.updateAppointmentFrequencyDays(newState);
                                }}
                                currentlySelectedItems={frequencyDays}
                                displayList={DISPLAY_DAYS}></SelectableList>
                        </div>
                        {errors.seriesData && (
                            <p className={"fs-10 schedule-error"}>{errors.seriesData}</p>
                        )}
                    </div>
                )}
                {showWorkingHourSuggestion && (
                    <>
                        {isUserAdminCondition && (
                            <p className="apti-CalendarAppointmentPicker--available-text">
                                Edit{" "}
                                <Link type={LinkTypes.linkV2} href={`/app/profile/${providerId}`}>
                                    working hours
                                </Link>{" "}
                                on {providerName}'s profile for permanently recurring availability
                                slots.
                            </p>
                        )}
                        {!isUserAdminCondition && (
                            <p className="apti-CalendarAppointmentPicker--available-text">
                                Edit{" "}
                                <Link type={LinkTypes.linkV2} href={"/app/profile"}>
                                    working hours
                                </Link>{" "}
                                on your profile for permanently recurring availability slots.
                            </p>
                        )}
                    </>
                )}
            </div>
            <div style={{ gap: 15 }} className={"d-flex justify-content-center"}>
                <Button
                    buttonType={ButtonTypes.primaryOutlineV2}
                    type={ButtonTypes.primaryOutline}
                    onClick={onCancelClick}
                    className="apti-CalendarAppointmentPicker--button">
                    Cancel
                </Button>
                <Button
                    buttonType={ButtonTypes.primaryV2}
                    onClick={onAddClick}
                    className="apti-CalendarAppointmentPicker--button">
                    {callId ? "Edit" : "Add"}
                </Button>
            </div>
        </div>
    );
};

CalendarAppointmentPicker.propTypes = {
    className: PropTypes.string,

    onAdd: PropTypes.func,
    onCancel: PropTypes.func,
};

CalendarAppointmentPicker.defaultProps = {
    className: undefined,
};

const mapStateToProps = (state) => {
    return {
        calendar: state.calendar,
    };
};

const mapDispatchToProps = {
    addAppointment,
    editAppointment,
    updateAppointmentEventType,
    updateAppointmentPatient,
    updateAppointmentIsReoccurring,
    updateAppointmentFrequencyDays,
    updateAppointmentFrequencyWeeks,
    updateAppointmentNumberOfOccurrences,
    fetchEventTypeOptions,
    resetAppointmentSelections,
    closeDrawer,
    checkEditUntouched,
    updateAppointmentLabel,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarAppointmentPicker);
