// Copyright aptihealth, inc. 2019 All Rights Reserved

/**
 * This file contains the structure for the table's header which contains the
 * title of each column.
 * The `colValue` is the bootstrap's grid column `class`
 */
export const ADMIN = "ADMIN";
export const PROVIDER = "PROVIDER";
export const ACTIVE = "Active";
export const PENDING = "Pending";
export const ARCHIVED = "Archived";

export const ACTIVE_CONF_PROVIDER = [
    { colValue: "col-2", listTitleText: "Patient's Name", center: false },
    { colValue: "col-1", listTitleText: "Acuity Index", center: false },
    { colValue: "col-3", listTitleText: "Email address", center: false },
    { colValue: "col-1", listTitleText: "Mobile number", center: true },
    { colValue: "col-1", listTitleText: "Date Invited", center: true },
    { colValue: "col-1", listTitleText: "DI completed?", center: true },
    { colValue: "col-2", listTitleText: "Status", center: true },
    { colValue: "col-1", listTitleText: "", center: true },
];

export const PENDING_CONF_PROVIDER = [
    { colValue: "col-2", listTitleText: "Patient's Name" },
    { colValue: "col-3", listTitleText: "Email Address" },
    { colValue: "col-2", listTitleText: "Mobile Number" },
    { colValue: "col-1", listTitleText: "Date Invited" },
    { colValue: "col-1", listTitleText: "Contacted" },
    { colValue: "col-1", listTitleText: "Action" },
    { colValue: "col-1", listTitleText: "" },
    { colValue: "col-1", listTitleText: "" },
];

export const ARCHIVED_CONF_PROVIDER = [
    { colValue: "col-2", listTitleText: "Patient's Name" },
    { colValue: "col-2", listTitleText: "Email Address" },
    { colValue: "col-2", listTitleText: "Mobile Number" },
    { colValue: "col-1", listTitleText: "Date Invited" },
    { colValue: "col-1", listTitleText: "Archived Reason" },
    { colValue: "col-2", listTitleText: "Archived Reason Context" },
    { colValue: "col-1", listTitleText: "" },
    { colValue: "col-1", listTitleText: "" },
];

export const ACTIVE_CONF_ADMIN = ACTIVE_CONF_PROVIDER;

export const PENDING_CONF_ADMIN = PENDING_CONF_PROVIDER;

export const ARCHIVED_CONF_ADMIN = ARCHIVED_CONF_PROVIDER;
