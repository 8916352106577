// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as actionTypes from "../actions/actionTypes";

let initialState = {
    patientCardLoaded: false,
    cardToken: undefined,
    lastFourDigits: undefined,
    expirationDate: undefined,
    username: undefined,
    showPaywall: undefined,
    isSoftPaywall: undefined,
    updatePaymentRequired: undefined,
    lastFailedChargeDate: undefined,
};

export const patientCardInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_PATIENT_CARD_INFO:
            return {
                ...state,
                ...action.payload,
            };
        case actionTypes.UPDATE_PATIENT_PAYWALL_INFO:
            return {
                ...state,
                ...action.payload,
            };
        case actionTypes.LOG_OUT:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};
