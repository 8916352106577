// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as actionTypes from "../actions/actionTypes";

let initialState = {
    practiceId: undefined,
    reports: [],
};

const logout = (state, action) => {
    return {
        ...initialState,
    };
};

export const insuranceReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_INSURANCE_LIST:
            return {
                ...state,
                ...action.payload,
            };
        case actionTypes.LOG_OUT:
            return logout(state, action);
        default:
            return state;
    }
};
