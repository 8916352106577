import React from "react";
import PropTypes from "prop-types";

import { createClassNameString, generateRandomId } from "../utils";

import { Image } from "../Image";
import { Popover } from "../Popover";
import { Portal } from "../Portal";

import "./styles.scss";

export const Info = ({ children, className, position, ...props }) => {
    const toolTipId = generateRandomId() + "-tooltip";

    return (
        <div className={createClassNameString(["apti-Info", position, className])} {...props}>
            <Image
                src="icons/info_button.svg"
                alt="info"
                data-for={toolTipId}
                data-tip="info-tooltip"
            />
            <Portal>
                <Popover toolTipId={toolTipId} className={"apti-Info--popover"} position={position}>
                    {children}
                </Popover>
            </Portal>
        </div>
    );
};

Info.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    className: PropTypes.string,
    position: PropTypes.oneOf(["left", "right", "top", "bottom"]),
};

Info.defaultProps = {
    children: <></>,
    className: undefined,
    position: "left",
};
