// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { api } from "../../../../APIRequests";
import { ICON_SIZES, ICON_TYPES } from "../../../../constants/icons";
import { getProviderProfile, isAuthorized, logOut } from "../../../../redux/actions/auth";
import { http } from "../../../../utils/axios";
import { usePopUpVisibility } from "../../../../utils/hooks";
import images from "../../../../utils/images";
import { ChevronDownIcon } from "../../../Icons/ChevronDownIcon";
import { ExitIcon } from "../../../Icons/ExitIcon";
import { SettingsIcon } from "../../../Icons/SettingsIcon";
import { UserIcon } from "../../../Icons/UserIcon";
import "./profile-menu-styles.scss";
import { getAutomationDataAttr } from "../../../../utils/automationUtils";

const PRACTICE_COLORS = [
    "#E03260",
    "#4C8BF4",
    "#5B57DC",
    "#579ABE",
    "#01579B",
    "#8A74A1",
    "#2EB67C",
    "#006B3C",
    "#DC4C40",
    "#682861",
    "#EAB930",
    "#B72C3C",
    "#FF8C4C",
    "#66381E",
    "#5C0991",
    "#526079",
];

const CommonMenu = (props) => {
    const [avatar, setAvatar] = useState(null);
    const [popupWasOpened, setPopupWasOpened] = useState(false);
    const { ref: popUpRef, isPopUpVisible, setIsPopUpVisible } = usePopUpVisibility(false);

    const fetchAvatar = function () {
        return http.post("/v1/patient/get_image", {
            key: props.profile.avatar,
        });
    };

    useEffect(function () {
        (async () => {
            if (props.profile && props.profile.avatar) {
                let cleanResponse = await fetchAvatar();
                if (cleanResponse && cleanResponse.url) {
                    setAvatar(cleanResponse.url);
                }
            }
        })();
    }, []);

    const dismissMenu = () => {
        setIsPopUpVisible(false);
    };

    const toggleProfileMenu = () => {
        setIsPopUpVisible(!isPopUpVisible);
    };

    const handleSettings = () => {
        dismissMenu();
        props.history.push(props.baseRoute + "/settings");
    };

    const handleProfile = () => {
        dismissMenu();
        props.history.push(props.baseRoute + "/profile");
    };

    const handleLogout = () => {
        dismissMenu();
        props.logOut({ history: props.history });
    };

    let isProvider,
        isBHSProvider = false;

    const practiceInfo = (practiceId) => {
        let practiceName, practiceInitials, practiceColor;
        if (practiceId != null && props.profile.practices_by_id != null) {
            practiceName = props.profile.practices_by_id[practiceId].name;
            practiceInitials = practiceName
                .split(" ")
                .map((word) => word.substring(0, 1).toUpperCase())
                .slice(0, 2)
                .join("");
            let practiceIndex = props.profile.practice_ids.indexOf(practiceId);
            practiceColor = PRACTICE_COLORS[practiceIndex % PRACTICE_COLORS.length];
        }

        return [practiceName, practiceInitials, practiceColor];
    };

    let name = "";
    let currentPracticeId, currentPracticeName, currentPracticeInitials, currentPracticeColor;
    let acceptPatientsText = "";
    if (props.profile) {
        isProvider = props.profile.hasOwnProperty("provider_type");
        name = isAuthorized("user:patient")
            ? props.profile.first_name + " " + props.profile.last_name
            : props.profile.name;
        currentPracticeId = isProvider ? props.profile.practice_id : null;

        if (currentPracticeId != null) {
            let [name, initials, color] = practiceInfo(currentPracticeId);
            currentPracticeName = name;
            currentPracticeInitials = initials;
            currentPracticeColor = color;
        }

        isBHSProvider = isProvider ? props.profile.provider_type.startsWith("BEHAVIORAL") : false;
        acceptPatientsText = isBHSProvider
            ? props.profile.accept_new_patients
                ? "ACCEPTING PATIENTS"
                : "NOT ACCEPTING PATIENTS"
            : "";
    }

    const profilePic = (height, width) => {
        return (
            <div className="Toolbar__ProfileMenu-avatar pr-2">
                {avatar && (
                    <img src={avatar} style={{ height: height, width: width }} alt="avatar" />
                )}
                {!avatar && (
                    <img
                        src={images("./common/avatar.png")}
                        style={{ height: height, width: width }}
                        alt="avatar"
                    />
                )}
            </div>
        );
    };

    const pickPractice = async (practiceId) => {
        if (practiceId === currentPracticeId) {
            return;
        }

        if (isProvider) {
            const data = { practice_id: practiceId };
            await api.provider.update_profile({ data });
            props.getProviderProfile();
        }

        toggleProfileMenu();
        props.history.push("/app/home");
        props.history.go();
    };

    useEffect(() => {
        if (!popupWasOpened && isPopUpVisible) {
            setPopupWasOpened(true);
        }
    }, [isPopUpVisible]);

    const profileDropdown = (
        <div style={{ display: popupWasOpened ? "block" : "none" }}>
            <div
                className={`Toolbar__ProfileMenu-dropdown d-flex flex-column align-items-center p-4 fs-14 mr-3 ${
                    isPopUpVisible ? "open" : "close"
                }  ${!isPopUpVisible ? "disabled-dropdown" : ""}`}>
                {isBHSProvider && (
                    <span className="fs-14 accepting-patients-text">
                        <svg height="20" width="20" className="accepting-patients-icon">
                            <circle
                                cx="10"
                                cy="10"
                                r="6"
                                fill={props.profile.accept_new_patients ? "#6AC060" : "red"}
                            />
                        </svg>
                        {acceptPatientsText}
                    </span>
                )}

                <div className="d-flex flex-column fs-22 fw-bold mt-1 mb-4 text-center profile-name-container">
                    {name}

                    {isProvider && (
                        <span className="fs-14 fw-normal provider-type">
                            {props.profile.provider_type
                                .split("_")
                                .map(
                                    (word) =>
                                        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
                                )
                                .join(" ")}
                        </span>
                    )}
                </div>

                {isProvider && (
                    <>
                        <div className="w-100 provider-list mb-3">
                            <ul className="w-100 d-flex justify-content-center practice-list">
                                {props.profile.practice_ids.map((practiceId, index) => {
                                    let [name] = practiceInfo(practiceId);
                                    return (
                                        <div key={practiceId}>
                                            <li
                                                className={`d-flex align-items-center justify-content-center cursor ${
                                                    props.profile.practice_id === practiceId
                                                        ? "fw-bold"
                                                        : "unselected-practice"
                                                }`}
                                                onClick={() => pickPractice(practiceId)}>
                                                <span>{name}</span>
                                            </li>
                                            {index !== props.profile.practice_ids.length - 1 && (
                                                <div className="d-flex align-items-center justify-content-center practice-separator" />
                                            )}
                                        </div>
                                    );
                                })}
                            </ul>
                        </div>
                    </>
                )}

                <h6 className="col-12 border-bottom" />
                <div className="d-flex flex-column w-100 mt-3">
                    <div
                        className="menu-option-item w-100 d-flex align-items-center cursor mb-1"
                        {...getAutomationDataAttr("ProfileMenuProfile")}
                        onClick={handleProfile}>
                        <UserIcon
                            iconType={ICON_TYPES.OUTLINE}
                            iconSize={ICON_SIZES.SMALL}
                            className="patient-item-icon"
                        />

                        <span className="ml-1 prescriber">Profile</span>
                    </div>
                    <div
                        className="menu-option-item w-100 d-flex align-items-center cursor mb-1"
                        {...getAutomationDataAttr("ProfileMenuSettings")}
                        onClick={handleSettings}>
                        <SettingsIcon
                            iconType={ICON_TYPES.OUTLINE}
                            iconSize={ICON_SIZES.SMALL}
                            className="settings-icon"
                        />

                        <span className="ml-1">Settings</span>
                    </div>
                    <div
                        className="menu-option-item w-100 d-flex align-items-center cursor"
                        onClick={handleLogout}>
                        <ExitIcon
                            iconType={ICON_TYPES.OUTLINE}
                            iconSize={ICON_SIZES.SMALL}
                            className="log-out-icon"
                        />
                        <span className="ml-1">Logout</span>
                    </div>
                </div>
            </div>
        </div>
    );

    const nameRender = name ? (
        <span className="fs-16 txt-ellipsis NavUserNameSpan" style={{ whiteSpace: "nowrap" }}>
            {name}
        </span>
    ) : null;
    const currentPracticeNamerender = currentPracticeName ? (
        <span className="Toolbar__ProfileMenu-patients fs-10 txt-ellipsis">
            {currentPracticeName}
        </span>
    ) : null;

    const profileMenuItem = (
        <div
            onClick={toggleProfileMenu}
            className="Toolbar__ProfileMenu cursor d-flex justify-content-start align-items-center">
            {(nameRender || currentPracticeNamerender) && (
                <div
                    className={`Toolbar__ProfileMenu-name-wpr ${
                        currentPracticeNamerender && "with-practice"
                    } d-lg-flex flex-column d-md-none d-sm-none d-none align-content-end`}
                    data-private="true"
                    style={!isProvider ? { marginTop: 0 } : {}}>
                    {nameRender}
                    <div className="practice-name d-flex align-content-end">
                        {currentPracticeNamerender}
                    </div>
                </div>
            )}
            <div
                className="Toolbar__ProfileMenu-caret ml-2 d-lg-block d-md-none d-sm-none d-none"
                {...getAutomationDataAttr("ProfileMenu")}>
                <ChevronDownIcon className="Toolbar__ChevronDown" iconSize={ICON_SIZES.SMALL} />
            </div>
            <div
                className="Toolbar__ProfileMenu-name-wpr d-block d-lg-none d-flex flex-column align-items-center"
                style={{ marginTop: 0, fill: "#FFFFFF" }}>
                <UserIcon iconType={ICON_TYPES.OUTLINE} iconSize={ICON_SIZES.SMALL} />
                <span
                    className="fs-16 txt-ellipsis ToolbarUserName"
                    style={{ whiteSpace: "nowrap" }}>
                    {name}
                </span>
            </div>
        </div>
    );

    return (
        <div ref={popUpRef}>
            {props.profile ? (
                profileMenuItem
            ) : (
                <span className="Toolbar__ProfileMenu-placeholder" />
            )}
            {profileDropdown}
        </div>
    );
};

export default connect(null, { logOut, getProviderProfile })(withRouter(CommonMenu));
