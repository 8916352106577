// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import "./styles.scss";
import images from "../../../utils/images";
import Button from "../Button";

const ErrorPopup = ({ dismiss }) => {
    return (
        <div className="ErrorPopup__wpr">
            <div className="ErrorPopup text-center d-flex flex-column align-items-center justify-content-between">
                <img
                    className="mt-2 py-4"
                    src={images("./icons/warning-round.svg")}
                    alt="error icons"
                />
                <div className="w-75">
                    <h2 className="fs-16 mb-3">
                        <strong>Something Went Wrong</strong>
                    </h2>
                    <p className="fs-14" style={{ lineHeight: "24px" }}>
                        We’re having technical issues at the moment. Please try again later.
                    </p>
                </div>
                <div className="ErrorPopup__close-btn-wpr mb-3 pb-4">
                    <Button onClick={dismiss} className="Btn Btn--pri">
                        Go Back
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ErrorPopup;
