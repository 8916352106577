// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import "./styles.scss";
import { toTitleCase, searchAndReplace } from "../../../utils/filters";
import CardPrimary from "../../../components/UI/Card/CardPrimary";
import images from "../../../utils/images";
import InsuranceInfo from "./InsuranceInfo";
import { api } from "../../../APIRequests";
import ProfileBasicInfo from "./ProfileBasicInfo";
import PatientProfileEmergencyInfo from "./PatientProfileEmergencyInfo";
import Button from "../../UI/Button";
import axios from "../../../utils/axios";
import ProfileDetailsCard from "./ProfileDetailsCard";
import _ from "lodash";

export const SEC = "SEC";
export const PRI = "PRI";

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editBasic: false,
            profileData: props.profileData,
            editEmergency: false,
            editPriIns: false,
            editSecIns: false,
        };
    }

    submitHandler = (formData, actions) => {
        const clonedFormData = _.cloneDeep(formData);
        let data = { ...clonedFormData };

        data["90_day_care_plan"] = data.ninety_day_care;
        if (data.avatar === "") {
            delete data.avatar;
        }

        api.patient
            .update_profile({ data })
            .then((response) => {
                actions.setSubmitting(false);
                this.setState({
                    profileData: data,
                });
                this.props.reloadImg(data);
                this.disableEditMode();
            })
            .catch((err) => {
                console.error(err);
            });
    };

    disableEditMode = () => {
        this.setState((ps) => {
            return {
                editPriIns: false,
                editSecIns: false,
            };
        });
    };

    editHandler = (insType) => {
        switch (insType) {
            case PRI:
                this.setState((ps) => {
                    return {
                        editPriIns: !ps.editPriIns,
                        editSecIns: false,
                    };
                });
                break;
            case SEC:
                this.setState((ps) => {
                    return {
                        editSecIns: !ps.editSecIns,
                        editSecIns: false,
                    };
                });
                break;
            default:
                return;
        }
    };

    /**
     * This modifies the insurance data in case when insurance has `None` as the value
     * For input select to detect proper value in case when there is no data, the `None`
     * returned from backend appers as string to select input which is delusional.
     *
     */
    modifySecondaryInsurance = (insuraceObj) => {
        if (!insuraceObj) {
            return null;
        }
        const modifiedIns = searchAndReplace({ ...insuraceObj }, "None", "");
        return modifiedIns;
    };

    addSecondaryInsuranceHandler = () => {
        this.setState({
            editSecIns: true,
        });
    };

    renderShortDetails = ({ first_name, last_name, avatar }) => (
        <div className="PatientProfile__short-details">
            <div className="py-4 py-lg-0 d-flex flex-row flex-lg-column align-items-center justify-content-lg-center">
                <div className="PatientProfile__avatar-wpr position-relative mx-3 mx-lg-0 mt-lg-5 mb-lg-4">
                    <img
                        className="mx-auto d-block"
                        src={avatar || images("./common/avatar.png")}
                        alt="provider avatar"
                    />
                    {/* <div role="button" className="PatientProfile__edit-avatar-btn cursor position-absolute" /> */}
                </div>
                <div className="text-lg-center text-white ">
                    <div className="fw-bold fs-16 fs-lg-18 mb-lg-2">
                        {toTitleCase(first_name) + " " + toTitleCase(last_name)}
                    </div>
                </div>
            </div>
        </div>
    );

    renderAddSecondaryInsuranceSection = (profileData) => {
        const modifiedProfileData = {
            ...profileData,
            secondary_insurance:
                this.modifySecondaryInsurance({ ...profileData.secondary_insurance }) ||
                profileData.secondary_insurance,
        };
        return (
            <>
                {this.state.editSecIns ? (
                    <InsuranceInfo
                        {...this.props}
                        data={modifiedProfileData}
                        submit={this.submitHandler}
                        edit={this.state.editSecIns}
                        type={SEC}
                        onEdit={this.editHandler}
                    />
                ) : (
                    <div className="PatientProfile__add-secondary-ins-wpr px-4 py-2 mb-3 mb-lg-4 d-flex mb-4 align-items-center justify-content-between">
                        <h1 className="fs-16 m-0 p-0">
                            <strong>No Secondary Insurance added</strong>
                        </h1>
                        <Button
                            onClick={this.addSecondaryInsuranceHandler}
                            className="Btn Btn--clr-pri pr-2"
                            type="button">
                            + Add
                        </Button>
                    </div>
                )}
            </>
        );
    };

    insuranceExists = (ins) => {
        if (ins) {
            let isIns = false;
            const keys = Object.keys(ins);
            keys.forEach((key) => {
                const value = ins[key];
                if (value && value !== "None") {
                    isIns = true;
                }
            });
            return isIns;
        }

        return false;
    };

    render() {
        const { profileData, editBasic, editEmergency } = this.state;
        return (
            <div className="PatientProfile">
                <div className="row no-gutters justify-content-center">
                    <div className="col-12">
                        {profileData && (
                            <ProfileBasicInfo
                                data={{ ...profileData }}
                                submit={this.submitHandler}
                                edit={editBasic}
                                type={"Edit"}
                            />
                        )}
                        {profileData && (
                            <PatientProfileEmergencyInfo
                                data={{ ...profileData }}
                                submit={this.submitHandler}
                                edit={editEmergency}
                                type={"Edit"}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Profile;

/**
 * Below function returns the rendered details which can be reused.
 * @param {string} title - title of the value
 * @param {string} value - value to go below title
 */
export const renderTitleWithValue = function (title, value) {
    const formatMobileNumber = (val) => {
        let firstNumber = val.substring(0, 3);
        let middleNumber = val.substring(3, 6);
        let lastNumber = val.substring(6, 10);
        let formattedMobileNumber = `(${firstNumber}) ${middleNumber}-${lastNumber}`;
        return formattedMobileNumber;
    };

    return (
        <div className="mb-4">
            <div className="fs-14 fw-bold mb-2">
                <span>{title}</span>
            </div>
            <div className="fs-14">
                <span>{title == "Mobile" ? formatMobileNumber(value) : value}</span>
            </div>
        </div>
    );
};
