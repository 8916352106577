import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AWAY } from "../../../../constants/event";
import {
    updateAppointmentEndTime,
    updateAppointmentStartTime,
} from "../../../../redux/actions/calendar";
import { getDurationAsHours, isStartTimeBeforeEndTime } from "../../../../utils/calendar/dates";
import { Text } from "../../../../component-library/";
import { Toggle } from "../../../../component-library/";
import { TextTypes } from "../../../../component-library/";

export const CalendarAppointmentAllDayToggle = () => {
    const dispatch = useDispatch();
    const appointmentSelections = useSelector((state) => state.calendar.appointmentSelections);
    const startTime = appointmentSelections.startTime;
    const endTime = appointmentSelections.endTime;
    const eventType = appointmentSelections.eventType;
    const isAllDayChecked = eventType === AWAY && startTime === "00:00" && endTime === "24:00";
    const duration = getDurationAsHours(startTime, endTime);
    const startTimeBeforeEndTime = isStartTimeBeforeEndTime(startTime, endTime);

    const setAwayDurationToAllDay = () => {
        if (isAllDayChecked) {
            dispatch(updateAppointmentEndTime(null));
        } else {
            dispatch(updateAppointmentStartTime("00:00"));
            dispatch(updateAppointmentEndTime("24:00"));
        }
    };

    return (
        <div className="d-flex py-2 justify-content-between">
            {startTime && endTime && startTimeBeforeEndTime && (
                <span className="d-flex align-items-center w-100">
                    {duration} hour{duration !== 1 && "s"}
                </span>
            )}
            <div className="d-flex w-100 justify-content-end">
                <Toggle
                    className={"mr-2"}
                    key={1}
                    name={"allDay"}
                    checked={isAllDayChecked}
                    onClick={setAwayDurationToAllDay}
                />
                <Text
                    className="apti-FormField--label d-flex align-items-center"
                    type={TextTypes.label}>
                    All Day
                </Text>
            </div>
        </div>
    );
};
