// Copyright aptihealth, inc. 2019 All Rights Reserved

import * as actionTypes from "../actions/actionTypes";
import LogRocket from "logrocket";
import { getUsernameFromToken, getUserSubType } from "../actions/auth";
import { getUserReferralPath } from "../../utils/logRocketUtils";

export const getLocalTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const initialState = {
    token: null,
    refreshToken: null,
    accessToken: null,
    error: null,
    errorType: null,
    timezone: getLocalTimezone(),
    loading: false,
    role: null,
    profile: null,
    referralResponse: null,
    referralCodeMailed: false,
    priInsFront: null,
    priInsBack: null,
    secInsFront: null,
    secInsBack: null,
    forgotPasswordStep: 1,
    forgotPasswordEmail: null,
    showRsPwSuccess: false,
    authRedirectPath: "/",
    session: null,
    challengeName: null,
    sessionTimer: null,
    sessionTimerId: null,
    showEmailVerified: false,
    daysUntilPasswordReset: null,
};

const authStart = (state, action) => {
    return {
        ...state,
        error: null,
        loading: true,
    };
};

const authSuccess = (state, action) => {
    window.localStorage.setItem("token", action.idToken);
    window.localStorage.setItem("refreshToken", action.refreshToken);
    window.localStorage.setItem("accessToken", action.accessToken);

    LogRocket.identify(getUsernameFromToken());

    return {
        ...state,
        token: action.idToken,
        refreshToken: action.refreshToken,
        accessToken: action.accessToken,
        error: null,
        loading: false,
        role: action.role,
        authRedirectPath: action.redirectPath,
        daysUntilPasswordReset: action.daysUntilPasswordReset,
    };
};

const pwResetRequired = (state, action) => {
    return {
        ...state,
        token: action.idToken,
        refreshToken: action.refreshToken,
        accessToken: action.accessToken,
        error: null,
        loading: false,
        errorType: "PasswordResetRequired",
    };
};

const authFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        errorType: action.errorType,
        loading: false,
    };
};

const authChallenge = (state, action) => {
    return {
        ...state,
        error: null,
        loading: false,
        session: action.response.Session,
        username: action.response.ChallengeParameters.USER_ID_FOR_SRP,
        challengeName: action.response.ChallengeName,
        authRedirectPath: action.redirectPath,
    };
};

const clearAuthChallenge = (state, action) => {
    return {
        ...state,
        session: initialState.session,
        username: null,
        challengeName: initialState.challengeName,
        authRedirectPath: initialState.redirectPath,
    };
};

const profileFetchSuccess = (state, action) => {
    const userSubType = getUserSubType(action.profile);
    const logRocketParams = {
        UserType: userSubType,
    };

    if (userSubType === "PATIENT") {
        logRocketParams.ReferralPath = getUserReferralPath(action.profile.group_type);
    }

    LogRocket.identify(getUsernameFromToken(), logRocketParams);

    return {
        ...state,
        profile: action.profile,
    };
};

const profileFetchFail = (state, action) => {
    return {
        ...state,
        profile: null,
    };
};

const loadToken = (state, action) => {
    return {
        ...state,
        token: action.idToken,
        refreshToken: action.refreshToken,
        accessToken: action.accessToken,
        error: null,
        loading: false,
        role: action.role,
        authRedirectPath: action.redirectPath,
    };
};

const refreshAccessToken = (state, action) => {
    window.localStorage.setItem("token", action.payload.IdToken);
    window.localStorage.setItem("accessToken", action.payload.AccessToken);

    LogRocket.identify(getUsernameFromToken());

    return {
        ...state,
        token: action.payload.IdToken,
        accessToken: action.payload.AccessToken,
    };
};

const referralSuccess = (state, action) => {
    return {
        ...state,
        loading: true,
        referralResponse: action.formData,
    };
};

const referralStart = (state, action) => {
    return {
        ...state,
        loading: true,
    };
};

const referralFail = (state, action) => {
    return {
        ...state,
        referralResponse: null,
        loading: false,
    };
};
const signUpSuccess = (state, action) => {
    return {
        ...state,
        referralResponse: null,
        referralCodeMailed: true,
    };
};
const signUpFail = (state, action) => {
    return {
        ...state,
        referralCodeMailed: false,
    };
};

function clearSessionTimers(state) {
    if (state.sessionTimer) {
        clearInterval(state.sessionTimer);
        localStorage.removeItem(state.sessionTimerId);
    }
}

const logOut = (state, action) => {
    clearSessionTimers(state);
    Object.keys(localStorage)
        .filter((key) => !key.startsWith("timer-"))
        .forEach((key) => localStorage.removeItem(key));

    const profile = state.profile;
    if (profile && profile.idp && profile.idp_logout_url) {
        window.location.href = profile.idp_logout_url;
        return null;
    } else {
        return {
            ...state,
            token: null,
            refreshToken: null,
            accessToken: null,
            role: null,
            profile: null,
            referralResponse: null,
            referralCodeMailed: false,
            authRedirectPath: action.redirectPath,
            challengeName: null,
            session: null,
            username: null,
            sessionTimer: null,
            sessionTimerId: null,
        };
    }
};

const setSessionTimer = (state, action) => {
    return {
        ...state,
        sessionTimer: action.sessionTimer,
        sessionTimerId: action.sessionTimerId,
    };
};

const rsPwSent = (state, action) => {
    return {
        ...state,
        forgotPasswordStep: 2,
        forgotPasswordEmail: action.payload,
    };
};

const rsPw = (state, action) => {
    return {
        ...state,
        forgotPasswordStep: 1,
        forgotPasswordEmail: null,
        showRsPwSuccess: true,
    };
};

const rsResetSteps = (state, action) => {
    return {
        ...state,
        forgotPasswordStep: 1,
    };
};

const hideRsPwSuccess = (state, action) => {
    return {
        ...state,
        showRsPwSuccess: false,
    };
};

const setPriFrontInsImg = (state, action) => {
    return {
        ...state,
        priInsFront: {
            url: action.payload.url,
            preview: action.payload.preview,
        },
    };
};

const setPriBackInsImg = (state, action) => {
    return {
        ...state,
        priInsBack: {
            url: action.payload.url,
            preview: action.payload.preview,
        },
    };
};
const setSecFrontInsImg = (state, action) => {
    return {
        ...state,
        secInsFront: {
            url: action.payload.url,
            preview: action.payload.preview,
        },
    };
};

const setSecBackInsImg = (state, action) => {
    return {
        ...state,
        secInsBack: {
            url: action.payload.url,
            preview: action.payload.preview,
        },
    };
};

const unsetPriFrontInsImg = (state, action) => {
    return {
        ...state,
        priInsFront: null,
    };
};

const unsetPriBackInsImg = (state, action) => {
    return {
        ...state,
        priInsBack: null,
    };
};

const unsetSecFrontInsImg = (state, action) => {
    return {
        ...state,
        secInsFront: null,
    };
};

const unsetSecBackInsImg = (state, action) => {
    return {
        ...state,
        secInsBack: null,
    };
};

const unsetPriInsImg = (state, action) => {
    return {
        ...state,
        priInsFront: null,
        priInsBack: null,
    };
};

const unsetSecInsImg = (state, action) => {
    return {
        ...state,
        secInsFront: null,
        secInsBack: null,
    };
};

const showEmailVerified = (state, action) => {
    return {
        ...state,
        showEmailVerified: action.showEmailVerified,
    };
};

const hasActiveCard = (state, action) => {
    return {
        ...state,
        profile: {
            ...state.profile,
            has_active_card: true,
        },
    };
};

const updateJWT = (state, action) => {
    return {
        ...state,
        token: action.token,
        refreshToken: action.refreshToken,
        accessToken: action.accessToken,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_CHALLENGE:
            return authChallenge(state, action);
        case actionTypes.CLEAR_AUTH_CHALLENGE:
            return clearAuthChallenge(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);

        case actionTypes.PW_RESET_REQUIRED:
            return pwResetRequired(state, action);
        case actionTypes.REFRESH_ACCESS_TOKEN:
            return refreshAccessToken(state, action);
        case actionTypes.RS_PW_CODE_SENT:
            return rsPwSent(state, action);
        case actionTypes.RS_FORGOT_PW_STEPS:
            return rsResetSteps(state, action);
        case actionTypes.RS_PW_SUCCESS:
            return rsPw(state, action);
        case actionTypes.HIDE_RS_PW_SUCCESS:
            return hideRsPwSuccess(state, action);
        case actionTypes.LOAD_TOKEN:
            return loadToken(state, action);
        case actionTypes.PROFILE_FETCH_SUCCESS:
            return profileFetchSuccess(state, action);
        case actionTypes.PROFILE_FETCH_FAIL:
            return profileFetchFail(state, action);

        case actionTypes.REFERRAL_START:
            return referralStart(state, action);
        case actionTypes.REFERRAL_SUCCESS:
            return referralSuccess(state, action);
        case actionTypes.REFERRAL_FAIL:
            return referralFail(state, action);

        case actionTypes.SIGNUP_SUCCESS:
            return signUpSuccess(state, action);
        case actionTypes.SIGNUP_FAIL:
            return signUpFail(state, action);
        case actionTypes.LOG_OUT:
            return logOut(state, action);
        case actionTypes.SET_SESSION_TIMER:
            return setSessionTimer(state, action);

        case actionTypes.SET_FRONT_PRI_INS_IMAGE:
            return setPriFrontInsImg(state, action);
        case actionTypes.SET_BACK_PRI_INS_IMAGE:
            return setPriBackInsImg(state, action);
        case actionTypes.SET_FRONT_SEC_INS_IMAGE:
            return setSecFrontInsImg(state, action);
        case actionTypes.SET_BACK_SEC_INS_IMAGE:
            return setSecBackInsImg(state, action);

        case actionTypes.UNSET_FRONT_PRI_INS_IMAGE:
            return unsetPriFrontInsImg(state, action);
        case actionTypes.UNSET_BACK_PRI_INS_IMAGE:
            return unsetPriBackInsImg(state, action);
        case actionTypes.UNSET_FRONT_SEC_INS_IMAGE:
            return unsetSecFrontInsImg(state, action);
        case actionTypes.UNSET_BACK_SEC_INS_IMAGE:
            return unsetSecBackInsImg(state, action);
        case actionTypes.UNSET_PRI_INS_IMAGES:
            return unsetPriInsImg(state, action);
        case actionTypes.UNSET_SEC_INS_IMAGES:
            return unsetSecInsImg(state, action);
        case actionTypes.SHOW_EMAIL_VERIFIED:
            return showEmailVerified(state, action);
        case actionTypes.UPDATE_PATIENT_CARD_INFO:
            return hasActiveCard(state, action);
        case actionTypes.UPDATE_JWT:
            return updateJWT(state, action);
        default:
            return state;
    }
};

export default reducer;
