import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";
import { AnswerBubble } from "../AnswerBubble";
import { Text } from "../Text";
import { TextColors, TextTypes } from "../constants";

export const HorizontalAnswerBubblesQuestionSet = ({
    className,
    style,
    questionSetCategory,
    questionSetOptions,
    answers,
    questionSetOptionsBottomLabels,
    optionColStyle,
    bubbleStyle,
    questionAnswerClassName,
}) => {
    const classNamesArray = ["apti-CL apti-HorizontalAnswerBubblesQuestionSet", className];
    return (
        <div className={createClassNameString(classNamesArray)} style={style}>
            <Text type={TextTypes.label} color={TextColors.purple}>
                {questionSetCategory}
            </Text>

            <div
                className={
                    "apti-HorizontalAnswerBubblesQuestionSetSection apti-HorizontalAnswerBubblesQuestionSetTopOptionLabelContainer"
                }>
                <div></div>
                <div>
                    <div className={"apti-HorizontalAnswerBubblesQuestionSetSubSection"}>
                        {questionSetOptions.map((questionSetOption, optionIndex) => {
                            return (
                                <div
                                    className={
                                        "apti-HorizontalAnswerBubblesQuestionSetOptionLabelContainer"
                                    }
                                    key={`apti-HorizontalAnswerBubblesQuestionSet-${optionIndex}`}>
                                    <div
                                        style={optionColStyle}
                                        className={
                                            "apti-HorizontalAnswerBubblesQuestionSetOptionLabel"
                                        }>
                                        {questionSetOption}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            {Object.entries(answers).map(([question, answerIds]) => {
                return (
                    <div
                        className={createClassNameString([
                            "apti-HorizontalAnswerBubblesQuestionSetSection",
                            questionAnswerClassName,
                        ])}
                        key={`apti-HorizontalAnswerBubblesQuestionSet-${question}`}>
                        <div>
                            <Text>{question}</Text>
                        </div>

                        <div>
                            <div className={"apti-HorizontalAnswerBubblesQuestionSetSubSection"}>
                                {questionSetOptions.map((questionSetOption, optionIndex) => {
                                    const filled = answerIds.includes(optionIndex);
                                    return (
                                        <div
                                            className={"d-flex w-100 justify-content-center"}
                                            key={`apti-HorizontalAnswerBubblesQuestionSet-${question}-${optionIndex}`}>
                                            <AnswerBubble filled={filled} style={bubbleStyle} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
            })}

            <div className={"apti-HorizontalAnswerBubblesQuestionSetSection"}>
                <div></div>
                <div>
                    <div className={"apti-HorizontalAnswerBubblesQuestionSetSubSection"}>
                        {questionSetOptionsBottomLabels.map((label, optionIndex) => {
                            return (
                                <div
                                    className={"apti-HorizontalAnswerBubblesQuestionSetBottomLabel"}
                                    key={`apti-QuestionsAnswerSetBottomLabel-${optionIndex}`}>
                                    {label}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

HorizontalAnswerBubblesQuestionSet.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    questionSetCategory: PropTypes.string,
    questionSetOptions: PropTypes.array,
    questionSetOptionsBottomLabels: PropTypes.array,
    answers: PropTypes.object,
    optionColStyle: PropTypes.object,
    bubbleStyle: PropTypes.object,
};

HorizontalAnswerBubblesQuestionSet.defaultProps = {
    className: undefined,
    style: {},
    questionSetCategory: undefined,
    questionSetOptions: [],
    questionSetOptionsBottomLabels: [],
    answers: {},
    optionColStyle: {},
    bubbleStyle: {},
};
