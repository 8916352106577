import "../styles.scss";
import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { getIn } from "formik";
import _ from "lodash";
import { createClassNameString } from "../../utils";
import { FormFieldError } from "../FormFieldError/FormFieldError";
import { Radio, RadioGroup } from "../../Radio";

export const FormRadioGroup = ({
    name,
    disabled,
    title,
    subTitle,
    options,
    customError,
    className,
    radioContainerClassName,
    radioLabelClassName,
    radioButtonClassName,
    formikProps,
}) => {
    const errorMessage = getIn(formikProps.errors, name);
    const isTouched = getIn(formikProps.touched, name);
    const value = _.get(formikProps.values, name);

    const handleChange = async (event) => {
        if (disabled) {
            return;
        }
        await formikProps.setFieldValue(name, event.target.value);
    };

    return (
        <div className={createClassNameString(["FormField-container", className])}>
            {(title || subTitle) && (
                <div data-public className="FormField-header_container">
                    {title && <h3 className="FormField-title">{title}</h3>}
                    {subTitle && <h3 className="FormField-subTitle">{subTitle}</h3>}
                </div>
            )}
            <div>
                <RadioGroup
                    className={"FormRadioGroup-radio_group"}
                    name={name}
                    onChange={handleChange}
                    value={value}
                    disabled={disabled}>
                    {options.map((option) => {
                        return (
                            <Radio
                                className={radioContainerClassName}
                                key={option.value}
                                version="v1"
                                labelClassName={createClassNameString([
                                    "FormRadioGroup-label",
                                    radioLabelClassName,
                                ])}
                                radioClassName={createClassNameString([
                                    "FormRadioGroup-radio_button",
                                    radioButtonClassName,
                                ])}
                                label={option.label}
                                value={option.value}
                            />
                        );
                    })}
                </RadioGroup>
                {!customError && errorMessage && isTouched && (
                    <FormFieldError>{errorMessage}</FormFieldError>
                )}
                {/* lets the dev pick what is displayed below the text area */}
                {customError && customError(value, errorMessage, isTouched)}
            </div>
        </div>
    );
};

FormRadioGroup.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    customError: PropTypes.func,
    formikProps: PropTypes.object,
};
