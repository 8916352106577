import Button from "../../UI/Button";
import React, { useState } from "react";
import "./styles.scss";
import { Select } from "../../UI/StyledInput";
import images from "../../../utils/images";

const MEMBER_CANCELLATION_OPTIONS = [
    { value: "conflict_with_time", display: "Conflict with time" },
    { value: "illness_medical", display: "Illness/medical" },
    { value: "need_sooner_appointment", display: "Need sooner appointment" },
    { value: "other", display: "Other" },
];

const ScheduleReviewDialog = (props) => {
    const { submitHandler, providerName, callId, cancelHandler } = props;
    const [cancellationReason, setCancellationReason] = useState(null);

    const handleRadioChange = (event) => {
        setCancellationReason(event.target.value);
    };

    const renderCancelReasonsMobile = () => {
        return (
            <div className="CancelReasonsMobile">
                <Select
                    name="cancelReason"
                    changeHandler={(reason) => setCancellationReason(reason.target.value)}
                    value={cancellationReason}
                    options={MEMBER_CANCELLATION_OPTIONS}
                />
            </div>
        );
    };
    const isCanceledButtonDisabled = cancellationReason === null;
    const renderCancelReasonsDesktop = () => {
        return (
            <div className="CancelReasonsDesktop">
                {MEMBER_CANCELLATION_OPTIONS.map((element) => {
                    return (
                        <div className={"text-left mt-2"}>
                            <input
                                className={"mr-2"}
                                type="radio"
                                value={element.value}
                                checked={cancellationReason === element.value}
                                onChange={(event) => setCancellationReason(event.target.value)}
                            />
                            <label className="fs-14">{element.display}</label>
                        </div>
                    );
                })}
            </div>
        );
    };

    const submit = () => {
        const data = {
            cancellation_reason: {
                reason_type: cancellationReason,
            },
            callId: callId,
        };
        submitHandler(data);
    };

    return (
        <>
            <div className="row CancelReviewContainer">
                <div className="col">
                    <h2 className="fs-17 fw-bold">
                        To cancel your appointment with {providerName} select a reason.
                    </h2>
                </div>
            </div>
            <Button type="button" className="close modal-close" onClick={cancelHandler}>
                <span aria-hidden="true">
                    <img src={images("./icons/grey-x-circle.svg")} />
                </span>
            </Button>
            <div className="row d-flex flex-column">
                <div className="col">
                    {renderCancelReasonsDesktop()}
                    {renderCancelReasonsMobile()}
                </div>
                <div className="mt-4">
                    <Button
                        onClick={submit}
                        type="button"
                        className="Btn Btn--pri Btn-sm"
                        disabled={isCanceledButtonDisabled}>
                        Cancel Appointment
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ScheduleReviewDialog;
