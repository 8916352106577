import React, { useState, useEffect } from "react";

import { ErrorCard } from "../../../component-library";
import { Accepted } from "../../../component-library/MemberProfileInsurance/InsurangeEligibility/Accepted";
import { Declined } from "../../../component-library/MemberProfileInsurance/InsurangeEligibility/Declined";
import { Pending } from "../../../component-library/MemberProfileInsurance/InsurangeEligibility/Pending";
import { NoMatch } from "../../../component-library/MemberProfileInsurance/InsurangeEligibility/NoMatch";
import { NotConfigured } from "../../../component-library/MemberProfileInsurance/InsurangeEligibility/NotConfigured";

function PatientProfileInsuranceEligibility({ data, pendingCount, showNotConfigured }) {
    const [status, setStatus] = useState();
    const [humanDate, setHumanDate] = useState(null);

    useEffect(() => {
        let checked = !data.timestamp ? new Date() : new Date(data.timestamp);
        setStatus(data.status);
        setHumanDate(
            checked.getMonth() + 1 + "/" + checked.getDate() + "/" + checked.getFullYear(),
        );
    }, [data]);

    return (
        <>
            {status && status === "accepted" && (
                <Accepted copay={data.copay} dateChecked={humanDate} carrier={data.carrier} />
            )}

            {status && status === "declined" && <Declined dateChecked={humanDate} />}

            {status && status === "no_match" && <NoMatch dateChecked={humanDate} />}

            {status && status === "pending" && pendingCount === 1 && (
                <ErrorCard>
                    <strong className="bold">Sorry...</strong>
                    {`We’re experiencing some issues right now with checking insurance coverage from ${data.carrier}. Please try again.`}
                </ErrorCard>
            )}

            {status && status === "pending" && pendingCount > 1 && (
                <Pending dateChecked={humanDate} />
            )}

            {status && status === "not_configured" && showNotConfigured && (
                <NotConfigured dateChecked={humanDate} />
            )}
        </>
    );
}

export default PatientProfileInsuranceEligibility;
