import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { createClassNameString } from "../../component-library";
import { ICON_SIZES, ICON_TYPES } from "../../constants/icons";

export const AptihealthIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23 21.3187C23 22.7199 21.9055 23.9565 20.4492 23.9565L14.0533 23.9565C12.5973 23.9565 11.5029 22.7198 11.5029 21.3187L11.5029 17.3625C11.5029 15.7521 10.9291 14.1323 9.69226 12.4863C8.4355 10.8138 7.30194 10.2047 6.31185 10.1995L2.25787 10.1995C0.960853 10.1995 -3.27722e-07 9.09854 0 7.86796L1.46289e-06 2.37492C1.79064e-06 1.14421 0.960905 0.0434262 2.25787 0.0434265L7.44676 0.0434279C8.74384 0.0434282 9.70486 1.14409 9.70486 2.37492L9.70485 6.57276C9.84332 7.68987 10.4473 8.83706 11.6631 10.0135C12.9308 11.2401 14.4026 11.8674 16.1206 11.9243L20.4493 11.9243C21.9054 11.9243 23 13.1607 23 14.5621L23 21.3187ZM20.4492 21.9565C20.6994 21.9565 21 21.7196 21 21.3187L21 14.5621C21 14.161 20.6995 13.9243 20.4492 13.9243L16.089 13.9243L16.0735 13.9238C14.7346 13.8821 13.4977 13.5651 12.372 12.9791C13.1152 14.3897 13.5029 15.8528 13.5029 17.3625L13.5029 21.3187C13.5029 21.7198 13.8036 21.9565 14.0533 21.9565L20.4492 21.9565ZM8.2215 8.61984C7.95584 8.01532 7.78294 7.38926 7.71097 6.7416L7.70485 6.68654L7.70486 2.37492C7.70486 2.14041 7.53405 2.04343 7.44675 2.04343L2.25787 2.04343C2.17093 2.04343 2 2.14029 2 2.37492L2 7.86796C2 8.10256 2.17098 8.19946 2.25787 8.19946L6.31874 8.19946C6.98297 8.20241 7.61693 8.34912 8.2215 8.61984Z"
                />
            </svg>
        );
    }

    return null;
};

AptihealthIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

AptihealthIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
