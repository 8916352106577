import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { CalendarEventBody, CalendarEventTime, CalendarEventTitle } from "./CalendarEvent";
import moment from "moment";
import { awayTimeType } from "../../../utils/calendar/event";

const AwayTime = ({ position, startTime, endTime, type, details, label }) => {
    const title = label ? label : "Out of Office";
    return (
        <CalendarEventBody
            className="AwayTime__body"
            position={position}
            startTime={startTime}
            endTime={endTime}
            details={details}>
            <CalendarEventTitle title={title} />
            <CalendarEventTime
                startTime={startTime}
                endTime={endTime}
                strikeTimes={type?.isCancelled}
            />
        </CalendarEventBody>
    );
};

AwayTime.propTypes = {
    position: PropTypes.object,
    startTime: PropTypes.instanceOf(moment),
    endTime: PropTypes.instanceOf(moment),
    type: awayTimeType,
};

export { AwayTime };
