import _ from "lodash";

export const copyFields = (original, copy, fields) => {
    fields.forEach((key) => {
        if (original.hasOwnProperty(key)) {
            copy[key] = _.cloneDeep(original[key]);
        }
    });
};

export const mergeObjectOnlyExistingFields = (target, source) => {
    const targetCopy = _.cloneDeep(target);
    Object.keys(source)
        .filter((key) => key in targetCopy)
        .forEach((key) => (targetCopy[key] = source[key]));
    return targetCopy;
};
