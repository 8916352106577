import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import {createClassNameString} from "../../component-library";
import {ICON_SIZES, ICON_TYPES} from "../../constants/icons";

export const LoupeIcon = ({className, iconType, iconSize, ...props}) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path fillRule="evenodd" clipRule="evenodd" d="M24 14.5C24 19.7467 19.7467 24 14.5 24C9.25329 24 5 19.7467 5 14.5C5 9.25329 9.25329 5 14.5 5C19.7467 5 24 9.25329 24 14.5ZM22 14.5C22 18.6421 18.6421 22 14.5 22C10.3579 22 7 18.6421 7 14.5C7 10.3579 10.3579 7 14.5 7C18.6421 7 22 10.3579 22 14.5Z" />
                <path d="M28.7071 27.2929L22.7071 21.2929C22.3166 20.9024 21.6834 20.9024 21.2929 21.2929C20.9024 21.6834 20.9024 22.3166 21.2929 22.7071L27.2929 28.7071C27.6834 29.0976 28.3166 29.0976 28.7071 28.7071C29.0976 28.3166 29.0976 27.6834 28.7071 27.2929Z" />
            </svg>

        );
    }

    return null;
};

LoupeIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

LoupeIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
