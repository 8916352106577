import images from "../../../../utils/images";
import React from "react";
import { withRouter } from "react-router-dom";

const EditNoteWrapper = (props) => {
    const { patient, note, noteDraftSubmitHandler, noteSubmitHandler } = props;
    return (
        <>
            <div className="survey-back-link-wpr mx-auto fs-16 my-2 p-3 pl-lg-0 pr-lg-0">
                <span className="survey-back-link" onClick={() => props.history.go(-1)}>
                    <img className="img-fluid" src={images("./common/solidBckBtn.svg")} />
                    <p className="d-inline ml-2">Back to Patient Profile</p>
                </span>
            </div>

            <div className="mt-3 ml-auto mr-auto fw-bold" style={{ maxWidth: 978 }}>
                {patient.first_name} {patient.last_name} (Date of Birth : {patient.dob})
            </div>

            <div className="mt-3 ml-auto mr-auto fw-bold" style={{ maxWidth: 978 }}>
                {note.title}
            </div>

            <div className="m-auto" style={{ maxWidth: 978 }}>
                {props.children}
            </div>

            <div className="d-flex justify-content-center my-3 mx-auto w-25">
                <div className="position-relative w-100 text-center">
                    <button
                        className="GuidedVideoCall__center-btn fs-14"
                        style={{ backgroundColor: "#5B57DC" }}
                        onClick={() => noteDraftSubmitHandler()}>
                        Save Draft
                    </button>
                </div>

                <div className="position-relative w-100 text-center">
                    <button
                        className="GuidedVideoCall__center-btn fs-14"
                        onClick={() => noteSubmitHandler()}>
                        Sign & Submit
                    </button>
                </div>
            </div>
        </>
    );
};

export default withRouter(EditNoteWrapper);
