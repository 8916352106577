import { useState } from "react";
import { useEffect } from "react";
import { api } from "../../APIRequests";

export const usePatient = (patientId, existingPatient) => {
    const [patient, setPatient] = useState();
    useEffect(() => {
        const getPatient = async () => {
            // if a existing patient is passed in
            // we do not need to make another network call
            if (existingPatient) {
                setPatient(existingPatient);
                return;
            }
            if (!patientId) {
                return;
            }
            const urlParams = { patientId };
            const options = { params: { showLoader: true } };
            const patientDetails = await api.shared.fetch_patient_details({ urlParams, options });
            setPatient(patientDetails.user);
        };

        getPatient();
    }, [patientId]);

    return patient;
};

export const usePatientDiagnosis = (patientId, existingDiagnosis) => {
    const [diagnosis, setDiagnosis] = useState();
    useEffect(() => {
        const getDiagnosis = async () => {
            // if an existing diagnosis is passed in
            // we do not need to make another network call
            if (existingDiagnosis) {
                setDiagnosis(existingDiagnosis);
                return;
            }
            if (!patientId || existingDiagnosis) {
                return existingDiagnosis;
            }
            const urlParams = { patientId };
            const diagnosisResponse = await api.shared.get_patient_diagnosis({ urlParams });
            setDiagnosis(diagnosisResponse);
        };

        getDiagnosis();
    }, [patientId]);

    return diagnosis;
};

export const usePaymentMethod = (patientId) => {
    const [paymentInfo, setPaymentInfo] = useState();

    useEffect(() => {
        const getPaymentMethod = async () => {
            if (!patientId) {
                return undefined;
            }

            const options = { params: { showLoader: true } };
            const paymentInfoResponse = await api.payment.fetchPatientCardInfo({
                data: { patient_id: patientId },
                options,
            });

            setPaymentInfo(paymentInfoResponse);
        };

        getPaymentMethod();
    }, [patientId]);

    return paymentInfo;
};
