// Copyright aptihealth, inc. 2021 All Rights Reserved
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./../styles.scss";
import { api } from "../../../../APIRequests";
import { getQueryParams, uuidv4 } from "../../../../utils/filters";
import HCPPatientInfo from "./HCPPatientInfo";
import HCPInfo from "./hcpInfo";
import { connect, useDispatch } from "react-redux";
import { showAlertWithAction } from "../../../../redux/actions/alerts";
import ReferAnotherPatientOrScheduleAppointment from "./referAnotherPatientOrScheduleAppointment";
import { ERROR_CODES } from "../../../../constants/errorCode";
import { Footer } from "../../../../component-library/Footer";
import { BrandedHeader } from "../../BrandedHeader";
import { addToast, toastMessageTypes } from "../../../../redux/actions/toaster";
import { CallScheduledReferOtherPatient } from "./CallScheduledReferOtherPatient";
import { CallSkippedReferOtherPatient } from "./CallSkippedReferOtherPatient";
import { HCP_WORKFLOW_STEPS } from "../../../../constants/hcpWorkflow";

const HCPWorkflow = (props) => {
    const [sharedWorkflowData, setSharedWorkflowData] = useState({});
    const [currentStepName, setCurrentStepName] = useState(null);
    const dispatch = useDispatch();

    const update_state_and_get_next_node = async (state, node_name, successToast) => {
        try {
            const options = { params: { showLoader: true } };
            const data = {
                workflow_instance_entity_id: sharedWorkflowData.workflow_instance.entity_id,
                workflow_instance_sort_key: sharedWorkflowData.workflow_instance.sort_key,
                node_name: node_name,
                state: state,
            };
            const response = await api.workflow.update_state_and_get_next_node({ options, data });

            if (successToast) {
                dispatch(
                    addToast({
                        message: successToast,
                        messageType: toastMessageTypes.success_v2,
                    }),
                );
            }

            // if workflow has finished redirect to home page
            if (response.workflow_instance.current_node_name === null) {
                props.history.push("");
                return;
            }

            setSharedWorkflowData({
                ...sharedWorkflowData,
                workflow_instance: response.workflow_instance,
            });

            window.scrollTo({ top: 0, behavior: "instant" });
        } catch (e) {
            console.log(e);
            const error = ERROR_CODES.hasOwnProperty(e.message)
                ? ERROR_CODES[e.message]
                : ERROR_CODES["WorkflowDefault"];
            props.showAlertWithAction(`${error.message} Code: ${error.code}`);
        }
    };

    const baseProps = {
        sharedWorkflowData,
        currentStepName,
        update_state_and_get_next_node,
    };

    // the following are the workflow steps that are acted on by the client
    // the other steps are present in dynamo happen in the background
    const workflowSteps = {
        [HCP_WORKFLOW_STEPS.hcp_info]: <HCPInfo {...baseProps} />,
        [HCP_WORKFLOW_STEPS.patient_info]: <HCPPatientInfo {...baseProps} />,
        [HCP_WORKFLOW_STEPS.schedule_call_or_skip]: (
            <ReferAnotherPatientOrScheduleAppointment {...baseProps} />
        ),
        [HCP_WORKFLOW_STEPS.call_scheduled_refer_another_patient]: (
            <CallScheduledReferOtherPatient {...baseProps} />
        ),
        [HCP_WORKFLOW_STEPS.call_skipped_refer_another_patient]: (
            <CallSkippedReferOtherPatient {...baseProps} />
        ),
    };

    useEffect(() => {
        (async () => {
            const options = { params: { showLoader: true } };
            if (!currentStepName) {
                const data = { workflow_name: "HCP_WORKFLOW" };
                const workflowResponse = await api.workflow.get_workflow({ options, data });
                if (workflowResponse) {
                    const data = {
                        workflow_name: workflowResponse.workflow_name,
                        workflow_config_entity_id: workflowResponse.entity_id,
                        entity_type: "session",
                        workflow_instance_entity_id: uuidv4(),
                    };
                    const response = await api.workflow.create_workflow_instance({ options, data });
                    setSharedWorkflowData({ ...sharedWorkflowData, workflow_instance: response });
                }
            } else if (
                !sharedWorkflowData.workflow_instance ||
                (currentStepName && !workflowSteps.hasOwnProperty(currentStepName))
            ) {
                setCurrentStepName(null);
                props.history.push("/app/hcp-workflow");
            }
        })();
    }, [currentStepName]);

    //display workflow step based on changes to workflow instance
    useEffect(() => {
        let stepName =
            sharedWorkflowData.workflow_instance &&
            sharedWorkflowData.workflow_instance.current_node_name;
        if (sharedWorkflowData.workflow_instance && !stepName) {
            stepName = sharedWorkflowData.workflow_instance.first_node;
        }

        if (stepName) {
            // adds param to url so we can display the right header
            // on the new patient referral
            if (
                (currentStepName === HCP_WORKFLOW_STEPS.call_scheduled_refer_another_patient ||
                    currentStepName === HCP_WORKFLOW_STEPS.call_skipped_refer_another_patient) &&
                stepName === HCP_WORKFLOW_STEPS.patient_info
            ) {
                props.history.push(
                    `/app/hcp-workflow?step=${stepName}&referring_another_patient=true`,
                );
            } else {
                props.history.push(`/app/hcp-workflow?step=${stepName}`);
            }

            setCurrentStepName(stepName);
        }
    }, [sharedWorkflowData.workflow_instance]);

    // display workflow step based on changes to nav buttons (go back/forward)
    useEffect(() => {
        // If back button is ever hit in workflow, automatically reset workflow to start point
        if (props.history.action === "POP") {
            setCurrentStepName(null);
            setSharedWorkflowData({});
            props.history.push("/app/hcp-workflow");
            return;
        }

        const stepName = getQueryParams("step", props.location.search);
        if (
            !sharedWorkflowData.workflow_instance ||
            (stepName &&
                stepName !== currentStepName &&
                stepName === sharedWorkflowData.workflow_instance.first_node)
        ) {
            setCurrentStepName(null);
            setSharedWorkflowData({});
            props.history.push("/app/hcp-workflow");
        } else if (
            stepName &&
            stepName !== currentStepName &&
            stepName !== sharedWorkflowData.workflow_instance.first_node
        ) {
            setCurrentStepName(stepName);
            props.history.push(`/app/hcp-workflow?step=${stepName}`);
        }
    }, [getQueryParams("step", props.location.search)]);

    return (
        <>
            <div className={"self-signup-content"} data-public>
                <div>
                    <BrandedHeader className="HCPContainer-header" />
                    {currentStepName &&
                        workflowSteps.hasOwnProperty(currentStepName) &&
                        workflowSteps[currentStepName]}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default connect(null, { showAlertWithAction })(withRouter(HCPWorkflow));
