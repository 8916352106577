import {isUserPatient} from "../redux/actions/auth";

/**
 * Checks if a conversation between a patient and provider is readonly. A conversation can only be
 * readonly for patients, and only if the provider is no longer on their care team.
 * @param careTeamMembers Members of the care team
 * @param channel Sendbird channel data
 * @returns {boolean} Conversation is readonly
 */
export const isConversationReadOnly = (careTeamMembers, channel) => {
    if (!isUserPatient() || !careTeamMembers || (channel?.otherMembers || []).length < 1) {
        return false;
    }
    return !careTeamMembers
        .map(member => member.provider_id)
        .includes(channel.otherMembers[0].userId);
};
