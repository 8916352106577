import React from "react";
import images from "../../../../../utils/images";
import { createClassNameString } from "../../../../../component-library";

export const MemberProfileNoteTypeOption = ({
    iconPath,
    iconStyle,
    onClick,
    title,
    subTitle,
    titleWrapperClassName,
}) => {
    return (
        <div onClick={onClick} className={"d-flex justify-content-between pointer"}>
            <div className={"add-note-section d-flex"}>
                <img
                    src={images(iconPath)}
                    alt="note icon"
                    className={"note-icon"}
                    style={iconStyle}
                />
                <div
                    className={createClassNameString(["d-flex flex-column", titleWrapperClassName])}
                    data-e2e="MemberProfileNotes_createGuidedSessionNote">
                    <p className={"fs-16 fw-bold mb-0"}>{title}</p>
                    {subTitle && <p className={"mb-0 fs-14 grey-text"}>{subTitle}</p>}
                </div>
            </div>
            <img
                src={images("./common/right-chevron.svg")}
                alt="note icon"
                className={"mr-4 new-note-chevron"}
            />
        </div>
    );
};
