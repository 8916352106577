import "../styles.scss";
import React from "react";
import { getIn } from "formik";
import PropTypes from "prop-types";
import _ from "lodash";
import { CurrencyInput } from "../../CurrencyInput/CurrencyInput";
import { createClassNameString } from "../../utils";

export const FormCurrencyField = ({ name, title, subTitle, className, formikProps }) => {
    const errorMessage = getIn(formikProps.errors, name);
    const isTouched = getIn(formikProps.touched, name);
    const value = _.get(formikProps.values, name);

    const handleChange = async (event) => {
        await formikProps.setFieldValue(name, event.target.value);
    };

    return (
        <div className={createClassNameString(["FormField-container", className])}>
            {(title || subTitle) && (
                <div data-public className="FormField-header_container">
                    {title && <h3 className="FormField-title">{title}</h3>}
                    {subTitle && <h3 className="FormField-subTitle">{subTitle}</h3>}
                </div>
            )}
            <div>
                <CurrencyInput
                    value={value}
                    onChange={handleChange}
                    // if isTouched is true and errorMessage is truthy (aka not null, "", etc)
                    // return errorMessage
                    errorMessage={isTouched && errorMessage && errorMessage}
                />
            </div>
        </div>
    );
};

FormCurrencyField.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    className: PropTypes.string,
    formikProps: PropTypes.object,
};
