// Copyright aptihealth, inc. 2021 All Rights Reserved

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Image } from "../../../component-library";
import { Footer } from "../../../component-library/Footer";
import { getQueryParams } from "../../../utils/filters";
import images from "../../../utils/images";
import Button from "../../UI/Button";
import "./styles.scss";

const EmailVerification = (props) => {
    const [message, setMessage] = useState(null);
    const isEmailVerified = getQueryParams("isEmailVerified", props.location.search) === "True";

    useEffect(() => {
        setMessage(
            isEmailVerified ? responseTranslationMap["success"] : responseTranslationMap["error"],
        );
    }, []);

    const responseTranslationMap = {
        success: {
            title: "Your email has been verified successfully.",
            content: (
                <>
                    <div className={"txt-pri fw-bold fs-lg-26 fs-20 my-3"}>
                        Welcome to aptihealth!
                    </div>
                    <div className={"mb-3"}>
                        If you already finished setting up your account, please click Log In at the
                        top right to access your account.
                    </div>
                    <div>
                        If you have not created your password already, you will receive an email
                        from aptihealth shortly with a link to finish your account set-up.
                    </div>
                </>
            ),
        },
        error: {
            title: "Your email verification was not successful.",
            content: (
                <div className={"my-3"}>
                    Please contact our support team at{" "}
                    <a
                        className="a-link"
                        href="mailto: support@aptihealth.com?subject=Web Support Request">
                        support@aptihealth.com
                    </a>{" "}
                    for further assistance.
                </div>
            ),
        },
    };

    return (
        <>
            <div className={"email-verification-content"}>
                <div className={"bg-purple-2 p-md-5 px-3 py-4 d-flex justify-content-between"}>
                    <img
                        className={"email-verification-logo"}
                        src={images("./logo/logo-and-tag.png")}
                        alt={"aptihealth"}
                    />
                    <div className={"mt-md-4 mt-0"}>
                        {isEmailVerified && (
                            <Button
                                className={"Btn Btn--otl-sec Btn--sm-2"}
                                style={{ borderRadius: 7 }}
                                onClick={() => {
                                    props.history.push("/auth/sign-in?isEmailVerified=true");
                                }}>
                                Log In
                            </Button>
                        )}
                    </div>
                </div>
                <div className={"m-md-5 p-md-5 p-4"}>
                    {message && (
                        <div className={"mx-auto"} style={{ maxWidth: 700 }}>
                            <div className={"fs-lg-32 fs-22 fw-bold proxima mb-3"}>
                                {message.title}
                            </div>
                            <div className={"fs-lg-18 fs-16"}>{message.content}</div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default withRouter(EmailVerification);
