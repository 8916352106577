// Copyright aptihealth, inc. 2019 All Rights Reserved

export const ADMIN_BASIC_SCHEMA = [
    {
        name: "name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Full Name",
            placeholder: "Full Name",
        },
    },
    {
        name: "user_type",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Customer Success Type",
            placeholder: "Customer Success Type",
        },
    },
    {
        name: "email",
        elementType: "input",
        elementConfig: {
            type: "email",
            label: "Email",
            placeholder: "Email",
        },
    },
    {
        name: "mobile",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Mobile",
            placeholder: "Mobile",
        },
    },
];
