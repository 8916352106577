import "./styles.scss";

import React, { useState } from "react";
import { useCheckNPS } from "./useCheckNPS";
import { NPSRating, NPSScoreReason } from "../../component-library";
import surveyRequests from "../../APIRequests/survey";
import { addToast, toastMessageTypes } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";

const SURVEY_TYPE = "nps_updated_calendar";

export const NPSUpdatedCalendar = () => {
    const dispatch = useDispatch();
    const { shouldShowNPS, recordAnswer } = useCheckNPS(SURVEY_TYPE);
    const [npsKey, setNPSKey] = useState();

    const finishLastQuestion = () => {
        recordAnswer();
        setNPSKey(undefined);
        dispatch(
            addToast({
                message: "Thank you for your feedback.",
                messageType: toastMessageTypes.success_v2,
            }),
        );
    };

    if (!shouldShowNPS) {
        return null;
    }

    return (
        <div className="NPSUpdatedCalendar">
            {!npsKey && (
                <NPSRating
                    featureArea="scheduling"
                    dismissHandler={async () => {
                        await surveyRequests.submit_survey({
                            options: { params: { showLoader: true } },
                            data: {
                                survey_type: SURVEY_TYPE,
                            },
                        });
                        recordAnswer();
                    }}
                    submitHandler={async (event) => {
                        const score = Number(event.target.value);
                        const response = await surveyRequests.submit_survey({
                            options: { params: { showLoader: true } },
                            data: {
                                survey_type: SURVEY_TYPE,
                                score,
                            },
                        });
                        setNPSKey(response?.key);
                    }}
                />
            )}

            {npsKey && (
                <NPSScoreReason
                    dismissHandler={finishLastQuestion}
                    submitHandler={async (scoreReason) => {
                        if (!scoreReason) {
                            finishLastQuestion();
                            return;
                        }

                        await surveyRequests.submit_survey({
                            options: { params: { showLoader: true } },
                            data: {
                                key: npsKey,
                                survey_type: SURVEY_TYPE,
                                score_reason: scoreReason,
                            },
                        });

                        finishLastQuestion();
                    }}
                />
            )}
        </div>
    );
};
