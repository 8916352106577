// Copyright aptihealth, inc. 2021 All Rights Reserved

import React, {useEffect} from "react";
import "./styles.scss";
import Button from "../../UI/Button";
import { ConsentToTermsOfUse } from "../../Common/ConsentToTermsOfUse";
import { trackEvent } from "../../../utils/EventTrackingUtil";

export const GuardianConsentToTermsOfUse = (props) => {
    const { consent, initializeConsent, setConsent, nextStep } = props;

    useEffect(() => {
        initializeConsent();
    }, []);

    const openDoc = (url) => {
        window.open(url);
    };
    const EulaLink = () => {
        return renderDocLink("Terms and Conditions of Use", () =>
            openDoc("https://www.aptihealth.com/terms-and-conditions"),
        );
    };
    const renderDocLink = (text, onClick) => {
        return (
            <Button
                className={"Btn-link txt-pri fw-bold"}
                style={{ margin: 0, whiteSpace: "nowrap" }}
                onClick={onClick}>
                {text}
            </Button>
        );
    };
    return (
        <>
            <div className={"txt-sec fw-bold pt-2 mx-3 workflow-step-title"} data-public={"true"}>
                Step 1 of 5: Confirm Parent/Caregiver Consent
            </div>

            <div className={"mx-4 my-3"} data-public={"true"}>
                <div className={"mb-4"}>
                    Thank you for your trust in aptihealth to treat your child. Before we have you
                    create their account, we need your consent on a few items. Please view and
                    print/save the documents linked below, as they inform you of your rights and
                    obligations while using aptihealth. By accessing and using aptihealth Services,
                    you are agreeing to our <EulaLink />.
                </div>

                <div className={"mx-md-5"}>
                    <ConsentToTermsOfUse
                        consent={consent}
                        setConsent={setConsent}
                        consentGivenBy={"PARENT"}
                    />
                </div>

                <div className={"d-flex justify-content-end pb-4"} data-public={"true"}>
                    <Button
                        className={"Btn Btn--pri"}
                        disabled={
                            consent["eula"] && consent["privacy"] && consent["hipaa"]
                                ? null
                                : "disabled"
                        }
                        onClick={() => {
                            trackEvent("Consent to Treatment");
                            nextStep();
                        }}>
                        Continue
                    </Button>
                </div>
            </div>
        </>
    );
};
