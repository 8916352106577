import React from "react";
import ProfileMenu from "../ProfileMenu";

const SelfSection = (props) => {
    const profileMenu = (
        <ProfileMenu profile={props.config.profile} baseRoute={props.config.baseRoute} />
    );

    return <div className="d-flex align-items-center ml-auto">{profileMenu}</div>;
};

export default SelfSection;
