import React from "react";
import "./styles.scss";
import { Input, Text, TextColors, TextTypes } from "../../../../../component-library";
import { getIn } from "formik";

export const CareManagementCredentialField = ({ formikProps, name, isViewOnly }) => {
    const errorMessage = getIn(formikProps.errors, name);
    const isTouched = getIn(formikProps.touched, name);
    const value = formikProps.values[name];

    const handleChange = async (event) => {
        await formikProps.setFieldValue(name, event.target.value);
    };

    return (
        <div className="CareManagementFieldShared--credential_wrapper">
            <div className="CareManagementFieldShared--credential_input_wrapper">
                <h3 className="CareManagementFieldShared--title">Credentials</h3>
            </div>
            <div className="CareManagementFieldShared--input_wrapper">
                {isViewOnly && (
                    <p className="CareManagementField--view_only_input">
                        {value === "" ? "None" : value}
                    </p>
                )}

                {!isViewOnly && (
                    <>
                        <Input
                            inputClassName="CareManagementCredentialField--input"
                            onChange={handleChange}
                            value={formikProps.values[name]}
                            disabled={true}
                        />
                        {errorMessage && isTouched && (
                            <Text type={TextTypes.small} color={TextColors.red} layout="block">
                                {errorMessage}
                            </Text>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
