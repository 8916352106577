// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import { CardHeading } from "../../UI/Headings";
import SettingsList from "./SettingsList";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { TourIcon } from "../../Icons/TourIcon";
import { LockIcon } from "../../Icons/LockIcon";
import { DocumentIcon } from "../../Icons/DocumentIcon";
import { EnvelopeIcon } from "../../Icons/EnvelopeIcon";
import { AptihealthIcon } from "../../Icons/AptihealthIcon";
import { CameraIcon } from "../../Icons/CameraIcon";
import { SettingsIcon } from "../../Icons/SettingsIcon";

const Settings = (props) => {
    const { baseRoute } = props;

    const showChangePassword = props.profile && !props.profile.idp;
    return (
        <div data-public={"true"}>
            <CardHeading text="Settings" className="py-md-4 mb-0 mx-md-3 d-none d-md-block fs-18" />
            <div className="Settings__HorizontalLine d-none d-md-block"></div>
            <div className="px-3 px-lg-4">
                <Link to={baseRoute + "/tutorial"}>
                    <SettingsList text="View Tutorials" iconComponent={TourIcon} />
                </Link>
                {/* <SettingsList text='Notifications' imgName='Notification' /> */}
                {showChangePassword && (
                    <Link to={baseRoute + "/change-password"}>
                        <SettingsList text="Change Password" iconComponent={LockIcon} />
                    </Link>
                )}
                <a href={"https://www.aptihealth.com/privacy"} target="_blank" rel="noreferrer">
                    <SettingsList text="Privacy Policy" iconComponent={DocumentIcon} />
                </a>
                <a href="mailto:support@aptihealth.com?subject=Web Support Request" target="_top">
                    <SettingsList text="Email Support" iconComponent={EnvelopeIcon} />
                </a>
                <Link to={baseRoute + "/system-check"}>
                    <SettingsList text="System Check" iconComponent={SettingsIcon} />
                </Link>
                <Link to={baseRoute + "/av-check"}>
                    <SettingsList text="Test Audio & Video" iconComponent={CameraIcon} />
                </Link>
                <a href={"https://www.aptihealth.com/about/"} target="_blank" rel="noreferrer">
                    <SettingsList text="About aptihealth" iconComponent={AptihealthIcon} />
                </a>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps, null)(Settings);
