// Copyright aptihealth, inc. 2019 All Rights Reserved

import { Form } from "formik";
import _ from "lodash";
import moment from "moment-timezone";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { api } from "../../../APIRequests";
import { CustomForm } from "../../../component-library/CustomForm";
import { getProfile } from "../../../components/Provider/Profile";
import Button from "../../../components/UI/Button";
import { CardPrimary } from "../../../components/UI/Card";
import FormikInput from "../../../components/UI/FormikInput";
import { providerRoute } from "../../../config/roles";
import { showAlertWithAction } from "../../../redux/actions/alerts";
import { getProviderProfile, isUserAdmin } from "../../../redux/actions/auth";
import images from "../../../utils/images";
import { SCHEMA } from "../Providers/schema/WorkingHoursSchema";
import { getAutomationDataAttr } from "../../../utils/automationUtils";

const FROM = "From";
const TO = "To";
const VALIDATION_ERROR_MSG = "End time must be after start time.";

/**
 * Sets the options array to display in the select dropdown.
 *
 * @returns {Array} the array of time slot options
 *
 */
const setTimeArray = () => {
    let arr = [];
    for (let hour = 0; hour < 24; hour++) {
        ["00", "15", "30", "45"].forEach((minuteEnding) => {
            let hourStr = `${hour < 10 ? "0" + hour : hour}:${minuteEnding}`;
            let localeDisplayTimeSlot = moment(hourStr, "HH:mm").format("hh:mm a");
            arr.push({
                value: hourStr,
                display: localeDisplayTimeSlot,
            });
        });
    }
    return arr;
};

const timeArrayOptions = setTimeArray();
const INITIAL_VALUES = {
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
};

class WorkingHours extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profileData: null,
            providerId: this.props.match.params.providerId
                ? this.props.match.params.providerId
                : null,
            formSchema: _.cloneDeep(SCHEMA),
            initialValues: { ...INITIAL_VALUES },
        };
    }

    async componentDidMount() {
        await getProfile(this, this.state.providerId);
        const { formSchema, initialValues } = this.enrichFormWithExistingHours();

        this.setState({
            formSchema,
            initialValues,
        });
    }

    enrichFormWithExistingHours = () => {
        const availability = this.state.profileData.availability;
        const initialValues = this.state.initialValues;
        const formSchema = [...this.state.formSchema].map((day) => {
            const dayName = day.name;

            const dayAvailability = availability[dayName];

            if (!dayAvailability || !dayAvailability.enabled || !dayAvailability.slots) {
                return day;
            }

            initialValues[dayName] = true;
            dayAvailability.slots.map((timeslot, i) => {
                const from = timeslot[0];
                const to = timeslot[1];
                const fromInputName = this.getTimeSlotInputName(dayName, FROM, i);
                const toInputName = this.getTimeSlotInputName(dayName, TO, i);
                day.slots.push([
                    this.convertDBSlotToFormInput(fromInputName, FROM, i),
                    this.convertDBSlotToFormInput(toInputName, TO, i),
                ]);
                initialValues[fromInputName] = from;
                initialValues[toInputName] = to;
            });
            return day;
        });
        return { formSchema, initialValues };
    };

    convertDBSlotToFormInput = (formInputName, position, i) => {
        return {
            name: formInputName,
            elementType: "select",
            elementConfig: {
                sentenceCase: false,
                options: timeArrayOptions,
                label: "",
                placeholder: position,
            },
        };
    };

    getTimeSlotInputName(day, position, i) {
        return `${day}_${i}_${position}`;
    }

    showValidationAlert = (message) => {
        this.props.showAlertWithAction(message);
    };

    // process the `dayWiseSlots` slots for creating error object
    processDayWiseSlotsForValidation = (dayWiseSlots) => {
        let errors = [];
        try {
            for (let [day, slots] of Object.entries(dayWiseSlots)) {
                let startIdx = 0;
                let maxIndex = Math.floor(Object.keys(slots).length / 2);

                for (let idx = startIdx; idx < maxIndex; idx++) {
                    if (!slots[`${day}_${idx}_${FROM}`] || !slots[`${day}_${idx}_${TO}`]) {
                        continue;
                    }

                    let slotValFrom = slots[`${day}_${idx}_${FROM}`].replace(":", "");
                    let slotValTo = slots[`${day}_${idx}_${TO}`].replace(":", "");

                    if (slotValFrom === "" && slotValTo === "") {
                        continue;
                    }

                    let numericSlotValFrom = Number(slotValFrom);

                    let numericSlotValTo = Number(slotValTo);

                    if (slotValTo !== "" && numericSlotValTo <= numericSlotValFrom) {
                        errors.push(`${day}_${idx}_${TO}`);
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }

        return errors;
    };

    // separating final formData based on weekday for validation
    getDayWiseSlots = (formData) => {
        let dayWiseSlots = {}; // will be like -> `{Monday:[{Monday_From_0:"09:00"}]}`
        for (let [key, value] of Object.entries(formData)) {
            const day = key.split(["_"])[0]; // extract the day string from string like "Monday_From_0"
            const hasDay = Object.prototype.hasOwnProperty.call(dayWiseSlots, day); //check if `dayWiseSlots` has already the `day` in it
            if (hasDay) {
                dayWiseSlots[day][key] = value; // if `hasDay === true` then add in already mapped day Object
            } else {
                dayWiseSlots[day] = { [key]: value }; // else add new day as Property of `dayWiseSlots`
            }
        }
        return dayWiseSlots;
    };

    //validate for prooper order of selected timeslots
    validateBeforeSubmit = (formData) => {
        let dayWiseSlots = this.getDayWiseSlots(formData);
        let errors = this.processDayWiseSlotsForValidation(dayWiseSlots);
        return errors;
    };

    onValidate = (values) => {
        let dayWiseSlots = this.getDayWiseSlots({ ...values });
        let errors = this.processDayWiseSlotsForValidation(dayWiseSlots);
        let modifiedErrors = {};
        errors.forEach((error) => {
            modifiedErrors[error] = VALIDATION_ERROR_MSG;
        });
        return modifiedErrors;
    };

    submitHandler = async (formData, actions) => {
        const { formSchema } = this.state;
        this.validateBeforeSubmit(formData);
        actions.setSubmitting(false);

        const availability = {};

        const availabilitySlotNames = formSchema
            .flatMap((el) => el.slots.flatMap((slot) => slot.map((slotItem) => slotItem.name)))
            .filter((slotName) => slotName.includes(FROM))
            .sort();

        availabilitySlotNames.forEach((availabilitySlotName) => {
            const fieldNameParts = availabilitySlotName.split("_");
            const day = fieldNameParts[0];
            const slotIndex = fieldNameParts[1];

            const associatedAvailabilitySlotName = this.getTimeSlotInputName(day, TO, slotIndex);

            const start = formData[availabilitySlotName];
            const end = formData[associatedAvailabilitySlotName];

            if (!start || !end) {
                return;
            }

            const slot = [start, end];
            if (availability[day] === undefined) {
                availability[day] = {
                    enabled: true,
                    slots: [slot],
                };
            } else {
                availability[day].slots.push(slot);
            }
        });

        try {
            await api.workingHours.saveWorkingHours({
                workingHours: availability,
                username: this.state.profileData.username,
            });
            // refetch profile so calendar updates
            if (!isUserAdmin()) {
                this.props.getProviderProfile(this.state.providerId);
            }
            actions.setSubmitting(false);
            this.props.history.push(this.getBackUrl());
        } catch (error) {
            console.log(error);
        }
    };

    getBackUrl = () => {
        let url = providerRoute + "/profile";
        return this.state.providerId ? `${url}/${this.state.providerId}` : url;
    };

    convertToUTC = (dateStr, timeStr) => moment(dateStr + " " + timeStr, "YYYY-MM-DD HH:mm");

    getUTCTimeSlots = (start, end) => {
        let dateArr = [];
        for (let hour = moment(start); hour.isBefore(end); hour.add(30, "minutes")) {
            dateArr.push({
                dateStr: hour.utc().format("YYYY-MM-DD"),
                timeStr: hour.utc().format("HH:mm"),
            });
        }
        return dateArr;
    };

    addNewTimeSlot = async (targetDay, formikProps) => {
        const newValues = { ...formikProps.values };
        const formSchema = [...this.state.formSchema].map((day, i) => {
            if (day.name !== targetDay) {
                return day;
            }
            const newSlotIndex = day.slots.length;
            const fromInputName = this.getTimeSlotInputName(targetDay, FROM, newSlotIndex);
            const toInputName = this.getTimeSlotInputName(targetDay, TO, newSlotIndex);
            day.slots.push([
                this.convertDBSlotToFormInput(fromInputName, FROM, newSlotIndex),
                this.convertDBSlotToFormInput(toInputName, TO, newSlotIndex),
            ]);
            newValues[fromInputName] = "";
            newValues[toInputName] = "";
            return day;
        });

        await formikProps.setValues(newValues);
        this.setState({
            formSchema,
        });
    };

    // set `To` field touched, if already populated and untouched slot's `From` field is changed
    setToFieldTouched = (fieldName, formikProps) => {
        formikProps.setFieldTouched(fieldName);
    };

    checkDay = async (dayName, formikProps) => {
        const newValues = _.cloneDeep(formikProps.values);

        newValues[dayName] = !formikProps.values[dayName];

        Object.keys(formikProps.values)
            .filter((name) => name.startsWith(`${dayName}_`))
            .forEach((name) => {
                delete newValues[name];
            });

        await formikProps.setValues(newValues);

        const formSchema = [...this.state.formSchema].map((day) => {
            if (dayName !== day.name) {
                return day;
            }

            day.slots = [];

            return day;
        });

        this.setState({ formSchema });
    };

    getRemoveBtn = (dayName, i, label, formikProps) => {
        let removeTimeBtnClass = "ProviderProfile__delete-time-slot ";
        removeTimeBtnClass += !formikProps.values[dayName] ? "ProviderProfile__time-slot-dis" : "";
        let onclick = formikProps.values[dayName]
            ? async () => {
                  const newValues = _.cloneDeep(formikProps.values);

                  const startInputName = this.getTimeSlotInputName(dayName, FROM, i);
                  const endInputName = this.getTimeSlotInputName(dayName, TO, i);

                  delete newValues[startInputName];
                  delete newValues[endInputName];

                  const formSchema = [...this.state.formSchema].map((day) => {
                      if (dayName !== day.name) {
                          return day;
                      }

                      day.slots.splice(i, 1);

                      return day;
                  });

                  this.setState({ formSchema });

                  await formikProps.setValues(newValues);

                  await formikProps.validateForm();
              }
            : () => {};
        return (
            <button
                type="button"
                className={"Btn Btn--clr-err p-0 ProviderProfile__delete-time-slot-btn"}
                style={{
                    backgroundColor: "white",
                    width: "Remove" === label ? 105 : 185,
                }}
                onClick={onclick}
                {...getAutomationDataAttr(`WorkingHoursFormRemoveTimeSlot${dayName}Slot${i}`)}>
                <div className={removeTimeBtnClass}>
                    <img width="8" src={images("./icons/cross-white-sm.svg")} alt="" />
                </div>
                <span>{label}</span>
            </button>
        );
    };

    getSlotRow = (day, slot, formikProps, i) => {
        const start = slot[0];
        const end = slot[1];
        return (
            <div key={`${day}_${i}`}>
                <div className={"d-flex align-items-center justify-content-between"}>
                    <span style={{ fontSize: 14 }}>
                        <b>Slot {i + 1}</b>
                    </span>
                    <div
                        key={start.name + "_" + i}
                        className={"d-block d-sm-none col-4"}
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}>
                        {this.getRemoveBtn(day, i, "Remove", formikProps)}
                    </div>
                </div>
                <div className={"row"}>
                    <div key={start.name + "_from_" + i} className={"col-6 col-lg-3"}>
                        <FormikInput
                            formEl={start}
                            errors={formikProps.errors}
                            touched={formikProps.touched}
                            testId={`WorkingHoursFormFieldTimeSlotFrom${day}Slot${i}`}
                        />
                    </div>
                    <div key={end.name + "_to_" + i} className={"col-6 col-lg-3"}>
                        <FormikInput
                            formEl={end}
                            errors={formikProps.errors}
                            touched={formikProps.touched}
                            testId={`WorkingHoursFormFieldTimeSlotTo${day}Slot${i}`}
                        />
                    </div>
                    <div
                        key={start.name + "_" + i}
                        className={"d-none d-sm-block col-3"}
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}>
                        {this.getRemoveBtn(day, i, "Remove Time Slot", formikProps)}
                    </div>
                </div>
            </div>
        );
    };

    renderForm = (formikProps) => {
        return (
            <Form id={"workingHoursForm"}>
                {this.renderControls(formikProps)}
                <div className="mx-auto px-0 ">
                    <div className="container">
                        <div className="row">
                            {this.state.formSchema.map((formEl, i) => {
                                const day = formEl.name;
                                formEl.elementConfig.checked = formikProps.values[day];
                                formEl.onclick = (day) => this.checkDay(day, formikProps);
                                let addTimeBtnClass = "ProviderProfile__add-time-slot ";
                                addTimeBtnClass += !formikProps.values[day]
                                    ? "ProviderProfile__time-slot-dis"
                                    : "";
                                return (
                                    <div className={"col-12"} key={day}>
                                        <div key={formEl.name}>
                                            <FormikInput
                                                formEl={formEl}
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                                testId={`WorkingHoursFormField${day}`}
                                            />
                                        </div>
                                        <fieldset
                                            disabled={formikProps.values[day] ? "" : "disabled"}>
                                            <div>
                                                {formEl.slots.map((slot, i) => {
                                                    return this.getSlotRow(
                                                        formEl.name,
                                                        slot,
                                                        formikProps,
                                                        i,
                                                    );
                                                })}
                                            </div>
                                            {formikProps.values[day] && (
                                                <div
                                                    key={day + "_add"}
                                                    className="d-flex align-items-center justify-content-between">
                                                    <button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "white",
                                                            width: 185,
                                                        }}
                                                        className={"Btn Btn--clr-sec p-0"}
                                                        {...getAutomationDataAttr(
                                                            `WorkingHoursFormAddNewTimeSlotButton${day}`,
                                                        )}
                                                        onClick={async () => {
                                                            await this.addNewTimeSlot(
                                                                day,
                                                                formikProps,
                                                            );
                                                        }}>
                                                        <div className={addTimeBtnClass}>
                                                            <img
                                                                width="8"
                                                                src={images(
                                                                    "./icons/cross-white-sm.svg",
                                                                )}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <span>Add New Time Slot</span>
                                                    </button>
                                                </div>
                                            )}
                                        </fieldset>
                                        <hr className="d-lg-block" />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="d-flex justify-content-center justify-content-lg-end">
                        <div>
                            <Button
                                type="button"
                                onClick={() => this.props.history.push(providerRoute + "/profile")}
                                className="Btn Btn--otl-pri Btn-sm mr-3">
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                className="Btn Btn--pri Btn-sm"
                                {...getAutomationDataAttr("WorkingHoursFormSubmit")}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        );
    };

    resetAll = (formikProps) => {
        this.setState({
            formSchema: _.cloneDeep(SCHEMA),
        });
        formikProps.setValues({ ...INITIAL_VALUES });
    };

    renderControls = (formikProps) => {
        return (
            <div className="d-flex">
                <Button
                    type="button"
                    onClick={() => this.resetAll(formikProps)}
                    style={{ border: "none" }}
                    className="Btn Btn--otl-pri Btn-sm mr-3"
                    {...getAutomationDataAttr("WorkingHoursFormResetButton")}>
                    Reset All
                </Button>
            </div>
        );
    };

    render() {
        return (
            <div data-public={"true"}>
                <div className="survey-back-link-wpr mx-auto fs-16 my-3 py-2">
                    <Link className="survey-back-link" to={this.getBackUrl()}>
                        <img className="img-fluid" src={images("./common/solidBckBtn.svg")} />
                        <p className="d-inline ml-2">Back to My Profile</p>
                    </Link>
                </div>
                <CardPrimary className="mx-auto" style={{ marginTop: 0 }}>
                    <div className="d-flex justify-content-between">
                        <span className="mb-3 mb-lg-0 fs-18 fw-bold" style={{ margin: 10 }}>
                            Working Hours
                        </span>
                    </div>
                    <div>
                        <hr className="d-block" />
                        <div>
                            {this.state.profileData && (
                                <CustomForm
                                    initialValues={this.state.initialValues}
                                    validate={this.onValidate}
                                    onSubmit={this.submitHandler}
                                    render={this.renderForm}
                                />
                            )}
                        </div>
                    </div>
                </CardPrimary>
            </div>
        );
    }
}

export default connect(null, { showAlertWithAction, getProviderProfile })(WorkingHours);
