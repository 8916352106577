// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import PropTypes from "prop-types";

const SidebarListItem = (props) => {
    const { title } = props;

    return (
        <div className="SidebarListItem">
            <span className="SidebarListItem__title fs-16 fw-bold d-block">{title}</span>
        </div>
    );
};

SidebarListItem.propTypes = {
    title: PropTypes.string.isRequired,
};

export default SidebarListItem;
