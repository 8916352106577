// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { useEffect, useState } from "react";
import { timeSlots } from "./constants";
import moment from "moment-timezone";
import { isAuthorized } from "../../../redux/actions/auth";
import { cloneDeep } from "lodash";
import * as events from "events";

const SCHEDULE_TYPES = {
    away: { value: "away", text: "Away" },
    video_consultation: { value: "video_consultation", text: "Video Consultation" },
};

const DailyScheduleWidget = (props) => {
    const {
        dateString,
        date,
        schedules,
        showNewSchedulePopUp,
        newScheduleDetails,
        showAwaySchedulePopUp,
        showActiveScheduleDetails,
    } = props;

    const calculateStartingCalendarWidth = () => {
        if (window.innerWidth > 1200) {
            return 978;
        } else if (window.innerWidth > 1000 && window.innerWidth <= 1200) {
            return 798;
        } else if (window.innerWidth > 775 && window.innerWidth <= 1000) {
            return 558;
        } else if (window.innerWidth > 583 && window.innerWidth <= 775) {
            return 378;
        } else {
            return window.innerWidth - 169;
        }
    };

    const [todaySchedule, setTodaySchedule] = useState(schedules);
    const [eventPositions, setEventPositions] = useState(null);
    const [calendarWidth, setCalendarWidth] = useState(calculateStartingCalendarWidth());

    useEffect(() => {
        const todaySchedule = schedules.filter((event) =>
            moment.utc(event.timestamp, "YYYY-MM-DD HH:mm").local().isSame(date, "day"),
        );

        calculateEventPositions(todaySchedule);

        setTodaySchedule(todaySchedule);
    }, [schedules, calendarWidth]);

    function calculateEventPositions(todaySchedule) {
        const eventVerticalPositions = todaySchedule
            .map(calculateVerticalPosition)
            .sort(sortByVerticalPosition);

        calculateHorizontalPositions(eventVerticalPositions);

        const eventPositions = eventVerticalPositions.reduce((obj, item) => {
            obj[item["callId"]] = item;
            return obj;
        }, {});

        setEventPositions(eventPositions);
    }

    useEffect(() => {
        function handleResize() {
            const calendarBody = document.getElementById("calendar-body");
            if (calendarBody) {
                const bodyDimensions = calendarBody.getBoundingClientRect();
                const width = bodyDimensions.width;
                if (width !== calendarWidth) {
                    setCalendarWidth(width);
                }
            }
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [calendarWidth]);

    const calculateVerticalPosition = (event) => {
        // converting UTC `timestamp` to Local timezone
        const localDateTime = moment.utc(event.timestamp, "YYYY-MM-DD HH:mm").local();
        const local_slot = localDateTime.format("HH:mm");
        const timeParts = local_slot.split(":");
        const hour = parseInt(timeParts[0]);
        const min = parseInt(timeParts[1]);
        const hourPosition = hour * 80;
        const minPosition = (80 / 60) * min;
        const positionTop = hourPosition + minPosition;
        const allotted_time = event["allotted_time"]
            ? event["allotted_time"]
            : getAllottedTimeDefault(event["type"]);
        const height = (80 / 60) * allotted_time;
        return {
            callId: event.callId,
            top: positionTop,
            height: height,
            bottom: positionTop + height,
            deleted: event.deleted,
        };
    };

    const sortByVerticalPosition = (eventA, eventB) => {
        if (eventA.top < eventB.top) {
            return -1;
        }
        if (eventA.top > eventB.top) {
            return 1;
        }
        if (eventA.bottom < eventB.bottom) {
            return -1;
        }
        if (eventA.bottom > eventB.bottom) {
            return 1;
        }
        return 0;
    };

    const calculateHorizontalPositions = (events) => {
        let eventGroups = [];
        let lastEventEnding = null;

        events.forEach((event) => {
            // Check if a new event group needs to be started
            if (lastEventEnding !== null && event.top >= lastEventEnding) {
                // The latest event is later than any of the event in the
                // current group. There is no overlap. Output the current
                // event group and start a new event group.
                packEvents(eventGroups, calendarWidth);
                eventGroups = []; // This starts new event group.
                lastEventEnding = null;
            }

            // Try to place the event inside the existing event groups
            let placed = false;

            for (let eventGroupIndex = 0; eventGroupIndex < eventGroups.length; eventGroupIndex++) {
                const eventGroup = eventGroups[eventGroupIndex];
                if (!collidesWith(eventGroup[eventGroup.length - 1], event)) {
                    eventGroup.push(event);
                    placed = true;
                    break;
                }
            }

            // It was not possible to place the event. Add a new event group
            if (!placed) {
                eventGroups.push([event]);
            }

            // Remember the latest event end time of the current group.
            // This is later used to determine if a new groups starts.
            if (lastEventEnding === null || event.bottom > lastEventEnding) {
                lastEventEnding = event.bottom;
            }
        });

        if (eventGroups.length > 0) {
            packEvents(eventGroups, calendarWidth);
        }
    };

    // Function does the layout for a group of events.
    const packEvents = (eventGroups, calendarWidth) => {
        const eventGroupsLength = eventGroups.length;
        const allCancelled = eventGroups.every((eventGroup) =>
            eventGroup.every((event) => event.deleted),
        );
        for (let eventGroupIndex = 0; eventGroupIndex < eventGroupsLength; eventGroupIndex++) {
            const eventGroup = eventGroups[eventGroupIndex];
            for (let eventIndex = 0; eventIndex < eventGroup.length; eventIndex++) {
                const event = eventGroup[eventIndex];
                const width =
                    calendarWidth / (allCancelled ? eventGroupsLength + 1 : eventGroupsLength);
                const left = `${
                    (eventGroupIndex / (allCancelled ? eventGroupsLength + 1 : eventGroupsLength)) *
                    100
                }%`;
                event.width = width;
                event.left = left;
            }
        }
    };

    // Check if two events collide.
    const collidesWith = (eventA, eventB) => {
        return eventA.bottom > eventB.top && eventA.top < eventB.bottom;
    };

    const renderTimeLegend = () => {
        let slotSet = timeSlots.map(({ slot }) => {
            let LocalTimeSlot = moment(slot, "HH:mm").format("hh:mm a");

            const slotLabel = (
                <div className="DailyWidget__timeslot-label position-absolute">
                    <span className="fs-16 PSA__text-gry">{LocalTimeSlot}</span>
                </div>
            );

            return (
                <div
                    key={slot + "empty-time-legend"}
                    className="DailyWidget__timeslot position-relative">
                    {slotLabel}
                </div>
            );
        });
        return slotSet;
    };

    const renderBlankSlots = () => {
        let slotSet = timeSlots.map(({ slot }) => {
            /** -----------------------------
             * below logic is used to show highlighted empty slot on click
             */
            let id_a = `-${slot}-a`; // eg. '-09:00-a'
            let id_b = `-${slot}-b`; // eg. '-09:00-b'
            let class_a =
                newScheduleDetails && newScheduleDetails.id == id_a
                    ? "DailyWidget__empty-slot-btn--active"
                    : "";
            let class_b =
                newScheduleDetails && newScheduleDetails.id == id_b
                    ? "DailyWidget__empty-slot-btn--active"
                    : "";
            /* ----------------------------- */

            /** Each hourly grid cell is split into two half hourly empty clickable slots to trigger pop ups for new/away schedules */
            const emptySlotClickables = (
                <div
                    data-slot={slot}
                    className="DailyWidget__empty-slot-wpr d-flex flex-column h-100">
                    <div
                        id={id_a}
                        role="button"
                        className={`DailyWidget__empty-slot-btn flex-fill ${class_a}`}></div>
                    <div
                        id={id_b}
                        role="button"
                        className={`DailyWidget__empty-slot-btn flex-fill ${class_b}`}></div>
                </div>
            );

            return (
                <div key={slot + "empty"} className="DailyWidget__timeslot position-relative">
                    {emptySlotClickables}
                </div>
            );
        });
        return slotSet;
    };

    const renderEachActiveSchedule = (scheduleDetails, slot, upperDuration, allotted_time) => {
        let LocalTimeslot = moment(slot, "HH:mm").format("hh:mm a");
        let LocalupperDuration = moment(upperDuration, "HH:mm").format("hh:mm a");
        let duration = LocalTimeslot + " - " + LocalupperDuration;
        let scheduleType = scheduleDetails.type;

        let activeScheduleBtnClasses = [
            "pl-4",
            "position-absolute",
            "float-right",
            "text-white",
            "flex-column",
            "justify-content-center",
            "align-items-start",
        ];

        if (allotted_time >= 30) {
            activeScheduleBtnClasses.push("d-flex");
        } else {
            activeScheduleBtnClasses.push("fs-12");
        }

        if (scheduleDetails.deleted) {
            activeScheduleBtnClasses.push("DailyWidget__timeslot-cancelled-btn");
        } else {
            activeScheduleBtnClasses.push("DailyWidget__timeslot-active-btn");
        }

        let onClickHandler = null;
        if (scheduleType === SCHEDULE_TYPES.away.value) {
            onClickHandler = (e) => {
                showAwaySchedulePopUp(e, scheduleDetails, duration);
            };
        } else if (scheduleType === SCHEDULE_TYPES.video_consultation.value) {
            onClickHandler = (e) => {
                showActiveScheduleDetails(e, scheduleDetails, duration);
            };
        }

        const top = eventPositions[scheduleDetails.callId].top;
        const height = eventPositions[scheduleDetails.callId].height;
        const left = eventPositions[scheduleDetails.callId].left;
        let width = eventPositions[scheduleDetails.callId].width;

        return (
            <button
                key={slot + "active" + scheduleDetails.callId}
                style={{ top: top, height: height, left: left, width: width }}
                className={activeScheduleBtnClasses.join(" ")}
                onClick={onClickHandler}>
                <span className="fs-12 fw-bold">
                    {!scheduleDetails.deleted ? scheduleDetails.patient_name : "Cancelled"}
                </span>
                {allotted_time < 30 && <span> </span>}
                <span className="fs-12">{duration}</span>
            </button>
        );
    };

    /**
     * timeslotHookMap is used to position the available schedules from the top of container with class `DailyWidget__body`,
     *  so that they are position at the correct slot interval.
     *  Each hourly slot is of height `80px` so half hourly slot will be `40px`
     *  By default we take each available schedule to be of half hour duration.
     *  So to position it at the correct slot interval with `position-absolute`,
     *  the product of `hookIndex` * `40` px is obtained.
     */
    const renderActiveSchedules = () => {
        let renderedSchedulesList = [];
        /** iterating over available schedules and rendering purple bar with absolute position */
        todaySchedule.map((sch) => {
            // converting UTC `timestamp` to Local timezone
            const localDateTime = moment.utc(sch.timestamp, "YYYY-MM-DD HH:mm").local();
            const local_slot = localDateTime.format("HH:mm");
            const allotted_time = sch["allotted_time"]
                ? sch["allotted_time"]
                : getAllottedTimeDefault(sch["type"]);
            const upperDuration = localDateTime.add(allotted_time, "minutes").format("HH:mm");
            renderedSchedulesList.push(
                renderEachActiveSchedule(sch, local_slot, upperDuration, allotted_time),
            );
        });
        return renderedSchedulesList;
    };

    const getAllottedTimeDefault = (type) => {
        if (isAuthorized("provider:behavioral") && type !== "away") {
            return 30;
        } else if (isAuthorized("provider:prescribe") && type !== "away") {
            return 30; //temp - this should go back to 15
        } else {
            return 30;
        }
    };

    return (
        /* ---------------- schedule availability widget ---------------------- */

        <div className="DailyWidget position-relative">
            {/* GRAY HEADER */}
            <div className="DailyWidget__header d-flex" data-public={"true"}>
                <div className="h-100 d-flex align-items-center justify-content-center fs-14 fw-bold">
                    <span>Time</span>
                </div>
                <div className="h-100 fs-14 d-flex flex-column justify-content-center align-items-center">
                    <span className="lh-0 mb-2 pb-1 fw-bold">{dateString.split(":")[0]}</span>
                    <span className="lh-0 mt-2 pt-1 PSA__text-gry">{dateString.split(":")[1]}</span>
                </div>
            </div>

            <div className={"d-flex w-100"}>
                <div className="DailyWidget__time-legend" data-public={"true"}>
                    {renderTimeLegend()}
                </div>

                {/* CALENDER GRID VIEW */}
                <div
                    onClick={showNewSchedulePopUp}
                    className="DailyWidget__body position-relative"
                    id={"calendar-body"}>
                    {renderBlankSlots()}
                    {calendarWidth && eventPositions && renderActiveSchedules()}
                    {/* `props.children` is the passed <ActiveScheduleDetails /> component */}
                    {props.children}
                </div>
            </div>
        </div>
    );
    /* ---------------------------  end  ------------------------------- */
};

export default DailyScheduleWidget;

export const getAllottedTimeDefault = (type) => {
    if (isAuthorized("provider:behavioral") && type !== "away") {
        return 30;
    } else if (isAuthorized("provider:prescribe") && type !== "away") {
        return 15;
    } else {
        return 30;
    }
};
