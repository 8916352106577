import { api } from "../APIRequests";
import moment from "moment-timezone";
import _ from "lodash";

export const getScheduleForToday = async (showLoader = false) => {
    const format = "YYYY-MM-DD HH:mm";
    const options = {
        params: { showLoader },
        queryParams: {
            local_start_date_time: moment().startOf("day").format(format),
            local_end_date_time: moment().endOf("day").format(format),
            timezone: moment.tz.guess(),
        },
    };
    return api.schedule.get_schedule({ options });
};

export const isCareTeamRestricted = (providerType, patientDetails) => {
    if (!patientDetails.is_wait_listed) {
        return false;
    }
    const relevantProviderTypesMap = {
        "Intake Behavioral Specialist": "BEHAVIORAL_INTAKE",
        Prescriber: "PRESCRIBE",
        "Behavioral Specialist": "BEHAVIORAL",
    };

    const wait_list_no_longer_schedule_with_roles = _.get(
        patientDetails,
        "wait_list_no_longer_schedule_with_roles",
        undefined,
    );

    if (wait_list_no_longer_schedule_with_roles === undefined) {
        return true;
    }

    return relevantProviderTypesMap[providerType]
        ? wait_list_no_longer_schedule_with_roles.includes(relevantProviderTypesMap[providerType])
        : true;
};
