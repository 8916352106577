// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as yup from "yup";
import {
    adultDobValidationSchemaFactory,
    dobValidationSchemaFactory,
    formatDate,
    noInsuranceCarrierValidationSchemaFactory,
} from "../../../../utils/yupSchemaUtils";
import moment from "moment-timezone";
import {
    DOB_VALIDATION_FACTORY,
    STANDARD_EMAIL_MATCH_VALIDATION,
} from "../../../../components/Common/FormHelpers/MemberInfo/ValidatorBuilder";

yup.addMethod(yup.date, "format", formatDate);

export const getPatientAge = (value) => {
    const momentDob = moment(value, "MM/DD/YYYY");
    return moment().diff(momentDob, "years", true);
};

export const dobValidationSchema = dobValidationSchemaFactory("MMDDYYYY");
export const patientDOBValidationSchema = dobValidationSchemaFactory("MM/DD/YYYY");

export const adultRecommendChildProgramDOBValidationSchema = adultDobValidationSchemaFactory(
    "MMDDYYYY",
    "Must be a valid date",
    `To register a child for care aged 5-17, you must first register yourself. Toggle the
    button below and you will be prompted to register your child when you finish.`,
);

export const VALIDATION_SCHEMA = () =>
    yup.object().shape({
        first_name: yup
            .string()
            .required("First Name is required")
            .matches(/^[a-zA-Z-]+$/, "Digits, space & special characters are not allowed"),
        last_name: yup
            .string()
            .required("Last Name is required")
            .matches(/^[a-zA-Z-]+$/, "Digits, space & special characters are not allowed"),
        dob: DOB_VALIDATION_FACTORY("MMDDYYYY", undefined, 18).required(
            "Date of Birth is required",
        ),
        email: yup.string().email("Enter a valid email address").required("Email is required"),
        email_validation: STANDARD_EMAIL_MATCH_VALIDATION()
            .email("Enter a valid email address")
            .required("Email is required")
            .test(
                "email-match",
                "Address Do Not Match. Please Check Email Spelling",
                function (value) {
                    if (!value) {
                        return false;
                    }
                    return this.parent.email === value;
                },
            ),
        gender: yup.string().required("Gender is required"),
        mobile: yup
            .string()
            .matches(/^\d{10}$/, "Contact Number must be a valid US number")
            .required("Mobile no. is required"),
        pcp_id: yup
            .string()
            .required("Primary Care Physician Information is a required")
            .nullable(),
        address: yup.object().shape({
            street: yup.string().required("Street is required").nullable(),
            city: yup
                .string()
                .required("City is required")
                .matches(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, "Enter a valid City")
                .nullable(),
            state: yup.string().required("State is required").nullable(),
            zip_code: yup
                .string()
                .required("Zip code is required")
                .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Enter a valid zip code")
                .nullable(),
        }),
        insurance: yup.array().when("is_spmi", {
            is: true,
            then: yup.array().of(
                yup.object().shape({
                    form: yup.object().shape({
                        state: yup.string().nullable(),
                        carrier: yup.string().required("Carrier is required").nullable(),
                        member_id: noInsuranceCarrierValidationSchemaFactory(
                            "carrier",
                            yup
                                .string()
                                .required("Member id is required")
                                .matches(/^[a-zA-Z0-9]*$/, "Enter a valid Member ID")
                                .nullable(),
                        ),
                        policy_holder_name: yup
                            .string()
                            .matches(
                                /^[a-z A-Z-]*$/,
                                "Digits, space & special characters are not allowed",
                            )
                            .nullable(),
                        policy_dob: yup
                            .string()
                            .matches(
                                /^(((01|03|05|07|08|10|12)(0[1-9]|1[0-9]|2[0-9]|3[01]))|((04|06|09|11)(0[1-9]|1[0-9]|2[0-9]|30))|(02(0[1-9]|1[0-9]|2[0-9])))(19|20)\d\d$/,
                                "Must be a valid date",
                            ),
                    }),
                }),
            ),
            otherwise: yup.array().of(
                yup.object().shape({
                    form: yup.object().shape({
                        state: yup.string().nullable(),
                        carrier: yup.string().nullable(),
                        member_id: yup
                            .string()
                            .matches(/^[a-zA-Z0-9]*$/, "Enter a valid Member ID")
                            .nullable(),
                        policy_holder_name: yup
                            .string()
                            .matches(
                                /^[a-z A-Z-]*$/,
                                "Digits, space & special characters are not allowed",
                            )
                            .nullable(),
                        policy_dob: yup
                            .string()
                            .matches(
                                /^(((01|03|05|07|08|10|12)(0[1-9]|1[0-9]|2[0-9]|3[01]))|((04|06|09|11)(0[1-9]|1[0-9]|2[0-9]|30))|(02(0[1-9]|1[0-9]|2[0-9])))(19|20)\d\d$/,
                                "Must be a valid date",
                            ),
                    }),
                }),
            ),
        }),
        emergency_name: yup
            .string()
            .matches(/^[a-z A-Z-]+$/, "Digits, space & special characters are not allowed"),
        emergency_contact_no: yup
            .string()
            .matches(/^\d{10}$/, "Contact Number must be a valid US number"),
    });

export const PATIENT_VALIDATION_SCHEMA = yup.object().shape({
    first_name: yup
        .string()
        .required("First Name is required")
        .matches(/^[a-zA-Z-]+$/, "Digits, space & special characters are not allowed"),
    last_name: yup
        .string()
        .required("Last Name is required")
        .matches(/^[a-zA-Z-]+$/, "Digits, space & special characters are not allowed"),
    dob: patientDOBValidationSchema.required("Date of Birth is required."),
    email: yup.string().email("Enter a valid email address").required("Email is required"),
    email_validation: yup
        .string()
        .email("Enter a valid email address")
        .required("Email is required")
        .test("email-match", "Address Do Not Match. Please Check Email Spelling", function (value) {
            if (!value) {
                return false;
            }
            return this.parent.email === value;
        }),
    gender: yup.string().required("Gender is required"),
    mobile: yup
        .string()
        .matches(/^\d{10}$/, "Contact Number must be a valid US number")
        .required("Mobile no. is required"),
    pcp_id: yup.string().required("Primary Care Physician Information is a required").nullable(),
    address: yup.object().shape({
        street: yup.string().required("Street is required").nullable(),
        city: yup
            .string()
            .required("City is required")
            .matches(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, "Enter a valid City")
            .nullable(),
        state: yup.string().required("State is required").nullable(),
        zip_code: yup
            .string()
            .required("Zip code is required")
            .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Enter a valid zip code")
            .nullable(),
    }),
    insurance: yup.array().of(
        yup.object().shape({
            form: yup.object().shape({
                state: yup.string().nullable(),
                carrier: yup.string().nullable(),
                // card_front: yup.string().required('front image is required').nullable(),
                // card_back: yup.string().required('back image is required').nullable(),
                member_id: yup
                    .string()
                    .matches(/^[a-zA-Z0-9]*$/, "Enter a valid Member ID")
                    .nullable(),
                policy_holder_name: yup
                    .string()
                    .matches(/^[a-z A-Z-]*$/, "Digits, space & special characters are not allowed")
                    .nullable(),
                policy_dob: yup
                    .string()
                    .matches(
                        /^(((01|03|05|07|08|10|12)(0[1-9]|1[0-9]|2[0-9]|3[01]))|((04|06|09|11)(0[1-9]|1[0-9]|2[0-9]|30))|(02(0[1-9]|1[0-9]|2[0-9])))(19|20)\d\d$/,
                        "Must be a valid date",
                    ),
            }),
        }),
    ),
    emergency_name: yup
        .string()
        .matches(/^[a-z A-Z-]+$/, "Digits, space & special characters are not allowed"),
    emergency_contact_no: yup
        .string()
        .matches(/^\d{10}$/, "Contact Number must be a valid US number"),
});

export const CHILD_VALIDATION_SCHEMA = () =>
    yup.object().shape({
        first_name: yup
            .string()
            .required("First Name is required")
            .matches(/^[a-zA-Z-]+$/, "Digits, space & special characters are not allowed"),
        last_name: yup
            .string()
            .required("Last Name is required")
            .matches(/^[a-zA-Z-]+$/, "Digits, space & special characters are not allowed"),
        dob: dobValidationSchema
            .required("Date of Birth is required.")
            .test("age-min", "Cannot be younger than 5", function (value) {
                if (!value) {
                    return false;
                }
                return getPatientAge(value) >= 5;
            })
            .test(
                "age-max",
                "Cannot be older than 18 for Teens + Kids Program. Patient must register themselves from the aptihealth website.",
                function (value) {
                    if (!value) {
                        return false;
                    }
                    return getPatientAge(value) <= 18;
                },
            ),
        email: yup.string().email("Enter a valid email address").required("Email is required"),
        gender: yup.string().required("Gender is required"),
        mobile: yup
            .string()
            .matches(/^\d{10}$/, "Contact Number must be a valid US number")
            .required("Mobile no. is required"),
        guardian_id: yup
            .string()
            .required("Primary Care Physician Information is a required")
            .nullable(),
        address: yup.object().shape({
            street: yup.string().required("Street is required").nullable(),
            city: yup
                .string()
                .required("City is required")
                .matches(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, "Enter a valid City")
                .nullable(),
            state: yup.string().required("State is required").nullable(),
            zip_code: yup
                .string()
                .required("Zip code is required")
                .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Enter a valid zip code")
                .nullable(),
        }),
        insurance: yup.object().shape({
            carrier: yup.string().required("Insurance Company is required").nullable(),
            member_id: noInsuranceCarrierValidationSchemaFactory(
                "carrier",
                yup
                    .string()
                    .required("Member ID is required")
                    .matches(/^[a-zA-Z0-9]*$/, "Enter a valid Member ID")
                    .nullable(),
            ),
        }),
        emergency_name: yup
            .string()
            .required("Emergency contact is required")
            .matches(/^[a-z A-Z-]+$/, "Digits, space & special characters are not allowed"),
        emergency_contact_no: yup
            .string()
            .required("Emergency contact's phone number is required")
            .matches(/^\d{10}$/, "Contact Number must be a valid US number"),
    });
