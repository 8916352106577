import React from "react";
import { createClassNameString } from "../../utils";
import PropTypes from "prop-types";
import { Text } from "../../Text";
import { Button } from "../../Button";
import { Card } from "../../Card";
import "./styles.scss";

export const OnDemandWelcomeEmailSuccess = ({ className, buttonClick, ...props }) => {
    return (
        <Card className={createClassNameString(["apti-OnDemandWelcomeEmailSuccess", className])}>
            <div className={"text-center"}>
                <Text className={"apti-OnDemandWelcomeEmailSuccess--title"} color={"black"}>
                    Success!
                </Text>
            </div>
            <div className={"text-center p-4"}>
                <Text className={"apti-OnDemandWelcomeEmailSuccess--sub-title"}>
                    The welcome email has been resent. Please instruct the member to check their
                    inbox and complete registration.
                </Text>
            </div>
            <div className={"d-flex align-items-center justify-content-center px-5 pb-4 pt-2"}>
                <Button
                    className={"primary apti-TwoButtonCard-button-center"}
                    onClick={buttonClick}>
                    OK
                </Button>
            </div>
        </Card>
    );
};

OnDemandWelcomeEmailSuccess.propTypes = {
    className: PropTypes.string,
    buttonClick: PropTypes.func,
};

OnDemandWelcomeEmailSuccess.defaultProps = {
    className: undefined,
    buttonClick: () => {},
};
