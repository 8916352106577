// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import Hr from "../../UI/Hr";
import images from "../../../utils/images";
import Button from "../../UI/Button";
import { withRouter } from "react-router-dom";
import { toTitleCase } from "../../../utils/filters";
import { getCanStartCall } from "../../../utils/calendar/util";

/**
 *
 * TODO: properly align three dots menu
 */
const SheduleListItemMobile = (props) => {
    const {
        provider_avatar,
        email,
        configs,
        callId,
        provider_name,
        provider_role,
        provider_username,
        menuClickHandler,
        scheduledDate,
        scheduledTime,
        hideThreeDotsMenu,
        timestamp,
        allotted_time,
        isToday,
        isMorning,
        isAfternoon,
        isEvening,
        currentTime,
    } = props;

    /**
     * Three dots menu in some places is not meant to be shown,
     * that's why it is conditionally rendered in return.
     */
    const threeDotsMenuJsx = (
        <span className="col-1 pl-1">
            <img
                role="button"
                onClick={(e) => menuClickHandler(e, callId, provider_username)}
                className="cursor px-2"
                src={images("./icons/three-dots-menu.svg")}
                alt="three dots menu"
            />
        </span>
    );

    const renderTimeOfDay = () => {
        if (isMorning) {
            return "Morning";
        }

        if (isAfternoon) {
            return "Afternoon";
        }

        if (isEvening) {
            return "Evening";
        }

        return "Time";
    };

    return (
        <div className="d-flex d-lg-none flex-column">
            <div className="d-flex w-100 mb-3 align-items-center">
                <div className="mr-3">
                    <img
                        width="36"
                        src={provider_avatar || images("./common/avatar.png")}
                        alt="provider's avatar"
                    />
                </div>
                <div className="w-100">
                    <div className="fs-16 fw-bold">{provider_name}</div>
                    <div className="fs-14 ASCH-text-gry">
                        {toTitleCase(provider_role.replace("_", " "))}
                    </div>
                    <Button
                        onClick={() => props.history.push(`/app/video-call?callId=${callId}`)}
                        className={"Btn Btn-xs fs-12 Btn--pri"}
                        type={"button"}
                        disabled={
                            !getCanStartCall(currentTime, timestamp, allotted_time, email, configs)
                        }>
                        Join Now
                    </Button>
                </div>
                {!hideThreeDotsMenu && threeDotsMenuJsx}
            </div>
            <div className="ml-5 pl-1 position-relative">
                <Hr />
                <div className="d-flex text-center justify-content-center mt-2 fs-14">
                    <div className="w-50">
                        <span className="ASCH-text-sec fw-bold">{isToday ? "Today" : "Date"}</span>
                        <br />
                        <span className="fs-16">{scheduledDate}</span>
                    </div>
                    <div className="w-50">
                        <span className="ASCH-text-sec fw-bold">{renderTimeOfDay()}</span>
                        <br />
                        <span className="fs-16">{scheduledTime}</span>
                    </div>
                </div>
                <div className="ASCH__veritcal-bar position-absolute"></div>
            </div>
        </div>
    );
};

export default withRouter(SheduleListItemMobile);
