// Copyright aptihealth, inc. 2021 All Rights Reserved

import React from "react";
import { withRouter } from "react-router-dom";
import { AptihealthIcon } from "../../../../component-library/Icons/components/AptihealthIcon";
import { BhsIcon } from "../../../../component-library/Icons/components/BhsIcon";
import { CaseManagerIcon } from "../../../../component-library/Icons/components/CaseManagerIcon";
import { IntakeIcon } from "../../../../component-library/Icons/components/IntakeIcon";
import { PcpIcon } from "../../../../component-library/Icons/components/PcpIcon";
import { PeerIcon } from "../../../../component-library/Icons/components/PeerIcon";
import { PrescriberIcon } from "../../../../component-library/Icons/components/PrescriberIcon";
import { ICON_SIZES, ICON_TYPES } from "../../../../constants/icons";
import images from "../../../../utils/images";
import "../styles.scss";

const MessagesWidget = (props) => {
    const { profile, totalUnreadMessages, sendBirdContacts } = props;

    const renderNoMessages = () => {
        return (
            <div className={"text-center mt-1 mx-4"}>
                <div className="fs-16 fw-bold">No Messages</div>
                <div className="fs-14">
                    Messages will help you communicate with your care team throughout your
                    aptihealth journey. New message notifications will appear here.
                </div>
            </div>
        );
    };

    const getCallIcon = (providerRole) => {
        switch (providerRole) {
            case "PCP":
                return <PcpIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconWhite"}/>;
            case "Prescriber":
                return <PrescriberIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconWhite"}/>;
            case "Behavioral Specialist":
                return <BhsIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconWhite"}/>;
            case "Intake Behavioral Specialist":
                return <IntakeIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconWhite"}/>;
            case "Peer":
                return <PeerIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconWhite"}/>;
            case "Case Manager":
                return <CaseManagerIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconWhite"}/>;
            default:
                return <AptihealthIcon iconType={ICON_TYPES.CIRCLE} iconSize={ICON_SIZES.LARGE} className={"IconWhite"}/>;
        }
    };

    const renderContacts = (careTeamMembers) => {
        return (
            <>
                {careTeamMembers
                    .filter(
                        (member) =>
                            sendBirdContacts[member["provider_id"]] &&
                            sendBirdContacts[member["provider_id"]]["shouldShow"],
                    )
                    .sort(
                        (memberA, memberB) =>
                            sendBirdContacts[memberB["provider_id"]]["unreadMessageCount"] -
                            sendBirdContacts[memberA["provider_id"]]["unreadMessageCount"],
                    )
                    .slice(0, 2)
                    .map((member) => {
                        const unreadMessageCount =
                            sendBirdContacts[member["provider_id"]]["unreadMessageCount"];
                        return (
                            <div
                                key={`messages-widget-${member["provider_id"]}`}
                                className={"py-2"}>
                                <div
                                    className={"d-flex cursor"}
                                    role={"button"}
                                    onClick={() =>
                                        props.history.push(
                                            `/app/messages?memberId=${member["provider_id"]}`,
                                        )
                                    }>
                                    <div className={"pr-lg-3 pr-2"}>
                                        {getCallIcon(member["type"])}
                                    </div>
                                    <div className="fs-16 fw-bold mt-2 widget-item-txt-wrapper">
                                        <div className="fs-16 fw-bold widget-item-txt">
                                            <div className="d-inline-flex">
                                                <div className={"mt-2 lh-15"}>{member.name}</div>
                                                {unreadMessageCount > 0 && (
                                                    <span
                                                        className={
                                                            "fs-14 item-count item-count-ter mt-1"
                                                        }
                                                        style={{ margin: "2px 5px" }}>
                                                        {unreadMessageCount}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </>
        );
    };

    return (
        <>
            <div
                className={
                    "dashboard-widget-card dashboard-widget-card-sm dashboard-widget-card-ter px-3 py-2 mb-3 " +
                    "text-white"
                }>
                <div className={"fs-lg-22 fs-20 fw-bold mb-1 d-inline-flex"}>
                    <div className={"mt-2 lh-18"}>Your Messages</div>{" "}
                    {totalUnreadMessages > 0 && (
                        <span className={"fs-14 item-count item-count-ter"}>
                            {totalUnreadMessages}
                        </span>
                    )}
                </div>
                {sendBirdContacts &&
                    Object.keys(sendBirdContacts).length === 0 &&
                    renderNoMessages()}
                {sendBirdContacts &&
                    Object.keys(sendBirdContacts).length > 0 &&
                    renderContacts(profile.care_team_members)}
                {sendBirdContacts && Object.keys(sendBirdContacts).length > 2 && (
                    <div
                        className={"widget-view-all d-flex fw-bold fs-14 cursor Btn-xs-2"}
                        role={"button"}
                        onClick={() => props.history.push("/app/messages")}>
                        <img src={images("./icons/common/view-all-white.svg")} alt={"+"} />
                        <div className={"mt-1 ml-1"}>View All</div>
                    </div>
                )}
            </div>
        </>
    );
};

export default withRouter(MessagesWidget);
