import React from "react";

import { getIn } from "formik";
import "./styles.scss";
import { Text, TextColors, TextTypes, Toggle } from "../../../component-library";

// NOTE: THIS PATTERN WAS COPIED FROM the care management form
export const WaitlistFormToggle = ({ name, formikProps }) => {
    const isToggled = formikProps.values[name];

    const handleChange = async () => {
        await formikProps.setFieldValue(name, !isToggled);
    };

    return <Toggle onClick={handleChange} name={name} checked={isToggled} />;
};
