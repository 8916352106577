import React, { useState } from "react";
import images from "../../../utils/images";
import "./style.scss";
import FormikInput from "../FormikInput";
import DatePicker from "../DatePicker";
import moment from "moment-timezone";

const DatePickerWithDisplay = (props) => {
    const { formEl, formikProps, dateStr, dateClickHandler, touched } = props;

    const [showDatePicker, setShowDatePicker] = useState(false);

    return (
        <>
            <div className={"d-flex"}>
                <FormikInput
                    inputClasses={formEl.inputClasses}
                    formEl={formEl}
                    errors={formikProps.errors}
                    touched={formikProps.touched}
                    disabled={"disabled"}
                />
                <div
                    style={{
                        marginTop:
                            formEl.inputClasses && formEl.inputClasses.includes("Input--sm")
                                ? 37
                                : 42,
                        marginLeft: 10,
                    }}
                    className={"cursor"}
                    role={"button"}
                    onClick={() => setShowDatePicker(!showDatePicker)}>
                    <img src={images("./icons/calender-icon.svg")} alt={"pick date"} />
                </div>
            </div>
            {showDatePicker && (
                <div className="inline-date-picker">
                    <DatePicker
                        externalCtx={dateStr ? moment(dateStr, "MM/DD/YYYY") : null}
                        onDaySelect={(dateStr, eventType) => {
                            touched && touched();

                            dateClickHandler(dateStr, eventType, formEl);
                            if (eventType !== "monthChange") {
                                setShowDatePicker(false);
                            }
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default DatePickerWithDisplay;
