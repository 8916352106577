import React, { useEffect, useState } from "react";
import images from "../../../utils/images";

export default function Header(props) {
    const [animate, setAnimate] = useState("");

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        setTimeout(function () {
            setAnimate("loaded");
        }, 1000);

        setTimeout(function () {
            setAnimate("loaded cta");
        }, 2000);
    }, []);

    const goToShareResults = () => {
        window.scrollTo({
            top: document.getElementById("shareResults").offsetTop - 40,
            behavior: "smooth",
        });
    };

    return (
        <header id="a5-summary-header" className={animate}>
            <div className="wrap">
                <a href="/" className="a5-summary-header-logo">
                    <span>
                        <img src={images("./common/logo.svg")} alt="aptihealth" />
                    </span>
                    {props.name ? null : (
                        <strong>
                            Transforming
                            <br /> Behavioral Healthcare
                        </strong>
                    )}
                </a>
                {props.name ? (
                    <h5>
                        <span>
                            <em>&amp;</em> <strong>{props.name}</strong>
                        </span>
                    </h5>
                ) : null}
                {props.isPublic && (
                    <button className={"btn"} onClick={goToShareResults}>
                        Send Results <br />
                        to Your <br />
                        Healthcare Provider
                    </button>
                )}
            </div>
        </header>
    );
}
