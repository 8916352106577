/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const ChevronLeftIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="ChevronLeft"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.7071 24.7071C20.0976 24.3166 20.0976 23.6834 19.7071 23.2929L12.4142 16L19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289C19.3166 6.90237 18.6834 6.90237 18.2929 7.29289L9.58579 16L18.2929 24.7071C18.6834 25.0976 19.3166 25.0976 19.7071 24.7071Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="ChevronLeft"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.4142 25.4142C21.1953 24.6332 21.1953 23.3668 20.4142 22.5858L13.8284 16L20.4142 9.41421C21.1953 8.63317 21.1953 7.36684 20.4142 6.58579C19.6332 5.80474 18.3668 5.80474 17.5858 6.58579L8.17157 16L17.5858 25.4142C18.3668 26.1953 19.6332 26.1953 20.4142 25.4142Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="ChevronLeft"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M-8.74228e-07 20C-1.35705e-06 8.9543 8.9543 -2.29875e-06 20 -2.78158e-06C31.0457 -3.2644e-06 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 -3.91405e-07 31.0457 -8.74228e-07 20ZM23.5607 24.9393C24.1464 25.5251 24.1464 26.4749 23.5607 27.0607C22.9749 27.6464 22.0251 27.6464 21.4393 27.0607L14.3787 20L21.4393 12.9393C22.0251 12.3536 22.9749 12.3536 23.5607 12.9393C24.1464 13.5251 24.1464 14.4749 23.5607 15.0607L18.6213 20L23.5607 24.9393Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

ChevronLeftIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

ChevronLeftIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
