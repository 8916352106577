// Copyright aptihealth, inc. 2022 All Rights Reserved
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { api } from "../../../APIRequests";
import { CompletedProfileInsurance } from "../../../component-library/MemberProfileInsurance";
import { Card, InsuranceEligibilityRecord, Link } from "../../../component-library";
import { calculateAge } from "../../../utils/momentUtils";
import "./styles.scss";
import {
    formatInsuranceType,
    formatTimestamp,
    getFormattedStatus,
    getStatusClass,
    getStatusDefinition,
    showRawResponse,
} from "../../../component-library/InsuranceEligibilityRecord/utils";
import { formatMobileNumber } from "../../../utils/filters";
import _ from "lodash";
import ImageFullPreview from "../../Common/ImageFullPreview";
import { isAuthorized } from "../../../redux/actions/auth";
import {
    getInsuranceImagePresignedUrl,
    INSURANCE_IMAGE_FILE_PRIMARY_BACK_SUB_TYPE,
    INSURANCE_IMAGE_FILE_PRIMARY_FRONT_SUB_TYPE,
    PATIENT_INSURANCE_IMAGE_FILE_TYPE,
} from "../../../utils/fileUtil";

const InsuranceEligibilityRecordManagement = (props) => {
    const [eligibilityRecord, setEligibilityRecord] = useState({
        id: "",
        email: "",
        username: "",
        mobile: "",
        registration_date: "",
        carrier: "",
        status: "",
        timestamp: "",
        check_type: "",
        number_of_attempts: 0,
        insurance_type: "",
        insurance_details: {
            card_back: "",
            card_front: "",
            dob: "",
            first_name: "",
            group_id: "",
            last_name: "",
            member_id: "",
            policy_dob: "",
            policy_holder_name: "",
            policy_relationship: "",
            state: "",
        },
        errors: [],
        raw_response_exists: false,
        raw_response_key: "",
    });

    const [insuranceCardImgInfo, setInsuranceCardImgInfo] = useState({
        frontSrcUrl: "",
        frontContentType: "",
        backSrcUrl: "",
        backContentType: "",
    });

    const [imgFullPreview, setImgFullPreview] = useState({
        url: null,
        contentType: null,
    });

    async function getInsuranceEligibilityRecord() {
        const data = {
            query_type: "id",
            id: props.match.params.id,
        };
        const eligibilityResponse = await api.admin.fetch_insurance_eligibility_records({ data });
        if (!eligibilityResponse) {
            return;
        }

        if (!eligibilityResponse.insurance_details) {
            eligibilityResponse["insurance_details"] = eligibilityRecord.insurance_details;
        }
        setEligibilityRecord(eligibilityResponse);
        return eligibilityResponse;
    }

    useEffect(() => {
        (async () => {
            const eligibilityResponse = await getInsuranceEligibilityRecord();
            const insuranceDetails = eligibilityResponse.insurance_details;
            if (!insuranceDetails) {
                return;
            }
            const newInsuranceCardImgInfo = _.cloneDeep(insuranceCardImgInfo);
            const frontImgKey = insuranceDetails.card_front;
            if (frontImgKey) {
                const frontImgResponse = await getInsuranceImagePresignedUrl(
                    eligibilityResponse.username,
                    PATIENT_INSURANCE_IMAGE_FILE_TYPE,
                    INSURANCE_IMAGE_FILE_PRIMARY_FRONT_SUB_TYPE,
                );
                newInsuranceCardImgInfo["frontSrcUrl"] = frontImgResponse.message;
                newInsuranceCardImgInfo["frontContentType"] = frontImgResponse.content_type;
            }

            const backImgKey = insuranceDetails.card_back;
            if (backImgKey) {
                const backImgResponse = await getInsuranceImagePresignedUrl(
                    eligibilityResponse.username,
                    PATIENT_INSURANCE_IMAGE_FILE_TYPE,
                    INSURANCE_IMAGE_FILE_PRIMARY_BACK_SUB_TYPE,
                );
                newInsuranceCardImgInfo["backSrcUrl"] = backImgResponse.message;
                newInsuranceCardImgInfo["backContentType"] = backImgResponse.content_type;
            }
            setInsuranceCardImgInfo(newInsuranceCardImgInfo);
        })();
    }, []);

    const runEligibilityCheck = async () => {
        const data = eligibilityRecord.insurance_details;
        data["check_type"] = "manual_admin";
        //todo change to v2
        await api.patient.fetch_insurance_eligibility_v2({ data });
        await getInsuranceEligibilityRecord();
    };

    const formatFullName = (insuranceDetails) => {
        return `${insuranceDetails.first_name || ""} ${insuranceDetails.last_name || ""}`;
    };

    const formatDOB = (insuranceDetails) => {
        if (!insuranceDetails.dob) {
            return "";
        }

        return `${eligibilityRecord.insurance_details.dob} (${calculateAge(
            eligibilityRecord.insurance_details.dob,
        )})`;
    };

    const formatMobile = (eligibilityRecord) => {
        if (!eligibilityRecord.mobile) {
            return "";
        }
        return formatMobileNumber(eligibilityRecord.mobile);
    };

    const formatPatientInfo = (eligibilityRecord) => {
        if (!eligibilityRecord.email) {
            return;
        }

        const insuranceDetails = eligibilityRecord.insurance_details;

        if (!eligibilityRecord.mobile) {
            return `${formatDOB(insuranceDetails)} • ${eligibilityRecord.email}`;
        }

        return `${formatDOB(insuranceDetails)} • ${formatMobile(eligibilityRecord)} • ${
            eligibilityRecord.email
        }`;
    };

    const formatRegistrationDate = (registrationDate) => {
        if (!registrationDate) {
            return;
        }

        return `Registered on: ${formatTimestamp(eligibilityRecord.registration_date)}`;
    };

    return (
        <>
            <div className={"container my-5"}>
                <div className={"pb-3"}>
                    <Card className={"insurance-eligibility-patient-info"}>
                        <div className={"d-flex justify-content-between"}>
                            <div className={"fs-18 fw-bold"}>
                                {formatFullName(eligibilityRecord.insurance_details)}
                            </div>
                            {eligibilityRecord.username && (
                                <Link
                                    href={`/app/patient/${eligibilityRecord.username}/profile/v2`}>
                                    View Profile
                                </Link>
                            )}
                        </div>
                        <div className={"d-lg-none d-md-block d-sm-block"}>
                            <div>{formatDOB(eligibilityRecord.insurance_details)}</div>
                            <div>{formatMobile(eligibilityRecord)}</div>
                            <div>{eligibilityRecord.email}</div>
                        </div>
                        <div className={"d-lg-block d-md-none d-none"}>
                            <span>{formatPatientInfo(eligibilityRecord)}</span>
                        </div>
                        <div>
                            <span>
                                {formatRegistrationDate(eligibilityRecord.registration_date)}
                            </span>
                        </div>
                    </Card>
                </div>

                <div className={"pb-3"}>
                    {imgFullPreview.url && (
                        <ImageFullPreview
                            url={imgFullPreview.url}
                            contentType={imgFullPreview.contentType}
                            close={() =>
                                setImgFullPreview({
                                    url: null,
                                    contentType: null,
                                })
                            }
                        />
                    )}

                    <Card>
                        <CompletedProfileInsurance
                            title={"Insurance Information"}
                            insuranceCompany={eligibilityRecord.carrier}
                            policyHolder={eligibilityRecord.insurance_details.policy_relationship}
                            memberId={eligibilityRecord.insurance_details.member_id}
                            groupId={eligibilityRecord.insurance_details.group_id}
                            stateOfInsurancePlan={eligibilityRecord.insurance_details.state}
                            policyHolderName={
                                eligibilityRecord.insurance_details.policy_holder_name
                            }
                            policyHolderDOB={eligibilityRecord.insurance_details.policy_dob}
                            insuranceCardImgFrontSrc={insuranceCardImgInfo.frontSrcUrl}
                            insuranceCardImgBackSrc={insuranceCardImgInfo.backSrcUrl}
                            onInsuranceCardImgFrontClick={() =>
                                setImgFullPreview({
                                    url: insuranceCardImgInfo.frontSrcUrl,
                                    contentType: insuranceCardImgInfo.frontContentType,
                                })
                            }
                            onInsuranceCardImgBackClick={() =>
                                setImgFullPreview({
                                    url: insuranceCardImgInfo.backSrcUrl,
                                    contentType: insuranceCardImgInfo.backContentType,
                                })
                            }
                            isEditable={false}
                        />
                    </Card>
                </div>

                <InsuranceEligibilityRecord
                    className={getStatusClass(eligibilityRecord.status)}
                    insuranceType={formatInsuranceType(eligibilityRecord.insurance_type)}
                    status={getFormattedStatus(eligibilityRecord.status)}
                    copay={eligibilityRecord.copay}
                    statusDefinition={getStatusDefinition(eligibilityRecord.status)}
                    numberOfAttempts={eligibilityRecord.number_of_attempts}
                    transactionId={eligibilityRecord.control_number}
                    timestamp={formatTimestamp(eligibilityRecord.timestamp)}
                    allowRetry={true}
                    retryButtonOnClick={runEligibilityCheck}
                    carrier={eligibilityRecord.carrier}
                    plan={eligibilityRecord.plan}
                    errors={eligibilityRecord.errors}
                    showDownloadRawResponse={
                        isAuthorized("admin:customer_success_executive") &&
                        eligibilityRecord.raw_response_exists
                    }
                    rawResponseKey={eligibilityRecord.raw_response_key}
                    downloadResponseButtonOnClick={() => showRawResponse(eligibilityRecord)}
                />
            </div>
        </>
    );
};

export default withRouter(InsuranceEligibilityRecordManagement);
