// Copyright aptihealth, inc. 2022 All Rights Reserved
import React from "react";
import { withRouter } from "react-router-dom";
import CardSecondary from "../../../../../components/UI/Card/CardSecondary";
import "./styles.scss";
import images from "../../../../../utils/images";
import Button from "../../../../../components/UI/Button";

const PostSessionsSurveyConfirmation = (props) => {
    return (
        <div className={"d-flex justify-content-center align-items-center"}>
            <CardSecondary
                className={
                    "PssConfirmation d-flex flex-column justify-content-center align-items-center"
                }>
                <img width="100" src={images("./icons/icn-hand-heart.png")} alt="heart hand icon" />
                <div className="py-4">
                    <p className="PssConfirmation__title pb-4">Keep up the good work!</p>
                    <p className="PssConfirmation__text pb-5">
                        Thank you for your feedback, it helps us make sure you get the most out of
                        your aptihealth experience. We’ll check in again soon.
                    </p>
                </div>
                <Button
                    className="Btn Btn--pri Btn-sm"
                    onClick={() => props.history.push("/app/home")}>
                    Done
                </Button>
            </CardSecondary>
        </div>
    );
};

export default withRouter(PostSessionsSurveyConfirmation);
