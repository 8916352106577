import React, {useEffect} from "react";
import Spinner from "../../UI/Spinner";
import {ChannelsSideBar} from "./Channel/ChannelsSideBar";
import {Chat} from "./Chat/Chat";
import PropTypes from "prop-types";
import {createClassNameString} from "../../../component-library";
import {getPatientProfile, isUserPatient} from "../../../redux/actions/auth";
import {useDispatch} from "react-redux";

const FIVE_MINUTES_IN_MS = 300000;

export const MessagesContainer = (props) => {
    const {
        channels,
        client,
        fetchMessagingInfo,
        isInactive,
        isMobile,
        loadingMoreChannels,
        mobileView,
        nextChannelPageQuery,
        onMessageReceived,
        onSelectChannel,
        selectedChannel,
        showMoreChannels,
        switchMessagesOrAddChannelView,
        switchMobileView,
        user
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        // Check for care team member updates to set chats to readonly
        if (isUserPatient()) {
            const interval = setInterval(() => {
                dispatch(getPatientProfile(false));
            }, FIVE_MINUTES_IN_MS);
            return () => {
                clearInterval(interval);
            };
        }
    }, []);

    const browserChannelSideBarClassNames = "col-12 col-sm-5 col-md-5 col-lg-4 col-xl-3";
    const channelSideBarClassName = createClassNameString([
        "Messages__ChannelSideBar",
        "container",
        isMobile ? "" : browserChannelSideBarClassNames,
    ]);
    const chatClassName = createClassNameString([
        "Messages__Chat",
        isMobile ? "" : "col",
    ]);

    const showChannelSideBar = !isMobile || mobileView === "CHANNEL_SELECT";
    const showChat = !isMobile || mobileView === "CHAT";

    return <div className="Messages row no-gutters">
        { showChannelSideBar &&
            <div className={channelSideBarClassName}>
                <ChannelsSideBar
                    client={client}
                    channels={[...channels]}
                    showMoreChannels={showMoreChannels}
                    hasMoreChannels={!!nextChannelPageQuery}
                    switchView={switchMessagesOrAddChannelView}
                    onSelectChannel={onSelectChannel}
                    onMessageReceived={onMessageReceived}
                />
            </div>
        }
        {loadingMoreChannels && <Spinner />}

        { showChat &&
            <div className={chatClassName}>
                <Chat
                    client={client}
                    user={user}
                    channel={selectedChannel}
                    isMobile={isMobile}
                    refetch={fetchMessagingInfo}
                    isInactive={isInactive}
                    switchMobileView={switchMobileView}
                />
            </div>
        }
    </div>;
};

MessagesContainer.propTypes = {
    channels: PropTypes.array.isRequired,
    client: PropTypes.object.isRequired,
    fetchMessagingInfo: PropTypes.func.isRequired,
    isInactive: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    loadingMoreChannels: PropTypes.bool.isRequired,
    mobileView: PropTypes.string.isRequired,
    nextChannelPageQuery: PropTypes.string,
    onSelectChannel: PropTypes.func.isRequired,
    selectedChannel: PropTypes.object.isRequired,
    showMoreChannels: PropTypes.func.isRequired,
    switchMessagesOrAddChannelView: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};
