import React, { useEffect, useState } from "react";
import { api } from "../../../../APIRequests";
import "./style.scss";
import { showAlertWithCustomHTML } from "../../../../redux/actions/alerts";
import { connect } from "react-redux";
import { ErrorCard } from "../../../../component-library/ErrorCard";
import { analytics, clickAnalytics } from "../signup_analytics";
import _ from "lodash";
import { CUSTOM_TOAST_ALERT, TOAST_ALERT, TOAST_ALERT_2, TOAST_ERROR } from "../../../UI/Alert";
import { getCarrierMetadata } from "../carrierMetadata";

// "plan": string, description of the plan,
// "copay": number, benefit amount if exists, otherwise null,
// "error": string, error message if exists, otherwise null ,
// "required_fields": array, required field names for a re submit if a no_match status comes back

const INSURANCE_CARRIER_URL_MAP = {
    CDPHP: "https://www.cdphp.com/",
};

const CLICK_URL_MAP = {
    apti_phone: (entryPoint, status, clickOrigin) => (
        <a
            className={"green bold link"}
            href={"tel:8884543827"}
            onClick={() => clickAnalytics(entryPoint, status, clickOrigin)}>
            {"(888) 454-3827"}
        </a>
    ),
    apti_email: (entryPoint, status, clickOrigin) => (
        <a
            className={"green bold link"}
            href={"mailto:support@aptihealth.com"}
            onClick={() => clickAnalytics(entryPoint, status, clickOrigin)}>
            {"support@aptihealth.com"}
        </a>
    ),
    carrier: (insuranceCarrier, entryPoint, status, clickOrigin) => (
        <a
            className={"green bold link"}
            onClick={() => clickAnalytics(entryPoint, status, clickOrigin)}
            target="_blank"
            href={
                insuranceCarrier in INSURANCE_CARRIER_URL_MAP
                    ? INSURANCE_CARRIER_URL_MAP[insuranceCarrier]
                    : ""
            }
            rel="noreferrer">
            {insuranceCarrier}
        </a>
    ),
};

const CARRIER_STATUS_MESSAGE_MAP = {
    teens_kids_program: {
        accepted: {
            title: (insuranceCarrier, copay, submissionAttempts) =>
                "Great news - your child is covered.",
            description: (insuranceCarrier, copay, submissionAttempts) => {
                return copay
                    ? `Their plan has a $${copay} copay.`
                    : `Their plan may have coinsurance or deductibles. Refer to your insurance card or contact ${insuranceCarrier} for details.`;
            },
        },
        declined: {
            title: (insuranceCarrier, copay, submissionAttempts) =>
                "Unfortunately, your child’s insurance does not cover aptihealth’s services.",
            description: (insuranceCarrier, copay, submissionAttempts) => {
                const carrierUrl = getCarrierMetadata(insuranceCarrier).url;
                return (
                    <div className={"inline"}>
                        {"For questions about your coverage, please contact "}
                        <a
                            className={"green bold link"}
                            target="_blank"
                            href={carrierUrl}
                            rel="noreferrer">
                            {insuranceCarrier}
                        </a>
                        .
                    </div>
                );
            },
        },
        no_match: {
            title: (insuranceCarrier, copay, submissionAttempts) => {
                return submissionAttempts > 4
                    ? `We're sorry, we could not verify that your child is insured by ${insuranceCarrier}.`
                    : `Hmm... We’re having trouble confirming that your child is insured by ${insuranceCarrier}.`;
            },
            description: (insuranceCarrier, copay, submissionAttempts) => {
                return submissionAttempts > 4
                    ? [
                          <span className="inline">
                              {"Please contact "}
                              {CLICK_URL_MAP["apti_email"](
                                  "teens_kids_program",
                                  "no_match",
                                  "email",
                              )}
                              {" or "}
                              {CLICK_URL_MAP["apti_phone"](
                                  "teens_kids_program",
                                  "no_match",
                                  "call",
                              )}
                              {" for assistance."}
                          </span>,
                      ]
                    : "Please double-check that the information below is correct and try again.";
            },
        },
        pending: {
            title: (insuranceCarrier, copay, submissionAttempts, accountData) =>
                "We weren’t able to check your child’s insurance coverage right now.",
            description: (insuranceCarrier, copay, submissionAttempts, accountData) => [
                <span className="inline">
                    {"Please contact "}
                    {CLICK_URL_MAP["apti_email"]("teens_kids_program", "pending", "email")}
                    {" or "}
                    {CLICK_URL_MAP["apti_phone"]("teens_kids_program", "pending", "call")}
                    {" before their first appointment to prevent billing issues."}
                </span>,
            ],
        },
        pending_retry: {
            title: (insuranceCarrier, copay, submissionAttempts, accountData) => "Sorry...",
            description: (insuranceCarrier, copay, submissionAttempts, accountData) => [
                <div>{`We're experiencing some issues right now with checking insurance coverage from ${insuranceCarrier}. Please try again.`}</div>,
            ],
        },
        not_configured: {
            title: (insuranceCarrier, copay, submissionAttempts) =>
                "Your insurance still needs to be confirmed.",
            description: (insuranceCarrier, copay, submissionAttempts) =>
                `Please contact ${insuranceCarrier} before your first appointment to confirm your coverage and cost of care.`,
        },
    },
    hcp_signup: {
        accepted: {
            title: (insuranceCarrier, copay, submissionAttempts, accountData) => "",
            description: (insuranceCarrier, copay, submissionAttempts, accountData) => {
                return copay ? (
                    <>
                        <span>
                            {accountData["first_name"]} will receive an email from aptihealth
                            listing next steps momentarily.{" "}
                        </span>
                        <div>
                            {accountData["first_name"]}'s plan has a $
                            <span className={"bold"}>{copay}</span> copay for sessions.
                        </div>
                    </>
                ) : (
                    <>
                        <span>
                            {accountData["first_name"]} will receive an email from aptihealth
                            listing next steps momentarily.{" "}
                        </span>
                        <div>{`${accountData["first_name"]}’s plan may have coinsurance or deductibles.`}</div>
                    </>
                );
            },
        },
        declined: {
            title: (insuranceCarrier, copay, submissionAttempts, accountData) =>
                `Unfortunately, ${accountData["first_name"]}'s insurance does not cover aptihealth’s services.`,
            description: (insuranceCarrier, copay, submissionAttempts, accountData) => {
                return "We aren't able to refer this patient.";
            },
        },
        no_match: {
            title: (insuranceCarrier, copay, submissionAttempts, accountData) => {
                return submissionAttempts > 4
                    ? `We're sorry, we could not verify that ${accountData["first_name"]} is insured by ${insuranceCarrier}.`
                    : `Hmm... We’re having trouble confirming that you're insured by ${insuranceCarrier}.`;
            },
            description: (insuranceCarrier, copay, submissionAttempts, accountData) => {
                return submissionAttempts > 4
                    ? [
                          <span className="inline">
                              {"Please contact "}
                              {CLICK_URL_MAP["apti_email"]("hcp_signup", "no_match", "email")}
                              {" or "}
                              {CLICK_URL_MAP["apti_phone"]("hcp_signup", "no_match", "call")}
                              {" for assistance."}
                          </span>,
                      ]
                    : "Please double-check that name, date of birth, and member ID are correct and try again.";
            },
        },
        pending: {
            title: (insuranceCarrier, copay, submissionAttempts, accountData) => "",
            description: (insuranceCarrier, copay, submissionAttempts, accountData) => [
                <>
                    <div>{`${accountData["first_name"]} will receive an email from aptihealth listing next steps momentarily.`}</div>
                    <br />
                    <div>
                        We were not able to check their insurance coverage right now. They should
                        contact {CLICK_URL_MAP["apti_email"]("hcp_signup", "pending", "email")} or{" "}
                        {CLICK_URL_MAP["apti_phone"]("hcp_signup", "pending", "call")} before their
                        first appointment to prevent billing issues.
                    </div>
                </>,
            ],
        },
        pending_retry: {
            title: (insuranceCarrier, copay, submissionAttempts, accountData) => "Sorry...",
            description: (insuranceCarrier, copay, submissionAttempts, accountData) => [
                <div>{`We're experiencing some issues right now with checking insurance coverage from ${insuranceCarrier}. Please try again.`}</div>,
            ],
        },
        not_configured: {
            title: (insuranceCarrier, copay, submissionAttempts, accountData) => "",
            description: (insuranceCarrier, copay, submissionAttempts, accountData) =>
                `${accountData["first_name"]} will receive an email from aptihealth listing next steps momentarily.`,
        },
    },
    default: {
        accepted: {
            title: (insuranceCarrier, copay, submissionAttempts) => "Great news - you're covered.",
            description: (insuranceCarrier, copay, submissionAttempts) => {
                return copay
                    ? [
                          "Your plan has a $",
                          <span className={"bold"}>{copay}</span>,
                          ` copay for sessions. Refer to your insurance card or contact ${insuranceCarrier} for details.`,
                      ]
                    : [
                          "Your plan may have coinsurance or deductibles. Refer to your insurance card or contact ",
                          <span className={"bold"}>{insuranceCarrier}</span>,
                          " for details.",
                      ];
            },
        },
        declined: {
            title: (insuranceCarrier, copay, submissionAttempts) =>
                "Unfortunately, your insurance does not cover aptihealth’s services.",
            description: (insuranceCarrier, copay, submissionAttempts, accountData, entryPoint) => {
                return (
                    <div className={"inline"}>
                        For questions about your coverage, please contact{" "}
                        {CLICK_URL_MAP["carrier"](
                            insuranceCarrier,
                            entryPoint,
                            "declined",
                            "payor",
                        )}
                        .
                    </div>
                );
            },
        },
        no_match: {
            title: (insuranceCarrier, copay, submissionAttempts) => {
                return submissionAttempts > 4
                    ? `We're sorry, we could not verify that you are insured by ${insuranceCarrier}.`
                    : `Hmm... We’re having trouble confirming that you're insured by ${insuranceCarrier}.`;
            },
            description: (insuranceCarrier, copay, submissionAttempts, accountData, entryPoint) => {
                return submissionAttempts > 4
                    ? [
                          <span className="inline">
                              {"Please contact "}
                              {CLICK_URL_MAP["apti_email"](entryPoint, "no_match", "email")}
                              {" or "}
                              {CLICK_URL_MAP["apti_phone"](entryPoint, "no_match", "call")}
                              {" for assistance."}
                          </span>,
                      ]
                    : ["Please double-check that the information below is correct and try again."];
            },
        },
        pending: {
            title: (insuranceCarrier, copay, submissionAttempts) =>
                "We weren’t able to check your insurance coverage right now.",
            description: (insuranceCarrier, copay, submissionAttempts, accountData, entryPoint) => [
                <span className="inline">
                    {"Please contact "}{" "}
                    {CLICK_URL_MAP["apti_email"](entryPoint, "pending", "email")} or{" "}
                    {CLICK_URL_MAP["apti_phone"](entryPoint, "pending", "call")}
                    {" before your first appointment to prevent billing issues."}
                </span>,
            ],
        },
        pending_retry: {
            title: (insuranceCarrier, copay, submissionAttempts, accountData) => "Sorry...",
            description: (insuranceCarrier, copay, submissionAttempts, accountData) => [
                <div>{`We're experiencing some issues right now with checking insurance coverage from ${insuranceCarrier}. Please try again.`}</div>,
            ],
        },
        not_configured: {
            title: (insuranceCarrier, copay, submissionAttempts) =>
                "Your insurance still needs to be confirmed.",
            description: (insuranceCarrier, copay, submissionAttempts) => [
                "Please contact ",
                <span className={"bold"}>{insuranceCarrier}</span>,
                " before your first appointment to confirm your coverage and cost of care.",
            ],
        },
    },
};
const requestInProgressToast = {
    hcp_signup: {
        title: (insuranceCarrier, copay, submissionAttempts, accountData) =>
            "We’re checking the patient’s insurance coverage.",
        description: (insuranceCarrier, copay, submissionAttempts, accountData) =>
            "This may take a little while longer. Please keep this page open.",
    },
    teens_kids_program: {
        title: (insuranceCarrier, copay, submissionAttempts, accountData) =>
            "We’re checking your child’s insurance coverage.",
        description: (insuranceCarrier, copay, submissionAttempts, accountData) =>
            "This may take a little while longer. Please keep this page open.",
    },
    default: {
        title: (insuranceCarrier, copay, submissionAttempts) =>
            "We’re checking your insurance coverage.",
        description: (insuranceCarrier, copay, submissionAttempts) =>
            "This may take a little while longer. Please keep this page open.",
    },
};

const InsuranceEligibilityV2 = ({
    submissionAttempts,
    insuranceDetails,
    eligibilityCallback,
    entryPoint,
    setEligibilityMessage,
    accountData,
    setRequiredFields,
    ...props
}) => {
    const [error, setError] = useState("");
    const [pendingCount, setPendingCount] = useState(0);

    const checkPatientEligibility = async (insuranceDetails) => {
        const options = { params: { showLoader: true } };
        return api.patient.fetch_insurance_eligibility_v2({ options, data: insuranceDetails });
    };

    const pendingRecheck = (attempts) => pendingCount < 1;

    const messageBuilder = (
        entryPoint,
        status,
        copay,
        submissionAttempts,
        accountData = {},
        required_fields = [],
    ) => {
        const insuranceCarrier = insuranceDetails["carrier"];
        const entryPointAccessor =
            entryPoint === "teens_kids_program" || entryPoint === "hcp_signup"
                ? entryPoint
                : "default";
        const carrierMessageMap = CARRIER_STATUS_MESSAGE_MAP[entryPointAccessor][status];
        const defaultRender = (
            <>
                <strong className="bold">
                    {carrierMessageMap.title(
                        insuranceCarrier,
                        copay,
                        submissionAttempts,
                        accountData,
                    )}
                </strong>
                {carrierMessageMap.description(
                    insuranceCarrier,
                    copay,
                    submissionAttempts,
                    accountData,
                    entryPoint,
                )}
            </>
        );

        const renderMessage = {
            no_match: (
                <ErrorCard submissionAttempts={submissionAttempts}>{defaultRender}</ErrorCard>
            ),
            declined: (
                <ErrorCard submissionAttempts={submissionAttempts}>{defaultRender}</ErrorCard>
            ),
            pending_retry: (
                <ErrorCard submissionAttempts={submissionAttempts}>{defaultRender}</ErrorCard>
            ),
        };

        return _.get(renderMessage, status, defaultRender);
    };

    const processEligibilityResults = (
        patientEligibility,
        submissionAttempts,
        entryPoint,
        accountData,
    ) => {
        const { copay, status, required_fields } = patientEligibility;
        const clonedStatus =
            status === "pending" && pendingRecheck(submissionAttempts) ? "pending_retry" : status;
        const messageRender = messageBuilder(
            entryPoint,
            clonedStatus,
            copay,
            submissionAttempts,
            accountData,
            required_fields,
        );
        setEligibilityMessage(messageRender);

        const renderAction = {
            pending: (messageRender) => {
                if (pendingRecheck(submissionAttempts)) {
                    setError(messageRender);
                } else if (
                    !pendingRecheck(submissionAttempts) &&
                    (entryPoint === "teens_kids_program" || entryPoint === "referral_signup")
                ) {
                    props.showAlertWithCustomHTML(messageRender, TOAST_ALERT_2);
                }
            },
            accepted: (messageRender) => props.showAlertWithCustomHTML(messageRender),
            no_match: (messageRender) => setError(messageRender),
            not_configured: (messageRender) => setError(messageRender),
            declined: (messageRender) => setError(messageRender),
        };

        const customActionEntryPoints = ["teens_kids_program", "referral_signup"];

        if (!customActionEntryPoints.includes(entryPoint)) {
            setError(messageRender);
            if (status === "no_match" && required_fields && entryPoint === "self_signup") {
                setRequiredFields(required_fields);
            }
        } else {
            _.get(renderAction, status, (emptyParam) => {})(messageRender);
        }
    };

    useEffect(() => {
        if (submissionAttempts > 0) {
            const eligibilityCheck = async () => {
                const reqInProgressTimeout = setTimeout(() => {
                    const entryPointAccessor =
                        entryPoint === "hcp_signup" || entryPoint === "teens_kids_program"
                            ? entryPoint
                            : "default";
                    props.showAlertWithCustomHTML(
                        <>
                            <strong className="bold">
                                {requestInProgressToast[entryPointAccessor].title(
                                    insuranceDetails["carrier"],
                                )}
                            </strong>
                            {requestInProgressToast[entryPointAccessor].description(
                                insuranceDetails["carrier"],
                            )}
                        </>,
                    );
                }, 2000);

                const patientEligibility = await checkPatientEligibility(insuranceDetails);

                clearTimeout(reqInProgressTimeout);
                props.showAlertWithCustomHTML(<></>, CUSTOM_TOAST_ALERT, true);

                const pendingAttempts =
                    patientEligibility["status"] === "pending" ? pendingCount + 1 : pendingCount;
                setPendingCount(pendingAttempts);
                analytics({ patientEligibility, submissionAttempts, entryPoint });
                processEligibilityResults(
                    patientEligibility,
                    submissionAttempts,
                    entryPoint,
                    accountData,
                );

                //pending and not_configured will return an error, so it won't enter the callback. check if it's erroring on a state other than pending/not_configured.
                if (patientEligibility["error"]) {
                    if (
                        (patientEligibility["status"] === "pending" && pendingAttempts > 1) ||
                        patientEligibility["status"] === "not_configured"
                    ) {
                        eligibilityCallback(
                            patientEligibility["status"],
                            submissionAttempts - pendingCount,
                            patientEligibility,
                        );
                        setError("");
                        setPendingCount(0);
                    }
                    return;
                }

                if (patientEligibility["status"] !== "declined") {
                    setError("");
                }

                setPendingCount(0);
                eligibilityCallback(
                    patientEligibility["status"],
                    submissionAttempts - pendingCount,
                    patientEligibility,
                );
            };
            eligibilityCheck();
        }
    }, [submissionAttempts]);

    return <>{error}</>;
};

const mapStateToProps = (state) => {
    return {
        configs: state.configs,
    };
};

export default connect(mapStateToProps, { showAlertWithCustomHTML })(InsuranceEligibilityV2);
