import React, { Component } from "react";
import GenericList from "../../../../components/Common/GenericList/GenericList";
import images from "../../../../utils/images";
import { adminRoute, providerRoute as provider } from "../../../../config/roles";
import OmnisearchQuestions from "../../../../APIRequests/omnisearch";
import {
    renderDefaultBackgroundColorBasedOnThresh,
    renderIcon,
} from "../../../../utils/GenericListUtils";
import { getQueryParams } from "../../../../utils/filters";
import Button from "../../../../components/UI/Button";
import { Link } from "react-router-dom";
import "./styles.scss";

class OmnisearchList extends Component {
    adherenceRenderFactory = (key) => {
        return (row) => {
            const classMap = { "bg-error": 0.5, "bg-warning": 0.75, "bg-success": 1 };
            let className = renderDefaultBackgroundColorBasedOnThresh(row[key], classMap);
            return (
                <span
                    key={row[key]}
                    className={"d-flex justify-content-center text-white " + className}>
                    {(row[key] * 100).toFixed(0)}%
                </span>
            );
        };
    };

    getPatientName = (row) => row.first_name + " " + row.last_name;

    sortHandler = async (col) => {
        if (col && col.isSortable) {
            let col_key = col.field_accessor.key;
            let fields = [col_key];
            if (col.sortFields != null) {
                fields = col.sortFields;
            }

            this.setState({
                sortOptions: {
                    col_key,
                    fields,
                    sortAsc:
                        !this.state.sortOptions ||
                        this.state.sortOptions.col_key !== col_key ||
                        !this.state.sortOptions.sortAsc,
                },
            });
        }
    };

    pageHandler = async () => this.setState({ page: this.state.page + 1 });

    PROVIDER_BASE_COLUMN_OPTIONS = [
        {
            display_name: "Provider Name",
            field_accessor: { key: "name", render_function: "" },
            col_size: "col-2",
            isSearchable: true,
            isSortable: true,
            sortFields: ["name.keyword"],
        },
        {
            display_name: "Email Address",
            field_accessor: { key: "email", render_function: "" },
            col_size: "col-3",
            isSearchable: true,
        },
        {
            display_name: "Total Patients",
            field_accessor: { key: "total_patients", render_function: "" },
            col_size: "col-1",
            isSearchable: false,
            isSortable: true,
        },
    ];

    PATIENT_BASE_COLUMN_OPTIONS = [
        {
            display_name: "Patient Name",
            field_accessor: {
                key: "first_name",
                render_function: (row) => this.getPatientName(row),
            },
            col_size: "col-2",
            isSearchable: true,
            isSortable: true,
            sortFields: ["first_name.keyword", "last_name.keyword"],
        },
        {
            display_name: "Email Address",
            field_accessor: { key: "email", render_function: "" },
            col_size: "col-3",
            isSearchable: true,
        },
        {
            display_name: "Mobile Number",
            field_accessor: { key: "mobile", render_function: "" },
            col_size: "col-1",
            isSearchable: true,
        },
    ];

    PROVIDER_IMPORTANT_FIELDS_MAP = {
        average_patient_adherence: {
            display_name: "Adherence",
            field_accessor: {
                key: "average_patient_adherence",
                render_function: this.adherenceRenderFactory("average_patient_adherence"),
            },
            col_size: "col-1",
            isSearchable: false,
            isSortable: true,
        },
        notes_in_draft_status: {
            display_name: "Notes In Draft Status",
            field_accessor: { key: "notes_in_draft_status", render_function: "" },
            col_size: "col-1",
            isSearchable: false,
            isSortable: true,
        },
    };

    PATIENT_IMPORTANT_FIELDS_MAP = {
        adherence: {
            display_name: "Adherence",
            field_accessor: {
                key: "adherence",
                render_function: this.adherenceRenderFactory("adherence"),
            },
            col_size: "col-1",
            isSearchable: false,
            isSortable: true,
        },
        percent_no_show: {
            display_name: "Percent No Show",
            field_accessor: {
                key: "percent_no_show",
                render_function: (row) => {
                    return `${(row["percent_no_show"] * 100).toFixed(1)}%`;
                },
            },
            col_size: "col-1",
            isSearchable: false,
            isSortable: true,
        },
    };

    PATIENT_ACTION_ITEMS_MAP = [
        {
            display_name: "Individual Profile",
            field_accessor: {
                key: "individual_id",
                render_function: (row) => {
                    return renderIcon(
                        this.props,
                        adminRoute + "/patient/" + row["username"] + "/profile/v2",
                        images("./common/avatar_icon.svg"),
                        "profile icon",
                    );
                },
            },
            col_size: "col-1",
            isSearchable: false,
        },
    ];

    PROVIDER_ACTION_ITEMS_MAP = [
        {
            display_name: "Practice Profile",
            field_accessor: {
                key: "practice_id",
                render_function: (row) => {
                    return renderIcon(
                        this.props,
                        adminRoute + "/practice/" + row["practice_id"],
                        images("./common/view.png"),
                        "view icon",
                    );
                },
            },
            col_size: "col-1",
            isSearchable: false,
        },
        {
            display_name: "Individual Profile",
            field_accessor: {
                key: "individual_id",
                render_function: (row) => {
                    return renderIcon(
                        this.props,
                        adminRoute +
                            "/practice/" +
                            row["practice_id"] +
                            "/provider/" +
                            row["username"],
                        images("./common/avatar_icon.svg"),
                        "profile icon",
                    );
                },
            },
            col_size: "col-1",
            isSearchable: false,
        },
        {
            display_name: "Patient List",
            field_accessor: {
                key: "username",
                render_function: (row) => {
                    return renderIcon(
                        this.props,
                        adminRoute +
                            "/practice/" +
                            row["practice_id"] +
                            "/provider/" +
                            row["username"] +
                            "/patientsList",
                        images("./common/report_icon.svg"),
                        "report icon",
                    );
                },
            },
            col_size: "col-1",
            isSearchable: false,
        },
    ];

    constructor(props) {
        super(props);
        const { importantFields, routeDetailLevel } = this.getTrimmedQueryParams();
        this.state = {
            columnOptions: this.getColumnOptions(routeDetailLevel, importantFields),
            gridData: [],
            page: 0,
            showMore: true,
            pageSize: 10,
            sortOptions: null,
        };
    }

    componentDidMount() {
        this.asyncOmnisearchAnswer([], 0);
    }

    componentDidUpdate(prevProps, prevState) {
        const sortOptions =
            prevProps.location.search !== this.props.location.search
                ? null
                : this.state.sortOptions;
        if (
            prevProps.location.search !== this.props.location.search ||
            prevState.sortOptions !== this.state.sortOptions
        ) {
            const { importantFields, routeDetailLevel } = this.getTrimmedQueryParams();
            this.setState({
                columnOptions: this.getColumnOptions(routeDetailLevel, importantFields),
                gridData: [],
                page: 0,
                showMore: true,
                sortOptions,
            });

            this.asyncOmnisearchAnswer([], 0, sortOptions);
        }
        if (prevState.page !== this.state.page) {
            this.asyncOmnisearchAnswer(this.state.gridData, this.state.page, sortOptions);
        }
    }

    getTrimmedQueryParams() {
        const importantFields =
            getQueryParams("importantFields", this.props.location.search.trim()) === ""
                ? []
                : getQueryParams("importantFields", this.props.location.search.trim()).split(",");
        const routeQuestionId = getQueryParams("questionId", this.props.location.search.trim());
        const routeDetailLevel = getQueryParams("detailLevel", this.props.location.search.trim());
        return { importantFields, routeDetailLevel, routeQuestionId };
    }

    getColumnOptions(detailLevel, importantFields = []) {
        if (detailLevel === "provider") {
            const importantFieldCols = importantFields
                .filter((importantField) => this.PROVIDER_IMPORTANT_FIELDS_MAP[importantField])
                .map((importantField) => this.PROVIDER_IMPORTANT_FIELDS_MAP[importantField]);
            return this.PROVIDER_BASE_COLUMN_OPTIONS.concat(importantFieldCols).concat(
                this.PROVIDER_ACTION_ITEMS_MAP,
            );
        } else if (detailLevel === "patient") {
            const importantFieldCols = importantFields
                .filter((importantField) => this.PATIENT_IMPORTANT_FIELDS_MAP[importantField])
                .map((importantField) => this.PATIENT_IMPORTANT_FIELDS_MAP[importantField]);
            return this.PATIENT_BASE_COLUMN_OPTIONS.concat(importantFieldCols).concat(
                this.PATIENT_ACTION_ITEMS_MAP,
            );
        }
    }

    async asyncOmnisearchAnswer(gridData = [], page = 0, sortOptions = null) {
        const { routeQuestionId } = this.getTrimmedQueryParams();
        let sort = [];
        if (sortOptions != null) {
            sort = sortOptions.fields.map((field) => (sortOptions.sortAsc ? field : `-${field}`));
        }
        const omnisearchAnswer = await OmnisearchQuestions.answerOmnisearch({
            questionId: parseInt(routeQuestionId),
            size: this.state.pageSize,
            sort,
            page,
        });
        omnisearchAnswer["entities"] = this.transformNotesInDraftStatusToLink(
            omnisearchAnswer["entities"],
        );
        const currentEntities = gridData.concat(omnisearchAnswer["entities"]);
        if (currentEntities.length >= omnisearchAnswer["total"]) {
            this.setState({ showMore: false });
        }
        this.setState({ gridData: currentEntities });
    }

    transformNotesInDraftStatusToLink(gridData) {
        return gridData.map((record) => {
            record["notes_in_draft_status"] = (
                <Link
                    className={"draft-note-link"}
                    to={adminRoute + `/draft-notes/${record["username"]}`}>
                    {record["notes_in_draft_status"]}
                </Link>
            );
            return record;
        });
    }

    render() {
        return (
            <>
                <GenericList
                    is_searchable={true}
                    column_options={this.state.columnOptions}
                    grid_data={this.state.gridData}
                    generic_list_key={"email"}
                    titleBarItemClickHandler={this.sortHandler}
                    sortOptions={this.state.sortOptions}
                />

                {this.state.gridData && this.state.showMore && (
                    <div className="d-flex align-items-center justify-content-center pt-2 pb-5">
                        <Button
                            className="Btn Btn--pri ShowMoreBtn ml-4 text-center"
                            onClick={this.pageHandler}>
                            Show More
                        </Button>
                    </div>
                )}
            </>
        );
    }
}

export default OmnisearchList;
