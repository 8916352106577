// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import images from "../../../utils/images";
import { Link } from "react-router-dom";
import { providerRoute } from "../../../config/roles";

const VIDEO_CONSULTATION = "video_consultation";

const Schedule90DayTask = (props) => {
    const { patientDetails } = props;
    return (
        <div className={"mt-2"}>
            <div
                className="PatientProfile__schedule-90-day-task px-4 d-flex align-items-center"
                style={{ maxWidth: "100%" }}>
                <div className="PatientProfile__schedule-90-day-task-icon-wpr d-flex align-items-center justify-content-center">
                    <img src={images("./icons/medical-history.svg")} alt="" />
                </div>
                <div className="fw-bold fs-14 fs-lg-16 ml-2">
                    <span>Schedule Video Consultation</span>
                </div>
                <Link
                    className="ml-auto"
                    to={{
                        pathname: `${providerRoute}/schedule`,
                        state: {
                            mode: VIDEO_CONSULTATION,
                            patientId: patientDetails && patientDetails.username,
                        },
                    }}>
                    <div className="PatientProfile__schedule-90-day-task-link pl-4">
                        <img src={images("./icons/back-arrow.svg")} alt="" />
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default Schedule90DayTask;
