// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useState } from "react";
import Button from "../../../components/UI/Button";

const HCPAutoCancelDialog = ({ scheduleHandler }) => {
    return (
        <div className="HCPAutoCancelDialog">
            <p className="HCPAutoCancelDialog__text">
                We’re happy to see you and congratulations on taking the next step to feeling
                better.
            </p>
            <p className="HCPAutoCancelDialog__text">
                We were unable to hold your appointment time because your registration wasn’t
                complete.
            </p>
            <p className="HCPAutoCancelDialog__text">Not to worry, let’s find a new time.</p>

            <Button
                onClick={scheduleHandler}
                type="button"
                className="HCPAutoCancelDialog__button Btn Btn--pri Btn-sm mx-2">
                Schedule
            </Button>
        </div>
    );
};

export default HCPAutoCancelDialog;
