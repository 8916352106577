import React from "react";
import Button from "../../../UI/Button";
import WorkflowHeader from "./WorkflowHeader";
import moment from "moment-timezone";
import _ from "lodash";
import { api } from "../../../../APIRequests";
import { getGroupAuthType } from "../../../../redux/actions/auth";
import { GROUP_AUTH_HEADER_NAME } from "../../../../constants/axios";

const RescheduleButtonOrActionText = ({
    consultationToConfirm,
    setConsultationToConfirm,
    alreadyScheduled,
    setAlreadyScheduled,
    appToDisplay,
    setAppToDisplay,
    addToInAppHistory,
    withinAppHistory,
    setWithinAppHistory,
}) => {
    if (!alreadyScheduled) {
        return (
            <div className={"txt-gry fs-14"}>
                You will receive an email and text confirming your session once scheduled.
            </div>
        );
    }
    if (alreadyScheduled) {
        return (
            <Button
                type={"button"}
                className={"Btn Btn--sec Btn--sm-2 workflow-btn"}
                onClick={() => {
                    addToInAppHistory(
                        appToDisplay,
                        setAppToDisplay,
                        alreadyScheduled,
                        setAlreadyScheduled,
                        consultationToConfirm,
                        setConsultationToConfirm,
                        withinAppHistory,
                        setWithinAppHistory,
                    );
                    setAppToDisplay("scheduleConsultation");
                    // setAlreadyScheduled(false);
                }}>
                Reschedule
            </Button>
        );
    }
};

const confirmScheduling = async (
    workflowInstance,
    consultationToConfirm,
    alreadyScheduled,
    schedulingData,
) => {
    // schedule call if not already scheduled
    if (!alreadyScheduled) {
        await scheduleCall(consultationToConfirm, workflowInstance);
    } else {
        // Already scheduled and now rescheduling since slot without a call_id picked
        if (consultationToConfirm["call_id"] == null) {
            const currentCallId = _.get(
                schedulingData,
                "intake_appointment_data.upcoming_appointments[0].call_id",
            );
            await rescheduleCall(currentCallId, consultationToConfirm, workflowInstance);
        }
    }

    const options = { params: { showLoader: true } };
    const data = {
        workflow_instance_sort_key: workflowInstance.sort_key,
        node_name: "schedule_appointment",
        state: {
            appointment_confirmed: true,
        },
    };
    const headers = {
        "X-On-Behalf-Of": workflowInstance.entity_id,
    };

    return api.workflow.update_state_and_get_next_node({ options, data, headers });
};

const callDetailsToDateData = (callDetails) => {
    let timezone = moment.tz.guess();
    let combinedDateTimeStr = `${callDetails.year}-${callDetails.month}-${callDetails.day} ${callDetails.start_time}`;
    let utcDatetime = moment.tz(combinedDateTimeStr, "YYYY-MM-DD HH:mm", timezone).utc();

    return {
        timezone,
        date: utcDatetime.format("YYYY-MM-DD"),
        time: utcDatetime.format("HH:mm"),
    };
};

const scheduleCall = async (callDetails, workflowInstance) => {
    const data = {
        local_date_time: `${callDetails.year}-${callDetails.month}-${callDetails.day} ${callDetails.start_time}`,
        provider_id: callDetails["provider_username"],
        patient_id: workflowInstance.entity_id,
        allotted_time: callDetails["allotted_time"],
        timezone: moment.tz.guess(),
    };

    const headers = {
        "X-On-Behalf-Of": workflowInstance.entity_id,
        "X-On-Behalf-Of-Entity-Type": "patient",
        [GROUP_AUTH_HEADER_NAME]: getGroupAuthType(),
    };

    return api.schedule.post_schedule({ data, headers });
};

const rescheduleCall = async (currentCallId, callDetails, workflowInstance) => {
    let dateTimeData = callDetailsToDateData(callDetails);

    const data = {
        call_id: currentCallId,
        local_date_time: `${callDetails.year}-${callDetails.month}-${callDetails.day} ${callDetails.start_time}`,
        provider_id: callDetails["provider_username"],
        patient_id: workflowInstance.entity_id,
        allotted_time: callDetails["allotted_time"],
        timezone: dateTimeData["timezone"],
    };

    const headers = {
        "X-On-Behalf-Of": workflowInstance.entity_id,
        "X-On-Behalf-Of-Entity-Type": "patient",
        [GROUP_AUTH_HEADER_NAME]: getGroupAuthType(),
    };

    return api.schedule.put_schedule({ data, headers });
};

const ContextSpecificButtons = ({
    consultationToConfirm,
    setConsultationToConfirm,
    alreadyScheduled,
    setAlreadyScheduled,
    appToDisplay,
    setAppToDisplay,
    addToInAppHistory,
    withinAppHistory,
    setWithinAppHistory,
    goBackInAppHistory,
    workflowInstance,
    schedulingData,
}) => {
    return (
        <div className="workflow-container-sm d-flex justify-content-between">
            <Button
                type={"button"}
                className={"Btn Btn--otl-gry Btn--sm-2 workflow-btn"}
                onClick={() => {
                    goBackInAppHistory(withinAppHistory, setWithinAppHistory);
                }}>
                Back
            </Button>
            <Button
                type={"button"}
                className={"Btn Btn--pri Btn--sm-2 workflow-btn"}
                onClick={async () => {
                    await confirmScheduling(
                        workflowInstance,
                        consultationToConfirm,
                        alreadyScheduled,
                        schedulingData,
                    );
                    setAppToDisplay("thankYou");
                }}>
                Confirm & Schedule
            </Button>
        </div>
    );
};

const ConfirmIntakeConsultation = ({
    schedulingData,
    consultationToConfirm,
    setConsultationToConfirm,
    alreadyScheduled,
    setAlreadyScheduled,
    appToDisplay,
    setAppToDisplay,
    addToInAppHistory,
    withinAppHistory,
    setWithinAppHistory,
    goBackInAppHistory,
    workflowInstance,
}) => {
    const startTime = moment(consultationToConfirm.start_time, "HH:mm").format("h:mm");
    const endTime = moment(consultationToConfirm.end_time, "HH:mm").format("h:mm a");

    return (
        <>
            <WorkflowHeader header={"Confirm your consultation"} showImage={true} />
            <div className={"workflow-container-sm workflow-container-border mb-5"}>
                <div className={"p-lg-5 p-3"}>
                    <div className="row">
                        <div className={"txt-gry fs-14"}>Date</div>
                    </div>
                    <div className="row">
                        <div
                            className={
                                "fw-bold fs-md-16 fs-14"
                            }>{`${consultationToConfirm.day_of_week}, ${consultationToConfirm.month_of_year} ${consultationToConfirm.day}, ${consultationToConfirm.year}`}</div>
                    </div>
                    <br />
                    <div className="row">
                        <div className={"txt-gry fs-14"}>Time</div>
                    </div>
                    <div className="row">
                        <div
                            className={"fw-bold fs-md-16 fs-14"}>{`${startTime} - ${endTime}`}</div>
                    </div>
                    <br />
                    <div className="row">
                        <div className={"txt-gry fs-14"}>Intake Coordinator</div>
                    </div>
                    <div className="row">
                        <div className={"fw-bold fs-md-16 fs-14"}>
                            {consultationToConfirm.provider_name}
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <RescheduleButtonOrActionText
                            schedulingData={schedulingData}
                            consultationToConfirm={consultationToConfirm}
                            setConsultationToConfirm={setConsultationToConfirm}
                            alreadyScheduled={alreadyScheduled}
                            setAlreadyScheduled={setAlreadyScheduled}
                            appToDisplay={appToDisplay}
                            setAppToDisplay={setAppToDisplay}
                            addToInAppHistory={addToInAppHistory}
                            withinAppHistory={withinAppHistory}
                            setWithinAppHistory={setWithinAppHistory}
                        />
                    </div>
                </div>
            </div>
            <ContextSpecificButtons
                consultationToConfirm={consultationToConfirm}
                setConsultationToConfirm={setConsultationToConfirm}
                alreadyScheduled={alreadyScheduled}
                setAlreadyScheduled={setAlreadyScheduled}
                appToDisplay={appToDisplay}
                setAppToDisplay={setAppToDisplay}
                addToInAppHistory={addToInAppHistory}
                withinAppHistory={withinAppHistory}
                setWithinAppHistory={setWithinAppHistory}
                goBackInAppHistory={goBackInAppHistory}
                workflowInstance={workflowInstance}
                schedulingData={schedulingData}
            />
        </>
    );
};

export default ConfirmIntakeConsultation;
