import { useState } from "react";
import { getDaySlotId } from "../../components/Calendar/CalendarContainer/CalendarBackground";
import { useEffect } from "react";
import { calendarBackgroundTimeAxisId } from "../../components/Calendar/CalendarContainer/CalendarBackgroundTimeAxis";

const useCalendarDimensions = (calendarBackgroundRef, stickyHeaderRef) => {
    const [calendarRect, setCalendarRect] = useState();

    /**
     * Does not matter which observer calls this, it will update the calendar dimensions
     * which triggers a recalculation of the events through passing component props
     */
    const observerCallback = () => {
        // get calendar background dimensions
        const calendarBackgroundRect = calendarBackgroundRef.current.getBoundingClientRect();
        // number of days does not make sense to use here because us changing it does not
        // instantaneously update the dom
        // number of days - 1 to account for TimeAxis element
        const numberOfDays = calendarBackgroundRef.current.children.length - 1;
        // get time axis dimensions
        const timeAxis = calendarBackgroundRef.current.children.namedItem(
            calendarBackgroundTimeAxisId,
        );
        const timeAxisRect = timeAxis.getBoundingClientRect();
        // get sticky header dimensions
        const stickyHeaderRect = stickyHeaderRef.current.getBoundingClientRect();
        // get the first days first slot dimensions
        const slot = calendarBackgroundRef.current.children.namedItem(getDaySlotId(1));
        const slotRect = slot.children[0].getBoundingClientRect();
        // creates a new rectangle relative to the calendar background
        const newRect = {};
        newRect.calendar = {
            top: stickyHeaderRect.height,
            left: timeAxisRect.width,
            width: calendarBackgroundRect.width - timeAxisRect.width,
        };
        newRect.day = {
            width: newRect.calendar.width / numberOfDays,
        };
        newRect.slot = {
            height: slotRect.height,
        };
        newRect.absoluteCalendar = {
            top: calendarBackgroundRect.top + newRect.calendar.top,
        };

        setCalendarRect(newRect);
    };

    /**
     * sets up observers
     */
    useEffect(() => {
        if (!calendarBackgroundRef || !calendarBackgroundRef.current) {
            return;
        }
        // used to detect when the number of days have changed the dom
        const mutationObserver = new MutationObserver(observerCallback);
        mutationObserver.observe(calendarBackgroundRef.current, {
            childList: true,
        });
        // used to detect when the width of days change
        const resizeObserver = new ResizeObserver(observerCallback);
        resizeObserver.observe(calendarBackgroundRef.current);
        // used to recalculate on window resize
        // ensures that mobile calculations take effect
        window.addEventListener("resize", observerCallback);

        return () => {
            mutationObserver.disconnect();
            resizeObserver.disconnect();
            window.removeEventListener("resize", observerCallback);
        };
    }, [calendarBackgroundRef]);

    return {
        calendarRect,
    };
};

export { useCalendarDimensions };
