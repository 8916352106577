// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import "./../styles.scss";
import { api } from "../../../../APIRequests";
import { getQueryParams } from "../../../../utils/filters";
import withRouter from "react-router-dom/es/withRouter";
import MemberProfileCarePlan from "./MemberProfileCarePlan";
import MemberProfileMedical from "./MemberProfileMedical";
import MemberProfileHeader from "./MemberProfileHeader";
import MemberProfileTimeline from "./MemberProfileTimeline";
import { MemberProfileReports } from "./MemberProfileReports";
import MemberProfileNotes from "./MemberProfileNotes";
import moment from "moment-timezone";
import { isNoteNoShow } from "../../../Common/GuidedVideoCall/Steps/ProviderVideoWorkflowStep";
import {
    ErrorCard,
    PatientChartPaymentBanner,
    Text,
    TextColors,
    TextTypes,
} from "../../../../component-library";
import MemberProfileAdmin from "./MemberProfileAdmin";
import { isAuthorized, isUserAdmin } from "../../../../redux/actions/auth";
import { admin_cse } from "../../../../config/roles";
import _ from "lodash";
import { PAYMENT_TAB } from "../../../../constants/MemberProfile";
import { Payment } from "./Payment/Payment";
import { hasAccessToPaymentTab } from "../../../../utils/authUtils";
import { getAutomationDataAttr } from "../../../../utils/automationUtils";

export const _90_DAY_CARE_PLAN = "90_day_care_plan";

export function patientIdChange(prevProps) {
    if (prevProps.match.params.patientId !== this.props.match.params.patientId) {
        this.setState({ patientId: this.props.match.params.patientId }, () =>
            this.componentDidMount(),
        );
    }
}

export const sortNotesBySessionDate = (notes) => {
    return notes.sort(
        (a, b) =>
            moment(b.session_date + " " + b.session_from, "MM/DD/YYYY HH:mm") -
            moment(a.session_date + " " + a.session_from, "MM/DD/YYYY HH:mm"),
    );
};

export const filterNotesByNoteType = (notes) => {
    return notes.filter(
        (note) =>
            note.note_type == null ||
            note.note_type === "Video Consultation" ||
            note.note_type === "Check-in" ||
            note.note_type === "Care Coordination" ||
            note.note_type === "Initial Clinical Assessment" ||
            note.note_type === "Care Communication" ||
            note.note_type === "Care Management" ||
            note.note_type === "Case Management" ||
            note.note_type === "Advanced Care Activity",
    );
};

export const filterNotesByCompletedAndNotNoShow = (notes) => {
    return notes.filter((note) => note.completed_date_time && !isNoteNoShow(note));
};

class MemberProfile extends Component {
    buildInitialState() {
        let selectedTab = getQueryParams("selectedTab", this.props.location.search);
        if (!selectedTab) {
            selectedTab = "Care Plan";
        }

        let openScheduleIntake = getQueryParams(
            "openScheduleIntakeAppointment",
            this.props.location.search,
        );
        if (!openScheduleIntake) {
            openScheduleIntake = false;
        } else {
            openScheduleIntake = openScheduleIntake === "true";
        }
        return {
            patientDetails: {
                referral_id: null,
                a5_screening_id: null,
                referred_date: null,
                first_name: null,
                last_name: null,
                status: null,
                gender: null,
                mobile: null,
                email: null,
                dob: null,
                is_scheduling_restricted: null,
                address: {
                    street: null,
                    city: null,
                    state: null,
                    zip_code: null,
                },
                emergency_contact_person: null,
                emergency_contact_number: null,
                primary_insurance: {
                    carrier: null,
                    member_id: null,
                    policy_dob: null,
                    policy_holder_name: null,
                    policy_relationship: null,
                    state: null,
                },
                secondary_insurance: {
                    carrier: null,
                    member_id: null,
                    policy_dob: null,
                    policy_holder_name: null,
                    policy_relationship: null,
                    state: null,
                },
                ninety_day_care: null,
                assessment_status: {
                    assessment_status: false,
                    schedule_video_call: false,
                    initial_video_consult: false,
                    initial_prescriber_call: false,
                },
                initial_clinical_assessment_complete: false,
                outcome_clinical_assessment_complete: false,
                reports: {},
                rating_scale: null,
                post_90_day_rating_scale: null,
            },
            isPatientDetailsLoaded: false,
            isNotesFetched: false,
            isCarePlanLoaded: false,
            patientId: this.props.match.params.patientId,
            patientName: "",
            selectedTab: selectedTab,
            files: [],
            carePlan: null,
            carePlanData: {
                careTeam: null,
                carePlanFile: null,
            },
            noteData: null,
            unfilteredNotes: null,
            progressTrendsData: {},
            medicalData: {
                insuranceImageUrls: {
                    pfUrl: null,
                    pbUrl: null,
                    sfUrl: null,
                    sbUrl: null,
                },
            },
            timelineData: {
                practiceName: null,
                activities: [],
            },
            patientDiagnosis: null,
            openScheduleIntake: openScheduleIntake,
            eligibilityDetails: {},
        };
    }

    constructor(props) {
        super(props);
        this.adminFocusRef = React.createRef();
        this.state = this.buildInitialState();
    }

    async componentDidMount() {
        await this.getPatientDetailsAndFiles();
        await this.getCarePlan();
        const urlParams = { patientId: this.props.match.params.patientId };
        this.setState({
            patientDiagnosis: await api.shared.get_patient_diagnosis({ urlParams }),
        });
        if (this.state.selectedTab === "Notes" || this.state.selectedTab === "Medical") {
            await this.getProgressNotes();
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        // Explicitly reset all state on patientId change to minimize risk of leftover state as multiple API calls
        // are involved. An explicit return is used (rather than using the shared 'patientIdChange' function) to prevent
        // a race condition with the other tab-switching behavior
        if (prevProps.match.params.patientId !== this.props.match.params.patientId) {
            this.setState(this.buildInitialState(), () => {
                this.componentDidMount();
            });
            return;
        }
        if (
            prevState.selectedTab !== this.state.selectedTab &&
            (this.state.selectedTab === "Notes" || this.state.selectedTab === "Medical")
        ) {
            await this.getProgressNotes();
        }
    }

    async getCarePlan() {
        const options = { params: { showLoader: true } };
        const urlParams = { patientId: this.state.patientId };
        const response = await api.shared.fetch_patient_care_plan_v2({ urlParams, options });
        this.setState({
            carePlan: response,
            isCarePlanLoaded: true,
        });
    }

    async getProgressNotes() {
        const urlParams = { patientId: this.state.patientId };
        const response = await api.shared.fetch_patient_notes_summary({ urlParams });
        if (response) {
            const notes = sortNotesBySessionDate(response);

            this.setState({
                noteData: filterNotesByNoteType(notes),
                unfilteredNotes: notes,
                isNotesFetched: true,
            });
        }
    }

    async getPatientDetails() {
        const urlParams = { patientId: this.state.patientId };
        const options = { params: { showLoader: true } };
        const response = await api.shared.fetch_patient_details({ urlParams, options });
        const patientDetails = response.user;
        const patientName = `${patientDetails.first_name} ${patientDetails.last_name}`;
        const eligibilityDetails = patientDetails.primary_eligibility_record;
        this.setState({
            patientDetails: patientDetails,
            patientName: patientName,
            isPatientDetailsLoaded: true,
            eligibilityDetails: eligibilityDetails,
        });
    }

    async getActivities() {
        let urlParams = { patientId: this.state.patientId };
        const options = { params: { showLoader: true } };
        try {
            const response = await api.shared.fetch_patient_activity_feed({ urlParams, options });
            this.setState({
                timelineData: {
                    ...this.state.timelineData,
                    activities: response.results,
                },
            });
        } catch (error) {
            console.log(error);
        }
    }

    async getPatientDetailsAndFiles() {
        await this.getPatientDetails();
        const files = await api.shared.get_files({
            entity_id: this.state.patientId,
            entity_type: "patient",
        });
        this.setState({
            files: files,
        });
    }

    getAssociatedFiles(files) {
        return files.filter((file) => {
            if (file?.file_sub_type === "ASSOCIATED_FILE_PATIENT") {
                return file;
            }
        });
    }

    renderCarePlanLoading = () => {
        // Stop gap until sticky footers are added
        return <div style={{ height: "100px" }} />;
    };

    render() {
        const showRestrictedSchedulingBanner =
            this?.state?.patientDetails?.is_scheduling_restricted;

        const selectedTabChangeHandler = async (tab) => {
            const newTabPath =
                this.props.history.location.pathname +
                this.props.history.location.search.replace(this.state.selectedTab, tab);
            this.props.history.push(newTabPath);
            if (tab === "Notes" && !this.state.isNotesFetched) {
                await this.getProgressNotes();
            }
            this.setState({ selectedTab: tab });
        };

        const renderSchedulingRestrictedBannerText = () => {
            const adminTextSuffix = (
                <span
                    onClick={() => {
                        this.setState({ selectedTab: "Admin" }, () =>
                            this?.adminFocusRef?.current?.scrollIntoView(),
                        );
                    }}
                    className={"bold apti-green-v2 underline_hover pointer"}>
                    Change Scheduling Restrictions
                </span>
            );

            const nonAdminTextSuffix =
                "To remove this restriction, please contact your " + "supervisor.";

            const textSuffix = isUserAdmin() ? adminTextSuffix : nonAdminTextSuffix;

            return <>This patient is unable to schedule or reschedule appointments. {textSuffix}</>;
        };

        return (
            <div className="container" {...getAutomationDataAttr("MemberProfilePage")}>
                {this.state.isPatientDetailsLoaded && (
                    <>
                        {this.state.patientDetails.show_paywall_banner && (
                            <PatientChartPaymentBanner
                                patientName={this.state.patientDetails.first_name}
                            />
                        )}
                        {!this.state.patientDetails.show_paywall_banner &&
                            showRestrictedSchedulingBanner && (
                                <ErrorCard className={"MemberProfile__error"}>
                                    <Text type={TextTypes.label} color={TextColors.red}>
                                        Patient Scheduling Restricted
                                    </Text>
                                    <Text type={TextTypes.paragraph} color={TextColors.black}>
                                        {renderSchedulingRestrictedBannerText()}
                                    </Text>
                                </ErrorCard>
                            )}

                        <MemberProfileHeader
                            patientDetails={this.state.patientDetails}
                            // used to refetch data after a user's status is changed
                            refetchDetails={async () => {
                                await this.getPatientDetails();
                                await this.getActivities();
                            }}
                            openScheduleIntake={this.state.openScheduleIntake}
                            patientDiagnosis={this.state.patientDiagnosis}
                            eligibilityDetails={this.state.eligibilityDetails}
                            updateState={(newState) => {
                                this.setState(newState);
                            }}
                            setPatientStatus={(status) => {
                                const patientDetails = { ...this.state.patientDetails };
                                patientDetails.status = status;
                                this.setState({ patientDetails });
                            }}
                            selectedTab={this.state.selectedTab}
                            selectedTabChangeHandler={selectedTabChangeHandler}
                        />
                        {this.state.selectedTab === "Reports" && (
                            <MemberProfileReports
                                data={this.state.files}
                                updateState={(newState) => this.setState(newState)}
                                patientDetails={this.state.patientDetails}
                            />
                        )}
                        {this.state.selectedTab === "Care Plan" && this.state.isCarePlanLoaded ? (
                            <MemberProfileCarePlan
                                data={this.state.carePlanData}
                                updateState={(newState) => this.setState(newState)}
                                patientDetails={this.state.patientDetails}
                                carePlan={this.state.carePlan}
                            />
                        ) : (
                            this.renderCarePlanLoading()
                        )}
                        {this.state.selectedTab === "Notes" && (
                            <MemberProfileNotes
                                patientDiagnosis={this.state.patientDiagnosis}
                                data={this.state.noteData}
                                patientDetails={this.state.patientDetails}
                                updateState={(newState) => this.setState(newState)}
                            />
                        )}
                        {this.state.selectedTab === "Medical" && (
                            <MemberProfileMedical
                                files={this.getAssociatedFiles(this.state.files)}
                                data={this.state.medicalData}
                                noteData={this.state.unfilteredNotes}
                                updateState={(newState) => {
                                    const updatedState = {
                                        ...newState,
                                        files: [
                                            ..._.get(newState, "files", []),
                                            ...this.state.files,
                                        ],
                                    };
                                    this.setState(updatedState);
                                }}
                                patientDetails={this.state.patientDetails}
                            />
                        )}
                        {this.state.selectedTab === "Timeline" && (
                            <MemberProfileTimeline
                                data={this.state.timelineData}
                                updateState={(newState) => this.setState(newState)}
                                patientDetails={this.state.patientDetails}
                            />
                        )}

                        {hasAccessToPaymentTab() && this.state.selectedTab === PAYMENT_TAB && (
                            <Payment patientDetails={this.state.patientDetails} />
                        )}

                        {isAuthorized(admin_cse) && this.state.selectedTab === "Admin" && (
                            <MemberProfileAdmin
                                adminFocusRef={this.adminFocusRef}
                                timelineData={this.state.timelineData}
                                updateState={(newState) => this.setState(newState)}
                                patientDetails={this.state.patientDetails}
                                selectedTabChangeHandler={selectedTabChangeHandler}
                                refetchDetails={async () => {
                                    await this.getPatientDetails();
                                    await this.getActivities();
                                }}
                            />
                        )}
                    </>
                )}
            </div>
        );
    }
}

export default withRouter(MemberProfile);
