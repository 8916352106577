// Copyright aptihealth, inc. 2021 All Rights Reserved

import { api } from "../../APIRequests";
import { getFeatureFlagValue } from "../../utils/featureFlag";
import { initialConfigsState } from "../reducers/configsReducer";
import * as actionTypes from "./actionTypes";

const configsLoaded = (configs) => {
    return {
        type: actionTypes.CONFIGS_LOADED,
        configs: configs,
    };
};

export const loadConfigs = () => async (dispatch) => {
    let configs = await Promise.all(
        Object.keys(initialConfigsState).map(async (featureName) => {
            const queryParams = { feature_name: featureName };
            const options = { params: { showLoader: false } };
            return {
                name: featureName,
                value: await api.public.get_feature_flag({ queryParams, options }),
            };
        }),
    );

    configs = configs.reduce((obj, feature) => {
        obj[feature.name] = getFeatureFlagValue(feature);
        return obj;
    }, {});

    dispatch(configsLoaded(configs));
};
