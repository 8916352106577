import React from "react";
import { useParams } from "react-router-dom";
import { api } from "../../../../../APIRequests";
import { Card, Text, TextTypes, Toggle } from "../../../../../component-library";
import CardHeading from "../../../../UI/Headings/CardHeading";
import Hr from "../../../../UI/Hr";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { addToast, toastMessageTypes } from "../../../../../redux/actions/toaster";

const actionDescription = "Restrict this patient's ability to schedule and reschedule their appointments.";
const restrictedToastMessage = "Patient scheduling restricted.";
const unrestrictedToastMessage = "Patient scheduling restriction removed.";

const RestrictPatientSchedulingCard = ({ patientDetails, refetchDetails }) => {
    const dispatch = useDispatch();
    const { patientId } = useParams();
    const isSchedulingRestricted = patientDetails.is_scheduling_restricted;
    const isPatientInactive = patientDetails.status === "INACTIVE";

    const onToggleChange = async () => {
        const updatedRestrictionState = !isSchedulingRestricted;

        await api.admin.restrict_patient_scheduling({
            data: {    
                patient_id: patientId,
                is_scheduling_restricted: updatedRestrictionState
            }
        });
        await refetchDetails();

        dispatch(
            addToast({
                message: updatedRestrictionState ? restrictedToastMessage : unrestrictedToastMessage,
                messageType: toastMessageTypes.success,
            }),
        );
    };

    return (
        <div className={"AdminTab__RestrictPatientScheduling"}>
            <Card className={"p-3 p-lg-4 mb-3 mb-lg-4"}>
                <CardHeading className="fs-16 m-0 mb-3" text={"Restrict Patient Scheduling"} />
                <Hr />
                <div className="no-gutters row w-100">
                    <div className="mt-4">
                        <p className={"admin-card-description"}>{actionDescription}</p>
                        <div className={"mt-3"} style={{ display: "flex", alignItems: "center" }}>
                            <Toggle
                                name='restrict_patient_toggle'
                                disabled={isPatientInactive}
                                checked={isSchedulingRestricted}
                                onChange={onToggleChange}
                                style={{ marginRight: 10 }}
                            />
                            <Text
                                type={TextTypes.paragraph}
                                style={isPatientInactive ? { opacity: 0.6 } : {}}
                            >
                                Restrict Patient Scheduling
                            </Text>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
};

RestrictPatientSchedulingCard.propTypes = {
    patientDetails: PropTypes.object,
    refetchDetails: PropTypes.func,
};

export default RestrictPatientSchedulingCard;
