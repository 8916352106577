import React from "react";
import _ from "lodash";
import CardSecondary from "../../../UI/Card/CardSecondary";
import images from "../../../../utils/images";
import { TextArea } from "../../../UI/StyledInput";
import { RATING_OPTIONS_LABEL_MAP } from "./MemberProfileNotes";
import { BehavioralGoalsRating } from "./MemberProfileNotesBehavioralGoals";

export const PrescribeDiagnosisProgress = (props) => {
    const { note, editable, editHandler, profileView } = props;

    let diagnosisProgress = note.content.diagnosis_progress;

    const [isEditing, setIsEditing] = React.useState(false);
    const [editCopy, setEditCopy] = React.useState(_.cloneDeep(diagnosisProgress));

    const handleEditCopyChange = (e, index, key, isNumber = false) => {
        let editCopyClone = _.cloneDeep(editCopy);
        editCopyClone[index][key] = isNumber ? parseInt(e.target.value) : e.target.value;
        setEditCopy(editCopyClone);

        if (editHandler) {
            editHandler("diagnosis_progress", editCopyClone);
        }
    };

    let content = (
        <>
            <div className="row mx-1 px-1">
                <div className="col-lg-5">
                    <p className="clr-sec fw-bold">Diagnosis</p>
                </div>
                <div className="col-lg-3 fw-bold">
                    <p className="clr-sec fw-bold">Assessment</p>
                </div>
                <div className="col-lg-3 fw-bold">
                    <div className="d-flex justify-content-between">
                        <p className="clr-sec"> Plan</p>
                        {editable && !isEditing && (
                            <img
                                src={images("./icons/edit-2.svg")}
                                alt="Edit Goals, Objectives, Progress"
                                style={{
                                    width: "30px",
                                    height: "30px",
                                }}
                                onClick={() => setIsEditing((curr) => !curr)}
                            />
                        )}
                        {editable && isEditing && (
                            <img
                                src={images("./icons/save.svg")}
                                alt="Save"
                                style={{
                                    width: "30px",
                                    height: "30px",
                                }}
                                onClick={() => {
                                    setIsEditing((curr) => !curr);
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
            {diagnosisProgress.map((progress, index) => {
                let style = { minHeight: 50 };
                if (index !== diagnosisProgress.length - 1) {
                    style.borderBottom = "1px  solid #DAE6EC";
                } else if (index === diagnosisProgress.length - 1 && profileView) {
                    style.borderBottom = "2px  solid #DAE6EC";
                }
                return (
                    <div className="row mt-1 mx-1 px-1" style={style}>
                        <div className="col-lg-5">
                            <div>
                                {index + 1}. {progress.label}
                            </div>

                            <div>
                                <div className="col-12 d-flex justify-content-start">
                                    {Object.values(RATING_OPTIONS_LABEL_MAP).map((optionLabel) => (
                                        <div className="w-25 fs-10 fw-bold text-center">
                                            <div className="m-auto">{optionLabel}</div>
                                        </div>
                                    ))}
                                </div>
                                <BehavioralGoalsRating
                                    optionLabelMap={RATING_OPTIONS_LABEL_MAP}
                                    progress={!editable ? progress : editCopy[index]}
                                    isEditing={editable}
                                    onClick={(e) => handleEditCopyChange(e, index, "answer", true)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            {!isEditing ? (
                                <p>{progress.assessment}</p>
                            ) : (
                                <TextArea
                                    value={editCopy[index].assessment}
                                    onChange={(e) => handleEditCopyChange(e, index, "assessment")}
                                />
                            )}
                        </div>
                        <div className="col-lg-3">
                            {!isEditing ? (
                                <p>{progress.plan}</p>
                            ) : (
                                <TextArea
                                    value={editCopy[index].plan}
                                    onChange={(e) => handleEditCopyChange(e, index, "plan")}
                                />
                            )}
                        </div>
                    </div>
                );
            })}
        </>
    );

    let desktopContent;

    if (!profileView) {
        desktopContent = (
            <CardSecondary className="mx-auto px-3 py-3 mt-3 d-none d-md-none d-lg-block">
                {content}
            </CardSecondary>
        );
    } else {
        desktopContent = <div className="">{content}</div>;
    }

    let mobileContent = (
        <div className="d-block d-md-block d-lg-none mt-3">
            <div className="d-flex justify-content-between">
                <p className="cmt-3 fw-bold clr-sec">Diagnosis</p>
                {editable && !isEditing && (
                    <img
                        src={images("./icons/edit-2.svg")}
                        alt="Diagnosis"
                        style={{
                            width: "30px",
                            height: "30px",
                        }}
                        onClick={() => setIsEditing((curr) => !curr)}
                    />
                )}
                {editable && isEditing && (
                    <img
                        src={images("./icons/save.svg")}
                        alt="Save"
                        style={{
                            width: "30px",
                            height: "30px",
                        }}
                        onClick={() => {
                            setIsEditing((curr) => !curr);
                        }}
                    />
                )}
            </div>
            {diagnosisProgress.map((progress, index) => {
                return (
                    <CardSecondary className="mx-auto  mt-2 px-3 py-3 d-block">
                        <div className="col-lg-3 fw-bold">
                            <p className="clr-sec fw-bold">Diagnosis</p>
                        </div>
                        <div>
                            {index + 1}. {progress.label}
                        </div>
                        <BehavioralGoalsRating
                            optionLabelMap={RATING_OPTIONS_LABEL_MAP}
                            progress={progress}
                            isEditing={isEditing}
                            onClick={(e) => handleEditCopyChange(e, index, "answer", true)}
                        />

                        <div className="col-lg-3 fw-bold">
                            <p className="clr-sec fw-bold">Assessment</p>
                        </div>
                        <div className="col-lg-3">
                            {!isEditing ? (
                                <p>{progress.actions}</p>
                            ) : (
                                <TextArea
                                    value={editCopy[index].actions}
                                    onChange={(e) => handleEditCopyChange(e, index, "assessment")}
                                />
                            )}
                        </div>

                        <div className="col-lg-4 fw-bold">
                            <p className="clr-sec fw-bold">Plan</p>
                        </div>
                        <div className="col-lg-3">
                            {!isEditing ? (
                                <p>{progress.plan}</p>
                            ) : (
                                <TextArea
                                    value={editCopy[index].plan}
                                    onChange={(e) => handleEditCopyChange(e, index, "plan")}
                                />
                            )}
                        </div>
                    </CardSecondary>
                );
            })}
        </div>
    );

    return (
        <>
            {desktopContent}
            {mobileContent}
        </>
    );
};
