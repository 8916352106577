// Copyright aptihealth, inc. 2019 All Rights Reserved
import "./styles.scss";

import React from "react";
import Hr from "../../UI/Hr";

const QuestionnaireLayout = (props) => {
    const { heading, children, headerRightContent } = props;

    return (
        <div className="container">
            <div className="QuestionnaireLayout px-3 mx-auto">
                <div className="d-flex justify-content-between">
                    <h1 className="QuestionnaireLayout__heading fs-16 fw-bold m-0 px-0 px-lg-3">
                        {heading}
                    </h1>
                    {headerRightContent && (
                        <h1 className="QuestionnaireLayout__heading fs-16 fw-bold m-0 px-0 px-lg-3">
                            {headerRightContent}
                        </h1>
                    )}
                </div>
                <Hr className="" />
                {/* `props.children` */}
                {children}
            </div>
        </div>
    );
};

export default QuestionnaireLayout;
