// Copyright aptihealth, inc. 2021 All Rights Reserved
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { PatientConcentScreen } from "../index";
import { getQueryParams } from "../../../utils/filters";
import { Form, Formik } from "formik";
import images from "../../../utils/images";
import FormikInput from "../../UI/FormikInput";
import Button from "../../UI/Button";
import * as yup from "yup";
import * as Yup from "yup";
import { yup_password_factory } from "../../../utils/yupSchemaUtils";
import { connect } from "react-redux";
import { completeAutoSignUp } from "../../../redux/actions/auth";
import Input from "../../UI/Input";
import { cloneDeep as _cloneDeep } from "lodash";
import { getLocalTimezone } from "../../../redux/reducers/authReducer";
import { getConsentSubHeader } from "../PatientConcentScreen";
import { Footer } from "../../../component-library/Footer";
import { CustomForm } from "../../../component-library/CustomForm";

// AutoSignup route (and /auth/auto-signup route) should now be fully deprecated
// This signup flow will no longer work as email will not be available as a queryparam
const AutoSignUp = (props) => {
    const [showPatientConsent, setShowPatientConsent] = useState(true);
    const [smsOptIn, setSmsOptIn] = useState(false);
    const formInitialValues = {
        email: "",
        code: getQueryParams("code", props.location.search),
        password: "",
        confirm_password: "",
    };

    const [consent, setConsent] = useState({
        eula: null,
        privacy: null,
        hipaa: null,
    });

    const submitHandler = async (formData, actions) => {
        const data = _cloneDeep(formData);
        data["preferences"] = {
            is_sms_enabled: smsOptIn,
            time_zone: getLocalTimezone(),
        };
        data["consent"] = consent;
        props.completeAutoSignUp(data);
    };

    const renderForm = (formikProps) => {
        const formikInputProps = {
            errors: formikProps.errors,
            touched: formikProps.touched,
            inputClasses: "Input--sm",
        };
        return (
            <Form>
                <div className="mx-auto px-0 mb-3">
                    <div className="container px-0">
                        <FormikInput
                            {...formikInputProps}
                            formEl={{
                                name: "password",
                                elementType: "input",
                                elementConfig: {
                                    type: "viewablePassword",
                                    label: "Create Password",
                                    placeholder: "Enter Password",
                                },
                            }}
                        />
                        <FormikInput
                            {...formikInputProps}
                            formEl={{
                                name: "confirm_password",
                                elementType: "input",
                                elementConfig: {
                                    type: "viewablePassword",
                                    label: "Confirm Password",
                                    placeholder: "Re-enter Password",
                                },
                            }}
                        />
                        <div className="col-12 d-flex pl-4 mt-4 mb-3 ml-1">
                            <Input
                                type="checkbox"
                                name="preferences"
                                style={{ height: 0, width: 0 }}
                                className="AddPatientInput mr-4"
                                onClick={() => setSmsOptIn(!smsOptIn)}
                            />
                            <div className="checkbox-text fw-bold fs-16 ml-2">
                                Check this box if you would like to receive SMS communications
                                regarding your care plan.
                            </div>
                        </div>
                        <div className="ResetPassword__submit-btn-wpr d-flex justify-content-center">
                            <Button
                                disabled={formikProps.isSubmitting}
                                type="submit"
                                className="Btn Btn--pri Btn-sm mt-1 ">
                                Create Password
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <>
            {showPatientConsent && (
                <PatientConcentScreen
                    continue={() => {
                        window.scrollTo(0, 0);
                        setShowPatientConsent(false);
                    }}
                    consent={consent}
                    setConsent={setConsent}
                />
            )}
            {!showPatientConsent && (
                <div>
                    <img
                        className="img-fluid"
                        src={images("./consent-header.png")}
                        alt={"Your Fast Track to Better Mental Health"}
                    />
                    {getConsentSubHeader()}
                    <div className={"brand-popup-inner-card"} style={{ maxWidth: 500 }}>
                        <CustomForm
                            initialValues={formInitialValues}
                            validationSchema={AUTO_SIGNUP_VALIDATION}
                            onSubmit={submitHandler}
                            render={renderForm}
                        />
                    </div>
                    <Footer />
                </div>
            )}
        </>
    );
};

export default connect(null, { completeAutoSignUp })(withRouter(AutoSignUp));

const AUTO_SIGNUP_VALIDATION = yup.object().shape({
    password: yup_password_factory(),
    confirm_password: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref("password"), null], "Password does not match"),
});
