/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const PrinterIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Printer"
                {...props}>
                <path d="M12 13L20 13V11L12 11V13Z" />
                <path d="M20 9L12 9V7L20 7V9Z" />
                <path d="M23 21.5C23.8284 21.5 24.5 20.8284 24.5 20C24.5 19.1716 23.8284 18.5 23 18.5C22.1716 18.5 21.5 19.1716 21.5 20C21.5 20.8284 22.1716 21.5 23 21.5Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 3V13H25C27.2091 13 29 14.7909 29 17V23C29 24.1046 28.1046 25 27 25H24V29H8V25H5C3.89543 25 3 24.1046 3 23V17C3 14.7909 4.79086 13 7 13H8V3H24ZM22 15H10V5H22V15ZM24 17V15H25C26.1046 15 27 15.8954 27 17V23H5L5 17C5 15.8954 5.89543 15 7 15H8V17H24ZM10 25H22V27H10V25Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Printer"
                {...props}>
                <path d="M20 9L12 9V7L20 7V9Z" />
                <path d="M12 13L20 13V11L12 11V13Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 13V3H8V13H7C4.79086 13 3 14.7909 3 17V23C3 24.1046 3.89543 25 5 25H8V29H24V25H27C28.1046 25 29 24.1046 29 23V17C29 14.7909 27.2091 13 25 13H24ZM22 5H10V15H22V5ZM22 25H10V27H22V25ZM24.5 20C24.5 20.8284 23.8284 21.5 23 21.5C22.1716 21.5 21.5 20.8284 21.5 20C21.5 19.1716 22.1716 18.5 23 18.5C23.8284 18.5 24.5 19.1716 24.5 20Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Printer"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.5 11.75H24.5V19.25H15.5V11.75ZM23 14.75H17V13.25H23V14.75ZM17 16.25V17.75H23V16.25H17Z"
                />
                <path d="M15.5 28.25V26.75H24.5V28.25H15.5Z" />
                <path d="M25.25 24.125C25.8713 24.125 26.375 23.6213 26.375 23C26.375 22.3787 25.8713 21.875 25.25 21.875C24.6287 21.875 24.125 22.3787 24.125 23C24.125 23.6213 24.6287 24.125 25.25 24.125Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM26 10.25V17.75H26.75C28.4069 17.75 29.75 19.0931 29.75 20.75V25.25C29.75 26.0784 29.0784 26.75 28.25 26.75H26V29.75H14V26.75H11.75C10.9216 26.75 10.25 26.0784 10.25 25.25V20.75C10.25 19.0931 11.5931 17.75 13.25 17.75H14V10.25H26Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

PrinterIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

PrinterIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
