// Copyright aptihealth, inc. 2019 All Rights Reserved
import { STATES } from "../constants/states";

export const INITIAL_VALUES = {
    email: "",
    first_name: "",
    last_name: "",
    dob: "",
    gender: "Male",
    mobile: "",
    address: {
        street: "",
        city: "",
        state: "",
        zip_code: "",
    },
    emergency_name: "",
    emergency_contact_no: "",
};

export const CONTACT_INFO_INITIAL_VALUES = {
    email: "",
    first_name: "",
    last_name: "",
    dob: "",
    gender: "Male",
    mobile: "",
};

export const ADDRESS_INITIAL_VALUES = {
    address: {
        street: "",
        city: "",
        state: "",
        zip_code: "",
    },
};

export const EMERGENCY_CONTACT_INITIAL_VALUES = {
    emergency_name: "",
    emergency_contact_no: "",
};

export const BASIC_CONTACT_INFO_SCHEMA = [
    {
        name: "first_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "First Name",
            placeholder: "First name",
        },
    },
    {
        name: "last_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Last Name",
            placeholder: "Last name",
        },
    },
    {
        name: "dob",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Date of Birth",
            placeholder: "MM/DD/YYYY",
        },
    },
    {
        name: "gender",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "male", display: "Male" },
                { value: "female", display: "Female" },
            ],
            label: "Gender",
            placeholder: "Gender",
        },
    },
    {
        name: "mobile",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Mobile",
            placeholder: "Mobile",
        },
    },
    {
        name: "email",
        elementType: "input",
        elementConfig: {
            type: "email",
            label: "Email",
            placeholder: "Email",
        },
    },
];

export const CHILD_ADDRESS_AUTOFILL = [
    {
        name: "child_address_autofill",
        elementType: "input",
        elementConfig: {
            displayName: "Check this box if your child's address is the same as yours",
            type: "checkbox",
            classNames: "font-gray",
        },
        onClick: true,
    },
];

export const DEFAULT_CHILD_PRACTICE_AUTOFILL = [
    {
        name: "default_child_practice_autofill",
        elementType: "input",
        elementConfig: {
            displayName: "Child has a different Primary Care Physician than above",
            type: "checkbox",
            classNames: "font-gray",
        },
        onClick: true,
    },
];

export const CHILD_SMS_CONSENT = [
    {
        name: "child_sms_consent",
        elementType: "input",
        elementConfig: {
            displayName:
                "I consent by electronic signature to receive calls, text messages (including SMS and MMS messages), and other electronic messages (including those made using an automatic telephone dialing system or an artificial or prerecorded voice) from aptihealth, healthcare providers with whom I am matched by aptihealth, or any of their agents or representatives at the phone number I have provided even if that number is registered on a corporate, state, or national do not call registry. I understand that my consent to such calls and messages is not required to use aptihealth’s services and that I may contact aptihealth at support@aptihealth.com or 888-454-3827 about its services at any time. I also understand that I may revoke my consent at any time.",
            type: "checkbox",
            classNames: "fs-14 font-gray",
        },
        onClick: true,
    },
];

export const BASIC_ADDRESS_SCHEMA = [
    {
        name: "address.street",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Street",
            placeholder: "Enter Street",
        },
    },
    {
        name: "address.city",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "City",
            placeholder: "Enter City",
        },
    },
    {
        name: "address.state",
        elementType: "select",
        elementConfig: {
            options: STATES,
            label: "State",
            placeholder: "Enter State",
        },
    },
    {
        name: "address.zip_code",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Zip",
            placeholder: "Enter zip",
        },
    },
];

export const BASIC_EMERGENCY_CONTACT_SCHEMA = [
    {
        name: "emergency_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Emergency contact person",
            placeholder: "Emergency contact person",
        },
    },
    {
        name: "emergency_contact_no",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Emergency contact number",
            placeholder: "Emergency contact number",
        },
    },
];

export const BASIC_SCHEMA = [
    {
        name: "first_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "First Name",
            placeholder: "First name",
        },
    },
    {
        name: "last_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Last Name",
            placeholder: "Last name",
        },
    },
    {
        name: "dob",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Date of Birth",
            placeholder: "MM/DD/YYYY",
        },
    },
    {
        name: "gender",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "male", display: "Male" },
                { value: "female", display: "Female" },
            ],
            label: "Gender",
            placeholder: "Gender",
        },
    },
    {
        name: "mobile",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Mobile",
            placeholder: "Mobile",
        },
    },
    {
        name: "email",
        elementType: "input",
        elementConfig: {
            type: "email",
            label: "Email",
            placeholder: "Email",
        },
    },
    // below object does not render form element
    // only marks the hook for nested form and displays heading
    {
        elementType: "segment",
        displayName: "Address",
    },
    {
        name: "address.street",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Street",
            placeholder: "Enter Street",
        },
    },
    {
        name: "address.city",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "City",
            placeholder: "Enter City",
        },
    },
    {
        name: "address.state",
        elementType: "select",
        elementConfig: {
            options: STATES,
            label: "State",
            placeholder: "Enter State",
        },
    },
    {
        name: "address.zip_code",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Zip",
            placeholder: "Enter zip",
        },
    },
    {
        name: "emergency_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Emergency contact person",
            placeholder: "Emergency contact person",
        },
    },
    {
        name: "emergency_contact_no",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Emergency contact number",
            placeholder: "Emergency contact number",
        },
    },
];

export const PRI_INSURANCE_SCHEMA = [
    // below object does not render form element
    // only marks the hook for nested form and displays heading
    // {
    //     elementType: 'segment',
    //     displayName: 'Primary Insurance (Optional)'
    // },
    // {
    //     name: 'insurance[0].form.state',
    //     elementType: 'select',
    //     elementConfig: {
    //         options: [],
    //         label: 'State',
    //         placeholder: 'Select State'
    //     }
    // },
    // {
    //     name: 'insurance[0].form.carrier',
    //     elementType: 'select',
    //     elementConfig: {
    //         options: [],
    //         label: 'Carrier',
    //         placeholder: 'Select Carrier'
    //     }
    // },
    // // {
    // //     name: 'insurance[0].form.plan_type',
    // //     elementType: 'select',
    // //     elementConfig: {
    // //         options: [{ value: 'one', display: 'One' },
    // //         { value: 'two', display: 'Two' },
    // //         ],
    // //         label: 'Plan type',
    // //         placeholder: 'Select Plan type'
    // //     }
    // // },
    // {
    //     name: 'insurance[0].form.member_id',
    //     elementType: 'input',
    //     elementConfig: {
    //         type: 'text',
    //         label: 'Member ID',
    //         placeholder: 'Enter Member ID'
    //     }
    // },
];

export const SEC_INSURANCE_SCHEMA = [
    // below object does not render form element
    // only marks the hook for nested form and displays heading
    // {
    //     elementType: 'actionSegment',
    //     action: {
    //         handler: (cb) => { cb() },
    //         name: 'Remove',
    //         style: 'Btn Btn--clr-err p-0'
    //     },
    //     displayName: 'Secondary Insurance'
    // },
    // {
    //     name: 'insurance[1].form.state',
    //     elementType: 'select',
    //     elementConfig: {
    //         options: [],
    //         label: 'State',
    //         placeholder: 'Select State'
    //     }
    // },
    // {
    //     name: 'insurance[1].form.carrier',
    //     elementType: 'select',
    //     elementConfig: {
    //         options: [],
    //         label: 'Carrier',
    //         placeholder: 'Select Carrier'
    //     }
    // },
    // // {
    // //     name: 'insurance[1].form.plan_type',
    // //     elementType: 'select',
    // //     elementConfig: {
    // //         options: [{ value: 'one', display: 'One' },
    // //         { value: 'two', display: 'Two' },
    // //         ],
    // //         label: 'Plan type',
    // //         placeholder: 'Enter Plan type'
    // //     }
    // // },
    // {
    //     name: 'insurance[1].form.member_id',
    //     elementType: 'input',
    //     elementConfig: {
    //         type: 'text',
    //         label: 'Member Id',
    //         placeholder: 'Enter Member Id'
    //     }
    // },
];
