// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import ContentLoader from "react-content-loader";

const ListLoader = () => {
    const desktopVersion = (
        <ContentLoader
            height={60}
            width={900}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <circle cx="42" cy="30" r="20" />
            <rect x="105" y="23" rx="3" ry="3" width="100" height="10"></rect>
            <rect x="280" y="23" rx="3" ry="3" width="100" height="10"></rect>
            <rect x="530" y="23" rx="3" ry="3" width="100" height="10"></rect>
            <rect x="710" y="23" rx="3" ry="3" width="100" height="10"></rect>
        </ContentLoader>
    );

    const mobileStyles = {
        width: "100 %",
        padding: "16px 20px",
        "border-radius": "4px",
        "background-color": "#ffffff",
        "box-shadow": "0 4px 14px 0 rgba(0, 0, 0, 0.09)",
    };

    const mobileVersion = (
        <ContentLoader
            height={300}
            width={900}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <circle cx="60" cy="60" r="50"></circle>
            <rect x="200" y="30" rx="3" ry="3" width="400" height="20"></rect>
            <rect x="200" y="80" rx="3" ry="3" width="250" height="20"></rect>
            <rect x="0" y="180" rx="3" ry="3" width="900" height="5"></rect>
            <rect x="150" y="250" rx="3" ry="3" width="200" height="20"></rect>
            <rect x="550" y="250" rx="3" ry="3" width="200" height="20"></rect>
        </ContentLoader>
    );

    return (
        <>
            <div className="d-none d-lg-block">{desktopVersion}</div>
            <div className="d-none d-lg-block">{desktopVersion}</div>
            <div className="d-none d-lg-block">{desktopVersion}</div>
            <div style={mobileStyles} className="d-lg-none mb-3">
                {mobileVersion}
            </div>
            <div style={mobileStyles} className="d-lg-none mb-3">
                {mobileVersion}
            </div>
            <div style={mobileStyles} className="d-lg-none">
                {mobileVersion}
            </div>
        </>
    );
};

export default ListLoader;
