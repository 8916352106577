import React, { useState, useEffect } from "react";
import images from "../../utils/images";
import Button from "../../components/UI/Button";
import { getConsentSubHeader } from "../../components/Authentication/PatientConcentScreen";
import { ConsentToTermsOfUse } from "../../components/Common/ConsentToTermsOfUse";
import FormikInput from "../../components/UI/FormikInput";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { yup_password_factory } from "../../utils/yupSchemaUtils";
import * as Yup from "yup";
import { cloneDeep as _cloneDeep } from "lodash";
import { getLocalTimezone } from "../../redux/reducers/authReducer";
import Input from "../../components/UI/Input";
import { connect } from "react-redux";
import { completeAutoSignUp } from "../../redux/actions/auth";
import { withRouter } from "react-router-dom";
import { Footer } from "../../component-library/Footer";
import { CustomForm } from "../../component-library/CustomForm";
import { tagReferralPath } from "../../utils/logRocketUtils";

const ChildSignUp = ({ referralInfo, ...props }) => {
    const [consent, setConsent] = useState({
        eula: null,
        privacy: null,
        hipaa: null,
    });
    const [smsOptIn, setSmsOptIn] = useState(false);

    useEffect(() => {
        tagReferralPath("teens_kids_program");
    }, []);
    
    const currentYear = new Date().getFullYear();

    const continueButtonEnabled = () => {
        const conditionsArray = [
            Array.isArray(consent["eula"]),
            Array.isArray(consent["privacy"]),
            Array.isArray(consent["hipaa"]),
        ];
        return conditionsArray.includes(false);
    };

    const renderForm = (formikProps) => {
        const formikInputProps = {
            errors: formikProps.errors,
            touched: formikProps.touched,
        };

        return (
            <Form>
                <div className="mt-5">
                    <FormikInput
                        {...formikInputProps}
                        formEl={{
                            name: "email",
                            elementType: "input",
                            elementConfig: {
                                label: "Email",
                                placeholder: "Enter Email",
                            },
                        }}
                    />
                </div>
                <p className="mt-2">Create Your Password</p>
                <div className={"d-flex"}>
                    <div className={"mr-5 justify-content-between-input"}>
                        <FormikInput
                            {...formikInputProps}
                            formEl={{
                                name: "password",
                                elementType: "input",
                                elementConfig: {
                                    type: "viewablePassword",
                                    label: "Create Password",
                                    placeholder: "Enter Password",
                                },
                            }}
                        />
                    </div>
                    <div className={"ml-5 justify-content-between-input"}>
                        <FormikInput
                            {...formikInputProps}
                            formEl={{
                                name: "confirm_password",
                                elementType: "input",
                                elementConfig: {
                                    type: "viewablePassword",
                                    label: "Confirm Password",
                                    placeholder: "Re-enter Password",
                                },
                            }}
                        />
                    </div>
                </div>
                <div className={"d-flex justify-content-left"} style={{ width: "100%" }}>
                    <Button
                        type="submit"
                        className="Btn Btn--pri mt-4"
                        disabled={continueButtonEnabled()}>
                        Continue
                    </Button>
                </div>
            </Form>
        );
    };

    const onSubmit = (formData) => {
        const data = _cloneDeep(formData);
        data["preferences"] = {
            is_sms_enabled: smsOptIn,
            time_zone: getLocalTimezone(),
        };
        data["consent"] = consent;
        data["code"] = referralInfo.referralCode;
        props.completeAutoSignUp(data);
    };

    return (
        <div>
            <div>
                <img className="img-fluid" src={images("./consent-header.png")} />
            </div>
            {getConsentSubHeader()}
            <div className={"top-body body-padding"}>
                <p className={"green-text fs-17 fw-bold mt-5"}>Get Started</p>

                <div className={"pl-4 pr-4"}>
                    <p className={"mt-4"}>
                        Before you attend your first appointment, which is your therapist
                        consultation, we need you to finish setting up your aptihealth account by
                        creating a password and agreeing to receive care from us. If you don’t
                        complete this step, you won’t be able to log in and attend your therapy
                        sessions. All your therapy sessions will take place in your own account, not
                        your parents/caregivers.
                    </p>
                    <p className={"fw-bold mt-4"}>Agree to aptihealth Terms</p>
                    <p>
                        Please view and print/save the documents linked below as they tell you of
                        your rights while using aptihealth. By using aptihealth, you are agreeing to
                        our terms of use.
                    </p>
                    <ConsentToTermsOfUse consent={consent} setConsent={setConsent} />
                    <div className={"mb-4 d-flex"}>
                        <Input
                            className="custom-checkbox mr-2 mt-1"
                            type={"checkbox"}
                            onClick={() => setSmsOptIn(!smsOptIn)}
                        />
                        <div>
                            Check this box if you would like to receive calls, text messages
                            (including SMS and MMS messages), and other electronic messages
                            (including those made using an automatic telephone dialing system or an
                            artificial or prerecorded voice) from aptihealth healthcare providers
                            with whom I am matched by aptihealth, or any of their agents or
                            representatives at the phone number I have provided even if that number
                            is registered on a corporate, state, or national do not call registry. I
                            understand that my consent to such calls and messages is not required to
                            use aptihealth’s services and that I may contact aptihealth at
                            support@aptihealth.com or 888-454-3827 about its services or to revoke
                            this consent at any time.
                        </div>
                    </div>
                    <CustomForm
                        initialValues={{
                            email: "",
                            password: "",
                            confirm_password: "",
                        }}
                        validationSchema={passwordValidation}
                        onSubmit={onSubmit}
                        render={renderForm}
                    />
                    <p className={"fw-bold mt-5 mb-5"}>
                        For more information, visit aptihealth.com/welcome or call us at (888)
                        454-3827.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default connect(null, { completeAutoSignUp })(withRouter(ChildSignUp));

const passwordValidation = yup.object().shape({
    email: Yup.string().required("Email is required").email("Enter valid email"),
    password: yup_password_factory(),
    confirm_password: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref("password"), null], "Password does not match"),
});
