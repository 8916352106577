import React, { useEffect, useState } from "react";
import { CloseIcon } from "../../../components/Icons/CloseIcon";
import images from "../../../utils/images";

const AppBanner = ({ onClose, deviceInfo }) => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
        setIsDarkMode(isDarkMode);
    }, []);

    const handleClickView = () => {
        if (deviceInfo.os === "iOS") {
            window.location.href = "https://apps.apple.com/us/app/aptihealth/id1477170874";
        } else if (deviceInfo.os === "Android") {
            window.location.href =
                "https://play.google.com/store/apps/details?id=com.aptihealth.aptihealth";
        }
    };

    if (!deviceInfo.isMobile) {
        return <></>;
    }

    return (
        <div className="mobile-banner-container">
            <div className={`AppBanner d-flex ${isDarkMode ? "dark" : null}`}>
                <div className="BannerIconContainer d-flex align-items-center">
                    <CloseIcon iconSize="IconSmall" className="BannerCloseIcon" onClick={onClose} />
                </div>
                <div className="BannerLogoContainer d-flex align-items-center">
                    <img className="BannerLogo" src={images("./logo/aptihealth/android.png")} />
                </div>
                <div className="BannerTextContainer d-flex flex-column justify-content-center">
                    <div className={`BannerTitle ${isDarkMode ? "dark" : null}`}>aptihealth</div>
                    <div
                        className={`BannerText txt-ellipsis text-nowrap ${
                            isDarkMode ? "dark" : null
                        }`}>
                        Video Therapy & Med Management
                    </div>
                    <div
                        className={`BannerText txt-ellipsis text-nowrap ${
                            isDarkMode ? "dark" : null
                        }`}>
                        {`Get on ${deviceInfo.os === "iOS" ? "the App" : "Google Play"} Store`}
                    </div>
                </div>
                <div className="BannerButtonContainer ml-auto d-flex justify-content-center align-items-center">
                    <div
                        className={`BannerViewButton d-flex justify-content-center align-items-center ${
                            isDarkMode ? "dark" : null
                        }`}
                        onClick={handleClickView}>
                        View
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppBanner;
