// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";

export const CancellationPolicyPopup = ({ onClickHandler, showCancellationPolicy }) => {
    return (
        <div
            onClick={onClickHandler}
            className={`CancellationPolicyPopup__background${
                showCancellationPolicy ? " active" : ""
            }`}>
            <div className="CancellationPolicyPopup__popup">
                <h3 className="CancellationPolicyPopup__popup--title">Cancellation Policy</h3>
                <p className="CancellationPolicyPopup__popup--text">
                    aptihealth requires 24 hours' notice when making appointment changes, which
                    includes the cancellation and reschedulng of appointments.
                </p>
                <p className="CancellationPolicyPopup__popup--text">
                    Providing less than 24 hours' notice, or skipping the appointment completely,
                    will be considered a cancellation of the scheduled appointment and may result in
                    a fee.
                </p>
                <p className="CancellationPolicyPopup__popup--text">
                    Making excessive appointment changes may result in a change of scheduling
                    options.
                </p>
            </div>
        </div>
    );
};
