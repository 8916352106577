import React, { useState } from "react";
import "./styles.scss";
import PostSessionSurveyQuestion from "./PostSessionSurveyQuestion";
import _ from "lodash";
import { api } from "../../../../../APIRequests";
import Button from "../../../../../components/UI/Button";
import moment from "moment";
import { PostSessionSurveyTutorialHoc } from "../../../../../component-library/hocs/PostSessionSurveyTutorialHOC";

const PostSessionSurveyTutorial = (props) => {
    const { questionSet } = props;
    const [questions, setQuestions] = useState(_.get(_.cloneDeep(questionSet), "questions", []));
    const [currentStep, setCurrentStep] = useState(0);

    const onSecondarySubmit = () => {
        const data = { username: props.profile.username, pss_tutorial: moment().unix() };
        api.patient.update_profile({ data }).then();
        props.submit();
    };

    const render = () =>
        Object.keys(_.cloneDeep(questions)).map((questionKey, index) => {
            let tutorialLightUp = false;
            let tutorialComponent = null;
            let forceActive = false;

            if (currentStep < 3) {
                switch (index) {
                    case 0:
                        questions[questionKey]["answer"] = null;
                        break;
                    case 1:
                        questions[questionKey]["answer"] = null;
                        break;
                    case 2:
                        questions[questionKey]["answer"] = null;
                        break;
                    case 3:
                        questions[questionKey]["answer"] = null;
                        break;
                    case 4:
                        questions[questionKey]["answer"] = null;
                        break;
                    default:
                        questions[questionKey]["answer"] = null;
                        break;
                }
            }

            if (index === 0 && currentStep !== 1) {
                tutorialComponent = (
                    <PostSessionSurveyTutorialHoc
                        className={"pss-tutorial-hoc"}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        onSecondarySubmit={onSecondarySubmit}
                    />
                );

                if (currentStep === 2) {
                    questions[questionKey]["answer"] = 0;
                }
            }

            if (currentStep === 1) {
                tutorialLightUp = true;
            }

            if (currentStep === 2 && index === 0) {
                tutorialLightUp = true;
            }

            if (currentStep === 3) {
                forceActive = true;
                switch (index) {
                    case 0:
                        questions[questionKey]["answer"] = 2;
                        break;
                    case 1:
                        questions[questionKey]["answer"] = 0;
                        break;
                    case 2:
                        questions[questionKey]["answer"] = 1;
                        break;
                    case 3:
                        questions[questionKey]["answer"] = 4;
                        break;
                    case 4:
                        questions[questionKey]["answer"] = 3;
                        break;
                    default:
                        questions[questionKey]["answer"] = 2;
                        break;
                }
            }

            return (
                <PostSessionSurveyQuestion
                    handleEditChange={() => {}}
                    surveyQuestion={questions[questionKey]}
                    questionIndex={questionKey}
                    tutorialLightUp={tutorialLightUp}
                    tutorialComponent={tutorialComponent}
                    forceActive={forceActive}
                    key={index}
                />
            );
        });

    return (
        <div className="container">
            <div className="survey-back-link-wpr mx-auto my-3 py-2">
                <h3 className={"font-color-gray fw-bold"}>How would you rate today’s session?</h3>
                <p className={"font-color-gray fs-14"}>
                    We care about your progress and want to make sure you’re set up for success. Let
                    us know how we’re doing by answering the questions below.
                </p>
                {currentStep === 1 && (
                    <PostSessionSurveyTutorialHoc
                        className={"pss-tutorial-hoc"}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        onSecondarySubmit={onSecondarySubmit}
                    />
                )}
                {render()}
                <div className="d-flex justify-content-end mt-3">
                    <Button type={"button"} className="Btn Btn--pri Btn-sm">
                        Submit
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PostSessionSurveyTutorial;
