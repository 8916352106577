// Copyright aptihealth, inc. 2019 All Rights Reserved
import { GROUP_AUTH_HEADER_NAME } from "../constants/axios";
import { getGroupAuthType } from "../redux/actions/auth";
import { toastMessageTypes } from "../redux/actions/toaster";
import { httpAuth, http } from "../utils/axios";

const paymentRequests = {
    fetchPatientCardInfo: ({ options, data }) => {
        let conf = {
            method: "post",
            url: "/v3/payment/get_user_payment_info",
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            data,
            ...options,
        };
        return httpAuth(conf);
    },
    putPatientCardInfo: ({ options, data }) => {
        let conf = {
            method: "post",
            url: "/v2/payment/method",
            data,
            ...options,
        };
        return httpAuth(conf);
    },
    getPaymentSessionToken: ({ options, data, errorTypeConfig }) => {
        let conf = {
            method: "post",
            url: "/v2/payment/session_token",
            data,
            ...options,
            errorTypeConfig,
        };
        return httpAuth(conf);
    },
    fetchPatientPaywall: ({ options }) => {
        let conf = {
            method: "post",
            url: "/v2/payment/paywall_check",
            ...options,
        };
        return httpAuth(conf);
    },
    manualCharge: ({ options, data }) => {
        let conf = {
            method: "post",
            url: "/v1/payment/manual_charge",
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            toastErrorType: toastMessageTypes.error_v2,
            ...options,
        };
        return httpAuth(conf);
    },
    fetchTransactionHistory: ({ options, data }) => {
        let conf = {
            method: "post",
            url: "/v1/payment/get_user_payment_history",
            toastErrorType: toastMessageTypes.error_v2,
            data,
            ...options,
        };
        return httpAuth(conf);
    },
};

export default paymentRequests;
