import { httpAuth } from "../utils/axios";

const metricsRequests = {
    create_metric: function ({ pk, entity_id, entity_type, rank, segments, metadata, options }) {
        const data = {
            pk,
            entity_id,
            entity_type,
            rank,
        };

        if (segments) {
            data.segments = segments;
        }
        if (metadata) {
            data.metadata = metadata;
        }

        let conf = {
            method: "post",
            url: "/v1/metrics/create_metric",
            data,
            ...options,
        };
        return httpAuth(conf);
    },
};

export default metricsRequests;
