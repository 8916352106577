import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Select } from "../../UI/StyledInput";
import moment from "moment-timezone";
import { Text, TextTypes, Toggle } from "../../../component-library";

export const transformFrequencyDaysForServer = (seriesData) => {
    if (seriesData && seriesData["frequencyDays"]) {
        return seriesData["frequencyDays"].map((day) => {
            if (day === 0) {
                return 6;
            }
            return day - 1;
        });
    }
};

const ReoccurrenceDetails = (props) => {
    const DISPLAY_DAYS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    const DISPLAY_WEEKS_MAP = [
        { value: 0, display: "Week" },
        { value: 1, display: "2 Weeks" },
        { value: 2, display: "3 Weeks" },
        { value: 3, display: "4 Weeks" },
        { value: 5, display: "6 Weeks" },
        { value: 7, display: "8 Weeks" },
        { value: 11, display: "12 Weeks" },
    ];

    const { seriesData, updateState } = props;

    const [isReoccurring, setIsReoccurring] = useState(seriesData.isReoccurring);
    const [frequencyDays, setFrequencyDays] = useState(seriesData.frequencyDays);
    const [frequencyWeeks, setFrequencyWeeks] = useState(seriesData.frequencyWeeks);
    const [numberOfOccurrences, setNumberOfOccurrences] = useState(seriesData.numberOfOccurrences);

    useEffect(() => {
        const { seriesData } = props;
        setFrequencyDays(seriesData.frequencyDays);
        setFrequencyWeeks(seriesData.frequencyWeeks);
        setNumberOfOccurrences(seriesData.numberOfOccurrences);
        setIsReoccurring(seriesData.isReoccurring);
    }, [props.seriesData]);

    const getSeriesData = () => {
        return { isReoccurring, frequencyDays, frequencyWeeks, numberOfOccurrences };
    };

    const renderReoccurrenceWeeks = () => {
        return (
            <div className="col-6">
                <label className="font-weight-bold">Repeat Every</label>
                <Select
                    changeHandler={(option) => {
                        const frequencyWeeks = parseInt(option.target.value);
                        setFrequencyWeeks(frequencyWeeks);
                        updateState({
                            isReoccurring,
                            frequencyDays,
                            frequencyWeeks,
                            numberOfOccurrences,
                        });
                    }}
                    name="frequencyWeeks"
                    options={DISPLAY_WEEKS_MAP}
                    value={frequencyWeeks}
                />
            </div>
        );
    };

    const renderReoccurrenceDays = () => {
        return (
            <div className={"row"}>
                <div className="col-12">
                    <label className="font-weight-bold">On</label>
                    <div className="row mx-0 justify-content-between">
                        <SelectableList
                            key={"days"}
                            updateSelectedItems={(newState) => {
                                setFrequencyDays(newState);
                                updateState({
                                    isReoccurring,
                                    frequencyDays: newState,
                                    frequencyWeeks,
                                    numberOfOccurrences,
                                });
                            }}
                            isMultiSelect={true}
                            selectedItems={frequencyDays}
                            list={DISPLAY_DAYS}
                            itemMinWidth="42px"
                            itemClassName="cursor font-weight-bold border text-center py-2 mr-1"></SelectableList>
                    </div>
                </div>
            </div>
        );
    };

    const renderNumberOfOccurrence = () => {
        const sessionDisplay = [];
        for (let index = 1; index <= 15; index++) {
            const sessionLabel = index > 1 ? "sessions" : "session";
            sessionDisplay.push({ value: index, display: `${index} ${sessionLabel}` });
        }

        return (
            <div className="col-6">
                <label className="font-weight-bold">For</label>
                <Select
                    changeHandler={(option) => {
                        const numOfOccurrences = parseInt(option.target.value);
                        setNumberOfOccurrences(numOfOccurrences);
                        updateState({
                            isReoccurring,
                            frequencyDays,
                            frequencyWeeks,
                            numberOfOccurrences: numOfOccurrences,
                        });
                    }}
                    name="numberOfOccurrences"
                    options={sessionDisplay}
                    value={numberOfOccurrences}
                />
            </div>
        );
    };

    const toggleButton = (
        <div className={"row col-12 align-items-center"}>
            <Toggle
                className={"mr-2"}
                id={"test"}
                checked={isReoccurring}
                onClick={() => {
                    setIsReoccurring(!isReoccurring);
                    updateState({
                        isReoccurring: !isReoccurring,
                        frequencyDays,
                        frequencyWeeks,
                        numberOfOccurrences,
                    });
                }}
            />
            <Text className={"fs-14"} type={TextTypes.label}>
                Recurring
            </Text>
        </div>
    );

    const fullReoccurenceView = (
        <>
            {toggleButton}
            <div className={"p-3 ReoccurrenceDetails__session_choices"}>
                <div className={"row mb-2"}>
                    {renderReoccurrenceWeeks()}
                    {renderNumberOfOccurrence()}
                </div>
                {renderReoccurrenceDays()}
            </div>
        </>
    );

    const render = isReoccurring ? fullReoccurenceView : toggleButton;

    return <div className="flex-grow-1 mb-3 fs-14">{render}</div>;
};

const SelectableList = (props) => {
    const selectedItemsToArray = () => {
        let selectedItemsAsArray = [];
        if (props.selectedItems !== undefined) {
            selectedItemsAsArray = Array.isArray(props.selectedItems)
                ? props.selectedItems
                : [props.selectedItems];
        }
        return selectedItemsAsArray;
    };

    const initialList = props.list && props.list.length > 0 ? props.list : [];
    const initialMinWidth = props.itemMinWidth ? props.itemMinWidth : "40px";
    const selectedItemsAsArray = selectedItemsToArray();

    const [list, setList] = useState(initialList);
    const [itemMinWidth, setItemMinWidth] = useState(initialMinWidth);
    const [isMultiSelect, setIsMultiSelect] = useState(props.isMultiSelect);
    const [selectedItems, setSelectedItems] = useState(selectedItemsAsArray);

    const className = props.itemClassName ? props.itemClassName : "";

    const handleMultiSelectAdd = (tempSelected, itemSelected) => {
        if (isMultiSelect) {
            tempSelected.push(itemSelected);
        } else {
            tempSelected.pop();
            tempSelected.push(itemSelected);
        }
    };

    const handleSelection = (index) => {
        const tempSelected = selectedItems ? _.cloneDeep(selectedItems) : [];
        if (Array.isArray(selectedItems) && selectedItems.includes(index)) {
            tempSelected.splice(tempSelected.indexOf(index), 1);
        } else {
            handleMultiSelectAdd(tempSelected, index);
        }

        setSelectedItems(tempSelected);
        if (props.updateSelectedItems) {
            props.updateSelectedItems(tempSelected);
        }
    };

    useEffect(() => {
        const selectedItemsAsArray = selectedItemsToArray();
        setSelectedItems(selectedItemsAsArray);
    }, [props.selectedItems]);

    if (props.renderFunction) {
        return props.renderFunction(list);
    }

    return list.map((value, index) => {
        const isSelectedClass =
            Array.isArray(selectedItems) && selectedItems.includes(index)
                ? " ReoccurrenceDetails ReoccurrenceDetails__active-list"
                : " ReoccurrenceDetails ReoccurrenceDetails__inactive-list";

        const columnRender = (
            <span
                key={`${value}` + index}
                onClick={() => handleSelection(index)}
                style={{ minWidth: itemMinWidth }}
                className={className + isSelectedClass}>
                {value}
            </span>
        );
        return columnRender;
    });
};

export default ReoccurrenceDetails;
