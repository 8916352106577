import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { timeSlots } from "../../../constants/calendar";
import { calculateBackgroundDayPosition } from "../../../utils/calendar/positions";
import { useMemo } from "react";
import moment from "moment";

export const CalendarNonWorkingHours = ({ calendarRect, numberOfDays }) => {
    // only recalculate each time calendar rect changes
    const nonWorkingHourElements = useMemo(() => {
        if (!calendarRect) {
            return null;
        }
        return new Array(numberOfDays).fill(null).map((_, index) => {
            return timeSlots.map(({ slot }) => {
                const start = moment(slot, "HH:mm");
                const end = moment(slot, "HH:mm").add(1, "hour");
                const position = calculateBackgroundDayPosition(start, end, index, calendarRect);
                return <div className="CalendarNonWorkingHours__slot" style={position} />;
            });
        });
    }, [calendarRect]);

    return nonWorkingHourElements;
};

CalendarNonWorkingHours.propTypes = {
    calendarRect: PropTypes.object,
    numberOfDays: PropTypes.number,
};

CalendarNonWorkingHours.defaultProps = {
    numberOfDays: 1,
};
