// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import "./styles.scss";
import * as yup from "yup";
import { ForgotPassword, Referral, SignIn } from "../../components/Authentication";
import PatientSignUp from "./PatientSignUp";
import ChildSignUp from "./ChildSignUp";
import { Redirect, Route, Switch } from "react-router-dom";
import Button from "../../components/UI/Button";
import {
    hideRsPwSuccess,
    logIn,
    mailResetPasswordCode,
    resetPw,
    showEmailVerified,
    signUp,
    validateReferral,
    resetForgotPwStep,
} from "../../redux/actions/auth";
import { connect } from "react-redux";
import { api } from "../../APIRequests";
import { getQueryParams } from "../../utils/filters";
import PatientSignUpSuccess from "../../components/Authentication/PatientSignUpSuccess";
import SelfSignUp from "../../components/Authentication/SelfSignUp/index";
import AutoSignUp from "../../components/Authentication/AutoSignUp";
import { trackEvent } from "../../utils/EventTrackingUtil";
import PasswordResetRequired from "../../components/Authentication/PwResetRequired";
import SSO from "../../components/Authentication/SSO";
import CompleteSignIn from "../../components/Authentication/CompleteSignIn";
import SignUp from "../../components/Authentication/SignUp";

const images = require.context("../../assets/images/", true);

class Authentication extends Component {
    referralCodeSchema = yup.string().required("Referral code is required");

    scrlWprRef = React.createRef();
    code = getQueryParams("code", this.props.location.search);
    campaign_id = getQueryParams("campaign_id", this.props.location.search);
    state = {
        referralForm: {
            errors: [],
            referralCode: this.code ? this.code : "",
        },
        email: "",
        forgotPasswordStep: 1,
        accountLocked: false,
        idpOptions: [],
        showIdp: false,
        completeSignIn: false,
    };

    componentDidMount() {
        if (
            this.state.referralForm.referralCode &&
            window.location.pathname !== "/auth/auto-signup" &&
            window.location.pathname !== "/auth/sso"
        ) {
            this.onReferralSubmit();
        }
        if (this.campaign_id === "hcp_welcome_v1") {
            trackEvent("HCP Member on consent screen");
        }
    }

    referralChange = (e) => {
        this.setState({
            referralForm: {
                errors: [],
                referralCode: e.target.value,
            },
        });
    };

    setEmail = (e) => {
        this.setState({
            email: e.target.value,
        });
    };

    onReferralSubmit = () => {
        this.referralCodeSchema
            .validate(this.state.referralForm.referralCode)
            .then((values) => {
                this.props.validateReferral(values, () => {
                    if (this.campaign_id === "child_referral_v1") {
                        this.props.history.push(
                            `/auth/child-sign-up?code=${this.state.referralForm.referralCode}`,
                        );
                    } else {
                        this.props.history.push("/auth/sign-up");
                    }
                });
            })
            .catch((err) => {
                this.setState((ps) => {
                    return {
                        ...ps,
                        referralForm: {
                            errors: err.errors.slice(),
                        },
                    };
                });
            });
    };

    resetCodeHandler = () => {
        this.props.mailResetPasswordCode(this.state.lastSubmittedEmail);
        this.setState({ email: this.state.lastSubmittedEmail });
        this.props.history.push("/auth/forgot-password");
    };

    checkIdp = async (email) => {
        const data = {
            email: email,
        };
        try {
            const response = await api.auth.get_user_idp({ data });
            if (response.idp_login_url && response.idp_login_url.length) {
                this.setState({ idpOptions: response });
                localStorage.setItem("idpOptions", JSON.stringify(response));
                localStorage.setItem("idpLoginUrl", response.idp_login_url);
                localStorage.setItem("idpLogoutUrl", response.idp_logout_url);
                localStorage.setItem("idpPrincipleUser", email);
                localStorage.setItem("ssoInProgress", true);
                this.setState({ showIdp: true });
                this.setState({ email: email });
                this.props.history.push("/auth/sso");
            } else {
                this.setState({ completeSignIn: true }, function () {
                    this.props.history.push("/auth/complete-sign-in");
                });
            }
        } catch (e) {
            console.log(e);
            this.setState({ completeSignIn: true }, function () {
                this.props.history.push("/auth/complete-sign-in");
            });
        }
    };

    check_if_idp = async (values, actions) => {
        actions.setSubmitting(false);
        this.checkIdp(values.USERNAME);
        this.setState({ email: values.USERNAME });
    };

    submitHandlerSignIn = async (values, actions) => {
        actions.setSubmitting(false);
        if (this.state.lastSubmittedEmail && this.state.email !== this.state.lastSubmittedEmail) {
            this.setState({ accountLocked: false });
        }

        values["USERNAME"] = this.state.email;
        await this.props.logIn(values);

        if (this.props.auth.errorType && this.props.auth.errorType === "UserLockedOutException") {
            this.setState({ lastSubmittedEmail: values.USERNAME });
            this.setState({ accountLocked: true });
        } else if (
            this.props.auth.errorType &&
            this.props.auth.errorType === "PasswordResetRequired"
        ) {
            this.props.history.push("/auth/password-reset-required");
        }
    };

    // handlers for reset/forgot password screens
    mailResetPwCode = (form, actions) => {
        actions.resetForm();
        this.props.mailResetPasswordCode(form.email);
        // this.setState({
        //     forgotPasswordStep: 2
        // })
    };

    resetPwCodeAgain = (email) => {
        this.props.mailResetPasswordCode(email);
    };

    resetPwSteps = () => {
        this.props.resetForgotPwStep();
    };
    setNewPasswordHandler = (form, actions) => {
        let formCopy = Object.assign({}, form);
        delete formCopy.ConfirmPassword;
        delete formCopy.email;
        if (this.props.auth.forgotPasswordEmail) {
            formCopy["Username"] = this.props.auth.forgotPasswordEmail;
            this.props.resetPw(formCopy, (success) => {
                actions.setSubmitting(false);
                if (success) {
                    this.props.history.push({ pathname: "/auth/sign-in" });
                    actions.resetForm();
                    this.setState({ accountLocked: false });
                }
            });
        }
    };

    setNewPasswordWithEmailHandler = (form, actions) => {
        let data = {
            Username: form.email,
            confirmation_code: form.confirmation_code,
            Password: form.Password,
        };
        this.props.resetPw(data, (success) => {
            actions.setSubmitting(false);
            if (success) {
                this.props.history.push({ pathname: "/auth/sign-in" });
                this.setState({ accountLocked: false });
            }
        });
    };

    showEmailVerifiedPopUp = () => {
        this.props.showEmailVerified(true);
    };

    scrollToTop = () => {
        this.scrlWprRef.current.scrollTop = 0;
    };

    emailVerifiedMarkup = (
        <div>
            <h6>
                <b>Email Verified</b>
            </h6>
            <p className="mt-4 fs-14">
                Congratulations! Your email address is successfully verified
            </p>
            <div className="d-flex justify-content-center pr-5">
                <Button
                    className="Btn Btn--pri Btn-sm mt-4"
                    onClick={() => this.props.history.push({ pathname: "/auth/sign-in" })}>
                    {" "}
                    Continue
                </Button>
            </div>
        </div>
    );
    // formWprRef = React.createRef()

    safeGetPracticeId = () => {
        if (
            this.props.auth &&
            this.props.auth.referralResponse &&
            this.props.auth.referralResponse.pcp_practice_id
        ) {
            return this.props.auth.referralResponse.pcp_practice_id;
        }

        return null;
    };

    render() {
        let showSvgArt = !this.props.auth.referralResponse && !this.props.auth.referralCodeMailed;
        return (
            <Switch>
                <Route
                    path="/auth/sso/:providerName/:email"
                    render={(props) => (
                        <SSO
                            {...props}
                            submit={this.submitHandlerSignIn}
                            emailVerified={this.showEmailVerifiedPopUp}
                        />
                    )}
                />
                <Route
                    path="/auth/sso"
                    render={(props) => (
                        <SSO
                            {...props}
                            idpOptions={this.state.idpOptions}
                            email={this.state.email}
                            showIdp={this.state.showIdp}
                            submit={this.submitHandlerSignIn}
                            emailVerified={this.showEmailVerifiedPopUp}
                        />
                    )}
                />

                <Route
                    path="/auth/sign-in"
                    render={(props) => (
                        <SignIn
                            {...props}
                            resetPwSteps={this.resetPwSteps}
                            email={this.state.email}
                            submit={this.check_if_idp}
                            emailVerified={this.showEmailVerifiedPopUp}
                            accountLocked={this.state.accountLocked}
                        />
                    )}
                />
                <Route
                    path="/auth/complete-sign-in"
                    render={(props) => (
                        <CompleteSignIn
                            {...props}
                            completeSignIn={this.state.completeSignIn}
                            submit={this.submitHandlerSignIn}
                            setEmail={this.setEmail}
                            email={this.state.email}
                            lastSubmittedEmail={this.state.lastSubmittedEmail}
                            resetCodeHandler={this.resetCodeHandler}
                            accountLocked={this.state.accountLocked}
                        />
                    )}
                />
                {this.props.auth.referralResponse && (
                    <Route
                        path="/auth/sign-up"
                        render={(props) => (
                            <PatientSignUp
                                {...props}
                                scrollToTop={this.scrollToTop}
                                showArt={showSvgArt}
                                refFromOuter={this.scrlWprRef}
                                campaignId={this.campaign_id}
                                practiceId={this.safeGetPracticeId()}
                            />
                        )}
                    />
                )}
                {this.props.auth.referralResponse && (
                    <Route
                        path="/auth/child-sign-up"
                        render={(props) => (
                            <ChildSignUp
                                {...props}
                                referralInfo={this.props.auth.referralResponse}
                            />
                        )}
                    />
                )}
                {this.props.auth.referralCodeMailed && (
                    <Route
                        path="/auth/sign-up"
                        render={() => (
                            <PatientSignUpSuccess
                                logIn={this.props.logIn}
                                showArt={showSvgArt}
                                refFromOuter={this.scrlWprRef}
                            />
                        )}
                    />
                )}

                {!this.props.auth.referralResponse && !this.props.auth.referralCodeMailed && (
                    <Route
                        path="/auth/referral"
                        render={(props) => (
                            <Referral
                                {...props}
                                referralForm={this.state.referralForm}
                                onReferralSubmit={this.onReferralSubmit}
                                showArt={showSvgArt}
                                refFromOuter={this.scrlWprRef}
                                referralChange={this.referralChange}
                            />
                        )}
                    />
                )}
                <Route
                    path="/auth/forgot-password"
                    render={(props) => (
                        <ForgotPassword
                            {...props}
                            emailFromPrevStep={this.state.email}
                            sendResetPasswordCode={this.mailResetPwCode}
                            currentStep={this.props.auth.forgotPasswordStep}
                            setNewPasswordWithEmail={this.setNewPasswordWithEmailHandler}
                            setNewPassword={this.setNewPasswordHandler}
                        />
                    )}
                />

                <Route path="/auth/self-signup/:step" component={SelfSignUp} />
                <Route path="/auth/self-signup" component={SelfSignUp} />
                <Route path="/auth/password-reset-required" component={PasswordResetRequired} />
                {/*auto-signup route is deprecated; SPMI referrals now use /account-setup*/}
                {/*<Route path="/auth/auto-signup" component={AutoSignUp} />*/}

                {this.props.configs.TEENS_AND_KIDS_FLOW && (
                    <>
                        {/* <Route path="/auth/signup/:step" component={SignUp} /> */}
                        {/* <Route path="/auth/signup" component={SignUp} /> */}
                    </>
                )}

                <Redirect to="/auth/sign-in" />
            </Switch>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        configs: state.configs,
    };
};

export default connect(mapStateToProps, {
    logIn,
    validateReferral,
    signUp,
    mailResetPasswordCode,
    showEmailVerified,
    resetPw,
    hideRsPwSuccess,
    resetForgotPwStep,
})(Authentication);
