// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { MENTAL_STATUS_QUESTIONS_SCHEMA } from "./schema/clinicalAssessmentFormSchema";
import { Form, Formik } from "formik";
import Button from "../../../../components/UI/Button";
import { providerRoute } from "../../../../config/roles";
import { convertStatus } from "./ClinicalAssessment";
import { cloneDeep } from "lodash";
import FormikInput from "../../../../components/UI/FormikInput";
import { CustomForm } from "../../../../component-library/CustomForm";

class MentalStatusQuestions extends Component {
    constructor(props) {
        super(props);
        let mentalStatusQuestions = this.getMentalQuestions(props.vals);
        this.state = {
            mental_status_questions: mentalStatusQuestions,
            allowSubmit: this.checkAllQuestions(mentalStatusQuestions),
        };
        this.SubmitBtnWprRef = React.createRef(null);
    }

    /**
     * Checks for enter key, and if submit button is enabled then submits form on Enter key
     */
    documentKeydownHandler = (e) => {
        if (e.keyCode == 13) {
            const submitBtn = this.SubmitBtnWprRef.current.querySelector(
                ".js-MentalStatusQuestion__Submit-btn",
            );
            if (submitBtn && !submitBtn.disabled) {
                submitBtn.click(); // submits the form
            }
        }
    };

    componentDidMount() {
        document.addEventListener("keydown", this.documentKeydownHandler);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.documentKeydownHandler);
    }

    submitHandler = (formData, actions) => {
        let data = { ...this.props.data };
        data[convertStatus(this.props.statusArr[2])] = this.trimRadioBeforeSubmit([
            ...this.state.mental_status_questions,
        ]);
        this.props.submitHandler(data, actions);
    };

    getMentalQuestions = (vals) => {
        if (vals) {
            let schema = cloneDeep(MENTAL_STATUS_QUESTIONS_SCHEMA);
            vals.forEach((r) => {
                let questionID = schema.findIndex((q) => q.name === r.name);
                if (schema[questionID]) {
                    if (!Array.isArray(r.value)) {
                        let optionID = schema[questionID].elementConfig.options.findIndex(
                            (o) => o.value === r.value,
                        );
                        schema[questionID].elementConfig.options[optionID].checked = true;
                    } else {
                        r.value.forEach((ans) => {
                            let optionID = schema[questionID].elementConfig.options.findIndex(
                                (o) => o.value === ans,
                            );
                            schema[questionID].elementConfig.options[optionID].checked = true;
                        });
                    }
                }
            });
            return schema;
        } else {
            return cloneDeep(MENTAL_STATUS_QUESTIONS_SCHEMA);
        }
    };

    updateAllowSubmit = () => {
        if (this.checkAllQuestions(this.state.mental_status_questions)) {
            this.setState({
                allowSubmit: true,
            });
        }
    };

    checkAllQuestions = (questions) => {
        return (
            this.trimRadioBeforeSubmit(questions).length === MENTAL_STATUS_QUESTIONS_SCHEMA.length
        );
    };

    inputHandler = (name, option) => {
        let mentalQuestions = cloneDeep(this.state.mental_status_questions);
        let index = mentalQuestions.findIndex((q) => q.name === name);
        mentalQuestions[index].elementConfig.options.forEach((o) => {
            if (mentalQuestions[index].elementType === "radio") {
                o.checked = o.value === option.value;
                if (!o.checked) {
                    delete o.checked;
                }
            } else {
                o.checked =
                    (o.value === option.value && !o.checked) ||
                    (o.value !== option.value && o.checked);
            }
        });
        this.setState(
            {
                mental_status_questions: mentalQuestions,
            },
            this.updateAllowSubmit,
        );
    };

    trimRadioBeforeSubmit = (questions) => {
        let clonedQuestions = cloneDeep(questions);
        return clonedQuestions
            .map((q) => {
                if (!q.elementConfig) {
                    return null;
                }
                if (q.elementType === "radio") {
                    let i = q.elementConfig.options.findIndex((q) => q.checked);
                    if (i !== -1) {
                        return {
                            name: q.name,
                            value: q.elementConfig.options[i].value,
                        };
                    } else {
                        return null;
                    }
                } else {
                    let checkedOptions = q.elementConfig.options
                        .filter((o) => o.checked)
                        .map((o) => o.value);
                    if (checkedOptions) {
                        return {
                            name: q.name,
                            value: checkedOptions,
                        };
                    } else {
                        return null;
                    }
                }
            })
            .filter((q) => q !== null);
    };

    renderForm = (formikProps) => {
        const isDisabled = !this.state.allowSubmit && !this.props.done;
        return (
            <Form>
                <div className="mx-auto px-0 ">
                    <fieldset disabled={this.props.done ? "disabled" : ""}>
                        <div className="container">
                            <div className="row">
                                <div className="mx-lg-2 mt-4 pt-2 px-lg-2">
                                    {this.state.mental_status_questions.map((q, i) => {
                                        q.onchange = q.onclick = this.inputHandler;
                                        return (
                                            <div key={i + q.name} className="col-12 col-lg-12">
                                                <FormikInput
                                                    formEl={q}
                                                    errors={formikProps.errors}
                                                    touched={formikProps.touched}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <div className="d-flex justify-content-center justify-content-lg-end">
                        <div>
                            <Button
                                type="button"
                                onClick={this.props.goBack}
                                className="Btn Btn--otl-pri Btn-sm mr-3">
                                Back
                            </Button>
                            <Button
                                type="submit"
                                className="js-MentalStatusQuestion__Submit-btn Btn Btn--pri Btn-sm"
                                disabled={isDisabled}>
                                Continue
                            </Button>
                            {/* {!this.state.allowSubmit && !this.props.done ?
                                <Button
                                    type="submit"
                                    className="Btn Btn--pri Btn-sm"
                                    disabled>
                                    Continue
                                </Button> :
                                <Button
                                    type="submit"
                                    className="Btn Btn--pri Btn-sm">
                                    Continue
                                </Button>} */}
                        </div>
                    </div>
                </div>
            </Form>
        );
    };

    render() {
        return (
            <div ref={this.SubmitBtnWprRef}>
                <CustomForm
                    onSubmit={this.submitHandler}
                    render={this.renderForm}
                    initialValues={{}}
                />
            </div>
        );
    }
}

export default MentalStatusQuestions;
