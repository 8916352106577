import React from "react";
import CardSecondary from "../../../UI/Card/CardSecondary";
import { Button, ButtonTypes } from "../../../../component-library";

export const CallSkippedReferOtherPatient = ({
    sharedWorkflowData,
    currentStepName,
    update_state_and_get_next_node,
}) => {
    return (
        <div className={"container hcp-mobile-container px-sm-3 py-sm-5"}>
            <CardSecondary className="HCPWorkflowShared-card">
                <div className="HCPWorkflowShared-header_container">
                    <h1 className="HCPWorkflowShared-title">
                        Thank you for your referral! We’ve got it from here.
                    </h1>
                    <p className="HCPWorkflowShared-sub_title">
                        <span style={{ display: "inline" }} data-private>
                            {sharedWorkflowData.workflow_instance.state.patient_info?.first_name}
                        </span>{" "}
                        will receive an email from our team with next steps soon.
                    </p>
                </div>

                <div className="ScheduleCallOrSkip-button_container">
                    <Button
                        buttonType={ButtonTypes.primaryV2}
                        onClick={async () => {
                            await update_state_and_get_next_node(
                                { refer_another_patient: false },
                                currentStepName,
                            );
                        }}>
                        Done
                    </Button>
                    <div className="ScheduleCallOrSkip-or_text_container">
                        <div className="ScheduleCallOrSkip-hr" style={{ width: 81 }} />
                        <p className="ScheduleCallOrSkip-or_text">OR</p>
                        <div className="ScheduleCallOrSkip-hr" style={{ width: 81 }} />
                    </div>
                    <Button
                        buttonType={ButtonTypes.underline}
                        className="ScheduleCallOrSkip-skip_step"
                        onClick={async () => {
                            await update_state_and_get_next_node(
                                { refer_another_patient: true },
                                currentStepName,
                            );
                        }}>
                        Refer another patient
                    </Button>
                </div>
            </CardSecondary>
        </div>
    );
};
