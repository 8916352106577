// Copyright aptihealth, inc. 2021 All Rights Reserved

import React, { Component } from "react";
import Layout from "../../../hoc/Layout/Layout";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { redirectOnSignIn } from "../../../utils/redirectOnSignIn";
import Workflow from "../../../components/Common/Workflow";

class WorkflowRouter extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        redirectOnSignIn(this.props.history);
    }

    render() {
        let baseUrl = this.props.url;
        return (
            <Layout>
                <Switch>
                    <Route path={baseUrl + "/workflow"} component={Workflow} />
                    <Redirect from={baseUrl} to={baseUrl + "/workflow"} />
                </Switch>
            </Layout>
        );
    }
}

export default withRouter(WorkflowRouter);
