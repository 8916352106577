/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const CheckmarkIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Checkmark"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.5301 7.15201C24.9984 7.44472 25.1408 8.06167 24.8481 8.53001L14.0751 25.7667L7.20006 16.6C6.86869 16.1582 6.95823 15.5314 7.40006 15.2C7.84189 14.8686 8.46869 14.9582 8.80006 15.4L13.925 22.2333L23.1521 7.47001C23.4448 7.00168 24.0617 6.8593 24.5301 7.15201Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Checkmark"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.06 5.30403C25.9967 5.88945 26.2814 7.12335 25.696 8.06002L14.1501 26.5335L6.4 16.2C5.73725 15.3164 5.91634 14.0628 6.8 13.4C7.68365 12.7373 8.93725 12.9164 9.6 13.8L13.8499 19.4666L22.304 5.94003C22.8894 5.00335 24.1233 4.71861 25.06 5.30403Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Checkmark"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM28.484 13.0525C28.9962 12.2329 28.7471 11.1532 27.9275 10.641C27.1079 10.1287 26.0282 10.3779 25.516 11.1975L18.1187 23.0332L14.4 18.075C13.8201 17.3018 12.7232 17.1451 11.95 17.725C11.1768 18.3049 11.0201 19.4018 11.6 20.175L18.3813 29.2168L28.484 13.0525Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

CheckmarkIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

CheckmarkIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
