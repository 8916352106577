import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
import { createClassNameString } from "../utils";
import { Button } from "../Button";
import { Card } from "../Card";

export const TwoButtonCard = ({
    className,
    buttonsPosition,
    buttonLeft,
    buttonRight,
    children,
    ...props
}) => (
    <Card
        className={createClassNameString(["apti-CL", "apti-TwoButtonCard", className])}
        {...props}>
        {children}
        <div
            className={createClassNameString([
                "apti-TwoButtonCard-button-container",
                buttonsPosition,
            ])}>
            {buttonLeft}

            {buttonRight}
        </div>
    </Card>
);

TwoButtonCard.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,

    buttonsPosition: PropTypes.oneOf([
        "left",
        "right",
        "space-around",
        "space-between",
        "space-evenly",
        "space-center",
    ]),

    buttonLeft: PropTypes.element,

    buttonRight: PropTypes.element,
};

TwoButtonCard.defaultProps = {
    className: undefined,
    children: <> </>,
    buttonsPosition: "space-around",
    buttonLeft: <Button>Left</Button>,
    buttonRight: <Button>Right</Button>,
    onClick: () => {},
};
