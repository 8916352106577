import React, { useState } from "react";
import _ from "lodash";
import CardSecondary from "../../../UI/Card/CardSecondary";
import DatePicker from "../../../UI/DatePicker";
import moment from "moment";
import images from "../../../../utils/images";

export const LAB_FINDING_QUESTIONS = {
    cbc: {
        label: "CBC",
        date: "",
        description: "",
        order: 1,
    },
    cmp: {
        label: "CMP",
        date: "",
        description: "",
        order: 2,
    },
    a1c: {
        label: "A1C",
        date: "",
        description: "",
        order: 3,
    },
    flp: {
        label: "FLP",
        date: "",
        description: "",
        order: 4,
    },
    tsh: {
        label: "TSH",
        date: "",
        description: "",
        order: 5,
    },
    urinalysis: {
        label: "Urinalysis",
        date: "",
        description: "",
        order: 6,
    },
    toxicology: {
        label: "Toxicology",
        date: "",
        description: "",
        order: 7,
    },
    med_levels: {
        label: "Med Levels",
        date: "",
        description: "",
        order: 8,
    },
};

export const PrescribeLabFindings = (props) => {
    const { note, profileView, createEditView, editable, editHandler, showTitle } = props;

    let labFindings = note.content.lab_findings;
    let [showCalender, setShowCalendar] = useState(
        Object.keys(LAB_FINDING_QUESTIONS).reduce((acc, key) => {
            acc[key] = false;
            return acc;
        }, {}),
    );

    const handleCalendarClick = (key) => {
        if (!editable) {
            return;
        }

        let showCalendarClone = _.cloneDeep(showCalender);
        showCalendarClone[key] = !showCalendarClone[key];
        setShowCalendar(showCalendarClone);
    };

    const onDateChange = (date, eventType, key) => {
        if (eventType !== "monthChange") {
            handleCalendarClick(key);
        }

        const dateParts = date.split("-");
        let formattedDate = dateParts[1] + "/" + dateParts[2] + "/" + dateParts[0];
        handleEditCopyChange({ target: { value: formattedDate } }, key, "date");
    };

    const handleEditCopyChange = (e, index, key) => {
        if (!editable) {
            return;
        }

        let editCopyClone = _.cloneDeep(labFindings);
        editCopyClone[index][key] = e.target.value;

        if (editHandler) {
            editHandler("lab_findings", editCopyClone);
        }
    };

    let content = (
        <div>
            {Object.entries(labFindings)
                .sort((a, b) => (a[1].order && b[1].order ? a[1].order - b[1].order : 0))
                .map(([key, value]) => {
                    return (
                        <div className="m-2">
                            <div className="row m-1">
                                <div className="fw-bold mr-2">{value.label}</div>
                                <input
                                    placeholder={"mm/dd/yyyy"}
                                    value={value.date}
                                    className="fs-10 mr-1"
                                    style={{ width: 64 }}
                                    disabled={!editable ? true : null}
                                    onClick={() => handleCalendarClick(key)}
                                />
                                {editable && (
                                    <img
                                        alt="calendar icon"
                                        style={{ width: 15 }}
                                        src={images("./icons/calender-icon.svg")}
                                        onClick={() => handleCalendarClick(key)}
                                    />
                                )}
                                {showCalender[key] && (
                                    <div className={"note-date-picker-wrapper"}>
                                        <DatePicker
                                            externalCtx={
                                                value.date && value.date !== ""
                                                    ? moment(value.date, "MM/DD/YYYY")
                                                    : moment()
                                            }
                                            loadingSlots={false}
                                            onDaySelect={(date, eventType) =>
                                                onDateChange(date, eventType, key)
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="w-100">
                                <input
                                    placeholder={"Reviewed & Findings"}
                                    value={value.description}
                                    disabled={!editable ? true : null}
                                    className="w-100"
                                    onChange={(e) => handleEditCopyChange(e, key, "description")}
                                />
                            </div>
                        </div>
                    );
                })}
        </div>
    );

    return (
        <>
            <CardSecondary className="px-2 py-3 m-3">
                <p className="fw-bold">Labs and findings</p>
                {content}
            </CardSecondary>
        </>
    );
};
