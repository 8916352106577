// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useState, useEffect } from "react";
import "./styles.scss";
import ActiveScheduleDetails from "./ActiveScheduleDetails";
import ScheduleEvent from "./ScheduleEvent";
import ScheduleDateControls from "./ScheduleDateControls";
import ScheduleDeleteDialog from "./ScheduleDeleteDialog";
import DailyScheduleWidget, { getAllottedTimeDefault } from "./DailyScheduleWidget";
import AwaySchedulePopUp from "./AwaySchedulePopUp";
import { timeSlots, AWAY, VIDEO_CONSULTATION } from "./constants";
import { api } from "../../../APIRequests";
import DatePicker from "../../UI/DatePicker";
import { getGroupAuthType, isAuthorized } from "../../../redux/actions/auth";
import moment from "moment";
import _ from "lodash";
import surveyRequests from "../../../APIRequests/survey";
import { showAlertWithAction } from "../../../redux/actions/alerts";
import { connect } from "react-redux";
import { TOAST_SUCCESS, TOAST_SUCCESS_2 } from "../../UI/Alert";

const ScheduleAvailability = (props) => {
    const {
        scheduleList,
        awayScheduleList,
        selectedDate /**selectedDate is the moment js object of call moment.utc().tz() */,
        deleteSchedule,
        deleteAwaySchedule,
        getNextDay,
        getPrevDay,
        getToday,
        getPickedDateSchedules,
        toggleDatePicker,
        showDatePicker,
        timezone,
        videoConsultation,
        dismissDatePicker,
        selectedDateHandler,
        getSpecificDateSchedule,
        showNPS,
        setShowNPS,
    } = props;

    const [activeScheduleDetails, setActiveScheduleDetails] = useState(null);
    const [awayScheduleDetails, setAwayScheduleDetails] = useState(null);
    const [scheduleCallIdToDelete, setScheduleCallIdToDelete] = useState(null);
    const [awayScheduleToDelete, setAwayScheduleToDelete] = useState(null);
    const [newScheduleDetails, setNewScheduleDetails] = useState(null);
    const [datePickerConf, setDatePickerConf] = useState(null);
    const [startTimeSlot, setStartTimeSlot] = useState(null);

    const displayDate = (format) => {
        if (selectedDate) {
            return selectedDate.format(format);
        }
        return "";
    };

    const performActionsForActiveSchedulePopUpOnDocumentClick = (target) => {
        let isClickFromActiveScheduleBtn = target.closest(".DailyWidget__timeslot-active-btn");
        let isClickFromDeletePopUp = target.closest(".ScheduleDeleteDialog");
        let isClickFromSchedulesPopUp = target.closest(".ActiveScheduleDetails");
        if (
            !isClickFromActiveScheduleBtn &&
            !isClickFromSchedulesPopUp &&
            !isClickFromDeletePopUp
        ) {
            scheduleDetailsDismissHandler();
        }
    };

    const performActionsForNewSchedulePopUpOnDocumentClick = (target) => {
        let isClickFromDatePickerBackdrop =
            Array.from(target.classList).findIndex(
                (el) => el === "NewSchedulePopUp__DatePicker-wpr",
            ) > -1;
        if (isClickFromDatePickerBackdrop) {
            setDatePickerConf(null);
        }
    };

    const onDocumentClick = (e) => {
        performActionsForNewSchedulePopUpOnDocumentClick(e.target);
        performActionsForActiveSchedulePopUpOnDocumentClick(e.target);
    };

    useEffect(function () {
        document.addEventListener("click", onDocumentClick, true);
        /** detach click listener on unmoount */
        return function () {
            document.removeEventListener("click", onDocumentClick);
        };
    }, []);

    /**
     * Contructs the popup position based on the target's top position.
     * The pop-up which opens when the time slot is clicked (empty| alloted).
     *
     *
     * @param {Object} targetRect the target's bounding client rect object
     * @param {Object} parentRect the parent's bounding client rect object
     * @returns {{top: number, left: number, bottom: string}>} the object containing position details
     */
    const getSchedulePopUpPosition = (targetRect, parentRect) => {
        let targetTop = targetRect.top;
        let targetBottom = targetRect.bottom;
        let parentTop = parentRect.top;
        let parentBottom = parentRect.bottom;
        let deviceWidth = window.innerWidth;
        let position = null;
        /**
         * If device is mobile device (width<1000) then we let `position=null`,
         * which in turn will render pop-up is center of screen.
         * For How, check `ScheduleEvent` and `ActiveScheduleDetails` components `style` variable.
         */
        if (deviceWidth > 1000) {
            let topPosition, leftPosition, bottomPosition;
            if (parentBottom - targetTop < 600) {
                topPosition = "auto";
                bottomPosition = 0 + (parentBottom - targetBottom) + "px";
            } else {
                topPosition = targetTop - parentTop - 30 + "px";
                bottomPosition = "auto";
            }
            leftPosition = "35%";
            position = { top: topPosition, left: leftPosition, bottom: bottomPosition };
        }

        return position;
    };

    /**
     * Shows the <SctiveScheduleDetails /> component once when activeScheduleDetails is set.
     * @param {Event} e - event from slot button click
     * @param {Object} data - will be the `data` in `timeSlot` object passed through multiple levels till here.
     */
    const activeScheduleClickHandler = (e, data, duration) => {
        let position = null;
        let parent = document.querySelector(".DailyWidget__body");
        let targetRect = e.target.getBoundingClientRect();
        let parentRect = parent.getBoundingClientRect();
        position = getSchedulePopUpPosition(targetRect, parentRect);
        setActiveScheduleDetails({
            position,
            duration,
            details: data,
        });
    };
    const awayScheduleClickHandler = (e, data, duration) => {
        let position = null;
        let parent = document.querySelector(".DailyWidget__body");
        let targetRect = e.target.getBoundingClientRect();
        let parentRect = parent.getBoundingClientRect();
        position = getSchedulePopUpPosition(targetRect, parentRect);
        setAwayScheduleDetails({
            position,
            duration,
            details: data,
        });
    };

    const getAbsoluteSlot = (slot, fragment) => {
        let lastIndex = fragment.length - 1;
        let frag = fragment.charAt(lastIndex);
        if (frag === "b") {
            slot = slot.replace(":00", ":30");
        }
        return slot;
    };

    const updateStartTimeSlot = (slotValue) => {
        setStartTimeSlot(slotValue);
    };

    /** The event listener is atached to element with `DailyWidget__body` class */
    const emptySlotClickHandler = (e) => {
        const authType = getGroupAuthType();
        if (["provider", "admin"].includes(authType)) {
            let target = e.target;
            let isTargetEmptySlotBtn =
                [...target.classList].findIndex((cl) => cl === "DailyWidget__empty-slot-btn") !==
                -1;
            if (isTargetEmptySlotBtn) {
                let id = target.id;
                let position = null;
                let targetRect = target.getBoundingClientRect();
                let parentRect = e.currentTarget.getBoundingClientRect();
                let parent = target.parentElement;
                let slotAssigned = parent.getAttribute("data-slot");
                let slotFragment = target.getAttribute("id");
                let absoluteSlot = getAbsoluteSlot(slotAssigned, slotFragment);
                position = getSchedulePopUpPosition(targetRect, parentRect);
                setNewScheduleDetails({ id, position });
                setStartTimeSlot(absoluteSlot);
            }
        }
    };

    /** -------- Active schedule related functions ------------ */
    const scheduleDetailsDismissHandler = () => {
        setActiveScheduleDetails(null);
    };

    const awayScheduleDismissHandler = () => {
        setAwayScheduleDetails(null);
    };

    const showDeleteDialog = (callId) => {
        setScheduleCallIdToDelete(callId);
    };
    const showAwayDeleteDialog = (slotToDelete) => {
        setAwayScheduleToDelete(slotToDelete);
    };

    const hideDeleteDialog = () => {
        setScheduleCallIdToDelete(null);
        setAwayScheduleToDelete(null);
    };

    const scheduleDeleteHandler = (cancellation_reason, twenty_four_hour_notice, delete_series) => {
        deleteSchedule(
            scheduleCallIdToDelete,
            cancellation_reason,
            twenty_four_hour_notice,
            delete_series,
        );
        setActiveScheduleDetails(null);
        setScheduleCallIdToDelete(null);
    };

    const awayScheduleDeleteHandler = () => {
        setAwayScheduleDetails(null);
        setAwayScheduleToDelete(null);
        deleteAwaySchedule(awayScheduleToDelete);
    };
    /** --------------------------------------------------------- */

    /** -------- New schedule related functions ------------ */

    function newScheduleDismissHandler() {
        setNewScheduleDetails(null);
    }

    /**
     * Creates provider's shedules of one of the followinig types ( `AWAY` / `VIDEO_CONSULTATION`).
     * Based on the `type` parameter the type of `api` call is determined in the function body.
     *
     * @param {Object} requestBody - The request body specific to the backend `api`
     * @param {sting} type - type of new schedule (`AWAY` / `VIDEO_CONSULTATION`)
     * @param {string} [patientId] - The patient id with which the provider is creating schedule.Not needed for `AWAY` type
     */
    const newScheduleCreateHandler = async (requestBody, type, patientId) => {
        await createNewScheduledEvent(type, requestBody, patientId, timezone);
        await getPickedDateSchedules(selectedDate.format("YYYY-MM-DD"));
        newScheduleDismissHandler();
    };

    /** --------------------------------------------------------- */

    const newSchedulePopUpJsx = newScheduleDetails && (
        <ScheduleEvent
            selectedDateHandler={selectedDateHandler}
            dismiss={newScheduleDismissHandler}
            datePickerConf={datePickerConf}
            setDatePickerConf={setDatePickerConf}
            patientId={videoConsultation.patientId}
            position={newScheduleDetails.position}
            slot={newScheduleDetails.slot}
            startTimeSlot={startTimeSlot}
            selectedDate={selectedDate}
            updateStartTimeSlot={updateStartTimeSlot}
            onNewScheduleCreate={newScheduleCreateHandler}
            existingConflicts={getExistingConflicts(scheduleList, selectedDate)}
            getSpecificDateSchedule={getSpecificDateSchedule}
        />
    );

    const activeScheduleDetailsJsx = activeScheduleDetails && (
        <ActiveScheduleDetails
            {...props}
            position={activeScheduleDetails.position}
            details={activeScheduleDetails.details}
            duration={activeScheduleDetails.duration}
            dismiss={scheduleDetailsDismissHandler}
            showDeletePopUp={showDeleteDialog}
        />
    );

    const awaySchedulePopUp = awayScheduleDetails && (
        <AwaySchedulePopUp
            position={awayScheduleDetails.position}
            details={awayScheduleDetails.details}
            duration={awayScheduleDetails.duration}
            dismiss={awayScheduleDismissHandler}
            showDeletePopUp={showAwayDeleteDialog}
        />
    );

    const scheduleDeleteDialogJsx = scheduleCallIdToDelete && (
        <ScheduleDeleteDialog
            type="video"
            callId={scheduleCallIdToDelete}
            scheduleDelete={scheduleDeleteHandler}
            cancelDelete={hideDeleteDialog}
        />
    );

    const awayScheduleDeleteDialogJsx = awayScheduleToDelete && (
        <ScheduleDeleteDialog
            type="away"
            scheduleDelete={awayScheduleDeleteHandler}
            cancelDelete={hideDeleteDialog}
        />
    );

    return (
        <div className="PrScheduleAvailability position-relative mb-5">
            <ScheduleDateControls
                dateString={displayDate("dddd MM, YYYY")}
                getPrevDay={getPrevDay}
                getNextDay={getNextDay}
                getToday={getToday}
                showDatePicker={toggleDatePicker}
            />

            <DailyScheduleWidget
                dateString={displayDate("dddd:MMMM DD")}
                date={displayDate("YYYY-MM-DD")}
                schedules={scheduleList}
                newScheduleDetails={newScheduleDetails}
                showNewSchedulePopUp={emptySlotClickHandler}
                showActiveScheduleDetails={activeScheduleClickHandler}
                showAwaySchedulePopUp={awayScheduleClickHandler}>
                {/* These will be used as `props.children` in `DailyScheduleWidget` component */}
                {activeScheduleDetailsJsx}
                {newSchedulePopUpJsx}
                {awaySchedulePopUp}
            </DailyScheduleWidget>
            {scheduleDeleteDialogJsx}
            {awayScheduleDeleteDialogJsx}
            {showDatePicker && (
                <div className="PrScheduleAvailability__DatePicker-wpr">
                    <DatePicker externalCtx={selectedDate} onDaySelect={getPickedDateSchedules} />
                </div>
            )}
        </div>
    );
};

export default connect(null, { showAlertWithAction })(ScheduleAvailability);

export const createNewScheduledEvent = async (type, requestBody, patientId, tz) => {
    const data = {
        local_date_time: requestBody.local_date_time,
        patient_id: patientId,
        timezone: tz,
        event_type: type,
        allotted_time: requestBody.allotted_time,
        seriesData: requestBody.seriesData,
    };
    if (requestBody.providerId) {
        data.provider_id = requestBody.providerId;
    }
    if (requestBody.provider_id) {
        data.provider_id = requestBody.provider_id;
    }

    if (requestBody.label) {
        data.label = requestBody.label;
    }

    try {
        await api.schedule.post_schedule({ data });
    } catch (err) {
        console.log(err);
    }
};

export const getExistingConflicts = (scheduleList, selectedDate) => {
    return scheduleList
        .filter(
            (event) =>
                moment.utc(event["timestamp"], "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD") ===
                selectedDate.format("YYYY-MM-DD"),
        )
        .filter((event) => !event.deleted)
        .map((event) => {
            let conflict = moment.utc(event["timestamp"], "YYYY-MM-DD HH:mm").local();
            const allottedTime = event["allotted_time"]
                ? event["allotted_time"]
                : getAllottedTimeDefault(event["type"]);
            let conflictEnd = moment
                .utc(event["timestamp"], "YYYY-MM-DD HH:mm")
                .local()
                .add(allottedTime, "minutes");
            const conflicts = [];
            while (conflict < conflictEnd) {
                conflicts.push(conflict.format("HH:mm"));
                conflict.add(15, "minutes");
            }
            return conflicts;
        });
};
