// Copyright aptihealth, inc. 2020 All Rights Reserved

import React, { Component } from "react";
import CardSecondary from "../../UI/Card/CardSecondary";
import Button from "../../UI/Button";
import "./styles.scss";
import { api } from "../../../APIRequests";
import PropTypes from "prop-types";

class ExceptionReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestId: props.requestId,
        };
    }

    handleTyping = (event) => {
        this.setState({ description: event.target.value });
    };

    handleSubmit = async () => {
        let report = {
            request_id: this.state.requestId,
            description: this.state.description ? this.state.description : "",
        };

        let response = await api.shared.post_exception_report({ report });
        this.props.dismiss();
    };

    render() {
        let { dismiss } = this.props;

        return (
            <div className="overlay">
                <div>
                    <CardSecondary className="mx-lg-auto  ExceptionReporting">
                        <div className="px-5 py-4 text-center">
                            <p>
                                We are sorry your request failed. Please give us a description of
                                your issue below:
                            </p>
                            <p> Request ID: {this.state.requestId}</p>
                            <textarea className="w-100" onChange={this.handleTyping} />
                            <div>
                                <Button className="Btn Btn--pri m-1" onClick={this.handleSubmit}>
                                    Submit
                                </Button>
                                <Button className="Btn Btn--gry m-1" onClick={dismiss}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </CardSecondary>
                </div>
            </div>
        );
    }
}

ExceptionReport.propTypes = {
    requestId: PropTypes.element.isRequired,
    dismiss: PropTypes.element.isRequired,
};

export default ExceptionReport;
