import React from "react";
import { CalendarIcon, EnvelopeIcon } from "../../../../../component-library";
import { ICON_SIZES, ICON_TYPES } from "../../../../../constants/icons";
import LinkIcon, { LinkIconColor } from "../../../../UI/LinkIcon";

const CareTeamMemberCardTag = ({ tagType }) => {
    const tag = tagType === "external" ? "External" : "aptihealth";
    const containerClass =
        tagType === "external"
            ? "CareTeamMemberCardTag--external"
            : "CareTeamMemberCardTag--internal";

    return (
        <div className={containerClass}>
            <p className="CareTeamMemberCardTag--text">{tag}</p>
        </div>
    );
};

export const CareTeamMemberCard = ({
    careTeamMember,
    tagType,
    canSchedule,
    onSchedule,
    onEnvelope,
}) => {
    return (
        <div className="CareTeamMemberCard--container">
            <div className="CareTeamMemberCard--details_container">
                <div className="CareTeamMemberCard--details">
                    <p className="CareTeamMemberCard--name">{careTeamMember.name}</p>
                    <CareTeamMemberCardTag tagType={tagType} />
                    <p className="CareTeamMemberCard--provider_type CareTeamMemberCard--subtext">
                        {careTeamMember.type}
                    </p>
                </div>
                <div className="CareTeamMemberCard--action_container">
                    {canSchedule && (
                        <LinkIcon
                            label={"Schedule"}
                            icon={
                                <CalendarIcon
                                    className="CareTeamMemberCard--icon"
                                    iconSize={ICON_SIZES.SMALL}
                                    iconType={ICON_TYPES.OUTLINE}
                                />
                            }
                            onClick={onSchedule}
                            color={LinkIconColor.TEAL}
                        />
                    )}
                    <LinkIcon
                        label={"Copy Email"}
                        icon={
                            <EnvelopeIcon
                                className="CareTeamMemberCard--icon"
                                iconSize={ICON_SIZES.SMALL}
                                iconType={ICON_TYPES.OUTLINE}
                            />
                        }
                        onClick={onEnvelope}
                        color={LinkIconColor.TEAL}
                    />
                </div>
            </div>
        </div>
    );
};
