// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import { ExitIcon } from "../../Icons/ExitIcon";
import { ICON_SIZES, ICON_TYPES } from "../../../constants/icons";

const Logout = (props) => {
    return (
        <div
            className="d-flex justify-content-between px-3 px-lg-4 cursor"
            onClick={props.onLogOut}>
            <button className="Settings__logout-btn">
                <p className="Settings__Logout my-4">Logout</p>
            </button>
            <ExitIcon
                iconType={ICON_TYPES.OUTLINE}
                iconSize={ICON_SIZES.SMALL}
                className="Settings__exit-icon"
            />
        </div>
    );
};

export default Logout;
