// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useEffect, useState } from "react";
import "./../styles.scss";
import images from "../../../../utils/images";
import { withRouter } from "react-router-dom";
import { noteV1Display, noteV2Display, noteV3Display, noteV4Display } from "./MemberProfileNotes";
import { api } from "../../../../APIRequests";
import moment from "moment-timezone";
import CareCommunicationView from "./CareCommunication/CareCommunicationView";
import CaseManagementView from "./CaseManagement/CaseManagementView";
import AddendumDisplay from "../../../../containers/Dashboard/common/ViewFullNote/AddendumDisplay";
import { getQueryParams } from "../../../../utils/filters";
import { isAuthorized } from "../../../../redux/actions/auth";
import { admin_cse } from "../../../../config/roles";
import { connect } from "react-redux";
import { showAlertWithAction } from "../../../../redux/actions/alerts";
import { CareManagementForm } from "./CareManagement/CareManagementForm";
import { AdvancedCareActivityViewOnlyForm } from "./AdvancedCareActivity/AdvancedCareActivityViewOnlyForm";

const PrintNoteView = (props) => {
    const { match } = props;
    const [notes, setNotes] = useState(undefined);
    const [user, setUser] = useState(undefined);
    const [addendums, setAddendums] = useState(undefined);

    useEffect(() => {
        (async () => {
            try {
                let noteIds = getQueryParams("noteIds", props.location.search.trim());

                if (!noteIds) {
                    return;
                }

                let noteIdList = noteIds.split(",");

                if (noteIdList.length > 50) {
                    props.showAlertWithAction(
                        "Too many notes selected for export. Please reduce the selection and try again (max: 50).",
                    );
                    return;
                }

                await getPatient();

                await getNotes(noteIdList);

                await getNoteAddendums(noteIdList);
            } catch (e) {
                console.log(e);
            }
        })();
    }, []);

    useEffect(() => {
        if (user !== undefined && notes !== undefined && addendums !== undefined) {
            document.title = `${user.first_name} ${user.last_name} ${notes.length} Notes - 
            ${moment().local().format("YYYY-MM-DD HH:mm")}`;
            window.print();
        }
    }, [user, notes, addendums]);

    const getPatient = async () => {
        try {
            const urlParams = { patientId: match.params.patientId };
            const patientResponse = await api.shared.fetch_patient_details({ urlParams });
            setUser(patientResponse.user);
        } catch (e) {
            console.log(e);
            props.showAlertWithAction("Error fetching patient information.");
        }
    };

    const getNotes = async (noteIds) => {
        try {
            const urlParams = { patientId: match.params.patientId };
            const queryParams = isAuthorized(admin_cse)
                ? { note_ids: noteIds }
                : { note_id: noteIds[0] };
            const response = await api.shared.fetch_patient_progress_notes({
                urlParams,
                queryParams,
            });
            let sortedNotes = [response];
            if (Array.isArray(response)) {
                sortedNotes = response.sort(
                    (a, b) =>
                        moment(b.session_date + " " + b.session_from, "MM/DD/YYYY HH:mm") -
                        moment(a.session_date + " " + a.session_from, "MM/DD/YYYY HH:mm"),
                );
            }
            setNotes(sortedNotes);
        } catch (e) {
            console.log(e);
            props.showAlertWithAction("Error fetching notes to print.");
        }
    };

    const getNoteAddendums = async (noteIds) => {
        try {
            const urlParams = { patientId: match.params.patientId };
            const data = {
                patient_id: match.params.patientId,
            };

            if (isAuthorized(admin_cse)) {
                data["note_ids"] = noteIds;
            } else {
                data["note_id"] = noteIds[0];
            }

            const addendumResponse = await api.shared.fetch_note_addendums({ urlParams, data });
            const newAddendums = addendumResponse.addendums.reduce((obj, addendum) => {
                const noteId = addendum["note_id"];

                if (obj[noteId] === undefined) {
                    obj[noteId] = [];
                }

                obj[noteId].push(addendum);
                return obj;
            }, {});
            setAddendums(newAddendums);
        } catch (e) {
            console.log(e);
            props.showAlertWithAction("Error fetching note addendums.");
        }
    };

    const displayNote = (note) => {
        if (!note.version || note.version === 1) {
            return noteV1Display(note, `${user.first_name} ${user.last_name}`);
        } else if (note.version === 2) {
            return noteV2Display(note, false, null, { profileView: true });
        } else if (note.version === 3) {
            // Check-in
            return noteV3Display(note);
        } else if (note.version === 4) {
            // Care-Coordination
            return noteV4Display(note);
        } else if (note.version === 5) {
            // Care Communication
            return <CareCommunicationView note={note} />;
        } else if (note.version === 6) {
            // Case Management
            return <CaseManagementView note={note} />;
        } else if (note.note_type === "Care Management") {
            const signature = note.signature ?? "";
            return (
                <CareManagementForm
                    noteState={{ ...note, signature }}
                    isEditing={false}
                    patientId={note.patient_id}
                />
            );
        } else if (note.note_type === "Advanced Care Activity") {
            return <AdvancedCareActivityViewOnlyForm note={note} />;
        }
    };

    const displayHeader = () => {
        return (
            <div className={"print-note-heading d-flex justify-content-between"}>
                <div>
                    <div className={"fw-bold fs-22"}>
                        {user.first_name} {user.last_name}
                    </div>
                    <div>Date of Birth: {user.dob}</div>
                </div>
                <div className={"text-right"}>
                    <div>{notes.length} Notes</div>
                    <div>Exported on: {moment().local().format("DD/MM/YYYY")}</div>
                </div>
            </div>
        );
    };

    const displayNoteHeader = (note) => {
        if (note.note_type === "Care Management" || note.note_type === "Advanced Care Activity") {
            return;
        }
        return (
            <div className={"print-note-heading"}>
                <p className={"fw-bold"}>
                    {note.title || `${note.note_type} ${note.session_date}`}
                </p>
            </div>
        );
    };

    return (
        <>
            <div
                className={"d-flex justify-content-between print-class"}
                style={{ padding: "20px 100px" }}>
                <div
                    className="cursor d-flex"
                    onClick={() =>
                        props.history.push(
                            `/app/patient/${match.params.patientId}/profile/v2?selectedTab=Notes`,
                        )
                    }>
                    <img src={images("./icons/common/chevron-left-secondary.svg")} alt={"<"} />
                    <div className="txt-sec ml-2" style={{ height: 20, lineHeight: "20px" }}>
                        Patient Profile
                    </div>
                </div>
                <div className="cursor d-flex" onClick={() => window.print()}>
                    <img
                        src={images("./icons/common/print-secondary.svg")}
                        alt="print icon"
                        style={{ height: "20px" }}
                    />
                    <div className={"txt-sec ml-2"} style={{ height: 20, lineHeight: "20px" }}>
                        Print
                    </div>
                </div>
            </div>
            <div className={"print-note"}>
                {user !== undefined && notes !== undefined && displayHeader()}

                {user !== undefined &&
                    addendums !== undefined &&
                    notes !== undefined &&
                    notes.map((note) => {
                        return (
                            <div className={"pb-5 mb-5"}>
                                <hr className="Hr mb-2" style={{ borderColor: "black" }} />
                                {displayNoteHeader(note)}
                                {displayNote(note)}
                                {note?.note_type !== "Care Management" &&
                                note?.note_type !== "Advanced Care Activity" &&
                                note?.provider_cp_credentials ? (
                                    <div className={"mb-2 mt-3"}>
                                        <div className={"fw-bold"}>Credentials</div>
                                        <div>{note.provider_cp_credentials}</div>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {addendums[note.id] &&
                                    addendums[note.id].map((addendum) => (
                                        <AddendumDisplay
                                            style={{ minHeight: 0, marginBottom: 10 }}
                                            addendum={addendum.addendum}
                                            timestamp={addendum.timestamp}
                                        />
                                    ))}
                                {note?.note_type !== "Care Management" &&
                                note?.note_type !== "Advanced Care Activity" &&
                                note?.signature ? (
                                    <div className={"mb-2 mt-3"}>
                                        <div className={"fw-bold"}>Signature</div>
                                        <div
                                            className="fs-20 border-bottom"
                                            style={{ fontFamily: "Apple Chancery, cursive" }}>
                                            {note.signature}
                                        </div>
                                    </div>
                                ) : (
                                    " "
                                )}
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default connect(null, { showAlertWithAction })(withRouter(PrintNoteView));
