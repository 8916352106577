// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useEffect, useState, useRef } from "react";
import { Line } from "react-chartjs-2";
import moment from "moment-timezone";
import { api } from "../../../APIRequests";

const NinetyDayTreatmentWidget = (props) => {
    const chartRef = useRef(null);
    const lagendWprRef = useRef(null);
    const [chartLabels, setChartLabels] = useState(null);
    const [chartData, setChartData] = useState(null);
    /** dateRange[i]
     * Returns two previous dates data from today
     *
     * @return {[]} array of moment object with two prev dates
     */
    const getPrevDates = function () {
        let arr = [];
        arr[0] = moment.utc().subtract(4, "days");
        arr[1] = moment.utc().subtract(3, "days");
        arr[2] = moment.utc().subtract(2, "days");
        arr[3] = moment.utc().subtract(1, "days");
        return arr;
    };

    let todaysDateUtc = moment.utc();

    let dateRange = [...getPrevDates(todaysDateUtc), todaysDateUtc];

    const getDateExtremeValues = (datesArray) => {
        /**
         * Items of `datesArray` are oblects returned by `moment()` call of library
         * Therefor calling `format('YYYY-MM-DD)` in the end below.
         */
        let start_date = datesArray[0].format("YYYY-MM-DD");
        let end_date = datesArray[datesArray.length - 1].format("YYYY-MM-DD");
        return { start_date, end_date };
    };

    const createChartLabels = () => {
        let deviceWidth = window.innerWidth;
        let dateLabels = null;
        if (deviceWidth < 768) {
            dateLabels = dateRange.map((date) => {
                return date.format("DD ddd");
            });
        } else {
            dateLabels = dateRange.map((date) => {
                return date.format("YYYY-MM-DD");
            });
        }

        setChartLabels(dateLabels);
    };

    /**
     * Converts UTC to local and then trims the entries which do not fall bw range after conversion to local.
     *
     * @param {object} countsObj - object with `keys` as `YYYY-MM-DD HH:mm`
     */
    const modifyCountsData = (countsObj) => {
        let countsObjModified = {}; // to contain entries falling within range of `dateRange`
        for (let keyAsDate in countsObj) {
            const localDate = moment
                .utc(keyAsDate, "YYYY-MM-DD HH:mm")
                .local()
                .format("YYYY-MM-DD");
            const lowerBoundDate = dateRange[0].format("YYYY-MM-DD"); // lower date in the range in format 'YYYY-MM-DD'
            const upperBoundDate = dateRange[dateRange.length - 1].format("YYYY-MM-DD"); // upper date in the range in format 'YYYY-MM-DD'

            //boolean which evaluates to `true` if this holds: `lowerBoundDate` <= `localDate` <= `upperBoundDate`
            const liesBetween = moment(localDate).isBetween(
                lowerBoundDate,
                upperBoundDate,
                null,
                "[]",
            );

            if (liesBetween) {
                const contained = Object.prototype.hasOwnProperty.call(
                    countsObjModified,
                    localDate,
                );
                if (contained) {
                    countsObjModified[localDate] += countsObj[keyAsDate];
                } else {
                    countsObjModified[localDate] = countsObj[keyAsDate];
                }
            }
        }

        return countsObjModified;
    };

    /**
     * Creates chart friendly data rom response
     */
    const createChartData = (receivedData) => {
        let startedModified = modifyCountsData(receivedData.started);
        let completedModified = modifyCountsData(receivedData.completed);

        startedModified = dateRange.map((item, i) => {
            return startedModified[item.format("YYYY-MM-DD")] || 0;
        });
        completedModified = dateRange.map((item, i) => {
            return completedModified[item.format("YYYY-MM-DD")] || 0;
        });
        setChartData({
            started: startedModified,
            completed: completedModified,
        });
    };

    const getChartData = (canvas) => {
        const ctx = canvas.getContext("2d");
        const gradientPri = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
        const gradientSec = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);

        gradientPri.addColorStop(0, "rgba(12, 208,167, 0.8)");
        gradientPri.addColorStop(0.2, "rgba(12, 208,167, 0.6)");
        gradientPri.addColorStop(0.8, "rgba(12, 208,167, 0)");
        gradientPri.addColorStop(1, "rgba(12, 208,167, 0)");

        gradientSec.addColorStop(0, "rgba(91,87,220, 0.8)");
        gradientSec.addColorStop(0.2, "rgba(91,87,220, 0.6)");
        gradientSec.addColorStop(0.8, "rgba(91,87,220, 0)");
        gradientSec.addColorStop(1, "rgba(91,87,220, 0)");

        return {
            labels: chartLabels,
            datasets: [
                {
                    borderColor: "#0CD0A7", // pri (started)
                    label: "Started",
                    backgroundColor: gradientPri,
                    fill: true,
                    data: chartData.started,
                },
                {
                    borderColor: "#5B57DC",
                    label: "Completed",
                    backgroundColor: gradientSec,
                    fill: true,
                    data: chartData.completed,
                },
            ],
        };
    };

    /**
     * Creates custom legend for charts js. Passed as a callback to `lagendCallback` property in `options`.
     *
     * @param {Object} chart the chart object passed by option when initialized
     * @returns {string} returns the html string for legend
     *
     */
    const createLegendCb = function (chart) {
        let legendHtml = [];
        let legendHeading =
            '<div class="fs-12 mr-3 mb-2 mr-lg-0 pb-lg-1 fw-bold">Conversion of <span class="d-lg-none"> :</span></div>';
        let legendStarted =
            '<span class="NinetyDayTreatmentWidget__chart-legend--started mr-3 mb-2 mr-lg-0 d-lg-block">Started</span>';
        let legendCompleted =
            '<span class="NinetyDayTreatmentWidget__chart-legend--completed mb-2 d-lg-block">Completed</span>';
        legendHtml.push(legendHeading);
        legendHtml.push(legendStarted);
        legendHtml.push(legendCompleted);
        return legendHtml.join("");
    };

    /** Option for chart js  Line chart */
    let options = {
        maintainAspectRatio: false,
        legendCallback: createLegendCb,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            },
        },
        elements: {
            line: {
                borderWidth: 2,
                tension: 0,
                fill: false,
            },
            point: {
                radius: 0,
                hitRadius: 5,
                hoverRadius: 5,
            },
        },
        legend: {
            display: false,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        min: 0,
                        stepSize: 50,
                    },
                    gridLines: {
                        zeroLineWidth: 0,
                    },
                },
            ],
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                },
            ],
        },
    };

    const onWindowResize = (e) => {
        /**
         * Only chart labels are different on mobile devices.
         * So to show short labels, the chart nedds to be rendered again with short labels.
         * `createChartLabels()` sets the `chartLabels` via `useState` hook,
         * and this causes the component to rerender along with chart.
         */
        createChartLabels();
    };

    useEffect(function () {
        let queryParams = { ...getDateExtremeValues(dateRange) };
        createChartLabels();
        /** fetch chart data from api */
        api.provider
            .fetch_90_day_care_widget_data({ queryParams })
            .then((response) => {
                createChartData(response);
                let chartInstance = chartRef.current.chartInstance;
                /** calling `generateLegend()` to create custom legends */
                lagendWprRef.current.innerHTML = chartInstance.generateLegend();
            })
            .catch((err) => {
                console.log(err);
            });

        window.addEventListener("resize", onWindowResize);
        return function () {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);

    const chartWprClasses = [
        "NinetyDayTreatmentWidget__chart-wpr",
        "mt-4",
        "mt-lg-0",
        "col-12",
        "col-lg-10",
        "order-last",
        "order-lg-first",
    ];

    const legendWprClasses = [
        "NinetyDayTreatmentWidget__chart-legend-wpr",
        "d-flex",
        "px-2",
        "px-lg-0",
        "flex-lg-column",
        "col-12",
        "col-lg-2",
        "order-first",
        "order-lg-last",
    ];
    return (
        <div className="NinetyDayTreatmentWidget p-2 p-lg-3">
            <h2 className="fs-18 mb-2 mb-lg-4 mt-2 ml-lg-2 px-2 px-lg-0 fw-bold text-uppercase">
                90 day treatments
            </h2>
            <div className="row no-gutters">
                <div className={chartWprClasses.join(" ")}>
                    {chartData && (
                        <Line
                            ref={chartRef}
                            height={130}
                            width={200}
                            options={options}
                            data={getChartData}
                        />
                    )}
                </div>
                {/* Custom chart lagend wpr */}
                <div ref={lagendWprRef} className={legendWprClasses.join(" ")}></div>
            </div>
        </div>
    );
};

export default NinetyDayTreatmentWidget;
