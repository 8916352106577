import "./styles.scss";
import React from "react";
import CardSecondary from "../../../../UI/Card/CardSecondary";
import { ErrorCard, Text, TextColors, TextTypes } from "../../../../../component-library";
import PropTypes from "prop-types";

export const CardOnFile = ({
    hasLoaded,
    hasCardOnFile,
    cardEndsIn,
    expiresOn,
    dateAdded,
    hasCardExpired,
}) => {
    return (
        <CardSecondary className="CardOnFile-card">
            <div data-public>
                <p className="CardOnFile-title">Card on File</p>
                <div className="CardOnFile-line" />
            </div>

            {hasLoaded && (
                <>
                    {hasCardExpired && (
                        <ErrorCard data-public className={"CardOnFile-error"}>
                            <Text type={TextTypes.label} color={TextColors.red}>
                                This card has expired
                            </Text>
                        </ErrorCard>
                    )}

                    {hasCardOnFile && (
                        <div className="CardOnFile-active_card_content">
                            <div className="CardOnFile-column_container">
                                <p data-public className="CardOnFile-column_name">
                                    Card Ending In
                                </p>
                                <p className="CardOnFile-column_content">{cardEndsIn}</p>
                            </div>

                            <div className="CardOnFile-column_container">
                                <p data-public className="CardOnFile-column_name">
                                    Expires
                                </p>
                                <p className="CardOnFile-column_content">{expiresOn}</p>
                            </div>

                            <div data-public className="CardOnFile-column_container">
                                <p className="CardOnFile-column_name">Added</p>
                                <p className="CardOnFile-column_content">{dateAdded}</p>
                            </div>
                        </div>
                    )}
                    {!hasCardOnFile && (
                        <p data-public className="CardOnFile-empty">
                            No card on file.
                        </p>
                    )}
                </>
            )}
        </CardSecondary>
    );
};

CardOnFile.propTypes = {
    hasLoaded: PropTypes.bool,
    hasCardOnFile: PropTypes.bool,
    cardEndsIn: PropTypes.string,
    expiresOn: PropTypes.string,
    dateAdded: PropTypes.string,
    hasCardExpired: PropTypes.bool,
};
