// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import { getAutomationDataAttr } from "../../../utils/automationUtils";

const TitleBarItem = (props) => {
    let classes = "List__Title-bar-item col fw-bold fs-14 d-flex";

    return (
        <span
            onClick={props.onClick || undefined}
            className={`${classes} ${props.classes} ${
                props.alignCenter
                    ? "align-items-center justify-content-center text-align-center"
                    : null
            }`}
            {...getAutomationDataAttr(props.testId)}>
            {props.text}
        </span>
    );
};

export default TitleBarItem;
