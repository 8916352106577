import { Text } from "../../../component-library";
import React from "react";

export const InactiveModal = (props) => {
    const _telClick = () => {
        window.open("tel:8884543827");
    };
    const _emailClick = () => {
        window.open("mailto:support@aptihealth.com");
    };

    return (
        <div className="text-align-center mb-5 mx-5">
            <p className={"mb-3"}>
                <Text type="heading-1">Your Account is Inactive</Text>
            </p>
            <p className={"pb-2"}>
                <Text type="paragraph" color="grey">
                    We're happy to see you! But your account is currently inactive. 
                    Please email us at{" "}
                </Text>
                <Text
                    className="patient-contact-link"
                    type="paragraph"
                    onClick={_emailClick}>
                    support@aptihealth.com
                </Text>
                <Text type="paragraph" color="grey">
                    {" "}
                    or call us at{" "}
                </Text>
                <Text
                    className="patient-contact-link"
                    type="paragraph"
                    onClick={_telClick}>
                    (888) 454-3827
                </Text>
                <Text type="paragraph" color="grey"> to begin receiving care again.</Text>
            </p>
            <p>
                <Text type="paragraph" color="grey">
                    In the meantime you will not be able to schedule new sessions or message your
                    care team.
                </Text>
            </p>
        </div>
    );
};
