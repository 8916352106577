// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";

const SlotTile = (props) => {
    const { active } = props;
    let classes = "Schedule__TimeSlot-options mr-2 mb-3 fs-15";
    if (props.className) {
        classes = classes + " " + props.className;
    }
    if (active == props.TimeOption) {
        classes = classes + " " + "Schedule__TimeSlot-options--active";
    }
    return (
        <div className={classes} onClick={() => props.click(props.TimeOption)}>
            <p className="m-0">{props.TimeOption}</p>
        </div>
    );
};

export default SlotTile;
