import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
import { CheckmarkIcon } from "../Icons";
import { ICON_SIZES, ICON_TYPES } from "../../constants/icons";
import { createClassNameString } from "../utils";
import { getAutomationDataAttr } from "../../utils/automationUtils";

export const Checkbox = ({ name, onChange, checked, label, labelClassName, testId, ...props }) => {
    return (
        // using the label tag means clicking anything inside will also trigger
        // the inputs events. we use this here to style a checkbox
        <label className="Checkbox-wrapper_label">
            <input
                className="Checkbox-input"
                type="checkbox"
                name={name}
                checked={checked}
                onChange={onChange}
                {...props}
            />
            <div className="Checkbox-container">
                <div className="Checkbox-check_container" {...getAutomationDataAttr(testId)}>
                    {checked && (
                        <CheckmarkIcon
                            className="Checkbox-check"
                            iconSize={ICON_SIZES.SMALL}
                            iconType={ICON_TYPES.FILLED}
                        />
                    )}
                </div>

                {label && (
                    <p className={createClassNameString(["Checkbox-label", labelClassName])}>
                        {label}
                    </p>
                )}
            </div>
        </label>
    );
};

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    testId: PropTypes.string,
};

Checkbox.defaultProps = {
    name: "",
};
