// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import { HeaderAttribute, HeaderTitle, DownloadBtn, SquashedValues } from "../ReportsView";
import Button from "../../../components/UI/Button";
import { toTitleCase } from "../../../utils/filters";
import moment from "moment-timezone";

const XBARReportHeader = ({ toggleCommentBox, reportData }) => {
    const {
        first_name,
        type,
        last_name,
        report_url,
        modified_at,
        patient_id,
        clinician_name,
        dob,
    } = reportData;

    const patientFullName = toTitleCase(first_name + " " + last_name);

    const localModifiedDateTime = moment
        .utc(modified_at, "MM/DD/YYYY HH:mm")
        .local()
        .format("MM/DD/YYYY hh:mm a");

    /** -----------
     * date of format `MM/DD/YYYY HH:mm:ss`
     * is split into ['MM/DD/YYYY','HH:mm:ss']
     */
    const splittedDate = localModifiedDateTime.split(" ");
    /** --------- */
    const LocaleModified_time = splittedDate[1] + " " + splittedDate[2];
    const LocaleModifiedDate = splittedDate[0];

    return (
        <div className="row no-gutters pb-3" style={{ borderBottom: "1px solid #B8B8B8" }}>
            {/* patient name */}
            <div className="col-12 mb-2">
                <HeaderTitle text={patientFullName} />
            </div>
            {/* report type */}
            <div className="col-12 mb-2">
                <HeaderAttribute
                    attribute="Report Type"
                    value={
                        <SquashedValues
                            valueList={[type, LocaleModifiedDate, LocaleModified_time]}
                        />
                    }
                />
            </div>
            {/* Clinician Name */}
            <div className="col-12 mb-2">
                <HeaderAttribute attribute="Clinician Name" value={clinician_name} />
            </div>
            {/* DOB */}
            <div className="col-12 col-lg-6 mb-2 mb-lg-0">
                <HeaderAttribute attribute="Date of Birth" value={dob} />
            </div>
            {/* header controls/actions */}
            <div className="col-12 col-lg-6 d-flex justify-content-lg-end">
                <div className="mr-2 mr-lg-3">
                    <DownloadBtn url={report_url} />
                </div>
                <Button
                    onClick={toggleCommentBox}
                    style={{ minHeight: "35px", padding: "4px 20px" }}
                    className="Btn fw-300 fs-14 Btn--pri">
                    Add Provider Comment
                </Button>
            </div>
        </div>
    );
};

export default XBARReportHeader;
