import "./styles.scss";
import CalendarCurrentTime from "./CalendarCurrentTime";
import React from "react";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { timeSlots } from "../../../constants/calendar";

export const calendarBackgroundTimeAxisId = "CalendarBackgroundTimeAxis";

export const CalendarBackgroundTimeAxis = ({ currentTime, calendarRect }) => {
    const timeSlotElements = timeSlots.map(({ slot }) => {
        let momentTimeSlot = moment(slot, "H").format("h");
        // AM/PM
        let momentTimeOfDay = moment(slot, "H").format("A");

        return (
            <div className="CalendarBackgroundTimeAxis__time" key={slot + "empty-time-legend"}>
                <div className="CalendarBackgroundTimeAxis__time__wrapper">
                    {/* The time is separated from AM/PM to hide AM/PM on mobile */}
                    <div className="CalendarBackgroundTimeAxis__time__label">
                        <div className="CalendarBackgroundTimeAxis__time__label__text">
                            {momentTimeSlot}
                        </div>
                        <div className="CalendarBackgroundTimeAxis__time__label__time-of-day">
                            {momentTimeOfDay}
                        </div>
                    </div>
                    {/* notch refers to the small protruding line on the left hand side of the calendar */}
                    <div className="CalendarBackgroundTimeAxis__time__notch" />
                </div>
            </div>
        );
    });

    return (
        <div id="CalendarBackgroundTimeAxis" className="CalendarBackgroundTimeAxis">
            {timeSlotElements}
            <CalendarCurrentTime calendarRect={calendarRect} currentTime={currentTime} />
        </div>
    );
};

CalendarBackgroundTimeAxis.propTypes = {
    className: PropTypes.string,
};

CalendarBackgroundTimeAxis.defaultProps = {
    className: undefined,
};
