// Copyright aptihealth, inc. 2021 All Rights Reserved
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import images from "../../../../utils/images";
import Button from "../../../UI/Button";
import { Form, Formik, getIn } from "formik";
import cloneDeep from "lodash/cloneDeep";
import * as yup from "yup";
import { api } from "../../../../APIRequests";
import FormikInput from "../../../UI/FormikInput";
import Label from "../../../UI/Label";
import { default as ReactSelect } from "react-select";
import InputError from "../../../UI/InputError";
import { showAlertWithAction } from "../../../../redux/actions/alerts";
import { connect } from "react-redux";
import { CustomForm } from "../../../../component-library/CustomForm";

const ConfirmInsurance = (props) => {
    const { sharedWorkflowData, setSharedWorkflowData } = props;

    const insuranceData =
        sharedWorkflowData.workflow_instance && sharedWorkflowData.workflow_instance.state;

    const [isEditing, setIsEditing] = useState(false);
    const [insuranceOptions, setInsuranceOptions] = useState([]);

    const confirmInsuranceHandler = async () => {
        try {
            const options = { params: { showLoader: true } };
            const data = {
                workflow_instance_sort_key: sharedWorkflowData.workflow_instance.sort_key,
                node_name: "confirm_insurance",
                state: {
                    insurance_confirmed: true,
                },
            };
            const headers = {
                "X-On-Behalf-Of": sharedWorkflowData.workflow_instance.entity_id,
            };
            const response = await api.workflow.update_state_and_get_next_node({
                options,
                data,
                headers,
            });
            setSharedWorkflowData({
                ...sharedWorkflowData,
                workflow_instance: response.workflow_instance,
            });
        } catch (e) {
            console.log(e);
            props.showAlertWithAction("Error confirming insurance.");
        }
    };

    const submitHandler = async (formData, actions) => {
        try {
            const options = { params: { showLoader: true } };
            const data = {
                email: sharedWorkflowData.workflow_instance.state.email,
                dob: sharedWorkflowData.workflow_instance.state.dob,
                insurance_data: { ...formData },
                workflow_instance_sort_key: sharedWorkflowData.workflow_instance.sort_key,
            };
            const headers = {
                "X-On-Behalf-Of": sharedWorkflowData.workflow_instance.entity_id,
            };
            const response = await api.shared.update_insurance({ options, data, headers });
            setSharedWorkflowData({
                ...sharedWorkflowData,
                workflow_instance: response.workflow_instance,
            });
            setIsEditing(false);
        } catch (e) {
            console.log(e);
            props.showAlertWithAction("Error saving insurance information");
        }
    };

    const updateInsuranceHandler = async () => {
        try {
            const options = { params: { showLoader: true } };
            const rawInsuranceList = await api.patient.fetch_insurance_list({ options });
            if (rawInsuranceList && rawInsuranceList.carriers) {
                setInsuranceOptions(
                    rawInsuranceList.carriers.map((carrier) => {
                        return { value: carrier, label: carrier };
                    }),
                );
                setIsEditing(true);
            }
        } catch (e) {
            console.log(e);
            props.showAlertWithAction("Error fetching insurance providers");
        }
    };

    const renderForm = (formikProps) => {
        const formikInputProps = {
            inputClasses: "Input--sm",
            errors: formikProps.errors,
            touched: formikProps.touched,
        };
        return (
            <div>
                <Form>
                    <div className={"workflow-container-sm workflow-container-border mb-5"}>
                        <div className={"p-lg-5 p-3"}>
                            <div className="row">
                                <div className={"col-12 mb-3"}>
                                    <div style={{ margin: "0 auto" }}>
                                        <Label>Insurance Provider</Label>
                                        <ReactSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            className={"react-select-box text-left"}
                                            classNamePrefix={"react-select-box"}
                                            name={"carrier"}
                                            defaultValue={
                                                insuranceData.carrier
                                                    ? {
                                                          value: insuranceData.carrier,
                                                          label: insuranceData.carrier,
                                                      }
                                                    : null
                                            }
                                            onChange={async (option, action) => {
                                                if (option) {
                                                    await formikProps.setFieldValue(
                                                        "carrier",
                                                        option.value,
                                                    );
                                                } else {
                                                    await formikProps.setFieldValue("carrier", "");
                                                }
                                                formikProps.setFieldTouched("carrier");
                                            }}
                                            options={insuranceOptions}
                                        />
                                        {getIn(formikProps.errors, "carrier") &&
                                        getIn(formikProps.touched, "carrier") ? (
                                            <InputError
                                                classes={"custom-error"}
                                                style={{ position: "relative" }}>
                                                {getIn(formikProps.errors, "carrier")}
                                            </InputError>
                                        ) : null}
                                    </div>
                                </div>

                                <div className={"col-12"}>
                                    <FormikInput
                                        {...formikInputProps}
                                        formEl={{
                                            name: "member_id",
                                            elementType: "input",
                                            elementConfig: {
                                                type: "text",
                                                label: "Member ID",
                                                placeholder: "Enter Member ID",
                                            },
                                        }}
                                    />
                                </div>

                                <div className={"col-12"}>
                                    <FormikInput
                                        {...formikInputProps}
                                        formEl={{
                                            name: "group_id",
                                            elementType: "input",
                                            elementConfig: {
                                                type: "text",
                                                label: "Group ID",
                                                placeholder: "Enter Group ID",
                                            },
                                        }}
                                    />
                                </div>

                                <div className={"col-12"}>
                                    <FormikInput
                                        {...formikInputProps}
                                        formEl={{
                                            name: "policy_holder_name",
                                            elementType: "input",
                                            elementConfig: {
                                                type: "text",
                                                label: "Primary Insurance Holder",
                                                placeholder: "Enter Primary Insurance Holder",
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="workflow-container-sm d-flex justify-content-between">
                        <Button
                            type={"button"}
                            className={"Btn Btn--otl-gry Btn--sm-2 workflow-btn"}
                            onClick={() => setIsEditing(false)}>
                            Cancel
                        </Button>
                        <Button type={"submit"} className={"Btn Btn--pri Btn--sm-2 workflow-btn"}>
                            Save
                        </Button>
                    </div>
                </Form>
            </div>
        );
    };

    return (
        <div className={"p-lg-5 p-3"}>
            <div className={"fs-22 txt-pri fw-bold mb-5 text-center"}>
                Confirm your insurance information
            </div>
            <div className={"text-center my-4"}>
                <img
                    src={images("./common/workflow/ed-workflow-1-confirm-ins-active.png")}
                    alt={"Confirm Insurance"}
                    className={"signup-progress-bar"}
                />
            </div>
            {sharedWorkflowData && sharedWorkflowData.workflow_instance && (
                <>
                    {!isEditing && (
                        <>
                            <div className={"workflow-container-sm workflow-container-border mb-5"}>
                                <div className={"p-lg-5 p-3"}>
                                    <div className={"row"}>
                                        <div className={"col-12 mb-4"}>
                                            <div className={"txt-gry fs-14"}>
                                                Insurance Provider
                                            </div>
                                            <div className={"fw-bold fs-md-16 fs-14"}>
                                                {insuranceData.carrier}
                                            </div>
                                        </div>
                                        <div className={"col-12 mb-4"}>
                                            <div className={"txt-gry fs-14"}>Member ID</div>
                                            <div className={"fw-bold fs-md-16 fs-14"}>
                                                {insuranceData.member_id}
                                            </div>
                                        </div>
                                        <div className={"col-12 mb-4"}>
                                            <div className={"txt-gry fs-14"}>Group ID</div>
                                            <div className={"fw-bold fs-md-16 fs-14"}>
                                                {insuranceData.group_id}
                                            </div>
                                        </div>
                                        <div className={"col-12 mb-1"}>
                                            <div className={"txt-gry fs-14"}>
                                                Policy Insurance Holder
                                            </div>
                                            <div className={"fw-bold fs-md-16 fs-14"}>
                                                {insuranceData.policy_holder_name}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="workflow-container-sm d-flex justify-content-between">
                                <Button
                                    type={"button"}
                                    className={"Btn Btn--otl-gry Btn--sm-2 workflow-btn"}
                                    onClick={() => props.backHandler()}>
                                    Back
                                </Button>
                                <Button
                                    type={"button"}
                                    className={"Btn Btn--pri Btn--sm-2 workflow-btn"}
                                    onClick={confirmInsuranceHandler}>
                                    Confirm & Continue
                                </Button>
                            </div>
                            <div className={"text-center my-5 fs-14"}>
                                <Button
                                    type={"button"}
                                    className={
                                        "Btn Btn--otl-pri Btn--sm-2 workflow-btn workflow-btn-lg"
                                    }
                                    style={{ padding: "0 12px" }}
                                    onClick={updateInsuranceHandler}>
                                    Update Insurance Information
                                </Button>
                            </div>
                        </>
                    )}
                    {isEditing && (
                        <>
                            <CustomForm
                                initialValues={cloneDeep(insuranceData)}
                                validationSchema={insuranceValidation}
                                onSubmit={submitHandler}
                                render={renderForm}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default connect(null, { showAlertWithAction })(withRouter(ConfirmInsurance));

const insuranceValidation = yup.object().shape({
    carrier: yup.string().required("Insurance provider is required").nullable(),
    member_id: yup
        .string()
        .required("Member id is required")
        .matches(/^[a-zA-Z0-9]*$/, "Enter a valid member id")
        .nullable(),
    policy_holder_name: yup
        .string()
        .matches(/^[a-z A-Z-]+$/, "Numerals, spaces & special characters are not allowed")
        .required("Policy holder name is required")
        .nullable(),
});
