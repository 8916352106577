import React, { Component } from "react";
import Slide from "./Slide";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import images from "../../utils/images";

class Testimonials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSlide: 0,
            activeSlide2: 0,
        };
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 6000,
            adaptiveHeight: true,
            beforeChange: (current, next) => this.setState({ activeSlide: next }),
            afterChange: (current) => this.setState({ activeSlide2: current }),
        };

        const Hannah =
            "My treatment plan was very personalized and goal-oriented. My psychologist and I made a plan and stuck to that plan to help me stay on track to achieve my goals. aptihealth truly helped me feel more empowered. I feel like my old self again.";
        const Tony = [
            "I had been seeking treatment for 12 years. 12 constant years of trying everything. And in three months, this program just did me wonders. ",
            <strong>
                <i>aptihealth gave me my life&nbsp;back</i>
            </strong>,
            ".",
        ];

        return (
            <section
                className={"testimonials bg" + this.state.activeSlide}
                style={{ maxWidth: "100vw" }}>
                <div className="wrap">
                    <div className="slider">
                        <Slider {...settings}>
                            <Slide
                                name="Tony"
                                title=", aptihealth Member"
                                quote={Tony}
                                photo={images("./authentication/profile-tony.jpg")}
                            />
                            <Slide
                                name="Hannah*"
                                title=", aptihealth Member"
                                quote={Hannah}
                                photo={images("./authentication/profile-hannah.jpg")}
                                desc="*Name has been changed to protect privacy."
                            />
                        </Slider>
                    </div>
                </div>
            </section>
        );
    }
}

export default Testimonials;
