import React from "react";
import PropTypes from "prop-types";

import { Button } from "../Button";
import { Image } from "../Image";

import "./styles.scss";

export const BrowseButton = ({ onClick, disabled, ...props }) => (
    <Button
        className="apti-BrowseButton"
        name="browse-button"
        type="button"
        onClick={onClick}
        disabled={disabled}
        {...props}>
        <>
            <Image src="icons/folder.svg" alt="folder" />
            Browse
        </>
    </Button>
);

BrowseButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

BrowseButton.defaultProps = {
    onClick: () => {},
    disabled: false,
};
