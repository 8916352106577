// Copyright aptihealth, inc. 2019 All Rights Reserved
import { httpAuth } from '../utils/axios';
import {getGroupAuthType} from "../redux/actions/auth";
import { GROUP_AUTH_HEADER_NAME } from '../constants/axios';

const BASE_CALENDAR_API_URL = process.env.REACT_APP_CALENDAR_API_URL;

const scheduleRequests = {
    get_schedule: function ({ options } = {}) {
        let queryParams = "";
        for (const [key, value] of Object.entries(options.queryParams)) {
            if (value) {
                queryParams = `${queryParams}${queryParams ? "&" : "?"}${key}=${encodeURIComponent(
                    value,
                )}`;
            }
        }

        const conf = {
            method: "get",
            url: `/v1/calendar/schedule${queryParams}`,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            ...options,
            baseURL: BASE_CALENDAR_API_URL,
        };
        return httpAuth(conf);
    },

    post_schedule: function ({ data, headers } = {}) {
        const conf = {
            method: "post",
            url: "/v1/calendar/schedule",
            headers: {
                [GROUP_AUTH_HEADER_NAME]: getGroupAuthType(),
                ...headers,
            },
            data,
            baseURL: BASE_CALENDAR_API_URL,
        };
        return httpAuth(conf);
    },

    put_schedule: function ({ data, headers } = {}) {
        const conf = {
            method: "put",
            url: "/v1/calendar/schedule",
            headers: {
                [GROUP_AUTH_HEADER_NAME]: getGroupAuthType(),
                ...headers,
            },
            data,
            baseURL: BASE_CALENDAR_API_URL,
        };
        return httpAuth(conf);
    },

    delete_schedule: function ({ data, headers, options } = {}) {
        let queryParams = "";
        for (const [key, value] of Object.entries(options.queryParams)) {
            if (value) {
                queryParams = `${queryParams}${queryParams ? "&" : "?"}${key}=${encodeURIComponent(
                    value,
                )}`;
            }
        }
        const conf = {
            method: "delete",
            url: `/v1/calendar/schedule${queryParams}`,
            headers: {
                [GROUP_AUTH_HEADER_NAME]: getGroupAuthType(),
                ...headers,
            },
            data,
            baseURL: BASE_CALENDAR_API_URL,
        };
        return httpAuth(conf);
    },
};

export default scheduleRequests;
