// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import PropTypes from "prop-types";

const SidebarSublist = (props) => {
    const { children } = props;

    const renderListItems = (listItems) => {
        let renderedListItems = React.Children.map(listItems, (child) => {
            return <li className="mb-4">{child}</li>;
        });
        return renderedListItems;
    };

    return (
        <div className="SidebarSublist ml-3 p-3">
            <ul>{renderListItems(children)}</ul>
        </div>
    );
};

SidebarSublist.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SidebarSublist;
