import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";
import { getAutomationDataAttr } from "../../utils/automationUtils";

export const TextArea = ({
    children,
    className,
    name,
    placeholder,
    resize,
    error,
    ignoreEnterSubmit,
    testId,
    ...props
}) => {
    let getPointer = (el) => {
        if (el.selectionStart) {
            return el.selectionStart;
        }
        return 0;
    };

    let onKeyDown = (e) => {
        if (e.key === "Enter" && ignoreEnterSubmit) {
            let content = e.target.value;
            let pointerPosition = getPointer(e.target);
            e.target.value =
                content.substring(0, pointerPosition) +
                content.substring(pointerPosition, content.length);
            e.stopPropagation();
        }
    };

    return (
        <textarea
            className={createClassNameString([
                "apti-CL apti-TextArea",
                className,
                `resize-${resize}`,
                error && "error",
            ])}
            name={name}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            {...getAutomationDataAttr(testId)}
            {...props}>
            {children}
        </textarea>
    );
};

TextArea.propTypes = {
    children: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    ignoreEnterSubmit: PropTypes.bool,
    error: PropTypes.bool,
    resize: PropTypes.oneOf(["both", "horizontal", "vertical", "none"]),
    testId: PropTypes.string,
};

TextArea.defaultProps = {
    children: undefined,
    className: undefined,
    name: "",
    error: false,
    placeholder: undefined,
    resize: "none",
    ignoreEnterSubmit: false,
    testId: undefined,
};
