// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { useState, useEffect } from "react";
import "./../styles.scss";
import Button from "../../../UI/Button";
import { api } from "../../../../APIRequests";
import { getFile } from "../../../../containers/Dashboard/Providers/providerAPI";
import images from "../../../../utils/images";
import _ from "lodash";
import { TextArea } from "../../../UI/StyledInput";

const CarePlanCreation = (props) => {
    const { patientId } = props;

    const [objectiveOne, setObjectiveOne] = useState("");
    const [objectiveTwo, setObjectiveTwo] = useState("");
    const [objectiveThree, setObjectiveThree] = useState("");
    const [fileName, setFileName] = useState("");

    useEffect(() => {
        (async () => {
            if (patientId) {
                const response = await api.provider.get_care_plan({
                    data: {},
                    urlParams: { patientId: patientId },
                });
                setObjectiveOne(_.get(response, "care_plan.objectives.objective_one", ""));
                setObjectiveTwo(_.get(response, "care_plan.objectives.objective_two", ""));
                setObjectiveThree(_.get(response, "care_plan.objectives.objective_three", ""));
                let cleanResponse = await fetchCarePlanKey();
                if (cleanResponse && cleanResponse.message) {
                    setFileName(cleanResponse.message);
                }
            }
        })();
    }, []);

    const fetchCarePlanKey = () => {
        let urlParams = { patientId: patientId };
        const options = { params: { showLoader: false } };
        return api.shared.fetch_patient_care_plan({ urlParams, options });
    };

    const viewCarePlan = async () => {
        if (!fileName) {
            let cleanResponse = await fetchCarePlanKey();
            if (cleanResponse && cleanResponse.message) {
                setFileName(cleanResponse.message);
            }
        }
        getFile(patientId, fileName);
    };

    let downloadButtonDisabled = !fileName;

    return (
        <>
            <div className="care-plan-upload">
                <div className="care-plan-upload__inner p-4" style={{ height: "unset" }}>
                    <div className="close-button" onClick={props.close}>
                        <img src={images("./icons/cross.svg")} />
                    </div>
                    <h5 className="fw-bold text-center fs-17 pb-4 m-0">Care Plan Details</h5>
                    <div className="d-flex flex-column care-plan-upload__objectives">
                        <div className={"care-plan-upload__objective"}>
                            <h6 className="fw-bold">Care Plan Goals and/or Objectives #1</h6>
                            <TextArea disabled={true} value={objectiveOne} />
                        </div>
                        <div className={"care-plan-upload__objective"}>
                            <h6 className="fw-bold">Care Plan Goals and/or Objectives #2</h6>
                            <TextArea disabled={true} value={objectiveTwo} />
                        </div>
                        <div className={"care-plan-upload__objective"}>
                            <h6 className="fw-bold">Care Plan Goals and/or Objectives #3</h6>
                            <TextArea disabled={true} value={objectiveThree} />
                        </div>
                    </div>
                    {/*DESKTOP BUTTONS*/}
                    <div className="d-none d-lg-flex care-plan-upload__buttons">
                        <Button
                            type="submit"
                            className="Btn Btn--otl-pri Btn-sm"
                            disabled={downloadButtonDisabled}
                            onClick={() => viewCarePlan()}>
                            {"Download Care Plan"}
                        </Button>
                    </div>
                    {/*MOBILE BUTTONS*/}
                    <div className="d-flex d-lg-none care-plan-upload__buttons">
                        <div className="d-flex align-items-center">
                            <Button
                                type="submit"
                                className="Btn Btn--otl-pri Btn-xs"
                                disabled={downloadButtonDisabled}
                                onClick={() => viewCarePlan()}>
                                {"Download Care Plan"}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CarePlanCreation;
