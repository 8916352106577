import momentTZ from "moment-timezone";
import { convertToMultiDaySlots } from "./timezoneConversion";

const convertWorkingHourToLocal = (workingHours, providerTZ, originTZ) => {
    if (!workingHours) {
        return;
    }
    const providerStartOfWeek = momentTZ.tz(providerTZ).startOf("week").startOf("day");
    const localWorkingHours = {};
    const createNewSlot = (dayName, wasEnabled) => {
        localWorkingHours[dayName] = {
            slots: [],
        };
    };
    const addToSlot = (dayName, value, wasEnabled) => {
        if (value[0] === "00:00" && value[1] === "00:00") {
            return;
        }
        if (!Object.keys(localWorkingHours).includes(dayName)) {
            createNewSlot(dayName);
        }
        localWorkingHours[dayName].slots.push(value);
    };
    // add all time slots
    for (const workingDayName of Object.keys(workingHours)) {
        const workingDayObject = workingHours[workingDayName];
        const workingDayIndex = momentTZ(workingDayName, "dddd").day();
        // get the current working day as a momentTZ instance in the providers timezone
        const providerWorkingDay = providerStartOfWeek.clone().add(workingDayIndex, "day");
        for (const slot of workingDayObject.slots) {
            const startTime = momentTZ(slot[0], "HH:mm");
            const startDayTime = providerWorkingDay
                .clone()
                .set({
                    hour: startTime.hour(),
                    minute: startTime.minute(),
                })
                .tz(originTZ);

            const endTime = momentTZ(slot[1], "HH:mm");
            const endDayTime = providerWorkingDay
                .clone()
                .set({
                    hour: endTime.hour(),
                    minute: endTime.minute(),
                })
                .tz(originTZ);

            // ensures that multi-day events are handled correctly
            const multiDaySlots = convertToMultiDaySlots(startDayTime, endDayTime);

            multiDaySlots.forEach((slot) => {
                const startDay = slot.startTime.format("dddd");
                addToSlot(startDay, [slot.startTime.format("HH:mm"), slot.endTime.format("HH:mm")]);
            });
        }
    }

    return localWorkingHours;
};

export { convertWorkingHourToLocal };
