// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import { Footer } from "../../component-library/Footer";
import { Header } from "../../component-library/Header";
import { Card } from "../../component-library/Card";
import { Link } from "react-router-dom";
import { clearAuthChallenge } from "../../redux/actions/auth";
import { connect } from "react-redux";

const FormWrapper = (props) => {
    const backLinkDisplay = props.showBackLink ? "block" : "none";

    const clearAuthData = () => {
        localStorage.removeItem("idpOptions");
        localStorage.removeItem("idpLoginUrl");
        localStorage.removeItem("ssoInProgress");
        localStorage.removeItem("idpLogoutUrl");

        if (props.challengeName) {
            props.clearAuthChallenge();
        }
    };

    return (
        <>
            <Header />
            <div className="apti-Backlink-Wrapper">
                <Link
                    to="/auth/sign-in"
                    style={{
                        color: "#0CD0A7",
                        fontWeight: 700,
                        display: backLinkDisplay,
                        position: "absolute",
                    }}
                    onClick={() => clearAuthData()}
                    className="underline_hover">
                    Back
                </Link>
                <Card className={"no-border apti-Login-Card auto-height"}>{props.children}</Card>
            </div>
            <Footer contentcolor="grey" />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        challengeName: state.auth.challengeName,
    };
};

export default connect(mapStateToProps, { clearAuthChallenge })(FormWrapper);
