import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";
import { getAutomationDataAttr } from "../../utils/automationUtils";

export const Toggle = ({
    className,
    id,
    name,
    checked,
    disabled,
    onClick,
    onChange,
    onBlur,
    testId,
    ...props
}) => {
    const onClickHandler = (e) => {
        onClick(e);
    };

    return (
        <div className={createClassNameString(["apti-CL apti-Toggle", className])} {...props}>
            <input
                className="apti-Toggle__mobile-toggle"
                type="checkbox"
                name={name}
                id={id ? id : name}
                checked={checked}
                disabled={disabled}
                onClick={onClickHandler}
                onChange={onChange}
                onBlur={onBlur}
                {...getAutomationDataAttr(testId)}
            />
            <label htmlFor={id ? id : name}></label>
        </div>
    );
};

Toggle.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    testId: PropTypes.string,
};
Toggle.defaultProps = {
    className: undefined,
    id: undefined,
    name: "toggle",
    checked: false,
    disabled: false,
    onClick: () => {},
    onChange: () => {},
    onBlur: () => {},
    testId: undefined,
};
