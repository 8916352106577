// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useLayoutEffect } from "react";
import images from "../../../utils/images";

const MobileCalenderView = React.forwardRef((props, sliderRef) => {
    const { year, month, prev, allowPrev, next, tilesArray, noOfSlots } = props;
    let selectedRef = React.createRef();

    useLayoutEffect(() => {
        selectedRef.current.scrollIntoView({ block: "center", inline: "start" });
    }, [props.month]);

    const mobileControls = (
        <div className="DatePicker__mobile-controls cursor pt-3 text-center">
            <div className="d-flex align-items-center justify-content-center">
                <span className="DatePicker__chevron-left cursor p-1">
                    <button onClick={prev}>
                        <img src={images("./common/chevron.svg")} alt="" />
                    </button>
                </span>
                <span className="month_and_year px-4 fw-bold">{month + " " + year}</span>
                <span className="DatePicker__chevron-right cursor p-1">
                    <button onClick={next}>
                        <img src={images("./common/chevron.svg")} alt="" />
                    </button>
                </span>
            </div>
        </div>
    );

    const mobileSlider = (
        <div className="DatePicker__mobile-slider scroll-fix pl-1" ref={sliderRef}>
            <div className="DatePicker__mobile-slider-inner d-flex">
                {tilesArray.map((tileConf) => {
                    let classes_date_tile = ["DatePicker__date-tile", "my-4", "mr-2"];
                    let slotsAvailabilityText = "Tap to view";

                    let ref = null;
                    if (props.selectedDay == tileConf.monthDay) {
                        classes_date_tile.push("DatePicker__date-tile--active");
                        slotsAvailabilityText = props.loadingSlots
                            ? "Loading..."
                            : noOfSlots
                            ? `${noOfSlots} slots available`
                            : "0 slots available";
                        ref = selectedRef;
                    }
                    return (
                        <div
                            ref={ref}
                            className={classes_date_tile.join(" ")}
                            onClick={(e) => props.onDayClick(e, tileConf.prefixedDay)}
                            key={tileConf.monthDay}>
                            <div className="DatePicker__date-tile-inner d-flex flex-column align-items-center justify-content-center">
                                <span className="fs-12 fw-bold">{tileConf.weekDay}</span>
                                <span className="fs-15">
                                    {tileConf.monthDay + " " + props.month}{" "}
                                </span>
                                <span className="fs-11">{slotsAvailabilityText}</span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );

    return (
        <div className="DatePicker__MobileCalanderView pl-2 d-block d-lg-none">
            {mobileControls}
            {mobileSlider}
        </div>
    );
});

export default MobileCalenderView;
