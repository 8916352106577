export const arrayToIndexedObject = (arr) => {
    return arr
        .map((label, index) => {
            return { label, index };
        })
        .reduce((acc, { label, index }) => {
            acc[index] = label;
            return acc;
        }, {});
};
