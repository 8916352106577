import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { createClassNameString } from "../../component-library";
import { ICON_SIZES, ICON_TYPES } from "../../constants/icons";

export const TourIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 4C11.3431 4 10 5.34315 10 7V25C10 26.6569 11.3431 28 13 28H19C20.6569 28 22 26.6569 22 25V7C22 5.34315 20.6569 4 19 4H13ZM19 6H13C12.4477 6 12 6.44772 12 7V25C12 25.5523 12.4477 26 13 26H19C19.5523 26 20 25.5523 20 25V7C20 6.44772 19.5523 6 19 6Z"
                />
                <path d="M25 9H24V7H25C26.6569 7 28 8.34315 28 10V22C28 23.6569 26.6569 25 25 25H24V23H25C25.5523 23 26 22.5523 26 22V10C26 9.44772 25.5523 9 25 9Z" />
                <path d="M8 9H7C6.44772 9 6 9.44772 6 10V22C6 22.5523 6.44772 23 7 23H8V25H7C5.34315 25 4 23.6569 4 22V10C4 8.34315 5.34315 7 7 7H8V9Z" />
            </svg>
        );
    }

    return null;
};

TourIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

TourIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
