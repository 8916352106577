/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const ShieldIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Shield"
                {...props}>
                <path d="M17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12V14.5H12.5C11.9477 14.5 11.5 14.9477 11.5 15.5C11.5 16.0523 11.9477 16.5 12.5 16.5H15V19C15 19.5523 15.4477 20 16 20C16.5523 20 17 19.5523 17 19V16.5H19.5C20.0523 16.5 20.5 16.0523 20.5 15.5C20.5 14.9477 20.0523 14.5 19.5 14.5H17V12Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.0001 6C23.0001 9 24.6314 10.2105 27.0001 11C27.0001 24 20.5019 27.0484 16.0001 29C11.2859 27.0502 5.00012 24 5.00012 11C7.70121 10.0996 9.00009 9.00039 9.00009 6C9.00009 5.43653 9.38603 5.20469 10.0001 5C11.5 4.50001 13.0001 4 16.1072 4C19 4 21.0001 4.5 22.0001 5C22.0539 5.0269 22.1069 5.05272 22.1588 5.07798C22.6268 5.30594 23.0001 5.48775 23.0001 6ZM16.1072 6C18.7175 6 20.3526 6.44082 21.0294 6.75232C21.1542 8.31181 21.6788 9.62313 22.6302 10.6724C23.307 11.4187 24.1262 11.9487 24.9745 12.3479C24.7677 17.6761 23.3334 20.8401 21.6503 22.8646C20.0079 24.8403 17.9798 25.926 15.9835 26.8223C13.923 25.931 11.8965 24.8457 10.2779 22.8746C8.62152 20.8575 7.22791 17.6975 7.0254 12.363C7.95921 11.9432 8.83216 11.3942 9.52282 10.5992C10.4463 9.5362 10.8679 8.24784 10.973 6.78422C12.243 6.36536 13.5269 6 16.1072 6Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Shield"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.00012 11C5.00012 24 11.2859 27.0502 16.0001 29C20.5019 27.0484 27.0001 24 27.0001 11C24.6314 10.2105 23.0001 9 23.0001 6C23.0001 5.48775 22.6268 5.30594 22.1588 5.07798C22.1069 5.05272 22.0539 5.0269 22.0001 5C21.0001 4.5 19 4 16.1072 4C13.0001 4 11.5002 4.49997 10.0002 4.99996C9.38616 5.20464 9.00009 5.43653 9.00009 6C9.00009 9.00039 7.70121 10.0996 5.00012 11ZM17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12V14.5H12.5C11.9477 14.5 11.5 14.9477 11.5 15.5C11.5 16.0523 11.9477 16.5 12.5 16.5H15V19C15 19.5523 15.4477 20 16 20C16.5523 20 17 19.5523 17 19V16.5H19.5C20.0523 16.5 20.5 16.0523 20.5 15.5C20.5 14.9477 20.0523 14.5 19.5 14.5H17V12Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Shield"
                {...props}>
                <path d="M20 16.25C20.4142 16.25 20.75 16.5858 20.75 17V18.875H22.625C23.0392 18.875 23.375 19.2108 23.375 19.625C23.375 20.0392 23.0392 20.375 22.625 20.375H20.75V22.25C20.75 22.6642 20.4142 23 20 23C19.5858 23 19.25 22.6642 19.25 22.25V20.375H17.375C16.9608 20.375 16.625 20.0392 16.625 19.625C16.625 19.2108 16.9608 18.875 17.375 18.875H19.25V17C19.25 16.5858 19.5858 16.25 20 16.25Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20.0001 29.75C16.4644 28.2877 11.7501 26 11.7501 16.25C13.7759 15.5747 14.7501 14.7503 14.7501 12.5C14.7501 12.0774 15.0396 11.9035 15.5002 11.75C16.6252 11.375 17.7501 11 20.0804 11C22.25 11 23.7501 11.375 24.5001 11.75C24.5404 11.7702 24.5802 11.7895 24.6191 11.8085C24.9701 11.9795 25.2501 12.1158 25.2501 12.5C25.2501 14.75 26.4736 15.6578 28.2501 16.25C28.2501 26 23.3765 28.2863 20.0001 29.75Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

ShieldIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

ShieldIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
