import { api } from "../APIRequests";
import { http } from "./axios";
import moment from "moment";

const ASSOCIATED_FILES_FILE_TYPE = "ASSOCIATED_FILES";
const PATIENT_ASSOCIATED_FILES_FILE_SUB_TYPE = "ASSOCIATED_FILE_PATIENT";
const PATIENT_ASSOCIATED_FILES_ENTITY_TYPE = PATIENT_ASSOCIATED_FILES_FILE_SUB_TYPE.toLowerCase();
const REFERRAL_ASSOCIATED_FILES_FILE_SUB_TYPE = "ASSOCIATED_FILE_REFERRAL";
const REFERRAL_ASSOCIATED_FILES_ENTITY_TYPE = REFERRAL_ASSOCIATED_FILES_FILE_SUB_TYPE.toLowerCase();
const SCREENING_ASSOCIATED_FILES_FILE_SUB_TYPE = "ASSOCIATED_FILE_SCREENING";
const SCREENING_ASSOCIATED_FILES_ENTITY_TYPE =
    SCREENING_ASSOCIATED_FILES_FILE_SUB_TYPE.toLowerCase();

export const PATIENT_INSURANCE_IMAGE_FILE_TYPE = "PATIENT_INSURANCE_IMAGE";
export const REFERRAL_INSURANCE_IMAGE_FILE_TYPE = "REFERRAL_INSURANCE_IMAGE";
export const INSURANCE_IMAGE_FILE_PRIMARY_FRONT_SUB_TYPE = "PRIMARY_INSURANCE_FRONT";
export const INSURANCE_IMAGE_FILE_PRIMARY_BACK_SUB_TYPE = "PRIMARY_INSURANCE_BACK";
export const INSURANCE_IMAGE_FILE_SECONDARY_FRONT_SUB_TYPE = "SECONDARY_INSURANCE_FRONT";
export const INSURANCE_IMAGE_FILE_SECONDARY_BACK_SUB_TYPE = "SECONDARY_INSURANCE_BACK";

export const UserFileTypes = Object.freeze({
    PATIENT: "patient",
    REFERRAL: "referral",
    SCREENING: "screening",
    RAW_ELIGIBILITY_RESPONSE: "raw_eligibility_response",
    ASSOCIATED_FILE_PATIENT: "associated_file_patient",
    ASSOCIATED_FILE_SCREENING: "associated_file_screening",
    ASSOCIATED_FILE_REFERRAL: "associated_file_referral",
    PATIENT_INSURANCE_IMAGE: "patient_insurance_image",
    REFERRAL_INSURANCE_IMAGE: "referral_insurance_image",
    ANONYMOUS_INSURANCE_IMAGE: "anonymous_insurance_image",
});

export const VALID_IMAGE_EXTENSIONS = ["jpg", "jpeg", "heif", "heic", "png", "pdf"];
export const VALID_ASSOCIATED_FILE_EXTENSIONS = [...VALID_IMAGE_EXTENSIONS, "doc", "docx"];
const MAX_FILE_BYTES = 20971520;
export const PRETTY_MAX_FILE_SIZE = "20MB";

/**
 * Upload the specified insurance image file to S3. Will throw an exception if the file is invalid -
 * make sure this is handled
 * @param {File} file Reference to file we'll upload
 * @param {string} fileName Name of the file including extension
 * @param {string} entityId User file entity ID
 * @param {string} entityType Type of user file entity
 * @return {string} Base 64 encoded data string for the provided file
 */
export const uploadInsuranceImageFile = async (file, fileName, entityId, entityType) => {
    validateFileSizeAndExtension(file, VALID_IMAGE_EXTENSIONS);
    const fileKey = `backend/insurance_cards/${fileName}`;
    await uploadFile(file, entityId, entityType, fileKey);
    return getBase64encodedFileData(file);
};

/**
 * Upload the list of specified associated files to S3 and store user files for each. Will throw an
 * exception if any file is invalid - make sure this is handled
 * @param {File[]} files Reference to files we'll upload
 * @param {string} fileType Type of file, e.g., patient for patient_associated_file entityType
 * @param {string} entityId User file entity ID
 * @param {string} entityType Type of user file entity
 * @return {Object[]} List of user files
 */
export const uploadAssociatedFiles = async (files, fileType, entityId, entityType) => {
    const newFiles = [];

    for (let selectedFile of files) {
        validateFileSizeAndExtension(selectedFile, VALID_ASSOCIATED_FILE_EXTENSIONS);

        const fileName =
            "backend/" + fileType + "_files/" + entityId + "/misc/" + selectedFile.name;
        const fileInfo = {
            key: fileName,
            timestamp: moment.utc().valueOf(),
        };

        await uploadFile(selectedFile, entityId, entityType, fileName);
        const newFile = await writeToUserFileTable(entityId, entityType, fileInfo);

        newFiles.push(newFile);
    }
    return newFiles;
};

const getFileExtension = (file) => {
    const fileNameSplit = file.name.split(".");
    const extension = fileNameSplit[fileNameSplit.length - 1];
    return extension ? extension.toLowerCase() : extension;
};

const validateFileSizeAndExtension = (file, allowedExtensions) => {
    const extension = getFileExtension(file);
    if (!allowedExtensions.includes(extension)) {
        throw new Error("Invalid file type");
    } else if (file.size > MAX_FILE_BYTES) {
        throw new Error("File size too large");
    }
};

const getBase64encodedFileData = (file) => {
    let base64encodedFileData = "";
    const reader = new FileReader();
    reader.onload = (e) => {
        base64encodedFileData = e.target.result;
    };
    reader.readAsDataURL(file);
    return base64encodedFileData;
};

const uploadFile = async (selectedFile, entityId, entity_type, key) => {
    const data = {
        method: "post_object",
        entity_id: entityId,
        entity_type: entity_type,
        file_key: key,
        content_type: selectedFile.type,
    };
    const response = await api.shared.get_presigned_url({ version: "v3", data });
    const formData = new FormData();

    Object.keys(response.fields).forEach((key) => {
        formData.append(key, response.fields[key]);
    });
    formData.append("file", selectedFile);
    formData.append("Content-Type", selectedFile.type);

    await http.post(response.url, formData);
};

export async function getReportPresignedUrl(report) {
    const version = "v3";
    const data = {
        method: "get_object",
        entity_id: report["entity_id"],
        entity_type: "patient",
        file_type: report["file_type"],
        file_sub_type: report["file_sub_type"],
        file_key: report["file_key"],
        content_type: "application/pdf",
    };
    const response = await api.shared.get_presigned_url({ version, data });
    return response.message;
}

export async function getInsuranceImagePresignedUrl(entityId, fileType, fileSubType) {
    const version = "v3";
    const data = {
        method: "get_object",
        entity_id: entityId,
        entity_type: fileType.toLowerCase(),
        file_type: fileType,
        file_sub_type: fileSubType,
        content_type: "json",
    };
    return await api.shared.get_presigned_url({ version, data });
}

export async function viewReport(report) {
    const reportUrl = await getReportPresignedUrl(report);
    window.open(reportUrl);
}

export async function getScreeningAssociatedFilePresignedUrl(id, key) {
    const version = "v3";
    const data = {
        method: "get_object",
        entity_id: id,
        entity_type: SCREENING_ASSOCIATED_FILES_ENTITY_TYPE,
        file_type: ASSOCIATED_FILES_FILE_TYPE,
        file_sub_type: SCREENING_ASSOCIATED_FILES_FILE_SUB_TYPE,
        file_key: key,
        content_type: "json",
    };
    const response = await api.shared.get_presigned_url({ version, data });
    if (response && response.message) {
        return response.message;
    }
}

export async function viewScreeningAssociatedFile(id, key) {
    const reportUrl = await getScreeningAssociatedFilePresignedUrl(id, key);
    window.open(reportUrl);
}

export async function getScreeningAssociatedFiles(entity_id) {
    return await api.shared.get_files({
        entity_id: entity_id,
        entity_type: ASSOCIATED_FILES_FILE_TYPE,
        entity_sub_type: SCREENING_ASSOCIATED_FILES_ENTITY_TYPE,
    });
}

export async function getPatientAssociatedFilePresignedUrl(id, key) {
    const version = "v3";
    const data = {
        method: "get_object",
        entity_id: id,
        entity_type: PATIENT_ASSOCIATED_FILES_ENTITY_TYPE,
        file_type: ASSOCIATED_FILES_FILE_TYPE,
        file_sub_type: PATIENT_ASSOCIATED_FILES_FILE_SUB_TYPE,
        file_key: key,
        content_type: "json",
    };
    const response = await api.shared.get_presigned_url({ version, data });
    if (response && response.message) {
        return response.message;
    }
}

export async function viewPatientAssociatedFile(id, key) {
    const reportUrl = await getPatientAssociatedFilePresignedUrl(id, key);
    window.open(reportUrl);
}

export async function getPatientAssociatedFiles(entity_id) {
    return await api.shared.get_files({
        entity_id: entity_id,
        entity_type: ASSOCIATED_FILES_FILE_TYPE,
        entity_sub_type: PATIENT_ASSOCIATED_FILES_ENTITY_TYPE,
    });
}

export async function getReferralAssociatedFilePresignedUrl(id, key) {
    const version = "v3";
    const data = {
        method: "get_object",
        entity_id: id,
        entity_type: REFERRAL_ASSOCIATED_FILES_ENTITY_TYPE,
        file_type: ASSOCIATED_FILES_FILE_TYPE,
        file_sub_type: REFERRAL_ASSOCIATED_FILES_FILE_SUB_TYPE,
        file_key: key,
        content_type: "json",
    };
    const response = await api.shared.get_presigned_url({ version, data });
    if (response && response.message) {
        return response.message;
    }
}

export async function viewReferralAssociatedFile(id, key) {
    const reportUrl = await getReferralAssociatedFilePresignedUrl(id, key);
    window.open(reportUrl);
}

export async function getReferralAssociatedFiles(entity_id) {
    return await api.shared.get_files({
        entity_id: entity_id,
        entity_type: ASSOCIATED_FILES_FILE_TYPE,
        entity_sub_type: REFERRAL_ASSOCIATED_FILES_ENTITY_TYPE,
    });
}

export async function writeToUserFileTable(entityId, entityType, fileInfo) {
    const data = {
        entity_id: entityId,
        entity_type: entityType,
        file_info: fileInfo,
    };
    return await api.shared.write_file(data);
}
