// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import "../styles.scss";
import { VideoViewBase } from "../index";
import { getAutomationDataAttr } from "../../../../utils/automationUtils";

export const AllowAVStep = (props) => {
    const { startPreview } = props;

    let content = (
        <div className="position-relative GuidedVideoCall__wrapper">
            <div className="GuidedVideoCall__allow_message fw-bold text-white fs-18">
                <p>
                    Select Allow to let aptihealth use your mic and camera for calls and meetings on
                    this browser.
                </p>
                <p className="fs-14">
                    When you do, we’ll turn your devices on for a moment to set them up.
                </p>
                <span
                    {...getAutomationDataAttr("AllowAVStep_allow")}
                    className="GuidedVideoCall__center-btn  mt-5 text-center cursor"
                    onClick={startPreview}>
                    Allow
                </span>
            </div>
        </div>
    );

    return <VideoViewBase content={content} {...props} />;
};
