/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const UserAddIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="UserAdd"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.5 9.5C21.5 12.5376 19.0376 15 16 15C12.9624 15 10.5 12.5376 10.5 9.5C10.5 6.46243 12.9624 4 16 4C19.0376 4 21.5 6.46243 21.5 9.5ZM19.5 9.5C19.5 11.433 17.933 13 16 13C14.067 13 12.5 11.433 12.5 9.5C12.5 7.567 14.067 6 16 6C17.933 6 19.5 7.567 19.5 9.5Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.4009 19H12C10.311 19 9.47123 19.4178 8.99482 19.9537C8.48556 20.5266 8.02804 21.6356 8.00124 23.7979C8.03497 23.8351 8.08598 23.886 8.16096 23.9502C8.48379 24.2269 9.04889 24.5655 9.86778 24.893C11.4924 25.5429 13.7387 26 16 26C16.3822 26 16.764 25.9869 17.1424 25.9621C17.2787 26.6515 17.5094 27.3069 17.8205 27.9144C17.2197 27.9702 16.6099 28 16 28C11 28 6 26 6 24C6 19 8 17 12 17H20C20.8885 17 21.6782 17.0987 22.3694 17.307C21.248 17.6386 20.2361 18.2254 19.4009 19ZM7.94275 23.7227C7.94275 23.7227 7.94584 23.7267 7.94995 23.7348C7.94447 23.7268 7.94275 23.7227 7.94275 23.7227Z"
                />
                <path d="M24.5 19C25.0523 19 25.5 19.4477 25.5 20V23.5H29C29.5523 23.5 30 23.9477 30 24.5C30 25.0523 29.5523 25.5 29 25.5H25.5V29C25.5 29.5523 25.0523 30 24.5 30C23.9477 30 23.5 29.5523 23.5 29V25.5H20C19.4477 25.5 19 25.0523 19 24.5C19 23.9477 19.4477 23.5 20 23.5H23.5V20C23.5 19.4477 23.9477 19 24.5 19Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="UserAdd"
                {...props}>
                <path d="M16 15C19.0376 15 21.5 12.5376 21.5 9.5C21.5 6.46243 19.0376 4 16 4C12.9624 4 10.5 6.46243 10.5 9.5C10.5 12.5376 12.9624 15 16 15Z" />
                <path d="M6 24C6 19 8 17 12 17H20C20.8885 17 21.6782 17.0987 22.3694 17.307C19.2651 18.2251 17 21.098 17 24.5C17 25.7297 17.2959 26.8903 17.8205 27.9144C17.2197 27.9702 16.6099 28 16 28C11 28 6 26 6 24Z" />
                <path d="M24.5 19C25.0523 19 25.5 19.4477 25.5 20V23.5H29C29.5523 23.5 30 23.9477 30 24.5C30 25.0523 29.5523 25.5 29 25.5H25.5V29C25.5 29.5523 25.0523 30 24.5 30C23.9477 30 23.5 29.5523 23.5 29V25.5H20C19.4477 25.5 19 25.0523 19 24.5C19 23.9477 19.4477 23.5 20 23.5H23.5V20C23.5 19.4477 23.9477 19 24.5 19Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="UserAdd"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 19.25C22.2782 19.25 24.125 17.4032 24.125 15.125C24.125 12.8468 22.2782 11 20 11C17.7218 11 15.875 12.8468 15.875 15.125C15.875 17.4032 17.7218 19.25 20 19.25ZM12.5 26C12.5 22.25 14 20.75 17 20.75H23C23.6663 20.75 24.2587 20.824 24.777 20.9802C22.4488 21.6688 20.75 23.8235 20.75 26.375C20.75 27.2973 20.972 28.1677 21.3654 28.9358C20.9148 28.9777 20.4574 29 20 29C16.25 29 12.5 27.5 12.5 26ZM27.125 23C27.125 22.5858 26.7892 22.25 26.375 22.25C25.9608 22.25 25.625 22.5858 25.625 23V25.625H23C22.5858 25.625 22.25 25.9608 22.25 26.375C22.25 26.7892 22.5858 27.125 23 27.125H25.625V29.75C25.625 30.1642 25.9608 30.5 26.375 30.5C26.7892 30.5 27.125 30.1642 27.125 29.75V27.125H29.75C30.1642 27.125 30.5 26.7892 30.5 26.375C30.5 25.9608 30.1642 25.625 29.75 25.625H27.125V23Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

UserAddIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

UserAddIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
