// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import Button from "../../UI/Button";
import images from "../../../utils/images";
import { PRETTY_MAX_FILE_SIZE, VALID_ASSOCIATED_FILE_EXTENSIONS } from "../../../utils/fileUtil";

class UploadFile extends Component {
    state = { drag: false };

    dropRef = React.createRef();

    componentDidMount() {
        let div = this.dropRef.current;
        div.addEventListener("dragover", this.handleDrag);
        div.addEventListener("drop", this.handleDrop);
    }
    componentWillUnmount() {
        let div = this.dropRef.current;
        div.removeEventListener("dragover", this.handleDrag);
        div.removeEventListener("drop", this.handleDrop);
    }

    handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ drag: false });
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.onInputChange(e.dataTransfer);
            e.dataTransfer.clearData();
        }
    };

    render() {
        return (
            <div className="UploadFile">
                <div className="UploadFile__inner p-4">
                    <div className={"d-flex justify-content-between"}>
                        <h5 className="fw-bold text-left fs-17 pb-4 m-0 txt-sec">Upload File(s)</h5>
                        <div onClick={this.props.hideModal} className={"cancel-icon"}>
                            <img
                                style={{ width: 14 }}
                                className="img-fluid"
                                src={images("./icons/cross.svg")}
                                alt={"cancel"}
                            />
                        </div>
                    </div>
                    <div className={"file-drop-area"} ref={this.dropRef}>
                        <div className={"text-center mt-2 p-3 txt-gry"}>
                            Drag And Drop Your Files Anywhere
                        </div>
                        <div className={"text-center m-3"}>
                            <div className="UploadFile__controls text-center fs-15 mb-4">
                                <div className="position-relative">
                                    <Button
                                        className="Btn Btn--pri"
                                        style={{ minHeight: 35, minWidth: 150, padding: 0 }}>
                                        Select File
                                    </Button>
                                    <input
                                        type="file"
                                        name="file"
                                        onChange={(e) => this.props.onInputChange(e.target)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <UploadFileList
                        files={this.props.files}
                        onFileRemove={this.props.onFileRemove}
                        listStyle={"w-100"}
                    />
                    {this.props.onInsertFiles && (
                        <div className={"d-flex justify-content-lg-end justify-content-center"}>
                            <Button
                                disabled={this.props.files.length === 0 ? "disabled" : null}
                                className="Btn Btn--pri"
                                style={{ minHeight: 35, minWidth: 150, padding: 0 }}
                                onClick={this.props.onInsertFiles}>
                                Upload File(s)
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default UploadFile;

export const UploadFileList = ({ files, onFileRemove, listStyle }) => {
    const getFileSizeDisplay = (fileSize) => {
        const gigabyte = 1000000000;
        const megabyte = 1000000;
        const kilobyte = 1000;
        if (fileSize >= gigabyte) {
            fileSize = (fileSize / gigabyte).toFixed(1) + " GB";
        } else if (fileSize >= megabyte) {
            fileSize = (fileSize / megabyte).toFixed(1) + " MB";
        } else if (fileSize >= kilobyte) {
            fileSize = (fileSize / kilobyte).toFixed(1) + " KB";
        } else {
            fileSize = fileSize + " B";
        }
        return fileSize;
    };

    return (
        <div className={"UploadFile--selected-files-section"} style={{ minHeight: 100 }}>
            <div className="UploadFile--validation-text">
                <span className="bold">Acceptable file formats:</span>{" "}
                {VALID_ASSOCIATED_FILE_EXTENSIONS.join(", ")}
                <br />
                <span className="bold">Max file size:</span> {PRETTY_MAX_FILE_SIZE}
            </div>
            {files.length > 0 && <div className={"text-left txt-gry"}>Uploads</div>}
            <ul className={listStyle + " fs-lg-16 fs-14"}>
                {files.map((file, index) => {
                    return (
                        <li
                            key={file.name}
                            className="row border-top"
                            style={{ height: 30, lineHeight: "30px" }}>
                            <div
                                className="col-lg-8 col-6"
                                style={{ overflow: "hidden", textOverflow: "ellipsis", height: 30 }}
                                data-toggle="tooltip"
                                data-placement="top"
                                title={file.name}>
                                {file.name}
                            </div>
                            <div className="col-lg-3 col-4">{getFileSizeDisplay(file.size)}</div>
                            <div className={"col-lg-1 col-2"}>
                                <div
                                    role="button"
                                    style={{ width: 18, height: 18, lineHeight: "18px" }}
                                    onClick={() => onFileRemove(index)}
                                    className={"cancel-icon mt-1"}>
                                    <img
                                        style={{ width: 10 }}
                                        className="img-fluid"
                                        src={images("./icons/cross.svg")}
                                        alt="remove"
                                    />
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
