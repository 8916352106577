import React from "react";
import PropTypes from "prop-types";

import NumberFormat from "react-number-format";

import { createClassNameString } from "../utils";

import "./styles.scss";

export const PhoneInput = ({
    className,
    name,
    value,
    placeholder,
    disabled,
    onChange,
    error,
    ...props
}) => {
    const numberFormat = (val) => {
        let mainVal;
        let firstNumber = val.substring(0, 3);
        let middleNumber = val.substring(3, 6);
        let lastNumber = val.substring(6, 10);
        if (firstNumber.length) {
            mainVal = `(${firstNumber})`;
        }
        if (firstNumber.length && middleNumber.length) {
            mainVal = `(${firstNumber}) ${middleNumber}`;
        }
        if (firstNumber.length && middleNumber.length && lastNumber.length) {
            mainVal = `(${firstNumber}) ${middleNumber}-${lastNumber}`;
        }

        return mainVal;
    };

    return (
        <NumberFormat
            className={createClassNameString([
                "apti-CL",
                "apti-PhoneInput",
                "apti-Input",
                className,
                error && "error",
            ])}
            name={name}
            type="text"
            elementtype="input"
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onValueChange={onChange}
            format={numberFormat}
            {...props}
        />
    );
};

PhoneInput.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

PhoneInput.defaultProps = {
    className: undefined,
    name: "",
    value: undefined,
    placeholder: undefined,
    disabled: false,
    onChange: () => {},
};
