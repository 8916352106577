import React from "react";
import PropTypes from "prop-types";

import { TextTypes } from "../../../constants";
import { Text } from "../../../Text";

import "../styles.scss";
import { createClassNameString } from "../../../utils";

export const InsuranceField = ({ children, className, title }) => (
    <div className={createClassNameString(["apti-CompletedProfileInsurance--field", className])}>
        <Text type={TextTypes.label}>{title}</Text>
        {children}
    </div>
);

InsuranceField.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
};

InsuranceField.defaultProps = {
    children: <></>,
    className: undefined,
    title: "",
};
