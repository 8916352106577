// Copyright aptihealth, inc. 2019 All Rights Reserved

import * as yup from "yup";

export const PRACTICE_VALIDATION_SCHEMA = yup.object().shape({
    name: yup.string().required("Practice Name is required"),
    address: yup.string().required("Address is required"),
    general_email: yup.string().email("Enter a valid email address").required("Email is required"),
    status: yup.string().required("Status is required"),
    practice_type: yup.string().required("Practice Type is required"),
    office_manager_name: yup.string().required("Office Manager Name is required"),
    phone_number: yup
        .string()
        .matches(/^\d{10}$/, "Phone Number must be a valid US number")
        .required("Phone Number is required"),
});

export const PRACTICE_INITIAL_VALUES = {
    name: "",
    address: "",
    phone_number: "",
    fax_number: "",
    office_manager_name: "",
    general_email: "",
    status: "",
    practice_type: "",
    intake_provider_ids: "",
    default_pcp_id: "",
};

export const PRACTICE_SCHEMA = [
    {
        name: "name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Practice Name",
            placeholder: "Practice Name",
        },
    },
    {
        name: "practice_type",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "Behavioral", display: "Behavioral" },
                { value: "Primary Care", display: "Primary Care" },
            ],
            label: "Practice Type",
            placeholder: "",
        },
    },
    {
        name: "phone_number",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Phone Number",
            placeholder: "Phone Number",
        },
    },
    {
        name: "fax_number",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Fax Number",
            placeholder: "Fax Number",
        },
    },
    {
        name: "general_email",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "General Email Address",
            placeholder: "General Email Address",
        },
    },
    {
        name: "office_manager_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Office Manager Name",
            placeholder: "Office Manager Name",
        },
    },
    {
        elementType: "segment",
        displayName: "Address",
    },
    {
        name: "address.street",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Street",
            placeholder: "Enter Street",
        },
    },
    {
        name: "address.city",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "City",
            placeholder: "Enter City",
        },
    },
    {
        name: "address.state",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "State",
            placeholder: "Enter State",
        },
    },
    {
        name: "address.zip_code",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Zip",
            placeholder: "Enter zip",
        },
    },
    {
        name: "status",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "active", display: "Active" },
                { value: "inactive", display: "Inactive" },
            ],
            label: "Status",
            placeholder: "",
        },
    },
    {
        name: "intake_provider_ids",
        elementType: "react-select",
        elementConfig: {
            options: [],
            label: "Intake Coordinators",
            placeholder: "Intake Coordinators",
        },
    },
    {
        name: "practice_specialization",
        elementType: "select",
        elementConfig: {
            sentenceCase: false,
            options: [
                { value: "HCP", display: "HCP" },
                { value: "SPMI", display: "SPMI" },
                { value: "None", display: "None" },
            ],
            label: "Practice Specialization",
            placeholder: "None",
        },
    },
    {
        name: "default_pcp_id",
        elementType: "select",
        elementConfig: {
            options: [],
            label: "Default PCP",
        },
    },
];
