// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component, Fragment } from "react";
import Button from "../../../components/UI/Button";
import ProgressBar from "../../../components/UI/ProgressBar";
import { NavControls, QuestionV2 } from "../../../components/Patient/Assessment";
import {
    fetchAssessmentCrisisRisk,
    getNextQuestion,
    getPreviousQuestion,
    setSelectedOptions,
    startAssessment,
} from "../../../redux/actions/patient";

import { connect } from "react-redux";
import { getQueryParams } from "../../../utils/filters";
import { updateReduxProfile, getPatientProfile } from "../../../redux/actions/auth";
import {
    A5_ASSESSMENT_TYPE,
    A5_V2_ASSESSMENT_TYPE,
    A5R_ASSESSMENT_TYPE,
} from "../../../constants/assessments";
import SuicidePreventionPopUp from "../../../components/Screening/a5Complete/SuicidePreventionPopUp";
import { bindActionCreators } from "redux";
import { toastMessageTypes } from "../../../redux/actions/toaster";

export const SCQ = "single_choice";
export const MCQ = "multiple_choice";

class Assessment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assessmentType:
                this.props.iteration || getQueryParams("assessmentType", props.location.search),
            showCrisisRisk: false,
            assessmentId: null,
        };
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.assessmentCompleted && this.props.assessmentCompleted) {
            if (
                !["A5", A5_ASSESSMENT_TYPE, A5_V2_ASSESSMENT_TYPE, A5R_ASSESSMENT_TYPE].includes(
                    this.state.assessmentType,
                )
            ) {
                this.updateProfileAndGoHome();
            }

            const options = { params: { showLoader: true } };
            const urlParams = { screening_id: this.state.assessmentId };
            const dispatch = this.props.dispatch;
            fetchAssessmentCrisisRisk({ options, urlParams, dispatch }).then((response) => {
                if (response) {
                    this.setState({
                        showCrisisRisk: true,
                    });
                } else {
                    this.updateProfileAndGoHome();
                }
            });
        }

        if (prevProps.location.search !== this.props.location.search) {
            await this.setState({
                assessmentType:
                    this.props.iteration ||
                    getQueryParams("assessmentType", this.props.location.search),
            });
            this.componentDidMount();
        }
    }

    errorTypeConfig = {
        NoActiveAssessmentError: {
            toastErrorType: toastMessageTypes.warning,
            errorCallback: () => this.props.history.push("/app/home"),
        },
    };

    componentDidMount() {
        if (this.props.profile.status === "INACTIVE") {
            this.updateProfileAndGoHome();
            return;
        }
        this.props.startAssessment(this.state.assessmentType, this.errorTypeConfig);
    }

    handleNext = () => {
        let requestBody = this.makeNextQuestionRequest();
        this.props.getNextQuestion(requestBody, this.state.assessmentType);
        if (!this.state.assessmentId) {
            this.setState({ assessmentId: requestBody.assessment_id });
        }
    };

    handlePrev = () => {
        this.props.getPreviousQuestion(this.state.assessmentType);
    };
    // getPercentage = (percentage) => {
    //     let percentageText
    //     if (percentage < 20) {
    //         percentageText = '0% Complete'
    //     } else if (percentage >= 20 && percentage < 40) {
    //         percentageText = '20% Complete'
    //     } else if (percentage >= 40 && percentage < 60) {
    //         percentageText = '40% Complete'
    //     } else if (percentage >= 60 && percentage < 80) {
    //         percentageText = '60% Complete'
    //     } else if (percentage >= 80 && percentage < 100) {
    //         percentageText = '80% Complete'
    //     } else {
    //         percentageText = '100% Complete'
    //     }
    //     return percentageText;
    // }
    makeNextQuestionRequest = () => {
        let currentQ = this.props.currentQuestion;
        let requestBody = {
            question_id: currentQ.question_id,
            assessment_type: currentQ.assessment_type,
            assessment_id: currentQ.assessment_id,
            options: this.constructSelectedOptions(),
        };
        return requestBody;
    };

    constructSelectedOptions = () => {
        let selectedOptionsPure = this.props.currentQuestion.options.filter((el, i) => {
            let found =
                this.props.selectedOptions.find((index) => {
                    return index === el.index;
                }) !== undefined;
            return found;
        });
        return selectedOptionsPure;
    };

    handleSingleOptionQuestion = (singleOption) => {
        let requestBody = this.makeNextQuestionRequest();
        requestBody["options"] = [singleOption];
        this.props.getNextQuestion(requestBody, this.props.iteration);
        if (!this.state.assessmentId) {
            this.setState({ assessmentId: requestBody.assessment_id });
        }
    };

    render() {
        return (
            <section className="Assessment">
                {this.props.currentQuestion && (
                    <Fragment>
                        <div className="container">
                            <div className="my-4">
                                {/* <span>{this.getPercentage(this.props.assessmentPerc)}</span> */}
                                <span>Progress</span>
                            </div>
                            <ProgressBar now={this.props.assessmentPerc} height="12px" />
                            <QuestionV2
                                currentQuestion={this.props.currentQuestion}
                                selectOption={(option) => {
                                    if (this.props.currentQuestion.type === "single_choice") {
                                        this.handleSingleOptionQuestion(option);
                                    } else {
                                        optionSelectHandler(option, this);
                                    }
                                }}
                                selectedOptions={this.props.selectedOptions}
                            />
                        </div>
                        <NavControls
                            isLast={this.props.isLastQuestion}
                            questionId={this.props.currentQuestion.question_id}
                            selectedOptions={this.props.selectedOptions}
                            next={this.handleNext}
                            prev={this.handlePrev}
                        />
                    </Fragment>
                )}
                {!this.props.currentQuestion && !this.props.hasAssessmentAssigned && (
                    <div
                        className={"text-align-center d-flex justify-content-center"}
                        style={{ height: 500 }}>
                        <div style={{ height: 160, alignSelf: "center" }}>
                            <div className={"fw-bold txt-pri fs-22 mb-3"}>
                                Oops! You’ve already submitted your assessment.
                            </div>
                            <div className={"txt-gry mb-4"}>
                                Click below to navigate back to your home screen and complete any
                                remaining tasks.
                            </div>
                            <Button
                                className={"Btn Btn--pri"}
                                type={"button"}
                                onClick={() => this.props.history.push("/app/home")}>
                                Back to Home
                            </Button>
                        </div>
                    </div>
                )}
                {this.state.showCrisisRisk && (
                    <SuicidePreventionPopUp
                        onCloseHandler={() => {
                            this.updateProfileAndGoHome();
                        }}
                    />
                )}
            </section>
        );
    }

    async updateProfileAndGoHome() {
        await this.props.getPatientProfile(true);
        this.props.history.push("/app/home");
    }
}

const mapStateToProps = (state) => {
    return {
        currentQuestion: state.patient.currentQuestion,
        assessmentPerc: state.patient.assessmentPerc,
        isLastQuestion: state.patient.isLastQuestion,
        selectedOptions: state.patient.selectedOptions,
        lastSelectedOption: state.patient.lastSelectedOption,
        startRatingScale: state.patient.startRatingScale,
        hasAssessmentAssigned: state.patient.hasAssessmentAssigned,
        assessmentCompleted: state.patient.assessmentCompleted,
        profile: state.auth.profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        ...bindActionCreators(
            {
                startAssessment,
                getNextQuestion,
                getPreviousQuestion,
                setSelectedOptions,
                updateReduxProfile,
                getPatientProfile,
            },
            dispatch,
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Assessment);

// toggle option state
export const optionSelectHandler = (option, that) => {
    let oldSelectedOptions = that.props.selectedOptions.slice();
    let index = null;
    let foundOption = oldSelectedOptions.find((optionIndex, i) => {
        index = i;
        return optionIndex == option.index;
    });
    let isNoneType = option.type && option.type == "None";
    let isSCQ = that.props.currentQuestion.type == SCQ;
    let isLastSelectedNoneType =
        that.props.lastSelectedOption &&
        that.props.lastSelectedOption.type &&
        that.props.lastSelectedOption.type == "None";

    if (isSCQ || isNoneType || isLastSelectedNoneType) {
        foundOption !== undefined
            ? (oldSelectedOptions = [])
            : (oldSelectedOptions = [option.index]);
    } else {
        foundOption !== undefined
            ? oldSelectedOptions.splice(index, 1)
            : oldSelectedOptions.push(option.index);
    }
    that.props.setSelectedOptions(oldSelectedOptions, option);
};
