import React from "react";
import "../styles.scss";
import { createClassNameString } from "../../utils";

export const FormFieldContainer = ({ title, subTitle, className, children }) => {
    return (
        <div className={createClassNameString(["FormField-container", className])}>
            {(title || subTitle) && (
                <div data-public className="FormField-header_container">
                    {title && <h3 className="FormField-title">{title}</h3>}
                    {subTitle && <h3 className="FormField-subTitle">{subTitle}</h3>}
                </div>
            )}
            {children}
        </div>
    );
};
