import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";

export const FormFieldError = ({ children, otherErrors = [] }) => {
    return (
        <p data-public className="FormFieldError-text">
            {/* if there is a child prioritize that error */}
            {children && children}
            {/* if there is not a child display the first other error */}
            {!children && otherErrors.length > 0 && otherErrors[0]}
        </p>
    );
};

FormFieldError.propTypes = {
    children: PropTypes.string,
};
