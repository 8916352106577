// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import "./Dashboard.scss";
import Patient from "./Patient";
import Provider from "./Providers";
import Admin from "./Admin";
import { withRouter } from "react-router-dom";

import {
    admin_admin,
    admin_bp,
    admin_cse,
    admin_csm,
    provider_behavioral,
    provider_pcp,
    provider_prescribe,
    provider_case_manager,
    provider_peer,
    system_workflow,
    user_patient,
} from "../../config/roles";
import WorkflowRouter from "./Workflow";

// here the role will decide which roles dashboard shall beb loaded

class Dashboard extends Component {
    component = null;

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.location.pathname !== "/app/schedule" &&
            this.props.location.pathname === "/app/schedule"
        ) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        switch (this.props.role) {
            case admin_admin:
            case admin_bp:
            case admin_cse:
            case admin_csm:
                this.component = <Admin url={this.props.match.url} />;
                break;
            case user_patient:
                this.component = <Patient url={this.props.match.url} />;
                break;
            case provider_pcp:
            case provider_behavioral:
            case provider_prescribe:
            case provider_case_manager:
            case provider_peer:
                this.component = <Provider url={this.props.match.url} />;
                break;
            case system_workflow:
                this.component = <WorkflowRouter url={this.props.match.url} />;
                break;
            default:
                this.component = <Patient url={this.props.match.url} />;
        }

        return <div className="Dashboard-container">{this.component}</div>;
    }
}

export default withRouter(Dashboard);
