import React, { useEffect, useState } from "react";
import images from "../../../../utils/images";
import Button from "../../../UI/Button";

const ScreeningCard = (props) => {
    const { title, message, submitText, cancelText, submitHandler, cancelHandler } = props;
    const renderMessages = message.map((message, index) => {
        const className = index === 0 ? "fs-14" : "mt-2 fs-14";
        return <div className={className}>{message}</div>;
    });

    const submitFn = submitHandler ? submitHandler : () => {};
    const cancelFn = cancelHandler ? cancelHandler : () => {};

    const submitClasses = cancelHandler
        ? "Btn Btn--pri Btn--sm-2 workflow-btn"
        : "Btn Btn--pri Btn--sm-2 workflow-btn Workflow__btn-Screening-home";

    return (
        <>
            <div className={"p-lg-5 p-3"}>
                <div className={"fs-22 txt-pri fw-bold mb-5 text-center"}>{title}</div>
                <div className={"text-center my-5"}>
                    <img
                        src={images("./common/workflow/eligibility-check-active.png")}
                        alt={"Eligibility Check"}
                    />
                </div>
                <div className={"workflow-container-sm font-color-gray"}>{renderMessages}</div>
            </div>
            <div className="workflow-container-sm d-flex justify-content-between">
                {cancelText && (
                    <Button
                        type={"button"}
                        onClick={cancelFn}
                        className={"Btn Btn--otl-gry Btn--sm-2 workflow-btn"}>
                        {cancelText}
                    </Button>
                )}
                <Button type={"button"} className={submitClasses} onClick={submitFn}>
                    {submitText}
                </Button>
            </div>
        </>
    );
};

export default ScreeningCard;
