import "./styles.scss";
import React from "react";
import { Button, ButtonTypes, CustomForm } from "../../../../../component-library";
import CloseOnOutsideClick from "../../../../Common/CloseOnOutsideClick/CloseOnOutsideClick";
import {
    COMMENT_CHAR_LIMIT,
    PAYMENT_MODAL_INITIAL_VALUES,
    PAYMENT_MODAL_VALIDATION,
} from "./PaymentModalSchema";
import { FormCurrencyField } from "../../../../../component-library/FormFields/FormCurrencyField/FormCurrencyField";
import { FormTextArea } from "../../../../../component-library/FormFields/FormTextArea/FormTextArea";
import { Form } from "formik";
import PropTypes from "prop-types";
import { FormFieldError } from "../../../../../component-library/FormFields/FormFieldError/FormFieldError";
import { CharacterCounter } from "../../../../../component-library/CharacterCounter/CharacterCounter";
import { convertNumberToCurrency } from "../../../../../utils/typeUtils";
import paymentRequests from "../../../../../APIRequests/payment";
import { useDispatch } from "react-redux";
import { addToast, toastMessageTypes } from "../../../../../redux/actions/toaster";

export const PaymentModal = ({ isOpen, setIsOpen, patientId, updateTransactionHistory }) => {
    const dispatch = useDispatch();

    const preventEnterFormSubmission = (event) => {
        if (event.key === "Enter" || event.code === "Enter") {
            event.preventDefault();
        }
    };

    const getTitle = (amount) => {
        const amountNumber = Number(amount);
        if (isNaN(amountNumber) || amount === "") {
            return "New";
        }
        return `$${convertNumberToCurrency(amountNumber)}`;
    };

    const getChargeAmount = (amount) => {
        const amountNumber = Number(amount);
        if (isNaN(amountNumber) || amount === "") {
            return "";
        }
        return ` $${convertNumberToCurrency(amountNumber)}`;
    };

    const renderForm = (formikProps) => {
        const amount = formikProps.values["amount"];

        return (
            <CloseOnOutsideClick
                className="PaymentModal-content"
                setShowComponent={(value) => {
                    formikProps.resetForm(formikProps);
                    setIsOpen(value);
                }}>
                <Form onKeyDown={preventEnterFormSubmission} noValidate={true}>
                    <div className="PaymentModal-form">
                        <h1 data-public className="PaymentModal-title">
                            {getTitle(amount)} Payment
                        </h1>
                        <FormCurrencyField
                            name="amount"
                            title="Amount"
                            className="PaymentModal-amount_input"
                            formikProps={formikProps}
                        />
                        <FormTextArea
                            name="comment"
                            title={
                                <>
                                    Comment <p className="PaymentModal-optional_text">(Optional)</p>
                                </>
                            }
                            className="PaymentModal-comment_input"
                            customError={(value, errorMessage, isTouched) => {
                                return (
                                    <div className="PaymentModal-comment_input-feedback_container">
                                        {errorMessage && isTouched && (
                                            <FormFieldError>{errorMessage}</FormFieldError>
                                        )}
                                        <CharacterCounter
                                            className="PaymentModal-comment_input-character_counter"
                                            length={value?.length}
                                            characterLimit={COMMENT_CHAR_LIMIT}
                                        />
                                    </div>
                                );
                            }}
                            formikProps={formikProps}
                        />
                        <div data-public className="PaymentModal-button_wrapper">
                            <Button
                                onClick={() => {
                                    formikProps.resetForm(formikProps);
                                    setIsOpen(false);
                                }}
                                className="PaymentModal-button PaymentModal-cancel_button"
                                buttonType={ButtonTypes.primaryOutlineV2}>
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                style={{ flexGrow: 1 }}
                                className="PaymentModal-button PaymentModal-submit_button"
                                buttonType={ButtonTypes.primaryV2}>
                                Charge{getChargeAmount(amount)}
                            </Button>
                        </div>
                    </div>
                </Form>
            </CloseOnOutsideClick>
        );
    };
    return (
        <>
            {isOpen && (
                <div className="PaymentModal-container">
                    <CustomForm
                        initialValues={PAYMENT_MODAL_INITIAL_VALUES}
                        validationSchema={PAYMENT_MODAL_VALIDATION}
                        validateOnChange={true}
                        validateOnBlur={true}
                        render={renderForm}
                        onSubmit={async (formData) => {
                            // transform string to number
                            const amount = Number(formData["amount"]);

                            const data = {
                                ...formData,
                                amount,
                                patient_id: patientId,
                            };

                            try {
                                const result = await paymentRequests.manualCharge({
                                    options: { params: { showLoader: true } },
                                    data,
                                });

                                // Refetch data only if payment succeeds.
                                if (result) {
                                    updateTransactionHistory();
                                }

                                dispatch(
                                    addToast({
                                        message: result.message,
                                        messageType: toastMessageTypes.success_v2,
                                    }),
                                );
                            } finally {
                                setIsOpen(false);
                            }
                        }}
                    />
                </div>
            )}
        </>
    );
};

PaymentModal.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    patientId: PropTypes.string,
    updateTransactionHistory: PropTypes.func,
};
