// Copyright aptihealth, inc. 2021 All Rights Reserved
import images from "../../../../utils/images";
import React from "react";
import Button from "../../../UI/Button";
import { withRouter } from "react-router-dom";
import { workflowSetPassword } from "../../../../redux/actions/auth";
import { connect } from "react-redux";
import { showAlertWithAction } from "../../../../redux/actions/alerts";

const MemberAgreement = (props) => {
    const submitHandler = async () => {
        const options = { params: { showLoader: true } };
        const data = {
            id: props.id,
            password: props.password,
        };
        try {
            props.workflowSetPassword({ options, data });
        } catch (e) {
            console.log(e);
            props.showAlertWithAction("Error setting password");
        }
    };

    return (
        <>
            <div className={"fs-22 txt-pri fw-bold mb-5 text-center"}>Member Agreement</div>
            <div className={"text-center my-5"}>
                <img
                    src={images("./common/workflow/member-agreement.png")}
                    alt={"Member Agreement"}
                />
            </div>
            <div className={"workflow-container-sm"}>
                <p className={"fs-16"}>
                    By clicking the "Agree & Continue" button below, I consent by electronic
                    signature to receive marketing and other calls, text messages (including SMS and
                    MMS messages), and other electronic messages (including those made using an
                    automatic telephone dialing system or an artificial or prerecorded voice) from
                    aptihealth, healthcare providers with whom I am matched by aptihealth, or any of
                    their agents or representatives at the phone number I have provided even if that
                    number is registered on a corporate, state, or national do not call registry. I
                    understand that my consent to such calls and messages is not required to use
                    aptihealth's services and that I may contact aptihealth at{" "}
                    <span className={"support-email fw-bold"}>support@aptihealth.com</span> or
                    888-454-3827 about its services at any time. I also understand that I may revoke
                    my consent at any time.
                </p>
                <div className="d-flex justify-content-center">
                    <Button onClick={submitHandler} className={"Btn Btn--pri workflow-btn-lg"}>
                        Agree & Continue
                    </Button>
                </div>
            </div>
        </>
    );
};

export default connect(null, { workflowSetPassword, showAlertWithAction })(
    withRouter(MemberAgreement),
);
