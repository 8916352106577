// Copyright aptihealth, inc. 2019 All Rights Reserved
import { http, httpAuth } from "../utils/axios";

const obfuscationRequests = {
    obfuscationGetWithUsername: ({ options, data }) => {
        let conf = {
            method: "post",
            url: "/v1/obfuscation/get_with_username",
            data,
            ...options,
        };
        return http(conf);
    },
    obfuscationCreate: ({ options, data }) => {
        let conf = {
            method: "post",
            url: "/v1/obfuscation/create",
            data,
            ...options,
        };
        return httpAuth(conf);
    },
};

export default obfuscationRequests;
