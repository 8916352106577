// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import CardSecondary from "../../UI/Card/CardSecondary";
import images from "../../../utils/images";
import Button from "../../UI/Button";
import { CardHeading } from "../../UI/Headings";

class AdmitPatient extends Component {
    render() {
        return (
            <div>
                <CardSecondary className="w-100 PatientProfile__AdmitPatient-wpr PatientProfile__CardBetweenMargin mx-lg-auto py-4 d-lg-flex d-block justify-content-between align-items-center px-3 px-lg-4">
                    <div className="d-flex align-items-center">
                        <img
                            src={images("./common/admitPatient.svg")}
                            alt="90 Days Plan"
                            className="PatientProfile__90DaysPlan img-fluid"
                            width="39px"
                        />
                        <CardHeading
                            text="Admit Patient to 90 Day Care Program"
                            className="d-lg-inline-block ml-2 pl-2 mr-0 mr-lg-5 fs-16 mb-0"
                        />
                    </div>
                    <div className="ml-auto mt-3 mt-lg-0">
                        <div className="d-block text-right">
                            <Button
                                className="Btn Btn--pri Btn-sm px-5"
                                onClick={this.props.popupHandler}>
                                Admit Patient
                            </Button>
                        </div>
                    </div>
                </CardSecondary>
            </div>
        );
    }
}

export default AdmitPatient;
