// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import Label from "../Label";
import { Field, getIn } from "formik";
import InputError from "../InputError";
import NumberFormat from "react-number-format";
import { formatMobileNumber } from "../../../utils/formInputUtils";

const MobileInput = (props) => {
    const { formEl, errors, touched, disabled, inputClasses } = props;

    let errorData = getIn(errors, formEl.name);
    let isTouched = getIn(touched, formEl.name);
    const className = `Input ${errorData && isTouched ? "is-invalid" : ""} ${
        inputClasses ? inputClasses : ""
    }`;

    const handleChange = (values, setFieldValue, setFieldTouched) => {
        const { value, formattedValue } = values;
        setFieldValue(formEl.name, value);
        setFieldTouched(formEl.name);
    };

    return (
        <div className={"FormGroup"}>
            <Label>{formEl.elementConfig.label}</Label>
            <Field
                name={formEl.name}
                render={({ form, field }) => {
                    const { value, onBlur, name } = field;
                    const { setFieldValue, setFieldTouched } = form; // `FormikBag`
                    return (
                        <NumberFormat
                            name={name}
                            onBlur={onBlur}
                            value={value}
                            onValueChange={(values) =>
                                handleChange(values, setFieldValue, setFieldTouched)
                            }
                            format={formatMobileNumber}
                            className={className}
                            placeholder={formEl.elementConfig.placeholder}
                            disabled={disabled}
                        />
                    );
                }}
            />

            {errorData && isTouched ? <InputError>{errorData}</InputError> : null}
        </div>
    );
};

export default MobileInput;
