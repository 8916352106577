import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";

export const TutorialPortal = ({
    className,
    title,
    subText,
    mobileSubText,
    children,
    secondaryButtonText,
    onSecondarySubmit,
    arrowPosition,
    secondaryButtonTextMobile,
    ...props
}) => {
    if (!mobileSubText) {
        mobileSubText = subText;
    }

    return (
        <div className={createClassNameString(["apti-TutorialPortal", className, arrowPosition])}>
            <p className={"apti-TutorialPortal--title"}>{title}</p>
            <p className={"apti-TutorialPortal--subText"}>{subText}</p>
            <p className={"apti-TutorialPortal--mobileSubText"}>{mobileSubText}</p>
            <div>
                {children}
                {secondaryButtonText && (
                    <p className={"apti-TutorialPortal--skip"} onClick={onSecondarySubmit}>
                        {secondaryButtonText}
                    </p>
                )}
                {secondaryButtonTextMobile && (
                    <p className={"apti-TutorialPortal--skip--mobile"} onClick={onSecondarySubmit}>
                        {secondaryButtonTextMobile}
                    </p>
                )}
            </div>
        </div>
    );
};

TutorialPortal.propTypes = {
    children: PropTypes.element,
    title: PropTypes.string,
    subText: PropTypes.string,
    mobileSubText: PropTypes.string,
    primaryButtonComponent: PropTypes.element,
    secondaryButtonText: PropTypes.string,
    secondaryButtonTextMobile: PropTypes.string,
    onSecondarySubmit: PropTypes.func,
    arrowPosition: PropTypes.string,
};

TutorialPortal.defaultProps = {
    children: <></>,
    className: undefined,
    title: "",
    subText: "",
    mobileSubText: null,
    secondaryButtonText: "",
    secondaryButtonTextMobile: "",
    onSecondarySubmit: () => {},
    arrowPosition: "null",
};
