// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import CardSecondary from "../../UI/Card/CardSecondary";
import ProgressBar from "../../UI/ProgressBar";
import moment from "moment";

export const getPercentage = (remainingTime) => {
    let percentagetext;
    if (remainingTime != null) {
        let passedTime = 90 - remainingTime;
        percentagetext = Math.floor((100 / 90) * passedTime);
    }
    return percentagetext;
};

export const getMemberProgress = (patientDetails) => {
    let startStr = "",
        endStr = "";
    let daysCompleted = 0,
        remainingDays = 90;
    if (
        patientDetails &&
        patientDetails.hasOwnProperty("ninety_day_start_date") &&
        patientDetails.hasOwnProperty("ninety_day_last_day")
    ) {
        startStr = patientDetails.ninety_day_start_date.split(" ")[0];
        endStr = patientDetails.ninety_day_last_day.split(" ")[0];
        const start = moment(patientDetails.ninety_day_start_date, "MM/DD/YYYY HH:mm:ss");
        daysCompleted = moment().diff(start, "days");
        if (daysCompleted > 90) {
            daysCompleted = 90;
        }
        remainingDays = 90 - daysCompleted;
    }
    return { startStr, endStr, daysCompleted, remainingDays };
};

export const PatientProfile90DayProgress = (props) => {
    let { startStr, endStr, daysCompleted, remainingDays } = getMemberProgress(
        props.patientDetails,
    );
    const daySuffix =
        daysCompleted % 10 === 1 && daysCompleted !== 11
            ? "st"
            : daysCompleted % 10 === 2 && daysCompleted !== 12
            ? "nd"
            : daysCompleted % 10 === 3 && daysCompleted !== 13
            ? "rd"
            : "th";
    return (
        <CardSecondary
            className="PatientProfile__CardBetweenMargin my-5 mx-lg-auto py-4 px-3 px-lg-4"
            style={{ maxWidth: "100%" }}>
            <div className={"row"}>
                <div className={"col-lg-3 col-12 PatientProfile__90DayProgressTitle"}>
                    <b>90 Day Care Program</b>
                </div>
                <div className={"col-lg-9 col-12"}>
                    <ProgressBar
                        height="6px"
                        now={getPercentage(remainingDays)}
                        currentMarker={daysCompleted}
                        currentMarkerTitle={`${daysCompleted}${daySuffix} Day`}
                    />
                    <div className="d-flex justify-content-between my-2">
                        <div>
                            <span>
                                <b>Start Date</b>
                            </span>
                            <br />
                            <span>{startStr}</span>
                        </div>
                        <div style={{ textAlign: "right" }}>
                            <span>
                                <b>End Date</b>
                            </span>
                            <br />
                            <span>{endStr}</span>
                        </div>
                    </div>
                </div>
            </div>
        </CardSecondary>
    );
};
