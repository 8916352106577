/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const UserIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="User"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.5 9.5C21.5 12.5376 19.0376 15 16 15C12.9624 15 10.5 12.5376 10.5 9.5C10.5 6.46243 12.9624 4 16 4C19.0376 4 21.5 6.46243 21.5 9.5ZM19.5 9.5C19.5 11.433 17.933 13 16 13C14.067 13 12.5 11.433 12.5 9.5C12.5 7.567 14.067 6 16 6C17.933 6 19.5 7.567 19.5 9.5Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 17H12C8 17 6 19 6 24C6 26 11 28 16 28C21 28 26 26 26 24C26 19 24 17 20 17ZM8.99482 19.9537C9.47123 19.4178 10.311 19 12 19H20C21.689 19 22.5288 19.4178 23.0052 19.9537C23.5144 20.5266 23.972 21.6356 23.9988 23.7979C23.965 23.8351 23.914 23.886 23.839 23.9502C23.5162 24.2269 22.9511 24.5655 22.1322 24.893C20.5076 25.5429 18.2613 26 16 26C13.7387 26 11.4924 25.5429 9.86778 24.893C9.04889 24.5655 8.48379 24.2269 8.16096 23.9502C8.08598 23.886 8.03497 23.8351 8.00124 23.7979C8.02804 21.6356 8.48556 20.5266 8.99482 19.9537ZM24.0572 23.7227C24.0572 23.7227 24.0555 23.7268 24.0501 23.7348C24.0542 23.7267 24.0572 23.7227 24.0572 23.7227ZM7.94275 23.7227C7.94275 23.7227 7.94584 23.7267 7.94995 23.7348C7.94447 23.7268 7.94275 23.7227 7.94275 23.7227Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="User"
                {...props}>
                <path d="M16 15C19.0376 15 21.5 12.5376 21.5 9.5C21.5 6.46243 19.0376 4 16 4C12.9624 4 10.5 6.46243 10.5 9.5C10.5 12.5376 12.9624 15 16 15Z" />
                <path d="M6 24C6 19 8 17 12 17H20C24 17 26 19 26 24C26 26 21 28 16 28C11 28 6 26 6 24Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="User"
                {...props}>
                <g>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM24.125 15.125C24.125 17.4032 22.2782 19.25 20 19.25C17.7218 19.25 15.875 17.4032 15.875 15.125C15.875 12.8468 17.7218 11 20 11C22.2782 11 24.125 12.8468 24.125 15.125ZM17 20.75C14 20.75 12.5 22.25 12.5 26C12.5 27.5 16.25 29 20 29C23.75 29 27.5 27.5 27.5 26C27.5 22.25 26 20.75 23 20.75H17Z"
                    />
                </g>
            </IconWrapper>
        );
    }

    return null;
};

UserIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

UserIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
