import React from "react";
import withRouter from "react-router-dom/es/withRouter";
import _ from "lodash";
import { GuidedSessionReconnect } from "../../../../component-library";
import { api } from "../../../../APIRequests";
import { connect } from "react-redux";

const ReconnectStep = (props) => {
    const { startPreview, callDetails, profile } = props;

    const { callId, patient_username, provider_username } = callDetails;

    const { pcp_practice_id } = profile;

    if (!callDetails) {
        return <></>;
    }

    const handleSessionComplete = () => {
        if (_.get(callDetails, "post_session_survey")) {
            props.history.push("/app/home");
        } else {
            props.history.push(`/app/video-call/ratings?callId=${callDetails["callId"]}`);
        }
    };

    let recordedMetric = {
        pk: "session_reconnect",
        entity_id: callId,
        entity_type: "call",
        segments: [
            {
                segment_id: patient_username,
                segment_type: "patient",
            },
            {
                segment_id: provider_username,
                segment_type: "provider",
            },
            {
                segment_id: pcp_practice_id,
                segment_type: "practice",
            },
        ],
    };

    const onClickReconnect = () => {
        recordedMetric.rank = "session_reconnect";
        api.metrics.create_metric(recordedMetric);

        startPreview();
    };

    const onClickDone = () => {
        recordedMetric.rank = "session_done";
        api.metrics.create_metric(recordedMetric);

        handleSessionComplete();
    };

    return (
        <div className={"d-flex justify-content-center align-items-center my-4"}>
            <GuidedSessionReconnect
                buttonLeftClick={onClickReconnect}
                buttonRightClick={onClickDone}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};
export default connect(mapStateToProps)(withRouter(ReconnectStep));
