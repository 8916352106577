import PropTypes from "prop-types";
import React from "react";

import { Image } from "../Image";
import { Text } from "../Text";
import { TextColors } from "../constants";
import { createClassNameString } from "../utils";

import "./styles.scss";

export const Footer = ({ ...props }) => {
    const currentYear = new Date().getFullYear();

    const privacyPolicy = { label: "Privacy Policy", link: "https://www.aptihealth.com/privacy" };
    const termsAndConditions = {
        label: "Terms and Conditions of Use",
        link: "https://www.aptihealth.com/terms-and-conditions/",
    };
    const copyright = { label: `© ${currentYear} AptiHealth`, link: null };
    const news = { label: "News", link: "https://www.aptihealth.com/news/" };
    const contact = { label: "Contact", link: "https://www.aptihealth.com/contact/ " };
    const androidApp = {
        label: "Android App",
        link: "https://play.google.com/store/apps/details?id=com.aptihealth.aptihealth",
    };
    const iosApp = {
        label: "iOS App",
        link: "https://apps.apple.com/us/app/aptihealth/id1477170874",
    };

    const licenseSection = [privacyPolicy, termsAndConditions];
    const copyrightNewsSection = [copyright, androidApp, iosApp, contact, news];

    let logoSrc, iconLinks;

    switch (props.contentcolor) {
        case "white":
            logoSrc = "icons/aptihealth_footer_logo_1_white.svg";
            iconLinks = [
                {
                    icon: "icons/facebook_icon_white.svg",
                    link: "https://www.facebook.com/aptihealth/",
                    alt: "AptiHealth Facebook",
                },
                {
                    icon: "icons/instagram_icon_white.svg",
                    link: "https://www.instagram.com/aptihealth/",
                    alt: "AptiHealth Instagram",
                },
                {
                    icon: "icons/twitter_icon_white.svg",
                    link: "https://twitter.com/aptihealth/",
                    alt: "AptiHealth Twitter",
                },
            ];
            break;
        default:
            logoSrc = "icons/aptihealth_footer_logo_1.svg";
            iconLinks = [
                {
                    icon: "icons/facebook_icon_grey.svg",
                    link: "https://www.facebook.com/aptihealth/",
                    alt: "AptiHealth Facebook",
                },
                {
                    icon: "icons/instagram_icon_grey.svg",
                    link: "https://www.instagram.com/aptihealth/",
                    alt: "AptiHealth Instagram",
                },
                {
                    icon: "icons/twitter_icon_grey.svg",
                    link: "https://twitter.com/aptihealth/",
                    alt: "AptiHealth Twitter",
                },
            ];
    }

    const renderTextLinks = (item, index, list) => {
        let onClick;
        if (item.link) {
            onClick = () => window.open(item.link, "_blank");
        }
        const cursorClass = onClick ? "cursor" : "";
        const textClass = onClick ? "apti-Footer--link" : "apti-Footer--text";
        const textProps = {
            color: TextColors[props.contentcolor],
            onClick,
        };
        if (index !== list.length - 1) {
            return (
                <>
                    <Text
                        className={createClassNameString([cursorClass, textClass])}
                        children={item.label}
                        key={item.label}
                        {...textProps}
                    />
                    <Text
                        className={createClassNameString(["apti-Footer--link-divider"])}
                        children="|"
                        key={item.label + "_divider"}
                        {...textProps}
                    />
                </>
            );
        } else {
            return (
                <Text
                    className={createClassNameString([cursorClass, textClass])}
                    children={item.label}
                    key={item.label}
                    {...textProps}
                />
            );
        }
    };

    const renderImageLinks = (item) => {
        let onClick;
        if (item.link) {
            onClick = () => window.open(item.link, "_blank");
        }
        const cursorClass = onClick ? "apti-Footer--cursor" : "";

        return (
            <Image
                className={createClassNameString([cursorClass, "apti-Footer--icon"])}
                key={item.alt}
                src={item.icon}
                alt={item.alt}
                onClick={onClick}
            />
        );
    };

    const renderDesktop = (
        <div className="apti-Footer" key="apti_footer" {...props} data-public="true">
            <div className="apti-Footer--left">
                <div className="apti-Footer--logo">
                    <Image src={logoSrc} />
                </div>
                <div className="apti-Footer--icon-container">{iconLinks.map(renderImageLinks)}</div>
            </div>
            <div className="apti-Footer--right">
                <div className="apti-Footer--license-section">
                    {licenseSection.map(renderTextLinks)}
                </div>
                <div className="apti-Footer--copyright-news-section">
                    {copyrightNewsSection.map(renderTextLinks)}
                </div>
            </div>
        </div>
    );

    const renderMobile = (
        <div className="apti-Footer--mobile" key="apti_footer_mobile">
            <div className="apti-Footer--mobile-logo" key="aptihealth_logo">
                <Image src={logoSrc} />
            </div>
            <div className="apti-Footer--mobile-element" key="social_icons">
                {iconLinks.map(renderImageLinks)}
            </div>
            <div className="apti-Footer--mobile-element" key="terms_and_conditions">
                {[termsAndConditions].map(renderTextLinks)}
            </div>
            <div className="apti-Footer--mobile-element" key="privacy_policy">
                {[privacyPolicy].map(renderTextLinks)}
            </div>
            <div className="apti-Footer--mobile-element" key="news_contact">
                {[news, contact, iosApp, androidApp].map(renderTextLinks)}
            </div>
            <div className="apti-Footer--mobile-element" key="copyright">
                {[copyright].map(renderTextLinks)}
            </div>
        </div>
    );

    return (
        <footer>
            {renderDesktop}
            {renderMobile}
        </footer>
    );
};

Footer.propTypes = {
    contentcolor: PropTypes.oneOf(["white", "grey"]),
};

Footer.defaultProps = {
    contentcolor: "grey",
};
