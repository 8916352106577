// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as yup from "yup";

export const INITIAL_CLINICAL_NOTES_VALIDATION_SCHEMA = yup.object().shape({
    difficulties_description: yup.string().required("Required"),
    difficulties_start: yup.string().required("Required"),
    difficulties_causation: yup.string().required("Required"),
    difficulties_change: yup.string().required("Required"),
    difficulties_impact: yup.string().required("Required"),
    difficulties_affect_others: yup.string().required("Required"),
    past_solutions: yup.string().required("Required"),
    past_solutions_success: yup.string().required("Required"),
    past_solutions_fail: yup.string().required("Required"),
    motivation_to_solve: yup.string().required("Required"),
    solution_requirements: yup.string().required("Required"),
    post_success_changes: yup.string().required("Required"),
    current_medications: yup.string().required("Required"),
    medication_duration: yup.string().required("Required"),
    medication_success: yup.string().required("Required"),
    medication_side_effects: yup.string().required("Required"),
    past_medications: yup.string().required("Required"),
    current_past_comparison: yup.string().required("Required"),
    current_no_comparison: yup.string().required("Required"),
    additional_info: yup.string().required("Required"),
});

export const OUTCOME_CLINICAL_NOTES_VALIDATION_SCHEMA = yup.object().shape({
    difficulties_description: yup.string().required("Required"),
    difficulties_change: yup.string().required("Required"),
    difficulties_impact: yup.string().required("Required"),
    difficulties_cope: yup.string().required("Required"),
    solution_change: yup.string().required("Required"),
    solution_how: yup.string().required("Required"),
    solution_affect_others: yup.string().required("Required"),
    motivation_to_solve: yup.string().required("Required"),
    solution_support: yup.string().required("Required"),
    solution_continue: yup.string().required("Required"),
    current_medications: yup.string().required("Required"),
    medication_success: yup.string().required("Required"),
    medication_side_effects: yup.string().required("Required"),
    current_past_comparison: yup.string().required("Required"),
    medication_future: yup.string().required("Required"),
    additional_info: yup.string().required("Required"),
});

export const INITIAL_CLINICAL_DIAGNOSIS_VALIDATION_SCHEMA = yup.object().shape({
    overall_assessment_impressions: yup.string().required("Required"),
    diagnostic_impressions: yup.string().required("Required"),
    recommendations: yup.string().required("Required"),
    enroll: yup.string().required("Required"),
    call_id: yup.string().required("Required"),
    ICD_10_diagnosis_axis_1: yup.array().required("Required").nullable(),
    additional_diagnostic_activities_placeholder: yup
        .object()
        .test("check_at_least_one_true", "Required", (obj) =>
            Object.values(obj).some((config) => config.value),
        ),
});

export const OUTCOME_CLINICAL_DIAGNOSIS_VALIDATION_SCHEMA = yup.object().shape({
    overall_assessment_impressions: yup.string().required("Required"),
    diagnostic_impressions: yup.string().required("Required"),
    recommendations: yup.string().required("Required"),
    pcp_consult_outcome: yup.string().required("Required"),
});
