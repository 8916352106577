import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { createClassNameString } from "../../component-library";
import { ICON_SIZES, ICON_TYPES } from "../../constants/icons";

export const EditIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.0858 8.91415C30.8668 8.13311 30.8668 6.86678 30.0858 6.08573L26.9142 2.91416C26.1331 2.13311 24.8668 2.13311 24.0858 2.91416L8.23568 18.7642C8.08268 18.9172 7.98344 19.1157 7.95284 19.3299L7.18854 24.68C7.09426 25.34 7.65994 25.9057 8.31991 25.8114L13.67 25.0471C13.8842 25.0165 14.0827 24.9172 14.2357 24.7642L30.0858 8.91415ZM13 23.1225L9.357 23.6429L9.87742 20H11V22H13V23.1225ZM25.6715 10.5L15 21.1715V20H13V18H11.8284L22.5 7.32843L25.6715 10.5ZM27.0857 9.08575L23.9142 5.91417L25.5 4.32837L28.6716 7.49994L27.0857 9.08575Z"
                />
            </svg>
        );
    }

    return null;
};

EditIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

EditIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
