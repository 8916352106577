// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Fragment } from "react";
import Button from "../../UI/Button";

const question = (props) => {
    let buttonElement;
    let onKeyDown = (e) => {
        if (e.keyCode === 13) {
            let target = document.querySelector(".Btn--pri");
            target.focus();
        }
    };
    buttonElement = props.currentQuestion.options.map((option) => {
        let active = props.selectedOptions.find((el) => {
            return el === option.index;
        });
        return (
            <div
                key={option.index}
                className="col-12 col-lg-6 px-2 px-lg-2 col-lg-4 d-flex py-2 justify-content-center">
                <Button
                    onKeyDown={onKeyDown}
                    onClick={(i) => {
                        props.selectOption(option);
                    }}
                    className={
                        "Btn cursor w-100 fw-normal text-break px-2 px-lg-3 text-center " +
                        (active !== undefined
                            ? "Btn--sec activeOptionBtn"
                            : "Btn--otl-gry inactiveOptionBtn")
                    }>
                    {option.answer}
                </Button>
            </div>
        );
    });
    return (
        <Fragment>
            <div className="Assessment__question-text mt-5 pt-lg-5">
                <p className="mb-2 mb-lg-3 fs-18">
                    <b>{props.currentQuestion.question}</b>
                </p>
            </div>
            <div className={props.excludeOuterClass ? null : "Assessment__options-outer"}>
                <div className="Assessment__options-inner">
                    <div className="row no-gutters">{buttonElement}</div>
                </div>
            </div>
        </Fragment>
    );
};
export default question;
