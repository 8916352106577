import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../../utils";

import { Button } from "../../Button";
import { Text } from "../../Text";

import { TwoButtonCard } from "../../TwoButtonCard";

import "./styles.scss";

export const PSSProviderNoShow = ({ className, onClickConfirm, onClickCancel, ...props }) => (
    <TwoButtonCard
        className={createClassNameString(["apti-PSSProviderNoShow", className])}
        buttonsPosition="space-center"
        buttonRight={
            <Button
                onClick={onClickConfirm}
                className={"primary apti-TwoButtonCard-button apti-TwoButtonCard-button-center"}>
                Yes, Message My Provider
            </Button>
        }
        buttonLeft={
            <Button
                onClick={onClickCancel}
                className={
                    "primary-outline apti-TwoButtonCard-button apti-TwoButtonCard-button-center"
                }>
                No, I’m All Set
            </Button>
        }
        {...props}>
        <div className={"text-center apti-TwoButtonCard-title"}>
            <Text className={"apti-TwoButtonCard-title-font"} color={"grey"}>
                It looks like your provider didn’t join your session. Do you need to contact them?
            </Text>
        </div>
    </TwoButtonCard>
);

PSSProviderNoShow.propTypes = {
    className: PropTypes.string,
    onClickConfirm: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
};

PSSProviderNoShow.defaultProps = {
    className: undefined,
    onClickConfirm: () => {},
    onClickCancel: () => {},
};
