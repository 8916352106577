import React from "react";
import PropTypes from "prop-types";

import NumberFormat from "react-number-format";

import { createClassNameString } from "../utils";

import "./styles.scss";

export const DateInput = ({
    className,
    name,
    value,
    placeholder,
    disabled,
    onChange,
    error,
    ...props
}) => {
    const numberFormat = (val) => {
        let month = val.substring(0, 2);
        let date = val.substring(2, 4);
        let year = val.substring(4, 8);

        let mainVal;
        if (month.length === 1) {
            mainVal = `${month}M/DD/YYYY`;
        } else if (month.length === 2) {
            mainVal = `${month}/DD/YYYY`;
        }

        if (month.length && date.length === 1) {
            mainVal = `${month}/${date}D/YYYY`;
        } else if (month.length && date.length === 2) {
            mainVal = `${month}/${date}/YYYY`;
        }

        if (month.length && date.length && year.length === 1) {
            mainVal = `${month}/${date}/${year}YYY`;
        } else if (month.length && date.length && year.length === 2) {
            mainVal = `${month}/${date}/${year}YY`;
        } else if (month.length && date.length && year.length === 3) {
            mainVal = `${month}/${date}/${year}Y`;
        } else if (month.length && date.length && year.length === 4) {
            mainVal = `${month}/${date}/${year}`;
        }

        return mainVal;
    };

    return (
        <NumberFormat
            className={createClassNameString([
                "apti-CL",
                "apti-DateInput",
                "apti-Input",
                className,
                error && "error",
            ])}
            name={name}
            type="text"
            elementtype="input"
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onValueChange={onChange}
            format={numberFormat}
            {...props}
        />
    );
};

DateInput.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

DateInput.defaultProps = {
    className: undefined,
    name: "",
    value: undefined,
    placeholder: undefined,
    disabled: false,
    onChange: () => {},
};
