import * as yup from "yup";
import { dobValidationSchemaFactory } from "../../../utils/yupSchemaUtils";
import { STATES } from "../../../constants/states";

export const A5_DETAILS_INITIAL_VALUES = {
    first_name: "",
    last_name: "",
    dob: "",
    gender: "",
    email: "",
    mobile: "",
    address: {
        street: "",
        city: "",
        state: "",
        zip_code: "",
    },
    pcp_id: "",
    notification_type: "",
};

export const A5_DETAILS_SCHEMA = [
    {
        name: "first_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "First Name",
            placeholder: "First name",
        },
    },
    {
        name: "last_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Last Name",
            placeholder: "Last name",
        },
    },
    {
        name: "dob",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Date of Birth",
            placeholder: "MM/DD/YYYY",
        },
    },
    {
        name: "gender",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "male", display: "Male" },
                { value: "female", display: "Female" },
            ],
            label: "Gender",
            placeholder: "Gender",
        },
    },
    {
        name: "email",
        elementType: "input",
        elementConfig: {
            type: "email",
            label: "Email",
            placeholder: "Email",
        },
    },
    {
        name: "mobile",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Mobile",
            placeholder: "Mobile",
        },
    },
    // below object does not render form element
    // only marks the hook for nested form and displays heading
    {
        elementType: "segment",
        displayName: "Address",
    },
    {
        name: "address.street",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Street",
            placeholder: "Enter Street",
        },
    },
    {
        name: "address.city",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "City",
            placeholder: "Enter City",
        },
    },
    {
        name: "address.state",
        elementType: "select",
        elementConfig: {
            options: STATES,
            label: "State",
            placeholder: "Enter State",
        },
    },
    {
        name: "address.zip_code",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Zip",
            placeholder: "Enter zip",
        },
    },
    {
        name: "pcp_practice_id",
        elementType: "select",
        elementConfig: {
            options: [],
            label: "Practice",
            placeholder: "Practice",
        },
    },
    {
        name: "pcp_id",
        elementType: "select",
        elementConfig: {
            options: [],
            label: "Primary Care Provider",
            placeholder: "Primary Care Provider",
        },
    },
    {
        name: "notification_type",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "text", display: "Text" },
                { value: "email", display: "Email" },
            ],
            label: "Notification Type",
            placeholder: "Notification Type",
        },
    },
];

export const A5_DETAILS_VALIDATION = yup.object().shape({
    first_name: yup
        .string()
        .required("First Name is required")
        .matches(/^[a-zA-Z-]+$/, "Digits, space & special characters are not allowed"),
    last_name: yup
        .string()
        .required("Last Name is required")
        .matches(/^[a-zA-Z-]+$/, "Digits, space & special characters are not allowed"),
    gender: yup.string().required("Gender is required"),
    address: yup.object().shape({
        street: yup.string().nullable(),
        city: yup
            .string()
            .matches(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, "Enter a valid City")
            .nullable(),
        state: yup.string().nullable(),
        zip_code: yup
            .string()
            .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Enter a valid zip code")
            .nullable(),
    }),
    dob: dobValidationSchemaFactory("MMDDYYYY").required("Required"),
    email: yup.string().email("Enter a valid email address").required("Email is required"),
    mobile: yup
        .string()
        .matches(/^\d{10}$/, "Contact Number must be a valid US number")
        .required("Mobile no. is required"),
    pcp_id: yup.string().required("Primary Care Provider is required"),
    notification_type: yup.string().required("Notification Type is required"),
});
