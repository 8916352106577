import React from "react";
import { ScrollableTextModal } from "../../ScrollableTextModal";

export const CreditCardAgreementScrollableTextModalHOC = ({
    show,
    onCancel,
    onSubmit,
    captchaComponent,
    captchaCompleted,
}) => {
    const agreementBodyContent = (
        <>
            At AptiHealth, we require keeping your credit card on file as a convenient method of
            payment for the payment of copayments and other charges that your insurance does not
            cover, but for which you are liable. Your credit card information will be kept
            confidential and secure and payments to your card will be processed at the time the
            authorized charge is incurred or, in some instances, in accordance with your insurer’s
            claim processing procedures.
            <br />
            <br />
            I authorize and request AptiHealth to charge my credit card for amounts due for services
            rendered by AptiHealth to me or my child that my insurance company identifies as my
            financial responsibilities based on the terms and policies associated with my insurance
            plan. This authorization relates to all payments not covered by my insurance company for
            services provided to me or my child by AptiHealth, including but not limited to,
            copayments for therapy sessions and deductibles, fees for cancelling an appointment or
            failing to arrive for an appointment without sufficient advance notice and other
            non-covered charges relating to our services, therapy sessions, pharmacological follow
            up appointments and coaching sessions not covered by my insurance and late fees and
            insurance discrepancies that are not resolved within a reasonable period of time
            following the date of service. Due to our 24-hour cancellation policy, we have the right
            to charge the credit card on file in connection with the late cancellation or failure to
            arrive for an appointment without sufficient advance notice.
            <br />
            <br />
            I understand that the payment information that I am providing in connection with this
            authorization is correct to the best of my knowledge, that it will be kept confidential
            and secure and it is my responsibility to immediately inform AptiHealth of any changes
            in my payment information and to update my payment information.. I understand that a
            temporary authorization hold of $0.01 will be placed on my card at the time it is
            provided to confirm my card is valid and that the funds should be reversed within 30
            days, depending on my bank’s policies.
            <br />
            <br />
            I knowingly authorize these charges and understand that I am financially responsible for
            payment for services rendered and any copayments, deductibles, and fees that my
            insurance does not cover. I hereby authorize AptiHealth to release all information
            necessary to secure payment for the services, fees and benefits described herein.
            <br />
            <br />I understand that this authorization is valid until I give a 30-day written notice
            to cancel the authorization to AptiHealth. I certify that I am an authorized user of
            this credit card and that I will not dispute the payment with my credit card company; so
            long as the transaction corresponds to the terms indicated in this authorization.
        </>
    );

    return (
        <ScrollableTextModal
            cardClassName={"apti-CreditCardAgreementModal"}
            title={"Authorization to Bill Credit Card on File"}
            show={show}
            submitButtonText={"Agree & Continue"}
            onSubmit={() => onSubmit()}
            onCancel={onCancel}
            bodyContent={agreementBodyContent}
            captchaComponent={captchaComponent}
            submitDisabled={!captchaCompleted}
            testId={"CreditCardAgreementModal"}
        />
    );
};
