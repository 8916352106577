// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useEffect } from "react";
import "./../styles.scss";
import CardSecondary from "../../../UI/Card/CardSecondary";
import images from "../../../../utils/images";
import { withRouter } from "react-router-dom";

const MemberProfileAccordion = (props) => {
    const { id, isEnabled, title, metaData, subTitle, badges } = props;
    const dataParent = "accordion" + id;
    const ariaControls = "collapse" + id;
    const dataTarget = "#collapse" + id;
    const ariaLabelledby = "heading" + id;

    const inputRef = React.useRef(null);

    let metadataClass = "col-lg-2";
    let buttonClass = "col-lg-1";

    useEffect(() => {
        if (props.selected) {
            inputRef.current.click();
            inputRef.current.scrollIntoView();
        }
    }, []);

    return (
        <CardSecondary style={{ maxWidth: "100%" }} className={"my-4"}>
            <div className="accordion" id={dataParent}>
                <div className="py-3">
                    <div
                        className={
                            "row text-left mx-3 py-2" + (isEnabled ? " txt-black" : " txt-gry")
                        }>
                        <div className={"col-lg-7 col-12 d-flex align-items-center"}>
                            {props.icon && (
                                <img
                                    style={{ height: "36px", marginRight: "15px" }}
                                    src={images(`./icons/${props.icon}`)}
                                />
                            )}
                            <div>
                                <div className={"fw-bold fs-18"}>{title}</div>
                                {subTitle}
                            </div>
                        </div>
                        <div className={"col-lg-2 col-12 d-flex flex-column align-items-center"}>
                            {badges}
                        </div>
                        <div className={`${metadataClass} col-12`}>{metaData}</div>
                        <div
                            className={`d-flex ${buttonClass} col-12 justify-content-end`}
                            style={{ padding: 0 }}>
                            {props.downloadIcon && (
                                <img
                                    style={{ height: "55px", cursor: "pointer" }}
                                    src={images("./icons/print.svg")}
                                    onClick={() =>
                                        props.history.push(
                                            `/app/patient/${props.patientId}/export_notes?noteIds=${props.id}`,
                                        )
                                    }
                                />
                            )}
                            {props.customButton ? (
                                props.customButton
                            ) : (
                                <button
                                    className={"AccordionBtn collapsed"}
                                    style={{ padding: 0, width: "30px" }}
                                    type="button"
                                    data-toggle="collapse"
                                    data-target={dataTarget}
                                    aria-expanded="false"
                                    disabled={isEnabled ? null : true}
                                    aria-controls={ariaControls}
                                    ref={inputRef}></button>
                            )}
                        </div>
                    </div>
                    <div
                        id={ariaControls}
                        className="collapse"
                        aria-labelledby={ariaLabelledby}
                        data-parent={"#" + dataParent}>
                        <div className="px-3 mt-4">{props.children}</div>
                    </div>
                </div>
            </div>
        </CardSecondary>
    );
};

export default withRouter(MemberProfileAccordion);
