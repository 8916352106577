import React from "react";
import images from "../../../../utils/images";
import { useRef } from "react";
import { useClickOutside } from "../../../../utils/hooks/useClickOutside";

const MemberProfileStatusPopUp = ({ openPopup, closePopup, isOpen, popUpOptions }) => {
    const ref = useRef();
    const internalPopUpOptions = popUpOptions.filter((option) => {
        if (option.shouldShow && !option.shouldShow()) {
            return false;
        }
        return true;
    });

    // close the popup if we click outside of it
    useClickOutside(ref, () => {
        if (isOpen) {
            closePopup();
        }
    });

    // if there are no available popup options do not show anything
    if (internalPopUpOptions.length === 0) {
        return null;
    }

    return (
        <div className="MemberProfileStatusPopUp__wrapper">
            <button className={"MemberProfileStatusPopUp__button"} onClick={openPopup}>
                <img src={images("./common/chevronV2Green.svg")} alt={"patient-menu-drop-down"} />
            </button>
            {isOpen && (
                <div ref={ref} className={"MemberProfileStatusPopUp__dropdown"}>
                    {internalPopUpOptions.map((option) => {
                        return (
                            <button
                                onClick={() => {
                                    option.action();
                                    closePopup();
                                }}
                                className="MemberProfileStatusPopUp__dropdown__item">
                                {option.title}
                            </button>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default MemberProfileStatusPopUp;
