// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Fragment } from "react";

const RatingControls = (props) => {
    const { rating, id, onRatingClick, type, readOnly } = props;
    let severity = "";
    let classes = {
        btn_0:
            rating !== null && rating >= 0
                ? "RatingScaleView__btn RatingScaleView__btn--active-0"
                : "RatingScaleView__btn",
        btn_1:
            rating !== null && rating >= 1
                ? "RatingScaleView__btn RatingScaleView__btn--active-1"
                : "RatingScaleView__btn",
        btn_2:
            rating !== null && rating >= 2
                ? "RatingScaleView__btn RatingScaleView__btn--active-2"
                : "RatingScaleView__btn",
        btn_3:
            rating !== null && rating >= 3
                ? "RatingScaleView__btn RatingScaleView__btn--active-3"
                : "RatingScaleView__btn",
        btn_4:
            rating !== null && rating >= 4
                ? "RatingScaleView__btn RatingScaleView__btn--active-4"
                : "RatingScaleView__btn",
        btn_5:
            rating !== null && rating >= 5
                ? "RatingScaleView__btn RatingScaleView__btn--active-5"
                : "RatingScaleView__btn",
    };

    const onRating = (e) => {
        let rating = e.target.innerText;
        onRatingClick(id, rating);
    };

    switch (Number(rating)) {
        case 0:
            severity = "No Problem";
            break;
        case 1:
            severity = "Mild Problem";
            break;
        case 2:
            severity = "Mild to Moderate";
            break;
        case 3:
            severity = "Moderate Problem";
            break;
        case 4:
            severity = "Moderate to Severe";
            break;
        case 5:
            severity = "Severe Problem";
            break;
        default:
            severity = "No Problem";
    }

    return (
        <div className="row no-gutters">
            {props.severityOnTop && (
                <div
                    className={
                        "col-12 pt-2 pt-lg-0 " +
                        (!props.severityOnTop
                            ? "d-flex align-items-center justify-content-end justify-content-lg-start"
                            : "text-center")
                    }>
                    <span className={"RatingScaleView__severity fs-12 fs-lg-14 fw-bold"}>
                        {severity}
                    </span>
                </div>
            )}
            <div className={"col-12 " + (!props.severityOnTop ? "col-lg-5" : "")}>
                <div
                    className={
                        "RatingScaleView__RatingControls d-flex " +
                        (!props.severityOnTop ? "" : "justify-content-center")
                    }>
                    {readOnly ? (
                        <Fragment>
                            <span className={classes.btn_0}>0</span>
                            <span className={classes.btn_1}>1</span>
                            <span className={classes.btn_2}>2</span>
                            <span className={classes.btn_3}>3</span>
                            <span className={classes.btn_4}>4</span>
                            <span className={classes.btn_5}>5</span>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <button
                                onClick={onRating}
                                className={classes.btn_0}
                                type={type ? type : "submit"}>
                                0
                            </button>
                            <button
                                onClick={onRating}
                                className={classes.btn_1}
                                type={type ? type : "submit"}>
                                1
                            </button>
                            <button
                                onClick={onRating}
                                className={classes.btn_2}
                                type={type ? type : "submit"}>
                                2
                            </button>
                            <button
                                onClick={onRating}
                                className={classes.btn_3}
                                type={type ? type : "submit"}>
                                3
                            </button>
                            <button
                                onClick={onRating}
                                className={classes.btn_4}
                                type={type ? type : "submit"}>
                                4
                            </button>
                            <button
                                onClick={onRating}
                                className={classes.btn_5}
                                type={type ? type : "submit"}>
                                5
                            </button>
                        </Fragment>
                    )}
                </div>
            </div>
            {!props.severityOnTop && (
                <div className="col-12 col-lg-5 pt-2 pt-lg-0 d-flex align-items-center justify-content-end justify-content-lg-start">
                    <span className={"RatingScaleView__severity fs-12 fs-lg-14 fw-bold"}>
                        {severity}
                    </span>
                </div>
            )}
        </div>
    );
};

export default RatingControls;
