import React, { useEffect } from "react";
import CardSecondary from "../../../UI/Card/CardSecondary";
import { api } from "../../../../APIRequests";
import Select from "react-select";
import InputError from "../../../UI/InputError";
import style from "../styles.scss";
import { FormField } from "../../../../component-library";
import { getAutomationDataAttr } from "../../../../utils/automationUtils";

export const NotesDiagnosis = (props) => {
    const { note, editable, profileView, editHandler, errors } = props;

    let numDiagnosis = 2;
    const [icdDataset, setIcdDataset] = React.useState([]);

    useEffect(() => {
        async function fetchICDDataset() {
            if (editable) {
                let dataset = await api.provider.fetch_ICD10_dataset();
                setIcdDataset(dataset);
            }
        }

        fetchICDDataset();
    }, []);

    const handleSelection = (index, selectedOptions) => {
        if (!editable) {
            return;
        }

        editHandler(`ICD_10_axis_${index}_diagnosis`, selectedOptions, false);
    };

    let innerContent = (
        <>
            <div>
                <div className="clr-sec fw-bold px-1 py-2">Diagnosis</div>
            </div>
            <div className={editable ? "px-1 row" : "px-1"}>
                {Array(numDiagnosis)
                    .fill()
                    .map((_, index) => {
                        let diagnosisIndex = `${index + 1}`;
                        if (editable) {
                            let filteredOptions = icdDataset.filter(
                                (type) => type.axis === diagnosisIndex,
                            );

                            let axisLabel = <span>ICD-10 Primary Diagnosis</span>;

                            if (diagnosisIndex === "2") {
                                axisLabel = <span>ICD-10 Secondary Diagnosis</span>;
                            }

                            return (
                                <>
                                    <div className="col-lg-6 col-12">
                                        {axisLabel}
                                        <Select
                                            components={{
                                                ClearIndicator: null,
                                            }}
                                            isMulti
                                            options={filteredOptions}
                                            defaultValue={
                                                note[`ICD_10_axis_${diagnosisIndex}_diagnosis`]
                                            }
                                            className={"basic-multi-select"}
                                            classNamePrefix="select"
                                            onChange={(selectedOptions) =>
                                                handleSelection(diagnosisIndex, selectedOptions)
                                            }
                                            {...getAutomationDataAttr(
                                                `NotesDiagnosis_diagnosis${diagnosisIndex}`,
                                                "id",
                                            )}
                                        />
                                        {errors[`ICD_10_axis_${diagnosisIndex}_diagnosis`] ? (
                                            <InputError classes={"custom-error"}>
                                                {errors[`ICD_10_axis_${diagnosisIndex}_diagnosis`]}
                                            </InputError>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </>
                            );
                        } else {
                            return (
                                <>
                                    <div className="d-flex flex-column fs-12">
                                        <ul>
                                            {note[`ICD_10_axis_${diagnosisIndex}_diagnosis`] &&
                                                note[`ICD_10_axis_${diagnosisIndex}_diagnosis`].map(
                                                    (icd_10) => {
                                                        return (
                                                            <div>
                                                                {icd_10["code"] +
                                                                    " - " +
                                                                    icd_10["text"]}
                                                            </div>
                                                        );
                                                    },
                                                )}
                                        </ul>
                                    </div>
                                </>
                            );
                        }
                    })}
            </div>
        </>
    );
    let content;

    if (!profileView) {
        content = (
            <CardSecondary className="mx-0 px-3 py-3 my-3 max-width" style={{ minHeight: 200 }}>
                {innerContent}
            </CardSecondary>
        );
    } else {
        content = (
            <div className="border-top-note border-right-note" style={{ minHeight: 150 }}>
                {innerContent}
            </div>
        );
    }

    return <>{content}</>;
};
