/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const PrescriberIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Prescriber"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.5 15C27.0898 15 30 12.0899 30 8.5C30 4.91015 27.0898 2 23.5 2C19.9101 2 17 4.91015 17 8.5C17 12.0899 19.9101 15 23.5 15ZM23.5 13C25.9853 13 28 10.9853 28 8.5C28 7.61948 27.7471 6.79803 27.31 6.10432L21.1043 12.31C21.798 12.7471 22.6195 13 23.5 13ZM19.6901 10.8958L25.8958 4.69008C25.202 4.25292 24.3805 4 23.5 4C21.0147 4 19 6.01472 19 8.5C19 9.38057 19.2529 10.2021 19.6901 10.8958Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.38906 20.1672C0.965039 17.7431 0.965038 13.813 3.38906 11.389C5.81309 8.96496 9.74321 8.96496 12.1672 11.389L20.9454 20.1672C23.3694 22.5912 23.3694 26.5213 20.9454 28.9453C18.5214 31.3694 14.5913 31.3694 12.1672 28.9453L3.38906 20.1672ZM15.9203 17.9705L19.5312 21.5814C21.1742 23.2244 21.1742 25.8881 19.5312 27.5311C17.8882 29.1741 15.2244 29.1741 13.5815 27.5311L9.97054 23.9202L15.9203 17.9705ZM14.5061 16.5562L10.753 12.8032C9.11005 11.1602 6.44625 11.1602 4.80328 12.8032C3.1603 14.4462 3.1603 17.11 4.80328 18.753L8.55632 22.506L14.5061 16.5562Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Prescriber"
                {...props}>
                <path d="M27.3347 3.25109L18.2511 12.3347C17.4644 11.2597 17 9.9341 17 8.5C17 4.91015 19.9101 2 23.5 2C24.9341 2 26.2597 2.46443 27.3347 3.25109Z" />
                <path d="M30 8.5C30 12.0899 27.0899 15 23.5 15C22.0659 15 20.7403 14.5356 19.6653 13.7489L28.7489 4.6653C29.5356 5.74025 30 7.06589 30 8.5Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.36279 10.5937C6.77209 9.00291 10.0462 9.26804 12.1672 11.3891L20.9454 20.1672C23.3694 22.5913 23.3694 26.5214 20.9454 28.9454C18.5214 31.3694 14.5913 31.3694 12.1672 28.9454L3.38906 20.1672C2.33595 19.1141 1.74036 17.7768 1.60229 16.4021C1.58146 16.1947 1.57104 15.9864 1.57104 15.7782C1.57104 14.1896 2.17705 12.6011 3.38906 11.3891C3.69207 11.0861 4.0186 10.8209 4.36279 10.5937ZM19.5312 21.5815L15.9203 17.9705L9.97054 23.9203L13.5815 27.5312C15.2244 29.1742 17.8882 29.1742 19.5312 27.5312C21.1742 25.8882 21.1742 23.2244 19.5312 21.5815Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Prescriber"
                {...props}>
                <path d="M19.9403 21.4779L22.6485 24.1861C23.8807 25.4183 23.8807 27.4162 22.6485 28.6484C21.4162 29.8806 19.4184 29.8806 18.1862 28.6484L15.478 25.9402L19.9403 21.4779Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM28.5011 10.4383L21.6884 17.251C21.0984 16.4448 20.7501 15.4506 20.7501 14.375C20.7501 11.6826 22.9327 9.5 25.6251 9.5C26.7006 9.5 27.6949 9.84832 28.5011 10.4383ZM30.5001 14.375C30.5001 17.0674 28.3174 19.25 25.6251 19.25C24.5495 19.25 23.5553 18.9017 22.749 18.3117L29.5617 11.499C30.1517 12.3052 30.5001 13.2994 30.5001 14.375ZM17.1255 16.5418C15.5347 14.951 13.0791 14.7522 11.2722 15.9453C11.014 16.1157 10.7691 16.3145 10.5419 16.5418C9.63285 17.4508 9.17834 18.6422 9.17834 19.8336C9.17834 19.9898 9.18616 20.146 9.20178 20.3016C9.30533 21.3326 9.75203 22.3356 10.5419 23.1254L17.1255 29.7091C18.9435 31.5271 21.8911 31.5271 23.7091 29.7091C25.5271 27.891 25.5271 24.9435 23.7091 23.1254L17.1255 16.5418Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

PrescriberIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

PrescriberIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
