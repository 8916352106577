// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import { withRouter } from "react-router-dom";
import images from "../../../utils/images";
import { Text, TextColors } from "../../../component-library";

const PatientCareTeamSchedule = (props) => {
    const { providerId, careTeamMembers, onMemberSelect } = props;

    const handleOnClick = (member) => {
        return member["disabled"]
            ? (provider_id, type) => {}
            : onMemberSelect(member.provider_id, member.type);
    };

    const numberOfDisabledCareTeamMembers = careTeamMembers.reduce((prev, current) => {
        const value = current?.disabled ? 1 : 0;
        return prev + value;
    }, 0);

    return (
        <div>
            <ul className="Schedule__CareTeamMembers">
                {careTeamMembers.map((member, index) => {
                    let defaultClasses = "cursor d-flex badge badge-pill";
                    let badgeClass =
                        providerId === member.provider_id ? " badge-selected" : " badge-light";
                    let imgRender = <img src={images("./common/avatar.png")} alt="avatar" />;
                    if (member["disabled"]) {
                        badgeClass = "badge-disabled";
                        imgRender = (
                            <img
                                className={"apti-disabled"}
                                src={images("./common/avatar.png")}
                                alt="avatar"
                            />
                        );
                    }

                    return (
                        <li
                            key={index}
                            onClick={() => handleOnClick(member)}
                            className={`${defaultClasses} ${badgeClass}`}>
                            <div className="">{imgRender}</div>
                            <div
                                className={"member-info d-flex flex-column justify-content-center"}>
                                <span className="fs-16 mb-1">{member.name}</span>
                                <span className="fs-12">{member.type}</span>
                            </div>
                        </li>
                    );
                })}
            </ul>
            {numberOfDisabledCareTeamMembers > 0 && (
                <div className="Schedule__CareTeamMembers--waitlist_restriction_text">
                    <Text color={TextColors.grey}>
                        One or more providers are currently unavailable due to a waitlist
                    </Text>
                </div>
            )}
        </div>
    );
};

export default withRouter(PatientCareTeamSchedule);
