import React from "react";
import { Form } from "formik";
import Button from "../../../UI/Button";
import _ from "lodash";
import { api } from "../../../../APIRequests";
import moment from "moment-timezone";
import Badge from "../../../UI/Badge";

const MakeTimeSlotButton = ({ timeSlotData, selectedAppointment, setSelectedAppointment }) => {
    if (timeSlotData == null) {
        return (
            <div className="row my-2 d-inline-block">
                <Button
                    type={"button"}
                    className={"Btn-xs Btn--otl-gry"}
                    onClick={() => {}}
                    style={{
                        fontSize: "15px",
                        width: "125px",
                        borderRadius: "5px",
                        borderColor: "white",
                    }}>
                    {"-"}
                </Button>
            </div>
        );
    } else {
        const startTime = moment(timeSlotData.start_time, "HH:mm").format("h:mm");
        const endTime = moment(timeSlotData.end_time, "HH:mm").format("h:mm a");
        return (
            <div className="row my-2 d-inline-block">
                <Button
                    type={"button"}
                    className={
                        timeSlotData === selectedAppointment
                            ? "Btn-xs Btn--sec text-white"
                            : "Btn-xs Btn--otl-gry"
                    }
                    style={{ fontSize: "15px", width: "125px", borderRadius: "5px" }}
                    onClick={() => {
                        setSelectedAppointment(timeSlotData);
                    }}>
                    {`${startTime}-${endTime}`}
                </Button>
            </div>
        );
    }
};

const adjustSlot = (timeSlots, maxDaySize) => {
    let acc = [];
    for (const i of Array(maxDaySize).keys()) {
        if (i < timeSlots.length) {
            acc.push(timeSlots[i]);
        } else {
            acc.push(null);
        }
    }

    return acc;
};

const Day = ({ oneDayData, maxDaySize, selectedAppointment, setSelectedAppointment }) => {
    const adjustedSlots = adjustSlot(oneDayData.slots, maxDaySize);

    return (
        <div className={"col-3 mb-3"} style={{ textAlign: "center" }}>
            <div className="row margin-center">
                <div className={"txt-gry fs-14 margin-center"}>{oneDayData.day_of_week}</div>
            </div>
            <div className="row margin-center">
                <div
                    className={
                        "fw-bold fs-md-16 fs-14 margin-center"
                    }>{`${oneDayData.month_of_year} ${oneDayData.day}`}</div>
            </div>
            <br />
            {adjustedSlots.map((slot) => (
                <MakeTimeSlotButton
                    timeSlotData={slot}
                    selectedAppointment={selectedAppointment}
                    setSelectedAppointment={setSelectedAppointment}
                />
            ))}
        </div>
    );
};

const updateDayData = (newAvailabilityData, schedulingData, setSchedulingData) => {
    const oldAvailability = _.get(schedulingData, "intake_appointment_data.upcoming_availability");
    schedulingData.intake_appointment_data.upcoming_availability =
        oldAvailability.concat(newAvailabilityData);
    setSchedulingData(schedulingData);
};

const setOrGetMoreDaysThenSet = async (
    updatedIndex,
    data,
    setDayIndex,
    schedulingData,
    setSchedulingData,
    workflowInstance,
) => {
    if (updatedIndex < data.length - 3) {
        setDayIndex(updatedIndex);
    } else {
        const lastDay = data[data.length - 1];
        const payload = {
            workflow_instance_sort_key: workflowInstance.sort_key,
            timezone: moment.tz.guess(),
            last_date: `${lastDay.year}-${lastDay.month}-${lastDay.day}`,
        };

        const headers = {
            "X-On-Behalf-Of": workflowInstance.entity_id,
            "X-On-Behalf-Of-Entity-Type": "patient",
        };

        const options = { params: { showLoader: true } };
        const result = await api.workflow.intake_get_additional_appointments({
            options,
            headers,
            data: payload,
        });
        updateDayData(result, schedulingData, setSchedulingData);
        setDayIndex(updatedIndex);
    }
};

const DayScroll = ({
    data,
    dayIndex,
    setDayIndex,
    setSchedulingData,
    schedulingData,
    workflowInstance,
}) => {
    const startDay = data[dayIndex];
    const endDay = data[dayIndex + 3];
    const previousArrow = dayIndex !== 0 ? "visible" : "hidden";
    const nextArrow = dayIndex !== 4 ? "visible" : "hidden";

    return (
        <div className="d-flex justify-content-center">
            <div className="d-flex align-items-center" style={{ visibility: previousArrow }}>
                <Button
                    type={"button"}
                    className={"Btn--otl-gry"}
                    style={{ borderColor: "white" }}
                    onClick={() => {
                        setDayIndex(dayIndex - 4);
                    }}>
                    {"<"}
                </Button>
            </div>
            <div className="">
                <div
                    className={
                        "fw-bold fs-md-16 fs-14 p-3"
                    }>{`${startDay.month_of_year} ${startDay.day} - ${endDay.month_of_year} ${endDay.day}, ${endDay.year}`}</div>
            </div>
            <div className="d-flex align-items-center" style={{ visibility: nextArrow }}>
                <Button
                    type={"button"}
                    className={"Btn--otl-gry"}
                    style={{ borderColor: "white" }}
                    onClick={() => {
                        setOrGetMoreDaysThenSet(
                            dayIndex + 4,
                            data,
                            setDayIndex,
                            schedulingData,
                            setSchedulingData,
                            workflowInstance,
                        );
                    }}>
                    {">"}
                </Button>
            </div>
        </div>
    );
};

const TimeSlotPicker = ({
    schedulingData,
    dayIndex,
    setDayIndex,
    selectedAppointment,
    setSelectedAppointment,
    setSchedulingData,
    workflowInstance,
}) => {
    const data = _.get(schedulingData, "intake_appointment_data.upcoming_availability");
    const todaysDate = _.get(schedulingData, "intake_appointment_data.todays_date_data");
    const dayOne = data[dayIndex];
    const dayTwo = data[dayIndex + 1];
    const dayThree = data[dayIndex + 2];
    const dayFour = data[dayIndex + 3];
    const hasDate = [dayOne, dayTwo, dayThree, dayFour].every((day) => day != null);
    const daySizes = [dayOne, dayTwo, dayThree, dayFour].map((day) =>
        day != null ? day.slots.length : 0,
    );
    const maxDaySize = Math.max(...daySizes);

    return (
        <>
            <div>
                <Form>
                    <div
                        className={
                            "workflow-container-md workflow-container-border mb-5 workflow-intake-scheduling"
                        }>
                        <div className={"p-4"}>
                            <div className="row fw-bold">
                                <Badge
                                    type="light-grey"
                                    style={{ width: "unset" }}
                                    classes="px-2"
                                    text={`Today's Date: ${todaysDate.day_of_week}, ${todaysDate.month_of_year} ${todaysDate.day}, ${todaysDate.year}`}
                                />
                            </div>
                            {hasDate && (
                                <DayScroll
                                    data={data}
                                    dayIndex={dayIndex}
                                    setDayIndex={setDayIndex}
                                    setSchedulingData={setSchedulingData}
                                    schedulingData={schedulingData}
                                    workflowInstance={workflowInstance}
                                />
                            )}

                            <div
                                className="row"
                                style={{ overflowY: "scroll", maxHeight: "250px" }}>
                                {dayOne && (
                                    <Day
                                        oneDayData={dayOne}
                                        maxDaySize={maxDaySize}
                                        selectedAppointment={selectedAppointment}
                                        setSelectedAppointment={setSelectedAppointment}
                                    />
                                )}
                                {dayTwo && (
                                    <Day
                                        oneDayData={dayTwo}
                                        maxDaySize={maxDaySize}
                                        selectedAppointment={selectedAppointment}
                                        setSelectedAppointment={setSelectedAppointment}
                                    />
                                )}
                                {dayThree && (
                                    <Day
                                        oneDayData={dayThree}
                                        maxDaySize={maxDaySize}
                                        selectedAppointment={selectedAppointment}
                                        setSelectedAppointment={setSelectedAppointment}
                                    />
                                )}
                                {dayFour && (
                                    <Day
                                        oneDayData={dayFour}
                                        maxDaySize={maxDaySize}
                                        selectedAppointment={selectedAppointment}
                                        setSelectedAppointment={setSelectedAppointment}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default TimeSlotPicker;
