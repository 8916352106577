import React from "react";
import { Card } from "../../../component-library/Card";
import { Text } from "../../../component-library";
import { connect } from "react-redux";
import { setDynamicRouteConfiguration } from "../../../redux/actions/navbar";
import CancellationPolicy from "../../../components/Patient/AvailableSchedulesList/CancellationPolicy";
import { ListBody, ListHeader, ListItem } from "../../../components/UI/ListView";

const ScheduleInactive = (props) => {
    const _telClick = () => {
        window.open("tel:8884543827");
    };
    const _emailClick = () => {
        window.open("mailto:support@aptihealth.com");
    };

    return (
        <>
            <div className="pt-2 position-relative">
                <ListBody>
                    <ListHeader className="d-none d-lg-block" title={"Schedule"} />
                    <div className="patient-schedule-inactive--aligner">
                        <div className="patient-schedule-inactive--fixed">
                            <Text className="d-block text-center m-3" type="heading-1">
                                Your Account is Currently Inactive
                            </Text>
                            <div className="mx-3 text-center">
                                <Text type="paragraph" color="grey">
                                    Looking to schedule a session? Please email us at{" "}
                                </Text>
                                <Text
                                    className="patient-contact-link"
                                    type="paragraph"
                                    onClick={_emailClick}>
                                    support@aptihealth.com
                                </Text>
                                <Text type="paragraph" color="grey">
                                    {" "}
                                    or call us at{" "}
                                </Text>
                                <Text
                                    className="patient-contact-link"
                                    type="paragraph"
                                    onClick={_telClick}>
                                    (888) 454-3827
                                </Text>
                                .
                            </div>
                        </div>
                    </div>
                </ListBody>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps, { setDynamicRouteConfiguration })(ScheduleInactive);
