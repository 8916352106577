/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const ExternalLinkIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="ExternalLink"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19 5C18.4477 5 18 4.55228 18 4C18 3.44772 18.4477 3 19 3C20.4485 3 29 3 29 3V13C29 13.5523 28.5523 14 28 14C27.4477 14 27 13.5523 27 13V6.41421L16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071C14.9024 16.3166 14.9024 15.6834 15.2929 15.2929L25.5858 5H19ZM7 10C7 8.34315 8.34315 7 10 7H14V9H10C9.44772 9 9 9.44772 9 10V22C9 22.5523 9.44772 23 10 23H22C22.5523 23 23 22.5523 23 22V18H25V22C25 23.6569 23.6569 25 22 25H10C8.34315 25 7 23.6569 7 22V10Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="ExternalLink"
                {...props}>
                <path d="M18 4C18 3.44772 18.4477 3 19 3C20.4485 3 29 3 29 3V13C29 13.5523 28.5523 14 28 14C27.4477 14 27 13.5523 27 13V6.41421L24.7076 8.70658C24.412 8.08881 23.9112 7.58799 23.2934 7.29237L25.5858 5H19C18.4477 5 18 4.55228 18 4Z" />
                <path d="M23.2934 7.29237L15.2929 15.2929C14.9024 15.6834 14.9024 16.3166 15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071L24.7076 8.70658C24.895 9.09822 25 9.53685 25 10V22C25 23.6569 23.6569 25 22 25H10C8.34315 25 7 23.6569 7 22V10C7 8.34315 8.34315 7 10 7H22C22.4631 7 22.9018 7.10495 23.2934 7.29237Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="ExternalLink"
                {...props}>
                <path d="M26.5307 14.5299L20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303C19.1768 20.2374 19.1768 19.7626 19.4697 19.4697L25.4701 13.4693C25.9334 13.691 26.309 14.0666 26.5307 14.5299Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM22.25 10.25C21.8358 10.25 21.5 10.5858 21.5 11C21.5 11.4142 21.8358 11.75 22.25 11.75H27.1893L25.4701 13.4693C25.1763 13.3287 24.8474 13.25 24.5 13.25H15.5C14.2574 13.25 13.25 14.2574 13.25 15.5V24.5C13.25 25.7426 14.2574 26.75 15.5 26.75H24.5C25.7426 26.75 26.75 25.7426 26.75 24.5V15.5C26.75 15.1526 26.6713 14.8237 26.5307 14.5299L28.25 12.8107V17.75C28.25 18.1642 28.5858 18.5 29 18.5C29.4142 18.5 29.75 18.1642 29.75 17.75V10.25C29.75 10.25 23.8667 10.25 22.25 10.25Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

ExternalLinkIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

ExternalLinkIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
