// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import { Link } from "../../../component-library";

const SchedulingWaitlisted = (props) => {
    return (
        <>
            <div className={"m-auto scheduling-not-allowed-desc"}>
                <div className={"fw-bold fs-24 mb-3"}>Scheduling is unavailable.</div>

                <div>
                    We’re working on finding a provider that’s the best match for you. Once a
                    provider has been assigned, you can schedule appointments with them. If you are
                    having a medical or life-threatening emergency, please call 911, go to the
                    nearest emergency room, or contact the National Suicide Prevention Lifeline at
                    988. For questions about waitlist status, contact our team at&nbsp;
                    <Link href="mailto:support@aptihealth.com">support@aptihealth.com</Link>.
                </div>
            </div>
        </>
    );
};

export default SchedulingWaitlisted;
