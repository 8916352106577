import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import {createClassNameString} from "../../component-library";
import {ICON_SIZES, ICON_TYPES} from "../../constants/icons";

export const CalendarIcon = ({className, iconType, iconSize, ...props}) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path d="M11 17H8V14H11V17Z" />
                <path d="M11 24H8V21H11V24Z" />
                <path d="M14.5 17H17.5V14H14.5V17Z" />
                <path d="M17.5 24H14.5V21H17.5V24Z" />
                <path d="M21 17H24V14H21V17Z" />
                <path d="M24 24H21V21H24V24Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M4 24C4 26.2091 5.79086 28 8 28H24C26.2091 28 28 26.2091 28 24V8C28 5.79086 26.2091 4 24 4H8C5.79086 4 4 5.79086 4 8V24ZM24 6H8C6.89543 6 6 6.89543 6 8V10L26 10V8C26 6.89543 25.1046 6 24 6ZM26 24V12L6 12V24C6 25.1046 6.89543 26 8 26H24C25.1046 26 26 25.1046 26 24Z" />
            </svg>
        );
    } else if (iconType === ICON_TYPES.FILLED) {
        return (
            <svg
                className={createClassNameString([iconSize, className])}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path fillRule="evenodd" clipRule="evenodd" d="M4 8V24C4 26.2091 5.79086 28 8 28H24C26.2091 28 28 26.2091 28 24V8C28 5.79086 26.2091 4 24 4H8C5.79086 4 4 5.79086 4 8ZM24 6H8C6.89543 6 6 6.89543 6 8V10H26V8C26 6.89543 25.1046 6 24 6ZM11 17H8V14H11V17ZM11 24H8V21H11V24ZM14.5 17H17.5V14H14.5V17ZM17.5 24H14.5V21H17.5V24ZM21 17H24V14H21V17ZM24 24H21V21H24V24Z" />
            </svg>

        );
    }

    return null;
};

CalendarIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

CalendarIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.DEFAULT,
};
