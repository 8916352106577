import { useEffect, useState } from "react";
import { api } from "../../APIRequests";

export const useProviderInactivationOptions = () => {
    const [options, setOptions] = useState([]);
    const getInactivationOptions = async () => {
        const response = await api.admin.get_provider_inactivation_options({
            params: { showLoader: true },
        });
        setOptions(response);
    };

    useEffect(() => {
        getInactivationOptions();
    }, []);

    const transformToSelectOptions = () => {
        return options.map((option) => {
            return {
                label: option.reason,
                value: option.reason_id,
            };
        });
    };

    return {
        selectableOptions: transformToSelectOptions(options),
    };
};
