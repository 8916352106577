import React, { useEffect } from "react";

/**
 * No-op insurance eligibility component. Does not reach out to backend to confirm eligibility,
 * simply returns a blank response when insurance is submitted.
 * @param submissionAttempts Number of insurance eligibility submission attempts
 * @param eligibilityCallback Function to call on insurance eligibility submission
 * @returns {JSX.Element} Empty component
 */
const SkipInsuranceEligibility = ({ submissionAttempts, eligibilityCallback }) => {
    useEffect(() => {
        // On the first attempt, return an empty result with no errors
        if (submissionAttempts) {
            eligibilityCallback(null, submissionAttempts, {});
        }
    }, [submissionAttempts]);

    return <></>;
};

export default SkipInsuranceEligibility;
