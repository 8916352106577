import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../../utils";
import { ButtonTypes, TextTypes } from "../../constants";

import { Text } from "../../Text";
import { Button } from "../../Button";

import "./styles.scss";

export const EmptyProfileInsurance = ({
    className,
    title,
    details,
    onAddButtonClick,
    ...props
}) => (
    <div className={createClassNameString(["apti-EmptyProfileInsurance", className])} {...props}>
        <div className="apti-EmptyProfileInsurance--left">
            <Text type={TextTypes.heading2}>{title}</Text>
            <Text type={TextTypes.paragraph}>{details}</Text>
        </div>
        <Button
            className="apti-EmptyProfileInsurance--button"
            buttonType={ButtonTypes.primary}
            onClick={onAddButtonClick}>
            Add
        </Button>
    </div>
);

EmptyProfileInsurance.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    details: PropTypes.string,
    onAddButtonClick: PropTypes.func.isRequired,
};

EmptyProfileInsurance.defaultProps = {
    className: undefined,
    title: "Title",
    details: "Details",
    onAddButtonClick: () => {},
};
