import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { api } from "../../../../../APIRequests";
import { Button, Card } from "../../../../../component-library";
import CardHeading from "../../../../UI/Headings/CardHeading";
import Hr from "../../../../UI/Hr";
import { Popup } from "../../../../../component-library/Popup";

const actionDescription =
    "Communicate the new, randomly-generated password displayed below to the patient. Once " +
    "entered, the patient will be prompted to set a new password. Note temporary passwords expire after 7 days.";

const ResetPatientPasswordCard = (props) => {
    const patientId = props.match.params.patientId;
    const patientDetails = props.patientDetails;

    const [newPassword, setNewPassword] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const submitReset = async () => {
        const resetPasswordData = { patient_id: patientId };
        const { new_password } = await api.admin.reset_patient_password({
            data: resetPasswordData,
        });
        setNewPassword(new_password);
        setShowConfirmation(false);

        const activityResponse = await api.shared.fetch_patient_activity_feed({
            urlParams: { patientId },
        });
        const newTimelineData = { ...props.timelineData };
        newTimelineData["activities"] = activityResponse.results;

        const patientDetailsUrlParams = { patientId: patientId };
        const patientDetailsOptions = { params: { showLoader: true } };

        const response = await api.shared.fetch_patient_details({
            urlParams: patientDetailsUrlParams,
            options: patientDetailsOptions,
        });

        props.updateState({ timelineData: newTimelineData, patientDetails: response.user });
    };

    return (
        <div className={"AdminTab__ResetPatientPasswordCard"}>
            {showConfirmation && (
                <Popup
                    title={"Reset Patient Password"}
                    subtitle={"Are you sure you want to reset this patient's password?"}
                    primaryOnClick={submitReset}
                    secondaryOnClick={() => setShowConfirmation(false)}
                />
            )}
            <Card className={"p-3 p-lg-4 mb-3 mb-lg-4"}>
                <CardHeading className="fs-16 m-0 mb-3" text={"Reset Patient Password"} />
                <Hr />
                <div className="no-gutters row w-100">
                    <div className="mt-4">
                        <p className={"admin-card-description"}>{actionDescription}</p>
                        <div className="no-gutters row">
                            <div className="col-6">
                                <p className={"mb-1 font-weight-bold"}>Account Lockout Status</p>
                                <p className="fs-14">
                                    {patientDetails?.auth?.account_status?.status}
                                    {patientDetails?.auth?.account_status?.reason && (
                                        <span>
                                            : {patientDetails?.auth?.account_status?.reason}
                                        </span>
                                    )}
                                </p>
                            </div>
                            <div className="col-6">
                                <p className={"mb-1 font-weight-bold"}>Password Expiration</p>
                                <p className="fs-14">
                                    {patientDetails?.auth?.password_expiration_timestamp}
                                </p>
                            </div>
                        </div>
                        <div className="no-gutters row">
                            <div className="col-6">
                                <p className={"mb-1 font-weight-bold"}>
                                    Count of Failed Login Attempts
                                </p>
                                <p className="fs-14">
                                    {patientDetails?.auth?.failed_login_attempts}
                                </p>
                            </div>
                        </div>
                        {newPassword && (
                            <div className="fs-14 mb-3">
                                <span>Password set to: {newPassword}</span>
                            </div>
                        )}
                        <Button
                            className="Btn Btn--pri Btn-sm"
                            onClick={() => setShowConfirmation(true)}
                            buttonType={"primary-outline"}>
                            Reset Password
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default withRouter(ResetPatientPasswordCard);
