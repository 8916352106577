import React, { useState } from "react";
import _ from "lodash";

import { AddInsurance } from "../../AddInsurance";
import { insuranceListToSelectFormat } from "../../../utils/GenericListUtils";
import { CATCH_ALL_INSURANCE_OPTIONS } from "../../../constants/insurnaceIconMap";
import { InsuranceAcknowledgementPopupHOC } from "../InsuranceAcknowledgementPopupHOC";
import { getCarrierMetadata } from "../../../components/Common/InsuranceEligibility/carrierMetadata";

export const MemberReferralInsuranceHOC = ({
    isPrimaryInsurance,
    formikProps,
    firstName, // From login
    lastName,
    insuranceCompanyOptions,
    submitAcknowledgementHandler,
    closeAcknowledgementHandler,
    showAcknowledgementPopup,
    ...props
}) => {
    const { handleChange, handleBlur, handleFocus, values, errors, touched } = formikProps;
    const { memberIdPlaceholder, memberIdTip, memberIdTooltipContents, groupIdTooltipContents } =
        getCarrierMetadata(values.insurance?.carrier);
    const [noInsurance, setNoInsurance] = useState(
        CATCH_ALL_INSURANCE_OPTIONS.includes(_.get(values, "insurance.carrier")),
    );

    const mapDropDownValue = (value) => ({
        label: value,
        value: value,
    });

    const getPolicyHolderValue = () => {
        if (values.insurance.policy_holder_question) {
            return values.insurance.policy_holder_question;
        }

        if (values.primary_insurance && values.primary_insurance.policy_relationship === null) {
            return "yes";
        }

        if (values.primary_insurance && values.primary_insurance.policy_relationship !== "self") {
            return "no";
        }

        return "yes";
    };

    const mappedValues = {
        insuranceCompany: mapDropDownValue(values.insurance?.carrier),
        policyHolder: getPolicyHolderValue(),
        memberId: values.insurance?.member_id,
        groupId: values.insurance?.group_id,
    };

    const mappedNames = {
        insuranceCompany: "insurance.carrier",
        policyRelationship: "insurance.policy_relationship",
        policyHolder: "insurance.policy_holder_question",
        policyHolderName: "insurance.policy_holder_name",
        memberId: "insurance.member_id",
        groupId: "insurance.group_id",
        insuranceFront: "insurance.card_front",
        insuranceBack: "insurance.card_back",
    };

    const mappedTouched = {
        insuranceCompany: _.get(touched, "insurance.carrier"),
        policyHolder: _.get(touched, "insurance.policy_holder_question"),
        memberId: _.get(touched, "insurance.member_id"),
        groupId: _.get(touched, "insurance.group_id"),
    };

    const mappedErrors = {
        insuranceCompany: _.get(errors, "insurance.carrier"),
        policyHolder: _.get(errors, "insurance.policy_holder_question"),
        memberId: _.get(errors, "insurance.member_id"),
        groupId: _.get(errors, "insurance.group_id"),
    };

    const generatePolicyHolderName = (e, value) => {
        let policyHolderName = "Other";
        if (value === "yes") {
            policyHolderName = `${firstName} ${lastName}`;
        }
        handleChange({
            ...e,
            target: { ...e.target, name: mappedNames["policyHolderName"], value: policyHolderName },
        });
    };

    const generatePolicyHolderRelationship = (e, value) => {
        let policyHolderRelationship = "Other";
        if (value === "yes") {
            policyHolderRelationship = "self";
        }
        handleChange({
            ...e,
            target: {
                ...e.target,
                name: mappedNames["policyRelationship"],
                value: policyHolderRelationship,
            },
        });
    };

    const mappedChangeHandler = (e) => {
        const { name, value } = e.target;
        const mappedName = mappedNames[name];
        let mappedValue = value;

        switch (name) {
            case "policyHolder":
                generatePolicyHolderName(e, value);
                generatePolicyHolderRelationship(e, value);
                break;
            case "insuranceCompany":
                if (CATCH_ALL_INSURANCE_OPTIONS.includes(value)) {
                    resetInsurance();
                    setNoInsurance(true);
                } else {
                    setNoInsurance(false);
                }
                break;
        }

        handleChange({ ...e, target: { ...e.target, name: mappedName, value: mappedValue } });
    };

    const resetInsurance = () => {
        for (const name in mappedNames) {
            if (name === "insuranceCompany") {
                continue;
            }
            formikProps.setFieldValue(mappedNames[name], "");
        }
    };

    return (
        <>
            <AddInsurance
                values={mappedValues}
                touched={mappedTouched}
                errors={mappedErrors}
                handleChange={mappedChangeHandler}
                handleBlur={handleBlur}
                handleFocus={handleFocus}
                hideUploadFile={true}
                insuranceCompanyOptions={insuranceListToSelectFormat(insuranceCompanyOptions)}
                isPrimaryInsurance={isPrimaryInsurance}
                noInsurance={noInsurance}
                memberIdPlaceholder={memberIdPlaceholder}
                memberIdTip={memberIdTip}
                memberIdTooltipContents={memberIdTooltipContents}
                groupIdTooltipContents={groupIdTooltipContents}
                {...props}
            />
            {showAcknowledgementPopup && (
                <InsuranceAcknowledgementPopupHOC
                    gaEventPrefix={"rpr"}
                    acknowledgementType={mappedValues.insuranceCompany.value}
                    disabled={!showAcknowledgementPopup}
                    primaryOnClick={submitAcknowledgementHandler}
                    secondaryOnClick={closeAcknowledgementHandler}
                />
            )}
        </>
    );
};
