// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";

import { api } from "../../../APIRequests";
import Button from "../../UI/Button";
import { ReportCompletedActivity, ReportGeneratedActivity } from "./ReportActivity";
import AssessmentActivity, { GenericAssessmentActivity } from "./AssessmentActivity";
import { patientIdChange } from "./index";
import moment from "moment-timezone";
import withRouter from "react-router-dom/es/withRouter";
import {
    CallCancelledActivity,
    CallCompletedActivity,
    CallRescheduledActivity,
    CallScheduledActivity,
    InstantCallRequestedActivity,
    PatientNoShowActivity,
    SchedulingRestrictionActivity,
} from "./ScheduleActivity";
import { MemberActivatedActivity, MemberInactivatedActivity } from "./MemberActivity";
import {
    EmailUpdatedActivity,
    PasswordResetActivity,
    PracticeChangedActivity,
} from "./AdminActivity";
import BillingChange from "./BillingChange";
import { WaitListAddedActivity, WaitListRemovedActivity } from "./WaitListActivity";
import { OPERATIONAL_RESTRICT_PATIENT_SCHEDULING } from "../../../constants/featureFlags";
import FeatureGate from "../../Common/FeatureGate/FeatureGate";
import { connect } from "react-redux";

class ActivityFeed extends Component {
    constructor(props) {
        super(props);
        let pageSize = 10;
        this.state = {
            activities: props.data ? props.data["activities"] : [],
            pageSize: pageSize,
            activitiesToShow: 5,
            patientId: this.props.match.params.patientId,
        };
    }

    componentDidMount() {
        if (this.state.activities.length === 0) {
            let urlParams = { patientId: this.state.patientId };
            const options = { params: { showLoader: false } };
            api.shared
                .fetch_patient_activity_feed({ urlParams, options })
                .then((response) => {
                    if (response.results) {
                        this.setState({ activities: response.results });
                        const data = { ...this.props.data };
                        data["activities"] = response.results;
                        if (this.props.updateState) {
                            this.props.updateState({ timelineData: data });
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    componentDidUpdate(prevProps) {
        patientIdChange.bind(this)(prevProps);
        // if the parents activities have changed update this components state
        // this is used when the parent re-fetches activities after a new activity has been added
        const parentActivities = this.props?.data?.activities;
        if (parentActivities && parentActivities.length !== this.state.activities.length) {
            this.setState({ activities: parentActivities });
        }
    }

    showMore = () =>
        this.setState({
            activitiesToShow: this.state.activitiesToShow + this.state.pageSize,
        });

    getShowMoreButton = () => {
        return (
            <div className="d-flex align-items-center justify-content-center py-4">
                <Button
                    className="Btn Btn--pri ShowMoreBtn ml-4 text-center"
                    onClick={this.showMore}>
                    Show More
                </Button>
            </div>
        );
    };

    activityFeedEntryFactory = (activity) => {
        const keyContent = activity.key_content || {};
        switch (activity.name) {
            case "Report Generated":
                return (
                    <ReportGeneratedActivity
                        localCreatedTime={activity.localCreatedTime}
                        keyContent={keyContent}
                    />
                );
            case "Report Completed":
                return (
                    <ReportCompletedActivity
                        localCreatedTime={activity.localCreatedTime}
                        keyContent={keyContent}
                    />
                );
            case "Clinical Assessment Completed":
                return (
                    <AssessmentActivity
                        localCreatedTime={activity.localCreatedTime}
                        keyContent={keyContent}
                    />
                );
            case "Video Consultation Scheduled":
                return (
                    <CallScheduledActivity
                        localCreatedTime={activity.localCreatedTime}
                        keyContent={keyContent}
                    />
                );
            case "Call Completed":
                return (
                    <CallCompletedActivity
                        localCreatedTime={activity.localCreatedTime}
                        keyContent={keyContent}
                    />
                );
            case "Video Consultation Cancelled":
                return (
                    <CallCancelledActivity
                        localCreatedTime={activity.localCreatedTime}
                        keyContent={keyContent}
                    />
                );
            case "Video Consultation Rescheduled":
                return (
                    <CallRescheduledActivity
                        localCreatedTime={activity.localCreatedTime}
                        keyContent={keyContent}
                    />
                );
            case "Instant Video Call Requested":
                return (
                    <InstantCallRequestedActivity
                        localCreatedTime={activity.localCreatedTime}
                        keyContent={keyContent}
                    />
                );
            case "Patient No-Show":
                return (
                    <PatientNoShowActivity
                        localCreatedTime={activity.localCreatedTime}
                        keyContent={keyContent}
                    />
                );
            case "Member Activation":
                return (
                    <MemberActivatedActivity
                        localCreatedTime={activity.localCreatedTime}
                        keyContent={keyContent}
                    />
                );
            case "Member Inactivation":
                return (
                    <MemberInactivatedActivity
                        localCreatedTime={activity.localCreatedTime}
                        keyContent={keyContent}
                    />
                );
            case "Email Updated":
                return (
                    <EmailUpdatedActivity
                        localCreatedTime={activity.localCreatedTime}
                        keyContent={keyContent}
                    />
                );
            case "Patient Practice Changed":
                return (
                    <PracticeChangedActivity
                        localCreatedTime={activity.localCreatedTime}
                        keyContent={keyContent}
                    />
                );
            case "Automatic Payment Changed":
                return (
                    <BillingChange
                        localCreatedTime={activity.localCreatedTime}
                        keyContent={keyContent}
                    />
                );
            case "Waitlisted":
                return (
                    <WaitListAddedActivity
                        localCreatedTime={activity.localCreatedTime}
                        keyContent={keyContent}
                    />
                );
            case "Removed from waitlist":
                return (
                    <WaitListRemovedActivity
                        localCreatedTime={activity.localCreatedTime}
                        keyContent={keyContent}
                    />
                );
            case "Admin Password Reset":
                return (
                    <PasswordResetActivity
                        localCreatedTime={activity.localCreatedTime}
                        keyContent={keyContent}
                    />
                );
            case "Scheduling Restriction Change":
                return (
                    <FeatureGate
                        flagName={OPERATIONAL_RESTRICT_PATIENT_SCHEDULING}
                        enabled={
                            this?.props?.flags?.privateFlags?.[
                                OPERATIONAL_RESTRICT_PATIENT_SCHEDULING
                            ]
                        }
                        alternateFlagDisabledBehavior={<></>}
                        hasLoaded={this?.props?.flags?.privateFlagsHaveLoaded}
                        defaultFlagBehavior={true}>
                        <SchedulingRestrictionActivity
                            localCreatedTime={activity.localCreatedTime}
                            keyContent={keyContent}
                        />
                    </FeatureGate>
                );
            case "Assessment":
                return (
                    <GenericAssessmentActivity
                        timestamp={activity.localCreatedTime}
                        keyContent={keyContent}
                    />
                );
            default:
                return <></>;
        }
    };

    render() {
        return (
            <div>
                {this.state.activities.slice(0, this.state.activitiesToShow).map((activity, i) => {
                    activity.localCreatedTime = moment
                        .utc(activity.timestamp)
                        .local()
                        .format("MM/DD/YYYY hh:mm a");
                    return (
                        <div key={activity.entity_id + i}>
                            {this.activityFeedEntryFactory(activity)}
                        </div>
                    );
                })}
                {this.state.activities &&
                    this.state.activitiesToShow < this.state.activities.length &&
                    this.getShowMoreButton()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        flags: state.flags,
    };
};

export default connect(mapStateToProps, {})(withRouter(ActivityFeed));
