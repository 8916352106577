// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import PropTypes from "prop-types";
import images from "../../../../utils/images";

const ReportsMainCollapse = (props) => {
    const { title, uid, children: collapsibleContent, accordionId } = props;
    /** creating a valid and unique id without empty spaces */
    return (
        <div className="ReportsMainCollapse mb-3">
            <div className="ReportsMainCollapse__header d-flex align-items-center justify-content-between">
                <h1 className="fs-18 fw-bold m-0">{title}</h1>
                <button
                    className="ReportsMainCollapse__toggle collapsed"
                    data-toggle="collapse"
                    data-target={`.${uid}`}>
                    <img
                        className="ReportsMainCollapse__toggle-arrow"
                        src={images("./icons/back-arrow.svg")}
                        alt=""
                    />
                </button>
            </div>
            <div
                className={`ReportsMainCollapse__content collapse ${uid}`}
                data-parent={accordionId}
                id={uid}>
                <hr className="m-0" />
                <div className="ReportsMainCollapse__content-scrollable position-relative py-4">
                    {collapsibleContent}
                </div>
            </div>
        </div>
    );
};

ReportsMainCollapse.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    accordionId: PropTypes.string,
};

export default ReportsMainCollapse;
