import React, { useState } from "react";
import { useEffect } from "react";
import images from "../../../utils/images";
import Button from "../../UI/Button";
import { connect } from "react-redux";
import { getQueryParams } from "../../../utils/filters";
import { api } from "../../../APIRequests";

const CompleteChildWorkflow = (props) => {
    const { nextStep, patientMetaData } = props;
    const [email, setEmail] = useState("your child's email");

    async function getPatient(patientId) {
        let patient;
        if (!patientMetaData) {
            const headers = {
                "X-On-Behalf-Of": patientId,
                "X-On-Behalf-Of-Entity-Type": "patient",
            };
            patient = await api.patient.get_patient_meta_data({ headers });
        } else {
            patient = patientMetaData;
        }
        return patient;
    }

    useEffect(() => {
        if (patientMetaData) {
            setEmail(patientMetaData.email);
        } else {
            const patientId = getQueryParams("patientId");
            getPatient(patientId).then((data) => setEmail(data.email));
        }
    }, []);

    return (
        <div className={"mx-5"} data-public={"true"}>
            <div className="mx-3">
                <div className={"txt-pri fw-bold fs-20 pt-4"}>
                    Congratulations, you've referred your child to aptihealth!
                </div>
                <div>Complete the final steps below to start care.</div>
            </div>
            <div className={"d-md-flex mx-3 my-5"}>
                <div className={"program-details-icon-container"}>
                    <img
                        src={images("./patient/teens_and_kids_program/browser_with_at_symbol.svg")}
                        alt={"general info"}
                        className={"program-complete-icon"}
                    />
                </div>
                <div>
                    <div className={"txt-pri fw-bold fs-20"}>
                        Have your child confirm their account
                    </div>
                    <div data-private={"true"}>
                        An email has been sent to {email} with instructions for creating their
                        password and logging into their account.
                    </div>
                </div>
            </div>
            <div className={"d-md-flex mx-3 my-5"}>
                <div className={"program-details-icon-container"}>
                    <img
                        src={images("./patient/teens_and_kids_program/laptop_with_avatar.svg")}
                        alt={"general info"}
                        className={"program-complete-icon"}
                    />
                </div>
                <div>
                    <div className={"txt-pri fw-bold fs-20"}>
                        Join the session from your child’s account
                    </div>
                    <div>
                        Please join your child in their therapist consultation session from your{" "}
                        <span className={"fw-bold"}>child’s aptihealth account.</span>
                    </div>
                </div>
            </div>
            <div className={"d-flex justify-content-end pb-4"}>
                <Button className={"Btn Btn--pri"} onClick={nextStep}>
                    Finish
                </Button>
            </div>
        </div>
    );
};

export default connect(null, {})(CompleteChildWorkflow);
