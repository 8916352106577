// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Fragment } from "react";
import images from "../../../utils/images";
import Hr from "../../UI/Hr";

const ContextMenu = ({ position, actions, sheduleDetails, isEditDisabled }) => {
    const {
        provider_avatar,
        provider_name,
        provider_username,
        callId,
        scheduledDate,
        scheduledTime,
        is_AM,
        allotted_time,
    } = sheduleDetails;
    const desktopStyles = {
        top: position ? `${position.top}px` : "50%",
        left: position ? `${position.left}px` : "50%",
        transform: !position && "translate(-50%,-50%)",
    };

    const disabledMenuItemClassName = `MenuPopUp__li${isEditDisabled ? "__disabled" : " cursor"}`;

    const mobileStyles = {
        bottom: "0",
        left: "0",
    };
    /**
     * TODO: add triangular notch on pop up
     */
    const desktopView = (
        <div
            style={desktopStyles}
            className="ASCH__Desktop-context-menu pl-3 d-none d-lg-flex align-items-center position-fixed">
            <ul className="ASCH__Desktop-context-menu--list">
                <li
                    onClick={() => {
                        if (isEditDisabled) {
                            return;
                        }

                        actions.edit.handler(
                            provider_username,
                            callId,
                            allotted_time,
                            sheduleDetails,
                        );
                    }}
                    aria-disabled={isEditDisabled}
                    className={`${disabledMenuItemClassName} my-3`}>
                    {actions.edit.caption}
                </li>
                <li
                    onClick={() => actions.delete.handler(sheduleDetails)}
                    className="MenuPopUp__li cursor my-3">
                    {actions.delete.caption}
                </li>
            </ul>
            <div className="ASCH__Desktop-context-menu--cancellation-policy">
                <h3 className="ASCH__Desktop-context-menu--header">Our Policy:</h3>
                <p className="ASCH__Desktop-context-menu--text">
                    aptihealth requires 24 hours' notice when making appointment changes, which
                    includes the cancellation and rescheduling of appointments.
                </p>
                <p className="ASCH__Desktop-context-menu--text">
                    Providing less than 24 hours' notice, or skipping the appointment completely,
                    will be considered a cancellation of the scheduled appointment and may result in
                    a fee.
                </p>
                <p className="ASCH__Desktop-context-menu--text">
                    Making excessive appointment changes may result in a change of scheduling
                    options.
                </p>
            </div>
        </div>
    );
    const mobileView = (
        <div className="ASCH__Mobile-context-menu-wpr d-block d-lg-none position-fixed">
            <div style={mobileStyles} className="ASCH__Mobile-context-menu position-absolute">
                {/* <SheduleListItemMobile {...sheduleDetails}/> */}
                <div className="ASCH__Mobile-context-menu-schedule-details px-4 pt-4 pb-3 d-flex flex-column">
                    <div className="d-flex w-100 mb-3 align-items-center">
                        <div className="mr-3">
                            <img
                                width="36"
                                src={provider_avatar || images("./common/avatar.png")}
                                alt="provider's avatar"
                            />
                        </div>
                        <div className="w-100">
                            <div className="fs-16 fw-bold">{provider_name}</div>
                            <div className="fs-14 ASCH-text-gry">Behavioral Health Specialist</div>
                        </div>
                    </div>
                    <div className="ml-5 pl-1 position-relative">
                        <Hr />
                        <div className="d-flex text-center justify-content-center mt-2 fs-14">
                            <div className="w-50">
                                <span className="ASCH-text-sec fw-bold">Today</span>
                                <br />
                                <span className="fs-16">{scheduledDate}</span>
                            </div>
                            <div className="w-50">
                                <span className="ASCH-text-sec fw-bold">
                                    {is_AM ? "Morning" : "Evening"}
                                </span>
                                <br />
                                <span className="fs-16">{scheduledTime}</span>
                            </div>
                        </div>
                        <div className="ASCH__veritcal-bar position-absolute"></div>
                    </div>
                </div>
                {/* actions list */}
                <div className="ASCH__Mobile-context-menu-action-list-wpr">
                    <ul className="px-4">
                        <li onClick={() => actions.delete.handler(sheduleDetails)} className="py-3">
                            {actions.delete.caption}
                        </li>
                        <li
                            onClick={() => {
                                if (isEditDisabled) {
                                    return;
                                }
                                actions.edit.handler(
                                    provider_username,
                                    callId,
                                    allotted_time,
                                    sheduleDetails,
                                );
                            }}
                            aria-disabled={isEditDisabled}
                            className={`${disabledMenuItemClassName} py-3`}>
                            {actions.edit.caption}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );

    return (
        <Fragment>
            {desktopView}
            {mobileView}
        </Fragment>
    );
};

export default ContextMenu;
