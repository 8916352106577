import React, { useState } from "react";
import PostSessionSurveyQuestion from "./PostSessionSurveyQuestion";
import _ from "lodash";
import { api } from "../../../../../APIRequests";
import Button from "../../../../../components/UI/Button";
import moment from "moment";

const PostSessionSurvey = (props) => {
    const { questionSet, callId } = props;
    const [questions, setQuestions] = useState(_.get(questionSet, "questions", []));
    const pssStartedDateTime = moment().format("YYYY-MM-DDTHH:mm:ss");

    const handleEditChange = (e, index, key) => {
        let clonedQuestions = _.cloneDeep(questions);
        if (clonedQuestions[index][key] === parseInt(e.target.value)) {
            clonedQuestions[index][key] = undefined;
        } else {
            clonedQuestions[index][key] = parseInt(e.target.value);
        }
        setQuestions(clonedQuestions);
    };

    const submitHandler = async () => {
        const submissionQuestions = _.cloneDeep(questions);
        submissionQuestions["started_date_time"] = pssStartedDateTime;
        const data = {
            patientId: props.profile.username,
            callId: callId,
            post_session_survey: submissionQuestions,
        };
        await api.shared.post_ratings_v2({ data });
        props.submit();
    };

    const questionNotAnswered = (question) => question.answer == null;
    const disableSubmitButton = Object.values(questions).some(questionNotAnswered);

    const render = Object.keys(questions).map((questionKey, index) => {
        return (
            <PostSessionSurveyQuestion
                handleEditChange={handleEditChange}
                surveyQuestion={questions[questionKey]}
                questionIndex={questionKey}
                key={index}
            />
        );
    });

    return (
        <div className="container">
            <div className="survey-back-link-wpr mx-auto my-3 py-2">
                <h3 className={"font-color-gray fw-bold"}>How would you rate today’s session?</h3>
                <p className={"font-color-gray fs-14"}>
                    We care about your progress and want to make sure you’re set up for success. Let
                    us know how we’re doing by answering the questions below.
                </p>
                {render}
                <div className="d-flex justify-content-end mt-3">
                    <Button
                        type={"button"}
                        onClick={submitHandler}
                        disabled={disableSubmitButton}
                        className="Btn Btn--pri Btn-sm">
                        Submit
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PostSessionSurvey;
