import React from "react";
import { Text } from "../Text";
import { Image } from "../Image";

import "./styles.scss";

export const Header = ({ ...props }) => {
    return (
        <>
            <header className="apti-Header">
                <Image className="apti-Header__logo" src="logo_lg.svg" />
            </header>
        </>
    );
};
