import CardSecondary from "../../UI/Card/CardSecondary";
import images from "../../../utils/images";
import React from "react";

export const ProviderBanner = (props) => {
    const { clickUrl, headerText, bodyText } = props;

    return (
        <CardSecondary className="pt-2 px-3 px-lg-4 banner">
            <div className="row" onClick={() => window.open(clickUrl, "_blank")}>
                <div className="col-lg-6">
                    <div className="mt-3">
                        <div className={"fs-18 mb-3"}>{headerText}</div>
                        <div className={"fs-1"}>{bodyText}</div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <img
                        className="banner-img"
                        src={images("./provider/banner.svg")}
                        alt={"banner"}
                    />
                </div>
            </div>
        </CardSecondary>
    );
};
