import moment from "moment";
import { hasOverlap } from "./calendar/dates";

export class DateTimeWindow {
    /**
     *
     * @param {import("moment").Moment} start
     * @param {import("moment").Moment} end
     * @param {*} information additional information that should be persisted on the object
     * @param {*} isValid
     */
    constructor(start, end, information = null, isValid = true) {
        this.start = start;
        this.end = end;
        this.isValid = isValid;
        this.information = information;
        if (this.start.isAfter(this.end) || this.end.isBefore(this.start)) {
            this.isValid = false;
        }
    }

    toObject() {
        return {
            start: this.start.format("YYYY-MM-DD HH:mm"),
            end: this.end.format("YYYY-MM-DD HH:mm"),
            isValid: this.isValid,
            information: this.information,
        };
    }

    copy() {
        return new DateTimeWindow(this.start, this.end, this.information, this.isValid);
    }

    /**
     *
     * @param {DateTimeWindow} otherWindow
     * @returns
     */
    hasOverlapWith(otherWindow) {
        return hasOverlap(this.start, this.end, otherWindow.start, otherWindow.end);
    }

    /**
     *
     * @param {DateTimeWindow} otherWindow
     */
    removeIntersection(otherWindow) {
        // removes the intersection of self and other and returns a modified version of self between two DateTimeWindows
        // self: the DateTimeWindow where the intersection is removed from
        // other: the DateTimeWindow that determines where the intersection should be removed from
        // examples:
        // reduction
        // self    : |    xxxxxxx |
        // other   : | xxxxxx     |
        // return  : |      xxxxx |

        // split reduction
        // self    : |    xxxxxxx |
        // other   : |      xxx   |
        // return1 : |    xxx     |
        // return2 : |        xxx |

        // removal- sets isValid to false
        // self    : |    xxxxxxx |
        // other   : |   xxxxxxxxx|
        // return  : |    xxxxxxx |
        if (!this.isValid) {
            return;
        }
        // self    : |    xxxxxxx |
        // other   : |      xxx   |
        // new self: |    xxx     |
        // return  : |        xxx |
        if (otherWindow.start.isAfter(this.start) && otherWindow.end.isBefore(this.end)) {
            return [
                new DateTimeWindow(this.start, otherWindow.start, this.information),
                new DateTimeWindow(otherWindow.end, this.end, this.information),
            ];
        }
        // self    : |    xxxxxxx |
        // other   : | xxxxxx     |
        // new self: |      xxxxx |
        else if (otherWindow.end.isAfter(this.start) && otherWindow.end.isBefore(this.end)) {
            return [new DateTimeWindow(otherWindow.end, this.end, this.information)];
        }
        // self    : |    xxxx    |
        // other   : |      xxxx  |
        // new self: |    xxx     |
        else if (otherWindow.start.isBefore(this.end) && otherWindow.start.isAfter(this.start)) {
            return [new DateTimeWindow(this.start, otherWindow.start, this.information)];
        }
        // self    : |    xxxxxxx |
        // other   : |   xxxxxxxxx|
        // new self: |    xxxxxxx |
        else if (
            otherWindow.start.isSameOrBefore(this.start) &&
            otherWindow.end.isSameOrAfter(this.end)
        ) {
            return [new DateTimeWindow(this.start, this.end, this.information, false)];
        }

        return [];
    }

    /**
     *
     * @param {DateTimeWindow} window1
     * @param {DateTimeWindow} window2
     * @returns
     */
    static canBeCombined(window1, window2) {
        return (
            window1.hasOverlapWith(window2) ||
            window1.start.isSame(window2.end) ||
            window1.end.isSame(window2.start)
        );
    }

    /**
     *
     * @param {DateTimeWindow[]} windowList
     * @returns
     */
    static combineOverlappingWindows(windowList) {
        const combinedWindows = [];

        // Sort the input windows by their start time
        const sortedWindows = windowList.slice().sort((a, b) => a.start.diff(b.start));

        let currentWindow = null;

        for (const window of sortedWindows) {
            if (currentWindow === null) {
                currentWindow = new DateTimeWindow(window.start, window.end, window.information);
            } else {
                if (DateTimeWindow.canBeCombined(window, currentWindow)) {
                    // Combine the overlapping windows
                    currentWindow.start = moment.min(currentWindow.start, window.start);
                    currentWindow.end = moment.max(currentWindow.end, window.end);
                } else {
                    // No overlap, add the current window to the result and update the current window to be the window
                    combinedWindows.push(currentWindow);
                    currentWindow = new DateTimeWindow(
                        window.start,
                        window.end,
                        window.information,
                    );
                }
            }
        }

        if (currentWindow !== null) {
            // Add the last window to the result
            combinedWindows.push(currentWindow);
        }

        return combinedWindows;
    }
    /**
     * Function to reduce window1 by window2
     * @param {DateTimeWindow[]} windowsA
     * @param {DateTimeWindow[]} windowsB
     * @returns {DateTimeWindow[]}
     */
    static reduceWindowsAByWindowsB(windowsA, windowsB) {
        let newReducedWindowA = windowsA.map((window) => {
            return window.copy();
        });
        // iterate through all conflict windows and eliminate used
        // adhoc availability
        for (const currentWindowB of windowsB) {
            // find all availability windows that conflict with
            // the current conflict window
            const conflictingWindowA = newReducedWindowA.filter((currentWindowA) =>
                currentWindowB.hasOverlapWith(currentWindowA),
            );
            // iterate through all found conflicting availability windows
            // and reduce them by the intersection
            for (const conflictWindowA of conflictingWindowA) {
                const result = conflictWindowA.removeIntersection(currentWindowB);
                // queue removal of old window
                conflictWindowA.isValid = false;
                // add results to be reduced further
                newReducedWindowA.push(...result);
            }
            // remove invalid windows
            newReducedWindowA = newReducedWindowA.filter((windowA) => windowA.isValid);
        }
        // combine overlaps to have the longest possible spanning availability windows
        const resultingWindows = DateTimeWindow.combineOverlappingWindows(newReducedWindowA);
        return resultingWindows;
    }
}
