// Copyright aptihealth, inc. 2024 All Rights Reserved

import React from "react";
import "./styles.scss";
import * as PropTypes from "prop-types";
import Button from "../Button";

export const LinkIconColor = {
    TEAL: "teal",
    PURPLE: "purple",
};

const LinkIcon = ({onClick, icon, label, color}) => {
    return (
        <Button className={`LinkIcon ${color}`} onClick={onClick}>
            <div className={`LinkIcon--icon ${color}`}>
                {icon}
            </div>
            <p className="LinkIcon--label">
                {label}
            </p>
        </Button>
    );
};

LinkIcon.propTypes = {
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.element.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf(Object.values(LinkIconColor)).isRequired,
};

LinkIcon.defaultProps = {
    onClick: () => {},
    icon: undefined,
    label: "",
    color: undefined,
};

export default LinkIcon;
