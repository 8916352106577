import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { createClassNameString } from "../utils";
import { PageBanner } from "../PageBanner/PageBanner";
import { Text } from "../Text/Text";
import { Button } from "../Button";
import { ButtonTypes } from "../constants";

export const PatientPaymentBanner = ({ className }) => {
    
    const history = useHistory();
    
    return (
        <PageBanner className={createClassNameString(["PatientPaymentBanner-banner", className])}>
            <Text data-public={"true"}>
                Reminder: A payment method must be kept on file to continue using aptihealth. 
            </Text>
            <Button
                className={"PatientPaymentBanner-button"}
                buttonType={ButtonTypes.primaryOutlineV2}
                onClick={() => history.push("/app/patient-payment")}
                data-public={"true"}>
                Add Card
            </Button>
        </PageBanner>
    );
};

PatientPaymentBanner.propTypes = {
    className: PropTypes.string,
};
