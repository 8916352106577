// Copyright aptihealth, inc. 2021 All Rights Reserved
import images from "../../../../utils/images";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import FormikInputFormat from "../../../UI/formikMobileInput";
import FormikInput from "../../../UI/FormikInput";
import Button from "../../../UI/Button";
import { api } from "../../../../APIRequests";
import { withRouter } from "react-router-dom";
import { STATES } from "../../../../constants/states";
import { connect } from "react-redux";
import { showAlertWithAction } from "../../../../redux/actions/alerts";
import moment from "moment";
import { dobValidationSchema } from "../../../../containers/Dashboard/Providers/schema/newPatientValidation";
import { CustomForm } from "../../../../component-library/CustomForm";

const DEMOGRAPHIC_CHECK_USER_AGREEMENT =
    "By clicking the check box below, I consent by electronic signature " +
    "to receive marketing and other calls, text messages (including SMS and MMS messages), and other electronic " +
    "messages (including those made using an automatic telephone dialing system or an artificial or prerecorded " +
    "voice) from aptihealth, healthcare providers with whom I am matched by aptihealth, or any of their agents " +
    "or representatives at the phone number I have provided even if that number is registered on a corporate, " +
    "state, or national do not call registry. I understand that my consent to such calls and messages is not " +
    "required to use aptihealth’s services and that I may contact aptihealth at support@aptihealth.com or " +
    "888-454-3827 about its services at any time. I also understand that I may revoke my consent at any time.";

const DemographicEligibilityCheck = (props) => {
    const { sharedWorkflowData, setSharedWorkflowData } = props;

    const [userAgreementIsChecked, setUserAgreementIsChecked] = useState(false);
    const [eligibleStates, setEligibleStates] = useState(STATES);

    useEffect(() => {
        (async () => {
            const options = { params: { showLoader: true } };
            const eligibleStatesResponse = await api.shared.eligible_states({ options });
            const states = eligibleStatesResponse.eligible_states;
            if (states) {
                states.push("Other");
                setEligibleStates(
                    states.map((state) => {
                        return { value: state, display: state };
                    }),
                );
            }
        })();
    }, []);

    const submitHandler = async (formData, actions) => {
        try {
            const options = { params: { showLoader: true } };
            const data = {
                dob: moment(formData.dob, "MMDDYYYY").format("MM/DD/YYYY"),
                state_of_residence: formData.state_of_residence,
                first_name: formData.first_name,
                last_name: formData.last_name,
                mobile: formData.mobile,
                user_agreement: userAgreementIsChecked,
                workflow_instance_entity_id: sharedWorkflowData.workflow_instance.entity_id,
                workflow_instance_sort_key: sharedWorkflowData.workflow_instance.sort_key,
            };
            const response = await api.shared.demographic_eligibility({ options, data });
            setSharedWorkflowData({
                ...sharedWorkflowData,
                workflow_instance: response.workflow_instance,
            });
        } catch (e) {
            console.log(e);
            props.showAlertWithAction("Error checking eligibility.");
        }
    };

    const renderForm = (formikProps) => {
        const formikInputProps = {
            inputClasses: "Input--sm",
            errors: formikProps.errors,
            touched: formikProps.touched,
        };
        return (
            <Form style={{ marginBottom: 200 }}>
                <div className={"workflow-container-sm"}>
                    <div className="mx-auto px-0 mb-1">
                        <div className="px-0 row pb-2">
                            <div className={"col-12"}>
                                <FormikInput
                                    {...formikInputProps}
                                    formEl={{
                                        name: "first_name",
                                        elementType: "input",
                                        elementConfig: {
                                            type: "text",
                                            label: "First Name",
                                            placeholder: "Enter First Name",
                                            labelClasses: "no-margin",
                                        },
                                    }}
                                />
                            </div>

                            <div className={"col-12"}>
                                <FormikInput
                                    {...formikInputProps}
                                    formEl={{
                                        name: "last_name",
                                        elementType: "input",
                                        elementConfig: {
                                            type: "text",
                                            label: "Last Name",
                                            placeholder: "Enter Last Name",
                                            labelClasses: "no-margin",
                                        },
                                    }}
                                />
                            </div>

                            {!sharedWorkflowData.workflow_instance.state.dob && (
                                <div className={"col-12"}>
                                    <FormikInputFormat
                                        {...formikInputProps}
                                        formEl={{
                                            name: "dob",
                                            elementType: "input",
                                            elementConfig: {
                                                type: "text",
                                                label: "Date of Birth",
                                                placeholder: "MM/DD/YYYY",
                                                labelClasses: "no-margin",
                                            },
                                        }}
                                        value={formikProps && formikProps.values["dob"]}
                                        onChange={async (val) => {
                                            await formikProps.setFieldValue("dob", val.value);
                                            formikProps.setFieldTouched("dob");
                                        }}
                                    />
                                </div>
                            )}

                            <div className={"col-12"}>
                                <FormikInput
                                    {...formikInputProps}
                                    formEl={{
                                        name: "state_of_residence",
                                        elementType: "select",
                                        elementConfig: {
                                            options: eligibleStates,
                                            label: "State of Residence",
                                            placeholder: "Enter State",
                                            labelClasses: "no-margin",
                                        },
                                    }}
                                />
                            </div>

                            <div className={"col-12"}>
                                <FormikInputFormat
                                    {...formikInputProps}
                                    formEl={{
                                        name: "mobile",
                                        elementType: "input",
                                        elementConfig: {
                                            type: "text",
                                            label: "Mobile",
                                            placeholder: "Enter Mobile",
                                            labelClasses: "no-margin",
                                        },
                                    }}
                                    value={formikProps && formikProps.values["mobile"]}
                                    onChange={async (val) => {
                                        await formikProps.setFieldValue("mobile", val.value);
                                        formikProps.setFieldTouched("mobile");
                                    }}
                                />
                            </div>

                            <div className={"col-12"}>
                                <FormikInputFormat
                                    {...formikInputProps}
                                    formEl={{
                                        name: "mobile_validation",
                                        elementType: "input",
                                        elementConfig: {
                                            type: "text",
                                            label: "Confirm Mobile",
                                            placeholder: "Re-enter Mobile",
                                            labelClasses: "no-margin",
                                        },
                                    }}
                                    value={formikProps && formikProps.values["mobile_validation"]}
                                    onChange={async (val) => {
                                        await formikProps.setFieldValue(
                                            "mobile_validation",
                                            val.value,
                                        );
                                        formikProps.setFieldTouched("mobile_validation");
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"col-12"}>
                    <div className={"scrollable-text-area txt-gry"}>
                        {DEMOGRAPHIC_CHECK_USER_AGREEMENT}
                    </div>
                </div>

                <div className={"workflow-container-sm"}>
                    <div className="mx-auto px-0 mb-1">
                        <div className="px-0 row pb-2">
                            <div className={"col-12 txt-gry"}>
                                <FormikInput
                                    {...formikInputProps}
                                    formEl={{
                                        name: "user_agreement",
                                        elementType: "input",
                                        onclick: () =>
                                            setUserAgreementIsChecked(!userAgreementIsChecked),
                                        elementConfig: {
                                            type: "checkbox",
                                            displayName: "I agree to the mobile terms listed above",
                                        },
                                    }}
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-between mb-5">
                            <Button
                                type={"button"}
                                className={"Btn Btn--otl-gry Btn--sm-2 workflow-btn"}
                                onClick={() => props.backHandler()}>
                                Back
                            </Button>
                            <Button
                                type={"submit"}
                                disabled={!userAgreementIsChecked ? "disabled" : null}
                                className={"Btn Btn--pri Btn--sm-2 workflow-btn"}>
                                Continue
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <>
            <div className={"p-lg-5 p-3"}>
                <div className={"fs-22 txt-pri fw-bold mb-3 text-center"}>
                    Tell us about yourself
                </div>
                <div className={"text-center mt-3 mb-4"}>
                    <img
                        src={images("./common/workflow/ed-workflow-1-demo-check-active.png")}
                        alt={"Eligibility Check"}
                    />
                </div>
                {sharedWorkflowData && sharedWorkflowData.workflow_instance && (
                    <CustomForm
                        initialValues={{
                            first_name: "",
                            last_name: "",
                            dob: sharedWorkflowData.workflow_instance.state.dob
                                ? moment(
                                      sharedWorkflowData.workflow_instance.state.dob,
                                      "MM/DD/YYYY",
                                  ).format("MMDDYYYY")
                                : "",
                            state_of_residence: "",
                            mobile: "",
                            mobile_validation: "",
                            user_agreement: false,
                        }}
                        validationSchema={demographicValidation}
                        onSubmit={submitHandler}
                        render={renderForm}
                    />
                )}
            </div>
        </>
    );
};

export default connect(null, { showAlertWithAction })(withRouter(DemographicEligibilityCheck));

const demographicValidation = yup.object().shape({
    first_name: yup
        .string()
        .required("First name is required")
        .matches(/^[a-zA-Z-]+$/, "Numerals, spaces & special characters are not allowed"),
    last_name: yup
        .string()
        .required("Last name is required")
        .matches(/^[a-zA-Z-]+$/, "Numerals, spaces & special characters are not allowed"),
    dob: dobValidationSchema.required("Date of birth is required"),
    state_of_residence: yup.string().required("State of residence is required").nullable(),
    mobile: yup
        .string()
        .matches(/^\d{10}$/, "Contact number must be a valid US number")
        .required("Mobile number is required"),
    mobile_validation: yup
        .string()
        .matches(/^\d{10}$/, "Contact number must be a valid US number")
        .required("Mobile number is required")
        .test(
            "mobile-match",
            "Mobile number does not match, please check the number",
            function (value) {
                if (!value) {
                    return false;
                }
                return this.parent.mobile === value;
            },
        ),
});
