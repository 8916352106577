import React, { Component } from "react";
import CardSecondary from "../../UI/Card/CardSecondary";
import "./styles.scss";
import { Rating } from "../../UI/Rating";
import { Form, Formik } from "formik";
import FormikInput from "../../UI/FormikInput";
import { cloneDeep } from "lodash";
import { getQueryParams } from "../../../utils/filters";
import Button from "../../UI/Button";
import images from "../../../utils/images";
import { connect } from "react-redux";
import { api } from "../../../APIRequests";
import { CustomForm } from "../../../component-library/CustomForm";

class VideoCallRating extends Component {
    constructor(props) {
        super(props);
        this.state = {
            callId: getQueryParams("callId", this.props.match.search),
            rating: 0,
            ratingProblemAnswers: cloneDeep(RATING_SCHEMA),
            area: 0,
        };
    }

    onStarClick = async (rating) => {
        await this.setState({ rating });

        if (rating > 3) {
            this.submitHandler({ rating_problem_map: {} });
        }
    };

    checkBoxHandler = (group, option) => {
        let options = cloneDeep(this.state.ratingProblemAnswers);
        let index = options.findIndex((q) => q.name === group);
        options[index].elementConfig.options.forEach((o) => {
            o.checked =
                (o.value === option.value && !o.checked) || (o.value !== option.value && o.checked);
        });

        this.setState({ ratingProblemAnswers: options });
    };

    renderForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 ">
                    <fieldset>
                        <div className="container">
                            <div className="row">
                                <div className="mx-lg-2 mt-4 pt-2 px-lg-2">
                                    {[RATING_SCHEMA[0]].map((q, i) => {
                                        q.onchange = q.onclick = this.checkBoxHandler;
                                        return (
                                            <div key={i + q.name} className="col-12 col-lg-12">
                                                <FormikInput
                                                    clinicialTextbox="ProblemOptions"
                                                    formEl={q}
                                                    errors={formikProps.errors}
                                                    touched={formikProps.touched}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mb-3">
                                <Button type="submit" className="Btn Btn--pri Btn-sm">
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </Form>
        );
    };

    submitHandler = (data) => {
        data["callId"] = this.state.callId;
        data["rating"] = this.state.rating;

        this.state.ratingProblemAnswers.map((q) => {
            if (q.elementConfig.options) {
                let checkedOptions = q.elementConfig.options
                    .filter((o) => o.checked)
                    .map((o) => o.value);
                if (checkedOptions) {
                    data["rating_problem_map"][q.name] = checkedOptions;
                }
            }
        });

        api.shared.post_ratings({ data });
        this.props.history.push(this.props.authRedirectPath);
    };

    render() {
        return (
            <CardSecondary className="VideoCallRating">
                <p className="mt-5">
                    <b>Your Session is Over for Now</b>
                </p>
                <p className="fs-12">What would be your overall rating of the session?</p>
                <Rating currentRating={0} totalRating={5} onClick={this.onStarClick} />

                {this.state.rating !== 0 && this.state.rating <= 3 && (
                    <>
                        <CustomForm
                            initialValues={{
                                rating: 0,
                                rating_problem_map: {},
                            }}
                            onSubmit={this.submitHandler}
                            render={this.renderForm}
                        />
                    </>
                )}
            </CardSecondary>
        );
    }
}

const authRedirectPath = (state) => {
    return {
        authRedirectPath: state.auth.authRedirectPath,
    };
};

export default connect(authRedirectPath)(VideoCallRating);

export const RATING_SCHEMA = [
    {
        name: "video",
        elementType: "input",
        elementConfig: {
            type: "checkbox",
            label: "What went wrong",
            options: [
                { display: "Communication Unclear", value: "COMMUNICATION_UNCLEAR" },
                { display: "Audio/Video Didn’t Connect", value: "AUDIO_VIDEO_DID_NOT_CONNECT" },
                { display: "Therapist Not Supportive", value: "THERAPIST_NOT_SUPPORTIVE" },
                { display: "Confused On Purpose", value: "CONFUSED_ON_PURPOSE" },
                { display: "Not Enough Time", value: "NOT_ENOUGH_TIME" },
                { display: "Other audio issue", value: "OTHER_AUDIO_ISSUE" },
            ],
        },
    },
];
