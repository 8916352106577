import React from "react";
import PropTypes from "prop-types";

import { Card } from "../Card";
import { Portal } from "../Portal";

import { createClassNameString } from "../utils";

import "./styles.scss";

export const Modal = ({ children, className, show, cardClassName, ...props }) => (
    <Portal>
        <div
            className={createClassNameString(["apti-Modal", show ? "show" : undefined, className])}>
            <Card
                className={createClassNameString([cardClassName, "apti-Modal__dialog"])}
                {...props}>
                {children}
            </Card>
        </div>
    </Portal>
);

Modal.propTypes = {
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
    show: PropTypes.bool,
};

Modal.defaultProps = {
    children: <></>,
    className: undefined,
    show: false,
};
