import * as yup from "yup";
import {
    _11_20_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98967,
    _21_30_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98968,
    _30_MIN_INDIVIDUAL_90832,
    _45_MIN_INDIVIDUAL_90834,
    _5_10_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98966,
    _60_MIN_INDIVIDUAL_90837,
    allottedTimeFactory,
    BEHAVIORAL,
    BEHAVIORAL_INTAKE,
    CM_SERVICES_20_MINS_MINIMUM_MONTH,
    CRISIS_EVAL_90839,
    CRISIS_EVAL_EXTENDED_90840,
    ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215,
    FAMILY_THERAPY_90847,
    FAMILY_THERAPY_WO_PATIENT_90846,
    hourArray,
    IN_APP_MESSAGING_98971,
    MEDICATION_MGMT_99212,
    MEDICATION_MGMT_99213,
    MEDICATION_MGMT_99214,
    minuteArray,
    OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
    OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
    PHQ_OR_GAD_96127,
    PRESCRIBE,
} from "../../../ScheduleAvailability/constants";

import { MaxDateValidation } from "../../../../../component-library/validation";

export const CASE_MANAGEMENT_INITIAL_VALUES = {
    patient_id: "",
    communication_type: "",
    patient_first_name: "",
    patient_last_name: "",
    provider_id: "",
    provider_type: "",
    provider_name: "",
    signature: "",
    provider_cp_credentials: "",
    session_from_hr: "",
    session_from_min: "",
    session_from_am_pm: "",
    session_date: "",
    call_type: "",
    is_billable: false,
    is_processed: false,
    version: 6,
    note_type: "Case Management",
    title: "Case Management Note",
    content: {
        communication_type: "",
        reason_for_communication: [],
        reason_for_communication_other: "",
        communication_and_plan: "",
        encounters: [],
    },
    ICD_10_axis_1_diagnosis: [],
    ICD_10_axis_2_diagnosis: [],
};

export const CASE_MANAGEMENT_VALIDATION = yup.object().shape({
    session_date: MaxDateValidation,
    session_from_hr: yup.string().required("Required"),
    session_from_min: yup.string().required("Required"),
    session_from_am_pm: yup.string().required("Required"),
    call_type: yup.string().required("Required"),
    signature: yup.string().required("Required"),
    provider_cp_credentials: yup.string().required("Required"),
    content: yup.object().shape({
        communication_type: yup.string().required("Required"),
        reason_for_communication: yup.string().required("Required"),
        communication_and_plan: yup.string().required("Required"),
        encounters: yup.array().of(
            yup.object().shape({
                encounter_date: yup.string().required("Required").nullable(),
                encounter_type: yup.string().required("Required").nullable(),
                setting: yup.string().required("Required").nullable(),
            }),
        ),
    }),
    ICD_10_axis_1_diagnosis: yup.string().required("Required").nullable(),
});

export const CASE_MANAGEMENT_SCHEMA = [
    {
        name: "content.communication_type",
        elementType: "select",
        elementClasses: "col-12 col-lg-3",
        inputClasses: "Input--sm-dropdown",
        elementConfig: {
            options: [
                { value: "Call", display: "Call" },
                { value: "Text", display: "Text" },
                { value: "Email", display: "Email" },
                { value: "apti Messaging", display: "apti Messaging" },
                { value: "On Call", display: "On Call" },
            ],
            placeholder: "Select",
            label: "Communication Type",
        },
    },
    {
        name: "session_date",
        elementType: "datePicker",
        elementClasses: "col-12 col-lg-3 col-md-3",
        inputClasses: "Input--sm",
        elementConfig: {
            type: "text",
            label: "Date",
            placeholder: "01/01/2020",
        },
    },
    {
        name: "session_from_hr",
        elementType: "select",
        elementClasses: "col-12 col-lg-2 col-md-3",
        inputClasses: "Input--sm-dropdown",
        elementConfig: {
            sentenceCase: false,
            options: hourArray,
            label: "Hour",
            placeholder: "",
        },
    },
    {
        name: "session_from_min",
        elementType: "select",
        elementClasses: "col-12 col-lg-2 col-md-3",
        inputClasses: "Input--sm-dropdown",
        elementConfig: {
            sentenceCase: false,
            options: minuteArray,
            label: "Minute",
            placeholder: "",
        },
    },
    {
        name: "session_from_am_pm",
        elementType: "select",
        elementClasses: "col-12 col-lg-2 col-md-3",
        inputClasses: "Input--sm-dropdown",
        elementConfig: {
            sentenceCase: false,
            options: [
                { value: "am", display: "am" },
                { value: "pm", display: "pm" },
            ],
            label: "am/pm",
            placeholder: "",
        },
    },
    {
        name: "call_type",
        elementType: "select",
        elementClasses: "col-lg-9 col-12",
        inputClasses: "Input--sm-dropdown",
        elementConfig: {
            label: "CPT Description",
            placeholder: "Select",
            options: [
                // AT1-2848 Product team: "So we would maintain the CPT dropdown however the only option in that dropdown is the Non-Billable option
                // the intention behind that is that if they were to want to add codes in the future they could however right now to support VBC, we
                // would have the encounter documented but it wouldn't appear in the billable file attached to a CPT"
                // { value: _30_MIN_INDIVIDUAL_90832, display: `90832: 30 Minutes Individual`, provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE] },
                // { value: _45_MIN_INDIVIDUAL_90834, display: `90834: 45 Minutes Individual`, provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE] },
                // { value: _60_MIN_INDIVIDUAL_90837, display: `90837: 60 Minutes Individual`, provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE] },
                // { value: CRISIS_EVAL_90839, display: `90839: 15 Minutes Crisis evaluation`, provider_types: [PRESCRIBE] },
                // { value: CRISIS_EVAL_90839, display: `90839: 45 Minutes Crisis evaluation`, provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE] },
                // { value: CRISIS_EVAL_EXTENDED_90840, display: `90840: 75 Minutes Crisis Evaluation Extended`, provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE] },
                // { value: FAMILY_THERAPY_90847, display: `90847: 45 Minutes Family Therapy w/ Patient`, provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE] },
                // { value: FAMILY_THERAPY_WO_PATIENT_90846, display: `90846: 45 Minutes Family Therapy w/o Patient`, provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE] },
                // { value: _5_10_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98966, display: `98966: 5-10 Minutes Phone Assmt. And Mgmt.`, provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE, PRESCRIBE] },
                // { value: _11_20_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98967, display: `98967: 11-20 Minutes Phone Assmt. And Mgmt`, provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE, PRESCRIBE] },
                // { value: _21_30_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98968, display: `98968: 21-30 Minutes Phone Assmt. And Mgmt`, provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE, PRESCRIBE] },
                // { value: IN_APP_MESSAGING_98971, display: `98971: In App Messaging`, provider_types: [BEHAVIORAL, PRESCRIBE] },
                // { value: OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204, display: `99204: 60 Minutes Office or Other Outpatient Visit w/ New Patient`, provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE] },
                // { value: OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205, display: `99205: 75 Minutes Office or Other Outpatient Visit w/ New Patient`, provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE] },
                // { value: MEDICATION_MGMT_99212, display: `99212: 15 Minutes Medication Management`, provider_types: [PRESCRIBE] },
                // { value: MEDICATION_MGMT_99213, display: `99213: 15 Minutes Medication Management`, provider_types: [PRESCRIBE] },
                // { value: MEDICATION_MGMT_99214, display: `99214: 30 Minutes Medication Management`, provider_types: [PRESCRIBE] },
                // { value: ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215, display: `99215: 45 Minutes Established Patient Complex`, provider_types: [PRESCRIBE] },
                // { value: CM_SERVICES_20_MINS_MINIMUM_MONTH, display: `99484: 20 Minutes CM Services, Min 20 Minutes Calendar Month`, provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE, PRESCRIBE] },
                {
                    value: "non_billable",
                    display: "Non Billable",
                    provider_types: [BEHAVIORAL, BEHAVIORAL_INTAKE, PRESCRIBE],
                },
            ],
        },
    },
    {
        name: "content.reason_for_communication",
        elementType: "input",
        elementClasses: "col-12",
        errorClasses: "InputError--lg-2",
        elementConfig: {
            type: "checkbox",
            label: "Reason for Communication",
            optionClasses: "col-lg-3 col-12 py-1",
            options: [
                { value: "Appointment Changes", display: "Appointment Changes" },
                { value: "Medical", display: "Medical" },
                { value: "Acute Care Encounter", display: "Acute Care Encounter" },
                { value: "Medication", display: "Medication" },
                { value: "SUD", display: "SUD" },
                { value: "SDoH", display: "SDoH" },
                { value: "Outreach/Support", display: "Outreach/Support" },
                { value: "Care Team Collaboration", display: "Care Team Collaboration" },
                {
                    value: "Other (open text)",
                    display: "Other (open text)",
                    openText: true,
                },
            ],
        },
    },
    {
        name: "content.encounters",
        elementType: "encounters",
    },
    {
        name: "content.communication_and_plan",
        elementType: "textarea",
        elementClasses: "col-12",
        inputClasses: "Input--lg",
        errorClasses: "InputError--lg-2",
        elementConfig: {
            type: "textArea",
            label: "Communication & Plan",
            placeholder: "Type Comment...",
            showNewLineTip: true,
        },
    },
    {
        name: "ICD_10_axis_1_diagnosis",
        elementClasses: "col-12 col-lg-6",
        elementType: "reactselect",
        elementConfig: {
            options: [],
            label: "Primary Diagnosis (Provisional Diagnosis)",
            placeholder: "",
        },
    },
    {
        name: "ICD_10_axis_2_diagnosis",
        elementClasses: "col-12 col-lg-6",
        elementType: "reactselect",
        elementConfig: {
            options: [],
            label: "Secondary Diagnosis (Provisional Diagnosis)",
            placeholder: "",
        },
    },
    {
        name: "signature",
        elementType: "input",
        elementClasses: "col-12 col-lg-7",
        inputClasses: "Input--sm",
        elementConfig: {
            hideTopLabel: true,
            displayName: "Click to Sign",
            type: "checkbox",
            label: "Signature",
            classNames: "font-weight-bold",
        },
    },
    {
        name: "provider_cp_credentials",
        elementType: "input",
        elementClasses: "col-12 col-lg-3",
        inputClasses: "Input--sm",
        disabled: "disabled",
        elementConfig: {
            type: "text",
            label: "Credentials",
            placeholder: "",
        },
    },
];
