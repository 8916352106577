import { DISCHARGE_SCHEMA } from "./schema/CareCoordinationSchema";
import { Select, TextArea } from "../../../UI/StyledInput";
import { cloneDeep, get, isObject, remove } from "lodash";
import Input from "../../../UI/Input";
import images from "../../../../utils/images";
import DatePicker from "../../../UI/DatePicker";
import Button from "../../../UI/Button";
import React, { useState } from "react";
import _ from "lodash";
import moment from "moment-timezone";

export const EditableDischarge = (props) => {
    const {
        index,
        dischargeValues,
        setDischargeValues,
        showDischargeDatePickers,
        setShowDischargeDatePickers,
        dischargeCount,
        setDischargeCount,
        dateIndex,
        setDateIndex,
        classes,
    } = props;

    const pickNoteDate = (dateStr, eventType) => {
        let parts = dateStr.split("-");
        let session_date = parts[1] + "/" + parts[2] + "/" + parts[0];
        setDischargeValue("discharge_date", session_date, dateIndex);
        const datePickers = showDischargeDatePickers;
        if (eventType !== "monthChange") {
            datePickers[dateIndex] = false;
        }
        setShowDischargeDatePickers(datePickers);
    };

    const setDischargeValue = (elementName, value, index) => {
        const currentDischargeValues = cloneDeep(dischargeValues);
        if (!isObject(currentDischargeValues[index])) {
            currentDischargeValues[index] = {};
        }

        currentDischargeValues[index][elementName] = value;
        setDischargeValues(currentDischargeValues);
    };

    const discharge = DISCHARGE_SCHEMA.map((formEl) => {
        if (formEl.elementType === "select") {
            return (
                <div
                    key={formEl.name + index}
                    className={
                        classes && classes.colEditable ? classes.colEditable : "col-12 col-lg-7"
                    }>
                    <div className={"FormGroup"}>
                        <label className="Label">{formEl.elementConfig.label}</label>
                        <Select
                            placeholder={{
                                display: formEl.elementConfig.placeholder,
                                value: formEl.elementConfig.placeholder,
                            }}
                            value={get(dischargeValues[index], [formEl.name], "")}
                            changeHandler={(e) =>
                                setDischargeValue(formEl.name, e.target.value, index)
                            }
                            options={formEl.elementConfig.options}
                        />
                    </div>
                </div>
            );
        } else if (formEl.elementType === "datepicker") {
            return (
                <div
                    key={formEl.name + index}
                    className={
                        "d-flex " +
                        (classes && classes.colEditable ? classes.colEditable : "col-8 col-lg-4")
                    }>
                    <div key={`fg${index}`} className={"FormGroup"}>
                        <label className="Label">{formEl.elementConfig.label}</label>
                        <div className={"d-flex"}>
                            <Input
                                className={"Input"}
                                disabled
                                placeholder={formEl.elementConfig.placeholder}
                                value={get(dischargeValues[index], [formEl.name], "")}
                                onChange={(e) =>
                                    setDischargeValue(formEl.name, e.target.value, index)
                                }
                            />
                            <img
                                onClick={() => {
                                    if (showDischargeDatePickers.includes(true)) {
                                        return;
                                    }
                                    const datePickers = [...showDischargeDatePickers];
                                    datePickers[index] = true;
                                    setShowDischargeDatePickers(datePickers);
                                    setDateIndex(index);
                                }}
                                className="ml-2 img-fluid"
                                src={images("./icons/calender-icon.svg")}
                            />
                        </div>
                    </div>
                    {showDischargeDatePickers[index] && (
                        <div
                            key={`dp${index}`}
                            className="PrScheduleAvailability__DatePicker-wpr-absolute NoteDatePicker">
                            <div style={{ "margin-top": "80px" }}>
                                <DatePicker
                                    externalCtx={
                                        dischargeDateExists(dischargeValues, index)
                                            ? moment(
                                                  dischargeValues[index]["discharge_date"],
                                                  "MM/DD/YYYY",
                                              )
                                            : moment()
                                    }
                                    onDaySelect={pickNoteDate}
                                />
                            </div>
                        </div>
                    )}
                    <div
                        className={"discharge-delete-button"}
                        key={`crossContainer${index}`}
                        onClick={() => {
                            if (index === 0) {
                                return;
                            }
                            dischargeValues.splice(index, 1);
                            showDischargeDatePickers.splice(index, 1);
                            setDischargeCount(dischargeCount - 1);
                            setDischargeValues(dischargeValues);
                            setShowDischargeDatePickers(showDischargeDatePickers);
                        }}>
                        <img
                            key={`cross${index}`}
                            className="img-fluid"
                            src={images("./icons/cross.svg")}
                        />
                    </div>
                </div>
            );
        } else if (formEl.elementType === "textarea") {
            return (
                <div
                    key={formEl.name + index}
                    className={classes && classes.colEditable ? classes.colEditable : "col-11"}>
                    <div className={"FormGroup"}>
                        <label className="Label">{formEl.elementConfig.label}</label>
                        <TextArea
                            placeholder={formEl.elementConfig.placeholder}
                            ids={"Input"}
                            classes={"Input"}
                            onChange={(e) => setDischargeValue(formEl.name, e.target.value, index)}
                        />
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    });
    discharge.push(
        <Button
            key={index}
            className="col-6 ml-3 mb-4 new-discharge-button"
            onClick={() => {
                const datePickers = showDischargeDatePickers;
                datePickers.push(false);
                setDischargeCount(dischargeCount + 1);
                setShowDischargeDatePickers(datePickers);
            }}
            type="button">
            Add new discharge date
        </Button>,
    );
    return discharge;
};

const dischargeDateExists = (dischargeValues, index) =>
    dischargeValues && index < dischargeValues.length && dischargeValues[index]["discharge_date"];

export const EditableDischargeV2 = (props) => {
    const {
        index,
        dischargeValues,
        setDischargeValues,
        showDischargeDatePickers,
        setShowDischargeDatePickers,
        dateIndex,
        setDateIndex,
        classes,
    } = props;

    const [discharges, setDischarges] = useState(_.cloneDeep(dischargeValues));

    const pickNoteDate = (dateStr, eventType) => {
        let parts = dateStr.split("-");
        let session_date = parts[1] + "/" + parts[2] + "/" + parts[0];
        handleDischargeChange("discharge_date", session_date, dateIndex);
        const datePickers = showDischargeDatePickers;
        if (eventType !== "monthChange") {
            datePickers[dateIndex] = false;
        }
        setShowDischargeDatePickers(datePickers);
    };

    const handleDischargeChange = (elementName, value, index) => {
        const currentDischarges = cloneDeep(discharges);
        if (!isObject(currentDischarges[index])) {
            currentDischarges[index] = {};
        }

        currentDischarges[index][elementName] = value;
        setDischargeValues(currentDischarges);
        setDischarges(currentDischarges);
    };

    const addDischarge = () => {
        const clonedDischarges = _.cloneDeep(discharges);
        clonedDischarges.push({});
        setDischarges(clonedDischarges);
    };

    const removeDischarge = (index) => {
        const clonedDischarges = _.cloneDeep(discharges);
        if (discharges.length > 0) {
            clonedDischarges.splice(index, 1);
        } else {
            clonedDischarges.splice(index, 1, {});
        }
        setDischarges(clonedDischarges);
    };

    const getSelectElement = (formEl, rowValue, rowIndex) => {
        return (
            <div
                key={formEl.name + rowIndex}
                className={classes && classes.colEditable ? classes.colEditable : "col-6"}>
                <div className={"FormGroup"}>
                    <label className="Label">{formEl.elementConfig.label}</label>
                    <Select
                        placeholder={{
                            display: formEl.elementConfig.placeholder,
                            value: formEl.elementConfig.placeholder,
                        }}
                        value={rowValue}
                        changeHandler={(e) =>
                            handleDischargeChange(formEl.name, e.target.value, rowIndex)
                        }
                        options={formEl.elementConfig.options}
                    />
                </div>
            </div>
        );
    };

    const getDatepickerElement = (formEl, rowValue, rowIndex) => {
        return (
            <div
                key={formEl.name + rowIndex}
                className={classes && classes.colEditable ? classes.colEditable : "col-6"}>
                <div
                    className={"dischargev2-delete-button float-right cursor"}
                    key={`crossContainer${rowIndex}`}
                    onClick={(rowIndex) => {
                        removeDischarge(rowIndex);
                    }}>
                    <img
                        key={`cross${rowIndex}`}
                        className="img-fluid"
                        src={images("./icons/cross.svg")}
                    />
                </div>
                <div key={`fg${rowIndex}`} className={"FormGroup"}>
                    <label className="Label">{formEl.elementConfig.label}</label>
                    <div className={"d-flex"}>
                        <Input
                            className={"Input"}
                            disabled
                            placeholder={formEl.elementConfig.placeholder}
                            value={rowValue}
                            onChange={(e) =>
                                handleDischargeChange(formEl.name, e.target.value, rowIndex)
                            }
                        />
                        <img
                            onClick={() => {
                                const datePickers = [...showDischargeDatePickers];
                                datePickers[rowIndex] = !datePickers[rowIndex];
                                setShowDischargeDatePickers(datePickers);
                                setDateIndex(rowIndex);
                            }}
                            className="ml-2 img-fluid cursor"
                            src={images("./icons/calender-icon.svg")}
                        />
                    </div>
                </div>
                {showDischargeDatePickers[rowIndex] && (
                    <div
                        key={`dp${rowIndex}`}
                        className="PrScheduleAvailability__DatePicker-wpr-absolute NoteDatePicker">
                        <div>
                            <DatePicker
                                externalCtx={
                                    dischargeDateExists(dischargeValues, index)
                                        ? moment(
                                              dischargeValues[index]["discharge_date"],
                                              "MM/DD/YYYY",
                                          )
                                        : moment()
                                }
                                onDaySelect={pickNoteDate}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const getTextareaElement = (formEl, rowValue, rowIndex) => {
        return (
            <div
                key={formEl.name + rowIndex}
                className={classes && classes.colEditable ? classes.colEditable : "col-11"}>
                <div className={"FormGroup"}>
                    <label className="Label">{formEl.elementConfig.label}</label>
                    <TextArea
                        placeholder={formEl.elementConfig.placeholder}
                        ids={"Input"}
                        classes={"Input"}
                        onChange={(e) =>
                            handleDischargeChange(formEl.name, e.target.value, rowIndex)
                        }
                    />
                </div>
            </div>
        );
    };

    const buildDefaultView = (rowValue, rowIndex) => {
        let elements = {};

        DISCHARGE_SCHEMA.forEach((formEl) => {
            const value = get(rowValue, [formEl.name], "");
            if (formEl.elementType === "select") {
                elements[formEl.elementType] = getSelectElement(formEl, value, rowIndex);
            } else if (formEl.elementType === "datepicker") {
                elements[formEl.elementType] = getDatepickerElement(formEl, value, rowIndex);
            } else if (formEl.elementType === "textarea") {
                elements[formEl.elementType] = getTextareaElement(formEl, value, rowIndex);
            }
        });
        return (
            <div className="col">
                <div className="row">
                    {elements["select"]}
                    {elements["datepicker"]}
                </div>
                <div className="row">{elements["textarea"]}</div>
            </div>
        );
    };

    if (discharges.length === 0) {
        addDischarge();
    }

    const display = discharges.map((value, index) => buildDefaultView(value, index));

    return (
        <>
            {display}
            <Button
                key={index}
                className="col-6 ml-3 mb-4 new-discharge-button"
                onClick={() => {
                    addDischarge();
                    const datePickers = showDischargeDatePickers;
                    datePickers.push(false);
                    setShowDischargeDatePickers(datePickers);
                }}
                type="button">
                Add new discharge date
            </Button>
        </>
    );
};
