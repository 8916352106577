// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import SurveyQuestion from "./SurveyQuestion";
import images from "../../../../../utils/images";
import { Link } from "react-router-dom";
import "./styles.scss";
import { getQueryParams } from "../../../../../utils/filters";

const Survey = (props) => {
    return (
        <div className="container" data-public={"true"}>
            <div className="survey-back-link-wpr mx-auto fs-16 my-3 py-2">
                <Link className="survey-back-link" to={"/app/home"} data-public={"true"}>
                    <img className="img-fluid" src={images("./common/solidBckBtn.svg")} />
                    <p className="d-inline ml-2">Back to 90 Day Care Program</p>
                </Link>
            </div>
            <SurveyQuestion mode={getQueryParams("mode", props.location.search)} />
        </div>
    );
};

export default Survey;
