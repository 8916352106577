import React from "react";
import PropTypes from "prop-types";
import { Image } from "../../../component-library";
import { createClassNameString } from "../../../component-library";
import CalendarAppointmentPicker from "../CalendarAppointmentPicker/CalendarAppointmentPicker";
import CalendarAppointmentView from "../CalendarAppointmentView/CalendarAppointmentView";
import { resetAppointmentSelections, closeDrawer } from "../../../redux/actions/calendar";
import { connect } from "react-redux";

import "./styles.scss";

const CalendarDrawer = ({ className, direction, calendar, calendarRect, ...props }) => {
    const renderDrawerContent = () => {
        if (calendar.selectedEvents.length) {
            return calendar.selectedEvents.map((event) => (
                <CalendarAppointmentView event={event} calendarRect={calendarRect} />
            ));
        }

        return <CalendarAppointmentPicker />;
    };

    const onCloseClick = () => {
        props.resetAppointmentSelections();
        props.closeDrawer();
    };

    return (
        <div
            className={createClassNameString([
                `apti-CalendarAppointmentPickerDrawer-Custom apti-CalendarAppointmentPickerDrawer-${direction}`,
                className,
            ])}>
            <div className="w-100 d-flex justify-content-end">
                <Image
                    className="apti-CalendarAppointmentPickerDrawer--x-button pr-4 pt-2"
                    src={"icons/icon-close-outline.svg"}
                    onClick={onCloseClick}
                />
            </div>
            {renderDrawerContent()}
        </div>
    );
};

CalendarDrawer.propTypes = {
    className: PropTypes.string,
    direction: PropTypes.string,
    calendarRect: PropTypes.object,
};

CalendarDrawer.defaultProps = {
    className: undefined,
    direction: "right",
    calendarRect: undefined,
};

const mapStateToProps = (state) => {
    return {
        calendar: state.calendar,
    };
};

const mapDispatchToProps = {
    resetAppointmentSelections,
    closeDrawer,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDrawer);
