// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useState } from "react";
import CarePlanCard from "../CarePlanCard";
import CareGoalsCard from "../CarePlan/CareGoalsCard/CareGoalsCard";
import CarePlanCreation from "./CarePlanCreation";
import withRouter from "react-router-dom/es/withRouter";
import CareTeamCard, { checkIfProviderOnCareTeam } from "../CarePlan/CareTeamCard/CareTeamCard";
import "./style.scss";
import { A5AssessmentCard } from "../CarePlan/A5AssessmentCard/A5AssessmentCard";
import { CarePlanScreenings } from "../CarePlan/CarePlanScreenings";
import { useCareTeam } from "../../../../utils/hooks/useCarePlan";
import { useSelector } from "react-redux";
import DemographicsCard from "../DemographicCard";
import { isUserPCP } from "../../../../redux/actions/auth";
import { PatientGoalsStrengthsCautions } from "../CarePlan/PatientGoalsStrengthsCautions/PatientGoalsStrengthsCautions";
import { getQueryParams } from "../../../../utils/filters";

const MemberProfileCarePlan = (props) => {
    const openLegacyGoals = getQueryParams("openLegacyGoals", props.location.search);

    const profile = useSelector((state) => state.auth.profile);
    const privateFlags = useSelector((state) => state.flags.privateFlags);
    const privateFlagsHaveLoaded = useSelector((state) => state.flags.privateFlagsHaveLoaded);

    const patientDetails = props.patientDetails;

    const [showCarePlanPopup, setShowCarePlanPopup] = useState(openLegacyGoals || false);
    const { careTeamMembers, refreshCareTeam } =
        useCareTeam(props.match.params.patientId, props.data.careTeam, { show_history: true }) ??
        [];

    const isProviderOnCareTeam = checkIfProviderOnCareTeam(profile, careTeamMembers);

    return (
        <>
            {showCarePlanPopup && (
                <CarePlanCreation
                    close={() => {
                        const searchParams = new URLSearchParams(props.location.search);
                        searchParams.delete("openLegacyGoals");
                        props.history.push({
                            pathname: location.pathname,
                            search: searchParams.toString(),
                        });
                        setShowCarePlanPopup(false);
                    }}
                    patientId={props.match.params.patientId}
                    isPatientInactive={patientDetails["status"] === "INACTIVE"}
                />
            )}

            <div className="MemberProfileCarePlan--container">
                <DemographicsCard
                    patientId={props.match.params.patientId}
                    demographics={{
                        ...props.carePlan?.demographics,
                        pcp_name: patientDetails?.pcp_name,
                        pcp_location: patientDetails?.pcp_location,
                    }}
                />
                <CareTeamCard
                    patientId={props.match.params.patientId}
                    careTeamMembers={careTeamMembers}
                    refreshCareTeam={refreshCareTeam}
                />

                {/* Care plan doesn't exist for unregistered patients, which these components require */}
                {patientDetails?.registration_date && (
                    <>
                        <CareGoalsCard
                            patientId={props.match.params.patientId}
                            careTeamMembers={careTeamMembers}
                            patientDetails={props.patientDetails}
                            isProviderOnCareTeam={isProviderOnCareTeam}
                            refreshCareTeam={refreshCareTeam}
                        />
                        <PatientGoalsStrengthsCautions
                            patientId={props.match.params.patientId}
                            isProviderOnCareTeam={isProviderOnCareTeam}
                        />
                    </>
                )}

                {!isUserPCP() && (
                    <A5AssessmentCard assessmentSummaries={props.carePlan?.assessment_summaries} />
                )}
                <CarePlanScreenings
                    phqSummary={props.carePlan?.assessment_summaries?.phq}
                    gadSummary={props.carePlan?.assessment_summaries?.gad}
                />

                <CarePlanCard
                    patientId={props.match.params.patientId}
                    showCard={() => setShowCarePlanPopup(true)}
                />
            </div>
        </>
    );
};

export default withRouter(MemberProfileCarePlan);
