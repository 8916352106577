// Copyright aptihealth, inc. 2021 All Rights Reserved
import { Form } from "formik";
import React, { useState } from "react";
import Button from "../../../UI/Button";
import { api } from "../../../../APIRequests";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { showAlertWithAction } from "../../../../redux/actions/alerts";
import "./styles.scss";
import OneTimeCodeInput, { transformErrors } from "../../OneTimeCodeInput";
import jwt_decode from "jwt-decode";
import _ from "lodash";

const EnterOTC = (props) => {
    const { sharedWorkflowData, setSharedWorkflowData } = props;

    const isExistingUser = sharedWorkflowData.workflow_instance.state.username;

    const [otc, setOtc] = useState("");
    const [errors, setErrors] = useState([]);

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            const options = { params: { showLoader: true } };
            const data = {
                otc: otc,
            };
            const otcVerificationResponse = await api.auth.verify_otc({ options, data });
            if (otcVerificationResponse.errors) {
                const transformedErrors = transformErrors(otcVerificationResponse.errors);
                setErrors(transformedErrors);

                const state = {};
                otcVerificationResponse.errors.forEach((error) => {
                    if (error === "INVALID_OTC") {
                        state["invalid_otc_used"] = true;
                    }

                    if (error === "ATTEMPT_AFTER_EXPIRED") {
                        state["expired_otc_used"] = true;
                    }
                });

                if (_.isEmpty(state)) {
                    return;
                }

                const data = {
                    workflow_instance_entity_id: sharedWorkflowData.workflow_instance.entity_id,
                    workflow_instance_sort_key: sharedWorkflowData.workflow_instance.sort_key,
                    node_name: "OTC",
                    state: state,
                };
                const response = await api.workflow.update_state_and_get_next_node({
                    options,
                    data,
                });
                setSharedWorkflowData({
                    ...sharedWorkflowData,
                    workflow_instance: response.workflow_instance,
                });
            } else {
                let idToken = otcVerificationResponse.auth.AuthenticationResult.IdToken;
                const username = jwt_decode(idToken)["cognito:username"];
                const data = {
                    workflow_instance_entity_id: sharedWorkflowData.workflow_instance.entity_id,
                    workflow_instance_sort_key: sharedWorkflowData.workflow_instance.sort_key,
                    node_name: "OTC",
                    state: {
                        otc_auth_success: true,
                        username: username,
                    },
                };

                global.workflowAuthIdToken = idToken;
                global.workflowAuthAccessToken =
                    otcVerificationResponse.auth.AuthenticationResult.AccessToken;
                global.workflowAuthRefreshToken =
                    otcVerificationResponse.auth.AuthenticationResult.RefreshToken;

                const response = await api.workflow.update_state_and_get_next_node({
                    options,
                    data,
                });
                setSharedWorkflowData({
                    ...sharedWorkflowData,
                    workflow_instance: response.workflow_instance,
                });
            }
        } catch (e) {
            console.log(e);
            props.showAlertWithAction("Error verifying one-time code.");
        }
    };

    const signInWithCredentialsClickHandler = async () => {
        const options = { params: { showLoader: true } };
        const data = {
            workflow_instance_entity_id: sharedWorkflowData.workflow_instance.entity_id,
            workflow_instance_sort_key: sharedWorkflowData.workflow_instance.sort_key,
            node_name: "OTC",
            state: {
                otc_auth_success: false,
                request_to_sign_in_with_credentials: true,
            },
        };
        const response = await api.workflow.update_state_and_get_next_node({ options, data });
        setSharedWorkflowData({
            ...sharedWorkflowData,
            workflow_instance: response.workflow_instance,
        });
    };

    const renderForm = () => {
        return (
            <Form style={{ marginBottom: 200 }}>
                <div className={"workflow-container-sm"}>
                    <div className="mx-auto px-0 my-5">
                        <OneTimeCodeInput
                            codeLength={5}
                            setOtc={setOtc}
                            errors={errors}
                            setErrors={setErrors}
                        />
                    </div>
                </div>

                {!isExistingUser && (
                    <div className={"workflow-container-sm"}>
                        <div className="mx-auto px-0 mb-1">
                            <div className="d-flex justify-content-between mb-5">
                                <Button
                                    type={"button"}
                                    className={"Btn Btn--otl-gry Btn--sm-2 workflow-btn"}
                                    onClick={props.backHandler}>
                                    Back
                                </Button>
                                <Button
                                    type={"submit"}
                                    className={"Btn Btn--pri Btn--sm-2 workflow-btn"}
                                    disabled={otc.length < 5 ? "disabled" : null}
                                    onClick={submitHandler}>
                                    Continue
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                {isExistingUser && (
                    <div className="d-flex justify-content-between flex-column align-items-center">
                        <Button
                            type={"submit"}
                            className={"Btn Btn--pri Btn--sm-2 workflow-btn-lg"}
                            key={"submit"}
                            disabled={otc.length < 5 ? "disabled" : null}
                            onClick={submitHandler}>
                            Sign in
                        </Button>
                        <div
                            style={{ color: "#6C757D" }}
                            className={
                                "d-flex justify-content-center align-items-center pt-4 pb-4"
                            }>
                            <hr className={"lines mr-3"} />
                            or
                            <hr className={"lines ml-3"} />
                        </div>
                        <Button
                            onClick={signInWithCredentialsClickHandler}
                            className={"Btn Btn--otl-pri Btn--sm-2 workflow-btn-lg"}
                            type={"button"}>
                            Sign in with credentials
                        </Button>
                    </div>
                )}
            </Form>
        );
    };

    return (
        <>
            <div className={"p-lg-5 p-3 my-5"}>
                {!isExistingUser && (
                    <>
                        <div className={"fs-22 txt-pri fw-bold mb-5 text-center"}>
                            Enter one-time code to continue
                        </div>
                        <div className={"text-center mt-3 mb-4 workflow-container-sm"}>
                            Please locate a staff member for help. A staff member will read you a
                            one-time code.
                            <div className={"fw-bold"}>Enter the 5-digit code below.</div>
                        </div>
                    </>
                )}
                {isExistingUser && (
                    <>
                        <div className={"fs-22 txt-pri fw-bold mb-5 text-center"}>
                            We found an existing account
                        </div>
                        <div className={"text-center mt-3 mb-4 workflow-container-sm"}>
                            We found an account that matches your information. Please locate a staff
                            member for help. A staff member will read you a one-time code.
                            <div className={"fw-bold"}>Enter the 5-digit code below.</div>
                        </div>
                    </>
                )}
                <div className={"my-5"}>
                    {sharedWorkflowData && sharedWorkflowData.workflow_instance && renderForm()}
                </div>
            </div>
        </>
    );
};

export default connect(null, { showAlertWithAction })(withRouter(EnterOTC));
