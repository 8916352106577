import React from "react";
import { UploadFileModal } from "../../UploadFileModal";

export const UploadFileModalHOC = ({
    showInsuranceCard,
    insuranceHide,
    onFrontInputChange,
    onBackInputChange,
    insuranceType, // dont need
    frontImagePreview, // dont need
    backImagePreview, // dont need
    done,
    removeImg, // dont need
    insEdit, // dont need
    frProgress, // dont need
    bckProgress, // dont need
}) => {
    return (
        <UploadFileModal
            show={showInsuranceCard}
            onFrontFileInputChange={(e) => onFrontInputChange(e, "pri")}
            onBackFileInputChange={(e) => onBackInputChange(e, "pri")}
            onSubmit={() => done("pri")}
            onCancel={insuranceHide}
        />
    );
};
