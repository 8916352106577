// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as actionTypes from "../actions/actionTypes";

let initialState = {
    sessionToken: undefined,
};

const logout = (state, action) => {
    return {
        ...initialState,
    };
};

export const paymentProcessorReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_PAYMENT_PROCESSOR_SESSION:
            return {
                ...state,
                sessionToken: action.payload.sessionToken,
            };
        case actionTypes.LOG_OUT:
            return logout(state, action);
        default:
            return state;
    }
};
