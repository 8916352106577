import moment from "moment-timezone";

export const durationToString = (duration) => {
    let hours = duration.hours().toString().padStart(2, "0");
    let minutes = duration.minutes().toString().padStart(2, "0");
    let seconds = duration.seconds().toString().padStart(2, "0");
    return hours === "00" ? `${minutes}:${seconds}` : `${hours}:${minutes}:${seconds}`;
};

export const convertUnknownDateStringFormat = (rawDateStr, targetFormat) => {
    const possibleRegex = [
        { exp: /[0-9]{10}.[0-9]*/g, dateFormat: "X" },
        { exp: /[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}/g, dateFormat: "YYYY-MM-DD HH:mm" },
        {
            exp: /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.*/g,
            dateFormat: "YYYY-MM-DD HH:mm:ssZ",
        },
        { exp: /[0-9]{2}\/[0-9]{2}\/[0-9]{4} [0-9]{2}:[0-9]{2}/g, dateFormat: "MM/DD/YYYY HH:mm" },
    ];
    const regex = possibleRegex.find((regex) => rawDateStr.match(regex.exp));
    const formattedDate = moment(rawDateStr, regex.dateFormat).format(targetFormat);
    return formattedDate !== "Invalid date" ? formattedDate : rawDateStr;
};

/**
 * Calculates Age given the Date of Birth
 * @param {string} dateOfBirth - Assumes MM/DD/YYYY format
 */
export const calculateAge = function (dateOfBirth) {
    if (!dateOfBirth) {
        return "";
    }
    const splitDate = dateOfBirth.split("/");
    const parsedDate = moment(`${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`);
    const now = moment();
    return now.diff(parsedDate, "years");
};

export const convertToStandardTimeStampFormat = (momentInstance) => {
    return `${momentInstance.tz("UTC").format("YYYY-MM-DDTHH:mm:ss.SSSSSS")}Z`;
};

export const localTimestampFromStandardTimestamp = (standardTimestamp, outputFormat) => {
    const localCompletedTime = moment.utc(standardTimestamp, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").local();

    if (localCompletedTime.isValid()) {
        return localCompletedTime.format(outputFormat);
    }

    return "";
};
