// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import { TitleBarItem } from "../../../../components/UI/ListView";
import {
    ACTIVE_CONF_ADMIN,
    ACTIVE_CONF_PROVIDER,
    PENDING_CONF_ADMIN,
    PENDING_CONF_PROVIDER,
    ARCHIVED_CONF_PROVIDER,
    ARCHIVED_CONF_ADMIN,
    ADMIN,
    PROVIDER,
    ACTIVE,
    PENDING,
    ARCHIVED,
} from "./constants";

const TitleBarItems = (props) => {
    const { source, status } = props;
    let selectedConf;

    if (source === PROVIDER && status === ACTIVE) {
        selectedConf = ACTIVE_CONF_PROVIDER;
    } else if (source === PROVIDER && status === PENDING) {
        selectedConf = PENDING_CONF_PROVIDER;
    } else if (source === ADMIN && status === ACTIVE) {
        selectedConf = ACTIVE_CONF_ADMIN;
    } else if (source === ADMIN && status === PENDING) {
        selectedConf = PENDING_CONF_ADMIN;
    } else if (source === PROVIDER && status === ARCHIVED) {
        selectedConf = ARCHIVED_CONF_PROVIDER;
    } else if (source === ADMIN && status === ARCHIVED) {
        selectedConf = ARCHIVED_CONF_ADMIN;
    }

    return selectedConf.map((titleConf, i) => {
        return (
            <TitleBarItem
                classes={titleConf.colValue}
                text={titleConf.listTitleText}
                alignCenter={titleConf.center}
            />
        );
    });
};

export default TitleBarItems;
