export const buildDefaultHCPPatientFormValues = () => {
    return {
        email: "",
        email_validation: "",
        first_name: "",
        last_name: "",
        dob: "",
        gender: "",
        mobile: "",
        address: {
            street: "",
            city: "",
            state: "New York",
            zip_code: "",
        },
        emergency_name: "",
        emergency_contact_no: "",
        secondary_insurance: {
            state: "",
            carrier: "",
            card_front: "",
            card_back: "",
            member_id: "",
            group_id: "",
            policy_holder_name: "",
        },
        pcp_id: "",
        default_child_practice_autofill: false,
        child_sms_consent: false,
        consent: [],
        medication_management_services: null,
        is_spmi: false,
    };
};
