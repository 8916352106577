import React from "react";
import PropTypes from "prop-types";

import { Card } from "../Card";

import { createClassNameString } from "../utils";

import "./styles.scss";

export const PageBanner = ({ children, className, ...props }) => (
    <Card className={createClassNameString(["apti-PageBanner", className])} {...props}>
        {children}
    </Card>
);

PageBanner.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    className: PropTypes.string,
};

PageBanner.defaultProps = {
    children: <></>,
    className: undefined,
};
