// Copyright aptihealth, inc. 2020 All Rights Reserved
import React from "react";
import "./styles.scss";
import { Button, ButtonTypes } from "../../../../component-library";
import CloseOnOutsideClick from "../../../../components/Common/CloseOnOutsideClick/CloseOnOutsideClick";

const DeleteNote = (props) => {
    return (
        <div className="DeleteNoteModal--body">
            <CloseOnOutsideClick className="DeleteNoteModal--inner" setShowComponent={props.close}>
                <div>
                    <h5 className="fw-bold text-center fs-17 m-0 DeleteNoteModal--title">
                        Delete Note
                    </h5>
                    <span className="text-center">
                        Are you sure you want to delete this Progress Note?
                    </span>
                </div>
                <div className="DeleteNoteModal--footer">
                    <Button onClick={props.close} buttonType={ButtonTypes.primaryOutlineV2}>
                        Cancel
                    </Button>
                    <Button onClick={props.clickHandler} buttonType={ButtonTypes.primaryV2}>
                        Delete
                    </Button>
                </div>
            </CloseOnOutsideClick>
        </div>
    );
};

export default DeleteNote;
