import * as yup from "yup";
import { XSS_REGEX } from "../../../../../constants/security";

export const OTHER_TEXT = "Other (Specify...)";

export const ADD_GOAL_VALIDATION = yup.object().shape({
    goal_text_id: yup.string().required("Goal is required"),
    goal_other_text: yup.string().when("goal_text", {
        is: OTHER_TEXT,
        then: yup
            .string()
            .required(`Goal specification is required when selecting "${OTHER_TEXT}"`)
            .test("special characters", "Special characters are not allowed", (value) => {
                return !XSS_REGEX.test(value);
            }),
    }),
    objective_text_id: yup.string().required("An objective is required"),
    objective_other_text: yup.string().when("objective_text", {
        is: OTHER_TEXT,
        then: yup
            .string()
            .required(`Objective specification is required when selecting "${OTHER_TEXT}"`)
            .test("special characters", "Special characters are not allowed", (value) => {
                return !XSS_REGEX.test(value);
            }),
    }),
    objective_target_date: yup
        .date()
        .required("Objective target date is required")
        .min(new Date(), "Objective target date must be after start date (today)"),
});

export const ADD_OBJECTIVE_VALIDATION = yup.object().shape({
    objective_text_id: yup.string().when("goal_text", (goal_text) => {
        if (goal_text !== OTHER_TEXT) {
            return yup.string().required("Objective is required");
        }
        return yup.string();
    }),
    objective_other_text: yup
        .string()
        .when(["goal_text", "objective_text"], (goal_text, objective_text) => {
            if (goal_text === OTHER_TEXT || objective_text === OTHER_TEXT) {
                return yup
                    .string()
                    .required("Objective specification is required")
                    .test("special characters", "Special characters are not allowed", (value) => {
                        return !XSS_REGEX.test(value);
                    });
            }
            return yup.string();
        }),
    objective_target_date: yup
        .date()
        .required("Objective target date is required")
        .min(new Date(), "Objective target date must be after start date (today)"),
});

export const EDIT_OBJECTIVE_VALIDATION = yup.object().shape({
    objective_target_date: yup
        .date()
        .required("Objective target date is required")
        .min(new Date(), "Objective target date must be in the future"),
});

export const COMPLETE_OR_REMOVE_OBJECTIVE_VALIDATION = yup.object().shape({
    new_goal_status: yup.string().when("isLastObjective", (isLastObjective) => {
        if (isLastObjective) {
            return yup.string().required("A new goal status is required.");
        }
        return yup.string();
    }),
});
